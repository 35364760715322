import { v4 } from "uuid";

export const addIdsToObjects = (arr) => {
  const updatedArr = arr.map((obj) => {
    if (!obj.id) {
      obj.id = v4();
    }
    return obj;
  });

  return updatedArr;
};
