import { useState } from "react";
import { MondayButton } from "../../../commonComponents";
import EmailsModal from "../../../commonComponents/EmailsModal";
import { EmailIcon } from "../../BasePage/src";

const IncidentEmails = ({ recordId, categoryName, title }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <MondayButton
        {...{
          "data-testid": "email-button",
          Icon: <EmailIcon />,
          className: "mondayButtonBlue",
          onClick: () => setOpen(true),
        }}
      >
        Communication
      </MondayButton>

      {open && (
        <EmailsModal
          title={title}
          open={open}
          setOpen={setOpen}
          recordId={recordId}
          categoryName={categoryName}
        />
      )}
    </>
  );
};

export default IncidentEmails;
