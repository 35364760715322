import { getDispatchLocationOptions } from "../../../../Header/forms/RequestFormModal/requestFormModalData";
import { searchSafetyAddress } from "../helpers";

const ADDITIONAL_LABEL_TEXT =
  " besides the named individual above? If so, please indicate everyone's full name and a brief description. If no one else was affected, please indicate N/A.";
const ATTESTATION_TEXT = " (if yes, please attach the attestation)";

/**
 * Adds conditional fields to the given fields array based on the provided conditions.
 *
 * @param {Array} fields - The array of fields.
 * @param {Object} field - The field object.
 * @param {number} fieldCategoryIndex - The index of the field category.
 * @param {string} selectedService - The selected service.
 * @param {string} typeOfEquipment - The type of equipment.
 * @param {string} immediateActions - The immediate actions taken following the incident.
 */
function addConditionalFields({
  fields,
  field,
  fieldCategoryIndex,
  selectedService,
  typeOfEquipment,
  immediateActions,
}) {
  if (
    field.formItemName === "serviceBeingDone" &&
    selectedService === "Other"
  ) {
    fields[fieldCategoryIndex]["fields"].push({
      type: "input",
      formItemName: "otherService",
      label: "Other Service",
      required: true,
      placeholder: "Other Service",
      category: field.category,
    });
  } else if (
    field.formItemName === "typeOfEquipmentInvolved" &&
    typeOfEquipment === "Other"
  ) {
    fields[fieldCategoryIndex]["fields"].push({
      type: "input",
      formItemName: "otherEquipment",
      label: "Other Equipment",
      required: true,
      placeholder: "Other Equipment",
      category: field.category,
    });
  } else if (
    field.formItemName === "immediateActionsTakenFollowingTheIncident" &&
    (immediateActions === "Escorted by..." || immediateActions === "Other...")
  ) {
    fields[fieldCategoryIndex]["fields"].push({
      type: "input",
      formItemName: "otherAction",
      label:
        immediateActions === "Escorted by..." ? "Escorted By" : "Other Action",
      required: true,
      placeholder:
        immediateActions === "Escorted by..." ? "Escorted By" : "Other Action",
      category: field.category,
    });
  }
}

/**
 * Generates an array of fields for the personal injury safety form.
 *
 * @param {Object} form - The form object.
 * @param {Array} [crews=[]] - The array of crews.
 * @param {Array} [projects=[]] - The array of projects.
 * @param {string} [defaultId=""] - The default ID.
 * @param {Array} [scheduling=[]] - The array of scheduling options.
 * @param {string} [propAddress=""] - The property address.
 * @param {Array} [dynamicFields=[]] - The array of dynamic fields.
 * @param {string} [selectedService=""] - The selected service.
 * @param {string} [typeOfEquipment=""] - The type of equipment.
 * @param {boolean} [mainDisabledField=false] - Whether the main disabled field is enabled or not.
 * @param {string} [immediateActions=""] - The immediate actions.
 * @param {Array} [schedulesForDate=[]] - The array of schedules for the date.
 * @param {Object} [userConfiguration={}] - The user configuration object.
 * @param {boolean} [disabledAllFields=false] - Whether all fields are disabled or not.
 * @param {Function} [onChangeAddress=()=>{}] - The function to handle address change.
 * @param {Function} [changeDateOfIncident=()=>{}] - The function to handle date of incident change.
 * @param {Function} [dispatchSafetyFormData=()=>{}] - The function to dispatch safety form data.
 * @returns {Array} - The array of fields for the personal injury safety form.
 */
const personalInjurySafetyFields = ({
  form,
  crews = [],
  projects = [],
  defaultId = "",
  scheduling = [],
  propAddress = "",
  dynamicFields = [],
  typeOfEquipment = "",
  selectedService = "",
  immediateActions = "",
  schedulesForDate = [],
  userConfiguration = {},
  projectExecutives = [],
  mainDisabledField = false,
  disabledAllFields = false,
  onChangeAddress = () => {},
  changeDateOfIncident = () => {},
  dispatchSafetyFormData = () => {},
}) => {
  let fields = [];

  const projectExecutivesOpt =
    projectExecutives?.map((executive) => ({
      id: executive.peId,
      label: `${executive.firstName} ${executive.lastName}`,
      value: `${executive.firstName} ${executive.lastName}`,
    })) || [];

  const foremen = crews
    ?.filter((e) => !!e?.foreman)
    ?.map(({ crewId, crewName, members }) => ({
      label: crewName,
      value: crewName,
      members,
      key: crewId,
    }));

  const crewMembers = crews
    ?.filter((e) => !e?.foreman)
    ?.map(({ crewId, crewName }) => ({
      label: crewName,
      value: crewId,
    }));

  for (let i = 0; i < dynamicFields?.length; i++) {
    let field = structuredClone(dynamicFields[i]);
    delete field["placeholder"];

    if (field.type === "select") {
      field = {
        ...field,
        showSearch: true,
        allowClear: true,
        ...(field.label.includes("Project Executive")
          ? { customOptions: projectExecutivesOpt }
          : {}),
      };
    }

    if (field.type === "placesautocomplete") {
      field = {
        ...field,
        allowClear: true,
        style: { width: 320 },
      };
    }

    if (field.formItemName === "didInjuredIndividualReturnToWorkOnTheSameDay") {
      field = {
        ...field,
        label: field.label + ATTESTATION_TEXT,
        style: { maxWidth: "30%" },
      };
    }

    if (field.formItemName === "fullNameOfInjuredIndividual") {
      field = {
        ...field,
        disabled: mainDisabledField || disabledAllFields,
      };
    }

    if (field.formItemName === "reportPreparedBy") {
      field = {
        ...field,
        customOptions:
          userConfiguration.allUsers?.Items?.map((e) => ({
            value: e.identityId,
            label: e.nameOfUser,
          })) || [],
        initialValue: !defaultId && userConfiguration?.identityId,
      };
    }

    if (field.formItemName === "fullNameOfForeman") {
      field = {
        ...field,
        customOptions: foremen || [],
        type: "predictautocomplete",
        addNewShow: false,
      };
    }

    if (
      field.formItemName === "dateOfInjury" ||
      field.formItemName === "reportPreparedOn"
    ) {
      field = {
        ...field,
        ...(field.formItemName === "dateOfInjury"
          ? { customOnChange: changeDateOfIncident }
          : {}),
        validTime: "past",
        form,
        // disabledDate(e) {
        //   return e > dayjsNY().add(1, "day").startOf("D").valueOf();
        // },
      };
    }

    if (field.formItemName === "timeOfInjury") {
      field = {
        ...field,
        dateField: "dateOfInjury",
        validTime: "past",
        form,
      };
    }

    if (field.formItemName === "addressOfInjury") {
      field = {
        ...field,
        onSelect: onChangeAddress,
        initialValue: propAddress,
        groupedOptions:
          getDispatchLocationOptions(schedulesForDate, projects) || [],
        showSearch: true,
        customOnSearch: async (searchValue) =>
          await searchSafetyAddress({
            searchValue,
            dispatchSafetyFormData,
            userConfiguration,
            projects,
            scheduling,
            schedulesForDate,
            dateField: "dateOfInjury",
            form,
          }),
      };
    }

    let fieldCategoryIndex = fields.findIndex(
      (e) => e?.title === field?.category
    );
    if (fieldCategoryIndex > -1) {
      fields[fieldCategoryIndex]["fields"].push(field);

      addConditionalFields({
        fields,
        field,
        fieldCategoryIndex,
        selectedService,
        typeOfEquipment,
        immediateActions,
      });
    } else {
      fields.push({
        title: field?.category,
        fields: [field],
      });
      addConditionalFields({
        fields,
        field,
        fieldCategoryIndex: fields.length - 1,
        selectedService,
        typeOfEquipment,
        immediateActions,
      });
    }

    if (disabledAllFields) {
      field.disabled = disabledAllFields;
    }
  }

  let additionalInfoOptions = fields.findIndex(
    (e) => e.title === "Additional Information"
  );
  if (additionalInfoOptions > -1) {
    let tmp = fields.splice(additionalInfoOptions, 1);
    fields.push(tmp[0]);
  }

  return fields;
};

export default personalInjurySafetyFields;
