import { fetchData } from "../ApiMethods";

export const allUsers = async ({ keysToInclude }) => {
  try {
    const user = await fetchData({
      endpoint: "userConfiguration",
      otherStringParams: {
        keysToInclude: JSON.stringify([
          ...new Set([...keysToInclude, "identityId"]),
        ]),
      },
    });

    return user.allUsers.Items;
  } catch (error) {
    console.error("Data source User Invitations error: ", error);
  }
};
