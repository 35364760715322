import { Tooltip } from "antd";
import { memo } from "react";
import { formatNumber } from "src/components/SidebarPages/utils";
import { SmallLoadableComp } from "src/components/Sidebars/components";

const FenceList = memo(function FenceList({
  view,
  onFenceClick,
  sortedFences,
  selectedFence,
  sortedProjects,
  projectsLoading,
}) {
  return (
    <div className="fences-section" style={{ position: "relative" }}>
      {view === "PROJECTS" && projectsLoading ? (
        <SmallLoadableComp loading={projectsLoading}>{null}</SmallLoadableComp>
      ) : null}
      {(view === "PROJECTS"
        ? projectsLoading
          ? []
          : sortedProjects
        : sortedFences
      ).map((el, index) => {
        return (
          <Tooltip title={el.accountName}>
            <div
              className={`fence-info ${
                selectedFence?.id === el?.id ? "location-selected" : ""
              }`}
              key={view + `-${index}`}
              onClick={() => {
                onFenceClick(el);
              }}
            >
              <b className="fence-name">{el.address}</b>
              <span className="fence-distance">
                {el.distance.distanceInFeet > 1000
                  ? formatNumber(el.distance.distanceInMile, {
                      unit: "mile",
                    })
                  : formatNumber(el.distance.distanceInFeet, {
                      unit: "foot",
                    })}
              </span>
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
});

export default FenceList;
