import moment from "moment";

export function updateTodoDetailsInConfigs(configs) {
  if (typeof configs === "object" && configs !== null) {
    return updateDetails(configs);
  }

  return configs;
}

function updateDetails(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === "string") {
        obj[key] = { hide: "", isDefault: "" };
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        updateDetails(obj[key]);
      }
    }
  }
  return obj;
}

export const defaultColumnDefsBasePage = (columnDefs) => {
  const findAFieldToSetAsDefault = columnDefs.filter((col) => {
    return (
      col?.hasOwnProperty("link") ||
      lowerCaseTrim(col?.field)?.includes("status") ||
      lowerCaseTrim(col?.headerName)?.includes("status") ||
      lowerCaseTrim(col?.field)?.includes("notes") ||
      lowerCaseTrim(col?.headerName)?.includes("notes") ||
      lowerCaseTrim(col?.headerName)?.includes("team") ||
      lowerCaseTrim(col?.field)?.includes("team")
    );
  });

  console.log({ findAFieldToSetAsDefault });

  return findAFieldToSetAsDefault && findAFieldToSetAsDefault.length
    ? findAFieldToSetAsDefault
    : [columnDefs?.[0]];
};

export const createColumnDefsFromDeviceConfigs = (
  deviceManagementConfigs,
  sourceColumnDefs
) => {
  const columnDefs = deviceManagementConfigs
    .map((dynamicColDef) => {
      const colDef = sourceColumnDefs.find((col) => {
        const sourceHeaderName = trimLowerCase(col?.headerName);
        const sourceField = trimLowerCase(col?.field);
        const dynamicHeaderName = trimLowerCase(dynamicColDef?.headerName);
        const dynamicField = trimLowerCase(dynamicColDef?.field);

        return (
          sourceHeaderName === dynamicHeaderName ||
          sourceField === dynamicField ||
          dynamicHeaderName.includes("checkbox")
        );
      });

      if (colDef) {
        return {
          ...colDef,
          hide: dynamicColDef?.hide,
        };
      }

      return dynamicColDef;
    })
    .filter(Boolean);

  const enhancedCols = columnDefs.map((col) => ({
    ...col,
    valueFormatter: ({ value }) => {
      if (checkIfItsDate(value)) {
        if (!checkDateFormat(value)) {
          return enhanceDateFormat(value);
        }
      }
      return value;
    },
    suppressColumnsToolPanel: col?.hide || false,
  }));

  // const defaultColumnDefs = defaultColumnDefsBasePage(sourceColumnDefs);

  // const merged = [...enhancedCols, ...defaultColumnDefs];

  // const uniqueValues = merged
  //   .filter(
  //     (value, index, self) =>
  //       index ===
  //       self.findIndex((t) => {
  //         const headerName1 = t?.headerName?.toLowerCase().trim() || "";
  //         const headerName2 = value?.headerName?.toLowerCase().trim() || "";
  //         return headerName1 === headerName2;
  //       })
  //   )
  // .map((column) => ({
  //   ...column,
  //   suppressColumnsToolPanel: column?.hide || false,
  // }));

  // const orderedColumns =
  //   uniqueValues && uniqueValues?.length
  //     ? uniqueValues?.sort((a, b) => {
  //         const aHasLink = a?.hasOwnProperty("link");
  //         const bHasLink = b?.hasOwnProperty("link");
  //         if (aHasLink && !bHasLink) return -1;
  //         if (!aHasLink && bHasLink) return 1;
  //         return 0;
  //       })
  //     : [];

  return enhancedCols;
};

function trimLowerCase(value) {
  if (value) {
    return value?.trim()?.toLocaleLowerCase();
  } else {
    return "";
  }
}

function checkDateFormat(value) {
  const regex = /^(0[1-9]|1[0-2])\/([0-2][1-9]|3[0-1])\/\d{4}$/;
  return value ? regex.test(value) : false;
}

function enhanceDateFormat(value) {
  if (value) {
    const date = new Date(value);
    return moment(date).format("MM/DD/YYYY");
  } else {
    return "";
  }
}

function checkIfItsDate(value) {
  if (value) {
    if (
      (typeof value !== "string" &&
        !Array.isArray(value) &&
        typeof value !== "object" &&
        value.toString().length === 10) ||
      value.toString().length === 12
    ) {
      const date = new Date(value);
      if (!isNaN(date.getTime())) {
        return true;
      } else {
        return false;
      }
    }
  }
  return false;
}

function lowerCaseTrim(value) {
  return value ? value.toLowerCase().trim() : "";
}

export function compareObjectsClones(toCloneFrom, toBeCloned) {
  const differences = [];

  for (const key in toCloneFrom) {
    if (toCloneFrom.hasOwnProperty(key)) {
      if (toBeCloned.hasOwnProperty(key)) {
        const isSame =
          JSON.stringify(toCloneFrom[key]) === JSON.stringify(toBeCloned[key]);
        differences.push({
          key,
          status: isSame ? "exists" : "missing",
        });
      } else {
        differences.push({
          key,
          status: "missing",
        });
      }
    }
  }
  return differences;
}

export const mapResponseToHierarchy = (data, hierarchy) => {
  if (!hierarchy) return data;

  if (Array.isArray(data)) {
    return data.map((item) => mapResponseToHierarchy(item, hierarchy));
  }

  const mappedData = data?.[hierarchy.key];
  if (!mappedData) return [];

  if (Array.isArray(mappedData)) {
    return mappedData.map((item) =>
      hierarchy.subKeys ? mapResponseToHierarchy(item, hierarchy.subKeys) : item
    );
  }

  if (hierarchy.subKeys) {
    return mapResponseToHierarchy(mappedData, hierarchy.subKeys);
  }

  return mappedData;
};

export function enhanceBasePageTitle(pathname, mainTitle) {
  if (!mainTitle && !pathname) {
    return "";
  }
  return `${formatString(pathname)} ${mainTitle}`;
}

function formatString(input) {
  return input
    .replace(/\//g, " ")
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ")
    .trim();
}

export function removeDynamicDigitPart(url) {
  if (!url) return;
  return url?.replace(/\/[^\/?]+\b[0-9a-fA-F\-]+\b/, "");
}

export function findMainTitle(path = "", sections = []) {
  let mainTitle = null;

  const search = (items, parentTitle = null) => {
    for (const item of items) {
      if (item.path === path) {
        mainTitle = parentTitle || item.title;
        return true;
      }

      if (item.tabs) {
        if (search(item.tabs, parentTitle || item.title)) {
          return true;
        }
      }
    }
    return false;
  };

  search(sections);

  return mainTitle;
}

export function findPositionByTitle(sections = [], category) {
  let position = null;

  const search = (items) => {
    for (const item of items) {
      // Check if the title matches the category
      if (item.title === category) {
        position = item.position;
        return true; // Stop searching further
      }

      // If nested tabs exist, search within them
      if (item.tabs) {
        if (search(item.tabs)) {
          return true; // Match found in nested tabs
        }
      }
    }
    return false; // No match found
  };

  search(sections);

  return position;
}

export function findTitleAtSameLevel(path = "", sections = []) {
  let title = null;

  const search = (items) => {
    return items.some((item) => {
      if (item.path === path) {
        title = item.title;
        return true;
      }

      if (item.tabs) {
        return search(item.tabs);
      }

      return false;
    });
  };

  search(sections);

  return title;
}

export const createBreadCrump = (items) => {
  return items.map((el) => ({ title: el }));
};
