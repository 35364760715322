import React from "react";
import SendEmailButton from "./SendEmailButton";
import FormattingOptions from "./formatting-options/FormattingOptions";
import AttachFiles from "./attach-files/AttachFiles";
import DocumentationTypes from "./documentation-types/DocumentationTypes";
import { DynamicFieldsProvider } from "../../../providers/DynamicFieldsProvider";
import DriveItems from "./drive-items/DriveItems";
import EmailBoxTemplates from "./templates/EmailBoxTemplates";
import Signatures from "./signatures/Signatures";
import { useSelector } from "react-redux";
import { DocumentationTypesProvider } from "../../../providers/DocumentationTypesProvider";
import DynamicFields from "../../dynamic-fields/DynamicFields";
import { useInitialEmailBoxProps } from "../../../providers/InitialEmailBoxProvider";

import "./EmailBoxFooter.scss";

function EmailBoxFooter({ rowData, recordDetails }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  // Checking if the component is redirected via initial email box props
  const { isRedirected } = useInitialEmailBoxProps();

  return (
    <div
      className={`email-box-footer-container ${
        isDarkMode ? " dark-mode" : ""
      } `}
      data-testid="email-box-footer"
    >
      {/* Button to send the email */}
      <SendEmailButton />

      {/* Options to format the email content */}
      <FormattingOptions />
      {/* Provider for documentation types, wrapping related components */}
      <DocumentationTypesProvider>
        <AttachFiles isRedirected={isRedirected} />

        {/* Provider for dynamic fields, wrapping related components */}
        <DynamicFieldsProvider>
          {/* Documentation types related to the record details */}
          <DocumentationTypes recordDetails={recordDetails} />

          <DriveItems rowData={rowData} recordDetails={recordDetails} />
          <EmailBoxTemplates />
          {/* <DynamicFields rowData={rowData} recordDetails={recordDetails} /> */}
        </DynamicFieldsProvider>
      </DocumentationTypesProvider>

      <Signatures />
    </div>
  );
}

export default EmailBoxFooter;
