import { Card, Modal } from "antd";
import React from "react";
import "./ForecastingCharts.scss";
import { Bar, Doughnut, PolarArea, Line } from "react-chartjs-2";
import { XIcon } from "../../../../../Communication/assets";

const ForecastingCharts = ({
  visibleCharts = {},
  setVisibleCharts = () => {},
}) => {
  const { visible, data } = visibleCharts || {};
  const { type = "", values = [] } = data || {};

  const cards = [
    { label: "Planned Nr of Days", key: "plannedNrOfDays" },
    { label: "Planned Nr of Hours", key: "plannedNrOfHours" },
    { label: "Planned Nr of Crew Members", key: "plannedNrOfCrews" },
    { label: "Planned Nr of Trucks", key: "plannedNrOfTrucks" },
  ];

  const manipulateData = values?.map((item) => ({
    ...item,
    display: item?.display?.flatMap((displayItem) => displayItem?.services),
  }));
  console.log("manipulateData", { manipulateData });

  return (
    <div>
      <Modal
        {...{
          title: "Forecasting Charts",
          centered: true,
          open: visible,
          onCancel: () => setVisibleCharts({}),
          className: "forecasting-charts",
          footer: null,
          closeIcon: <XIcon />,
        }}
      >
        <h1>{type} charts</h1>
        {/* {cards?.map((card) => {
          return <Card title={card?.label}>
            {manipulateData?.map((data) => {
              return <Card />
            })}
          </Card>;
        })} */}
      </Modal>
    </div>
  );
};

export default ForecastingCharts;
