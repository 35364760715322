import { useEffect, useState, Suspense, lazy, useMemo } from "react";
import { Route, useNavigate, Routes, useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import OldRoutes from "./Routes";
import "./containers/ForgotPassword/ForgotPassword.css";
const FleetsLiveView = lazy(() =>
  import("./components/SidebarPages/Fleet/fleetsLive/FleetsLiveView")
);
const PermitDrawings = lazy(() =>
  import("./Dashboard/PermitDrawingDashboard/PermitDrawingDashboard")
);
const EstimationDashboard = lazy(() =>
  import("./Dashboard/EstimationDashboard/EstimationDashboard")
);

const DocumentationDashboard = lazy(() =>
  import("./Dashboard/DocumentationDashboard/DocumentationDashboard")
);

const Pdf = lazy(() => import("./components/SidebarPages/Projects/Pdf/Pdf"));
const SettingsPage = lazy(() => import("./components/pages/Settings"));
const Orders = lazy(() => import("./components/pages/Orders/orders"));
const OrdersReport = lazy(() =>
  import("./components/pages/Orders/OrdersReport")
);
const Payroll = lazy(() => import("./components/pages/Payroll/Payroll"));
import Axios from "axios";
import { gapi } from "gapi-script";
import moment from "moment-timezone";
import { wsEndpoints } from "./AppData";
import { useIdleTimer } from "react-idle-timer";
import { LoadableComp } from "./components/SidebarPages/XComponents";
import { useSelector, useDispatch } from "react-redux";
import { setAuthUser } from "./actions/authUser";
import "./customDefinations";
import { GOOGLE_SCOPES } from "./helpers/constants/googleScopes";
import "./index.scss";

// import "antd/dist/reset.css";
import {
  UserConfig,
  programFields,
  base64,
  hotCredentials,
  serviceDefinitions,
  preferences,
  proposedTypeOfWork,
  accessToken,
  proposedConstructionServices,
  userGroups,
  updateTopicCategories,
  updateNotificationSettings,
  sampleObjectsAction,
  yards,
  dispatchCustomerUsers,
  dispatchTeams,
} from "./actions";
import dayjs from "dayjs";
import { apiRoutes, fetchData } from "./components/SidebarPages/Fleet/utils";
import {} from "./licensing/arjs-license";
import IdleComponent from "./components/commonComponents/IdleComponent/IdleComponent";
import {
  useSocket,
  useUserSession,
  useGlobalSessionSocket,
  useUsersActivity,
  useDeviceType,
  useUserAgentNavigatorDevice,
} from "./hooks";
import { message } from "antd";
import RouterConfig from "./components/RouterConfig";
import ProposalBuilder from "./components/ProposalBuilder/ProposalBuilder";

//commit test
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";
import objectSupport from "dayjs/plugin/objectSupport";
import pluralGetSet from "dayjs/plugin/pluralGetSet";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { handleDarkMode } from "./reducers/darkModeAction";
import { NotAuthorized } from "./components";
import WaitingRoomProposal from "./components/ProposalBuilder/WaitingRoomProposal/WaitingRoomProposal";
import NewProposalBuilder from "./components/ProposalBuilder/NewProposalBuilder/NewProposalBuilder";
import { fetchAllData, filterTables, modifyPreferences } from "./utils";
import OnBoarding from "./components/OnBoarding/OnBoarding";
import {
  handleSessionMessage,
  handleUserSessionIdle,
} from "./utils/SessionsNotification/SessionsNotification";
import { useRedux } from "./components/SidebarPages/hooks";
import AskToLogoutTimer from "./components/AskToLogoutTimer/AskToLogoutTimer";
import { useAuth } from "./hooks/useAuth";
import PayrollLive from "./components/pages/PayrollLive/PayrollLive";
import TwoFactorAuthenticatorModal from "./components/TwoFactorAuthenticatorModal/TwoFactorAuthenticatorModal";
import { devices } from "./components/pages/Settings/settingsComponents/DeviceManagement/utils";
import {
  sendEmailForUnauthorizedIPDevice,
  sendUnauthorizedIPDeviceNotification,
} from "./components/pages/Settings/settingsComponents/AllowedIpAddresses/components/grid/components/utils";
import { returnDetailsForWhiteListIps } from "./components/pages/Settings/settingsComponents/AllowedIpAddresses/whiteListsIpAddressesFunctions";
import { getGeoLocation, getSessionInfo } from "./hooks/useEditLogs";
import { isEmpty } from "lodash";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(objectSupport);
dayjs.extend(pluralGetSet);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);

dayjs.tz.setDefault("America/New_York");

/** @returns {WebSocket} */
export const wsRefGetter = () => {
  return useSocket.getState(wsEndpoints.online);
};

const App = () => {
  const [boardingVisible, setBoardingVisible] = useState(false);
  const [childProps, setChildProps] = useState([]);
  const [isIdle, setIsIdle] = useState(
    JSON.parse(window.localStorage.getItem("isIdle")) || false
  );
  const { preferences: p } = useSelector((state) => state.preferences);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const currentDevice = useUserAgentNavigatorDevice();

  const [isSessionIdle, setIsSessionIdle] = useState({
    idle: false,
    socket: true,
  });

  const [role, setRole] = useState("");
  // const [dataEntryModalVisibility, setDataModalVisibility] = useState(false);
  const [credentialsResults, setCredentialsResult] = useState();
  const [loading, setLoading] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [restrict, setRestrict] = useState();
  const userSession = useUserSession();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { isAuthenticated, user, signOut, is2FaActive } = useAuth();

  const sessionIdle = useIdleTimer({
    timeout: 55 * 60 * 1000,
    onPresenceChange: (presence) =>
      handleUserSessionIdle(
        presence,
        userConfiguration,
        wsSessions,
        setIsSessionIdle
      ),
    debounce: 1000,
    startManually: true,
  });

  const wsSessions = useGlobalSessionSocket({
    maxRetries: 450,
    sessionTimerFn: sessionIdle || {},
    isSessionIdle,
  });

  const fetchEmailTempForIpAddress = async () => {
    try {
      const res = await filterTables(
        "emailTemplates",
        "templateName",
        "Unauthorized IP Addresses"
      );
      return res?.[0] ?? {};
    } catch (error) {
      console.log(`error fetching email templates`, error);
    }
  };

  useUsersActivity(); //calling this hook to connect with user-activity-ws

  const [askToLogout, setAskToLogout] = useRedux("askToLogout");

  moment.tz.setDefault("America/New_York");
  dayjs.tz.setDefault("America/New_York");

  const checkRestrictionLink =
    "https://leadmanager-express-backend.ue.r.appspot.com/checkRestriction";

  // useEffect(() => {
  //   axios
  //     .get(checkRestrictionLink)
  //     .then((response) => {
  //       console.log({ response });
  //       setIsAuthorized(true);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //       setIsAuthorized(false);
  //       navigate("/notAuthorized");
  //     });
  // }, []);

  //region redux
  useEffect(() => {
    if (!!isAuthenticated) {
      async function fetchConfig() {
        await Promise.all([
          API.get("base64s", "/base64s"),
          API.get("programFields", "/programFields"),
          API.get("serviceDefinitions", "/serviceDefinitions"),
          API.get("preferences", "/preferences"),
          API.get("proposedTypeOfWork", "/proposedTypeOfWork"),
          API.get(
            "proposedConstructionServices",
            "/proposedConstructionServices"
          ),
          // API.get("templates", "/templates"),
          API.get("userGroups", "/userGroups"),
          API.get("categories", "/categories"),
          API.get("teams", "/teams"),
          fetchAllData({
            endpoint: "customerUsers",
            resultPosition: "customerUsers",
            resultId: "identityId ",
            otherStringParams: {
              allUsers: true,
            },
          }),
          API.get("globalNotificationSettings", "/globalNotificationSettings"),
          API.get("sampleObjects", "/sampleObjects"),
        ]).then(
          async ([
            base64sRes,
            programFieldsRes,
            serviceDefinitionsRes,
            preferencesRes,
            proposedTypeOfWorkRes,
            proposedConstructionServicesRes,
            userGroupsRes,
            categoriesRes,
            teamsRes,
            customerUsersRes,
            globalNotificationSettingsRes,
            sampleObjects,
          ]) => {
            dispatch(programFields(programFieldsRes));
            dispatch(base64(base64sRes));
            dispatch(serviceDefinitions(serviceDefinitionsRes));
            dispatch(
              yards(
                programFieldsRes.find(({ fieldName }) => fieldName === "Yards")
                  ?.fieldOptions || []
              )
            );
            dispatch(
              preferences(
                modifyPreferences({
                  userPrefs: preferencesRes,
                  notificationSettings: globalNotificationSettingsRes,
                })
              )
            );
            dispatch(proposedTypeOfWork(proposedTypeOfWorkRes));
            dispatch(
              proposedConstructionServices(proposedConstructionServicesRes)
            );
            dispatch(userGroups(userGroupsRes));
            if (preferencesRes?.preferences.hasOwnProperty("darkMode")) {
              dispatch(handleDarkMode(preferencesRes?.preferences?.darkMode));
            }
            dispatch(updateTopicCategories(categoriesRes));
            dispatch(dispatchTeams(teamsRes));
            dispatch(dispatchCustomerUsers(customerUsersRes));
            dispatch(
              updateNotificationSettings(
                globalNotificationSettingsRes.sort(
                  (a, b) => a.topicId - b.topicId
                )
              )
            );

            const companyLogos = base64sRes?.map(({ base64, fileName }) => ({
              base64,
              fileName,
            }));

            setCredentialsResult((prev) => ({
              ...(prev || {}),
              preferences: preferencesRes,
              companyLogos,
            }));
            dispatch(sampleObjectsAction(sampleObjects));
          }
        );
      }
      fetchConfig();
    }
  }, [isAuthenticated]);

  //region validations
  useEffect(() => {
    if (isAuthenticated === null) {
      setRestrict();
    } else if (isAuthenticated === false) {
      setRestrict(false);
    }

    if (!!isAuthenticated) {
      async function fetchEffectData() {
        let hasUserConfig = false;
        if (!isEmpty(userConfiguration)) {
          //user is already in redux updated from AuthProvider
          hasUserConfig = true;
        }
        const userConfigurationRes = hasUserConfig
          ? userConfiguration
          : await fetchData(apiRoutes.userConfiguration);

        const programFieldsRes = await filterTables(
          "programFields",
          "fieldName",
          "Allowed IP Addresses"
        );

        const fieldOptionsIPs = programFieldsRes?.[0]?.fieldOptions ?? [];

        const ipAddressWhitelist =
          userConfigurationRes?.ipAddressWhitelist?.list;

        const { isSuspended, activeSessions } = userConfigurationRes;
        const sessionId = JSON.parse(window.localStorage.getItem("sessionId"));
        const { logout } =
          activeSessions?.find(({ sessionId: sId }) => sId === sessionId) || {};

        if (!!isSuspended || !!logout) {
          !!isSuspended &&
            message.error(
              "This user has been suspended, please contact your administrator!"
            );
          await signOut();
          return;
        }

        setRestrict();
        const currIp = (await fetchData(apiRoutes.getIp)).split(",")[0];
        // const currIp = "00.00.000.000"; // mock for testing

        const currentWhiteListIpForUser = ipAddressWhitelist?.find(
          ({ ipv4 }) => ipv4 === currIp
        );

        const currentWhiteListIpForProgramFields = fieldOptionsIPs?.find(
          ({ ipv4 }) => ipv4 === currIp
        );

        let geoLocation = "Denied";

        try {
          geoLocation = await getGeoLocation();
        } catch (error) {}

        const sessionInfo = await getSessionInfo({ geoLocation });

        const {
          restrict = false,
          reason = "",
          checkedFrom = "",
        } = returnDetailsForWhiteListIps(
          currentDevice,
          currIp,
          currentWhiteListIpForUser,
          currentWhiteListIpForProgramFields,
          userConfigurationRes.nameOfUser,
          geoLocation
        );

        const correctDeviceName = devices.find(
          ({ deviceLabel }) => deviceLabel === currentDevice
        ).deviceName;

        if (restrict) {
          const logData = {
            category: "Unauthorized Activity Ip",
            actionType: "Ip Address Access",
            recordId: "Ip Address Access",
            recordName: currIp,
            topic: "Device & IP Management",
            label: "",
            previousData: {},
            currentData: {
              devices: {
                deviceName: correctDeviceName,
              },
              reason,
              checkedFrom,
              status: "Access Denied - redirected to /notAuthorized",
            },
            updatedKeys: [],
            updatedAt: Date.now(),
            nameOfUser: userConfigurationRes?.nameOfUser || "",
            cognitoUserId: userConfigurationRes?.cognitoUserId || "",
            sessionInfo: {
              ...sessionInfo,
              ipAddress:
                sessionInfo.ipAddress === "Ip not found!" ||
                !sessionInfo.ipAddress
                  ? currIp
                  : sessionInfo.ipAddress,
            },
          };
          setIsAuthorized(false);
          setRestrict(true);
          navigate("/notAuthorized");

          await sendUnauthorizedIPDeviceNotification(
            logData,
            userConfigurationRes
          );
          const emailTemplateObject = await fetchEmailTempForIpAddress();
          const r = await sendEmailForUnauthorizedIPDevice(
            logData,
            emailTemplateObject
          );
          const proceedLog = {
            ...logData,
            currentData: { ...logData.currentData, emailSent: r || false },
          };
          console.log({ proceedLog });
          await API.post("editLogs", "/editLogs", {
            body: proceedLog,
          }).catch((err) => console.log("Error posting logs", { err }));
          return;
        }
        if (location.pathname === "/notAuthorized") {
          navigate("/dashboard");
        }
        setRestrict(false);

        setIsAuthorized(true);

        if (!hasUserConfig) {
          dispatch(UserConfig(userConfigurationRes));
        }

        sessionIdle.start();

        !sessionId
          ? await userSession.open(userConfigurationRes)
          : await userSession.update({ isActive: true }, userConfigurationRes);

        /**
         * Function that runs when program launches and saves time user logged in (if checking 'remember me' checkbox) in local storage
         * On each login user has it checks if time saved in local storage is older than current time, the program will
         * sign out automatically and redirect to sign in page
         */
        fetchData(apiRoutes.hotCredentials).then((res) => {
          dispatch(hotCredentials(res));
          const auth2 = () => {
            window.google.accounts.id.initialize({
              clientId: res.find(({ credentialId }) => credentialId === "3")
                ?.credentialValue,
              scope: GOOGLE_SCOPES,
            });
          };
          auth2();

          Axios.post("https://oauth2.googleapis.com/token", {
            clientId: res.find(({ credentialId }) => credentialId === "3")
              ?.credentialValue,
            client_secret: res.find(({ credentialId }) => credentialId === "4")
              ?.credentialValue,
            refresh_token: res.find(({ credentialId }) => credentialId === "1")
              ?.credentialValue,
            grant_type: "refresh_token",
          })
            .then(async (firstRes) => {
              dispatch(accessToken(firstRes.data.access_token));

              setCredentialsResult((prev) => ({
                ...prev,
                accessToken: firstRes.data.access_token,
              }));

              !!firstRes?.refresh_token &&
                (await API.put(
                  apiRoutes.hotCredentials,
                  `/${apiRoutes.hotCredentials}/1`,
                  {
                    body: {
                      credentialValue: firstRes.refresh_token,
                    },
                  }
                ).then(() => {
                  res[0].credentialValue = firstRes.refresh_token;
                }));

              setInterval(() => {
                Axios.post("https://oauth2.googleapis.com/token", {
                  clientId: res.find(({ credentialId }) => credentialId === "3")
                    ?.credentialValue,
                  client_secret: res.find(
                    ({ credentialId }) => credentialId === "4"
                  )?.credentialValue,
                  refresh_token: res.find(
                    ({ credentialId }) => credentialId === "1"
                  )?.credentialValue,
                  grant_type: "refresh_token",
                })
                  .then(async (resp) => {
                    dispatch(accessToken(resp.data.access_token));

                    setCredentialsResult((prev) => ({
                      ...prev,
                      accessToken: resp.data.access_token,
                    }));

                    !!firstRes?.refresh_token &&
                      (await API.put(
                        apiRoutes.hotCredentials,
                        `/${apiRoutes.hotCredentials}/1`,
                        {
                          body: {
                            credentialValue: firstRes.refresh_token,
                          },
                        }
                      ).then(() => {
                        res[0].credentialValue = firstRes.refresh_token;
                      }));
                  })
                  .catch((e) => console.error(e));
              }, 3500000);
            })
            .catch((e) => console.error(e));
        });
      }
      fetchEffectData();
    }
  }, [isAuthenticated, currentDevice]);

  useEffect(() => {
    setChildProps({
      isAuthenticated,
      authenticatedUser: user,
      accessToken: credentialsResults?.accessToken,
      companyLogos: credentialsResults?.companyLogos,
      department: role,
    });
  }, [credentialsResults, isAuthenticated, user, role]);

  useEffect(() => {
    function handleSessions(msg) {
      handleSessionMessage(
        msg,
        userConfiguration,
        wsSessions,
        userSession,
        setAskToLogout,
        navigate,
        dispatch
      );
    }
    if (!!isAuthenticated && !!p?.preferences && !!userConfiguration) {
      //open onboarding if user hasn't seen it
      setBoardingVisible(!userConfiguration?.boardingConfigs);

      try {
        wsSessions.addEventListener("message", handleSessions);
        wsSessions.send(
          JSON.stringify({ request: "get-session-notifications", body: {} })
        );
      } catch (err) {
        console.error("Error: Session notification closed ", { err });
      }
    }
    return () => {
      wsSessions.removeEventListener("message", handleSessions);
    };
  }, [
    wsSessions,
    JSON.stringify(p?.preferences?.notifications),
    isAuthenticated,
    userConfiguration,
  ]);

  const handleOnIdle = (event) => {
    if (process.env.NODE_ENV === "production") {
      isAuthenticated && setIsIdle(true);
      start();
      // if (isIdle && isAuthenticated) {
      //   window.localStorage.setItem("loginAttempts", 0);
      //   Auth.signOut().then((e) => {
      //     setIsIdle(false);
      //     navigate("/login");
      //   });
      // }
    }
  };

  useEffect(() => {
    window.localStorage.setItem("isIdle", isIdle);
  }, [isIdle]);

  // const { start } = useIdleTimer({
  //   timeout: userConfiguration?.idleTimeOut,
  //   onIdle: handleOnIdle,
  //   debounce: 500,
  // });

  const loadingIndicator = useMemo(() => {
    if (
      (isAuthenticated === undefined || isAuthenticated === null) &&
      childProps === undefined
    ) {
      return true;
    }
    if (restrict === undefined) {
      return true;
    }
    return false;
  }, [isAuthenticated, childProps, restrict, user?.signInUserSession]);

  return (
    <LoadableComp className="fullWidth" loading={loadingIndicator}>
      <IdleComponent
        {...{
          isIdle,
          setIsIdle,
          credentialsResults,
          userConfiguration,
        }}
      />

      <Suspense
        fallback={<LoadableComp loading={true} className="fullWidth" />}
      >
        <div
          style={{
            overflow: "auto",
            display: isIdle ? "none" : "block",
            height: "100%",
            // background: "#F4F5F6",
            // backgroundColor: "#F4F5F6",
          }}
        >
          <Routes>
            {isAuthorized ? (
              <>
                <Route
                  exact
                  path="/two-factor-authentication"
                  element={
                    <TwoFactorAuthenticatorModal
                      childProps={childProps}
                      open={!is2FaActive}
                    />
                  }
                />
                <Route
                  exact
                  path="/orders"
                  element={<Orders childProps={childProps} />}
                />
                <Route exact path="/ordersReport" element={<OrdersReport />} />
                <Route
                  exact
                  path="/pdf"
                  element={<Pdf childProps={childProps} />}
                />
                <Route
                  exact
                  path="/estimationDashboard"
                  element={
                    <EstimationDashboard
                      childProps={childProps}
                      estimations={
                        credentialsResults && credentialsResults.allEstmations
                      }
                    />
                  }
                />
                <Route
                  exact
                  path="/proposalBuilder"
                  element={<WaitingRoomProposal childProps={childProps} />}
                />{" "}
                <Route
                  exact
                  path="/new-proposal-builder"
                  element={<NewProposalBuilder childProps={childProps} />}
                />
                {/* <Route exact path="/new-proposal-builder">
                  <NewProposalBuilder childProps={childProps} />
                </Route> */}
                {/* <Route
                  exact
                  path="/projectCost"
                  element={<Payroll childProps={childProps} />}
                /> */}
                <Route
                  exact
                  path="/permitDrawingsDashboard"
                  element={<PermitDrawings />}
                />
                <Route
                  exact
                  path="/documentationDashboard"
                  element={<DocumentationDashboard />}
                />
                <Route
                  exact
                  path="/fleets/live"
                  element={<FleetsLiveView childProps={childProps} />}
                />
                <Route
                  exact
                  path="/projectCost/live"
                  element={<PayrollLive childProps={childProps} />}
                />
                {location.pathname.toLowerCase().includes("settings") ? (
                  !!p &&
                  !!user && (
                    <Route
                      path="/*"
                      element={<SettingsPage childProps={childProps} />}
                    />
                  )
                ) : (
                  <Route
                    path="/*"
                    element={<RouterConfig childProps={childProps} />}
                  />
                )}
              </>
            ) : (
              <Route exact path="/notAuthorized" element={<NotAuthorized />} />
            )}
          </Routes>
        </div>
      </Suspense>
      {boardingVisible && (
        <OnBoarding
          {...{ visible: boardingVisible, setVisible: setBoardingVisible }}
        />
      )}
      {askToLogout && (
        <AskToLogoutTimer
          {...{
            askToLogout,
            setAskToLogout,
          }}
        />
      )}
    </LoadableComp>
  );
};
export default App;
