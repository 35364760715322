import {
  BoldIcon,
  ClearIcon,
  FillBucketIcon,
  FontSizeIcon,
  ItalicIcon,
  ListIcon,
  TextColorIcon,
  TextFormatIcon,
  UnderlineIcon,
} from "../assets";
import {
  fontSizeOptions,
  listOptions,
  textFormatOptions,
} from "./toolBoxConstants";

const hasList = ["elevationLabel", "itemLabel", "addonLabel"];

const configToolBoxOptions = [
  { title: "Bold", icon: <BoldIcon />, styleKey: "bold" },
  { title: "Italic", icon: <ItalicIcon />, styleKey: "italic" },
  { title: "Underline", icon: <UnderlineIcon />, styleKey: "underline" },
  {
    title: "Font Size",
    icon: <FontSizeIcon />,
    style: { color: "black" },

    dropdown: {
      items: fontSizeOptions,
      styleKey: "font-size",
    },
  },
  {
    title: "Text Format",
    icon: <TextFormatIcon />,
    style: { color: "black" },

    dropdown: {
      items: textFormatOptions,
      styleKey: "text-format",
    },
  },
  {
    title: "List Type",
    icon: <ListIcon />,

    style: { color: "black" },

    dropdown: {
      items: listOptions,
      styleKey: "listType",
    },
    condition: (category) => hasList.includes(category),
  },
  {
    title: "Text Color",
    icon: <TextColorIcon />,
    styleKey: "color",
    type: "colorPicker",
  },
  {
    title: "Background Color",
    styleKey: "background-color",
    icon: <FillBucketIcon />,
    type: "colorPicker",
  },
  { title: "Clear", icon: <ClearIcon />, type: "clear" },
];

export default configToolBoxOptions;
