import { Input } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as DescIcon } from "./descriptionIcon.svg";
import { RedWarningModal } from "../../../../../../../commonComponents";
import { InfoText } from "../../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";

function AccessDescription({
  selectedRoute,
  onDescriptionChange,
  changeDescription,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [visible, setVisible] = useState(false);
  const [desc, setDesc] = useState(selectedRoute.description);

  const title = "Route Description";

  return (
    <>
      <div
        style={{
          fontWeight: 400,
          fontSize: 14,
          color:
            desc || changeDescription
              ? "#1264A3"
              : isDarkMode
              ? "#f9f9f9"
              : "#231F20",
          cursor: !changeDescription && !desc ? "default" : "pointer",
        }}
        onClick={() => {
          if (!changeDescription && !desc) {
            return;
          } else {
            setVisible(true);
          }
        }}
      >
        {changeDescription ? "Add Description" : "View Description"}
      </div>
      {visible && (
        <RedWarningModal
          visible={true}
          confirmModal={true}
          TitleIcon={DescIcon}
          titleText={changeDescription ? "Edit " + title : "" + title}
          onCancel={() => {
            setVisible(false);
          }}
          footer={changeDescription ? undefined : null}
          setVisible={setVisible}
          footerProps={{
            onConfirm: () => {
              setVisible(false);
              onDescriptionChange(selectedRoute, desc);
            },
          }}
        >
          <InfoText
            {...{
              text: (
                <span>
                  Write a description for <b>{selectedRoute.title}</b>
                </span>
              ),
              style: { marginBottom: 10 },
            }}
          />
          <Input.TextArea
            {...{
              value: desc,
              onChange: (e) => {
                setDesc(e.target.value);
              },
              autoSize: { minRows: 4, maxRows: 20 },
              //   disabled: !changeDescription,
              style: { marginBottom: !changeDescription ? "20px" : 0 },
            }}
          />
        </RedWarningModal>
      )}
    </>
  );
}

export default AccessDescription;
