import { useEffect, useState, useRef, useMemo } from "react";
import { Alert, Form, Tour, message } from "antd";
import {
  BorderedTextCard,
  // InputComponent,
  NormalSizedModal,
} from "../../../SidebarPages/Fleet/components";
import "./PermitDrawingModal.scss";
import {
  body,
  dataGridColumns,
  fieldsJSON,
  // footerButtons,
  gridFilters,
  gridSidebar,
  logsColumnDefs,
} from "./permitDrawingModalData";
import {
  clearFilter,
  compareIncluding,
  // getRandomColor,
  onFilter,
} from "../../../SidebarPages/utils";
import {
  apiRoutes,
  fetchData,
  validateForm,
} from "../../../SidebarPages/Fleet/utils";
import _, { isEmpty } from "lodash";
import {
  ArchEngPopoverContent,
  FormGrid,
  RenderDynamicComponents,
} from "../Components";
import { AvatarImages, MondayButton } from "../../../commonComponents";
import { API } from "aws-amplify";
import {
  // lazyFetch,
  // putApiMethod,
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
  updateTeamsConfiguration,
} from "../../../../utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import {
//   ArrowDownOutlined,
//   CheckOutlined,
//   DownOutlined,
// } from "@ant-design/icons";
// import { ReactComponent as CloseIcon } from "../../../SidebarPages/DynamicView/src/close.svg";
import { ReactComponent as Tick } from "../../../pages/Settings/settingsComponents/Roles/src/Tick.svg";
import { ReactComponent as WarningIcon } from "../../../../icons/warningTriangle.svg";
import WarningModal from "../../../commonComponents/WarningModal/WarningModal";
import { sortBy } from "lodash";
// import { hasValuesChanged } from "../../../../utils/hasValuesChanged";
import { TickIcon } from "../../../pages/Settings/settingsComponents/Roles/src";
// import { requestAllPages } from "../../../../utils/paginationFetchData";
// import { DownCaretWhite, PlusIcon } from "../../../../assets";
import { useCreateDriveFolders } from "../../../../hooks/useCreateDriveFolders";
import { XIcon } from "../../../SidebarPages/Communication/assets";
// import { CollapseArrow, CollapseIcon } from "../../../../icons";
// import { VideoTutorialIcon } from "../../components/ModalVideoTutorials/assets/svg";
import CustomModalHeader, {
  PlayVideoTutorial,
  tourTitle,
} from "../../../commonComponents/CustomModalHeader/CustomModalHeader";
import DynamicTeamModal from "../DynamicTeamModal/DynamicTeamModal";
import { dayjsNY } from "../../../DateComponents/contants/DayjsNY";
// import { fetchAllData } from "../../../SidebarPages/Fleet/utils/fetchAllData";
import { permitDrawingWS } from "../../../../sockets";
import { AddServicePopover } from "./components/AddServicePopover";
import { getSelectedBaseRecords } from "src/utils/searchFetchForInput";
import { SmallLoadableComp } from "src/components/Sidebars/components";
// import { driveApi } from "src/integrations/DriveRequest";
import { useEditLogs } from "../../../../hooks";
import NewNonUserModal from "../../../pages/Settings/settingsComponents/Engineers And Architects/Modal/NewNonUserModal";
import { fetchAllData } from "../../../SidebarPages/Fleet/utils/fetchAllData";

const PermitDrawingModal = ({
  visible,
  setVisible,
  requestId,
  // requestObject,
  permitDrawing = {},
  refreshTable = () => {},
  isNextStep,
  afterSaveRequest = async () => {},
}) => {
  const {
    permitId,
    projectId,
    drawingType,
    assignedTo,
    sow,
    initialDrawingId,
    drawingAssignedTo,
    services: proppedServices = [],
  } = permitDrawing;

  const [permitDrawings, setPermitDrawings] = useState([]);
  const [permitDrawingsLogs, setPermitDrawingsLogs] = useState([]);
  const [statusLabels, setStatusLabels] = useState([]);
  const [projects, setProjects] = useState([]);
  const [services, setServices] = useState([]);
  const [projectExecutives, setProjectExecutives] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});
  const [engineers, setEngineers] = useState([]);
  const [selectedEngineer, setSelectedEngineer] = useState();
  const [selectedDrawingType, setSelectedDrawingType] = useState();
  const [selectedServices, setSelectedServices] = useState([]);
  const [existingDrawings, setExistingDrawings] = useState([]);
  const [engineerPopoverVisible, setEngineerPopoverVisible] = useState(false);
  const [projectPopoverVisible, setProjectPopoverVisible] = useState(false);
  const [ProjectPopoverContent, setProjectPopoverContent] = useState();
  const [allDrawingsGridApi, setAllDrawingsGridApi] = useState();
  const [initialDrawingsGridApi, setInitialDrawingsGridApi] = useState();
  const [saving, setSaving] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [permitDrawingTeam, setPermitDrawingTeam] = useState([]);
  const [formChanged, setFormChanged] = useState(false);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [selectedTeam, setSelectedTeam] = useState([]);
  const navigate = useNavigate();
  const [projectManagers, setProjectManagers] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [currentPageKey, setCurrentPageKey] = useState();
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [nonUserModal, setNonUserModal] = useState(false);

  const [selectedAltAddresses, setSelectedAltAddresses] = useState([]);

  const filteredServices = useRef([]);

  const driveFunctions = useCreateDriveFolders("Permit Drawings");
  const { saveAddedLogs } = useEditLogs();

  const [
    { programFields },
    { accessToken },
    { userConfiguration },
    { isDarkMode },
  ] = useSelector((state) => [
    state.programFields,
    state.accessToken,
    state.userConfig,
    state.darkMode,
  ]);

  const [tourOpen, setTourOpen] = useState(false);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);
  const videoTutorialLink = programFields
    ?.filter((item) => item.fieldName === "Portal Video Tutorials")[0]
    ?.fieldOptions.find((item) => item.categoryName === "Project Management")
    ?.subCategories[0].videos.find(
      (item) => item.videoName === "New Permit Drawing"
    )?.videoLink;

  async function fetchTeams() {
    await API.get("teams", "/teams").then((r) => setPermitDrawingTeam(r));
  }

  useEffect(() => {
    fetchTeams();
    API.get("engineers", "/engineers").then((res) =>
      setEngineers(sortBy(res, "nameOfUser"))
    );
  }, []);

  // const engineers = sortBy(
  //   (userConfiguration?.allUsers?.Items || []).filter(({ groupName }) =>
  //     compareIncluding(groupName, "engineer")
  //   ),
  //   "nameOfUser"
  // );

  const { projectId: selectedProjectId, accountName: permitCompany } =
    selectedProject || {};

  const selectedRows = () => initialDrawingsGridApi?.getSelectedRows();

  const originalDrawing = !!permitId
    ? permitDrawings?.find(({ permitId: arrPermitId }) =>
        compareIncluding(arrPermitId, permitId)
      )
    : {};

  const selectedProjectDrawings = useMemo(() => {
    if (!permitDrawings?.length) return [];
    return (
      permitDrawings?.filter(({ projectId: drawingProjectId }) =>
        compareIncluding(drawingProjectId, selectedProject?.projectId)
      ) || []
    );
  }, [permitDrawings, JSON.stringify(selectedProject)]);

  const initialDrawingsGridData = useMemo(() => {
    return selectedProjectDrawings?.filter(
      (el) =>
        selectedServices?.includes(el?.sow) &&
        (el?.drawingType !== "Review" || el?.drawingType !== "Supersede")
    );
  }, [selectedServices, selectedProjectDrawings]);

  const bodyObj = ({
    drawingType,
    initialDrawingId,
    drawingAssignedTo,
    sow,
    jobSiteAddress,
    projectManager,
    assignedTo,
    projectExecutive,
  }) => {
    const engineerUserLink = userConfiguration?.allUsers?.Items?.filter(
      (user) => user?.cognitoUserId === selectedEngineer?.userLink
    )?.map(({ nameOfUser, identityId, cognitoUserId }) => ({
      nameOfUser,
      identityId,
      cognitoUserId,
    }));
    const formatPM = projectManagers.map((manager) => ({
      nameOfUser: manager.label,
      identityId: manager.value,
      cognitoUserId: manager.cognitoUserId,
    }));
    const otherTeamUsers = [...(formatPM || []), ...engineerUserLink];

    return body({
      drawingType,
      projectManager,
      assignedTo,
      projectExecutive,
      form,
      initialDrawingId,
      drawingAssignedTo,
      projectId: projectId || selectedProjectId,
      sow,
      jobSiteAddress,
      permitCompany,
      requestId,
      teams: updateTeamsConfiguration(
        userConfiguration,
        selectedTeam,
        otherTeamUsers,
        true
      ),
    });
  };

  const filterGrid = ({ gridApi, column, current, type }) =>
    !!gridApi && onFilter({ gridApi, column, current, type });

  const pExecutivesNames = projectExecutives?.map(({ firstName, lastName }) =>
    [firstName, lastName].join(" ")
  );

  const engineersNames = engineers?.map(({ nameOfUser }) => nameOfUser);

  const onEngineerPopoverRejection = () => {
    form.resetFields(["assignedTo", "permitEngineerFilter"]);
    setSelectedEngineer("");
    setEngineerPopoverVisible("");
    clearFilter({ gridApi: allDrawingsGridApi, column: "assignedTo" });
  };

  const onSelect = (_, data) => {
    setSelectedTeam((prev) => [
      ...(prev || []),
      {
        value: data.value,
        members: data?.members,
        teamId: data?.teamId,
      },
    ]);
  };

  const openPermitDrawingTeamModal = () => {
    setOpen(true);
  };

  const onDeselect = (val, data) => {
    const teamName = data?.value || val;
    setSelectedTeam((prev) =>
      prev
        ?.filter((el) => el.value !== teamName)
        ?.map((el) => ({
          ...el,
          teamId: el.teamId?.filter((id) => id !== data?.teamId?.[0]) || [],
        }))
    );
    if (form.getFieldValue("permitTeam").length < 1) {
      setSelectedTeam([]);
    }
  };

  const ClearOptions = () => {
    setOpen(false);
  };

  const populatedFields = fieldsJSON({
    setSelectedProject,
    setSelectedDrawingType,
    setSelectedEngineer,
    filterGrid,
    onSelect,
    setSelectedServices,
    setSelectedTeam,
    projectId,
    permitId,
    onDeselect,
    selectedDrawingType,
    disabled: projectPopoverVisible || !selectedDrawingType,
    allDrawingsGridApi,
    initialDrawingsGridApi,
    form,
    services,
    serviceOptions,
    projects,
    pExecutivesNames,
    setProjectManagers,
    isDarkMode,
    projectManagersOptions:
      programFields
        ?.find((prog) => prog.fieldName === "Project Managers")
        ?.fieldOptions?.map((manager) => ({
          label: manager.nameOfUser,
          value: manager?.identityId,
          ...manager,
        })) || [],
    engineersOptions: engineers.map(
      ({ userLink, nameOfUser, engineerId = "" }) => ({
        label: nameOfUser,
        value: nameOfUser,
        userLink,
        nameOfUser,
        engineerId,
      })
    ),
    permitDrawingTeam: permitDrawingTeam?.map((team) => ({
      label: team.teamName,
      value: team.teamName,
      teamId: [team.teamId],
      members: team.members?.map(
        ({ identityId, nameOfUser, cognitoUserId = "" }) => ({
          identityId,
          nameOfUser,
          cognitoUserId,
        })
      ),
    })),
    allTeams: permitDrawingTeam,
    projectPopoverVisible: visible && projectPopoverVisible,
    ProjectPopoverContent,
    engineerPopoverVisible: visible && engineerPopoverVisible,
    EngineerPopoverContent: (
      <ArchEngPopoverContent
        {...{
          onPopoverRejection: onEngineerPopoverRejection,
          assignedPerson: selectedEngineer,
          setVisible: setEngineerPopoverVisible,
          existingJobsites: existingDrawings,
          taskType: "permit drawing",
        }}
      />
    ),
    projectNames: projects?.map?.(({ projectName }) => projectName),
    setProjects,
    loadingProjects,
    setLoadingProjects,
    setCurrentPageKey,
    currentPageKey,
    userConfiguration,
    setNonUserModal,
    selectedProject,
    setSelectedAltAddresses,
    selectedAltAddresses,
  });

  const resetForm = () => {
    form.resetFields(
      !!projectId
        ? populatedFields
            ?.flatMap(({ fields }) =>
              fields?.map(({ formItemName }) => formItemName)
            )
            ?.filter(
              (formItem) =>
                !["jobSiteAddress", "projExecutive"].includes(formItem)
            )
        : undefined
    );
    setSelectedServices([]);
    setSelectedProject({});
    setSelectedEngineer("");
    setSelectedDrawingType("");
    setEngineerPopoverVisible(false);
    !!permitId && refreshTable(originalDrawing);
    driveFunctions?.reset();
  };

  const savePermitDrawing = async (redirect) => {
    showLoadingMsg();
    setSaving(true);
    if (!permitId) {
      await Promise.all(
        selectedServices?.map(async (service) => {
          const {
            initialDrawingId: elInitialDrawingId,
            permitId: elPermitId,
            jobSiteAddress,
            projectManager,
            assignedTo,
            projectExecutive,
            drawingType: elDrawingType,
          } = initialDrawingsGridData?.find(
            ({ sow: arrSow }) => arrSow === service
          ) || {};

          const googleDriveFolderIds =
            selectedProject?.googleDriveFolderIds?.[
              compareIncluding(selectedDrawingType, "Initial Drawing")
                ? "initialDrawings"
                : compareIncluding(selectedDrawingType, "Amendment Drawing")
                ? "amendmentDrawings"
                : compareIncluding(selectedDrawingType, "Review")
                ? "reviewDrawings"
                : compareIncluding(selectedDrawingType, "Supersede")
                ? "supersedeDrawings"
                : "initialDrawings"
            ];

          let allFolders = null;
          try {
            const { folders } = await driveFunctions.create({
              parentFolderName: `${service} ${dayjsNY().format(
                "DD/MM/YYYY HH:mm:ss"
              )}`,
              parentId: !!googleDriveFolderIds
                ? googleDriveFolderIds
                : selectedProject?.googleDriveFolderIds?.["initialDrawings"],
            });
            allFolders = folders;
          } catch (error) {
            console.log(error);
            allFolders = null;
          }
          const populatedBody =
            selectedDrawingType === "Amendment Drawing" ||
            selectedDrawingType === "Supersede" ||
            selectedDrawingType === "Review"
              ? {
                  ...bodyObj({
                    initialDrawingId:
                      selectedDrawingType === "Amendment Drawing" &&
                      compareIncluding(elDrawingType, "amendment drawing")
                        ? elInitialDrawingId
                        : elPermitId,
                    drawingAssignedTo: elPermitId || "",
                    sow: service,
                    jobSiteAddress:
                      jobSiteAddress || selectedProject?.projectName,
                    projectManager:
                      projectManagers || selectedProject?.projectManager,
                    assignedTo: selectedEngineer?.label || assignedTo,
                    projectExecutive,
                    drawingType: selectedDrawingType,
                  }),
                  // googleDriveFolderIds: googleDriveFolderIds?.[service],
                  googleDriveFolderIds: {
                    ...(allFolders || {}),
                  },
                  googleDriveUploads: permitDrawing?.requestFiles || [],
                  alternativeAddresses: selectedAltAddresses || [],
                }
              : {
                  ...bodyObj({
                    initialDrawingId: "",
                    drawingAssignedTo: "",
                    sow: service,
                    jobSiteAddress: selectedProject?.projectName,
                    projectManager:
                      projectManagers || selectedProject?.projectManager,
                    assignedTo: selectedEngineer?.label,
                    projectExecutive: selectedProject?.projectExecutive,
                    drawingType: selectedDrawingType,
                  }),
                  // googleDriveFolderIds: googleDriveFolderIds?.[service],
                  googleDriveFolderIds: {
                    ...(allFolders || {}),
                  },
                  googleDriveUploads: permitDrawing?.requestFiles || [],
                  alternativeAddresses: selectedAltAddresses || [],
                };
          await API.post(
            apiRoutes.permitDrawings,
            `/${apiRoutes.permitDrawings}`,
            {
              body: populatedBody,
            }
          )
            .then(async (r) => {
              saveAddedLogs({
                recordId: r?.permitId,
                recordName: r?.sow,
                category: "Permit Drawings",
                topic: r?.jobSiteAddress,
              });

              setTimeout(() => refreshTable([r]), 0);
              if (!!requestId) {
                await afterSaveRequest({
                  newRecordId: r?.permitId,
                  // requestId,
                  path: "permitDrawings",
                  // cognitoUserId: userConfiguration?.cognitoUserId,
                  moveFilesParams: {
                    filesToMove: permitDrawing?.requestFiles || [],
                    newParentId: allFolders?.permitDrawings,
                    accessToken,
                  },
                  recordName: requestObject?.recordName,
                });

                //This is done by the above function
                // requestFormWs.send(
                //   JSON.stringify({
                //     request: "request-response",
                //     body: { requestId, responseFrom: "approved" },
                //   })
                // );
                // await moveRequestFiles({
                //   requestId,
                // });
              }
              resetForm();
              permitDrawingWS.send(
                JSON.stringify({
                  request: "permitDrawingEdited",
                  nodeEnv: process.env.NODE_ENV,
                  body: { ...r },
                })
              );

              !!!isNextStep &&
                redirect &&
                navigate(`/permitdrawings/${r?.permitId}`);
            })
            .catch((e) => {
              console.error(e);
              showErrorMsg();
            });
        })
      );
    } else {
      const putBody = bodyObj({
        sow,
        initialDrawingId,
        drawingAssignedTo,
      });

      await API.put(
        apiRoutes.permitDrawings,
        `/${apiRoutes.permitDrawings}/${permitId}`,
        {
          body: putBody,
        }
      )
        .then(() => {
          refreshTable({ ...originalDrawing, ...putBody });
        })
        .catch((e) => console.error(e));
    }

    message.destroy("projectDriveFolders");
    message.destroy("windowMessages");
    showSuccessMsg();
    setSaving(false);
  };

  const onSave = () => {
    setVisible(false);
    savePermitDrawing();
  };

  const onCancel = () => {
    resetForm();
    setFormChanged(false);
    setVisible(false);
    setCancelModalVisible(false);
  };

  const onCancelModal = () => {
    setVisible(false);
    setCancelModalVisible(false);
  };

  const onEnterPress = (event) => {
    if (event.charCode === 13) {
      onCancel();
    }
  };

  const onGridReady = ({ params, setApi }) => {
    setApi(params.api);
    params.api.sizeColumnsToFit();
    onRowDataChanged({ gridApi: params.api, rowData: initialDrawingsGridData });
  };

  const onRowDataChanged = ({ gridApi, rowData = [] }) => {
    if (!!gridApi) {
      const recordsToBeSelected = rowData?.reduce(
        (acc, { sow }) =>
          acc?.map(({ sow: accSow }) => accSow)?.includes(sow)
            ? acc
            : [
                ...acc,
                _.maxBy(
                  rowData?.filter(
                    ({ sow: filteredSow }) => filteredSow === sow
                  ),
                  "createdAt"
                ),
              ],
        []
      );
      gridApi?.forEachNode(
        (node) =>
          !!recordsToBeSelected
            ?.map(({ permitId }) => permitId)
            .includes(node.data.permitId) && node.setSelected(true)
      );
    }
  };

  const populatedColDefs = () => dataGridColumns({ accessToken });

  const populatedLogsColDefs = logsColumnDefs(statusLabels);

  const populatedFieldsObj = populatedFields?.reduce(
    (acc, { category, fields }) => ({ ...acc, [category]: fields }),
    {}
  );

  const populatedGridFilters = gridFilters({
    projectExecutives: pExecutivesNames,
    engineers: engineersNames,
    sow: _.uniqWith(
      permitDrawings?.map(({ sow }) => sow),
      _.isEqual
    ),
  });

  const onRowSelected = (row) => {
    if (!!row.node.isSelected()) {
      initialDrawingsGridApi.forEachNode(
        (node) =>
          compareIncluding(node?.data?.sow, row?.node?.data?.sow) &&
          node?.id !== row?.node?.id &&
          node.isSelected() &&
          node.setSelected(false)
      );
    }
  };

  const validatePermitDrawingForm = (onSuccessFunc, redirect = true) => {
    const allDrawingsAssigned = selectedServices?.every((el) =>
      selectedRows()
        ?.map(({ sow: arrSow }) => arrSow)
        .includes(el)
    );

    console.log({
      allDrawingsAssigned,
      selectedServices,
      selectedRows: selectedRows()?.map(({ sow: arrSow }) => arrSow),
    });

    const validate = () =>
      validateForm(form, () => {
        onSuccessFunc(redirect);
      });

    if (
      !compareIncluding(selectedDrawingType, "initial drawing") &&
      !permitId
    ) {
      if (!!allDrawingsAssigned && !!selectedServices.length) {
        validate();
      } else {
        message.warning("Please assign all drawings!");
      }
    } else if (!!selectedServices?.length) {
      validate();
    } else if (!!permitId) {
      validate();
    } else {
      !selectedServices?.length &&
        message.warning("Please select the services!");
    }
  };

  const isWorkingOnOtherDrawings = ({
    key,
    person,
    setPopoverVisible,
    setExistingDrawings,
  }) => {
    if (!!person) {
      const existingDrawings = permitDrawings?.filter(
        ({ [key]: objectKey, permitStatus }) =>
          compareIncluding(objectKey, person) &&
          !compareIncluding(permitStatus, "completed") &&
          !compareIncluding(permitStatus, "approved")
      );
      if (!!existingDrawings?.length && person !== originalDrawing[key]) {
        setExistingDrawings(existingDrawings);
        setPopoverVisible(true);
      } else setPopoverVisible(false);
    }
  };

  useEffect(() => {
    if (!!projectId && !!projects?.length) {
      form.setFieldsValue(permitDrawing);
      setSelectedProject(
        projects?.find(
          ({ projectId: arrProjectId }) => arrProjectId === projectId
        )
      );
    }

    if (!!permitId) {
      fetchData(apiRoutes.permitDrawingsLogs).then((r) =>
        setPermitDrawingsLogs(
          r?.filter(({ permitId: arrPermitId }) => arrPermitId === permitId)
        )
      );
      setSelectedEngineer({ nameOfUser: assignedTo });
      setSelectedDrawingType(drawingType || "");
    }

    if (!!proppedServices.length) {
      form.setFieldsValue(
        proppedServices.reduce((acc, curr) => ({ ...acc, [curr]: true }), {})
      );
      !requestId && setSelectedServices(proppedServices);
    }

    if (!!requestId) {
      setSelectedDrawingType(permitDrawing?.drawingType || "");
    }
  }, [JSON.stringify(permitDrawing), projects]);

  useEffect(() => {
    if (!_.isEmpty(selectedProject) && !!selectedDrawingType) {
      const projectServices =
        _.uniq(
          Object.values(selectedProject?.services)
            ?.flat(1)
            ?.filter(({ isScope }) => !isScope)
            ?.map(({ label }) => label)
        ) || [];

      const amendmentEligibleServices = _.uniq(
        selectedProjectDrawings?.map(({ sow }) => sow)
      );
      form.setFieldsValue({
        projExecutive: selectedProject?.projectExecutive,
        projectManager:
          selectedProject?.projectManager?.map((manager) =>
            typeof manager === "string"
              ? manager
              : {
                  // ...manager,
                  label: manager.nameOfUser,
                  value: manager.cognitoUserId,
                  cognitoUserId: manager?.cognitoUserId || "",
                }
          ) || [],
      });
      setProjectManagers(
        selectedProject?.projectManager?.map((manager) =>
          typeof manager === "string"
            ? manager
            : {
                label: manager?.nameOfUser,
                value: manager?.identityId,
                cognitoUserId: manager?.cognitoUserId || "",
              }
        ) || []
      );

      form.resetFields(services);
      setServices(
        compareIncluding(selectedDrawingType, "Amendment Drawing")
          ? amendmentEligibleServices
          : projectServices
      );
      setSelectedServices([]);
      setProjectPopoverContent(
        compareIncluding(selectedDrawingType, "Amendment Drawing")
          ? "This jobsite does not have any existing drawings"
          : "This jobsite does not have any services"
      );
      setProjectPopoverVisible(
        compareIncluding(selectedDrawingType, "Amendment Drawing")
          ? !amendmentEligibleServices.length
          : !projectServices.length
      );
      API.get(
        apiRoutes.serviceDefinitions,
        `/${apiRoutes.serviceDefinitions}`
      ).then((res) => {
        const allServiceNames = res.flatMap(({ serviceName }) => ({
          serviceName,
        }));
        if (compareIncluding(selectedDrawingType, "Amendment Drawing")) {
          const resultServices = allServiceNames.flatMap(({ serviceName }) =>
            amendmentEligibleServices.includes(serviceName) ? [] : serviceName
          );
          setServiceOptions(resultServices);
          !!requestId &&
            setSelectedServices(
              proppedServices?.filter((el) => !resultServices.includes(el)) ||
                []
            );

          filteredServices.current = resultServices;
        } else {
          const resultServices = allServiceNames.flatMap(({ serviceName }) =>
            projectServices.includes(serviceName) ? [] : serviceName
          );
          setServiceOptions(resultServices);
          !!requestId &&
            setSelectedServices(
              proppedServices?.filter((el) => !resultServices.includes(el)) ||
                []
            );

          filteredServices.current = resultServices;
        }
      });
    }
  }, [JSON.stringify(selectedProject), selectedDrawingType]);

  useEffect(() => {
    isWorkingOnOtherDrawings({
      key: "assignedTo",
      person: selectedEngineer,
      setPopoverVisible: setEngineerPopoverVisible,
      setExistingDrawings,
    });
  }, [selectedEngineer, permitDrawings.length]);

  useEffect(() => {
    Promise.all([
      fetchData(apiRoutes.projectExecutives),
      fetchAllData("permitDrawings", "permitDrawings", "permitId"),
      // fetchAllData("projects", "projects", "projectId"),
      getSelectedBaseRecords({
        idKey: "projectId",
        table: "projects",
        setRecords: setProjects,
        userConfiguration,
        setCurrentPageKey,
      }),
      // lazyFetch({
      //   tableName: apiRoutes.serviceDefinitions,
      //   listOfKeys: ["serviceName"],
      // }),
    ])
      .then(([executives, drawings, _, serviceDefinitions]) => {
        const pd = Array.isArray(drawings)
          ? drawings
          : drawings?.["permitDrawings"] || [];
        setProjectExecutives(executives);
        setPermitDrawings(pd);
        // setProjects(projects);
        // setServiceOptions(
        //   serviceDefinitions.map(({ serviceName }) => serviceName)
        // );
      })
      .catch((error) => console.log({ error }));
  }, []);

  useEffect(() => {
    setStatusLabels(
      programFields
        ?.find(({ fieldName }) =>
          compareIncluding(fieldName, "Permit Logs Status")
        )
        ?.fieldOptions?.map(({ statusName, statusColor }) => ({
          status: statusName,
          color: statusColor,
        }))
    );
  }, [programFields]);

  useEffect(() => {
    setInitialValues(form.getFieldsValue());
  }, []);

  const steps =
    programFields
      ?.find(({ fieldName }) => fieldName === "Tutorials Steps")
      ?.fieldOptions?.find(
        ({ categoryName }) => categoryName === "permitDrawingModal"
      )?.steps || [];

  function stepsMapHelper(title) {
    const stepsMap = {
      "Drawing Type": document.querySelector(".permitDrawingRecordType"),
      "Required Fields": document.querySelector(".coreInfoContainer"),
      "Jobsite Address": document.querySelector(".jobsiteAddress"),
      "Engineer Assigned To": document.querySelector(".engAssignedTo"),
      "Filter Existing Drawings": document.querySelector(".filtersCard"),
      "Clear Filters": document.querySelector(".clearFiltersBtn"),
      "Save Drawing": document.querySelector(".finishBtn"),
    };
    return stepsMap[title] || null;
  }

  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        title: tourTitle(step?.title, () => {
          setShowVideoTutorial(true);
          setTourOpen(false);
        }),
        description: step?.description,
        target: () => stepsMapHelper(step?.title),
        className: `custom-tour-light`,
      };
    });
    return newSteps;
  }
  const tourSteps = mapRefs(steps);

  const serviceSelectWarningMessage = useMemo(() => {
    if (
      !(selectedServices || [])?.length ||
      selectedDrawingType !== "Initial Drawing"
    ) {
      return null;
    }
    const doneBefore = selectedServices.filter((ser) =>
      (selectedProjectDrawings || []).some(
        ({ sow, drawingType }) =>
          sow === ser && drawingType === "Initial Drawing"
      )
    );
    if (doneBefore?.length > 0) {
      return (
        <Alert
          message={
            <div>
              <span style={{ fontWeight: 600 }}>
                {selectedServices.join(", ")}
              </span>{" "}
              already has initial drawings!
            </div>
          }
          type="warning"
          style={{ marginBottom: 10, width: "98%" }}
        />
      );
    }
  }, [selectedDrawingType, selectedServices, selectedProjectDrawings]);

  return (
    <>
      <NormalSizedModal
        visible={visible}
        // title={`${!permitId ? "New" : "Edit"} Permit Drawing`}
        title={
          <CustomModalHeader
            title={`${!permitId ? "New" : "Edit"} Permit Drawing`}
            onClick={() => {
              setTourOpen(true);
            }}
          />
        }
        className={`permitDrawingModalContainer ${
          isDarkMode && "permitDrawingModalContainerDark"
        }`}
        onCancel={() => {
          formChanged ? setCancelModalVisible(true) : onCancel();
        }}
        centered
        closable={true}
        closeIcon={<XIcon />}
      >
        <Form
          form={form}
          onFieldsChange={() => {
            setFormChanged(true);
          }}
        >
          <div className="modalBodyContainer">
            <div className="modalBodyContainer-splash permitDrawingRecordType">
              <span className="radioTitle">Select a drawing type</span>
              {RenderDynamicComponents(populatedFieldsObj["permitDrawingType"])}
            </div>

            <BorderedTextCard
              title="Permit Drawing Information"
              childrenClassName="cardContainer"
              className="coreInfoContainer"
            >
              <SmallLoadableComp
                {...{
                  loading: !permitDrawings?.length,
                  darkMode: isDarkMode,
                }}
              >
                <div className="inputsContainer">
                  {!!permitId && (
                    <div className="hint">{`Previously ${originalDrawing?.assignedTo}`}</div>
                  )}
                  {RenderDynamicComponents(populatedFieldsObj["inputs"])}
                  <div className="permitAvatarsDiv">
                    {!!selectedTeam?.length && (
                      <MondayButton
                        {...{ Icon: <TickIcon /> }}
                        onClick={openPermitDrawingTeamModal}
                      >
                        Members
                      </MondayButton>
                    )}
                    <div className="permitAvatars">
                      <AvatarImages
                        {...{
                          cognitoUserIds: selectedTeam
                            .reduce(
                              (acc, { members }) => [...acc, ...members],
                              []
                            )
                            .map(({ cognitoUserId }) => cognitoUserId),
                        }}
                      />
                    </div>
                  </div>
                </div>

                {!!selectedProject?.projectId && !permitId && (
                  <div className="servicesContainer">
                    {serviceSelectWarningMessage}
                    <span
                      className="labeledInputLabel"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span style={{ color: "red" }}>*</span>Services
                      <AddServicePopover
                        {...{
                          isDarkMode,
                          serviceOptions,
                          services,
                          setServices,
                        }}
                      />
                    </span>
                    <div className="checkboxContainer">
                      {!!services.length &&
                        RenderDynamicComponents(populatedFieldsObj["services"])}
                    </div>
                  </div>
                )}
              </SmallLoadableComp>

              {!_.isEmpty(selectedProject) &&
              (!compareIncluding(selectedDrawingType, "initial drawing") ||
                (!!permitId && !!permitDrawingsLogs.length)) &&
              (!!initialDrawingsGridData.length ||
                !!permitDrawingsLogs.length) ? (
                <>
                  <div className="labeledInputLabel">
                    {!permitId ? "Assigned to" : "History"}{" "}
                  </div>
                  <FormGrid
                    {...{
                      gridApi: initialDrawingsGridApi,
                      form,
                      onGridReady: (params) =>
                        onGridReady({
                          params,
                          setApi: setInitialDrawingsGridApi,
                        }),
                      onRowSelected,
                      onRowDataChanged: () =>
                        onRowDataChanged({
                          gridApi: initialDrawingsGridApi,
                          rowData: initialDrawingsGridData,
                        }),
                      rowSelection: "multiple",
                      rowMultiSelectWithClick: true,
                      gridSideBar: gridSidebar,
                      gridColumns: !permitId
                        ? populatedColDefs()
                        : populatedLogsColDefs,
                      gridData: !permitId
                        ? initialDrawingsGridData
                        : permitDrawingsLogs,
                      gridFilters: populatedGridFilters,
                      style: { width: "100%", height: 300 },
                      gridStyle: { width: "100%" },
                      isDarkMode,
                    }}
                  />
                </>
              ) : (
                <span>
                  {!compareIncluding(selectedDrawingType, "initial drawing") &&
                  initialDrawingsGridData?.length === 0 &&
                  selectedServices?.length > 0
                    ? "There are no initial drawings available for this project."
                    : null}
                </span>
              )}
            </BorderedTextCard>

            <FormGrid
              {...{
                className: "allDrawingsGrid",
                gridApi: allDrawingsGridApi,
                showFilters: true,
                form,
                onGridReady: (params) =>
                  onGridReady({ params, setApi: setAllDrawingsGridApi }),
                gridSideBar: gridSidebar,
                gridColumns: populatedColDefs()?.map(
                  ({ checkboxSelection, ...rest }) => rest
                ),
                gridData: permitDrawings?.filter(
                  ({ permitStatus: dataStatus }) =>
                    dataStatus?.toLowerCase() !== "completed"
                ),
                gridFilters: populatedGridFilters,
                gridStyle: { width: "100%", height: "100%" },
                rowHeight: 40,
                isDarkMode,
              }}
            />
          </div>
        </Form>
        <div className="footerButtons">
          <MondayButton
            onClick={() => {
              if (formChanged) {
                setCancelModalVisible(true);
              } else {
                onCancel();
              }
            }}
            Icon={<XIcon />}
            className="mondayButtonRed"
            tooltipCategory="Permit Drawings"
            tooltipKey="cancel"
          >
            Cancel
          </MondayButton>
          <MondayButton
            onClick={() => validatePermitDrawingForm(onSave)}
            className="mondayButtonBlue finishBtn"
            Icon={<TickIcon width={17} height={17} />}
            tooltipCategory="Permit Drawings"
            tooltipKey="finish"
          >
            Finish
          </MondayButton>
        </div>
      </NormalSizedModal>
      <WarningModal
        visible={cancelModalVisible}
        setVisible={setCancelModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        onKeyPress={(e) => onEnterPress(e)}
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          <p>Are you sure you want to cancel?</p>
          <div className="buttons">
            <MondayButton
              onClick={() => setCancelModalVisible(false)}
              Icon={<XIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton
              onClick={onCancel}
              Icon={<Tick width={17} height={17} />}
            >
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
      <div>
        {open && (
          <DynamicTeamModal
            {...{
              open,
              setOpen,
              ClearOptions,
              selectedTeam,
              setSelectedTeam,
              proppedAllTeams: permitDrawingTeam,
              onSave: (data) => {
                form.setFieldValue("permitTeam", data || []);
              },
            }}
          />
        )}
      </div>
      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={tourSteps}
          mask={{ color: "#2a2b3a71" }}
        />
      )}
      {showVideoTutorial && (
        <PlayVideoTutorial
          {...{
            visible: showVideoTutorial,
            setVisible: setShowVideoTutorial,
            url: videoTutorialLink,
            title: "Create Permit Drawing Tutorial",
          }}
        />
      )}
      {nonUserModal && (
        <NewNonUserModal
          open={nonUserModal}
          setVisible={setNonUserModal}
          rowData={engineers}
          role={"engineers"}
          updateDataOnSave={({ data }) => {
            setEngineers((prev) => [data, ...prev]);
          }}
        />
      )}
    </>
  );
};

export default PermitDrawingModal;
