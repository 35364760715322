import { useMemo, useState } from "react";
import { useProgramFields } from "../../../../../hooks";
import { handlePricingAccess } from "../../../../SidebarPages/Dashboard/components/Category/categoryData";
import {
  pricingFields,
  widgetOptionsMapping,
} from "../../../../SidebarPages/Dashboard/components/Entity/EntityData";
import { uniqBy } from "lodash";
import { coordinatesAdder } from "../../../../SidebarPages/Dashboard/addWidgetsModalData";
import DefaultWidgetList from "../../../../SidebarPages/Dashboard/components/DefaultWidgetsModal/components/DefaultWidgetList";
import NoWidgetsList from "../../../../SidebarPages/Dashboard/components/DefaultWidgetsModal/components/NoWidgetsList";
import { Result } from "antd";
import { useSelector } from "react-redux";
import "./DashboardBoarding.scss";
import BoardingNavigationBtn from "../../BoardingNavigationBtn/BoardingNavigationBtn";

const DashboardBoarding = ({ setBoardingChanges = () => {} }) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { preferences: userPrefs } = useSelector((state) => state.preferences);

  const {
    ["Default Dashboard Config"]: {
      [userConfiguration?.groupName]: userGroupConfig,
    },
  } = useProgramFields();

  const filteredConfig = useMemo(() => {
    return userGroupConfig?.widgetConfig?.filter((el) => {
      if (el?.BodyComponentParams?.table === "To Do") {
        if (el?.BodyComponentParams?.field === "todoUser") {
          if (userConfiguration?.groupName === "Admin") {
            return true;
          }
          return false;
        }
        return true;
      }

      if (pricingFields?.[el?.BodyComponentParams?.table]) {
        const { pricingView = "", subView = "" } =
          pricingFields?.[el?.BodyComponentParams?.table] || {};

        if (
          pricingFields?.[el?.BodyComponentParams?.table]?.fields?.includes(
            el?.BodyComponentParams?.field
          )
        ) {
          return handlePricingAccess(
            userConfiguration?.routeConfig,
            el?.BodyComponentParams?.table,
            subView,
            pricingView
          );
        }

        return true;
      }

      return true;
    });
  }, []);

  const [defaultWidgets, setDefaultWidgets] = useState({
    selected: filteredConfig.filter(({ id }) =>
      userPrefs?.widgetConfiguration?.dashboard?.some((card) => card.id == id)
    ),
    notSelected:
      filteredConfig.filter(
        ({ id }) =>
          !userPrefs?.widgetConfiguration?.dashboard?.some(
            (card) => card.id == id
          )
      ) || [],
  });

  const handleChartSelect = ({ chart, selected }) => {
    const card = document.getElementById(`${chart?.title}-${chart?.id}`);

    if (!!card) {
      if (selected) {
        card.classList.add("remove-selected");
      } else {
        card.classList.add("select-default");
      }
    }

    setTimeout(() => {
      let newWidget = {};

      if (selected) {
        newWidget = {
          selected: defaultWidgets?.selected?.filter(
            (el) => el?.id !== chart?.id
          ),
          notSelected: uniqBy([...defaultWidgets?.notSelected, chart], "id"),
        };
      } else {
        newWidget = {
          selected: uniqBy([...defaultWidgets?.selected, chart], "id"),
          notSelected: defaultWidgets?.notSelected?.filter(
            (el) => el?.id !== chart?.id
          ),
        };
      }

      setDefaultWidgets(newWidget);

      let layout = {};
      let widgetRepetition = {};
      let statesData = {};

      newWidget?.selected?.forEach((el) => {
        const {
          type = "",
          id: chartId = "",
          BodyComponentParams = {},
        } = el || {};
        const { table = "", field = "" } = BodyComponentParams || {};

        layout = coordinatesAdder({
          layout: layout || {},
          widgetOptions: widgetOptionsMapping[type],
          IdHandler: chartId,
        });

        widgetRepetition = {
          ...widgetRepetition,
          [table]: {
            ...widgetRepetition?.[table],
            [field]: {
              ...widgetRepetition?.[table]?.[field],
              [type]: true,
            },
          },
        };

        Object.assign(statesData, {
          [chartId]: table,
        });
      });

      setBoardingChanges((prev) => ({
        ...prev,
        defaultDashboardConfig: {
          layout,
          widgetRepetition,
          statesData,
          widgetConfig: newWidget?.selected,
        },
      }));
    }, 480);
  };

  return (
    <>
      <div
        className={`dashboard-boarding ${
          isDarkMode ? "dashboard-boarding-dark" : ""
        }`}
      >
        {filteredConfig?.length ? (
          <>
            <div className="dashboard-boarding-list">
              <div className="dashboard-boarding-list-title">
                Default Widgets
              </div>
              {defaultWidgets?.notSelected?.length ? (
                <DefaultWidgetList
                  {...{
                    defaultWidgets: defaultWidgets?.notSelected,
                    handleChartSelect,
                    selected: false,
                  }}
                />
              ) : (
                <NoWidgetsList message="You have selected all the default widgets." />
              )}
            </div>

            <div className="dashboard-boarding-list">
              <div className="dashboard-boarding-list-title">
                Selected Widgets
              </div>
              {defaultWidgets?.selected?.length ? (
                <DefaultWidgetList
                  {...{
                    defaultWidgets: defaultWidgets?.selected,
                    handleChartSelect,
                    selected: true,
                  }}
                />
              ) : (
                <NoWidgetsList message="No widgets selected." />
              )}
            </div>
          </>
        ) : (
          <Result
            {...{
              status: "404",
              title: "No default widgets available!",
              style: { margin: "auto" },
            }}
          />
        )}
      </div>

      <BoardingNavigationBtn />

      {/* <div className="dashboard-boarding-footer">
        <MondayButton
          {...{
            className: "mondayButtonGreen",
            id: "confirm-widgets-button",
            onClick: confirmDefaultWidgets,
            disabled: !defaultWidgets?.selected?.length,
            Icon: <PlusIcon />,
          }}
        >
          Apply Changes
        </MondayButton>
      </div> */}
    </>
  );
};

export default DashboardBoarding;
