import { dayjsNY } from "../../../../../../../../DateComponents/contants/DayjsNY";

export const columnDefs = [
  {
    field: "selection",
    headerName: "",
    width: 50,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    sortable: false,
    filter: false,
    pinned: "left",
  },
  {
    field: "employeeFullName",
    headerName: "Employee Name",
    filter: "agTextColumnFilter",
    flex: 1,
  },
  {
    field: "employeeId",
    headerName: "Employee ID",
    filter: "agTextColumnFilter",
    width: 120,
  },
  {
    field: "employeeRole",
    headerName: "Role",
    filter: "agTextColumnFilter",
    flex: 1,
  },
  {
    field: "punchDate",
    headerName: "Date",
    filter: "agDateColumnFilter",
    valueFormatter: (params) => {
      return dayjsNY(params.value).format("MM/DD/YYYY");
    },
    width: 120,
  },
  {
    field: "punchTime",
    headerName: "Time",
    filter: "agDateColumnFilter",
    valueFormatter: (params) => {
      return dayjsNY(params.value).format("hh:mm A");
    },
    width: 120,
  },
  {
    field: "punchType",
    headerName: "Type",
    filter: "agTextColumnFilter",
    width: 100,
  },
  {
    field: "punchLocation",
    headerName: "Location",
    filter: "agTextColumnFilter",
    flex: 1,
  },
  {
    field: "isEdited",
    headerName: "Edited",
    filter: "agSetColumnFilter",
    cellRenderer: (params) => {
      return params.value ? "Yes" : " No";
    },
    width: 100,
  },
  {
    field: "fingerCheckId",
    headerName: "Finger Check ID",
    filter: "agNumberColumnFilter",
    width: 140,
    hide: true,
  },
  {
    field: "crewTeamName",
    headerName: "Crew Team",
    filter: "agTextColumnFilter",
    flex: 1,
  },
  {
    field: "crewForeman",
    headerName: "Foreman",
    filter: "agTextColumnFilter",
    flex: 1,
  },
  {
    field: "crewPosition",
    headerName: "Position",
    filter: "agTextColumnFilter",
    width: 120,
  },
];
