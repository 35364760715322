import { camelCase } from "lodash";
import { companyDetails } from "../../../helpers/constants";
import createPDF from "../../../integrations/createPDF";
import { compareIncluding } from "../../SidebarPages/utils";
import dayjs from "dayjs";

const { logoName } = companyDetails;

export const onGeneratePDFMobile = ({
  action,
  getDocDefinition = false,
  dataToExport = [],
  columnHeader = [],
  base64 = null,
  titleOfPdf = "",
  title,
}) => {
  const docDefinition = buildDocDefinition({
    dataToExport,
    columnHeader,
    base64,
    titleOfPdf: titleOfPdf || title,
  });

  if (getDocDefinition) {
    return docDefinition;
  }

  createPDF({
    action,
    docDefinition,
    title: titleOfPdf,
  });
};

const buildDocDefinition = ({
  dataToExport,
  columnHeader,
  base64,
  titleOfPdf,
}) => {
  const pageMargins = [20, 80, 20, 60];
  const logo = base64?.find(({ fileName }) =>
    compareIncluding(fileName, logoName)
  )?.base64;

  const header = buildHeader(logo, titleOfPdf);
  const content = buildGridTableContent(dataToExport, columnHeader, titleOfPdf);

  return {
    pageOrientation: "landscape",
    pageMargins,
    header,
    content,
    footer: buildFooter,
    styles: {
      table: {
        fontSize: 11,
        maxHeight: 600,
      },
      tableHeader: {
        fontSize: 11,
        color: "#1A202C",
        noWrap: false,
      },
      tableCell: {
        fontSize: 11,
        color: "#1A202C",
      },
      sectionHeader: {
        fontSize: 14,
        bold: true,
        color: "#2D3748",
        margin: [0, 0, 0, 10],
      },
    },
  };
};

const buildHeader = (logo, titleOfPdf) => ({
  columns: [
    {
      text: titleOfPdf,
      fontSize: 16,
      alignment: "left",
      margin: [20, 10, 20, 10],
    },
    {
      image: logo,
      fit: [120, 120],
      alignment: "center",
    },
    {
      text: dayjs().format("MMM D, YYYY h:mm A"),
      fontSize: 11,
      alignment: "right",
      margin: [20, 10, 20, 10],
    },
  ],
  border: [false, false, false, false],
});

const buildGridTableContent = (dataToExport, columnHeader, titleOfPdf) => {
  let tableNumber = 1;
  const createOpportunityTable = (opportunity) => {
    return {
      table: {
        widths: ["35%", "65%"],
        body: columnHeader.map((header) => {
          let item = opportunity[camelCase(header)];
          if (Array.isArray(item)) {
            item = {
              text: item[0]?.value,
              color: "#1264A3",
              link: item[0]?.redirect,
              noWrap: false,
            };
          } else if (
            item?.toString()?.length === 10 ||
            item?.toString()?.length === 13
          ) {
            item = dayjs(item).format("MM/DD/YYYY");
          } else if (
            typeof item === "string" &&
            item?.startsWith("data:image/png;")
          ) {
            item = {
              image: item,
              fit: [128, 100],
              alignment: "center",
            };
          } else if (
            typeof item === "string" &&
            item?.startsWith("#") &&
            item.length === 7
          ) {
            item = {
              text: item,
              background: item,
              noWrap: false,
            };
          } else {
            item = {
              text: item?.toString(),
              noWrap: false,
            };
          }

          return [
            { text: `${header}:`, style: "tableHeader" },
            {
              ...(typeof item !== "string" ? item : { text: item }),
              style: "tableCell",
            },
          ];
        }),
      },
      layout: {
        hLineWidth: () => 1,
        vLineWidth: () => 1,
        hLineColor: () => "#E2E8F0",
        vLineColor: () => "#E2E8F0",
        fillColor: (rowIndex) => (rowIndex % 2 === 0 ? "#F7FAFC" : null),
        paddingTop: (i) => (i === 0 ? 2 : 5),
        paddingBottom: (i) => (i === 0 ? 2 : 5),
      },
      margin: [0, 0, 10, 15],
    };
  };

  const chunkedData = [];
  for (let i = 0; i < dataToExport.length; i += 2) {
    chunkedData.push(dataToExport.slice(i, i + 2));
  }

  return chunkedData.map((chunk) => ({
    columns: chunk.map((opportunity, index) => ({
      width: "50%",
      stack: [
        {
          text: `${tableNumber++}. ${titleOfPdf} Details`,
          style: "sectionHeader",
          decorationStyle: "solid",
        },
        createOpportunityTable(opportunity),
        index === chunk.length - 1 ? { text: "", pageBreak: "after" } : null,
      ].filter(Boolean),
    })),
    columnGap: 20,
  }));
};

const buildFooter = function (currentPage, pageCount) {
  return {
    columns: [
      {
        text: `Page ${currentPage} of ${pageCount}`,
        alignment: "right",
        margin: [0, 0, 20, 0],
        fontSize: 9,
        color: "#718096",
      },
    ],
    margin: [0, 10, 0, 0],
    border: [false, true, false, false],
    borderColor: "#E2E8F0",
    borderLineWidth: 1,
  };
};
