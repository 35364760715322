import { AgGridReact } from "ag-grid-react";
import { Button, message, Tooltip } from "antd";
import { useState, useEffect } from "react";
import { filterTables } from "../../../../../utils";
import "./AssignProjectGrid.scss";
import { useSelector } from "react-redux";

function AssignProjectGrid(props) {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [related, setRelated] = useState([]);
  const [loading, setLoading] = useState(false);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const columnDefs = [
    {
      headerName: "Project Name",
      field: "projectName",
      cellEditor: "agTextCellEditor",
      cellRenderer: (params) => (
        <Tooltip title={props?.params?.projectNameTooltip}>
          {params?.data?.projectName}
        </Tooltip>
      ),
      minWidth: 250,
    },
    {
      headerName: "Account Name",
      field: "accountName",
      cellEditor: "agSelectCellEditor",
    },
    {
      headerName: "Project Status",
      field: "projectStatus",
      cellEditor: "agTextCellEditor",
    },
    {
      headerName: "Project Exective",
      field: "projectExecutive",
      cellEditor: "agSelectCellEditor",
    },
  ];

  useEffect(() => {
    if (!props?.params?.projectId) return;
    setLoading(true);
    filterTables("projects", "projectId", props?.params?.projectId)
      .then((r) => {
        if (r?.length > 0) {
          setRelated(r);
          setLoading(false);
        } else {
          setRelated([]);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Error getting related project!");
        setLoading(false);
      });
  }, []);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  return (
    <div
      className={`assignProjectGrid ${isDarkMode && "assignProjectGridDark"}`}
    >
      {props?.params?.projectId ? (
        <div
          className={`grid-container ${
            isDarkMode
              ? "dark-ag-theme ag-theme-alpine-dark"
              : "light-ag-theme ag-theme-alpine"
          }`}
          style={{ width: "100%", height: 200 }}
        >
          <AgGridReact
            columnDefs={columnDefs}
            rowData={related}
            onGridReady={onGridReady}
            animateRows
            pagination={false}
            defaultColDef={{
              resizable: false,
              enablePivot: false,
              enableColResize: false,
              enableRowGroup: false,
              editable: false,
              sortable: false,
              flex: 1,
              minWidth: 100,
              filter: false,
            }}
            rowGroupPanelShow={"never"}
            rowHeight={45}
          />
        </div>
      ) : (
        <div className="noRelatedProjects">No Related Projects</div>
      )}
    </div>
  );
}

export default AssignProjectGrid;
