function DoubleLineLabel({
  label,
  comparator = "||",
  isOption,
  inline,
  style = {},
  sameColor = false,
}) {
  const black = "#231F20";
  const l1 = label?.split(comparator)?.[0] || "";
  const l2 = label?.split(comparator)?.[1] || "";
  const isRow = l1?.length < 10 && l2?.length < 10;
  const allStyles = {
    container: {
      display: "flex",
      flexDirection: inline || isRow ? "row" : "column",
      ...(inline || isRow ? { gap: 5 } : {}),
      ...(style?.container || {}),
    },
    main: {
      fontSize: isOption ? "14px" : "16px",
      color: black,
      ...(style?.main || {}),
    },
    secondary: {
      fontSize: "14px",
      color: sameColor ? black : "#9d6e43",
      ...(style?.secondary || {}),
    },
  };

  const hasLineBreak = l2 && isRow;

  return (
    <div style={allStyles?.container} className="double-line-label">
      <span style={allStyles.main}>{l1}</span>
      {hasLineBreak || inline ? <span style={allStyles.main}> / </span> : null}
      {l2 && <span style={allStyles.secondary}>{l2}</span>}
    </div>
  );
}

export default DoubleLineLabel;
