import { useContext, useState } from "react";
import { MobileBasePageContext } from "../MobileBasePage";
import Grid from "./Grid";
import MappingConfigModal from "../../../pages/Settings/settingsComponents/MappingConfigurations/components/Modal/MappingConfigModal";
import { useSelector } from "react-redux";

const expandIcon = (onClick, fill) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={() => {
      onClick();
    }}
    data-testid="expand-icon"
  >
    <path
      d="M9.33366 22.6665H13.3337C13.7114 22.6665 14.0281 22.7943 14.2837 23.0498C14.5392 23.3054 14.667 23.6221 14.667 23.9998C14.667 24.3776 14.5392 24.6943 14.2837 24.9498C14.0281 25.2054 13.7114 25.3332 13.3337 25.3332H8.00033C7.62255 25.3332 7.30588 25.2054 7.05033 24.9498C6.79477 24.6943 6.66699 24.3776 6.66699 23.9998V18.6665C6.66699 18.2887 6.79477 17.9721 7.05033 17.7165C7.30588 17.4609 7.62255 17.3332 8.00033 17.3332C8.3781 17.3332 8.69477 17.4609 8.95033 17.7165C9.20588 17.9721 9.33366 18.2887 9.33366 18.6665V22.6665ZM22.667 9.33317H18.667C18.2892 9.33317 17.9725 9.20539 17.717 8.94984C17.4614 8.69428 17.3337 8.37761 17.3337 7.99984C17.3337 7.62206 17.4614 7.30539 17.717 7.04984C17.9725 6.79428 18.2892 6.6665 18.667 6.6665H24.0003C24.3781 6.6665 24.6948 6.79428 24.9503 7.04984C25.2059 7.30539 25.3337 7.62206 25.3337 7.99984V13.3332C25.3337 13.7109 25.2059 14.0276 24.9503 14.2832C24.6948 14.5387 24.3781 14.6665 24.0003 14.6665C23.6225 14.6665 23.3059 14.5387 23.0503 14.2832C22.7948 14.0276 22.667 13.7109 22.667 13.3332V9.33317Z"
      fill={fill}
    />
  </svg>
);

const collapseIcon = (fill) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="collapse-icon"
  >
    <path
      d="M12.0003 19.9998H8.00033C7.62255 19.9998 7.30588 19.8721 7.05033 19.6165C6.79477 19.3609 6.66699 19.0443 6.66699 18.6665C6.66699 18.2887 6.79477 17.9721 7.05033 17.7165C7.30588 17.4609 7.62255 17.3332 8.00033 17.3332H13.3337C13.7114 17.3332 14.0281 17.4609 14.2837 17.7165C14.5392 17.9721 14.667 18.2887 14.667 18.6665V23.9998C14.667 24.3776 14.5392 24.6943 14.2837 24.9498C14.0281 25.2054 13.7114 25.3332 13.3337 25.3332C12.9559 25.3332 12.6392 25.2054 12.3837 24.9498C12.1281 24.6943 12.0003 24.3776 12.0003 23.9998V19.9998ZM20.0003 11.9998H24.0003C24.3781 11.9998 24.6948 12.1276 24.9503 12.3832C25.2059 12.6387 25.3337 12.9554 25.3337 13.3332C25.3337 13.7109 25.2059 14.0276 24.9503 14.2832C24.6948 14.5387 24.3781 14.6665 24.0003 14.6665H18.667C18.2892 14.6665 17.9725 14.5387 17.717 14.2832C17.4614 14.0276 17.3337 13.7109 17.3337 13.3332V7.99984C17.3337 7.62206 17.4614 7.30539 17.717 7.04984C17.9725 6.79428 18.2892 6.6665 18.667 6.6665C19.0448 6.6665 19.3614 6.79428 19.617 7.04984C19.8725 7.30539 20.0003 7.62206 20.0003 7.99984V11.9998Z"
      fill={fill}
    />
  </svg>
);

const MobileGridTable = () => {
  const { title, rowData } = useContext(MobileBasePageContext);
  const [expandTable, setExpandTable] = useState(false);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <div data-testid="mobile-grid-table" className="mobileBasePageGrid">
      <div className={`mobileBasePageGridWrapper ${isDarkMode ? "dark" : ""}`}>
        <div className={`categoryHeader ${isDarkMode ? "dark" : ""}`}>
          <div>
            {title} ({rowData.length})
          </div>
          {expandIcon(
            () => setExpandTable(true),
            isDarkMode ? "#ffffff" : "#231F20"
          )}
        </div>
        <Grid />
        <MappingConfigModal
          open={expandTable}
          onCancel={() => setExpandTable(false)}
          closeIcon={collapseIcon(isDarkMode ? "#ffffff" : "#231F20")}
          title={`${title} (${rowData.length})`}
          className="noPadding"
          width={1000}
        >
          <Grid isModal />
        </MappingConfigModal>
      </div>
    </div>
  );
};

export default MobileGridTable;
