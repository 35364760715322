import { Tooltip } from "antd";
import { cloneDeep } from "lodash";
import JSONViewer from "./JSONViewer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateEditLogs } from "./updateEditLogs";
import { LeftArrow } from "../../../../BasePage/src";
import { ReportIcon } from "../../../../../../icons";
import { ReportDesigner } from "../../../components";
import { useEditLogs } from "../../../../../../hooks";
import { views } from "../../reportsConfigurationData";
import objectSamplesFormat from "./objectSamplesFormat";
import { updateReport } from "../../actions/wrapperFunctions";
import { XIcon } from "../../../../Communication/assets/index";
import { generatePdfThumbnail } from "../../../../../../utils";
import { ReportsConfigContext } from "../../ReportsConfigContext";
import { RedWarningModal } from "../../../../../commonComponents/index";
import { useContext, useEffect, useRef, useState, useMemo } from "react";
import { getChangedData } from "../../../../Accounting/components/utilities";
import {
  attachDataSources,
  attachParameters,
  extractDataSources,
  extractReportParameters,
  generateReportBlob,
  mapProppedDataSources,
  populateReport,
} from "../../../utils";

import "./ReportConfig.scss";

const ReportConfig = () => {
  const {
    objectSamples,
    selectedCategory,
    selectedReport,
    setSelectedReport,
    setActiveView,
    curryDispatch,
  } = useContext(ReportsConfigContext);
  const designerRef = useRef();

  const [confirmationModalVisible, setConfirmationModalVisible] = useState();
  const [samplesModalVisible, setSamplesModalVisible] = useState(false);

  const [newObjSamples, setNewObjSamples] = useState(objectSamples);

  const samples = useMemo(() => {
    return objectSamplesFormat(selectedCategory, newObjSamples);
  }, [objectSamples, newObjSamples, selectedCategory]);

  const { categoryParameters = [] } = selectedCategory || {};

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { saveAddedLogs } = useEditLogs();
  const navigate = useNavigate();

  // Destructuring of the selected report
  const {
    reportId,
    datasets,
    reportParameters = [],
    reportName,
    reportObj,
  } = selectedReport || {};
  const isNew = !reportObj;

  const onBack = () => {
    setSelectedReport(undefined);

    const reportFromLS = localStorage.getItem("newReport");
    // check if there is a new report in local storage
    if (reportFromLS) {
      navigate("/reports");
    } else {
      setActiveView(views.CATEGORY_CONFIG);
    }
  };

  // new log object that will store the changes
  let newEditLog = {
    recordId: reportId,
    recordName: reportName,
    topic: `ReportsConfiguration/${selectedCategory?.department}/${reportName}`,
    actionType: "Edit",
    category: "Reports Configuration",
    currentData: {},
    label: "",
    nameOfUser: userConfiguration.nameOfUser,
    cognitoUserId: userConfiguration?.cognitoUserId,
    previousData: {},
    updatedKeys: [],
  };

  const onSave = async (report) => {
    try {
      const formattedReportObj = extractDataSources(cloneDeep(report));
      const newReport = {
        ...selectedReport,
        reportObj: formattedReportObj,
        datasets: formattedReportObj?.definition?.DataSets,
        reportParameters: extractReportParameters(formattedReportObj),
        datasources: formattedReportObj?.definition?.DataSources || [],
      };

      const objSampleObjects = samples?.reduce((acc, curr) => {
        acc[curr.name] = curr?.data || [];
        return acc;
      }, {});

      const populatedReportObj = await populateReport({
        report: newReport,
        categoryParameters,
        customData: objSampleObjects,
      });

      let thumbnail = "";
      try {
        const pdfBlob = await generateReportBlob(populatedReportObj);
        // const url = URL.createObjectURL(pdfBlob.data);
        // window.open(url);
        // URL.revokeObjectURL(url);
        if (!pdfBlob) {
          console.error("Error in generating report blob");
          throw new Error("Error in generating report blob");
        }
        const buffer = await pdfBlob.data.arrayBuffer();
        if (!buffer) {
          console.error("Error in generating report blob buffer");
          throw new Error("Error in generating report blob buffer");
        }
        thumbnail = await generatePdfThumbnail(new Uint8Array(buffer));
      } catch (e) {
        console.error("Error in generating report blob", e);
        thumbnail = "";
      }

      // update changes in DB
      curryDispatch(
        updateReport(selectedCategory, {
          ...newReport,
          thumbnail: thumbnail ? thumbnail : selectedReport?.thumbnail,
        })
      );
      // Get changes
      let result = getChangedData(newReport.reportObj, reportObj);

      if (!!result) {
        const { currChange, prevChange } = updateEditLogs(result);

        newEditLog.currentData = currChange;
        newEditLog.previousData = prevChange;
        newEditLog.updatedKeys.push(reportName);
      }

      // update DB with current chagnes
      saveAddedLogs(newEditLog);
      onBack();
    } catch (error) {
      console.error("Error saving report", error);
    }
  };

  const onBackButtonClick = async () => {
    const report = await designerRef.current.getReport();

    !!report.isDirty ? setConfirmationModalVisible(true) : onBack();
  };

  useEffect(() => {
    (async () => {
      if (!reportId) return;
      const designerComponent = designerRef.current;
      let loadedReport = cloneDeep(await designerComponent.getReport());

      loadedReport = {
        ...loadedReport,
        id: reportId,
        displayName: reportName,
        definition: reportObj?.definition || loadedReport.definition,
      };

      loadedReport.definition.DataSets = datasets;

      // Loads the report
      designerComponent.setReport(
        attachParameters(
          attachDataSources(loadedReport, samples, isNew),
          reportParameters,
          categoryParameters
        )
      );
    })();
  }, [reportId]);

  return (
    <>
      <div className="reportConfigurationDesignerContainer">
        <ReportDesigner
          {...{
            ref: designerRef,
            dataSources: mapProppedDataSources(samples),
            onSave,
            newEditLog,
            reportName,
            isDarkMode,
            reportsAvailable: selectedCategory.reportsAvailable,
          }}
        />
        <div
          className="previewButton"
          onClick={() => setSamplesModalVisible(true)}
        >
          <Tooltip title="Preview Object Samples" placement="right">
            <ReportIcon />
          </Tooltip>
        </div>
        <div className="backButton" onClick={onBackButtonClick}>
          <Tooltip title="Back" placement="right">
            <LeftArrow />
          </Tooltip>
        </div>
      </div>

      {confirmationModalVisible && (
        <RedWarningModal
          visible={confirmationModalVisible}
          onCancel={() => {
            setConfirmationModalVisible(false);
          }}
          setVisible={setConfirmationModalVisible}
          children={
            <p>You have unsaved changes, are you sure you want to go back?</p>
          }
          footerProps={{
            onConfirm: () => {
              onBack();
              setConfirmationModalVisible(false);
            },
          }}
        />
      )}

      {samplesModalVisible && (
        <RedWarningModal
          visible={samplesModalVisible}
          onCancel={() => setSamplesModalVisible(false)}
          setVisible={setSamplesModalVisible}
          titleText="Object Samples"
          footerProps={{
            onConfirm: () => {
              setSamplesModalVisible(false);
            },
          }}
          TitleIcon={ReportIcon}
          confirmModal
          footer={[
            {
              text: "Close",
              onClick: () => setSamplesModalVisible(false),
              disabled: false,
              Icon: XIcon,
              className: "mondayButtonRed",
            },
          ]}
        >
          {samples
            .filter((el) => selectedReport?.datasources.includes(el.name))
            .map((sample) => (
              <div style={{ textAlign: "left" }} key={sample.name}>
                <div>{sample.name}</div>
                <JSONViewer
                  {...{
                    jsonData: sample.data,
                    onChange: (data) => {
                      console.log("ReportConfig ~ data:", data);
                      const newSamples = newObjSamples.map((el) =>
                        el.name === sample.name ? { ...el, data } : el
                      );
                      setNewObjSamples(newSamples);
                    },
                  }}
                />
              </div>
            ))}
        </RedWarningModal>
      )}
    </>
  );
};

export default ReportConfig;
