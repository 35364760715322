import { message } from "antd";
import axios from "axios";
import * as FileSaver from "file-saver";
import { driveApi } from "../../../../../../../../integrations/DriveRequest";
import { API } from "aws-amplify";
import { b64toBlob } from "../../../../../../../ProposalBuilder/components/ProposalPages/MergePdfs/MergePdfs";
import Swal from "sweetalert2";

export async function exportToXLSX(
  name,
  accountName,
  result,
  accessToken,
  folderId,
  customName,
  fakeApplication
) {
  const hideLoad = message.loading("Generating to Excel...", 0);
  console.log("name", name, "accountName", accountName, "result", result);
  let temp = result?.applicationData?.workSheet1DataExcel || [];
  let services = getServicesName(
    result?.applicationData?.workSheet2DataExcel?.map((item) => item?.label)
  );
  let fileName = !!temp?.applicationNo
    ? name.replace(/\W+/g, " ") +
      ` Req#${temp?.applicationNo} ${temp?.periodTo} ` +
      ".xlsx"
    : name + ".xlsx";
  // const url =
  //   "https://leadmanager-express-backend.ue.r.appspot.com/api/export/xlsx";
  // // console.log("temp", {
  // 	accountName: accountName,
  // 	name: name,
  // 	apiKey: "5e550158-dc8c-4814-b8d3-db8a8341bf88",
  // 	...result,
  // });
  // const url = "http://localhost:8080/api/export/xlsx"

  await API.post("exportReqs", "/exportReqs", {
    body: {
      data: {
        accountName: accountName,
        name: name,
        apiKey: "5e550158-dc8c-4814-b8d3-db8a8341bf88",
        ...(result?.applicationData || {}),
      },
      name: name,
      accountName: accountName,
    },
  }).then(async (response) => {
    if (!accessToken) {
      console.log("response", response);
      let blob = b64toBlob(
        response.body,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
      FileSaver.saveAs(blob, fileName);
      hideLoad();
      message.success("Export successful.");
      return;
    }
    const loading = message.loading("Uploading to Google Drive...", 0);
    const driveRequest = driveApi({ accessToken });
    await driveRequest
      .uploadFile(
        response.body,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
        {
          name: customName,
          parents: [folderId],
          //   mimeType:
          mimeType: "application/vnd.google-apps.spreadsheet",
        },
        true
      )
      .then((response) => {
        attachDriveIdToRequisition(fakeApplication, folderId);
        loading();
        Swal.fire({
          title: "Success",
          text: "Current Application successful",
          icon: "success",
        });
      });
  });
  return;
  const url = "http://10.0.0.85:8080/api/export/xlsx";
  const hideLoading = message.loading(
    "File is being processed. Please wait...",
    0
  );
  axios({
    method: "post",
    url,
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
    data: JSON.stringify({
      accountName: accountName,
      name: name,
      apiKey: "5e550158-dc8c-4814-b8d3-db8a8341bf88",
      ...result,
    }),
  })
    .then(async (response) => {
      hideLoading();
      console.log("response", response);
      FileSaver.saveAs(response.data, fileName);
      message.success("Export successful.");
      // blob to 64
      if (!accessToken) return;
      const reader = new FileReader();
      reader.onload = async function () {
        var b64 = reader.result.replace(/^data:.+;base64,/, "");
        const driveRequest = driveApi({ accessToken });
        await driveRequest
          .uploadFile(
            b64,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
            {
              name: customName,
              parents: [folderId],
              //   mimeType:
              mimeType: "application/vnd.google-apps.spreadsheet",
            },
            true
          )
          .then((response) => response.json());
      };
      attachDriveIdToRequisition(fakeApplication, folderId);

      reader.readAsDataURL(response.data);
    })
    .catch((err) => {
      hideLoading();
      console.error(err);
      message.error("Something went wrong...");
    });
}

const getServicesName = (services) => {
  if (Array.isArray(services)) {
    console.log("services", services.join(", "));
    return services.join(", ");
  }
};

const attachDriveIdToRequisition = async (fakeApplication, driveId) => {
  console.log("fakeApplication", fakeApplication);
  // if (fakeApplication?.applicationNo === 1) {
  await API.put(
    "applications",
    `/applications/${fakeApplication?.applicationId}`,
    {
      body: {
        googleDriveLocationId: driveId,
      },
    }
  );
  // }
};

export async function exportSOVtoExcel({ workSheetDataExcel, fakeSOV }) {
  let fileName = `${fakeSOV?.projectName || "AIA_Application"}__SOV${
    fakeSOV?.SOVNo || ""
  }`;
  const hideLoad = message.loading("Exporting to Excel...", 0);
  await API.post("exportSoV", "/exportSoV", {
    body: {
      data: { workSheetDataExcel },
      name: "SOV",
    },
  }).then((response) => {
    console.log("response", response);
    let blob = b64toBlob(
      response.body,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
    FileSaver.saveAs(blob, fileName);
    hideLoad();
    message.success("Export successful.");
  });
  // exportToXLSX(fileName, "", {
  //   sovData: { workSheetDataExcel },
  // });
}
