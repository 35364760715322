import { useState, useEffect, useContext } from "react";

import { getDegTotals } from "../utils";
import DegModalContext from "../DegModalContext";
import { formatCurrency } from "../../../../../../../SidebarPages/utils";

function FooterTotals({ api }) {
  const { fingerCheckWeeklyData } = useContext(DegModalContext);

  const isEntriesTab = api?.getDisplayedRowAtIndex(5)?.data?.entryId || null;

  const [totals, setTotals] = useState({
    total: 0,
    totalOt: 0,
    totalReg: 0,
    totalOvh: 0,
    totalWOvh: 0,
  });

  useEffect(() => {
    function findSums() {
      let employeeWeekTotals =
        api?.context?.contextParams?.providedBeanInstances?.gridOptions?.context
          .employeeWeekTotals || {};

      let generalOverheadTotal =
        api?.context?.contextParams?.providedBeanInstances?.gridOptions?.context
          .generalOverheadTotal || 0;

      setTotals(
        getDegTotals({
          employeeWeekTotals,
          generalOverheadTotal,
        })
      );
    }

    api.addEventListener("modelUpdated", findSums);
    return () => {
      api.removeEventListener("modelUpdated", findSums);
    };
  }, []);

  return (
    <div style={{ display: "flex", gap: "1rem" }}>
      <span data-testid="totalReg">
        Total Reg:&nbsp;<strong>{formatCurrency(totals.totalReg)}</strong>
      </span>
      <span data-testid="totalOt">
        Total OT:&nbsp;<strong>{formatCurrency(totals.totalOt)}</strong>
      </span>
      <span data-testid="totalReimbursement">
        Total Reimbursement:&nbsp;
        <strong>{formatCurrency(totals.totalReimbursement)}</strong>
      </span>
      <span data-testid="totalOverhead">
        Total Overhead:&nbsp;<strong>{formatCurrency(totals.totalOvh)}</strong>
      </span>
      <span data-testid="totalProjectCost">
        Total Project Cost:&nbsp;<strong>{formatCurrency(totals.total)}</strong>
      </span>
      <span data-testid="total">
        Total:&nbsp;<strong>{formatCurrency(totals.totalWOvh)}</strong>
      </span>
      {isEntriesTab && fingerCheckWeeklyData && (
        <>
          <span data-testid="total">|</span>
          <span data-testid="total">
            <strong>
              {api?.getDisplayedRowCount()} / {fingerCheckWeeklyData?.length}
            </strong>
            &nbsp; Entries from Finger Check
          </span>
        </>
      )}
    </div>
  );
}

export default FooterTotals;
