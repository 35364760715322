import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";

export const defaultColumns = [
  {
    field: "scheduleAddress",
  },
  { field: "scheduleName" },
  { field: "typeOfWork", width: 155 },
  { field: "estimation", width: 130 },
  { headerName: "Service", field: "serviceName", width: 125 },
  { field: "typeOfProgress", width: 178 },
  { field: "elevation" },
  { field: "pli", width: 70 },
  { field: "length", width: 110, aggFunc: "sum" },
  { field: "height", width: 110, aggFunc: "sum" },
  { field: "width", width: 110, aggFunc: "sum" },
  {
    field: "startDate",
    cellRenderer: (params) => params?.value,
    valueGetter: (params) =>
      params?.data?.startDate
        ? dayjsNY(params?.data?.startDate).format("MM/DD/YYYY")
        : null,
    width: 125,
  },
  { field: "dayProgress" },
  {
    headerName: "Pli Day (%)",
    field: "pliProgressPercentage",
    cellRenderer: (params) => params?.value,
    valueGetter: (params) =>
      Math.round(params?.data?.pliProgressPercentage) || 0 + "%",
    width: 135,
  },
  {
    headerName: "Pli Day (lf)",
    field: "progressValues",
    cellRenderer: (params) => params?.value,
    valueGetter: (params) => {
      return (
        params?.data?.progressValues?.reduce(
          (acc, curr) => acc * (Object.values(curr)?.[0] || 0),
          1
        ) || 0
      );
    },
    width: 135,
  },

  {
    headerName: "Day Progress (%)",
    field: "pliProgressPercentage",
    cellRenderer: (params) => params?.value,
    valueGetter: (params) =>
      params?.data?.isHoist
        ? `${params?.data?.dayProgress}%`
        : (Math.round(params?.data?.pliProgressPercentage) || 0) + "%",
    width: 185,
  },
  {
    headerName: "Elevation day (%)",
    cellRendererFramework: (params) => {
      if (params?.data?.isHoist) return "";
      const progressValues = params?.data?.progressValues?.[0] || {};
      const reduceProgress =
        Object.values(progressValues).reduce((acc, curr) => acc + curr, 0) || 0;

      const dayProgress = reduceProgress;
      const totalElevation = params?.data?.totalElevation || 0;

      return isNaN(dayProgress / totalElevation)
        ? 0
        : ((dayProgress / totalElevation) * 100).toFixed(2) + "%";
    },
  },
  {
    headerName: "Service day (%)",
    cellRendererFramework: (params) => {
      if (params?.data?.isHoist) return "";
      const progressValues = params?.data?.progressValues?.[0] || {};
      const reduceProgress =
        Object.values(progressValues).reduce(
          (acc, curr) => acc + (curr || 0),
          0
        ) || 0;
      const dayProgress = reduceProgress;
      const totalService = params?.data?.totalService || 0;
      return isNaN(dayProgress / totalService)
        ? 0
        : ((dayProgress / totalService) * 100).toFixed(2) + "%";
    },
  },
];

export const columnDefsForServices = {
  "Horizontal Netting": [...defaultColumns],
  Scaffold: [...defaultColumns],
  "Sidewalk Shed": [...defaultColumns],
  Hoist: [],
  Fence: [...defaultColumns],
  "Roof Protection": [...defaultColumns],
  "Overhead Protection": [...defaultColumns],
  "Needle Beam": [...defaultColumns],
  Barriers: [...defaultColumns],
  "Working Platform": [...defaultColumns],
  "System Scaffold": [...defaultColumns],
  "Stair Tower": [...defaultColumns],
  Shoring: [...defaultColumns],
  "Plywood Enclosure": [...defaultColumns],
  "Debris Chute": [...defaultColumns],
  "Hanging Scaffold": [...defaultColumns],
  "Vertical Netting": [...defaultColumns],
  "Window Protection": [...defaultColumns],
  "Platform Scaffold": [...defaultColumns],
  "Elevated Roof Protection": [...defaultColumns],
};
