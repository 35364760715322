import { checkValueTypes } from "../../hooks/useEditLogs";
import { fetchAllData } from "../ApiMethods";

export const getAllLogs = async ({ keysToInclude }) => {
  try {
    const logs = await fetchAllData({
      endpoint: "editLogs",
      resultId: "logId",
      otherStringParams: {
        keysToInclude: JSON.stringify([
          ...new Set([...keysToInclude, ...["logId"]]),
        ]),
      },
    });

    return logs
      .map((log) => {
        const hasValidTypes = checkValueTypes(log);

        return hasValidTypes && log;
      })
      .filter(Boolean);
  } catch (error) {
    console.error("Data source User Invitations error: ", error);
  }
};
