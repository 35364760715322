import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import "./Overview.scss";
import { useState, useEffect, useRef, lazy } from "react";
import { onDragEnd } from "../../utils";
import { alignItems } from "../../utils/alignItems";
import { HiddenIcon, NotesIcon } from "../../src";
import DrawerComponent from "./components/Drawer";
import { LoadableComp } from "../../../XComponents";
import { Popover, Tooltip } from "antd";
import { CardView } from "../../components";
import { useNavigate, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { API, a } from "aws-amplify";
import { preferences } from "../../../../../actions";
import DrivePermission from "./components/DrivePermission/DrivePermission";
import { DriveIcon, FolderIcon } from "../../../Communication/assets";
import { Notes } from "../../../../commonComponents/Notes/Notes";
import { ReportIcon, WarningIcon } from "../../../../../icons";
import Report from "./components/Report/Report";
import { useProgramFields, useQuery } from "../../../../../hooks";
import { uuidRegex } from "../../../Documentation/View/documentationViewData";
// import ToDoSideButton from "../../../ToDos/ToDoSideButton";
import { filterTables } from "../../../../../utils";
import lazyRetry from "../../../../../utils/LazyRetry";
import {
  findMissingFolderKeys,
  googleDriveFolderIdKeys,
} from "./components/DrivePermission/utils/utils";
import AddDriveFolderModal from "./components/AddDriveFolderModal/AddDriveFolderModal";
import { driveApi } from "src/integrations/DriveRequest";
import dayjs from "dayjs";
import { MIME_FOLDER } from "src/integrations/DriveRequest/driveStatics";
import { LoadingDots } from "src/components/commonComponents/3LoadingDots/LoadingDots";
import { keys } from "../../../../pages/Settings/settingsComponents/Roles/RolesData";
import PopoverLinksContent from "./components/PopoverLinksContent/PopoverLinksContent";
import EmailBoxContainer from "./components/EmailBoxContainer/EmailBoxContainer";
import { useMemo } from "react";
import { WithTooltip } from "../../../../commonComponents";
import DynamicReportDesigner from "../../../Reports/components/DynamicReportDesigner/DynamicReportDesigner";
// import ToDoModal from "../../../ToDos/ToDoModal";

const ToDoModal = lazy(() =>
  lazyRetry(
    () =>
      import(/* webpackChunkName: "ToDoModal" */ "../../../ToDos/ToDoModal"),
    "ToDoModal"
  )
);

const Overview = ({
  viewTitle,
  props: overviewData = [],
  getEditedValue,
  currentStep,
  sideButtons = [],
  notesProps = {},
  stepperParams,
  rowData = {},
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = useQuery();

  const [dataView, setDataView] = useState([]);
  const [columns, setColumns] = useState({});
  const [visible, setVisible] = useState(false);
  const [changed, setChanged] = useState(false);
  const [notes, setNotes] = useState(
    !!location?.state?.data?.notesOpen ||
      queryParams.get("emailHref") === "notesOpen" || // this param is sended by backend to users email, from mentions list email on click we get this param
      false
  );
  const [todos, setTodos] = useState({
    rowData: [],
    visible:
      queryParams.get("emailHref") === "todosOpen" || // this param is sended by backend email
      false,
  });
  const [visibleReport, setVisibleReport] = useState(false);
  let customCategoryName = "";
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [driveAccess, setDriveAccess] = useState(false);
  const [addDriveModal, setAddDriveModal] = useState(false);
  const [driveFolder, setDriveFolder] = useState(false);
  const { preferences: pref } = useSelector((state) => state.preferences);
  const [emailBoxes, setEmailBoxes] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const dispatch = useDispatch();
  const {
    ["Reports Integration Config"]: reportsIntegrationConfig,
    ["To Do Categories"]: todoCategories,
    ["List of Folders"]: folderList,
  } = useProgramFields();

  const driveRequest = driveApi({ accessToken });

  const callDriveFolderRef = useRef(1);

  const hasToDo =
    Array.isArray(todoCategories) && todoCategories?.length > 0
      ? todoCategories?.find((el) => {
          if (location.pathname.includes("drivers")) {
            return el?.title?.split(" ")?.join("")?.toLowerCase() === "drivers";
          }
          return (
            el?.title?.split(" ")?.join("")?.toLowerCase() ===
            location?.pathname?.split("/")?.[1]?.toLowerCase()
          );
        })?.hasToDo
      : false;

  const reportEditAccess =
    userConfiguration?.routeConfig
      ?.find((r) => r?.title === "Settings")
      ?.children.find((c) => c?.title === "Report")?.write || false;

  const todoRouteAccess = userConfiguration?.routeConfig?.find(
    (el) => el?.title?.toLowerCase() === "to do"
  )?.read;

  const formattedPath = location.pathname.replace(uuidRegex, "");
  const { partitionKeys, categoryName } = useMemo(
    () => reportsIntegrationConfig?.[formattedPath] || {},
    [reportsIntegrationConfig, location]
  );

  // this is used to send dynamic primaryKey for reports to be filtered based on primaryKey value
  const modifiedFormattedPath = formattedPath.replace(/\//g, "");

  let dynamicPrimaryKey =
    modifiedFormattedPath === "scheduling"
      ? "scheduleId"
      : modifiedFormattedPath === "permitdrawings"
      ? "permitId"
      : modifiedFormattedPath === "fleetViolations"
      ? "violationId"
      : modifiedFormattedPath === "fleetdrivers"
      ? "driverId"
      : modifiedFormattedPath.endsWith("ies")
      ? modifiedFormattedPath.slice(0, -3) + "yId"
      : modifiedFormattedPath.endsWith("s")
      ? modifiedFormattedPath.slice(0, -1) + "Id"
      : modifiedFormattedPath + "Id";

  let recordTable = window.location.pathname
    .split("/")
    .slice(0, window.location.pathname.split("/").length - 1)
    .join("");

  /**
   *
   * * Custom logic for paths where the record name is set in the second URL segment | e.g "unwantedPath/recordName"
   * Add additional paths as needed
   */

  const pathsWithRecordInSecondSegment = ["/hrManagement"];
  if (
    pathsWithRecordInSecondSegment.some((path) =>
      formattedPath.startsWith(path)
    )
  ) {
    const lastSegment = formattedPath.split("/").pop() || "";

    dynamicPrimaryKey =
      formattedPath === "/scheduling"
        ? "scheduleId"
        : formattedPath === "/permitdrawings"
        ? "permitId"
        : lastSegment.endsWith("ies")
        ? lastSegment.slice(0, -3) + "yId"
        : lastSegment.endsWith("s")
        ? lastSegment.slice(0, -1) + "Id"
        : lastSegment + "Id";

    customCategoryName =
      formattedPath === "/hrManagement/crews"
        ? "EmployeeCrewReport"
        : formattedPath === "/hrManagement/drivers"
        ? "EmployeeDriversReport"
        : formattedPath === "/hrManagement/architects"
        ? "EmployeeArchitectsReport"
        : formattedPath === "/hrManagement/engineers"
        ? "EmployeeEngineerReport"
        : "";

    recordTable = window.location.pathname.split("/")[2];
  }

  const driveKeys = useMemo(() => {
    const folderIdKey = googleDriveFolderIdKeys[recordTable];

    return {
      key: rowData?.googleDriveFolderIds?.[folderIdKey?.key],
      ...folderIdKey,
    };
  }, [rowData]);

  const missingKeys = findMissingFolderKeys(
    driveKeys.folderName,
    rowData?.googleDriveFolderIds,
    folderList
  );
  const hasMissingFolders = useMemo(() => {
    return missingKeys.length > 0;
  }, [rowData?.googleDriveFolderIds, driveKeys.folderName]);

  //updates the cards on row data change
  useEffect(() => {
    setDataView(overviewData);
  }, []);

  useEffect(() => {
    (async () => {
      if (
        !rowData?.googleDriveFolderIds ||
        keys(rowData?.googleDriveFolderIds).length === 0
      ) {
        setDriveFolder(false);
        callDriveFolderRef.current = 0;
      } else {
        try {
          const driveIdKey = driveKeys?.key;
          const driveFolderId = rowData?.googleDriveFolderIds?.[driveIdKey];
          const driveRes = await driveRequest.getDriveItem(driveFolderId);
          callDriveFolderRef.current = 0;
          if (driveRes?.ok) {
            const res = await driveRes.json();
            setDriveFolder(res?.id || driveFolderId);
            if (hasMissingFolders) {
              callDriveFolderRef.current = 2;
            }
          } else {
            throw new Error("Folder not found");
          }
        } catch (err) {
          callDriveFolderRef.current = 0;
          // console.log("Folder not found: ", err);
          setDriveFolder(false);
        }
      }
    })();
  }, [JSON.stringify(rowData?.googleDriveFolderIds), accessToken]);

  useEffect(() => {
    let urlId = location.pathname.match(uuidRegex)?.[0]?.replace("/", "");
    if (!!hasToDo && !!todoRouteAccess) {
      if (!urlId) {
        urlId = location.pathname.split("/").at(-1);
      }

      filterTables("todos", "todoRecordId", urlId)
        ?.then((res) => {
          setTodos((prev) => ({
            rowData: res || [],
            visible: queryParams.get("todosOpen") === "true" || prev?.visible,
          }));
        })
        ?.catch((err) => console.log(err));
    }
  }, [hasToDo, userConfiguration]);
  /* REPORT FUNCTIONS START*/
  const closeReportModal = () => setVisibleReport(false);
  const openReportModal = () => setVisibleReport(true);

  /* REPORT FUNCTIONS END*/
  useEffect(() => {
    if (changed && Object.keys(columns).length) {
      const CurrentViewArray = Object.keys(columns)
        .map((e) => {
          return columns[e].items.map(({ id, hidden }, index) => ({
            id,
            hidden,
            index,
            position: e,
          }));
        })
        .flat(1);

      Object.keys(columns).length !== 0 &&
        API.patch("preferences", "/preferences", {
          body: {
            preferences: {
              ...pref.preferences,
              views: {
                ...(pref?.preferences?.views || {}),
                [window.location.pathname
                  .split("/")
                  .slice(0, window.location.pathname.split("/").length - 1)
                  .join("/")]:
                  pref?.preferences?.views &&
                  window.location.pathname
                    .split("/")
                    .slice(0, window.location.pathname.split("/").length - 1)
                    .join("/") in pref?.preferences?.views
                    ? pref?.preferences?.views[
                        window.location.pathname
                          .split("/")
                          .slice(
                            0,
                            window.location.pathname.split("/").length - 1
                          )
                          .join("/")
                      ].map(({ id, index, position }) => ({
                        id,
                        hidden: CurrentViewArray.find((e) => e?.id === id)
                          ? false
                          : true,
                        index: CurrentViewArray.find((e) => e?.id === id)
                          ? CurrentViewArray.find((e) => e?.id === id)?.index
                          : index,
                        position: CurrentViewArray.find((e) => e?.id === id)
                          ? CurrentViewArray.find((e) => e?.id === id)?.position
                          : position,
                      }))
                    : overviewData.map(({ id, index, align }) => ({
                        id,
                        hidden: CurrentViewArray.find((e) => e?.id === id)
                          ? false
                          : true,
                        index: CurrentViewArray.find((e) => e?.id === id)
                          ? CurrentViewArray.find((e) => e?.id === id)?.index
                          : index,
                        position: CurrentViewArray.find((e) => e?.id === id)
                          ? CurrentViewArray.find((e) => e?.id === id)?.position
                          : align,
                      })),
              },
            },
          },
        }).then((res) => {
          dispatch(
            preferences({
              ...pref,
              preferences: {
                ...pref.preferences,
                views: {
                  ...(pref?.preferences?.views || {}),
                  [window.location.pathname
                    .split("/")
                    .slice(0, window.location.pathname.split("/").length - 1)
                    .join("/")]: overviewData.map(({ id, index, align }) => ({
                    id,
                    hidden: CurrentViewArray.find((e) => e?.id === id)
                      ? false
                      : true,
                    index: CurrentViewArray.find((e) => e?.id === id)
                      ? CurrentViewArray.find((e) => e?.id === id)?.index
                      : index,
                    position: CurrentViewArray.find((e) => e?.id === id)
                      ? CurrentViewArray.find((e) => e?.id === id)?.position
                      : align,
                  })),
                },
              },
            })
          );
        });
      setChanged(false);
    }
    // !changed && setChanged(true);
  }, [columns]);

  const [dragDisabled, setDragDisabled] = useState(false);

  const buttons = [
    {
      Icon: HiddenIcon,
      label: "Show Hidden Card",
      onClick: () => setVisible(true),
      tooltipKey: "showHiddenCards",
    },
    !isEmpty(notesProps) && {
      Icon: NotesIcon,
      label: "Show Notes",
      onClick: () => setNotes(true),
      tooltipKey: "showNotes",
    },
    rowData?.googleDriveFolderIds &&
      keys(rowData?.googleDriveFolderIds || {}).length !== 0 &&
      keys(googleDriveFolderIdKeys).includes(recordTable) &&
      userConfiguration.departmentName === "Executive" &&
      userConfiguration.groupName === "Admin" && {
        Icon: DriveIcon,
        label: "Edit Drive Access Right",
        onClick: () => setDriveAccess(true),
        tooltipKey: "showGoogleAccess",
      },
    {
      Icon: ReportIcon,
      label: "Report",
      onClick: () => openReportModal(),
      tooltipKey: "showReport",
    },
    // !!hasToDo &&
    //   !!todoRouteAccess && {
    //     Icon: ToDoSideButton,
    //     label: "To Do",
    //     todosCount: todos?.rowData?.length || 0,
    //     onClick: () => setTodos((prev) => ({ ...prev, visible: true })),
    //   },
    !location.pathname.includes("engineersAndArchitects") && {
      Icon:
        callDriveFolderRef?.current === 1
          ? LoadingDots
          : callDriveFolderRef?.current === 2
          ? WarningIcon
          : FolderIcon,
      label:
        callDriveFolderRef?.current === 2
          ? "Update Drive Folders"
          : "Drive Folder",
      className:
        !!driveKeys?.key && !!driveFolder
          ? ""
          : callDriveFolderRef?.current === 0
          ? "mondayButtonRed"
          : "",
      onClick: () =>
        !!driveKeys && !!driveFolder && !hasMissingFolders
          ? window.open(`https://drive.google.com/drive/folders/${driveFolder}`)
          : setAddDriveModal(true),
      tooltipKey: "driveFolder",
    },
    ...sideButtons,
  ].filter(Boolean);

  useEffect(() => {
    if (currentStep < 10 && currentStep > 5) {
      if (
        dataView.find(({ title }) => title === "Status")?.cardParams?.data
          ?.value === "Not Converted"
      ) {
        dataView.find(({ title }) => title === "Status").cardParams.data.value =
          "Converted";
      }
    }
    const disableDrag = (e) => {
      setDragDisabled(e);
    };
    // Added condition if dataView doesnt get the data in time and comes as "[]" and sets columns empty "{}" this makes all the cards hidden
    if (dataView?.length !== 0) {
      setColumns({
        left: {
          items: alignItems({
            overviewData: dataView,
            align: "left",
            dataView,
            setDataView,
            hidden: false,
            getEditedValue,
            currentStep,
            disableDrag,
            setChanged,
          }),
        },
        right: {
          items: alignItems({
            overviewData: dataView,
            align: "right",
            dataView,
            setDataView,
            hidden: false,
            getEditedValue,
            currentStep,
            disableDrag,
            setChanged,
          }),
        },
      });
    }
  }, [JSON.stringify(dataView), currentStep]);

  return (
    <LoadableComp loading={!columns}>
      <div className="OverviewPage">
        {stepperParams && (
          <div className="tabContainer">
            <CardView.AbsoluteStepper {...{ params: stepperParams }} />
          </div>
        )}
        <div className="overviewBody">
          <div className="cardsBody">
            <DragDropContext
              onDragEnd={(result) =>
                onDragEnd(result, columns, setColumns, setChanged)
              }
            >
              {Object.entries(columns).map(([columnId, column]) => (
                <Droppable droppableId={columnId} key={columnId}>
                  {(provided) => (
                    <div
                      className="leftPart"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {column.items.map((item, index) => (
                        <Draggable
                          isDragDisabled={dragDisabled}
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided) => {
                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  display: "flex",
                                  userSelect: "none",
                                  ...provided.draggableProps.style,
                                }}
                              >
                                {item.component}
                              </div>
                            );
                          }}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              ))}
            </DragDropContext>
          </div>
          <div className={`sideBarIcons ${isDarkMode && "sideBarIconsDark"}`}>
            {buttons.map(
              (
                {
                  Icon,
                  label,
                  onClick,
                  className,
                  popover = false,
                  popoverProps = {},
                  tooltipKey,
                  ...rest
                },
                i
              ) => {
                const buttonIcon = (
                  <div
                    className={`hiddenButton ${className}`}
                    onClick={onClick}
                  >
                    <span className="hiddenIcon">
                      <Icon {...{ rest }} />
                    </span>
                  </div>
                );

                return popover ? (
                  Object.keys(popoverProps).filter((el) => popoverProps[el])
                    .length > 0 && (
                    <Popover
                      content={PopoverLinksContent({
                        popoverProps,
                        isDarkMode,
                      })}
                      placement="left"
                      // title={label}
                      trigger="hover"
                      overlayClassName={`popoverLinkOverview ${
                        isDarkMode && "darkPopoverLinkOverview"
                      }`}
                    >
                      <div className={`hiddenButton ${className}`}>
                        <span className="hiddenIcon">
                          <Icon {...{ rest }} />
                        </span>
                      </div>
                    </Popover>
                  )
                ) : !!tooltipKey ? (
                  <WithTooltip tooltipKey={tooltipKey}>
                    {buttonIcon}
                  </WithTooltip>
                ) : (
                  <Tooltip
                    color="white"
                    key={i}
                    placement="left"
                    overlayInnerStyle={{ color: "black" }}
                    title={label}
                    trigger="hover"
                  >
                    {buttonIcon}
                  </Tooltip>
                );
              }
            )}
          </div>
        </div>
        {overviewData && (
          <DrawerComponent
            {...{ visible, setVisible, dataView, setDataView, setChanged }}
          />
        )}
        {!!notesProps && (
          <Notes
            {...{
              ...notesProps,
              notesVisibility: notes,
              setNotesVisibility: setNotes,
            }}
          />
        )}
        {/* {visibleReport && (
          <DynamicReportDesigner
            {...{
              visible: visibleReport,
              onCancel: closeReportModal,
              recordId: rowData[dynamicPrimaryKey],
              setVisibleReport,
              emailBoxes,
              setEmailBoxes,
              setAttachments,
              sendByEmail: true,
            }}
          />
        )} */}
        {visibleReport ? (
          reportEditAccess ? (
            <DynamicReportDesigner
              {...{
                visible: visibleReport,
                onCancel: () => setVisibleReport(false),
                recordId: rowData[dynamicPrimaryKey] || "",
                setVisibleReport,
                sendByEmail: true,
                // customData: [rowData],
                rowData,
              }}
            />
          ) : (
            <Report
              {...{
                isModalVisible: visibleReport,
                onCancel: closeReportModal,
                recordId: rowData[dynamicPrimaryKey],
                setVisibleReport,
                emailBoxes,
                setEmailBoxes,
                setAttachments,
                sendByEmail: true,
                customCategoryName,
              }}
            />
          )
        ) : null}

        {!!hasToDo && !!todos?.visible && (
          <ToDoModal
            {...{
              toDoVisible: todos?.visible,
              setToDoVisible: (e) =>
                setTodos((prev) => ({ ...prev, visible: e })),
              rowData: todos?.rowData,
              recordName: viewTitle,
              onDeleteToDo: (todoId) => {
                setTodos((prev) => ({
                  ...prev,
                  rowData: prev?.rowData?.filter(
                    ({ todoId: id }) => id !== todoId
                  ),
                }));
              },
              onAddTodo: (todo) => {
                setTodos((prev) => ({
                  ...prev,
                  rowData: [...prev?.rowData, todo],
                }));
              },
              onEditTodo: (todo) => {
                setTodos((prev) => ({
                  ...prev,
                  rowData: prev?.rowData?.map((el) =>
                    el?.todoId === todo?.todoId ? todo : el
                  ),
                }));
              },
              isDarkMode,
              // recordId: location?.pathname?.split("/")?.[2],
            }}
          />
        )}
        {driveAccess && (
          <DrivePermission
            viewTitle={viewTitle}
            userConfiguration={userConfiguration}
            rowData={rowData}
            visible={driveAccess}
            setVisible={setDriveAccess}
          />
        )}
        {addDriveModal && (
          <AddDriveFolderModal
            {...{
              open: addDriveModal,
              onCancel: () => setAddDriveModal(false),
              folderData: driveKeys,
              rowData,
              setDriveFolder,
              driveRequest,
              hasMissingFolders:
                hasMissingFolders && callDriveFolderRef?.current === 2,
              callBack: () => {
                callDriveFolderRef.current = 0;
              },
            }}
          />
        )}
        <EmailBoxContainer
          {...{
            emailBoxes,
            rowData,
            setEmailBoxes,
            attachments,
            setAttachments,
            visibleReport,
          }}
        />
      </div>
    </LoadableComp>
  );
};
export default Overview;
