import { Avatar, Form, Modal, Select, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { driveApi } from "../../../../../../../integrations/DriveRequest";
import {
  MondayButton,
  MultiLevelTreeLogs,
} from "../../../../../../commonComponents";
import { AddIcon } from "../../../../../../Header/components/GoogleEvents/assets";
import { state } from "../../../../../../pages/Settings/settingsComponents/Roles/RolesData";
import { TickIcon } from "../../../../../../pages/Settings/settingsComponents/Roles/src";
import { XIcon } from "../../../../../Communication/assets";
import { DeleteIcon } from "../../../../src";
import "./DrivePermission.scss";
import { findProp } from "./utils/utils";
import { InputComponent } from "../../../../../Fleet/components/index";
import { SearchIcon } from "../../../../../Communication/assets";
import WarningModal from "../../../../../../commonComponents/WarningModal/WarningModal";
import { WarningTriangle } from "../../../../src";
import { FolderOutlined, HistoryOutlined } from "@ant-design/icons";
import { getChangedData } from "../../../../../Accounting/components/utilities";
import { v4 as uuidv4 } from "uuid";
import { DropDownArrow } from "../../../../../Fleet/components/InputComponent/assets/index";
import { nameFirstDigits } from "../../../../../../../utils/nameFirstDigits";
import {
  defaultColors,
  fetchAllData,
  updateDocumentTitle,
} from "../../../../../../../utils";
import { useEditLogs } from "../../../../../../../hooks";

const DrivePermission = ({
  viewTitle,
  userConfiguration,
  rowData,
  visible,
  setVisible,
}) => {
  const { accessToken } = useSelector((state) => state.accessToken);
  const driveRequest = driveApi({ accessToken });
  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [folderSelected, setFolderSelected] = useState();
  const [bottom, setBottom] = useState(false);
  const [usersWithAccess, setUsersWithAccess] = useState([]);
  const [oldUsersWithAccess, setOldUsersWithAccess] = useState([]);
  const [form] = Form.useForm();
  const [scrollable, setScrollable] = useState();
  const [userDetailModal, setUserDetailsModal] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [inputVal, setInputVal] = useState("");
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deletedUsers, setDeletedUsers] = useState([]);
  const [changes, setChanges] = useState(false);
  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const [closeWarningVisible, setCloseWarningVisible] = useState(false);
  const [folderChangeWarning, setFolderChangeWarning] = useState(false);
  const [selectedFoldersForAccess, setSelectedFoldersForAccess] = useState([]);
  const [editLogsVisibility, setEditLogsVisibility] = useState(false);
  const [editLogs, setEditLogs] = useState([]);
  const [selectedUser, setSelectedUser] = useState();

  const { saveAddedLogs } = useEditLogs();

  const category = window.location.pathname.split("/")[1];

  const driveFolderKeys = programFields.find(
    ({ fieldName }) => fieldName === "Drive Folder Keys"
  ).fieldOptions;

  const selectedFolderName = driveFolderKeys.find(
    ({ folderKey }) => folderKey === folderSelected
  )?.folderName;

  const rowDataFoldersList = Object.keys(rowData.googleDriveFolderIds).filter(
    (e) => driveFolderKeys.find(({ folderKey }) => folderKey === e)
  );

  let newEditLog = {
    recordId: "",
    recordName: "",
    topic: "",
    actionType: "",
    category: `${rowData.projectName}/Google Drive Access`,
    currentData: {},
    label: "",
    previousData: {},
    updatedKeys: [],
  };

  useEffect(() => {
    document.getElementById("bodyToScroll")?.scrollHeight &&
      setScrollable(document.getElementById("bodyToScroll").scrollHeight > 220);
  }, [document.getElementById("bodyToScroll")?.scrollHeight]);

  useEffect(() => {
    programFields &&
      setFolderSelected(
        Object.keys(rowData.googleDriveFolderIds).filter((e) =>
          driveFolderKeys.find(({ folderKey }) => folderKey === e)
        )[0]
      );
  }, [programFields]);

  useEffect(() => {
    userDetailModal && form.setFieldsValue(userDetailModal);
    setTimeout(() => {
      document.getElementById("userDriveAccess")?.classList?.remove("shake");
    }, 500);
  }, [userDetailModal]);

  useEffect(() => {
    folderSelected &&
      driveRequest
        .permissionList(
          findProp(rowData.googleDriveFolderIds, folderSelected)[0]
        )
        .then((res) =>
          res.json().then((list) => {
            setUsersWithAccess(
              userConfiguration?.allUsers?.Items?.map(
                ({ userName, nameOfUser }) => {
                  const permission = list.permissions?.find(
                    ({ id, role, permissionId, emailAddress }) =>
                      emailAddress === userName
                  );

                  if (!!permission) {
                    return {
                      nameOfUser,
                      userName,
                      permissionId: permission.id,
                      type: permission.role,
                    };
                  }
                }
              )?.filter(Boolean)
            );
            setOldUsersWithAccess(
              userConfiguration?.allUsers?.Items?.map(
                ({ userName, nameOfUser }) => {
                  const permission = list.permissions.find(
                    ({ id, role, emailAddress }) => emailAddress === userName
                  );
                  if (!!permission) {
                    return {
                      nameOfUser,
                      permissionId: permission.id,
                      type: permission.role,
                    };
                  }
                }
              )?.filter(Boolean)
            );
          })
        );
    setSelectedFoldersForAccess([folderSelected]);
  }, [folderSelected]);

  useEffect(() => {
    fetchAllData({
      endpoint: "editLogs",
      resultPosition: "editLogs",
      resultId: "logId",
      otherStringParams: {
        getMaxLimit: "true",
        filters: JSON.stringify([
          {
            conditions: [
              {
                column: "category",
                value: `${rowData.projectName}/Google Drive Access`,
                formula: "is",
              },
            ],
          },
        ]),
      },
    })
      .then((res) => setEditLogs(res))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (userDetailModal === false && selectedFoldersForAccess.length !== 1) {
      setSelectedFoldersForAccess([folderSelected]);
    }
  }, [userDetailModal]);

  const closeModal = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
  };

  return (
    <>
      {visible && (
        <Modal
          key={1}
          open={true}
          // maskStyle={{ zIndex: 950 }}
          centered={true}
          onCancel={() => {
            closeModal();
            changes ? setCloseWarningVisible(true) : setVisible(false);
          }}
          closeIcon={<XIcon />}
          wrapClassName={
            userDetailModal
              ? "googleDriveAccessModalWrap"
              : "googleDriveAccessModalWrapSingle"
          }
          className={
            userDetailModal
              ? `googleDriveAccessModal ${
                  isDarkMode && "googleDriveAccessModalDark"
                }`
              : `googleDriveAccessModalSingle ${
                  isDarkMode && "googleDriveAccessModalSingleDark"
                }`
          }
          title="Google Drive Access"
          afterOpenChange={(event) => {
            event && updateDocumentTitle({ newTitle: "Google Drive Access" });
          }}
          footer={
            <div className="googleDriveAccessModalFooter">
              <MondayButton
                className="cancelFooterClassName"
                onClick={() => {
                  closeModal();
                  changes ? setCloseWarningVisible(true) : setVisible(false);
                }}
                Icon={<XIcon />}
              >
                Cancel
              </MondayButton>
              <MondayButton
                {...{
                  className: "mondayButtonBlue",
                  Icon: <HistoryOutlined width={18} />,
                  onClick: () => setEditLogsVisibility(true),
                }}
              >
                Google Drive Access Logs
              </MondayButton>
              <MondayButton
                disabled={!changes}
                onClick={() => setSaveModalVisible(true)}
                Icon={<TickIcon width={17} height={17} />}
              >
                Save Changes
              </MondayButton>
            </div>
          }
        >
          <div className="googleDriveAccessModalBody">
            <div className="googleDriveAccessModalBodyForlders">
              <div className="googleDriveAccessModalBodyForldersName">
                {Object.keys(rowData.googleDriveFolderIds)
                  .filter((e) =>
                    driveFolderKeys.find(({ folderKey }) => folderKey === e)
                  )
                  .map((el) => (
                    <span
                      onClick={() =>
                        changes
                          ? setFolderChangeWarning(el)
                          : setFolderSelected(el)
                      }
                      className={
                        el === folderSelected
                          ? "foldersOption"
                          : "foldersOptionSelected"
                      }
                    >
                      {
                        driveFolderKeys.find(
                          ({ folderKey }) => folderKey === el
                        ).folderName
                      }
                    </span>
                  ))}
              </div>
              <div className="googleDriveAccessModalBodyAddUser">
                <MondayButton
                  disabled={userDetailModal}
                  onClick={() => setUserDetailsModal({})}
                  Icon={<AddIcon />}
                >
                  Add User
                </MondayButton>
              </div>
            </div>
            <div className="googleDriveFoldersHierarchyWrapper">
              <div className="googleDriveFoldersHierarchy">
                <h3>Google Drive Hierarchy Folders</h3>
                <div className="folders-hierarchy">
                  <div className="folderName">
                    <FolderOutlined />
                    <h3>CORE</h3>
                  </div>

                  <div className="folders-hierarchy">
                    <div className="hierarchy-line-left-bottom" />
                    <div className="folderName">
                      <FolderOutlined />
                      <h3>
                        {category.charAt(0).toUpperCase() + category.slice(1)}
                      </h3>
                    </div>
                    <div className="folders-hierarchy">
                      <div className="hierarchy-line-left-bottom" />
                      <div className="folderName">
                        <FolderOutlined />
                        <h3>{viewTitle}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="usersTable">
                <div className="usersTableSearchContainer">
                  <InputComponent
                    type="input"
                    style={{ width: 300 }}
                    placeholder="Search user..."
                    prefix={<SearchIcon width={20} height={15} />}
                    onChange={(e) => setInputVal(e.target.value)}
                  />
                </div>
                {usersWithAccess.length === 0 ? (
                  <div className="userTableNotFound">
                    <span className="noUserLabel">No user found</span>
                  </div>
                ) : (
                  <Avatar.Group
                    size="large"
                    maxCount={10}
                    maxStyle={{
                      color: "#fff",
                      backgroundColor: "#1264a3",
                    }}
                  >
                    {usersWithAccess
                      ?.filter(
                        (user) =>
                          user.nameOfUser
                            .toLowerCase()
                            .includes(inputVal?.toLowerCase()) ||
                          user.type
                            .toLowerCase()
                            .includes(inputVal?.toLowerCase())
                      )
                      .map((user, i) => {
                        const { nameOfUser, type } = user;
                        return (
                          <Tooltip
                            title={
                              <div>
                                {nameOfUser} : {type.toString().capitalize()}
                              </div>
                            }
                            key={i}
                          >
                            <Avatar
                              style={{
                                color: "#fffff",
                                backgroundColor:
                                  defaultColors[i % defaultColors.length],
                                cursor: "pointer",
                              }}
                              onClick={() => setSelectedUser(user)}
                            >
                              {nameFirstDigits(nameOfUser)}
                            </Avatar>
                          </Tooltip>
                        );
                      })}
                  </Avatar.Group>
                )}

                {selectedUser && (
                  <div>
                    <div className="tableHeader">
                      <span className="headerCell">User</span>
                      <span className="headerCell">Type</span>
                      <span className="headerCell">Details</span>
                    </div>
                    <div id="bodyToScroll" className="usersTableBody">
                      <div className="tableBody">
                        <span className="bodyCell">
                          {selectedUser.nameOfUser}
                        </span>
                        <span className="bodyCell">
                          {selectedUser.type.toString().capitalize()}
                        </span>
                        <span
                          onClick={() => {
                            setUserDetailsModal({
                              permissionId: selectedUser.permissionId,
                              type: selectedUser.type,
                            });
                            setCurrentUser({
                              permissionId: selectedUser.permissionId,
                              type: selectedUser.type,
                              nameOfUser: selectedUser.nameOfUser,
                            });
                            document
                              .getElementById("userDriveAccess")
                              .classList.add("shake");
                          }}
                          className="bodyCellEdit"
                        >
                          Edit Row
                        </span>
                        <span
                          className="bodyCellDelete"
                          onClick={() => {
                            setDeleteModalVisible({
                              permissionId: selectedUser.permissionId,
                              type: selectedUser.type,
                              nameOfUser: selectedUser.nameOfUser,
                            });
                          }}
                        >
                          <DeleteIcon />
                        </span>
                      </div>
                    </div>
                    <WarningModal
                      visible={!!deleteModalVisible}
                      setVisible={setDeleteModalVisible}
                      title="Warning Message"
                      closable={true}
                      className="logout-warning-modal"
                      darkMode={isDarkMode}
                    >
                      <div className="logout-modal-body">
                        <span>
                          <WarningTriangle />
                        </span>
                        <p>
                          Are you sure want to delete{" "}
                          {deleteModalVisible.nameOfUser}?
                        </p>
                        <div className="buttons">
                          <MondayButton
                            Icon={<XIcon />}
                            onClick={() => setDeleteModalVisible(false)}
                            className="mondayButtonRed"
                          >
                            No
                          </MondayButton>
                          <MondayButton
                            onClick={() => {
                              setDeletedUsers((prev) => [
                                ...prev,
                                deleteModalVisible,
                              ]);
                              setDeleteModalVisible(false);
                              setUsersWithAccess((prev) =>
                                prev.filter(
                                  (e) =>
                                    e?.permissionId !==
                                    deleteModalVisible.permissionId
                                )
                              );
                              setChanges(true);
                            }}
                            Icon={<TickIcon />}
                          >
                            Yes
                          </MondayButton>
                        </div>
                      </div>
                    </WarningModal>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
      <Modal
        key={2}
        className={`userDriveAccess ${isDarkMode && "userDriveAccessDark"}`}
        open={userDetailModal !== false}
        // maskStyle={{ zIndex: 990 }}
        mask={false}
        centered
        closeIcon={<XIcon />}
        onCancel={() => {
          form.resetFields();
          setUserDetailsModal(false);
        }}
        wrapClassName="userDriveAccessWrap"
        title={
          currentUser?.nameOfUser
            ? `You're editing ${currentUser?.nameOfUser}`
            : "You're adding a new user"
        }
        footer={
          <div className="userDriveAccessFooter">
            <MondayButton
              Icon={<XIcon />}
              className="cancelFooterClassName"
              onClick={() => {
                form.resetFields();
                setUserDetailsModal(false);
              }}
            >
              Cancel
            </MondayButton>
            <MondayButton
              onClick={() => {
                form.validateFields().then(() => {
                  if (
                    usersWithAccess.find(
                      ({ permissionId }) =>
                        permissionId === form.getFieldValue().permissionId
                    )
                  ) {
                    usersWithAccess.find(
                      ({ permissionId }) =>
                        permissionId === form.getFieldValue("permissionId")
                    ).type = form.getFieldValue("type");
                    setUsersWithAccess(state(usersWithAccess));
                    form.resetFields();
                    setChanges(true);
                    setUserDetailsModal(false);
                  } else {
                    setUsersWithAccess((prev) => [
                      ...prev,
                      {
                        nameOfUser: userConfiguration.allUsers?.Items?.find(
                          ({ permissionId }) =>
                            permissionId === form.getFieldValue("permissionId")
                        ).nameOfUser,
                        permissionId: form.getFieldValue("permissionId"),
                        type: form.getFieldValue("type"),
                      },
                    ]);
                    form.resetFields();
                    setChanges(true);
                    setUserDetailsModal(false);
                  }
                });
              }}
              Icon={<TickIcon width={17} height={17} />}
            >
              Save Changes
            </MondayButton>
          </div>
        }
      >
        <div className="userDriveAccessBody" id="userDriveAccess">
          <Form form={form} layout="vertical">
            <Form.Item
              name="permissionId"
              label="User Name"
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Select
                showSearch
                suffixIcon={<DropDownArrow />}
                popupClassName={isDarkMode && "darkDropDown"}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onSelect={(e) => {
                  setCurrentUser(
                    userConfiguration.allUsers?.Items?.find(
                      ({ permissionId }) => e === permissionId
                    )
                  );
                  if (
                    usersWithAccess.find(
                      ({ permissionId }) => permissionId === e
                    )
                  ) {
                    form.setFieldsValue({
                      permissionId: e,
                      type: usersWithAccess.find(
                        ({ permissionId }) => permissionId === e
                      ).type,
                    });
                  } else {
                    form.setFieldsValue({
                      permissionId: e,
                      type: undefined,
                    });
                  }
                }}
                className="userDriveAccessBodyInput"
                placeholder="User Name"
              >
                {userConfiguration.allUsers?.Items?.filter(
                  (user) => !!user?.permissionId?.length
                )
                  .filter(
                    (user) =>
                      !usersWithAccess.find(
                        (uwa) =>
                          uwa.permissionId === user.permissionId &&
                          uwa.nameOfUser !== currentUser?.nameOfUser
                      )
                  )
                  .map(({ nameOfUser, permissionId, identityId }) => (
                    <Select.Option value={permissionId} key={identityId}>
                      {nameOfUser}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="type"
              label="Permission Type"
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Select
                className="userDriveAccessBodyInput"
                placeholder="Permission Type"
                popupClassName={isDarkMode && "darkDropDown"}
                suffixIcon={<DropDownArrow />}
              >
                <Select.Option value="writer">Writer</Select.Option>
                <Select.Option value="reader">Reader</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="folder"
              label="Select folders to add access"
              rules={[
                {
                  required: !selectedFoldersForAccess.length && true,
                  message: "This field is required!",
                },
              ]}
            >
              <InputComponent
                className="folderSelector"
                type="select"
                mode="multiple"
                placeholder="Select folders for access..."
                dropdownClassName={isDarkMode && "darkDropDown"}
                suffixIcon={<DropDownArrow />}
                allowClear={false}
                value={folderSelected}
                defaultValue={{
                  value: folderSelected,
                  label: selectedFolderName,
                }}
                onSelect={(e) =>
                  setSelectedFoldersForAccess((prev) => [...prev, e])
                }
                onDeselect={(e) => {
                  setSelectedFoldersForAccess(
                    selectedFoldersForAccess.filter((folder) => folder !== e)
                  );
                }}
                options={rowDataFoldersList
                  .filter(
                    (folder) => !selectedFoldersForAccess.includes(folder)
                  )
                  .map((folder) => (
                    <Select.Option value={folder}>
                      {
                        driveFolderKeys.find(
                          ({ folderKey }) => folderKey === folder
                        ).folderName
                      }
                    </Select.Option>
                  ))}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <WarningModal
        visible={saveModalVisible}
        setVisible={setSaveModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningTriangle />
          </span>
          <p>Are you sure want to save this changes ?</p>
          <div className="buttons">
            <MondayButton
              Icon={<XIcon />}
              onClick={() => setSaveModalVisible(false)}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton
              onClick={() => {
                oldUsersWithAccess.forEach((oldUser) => {
                  usersWithAccess.forEach((user) => {
                    if (
                      user.permissionId === oldUser.permissionId &&
                      user.type !== oldUser.type
                    ) {
                      let result = getChangedData(user, oldUser);
                      selectedFoldersForAccess.forEach((folder) => {
                        driveRequest.updatePermission(
                          findProp(rowData.googleDriveFolderIds, folder)[0],
                          user.type,
                          user.permissionId
                        );
                        // Get the actual FolderName
                        let folderName = driveFolderKeys.find(
                          ({ folderKey }) => folderKey === folder
                        ).folderName;

                        newEditLog.recordId = uuidv4();
                        newEditLog.recordName = rowData.projectName;
                        newEditLog.actionType = "Change Access Type";
                        newEditLog.currentData = {
                          [folderName]: {
                            [user.nameOfUser]: result.curr,
                            dummyKey: 0,
                          },
                        };
                        newEditLog.previousData = {
                          [folderName]: {
                            [user.nameOfUser]: result.prev,
                            dummyKey: 0,
                          },
                        };

                        setEditLogs((prev) => [...prev, newEditLog]);
                        saveAddedLogs(newEditLog);
                      });
                    }
                  });
                });
                // On Adding access on a user
                if (oldUsersWithAccess.length < usersWithAccess.length) {
                  const neededNr =
                    usersWithAccess.length - oldUsersWithAccess.length;
                  const listOfNewAccessUsers = usersWithAccess.slice(-neededNr);
                  listOfNewAccessUsers.forEach((user) => {
                    let result = getChangedData(
                      { name: user.nameOfUser, premissionType: user.type },
                      {}
                    );
                    selectedFoldersForAccess.forEach((folder) => {
                      driveRequest.share(
                        findProp(rowData.googleDriveFolderIds, folder)[0],
                        user.type,
                        "user",
                        userConfiguration.allUsers?.Items?.find(
                          ({ permissionId }) =>
                            permissionId === user.permissionId
                        ).userName,
                        true // this boolean is used to stop e-mail notification
                      );

                      // Get the actual FolderName
                      let folderName = driveFolderKeys.find(
                        ({ folderKey }) => folderKey === folder
                      ).folderName;

                      newEditLog.recordId = uuidv4();
                      newEditLog.recordName = rowData.projectName;
                      newEditLog.actionType = "Grant Access";
                      newEditLog.currentData = { [folderName]: result.curr };
                      newEditLog.previousData = { [folderName]: result.prev };

                      setEditLogs((prev) => [...prev, newEditLog]);
                      saveAddedLogs(newEditLog);
                    });
                  });
                }
                // On Removing acces on a user
                if (usersWithAccess.length < oldUsersWithAccess.length) {
                  deletedUsers.forEach((user) => {
                    let result = getChangedData(
                      {},
                      { name: user.nameOfUser, premissionType: user.type }
                    );
                    driveRequest.deletePermission(
                      findProp(rowData.googleDriveFolderIds, folderSelected)[0],
                      user.permissionId
                    );

                    newEditLog.recordId = uuidv4();
                    newEditLog.recordName = rowData.projectName;
                    newEditLog.actionType = "Remove Access";
                    newEditLog.currentData = {
                      [selectedFolderName]: result.curr,
                    };
                    newEditLog.previousData = {
                      [selectedFolderName]: result.prev,
                    };

                    setEditLogs((prev) => [...prev, newEditLog]);
                    saveAddedLogs(newEditLog);
                  });
                }
                closeModal();
                setVisible(false);
                setDeletedUsers([]);
                setChanges(false);
              }}
              Icon={<TickIcon />}
            >
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>

      <WarningModal
        visible={closeWarningVisible}
        setVisible={setCloseWarningVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningTriangle />
          </span>
          <p>You have unsaved changes, are you sure you want to exit?</p>
          <div className="buttons">
            <MondayButton
              Icon={<XIcon />}
              onClick={() => {
                closeModal();
                setCloseWarningVisible(false);
              }}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton
              onClick={() => {
                setCloseWarningVisible(false);
                closeModal();
                setVisible(false);
                setDeletedUsers([]);
              }}
              Icon={<TickIcon />}
            >
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>

      <WarningModal
        visible={!!folderChangeWarning}
        setVisible={setFolderChangeWarning}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningTriangle />
          </span>
          <p>
            You have unsaved changes on this field, are you sure you want to
            change fields?
          </p>
          <div className="buttons">
            <MondayButton
              Icon={<XIcon />}
              onClick={() => setFolderChangeWarning(false)}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton
              onClick={() => {
                setFolderChangeWarning(false);
                setFolderSelected(folderChangeWarning);
                setDeletedUsers([]);
                setChanges(false);
              }}
              Icon={<TickIcon width={17} height={17} />}
            >
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
      {editLogsVisibility && (
        <MultiLevelTreeLogs
          {...{
            visible: editLogsVisibility,
            setVisible: setEditLogsVisibility,
            logsData: editLogs || [],
            title: "Google Drive Access Logs",
          }}
        />
      )}
    </>
  );
};
export default DrivePermission;
