import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Checkbox, Modal, message } from "antd";
import React, { useState } from "react";
import { MondayButton } from "../../../../../../../../../commonComponents";
import FileItem from "../file-item/FileItem";

import { useSelector } from "react-redux";
import { driveApi } from "../../../../../../../../../../integrations/DriveRequest";
import { _CREATE_HEADERS } from "../../../../../../../../../../integrations/DriveRequest/driveStatics";
import { blobToBase64 } from "../../../../../../../../utils";
import { getLength } from "../../../../../../../functions";
import { Buffer } from "buffer";
import { showLoadingMsg } from "../../../../../../../../../../utils";

import { useDocumentationTypes } from "../../../../../providers/DocumentationTypesProvider";
import { useAttachments } from "../../../../../providers/AttachmentsProvider";
import { useFilePreviewModal } from "../../../../../providers/FilePreviewModalProvider";

import "./doc-types-files-modal.scss";

function DocTypesFilesModal({
  isDocTypesModalOpen,
  setIsDocTypesModalOpen,
  files,
}) {
  const [selectedDocFiles, setSelectedDocFiles] = useState([]);
  const { accessToken } = useSelector((state) => state.accessToken);
  const driveRequest = driveApi({ accessToken });
  const { setSelectedFile, setFilePreview } = useFilePreviewModal();
  const { attachments, setAttachments } = useAttachments();
  const [loading, setLoading] = useState(false);

  const { setDocumentationTypesFiles } = useDocumentationTypes(); // Function to set documentation types files

  // Function to attach selected files to the system
  const handleAttachFiles = async (selectedDocFiles) => {
    setLoading(true);
    try {
      // Fetch file data and convert to base64 for each selected file
      const newFilesData = await Promise.all(
        selectedDocFiles.map(async (file) => {
          let src;

          // Get the file from the drive using its ID
          const response = await driveRequest.getDriveItem(file.id, {
            alt: "media",
          });

          const blob = await response.blob(); // Convert the file to a blob object
          await blobToBase64(blob).then((res) => (src = res)); // Convert blob to base64

          return {
            name: file.name,
            blob: src,
            size: getLength(Buffer.from(src.substring(src.indexOf(",") + 1))), // Calculate file size
            type: blob.type,
            mimeType: file.mimeType || blob.type,
            id: file.id,
            status: 400,
          };
        })
      );

      // Filter out invalid files (null entries)
      const validNewFilesData = newFilesData.filter((file) => file !== null);

      // Add valid files to the attachments state
      setAttachments((prevUploadedFiles) => {
        return [...prevUploadedFiles, ...validNewFilesData];
      });

      // Add valid files to the documentation types files state
      setDocumentationTypesFiles((prev) => {
        return [...prev, ...validNewFilesData];
      });

      setIsDocTypesModalOpen(false); // Close the modal after attaching files
    } catch (error) {
      console.error("Error fetching file data:", error);
    } finally {
      setLoading(false); // Set loading state to false after file processing
    }
  };

  // Function to handle file item click for preview
  const handleFileItemClick = async (file) => {
    showLoadingMsg({
      content: "File is being prepared preview. Please wait...", // Show a loading message while preparing file preview
    });

    try {
      let src;
      // Fetch the file from the drive
      const response = await driveRequest.getDriveItem(file.id, {
        alt: "media",
      });
      const blob = await response.blob(); // Convert the file to a blob
      await blobToBase64(blob).then((res) => (src = res)); // Convert blob to base64

      // Set the file for preview with its base64 source and metadata
      setSelectedFile({
        src,
        mimeType: blob?.type,
        name: file?.name,
      });

      setFilePreview(true); // Open the file preview modal
      message.destroy(); // Clear loading message
    } catch (error) {
      message.error("File preparation for review failed"); // Show error message if preview fails
    }
  };

  return (
    <Modal
      className="doc-types-files-modal"
      open={isDocTypesModalOpen}
      onCancel={() => setIsDocTypesModalOpen(false)}
      title="Documentation Types"
      footer={
        <div className="doc-types-files-modal-footer-container">
          <MondayButton
            className="mondayButtonRed"
            onClick={() => {
              setIsDocTypesModalOpen(false);
            }}
            Icon={<CloseOutlined />}
            data-testid="doc-types-files-modal-cancel-button"
          >
            Cancel
          </MondayButton>
          {selectedDocFiles.length > 0 &&
            !selectedDocFiles.every((file) =>
              attachments.some((uploadedFile) => uploadedFile.id === file.id)
            ) && (
              <MondayButton
                className="mondayButtonBlue"
                Icon={<CheckOutlined />}
                onClick={() => handleAttachFiles(selectedDocFiles)}
                loading={loading}
                disabled={loading}
              >
                Attach Files
              </MondayButton>
            )}
        </div>
      }
      width={450}
      centered
    >
      <div className="doc-types-files-modal-body-container">
        {files?.map((file) => (
          <>
            <Checkbox
              defaultChecked={attachments.some(
                (uploadedFile) => uploadedFile.id === file.id
              )}
              disabled={attachments.some(
                (uploadedFile) => uploadedFile.id === file.id
              )}
              onChange={(e) => {
                setSelectedDocFiles((prevState) => {
                  if (e.target.checked) {
                    return [...prevState, file];
                  } else {
                    return prevState.filter(
                      (selectedFile) => selectedFile.id !== file.id
                    );
                  }
                });
              }}
              data-testid={`file-item-checkbox-${file?.id}`}
            />
            <FileItem file={file} onClick={() => handleFileItemClick(file)} />
          </>
        ))}
      </div>
    </Modal>
  );
}

export default DocTypesFilesModal;
