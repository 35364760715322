import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { driveApi } from "../../../../../../integrations/DriveRequest";

const useDynamicFieldsData = (rowData, recordDetails) => {
  // Manage state for loading status, dynamic fields, and drive items
  const [loading, setLoading] = useState(true);
  const [allDynamicFields, setAllDynamicFields] = useState([]);
  const [dynamicFields, setDynamicFields] = useState([]);
  const [driveItems, setDriveItems] = useState([]);

  // Get accessToken from Redux store for API requests
  const { accessToken } = useSelector((state) => state.accessToken);
  const driveRequest = driveApi({ accessToken });

  // Recursive function to retrieve items from Google Drive by folderId
  const getDriveItems = async (folderId, folderName, itemsList) => {
    const res = await driveRequest.getFilesByFolderId(folderId);
    const { files } = await res.json();

    // Separate folders and files
    const folders = [];
    const items = [];

    for (const { mimeType, id, name } of files || []) {
      if (mimeType === "application/vnd.google-apps.folder") {
        folders.push({ id, name });
      } else {
        items.push({
          folder: folderName,
          folderId,
          id,
          name,
          type: "Item",
        });
      }
    }

    // Add files to itemsList immediately
    itemsList.push(...items);

    // Fetch all folders in parallel
    await Promise.all(
      folders.map(async ({ id, name }) => {
        await getDriveItems(id, name, itemsList);
        itemsList.push({
          folder: folderName,
          folderId,
          id,
          name,
          type: "Folder",
        });
      })
    );

    return itemsList;
  };

  // Trigger data fetching on component mount
  useEffect(() => {
    fetchData();
  }, []);

  // Fetch dynamic fields and drive items
  const fetchData = async () => {
    try {
      // Fetch dynamic fields data from the API
      const res = await API.get("dynamicFields", "/dynamicFields");
      setAllDynamicFields(res);

      const documents = [];

      // Filter dynamic fields for "documentation" and "single" render types
      const documentsFields = filterDynamicFields(
        res,
        recordDetails?.categoryType,
        "documentation"
      );
      const singleFields = filterDynamicFields(
        res,
        recordDetails?.categoryType,
        "single"
      );

      // For each document field, fetch associated Google Drive items
      for await (let {
        dynamicFieldValue,
        dynamicFieldLabel,
      } of documentsFields) {
        const folderId =
          recordDetails?.categoryType !== "inspections"
            ? rowData?.googleDriveFolderIds &&
              rowData?.googleDriveFolderIds[dynamicFieldValue]
            : rowData?.inspectionFolderId;
        const folderName =
          recordDetails?.categoryType !== "inspections"
            ? dynamicFieldLabel
            : rowData[dynamicFieldValue];

        if (folderId && folderName) {
          // Fetch drive items for the folder
          documents.push(
            await getDriveItems(folderId, folderName, documents).then((res) =>
              Promise.all(res.flat(1))
            )
          );
        }
      }

      // Update state with fetched drive items and dynamic fields
      setDriveItems(documents.flat(1));
      setDynamicFields([...documentsFields, ...singleFields]);
    } catch (error) {
      console.error("Error fetching dynamic fields:", error);
    } finally {
      // Set loading to false once data is fetched
      setLoading(false);
    }
  };

  // Filter dynamic fields based on categoryType and renderFor value
  function filterDynamicFields(allDynamicFields, categoryType, renderFor) {
    const allDynamicFieldsFiltered = allDynamicFields.filter(
      ({ dynamicFieldCategory, dynamicFieldRenderFor }) =>
        dynamicFieldCategory.toLowerCase() === categoryType &&
        dynamicFieldRenderFor === renderFor
    );

    return allDynamicFieldsFiltered;
  }

  // Return the relevant data and state to the component using this hook
  return {
    loading,
    allDynamicFields,
    driveItems,
    setDriveItems,
    dynamicFields,
  };
};

export default useDynamicFieldsData;
