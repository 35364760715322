import React, { useEffect, useState } from "react";
import { Checkbox, Modal, Tooltip, message } from "antd";
import "./PdfProjectOverview.scss";
import { filterTables, lazyFetch } from "../../../../utils";
import { MondayButton } from "../../../commonComponents";
import { typeOfWorkColors } from "../../../Header/forms/Scheduling/DataAgGrid/columnDefs";
import moment from "moment-timezone";
import { CollapseArrow, PdfWhiteIcon } from "../../../../assets";
import { DynamicTable } from "../../../commonComponents/DynamicTable/DynamicTable";
import columnDefs from "./datas/columnDefs";
import { pdfExportFunc } from "../../../../utils/exportToPdf";
import { XIcon } from "../../Communication/assets/index";
import { ProgressStatistics } from "../../Scheduling/helpers/totals";
import { get_ProgressDimensionsByServiceId } from "../../../Header/forms/DataEntryGrid/tools/columnDefinitions/ProgressColumnDefinition";
import {
  calcData,
  onlyEstWithScopeFalse,
  progressInPerc,
  scheduleArray,
} from "../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { quickStatistics_Schedule_and_Project } from "../../Scheduling/helpers/quickStatistics";
import { checkIfServiceIsHoist } from "../../Estimations/DataEntryGrid/models/Service";
import { useSelector } from "react-redux";

const PdfProjectPreview = ({
  previewVisibile,
  setPreviewVisible,
  services = [],
  schedule = [],
  setSchedule,
  rowData,
  userConfiguration,
  projectAccountName,
  date = "",
  setDate,
  darkMode = false,
  estimations = [],
}) => {
  const exportToPdf = () => {
    pdfExportFunc(
      "pdf-project-modal",
      "",
      "Schedule Overview",
      "schedulePreviewPDF"
    );
  };

  const collapseData = (estimationId, typeOfWork) => {
    const services =
      document.querySelectorAll(`#service-${estimationId}-${typeOfWork}`) || [];
    services.forEach((service) => {
      service.style.display = service.style.display === "none" ? "" : "none";
    });
  };

  const totalDataForEst = (element = "", data, type = "") => {
    let total = 0;
    if (element === "estimate") {
      if (type === "services") {
        total = data?.length || 0;
      } else if (type === "elevations") {
        data?.forEach((a) => {
          total += a?.serviceOptions?.[0]?.length || 0;
        });
      } else if (type === "plis") {
        data?.forEach((a) => {
          a?.serviceOptions?.[0]?.forEach((b) => {
            total += b?.items?.length || 0;
          });
        });
      }
    } else if (element === "service") {
      if (type === "elevations") {
        total = data?.serviceOptions?.[0]?.length || 0;
      } else if (type === "plis") {
        data?.serviceOptions?.[0]?.forEach((a) => {
          total += a?.items?.length || 0;
        });
      }
    } else if (element === "elevation") {
      if (type === "plis") {
        total = data?.items?.length || 0;
      }
    }

    return total;
  };

  const totalEst = (element = "", services, type = "") => {
    let total = 0;
    if (element === "estimate") {
      services?.forEach((a) => {
        a?.serviceOptions?.[0]?.forEach?.((b) => {
          const plis = b?.items?.map((p) => ({
            ...p,
            [type]: p?.[type] || p?.originalPli?.[type],
          }));
          total += calcData(plis, type);
        });
      });
    } else {
      services?.serviceOptions?.[0]?.forEach?.((b) => {
        const plis = b?.items?.map((p) => ({
          ...p,
          [type]: p?.[type] || p?.originalPli?.[type],
        }));
        total += calcData(plis, type);
      });
    }
    return total || 0;
  };

  const remainingSchedule = schedule?.map?.((el) => {
    return {
      progress: el?.totalScheduleProgress || 0,
      typeOfWork: el?.typeOfWork || "",
    };
  });

  const getDetails = () => {
    return Object.entries(
      schedule?.reduce?.((acc, el) => {
        return { ...acc, [el?.scheduleId]: el };
      }, {})
    )?.map?.(([key, value], i) => {
      const estimateData = onlyEstWithScopeFalse(value?.toBeScheduled) || {};
      return {
        [i]: Object.entries(estimateData)?.flatMap?.(
          ([estId, services], index) => {
            const estimateLabel =
              "Estimate " +
              (services?.[0]?.quickbooksNumber ||
                services?.[0]?.estimationNumber ||
                index + 1);
            let isFirstService = true;
            return services?.flatMap?.((service) => {
              const isHoist = checkIfServiceIsHoist(service);
              const { d1, d2 } = get_ProgressDimensionsByServiceId(
                service?.serviceId
              );
              const { label = "", serviceOptions = [] } = service || {};
              const elevations = serviceOptions?.[0] || [];
              const output = [];
              if (isFirstService) {
                output.push({
                  estimate: {
                    value: estimateLabel,
                    onClick: () => collapseData(estId, value?.typeOfWork),
                    style: {
                      paddingLeft: 5,
                      width: 100,
                      fontWeight: 600,
                      cursor: "pointer",
                      border: "1px solid #DDE3E5",
                    },
                  },

                  service: {
                    // value: `${totalDataForEst(
                    //   "estimate",
                    //   services,
                    //   "services"
                    // )} Scope of Work`,
                    style: {
                      border: "1px solid #DDE3E5",
                      // paddingLeft: 5,
                      // width: 150,
                    },
                  },
                  elevation: {
                    // value: `${totalDataForEst(
                    //   "estimate",
                    //   services,
                    //   "elevations"
                    // )} Elevation`,
                    style: {
                      border: "1px solid #DDE3E5",
                      // paddingLeft: 5,
                      // width: 150,
                    },
                  },
                  pli: {
                    // value: `${totalDataForEst("estimate", services, "plis")} ${
                    //   isHoist ? "Floor" : "PLI"
                    // }`,
                    style: {
                      border: "1px solid #DDE3E5",
                      // paddingLeft: 5,
                      // width: 80,
                    },
                  },
                  l: {
                    style: {
                      border: "1px solid #DDE3E5",
                      // paddingLeft: 5,
                      // width: 60,
                    },
                  },
                  h: {
                    style: {
                      border: "1px solid #DDE3E5",
                      // paddingLeft: 5,
                      // width: 60,
                    },
                  },
                  w: {
                    style: {
                      border: "1px solid #DDE3E5",
                      // paddingLeft: 5,
                      // width: 60,
                    },
                  },

                  sqf: {
                    style: {
                      border: "1px solid #DDE3E5",
                      // paddingLeft: 5,
                      // width: 60,
                    },
                  },
                  item: {
                    style: {
                      border: "1px solid #DDE3E5",
                      // paddingLeft: 5,
                      // width: 80,
                    },
                  },
                  remainInstall: {
                    style: {
                      border: "1px solid #DDE3E5",
                      // paddingLeft: 5,
                      // width: 80,
                    },
                  },
                  itemPerc: {
                    value:
                      ProgressStatistics.estimationTotal(services)
                        ?.progressPercentage,
                    style: {
                      paddingLeft: 5,
                      width: 80,
                      border: "1px solid #DDE3E5",
                    },
                  },
                  remainInstallPerc: {
                    value:
                      100 -
                      ProgressStatistics.estimationTotal(services)
                        ?.progressPercentage,
                    style: {
                      paddingLeft: 5,
                      width: 170,
                      border: "1px solid #DDE3E5",
                    },
                  },
                });
                isFirstService = false;
              }
              output.push({
                estimate: {
                  id: `service-${estId}-${value?.typeOfWork}`,
                  style: {
                    border: "1px solid #DDE3E5",
                  },
                },
                service: {
                  value: label,
                  id: `service-${estId}-${value?.typeOfWork}`,
                  style: {
                    paddingLeft: 5,
                    width: 150,
                    border: "1px solid #DDE3E5",
                  },
                },
                elevation: {
                  id: `service-${estId}-${value?.typeOfWork}`,
                  style: {
                    border: "1px solid #DDE3E5",
                  },
                },
                pli: {
                  id: `service-${estId}-${value?.typeOfWork}`,
                  style: {
                    border: "1px solid #DDE3E5",
                  },
                },
                // elevation: {
                //   value: `${totalDataForEst(
                //     "service",
                //     service,
                //     "elevations"
                //   )} Elevation`,
                //   style: {
                //     border: "1px solid #DDE3E5",
                //     paddingLeft: 5,
                //     width: 150,
                //   },
                // },
                // pli: {
                //   value: `${totalDataForEst("service", service, "plis")} ${
                //     isHoist ? "Floor" : "PLI"
                //   }`,
                //   style: {
                //     border: "1px solid #DDE3E5",
                //     paddingLeft: 5,
                //     width: 80,
                //   },
                // },
                l: {
                  id: `service-${estId}-${value?.typeOfWork}`,
                  value: !isHoist ? totalEst("service", service, "length") : "",
                  style: {
                    paddingLeft: 5,
                    width: 60,
                    border: "1px solid #DDE3E5",
                  },
                },
                w: {
                  id: `service-${estId}-${value?.typeOfWork}`,
                  value: !isHoist ? totalEst("service", service, "width") : "",
                  style: {
                    paddingLeft: 5,
                    width: 60,
                    border: "1px solid #DDE3E5",
                  },
                },
                h: {
                  id: `service-${estId}-${value?.typeOfWork}`,
                  value: !isHoist ? totalEst("service", service, "height") : "",
                  style: {
                    paddingLeft: 5,
                    width: 60,
                    border: "1px solid #DDE3E5",
                  },
                },
                sqf: {
                  id: `service-${estId}-${value?.typeOfWork}`,
                  value: !isHoist ? totalEst("service", service, "sqft") : "",
                  style: {
                    paddingLeft: 5,
                    width: 60,
                    border: "1px solid #DDE3E5",
                  },
                },
                item: {
                  id: `service-${estId}-${value?.typeOfWork}`,
                  value: !isHoist
                    ? ProgressStatistics.serviceTotal(service)?.progress
                    : "",
                  style: {
                    paddingLeft: 5,
                    width: 80,
                    border: "1px solid #DDE3E5",
                  },
                },
                remainInstall: {
                  id: `service-${estId}-${value?.typeOfWork}`,
                  value: !isHoist
                    ? ProgressStatistics.serviceTotal(service)?.remaining
                    : "",
                  style: {
                    paddingLeft: 5,
                    width: 80,
                    border: "1px solid #DDE3E5",
                  },
                },
                itemPerc: {
                  id: `service-${estId}-${value?.typeOfWork}`,
                  value:
                    ProgressStatistics.serviceTotal(service)
                      ?.progressPercentage,
                  style: {
                    paddingLeft: 5,
                    width: 80,
                    border: "1px solid #DDE3E5",
                  },
                },
                remainInstallPerc: {
                  id: `service-${estId}-${value?.typeOfWork}`,
                  value:
                    ProgressStatistics.serviceTotal(service)
                      ?.remainingPercentage,
                  style: {
                    paddingLeft: 5,
                    border: "1px solid #DDE3E5",
                    width: 80,
                  },
                },
              });
              // if (!isFirstService) {
              //   output.push({
              //     estimate: {
              //       id: `service-${estId}-${value?.typeOfWork}`,
              //     },
              //   });
              // }
              output.push(
                ...elevations.flatMap((elevation) => {
                  const { items = [] } = elevation;
                  return [
                    {
                      estimate: {
                        id: `service-${estId}-${value?.typeOfWork}`,
                        style: {
                          border: "1px solid #DDE3E5",
                        },
                      },
                      service: {
                        id: `service-${estId}-${value?.typeOfWork}`,
                        style: {
                          border: "1px solid #DDE3E5",
                        },
                      },
                      elevation: {
                        value:
                          elevation.elevationLabel?.trim() +
                          " - " +
                          elevation.elevationId?.trim(),
                        id: `service-${estId}-${value?.typeOfWork}`,
                        style: {
                          paddingLeft: 5,
                          width: 300,
                          fontWeight: 600,
                          border: "1px solid #DDE3E5",
                        },
                      },
                      pli: {
                        id: `service-${estId}-${value?.typeOfWork}`,
                        style: {
                          border: "1px solid #DDE3E5",
                        },
                      },
                      // pli: {
                      //   value: `${totalDataForEst(
                      //     "elevation",
                      //     elevation,
                      //     "plis"
                      //   )} ${isHoist ? "Floor" : "PLI"}`,
                      //   style: {
                      //     border: "1px solid #DDE3E5",
                      //     paddingLeft: 5,
                      //     width: 80,
                      //   },
                      // },
                      l: {
                        id: `service-${estId}-${value?.typeOfWork}`,
                        value: !isHoist
                          ? calcData(
                              elevation?.items?.map((item) => ({
                                ...item,
                                length:
                                  item?.length ||
                                  item?.originalPli?.length ||
                                  0,
                              })),
                              "length"
                            )
                          : "",
                        style: {
                          paddingLeft: 5,
                          border: "1px solid #DDE3E5",
                        },
                      },
                      h: {
                        id: `service-${estId}-${value?.typeOfWork}`,
                        value: !isHoist
                          ? calcData(
                              elevation?.items?.map((item) => ({
                                ...item,
                                height:
                                  item?.height ||
                                  item?.originalPli?.height ||
                                  0,
                              })),
                              "height"
                            )
                          : "",
                        style: {
                          paddingLeft: 5,
                          border: "1px solid #DDE3E5",
                        },
                      },
                      w: {
                        id: `service-${estId}-${value?.typeOfWork}`,
                        value: !isHoist
                          ? calcData(
                              elevation?.items?.map((item) => ({
                                ...item,
                                width:
                                  item?.width || item?.originalPli?.width || 0,
                              })),
                              "width"
                            )
                          : "",
                        style: {
                          paddingLeft: 5,
                          border: "1px solid #DDE3E5",
                        },
                      },
                      sqf: {
                        id: `service-${estId}-${value?.typeOfWork}`,
                        value: !isHoist
                          ? elevation?.items?.reduce?.(
                              (prev, el) => prev + (el?.originalPli?.sqft || 0),
                              0
                            ) || 0
                          : "",
                        style: {
                          paddingLeft: 5,
                          border: "1px solid #DDE3E5",
                        },
                      },
                      item: {
                        id: `service-${estId}-${value?.typeOfWork}`,
                        value: !isHoist
                          ? ProgressStatistics.elevationTotal2(
                              elevation,
                              service?.serviceId
                            )?.progress
                          : "",
                        style: {
                          paddingLeft: 5,
                          border: "1px solid #DDE3E5",
                        },
                      },
                      remainInstall: {
                        id: `service-${estId}-${value?.typeOfWork}`,
                        value: !isHoist
                          ? ProgressStatistics.elevationTotal2(
                              elevation,
                              service?.serviceId
                            )?.remaining
                          : "",
                        style: {
                          paddingLeft: 5,
                          border: "1px solid #DDE3E5",
                        },
                      },
                      itemPerc: {
                        id: `service-${estId}-${value?.typeOfWork}`,
                        value: ProgressStatistics.elevationTotal2(
                          elevation,
                          service?.serviceId
                        )?.progressPercentage,
                        style: {
                          paddingLeft: 5,
                          width: 80,
                          border: "1px solid #DDE3E5",
                        },
                      },
                      remainInstallPerc: {
                        id: `service-${estId}-${value?.typeOfWork}`,
                        value: ProgressStatistics.elevationTotal2(
                          elevation,
                          service?.serviceId
                        )?.remainingPercentage,
                        style: {
                          paddingLeft: 5,
                          border: "1px solid #DDE3E5",
                        },
                      },
                    },
                    ...items?.map?.((pli, pliIndex) => ({
                      estimate: {
                        id: `service-${estId}-${value?.typeOfWork}`,
                        style: {
                          border: "1px solid #DDE3E5",
                        },
                      },
                      service: {
                        id: `service-${estId}-${value?.typeOfWork}`,
                        style: {
                          border: "1px solid #DDE3E5",
                        },
                      },
                      elevation: {
                        id: `service-${estId}-${value?.typeOfWork}`,
                        style: {
                          border: "1px solid #DDE3E5",
                        },
                      },
                      pli: {
                        id: `service-${estId}-${value?.typeOfWork}`,
                        value: isHoist
                          ? `Floor ${pli?.floor || pliIndex + 1}`
                          : "Pli" + " " + pli?.id || pliIndex + 1,
                        style: {
                          paddingLeft: 5,
                          width: 50,
                          border: "1px solid #DDE3E5",
                        },
                      },
                      l: {
                        id: `service-${estId}-${value?.typeOfWork}`,
                        value: pli?.length || pli?.originalPli?.length,
                        style: {
                          paddingLeft: 5,
                          border: "1px solid #DDE3E5",
                        },
                      },
                      h: {
                        id: `service-${estId}-${value?.typeOfWork}`,
                        value: pli?.height || pli?.originalPli?.height,
                        style: {
                          paddingLeft: 5,
                          border: "1px solid #DDE3E5",
                        },
                      },
                      w: {
                        id: `service-${estId}-${value?.typeOfWork}`,
                        value: pli?.width || pli?.originalPli?.width,
                        style: {
                          paddingLeft: 5,
                          border: "1px solid #DDE3E5",
                        },
                      },
                      sqf: {
                        id: `service-${estId}-${value?.typeOfWork}`,
                        value:
                          pli?.surface ||
                          pli?.sqft ||
                          pli?.originalPli?.surface ||
                          pli?.originalPli?.sqft,
                        style: {
                          paddingLeft: 5,
                          border: "1px solid #DDE3E5",
                        },
                      },
                      item: {
                        id: `service-${estId}-${value?.typeOfWork}`,
                        value: !isHoist ? pli?.totalProgress : "",
                        style: {
                          paddingLeft: 5,
                          border: "1px solid #DDE3E5",
                        },
                      },
                      remainInstall: {
                        id: `service-${estId}-${value?.typeOfWork}`,
                        value: !isHoist
                          ? !d2
                            ? pli?.[d1] - pli?.totalProgress
                            : pli?.[d1] * pli?.[d2] - pli?.totalProgress
                          : "",
                        style: {
                          paddingLeft: 5,
                          border: "1px solid #DDE3E5",
                        },
                      },
                      itemPerc: {
                        id: `service-${estId}-${value?.typeOfWork}`,
                        value: progressInPerc(pli?.totalProgress, d1, d2, pli),
                        style: {
                          paddingLeft: 5,
                          width: 80,
                          border: "1px solid #DDE3E5",
                        },
                      },
                      remainInstallPerc: {
                        id: `service-${estId}-${value?.typeOfWork}`,
                        value:
                          100 - progressInPerc(pli?.totalProgress, d1, d2, pli),
                        style: {
                          paddingLeft: 5,
                          border: "1px solid #DDE3E5",
                        },
                      },
                    })),
                  ];
                })
              );
              return output;
            });
          }
        ),
      };
    });
  };

  useEffect(() => {
    const getDefaultSchedule = async (rowData) => {
      const schedules = await filterTables("scheduling", "projectId", rowData);
      message.destroy();
      if (schedules?.length === 0) {
        message.info("No schedules found for this project!", 5);
        // setPreviewVisible(false);
        return;
      }
      message.destroy();
      return schedules;
    };
    const getStartDateFromSchedule = (schedules) => {
      let toReturn = {};
      schedules?.forEach?.((el) => {
        const scheduleDays = el?.scheduleDays?.sort(
          (a, b) => moment(a?.createdAt) - moment(b?.createdAt)
        );
        const startDate = moment(scheduleDays?.[0]?.startDate).format(
          "MM/DD/YYYY"
        );
        toReturn[el?.typeOfWork] = startDate;
      });
      return toReturn;
    };
    const getMatchingSchedules = (schedules) => {
      const checkIfAllSchHaveSameTypeOfWork = schedules?.every(
        (el) => el?.typeOfWork === schedules?.[0]?.typeOfWork
      );
      if (checkIfAllSchHaveSameTypeOfWork) {
        const sortedSchedules = schedules?.sort(
          (a, b) => moment(a?.createdAt) - moment(b?.createdAt)
        );
        return [sortedSchedules?.[sortedSchedules?.length - 1]];
      } else {
        const schedulesByTypeOfWork = schedules?.reduce((prev, el) => {
          const sortSchWsameToW = schedules
            ?.filter((a) => a?.typeOfWork === el?.typeOfWork)
            ?.sort((a, b) => moment(a?.createdAt) - moment(b?.createdAt));

          return {
            ...prev,
            [el?.typeOfWork]:
              sortSchWsameToW?.length === 1
                ? sortSchWsameToW
                : sortSchWsameToW?.[sortSchWsameToW?.length - 1],
          };
        }, {});
        return Object.values(schedulesByTypeOfWork || {}).flat();
      }
    };
    const fetchData = async () => {
      if (!!rowData) {
        message.loading("Loading Schedule Overview", 0);
        const schedules = await getDefaultSchedule(rowData);
        const startDate = getStartDateFromSchedule(schedules);
        const defaultSchedules = getMatchingSchedules(schedules);
        setSchedule(defaultSchedules);
        setDate(startDate);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <Modal
        className={`pdf-project-overview${darkMode ? "_dark" : ""}`}
        centered
        open={previewVisibile}
        title="Schedule Overview"
        onCancel={() => setPreviewVisible(false)}
        closeIcon={<XIcon />}
        maskClosable={false}
        closable={true}
        width={1880}
        footer={
          null
          // <div className="ft-btns-sch-pdf">
          //   <MondayButton
          //     onClick={exportToPdf}
          //     {...{
          //       Icon: <PdfWhiteIcon />,
          //       className: "mondayButtonBlue",
          //     }}
          //   >
          //     Export to PDF
          //   </MondayButton>
          // </div>
        }
      >
        <>
          <div
            className="pdf-project-modal"
            id="pdf-project-modal"
            style={{
              maxHeight: schedule?.length > 1 ? 720 : "",
              overflow: schedule?.length > 1 ? "auto" : "",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              // width: "calc(100vw - 90px)",
            }}
          >
            {scheduleArray(
              schedule,
              userConfiguration,
              projectAccountName,
              remainingSchedule,
              date
            )?.map?.((el, i) => {
              const dateOfSeen = moment().format("MMMM Do YYYY");
              return Object.keys(el)?.map((x, index) => (
                <div key={index + i}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{
                        color: typeOfWorkColors?.[x],
                        fontWeight: 600,
                      }}
                    >
                      {x}
                    </span>
                    <span
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      {dateOfSeen}
                    </span>
                  </div>
                  <div className="pdf-project-modal-table-header">
                    {Object.values(el || {})?.reduce((prev, y, i) => {
                      return [
                        ...prev,
                        ...(y?.map((z, index) => {
                          return (
                            <div
                              className="pdf-project-modal-table-header-item"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                              key={index}
                            >
                              <span style={{ fontWeight: 600 }}>
                                {z?.value}
                              </span>
                              <span>{z?.label}</span>
                            </div>
                          );
                        }) || []),
                      ];
                    }, [])}
                  </div>
                  <div
                    style={{
                      maxHeight: schedule?.length > 1 ? "" : 720,
                      overflow: schedule?.length > 1 ? "" : "auto",
                    }}
                  >
                    <DynamicTable
                      {...{
                        columnDefs: columnDefs(x),
                        rowData: getDetails()?.[i]?.[i] || [],
                        tableStyle: {
                          width: "100%",
                          border: "1px solid #DDE3E5",
                          borderCollapse: "collapse",
                        },
                        tableHeaderStyle: {
                          border: "1px solid #DDE3E5",
                          background:
                            x === "Installation"
                              ? "#0f5c97"
                              : typeOfWorkColors?.[x],
                          paddingLeft: 5,
                          color: "#fff",
                        },
                        tableHeaderTr: {
                          textAlign: "left",
                        },
                      }}
                    />
                  </div>
                </div>
              ));
            })}
          </div>
        </>
      </Modal>
    </div>
  );
};

export default PdfProjectPreview;
