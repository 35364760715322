import { Alert, Flex } from "antd";
import Forecasting from "../../../../../SidebarPages/Projects/Tabs/Forecasting/Forecasting";
import "./ForecastingStep.scss";

const ForecastingStep = ({
  defaultData,
  handleSavedRecord,
  setVisible,
  currentStep,
  setCurrentStep,
  filteredSteps,
}) => {
  return (
    <Flex className="ForecastingStepContainer">
      <Alert
        type="warning"
        message="This step content has its own save Changes Button in the footer!"
      />

      <Forecasting
        rowData={defaultData}
        setRowData={(data) => {
          handleSavedRecord(data);
          currentStep === filteredSteps?.length - 1
            ? setVisible(false)
            : setCurrentStep((prev) => prev + 1);
        }}
      />
    </Flex>
  );
};

export default ForecastingStep;
