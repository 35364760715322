import { message, Modal } from "antd";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import { filterTables } from "../../../../../../../utils/filterTables";
import { useProgramFields, useResponsive } from "../../../../../../../hooks";
import { ReportViewer } from "../../../../../Reports/components";
import { attachTemplate, populateReport } from "../../../../../Reports/utils";
import { uuidRegex } from "../../../../../Documentation/View/documentationViewData";
import { SmallLoadableComp } from "../../../../../../Sidebars/components";
import { Core, PdfExport } from "@grapecity/activereports";
import { blobToBase64 } from "../../../../../utils";
import { useEmailBoxContainer } from "../../../../../Communication/components/EmailBox/providers/EmailBoxContainerProvider";
import { XIcon } from "../../../../../Communication/assets";

import "./Report.scss";

const FIELD_ID = "354gfdgd-5345bcbf-fgjfhjgfh";

const Report = ({
  isModalVisible,
  onCancel,
  recordId,
  customCategoryName = "",
  customPartitionKeys = "",
  customData = null,
  customTemplate,
  setVisibleReport = () => {},
  sendByEmail = false,
  manualPath,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { openEmailBox, setAttachments, emailBoxes, closeEmailBox } =
    useEmailBoxContainer();

  const viewerRef = useRef();
  let location = useLocation();

  const { accessToken } = useSelector((state) => state.accessToken);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { programFields } = useSelector((state) => state.programFields);
  const [docDefinition, setDocDefinition] = useState();
  const formattedPath = location.pathname.replace(uuidRegex, "");
  const { sampleObjects } = useSelector((state) => state.sampleObjects);

  const { ["Reports Integration Config"]: reportsIntegrationConfig } =
    useProgramFields();

  const reportTemplates = programFields
    ?.find((field) => field.fieldId === FIELD_ID)
    ?.fieldOptions.filter((template) => template.defaultTemplate);

  const [report, setReport] = useState();

  const categoryParameters = [];
  const activeFilters = {};

  const { categoryName, partitionKeys } = useMemo(
    () =>
      manualPath
        ? reportsIntegrationConfig?.[manualPath]
        : reportsIntegrationConfig?.[formattedPath] || {},
    [reportsIntegrationConfig, location, manualPath]
  );

  const exportsSettings = {
    title: report?.reportName,
    filename: report?.reportName,
    author: report?.createdBy,
    printing: "highResolution",
    pdfVersion: "1.5",
  };

  const getReport = () => {
    if (!customTemplate)
      filterTables(
        "reportsAvailable",
        "reportName",
        `${customCategoryName || categoryName + "Report"}`
      )
        .then((res) => {
          setReport(res[0]);
        })
        .catch((error) => console.log("Error fetching data: ", error));
    else {
      setReport(customTemplate);
    }
  };

  useEffect(() => {
    getReport();
  }, []);

  const newReport = useMemo(
    () => attachTemplate(report, reportTemplates),
    [report]
  );

  useEffect(() => {
    (async () => {
      try {
        if (!newReport || !accessToken) return;
        setIsLoading(true);
        if (!!accessToken && !!newReport) {
          const { definition } = await populateReport({
            report: newReport,
            categoryParameters,
            accessToken,
            activeFilters,
            recordId,
            partitionKeys: customPartitionKeys || partitionKeys,
            customData,
            sampleObjects,
          });

          if (sendByEmail) {
            const pdfExportSettings = {
              title: "Test document",
              author: "GrapeCity",
              keywords: "export, report",
              subject: "Report",
              pdfVersion: "1.4",
            };

            const openReport = new Core.PageReport();
            await openReport.load(definition);
            const doc = await openReport.run();
            const result = await PdfExport.exportDocument(
              doc,
              pdfExportSettings
            );

            URL.createObjectURL(result.data);
            await blobToBase64(result.data).then((res) => {
              setDocDefinition({ res, size: result?.data.size });
            });
          }

          viewerRef.current.Viewer.open(definition);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error opening report", error);
        message.error("Error opening report");
        setIsLoading(false);
      }
    })();
  }, [newReport, accessToken, viewerRef]);

  const { mobile } = useResponsive();

  useEffect(() => {
    if (!viewerRef.current) return;
    if (!sendByEmail) return;

    let sendToEmailButton = {
      key: "$sendToEmailButton",
      iconCssClass: "mdi mdi-email",
      // text: "Send to email",
      enabled: !isLoading,
      action: async function (item) {
        message.info("Uploading the attachments...");

        if (emailBoxes.length > 0) {
          await closeEmailBox(0);
        }

        setAttachments(() => [
          {
            type: "application/pdf",
            name: `${report?.reportName}.pdf`,
            blob: docDefinition?.res,
            size: docDefinition?.size,
          },
        ]);

        openEmailBox(null, mobile);
        setVisibleReport(false);
      },
    };
    viewerRef.current.Viewer.toolbar.addItem(sendToEmailButton);
  }, [isLoading, docDefinition]);

  return (
    <Modal
      {...{
        open: isModalVisible,
        onCancel: onCancel,
        title: report?.reportName || "Report Viewer",
        footer: false,
        height: "85vh",
        width: "90vw",
        centered: true,
        closeIcon: <XIcon />,
        className: `report-modalZh ${isDarkMode ? "report-modalZh-dark" : ""}`,
      }}
      data-testid="report-modal"
    >
      <SmallLoadableComp loading={isLoading} />
      <div style={{ height: "75vh" }}>
        <ReportViewer ref={viewerRef} exportsSettings={exportsSettings} />
      </div>
    </Modal>
  );
};

export default Report;
