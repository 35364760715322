import { Avatar, Divider, Modal, Tooltip } from "antd";
import { repeatelem } from "../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { defaultColors, getInitials } from "../../../utils";
import { useSelector } from "react-redux";
import { useGetImageSrc } from "../../../hooks";
import {
  Fragment,
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import "./DynamicAvatars.scss";
import { XIcon } from "../../SidebarPages/Communication/assets";
import MondayButton from "../MondayButton/MondayButton";
import { dayjsNY } from "../../DateComponents/contants/DayjsNY";
import { useDispatch } from "react-redux";
import { driveApi } from "../../../integrations/DriveRequest";
import { updateUserImages } from "../../../actions";

/**
 * Renders a group of dynamic avatars based on the provided members data.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.members - The array of members to display avatars for.
 * Array of objects with the following properties:
 *  @property {string} [props.members.nameOfUser] - The name of the member.
 *  @property {string} [props.members.identityId] - The identity ID of the member.
 * | OR | Array of strings with the member's name.
 * @param {string} [props.className="profile-photos-container"] - The CSS class name for the avatar group container.
 * @param {number} [props.maxCount=5] - The maximum number of avatars to display.
 * @param {Object} [props.groupStyle={}] - The CSS styles for the avatar group container.
 * @param {Object} [props.avatarStyle={}] - The CSS styles for each avatar.
 * @param {string|boolean} [props.nameKey=false] - The key to access the member's name in the members array.
 * @param {string|boolean} [props.idKey=false] - The key to access the member's identity ID in the members array.
 * @param {string|boolean} [props.customTooltip=false] - The key for adding custom Tooltip.
 * @param {boolean} [props.fetchPhoto=true] - Determines whether to fetch the member's photo.
 * @returns {JSX.Element} The rendered DynamicAvatars component.
 */

export const DynamicAvatars = ({
  members = [],
  crew = {},
  className = "profile-photos-container",
  maxCount = 5,
  size,
  groupStyle = {},
  avatarStyle = {},
  title,
  nameKey = false,
  idKey = false,
  fetchPhoto = true,
  customTooltip = false,
  isDarkMode,
  todaySchedules,
}) => {
  const { isDarkMode: isDarkModeSelector } = useSelector(
    (state) => state.darkMode
  );

  const finalDarkMode = isDarkMode ?? isDarkModeSelector;

  const [avatarModal, setAvatarModal] = useState(false);
  const avatarRef = useRef(null);

  const openModal = useCallback(() => {
    setAvatarModal(true);
  }, []);

  useEffect(() => {
    if (!avatarRef.current) {
      return;
    }

    try {
      const avatars =
        avatarRef.current.getElementsByClassName("ant-avatar-circle");

      if (avatars.length < 6) {
        return;
      }

      avatars.item(5).addEventListener("mousedown", openModal);
    } catch (err) {
      console.log("Error setting click handler: ", err);
    }
  }, [members, crew]);

  const removeDuplicates = members.reduce((acc, member) => {
    if (typeof member === "string") {
      if (!acc[member]) {
        acc[member] = member;
      }
      return acc;
    }
    const identityId = member?.[idKey] || member?.identityId;
    if (!acc[identityId]) {
      acc[identityId] = member;
    }
    return acc;
  }, []);

  const uniqueMembers = Object.values(removeDuplicates);

  const sameAuthorColor = uniqueMembers?.reduce((acc, prev, index) => {
    return {
      ...acc,
      [typeof prev === "object" ? prev?.[nameKey] || prev?.nameOfUser : prev]:
        repeatelem(defaultColors, members?.length)[index],
    };
  }, {});

  return (
    <Fragment>
      <div className="dynamicAvatarsDiv" ref={avatarRef}>
        <Avatar.Group
          className={className}
          max={{
            count: maxCount,
            popover: {
              trigger: "none",
            },
            style: {
              color: "#323338",
              backgroundColor: "#f8f8fa",
              ...groupStyle,
            },
          }}
        >
          {uniqueMembers?.map((member, i) => {
            const memberName =
              typeof member === "object"
                ? member?.[nameKey] || member?.nameOfUser
                : member;

            return (
              <Tooltip title={memberName} key={`${memberName}${i}`}>
                <Avatar
                  key={`avatar-${memberName}${i}`}
                  shape="circle"
                  src={fetchPhoto && <ImageCustom name={memberName} />}
                  className="dynamicAvatar"
                  style={{
                    // pointerEvents: "none",
                    backgroundColor: `${sameAuthorColor?.[memberName]}`,
                    // color: "#323338",
                    color: "#fff",
                    width: `${size || 35}px`,
                    height: `${size || 35}px`,
                    fontSize: "12px",
                    fontWeight: 600,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid white",
                    gap: "5px",
                    ...avatarStyle,
                  }}
                >
                  {getInitials(memberName)}
                </Avatar>
              </Tooltip>
            );
          })}
        </Avatar.Group>
      </div>
      <Modal
        title={title}
        open={avatarModal}
        className={`dynamicAvatarsModal ${
          finalDarkMode && "dynamicAvatarsModalDark"
        }`}
        closeIcon={<XIcon />}
        closable={true}
        centered={true}
        destroyOnClose={false}
        footer={
          <MondayButton
            className="mondayButtonRed"
            Icon={<XIcon />}
            onClick={() => {
              setAvatarModal(false);
            }}
          >
            Close
          </MondayButton>
        }
        onCancel={() => {
          setAvatarModal(false);
        }}
      >
        {Object.keys(crew || {}).length ? (
          <div className="dynamicAvatarHeader-container">
            <span className="dynamicAvatarModal-name">{crew?.crewName}</span>
            <div className="dynamicAvatarDiv">
              <div className="dynamicAvatar-item">
                <span className="dynamicAvatarLength">
                  {members?.length} members
                </span>
                <Divider
                  style={{ height: 15, borderRight: "2px solid #dee2eb" }}
                  type="vertical"
                />
                <div className="dynamicAvatarHeader">
                  <span className="dynamicAvatarForeman">Foreman:</span>
                  <span>{crew?.crewName}</span>
                </div>
              </div>

              <div className="dynamicAvatarData">
                <div>
                  <span className="dynamicAvatar-created">Created At: </span>
                  <span>{dayjsNY(crew?.createdAt).format("MM/DD/YYYY")}</span>
                </div>

                <Divider
                  style={{ height: 15, borderRight: "2px solid #dee2eb" }}
                  type="vertical"
                />
                <div>
                  <span className="dynamicAvatar-created">Created By: </span>
                  <span>
                    {crew?.createdBy?.name
                      ? crew.createdBy.name
                      : crew?.createdBy?.nameOfUser}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="dynamicAvatarsModalDiv">
          {uniqueMembers?.map((member, i) => {
            const memberName =
              typeof member === "object"
                ? member?.[nameKey] || member?.nameOfUser
                : member;

            return (
              <div className="dynamicAvatarsItem" key={`${memberName}${i}`}>
                <Tooltip
                  title={customTooltip ? member?.tooltip || "" : memberName}
                >
                  <Avatar
                    shape="circle"
                    src={fetchPhoto && <ImageCustom name={memberName} />}
                    gap={4}
                    style={{
                      backgroundColor: `${
                        finalDarkMode ? "#2a2b3a" : "#F8F8FA"
                      }`,
                      color: finalDarkMode ? "#fff" : "#323338",
                      width: `${size || 60}px`,
                      height: `${size || 60}px`,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "5px",
                      fontWeight: 600,
                      fontSize: "24px",
                    }}
                  >
                    {getInitials(memberName)}
                  </Avatar>
                </Tooltip>
                <div className="dynamicAvatarLabel">
                  <span className="dynamicAvatarName">{member?.crewName}</span>
                  <span className="dynamicAvatarName">
                    {member?.nameOfUser}
                  </span>
                  <span className="dynamicAvatarName">{member?.name}</span>
                  <span className="dynamicAvatarRole">
                    {crew?.crewPosition}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
    </Fragment>
  );
};

const ImageCustom = ({ src = "", style = {}, className = "", name }) => {
  const { imageSrc, handleGetImageSrc, houseKeeping } = useGetImageSrc();
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { accessToken } = useSelector((state) => state.accessToken);

  const isValidImage = (imgSrc) => {
    if (!imgSrc) return false;
    const image = new Image();
    image.src = imgSrc;
    return image.complete && image.naturalWidth > 0;
  };

  useEffect(() => {
    const user = userConfiguration?.allUsers?.Items?.find?.(
      (el) => el?.nameOfUser === name
    );

    if (
      user &&
      !!user?.googleDriveFileId &&
      !!user?.googleDriveFileId !== "" &&
      accessToken
    ) {
      handleGetImageSrc(user?.googleDriveFileId);
    }

    return () => houseKeeping();
  }, [src, userConfiguration, accessToken]);

  return !!imageSrc && isValidImage(imageSrc) ? (
    <img src={imageSrc} alt={""} style={style} className={className} />
  ) : (
    <div className="dynamicAvatar-name">{getInitials(name)}</div>
  );
};

function ImageCustom2({ user }) {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const [currentFile, setCurrentFile] = useState({});

  const { userImages } = useSelector((state) => state.userImages);
  const { accessToken } = useSelector((state) => state.accessToken);
  const dispatch = useDispatch();
  const { getImageSrc } = driveApi({ accessToken });
  const allUsers = userConfiguration?.allUsers?.Items || [];
  const allUsersObj = useMemo(() => {
    if (!allUsers) return {};
    return allUsers?.reduce((acc, user) => {
      acc[user.cognitoUserId] = user;
      return acc;
    }, {});
  }, [userConfiguration]);

  const currentUserFinder = () => {
    if (typeof user === "string") {
      return allUsers?.find((el) => el.nameOfUser === user);
    } else if (typeof user === "object" && user?.cognitoUserId) {
      return allUsersObj[user.cognitoUserId];
    }
  };

  useEffect(() => {
    const updateCurrentFile = async () => {
      if (!accessToken) {
        setCurrentFile(null);
        return;
      }

      const currUser = currentUserFinder();
      if (!currUser) {
        setCurrentFile(null);
        return;
      }

      const userImage = userImages?.[currUser.cognitoUserId];
      if (userImage) {
        setCurrentFile({ src: userImage });
        return;
      }

      if (!currUser.googleDriveFileId) {
        setCurrentFile(null);
        return;
      }

      try {
        const image = await getImageSrc(currUser.googleDriveFileId);
        setCurrentFile({ ...image });
        dispatch(
          updateUserImages({
            [currUser.cognitoUserId]: image.src,
          })
        );
      } catch (err) {
        console.error("Error getting image src: ", { err, user: currUser });
        setCurrentFile(null);
      }
    };

    updateCurrentFile();
  }, [accessToken, userImages, dispatch]);

  return currentFile?.src ? (
    <img src={currentFile?.src} alt={""} />
  ) : (
    <div className="dynamicAvatar-name">
      {getInitials(currentUserFinder()?.nameOfUser)}
    </div>
  );
}
