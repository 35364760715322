import "./Styles.scss";
import { Flex, Modal } from "antd";

import Communication from "../../SidebarPages/Communication/Communication";
import { XIcon } from "../../SidebarPages/Communication/assets";
import MondayButton from "../MondayButton/MondayButton";

const EmailsModal = (props) => {
  const { title = "Emails", open, setOpen, recordId, categoryName } = props;

  return (
    <Modal
      className="emails-modal"
      title={title}
      open={open}
      onCancel={() => setOpen(false)}
      closeIcon={<XIcon />}
      footer={() => (
        <Flex justify="flex-end">
          <MondayButton
            data-testid="close-email-modal"
            key="close"
            className="mondayButtonRed"
            onClick={() => setOpen(false)}
            Icon={<XIcon />}
            buttonStyle={{ width: 150 }}
          >
            Close
          </MondayButton>
        </Flex>
      )}
    >
      <Communication recordId={recordId} categoryName={categoryName} />
    </Modal>
  );
};

export default EmailsModal;
