import { fetchAllData } from "../ApiMethods";

// optimized report fetch data
export const getRentalComparisonWithAccounting = async ({
  keysToInclude,
  reportUsedKeys,
}) => {
  const finalKeysToInclude = [
    ...new Set([
      "rentalId",
      "projectId",
      "rentalNumber",
      ...keysToInclude,
      ...(reportUsedKeys.includes("services")
        ? ["projectTaxRate", "rentalNumber", "rentalStatus", "createdAt"]
        : []),
    ]),
  ];

  let [rentalsRes, invoicesRes, paymentsRes] = await Promise.all([
    fetchAllData({
      endpoint: "rentals",
      resultId: "rentalId",
      otherStringParams: { keysToInclude: JSON.stringify(finalKeysToInclude) },
    }),
    fetchAllData({
      endpoint: "invoices",
      resultId: "invoiceId",
      otherStringParams: {
        keysToInclude: JSON.stringify([
          "invoiceId",
          "projectId",
          "categoriesFrom",
          "invoiceItems",
          "invoiceNumber",
        ]),
      },
    }),
    fetchAllData({
      endpoint: "payments",
      resultId: "paymentId",
      otherStringParams: {
        keysToInclude: JSON.stringify(["paymentId", "payments", "invoices"]),
      },
    }),
  ]);

  const result = rentalsRes.map((rental) => {
    const invoices = invoicesRes
      .filter(
        (invoice) =>
          invoice.projectId === rental.projectId &&
          invoice.categoriesFrom.includes("rentals")
      )
      .map((invoice) => {
        return {
          ...invoice,
          invoiceItems: invoice.invoiceItems.filter(
            (item) =>
              item.category === "rentals" &&
              item.group === `Rental ${rental.rentalNumber}`
          ),
        };
      });

    const payments = paymentsRes.filter((payment) =>
      payment.payments.some((paymentDetail) =>
        invoices.some(
          (invoice) =>
            invoice.invoiceItems.length > 0 &&
            invoice.invoiceNumber === paymentDetail.invoiceNumber
        )
      )
    );

    return {
      ...rental,
      services: rental.services.map((service) => {
        const totalServiceAmount = service.serviceOptions?.reduce(
          (acc, optionGroup) => {
            return (
              acc +
              optionGroup?.items?.reduce((innerAcc, item) => {
                return (
                  innerAcc +
                  (item.appliedAmount +
                    item.appliedAmount * (rental.projectTaxRate || 0))
                );
              }, 0)
            );
          },
          0
        );

        return {
          rentalNumber: rental.rentalNumber,
          rentalId: rental.rentalId,
          rentalStatus: rental.rentalStatus,
          serviceName: service.label,
          rentPercentage: service.rentPercentage,
          retainage: service.retainage,
          createdAt: rental.createdAt,
          serviceId: service.serviceId,
          totalServiceRentalAmount: totalServiceAmount,
          totalInvoice: invoices.reduce((sum, invoice) => {
            return (
              sum +
              invoice.invoiceItems
                .filter((item) => item.name === service.label)
                .reduce((itemSum, item) => {
                  return itemSum + item.total;
                }, 0)
            );
          }, 0),
          totalPaid: payments.reduce((sum, payment) => {
            return (
              sum +
              payment.invoices.reduce((itemSum, item) => {
                return (
                  itemSum +
                  totalServiceAmount *
                    ((item?.invoicePaidPercentage || 0) / 100)
                );
              }, 0)
            );
          }, 0),
        };
      }),
    };
  });

  return result;
};
