import { message } from "antd";
import { API } from "aws-amplify";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import React, { useState, useEffect, useContext, useMemo } from "react";

import {
  columnDefs,
  getGridFilters,
  chartingOptions,
  pinnedBottomRowData,
} from "./AgGridData";
import PayrollContext from "../../PayrollContext";
import { useEditLogs } from "../../../../../hooks";
import PayrollLayout from "../../Layout/PayrollLayout";
import { LoadableComp } from "../../../../SidebarPages/XComponents";
import { DownloadIcon } from "../../../../SidebarPages/BasePage/src";
import { Tick } from "../../../Settings/settingsComponents/Roles/src";
import { XIcon } from "../../../../SidebarPages/Communication/assets";
import { MondayButton, WarningModal } from "../../../../commonComponents";
import { WarningTriangle } from "../../../../SidebarPages/DynamicView/src";
import broadcastNotification from "../../../../../helpers/controllers/broadcastNotification";
import { UpdateCrew } from "../../../Settings/settingsComponents/Crews/Components/UpdateCrew/UpdateCrew";
import DeleteEmployeeModal from "../../../../SidebarPages/HrManagement/Pages/Employee/components/DeleteEmployeeModal";
import UploadEmployeesModal from "../../../Settings/settingsComponents/Crews/Components/UploadEmployees/UploadEmployeesModal";
import UpdateEmployeeModal from "../../../../SidebarPages/HrManagement/Pages/Employee/components/UpdateEmployee/UpdateEmployeeModal";

import "../../Payroll.scss";

function EmployeesTab({ accessRight }) {
  const [{ userConfiguration }, { isDarkMode }, { programFields }] =
    useSelector((state) => [
      state.userConfig,
      state.darkMode,
      state.programFields,
    ]);

  const {
    jobsites,
    crewTeams,
    employeesList,
    salaryAccess = {},
    isFromHrManagement = false,
  } = useContext(PayrollContext);

  const [gridApi, setGridApi] = useState();
  const [loading, setLoading] = useState(true);
  const [rowToEdit, setRowToEdit] = useState(false);
  const [rowData, setRowData] = useState(employeesList);
  const [rowToDelete, setRowToDelete] = useState(false);
  const [uploadVisible, setUploadVisible] = useState(false);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);

  const { saveAddedLogs } = useEditLogs();

  const paginationPageSize = Math.floor((window.innerHeight - 357) / 38);

  const getCrews = async () => {
    return await API.get("crews", "/crews")
      .then((res) => {
        setRowData(res);
      })
      .catch((e) => {
        console.error(e);
        message.error("Error while getting crews.");
      });
  };

  function recordClicked(rowParams) {
    setRowToEdit(rowParams);
    setUpdateModalVisible(true);
  }

  function handleUpdateModalVisible() {
    setUpdateModalVisible((prevState) => !prevState);
    setRowToEdit(null);
  }

  const deleteCrew = async (id) => {
    message.loading({ content: "Deleting Employee...", duration: 0, key: "1" });
    const selectedForeman = rowData?.find(
      (res) => res?.crewId === rowToDelete?.foreman
    );

    if (selectedForeman) {
      selectedForeman.members = selectedForeman?.members?.filter((val) => {
        return val?.crewId !== id;
      });
    }

    return await API.del("crews", `/crews/${id}`)
      .then(async () => {
        setRowData(rowData?.filter((el) => el?.crewId !== id));
        if (selectedForeman) {
          await API.patch("crews", `/crews/${selectedForeman?.crewId}`, {
            body: {
              members: selectedForeman?.members || [],
            },
          });
        }
      })
      .then(() => {
        broadcastNotification(
          "20",
          "onCrewDeletion",
          [
            {
              commonNext: rowToDelete?.crewName,
              common: userConfiguration.nameOfUser,
            },
            {
              userName: userConfiguration.nameOfUser,
              currentUser: userConfiguration.cognitoUserId,
            },
          ],
          ""
        );
        saveAddedLogs({
          category: "Crews",
          actionType: "Delete",
          recordId: rowToDelete?.crewId,
          recordName: rowToDelete?.crewName,
        });

        setRowToDelete(false);
        message.success({
          content: `${rowToDelete?.crewName} deleted successfully`,
          key: "1",
        });
      })
      .catch((e) => {
        console.error(e);
        message.error({ content: "Something went wrong!", key: "1" });
      });
  };

  const crewStatuses = useMemo(() => {
    const fieldIndex = programFields.findIndex(
      (field) => field?.fieldName === "Crew Status"
    );
    const statuses = programFields?.[fieldIndex]?.fieldOptions || [];
    return statuses;
  }, [programFields]);

  const gridFilters = useMemo(() => {
    return getGridFilters(isFromHrManagement);
  }, [isFromHrManagement]);

  const columns = useMemo(() => {
    return columnDefs({
      isDarkMode,
      crewStatuses,
      salaryAccess,
      recordClicked,
      setRowToDelete,
      isFromHrManagement,
      writeAccess: accessRight?.write,
      columnAccess: accessRight?.children || [],
    });
  }, [
    isDarkMode,
    accessRight,
    crewStatuses,
    salaryAccess,
    recordClicked,
    setRowToDelete,
    isFromHrManagement,
  ]);

  useEffect(() => {
    if (employeesList?.length && loading) {
      setLoading(false);
      setRowData(employeesList);
    }
  }, [employeesList, loading]);

  return (
    <>
      <LoadableComp
        loading={loading || !rowData?.length}
        componentTestId={"employees-tab-loading"}
      >
        <></>
      </LoadableComp>
      <PayrollLayout
        {...{
          rowData,
          gridFilters,
          chartingOptions,
          title: "Employees",
          paginationPageSize,
          isFromHrManagement,
          dataIdKey: "crewId",
          columnDefs: columns,
          getGridApi: setGridApi,
          hasNew: accessRight?.write,
          suppressDoubleClickEdit: true,
          additionalActionsButtons: accessRight.write
            ? [
                <MondayButton
                  key={"upload-btn"}
                  Icon={<DownloadIcon />}
                  className="mondayButtonBlue"
                  data-testid="employees-upload-btn"
                  disabled={true}
                  onClick={() => setUploadVisible(true)}
                >
                  Upload
                </MondayButton>,
              ]
            : [],
          context: {
            crewTeams,
          },
          pinnedBottomRowData:
            isFromHrManagement && pinnedBottomRowData(rowData),
          additionalGridProps: {
            paginationAutoPageSize: true,
          },
        }}
      />
      {rowToEdit && rowToEdit?.data?.crewStatus && (
        <UpdateCrew
          {...{
            jobsites,
            crews: rowData,
            title: "Employees",
            updateModalVisible,
            refreshData: getCrews,
            handleUpdateModalVisible,
            recordSelected: rowToEdit.data,
            refreshTable: (res) => gridApi.applyTransaction({ update: [res] }),
          }}
        />
      )}
      {rowToEdit &&
        (rowToEdit?.data?.engineerId ||
          rowToEdit?.data?.architectId ||
          rowToEdit?.data?.driverId) && (
          <UpdateEmployeeModal
            {...{
              title: "Employees",
              refreshData: getCrews,
              employeeData: rowData,
              recordData: rowToEdit,
              handleUpdateModalVisible,
              updateEmployeeModalVisible: updateModalVisible,
              refreshTable: (res) =>
                gridApi.applyTransaction({ update: [res] }),
            }}
          />
        )}
      {rowToDelete && rowToDelete?.crewStatus && (
        <WarningModal
          closable={true}
          visible={rowToDelete}
          darkMode={isDarkMode}
          title="Warning Message"
          setVisible={setRowToDelete}
          className="logout-warning-modal"
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p>Are you sure you want to delete {rowToDelete?.crewName}?</p>
            <div className="buttons">
              <MondayButton
                Icon={<XIcon />}
                className="mondayButtonRed"
                data-testid="close-warning-btn"
                onClick={() => setRowToDelete(false)}
              >
                No
              </MondayButton>
              <MondayButton
                data-testid="delete-employee-btn"
                Icon={<Tick width={17} height={17} />}
                onClick={() => deleteCrew(rowToDelete?.crewId)}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
      {rowToDelete &&
        (rowToDelete?.engineerId ||
          rowToDelete?.architectId ||
          rowToDelete?.driverId) && (
          <DeleteEmployeeModal
            {...{
              rowData,
              setRowData,
              rowToDelete,
              setRowToDelete,
              refreshTable: () =>
                gridApi.applyTransaction({ remove: [rowToDelete] }),
            }}
          />
        )}
      {uploadVisible && accessRight.write && (
        <UploadEmployeesModal
          {...{
            open: uploadVisible,
            employeesList: rowData,
            setEmployeesList: setRowData,
            onCancel: () => setUploadVisible(false),
          }}
        />
      )}
    </>
  );
}

EmployeesTab.propTypes = {
  accessRight: {
    write: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.array,
  },
};

export default EmployeesTab;
