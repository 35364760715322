import "./GeofenceStep.scss";
import isEmpty from "lodash/isEmpty";
import { useEffect } from "react";
import { Form, Popover } from "antd";
import Geofence from "../../../../../commonComponents/Map/Geofence/Geofence";

const GeofenceStep = ({
  form,
  projectAddress,
  projectAddressInfo = {},
  setGeoFenceInfo,
  geoFenceInfo = [],
  useExistingData = false,
  existingOpportunity = {},
}) => {
  const alternativeAddresses =
    Form.useWatch("alternativeAddresses", { form, preserve: true }) || [];

  // get the data when converting from opportunity to project
  const coordinatesArray = (() => {
    if (useExistingData) {
      if (
        existingOpportunity?.opportunityLatitude &&
        existingOpportunity?.opportunityLongitude
      ) {
        return [
          {
            coordinates: {
              lat: existingOpportunity?.opportunityLatitude,
              lng: existingOpportunity?.opportunityLongitude,
            },
            address: existingOpportunity?.opportunityAddress,
          },
        ];
      }
    }

    if (
      projectAddressInfo?.coordinates?.lat &&
      projectAddressInfo?.coordinates?.lng
    ) {
      return [
        {
          coordinates: {
            lat: projectAddressInfo?.coordinates?.lat,
            lng: projectAddressInfo?.coordinates?.lng,
          },
          address: projectAddressInfo?.formatted_address || projectAddress,
        },
      ];
    }
    return [];
  })();

  // const coordinatesArray = [
  //   {
  //     coordinates: {
  //       lat: useExistingData
  //         ? existingOpportunity?.opportunityLatitude
  //         : projectAddressInfo?.coordinates?.lat,
  //       lng: useExistingData
  //         ? existingOpportunity?.opportunityLongitude
  //         : projectAddressInfo?.coordinates?.lng,
  //     },
  //     address: useExistingData
  //       ? existingOpportunity?.opportunityAddress
  //       : projectAddressInfo.formatted_address,
  //   },
  // ];

  const existingGeofence = existingOpportunity.geoFenceInfo;

  useEffect(() => {
    if (!!useExistingData && !isEmpty(existingOpportunity)) {
      setGeoFenceInfo([...existingGeofence]);
    }
  }, [useExistingData, existingOpportunity]);

  const getCoordinates = projectAddressInfo?.coordinates;
  const bringAltAddress = alternativeAddresses[0]?.address;

  return (
    <div className="geoFencStepContainer">
      {/* <StepHeader title="Geofence" /> */}
      <div className="geofencestep-card-container">
        <div className="geofencestep-card-header-container">
          <div>Geofence</div>
          <div style={{ fontSize: "25px", fontWeight: "550" }}>
            <Popover
              content={
                <span style={{ fontSize: "23px", fontWeight: "530" }}>
                  {bringAltAddress || "There is no alternative address"}
                </span>
              }
            >
              {projectAddress}
            </Popover>
          </div>
        </div>
        <Geofence
          key={geoFenceInfo.length}
          places={coordinatesArray}
          setGeoFenceInfo={setGeoFenceInfo}
          geoFenceInfo={geoFenceInfo}
          rowData={{ projectAddress }}
        />
      </div>
    </div>
  );
};
export default GeofenceStep;
