import { Modal, message } from "antd";
import React, { useCallback, useRef, useState } from "react";
import "./ShortcutForPliDays.scss";
import { XIcon } from "../../../../SidebarPages/Communication/assets";
import {
  columnDefsForTransferDays,
  swalToast,
} from "../SchedulingFirstPage/helperData";
import { AgGridReact } from "ag-grid-react";
import { MondayButton } from "../../../../commonComponents";
import { TickIcon } from "../../../../pages/Settings/settingsComponents/Roles/src";
import { DatePicker } from "antd";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
const TransferDays = (props) => {
  const {
    transferDays = {},
    scheduleDays = [],
    closeTransferDays = () => {},
    handleDays = () => {},
    comesFromElevation = false,
  } = props;
  const { RangePicker } = DatePicker;
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState();
  const [selectedDataset, setSelectedDataset] = useState([]);

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  }, []);

  const saveData = () => {
    const selectedNodes = gridApi?.getSelectedNodes() || [];
    const selectedData =
      selectedNodes?.length > 0
        ? selectedNodes?.map?.((node) => node?.data?.id)
        : [];
    if (!!comesFromElevation) {
      handleDays(
        transferDays?.serviceId,
        transferDays?.elevationIndex,
        selectedData
      );
    } else {
      handleDays(selectedData);
    }
    closeTransferDays(false);
    swalToast({
      icon: "success",
      title: "Days updated successfully!",
      showConfirmButton: false,
      position: "bottom-end",
      timer: 4500,
    });
  };

  const onFirstDataRendered = (params) => {
    params?.api?.forEachNode((node) => {
      if (transferDays?.days?.includes(node?.data?.id)) {
        node?.setSelected(true);
      }
    });
  };

  return (
    <div>
      <Modal
        className="transfer-days-modal"
        title="Select Days"
        centered
        open={transferDays?.visible}
        onCancel={() => closeTransferDays(false)}
        width={650}
        height="fit-content"
        closeIcon={<XIcon />}
        destroyOnClose={true}
        maskClosable={false}
        footer={[
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <MondayButton
              onClick={() => closeTransferDays(false)}
              {...{
                className: "mondayButtonRed",
                Icon: <XIcon />,
              }}
            >
              Close
            </MondayButton>
            <MondayButton
              onClick={saveData}
              {...{
                Icon: <TickIcon />,
              }}
            >
              Save Changes
            </MondayButton>
          </div>,
        ]}
      >
        <div className="daysInPLiGrid">
          <RangePicker
            defaultValue={[
              dayjsNY(scheduleDays?.[0]?.startDate),
              dayjsNY(scheduleDays?.[scheduleDays?.length - 1]?.endDate),
            ]}
            style={{ width: "100%", marginBottom: "20px" }}
            format="MM/DD/YYYY"
            disabledDate={(current) => {
              let disabled = false;
              if (current) {
                const currentDate = dayjsNY(current).startOf("day").valueOf();
                const startDate = dayjsNY(scheduleDays?.[0]?.startDate)
                  .startOf("day")
                  .valueOf();
                const endDate = dayjsNY(
                  scheduleDays?.[scheduleDays?.length - 1]?.endDate
                )
                  .startOf("day")
                  .valueOf();
                disabled = currentDate < startDate || currentDate > endDate;
              }
              return disabled;
            }}
            onChange={(dates) => {
              const startDate = dayjsNY(dates[0])?.startOf("day").valueOf();
              const endDate = dayjsNY(dates[1])?.endOf("day").valueOf();
              const filteredData = scheduleDays
                ?.filter((day) => {
                  const dayDate = dayjsNY(day?.startDate)
                    .startOf("day")
                    .valueOf();
                  return dayDate >= startDate && dayDate <= endDate;
                })
                ?.map((day) => day?.id);
              if (filteredData?.length > 0) {
                gridApi?.forEachNode((node) => {
                  if (filteredData?.includes(node?.data?.id)) {
                    node?.setSelected(true);
                  } else {
                    node?.setSelected(false);
                  }
                });
              }
            }}
          />
          <div
            className={`documentationsTable ${"light-ag-theme ag-theme-alpine"}`}
            style={{ width: "100%", height: 400 }}
          >
            <AgGridReact
              {...{
                ref: gridRef,
                columnDefs: columnDefsForTransferDays,
                rowData: scheduleDays,
                animateRows: true,
                onGridReady,
                suppressDragLeaveHidesColumns: true,
                suppressRowClickSelection: true,
                onFirstDataRendered,
                onComponentStateChanged: onFirstDataRendered,
                headerHeight: 32,
                rowHeight: 32,
                rowSelection: "multiple",
                defaultColDef: {
                  resizable: true,
                  enablePivot: true,
                  enableColResize: true,
                  enableRowGroup: true,
                  editable: false,
                  sortable: true,
                  flex: 1,
                  minWidth: 100,
                  filter: true,
                },
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TransferDays;
