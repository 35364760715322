import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Checkbox } from "antd";

export const SettingsColumnsPanel = ({ options, setOptions }) => {
  const [columns, setColumns] = useState(options);
  const [checkAll, setCheckAll] = useState(true);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(columns);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setColumns(items);
    setOptions(items);
  };

  const handleCheckboxChange = (index) => {
    const newColumns = [...columns];
    newColumns[index].checked = !newColumns[index].checked;
    setColumns(newColumns);
    setOptions(newColumns);
  };

  const handleCheckAllChange = (e) => {
    const newColumns = columns.map((column) => ({
      ...column,
      checked: e.target.checked,
    }));
    setColumns(newColumns);
    setOptions(newColumns);
    setCheckAll(e.target.checked);
  };

  return (
    <div>
      <Checkbox
        indeterminate={columns.some((column) => column.checked) && !checkAll}
        onChange={handleCheckAllChange}
        checked={checkAll}
      >
        All
      </Checkbox>

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="columns">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                gap: "8px",
                background: "#2a3550",
                color: "#fff",
                marginLeft: 20,
              }}
            >
              {columns.map((column, index) => (
                <Draggable
                  key={column.id}
                  draggableId={column.id}
                  index={index}
                >
                  {(provided) => (
                    <div
                      className=""
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style,
                      }}
                    >
                      <Checkbox
                        checked={column.checked}
                        onChange={() => handleCheckboxChange(index)}
                      >
                        {column.content.label}
                      </Checkbox>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
