import React from "react";
import { Modal } from "antd";

import "./SettingsModal.scss";
import { XIcon } from "../../Communication/assets/index";

const SettingsModal = ({ visible, closeModal, title, children }) => {
  return (
    <Modal
      open={visible}
      onCancel={closeModal}
      title={title}
      closeIcon={<XIcon style={{ fill: "#fff" }} />}
      wrapClassName="settingsModal"
      footer={null}
      destroyOnClose={true}
    >
      {children}
    </Modal>
  );
};
export default SettingsModal;
