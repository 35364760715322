import "./propTypes";
import { Modal } from "antd";
import { cloneElement, useState } from "react";
import { AlertIcon } from "src/assets";
import { useSelector } from "react-redux";
import MondayButton from "../MondayButton/MondayButton";
import { TrashIcon } from "../../SidebarPages/Communication/assets";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";

import "./RedWarningModal.scss";

/**
 *
 * @param {RedWarningModalTypes} props - The props for MyComponent.
 * @returns {JSX.Element} - The rendered component.
 */

/**
 *
 * Simple usage:
 * <RedWarningModal
 *      visible={true}
 *      onCancel={() => {
 *         setVisible(false)
 *          console.log("cancelled");
 *          }}
 *      setVisible={setVisible}
 *      children={<div>Are you sure...</div>}
 *      footerProps={{
 *        onConfirm: () => {
 *          console.log("confirmed");
 *        },
 *      }}
 *    />
 *
 */

function RedWarningModal({
  visible,
  onCancel = () => {},
  setVisible = () => {},
  children,
  TitleIcon = AlertIcon,
  titleText = "Warning",
  footer,
  modalClassName = "",
  footerProps = {},
  confirmModal = false, //grey header -> default header color is red
  deleteModal = false, //add trash icon
  modalProps = {},
  noHeaderIcons = false,
  transformIcon = {
    transform: "rotate(-22deg) scale(3.5)",
  },
  disableOnConfirm = true,
}) {
  const [disableSave, setDisableSave] = useState(false);
  const {
    onConfirm = () => {},
    saveText = "Confirm",
    saveDisabled = false,
    SaveIcon = TickIcon,
    saveClassName = "mondayButtonGreen",
    closeText = "Cancel",
  } = footerProps || {};

  function onSaveConfirm() {
    if (disableOnConfirm) {
      setDisableSave(true);
    }
    onConfirm();
  }

  const initialFooter = footer
    ? footer
    : [
        {
          text: closeText,
          onClick: onCancel,
          disabled: false,
          Icon: XIcon,
          className: "mondayButtonRed",
        },
        {
          text: saveText,
          onClick: onSaveConfirm,
          disabled: saveDisabled || disableSave,
          Icon: SaveIcon,
          className: saveClassName,
        },
      ];
  const { isDarkMode } = useSelector((state) => state.darkMode);
  return (
    <Modal
      {...{
        open: visible,
        onCancel: () => {
          setVisible(false);
        },
        "data-testid": "red-warning-modal",
        title: (
          <div className="warningModalTitleContainer">
            <span className="warningModalTitle">
              {noHeaderIcons ? null : deleteModal ? (
                <TrashIcon />
              ) : (
                <TitleIcon />
              )}
              {titleText}
            </span>
            {noHeaderIcons ? null : deleteModal ? (
              <TrashIcon
                fill="#ffffff80"
                style={{
                  transform:
                    "rotate(-32deg) scale(3.5) translateX(2px) translateY(4px) ",
                }}
              />
            ) : (
              <TitleIcon
                fill="#ffffff80"
                style={{
                  ...transformIcon,
                }}
              />
            )}
          </div>
        ),
        footer:
          footer === null ? null : (
            <div className="redWarningModalFooter">
              {initialFooter?.map(
                (
                  {
                    text = "",
                    onClick = () => {},
                    disabled = false,
                    Icon = null,
                    className = "",
                    ...restProps
                  },
                  i
                ) => (
                  <ButtonComp
                    key={i}
                    onClick={onClick}
                    Icon={!Icon ? null : <Icon />}
                    disabled={disabled}
                    className={className}
                    data-testid={"warning-modal-button" + text}
                    hasIcon={!!Icon}
                    {...restProps}
                  >
                    {text}
                  </ButtonComp>
                )
              )}
            </div>
          ),
        centered: true,
        closeIcon: false,
        className: `redWarningsModal ${isDarkMode && "redWarningsModalDark"} 
       ${modalClassName} ${confirmModal && !deleteModal && "confirmModal"}`,
        ...modalProps,
      }}
    >
      {children}
    </Modal>
  );
}

export default RedWarningModal;

//antd button slow on development environment
function ButtonComp({
  children,
  disabled = false,
  Icon = null,
  onClick = () => {},
  className = "mondayButtonGreen",
  containerClassName = "",
  style = {},
  buttonStyle = {},
  middleLineStyle = {},
  hasIcon = true,
  loading = false,
  // tooltipCategory,
  // tooltipKey,
  // noTooltip = true,
  ...rest
}) {
  const isDarkMode = localStorage.getItem("darkmode") === "true";

  const buttonToReturn = (
    <button
      style={buttonStyle}
      onClick={onClick}
      className={`mondayButtonStyle ${
        isDarkMode && "mondayButtonStyleDark"
      } ${className}`?.trim()}
      disabled={disabled}
      // data-testid={rest?.["data-testid"] || "monday-button"}
      {...rest}
    >
      {children && (
        <div className="mondayButtonText">
          {/* 1. Added spin for loading state especially for e-mail communication in order to prevent multiple clicks */}
          {loading && <Spin size="small" data-testid="monday-button-spinner" />}
          {children}
        </div>
      )}

      {children && hasIcon && (
        <div
          className="mondayButtonDivider"
          style={
            !!disabled
              ? { backgroundColor: "transparent !important" }
              : { middleLineStyle }
          }
        />
      )}
      {Icon && cloneElement(Icon, { className: "mondayButtonIcon" })}
    </button>
  );
  return (
    <div
      className={`mondayButtonContainer ${containerClassName}`}
      style={style}
      data-testid="monday-button-container"
    >
      {buttonToReturn}
    </div>
  );
}
