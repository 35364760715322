import { useState } from "react";

import {
  extractColumnNames,
  extractColumnNamesFromTableData,
} from "../../utils/helpers";
import { showWarningMsg } from "../../../../../../utils";
import { InputComponent } from "../../../../Fleet/components";
import { CustomCheckBox } from "src/components/commonComponents";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import styles from "./TableOptions.module.scss";
import sortHeaders from "../utils/sortHeaders";
import { useSelector } from "react-redux";

const TableOptions = ({
  title,
  columnDefs,
  isReport = true,
  options,
  setOptions,
  tableColumns,
  downloadExcel,
}) => {
  const [search, setSearch] = useState("");
  const maxLength = isReport ? 6 : 9;
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const tableOptions =
    extractColumnNamesFromTableData(tableColumns) ||
    extractColumnNames(columnDefs);

  const [allOptions, setAllOptions] = useState([
    ...extractColumnNames(options),
    ...tableOptions.filter(
      (option) =>
        option.name.toLowerCase().includes(search.toLowerCase()) &&
        !options.includes(option.column)
    ),
  ]);

  const addOptionToTable = (option) => {
    const checked = [];
    const unChecked = [];
    if (!downloadExcel && !tableColumns && options.length > maxLength) {
      showWarningMsg({
        content: `You can select maximum ${maxLength + 1} table columns`,
      });
      return;
    }

    allOptions.forEach((header) => {
      if (options.includes(header.column)) {
        checked.push(header);
      } else {
        header.column !== option.column && unChecked.push(header);
      }
    });

    setAllOptions([...checked, option, ...unChecked]);

    setOptions((prev) => [...prev, option.column]);
  };

  const removeOptionFromTable = (option) => {
    if (!downloadExcel && !tableColumns && options.length === 1) {
      showWarningMsg({
        content: "At least one table column should be selected",
      });
      return;
    }

    setAllOptions((prev) => [
      ...prev.filter((item) => item.column !== option.column),
      option,
    ]);

    setOptions((prev) =>
      prev.filter((tableOption) => tableOption !== option.column)
    );
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;
    if (source.index === destination.index) return;

    const movedItem = allOptions[source.index];
    const updatedHeaders = Array.from(allOptions);
    updatedHeaders.splice(source.index, 1);
    updatedHeaders.splice(destination.index, 0, movedItem);

    const { sortedData, includes } = sortHeaders(updatedHeaders, options);

    setAllOptions(sortedData);
    setOptions(includes);
  };

  const handleSearch = (value) => {
    setSearch(value);
    setAllOptions([
      ...extractColumnNames(options),
      ...tableOptions.filter(
        (option) =>
          option.name.toLowerCase().includes(value.toLowerCase()) &&
          !options.includes(option.column)
      ),
    ]);
  };

  return (
    <div className={isDarkMode && styles.tableOptionBodyDark}>
      <h3>Add/Remove Table {title}</h3>
      <InputComponent
        type="text"
        dataTestid="table-options-search"
        placeholder={`Search table ${title}...`}
        onChange={(event) => handleSearch(event.target.value)}
      />

      <div className={styles.tableOptionBody}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {allOptions.map((option, index) => {
                  const isChecked = options.includes(option.column);
                  return (
                    <Draggable
                      key={option.id.toString()}
                      draggableId={option.id.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <CustomCheckBox
                            key={option.name}
                            {...{
                              name: option.name,
                              checked: isChecked,
                              onChange: () =>
                                isChecked
                                  ? removeOptionFromTable(option)
                                  : addOptionToTable(option),
                              style: { marginBottom: "10px", width: "99%" },
                              "data-testid": `table-option-${option.name
                                .toLowerCase()
                                .split(" ")
                                .join("-")}`,
                            }}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default TableOptions;
