import { useSelector } from "react-redux";
import dayjs from "dayjs";

function LicensePlate(props) {
  const { params } = props;
  const { licensePlateHistory } = params;
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <div
      style={{ padding: "15px 0", ...(isDarkMode ? { color: "#F2F2F2" } : {}) }}
    >
      <div>
        <strong>License Plate: </strong>
        <span style={{ ...(isDarkMode ? { color: "#ACACCA" } : {}) }}>
          {params?.licensePlate}{" "}
        </span>
      </div>

      <div style={{ ...(isDarkMode ? { color: "#ACACCA" } : {}) }}>
        {licensePlateHistory?.length > 0 &&
          licensePlateHistory.at(-1)?.isPreviousRecord !== true && (
            <>
              <strong>License Plate Active since: </strong>
              <span>
                {dayjs(licensePlateHistory.at(-1).startDate).format(
                  "MM/DD/YYYY"
                )}{" "}
                -{" "}
                {licensePlateHistory.at(-1).endDate
                  ? dayjs(licensePlateHistory.at(-1).endDate).format(
                      "MM/DD/YYYY"
                    )
                  : "Present"}
              </span>
            </>
          )}
      </div>
    </div>
  );
}

export default LicensePlate;
