import { quickStatistics_Schedule_and_Project } from "../../components/SidebarPages/Scheduling/helpers/quickStatistics";
import { ProgressStatistics } from "../../components/SidebarPages/Scheduling/helpers/totals";
import { fetchAllData } from "../ApiMethods";

export const getSchedulingTotalProgress = async ({ keysToInclude }) => {
  const [
    scheduling,
    toBeScheduledArr,
    invoicesRes,
    paymentsRes,
    requisitionsRes,
  ] = await Promise.all([
    fetchAllData({
      endpoint: "scheduling",
      resultId: "scheduleId",
      otherStringParams: {
        keysToInclude: JSON.stringify([
          ...new Set([...keysToInclude, ...["scheduleId", "projectId"]]),
        ]),
      },
    }),
    fetchAllData({
      endpoint: "toBeScheduled",
      resultPosition: "toBeScheduled",
      resultId: "toBeScheduledId",
      otherStringParams: {
        keysToInclude: JSON.stringify([
          "toBeScheduledId",
          "label",
          "estimationNumber",
          "scheduleId",
          "estimationId",
          "totalities",
        ]),
      },
    }),
    fetchAllData({
      endpoint: "invoices",
      resultId: "invoiceId",
      otherStringParams: {
        keysToInclude: JSON.stringify([
          "invoiceId",
          "projectId",
          "categoriesFrom",
          "invoiceItems",
          "invoiceNumber",
          "invoicePaidPercentage",
        ]),
      },
    }),
    fetchAllData({
      endpoint: "payments",
      resultId: "paymentId",
      otherStringParams: {
        keysToInclude: JSON.stringify(["paymentId", "payments", "invoices"]),
      },
    }),
    fetchAllData({
      endpoint: "applications",
      resultId: "applicationId",
      otherStringParams: {
        keysToInclude: JSON.stringify([
          "applicationId",
          "estimationsSelected",
          "services",
        ]),
      },
    }),
  ]);

  const result = scheduling.map((schedule) => {
    const toBeScheduled = toBeScheduledArr.filter(
      ({ scheduleId }) => scheduleId === schedule.scheduleId
    );

    return {
      ...schedule,
      services: toBeScheduled.map((service) => {
        const { progressPercentage = 0 } =
          ProgressStatistics.serviceTotal(service);

        const requisitions = requisitionsRes
          .filter((requisition) =>
            requisition.estimationsSelected.includes(service.estimationId)
          )
          .map((req) => ({
            services: req.services.filter(
              (reqService) =>
                reqService.estimationId === service.estimationId &&
                reqService.label === service.label
            ),
          }));

        const invoices = invoicesRes
          .filter(
            (invoice) =>
              invoice.projectId === schedule.projectId &&
              invoice.categoriesFrom.includes("estimations")
          )
          .map((invoice) => {
            return {
              ...invoice,
              invoiceItems: invoice.invoiceItems.filter(
                (item) =>
                  item.category === "estimations" &&
                  item.name === service.label &&
                  item.categoryId === service.estimationId
              ),
            };
          });

        const payments = paymentsRes.filter((payment) =>
          payment.payments.some((paymentDetail) =>
            invoices.some(
              (invoice) => invoice.invoiceNumber === paymentDetail.invoiceNumber
            )
          )
        );

        return {
          ...schedule,
          estimationNumber: service.estimationNumber,
          serviceName: service.label,
          totalProgress: progressPercentage,
          totalRequisitionAmount: requisitions.reduce(
            (sum, requisition) =>
              (sum += requisition.services.reduce(
                (acc, item) =>
                  (acc +=
                    item.amounts.reduce(
                      (itemSum, amount) => (itemSum += amount.paymentDue),
                      0
                    ) || 0),
                0
              )),
            0
          ),
          totalInvoiceAmount: invoices.reduce(
            (sum, invoice) =>
              sum +
              invoice.invoiceItems
                .filter((item) => item.name === service.label)
                .reduce((itemSum, item) => itemSum + item.total, 0),
            0
          ),
          totalPaymentAmount: payments?.reduce(
            (sum, payment) =>
              sum +
              payment.invoices.reduce(
                (itemSum, item) =>
                  itemSum +
                  (service.totalities?.totalPrice || 0) * ///////////////////////// totalities missing in toBeScheduled
                    ((item?.invoicePaidPercentage || 0) / 100),
                0
              ),
            0
          ),
        };
      }),
    };
  });
  return result;
};
