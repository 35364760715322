import { useCallback, useContext } from "react";
import {
  onClientSelect,
  onDocCategorySelect,
  onFleetSelect,
  onIncidentAddressSelect,
  onProjectAddressSelect,
  onSafetySelect,
  onScheduleSelect,
  onInspectionProjectSelect,
} from "./fieldSelectHandlers";
import onDispatchLocationChange from "./fieldSelectHandlers/onDispatchLocationChange";
import { RequestModalContext, useRequestModalContext } from "../store";

/**
 * @usage - This hook is used to handle the select event of the main field in the request form
 * the functions here should be used to populate form fields based on the selected main field such as
 * projectManager, projectExecutive, client, ...etc. If a new request type is added, a new function should be created
 * and implemented in the if statement below, however if the logic matches one of the existing functions it should be
 * added as a condition for that function. Note that the function should be added to the fieldSelectHandlers file.
 * Most of the functions below also return recordId and recordName which are 2 necessary keys needed in the body of the request
 * so if a new function is created, it should also return these 2 keys.
 *
 *
 * @param {String} requestType - The type of request
 * @param {Object} params - Object that contains the necessary parameters needed for the select handler
 * @returns  - The function to be called when the main field is selected or cleared
 */
const useRequestRecordSelect = (requestType, params) => {
  const projectAddressSelectRequests = [
    "Permit Drawing",
    "Estimation",
    "Scheduling",
    "Service Inspection",
    "Change Order",
  ];

  const {
    requestData: { fetchedData = null },
    dispatchRequestData = () => {},
    currentPageKey = null,
  } = useRequestModalContext();

  Object.assign(params, { dispatchRequestData });

  const onMainFieldSelect = useCallback(
    async ({ val, opt = null, clear = false, field = null }) => {
      const {
        safety = [],
        accounts = [],
        projects = [],
        schedules = [],
        opportunities = [],
        docConfiguration = [],
      } = fetchedData || {};

      if (projectAddressSelectRequests.includes(requestType)) {
        // return () =>
        return onProjectAddressSelect({
          val,
          opt,
          clear,
          projects,
          accounts,
          opportunities,
          isChangeOrder: requestType === "Change Order",
          ...params,
        });
      } else if (requestType === "Schedule Change") {
        // return () =>
        return onScheduleSelect({ val, clear, schedules, ...params });
      } else if (requestType === "Documentation") {
        // async () =>
        return await onDocCategorySelect({
          val,
          clear,
          fetchedData,
          docConfiguration,
          currentPageKey,
          ...params,
        });
      } else if (requestType === "Opportunity") {
        // return () =>
        return onClientSelect({ val, opt, clear, accounts, ...params });
      } else if (requestType === "Work Order") {
        // return () =>
        return onFleetSelect({ val, opt, clear, ...params });
      } else if (requestType === "Dispatch") {
        // return () =>
        return onDispatchLocationChange({ val, option: opt, field, ...params });
      } else if (
        requestType === "Safety Inspection" ||
        requestType === "Claim"
      ) {
        // return () =>
        return onSafetySelect({ val, opt, clear, safety, ...params });
      } else if (requestType === "Incident") {
        // return () =>
        return onIncidentAddressSelect({
          val,
          type: opt?.type,
          recordName: opt?.label,
          clear,
          projects,
          schedules,
          ...params,
        });
      } else if (requestType === "Inspection") {
        return onInspectionProjectSelect({
          opt,
          clear,
          projects,
          ...params,
        });
      }
    },
    [requestType, JSON.stringify(fetchedData), JSON.stringify(params)]
  );

  return { onMainFieldSelect };
};

export default useRequestRecordSelect;
