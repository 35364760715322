import _ from "lodash";
import { formatCurrency } from "../../utils";
import {
  defectsCols,
  workOrderCols,
  reminderColumns,
  mismatchColumns,
  fleetInspectionCols,
  assignedMechanicsCols,
} from "../data";
import {
  UserType,
  FleetType,
  ChartData,
  LayoutType,
  DefectType,
  ChartTypes,
  DatasetType,
  ListInfoType,
  LocationType,
  WorkOrderType,
  VehicleReport,
  BaseLayoutType,
  LayoutCardType,
  SmallInfoCardType,
  FleetInspectionType,
  MaintenanceReducerType,
} from "../types";
import {
  MaintenanceIcon,
  InspectionsWhite,
  FleetInspectionsBlankIcon,
} from "src/icons";
import {
  LightingIcon,
  WarningBellIcon,
  WarningFileIcon,
  CalendarDatesIcon,
  AccountingSideBarIconBlank,
} from "src/assets";
import { hexToRgba, camelToTitle } from "src/utils";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { getRandomColor } from "src/components/Header/forms/Scheduling/helpers/creators";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import calculateUpcomingMaintenances from "src/components/SidebarPages/FleetsMaintenances/Tabs/ScheduleMaintenance/utils/calculateUpcomingMaintenances";

type Dictionary<T> = {
  [key: string]: T;
};

type OverviewAnalyticsType = Dictionary<Dictionary<number>>;

type DevicesMismatch = {
  fleetName: string;
  fleetId: string;
  deviceSerialNumber: string;
  vinInSystem: string;
  foundVin: string | null;
};

function positionSmallCards(smallCards: SmallInfoCardType[]): LayoutType {
  return smallCards.map((params, index) => {
    const y = index > 3 ? 3 : 0;
    const x = (index % 4) * 3;

    return {
      h: 3,
      y,
      isResizable: false,
      w: 3,
      i: `${params.title}-Small`,
      x,
      contentData: {
        type: "Small",
        params,
      },
    };
  });
}

function positionListCards(listCards: ListInfoType[]): LayoutType {
  return listCards.map((params, index) => {
    return {
      h: 9,
      x: 0,
      w: 6,
      minW: 4,
      minH: 7,
      i: `${params.cardTitle}-List`,
      y: 6 + (listCards.length - 1) * index * 9,
      contentData: {
        type: "List",
        params,
      },
    };
  });
}

function positionChartCards(chartCards: ChartData[]): LayoutType {
  return chartCards.map((params, index) => {
    return {
      h: 9,
      x: 6,
      w: 6,
      minW: 4,
      minH: 7,
      i: `${params.cardTitle}-Chart`,
      y: 6 + (chartCards.length - 1) * index * 9,
      contentData: {
        type: "Chart",
        params,
      },
    };
  });
}

function processAnalytics(
  analytics: OverviewAnalyticsType,
  chartTypes: Dictionary<ChartTypes>
): ChartData[] {
  const chartsData: ChartData[] = [];

  for (const key in analytics) {
    const cardTitle = camelToTitle(key);
    const labels = Object.keys(analytics[key]).map(camelToTitle) as string[];

    const backgroundColor = [];
    const borderColor = [];
    const data = [];

    const dataset: DatasetType = {
      backgroundColor,
      borderColor,
      data,
      borderWidth: 2,
      label: cardTitle,
    };

    for (const results in analytics[key]) {
      const color = getRandomColor();
      const bck = hexToRgba(color, 0.4);
      backgroundColor.push(bck);
      borderColor.push(color);
      data.push(analytics[key][results]);
    }

    chartsData.push({
      cardTitle,
      labels,
      datasets: [dataset],
      chartType: chartTypes[key],
    });
  }

  return chartsData;
}

type ConfigParam = {
  userId: string;
  mechanicUsers: UserType[];
  customConfiguration?: BaseLayoutType;
};

type UpcomingMaintenance = {
  fleetName: string;
  fleetId: string;
  programName: string;
  programId: string;
  vinNumber: string;
  licensePlate: string;
  odometerReading: number;
  maintenanceReason: string;
  estimatedMaintenanceDate: number;
  estimatedOdometer: number;
  lastDateScheduleService: number;
  lastMileScheduleService: number;
};

/**
 * Function responsible for generating the default grid layout.
 * Also accepts a custom positioning
 */
function generateDefaultOverview(
  data: MaintenanceReducerType,
  config: ConfigParam
): LayoutType {
  let layout: LayoutType = [];

  const {
    fleet,
    reports,
    defects,
    locations,
    workOrders,
    inspections,
    inventoryItems,
    servicePrograms,
    serviceMaintenance,
  } = data;

  const { mechanicUsers, userId, customConfiguration } = config;

  const today = dayjsNY().startOf("day").valueOf();

  let defectsOngoing = [];
  let ongoingWorkOrders = 0,
    ongoingLaborCost = 0,
    completedLaborCost = 0;

  for (const wo of workOrders) {
    const { laborInformation, dueDate, workOrderStatus } = wo;
    const isOngoing =
      dayjsNY(dueDate).endOf("day").valueOf() >= today &&
      workOrderStatus !== "Completed";

    const cost = laborInformation.reduce(
      (allInfo, currentInfo) => allInfo + Number(currentInfo.total),
      0
    );

    if (isOngoing) {
      ++ongoingWorkOrders;
      ongoingLaborCost += cost;
    } else {
      completedLaborCost += cost;
    }
  }

  for (const defect of defects) {
    if (defect.defectStatus === "In Progress") {
      defectsOngoing.push(defect);
    }
  }

  let failedInspections = 0,
    passedInspections = 0;
  for (let insp of inspections) {
    const { inspectionStatus } = insp;

    if (inspectionStatus === "Failed") {
      ++failedInspections;
    } else {
      ++passedInspections;
    }
  }

  const newDefects = defects.filter(
    ({ defectStatus }) => defectStatus === "New"
  );

  const inventoryDictionary: Dictionary<string> = inventoryItems.reduce(
    (acc, val) => ({
      ...acc,
      [val.itemId]: val.itemName,
    }),
    {}
  );

  const locationCollection = locations.reduce(
    (acc, val) => ({
      ...acc,
      [val.deviceSerialNumber]: val,
    }),
    {} as Record<string, LocationType>
  );

  const mismatchRows: DevicesMismatch[] = [];

  const upcomingMaintenances: UpcomingMaintenance[] =
    calculateUpcomingMaintenances({
      vehicleReports: data.reports,
      servicePrograms,
      vehicles: fleet,
    });

  const overdueDate = Date.now();
  const endNextMonth = dayjsNY().add(1, "month").endOf("month").valueOf();
  const thisMonth: [number, number] = [
    overdueDate,
    dayjsNY().endOf("month").valueOf(),
  ];

  let overdueM = 0,
    thisMonthM = 0,
    nextMonthM = 0,
    upcomingM = 0;

  for (const maintenance of upcomingMaintenances) {
    if (maintenance.estimatedMaintenanceDate < overdueDate) {
      ++thisMonthM;
    } else if (maintenance.estimatedMaintenanceDate > endNextMonth) {
      ++upcomingM;
    } else if (
      maintenance.estimatedMaintenanceDate >= thisMonth[0] &&
      maintenance.estimatedMaintenanceDate <= thisMonth[1]
    ) {
      ++thisMonthM;
    } else {
      ++nextMonthM;
    }
  }

  for (const maintenance of serviceMaintenance) {
    if (maintenance.workOrderId) {
      continue;
    }

    if (maintenance.serviceStatus === "Overdue") {
      ++overdueM;
      continue;
    }

    if (
      maintenance.expirationDate < dayjsNY().startOf("day") &&
      !maintenance.workOrderId
    ) {
      ++overdueM;
      continue;
    }

    ++thisMonthM;
  }

  for (const vehicle of fleet) {
    if (
      !vehicle?.deviceSerialNumber ||
      !locationCollection?.[vehicle?.deviceSerialNumber]
    ) {
      mismatchRows.push({
        fleetId: vehicle.fleetId,
        fleetName: vehicle.fleetName,
        deviceSerialNumber: vehicle.deviceSerialNumber,
        foundVin: null,
        vinInSystem: vehicle.vinNumber,
      });
    } else {
      if (
        locationCollection[vehicle.deviceSerialNumber]["vin"] !==
        vehicle["vinNumber"]
      ) {
        mismatchRows.push({
          deviceSerialNumber: vehicle.deviceSerialNumber,
          fleetId: vehicle.fleetId,
          fleetName: vehicle.fleetName,
          foundVin: locationCollection[vehicle.deviceSerialNumber]["vin"],
          vinInSystem: vehicle.vinNumber,
        });
      }
    }
  }

  /**
   * Here we define the small cards params that will sit on the top of the screen
   */
  const dashboardSmallCards: SmallInfoCardType[] = [
    {
      color: "#6960d7",
      content: `${ongoingWorkOrders} ongoing`,
      title: "Work Orders",
      icon: MaintenanceIcon,
    },
    {
      color: "#71cf48",
      content: `${formatCurrency(ongoingLaborCost)} ongoing | ${formatCurrency(
        completedLaborCost
      )} completed`,
      title: "Labor Cost",
      icon: AccountingSideBarIconBlank,
    },
    {
      color: "#ff9f43",
      content: `${newDefects.length} new`,
      title: "Defects",
      icon: FleetInspectionsBlankIcon,
    },
    {
      color: "#11bfe9",
      content: `${failedInspections} Failed | ${passedInspections} Passed`,
      title: "Inspections",
      icon: InspectionsWhite,
    },
    {
      color: "#ff6666",
      content: `${overdueM} Maintenances`,
      title: "Overdue Maintenances",
      icon: WarningBellIcon,
    },
    {
      color: "#478ad8",
      content: `${thisMonthM} Maintenances`,
      title: "This Month Maintenances",
      icon: WarningFileIcon,
    },
    {
      color: "#ffa600",
      content: `${nextMonthM} Maintenances`,
      title: "Next Month Maintenances",
      icon: LightingIcon,
    },
    {
      color: "#dc32ff",
      content: `${upcomingM} Maintenances`,
      title: "Upcoming Maintenances",
      icon: CalendarDatesIcon,
    },
  ];

  /**
   * These are the table card params, by default they extend
   * vertically on the left side of the screen
   */
  const assignedMechanics = [];
  const groupedMechanics = _.countBy(
    workOrders,
    (wo: WorkOrderType) => wo.mechanicId
  );

  for (const mechanicId in groupedMechanics) {
    const user = mechanicUsers.find(
      ({ cognitoUserId }) => cognitoUserId === mechanicId
    );
    if (user) {
      assignedMechanics.push({
        mechanic: user?.nameOfUser,
        googleDriveFileId: user?.googleDriveFileId,
        jobs: groupedMechanics[mechanicId],
        userName: user?.userName,
      });
    } else {
      const pastWorkOrder = workOrders.find(
        ({ mechanicId: id }) => mechanicId === id
      );
      if (pastWorkOrder) {
        assignedMechanics.push({
          mechanic: pastWorkOrder.mechanicInfo.mechanic,
          jobs: groupedMechanics[mechanicId],
        });
      }
    }
  }

  const reportsByVehicle: Dictionary<VehicleReport> = _.keyBy(
    reports.vehicleReports,
    "fleetId"
  );

  const vehiclesMap = fleet.reduce(
    (acc, val) => ({
      ...acc,
      [val.fleetId]: val,
    }),
    {} as Record<string, FleetType>
  );

  const remindersDataSource = servicePrograms.flatMap((e) => {
    if (
      !(e?.recipients || "").includes(userId) ||
      !(e?.reminderChannels || "").includes("Dashboard")
    ) {
      return [];
    }

    const reminders = Object.values(e.executionData).filter((x) =>
      x.dateReminder ? true : x.milesReminder || x.dateReminderForMiles
    );

    if (!reminders.length) {
      return [];
    }

    return reminders.map((v) => {
      const odometerReading = Number(
        reportsByVehicle?.[v?.fleetId]?.["odometerReading"]
      ).toFixed(2);

      let daysToReachMiles: number;
      if (
        odometerReading &&
        v.nextExecutionMiles &&
        reportsByVehicle?.[v.fleetId]?.["averageMilesPerDay"]
      ) {
        daysToReachMiles =
          (Number(v.nextExecutionMiles) -
            Number(reportsByVehicle?.[v?.fleetId]?.["odometerReading"])) /
          reportsByVehicle?.[v.fleetId]?.["averageMilesPerDay"];
      }

      const { vinNumber, licensePlate } = vehiclesMap[v.fleetId] || {};

      return {
        programName: e.programName,
        programId: e.programId,
        fleetName: v.fleetName,
        daysToReachMiles,
        vinNumber,
        licensePlate,
        odometerReading: Number(
          reportsByVehicle?.[v?.fleetId]?.["odometerReading"]
        ).toFixed(2),
        nextExecutionMiles: v.nextExecutionMiles,
        nextExecutionDate: v.nextExecutionDate,
      };
    });
  });

  const thisMonthRange = [
    dayjsNY().startOf("month").valueOf(),
    dayjsNY().endOf("month").valueOf(),
  ];

  const dashboardListCards: ListInfoType[] = [
    {
      cardTitle: "Service Program Reminders",
      columns: reminderColumns,
      dataSource: remindersDataSource,
    },
    {
      cardTitle: "Data mismatch",
      columns: mismatchColumns,
      dataSource: mismatchRows,
    },
    {
      cardTitle: "Assigned Mechanics",
      dataSource: assignedMechanics,
      columns: assignedMechanicsCols,
      showHeader: false,
    },
    {
      cardTitle: "Inspections (This Month)",
      columns: fleetInspectionCols,
      dataSource: inspections
        .filter((e) => {
          const dt = parseInTz(e.inspectionDate, "MM/DD/YYYY").valueOf();
          return dt >= thisMonthRange[0] && dt <= thisMonthRange[1];
        })
        .sort(
          (a, b) =>
            parseInTz(a.inspectionDate, "MM/DD/YYYY") -
            parseInTz(b.inspectionDate, "MM/DD/YYYY")
        ),
    },
    {
      cardTitle: "Work Orders (Ongoing)",
      columns: workOrderCols,
      dataSource: workOrders
        .filter((e) => e.workOrderStatus !== "Completed")
        .sort((a, b) => a.dueDate - b.dueDate),
    },
    {
      cardTitle: "Defects (Ongoing)",
      columns: defectsCols,
      dataSource: defectsOngoing,
    },
  ];

  /**
   * Here we define the chart params
   */
  const defectsPerType = _.countBy(
    defects,
    (defect: DefectType) => defect.defectName
  );

  const inspectionsMechanics = _.countBy(
    inspections,
    (inspection: FleetInspectionType) => inspection.mechanic
  );

  const inventoryPartsUsed = {};
  for (const workOrder of workOrders) {
    for (const item of workOrder["inventoryItems"]) {
      if (!inventoryDictionary?.[item.itemId]) {
        continue;
      }

      inventoryPartsUsed[inventoryDictionary[item.itemId]] =
        (inventoryPartsUsed[item.itemId] || 0) + Number(item.quantity);
    }
  }

  const chartParams = processAnalytics(
    {
      defectsPerType,
      inspectionsMechanics,
      inventoryPartsUsed,
    },
    {
      defectsPerType: "Pie",
      inspectionsMechanics: "Vertical",
      inventoryPartsUsed: "Vertical",
    }
  );

  if (!customConfiguration) {
    layout = [
      ...positionSmallCards(dashboardSmallCards),
      ...positionListCards(dashboardListCards),
      ...positionChartCards(chartParams),
    ];
  } else {
    for (const item of customConfiguration) {
      const cardType = item.i.match(/\-\w+$/g)?.[0];
      const searchItem = item.i.replace(cardType, "");
      const data = [
        ...dashboardSmallCards,
        ...dashboardListCards,
        ...chartParams,
      ].find((e) => {
        if ("title" in e) {
          return e["title"] === searchItem;
        } else if ("cardTitle" in e) {
          return e["cardTitle"] === searchItem;
        }

        return false;
      });

      if (!data) {
        continue;
      }

      const contentData = {
        type: cardType.replace("-", "") as "Small" | "Chart" | "List",
        params: data,
      } as LayoutCardType["contentData"];

      layout.push({
        ...item,
        contentData,
      });
    }
  }

  return layout;
}

export default generateDefaultOverview;
