/**
 *
 * @param {String} requestType - The type of request being made
 * @param {Object} selectedMainField  - The selected main field object
 * @returns {String} - The category of the request which is used to redirect to the record
 * this request is being based on
 */
const requestCategory = (requestType, selectedMainField) => {
  const categories = {
    Project: "projects",
    Scheduling: "projects",
    "Permit Drawing": "projects",
    "Service Inspection": "projects",
    "Change Order": "projects",
    Inspection: "projects",
    Dispatch: "dispatch",
    "Safety Inspection": "safety",
    Claim: "safety",
    Opportunity: "clients",
    "Schedule Change": "scheduling",
    "Work Order": "fleets",
    "Fleet Inventory": "fleets",
    "New Vendor": "vendors",
    Incident: selectedMainField?.hasOwnProperty("scheduleId")
      ? "scheduling"
      : "projects",
    Estimation: selectedMainField?.hasOwnProperty("opportunityId")
      ? "opportunities"
      : "projects",
    Documentation: selectedMainField?.apiData?.table || null,
  };

  return categories[requestType] || null;
};

export default requestCategory;
