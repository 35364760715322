import React, { useState } from "react";
import { columnDefsForPliData } from "../../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { AgGridReact } from "ag-grid-react";
import ProgressChanges from "../ProgressChanges/ProgressChanges";
import "./TableView.scss";
import { useSelector } from "react-redux";
import hasUploadedImages from "./hasUploadedImages";
import { message } from "antd";
const TableView = ({
  d1 = "",
  d2 = "",
  items = [],
  dayId = "",
  detailIndex,
  serviceIndex,
  elevationIndex,
  serviceId,
  onChangeProgress = () => {},
  onSave = () => {},
  serviceName,
  estimationNumber,
  endDate,
  googleDriveFolderIds,
  detail,
  service,
  elevationId,
  scheduleId,
  estNr,
  typeOfWork,
  fullElevationName,
  pliSelected,
  setPliSelected,
}) => {
  const { userConfiguration } = useSelector((state) => state?.userConfig);

  const autoCompleteItems = (data = null, type = "") => {
    let item = { ...pliSelected };

    if (type === "dimensions") {
      let findProgressByDay = item?.data?.progressByDay?.find(
        (progress) => progress?.day === dayId
      );

      if (findProgressByDay) {
        if (!findProgressByDay.foreman) {
          findProgressByDay.foreman = {};
        }
        data.forEach((dim) => {
          findProgressByDay.foreman[dim] = item?.data?.[dim];
        });

        findProgressByDay.foreman.nameOfForeman = userConfiguration?.nameOfUser;
      }
    } else if (service?.isHoist) {
      message.error("You can't change the progress for this service");
    } else {
      let findBreakdown = item?.data?.breakdownValue?.find(
        (breakdown) => breakdown?.id === data?.id
      );

      if (findBreakdown) {
        findBreakdown.progressByDay = findBreakdown?.progressByDay?.map(
          (progress) => {
            if (progress?.day === dayId) {
              return {
                ...progress,
                foreman: {
                  ...progress?.foreman,
                  nameOfForeman: userConfiguration?.nameOfUser,
                  progress: 100,
                },
              };
            }
            return progress;
          }
        );
      }
    }
    setPliSelected(item);
  };

  return (
    <div
      className={`documentationsTable ${"light-ag-theme ag-theme-alpine"}`}
      style={{ width: "100%", height: "100%" }}
    >
      <AgGridReact
        {...{
          columnDefs: columnDefsForPliData({
            d1,
            d2,
            hasUploaded: (item) =>
              hasUploadedImages(
                item,
                userConfiguration,
                dayId,
                service,
                elevationIndex
              ),
          }),
          rowData: items?.map((pli) => ({
            ...pli,
            length: pli?.["length"] || pli?.originalPli?.["length"] || "-",
            height: pli?.["height"] || pli?.originalPli?.["height"] || "-",
            width: pli?.["width"] || pli?.originalPli?.["width"] || "-",
          })),
          rowHeight: 45,
          headerHeight: 32,
          animateRows: true,
          suppressDragLeaveHidesColumns: true,
          suppressRowClickSelection: true,
          onRowClicked: (data) => setPliSelected(data),
          defaultColDef: {
            resizable: true,
            enablePivot: true,
            enableColResize: true,
            enableRowGroup: true,
            editable: false,
            sortable: true,
            filter: true,
            pivot: true,
            enableRowGroup: true,
            enableValue: true,
          },
        }}
      />
      {Object.keys(pliSelected || {}).length > 0 && (
        <ProgressChanges
          {...{
            pliSelected,
            onCancel: () => setPliSelected({}),
            d1,
            d2,
            dayId,
            detailIndex,
            serviceIndex,
            elevationIndex,
            serviceId,
            onChangeProgress,
            onSave,
            endDate,
            serviceName,
            estimationNumber,
            estNr,
            googleDriveFolderIds,
            detail,
            service,
            elevationId,
            scheduleId,
            fullElevationName,
            typeOfWork,
            autoCompleteItems,
          }}
        />
      )}
    </div>
  );
};

export default TableView;
