import { useEffect, useState } from "react";
import { message } from "antd";
import { useSelector } from "react-redux";

import {
  handleEmailAttachments,
  handleGoogleDriveUpload,
} from "../../Projects/utils/email-attachments-helpers";
import { useEmailBoxContainer } from "../../Communication/components/EmailBox/providers/EmailBoxContainerProvider";
import { useResponsive } from "../../../../hooks";

const useHandleAttachments = ({
  base64,
  accessToken,
  category,
  driveRequest,
  rowObject,
  driveFunctions,
  folderDriveKeyName,
  dynamicFields,
  selectedTeam,
  crews,
  userConfiguration,
  defaultId,
  witnessCount,
  witnessSignings,
  rowObjectKey,
  pdfFileName,
  objectsWithPk = [],
  uploadedFiles,
}) => {
  const [emailBox, setEmailBox] = useState(false);
  const [uploadedAttachments, setUploadedAttachments] = useState([]);

  const { openEmailBox, setAttachments, attachments, emailBoxes } =
    useEmailBoxContainer();
  const { mobile } = useResponsive();

  const { emailActionType } = useSelector((state) => state.communication);

  const handleAttachments = async (uploadedFiles) => {
    if (emailBoxes.length > 0) {
      message.warning("Email Box is already opened!");
      return;
    }

    message.loading({ content: "Preparing attachments...", duration: 0 });
    try {
      openEmailBox(null, mobile);
      if (uploadedFiles.length > 0) {
        await handleUploadedFiles(uploadedFiles);
      }

      await handleEmailAttachments({
        base64,
        accessToken,
        category,
        driveRequest,
        rowObject,
        driveFunctions,
        folderDriveKeyName,
        createPdfParams: {
          dynamicFields,
          selectedTeam,
          crews,
          userConfiguration,
          defaultId,
          witnessCount,
          witnessSignings,
        },
        setAttachments,
        rowObjectKey,
        pdfFileName,
        objectsWithPk,
      });
    } catch (error) {
      console.error("[Error while preparing attachments]", error);
      message.error("Could not attach the file to email");
    } finally {
      message.destroy();
    }
  };

  const handleUploadedFiles = async (files = []) => {
    let newAttachments = [];

    try {
      for (const file of files) {
        if (!file.id) continue;

        const handledFile = await handleGoogleDriveUpload(file, driveRequest);
        newAttachments.push(handledFile);
      }

      setAttachments((prevAttachments) => {
        const combined = [
          ...prevAttachments.filter(Boolean),
          ...newAttachments.filter(Boolean),
        ];

        return Array.from(new Set(combined));
      });

      return true;
    } catch (error) {
      message.error("Error while getting the file from Google Drive");
      console.error("[Error while getting uploaded files]", error);
    }
  };

  useEffect(() => {
    if (uploadedFiles?.length) {
      setUploadedAttachments(uploadedFiles);
    }
  }, [uploadedFiles]);

  useEffect(() => {
    if (!uploadedAttachments?.length || emailBoxes?.length === 0) return;

    const newUploadedFiles = uploadedFiles?.filter(
      (file) =>
        !uploadedAttachments.some((attachment) => attachment.id === file.id)
    );

    if (
      (emailActionType === "DRAFT" && !newUploadedFiles.length) ||
      (emailActionType === "COMPOSE" && emailBoxes?.length === 2)
    ) {
      return;
    }

    const retrieveBlob = async (file) => {
      if (!file.url) return;

      try {
        const response = await driveRequest.getImageSrc(file.id);

        return { ...file, blob: response?.src };
      } catch (error) {
        console.error("Error fetching or converting file:", error);
        return null;
      }
    };

    const processFiles = async () => {
      message.loading("Attaching files to email...", 0);

      const filesToProcess =
        emailActionType === "DRAFT" ? newUploadedFiles : uploadedFiles;
      const newFilesPromises = filesToProcess.map(retrieveBlob);
      const newFiles = await Promise.all(newFilesPromises);

      const validFiles = newFiles
        .filter((file) => file !== null)
        ?.map((file) => ({
          id: file.id,
          type: file.type,
          mimeType: file.mimeType,
          name: file.name,
          blob: file.blob,
        }));

      setAttachments(validFiles);

      message.destroy();
      message.success("Files attached successfully!");
    };

    processFiles();
  }, [uploadedAttachments, emailActionType]);

  return {
    handleAttachments,
    handleUploadedFiles,
    emailBox,
    attachments,
    setAttachments,
    setEmailBox,
  };
};

export default useHandleAttachments;
