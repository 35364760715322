import { useMemo, useState } from "react";
import { API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EyeFilled } from "@ant-design/icons";
import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
  showWarningMsg,
} from "../../../../../../../../utils";
import { Tick } from "../../../src";
import { TourGuide } from "../../../common";
import RoleWarningModal from "./RoleWarningModal";
import { ReactComponent as Back } from "../../../src/back.svg";
import { userRolesStepData } from "../utils";
import { userGroupsConfig } from "../../../../../../../../actions";
import {
  ExportOrEmailDropdown,
  LogsButton,
  MondayButton,
} from "../../../../../../../commonComponents";
import PreviewAccessModal from "./PreviewAccessModal/PreviewAccessModal";
import {
  filterRoutes,
  removeDescription,
} from "../../../../Management/EditUser/components/UserAccessWrites/utils/filterRoutes";
import {
  exportToExcel,
  onGeneratePDF,
  uploadExcelToDrive,
} from "../utils/exportToPdfHandler";
import { useEditLogs, useResponsive } from "../../../../../../../../hooks";
import PdfHeadersModal from "../../../../../../../commonComponents/PdfHeadersModal/PdfHeadersModal";
import useExportHeadersModal from "../../../../../../../../hooks/useExportHeadersModal";
import { tableHeaders } from "../constants/tableHeaders";
import { isEmpty } from "lodash";

const RoleFooter = ({
  accessConfiguration: accessConfig,
  unchangedData,
  setUnchangedData,
  departmentGroups,
  notificationSettings,
  notificationConfig,
}) => {
  const [warningModalVisible, setWarningModalVisible] = useState(false);
  const [previewAccessVisible, setPreviewAccessVisible] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { departmentName, role } = useParams();

  const { mobile } = useResponsive();

  const { base64 } = useSelector((state) => state.base64);
  const { userGroups } = useSelector((state) => state.userGroups);
  const { saveAddedLogs } = useEditLogs();

  const accessConfiguration = useMemo(() => {
    if (
      !accessConfig ||
      !(accessConfig || {}).hasOwnProperty("getFinalObject")
    ) {
      return notificationConfig;
    } else {
      const accesses = accessConfig.getFinalObject();
      return {
        ...accesses,
        "Notification Preferences":
          notificationConfig["Notification Preferences"],
      };
    }
  }, [accessConfig]);

  let initialLogs = {
    route: {},
    sidebar: {},
    notificationPref: {},
  };

  let logs = {
    actionType: "Edit",
    category: role,
    recordId: `Role Access ${role}`,
    updatedAt: Date.now(),
    currentData: { ...initialLogs },
    previousData: { ...initialLogs },
    recordName: "Route",
    label: "",
    updatedKeys: [],
  };

  const currentRole = role || "Engineer/Architect";

  const userGroup = userGroups
    .filter(
      (group) =>
        group.departmentName.toLowerCase() === departmentName.toLowerCase()
    )
    .find((role) => role.groupName.toLowerCase() === currentRole.toLowerCase());

  const previewAccessData = departmentGroups.find(
    (el) => el[currentRole] !== undefined
  )?.[currentRole];

  const generateLogForNotificationPref = (tmpUnchangedData) => {
    const prevNotificationPref =
      tmpUnchangedData["Notification Preferences"]?.notifications?.topics || {};
    const newNotificationPref =
      notificationConfig["Notification Preferences"]?.notifications?.topics ||
      {};

    const prevTopics = Object.values(prevNotificationPref);
    const newTopics = Object.values(newNotificationPref);

    prevTopics.forEach((item, index) => {
      for (const [key, value] of Object.entries(item)) {
        if (value !== newTopics[index][key]) {
          logs.currentData.notificationPref[key] = newTopics[index][key]
            ? "Yes"
            : "No";
          logs.previousData.notificationPref[key] = value ? "Yes" : "No";
        }
      }
    });
  };

  const removeEmptyKeys = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (isEmpty(obj[key])) {
        delete obj[key];
      }
    });
    return obj;
  };

  function generateLogs(callBack) {
    try {
      const isSuperAdmin = role === "Super Admin";
      let tmpUnchangedData = { ...unchangedData };

      const accessLogs = accessConfig.getLogs();
      const descLogs = accessConfig.getOnlyDescriptionLogs();
      let routeAndSidebar = isSuperAdmin ? descLogs : accessLogs;
      generateLogForNotificationPref(tmpUnchangedData);
      logs.currentData.route = routeAndSidebar.currentData.route;
      logs.previousData.route = routeAndSidebar.previousData.route;
      logs.currentData.sidebar = routeAndSidebar.currentData.sidebar;
      logs.previousData.sidebar = routeAndSidebar.previousData.sidebar;
      removeEmptyKeys(logs.currentData);
      removeEmptyKeys(logs.previousData);
      if (isEmpty(logs.currentData)) {
        showWarningMsg({ content: "No changes detected." });
        logs = {
          ...logs,
          currentData: { ...initialLogs },
          previousData: { ...initialLogs },
        };
        return;
      } else {
        callBack(logs);
      }
    } catch (error) {
      console.error("Error generating logs", error);
    }
  }

  const saveChanges = async () => {
    const isSuperAdmin = role === "Super Admin";

    const { Route, Sidebar } = accessConfiguration || {};

    let body = {};
    if (isSuperAdmin) {
      body = {
        preferences: notificationConfig,
        routeConfig: Route,
        sidebarConfig: Sidebar,
      };
    } else {
      const routeConfig = removeDescription(filterRoutes(Route));
      const sidebarConfig = removeDescription(filterRoutes(Sidebar));

      body = {
        preferences: notificationConfig,
        routeConfig,
        sidebarConfig,
      };
    }

    showLoadingMsg({ content: "Saving changes. Please wait..." });
    await API.put("userGroups", `/userGroups/${userGroup.groupId}`, {
      body: {
        groupConfigs: {
          ...body,
        },
      },
    })
      .then(() => {
        dispatch(
          userGroupsConfig({
            groupId: userGroup.groupId,
            groupConfigs: {
              ...body,
            },
          })
        );
        showSuccessMsg({ content: "Changes saved successfully." });
        setUnchangedData({
          ...accessConfiguration,
          "Notification Preferences": notificationConfig,
        });
        saveAddedLogs(warningModalVisible);
      })
      .catch((err) => {
        showErrorMsg({ content: "Error saving changes." });
        console.error("roleAccess, saveChanges -> error", { err });
      })
      .finally(() => {
        setWarningModalVisible(false);
      });
  };

  const additionalData = {
    accessConfiguration,
    notificationSettings,
    currentRole,
    logs,
    base64,
    includeNotificationPref: true,
  };

  const { openModal, getHeadersModal, handleCloseModal, handleFinishModal } =
    useExportHeadersModal({
      additionalData,
      onGeneratePDF,
      exportToExcel,
    });

  return (
    <>
      <div
        className={`submitButtonContainer ${
          mobile && "submitButtonContainer-mobile"
        }`}
      >
        <div className="left">
          <MondayButton
            className="backButton"
            Icon={<Back />}
            // onClick={() => navigate(`/settings/rolesAccess/${departmentName}`)}
            onClick={() =>
              location.key === "default"
                ? navigate(`/settings/rolesAccess`)
                : navigate(-1)
            }
          >
            Back
          </MondayButton>
          <TourGuide
            {...{
              tab: 7,
              category: "rolesAccessRightsChanges",
              stepsData: userRolesStepData,
            }}
          />
        </div>
        <div className="right">
          <LogsButton
            {...{
              logsTitle: `Role Access ${role} Logs`,
              filtersObj: { recordId: `Role Access ${role}` },
            }}
          />
          <MondayButton
            id={"previewButton"}
            className="mondayButtonBlue iconButtons"
            Icon={<EyeFilled style={{ fontSize: "22px", color: "#fff" }} />}
            onClick={() => setPreviewAccessVisible(true)}
          ></MondayButton>
          <ExportOrEmailDropdown
            {...{
              rowData: true,
              onGeneratePDF: (action, getDocDefinition) =>
                getDocDefinition
                  ? onGeneratePDF(
                      action,
                      additionalData,
                      getDocDefinition,
                      null,
                      saveAddedLogs
                    )
                  : getHeadersModal(action, getDocDefinition),
              exportGridToExcel: (getDocDefinition) =>
                getDocDefinition
                  ? exportToExcel(
                      additionalData,
                      getDocDefinition,
                      null,
                      saveAddedLogs
                    )
                  : getHeadersModal(false, getDocDefinition, "excel"),
              uploadExcelToDrive: (driveRequest, driveFolderId) =>
                uploadExcelToDrive(additionalData, driveRequest, driveFolderId),
              defaultDocDefinition: true,
              title: `RolesAccess_${currentRole}`,
            }}
          />

          <MondayButton
            id={"submitButton"}
            className="mondayButton"
            Icon={<Tick />}
            // onClick={() => setWarningModalVisible(true)}
            onClick={() =>
              generateLogs((newLog) => setWarningModalVisible(newLog))
            }
          >
            Submit
          </MondayButton>
        </div>
      </div>
      {previewAccessVisible && (
        <PreviewAccessModal
          {...{
            previewAccessData,
            previewAccessVisible,
            setPreviewAccessVisible,
            additionalData,
            saveAddedLogs,
          }}
        />
      )}
      {warningModalVisible && (
        <RoleWarningModal
          {...{
            saveChanges,
            warningModalVisible,
            setWarningModalVisible,
            roles: true,
          }}
        />
      )}
      {openModal && (
        <PdfHeadersModal
          {...{
            isOpen: openModal,
            onClose: () => handleCloseModal(),
            onFinish: (data) => handleFinishModal(data),
            headers: tableHeaders,
            title: "Role Access",
            nested: true,
          }}
        />
      )}
    </>
  );
};

export default RoleFooter;
