import { useState } from "react";
import { API } from "aws-amplify";

import { EntryType } from "../payrollLiveTypes";

type QueryStringParameters = {
  filters?: string;
  getMaxLimit?: string;
  withPagination?: string;
  ExclusiveStartKey?: string;
};

type PropType = {
  id?: string;
  queryStringParameters?: QueryStringParameters;
  method: "post" | "put" | "delete" | "get" | "updateBatch";
  body?: Partial<EntryType> | Array<Partial<EntryType>> | Array<string>;
};

function sleep(delay) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}

const TABLE = "degEntries";

export async function entriesApiCrud({
  id,
  body,
  method,
  queryStringParameters,
}: PropType) {
  if (method === "post") {
    return API.post(TABLE, `/${TABLE}`, {
      body: {
        entries: body,
      },
    });
  }
  if (method === "put") {
    return API.put(TABLE, `/${TABLE}/${id}`, { body });
  }
  if (method === "delete") {
    return API.del(TABLE, `/${TABLE}/${id}`, {
      body: {
        entries: body,
      },
    });
  }
  if (method === "get") {
    return API.get(TABLE, `/${TABLE}`, { queryStringParameters });
  }
  if (method === "updateBatch") {
    return API.post("updateBatch", "/updateBatch", {
      body: {
        entries: body,
      },
    });
  }
}

type EntriesCrudProps = {
  entries: Array<Partial<EntryType>>;
  onErrorCallback?: (error: any) => void;
  onSuccessCallback?: (res?: any) => void;
};

export function useEntriesApi() {
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  async function updateEntries({
    entries,
    onErrorCallback,
    onSuccessCallback,
  }: EntriesCrudProps) {
    setLoading(true);
    const firstEntry = { ...entries[0] };
    const apiProps: PropType =
      entries?.length === 1
        ? { method: "put", body: firstEntry, id: entries[0]?.entryId }
        : { method: "updateBatch", body: entries };

    if (entries?.length === 1) {
      delete firstEntry?.entryId;
      delete firstEntry.duration;
      delete firstEntry?.userId;
    }

    if (entries?.length >= 1000) {
      try {
        for (let i = 0; i < entries.length; i += 1000) {
          const entriesBatch = entries.slice(i, i + 1000);
          if (i) {
            await sleep(500);
          }
          await entriesApiCrud({
            method: "updateBatch",
            body: entriesBatch,
          });
        }
        if (onSuccessCallback) {
          onSuccessCallback(entries);
        }
      } catch (err) {
        setError("Error updating entries.");
        console.log("Error updating entries: ", err);
        if (onErrorCallback) {
          onErrorCallback(err);
        }
      }
    } else {
      entriesApiCrud(apiProps)
        .then((res) => {
          if (onSuccessCallback) {
            onSuccessCallback(res);
          }
        })
        .catch((err) => {
          setError("Error updating entries.");
          console.log("Error updating entries: ", err);
          if (onErrorCallback) {
            onErrorCallback(err);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  async function removeEntries({
    entries,
    onErrorCallback,
    onSuccessCallback,
  }: EntriesCrudProps) {
    setLoading(true);
    const idsToDelete = entries.map((el) => el?.entryId);
    entriesApiCrud({ method: "delete", body: idsToDelete, id: "123" })
      .then((res) => {
        if (onSuccessCallback) {
          onSuccessCallback(res);
        }
      })
      .catch((err) => {
        setError("Error deleting entries");
        console.log("Error deleting entries: ", err);
        if (onErrorCallback) {
          onErrorCallback(err);
        }
      })
      .finally(() => setLoading(false));
  }

  async function postEntries({
    entries,
    onErrorCallback,
    onSuccessCallback,
  }: EntriesCrudProps) {
    setLoading(true);

    entriesApiCrud({ method: "post", body: entries })
      .then((res) => {
        if (onSuccessCallback) {
          onSuccessCallback(res);
        }
      })
      .catch((err) => {
        setError("Error posting Entries");
        console.log("Error posting Entries: ", err);
        if (onErrorCallback) {
          onErrorCallback(err);
        }
      })
      .finally(() => setLoading(false));
  }

  async function getEntries({
    onErrorCallback,
    onSuccessCallback,
    queryStringParameters,
  }: {
    onErrorCallback?: (err: any) => void;
    onSuccessCallback?: (res: any) => void;
    queryStringParameters: QueryStringParameters;
  }) {
    setLoading(true);
    return entriesApiCrud({
      method: "get",
      queryStringParameters,
    })
      .then((res) => {
        if (onSuccessCallback) {
          onSuccessCallback(res);
        }
      })
      .catch((err) => {
        setError("Error getting Entries");
        console.log("Error getting Entries: ", err);
        if (onErrorCallback) {
          onErrorCallback(err);
        }
      })
      .finally(() => setLoading(false));
  }

  return {
    error,
    loading,
    setError,
    setLoading,
    getEntries,
    postEntries,
    updateEntries,
    removeEntries,
  };
}
