import _ from "lodash";
import { useState, useEffect } from "react";
import { message } from "antd";
import { fetchData } from "../components/SidebarPages/Fleet/utils/fetchData";
// import { groupSchedulesByServices } from "../../../Projects/Tabs/ScheduleView/AgGridData/utils/rowDataGenerator";

/**
 * Function that creates a new element to handle pagination in the grid
 * @returns a new DOM element
 */
const isDarkMode = localStorage.getItem("darkmode") || false;
function createNewElement() {
  let newItem = document.createElement("span");
  newItem.setAttribute("id", "customNextButton");
  newItem.style.color = isDarkMode === "true" ? "#fff" : "#181d1f";
  newItem.style.cursor = "pointer";
  newItem.style.fontSize = "14";
  newItem.style.fontWeight = "bolder";
  newItem.innerHTML = "Get Other Data";

  return newItem;
}

function gridPaginationHelper() {
  //update Input Position And Pathname on Pagination
  const categoryLabels = {
    leads: "Leads",
    opportunities: "Opportunities",
    estimations: "Estimations",
    projects: "Projects",
    scheduling: "Schedules",
    default: "",
  };
  const pathArr = window.location.pathname.split("/");
  const cat = pathArr?.length > 2 ? "default" : pathArr[1];

  //------------------movePaginationInput
  const flagEl = document?.querySelector(".ag-paging-page-summary-panel");
  const paginationPanel = document?.querySelector(".ag-status-bar-right");
  if (flagEl && paginationPanel) {
    const offsetWidth1 = flagEl?.offsetWidth || 75;
    paginationPanel.style.right = `${offsetWidth1}px`;
  }
  //------------------movePaginationInput

  //------------------set PathName on pagination
  const spanElements = document.querySelectorAll(
    ".ag-paging-row-summary-panel-number"
  );
  const spanElement = spanElements?.[2] || null;
  if (!!spanElement) {
    const name = categoryLabels?.[cat] ?? categoryLabels.default;
    const oldVal = spanElement.innerHTML;
    if (oldVal.includes(categoryLabels?.[cat])) return;
    spanElement.innerHTML = oldVal + " " + name;
  }
  //------------------set PathName on pagination
}

//holds the HTML for the original grid button
const originalGridButton =
  '<span class="ag-icon ag-icon-next" unselectable="on" role="presentation" ></span>';

/**
 * Resets the original grid button HTML
 */
function resetOriginalButton() {
  let t = document?.querySelector('[aria-label="Next Page"]');
  if (!!t) {
    t.innerHTML = originalGridButton;
    gridPaginationHelper();
  }
}

/**
 * Gives event handlers to "Previous" buttons in the grid footer
 */
function giveHandlersToPrevious() {
  //gets the buttons that control the pagination for the previous pages
  //when the user want to access a previous page, we want to set the original button back
  let prevButton = document?.querySelector('[aria-label="Previous Page"]');
  let firstPage = document?.querySelector('[aria-label="First Page"]');
  firstPage?.addEventListener("click", () => {
    resetOriginalButton();
  });
  prevButton?.addEventListener("click", () => {
    resetOriginalButton();
  });
}

/**
 * This is a hook that replaces the disabled "Next" button of AgGrid with a custom button
 * @param {String} queryTable The table to make requests to
 * @param {String} recordIdKey The key that holds the ID of the data
 * @param {Function} setLoading Function that manipulates the page's loading screen
 * @param {Object} filtersData The filtering object, when filters are active the pagination controls are off
 * @param {Number} gridPaginationSize The page's grid pagination size
 * @param {String} tabPosition Gets the tab position from the history hook passed down from the tabs component
 * @param {Boolean} paginationWithFilters Boolean that indicates if pagination is active alongside filters. When true, pagination will be used to fetch data, even if filters are active.
 * @returns the row data to be put in the grid and multiple data handlers
 */
export function usePaginationControls(
  queryTable = "",
  recordIdKey = "",
  setLoading = () => {},
  gridPaginationSize = 12,
  tabPosition = "",
  filtersData = [],
  paginationWithFilters = false
) {
  //holds all the grid data
  const [rowData, setRowData] = useState([]);
  const [statuses, setStatuses] = useState({});

  //holds the last evaluated ID
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState();

  //holds the number of times we fetched the data
  //Useful wherever there is a programmatic refresh button
  //we want to get the same number of records, even if they're updated
  const [timesFetched, setTimesFetched] = useState(0);
  let lastEvalKey = lastEvaluatedKey; //holds the last evaluated ID
  let tmpData = rowData; //holds the data that needs to be attached to the rowData
  /**
   * Function that returns the next page or all the data depending on the method call
   * @param {String} method -> "page" | "all": The data fetching method
   * @param {Boolean} firstCall Useful when the number of records is smaller than 12
   */

  async function getPaginatedData(
    method,
    firstCall = false,
    filters = [],
    withPagination
  ) {
    //We want to differentiate the first call from the others. The "LastEvaluatedKey" does not get
    //sent on two occasions: on first call when the total number of records is smaller than 12 and
    //when the last set of data is sent
    let tmpTimesFetched = 0; //to be updated on every successful fetch
    let allStatuses = statuses;
    if (firstCall) setLoading(true);

    if (method === "all") {
      lastEvalKey = undefined;
      // setRowData([]);
      tmpData = [];
    }
    /**
     * The actual data fetching function, when called recursively through the method, fetches all the data
     */
    async function getNextPage() {
      await fetchData(queryTable, queryTable, {
        queryStringParameters: {
          ExclusiveStartKey: !!lastEvalKey ? lastEvalKey : undefined,
          withPagination,
          ...(Array.isArray(filters) &&
            filters.length > 0 && { filters: JSON.stringify(filters) }),
          ...(withPagination &&
            method === "all" && {
              getMaxLimit: "true", // When this key is set to "true", data retrieval is based on the total size limit, not restricted to just 24 records.
            }),
        },
      }).then(async (res) => {
        if (Object.keys(res?.countStatuses || {}).length > 0)
          allStatuses = res.countStatuses;
        if (Array.isArray(res)) {
          tmpData = res;
          lastEvalKey = "lastPage";
          return;
        }

        if (!!res?.LastEvaluatedKey) {
          lastEvalKey = res["LastEvaluatedKey"][recordIdKey];
          ++tmpTimesFetched;

          if (method === "all") {
            //gets all the data
            await getNextPage();
          } else {
            //method used when re-initializing the calls programmatically
            if (!!firstCall && timesFetched > 0) {
              while (tmpTimesFetched < timesFetched) {
                await getNextPage();
              }
            }
          }
        } else {
          lastEvalKey = "lastPage";
        }
        const queryTableKey =
          queryTable === "scheduleOfValues" ? "schedules" : queryTable;
        tmpData = firstCall
          ? res[queryTableKey]
          : tmpData.concat(res[queryTableKey]);
      });
    }

    await getNextPage()
      .then(() => {
        let d = tmpData;
        if (window.location.pathname === "/settings/editLogs") {
          d = tmpData?.filter((el) => typeof el?.actionType === "string");
        }
        setRowData(_.uniqBy(d, recordIdKey));
        setStatuses(allStatuses);
        setLastEvaluatedKey(lastEvalKey);
        if (firstCall) setLoading(false);

        setTimesFetched(0);

        //we want to programmatically click the "Next" button in order to go to the next retrieved data
        if (gridPaginationSize === 12) {
          try {
            let tmp = document.querySelector('[aria-label="Next Page"]');
            tmp.click();
          } catch {}
          onPaginationChanged();
        } else {
          //if the pagination size is smaller than the amount of records we get,
          //we do not want to navigate to the next page since there'll be new records
          //underneath the existing data that will be skipped by the click
          resetOriginalButton();
        }
      })
      .catch((err) => {
        console.log("Error Fetching Paginated Data: ", err);
        message.error(
          "Something went wrong while fetching data. Please try again later."
        );
        if (firstCall) setLoading(false);
      });
  }

  /**
   * This is the pagination handler of the grid. It is to be passed as a prop to the base page.
   * Scans the grid footer for the disabled "Next" arrow and replaces it with a custom element
   * @param {gridApi} params Holds the AgGrid api
   */
  function onPaginationChanged(params = {}) {
    //if the user clicked "fetch all" we don't want to replace the "Next" button
    if (lastEvaluatedKey !== "lastPage") {
      try {
        //if there are filters active we do not want to replace the default button
        // if (Object.keys(filtersData).length !== 0) throw new Error();
        if (!lastEvaluatedKey) throw new Error();

        //gets the disabled "Next" button on the last page
        let tmp = document?.querySelector(
          '[aria-label="Next Page"][class="ag-paging-button ag-disabled"]'
        );

        //on last page replaces the grid arrow with the custom button
        let newItem = createNewElement();
        newItem.addEventListener("click", () => {
          getPaginatedData("page", false, filtersData, true);
        });

        try {
          //if the element is not found, we throw an error to replace the original button
          tmp.replaceChild(newItem, tmp.firstChild);
          gridPaginationHelper();
        } catch {
          throw new Error();
        }
        return;
      } catch {
        resetOriginalButton();
      }
    } else {
      resetOriginalButton();
    }
  }

  /**
   * When called resets all states to simulate a first-render fetch
   */
  async function reInitializeFetch() {
    //we loop through the fetches only when we have more than 12 records
    rowData?.length > 12 && setTimesFetched(Math.ceil(rowData?.length / 12));
    setLastEvaluatedKey();
    lastEvalKey = undefined;
    setRowData([]);
    await getPaginatedData("page", true);
  }

  async function filterSearchData(filters, clear = false) {
    if (!clear) {
      rowData?.length > 12 && setTimesFetched(Math.ceil(rowData?.length / 12));
    }
    // //we loop through the fetches only when we have more than 12 records
    setLastEvaluatedKey();
    lastEvalKey = undefined;
    tmpData = [];
    setRowData([]);
    await getPaginatedData("page", false, filters, true);
  }

  useEffect(() => {
    giveHandlersToPrevious();
    let firstPage = document?.querySelector('[aria-label="First Page"]');
    firstPage?.click();
    onPaginationChanged();
  }, [tabPosition]);

  useEffect(() => {
    const hasFilters = !!paginationWithFilters
      ? false
      : Array.isArray(filtersData) && filtersData.length > 0;
    //initial data call
    getPaginatedData(
      hasFilters ? "all" : "page",
      true,
      filtersData,
      filtersData ? true : undefined
    ).then(() => {
      onPaginationChanged();

      try {
        //we give the "Previous" button event handlers
        giveHandlersToPrevious();

        //we simulate a page click on first render, so when the pagination
        //is lower than 12 we stay on the same page
        let firstPage = document?.querySelector('[aria-label="First Page"]');
        firstPage.click();
      } catch {}
    });

    return () => {
      resetOriginalButton();
    };
  }, []);

  return {
    rowData,
    statuses,
    async fetchAllHandler(withPagination, gridApi) {
      const loadingIndicator = gridApi;
      loadingIndicator
        ? gridApi?.showLoadingOverlay?.()
        : message.loading("fetching");
      return await getPaginatedData("all", false, [], withPagination).then(
        () => {
          const loadingIndicator = gridApi;
          loadingIndicator
            ? gridApi?.showLoadingOverlay?.()
            : message.loading("fetching");
          resetOriginalButton();
        }
      );
    },
    onPaginationChanged,
    reInitializeFetch,
    filterSearchData,
  };
}
