import types from "../../types";

const initialState = {
  teams: [],
};

const teamsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.TEAMS:
      return {
        ...state,
        teams: payload,
      };

    default:
      return state;
  }
};

export default teamsReducer;
