import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { filterTables } from "../../../../utils";
import { fetchData } from "../../Fleet/utils";
import { getFiltersObject, getPanelObject } from "../../../../utils";
import { columnDefs, excelColumnsConfig } from "./AgGridData";
import NewSafetyInspection from "./Modal/NewSafetyInspection";
import PreSafetyInspectionModal from "./Modal/PreSafetyInspectionModal";
import BasePage from "../../BasePage";
import { LoadableComp } from "../../XComponents";
import { getExcelColumnKeys } from "../../../../utils/getExcelColumnKeys";
import { getChartingOptions } from "./utils/getChartingOptions";
import MobileBasePage from "../../../MobileComponents/MobileBasePage/MobileBasePage";
import { useResponsive } from "../../../../hooks";

function SafetyInspections({ projectId, projectName }) {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { programFields } = useSelector((state) => state.programFields);

  const [rowData, setRowData] = useState();
  const [accessRight] = useState(
    userConfiguration?.routeConfig?.find(
      ({ title }) => title === "Safety Inspection"
    )
  );
  const { mobile, tablet } = useResponsive();

  const [edit, setEdit] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const filtersObject = getFiltersObject(programFields, "Safety Inspection");
  const panelObject = getPanelObject(programFields, "Safety Inspection");
  const [gridApi, setGridApi] = useState();

  const navigate = useNavigate();
  const location = useLocation();

  function getRowData() {
    (projectId
      ? filterTables("allInspections", "projectId", projectId)
      : fetchData("allInspections")
    )
      .then((inspections) => {
        const formattedData = inspections?.map((inspection) => ({
          ...inspection,
          inspectionDate: inspection?.inspectionObject?.inspectionDate || null,
          projectName: inspection?.inspectionObject?.projectName || "N/A",
          scheduleAddress:
            inspection?.inspectionObject?.scheduleAddress || "N/A",
          accountName: inspection?.inspectionObject?.accountName || "N/A",
        }));
        setRowData(formattedData);
      })
      .catch((err) => {
        console.log("Error getting data: ", err);
        setRowData([]);
      });
  }

  function reloadGrid(gridApi) {
    gridApi.showLoadingOverlay();
    getRowData();
  }

  useEffect(() => {
    getRowData();
  }, []);

  const exportGridToExcel = (tableColumns) => {
    gridApi.exportDataAsExcel({
      columnKeys: getExcelColumnKeys(gridApi, tableColumns),
      processCellCallback(params) {
        const value = params?.value;
        return value === undefined ? "" : `${value}`;
      },
    });
  };

  const columnDefsMemo = useMemo(() => {
    return columnDefs({ navigate });
  }, []);

  if (!accessRight) {
    <></>;
  }

  return (
    <LoadableComp loading={!rowData}>
      {mobile || tablet ? (
        <MobileBasePage
          title="Safety Inspection"
          rowData={rowData}
          columnDefs={columnDefsMemo}
          reloadData={reloadGrid}
        />
      ) : (
        <BasePage
          {...{
            title: "Safety Inspection",
            rowData,
            filtersObject,
            panelObject,
            customNew: setEdit,
            exportGridToExcel,
            defaultExcelExportParams: {
              columnKeys: excelColumnsConfig,
              fileName: "Safety Inspection",
            },
            columnDefs: columnDefsMemo,
            getGridApi: (e) => {
              setGridApi(e);
            },
            getChartingOptions: (data) => getChartingOptions(data),
            onRowDoubleClicked(param) {
              navigate(`/safetyInspections/${param?.inspectionId}`, {
                state: {
                  ...location.state,
                  rowObject: param,
                },
              });
            },
            hasNew: true,
            isTab: false,
            reloadGrid,
          }}
        />
      )}
      {edit && (
        <PreSafetyInspectionModal
          {...{
            open: edit,
            onCancel: () => {
              setEdit(false);
            },
            onSafetySelected: (inc) => {
              setEdit(false);
              setSelectedRow(inc);
            },
            projectId,
          }}
        />
      )}
      {!!selectedRow && (
        <NewSafetyInspection
          {...{
            visible: !!selectedRow,
            refreshTable(object) {
              let o = object[0];
              setRowData([
                {
                  ...o,
                  projectName: o?.inspectionObject?.projectName,
                  accountName: o?.inspectionObject?.accountName,
                  scheduleAddress: o?.inspectionObject?.scheduleAddress,
                },
                ...rowData,
              ]);
            },
            setVisible: setSelectedRow,
            inspectionCategory: selectedRow?.inspectionCategory,
            rowObject: selectedRow,
            propProjId: projectId,
            propAddress: projectName,
          }}
        />
      )}
    </LoadableComp>
  );
}

export default SafetyInspections;
