import { isTimestamp } from "./isTimeStamp";

export const dataTypes = [
  { id: 1, value: "string", label: "String" },
  { id: 2, value: "number", label: "Number" },
  { id: 3, value: "boolean", label: "Boolean" },
  { id: 4, value: "date", label: "Date" },
  { id: 5, value: "array", label: "Array" },
  { id: 6, value: "object", label: "Object" },
];

export function getKeyTypes(dataSample) {
  return Object.keys(dataSample).map((key) => {
    const sampleValue = dataSample[key];
    let type = "string"; // Default to string

    if (typeof sampleValue === "number") return { value: key, type: "number" };

    if (typeof sampleValue === "boolean")
      return { value: key, type: "boolean" };

    if (sampleValue instanceof Date) return { value: key, type: "date" };

    if (Array.isArray(sampleValue)) return { value: key, type: "array" };

    if (typeof sampleValue === "object" && sampleValue !== null)
      return { value: key, type: "object" };

    return { value: key, type };
  });
}
