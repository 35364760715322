import dayjs from "dayjs";

import InspectionGallery from "../../../../../integrations/AgGridDatas/components/InspectionGallery";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import { Notes } from "../../../../commonComponents/Notes/Notes";
import { setDateMonth } from "../../../Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { sortDates } from "../../../ToDos/AgGridData/columnDefs";
import RedirectApprovals from "../../../Approvals/components/RedirectApprovals/RedirectApprovals";
import { AvatarImages } from "../../../../commonComponents";

export const excelColumnsConfig = [
  "inspectionName",
  "inspectionDate",
  "inspectionCategory",
  "inspectionStatus",
  "projectName",
  "accountName",
  "scheduleAddress",
  "createdBy",
  "createdAt",
];

/**
 * Generates an array of column definitions for the AgGrid component.
 *
 * @param {Function} navigate - The navigate function used for routing.
 * @returns {Array} An array of column definitions.
 */
export function columnDefs({ navigate }) {
  return [
    {
      headerName: "Inspection Name",
      field: "inspectionName",
      minWidth: 200,
      valueGetter({ data }) {
        return data?.inspectionName || "N/A";
      },
      link: (data) => `/safetyInspections/${data?.inspectionId}`,
    },
    {
      headerName: "Date of Inspection",
      field: "inspectionDate",
      filter: "agDateColumnFilter",
      cellRenderer: ({ value }) => {
        return dayjs(value).format("MM/DD/YYYY") !== "Invalid Date"
          ? `${dayjs(value).format("MM/DD/YYYY")}`
          : "--";
      },
      getQuickFilterText: ({ value }) => value,
      comparator: (date1, date2) => sortDates(date1, date2, "MM/DD/YYYY"),
    },
    {
      headerName: "Inspection Category",
      field: "inspectionCategory",
    },
    {
      headerName: "Status",
      field: "inspectionStatus",
      // minWidth: 200,
      // maxWidth: 200,
    },
    {
      headerName: "Project Name",
      field: "projectName",
    },
    {
      headerName: "Client Name",
      field: "accountName",
    },
    {
      headerName: "Schedule Address",
      field: "scheduleAddress",
    },
    {
      headerName: "Created At",
      field: "createdAt",
      sort: "desc",
      cellRenderer: ({ value }) => {
        return dayjs(value).format("MM/DD/YYYY") !== "Invalid date"
          ? `${dayjs(value).format("MM/DD/YYYY")}`
          : "--";
      },
      comparator: (date1, date2) => sortDates(date1, date2, "MM/DD/YYYY"),
    },
    {
      headerName: "Created By",
      field: "createdBy",
      valueGetter({ data }) {
        return data?.createdBy?.name || "No Data";
      },
      cellRenderer({ value }) {
        return (
          <AvatarImages
            {...{
              nameOfUsers: [value],
            }}
          />
        );
      },
    },
    {
      headerName: "Team",
      field: "teamsConfiguration",
      enableRowGroup: false,
      pdfExportOptions: {
        skipColumn: true,
      },
      cellRenderer({ value }) {
        const otherCondition = (variable) => {
          if (typeof variable !== "string") {
            return [];
          } else {
            return /[a-zA-Z]/.test(variable) ? [variable] : [];
          }
        };
        const members = Array.isArray(value)
          ? (value || [])
              .flatMap(({ members = [] }) =>
                members.map(({ nameOfUser }) => nameOfUser)
              )
              .filter(Boolean)
          : otherCondition(value);

        return (
          <AvatarImages
            {...{
              nameOfUsers: members,
            }}
          />
        );
      },
    },
    {
      headerName: "Last Modified By",
      field: "lastModifiedBy",
      valueGetter({ data }) {
        return data?.lastModifiedBy?.name || "No Data";
      },
      cellRenderer({ value, data }) {
        if (value === "No Data") {
          return (
            <AvatarImages
              {...{
                nameOfUsers: [data?.createdBy?.name],
              }}
            />
          );
        }
        return (
          <AvatarImages
            {...{
              nameOfUsers: [value],
            }}
          />
        );
      },
    },
    {
      headerName: "Gallery",
      field: "gallery",
      resizable: false,
      sortable: false,
      enableRowGroup: false,
      pdfExportOptions: {
        skipColumn: true,
      },
      cellRendererFramework({ data }) {
        return InspectionGallery({
          data: {
            safetyFolderId: data?.googleDriveFolderIds?.safetyInspectionObject,
            ...data,
          },
          title: data?.inspectionName,
        });
      },
    },
    {
      headerName: "From Request",
      field: "fromRequest",
      minWidth: 100,
      valueGetter: ({ data }) => {
        return !!data?.inspectionObject?.requestId ? "Yes" : "No";
      },
      cellStyle: { display: "flex", justifyContent: "center" },
      cellRendererFramework: ({ value, data }) => (
        <RedirectApprovals
          {...{
            value,
            requestId: data?.inspectionObject?.requestId,
          }}
        />
      ),
    },
  ];
}
