import moment from "moment";

import { callGmailAPI } from "../../../../../../../SidebarPages/Communication/functions";
import { camelToTitle } from "../../../../../../Payroll/Tabs/DEG/components/modalComponents/ShiftEditModal/editShiftModalData";
import broadcastNotification from "../../../../../../../../helpers/controllers/broadcastNotification";

const dynamicColumnDefs = (key) => {
  let modifyKey;

  if (key === "updatedAt") {
    modifyKey = "Executed At";
  } else if (key === "ipv4") {
    modifyKey = "IP Address";
  } else {
    modifyKey = camelToTitle(key);
  }

  return modifyKey;
};

const dynamicRow = (value) => {
  let modifyValue = value;

  if (typeof value === "number") {
    modifyValue = moment(value).format("MM/DD/YYYY - hh:mm A");
  } else if (typeof value === "string") {
    modifyValue = value;
  } else {
    modifyValue = `other data type ${typeof value}`;
  }

  return modifyValue;
};

const dynamicList = (dataToShow) => {
  const otherValues = Object?.entries(dataToShow || {})
    .map(
      ([key, value]) =>
        `<table style="width: 100%; border-collapse: collapse; margin-top: 10px;">
            <tr style="border-bottom: 1px solid #ccc;">
              <th style="text-align: left; padding: 8px; width: 30%;">${dynamicColumnDefs(
                key
              )}:</th>
              <td style="text-align: left; padding: 8px; width: 70%;">${dynamicRow(
                value
              )}</td>
            </tr>
          </table>`
    )
    .join("");

  const unapprovedData = dataToShow;

  return {
    otherValues,
    unapprovedData,
  };
};

const generateEmailTemplate = (params, emailTemp = {}) => {
  return emailTemp?.templateBody?.replace(/\$\{([\w.]+)\}/g, (_, key) => {
    return key
      .split(".")
      .reduce((acc, curr) => (acc && acc[curr] ? acc[curr] : ""), params);
  });
};

export const sendEmailForUnauthorizedIPDevice = async (
  body,
  emailTemp = {}
) => {
  const { lat, lng } = body?.sessionInfo?.coords;

  const proceed = {
    device: body?.currentData?.devices.deviceName,
    ipv4: body?.sessionInfo?.ipAddress,
    reason:
      body?.currentData?.reason ||
      `No access from IP Address: (${body?.sessionInfo?.ipAddress}) from device: (${body?.currentData?.devices?.deviceName})`,
    nameOfUser: body?.nameOfUser,
    updatedAt: body?.updatedAt,
    status: "Redirected to /notAuthorized",
    address: body?.sessionInfo?.address || "Denied location access!",
    coordinates:
      lat || lng
        ? `Latitude: ${lat}, Longitude: ${lng}`
        : "Denied location access!",
  };

  if (process.env.NODE_ENV === "development") return;

  try {
    await callGmailAPI("sendEmail", {
      to: emailTemp?.templateRoles?.to,
      body: generateEmailTemplate(
        {
          otherValues: dynamicList(proceed).otherValues,
          ipv4: dynamicList(proceed).unapprovedData.ipv4,
          nameOfUser: dynamicList(proceed).unapprovedData.nameOfUser,
        },
        emailTemp
      ),
      attachments: [],
      subject: emailTemp.templateSubject,
      authData: false,
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const sendUnauthorizedIPDeviceNotification = async (
  body,
  userConfiguration
) => {
  const proceed = {
    reason:
      body?.currentData?.reason ||
      `No access from IP Address: (${body?.sessionInfo?.ipAddress}) from device: (${body?.currentData?.devices?.deviceName})`,
    nameOfUser: body?.nameOfUser,
  };

  const tilesToCheck = ["Device & Ip Management"];

  const usersToSendNotification = userConfiguration?.allUsers?.Items?.map(
    ({ routeConfig, cognitoUserId }) => {
      const settingsRoute = routeConfig.find(
        ({ title }) => title === "Settings"
      );

      if (settingsRoute) {
        const foundRoute = settingsRoute.children.filter(({ title }) =>
          tilesToCheck.includes(title)
        );

        if (foundRoute && foundRoute.length) {
          return cognitoUserId;
        }
      }
      return null;
    }
  ).filter(Boolean);

  try {
    await broadcastNotification(
      "118",
      "sendUnauthorizedIPDevice",
      [
        {
          common: proceed.nameOfUser,
          commonNext: " - " + proceed.reason,
        },
        {
          userName: proceed.nameOfUser,
          currentUser: userConfiguration.cognitoUserId,
          cognitos: usersToSendNotification,
        },
      ],
      null
    );
  } catch (error) {
    console.log({ error });
  }
};

export const tableInfoForIpAddressUsers = (ipv4, toReturn, rowData) => {
  const forDeleteLength = rowData["delete"].length;
  const forCreateLength = rowData["create"].length;
  return {
    create: ipv4 ? (
      <div style={{ display: "flex", gap: "5px" }}>
        Showing <div style={{ fontWeight: "bold" }}>{toReturn.length}</div>{" "}
        {toReturn.length === 1 ? "user" : "users"} that doesn't use this ip{" "}
        <div style={{ fontWeight: "bold" }}>{ipv4}</div>
      </div>
    ) : (
      <></>
    ),
    edit: (
      <div style={{ display: "flex", gap: "5px" }}>
        Showing <div style={{ fontWeight: "bold" }}>{forCreateLength}</div>{" "}
        {forCreateLength === 1 ? "user" : "users"} that doesn't use this ip, and{" "}
        <div style={{ fontWeight: "bold" }}>{forDeleteLength}</div>
        that use this ip
        <div style={{ fontWeight: "bold" }}>{ipv4}</div>
      </div>
    ),
    delete: (
      <div style={{ display: "flex", gap: "5px" }}>
        Showing <div style={{ fontWeight: "bold" }}>{forDeleteLength}</div>{" "}
        {forDeleteLength === 1 ? "user" : "users"} that use this ip{" "}
        <div style={{ fontWeight: "bold" }}>{ipv4}</div>
      </div>
    ),
  };
};
