import DynamicFields from "../../../EmailBox/components/dynamic-fields/DynamicFields";
import DriveItems from "../../../EmailBox/components/footer/EmailBoxFooter/drive-items/DriveItems";
import DocumentationTypes from "../../../EmailBox/components/footer/EmailBoxFooter/documentation-types/DocumentationTypes";
import AttachFiles from "../../../EmailBox/components/footer/EmailBoxFooter/attach-files/AttachFiles";
import FormattingOptions from "../../../EmailBox/components/footer/EmailBoxFooter/formatting-options/FormattingOptions";
import EmailBoxTemplates from "../../../EmailBox/components/footer/EmailBoxFooter/templates/EmailBoxTemplates";

import "./reply-email-box-footer-toolbar.scss";

function ReplyEmailBoxFooterToolbar({ rowData, recordDetails }) {
  return (
    <div className="reply-email-box-footer-toolbar-container">
      <FormattingOptions />
      <AttachFiles />
      <DocumentationTypes recordDetails={recordDetails} />
      <DriveItems rowData={rowData} recordDetails={recordDetails} />
      <EmailBoxTemplates />
      {/* <DynamicFields recordDetails={recordDetails} rowData={rowData} /> */}
    </div>
  );
}

export default ReplyEmailBoxFooterToolbar;
