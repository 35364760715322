import { fetchAllData } from "../ApiMethods";

const totalitiesKeys = [
  "currentPaymentDue",
  "currentRetainage",
  "totalAmount",
  "totalRetainage",
];

// optimized report fetch data
export const modifyAndSortApplications = async ({
  keysToInclude = [],
  reportUsedKeys = [],
}) => {
  try {
    let finalKeysToInclude = keysToInclude;

    if (reportUsedKeys.some((key) => totalitiesKeys.includes(key))) {
      finalKeysToInclude = [...finalKeysToInclude, "totalities"];
    }

    if (
      reportUsedKeys.includes("periodStart") ||
      reportUsedKeys.includes("periodEnd")
    ) {
      finalKeysToInclude = [...finalKeysToInclude, "periodTo"];
    }

    const applications = await fetchAllData({
      endpoint: "applications",
      resultId: "applicationId",
      otherStringParams: {
        ...(finalKeysToInclude.length && {
          keysToInclude: JSON.stringify([
            ...new Set([...finalKeysToInclude, "applicationId"]),
          ]),
        }),
      },
    });

    const applicationsWithRetainage = applications.map((application) => {
      return {
        ...application,
        currentPaymentDue: application?.totalities?.currentPaymentDue || 0,
        currentRetainage: application?.totalities?.currentRetainage || 0,
        totalAmount: application?.totalities?.totalAmount || 0,
        totalRetainage: application?.totalities?.totalRetainage || 0,
        periodStart: application?.periodTo?.start,
        periodEnd: application?.periodTo?.end,
      };
    });

    // Filter out falsy values, sort by application number desc and return the resulting array
    const newApplications = applicationsWithRetainage
      .filter(Boolean)
      .sort((a, b) => b?.applicationNo - a?.applicationNo);

    return newApplications;
  } catch (error) {
    console.log(
      "data source function modifyAndSortApplications error: ",
      error
    );
  }
};
