import Card from "../ServiceTemplates/Card";
import { getStylesFromTemplates } from "../../helpers/fetchProposalTemplates";
import { useProposalContext } from "../../../../WaitingRoomProposal/ProposalContext";
import { mapTagToData } from "../../../ProposalHeader/components/RealTimeTakeOffPreview/mappers/dataMapper";

import "./GeneralTemplatesSidebar.scss";

function addStringToDoubleBracesTags(html, keyToAdd) {
  const regex = /\{\{(\w+)\}\}/g;
  const updatedHtml = html.replace(
    regex,
    `<span class="proposalTag" contenteditable="false" style="cursor: not-allowed;">{{$1_${keyToAdd}}}</span>`
  );
  return updatedHtml;
}

export const getMyMadeSenseData = (html, proposalData, styles) => {
  return mapTagToData({
    proposalData,
    html,
    styles,
  });
};

function GeneralTemplatesSidebar({
  editorRef,
  estimationSample,
  serviceTemplates,
  proposalTemplates,
  updateTemplate,
}) {
  const { proposalData, setProposalData } = useProposalContext();

  const onDragEnd = (event, item) => {
    const droppedHTML = item?.templateContent || "";
    const realContent = item?.parentTemplateContent || "";
    const x = event.clientX;
    const y = event.clientY;
    const elements = document.elementsFromPoint(x, y);
    const isOverTarget = elements?.some((element) =>
      element?.classList?.contains("jodit-workplace")
    );
    event.preventDefault();
    if (editorRef.current && isOverTarget) {
      const wrappedHTML = `<div>${droppedHTML}</div>`;
      const styles = getStylesFromTemplates(
        { templateContent: realContent },
        serviceTemplates
      );
      const myMadeSenseData = getMyMadeSenseData(
        wrappedHTML,
        proposalData,
        styles
      );

      editorRef.current.component.selection.insertHTML(myMadeSenseData);

      const getNewHtml = editorRef.current.component.value;
      const tmp = {
        ...(proposalData || {}),
        templateData: {
          ...(proposalData?.templateData || {}),
        },
        getNewHtml,
        parentTemplateContent: item?.parentTemplateContent || "",
      };
      setProposalData(tmp);
    }
  };

  return (
    <div className="general-templates">
      {Array.isArray(proposalTemplates) &&
        proposalTemplates?.map((template, i) => (
          <div key={i} className="serviceTemplateItem">
            <Card
              template={template}
              onDragEnd={onDragEnd}
              serviceTemplates={serviceTemplates}
              estimationSample={estimationSample}
              updateTemplate={updateTemplate}
            />
          </div>
        ))}
    </div>
  );
}

export default GeneralTemplatesSidebar;
