import { assignedToObject, projectManagerSelect, teamSelect } from "..";
import sortFields from "../sortFields";

const inspectionRequestFields = ({
  teams = [],
  projects = [],
  accounts = [],
  inputFields = [],
  isDarkMode = false,
  projectManagers = [],
  inspectionTypes = [],
  userConfiguration = {},
  globalDisabled = false,
  onTeamSelect = () => {},
  allFieldsDisabled = false,
  onMainFieldSelect = () => {},
}) => {
  const newFields = [];

  inputFields.forEach((input) => {
    let field = structuredClone(input);

    const { type = "", formItemName = "" } = field;

    field.className = "defaultInputComponent";
    field.disabled = !!allFieldsDisabled || globalDisabled;

    if (isDarkMode) {
      if (type === "select") {
        Object.assign(field, { dropdownClassName: "darkDropDown" });
      } else if (type === "datepicker" || type === "rangepicker") {
        Object.assign(field, { dropdownClassName: "darkDateDropDown" });
      }
    }

    if (formItemName === "projectName") {
      Object.assign(field, {
        options: projects.map((p) => p?.projectName) || [],
        onSelect: (val, opt) => {
          onMainFieldSelect({ val, opt });
        },
        onClear: () => onMainFieldSelect({ clear: true }),
      });
    }

    if (formItemName === "client") {
      Object.assign(field, {
        customOptions: (accounts || [])?.map((el) => ({
          value: el?.accountId,
          label: el?.accountName,
        })),
        allowClear: true,
      });
    }

    if (formItemName === "assignedTo") {
      Object.assign(field, {
        ...assignedToObject({
          title: "Inspections",
          users: userConfiguration?.allUsers?.Items,
          currentUser: userConfiguration?.cognitoUserId,
        }),
      });
    }

    if (formItemName === "inspectionType") {
      Object.assign(field, {
        customOptions: (inspectionTypes || [])?.map((el) => ({
          value: el?.key,
          label: el?.statusName,
        })),
      });
    }

    if (formItemName === "team") {
      Object.assign(field, {
        ...teamSelect({ teams, isDarkMode, onTeamSelect }),
      });
    }

    if (formItemName === "projectManager") {
      Object.assign(field, {
        ...projectManagerSelect(projectManagers?.map((el) => el?.nameOfUser)),
      });
    }

    newFields.push(field);
  });

  return sortFields(newFields);
};

export default inspectionRequestFields;
