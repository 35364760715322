import { fetchAllData } from "../ApiMethods";

export const getSchedulingServices = async ({ keysToInclude }) => {
  try {
    const [scheduling, toBeScheduledArr] = await Promise.all([
      fetchAllData({
        endpoint: "scheduling",
        resultId: "scheduleId",
        otherStringParams: {
          keysToInclude: JSON.stringify([
            ...new Set([...keysToInclude, ...["scheduleId"]]),
          ]),
        },
      }),
      fetchAllData({
        endpoint: "toBeScheduled",
        resultPosition: "toBeScheduled",
        resultId: "toBeScheduledId",
        otherStringParams: {
          keysToInclude: JSON.stringify([
            "toBeScheduledId",
            "scheduleId",
            "label",
            "serviceOptions",
            "serviceId",
          ]),
        },
      }),
    ]);

    return scheduling.map((schedule) => {
      const toBeScheduled = toBeScheduledArr.filter(
        ({ scheduleId }) => scheduleId === schedule.scheduleId
      );

      return {
        ...schedule,
        items: toBeScheduled.flatMap((service, index) => {
          const estimationName = `Estimation ${index + 1}`;

          const { label, serviceOptions } = service;
          const options = serviceOptions[0] || [];

          return options.flatMap((option) => {
            if (option.items && option.items.length > 0) {
              return option.items.map((nestedOption) => ({
                estimationName,
                label,
                scheduleName: schedule.scheduleName,
                elevationLabel: option.elevationLabel,
                totalProgress: nestedOption?.totalProgress || 0,
                price: nestedOption?.originalPli?.totalPrice || 0,
                approved: nestedOption?.originalPli?.approved || true,
              }));
            }

            return {
              estimationName,
              label,
              scheduleName: schedule.scheduleName,
              elevationLabel: option.elevationLabel,
              totalProgress: option?.totalProgress,
              price: option.price,
              approved: option.approved,
            };
          });
        }),
      };
    });
  } catch (error) {
    console.error("data source function getSchedulingServices error: " + error);
  }
};
