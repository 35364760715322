import { groupBy } from "lodash";
import { API } from "aws-amplify";
import { v4 as uuid } from "uuid";
import { type Dayjs } from "dayjs";
import { Form, message } from "antd";
import { AxiosResponse } from "axios";
import { useSelector } from "react-redux";
import { useEffect, useMemo, useReducer, useRef, useState } from "react";

import {
  getShiftOrder,
  getLiveSchedules,
  getWeekDateRange,
  getScheduledEmployees,
} from "./utils";
import {
  EntryType,
  ShiftEntry,
  FilterTypes,
  PayrollType,
  ScheduleType,
  CrewTeamType,
  GroupedEntry,
  AnalyticsType,
  ExcelDataType,
  EmployeeReportType,
  ControlPanelRefType,
} from "./payrollLiveTypes";
import LiveDegPayroll from "./Pages/LiveDegPayroll";
import PayrollLiveMap from "./Pages/PayrollLiveMap";
import PayrollLiveContext from "./PayrollLiveContext";
import EmployeeReports from "./Pages/EmployeeReports";
import { NotFound } from "src/components/SidebarPages";
import { gsiQueryTable, filterTables } from "src/utils";
import {
  getEmployeeAnalytics,
  groupEntriesInShifts,
} from "../Payroll/Tabs/DEG/components/modalComponents/utils";
import {
  StoreType,
  JobsiteType,
  RouteConfig,
  EmployeeType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import PayrollLiveAnalytics from "./Pages/PayrollLiveAnalytics";
import {
  getPayrollLiveReport,
  getEmployeesLiveEntries,
} from "../Payroll/Tabs/DEG/FingerCheckConfig/fingercheckFunctions";
import { LoadableComp } from "src/components/SidebarPages/XComponents";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { PayrollLiveControlPanel, PayrollLiveSidebar } from "./components";
import { ANALYTICS_REDUCER } from "src/components/pages/Payroll/Tabs/DEG/components/modalComponents/utils";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { REDUCER_INIT } from "src/components/pages/Payroll/Tabs/DEG/components/modalComponents/utils/reducerInitialStates";

import "./PayrollLive.scss";

const FILTER_INITIAL_VALUE = {
  distance: 0,
  schedules: [],
  crewSearch: "",
  liveStatus: [],
  employeeId: [],
  employeeName: [],
  scheduleMatch: [],
  selectedData: null,
  punchTimeEnd: null,
  employeeSearch: "",
  employeeNumber: [],
  scheduleAddress: "",
  punchTimeStart: null,
  approvedEntries: null,
};

const EMPLOYEE_FILTERS = [
  "distance",
  "liveStatus",
  "employeeId",
  "employeeName",
  "punchTimeEnd",
  "scheduleMatch",
  "employeeNumber",
  "employeeSearch",
  "punchTimeStart",
  "approvedEntries",
];

const FETCH_DATE_FORMAT = "YYYY-MM-DD";
const SCHEDULE_FILTERS = ["schedules", "scheduleAddress"];

const minute = 60000; // milliseconds in 1 minute

function PayrollLive() {
  const { isDarkMode } = useSelector((store: StoreType) => store.darkMode);
  const userConfiguration = useSelector(
    (store: StoreType) => store.userConfig.userConfiguration
  );
  const programFields = useSelector(
    (store: StoreType) => store.programFields.programFields
  );

  const [liveRecords, setLiveRecords] = useState<Array<any>>([]);
  const [selectedPage, setSelectedPage] = useState<string>(
    sessionStorage.getItem("selectedPage") || "map"
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [jobsites, setJobsites] = useState<Array<JobsiteType>>([]);
  const [allTeams, setAllTeams] = useState<Array<CrewTeamType>>([]);
  const [degEntries, setDegEntries] = useState<Array<EntryType>>([]);
  const [schedules, setSchedules] = useState<Array<ScheduleType>>([]);
  const [historyInfo, setHistoryInfo] = useState<string | undefined>();
  const [shiftEntries, setShiftEntries] = useState<Array<ShiftEntry>>([]);
  const [employeesInfo, setEmployeesInfo] = useState<string | undefined>();
  const [schedulesInfo, setSchedulesInfo] = useState<string | undefined>();
  const [filters, setFilters] = useState<FilterTypes>(FILTER_INITIAL_VALUE);
  const [accessRight, setAccessRight] = useState<undefined | RouteConfig>();
  const [todaySchedules, setTodaySchedules] = useState<Array<ScheduleType>>([]);
  const [groupedEntries, setGroupedEntries] = useState<Array<GroupedEntry>>([]);
  const [selectedWeekDeg, setSelectedWeekDeg] = useState<
    undefined | PayrollType
  >();
  const [excelUploadedData, setExcelUploadedData] = useState<
    Array<ExcelDataType>
  >([]);
  const [programEmployees, setProgramEmployees] = useState<Array<EmployeeType>>(
    []
  );
  const [employeesReport, setEmployeesReport] = useState<
    Array<EmployeeReportType>
  >([]);

  const [analytics, analyticsUpdate] = useReducer(
    ANALYTICS_REDUCER,
    REDUCER_INIT
  );

  const [controlPanelForm] = Form.useForm();
  const selectedDate = Form.useWatch("selectedDate", controlPanelForm);
  const clientCompany = Form.useWatch("clientCompany", controlPanelForm);

  const mapRef = useRef(null);
  const sidebarRef = useRef(null);
  const controlPanelRef = useRef<ControlPanelRefType>(null);

  // #region clearFilters
  function clearFilters() {
    setFilters({
      schedules: [],
      crewSearch: "",
      liveStatus: [],
      employeeId: [],
      employeeName: [],
      scheduleMatch: [],
      punchTimeEnd: null,
      employeeSearch: "",
      employeeNumber: [],
      scheduleAddress: "",
      punchTimeStart: null,
      approvedEntries: null,
    });
    setHistoryInfo(undefined);
    if (mapRef?.current) {
      mapRef.current.fitBoundsToMarkers([]);
      mapRef.current.setEmployeesHistory([]);
    }
  }

  async function getLatestEntries(companyName: string, date?: string) {
    message.loading({
      duration: 0,
      key: "liveEntries",
      content: "Getting latest entries...",
    });
    await getLiveSchedules({
      jobsites,
      setTodaySchedules,
      nowTime: parseInTz(date, FETCH_DATE_FORMAT),
    });
    getEmployeesLiveEntries({ companyName, date })
      .then((res) => {
        const nowTime = Date.now();
        localStorage.setItem("lastReportFetch", JSON.stringify(nowTime));
        controlPanelRef?.current?.setLastFetch?.(nowTime);
        setDegEntries((prev) => {
          return res
            .map((el) => ({ ...el, jobAddress: el?.jobsiteMatch?.jobAddress }))
            .concat(
              prev.filter(
                (el) => res.findIndex((e) => e?.entryId === el?.entryId) === -1
              )
            );
        });

        message.success({
          duration: 1.8,
          key: "liveEntries",
          content: "Entries updated",
        });
      })
      .catch((error) => {
        message.error({
          duration: 1.8,
          key: "liveEntries",
          content: "Network error",
        });
        console.log("error: ", error);
      });
  }

  // #region getEmployeeReports
  function getEmployeeReports(getLive?: boolean, reportDate?: Dayjs) {
    const selectedClient = clientConfigs.find(
      (el) => el?.configId === (clientCompany || defaultCompany)
    );

    const fetchDate = reportDate
      ? reportDate?.format?.(FETCH_DATE_FORMAT)
      : selectedDate
      ? selectedDate.format(FETCH_DATE_FORMAT)
      : dayjsNY().startOf("d").format(FETCH_DATE_FORMAT);

    if (!getLive) {
      message.loading({
        duration: 0,
        key: "getEmployeeReports",
        content: "Getting employees report....",
      });
    }

    getPayrollLiveReport({
      excludedEmployees: [],
      selectedDate: fetchDate,
      clientKey: selectedClient?.clientKey,
      companyName: selectedClient?.clientName,
    })
      .then((res: AxiosResponse<Array<EmployeeReportType>>) => {
        const tmpEmployeesReport = (res?.data || []).map((el) => ({
          ...el,
          company: selectedClient?.clientName,
          employeeId: `${selectedClient?.clientName}-${el.employeeNumber}`,
        }));

        setEmployeesReport((prev) => {
          if (
            !prev.length ||
            prev?.[0]?.company !== selectedClient?.clientName
          ) {
            return tmpEmployeesReport;
          }

          let modifiedResponse = [];
          for (let i = 0; i < prev.length; i++) {
            const empReport = prev[i];
            let empToPush = empReport;
            for (let j = 0; j < tmpEmployeesReport.length; j++) {
              const resEmp = tmpEmployeesReport[j];
              if (
                empReport?.fingerCheckId === resEmp?.fingerCheckId &&
                empReport.punchTime !== resEmp.punchTime &&
                !!resEmp?.punchTime
              ) {
                Object.assign(empToPush, resEmp);
              }
            }
            modifiedResponse.push(empToPush);
          }

          return modifiedResponse;
        });
        message.destroy();
      })
      .catch((err) => {
        console.log("Get payroll live report error: ", err);
        setEmployeesReport([]);
        message.destroy();
      });

    if (getLive) {
      getLatestEntries(selectedClient?.clientName, fetchDate);
    } else {
      const weekDateRange = getWeekDateRange(
        parseInTz(fetchDate, FETCH_DATE_FORMAT)
      );
      const degFilter = [
        {
          id: uuid(),
          operator: "AND",
          conditions: [
            {
              id: uuid(),
              operator: "AND",
              column: "fromDate",
              dataType: "number",
              columnType: "number",
              value: weekDateRange?.[0]?.valueOf?.(),
              formula: "is_greater_than_or_equal_to",
            },
            {
              id: uuid(),
              formula: "is",
              operator: "AND",
              dataType: "string",
              columnType: "string",
              column: "companyName",
              value: selectedClient.clientName,
            },
          ],
        },
      ];

      API.get("deg", "/deg", {
        queryStringParameters: {
          getMaxLimit: "true",
          withPagination: "true",
          ExclusiveStartKey: undefined,
          filters: JSON.stringify(degFilter),
        },
      }).then((degRes) => {
        console.log("degRes: ", degRes);
        if (degRes?.deg?.length) {
          const deg = degRes?.deg.find(
            (el) => el.toDate <= weekDateRange[1].valueOf()
          );
          console.log("deg: ", deg);
          if (deg?.degStatus === "Completed") {
            message.warning({
              duration: 4,
              content: "This week`s DEG have been completed.",
            });
          }
          setSelectedWeekDeg(deg);
          gsiQueryTable({
            filterKey: "degId",
            filterValue: deg?.degId,
            tableName: "degEntries",
            indexName: "degId-index",
          })
            .then((res) => {
              const filteredRes = res.filter((el) => {
                const companyCondition =
                  el?.company === selectedClient?.clientName ||
                  el?.companyName === selectedClient?.clientName;
                const timeCondition =
                  parseInTz(fetchDate, FETCH_DATE_FORMAT)
                    .startOf("d")
                    .valueOf() ===
                  parseInTz(el?.punchTimeStamp)?.startOf?.("d")?.valueOf?.();

                return companyCondition && timeCondition;
              });

              getEmployeeAnalytics({
                degGridApi: {},
                analyticsUpdate,
                degRows: filteredRes || [],
              });
              setDegEntries(
                filteredRes.map((el) => ({
                  ...el,
                  jobAddress: el?.jobsiteMatch?.jobAddress,
                }))
              );
              message.destroy();
            })
            .catch((err) => {
              console.log("error: ", err);
              getEmployeeAnalytics({
                degGridApi: {},
                analyticsUpdate,
                degRows: [],
              });
              setDegEntries([]);
              message.destroy();
            });
        }
      });
    }
  }

  function goFullScreen() {
    let elem = document.documentElement;
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    } else {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      }
    }
  }

  function onPageSelect(page: string) {
    setSelectedPage(page);
    sessionStorage.setItem("selectedPage", page);
  }

  const clientConfigs = useMemo(() => {
    if (programFields?.length) {
      let index = programFields.findIndex(
        (field) => field?.fieldName === "Payroll Configuration"
      );

      return programFields[index].fieldOptions.filter((el) => el?.activeConfig);
    }
    return [];
  }, [programFields]);

  const defaultCompany = useMemo(() => {
    return clientConfigs[0]?.configId;
  }, [clientConfigs]);

  // #region crewTeams
  const crewTeams = useMemo(() => {
    const selectedClient = clientConfigs.find(
      (el) => el?.configId === (clientCompany || defaultCompany)
    );
    return allTeams.filter((el) => {
      return el?.company === selectedClient?.clientName;
    });
  }, [allTeams, clientCompany, clientConfigs]);

  // #region filteredCrews
  const filteredCrews = useMemo(() => {
    let filteredData = [];
    for (let i = 0; i < crewTeams.length; i++) {
      const team = crewTeams[i];
      let pass = true;
      if (
        filters?.crewSearch?.length &&
        !team?.crewTeamName
          ?.toLowerCase()
          .includes(filters?.crewSearch.toLowerCase())
      ) {
        pass = false;
      }

      if (pass) {
        filteredData.push(team);
      }
    }
    return filteredData;
  }, [crewTeams, filters?.crewSearch]);

  // #region filteredSchedules
  const filteredSchedules = useMemo(() => {
    let filterPassSchedules = [];
    if (!schedules?.length) {
      return [];
    }

    for (const el of schedules as Array<
      ScheduleType & { jobAddress?: string }
    >) {
      let filterPass = true;
      const filterKeys = Object.keys(filters);
      if (filterKeys.length) {
        for (let k = 0; k < filterKeys.length; k++) {
          const key = filterKeys[k];
          if (SCHEDULE_FILTERS.includes(key)) {
            const filterValue = filters[key];
            if (filterValue?.length && !filterValue.includes(el?.projectId)) {
              filterPass = false;
            }
            if (key === "scheduleAddress" && !!filterValue) {
              filterPass = (el?.[key] || el?.jobAddress)
                .toLowerCase()
                .includes(filterValue.toLowerCase());
            }
          }
        }
        if (filterPass) {
          filterPassSchedules.push(el);
        }
      } else {
        filterPassSchedules = schedules;
      }
    }

    return filterPassSchedules;
  }, [schedules, JSON.stringify(filters)]);

  // #region filteredEmployees
  const filteredEmployees = useMemo(() => {
    let filterPassEmployees = [];
    if (!liveRecords?.length) {
      return [];
    }

    const employeeEntries = groupBy(degEntries, "employeeId");

    const filterKeys = Object.keys(filters);
    for (const emp of liveRecords) {
      const empEntries = (employeeEntries?.[emp?.employeeId] || []).sort(
        (a, b) => a?.punchTimeStamp - b?.punchTimeStamp
      );
      let filterPass = true;

      if (filterKeys?.length) {
        for (let k = 0; k < filterKeys.length; k++) {
          const key = filterKeys[k];
          if (EMPLOYEE_FILTERS.includes(key)) {
            const filterValue = filters[key];
            if (
              filterValue?.length &&
              !filterValue.includes(
                emp?.[key] || emp?.projectId || emp?.color
              ) &&
              key !== "employeeSearch"
            ) {
              filterPass = false;
            }

            if (key === "distance" && !!filterValue) {
              filterPass = emp?.distance <= filterValue;
            }

            if (
              filterPass &&
              key === "liveStatus" &&
              filterValue.length === 1 &&
              filterValue.includes("In")
            ) {
              filterPass = emp.liveStatus === "In";
            }

            if (key === "punchTimeStart" && !!filterValue && !!emp?.punchTime) {
              filterPass =
                filterPass &&
                parseInTz(emp?.punchTime).valueOf() >= filterValue;
            }
            if (key === "punchTimeEnd" && !!filterValue && !!emp?.punchTime) {
              filterPass =
                filterPass &&
                parseInTz(emp?.punchTime).valueOf() <= filterValue;
            }
            if (filterPass && key === "employeeSearch" && !!filterValue) {
              filterPass =
                filterPass &&
                emp.employeeName
                  .toLowerCase()
                  .includes(filterValue.toLowerCase());
              if (
                "foreman".includes(filterValue.toLowerCase()) &&
                emp?.isForeman
              ) {
                filterPass = true;
              }
            }

            if (key === "approvedEntries") {
              const matchedEntry = empEntries?.[empEntries?.length - 1];
              if (
                filterValue === "Approved" &&
                emp?.liveStatus !== "No Punch" &&
                matchedEntry?.activityStatus === "Completed"
              ) {
                filterPass = true;
              }
              if (
                filterValue === "Unapproved" &&
                emp?.liveStatus !== "No Punch" &&
                matchedEntry?.activityStatus !== "Completed"
              ) {
                filterPass = true;
              }
            }
          }
        }
        if (filterPass) {
          filterPassEmployees.push(emp);
        }
      } else {
        filterPassEmployees = liveRecords;
      }
    }
    return filterPassEmployees;
  }, [degEntries, liveRecords, JSON.stringify(filters)]);

  const todayScheduleField = useMemo(() => {
    if (!programFields?.length) {
      return;
    }

    let index = programFields.findIndex(
      (el) => el?.fieldName === "Today's Scheduling"
    );
    const selectedField = programFields?.[index];
    const selectedClient = clientConfigs.find(
      (el) => el?.configId === clientCompany
    );
    const clientIndex = selectedField.fieldOptions.findIndex(
      (el) => el?.clientName === selectedClient?.clientName
    );

    const defaultExcelData =
      clientIndex > -1
        ? selectedField.fieldOptions?.[clientIndex]?.excelData
        : [];
    setExcelUploadedData(defaultExcelData);
    return selectedField;
  }, [programFields, clientCompany, clientConfigs]);

  // #region useEffects
  // Get initial data and employee reports interval
  useEffect(() => {
    if (clientCompany && todayScheduleField) {
      async function getJobs() {
        let allJobs = [];
        await API.get("jobsites", "/jobsites", {})
          .then((res) => {
            setJobsites(res);
            allJobs = res;
          })
          .catch((error) => console.log("Error getting Jobsites: ", error));

        const nowTime = parseInTz();
        await getLiveSchedules({
          nowTime,
          jobsites: allJobs,
          setTodaySchedules,
        });
      }
      getJobs();
      API.get("crews", "/crews", {})
        .then((res) =>
          setProgramEmployees(
            res.filter((el: EmployeeType) => el?.crewStatus === "Active")
          )
        )
        .catch((error) =>
          console.log("Error getting program employees: ", error)
        );
      filterTables("crewTeams", "crewTeamStatus", "Active")
        .then(setAllTeams)
        .catch((err) => console.log("Error getting crew teams: ", err));
    }
  }, [todayScheduleField, clientCompany]);

  useEffect(() => {
    if (
      parseInTz(selectedDate).startOf("d").valueOf() !==
      dayjsNY().startOf("d").valueOf()
    ) {
      return;
    }
    if (!!clientCompany || !!defaultCompany) {
      getEmployeeReports(false);
      const fetchReportInterval = setInterval(() => {
        if (
          parseInTz(selectedDate).startOf("d").valueOf() !==
          dayjsNY().startOf("d").valueOf()
        ) {
          return;
        }
        const now = new Date();

        const minute = now.getMinutes();
        if ([0, 15, 30, 45].includes(minute)) {
          const nowTime = Date.now();
          localStorage.setItem("lastReportFetch", JSON.stringify(nowTime));
          controlPanelRef?.current?.setLastFetch?.(nowTime);
          getEmployeeReports(false);
        }
      }, minute);

      return () => {
        clearInterval(fetchReportInterval);
      };
    }
  }, [clientCompany, selectedDate, defaultCompany]);

  // get schedule matches with employee punchCoordinates
  useEffect(() => {
    if (clientCompany && jobsites?.length && employeesReport?.length) {
      const { employeesMatched, jobsitesIncluded } = getScheduledEmployees({
        jobsites,
        degEntries,
        employeesReport,
        todaySchedules: todaySchedules as Array<
          ScheduleType & { includedEmployeeIds: Array<string> }
        >,
      });
      setSchedules((prev) => {
        const allNewData = todaySchedules.concat(jobsitesIncluded);
        const filteredPrevData = prev.filter(
          (el: ScheduleType & { jobsiteId?: string }) =>
            allNewData.findIndex(
              (job: ScheduleType & { jobsiteId?: string }) =>
                job?.scheduleId === el?.scheduleId ||
                job?.jobsiteId === el?.jobsiteId
            ) === -1
        );
        return filteredPrevData.concat(allNewData);
      });
      setLiveRecords(employeesMatched);
    }
  }, [
    jobsites,
    degEntries,
    clientCompany,
    todaySchedules,
    JSON.stringify(employeesReport),
  ]);

  useEffect(() => {
    const shifts = groupEntriesInShifts({
      jobsites,
      analytics,
      rowData: degEntries,
      crews: programEmployees,
    });
    const groupedReports = getShiftOrder({ entries: degEntries });
    setShiftEntries(shifts);
    setGroupedEntries(groupedReports);
  }, [JSON.stringify(analytics), degEntries]);

  useEffect(() => {
    if (userConfiguration) {
      const tmpAccessRight = userConfiguration?.routeConfig
        ?.find(({ title }) => title === "Project Cost")
        .children.find((el) => el?.title === "DEG");
      setAccessRight(tmpAccessRight);
      setLoading(false);
    }
  }, [userConfiguration]);

  return (
    <LoadableComp loading={loading}>
      {accessRight ? (
        <PayrollLiveContext.Provider
          value={{
            mapRef,
            filters,
            jobsites,
            crewTeams,
            schedules,
            setFilters,
            sidebarRef,
            degEntries,
            setAllTeams,
            accessRight,
            historyInfo,
            goFullScreen,
            selectedPage,
            shiftEntries,
            setSchedules,
            onPageSelect,
            clearFilters,
            clientCompany,
            filteredCrews,
            clientConfigs,
            employeesInfo,
            setDegEntries,
            schedulesInfo,
            groupedEntries,
            setHistoryInfo,
            analyticsUpdate,
            selectedWeekDeg,
            setShiftEntries,
            controlPanelRef,
            setSelectedPage,
            setEmployeesInfo,
            programEmployees,
            controlPanelForm,
            setSchedulesInfo,
            getLatestEntries,
            setTodaySchedules,
            excelUploadedData,
            setGroupedEntries,
            filteredEmployees,
            filteredSchedules,
            setSelectedWeekDeg,
            setEmployeesReport,
            getEmployeeReports,
            setProgramEmployees,
            setExcelUploadedData,
            employeesReport: liveRecords,
            analytics: analytics as AnalyticsType,
          }}
        >
          <section
            className={`payroll-live ${isDarkMode ? "payroll-live-dark" : ""}`}
          >
            <PayrollLiveControlPanel ref={controlPanelRef} />
            <div className="payroll-live-body">
              <PayrollLiveSidebar ref={sidebarRef} />
              {selectedPage === "deg" ? <LiveDegPayroll /> : null}
              {selectedPage === "analytics" ? <PayrollLiveAnalytics /> : null}
              {selectedPage === "map" ? <PayrollLiveMap ref={mapRef} /> : null}
              {selectedPage === "employeeReports" ? <EmployeeReports /> : null}
            </div>
          </section>
        </PayrollLiveContext.Provider>
      ) : (
        <section
          style={{ height: "100vh", width: "100vw" }}
          className={`payroll-live ${isDarkMode ? "payroll-live-dark" : ""}`}
        >
          <NotFound />
        </section>
      )}
    </LoadableComp>
  );
}

export default PayrollLive;
