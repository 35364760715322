import { Alert, Badge, Card, Modal, Popover, Tooltip, message } from "antd";
import React, { useCallback, useMemo, useRef, useState } from "react";
import "./CompareChanges.scss";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import {
  detailedComparison,
  progressForService,
  upperCaseFirstLetter,
} from "../../SchedulingFirstPage/helperData";
import { AgGridReact } from "ag-grid-react";
import { MondayButton } from "../../../../../commonComponents";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import Swal from "sweetalert2";
import { WarningOutlined } from "@ant-design/icons";
const CompareChanges = ({
  compareChanges = {},
  onCancel = () => {},
  quickbooksNumber = "",
  updateChanges = () => {},
  serviceDefinitions = [],
  estimationId = "",
  thisProjectSchedules = [],
}) => {
  const { visible = false, contnet = {} } = compareChanges || {};
  const { scheduleEstimation = [], projectEstimation = [] } = contnet || {};
  const [gridApi, setGridApi] = useState(null);
  const [filteredData, setFilteredData] = useState("all");
  const gridRef = useRef();
  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  }, []);

  const rowData =
    detailedComparison(
      scheduleEstimation,
      projectEstimation,
      serviceDefinitions,
      estimationId
    ) || [];

  const conflictsWarning = () => {
    if (!thisProjectSchedules?.length) return [];
    let toReturn = [];

    thisProjectSchedules.forEach((schedule) => {
      const { toBeScheduled = {} } = schedule;

      Object.entries(toBeScheduled).forEach(([key, services]) => {
        const conflictingEstimations = rowData?.filter(
          (row) => row?.estimationId === key
        );

        if (conflictingEstimations?.length) {
          conflictingEstimations.forEach((data) => {
            const service = services.find(
              (service) =>
                service?.serviceId?.toString() === data?.serviceId?.toString()
            );

            if (service) {
              const progress = progressForService(service);
              if (progress > 0) {
                toReturn.push({
                  label: data?.label,
                  description: data?.description,
                  progress,
                  scheduleName: schedule?.scheduleName,
                  serviceId: data?.serviceId,
                  category: data?.category,
                });
              }
            }
          });
        }
      });
    });

    return toReturn;
  };

  const keys = [
    {
      // headerName: "Select",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      width: 60,
    },
    {
      field: "category",
      width: 215,
    },
    {
      headerName: "Service",
      field: "label",
    },
    {
      headerName: "Elevation",
      field: "elevationLabel",
      width: 350,
    },
    {
      field: "elevationId",
      width: 140,
    },
    {
      headerName: "Pli",
      field: "pliId",
      width: 75,
    },
    {
      headerName: "Dimension",
      field: "dimension",
      width: 300,
      cellStyle: {
        textAlign: "left",
      },
      cellRenderer: (params) => {
        const { value } = params || {};
        return !!value ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>{upperCaseFirstLetter(value)}</span>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 15,
              }}
            >
              <Badge
                text={params?.data?.mainEstDim}
                count={"Estimate"}
                color="#71cf48"
                size="small"
              />
              {/* <CompareIcon fill="red" /> */}
              <Badge
                size="small"
                text={params?.data?.scheduleDim}
                count="Schedule"
                color="#fe4c4a"
              />
            </div>
          </div>
        ) : (
          ""
        );
      },
      valueGetter: (params) => upperCaseFirstLetter(params?.data?.dimension),
    },
    {
      headerName: "Description",
      field: "description",
      width: 330,
      cellRenderer: (params) => {
        const { value } = params || {};
        let content;
        if (value === "Dimension mismatch") {
          content = (
            <Popover
              placement="left"
              content={
                <div
                  style={{
                    padding: "10px",
                    maxWidth: "300px",
                    wordBreak: "break-word",
                  }}
                >
                  <span>
                    <WarningOutlined />{" "}
                    {<b>{upperCaseFirstLetter(params?.data?.dimension)}</b>}{" "}
                    mismatch between schedule estimation and project estimation!
                  </span>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "5px",
                      }}
                    >
                      <span>Estimate: </span>
                      {""}
                      <b style={{ color: "green" }}>
                        {params?.data?.mainEstDim}
                      </b>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "5px",
                      }}
                    >
                      <span>Schedule: </span>
                      {""}
                      <b style={{ color: "red" }}>
                        {params?.data?.scheduleDim}
                      </b>
                    </div>
                  </div>
                </div>
              }
            >
              <span>{params?.value}</span>
            </Popover>
          );
        }
        return content || <span>{params?.value}</span>;
      },
      valueGetter: (params) => params?.data?.description || "",
    },
  ];

  const handleFixSelectedChanges = () => {
    try {
      if (!gridApi?.getSelectedRows()?.length) {
        Swal.fire({
          title: "Please select at least one row to fix the changes!",
          icon: "error",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }

      gridApi?.getSelectedRows()?.forEach((row) => {
        const { function: functionName = "", parameters = [] } = row || {};
        updateChanges(functionName, parameters);
      });
      onCancel();
      message.success("Changes have been fixed successfully!");
    } catch (error) {
      console.error("An error occurred while fixing changes:", error);
      message.error(
        "An error occurred while fixing changes. Please try again."
      );
    }
  };

  const btns = [
    {
      label: "New data added",
      filters: "new",
    },
    {
      label: "Data removed",
      filters: "remove",
    },
    {
      label: "Dimension mismatch",
      filters: "Dimension mismatch",
    },
    {
      label: "All changes",
      filters: "all",
    },
  ];

  const rowNr = gridApi?.getDisplayedRowCount();

  const scheduleConflicts = [
    ...new Set([
      ...conflictsWarning()?.map(({ scheduleName = "" }) => scheduleName),
    ]),
  ];

  const scheduleConflictsString = scheduleConflicts?.length
    ? scheduleConflicts?.length === 1
      ? scheduleConflicts?.[0]
      : scheduleConflicts?.length === 2
      ? scheduleConflicts.join(" and ")
      : scheduleConflicts
          ?.slice(0, -1)
          ?.join(", ")
          ?.concat(" and ")
          ?.concat(scheduleConflicts?.slice(-1))
    : "";

  const gridHeight = useMemo(() => {
    if ((rowData?.length || [])?.length < 3) return 300;
    if (rowData?.length < 10) return 11 * 45 + 150;
    return rowData?.length * 45 + 150;
  }, [rowData]);

  return (
    <div>
      <Modal
        {...{
          open: visible,
          centered: true,
          footer: null,
          title:
            "Differences between schedule estimation and project estimation" +
            " " +
            `(${quickbooksNumber})`,
          onCancel,
          width: 1710,
          className: "compare-changes-service",
          closeIcon: <XIcon />,
          maskClosable: false,
          footer: [
            <MondayButton
              Icon={<XIcon />}
              onClick={onCancel}
              className="mondayButtonRed"
            >
              Close
            </MondayButton>,
            <span
              style={{
                color: "#ACACAC",
                fontSize: 14,
              }}
            >
              You can fix the changes by <b>selecting the rows</b> and clicking
              on the "<b>Fix Selected Changes</b>" button.
            </span>,
            <MondayButton
              Icon={<TickIcon />}
              onClick={handleFixSelectedChanges}
            >
              Fix Selected Changes
            </MondayButton>,
          ],
        }}
      >
        <div className="compare-changes-service-content">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="compareBtnFilters">
              {btns.map((btn, index) => (
                <MondayButton
                  hasIcon={false}
                  className={
                    filteredData === btn.filters
                      ? "mondayButtonBlue"
                      : "mondayButtonGrey"
                  }
                  key={index}
                  onClick={() => {
                    setFilteredData(btn.filters);
                    if (btn.filters === "all") {
                      gridApi?.setQuickFilter("");
                      return;
                    }
                    gridApi?.setQuickFilter(btn.filters);
                  }}
                >
                  {btn.label}
                </MondayButton>
              ))}
            </div>
            <Alert
              message={`
              There ${rowNr === 1 ? "is only" : "are"} ${rowNr} ${
                rowNr === 1 ? "change" : "changes"
              } between schedule and project estimation ${quickbooksNumber} based on the applied filter.`}
              type="info"
              banner
            />
          </div>
          {conflictsWarning()?.length > 0 && (
            <div
              style={{
                display: "flex",
                marginTop: 20,
              }}
            >
              <span>
                For the highlighted services, progress is currently given to{" "}
                <b>{scheduleConflictsString}</b>. Altering these services will
                impact all the schedules mentioned.
              </span>
            </div>
          )}
          {/* <div>
            {scheduleConflicts?.length > 0 && (
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  marginTop: 20,
                }}
              >
                {scheduleConflicts?.map((schedule, index) => (
                  <span
                    onClick={() => {
                      gridApi?.setQuickFilter(schedule);
                    }}
                    style={{
                      backgroundColor: "#f0f0f0",
                      padding: "5px 10px",
                      borderRadius: 5,
                      cursor: "pointer",
                    }}
                  >
                    {schedule}
                  </span>
                ))}
              </div>
            )}
          </div> */}

          <div
            className={`documentationsTable ${"light-ag-theme ag-theme-alpine"}`}
            style={{ width: "100%", height: gridHeight }}
          >
            <AgGridReact
              {...{
                ref: gridRef,
                onGridReady,
                columnDefs: keys,
                rowData,
                animateRows: true,
                suppressDragLeaveHidesColumns: true,
                suppressRowClickSelection: true,
                rowSelection: "multiple",
                rowGroupPanelShow: "always",
                pivotPanelShow: "always",
                rowHeight: 45,
                headerHeight: 32,
                getRowStyle: (params) => {
                  if (conflictsWarning()?.length > 0) {
                    if (
                      conflictsWarning()?.find(
                        (el) =>
                          el?.serviceId === params?.data?.serviceId &&
                          el?.category === params?.data?.category
                      )
                    ) {
                      return {
                        color: "#ACACAC",
                      };
                    }
                  }
                },
                groupHeaderHeight: 32,
                floatingFiltersHeight: 20,
                enableCellChangeFlash: true,
                pivotHeaderHeight: 32,
                pivotGroupHeaderHeight: 32,
                defaultColDef: {
                  resizable: true,
                  enablePivot: true,
                  enableColResize: true,
                  enableRowGroup: true,
                  editable: false,
                  sortable: true,
                  // flex: 1,
                  filter: true,
                  pivot: true,
                  enableRowGroup: true,
                  enableValue: true,
                },
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CompareChanges;
