import { useState, useRef } from "react";

export const useDragScroll = () => {
  const ref = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const onMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - ref.current.offsetLeft);
    setScrollLeft(ref.current.scrollLeft);
  };

  const onMouseUp = () => {
    setIsDragging(false);

    ref.current.style.cursor = "grab";
    ref.current.style.border = "none";

    document.body.style.userSelect = "auto";
  };

  const onMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    document.body.style.userSelect = "none";

    const x = e.pageX - ref.current.offsetLeft;
    const scroll = (x - startX) * 0.9;

    ref.current.scrollLeft = scrollLeft - scroll;

    if (ref.current.scrollLeft !== 0) {
      ref.current.style.cursor = "grabbing";
      ref.current.style.borderLeft = "1px solid rgba(0, 0, 0, 0.2)";
      ref.current.style.borderRight = "1px solid rgba(0, 0, 0, 0.2)";
    }
  };

  return { ref, onMouseDown, onMouseLeave: onMouseUp, onMouseUp, onMouseMove };
};
