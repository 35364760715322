/**
 * Handles the selection of a project address.
 *
 * @param {Object} opt - The selected option.
 * @param {boolean} [clear=false] - Indicates whether to clear the fields.
 * @param {Array} [projects=[]] - The list of projects.
 * @param {boolean} [isChangeOrder=false] - Indicates whether it's a change order.
 * @param {Function} [setFieldsValue=() => {}] - The function to set field values.
 * @param {Function} [dispatchRequestData=() => {}] - The function to dispatch request data.
 */
const onInspectionProjectSelect = ({
  opt,
  clear = false,
  projects = [],
  setFieldsValue = () => {},
  dispatchRequestData = () => {},
}) => {
  if (clear) {
    setFieldsValue({
      projectExecutive: "",
      projectManager: [],
      client: "",
      team: [],
      bin: "",
    });
    dispatchRequestData({
      type: "SELECT_MAIN_FIELD",
      payload: null,
    });

    dispatchRequestData({
      type: "SELECTED_TEAM",
      payload: [],
    });
  } else {
    const {
      projectId,
      projectExecutive,
      teamsConfiguration,
      accountName,
      // projectManager,
      // googleDriveFolderIds,
      // primaryContact,
      // insuranceRequirement,
      // laborRequirement,
      // contacts,
    } = projects.find((el) => el.projectAddress === opt.value);

    if (!!teamsConfiguration?.length) {
      dispatchRequestData({
        type: "SELECTED_TEAM",
        payload: teamsConfiguration,
      });
    }

    const toSet = {
      projectExecutive,
      team: teamsConfiguration?.map((team) => ({
        value: team?.value,
      })),
      client: accountName || "",
    };

    const payload = {
      recordId: projectId,
      recordName: opt?.value,
      accountName: accountName,
      projectId,
      category: "projects",
    };

    setFieldsValue(toSet);

    dispatchRequestData({
      type: "SELECT_MAIN_FIELD",
      payload,
    });
  }
};
export default onInspectionProjectSelect;
