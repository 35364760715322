import moment from "moment";
import { BarFilterFunctions } from "../FilterComponents/BarChart/BarChartData";
import { CircleFilterFunctions } from "../FilterComponents/CircleProgressBar/circleProgresBarData";
import { LineFilterFunctions } from "../FilterComponents/LineChartFull/LineChartData";
import { lineChartFilter } from "../FilterComponents/LongLineStepper/longLineSteperData";
import { PieFilterFunctions } from "../FilterComponents/Pie/pieData";
import { StatusFilterFunctions } from "../FilterComponents/Progress/progressData";
import { StraightProgressBarFilter } from "../FilterComponents/StreightProgressBar/streightProgressBarData";
import { compareObjects } from "../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { FILTER_VALUES } from "../FilterComponents/filtersData";
import { toCamelCase } from "../../../../pages/Settings/settingsComponents/ApprovalsDynamicForms/FormFieldModals/newFieldModal";
import dayjs from "dayjs";

export const filterData = (type, functionParams) => {
  switch (type) {
    case "BarChart":
      return BarFilterFunctions(functionParams);
    case "Pie":
      return PieFilterFunctions(functionParams);
    case "StreightProgressBar":
      return StraightProgressBarFilter(functionParams);
    case "LongLineSteper":
      return lineChartFilter(functionParams);
    case "CircleProgresBar":
      return CircleFilterFunctions(functionParams);
    case "Progress":
      return StatusFilterFunctions(functionParams);
    case "LineChartFull":
      return LineFilterFunctions(functionParams);
  }
};

/**
 * Updates the properties of a single card element based on the provided parameters.
 *
 * @param {Object} element - The original card element.
 * @param {string} type - The type of the card element.
 * @param {string} id - The ID of the card element.
 * @param {Object} newData - The new data to update the card element with.
 * @param {string} url - The URL of the card element.
 * @param {Object} updatedDynamicStates - The updated dynamic states.
 * @param {string} circleProgressPicker - The circle progress picker.
 * @returns {Object} - The updated card element.
 */
export function setSingleCard(
  element = {},
  type = "",
  id = "",
  newData,
  url = "",
  updatedDynamicStates = {},
  circleProgressPicker
) {
  if (type === "BarChart" || type === "Pie") {
    const { BodyComponentParams = {} } = element;
    const { datasets = [] } = BodyComponentParams;
    const [dataSetObject = {}] = datasets;

    return element?.id !== id
      ? element
      : url !== "accounting"
      ? {
          ...element,
          BodyComponentParams: {
            ...BodyComponentParams,
            datasets: [
              {
                ...dataSetObject,
                data: newData?.data || dataSetObject?.data,
                backgroundColor:
                  newData?.backgroundColor || dataSetObject?.backgroundColor,
              },
            ],
            labels: newData?.labels || BodyComponentParams?.labels,
          },
        }
      : {
          ...element,
          BodyComponentParams: {
            ...BodyComponentParams,
            custom: newData?.[0]?.custom,
            datasets: datasets?.map((a, index) => ({
              ...a,
              data: newData?.data,
            })),
          },
        };
  } else if (type === "StreightProgressBar" || type === "LongLineSteper") {
    const { BodyComponentParams = {} } = element;

    return element?.id !== id
      ? element
      : {
          ...element,
          BodyComponentParams: {
            ...BodyComponentParams,
            content: newData,
          },
        };
  } else if (type === "CircleProgresBar") {
    const { BodyComponentParams = {} } = element;
    const { field = "" } = BodyComponentParams;

    return element?.id !== id
      ? element
      : url !== "accounting"
      ? {
          ...element,
          BodyComponentParams: {
            ...BodyComponentParams,
            percent: newData?.percent || newData,
            data: updatedDynamicStates[id]?.filter((e) => {
              e?.[field] === circleProgressPicker;
            }),
          },
        }
      : {
          ...element,
          BodyComponentParams: {
            ...BodyComponentParams,
            ...newData,
          },
        };
  } else if (type === "Progress") {
    const { BodyComponentParams = {} } = element;

    return element?.id !== id
      ? element
      : {
          ...element,
          BodyComponentParams: {
            ...BodyComponentParams,
            statusData: newData
              ? newData
              : BodyComponentParams?.statusData || [],
          },
        };
  } else if (type === "LineChartFull") {
    const { BodyComponentParams = {} } = element;
    const { datasets = [] } = BodyComponentParams;
    const [dataSetObject = {}] = datasets;

    return element?.id !== id
      ? element
      : url !== "accounting"
      ? {
          ...element,
          BodyComponentParams: {
            ...BodyComponentParams,
            datasets: [
              {
                ...dataSetObject,
                data: newData,
              },
            ],
          },
        }
      : {
          ...element,
          BodyComponentParams: {
            ...BodyComponentParams,
            custom: newData?.[0]?.custom,
            datasets: datasets?.map((el, index) => ({
              ...el,
              data: newData?.[index]?.data,
            })),
          },
        };
  }
}

/**
 * Filters the chart data based on the provided filter function data.
 *
 * @param {Object} filterFuncData - The filter function data.
 * @param {Function} setRowData - The function to set the row data.
 * @param {string} id - The ID of the element.
 * @param {string} url - The URL.
 * @param {string} table - The table name.
 * @param {string} fields - The fields.
 * @param {string} type - The type.
 * @param {Function} circleProgressPicker - The circle progress picker function.
 * @param {Object} updatedDynamicStates - The updated dynamic states.
 */
export function filterChart(
  filterFuncData = {},
  setRowData = () => {},
  id = "",
  url = "",
  table = "",
  fields = "",
  type = "",
  circleProgressPicker,
  updatedDynamicStates = {}
) {
  const newData = filterFuncData?.[table]()?.[fields]() || {};

  setRowData((prev) =>
    prev?.map((element) => {
      if (element?.id === id) {
        return setSingleCard(
          element,
          type,
          id,
          newData,
          url,
          updatedDynamicStates,
          circleProgressPicker
        );
      } else {
        return element;
      }
    })
  );
}

/**
 * Appends filters to widgets based on the provided parameters.
 *
 * @param {Object} filterStates - The filter states object.
 * @param {Object} BodyComponentParams - The body component parameters object.
 * @param {Object} initialFilterStates - The initial filter states object.
 * @param {Object} preferences - The preferences object.
 * @param {Object} childParams - The child parameters object.
 * @param {Function} setCardFilterPreferences - The function to set card filter preferences.
 * @param {string} id - The ID of the widget.
 * @param {boolean} clear - Indicates whether to clear the filters.
 */
export function appendFiltersToWidgets(
  filterStates = {},
  BodyComponentParams = {},
  initialFilterStates = {},
  preferences = {},
  childParams,
  setCardFilterPreferences = () => {},
  id = "",
  clear = false
) {
  const url = window.location.pathname.split("/")[1];

  /**
   * If the clear flag is set to true, clear the filters.
   */
  if (clear) {
    setCardFilterPreferences((prev) =>
      prev?.map((el) => {
        if (el?.id === id) {
          let tmp = el?.BodyComponentParams;
          delete tmp?.filterStates;
          return {
            ...el,
            BodyComponentParams: tmp,
          };
        }
      })
    );
  } else {
    let tmpFilterStates = { ...filterStates };
    let tmpBodyFilters = { ...BodyComponentParams?.filterStates };

    Object.keys(tmpFilterStates).forEach((key) => {
      if (tmpFilterStates[key] !== null) {
        if (key === "filterByRange") {
          tmpFilterStates[key]?.map((el) => moment(el).valueOf());
        } else if (key === "filterByDay") {
          tmpFilterStates[key] = moment(tmpFilterStates[key]).valueOf();
        } else {
          tmpFilterStates[key] = tmpFilterStates[key];
        }
      }
    });

    Object.keys(tmpBodyFilters).forEach((key) => {
      if (tmpBodyFilters[key] !== null) {
        if (key === "filterByRange") {
          tmpBodyFilters[key]?.map((el) => moment(el).valueOf());
        } else if (key === "filterByDay") {
          tmpBodyFilters[key] = moment(tmpBodyFilters[key]).valueOf();
        } else {
          tmpBodyFilters[key] = tmpBodyFilters[key];
        }
      }
    });

    /**
     * If the filter states differ from the body filters and the initial filter states, update the card filter preferences.
     */
    if (
      compareObjects(tmpFilterStates, tmpBodyFilters) === false &&
      compareObjects(tmpFilterStates, initialFilterStates) === false
    ) {
      let toReturn = {};

      const currCard = preferences?.widgetConfiguration?.[url]?.find(
        (el) => el.id === id
      );
      toReturn = {
        ...currCard,
        BodyComponentParams: {
          ...currCard?.BodyComponentParams,
          filterStates,
        },
      };

      let tmp = preferences?.widgetConfiguration?.[url];

      tmp.forEach((el) => {
        if (el.id === id) {
          Object.assign(el, toReturn);
        }
      });

      setCardFilterPreferences(tmp);
    }
  }
}

/**
 * Filters dynamic states based on the provided filter criteria.
 *
 * @param {string} id - The ID.
 * @param {string} table - The table.
 * @param {string} fields - The fields.
 * @param {string} statusKey - The status key.
 * @param {string} addressKey - The address key.
 * @param {string} thirdField - The third field.
 * @param {Object} dynamicStates - The dynamic states.
 * @param {string} secondaryField - The secondary field.
 * @param {Object} tmpFilterStates - The temporary filter states.
 * @param {Function} setFilterStates - The function to set filter states.
 * @param {Function} setUpdatedDynamicStates - The function to set updated dynamic states.
 * @returns {Array} - The filtered data.
 */
export function filterDynamicStates({
  id = "",
  table = "",
  fields = "",
  statusKey = "",
  addressKey = "",
  thirdField = "",
  dynamicStates = {},
  secondaryField = "",
  tmpFilterStates = {},
  setFilterStates = () => {},
  setUpdatedDynamicStates = () => {},
}) {
  const {
    filterByRange = [],
    filterByDay = null,
    filterByTime = null,
    filterByName = null,
    filterByProjectManager = null,
    filterByStatus = null,
    filterByPercentage = null,
  } = tmpFilterStates;

  const dateKey = table === "Project Cost" ? "punchTimeStamp" : "createdAt";

  let filteredData = (dynamicStates[id] || []).slice();

  switch (filterByTime) {
    /**
     * If the filter by time is set to "Pick Range", filter the data based on the range.
     */
    case "Pick Range":
      /**
       * If the filter by range is an array, filter the data based on the range.
       */
      if (Array.isArray(filterByRange)) {
        const [start, end] = [
          dayjs(filterByRange?.[0]).valueOf(),
          dayjs(filterByRange?.[1]).valueOf(),
        ];
        filteredData = filteredData?.filter(
          (el) => el?.[dateKey] >= start && el?.[dateKey] <= end
        );
      } else if (filterByDay) {
        /**
         * If the filter by day is set, filter the data based on the day.
         */
        const [start, end] = [
          dayjs(filterByDay).startOf("day").valueOf(),
          dayjs(filterByDay).endOf("day").valueOf(),
        ];
        filteredData = filteredData?.filter(
          (el) => el?.[dateKey] >= start && el?.[dateKey] <= end
        );
      }
      break;
    default:
      const [start, end] = FILTER_VALUES()?.[toCamelCase(filterByTime)];
      filteredData = filteredData?.filter(
        (el) => el?.[dateKey] >= start && el?.[dateKey] <= end
      );
      break;
  }

  /**
   * If the filter by project manager is set, filter the data based on the project manager.
   * If the project manager key is not found, use the project managers key.
   * If the project manager key is found, use the project manager key.
   */
  if (filterByProjectManager) {
    const pmKey = (dynamicStates?.[id] || [])?.some((el) =>
      el?.hasOwnProperty("projectManager")
    )
      ? "projectManager"
      : "projectManagers";

    filteredData = filteredData?.filter((rowData) => {
      const managers = Array.isArray(rowData?.[pmKey])
        ? rowData[pmKey]
        : [rowData[pmKey]];
      return managers.some((manager) => {
        if (typeof manager === "object" && !Array.isArray(manager)) {
          return (
            manager?.nameOfUser?.toLowerCase() ===
            filterByProjectManager?.toLowerCase()
          );
        }
        return manager?.toLowerCase() === filterByProjectManager?.toLowerCase();
      });
    });
  }

  /**
   *  If the filter by status is set, filter the data based on the status.
   */
  if (filterByStatus) {
    filteredData = filteredData?.filter(
      (rowData) =>
        rowData?.[statusKey]?.toLowerCase() === filterByStatus?.toLowerCase()
    );
  }

  /**
   * If the filter by name is set, filter the data based on the name.
   * If the address key is found, use the address key.
   * If the secondary field is found, use the secondary field.
   */
  if (filterByName) {
    const filterKey = () => {
      if (filteredData?.[0].hasOwnProperty("projectAddress")) {
        return "projectAddress";
      } else if (filteredData?.[0].hasOwnProperty("jobsiteAddress")) {
        return "jobsiteAddress";
      } else if (filteredData?.[0].hasOwnProperty("scheduleAddress")) {
        return "scheduleAddress";
      }
      return secondaryField ? secondaryField : fields || thirdField;
    };

    filteredData = filteredData?.filter(
      (rowData) =>
        rowData?.[addressKey || filterKey()]?.toLowerCase() ===
        filterByName?.toLowerCase()
    );
  }

  /**
   * If the filter by percentage is set, filter the data based on the percentage.
   * If the engineering progress key is found, use the engineering progress key.
   * If the architectural progress key is found, use the architectural progress key.
   */
  if (filterByPercentage) {
    filteredData = filteredData?.filter(
      (rowData) =>
        rowData?.engProgress ===
          parseInt(filterByPercentage.replace("%", "")) ||
        rowData?.archProgress ===
          parseInt(filterByPercentage.replace("%", "")) ||
        ((!rowData?.engProgress || !rowData?.archProgress) &&
          filterByPercentage === "0%")
    );
  }

  setUpdatedDynamicStates((prev) => ({ ...prev, [id]: filteredData }));

  setFilterStates(tmpFilterStates);

  return filteredData;
}
