import { useState, useEffect } from "react";
import { message, Form, Tooltip } from "antd";
import { MondayButton } from "src/components/commonComponents";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";
import moment from "moment";
import { getMomentDayTimestamp } from "../../../Fleet/utils";
import { API } from "aws-amplify";
import "./LicensePlate.scss";
import { useSelector } from "react-redux";

function LicensePlate(props) {
  const [fleets, setFleets] = useState([]);
  const [form] = Form.useForm();
  const activeLicensePlateEndDate = Form.useWatch(
    "activeLicensePlateEndDate",
    form
  );
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { params, setEditMode, getEditedValue } = props;
  const { licensePlateHistory } = params;

  useEffect(() => {
    API.get("fleet", "/fleet").then((res) => setFleets(res));
  }, []);

  function onCancel() {
    setEditMode(false);
  }

  function getCardData({ licensePlate, licensePlateHistoryObject }) {
    return {
      id: "licensePlate",
      Card: "LicensePlate",
      title: "License Plate",
      params: {
        ...params,
        licensePlate,
        licensePlateHistory: licensePlateHistoryObject,
      },
    };
  }
  async function saveHandler() {
    try {
      const {
        licensePlate,
        activeLicensePlateStartDate,
        activeLicensePlateEndDate,
        newLicensePlateStartDate,
      } = await form.validateFields();
      const startTimestamp = getMomentDayTimestamp(newLicensePlateStartDate);
      const startTimestampOfActive = getMomentDayTimestamp(
        activeLicensePlateStartDate
      );
      const endTimestamp = getMomentDayTimestamp(activeLicensePlateEndDate);
      let newLicensePlateHistory;
      if (
        licensePlateHistory?.length > 0 &&
        !licensePlateHistory.at(-1).endDate
      ) {
        newLicensePlateHistory = [
          ...licensePlateHistory.slice(0, licensePlateHistory.length - 1),
          {
            ...licensePlateHistory.at(-1),
            endDate: endTimestamp,
          },
          {
            licensePlate,
            startDate: startTimestamp,
            endDate: null,
          },
        ].filter(Boolean);
      } else {
        newLicensePlateHistory = [
          ...(licensePlateHistory?.length > 0 ? licensePlateHistory : []),
          {
            licensePlate,
            startDate: startTimestampOfActive,
            endDate: null,
          },
        ].filter(Boolean);
      }

      getEditedValue(
        { licensePlate, licensePlateHistory: newLicensePlateHistory },
        { licensePlate: params?.licensePlate },
        {},
        getCardData({
          licensePlate: params?.licensePlate,
          licensePlateHistoryObject: licensePlateHistory
            ? [
                ...licensePlateHistory.slice(0, licensePlateHistory.length - 1),
                newLicensePlateHistory.at(-2),
              ]
            : null,
        }),
        getCardData({
          licensePlate,
          licensePlateHistoryObject: newLicensePlateHistory,
        })
      );

      message.success("License Plate updated successfully");
      onCancel();
    } catch (error) {
      console.error("Error saving license plate:", error);
      message.error("Failed to add license plate");
    }
  }

  return (
    <Form
      form={form}
      initialValues={{
        licensePlate: params?.licensePlate,
      }}
    >
      <div
        className={`changeLicensePlate ${
          isDarkMode && "changeLicensePlateDark"
        }`}
      >
        <Tooltip
          title={
            !activeLicensePlateEndDate &&
            Boolean(licensePlateHistory && licensePlateHistory?.length != 0) &&
            !licensePlateHistory.at(-1).endDate &&
            "Enter the End Date to edit"
          }
        >
          <InputComponent
            label="License Plate"
            required={true}
            type="text"
            formItemName="licensePlate"
            showSearch={true}
            disabled={
              !activeLicensePlateEndDate &&
              Boolean(
                licensePlateHistory &&
                  licensePlateHistory?.length != 0 &&
                  !licensePlateHistory.at(-1).endDate
              )
            }
            rules={[
              { required: true, message: "Please enter a license plate" },
              {
                validator: async (_, value) => {
                  const trimmedValue = String(value || "")
                    .trim()
                    .toLowerCase();
                  if (trimmedValue.toLowerCase() === "no plate") {
                    return Promise.resolve();
                  }

                  const isLicensePlateInUse = (fleets || []).some(
                    (fleet) =>
                      fleet.fleetId !== params?.fleetId &&
                      String(fleet.licensePlate || "")
                        .trim()
                        .toLowerCase() === trimmedValue
                  );

                  return isLicensePlateInUse
                    ? Promise.reject(
                        new Error(
                          "This license plate is already in use by another vehicle"
                        )
                      )
                    : Promise.resolve();
                },
              },
            ]}
          />
        </Tooltip>
        <InputComponent
          label={"Start Date for Active License Plate"}
          type="datePicker"
          required={
            !licensePlateHistory ||
            licensePlateHistory?.length < 1 ||
            !!licensePlateHistory.at(-1).endDate
          }
          formItemName={"activeLicensePlateStartDate"}
          disabled={
            licensePlateHistory?.length > 0 &&
            !licensePlateHistory.at(-1).endDate
          }
          defaultValue={
            licensePlateHistory?.length > 0 &&
            !licensePlateHistory.at(-1).endDate
              ? moment(licensePlateHistory?.at(-1)?.startDate)
              : null
          }
          disabledDate={(current) => {
            if (current.valueOf() > Date.now()) return true;
            if (licensePlateHistory?.length > 0) {
              const sortedHistory = [...licensePlateHistory].sort(
                (a, b) => moment(a.startDate) - moment(b.startDate)
              );
              if (current.valueOf() < sortedHistory.at(-1).endDate) return true;
            }
          }}
        />
        {licensePlateHistory?.length > 0 &&
          !licensePlateHistory.at(-1).endDate && (
            <InputComponent
              label={"End Date for Active License Plate"}
              type="datePicker"
              formItemName="activeLicensePlateEndDate"
              required={true}
              onChange={(date) => {
                if (!date && params?.licensePlate) {
                  form.setFieldValue("licensePlate", params.licensePlate);
                }
              }}
              disabledDate={(current) =>
                current > Date.now() ||
                current.valueOf() < licensePlateHistory?.at(-1).startDate
              }
            />
          )}
        {activeLicensePlateEndDate && (
          <InputComponent
            label={"Start Date for new License Plate"}
            type="datePicker"
            formItemName="newLicensePlateStartDate"
            required={true}
            disabledDate={(current) => {
              return (
                current > Date.now() ||
                current.valueOf() < activeLicensePlateEndDate
              );
            }}
          />
        )}
      </div>
      <div className="confirmContainer">
        <MondayButton
          Icon={<XIcon />}
          className="mondayButtonRed"
          onClick={onCancel}
        >
          Cancel
        </MondayButton>
        <MondayButton Icon={<TickIcon />} onClick={saveHandler}>
          Save
        </MondayButton>
      </div>
    </Form>
  );
}

export default LicensePlate;
