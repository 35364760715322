import React, { useEffect, useImperativeHandle, useState } from "react";
import {
  keys,
  state,
} from "../../../../pages/Settings/settingsComponents/Roles/RolesData";
import { Link } from "react-router-dom";
import { Checkbox, Button, Tooltip } from "antd";
import "./Information.scss";
import dayjs from "dayjs";
import { useDynamicViewContext } from "../../utils/dynamicViewContext";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import { parseInTz } from "../../../Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import SingleFileUploaderPreviewer from "./components/SingleFileUploaderPreviewer/SingleFileUploaderPreviewer";
import SecureValueDisplay from "./components/SecureValueDisplay/SecureValueDisplay";
import MultipleInputs from "./components/MultipleInputs/MultipleInputs";

const Information = (cardData) => {
  let { params = {}, edited, setNewValues } = cardData;
  const [values, setValues] = useState();
  const { unqualifiedRes } = useDynamicViewContext();

  let taxExpeptValue = values?.rowData?.find(
    (e) => e?.formItemName === "taxExempt"
  );

  useEffect(() => {
    if (edited) {
      values.rowData.forEach((e) => {
        if (keys(edited).find((el) => el === e.formItemName)) {
          e.value = edited[e.formItemName];
        }
      });
      setNewValues(state(values));
      setValues(state(values));
    } else {
      setValues(params);
    }
  }, [JSON.stringify(cardData)]);

  useImperativeHandle(unqualifiedRes, () => ({
    setUnqualifiedValue: (val, key) => {
      setValues((prev) => ({
        rowData: [
          ...prev.rowData,
          {
            ...((
              prev.rowData.find(({ formItemName }) => formItemName === key) ||
              {}
            ).value = val),
          },
        ]
          .filter(Boolean)
          .filter((el) => !_.isEmpty(el)),
      }));
      setNewValues({
        rowData: [
          ...values.rowData,
          {
            ...((
              values.rowData.find(({ formItemName }) => formItemName === key) ||
              {}
            ).value = val),
          },
        ]
          .filter(Boolean)
          .filter((el) => !_.isEmpty(el)),
      });
    },
    setProposalSentDate: (val, key) => {
      setValues((prev) => ({
        rowData: [
          ...prev.rowData,
          {
            ...((
              prev.rowData.find(({ formItemName }) => formItemName === key) ||
              {}
            ).value = val),
          },
        ]
          .filter(Boolean)
          .filter((el) => !_.isEmpty(el)),
      }));
      setNewValues({
        rowData: [
          ...values.rowData,
          {
            ...((
              values.rowData.find(({ formItemName }) => formItemName === key) ||
              {}
            ).value = val),
          },
        ]
          .filter(Boolean)
          .filter((el) => !_.isEmpty(el)),
      });
    },
  }));

  return (
    <div className="InformationBody">
      {values &&
        keys(values).length !== 0 &&
        values?.rowData
          ?.filter?.(Boolean)
          ?.map?.(
            (
              {
                label,
                value = "",
                buttonValue = "",
                tooltip = "",
                type,
                linkId = null,
                dynamicFields,
                onClick = () => {},
              },
              index
            ) => {
              return (
                <React.Fragment key={index}>
                  {type === "SingleFileUploader" ? (
                    <div className="InforamtionBodyElementContainerContentFullWidth">
                      <span className="InforamtionBodyElementContainerContent">
                        <SingleFileUploaderPreviewer fileId={value} />
                      </span>
                    </div>
                  ) : type === "CheckBox" ? (
                    <div style={{ pointerEvents: "none", marginBottom: 10 }}>
                      <div className="configElementContainer">
                        <div
                          className="bluContainer"
                          style={{ width: !!value ? 36 : null }}
                        >
                          <div
                            className="navyBlueContainer"
                            style={{
                              opacity: !!value ? 1 : null,
                            }}
                          >
                            <span style={!value ? { opacity: 0 } : {}}>
                              <Checkbox checked={value} />
                            </span>
                          </div>
                        </div>
                        <span className="configElementTitle">{label}</span>
                      </div>
                    </div>
                  ) : type === "Button" ? (
                    <div className="InforamtionBodyElementContainer">
                      <span className="InformationBodyElementContainerTitle">
                        {label}:
                      </span>
                      <span className="InforamtionBodyElementContainerContent">
                        <div>{value} | </div>
                        <Button
                          onClick={() => onClick()}
                          type="primary"
                          size="small"
                        >
                          {buttonValue}
                        </Button>
                      </span>
                    </div>
                  ) : label === "Pay Rate" ? (
                    <div className="InforamtionBodyElementContainer">
                      <span className="InformationBodyElementContainerTitle">
                        {label}:
                      </span>
                      <span className="InforamtionBodyElementContainerContent">
                        <SecureValueDisplay value={value} />
                      </span>
                    </div>
                  ) : type === "PlacesInputWithCoordinates" ? (
                    <div className="InforamtionBodyElementContainer">
                      <span className="InformationBodyElementContainerTitle">
                        {label}:
                      </span>
                      <span className="InforamtionBodyElementContainerContent">
                        {value?.address}
                      </span>
                    </div>
                  ) : type === "MultipleInputs" ? (
                    <MultipleInputs
                      value={value}
                      dynamicFields={dynamicFields}
                    />
                  ) : (
                    <>
                      <div className="InforamtionBodyElementContainer">
                        <span className="InformationBodyElementContainerTitle">
                          {label}:
                        </span>
                        {type === "Progress" ? (
                          <div className="progressInformationContainer">
                            <div className="progressInformationContainerActive">
                              <div
                                className="progressInformationContainerSelected"
                                style={{
                                  marginLeft:
                                    value === ""
                                      ? 270 * 0
                                      : (270 * value) / 100,
                                  width:
                                    270 -
                                    (value === "" ? 270 * 0 : 270 * value) /
                                      100,
                                }}
                              ></div>
                            </div>
                            <span className="InforamtionBodyElementContainerContent">
                              {value === "''" ? 0 : value}%
                            </span>
                          </div>
                        ) : (
                          <span
                            {...{
                              id:
                                label === "Last Modified By"
                                  ? "lastModifiedBy"
                                  : null,
                            }}
                            className="InforamtionBodyElementContainerContent"
                          >
                            {value?.toString()?.includes("http") === true ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: `<p><a href=${value} rel="noopener noreferrer" target="_blank">${value}</a></p>`,
                                }}
                              />
                            ) : !!linkId ? (
                              <Link
                                to={{
                                  pathname: `${linkId}`,
                                }}
                                target="_blank"
                              >
                                {value}
                              </Link>
                            ) : label === "Propability" ||
                              label === "Tax Rate" ? (
                              label === "Tax Rate" ? (
                                taxExpeptValue?.value === "Partial" ? (
                                  `${value * 100}%  Rental`
                                ) : (
                                  `${value * 100}%`
                                )
                              ) : (
                                `${value}%`
                              )
                            ) : label === "Contacts" ? (
                              value
                                ?.map(
                                  (cont) =>
                                    (!!cont?.name?.trim() && cont?.name) ||
                                    cont?.email ||
                                    "No name"
                                )
                                .join(", ")
                            ) : label === "Primary Contact" ? (
                              value.email
                            ) : type === "TimePicker" ? (
                              dayjsNY(value)?.format("h:mm a")
                            ) : type === "DatePicker" ? (
                              dayjsNY(value || "")
                                .format("MM/DD/YYYY")
                                .toString() !== "Invalid Date" ? (
                                dayjsNY(value || "").format("MM/DD/YYYY")
                              ) : (
                                ""
                              )
                            ) : label === "Last Modified By" &&
                              value?.includes("object") ? (
                              ""
                            ) : label === "Payment Term" &&
                              Array.isArray(value) ? (
                              value?.map((cont) => cont.value || "")
                            ) : Array.isArray(value) ? (
                              value.join(", ")
                            ) : type === "Phone" ? (
                              value === "" ? (
                                ""
                              ) : (
                                <a
                                  href={`tel:${numericStringMask(
                                    new String(value).valueOf(),
                                    "+# ### ### ####"
                                  )}`}
                                >
                                  {numericStringMask(
                                    new String(value).valueOf(),
                                    "+# ### ### ####"
                                  )}
                                </a>
                              )
                            ) : label === "Odometer Reading" ? (
                              `${value} mil`
                            ) : label === "Linxup Connected" ? (
                              <div
                                style={{
                                  width: 150,
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 5,
                                }}
                              >
                                <div
                                  style={{
                                    height: 20,
                                    width: 20,
                                    backgroundColor:
                                      value && value !== "Offline - Unplugged"
                                        ? "#7AC14D"
                                        : "#E75651",
                                    borderRadius: "50%",
                                  }}
                                ></div>
                                <div>
                                  {value && value !== "Offline - Unplugged"
                                    ? "Connected"
                                    : "Not Connected"}
                                </div>
                              </div>
                            ) : (
                              <Tooltip title={tooltip}>{value}</Tooltip>
                            )}
                          </span>
                        )}
                      </div>
                    </>
                  )}
                </React.Fragment>
              );
            }
          )}
    </div>
  );
};
export default Information;

function numericStringMask(str, mask) {
  if (!mask) return str;
  const numeric = str.replaceAll(/[^\d]/g, "");
  let idx = 0;
  const formatted = mask.split("").map((el) => {
    if (el === "#") {
      el = numeric[idx];
      idx++;
    }
    return el;
  });
  return formatted.join("");
}
