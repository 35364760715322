import { API } from "aws-amplify";

export const proposalPostHandler = async ({
  proposalData,
  setCurrentVersion = () => {},
}) => {
  return await API.post("proposalBuilds", "/proposalBuilds", {
    body: {
      ...proposalData,
    },
  }).then((response) => {
    setCurrentVersion(response);
    return response;
    console.log("response", response);
  });
};

export const proposalUpdateHandler = async ({
  proposalId,
  proposalData,
  wrappedHtml = "",
}) => {
  return await API.put("proposalBuilds", `/proposalBuilds/${proposalId}`, {
    body: {
      ...proposalData,
      wrappedHtml,
    },
  }).then((response) => {
    console.log("response", response);
  });
};

export const proposalDeleteHandler = async (proposalId) => {
  return await API.del("proposalBuilds", `/proposalBuilds/${proposalId}`).then(
    (response) => {
      console.log("response", response);
    }
  );
};
