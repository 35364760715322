import React, { useState } from "react";
import { CheckboxHandlers } from "../../NotificationData";
import {
  showErrorMsg,
  showInfoMsg,
  showLoadingMsg,
} from "../../../../../../utils";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DeleteModal from "../DeleteModal";
import { updateNotifications } from "../../../../../../actions/notificationsActions";
import apiService from "../../utils/notifications-api";

// Styles for counts
const counterStyle = {
  backgroundColor: "#fe4c4a",
  color: "#fff",
  borderRadius: "9px",
  padding: "0px 4px",
  position: "relative",
  bottom: "10px",
  right: "2px",
  fontSize: "14px",
  lineHeight: "12px",
  fontWeight: "normal",
};

function CheckboxHandlersActions({
  selectedItems,
  setSelectedItems,
  dataType,
  data,
  selectAllHandler,
  tabTitle,
  allSelected,
  setAllSelected,
}) {
  const dispatch = useDispatch();
  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const [open, setOpen] = useState(false);
  // Function to calculate counts for marked/unmarked items in checkbox Actions
  const getMarkedCounts = () => {
    const selectedNotificationObjects = findNotificationsByIds(
      selectedItems,
      data
    );
    const unreadItems = selectedNotificationObjects.filter(
      (item) => !item.seen
    );
    const readItems = selectedNotificationObjects.filter((item) => item.seen);

    return {
      unreadCount: unreadItems.length,
      readCount: readItems.length,
    };
  };

  const checkboxActions = async (action, value) => {
    // Determine the type of request based on the action (delete or modify)
    let tmpRequest = action === "delete" ? "delete" : "modify";

    let tmpAction; // Variable to hold the action type for modification
    // Set the tmpAction based on the specified action
    action === "hide"
      ? (tmpAction = "hasBeenHidden")
      : action === "mark as unread" || action === "mark as read"
      ? (tmpAction = "hasBeenSeen")
      : (tmpAction = null); // If action doesn't match, set to null

    // Check if the data type is notifications
    if (dataType === "notifications") {
      const selectedNotifications = selectedItems; // Get selected notifications

      if (tmpAction) {
        // If there is a temporary action defined
        const payload = {
          id: authenticatedUser.sub,
          action: tmpRequest, // Request type (delete or modify)
          selectedNotifications, // Selected notifications to modify
          modifyKey: tmpAction, // The key to modify (hasBeenHidden or hasBeenSeen)
          modValue: value, // Value to set for the modifyKey
          allSelected, // Flag indicating if all items are selected
        };

        // Check if all notifications are selected

        if (selectedNotifications.length > 0) {
          dispatch(
            updateNotifications({
              notifications:
                selectedNotifications ||
                data.map((notification) => notification.id), // Only the selected notifications' IDs
              action:
                tabTitle.toLowerCase() === "hidden" ? "move to all" : action,
            })
          );

          checkboxHandlerFunctions.removeSelectionHandler(); // Remove selections
          apiService.modifySelection(payload); // Modify only the selected items
          setAllSelected(false); // Reset the "all selected" state
        } else {
          await apiService
            .modifySelection(payload)
            .then(() => {
              // Dispatch action to update notifications after successful modification
              dispatch(
                updateNotifications({
                  notifications: selectedNotifications, // Update with selected notifications
                  action:
                    tabTitle.toLowerCase() === "hidden"
                      ? "move to all"
                      : action,
                })
              );
            })
            .catch((error) => {
              // Handle errors during modification
              showErrorMsg({
                content:
                  "Error while modifying selected notifications, please try again later!",
              });
              throw error;
            });
        }
      } else {
        // If no temporary action, handle general action
        const payload = {
          id: authenticatedUser.sub,
          action,
          selectedNotifications,
          allSelected,
        };

        if (allSelected) {
          // Update notifications for all and reset selection
          dispatch(
            updateNotifications({
              notifications: data.map((notification) => notification.id),
              action,
            })
          );
          checkboxHandlerFunctions.removeSelectionHandler();
          apiService.modifyAll(payload);
          setAllSelected(false);
        } else {
          await apiService
            .modifySelection(payload)
            .then(() => {
              dispatch(
                updateNotifications({
                  notifications: selectedNotifications,
                  action,
                })
              );
            })
            .catch((error) => {
              showErrorMsg({
                content:
                  "Error while modifying selected notifications, please try again later!",
              });
              throw error;
            });
        }
      }
    }

    setSelectedItems([]);
  };

  const findNotificationsByIds = (ids, data) => {
    return data.filter((notification) => ids.includes(notification.id));
  };

  const checkboxHandlerFunctions = {
    toggleReadHandler: async () => {
      // Destructuring the result of getMarkedCounts() function to extract 'unreadCount' and 'readCount' values
      const { unreadCount, readCount } = getMarkedCounts();
      const selectedNotificationObjects = findNotificationsByIds(
        selectedItems,
        data
      );

      showLoadingMsg({ content: "Making selected notificatons as read..." });

      const unreadItems = selectedNotificationObjects.filter(
        (item) => !item.seen
      );

      if (unreadItems.length > 0 && unreadCount > 0) {
        await checkboxActions("mark as read", true).then(() => {
          if (allSelected) {
            showInfoMsg({
              content: `${unreadCount} Notification${
                unreadCount > 1 ? "s" : ""
              } marked as read`,
            });
          } else {
            showInfoMsg({
              content: `${
                unreadItems.length > 1
                  ? `${unreadItems.length} Notifications have`
                  : `${unreadItems.length} Notification has`
              } been marked as read`,
            });
          }
          checkboxHandlerFunctions.removeSelectionHandler();
        });
      } else {
        showInfoMsg({
          content: `All selected notifications are already marked as read`,
        });
      }
    },

    toggleUnreadHandler: async () => {
      const selectedNotificationObjects = findNotificationsByIds(
        selectedItems,
        data
      );

      showLoadingMsg({ content: "Making selected notificatons as unread..." });

      const readItems = selectedNotificationObjects.filter((item) => item.seen);

      if (readItems.length > 0) {
        await checkboxActions("mark as unread", false);
        if (allSelected) {
          showInfoMsg({ content: "All notifications marked as unread" });
        } else {
          showInfoMsg({
            content: `${
              readItems.length > 1
                ? `${readItems.length} Notifications have`
                : `${readItems.length} Notification has`
            } been marked as unread`,
          });
        }
        checkboxHandlerFunctions.removeSelectionHandler();
      } else {
        showInfoMsg({
          content: `All selected notifications are already marked as unread`,
        });
      }
    },

    deleteSelectionsHandler: () => {
      setOpen(true);
    },
    hideSelectionsHandler: async (title) => {
      showLoadingMsg({
        content: "Hiding selected notificatons...",
      });

      let tmpValue = title === "Hidden" ? false : true;
      await checkboxActions("hide", tmpValue);
      // Destructuring the result of getMarkedCounts() function to extract 'unreadCount' and 'readCount' values
      const { unreadCount, readCount } = getMarkedCounts();

      if (allSelected) {
        showInfoMsg({
          content: `
          ${unreadCount + readCount} 
           All notification${unreadCount + readCount > 1 ? "s" : ""}
             been moved to ${tmpValue === false ? "all" : "hidden"} section`,
        });
      } else {
        showInfoMsg({
          content: `
          ${
            selectedItems && selectedItems.length > 1
              ? `${selectedItems.length} Notifications have`
              : `${selectedItems.length} Notification has`
          } 
             been moved to ${tmpValue === false ? "all" : "hidden"} section`,
        });
      }
    },
    selectAllHandler: async () => {
      if (allSelected) {
        setSelectedItems([]);
        setAllSelected(false);
      } else {
        const allNotificationsIds = data.map((notification) => notification.id);
        setSelectedItems(allNotificationsIds);
        setAllSelected(true);
      }
    },
    removeSelectionHandler: () => setSelectedItems([]),
  };

  // Destructuring the result of getMarkedCounts() function to extract 'unreadCount' and 'readCount' values
  const { unreadCount, readCount } = getMarkedCounts();

  return (
    <>
      <div className="notifications-modify">
        {CheckboxHandlers.map(({ title, handler }, i) => {
          // Calculate the count for each button
          let countText = "";
          if (handler === "toggleReadHandler") {
            countText = readCount > 0 ? `${readCount}` : "";
          } else if (handler === "toggleUnreadHandler") {
            countText = unreadCount > 0 ? `${unreadCount}` : "";
          } else {
            countText = `${selectedItems.length}`; // For other actions, show the selected item count
          }

          return (
            <button
              key={i}
              onClick={() => {
                checkboxHandlerFunctions[handler](tabTitle);
              }}
            >
              {title}
              {countText && <span style={counterStyle}>{countText}</span>}
            </button>
          );
        })}
      </div>

      <DeleteModal
        open={open}
        onCancel={() => setOpen(false)}
        onSubmit={async () => {
          showLoadingMsg({
            content: "Deleting selected notificatons...",
          });

          checkboxActions("delete", null);
          if (allSelected) {
            showInfoMsg({
              content: "All notifications have been deleted",
            });
          } else {
            showInfoMsg({
              content: `${
                selectedItems && selectedItems.length > 1
                  ? `${selectedItems.length} Notifications have`
                  : `${selectedItems.length} Notification has`
              } been deleted`,
            });
          }
        }}
        notificationLength={selectedItems.length}
      />
    </>
  );
}

export default CheckboxHandlersActions;
