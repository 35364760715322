import _ from "lodash";
import { fetchAllData } from "../../../../../../utils";
import { API } from "aws-amplify";

export const getDriverOptions = (activities = [], drivers = []) => {
  const activityDriverIds = new Set(
    activities.filter((a) => a.driverId).map((a) => a.driverId)
  );

  const suggestedDrivers =
    activityDriverIds.size > 0
      ? {
          label: "Suggested from activities",
          options: Array.from(
            new Map(
              activities
                .filter((a) => a.driverId && a.driverName)
                .map(({ driverName, driverId }, i) => [
                  driverId,
                  { key: `activity-${i}`, label: driverName, value: driverId },
                ])
            ).values()
          ),
        }
      : null;

  const allDrivers = {
    label: "All Drivers",
    options: drivers
      .filter((driver) => !activityDriverIds.has(driver.driverId))
      .map(({ driverName, driverLastName, driverId }, i) => ({
        key: `driver-${i}`,
        label: driverLastName ? `${driverName} ${driverLastName}` : driverName,
        value: driverId,
      })),
  };

  return [
    suggestedDrivers,
    allDrivers.options.length > 0 ? allDrivers : null,
  ].filter(Boolean);
};

export const getProjectOptions = (activities = [], projects = []) => {
  const activityProjectIds = new Set(
    activities.filter((a) => a.projectId?.trim()).map((a) => a.projectId)
  );

  const suggestedProjects =
    activityProjectIds.size > 0
      ? {
          label: "Suggested from Activities",
          options: Array.from(activityProjectIds)
            .map((projectId) => {
              const project = projects.find((p) => p.projectId === projectId);
              return project?.projectName
                ? {
                    key: `activity-${projectId}`,
                    label: project.projectName,
                    value: project.projectId,
                  }
                : null;
            })
            .filter(Boolean),
        }
      : null;

  const allProjects = projects?.length
    ? {
        label: "All Projects",
        options: projects
          .filter((project) => !activityProjectIds.has(project.projectId))
          .map(({ projectName, projectId }, i) => ({
            key: `project-${i}`,
            label: projectName,
            value: projectId,
          })),
      }
    : null;

  return [
    suggestedProjects?.options.length > 0 ? suggestedProjects : null,
    allProjects?.options.length > 0 ? allProjects : null,
  ].filter(Boolean);
};

export const getActivityForTimestamp = (activities, timestamp) => {
  for (let i = 0; i < activities.length; i++) {
    const currentActivity = activities[i];
    const nextActivity = activities[i + 1];

    const actualDepart = new Date(currentActivity.actualDepart).getTime();

    const actualArrive = new Date(currentActivity.actualArrive).getTime();

    const actualNextDepart = nextActivity
      ? new Date(nextActivity?.actualDepart).getTime()
      : 0;

    if (timestamp >= actualDepart && timestamp <= actualArrive) {
      return currentActivity;
    }

    if (
      nextActivity &&
      timestamp >= actualArrive &&
      timestamp < actualNextDepart
    ) {
      return currentActivity;
    }
  }

  return null;
};

export const groupAndSortActivities = (activities) => {
  const groupedActivities = activities.reduce((groups, activity) => {
    if (!groups[activity.dispatchId]) {
      groups[activity.dispatchId] = [];
    }
    groups[activity.dispatchId].push(activity);
    return groups;
  }, {});

  for (let dispatchId in groupedActivities) {
    groupedActivities[dispatchId].sort((a, b) => {
      const aDate = a.actualDepart || a.startingTime; //startingTime is for testing purposes
      const bDate = b.actualDepart || b.startingTime;

      if (aDate && bDate) {
        return new Date(aDate) - new Date(bDate);
      }
      if (aDate) {
        return -1;
      }
      if (bDate) {
        return 1;
      }
      return 0;
    });
  }

  return groupedActivities;
};

export const updateViolationsForActivities = (
  updateData,
  isFleetLive = false
) => {
  Object.entries(updateData).forEach(([activityId, activity]) => {
    fetchAllData({
      endpoint: "fleetViolations",
      resultId: "violationId",
      otherStringParams: {
        getMaxLimit: "true",
        filters: JSON.stringify([
          {
            conditions: [
              {
                column: "activityId",
                value: activityId,
                formula: "is",
              },
            ],
          },
        ]),
      },
    })
      .then((violations) => {
        if (violations?.length > 0) {
          violations.forEach((violation) => {
            if (
              !isFleetLive &&
              violation?.driver === activity.driverName &&
              violation?.projectId === activity.projectId
            ) {
              return;
            }
            const violationCopy = _.cloneDeep(violation);
            delete violationCopy["userId"];
            delete violationCopy["violationId"];

            const body = {
              ...violationCopy,
              violation_status: "Pending",
            };

            API.put(
              "fleetViolations",
              `/fleetViolations/${violation?.violationId}`,
              {
                body,
              }
            ).catch((err) => {
              console.log("Error updating violation: ", err);
            });
          });
        }
      })
      .catch((err) => {
        console.log("Error fetching violations: ", err);
      });
  });
};

export const getLicensePlateOptions = (fleet = [], oldPlates = []) => {
  const oldPlateSet = new Set(oldPlates.map((plate) => plate.licensePlate));

  const activePlates = fleet
    .filter((plate) => !oldPlateSet.has(plate.licensePlate))
    .map(({ licensePlate, fleetId }, i) => ({
      key: `active-${i}`,
      label: licensePlate,
      value: fleetId,
    }));

  const groupedOldPlates = oldPlates.map(({ licensePlate, plateId }, i) => ({
    key: `old-${i}`,
    label: licensePlate,
    value: plateId,
  }));

  return [
    activePlates.length > 0 && {
      label: "Active Plates",
      options: activePlates,
    },
    groupedOldPlates.length > 0 && {
      label: "Old Plates",
      options: groupedOldPlates,
    },
  ].filter(Boolean);
};
