import React, { useMemo, useState } from "react";
import { Divider, Input, message, Radio } from "antd";
import { Select } from "antd";
import { useSelector } from "react-redux";
import {
  ESTIMATION_INSERTS,
  getServiceProposalInserts,
} from "../../../utils/dataHolder";
import { useRedux } from "src/components/SidebarPages/hooks";
import Swal from "sweetalert2";
import ServiceCategoriesInsideEditor from "./ServiceCategoriesInsideEditor/ServiceCategoriesInsideEditor";
import { InputComponent } from "../../../../../../../SidebarPages/Fleet/components";

import "./ServiceCategoriesSelectionProposal.scss";
import { useSettingsProposalContext } from "../../ProposalBuilderSettings/ProposalContext";
import ElevationHtmlConfiguration from "../../EditTemplate/EditGeneral/ElevationHtmlConfiguration/ElevationHtmlConfiguration";
import {
  MaxTagTooltip,
  MondayButton,
} from "../../../../../../../commonComponents";
import { ReportCheck } from "../../../../../../../SidebarPages/Fleet/fleetsLive/components/LiveReportsView/components/ReportViewController/components";

function ServiceCategoriesSelectionProposal({
  details,
  setDetails,
  proposalDesignRef,
  type,
  disabled = false,
  changeDisabled = () => {},
  onChange,
  elevationEdit,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const serviceDefinitions = useSelector((state) => state.serviceDefinitions);

  const { serviceTemplates, estimationSample } = useSettingsProposalContext();

  const [selectedService, setSelectedService] = useState(null);
  const [groupTitle, setGroupTitle] = useState("general");

  const serviceProposalInserts = useMemo(() => {
    const inserts =
      getServiceProposalInserts(type, serviceTemplates, serviceDefinitions) ||
      [];
    if (type === "generalTemplate") {
      if (!selectedService) {
        return [];
      } else {
        return inserts?.filter((el) => {
          const serviceIdMatch =
            el?.others?.additionalDetails?.service?.serviceId ===
            selectedService?.serviceId;
          const servicesIncludeMatch =
            Array.isArray(el?.others?.additionalDetails?.services) &&
            el?.others.additionalDetails.services.includes(
              selectedService?.serviceId
            );
          return serviceIdMatch || servicesIncludeMatch;
        });
      }
    } else {
      return inserts;
    }
  }, [serviceTemplates, type, details?.service?.serviceName, selectedService]);

  const generalInfoInserts = useMemo(() => {
    return Object.entries(ESTIMATION_INSERTS).map(([key, value]) => {
      return {
        name: key,
        value: value,
      };
    });
  }, []);

  const serviceOptions = useMemo(() => {
    function mapServices(item) {
      return {
        value: item?.serviceId,
        label: item?.serviceId + ". " + item?.serviceName,
      };
    }

    if (groupTitle === "hoist") {
      return serviceDefinitions
        .filter((item) => !!item?.isHoist)
        .map(mapServices);
    } else if (groupTitle === "scope") {
      return serviceDefinitions
        .filter((item) => !!item?.isScope)
        .map(mapServices);
    } else {
      return serviceDefinitions
        .filter((item) => !item?.isScope && !item.isHoist)
        .map(mapServices);
    }
  }, [serviceTemplates, groupTitle]);

  function onSelect(value, data) {
    setDetails((prev) => ({
      ...prev,
      otherDetails: {
        ...prev.otherDetails,
        services: [...prev.otherDetails.services, value],
      },
    }));
  }
  function onDeselect(value, data) {
    setDetails((prev) => ({
      ...prev,
      otherDetails: {
        ...prev.otherDetails,
        services: prev.otherDetails.services.filter((el) => el !== value),
      },
    }));
  }

  const groupChange = (e) => {
    setGroupTitle(e.target.value);
    setDetails((prev) => ({
      ...prev,
      service: {},
      otherDetails: {
        ...prev.otherDetails,
        services: [],
      },
    }));
  };

  return (
    <div
      className={`service_categories_picker ${
        isDarkMode ? "service_categories_picker_dark" : ""
      }`}
    >
      <div className="pick-header">
        <div className="pick-title">
          Category:{" "}
          {type === "generalTemplate" ? "General Template" : "Service Template"}
        </div>{" "}
      </div>
      <div className="pick-body">
        <>
          <div className="pick-category">
            {details?.templateCategory === "service" ? (
              <div>
                <Radio.Group value={groupTitle} onChange={groupChange}>
                  <Radio.Button value="general">General Services</Radio.Button>
                  <Radio.Button value="scope">Scope Services</Radio.Button>
                  <Radio.Button value="hoist">Hoist</Radio.Button>
                </Radio.Group>
                <InputComponent
                  {...{
                    // disabled,
                    type: "select",
                    // mode: "multiple",
                    label: "Service",
                    noFormItem: true,
                    placeholder: "Select Service",
                    initialValue: details?.service?.serviceName,
                    onChange: (value) => {
                      const selectedService = serviceDefinitions.find(
                        (el) => el.serviceId === value
                      );
                      setDetails((prev) => ({
                        ...prev,
                        service: selectedService,
                      }));
                    },
                    customOptions: serviceOptions,
                  }}
                />
              </div>
            ) : null}
            {!!details?.service?.serviceName ? (
              <div style={{ display: "flex", gap: 10, alignItems: "flex-end" }}>
                <InputComponent
                  {...{
                    // disabled,
                    type: "select",
                    mode: "multiple",
                    style: { width: "100%" },
                    label: "Use also for",
                    noFormItem: true,
                    placeholder: "Select Service",
                    initialValue: details?.otherDetails?.services,
                    onSelect,
                    onDeselect,
                    customOptions: serviceOptions.filter(
                      (el) => el.value !== details?.service?.serviceId
                    ),
                    onClear: () => {
                      setDetails((prev) => ({
                        ...prev,
                        otherDetails: {
                          ...prev.otherDetails,
                          services: [],
                        },
                      }));
                    },
                    maxTagPlaceholder: (e) => {
                      return (
                        <MaxTagTooltip
                          {...{
                            array: e,
                            onDeselect,
                          }}
                        />
                      );
                    },
                    maxTagCount: 3,
                  }}
                />
                <ReportCheck
                  {...{
                    style: { width: 150 },
                    label: "Select All",
                    checked: !!serviceOptions
                      ?.filter((el) => el.value !== details?.service?.serviceId)
                      ?.every((el) =>
                        details?.otherDetails?.services?.includes(el.value)
                      ),
                    onChange: (e) => {
                      if (e) {
                        setDetails((prev) => ({
                          ...prev,
                          otherDetails: {
                            ...prev.otherDetails,
                            services: serviceOptions
                              ?.filter(
                                (el) => el.value !== details?.service?.serviceId
                              )
                              ?.map((el) => el.value),
                          },
                        }));
                      } else {
                        setDetails((prev) => ({
                          ...prev,
                          otherDetails: {
                            ...prev.otherDetails,
                            services: [],
                          },
                        }));
                      }
                    },
                  }}
                />
              </div>
            ) : null}
          </div>{" "}
          {type === "generalTemplate" ? (
            <>
              <div>
                <div className="pick-title">General Info</div>
                <div className="pick-drags">
                  {generalInfoInserts?.map((item, key) => {
                    return (
                      <div
                        key={"card" + key + `${disabled ? "disabled" : ""}`}
                        className="cardProposal"
                        draggable={true}
                        onDragStart={(event) => {
                          event.dataTransfer.setData("text/plain", item?.value);
                        }}
                        onDragEnd={(event) => {
                          console.log("onDragEnd", event);
                        }}
                      >
                        <div className="cardProposalTitle">{item.name}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <Divider style={{ margin: "10px 0" }} />
            </>
          ) : null}
          <div className="pick-title">
            <span>Service Content</span>
            {type === "generalTemplate" && (
              <InputComponent
                {...{
                  type: "select",
                  // label: "Service",
                  noFormItem: true,
                  customOptions: serviceOptions,
                  onChange: (value) => {
                    const selectedService = serviceDefinitions.find(
                      (el) => el.serviceId === value
                    );
                    setSelectedService(selectedService);
                  },
                  onClear: () => {
                    setSelectedService(null);
                  },
                  required: true,
                  style: { width: "200px" },
                }}
              />
            )}
          </div>
          <div className="pick-drags">
            {serviceProposalInserts?.map((item, key) => {
              return (
                <div
                  key={"card" + key + `${disabled ? "disabled" : ""}`}
                  className="cardProposal"
                  draggable={true}
                  onDragStart={(event) => {
                    event.stopPropagation();
                    function resetDataTransfer() {
                      event.dataTransfer.clearData();
                      event.dataTransfer.setData("text/plain", "");
                    }
                    onChange();
                    if (type === "generalTemplate") {
                      resetDataTransfer();
                      const name = item?.name;
                      const id = item?.others?.templateId;
                      const service = selectedService?.serviceName;
                      const toTransfer = `{{${
                        name + "_" + id + "_" + service
                      }}}`;
                      event.dataTransfer.setData("text/plain", toTransfer);
                    } else {
                      resetDataTransfer();
                      const currVal =
                        proposalDesignRef?.current?.component?.value;
                      if (
                        item?.value === "{{engineersWork}}" &&
                        currVal?.includes(item?.value)
                      ) {
                        message.warning("This item is already in the template");
                      } else {
                        event.dataTransfer.setData("text/plain", item?.value);
                      }
                    }
                  }}
                  onDragEnd={(event) => {
                    console.log("onDragEnd", event);
                  }}
                >
                  <div className="cardProposalTitle">{item.name}</div>
                  {type === "generalTemplate" && (
                    <ServiceCategoriesInsideEditor
                      item={item}
                      keyRef={"card" + key}
                      changeDisabled={changeDisabled}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </>
      </div>
    </div>
  );
}

export default ServiceCategoriesSelectionProposal;
