const validateStringValue = (value, dynamicKeys) => {
  // Check for expressions like "=Fields!FieldName.Value"
  if (value.includes("Fields!") && value.includes(".Value")) {
    const regex = /Fields!([a-zA-Z0-9_]+)\.Value/g;

    let match;
    while ((match = regex.exec(value)) !== null) {
      // Add the field name as a dynamic key
      dynamicKeys.add(match[1]);
    }
  }

  if (value.includes("$dataset:") && !value.includes(".")) {
    // Check for other dynamic keys such as invoiceItems
    const regex2 = /\$dataset:([A-Za-z0-9]+)\/([A-Za-z0-9]+)/i;

    if (regex2.test(value)) {
      const match2 = regex2.exec(value);
      dynamicKeys.add(match2[2]); // We are interested in the part before "/"
    }
  }
};

// Helper function to traverse ExpressionFieldName objects and find dynamic keys
const traverseExpressionFieldName = (obj, dynamicKeys) => {
  if (typeof obj === "string") {
    validateStringValue(obj, dynamicKeys);
  } else if (Array.isArray(obj)) {
    obj.forEach((item) => traverseExpressionFieldName(item, dynamicKeys));
  } else if (typeof obj === "object" && obj !== null) {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];

      if (key === "ValueField" && value) {
        // Handle the new case where there is an ValueField key (used in report parameters)
        dynamicKeys.add(value);
      } else if (typeof value === "string") {
        validateStringValue(value, dynamicKeys);
      } else {
        traverseExpressionFieldName(value, dynamicKeys); // Recursively traverse objects
      }
    });
  }
};

export const extractUsedKeysFromReportObj = ({
  reportObj,
  currSampleObjKeys = [],
}) => {
  const reportUsedKeys = new Set();

  // Start the traversal of the report definition
  traverseExpressionFieldName(reportObj.definition, reportUsedKeys);

  let keysToInclude = [...reportUsedKeys];

  if (currSampleObjKeys.length) {
    keysToInclude = keysToInclude.filter((key) =>
      currSampleObjKeys.includes(key)
    );
  }

  return {
    keysToInclude: keysToInclude.filter(Boolean),
    reportUsedKeys: [...reportUsedKeys].filter(Boolean),
  };
};
