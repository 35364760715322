import { FormCard } from "../../../../Fleet/components";
import { Tree } from "antd";
import { FolderFilled } from "@ant-design/icons";
import { CardHeader } from "./components";
import "./DocumentationFileTree.scss";
import { openInNewTab } from "../../../../../../utils";
import { useState } from "react";
import { driveApi } from "../../../../../../integrations/DriveRequest";
import { fileIcons } from "../../documentationViewData";
import { FilePreviewModal } from "..";

const DocumentationFileTree = ({
  isEditable,
  record = {},
  accessToken,
  className = "",
  recordValue = "",
  documentation = [],
  categoryConfiguration,
  setTemplateModalVisible,
}) => {
  const driveRequest = driveApi({ accessToken });

  const [previewModalVisible, setPreviewModalVisible] = useState();
  const [currentFile, setCurrentFile] = useState();

  const { googleDriveFolderIds = {} } = record;

  const docFolderId = googleDriveFolderIds?.docObject || "";

  const docKey = docFolderId || "documentation";

  const treeData = [
    {
      title: recordValue,
      key: recordValue,
      selectable: false,
      children: [
        {
          title: "Documentation",
          key: docKey,
          selectable: !!docFolderId,
          children: documentation?.reduce(
            (acc, { docType, folderId }) =>
              !!acc
                ?.map?.(({ title: accDocType }) => accDocType)
                ?.includes(docType)
                ? acc
                : [
                    ...acc,
                    {
                      title: docType,
                      key: folderId,
                      children: documentation
                        ?.filter?.(
                          ({ docType: arrDocType }) => docType === arrDocType
                        )
                        ?.flatMap?.(({ googleDriveUploads }) =>
                          googleDriveUploads?.map?.(
                            ({ name, id, ...rest }) => ({
                              title: name,
                              key: id,
                              ...rest,
                            })
                          )
                        ),
                    },
                  ],
            []
          ),
        },
      ],
    },
  ];

  const titleRender = (data = {}) => (
    <span
      className={!!data?.children ? "folder" : "file"}
      onClick={() => {
        const { type, mimeType, key: id, url } = data;

        type !== "photo" && mimeType !== "application/pdf"
          ? onSelect(data)
          : (() => {
              setPreviewModalVisible(true);
              driveRequest
                .getImageSrc(id)
                .then((image) => setCurrentFile({ ...image, mimeType, url }));
            })();
      }}
    >
      {!!data?.children ? (
        <FolderFilled className="fileIcon" />
      ) : (
        (() => {
          const { title: name } = data;
          const fileExtension = name.split(".").pop();

          const fileType = Object.keys(fileIcons).includes(fileExtension)
            ? fileExtension
            : "image";

          return <span className="fileIcon">{fileIcons[fileType]}</span>;
        })()
      )}
      {data.title}
    </span>
  );

  const onSelect = ({ key, children, url }) => {
    key !== recordValue &&
      key !== "documentation" &&
      !!key &&
      (!!children
        ? openInNewTab(`https://drive.google.com/drive/folders/${key}`)
        : openInNewTab(url));
  };

  return (
    <>
      <FormCard
        title={
          <CardHeader
            {...{ setTemplateModalVisible, categoryConfiguration, isEditable }}
          />
        }
        className={`documentationFileTree ${className}`}
        childrenClassName="treeContainer"
      >
        {!!recordValue && (
          <Tree
            {...{
              treeData,
              titleRender,
              showIcon: true,
              defaultExpandedKeys: [recordValue, docKey],
            }}
          />
        )}
      </FormCard>
      {!!previewModalVisible && (
        <FilePreviewModal
          {...{
            visible: previewModalVisible,
            setVisible: () => {
              setCurrentFile({});
              setPreviewModalVisible(false);
            },
            file: currentFile,
          }}
        />
      )}
    </>
  );
};

export default DocumentationFileTree;
