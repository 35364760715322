import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { message } from "antd";
import { v4 as uuid } from "uuid";
import { uniqBy, debounce } from "lodash";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";

import {
  degFilterHandler,
  groupEntriesInShifts,
  getNotFoundEmployees,
  gridCustomOverlayLoading,
} from "../utils";
import {
  columnDefs,
  gridFilterData,
  excelColumnsConfig,
} from "./degStepColumns";
import DegHeader from "../DegHeader/DegHeader";
import { EMPTY_ROW } from "../utils/degParser";
import DegModalContext from "../DegModalContext";
import FooterTotals from "../FooterTotals/FooterTotals";
import { DEG_DATE_FORMAT } from "../utils/cellFunctions";
import { OVERHEAD_ENTRY_TYPES } from "../../degModalData";
import { useEntriesApi } from "../../../../../../PayrollLive/utils";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import Filter from "../../../../../../../SidebarPages/BasePage/components/Filter/Filter";

let degExternalFilter = {
  showHr: true,
  showOnlyHr: false,
  showIncorrect: true,
  showIncomplete: true,
  showOnlyIncorrect: false,
  showOnlyIncomplete: false,
};

function DegStep() {
  const {
    crews,
    rowData,
    degName,
    jobsites,
    notFound,
    emptyData,
    crewTeams,
    analytics,
    rowToEdit,
    isLastStep,
    isDarkMode,
    setRowData,
    setNotFound,
    setEmptyData,
    getEntryLogs,
    incorrectData,
    setDegGridApi,
    degStepFilters,
    warningFilters,
    setDegColumnApi,
    jobsiteServices,
    degAccessRights,
    scheduleMatching,
    addActionToRegister,
    degGridApi: gridApi,
    filtersFromSelection,
    updateStatisticsHandler,
    setFiltersFromSelection,
  } = useContext(DegModalContext);

  const [filterOpen, setFilterOpen] = useState(false);
  const [searchInput, setSearchInput] = useState(null);
  const [fieldSelected, setFieldSelected] = useState(false);
  const [actionsDisabled, setActionsDisabled] = useState(true);
  const [filtersData, setFiltersData] = useState(
    JSON.parse(sessionStorage.getItem("degStepFilters")) || degStepFilters || {}
  );

  const headerRef = useRef();
  const { postEntries, removeEntries, updateEntries, loading } =
    useEntriesApi();

  const { employeeWeekTotals, employeesHoursPerDay, generalOverheadTotal } =
    analytics;

  const {
    hrHighlighted,
    incorrectHighlighted,
    incompleteHighlighted,
    missingLunchHighlighted,
    overheadEntriesHighlighted,
  } = warningFilters;

  const paginationPageSize = Math.floor((window.innerHeight - 340) / 42);

  const onGridReady = useCallback((param) => {
    setDegGridApi(param.api);
    setDegColumnApi(param.columnApi);
    if (!rowData?.length && rowToEdit?.degId) {
      param.api.showLoadingOverlay();
    }
    param.columnApi.autoSizeAllColumns(false);
  }, []);

  const onFirstDataRendered = useCallback(function ({ api, columnApi }) {
    const degColumnsState = JSON.parse(
      sessionStorage.getItem("degStepColumns")
    );

    if (!degColumnsState?.length) {
      columnApi.autoSizeAllColumns(false);
      sessionStorage.setItem(
        "degStepColumns",
        JSON.stringify(columnApi.getColumnState())
      );
    } else {
      columnApi.applyColumnState({
        state: degColumnsState,
        applyOrder: true,
      });
    }
    api.hideOverlay();
  }, []);

  const getRowId = useCallback((param) => {
    return param?.data?.entryId;
  }, []);

  const getRowStyle = useCallback(
    (param) => {
      if (incorrectHighlighted) {
        if (incorrectData.includes(param?.node?.data?.entryId)) {
          return { background: "rgba(245, 76, 74, 0.6)" };
        }
      }
      if (incompleteHighlighted) {
        if (emptyData?.includes(param?.node?.data?.entryId)) {
          return { background: "#dfab0199" };
        }
      }
      if (hrHighlighted) {
        if (param?.node?.data?.punchType === "HR") {
          return { background: "#1bd60a50" };
        }
      }
      if (missingLunchHighlighted) {
        if (param?.node?.data?.missingLunch) {
          return { background: "rgba(223, 171, 1, 0.6)" };
        }
      }
      if (overheadEntriesHighlighted) {
        if (OVERHEAD_ENTRY_TYPES.includes(param?.node?.data?.punchType)) {
          return { background: "rgba(143, 101, 177, 0.4)" };
        }
      }
    },
    [
      rowData,
      emptyData,
      isLastStep,
      hrHighlighted,
      incorrectData,
      incorrectHighlighted,
      incompleteHighlighted,
      overheadEntriesHighlighted,
    ]
  );

  const isExternalFilterPresent = useCallback(() => {
    return (
      !degExternalFilter.showHr ||
      !degExternalFilter.showIncorrect ||
      !degExternalFilter.showIncomplete ||
      degExternalFilter.showOnlyIncorrect ||
      degExternalFilter.showOnlyIncomplete ||
      degExternalFilter.showOnlyHr
    );
  }, [degExternalFilter]);

  const doesExternalFilterPass = useCallback(
    ({ data }) => {
      if (degExternalFilter.showOnlyIncorrect) {
        return incorrectData?.includes(data?.entryId);
      } else if (degExternalFilter.showOnlyIncomplete) {
        return emptyData?.includes(data?.entryId);
      } else if (degExternalFilter.showOnlyHr) {
        return data?.punchType === "HR";
      } else {
        if (data?.punchType === "HR") {
          return degExternalFilter?.showHr;
        }
        if (incorrectData?.includes(data?.entryId)) {
          return degExternalFilter.showIncorrect;
        }
        if (emptyData?.includes(data?.entryId)) {
          return degExternalFilter.showIncomplete;
        }
        return true;
      }
    },
    [degExternalFilter, incorrectData, emptyData]
  );

  const statusBar = useMemo(() => {
    const rateAccess =
      degAccessRights.children.findIndex((el) => el?.title === "Rate") > -1;
    return {
      statusPanels: rateAccess
        ? [
            {
              align: "left",
              key: "footerTotals",
              statusPanel: FooterTotals,
            },
          ]
        : [],
    };
  }, [degAccessRights]);

  const groupByShift = useMemo(() => {
    if (analytics && !!crews?.length && !!jobsites?.length) {
      return groupEntriesInShifts({
        crews,
        rowData,
        jobsites,
        analytics: analytics,
      });
    }
    return [];
  }, [analytics, crews, jobsites, rowData]);

  const memoColumnDefs = useMemo(() => {
    const degColumnsState = JSON.parse(
      sessionStorage.getItem("degStepColumns")
    );

    if (fieldSelected) {
      const columns = columnDefs({
        notFound,
        isLastStep,
        openEntryLogs,
        degAccessRights,
        jobsiteServices,
        setEditModalData,
        setAddEmployeeModal,
        employeesHoursPerDay,
        editModalData: getEditEntry,
      }).map((column) =>
        column.field === fieldSelected
          ? column
          : { ...column, getQuickFilterText: () => "" }
      );

      let modifiedColumns = [];
      if (degColumnsState?.length) {
        for (let i = 0; i < degColumnsState?.length; i++) {
          const el = degColumnsState[i];
          const elIndex = columns.findIndex(
            ({ field, colId }) => colId === el?.colId || field === el?.colId
          );
          if (columns?.[elIndex]) {
            modifiedColumns.push(columns[elIndex]);
          }
        }
      }

      return degColumnsState?.length ? modifiedColumns : columns;
    } else {
      const columns = columnDefs({
        notFound,
        isLastStep,
        openEntryLogs,
        degAccessRights,
        jobsiteServices,
        setEditModalData,
        setAddEmployeeModal,
        employeesHoursPerDay,
        editModalData: getEditEntry,
      });

      let modifiedColumns = [];
      if (degColumnsState?.length) {
        for (let i = 0; i < degColumnsState?.length; i++) {
          const el = degColumnsState[i];
          const elIndex = columns.findIndex(
            ({ field, colId }) => colId === el?.colId || field === el?.colId
          );
          if (columns?.[elIndex]) {
            modifiedColumns.push(columns[elIndex]);
          }
        }
      }

      return degColumnsState?.length ? modifiedColumns : columns;
    }
  }, [
    notFound,
    isLastStep,
    fieldSelected,
    degAccessRights,
    jobsiteServices,
    employeesHoursPerDay,
  ]);

  function getColumnState(event) {
    if (
      event?.finished &&
      (event.source === "uiColumnMoved" || event.source === "uiColumnResized")
    ) {
      const columnOrder = event.columnApi.getColumnState();
      sessionStorage.setItem("degStepColumns", JSON.stringify(columnOrder));
    }
  }

  function getEditEntry() {
    if (headerRef?.current) {
      return headerRef.current.getEditEntry();
    }
  }

  function setEditModalData(data) {
    if (headerRef?.current) {
      headerRef.current.setEditEntry(data);
    }
  }

  function setAddEmployeeModal(employee) {
    if (headerRef?.current) {
      headerRef.current.setAddEmployee(employee);
    }
  }

  function setShiftBreakWarning(visible) {
    if (headerRef?.current) {
      headerRef.current.setShiftBreakWarning(visible);
    }
  }

  async function openEntryLogs(entryId) {
    await getEntryLogs(entryId);
    if (headerRef?.current) {
      headerRef.current.setEntryLogVisible(entryId);
    }
  }

  function onSearch(e) {
    if (gridApi) {
      gridApi.setFilterModel({
        uploadName: {
          filter: e,
          type: "contains",
          filterType: "text",
        },
      });
    }
  }

  function addData() {
    message.loading({
      duration: 0,
      key: "addData",
      content: "Adding Entry...",
    });
    let entryId = uuid();
    const newRowData = { ...EMPTY_ROW, entryId, degId: rowToEdit?.degId };

    postEntries({
      entries: [newRowData],
      onErrorCallback: (err) => {
        console.log("Error adding new entry: ", err);
        message.error({
          duration: 3,
          key: "addData",
          content: "There was a problem adding this entry",
        });
      },
      onSuccessCallback: () => {
        message.success({
          duration: 3,
          key: "addData",
          content: "Entry added successfully",
        });
        addActionToRegister({
          type: "new",
          newActions: [newRowData],
        });
        setEmptyData([...emptyData, entryId]);
        setRowData((prev) => [newRowData].concat(prev));
      },
    });
  }

  function removeData(checkForShiftBreak = true) {
    if (gridApi) {
      message.loading({
        duration: 0,
        key: "removeData",
        content: "Deleting Entries...",
      });
      let employeesToUpdate = [];
      let brokenShift = [];
      let rowsToRemove = gridApi.getSelectedNodes()?.map(({ data }) => {
        if (data?.employeeId) {
          employeesToUpdate.push(data?.employeeId);
        }
        brokenShift = checkForShiftBreak
          ? brokenShift.concat(
              groupByShift.find(
                (shift) =>
                  shift?.employeeId === data?.employeeId &&
                  shift.firstClockIn <= data.punchTimeStamp &&
                  data.punchTimeStamp <= shift.clockOut
              )
            )
          : [];
        return data;
      });
      const rowIds = rowsToRemove.flatMap((el) =>
        el?.entryId ? el.entryId : []
      );
      brokenShift = uniqBy(
        brokenShift?.filter(Boolean),
        ({ shiftId }) => shiftId
      );
      if (brokenShift.length) {
        setShiftBreakWarning(brokenShift);
        return;
      } else {
        removeEntries({
          entries: rowsToRemove,
          onSuccessCallback: () => {
            const removeActions = {
              newEntries: [],
              editedEntries: [],
              removedEntries: rowsToRemove,
            };

            addActionToRegister({ type: "remove", removeActions });

            setRowData((prev) =>
              prev.filter((el) => !rowIds.includes(el?.entryId))
            );

            setNotFound(
              getNotFoundEmployees({
                crews,
                rowData,
              })
            );
            if (
              rowsToRemove?.some(({ entryId }) => emptyData?.includes(entryId))
            ) {
              setEmptyData(
                emptyData?.filter((entryId) => {
                  return !rowsToRemove?.find(({ entryId: e }) => e === entryId);
                })
              );
            }
            message.success({
              duration: 3,
              key: "removeData",
              content: "Entries deleted successfully",
            });
          },
          onErrorCallback: (err) => {
            console.log("Error removing entries: ", err);
            message.loading({
              duration: 3,
              key: "removeData",
              content: "There was a problem on deleting the entries",
            });
          },
        });
      }
    }
  }

  function duplicateRows() {
    if (gridApi) {
      message.loading({
        duration: 0,
        key: "duplicateRows",
        content: "Duplicating rows..",
      });
      let employeesToUpdate = [];
      let existingEmptyEntries = [];
      let add = gridApi.getSelectedNodes()?.map(({ data }) => {
        let entryId = uuid();
        if (emptyData?.includes(data?.entryId)) {
          existingEmptyEntries.push(entryId);
        }
        employeesToUpdate.push(data?.employeeId);
        return { ...data, entryId, degId: rowToEdit?.degId };
      });

      updateEntries({
        entries: add,
        onSuccessCallback: () => {
          setRowData((prev) => add.concat(prev));
          updateStatisticsHandler(employeesToUpdate.filter(Boolean));
          setEmptyData([...emptyData, ...existingEmptyEntries]);
          addActionToRegister({
            type: "new",
            newActions: add,
          });
          message.success({
            duration: 3,
            key: "duplicateRows",
            content: "Rows duplicated successfully",
          });
        },
        onErrorCallback: (err) => {
          console.log("Error duplicating rows: ", err);
          message.error({
            duration: 3,
            key: "duplicateRows",
            content: "There was a problem duplicating rows",
          });
        },
      });
    }
  }

  function onSelectionChanged() {
    if (!gridApi.getSelectedNodes()?.length) {
      setActionsDisabled(true);
    } else {
      setActionsDisabled(false);
    }
  }

  function onFilterTextChange(e) {
    if (!gridApi) {
      gridApi?.forEachDetailGridInfo?.((params) => {
        params.api.setQuickFilter(e);
      });
    } else {
      gridApi.setQuickFilter(e);
    }
    setSearchInput(e);
  }

  function clearFilters() {
    setFiltersData({ distanceFromJob: [] });
    setFiltersFromSelection({});
    sessionStorage.setItem("degStepFilters", JSON.stringify({}));
  }

  function showHasFilters() {
    return !!Object.keys(filtersData)?.filter((key) => filtersData[key])
      ?.length;
  }

  function updateExternalFilter(filter) {
    if (gridApi) {
      degExternalFilter = { ...degExternalFilter, ...filter };
      gridApi.onFilterChanged();
    }
  }

  useEffect(() => {
    return () => {
      degExternalFilter = {
        showHr: true,
        showOnlyHr: false,
        showIncorrect: true,
        showIncomplete: true,
        showOnlyIncorrect: false,
        showOnlyIncomplete: false,
      };
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      let rowsToRefresh = [];
      rowData.forEach((data) => {
        if (
          !!emptyData?.includes(data?.entryId) ||
          incorrectData.includes(data?.entryId) ||
          OVERHEAD_ENTRY_TYPES.includes(data?.punchType) ||
          data?.punchType === "HR"
        ) {
          rowsToRefresh.push(data?.entryId);
        }
      });

      if (!gridApi?.destroyCalled) {
        gridApi?.redrawRows?.({
          rowNodes: rowsToRefresh
            .map((entryId) => {
              return entryId && gridApi?.getRowNode?.(entryId);
            })
            .filter(Boolean),
        });
      }
    }
  }, [
    gridApi,
    rowData,
    emptyData,
    hrHighlighted,
    incorrectData,
    incorrectHighlighted,
    incompleteHighlighted,
    overheadEntriesHighlighted,
  ]);

  useEffect(() => {
    degFilterHandler({ gridApi, filtersData });
  }, [JSON.stringify(filtersData), gridApi]);

  useEffect(() => {
    if (Object.keys(filtersFromSelection || {})?.length) {
      setFiltersData(filtersFromSelection);
    }
  }, [filtersFromSelection]);

  return (
    <div className="deg-step" data-testid="deg-step">
      <DegHeader
        ref={headerRef}
        {...{
          addData,
          loading,
          onSearch,
          notFound,
          filterOpen,
          removeData,
          searchInput,
          setFilterOpen,
          duplicateRows,
          showHasFilters,
          actionsDisabled,
          setFieldSelected,
          degExternalFilter,
          onFilterTextChange,
          updateExternalFilter,
        }}
      />
      <div
        className={`deg-grid-container ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        <AgGridReact
          {...{
            rowData,
            getRowId,
            statusBar,
            getRowStyle,
            onGridReady,
            pagination: true,
            animateRows: true,
            paginationPageSize,
            onSelectionChanged,
            onFirstDataRendered,
            doesExternalFilterPass,
            isExternalFilterPresent,
            rowSelection: "multiple",
            columnDefs: memoColumnDefs,
            enableCellChangeFlash: true,
            rowGroupPanelShow: "always",
            onColumnMoved: getColumnState,
            suppressRowClickSelection: true,
            onColumnResized: debounce(getColumnState, 300),
            overlayLoadingTemplate: gridCustomOverlayLoading(),
            context: {
              crewTeams,
              rowToEdit,
              scheduleMatching,
              employeeWeekTotals,
              generalOverheadTotal,
            },
            defaultExcelExportParams: {
              columnKeys: excelColumnsConfig,
              fileName: analytics?.dateRange?.length
                ? `Week(${dayjsNY(
                    analytics?.dateRange?.[0]
                  ).week()}) (${dayjsNY(analytics?.dateRange?.[0]).format(
                    DEG_DATE_FORMAT
                  )} - ${dayjsNY(analytics?.dateRange?.[1]).format(
                    DEG_DATE_FORMAT
                  )}) Entries`
                : degName,
            },
            sideBar: {
              toolPanels: [
                {
                  id: "columns",
                  iconKey: "columns",
                  labelKey: "columns",
                  labelDefault: "Columns",
                  toolPanel: "agColumnsToolPanel",
                },
                {
                  id: "filters",
                  iconKey: "filter",
                  labelKey: "filters",
                  labelDefault: "Filters",
                  toolPanel: "agFiltersToolPanel",
                },
              ],
            },
            defaultColDef: {
              flex: 1,
              filter: true,
              minWidth: 130,
              sortable: true,
              resizable: true,
              enableColResize: true,
              enableRowGroup: false,
              suppressSizeToFit: true,
              useValueParserForImport: true,
              useValueFormatterForExport: true,
              suppressDragLeaveHidesColumns: true,
            },
          }}
        />
      </div>
      {filterOpen && (
        <Filter
          title={degName}
          setOpen={setFilterOpen}
          filters={gridFilterData}
          filtersData={filtersData}
          clearFilters={clearFilters}
          rowData={rowData?.length ? rowData : []}
          getFilters={(val) => {
            // eslint-disable-next-line no-undef
            setFiltersData(structuredClone(val));
            sessionStorage.setItem("degStepFilters", JSON.stringify(val));
          }}
        />
      )}
    </div>
  );
}

export default DegStep;
