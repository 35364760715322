import { useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import htmlParser from "../../../utils/htmlParser";
import MondayButton from "../MondayButton/MondayButton";
import { CloseIconModal } from "../../../assets";
import "./AgDescription.scss";

// this function handles empty rich text for example these cases <p><b></b></p> when dont have main value
const containsString = (html) => {
  if (!html) return "";
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || "";
};

const AgDescription = ({ title, value }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);

  const hasDesc = containsString(value);

  return (
    <>
      {!!hasDesc ? (
        <div
          style={{
            color: "#1264A3",
            fontWeight: "600",
            cursor: "pointer",
          }}
          onClick={() => {
            setOpenDescriptionModal(true);
          }}
        >
          Description
        </div>
      ) : (
        <div
          style={{
            color: "#ACACAC",
          }}
        >
          No Description
        </div>
      )}
      {openDescriptionModal ? (
        <Modal
          title={title}
          open={openDescriptionModal}
          onCancel={() => setOpenDescriptionModal(false)}
          className={`agDescription-modal ${
            isDarkMode && "agDescription-modalDark"
          }`}
          footer={null}
        >
          <span className="title">Description</span>
          <span className="description">{htmlParser(value)}</span>
          <MondayButton
            className="mondayButtonRed"
            Icon={<CloseIconModal />}
            onClick={() => setOpenDescriptionModal(false)}
          >
            Close
          </MondayButton>
        </Modal>
      ) : null}
    </>
  );
};

export default AgDescription;
