import { LOG_CATEGORIES_PROFILE } from "./LOG_CATEGORIES_PROFILE";
import { userLogsObj } from "./userLogsObj";
import { getChangedData } from "../../../../../SidebarPages/Accounting/components/utilities";

export const handleSaveLogs = ({
  userConfiguration,
  preferences,
  profileChanges,
  saveAddedLogs,
}) => {
  const newLogs = [];

  const defaultLogData = userLogsObj();

  // save log for profile picture changes
  if (profileChanges.hasOwnProperty("userImage")) {
    const { userImage } = profileChanges;

    newLogs.push({
      ...defaultLogData,
      actionType: `${!!userImage ? "Upload" : "Delete"} Profile Picture`,
      category: LOG_CATEGORIES_PROFILE.userImage,
      label: "Account Information",
      currentData: { googleDriveFileId: userImage },
      previousData: {
        googleDriveFileId: userConfiguration?.googleDriveFileId,
      },
    });
  }

  // save log for pinNumber
  if (profileChanges.hasOwnProperty("pinNumber")) {
    const { pinNumber } = profileChanges;
    newLogs.push({
      ...defaultLogData,
      actionType: "Update",
      category: LOG_CATEGORIES_PROFILE.pinNumber,
      label: "Account Information",
      currentData: { pinNumber },
      previousData: { pinNumber: userConfiguration?.pinNumber },
    });
  }

  // save log for idleTimeOut
  if (profileChanges.hasOwnProperty("idleTimeOut")) {
    const { idleTimeOut } = profileChanges;
    newLogs.push({
      ...defaultLogData,
      actionType: "Update",
      category: LOG_CATEGORIES_PROFILE.idleTimeOut,
      label: "Account Information",
      currentData: { idleTimeOut },
      previousData: { idleTimeOut: userConfiguration?.idleTimeOut },
    });
  }

  //save logs for topic notification changes
  if (profileChanges.hasOwnProperty("myNotifications")) {
    const { myNotifications } = profileChanges;

    const result = getChangedData(
      myNotifications,
      preferences.preferences.notifications.topics
    );

    newLogs.push({
      ...defaultLogData,
      actionType: "Enable/Disable",
      category: LOG_CATEGORIES_PROFILE.myNotifications,
      recordId: userConfiguration?.cognitoUserId,
      currentData: result.curr,
      previousData: result.prev,
    });
  }

  // save log for profileColor
  if (profileChanges.hasOwnProperty("profileColor")) {
    const { profileColor } = profileChanges;
    newLogs.push({
      ...defaultLogData,
      actionType: "Update",
      category: LOG_CATEGORIES_PROFILE.profileColor,
      label: "Account Information",
      currentData: { profileColor },
      previousData: { profileColor: preferences.preferences?.profileColor },
    });
  }

  // save logs for changes in automation preferences
  if (profileChanges.hasOwnProperty("automationPreferences")) {
    const { automationPreferences } = profileChanges;

    const result = getChangedData(
      automationPreferences,
      preferences.preferences.notifications.automationPreferences
    );

    newLogs.push({
      ...defaultLogData,
      actionType: "Enable/Disable",
      category: LOG_CATEGORIES_PROFILE.myNotifications,
      recordId: userConfiguration?.cognitoUserId,
      topic: "Automation Preferences",
      currentData: result.curr,
      previousData: result.prev,
    });
  }

  if (newLogs.length > 0) {
    saveAddedLogs(newLogs);
  }
};
