import { Alert, Card, message, Modal, Spin } from "antd";
import React, { useState } from "react";
import Forecasting from "../../Forecasting";
import "./ForecastingRaport.scss";
import { XIcon } from "../../../../../Communication/assets";
import { useEffect } from "react";
import { fetchAllData, gsiQueryTable } from "../../../../../../../utils";
import { LoadingOutlined } from "@ant-design/icons";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import { API } from "aws-amplify";
import {
  detailedComparison,
  getDaysForSpecificService,
  getDaysWithProgress,
  getElevationDays,
  getProgressDaysForService,
  onlyEstWithScopeFalse,
  preventDuplicate,
} from "../../../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { MondayButton } from "../../../../../../commonComponents";
import {
  forecastingData,
  forecastingRaport,
  isKeyTheSame,
  transformData,
} from "./forecastingData";
import { typeOfWorkColors } from "../../../../../../Header/forms/Scheduling/DataAgGrid/columnDefs";
import { removeDuplicates } from "../../../ScheduleView/AgGridData/utils/functions";
import { EyeIcon } from "../../../../../DynamicView/src";
import { InfoIconBlue } from "../../../../../../Header/forms/Scheduling/Subcomponents/SelectView/newModals/TrucksModal/HelpingComponents/icons";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { EmptyBox, WarningBellIcon } from "../../../../../../../assets";
import CompareChanges from "../../../../../../Header/forms/Scheduling/PLIPerService/CompareChanges/CompareChanges";
import HeaderStatistics from "../../../../../../ProposalBuilder/WaitingRoomProposal/HeaderStatistics/HeaderStatistics";
import { ChartIcon } from "../../../../../BasePage/src";
import ForecastingCharts from "../ForecastingCharts/ForecastingCharts";

const ForecastingRaport = (props) => {
  const serviceDefinitions = useSelector((state) => state?.serviceDefinitions);

  const projectId = props?.rowData?.projectId;
  const isModalVisible = props?.forecastingRaport || false;
  const [noSchedules, setNoSchedules] = useState(false);
  const [types, setTypes] = useState([]);
  const [visibleCharts, setVisibleCharts] = useState({});
  let compare = {};
  useEffect(() => {
    const fetchSchedulesData = async () => {
      Swal.fire({
        title: "Please wait...",
        text: "We are getting all the schedules for you!",
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });
      try {
        const schedules = await fetchAllData({
          endpoint: "scheduling",
          resultPosition: "scheduling",
          resultId: "scheduleId",
          otherStringParams: {
            filters: JSON.stringify([
              {
                conditions: [
                  {
                    column: "projectId",
                    value: projectId,
                    formula: "is",
                  },
                ],
              },
            ]),
          },
        });

        if (schedules?.length === 0) {
          setNoSchedules(true);
          return;
        }

        if (Array.isArray(schedules) && schedules?.length > 0) {
          const schedulesWithToBeScheduled = [];
          for (const schedule of schedules) {
            const sowItems = await gsiQueryTable({
              tableName: "toBeScheduled",
              indexName: "scheduleId-index",
              filterKey: "scheduleId",
              filterValue: schedule?.scheduleId,
            });

            schedule.toBeScheduled = _.groupBy(sowItems, "estimationId");
            schedulesWithToBeScheduled.push(schedule);
          }

          const groupSchedules =
            _.groupBy(schedulesWithToBeScheduled, "typeOfWork") || {};

          let temp = {};

          Object.entries(groupSchedules)?.forEach(([key, value], index) => {
            const sortedSchedules = value?.sort(
              (a, b) => dayjsNY(a?.createdAt) - dayjsNY(b?.createdAt)
            );

            const allCrews = value?.flatMap((crews) => crews?.scheduleCrews);

            temp[key] = {};
            sortedSchedules?.forEach((schedule) => {
              const progressDays = getDaysWithProgress(
                schedule?.scheduleId,
                schedule?.scheduleDays,
                schedule?.toBeScheduled,
                schedule?.typeOfWork
              )?.sort((a, b) => a?.startDate - b?.startDate);

              Object.entries(schedule?.toBeScheduled)?.forEach(
                ([estimationId, services]) => {
                  if (!temp[key][estimationId]) {
                    temp[key][estimationId] = [];
                  }
                  temp[key][estimationId] = [
                    ...(temp[key][estimationId] || []),
                    ...(services || [])?.map((ser) => {
                      const serviceDays = [
                        ...new Set(
                          getDaysForSpecificService(ser?.serviceOptions)
                        ),
                      ];

                      const getDates =
                        schedule?.scheduleDays
                          ?.filter((day) => serviceDays?.includes(day?.id))
                          ?.sort(
                            (a, b) =>
                              dayjsNY(a?.startDate) - dayjsNY(b?.startDate)
                          ) || [];

                      const getCrews =
                        allCrews?.filter((crew) =>
                          crew?.days?.some(
                            (mem) =>
                              !!getDates?.find((day) => day?.id === mem?.dayId)
                          )
                        ) || [];

                      let plannedNrOfHours = 0;
                      getDates?.forEach((date) => {
                        const hoursPerDay = dayjsNY(date?.endDate).diff(
                          dayjsNY(date?.startDate),
                          "hours",
                          true
                        );
                        plannedNrOfHours +=
                          hoursPerDay > 8 ? hoursPerDay - 1 : hoursPerDay;
                      });

                      const serviceDaysProgress =
                        preventDuplicate(
                          progressDays?.filter(
                            (el) =>
                              el?.estimationId === estimationId &&
                              el?.serviceId === ser?.serviceId
                          ),
                          "dayId"
                        ) || [];

                      const getCrewsProgress =
                        getCrews?.filter((crew) =>
                          crew?.days?.some(
                            (day) =>
                              !!serviceDaysProgress?.find(
                                (d) => d?.dayId === day?.dayId
                              )
                          )
                        ) || [];

                      let progressHours = 0;
                      if (serviceDaysProgress?.length > 0) {
                        serviceDaysProgress?.forEach((date) => {
                          const hoursPerDay = dayjsNY(date?.endDate).diff(
                            dayjsNY(date?.startDate),
                            "hours",
                            true
                          );
                          progressHours +=
                            hoursPerDay > 8 ? hoursPerDay - 1 : hoursPerDay;
                        });
                      }

                      ser[`${key}firstProgressDay`] =
                        serviceDaysProgress?.length > 0
                          ? dayjsNY(serviceDaysProgress?.[0]?.startDate)
                              .startOf("day")
                              .valueOf()
                          : "N/A";
                      ser[`${key}daysWithProgress`] =
                        serviceDaysProgress?.length || 0;
                      ser[`${key}progressHours`] = progressHours || 0;
                      ser[`${key}potentialStartDate`] =
                        getDates?.length > 0
                          ? dayjsNY(getDates?.[0]?.startDate)
                              .startOf("day")
                              .valueOf()
                          : undefined;
                      ser[`${key}plannedNrOfDays`] = getDates?.length || 0;
                      ser[`${key}plannedNrOfHours`] = plannedNrOfHours || 0;
                      ser[`${key}plannedNrOfCrews`] = getCrews?.length || 0;
                      ser[`${key}crewsProgress`] =
                        getCrewsProgress?.length || 0;
                      ser[`${key}plannedNrOfTrucks`] = 0;
                      ser["scheduleCreated"] = schedule?.createdAt;

                      ser["serviceOptions"] = [
                        ser?.serviceOptions?.[0]?.map((elevation) => {
                          const eleDays = [
                            ...new Set(getElevationDays(elevation)),
                          ];

                          const getEleDates = getDates?.filter((date) =>
                            eleDays?.includes(date?.id)
                          );

                          const eleCrews = getCrews?.filter((crew) =>
                            crew?.days?.some((day) =>
                              eleDays?.includes(day?.dayId)
                            )
                          );

                          const elevationProgressDays =
                            serviceDaysProgress?.filter(
                              (b) =>
                                b?.elevationName ===
                                  elevation?.elevationLabel &&
                                b?.elevationId === elevation?.elevationId
                            ) || [];

                          const getEleCrewsProgress =
                            eleCrews?.filter((crew) =>
                              crew?.days?.some(
                                (day) =>
                                  !!elevationProgressDays?.find(
                                    (d) => d?.dayId === day?.dayId
                                  )
                              )
                            ) || [];

                          let progressHours = 0;

                          if (elevationProgressDays?.length > 0) {
                            elevationProgressDays?.forEach((date) => {
                              const hoursPerDay = dayjsNY(date?.endDate).diff(
                                dayjsNY(date?.startDate),
                                "hours",
                                true
                              );
                              progressHours +=
                                hoursPerDay > 8 ? hoursPerDay - 1 : hoursPerDay;
                            });
                          }

                          let eleHours = 0;

                          getEleDates?.forEach((date) => {
                            const hoursPerDay = dayjsNY(date?.endDate).diff(
                              dayjsNY(date?.startDate),
                              "hours",
                              true
                            );
                            eleHours +=
                              hoursPerDay > 8 ? hoursPerDay - 1 : hoursPerDay;
                          });
                          elevation[`${key}crewsProgress`] =
                            getEleCrewsProgress?.length || 0;
                          elevation[`${key}progressHours`] = progressHours || 0;
                          elevation[`${key}daysWithProgress`] =
                            elevationProgressDays?.length || 0;
                          elevation[`${key}plannedNrOfDays`] =
                            getEleDates?.length || 0;
                          elevation[`${key}plannedNrOfHours`] = eleHours || 0;
                          elevation[`${key}plannedNrOfCrews`] =
                            eleCrews?.length || 0;
                          elevation[`${key}plannedNrOfTrucks`] = 0;

                          elevation.items = elevation?.items?.map((pli) => {
                            let pliHours = 0;

                            const getPliDates =
                              getEleDates?.filter((date) =>
                                pli?.days?.includes(date?.id)
                              ) || [];

                            const PliCrews =
                              getCrews?.filter((crew) =>
                                crew?.days?.some((day) =>
                                  pli?.days?.includes(day?.dayId)
                                )
                              ) || [];

                            const getPliCrewsProgress =
                              PliCrews?.filter((crew) =>
                                crew?.days?.some(
                                  (day) =>
                                    !!elevationProgressDays?.find(
                                      (d) => d?.dayId === day?.dayId
                                    )
                                )
                              ) || [];

                            const pliProgressDays =
                              progressDays?.filter(
                                (item) =>
                                  item?.estimationId === estimationId &&
                                  item?.serviceId === ser?.serviceId &&
                                  item?.elevationName ===
                                    elevation?.elevationLabel &&
                                  item?.elevationId ===
                                    elevation?.elevationId &&
                                  item?.pli === pli?.id
                              ) || [];

                            let progressHours = 0;

                            if (pliProgressDays?.length > 0) {
                              pliProgressDays?.forEach((date) => {
                                const hoursPerDay = dayjsNY(date?.endDate).diff(
                                  dayjsNY(date?.startDate),
                                  "hours",
                                  true
                                );
                                progressHours +=
                                  hoursPerDay > 8
                                    ? hoursPerDay - 1
                                    : hoursPerDay;
                              });
                            }

                            const pliCrews = getCrews?.filter((crew) =>
                              crew?.days?.some((day) =>
                                pli?.days?.includes(day?.dayId)
                              )
                            );
                            getPliDates?.forEach((date) => {
                              const hoursPerDay = dayjsNY(date?.endDate).diff(
                                dayjsNY(date?.startDate),
                                "hours",
                                true
                              );
                              pliHours +=
                                hoursPerDay > 8 ? hoursPerDay - 1 : hoursPerDay;
                            });
                            pli[`${key}crewsProgress`] =
                              getPliCrewsProgress?.length || 0;
                            pli[`${key}progressHours`] = progressHours || 0;
                            pli[`${key}daysWithProgress`] =
                              pliProgressDays?.length || 0;
                            pli[`${key}plannedNrOfDays`] =
                              getPliDates?.length || 0;
                            pli[`${key}plannedNrOfHours`] = pliHours || 0;
                            pli[`${key}plannedNrOfCrews`] =
                              pliCrews?.length || 0;
                            pli[`${key}plannedNrOfTrucks`] = 0;
                            return pli;
                          });

                          return elevation;
                        }),
                      ];

                      return ser;
                    }),
                  ];
                  temp[key] = transformData(temp[key], key);
                }
              );
            });
          });

          props?.setSchedules(temp);

          setTypes(Object.keys(groupSchedules));
        } else {
          props.setSchedules({});
        }
      } catch (error) {
        console.error("Error fetching scheduling data:", error);
        Swal.close();
      } finally {
        Swal.close();
      }
    };

    if (projectId && isModalVisible) {
      setTimeout(fetchSchedulesData, 0);
    }
  }, [isModalVisible, projectId]);

  return (
    <div>
      <Modal
        {...{
          title: null,
          open: props?.forecastingRaport,
          centered: true,
          className: "forecasting-raport",
          onCancel: () => {
            props.onClose();
          },
          closeIcon: null,
          footer: null,
        }}
      >
        {!!noSchedules ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: 10,
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
            }}
          >
            <h1>
              NO SCHEDULES WERE FOUND FOR {props?.rowData?.projectAddress}
            </h1>
          </div>
        ) : (
          // <>
          //   <HeaderStatistics />
          //   <div>elel</div>
          // </>
          types?.map((type, typeOfWorkIndex) => {
            compare[type] = {};
            return (
              <div
                style={{ display: "flex", flexDirection: "column", gap: 10 }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                    paddingTop: typeOfWorkIndex === 1 ? 10 : "",
                  }}
                >
                  <h2>{props?.rowData?.projectAddress}</h2>
                  <h1
                    style={{
                      color: typeOfWorkColors[type],
                    }}
                  >
                    ({type})
                  </h1>
                  {/* <button
                    onClick={() =>
                      setVisibleCharts({
                        visible: true,
                        data: {
                          type,
                          values: forecastingRaport({
                            forecastEstimations: onlyEstWithScopeFalse(
                              props?.projectServices || {},
                              serviceDefinitions
                            ),
                            scheduleEstimations: props?.schedules?.[type],
                          }),
                        },
                      })
                    }
                  >
                    Charts
                  </button> */}
                  {/* <MondayButton
                    {...{
                      className: "mondayButtonBlue",
                      Icon: <ChartIcon />,
                      onClick: () => setVisibleCharts(true),
                    }}
                  >
                    Charts
                  </MondayButton> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                ></div>
                <div className="forecastingRaportContent">
                  {forecastingRaport({
                    forecastEstimations: onlyEstWithScopeFalse(
                      props?.projectServices || {},
                      serviceDefinitions
                    ),
                    scheduleEstimations: props?.schedules?.[type],
                  })?.map((data, index) => {
                    compare[type][data.header] = {};
                    return (
                      <div className="content">
                        <div className="header">{data?.header}</div>
                        <div className="data">
                          <div className="estimation-container">
                            {data?.display?.map((estimation, index) => {
                              compare[type][data.header][
                                estimation.estimationId
                              ] = {};

                              return (
                                <div
                                  className={`estimation-card`}
                                  key={estimation.estimation}
                                >
                                  <h2
                                    className="estimation-title"
                                    style={{
                                      borderBottom: `3px solid ${data?.borderBottomColor}`,
                                    }}
                                  >
                                    Estimation: {estimation.estimation}{" "}
                                  </h2>
                                  {estimation?.services?.map(
                                    (service, serviceIndex) => {
                                      compare[type][data.header][
                                        estimation.estimationId
                                      ][service.serviceId] = service;

                                      const commonParams = [
                                        compare,
                                        estimation?.estimationId,
                                        service?.serviceId,
                                        typeOfWorkIndex,
                                      ];

                                      return (
                                        <div
                                          className="service"
                                          key={serviceIndex}
                                        >
                                          <h3 className="service-label">
                                            {service.label}
                                          </h3>
                                          <div className="service-details">
                                            <p>
                                              <strong
                                                style={
                                                  {
                                                    // background: !isKeyTheSame(
                                                    //   ...commonParams,
                                                    //   [
                                                    //     `${type}potentialStartDate`,
                                                    //     `${type}firstProgressDay`,
                                                    //   ]
                                                    // )
                                                    //   ? "yellow"
                                                    //   : "",
                                                  }
                                                }
                                              >
                                                Start Date:
                                              </strong>{" "}
                                              {data?.progress
                                                ? typeof service?.[
                                                    `${type}firstProgressDay`
                                                  ] === "number"
                                                  ? dayjsNY(
                                                      service?.[
                                                        `${type}firstProgressDay`
                                                      ]
                                                    ).format("MM/DD/YYYY")
                                                  : "N/A"
                                                : !!service?.[
                                                    `${type}potentialStartDate`
                                                  ]
                                                ? dayjsNY(
                                                    service?.[
                                                      `${type}potentialStartDate`
                                                    ]
                                                  ).format("MM/DD/YYYY")
                                                : "N/A"}
                                            </p>
                                            <p>
                                              <strong
                                                style={
                                                  {
                                                    // background: !isKeyTheSame(
                                                    //   ...commonParams,
                                                    //   [
                                                    //     `${type}plannedNrOfDays`,
                                                    //     `${type}daysWithProgress`,
                                                    //   ]
                                                    // )
                                                    //   ? "yellow"
                                                    //   : "",
                                                  }
                                                }
                                              >
                                                Days:
                                              </strong>{" "}
                                              {data?.progress
                                                ? service?.[
                                                    `${type}daysWithProgress`
                                                  ]
                                                : service?.[
                                                    `${type}plannedNrOfDays`
                                                  ]}
                                            </p>
                                            <p>
                                              <strong
                                                style={
                                                  {
                                                    // background: !isKeyTheSame(
                                                    //   ...commonParams,
                                                    //   [
                                                    //     `${type}plannedNrOfHours`,
                                                    //     `${type}progressHours`,
                                                    //   ]
                                                    // )
                                                    //   ? "yellow"
                                                    //   : "",
                                                  }
                                                }
                                              >
                                                Hours:
                                              </strong>{" "}
                                              {data?.progress
                                                ? service?.[
                                                    `${type}progressHours`
                                                  ]
                                                : service?.[
                                                    `${type}plannedNrOfHours`
                                                  ]}
                                            </p>
                                            <p>
                                              <strong
                                                style={
                                                  {
                                                    // background: !isKeyTheSame(
                                                    //   ...commonParams,
                                                    //   [
                                                    //     `${type}plannedNrOfCrews`,
                                                    //     `${type}crewsProgress`,
                                                    //   ]
                                                    // )
                                                    //   ? "yellow"
                                                    //   : "",
                                                  }
                                                }
                                              >
                                                Crews:
                                              </strong>{" "}
                                              {data?.progress
                                                ? service?.[
                                                    `${type}crewsProgress`
                                                  ]
                                                : service[
                                                    `${type}plannedNrOfCrews`
                                                  ]}
                                            </p>
                                            <p>
                                              <strong
                                                style={
                                                  {
                                                    // background: !isKeyTheSame(
                                                    //   ...commonParams,
                                                    //   [
                                                    //     `${type}plannedNrOfTrucks`,
                                                    //     `${type}trucksProgress`,
                                                    //   ]
                                                    // )
                                                    //   ? "yellow"
                                                    //   : "",
                                                  }
                                                }
                                              >
                                                Trucks:
                                              </strong>{" "}
                                              {
                                                service[
                                                  `${type}plannedNrOfTrucks`
                                                ]
                                              }
                                            </p>
                                          </div>
                                          {service?.serviceOptions?.[0]?.map(
                                            (elevation, elevationIndex) => (
                                              <div
                                                className="elevation"
                                                key={elevationIndex}
                                              >
                                                <h4 className="elevation-name">
                                                  {elevation.elevationLabel +
                                                    " " +
                                                    elevation?.elevationId}
                                                </h4>
                                                <div className="elevation-details">
                                                  <p>
                                                    <strong>Days:</strong>{" "}
                                                    {data?.progress
                                                      ? elevation?.[
                                                          `${type}daysWithProgress`
                                                        ]
                                                      : elevation?.[
                                                          `${type}plannedNrOfDays`
                                                        ]}
                                                  </p>
                                                  <p>
                                                    <strong>Hours:</strong>{" "}
                                                    {data?.progress
                                                      ? elevation?.[
                                                          `${type}progressHours`
                                                        ]
                                                      : elevation?.[
                                                          `${type}plannedNrOfHours`
                                                        ]}
                                                  </p>
                                                  <p>
                                                    <strong>Crews:</strong>{" "}
                                                    {data?.progress
                                                      ? elevation?.[
                                                          `${type}crewsProgress`
                                                        ]
                                                      : elevation?.[
                                                          `${type}plannedNrOfCrews`
                                                        ]}
                                                  </p>
                                                  <p>
                                                    <strong>Trucks:</strong>{" "}
                                                    {
                                                      elevation?.[
                                                        `${type}plannedNrOfTrucks`
                                                      ]
                                                    }
                                                  </p>
                                                </div>
                                                <table className="items-table">
                                                  <thead>
                                                    <tr>
                                                      <th>ID</th>
                                                      <th>Length</th>
                                                      <th>Width</th>
                                                      <th>Height</th>
                                                      <th>Days</th>
                                                      <th>Hours</th>
                                                      <th>Crews</th>
                                                      <th>Trucks</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {elevation?.items?.map(
                                                      (item) => (
                                                        <tr key={item?.id}>
                                                          <td>{item?.id}</td>
                                                          <td>
                                                            {item?.["length"] ||
                                                              item
                                                                ?.originialPli?.[
                                                                "length"
                                                              ] ||
                                                              "-"}
                                                          </td>
                                                          <td>
                                                            {item?.width ||
                                                              item
                                                                ?.originalPli?.[
                                                                "width"
                                                              ] ||
                                                              "-"}
                                                          </td>
                                                          <td>
                                                            {item?.height ||
                                                              item
                                                                ?.originalPli?.[
                                                                "height"
                                                              ] ||
                                                              "-"}
                                                          </td>
                                                          <td>
                                                            {data?.progress
                                                              ? item?.[
                                                                  `${type}daysWithProgress`
                                                                ]
                                                              : item?.[
                                                                  `${type}plannedNrOfDays`
                                                                ]}
                                                          </td>
                                                          <td>
                                                            {data?.progress
                                                              ? item?.[
                                                                  `${type}progressHours`
                                                                ]
                                                              : item?.[
                                                                  `${type}plannedNrOfHours`
                                                                ]}
                                                          </td>
                                                          <td>
                                                            {data?.progress
                                                              ? item?.[
                                                                  `${type}crewsProgress`
                                                                ]
                                                              : item?.[
                                                                  `${type}plannedNrOfCrews`
                                                                ]}
                                                          </td>
                                                          <td>
                                                            {
                                                              item?.[
                                                                `${type}plannedNrOfTrucks`
                                                              ]
                                                            }
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })
        )}
        {visibleCharts?.visible && (
          <ForecastingCharts
            {...{
              visibleCharts,
              setVisibleCharts,
            }}
          />
        )}
      </Modal>
      {/* {missmatches?.visible && (
        <CompareChanges
          {...{
            compareChanges: missmatches,
            onCancel: () => setMissmatches({}),
            serviceDefinitions,
            estimationId: missmatches?.content?.estimationId,
          }}
        />
      )} */}
    </div>
  );
};

export default ForecastingRaport;
