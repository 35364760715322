import moment from "moment";
import { getInitials } from "../getInitials";
import { fetchAllData } from "../ApiMethods";
import { getRecordKeysFromReportKeys } from "./helpers/getRecordKeysFromReportKeys";

const projectQueryParams = {
  keysToInclude: JSON.stringify([
    "projectId",
    "accountId",
    "projectExecutive",
    "projectName",
  ]),
};

const accountQueryParams = {
  keysToInclude: JSON.stringify([
    "accountId",
    "accountName",
    "billingAddress",
    "accountPhone",
  ]),
};

const recordKeysMapper = {
  type: ["chargeType"],
  date: ["createdAt"],
  credit_nr: ["chargeNumber"],
  category_from: ["categoryFrom"],
  items: ["chargeItems"],
  ref_name: ["categoryFrom"],
  amount: ["chargeType"],
  subtotal: ["chargeType", "creditAmount", "taxAmount", "chargeAmount"],
  sales_tax: ["taxAmount"],
  total: ["chargeType", "chargeAmount"],
  invoices: ["chargeType", "appliedAmount", "invoicedAmount"],
  balance_credit: [
    "chargeType",
    "chargeAmount",
    "appliedAmount",
    "invoicedAmount",
  ],
};

// optimized report fetch data
export const getChargesOverview = async ({ keysToInclude, reportUsedKeys }) => {
  const finalKeysToInclude = [
    ...new Set([
      "chargeId",
      "projectId",
      ...keysToInclude,
      ...getRecordKeysFromReportKeys(reportUsedKeys, recordKeysMapper),
    ]),
  ];

  const [projectsRes, accountsRes, chargesRes] = await Promise.all([
    fetchAllData({
      endpoint: "projects",
      resultId: "projectId",
      otherStringParams: projectQueryParams,
    }),
    fetchAllData({
      endpoint: "accounts",
      resultId: "accountId",
      otherStringParams: accountQueryParams,
    }),
    fetchAllData({
      endpoint: "charges",
      resultId: "chargeId",
      otherStringParams: { keysToInclude: JSON.stringify(finalKeysToInclude) },
    }),
  ]).catch((err) => console.error("Failed fetching data:", err));

  return chargesRes
    .map((charge) => {
      let project = projectsRes.find(
        (project) => project.projectId === charge.projectId
      );
      let client = accountsRes.find(
        (account) => account.accountId === project?.accountId
      );

      if (!project || !client) return;

      const newObj = {
        chargeId: charge.chargeId,
        logo: "core black 2",
        type: charge.chargeType !== "Credit Memo" ? "Charge" : "Credit Memo",
        date: moment(charge.createdAt).valueOf(),
        credit_nr: charge.chargeNumber,
        projectExecutive:
          getInitials(project.projectExecutive || "") || "no representative",
        category_from: charge.categoryFrom,
        customer: {
          name: client?.accountName || "No client name",
          address: client?.billingAddress || "No billing address",
          phone_number: client?.accountPhone || "No phone number",
        },
        project_name: project.projectName || "404",
        items: charge?.chargeItems?.map((item) => ({
          service: item.label,
          chargeId: charge.chargeId,
          description:
            item.serviceDescription?.replace(/<[^>]+>/g, "") ||
            item.description?.replace(/<[^>]+>/g, "") ||
            "",
          ref_name: charge.categoryFrom,
          taxRate: item?.taxRate || 0,
          taxAmount: item.taxAmount || 0,
          amount:
            charge.chargeType === "Credit Memo"
              ? (item.price || 0) * -1
              : item.price || 0,
        })),
        subtotal:
          charge.chargeType === "Credit Memo"
            ? -(charge.creditAmount - parseInt(charge.taxAmount)) || 0
            : charge.chargeAmount - parseInt(charge.taxAmount) || 0,
        sales_tax: parseInt(charge.taxAmount) || 0,
        total:
          charge.chargeType === "Credit Memo"
            ? -charge.chargeAmount || 0
            : charge.chargeAmount || 0,
        invoices:
          charge.chargeType === "Credit Memo"
            ? charge?.appliedAmount || 0
            : charge?.invoicedAmount || 0,
        balance_credit:
          charge.chargeType === "Credit Memo"
            ? parseInt(charge?.chargeAmount) -
              (parseInt(charge?.appliedAmount) || 0)
            : parseInt(charge?.chargeAmount) -
              (parseInt(charge?.invoicedAmount) || 0),
      };
      return newObj;
    })
    .filter(Boolean);
};
