export const useUserAgentNavigatorDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (/tablet|ipad/.test(userAgent)) {
    return "iPadManagement";
  } else if (/mobile|iphone|android/.test(userAgent)) {
    return "mobileManagement";
  } else {
    return "webManagement";
  }
};
