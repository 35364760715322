import { API } from "aws-amplify";
import { type Dayjs } from "dayjs";
import { Dispatch, SetStateAction } from "react";

import { getLatLngFromAddress } from "src/utils";
import { CrewTeamType, ScheduleType } from "../payrollLiveTypes";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { JobsiteType } from "src/components/SidebarPages/FleetMaintenanceView/types";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

type Props = {
  nowTime: Dayjs;
  jobsites: Array<JobsiteType>;
  setTodaySchedules: Dispatch<SetStateAction<Array<ScheduleType>>>;
};

export async function getLiveSchedules({
  nowTime,
  jobsites,
  setTodaySchedules,
}: Props) {
  const today = nowTime.startOf("d").toISOString();
  const yesterday = nowTime.subtract(1, "d").startOf("d").toISOString();

  API.get("fetchSchedulesByDay", `/fetchSchedulesByDay`, {
    queryStringParameters: {
      searchDates: JSON.stringify([today, yesterday]),
    },
  })
    .then(async (res) => {
      let modifiedRes = [];
      for (let i = 0; i < res?.items?.length; i++) {
        const schedule = res?.items?.[i];
        const scheduledDay = schedule.scheduleDays.find(
          (day) =>
            dayjsNY(day.startDate).startOf("d").valueOf() ===
            nowTime.startOf("d").valueOf()
        );
        if (
          !scheduledDay ||
          scheduledDay?.status === "Canceled" ||
          scheduledDay?.status === "Postponed"
        ) {
          continue;
        }

        const jIndex = jobsites.findIndex(
          (job) => job?.projectId === schedule?.projectId
        );

        const scheduleCrews = (schedule?.scheduleCrews || []).flatMap(
          (crewConfig: CrewTeamType) => {
            const todayTeamConfig = (crewConfig?.days || []).filter(
              (el) =>
                parseInTz(el?.startDate || 0)
                  .startOf("d")
                  .valueOf() === parseInTz().startOf("d").valueOf()
            );
            if (todayTeamConfig?.length) {
              return { ...crewConfig, days: todayTeamConfig };
            } else {
              return [];
            }
          }
        );

        const includedEmployeeIds = scheduleCrews.flatMap((el) =>
          el?.days?.length ? el?.crewId : []
        );

        const scheduleObject = {
          ...schedule,
          includedEmployeeIds,
          jobsiteId: jobsites?.[jIndex]?.jobsiteId,
          geoFenceInfo: jobsites?.[jIndex]?.geoFenceInfo,
          radius: jobsites?.[jIndex]?.locationRadius || 300,
          addressPosition: jobsites?.[jIndex]?.addressPosition,
        };

        if (!jobsites?.[jIndex]?.addressPosition?.lat) {
          const addressCoordinates = await getLatLngFromAddress(
            schedule?.scheduleAddress
          );
          Object.assign(scheduleObject, {
            addressPosition: addressCoordinates,
          });
        }
        if (
          modifiedRes.findIndex(
            (el) => el?.projectId === scheduleObject?.projectId
          ) === -1
        ) {
          modifiedRes.push(scheduleObject);
        }
      }

      setTodaySchedules(modifiedRes);
    })
    .catch((err) => console.log("err schedule days: ", err));
}
