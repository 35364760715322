import { API } from "aws-amplify";
import {
  fetchAllData,
  gsiQueryTable,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../utils";
import { swalToast } from "src/components/Header/forms/Scheduling/SchedulingFirstPage/helperData";
import Swal from "sweetalert2";
import { addNewRecordToRequest } from "src/components/Header/forms/RequestFormModal/helperData";
import { message } from "antd";
import { checkIfServiceIsHoist } from "../../Estimations/DataEntryGrid/models/Service";
import {
  addonDidNotChange,
  calculateIsBreakdownCompleted,
  calculateIsJumpCompleted,
  calculateIsSubPliCompleted,
  compareAddons,
  compareJumps,
  comparePlis,
  getStateSizeInKB,
  jumpDidNotChange,
  pliDidNotChange,
  pliEditedFromNextSchedules,
  plisDimensionMisMatch,
  processPli,
  scheduleProgress,
  transformOption,
  updateNextSchedules,
  updatePrevSchedules,
} from "../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { get_ProgressDimensionsByServiceId } from "../../../Header/forms/DataEntryGrid/tools/columnDefinitions/ProgressColumnDefinition";
import dayjs from "dayjs";
import { filter, groupBy } from "lodash";
import { breakdown_dimension } from "../../../Header/forms/Scheduling/PLIPerService/componentsForPli/Breakdown/breakdown";
export function getSchedule(scheduleId) {
  return API.get("scheduling", `/scheduling/${scheduleId}`);
}

export function getProject(projectId) {
  return API.get("projects", `/projects/${projectId}`);
}

export function saveSchedule(schedule) {
  showLoadingMsg();
  const { userId, scheduleId, ...saveableScheduleObject } = schedule || {};
  return API.put("scheduling", `/scheduling/${scheduleId}`, {
    body: saveableScheduleObject,
  }).then((r) => {
    showSuccessMsg();
  });
}

// export async function saveMultipleSchedules(
//   schedules = [],
//   notificationForProgressChange = () => {},
//   updateRowData = () => {}
// ) {
//   return new Promise(() => {
//     schedules?.forEach((schedule) => {
//       const { userId, scheduleId, ...saveableScheduleObject } = schedule || {};
//       API.put("scheduling", `/scheduling/${scheduleId}`, {
//         body: saveableScheduleObject,
//       })
//         .then((r) => {
//           // showSuccessMsg();
//           notificationForProgressChange();
//           updateRowData();
//           swalToast({
//             icon: "success",
//             title: "Schedule saved successfully!",
//             timer: 3000,
//             position: "bottom-end",
//             showConfirmButton: false,
//           });
//         })
//         .catch((e) => {
//           swalToast({
//             icon: "error",
//             title: "Error saving schedule!",
//             timer: 3000,
//             position: "bottom-end",
//             showConfirmButton: false,
//           });
//           console.log("eeeeeeeee", { e });
//         });
//     });
//   });
// }

function delayUpdate(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function updateSchedule(
  schedule,
  toBeScheduled,
  getToBeScheduled,
  createdAt,
  schId,
  scheduleName
) {
  const groupByEstimationId = groupBy(getToBeScheduled, "estimationId");
  const isAfter =
    dayjs(schedule?.createdAt).isAfter(dayjs(createdAt)) &&
    schId !== schedule?.scheduleId;

  if (isAfter) {
    updateNextSchedules(schedule, toBeScheduled, groupByEstimationId);
  } else if (schId === schedule?.scheduleId) {
    schedule.toBeScheduled = toBeScheduled;
  } else if (!isAfter && schId !== schedule?.scheduleId) {
    updatePrevSchedules(
      schedule,
      toBeScheduled,
      groupByEstimationId,
      schId,
      scheduleName
    );
  }

  const { userId, scheduleId, ...saveableScheduleObject } = schedule || {};

  try {
    await API.put("scheduling", `/scheduling/${scheduleId}`, {
      body: {
        ...saveableScheduleObject,
        toBeScheduled: {},
        totalScheduleProgress: scheduleProgress(schedule?.toBeScheduled || {}),
        scheduleEstimates: Object.keys(schedule?.toBeScheduled || {}).length,
      },
    });
  } catch (error) {
    console.error("Error updating schedule:", error);
    throw new Error(`Failed to save schedule for ${scheduleId}`);
  }
}

async function updateToBeScheduledItems(schedule, getToBeScheduled) {
  const batchSize = 3;
  const delayBetweenBatches = 4000;

  try {
    for (let i = 0; i < getToBeScheduled?.length; i += batchSize) {
      const batch = getToBeScheduled?.slice(i, i + batchSize);
      const updateEstimatesPromises = batch?.map(async (toBeScheduledItem) => {
        const findEsti = schedule?.toBeScheduled?.[
          toBeScheduledItem?.estimationId
        ]?.find((esti) => esti?.serviceId === toBeScheduledItem?.serviceId);

        try {
          if (findEsti) {
            await API.put(
              `toBeScheduled`,
              `/toBeScheduled/${toBeScheduledItem?.toBeScheduledId}`,
              {
                body: {
                  serviceOptions: findEsti?.serviceOptions || [],
                  serviceAddons: findEsti?.serviceAddons || [],
                  isNotAppliedForProgress:
                    findEsti?.isNotAppliedForProgress || false,
                  isSelected: findEsti?.isSelected || false,
                },
              }
            );
            message.destroy("savingEstimates");
          } else {
            await API.del(
              `toBeScheduled`,
              `/toBeScheduled/${toBeScheduledItem?.toBeScheduledId}`
            );
            message.destroy("savingEstimates");
            message.success(
              `${toBeScheduledItem?.label} deleted successfully for ${schedule?.scheduleName}!`
            );
          }
        } catch (e) {
          const action = findEsti ? "saving" : "deleting";
          message.error(
            `Error ${action} estimates for ${schedule?.scheduleName}!`
          );
          console.log("findEsti", { findEsti });
          console.error(`Error ${action} estimates:`, e);
          throw new Error(
            `Error ${action} estimates for ${schedule?.scheduleName}`
          );
        }
      });

      await Promise.all(updateEstimatesPromises);
      await delayUpdate(delayBetweenBatches);
    }
  } catch (error) {
    console.error("Error updating estimates:", error);
    message.error(`Failed to update estimates for ${schedule?.scheduleName}!`);
    throw new Error(`Failed to update estimates for ${schedule?.scheduleName}`);
  }
}

export async function saveMultipleSchedules(
  serviceItems = [],
  schedules = [],
  toBeScheduled,
  createdAt,
  schId,
  scheduleName,
  schedule,
  progressLogs = [],
  notificationForProgressChange = () => {},
  updateRowData = () => {},
  afterSaveRequest = async () => {},
  requestId
) {
  try {
    Swal.fire({
      title: "Please wait...",
      text: "We are saving the schedule for you!",
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    const { userId, scheduleId, ...saveableScheduleObject } = schedule || {};
    const schedulePayload = {
      ...saveableScheduleObject,
      toBeScheduled: {},
      totalScheduleProgress: scheduleProgress(toBeScheduled || {}),
      scheduleEstimates: Object.keys(toBeScheduled || {}).length,
    };

    try {
      await API.put(`scheduling`, `/scheduling/${schId}`, {
        body: schedulePayload,
      });
    } catch (error) {
      console.error("Error saving schedule:", error);
      Swal.close();
      message.error("Failed to save schedule.");
      return;
    }

    let hasUpdates = false;
    const updatePromises = serviceItems?.map(async (service) => {
      try {
        const id = service?.toBeScheduledId;
        const findService = toBeScheduled?.[service?.estimationId]?.find(
          (ser) => ser?.serviceId === service?.serviceId
        );

        if (findService) {
          hasUpdates = true;
          await API.put("toBeScheduled", `/toBeScheduled/${id}`, {
            body: {
              serviceOptions: findService?.serviceOptions || [],
              serviceAddons: findService?.serviceAddons || [],
              isNotAppliedForProgress:
                findService?.isNotAppliedForProgress || false,
              isSelected: findService?.isSelected || false,
            },
          });
        }
      } catch (err) {
        message.error(
          `Error saving ${service?.label} for ${schedule?.scheduleName}!`
        );
        Swal.close();
        console.warn(
          `Error service update for ${service?.toBeScheduledId}:`,
          err
        );
      }
    });

    await Promise.all(updatePromises);

    const processSchedules = async () => {
      for (const sch of schedules) {
        try {
          const isAfter =
            dayjs(sch?.createdAt).isAfter(dayjs(createdAt)) &&
            schId !== sch?.scheduleId;
          const isBefore =
            dayjs(sch?.createdAt).isBefore(dayjs(createdAt)) &&
            schId !== sch?.scheduleId;

          const data = await fetchScheduleItems(sch?.scheduleId);
          if (!data || !Array.isArray(data) || data.length === 0) continue;

          if (isAfter) {
            updateNextSchedules(
              sch,
              toBeScheduled,
              groupBy(data, "estimationId")
            );
          } else if (isBefore) {
            updatePrevSchedules(
              sch,
              toBeScheduled,
              groupBy(data, "estimationId"),
              schId,
              scheduleName
            );
          }

          for (let i = 0; i < data?.length; i += 30) {
            const chunk = data?.slice(i, i + 30);
            await Promise.all(
              chunk?.map(async (ser) => {
                try {
                  const findEsti = sch?.toBeScheduled?.[
                    ser?.estimationId
                  ]?.find((esti) => esti?.serviceId === ser?.serviceId);
                  if (!findEsti) return;

                  await retryWithBackoff(() =>
                    API.put(
                      `toBeScheduled`,
                      `/toBeScheduled/${ser?.toBeScheduledId}`,
                      {
                        body: {
                          serviceOptions: findEsti?.serviceOptions || [],
                          serviceAddons: findEsti?.serviceAddons || [],
                        },
                      }
                    )
                  );
                } catch (err) {
                  console.warn(
                    `Skipping service update for ${ser?.serviceId}:`,
                    err
                  );
                }
              })
            );
            await sleep(1000);
          }
        } catch (innerErr) {
          console.error(
            `Skipping schedule ${sch?.scheduleId} due to error:`,
            innerErr
          );
        }
      }
    };

    if (schedules?.length > 0) {
      await processSchedules();
    }

    const logPromises = progressLogs?.map(async (log) => {
      try {
        await API.post("progressItems", "/progressItems", { body: log });
      } catch (error) {
        console.error("Error saving progress log:", error);
      }
    });

    await Promise.all(logPromises);

    if (requestId) {
      await afterSaveRequest();
    }

    notificationForProgressChange();
    updateRowData(schedule);
    Swal.close();

    Swal.fire({
      icon: "success",
      title: "Schedule saved successfully!",
      timer: 3000,
      showConfirmButton: false,
    });
  } catch (error) {
    Swal.close();
    console.error("Error saving schedules:", error);
    Swal.fire({
      icon: "error",
      title: "Error saving schedules!",
      timer: 3000,
      showConfirmButton: false,
    });
  }
}

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const retryWithBackoff = async (fn, retries = 5, delay = 2000) => {
  for (let i = 0; i < retries; i++) {
    try {
      return await fn();
    } catch (error) {
      if (error.response?.status === 429 && i < retries - 1) {
        const backoff = delay * 2 ** i;
        message.warning(`Rate limit hit, retrying in ${backoff}ms...`);
        console.warn(`Rate limit hit, retrying in ${backoff}ms...`);
        await sleep(backoff);
      } else {
        console.error("API request failed:", error);
        throw error;
      }
    }
  }
};

export const fetchScheduleItems = async (scheduleId) =>
  await gsiQueryTable({
    tableName: "toBeScheduled",
    indexName: "scheduleId-index",
    filterKey: "scheduleId",
    filterValue: scheduleId,
  });

export function createSchedule(schedule) {
  return API.post("scheduling", "/scheduling", {
    body: schedule,
  });
}

export function deleteSchedule(scheduleId) {
  return API.del("scheduling", `/scheduling/${scheduleId}`);
}
