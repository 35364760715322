import { API } from "aws-amplify";
import { message } from "antd";

import { apiRoutes } from "../../../../SidebarPages/Fleet/utils";
import {
  capitalizeFirstLetters,
  responseReturned,
} from "../../../forms/RequestFormModal/requestFormModalData";
import { sendRequestWsMessageCP } from "./customerApprovals";
import broadcastNotification from "../../../../../helpers/controllers/broadcastNotification";
import { getCognitosForNotification } from "../../../../../utils/notificationHelpers";

/**
 * Cancels a request instantly and updates the necessary logs and status.
 *
 * @param {Object} requestForm - The request form object.
 * @param {Object} wsPointer - The WebSocket pointer object.
 * @param {Object} userConfiguration - The authenticated user object.
 * @param {Function} saveAddedLogs - The function to save added logs.
 * @returns {Promise<void>} - A promise that resolves when the request is updated.
 */
export async function approvalInstantDecline(
  requestForm,
  wsPointer,
  userConfiguration,
  saveAddedLogs,
  sendWsMessage,
  authUser
) {
  message.loading({
    content: "Cancelling Request...",
    key: "requestCancel",
  });

  API.patch(
    apiRoutes.formRequests,
    `/${apiRoutes.formRequests}/${requestForm?.requestId}`,
    {
      body: {
        responseFrom: "declined",
        requestObject: {
          ...requestForm?.requestObject,
          status: "Cancelled",
        },
      },
    }
  )
    .then(() => {
      saveAddedLogs([
        {
          recordName: requestForm?.requestObject?.recordName,
          category: "Approvals Action",
          topic: requestForm?.requestType,
          actionType: "Declined",
          nameOfUser: userConfiguration?.nameOfUser,
          currentData: {
            requestedBy: requestForm?.userRequesting,
            category: requestForm?.category,
            recordId: requestForm?.recordId,
            requestId: requestForm?.requestId,
          },
        },
        {
          recordName: requestForm?.requestObject?.recordName,
          category: "Approvals",
          topic: requestForm?.requestType,
          nameOfUser: userConfiguration?.nameOfUser,
          currentData: {
            status: "Cancelled",
            response: "Declined",
          },
          previousData: {
            status: requestForm?.requestObject?.status,
            response: capitalizeFirstLetters(
              responseReturned(requestForm?.requestObject?.status)
            ),
          },
          id: userConfiguration?.cognitoUserId,
        },
      ]);

      sendRequestWsMessageCP({
        sendWsMessage,
        action: "CP_RequestCancelled",
        recordId: requestForm?.requestId,
        nameOfUser: userConfiguration?.nameOfUser,
        recordName: requestForm?.requestObject?.recordName,
        createdBy: requestForm?.createdBy,
        updatedReq: {
          responseFrom: "Declined",
          requestObject: { status: "Cancelled" },
        },
      });

      broadcastNotification(
        "13",
        "onRequestCancel",
        [
          {
            common: userConfiguration?.nameOfUser,
            recordName: requestForm?.requestObject?.recordName,
          },
          {
            userName: userConfiguration?.nameOfUser,
            currentUser: authUser?.sub,
            cognitos: getCognitosForNotification(userConfiguration),
          },
        ],
        requestForm?.requestId
      );

      wsPointer.send(
        JSON.stringify({
          request: "request-response",
          body: {
            requestId: requestForm?.requestId,
            responseFrom: "declined",
            requestObject: {
              status: "Cancelled",
            },
          },
        })
      );
      message.success({
        content: "Request Updated!",
        key: "requestCancel",
      });
    })
    .catch((err) => {
      message.error({
        content: "Something Went Wrong!",
        key: "requestCancel",
      });
      console.log("Error Reverting Request: ", err);
    });
}
