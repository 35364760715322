import { message } from "antd";
import { apiPut } from "../../../../../../../../../../utils";
import { updateOtherUser } from "../../../../../../../../../../actions";

function getLabelFromIdleOptions(time, idleOptions) {
  const selectedOption = idleOptions.find((option) => option.value === time);
  return selectedOption ? selectedOption.label : "";
}

async function changePinAndIdleFn({
  form,
  user,
  dispatch,
  setButtonDisabled,
  saveAddedLogs,
  idleOptions,
}) {
  const { pin, idleTime } = form.getFieldsValue();

  const isValidPin = /^[0-9]{4}$/.test(pin);
  if (!isValidPin)
    return message.error("Please enter a valid 4-digit PIN, e.g., 1234.");
  if (!idleTime) return message.error("Idle time cannot be empty.");

  await apiPut("userConfiguration", user?.identityId, {
    pinNumber: pin,
    idleTimeOut: idleTime,
  })
    .then((res) => {
      message.success("User Updated Successfully!");
      if (pin !== user.pinNumber) {
        saveAddedLogs({
          recordId: `Users Actions - ${user.nameOfUser}`,
          recordName: user?.nameOfUser,
          actionType: "Pin Changed",
          category: "Users Actions",
          updatedKeys: [
            {
              label: `${user?.nameOfUser}`,
              previousValue: `****`,
              updatedValue: `Pin Changed`,
            },
          ],
        }).catch((err) => console.log("Error Creating Logs: ", err));
      }
      if (idleTime !== user.idleTimeOut) {
        saveAddedLogs({
          recordId: `Users Actions - ${user.nameOfUser}`,
          recordName: user?.nameOfUser,
          actionType: "Idle Timeout Changed",
          category: "Users Actions",
          updatedKeys: [
            {
              label: `${user?.nameOfUser}`,
              previousValue: getLabelFromIdleOptions(
                user.idleTimeOut,
                idleOptions
              ),
              updatedValue: getLabelFromIdleOptions(idleTime, idleOptions),
            },
          ],
        }).catch((err) => console.log("Error Creating Logs: ", err));
      }
    })
    .catch((err) => {
      message.error("ERROR");
      console.error(err);
    });
  dispatch(
    updateOtherUser({
      pinNumber: pin,
      idleTimeOut: idleTime,
    })
  );
  setButtonDisabled(true);
}

export default changePinAndIdleFn;
