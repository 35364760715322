import { useState, useCallback } from "react";
import { AutoComplete, Input } from "antd";
import LabeledInput from "../LabeledInput/LabeledInput";
import { NYC_CENTER } from "../../../../commonComponents/Map/mapData";
import { compareIncluding } from "../../../utils";
import _ from "lodash";

const SimplePlacesInput = ({
  form,
  label = "",
  formItemName = "",
  initialValue = "",
  className = "",
  placeholder = "",
  popoverTitle = "",
  popupClassName = "",
  dropdownStyle = "",
  popoverClassName,
  popoverPlacement,
  popoverContent,
  noFormItem = false,
  required = false,
  disabled = false,
  popoverVisible = false,
  keepOptions = false,
  isCustomValue = true,
  autoFocus = false,
  filterOption = true,
  allowSearch = true,
  open = false,
  defaultOpen = false,
  style = {},
  existingValues = [],
  groupedOptions = [],
  rules = [],
  dataTestid,
  customLabel = "",
  onClear = () => {},
  onKeyDown = () => {},
  onSelect = () => {},
  onBlur = () => {},
  onChange = () => {},
  customOnSearch = () => {},
  onPopupScroll = () => {},
}) => {
  const [predictions, setPredictions] = useState([]);
  const [changingValue, setChangingValue] = useState("");
  const [selectedAddress, setSelectedAddress] = useState(initialValue);
  const [autocompleteService] = useState(
    new google.maps.places.AutocompleteService()
  );

  const handleBlur = () => {
    if (!!form && !!selectedAddress && !!form.getFieldValue(formItemName)) {
      form.setFieldsValue({ [formItemName]: selectedAddress });
    }
    setPredictions([]);
  };

  const handleClear = (val) => {
    if (!val) {
      setChangingValue("");
      setSelectedAddress("");
      setPredictions([]);
    }
  };

  const onSearch = useCallback(
    async (value) => {
      if (!autocompleteService || !allowSearch) {
        return;
      }
      customOnSearch(value);
      setChangingValue(value);

      try {
        let results = [];

        if (value.length > 2) {
          results =
            (
              await autocompleteService.getPlacePredictions({
                input: value,
                language: "en-US",
                locationBias: {
                  center: NYC_CENTER,
                  radius: 200000,
                },
              })
            )?.predictions || [];
        }

        setPredictions(results);
      } catch (err) {
        console.log("Error searching: ", err);
      }
    },
    [autocompleteService]
  );
  const onSelection = (val) => {
    setSelectedAddress(val);
    setPredictions([]);
  };

  function renderOptions() {
    const reducedGroupedOptions = groupedOptions
      ?.map((curr) => ({
        ...curr,
        options: curr?.options?.filter((item) =>
          compareIncluding(item["value"], changingValue)
        ),
      }))
      .filter(({ options }) => !!options?.length);

    if (keepOptions === true) {
      return [
        ...reducedGroupedOptions,
        {
          label: "Google Results",
          options: predictions?.map(({ place_id, description }) => {
            return { key: place_id, value: description };
          }),
        },
      ]?.filter(Boolean);
    } else {
      return !!reducedGroupedOptions?.find(({ options }) => !!options?.length)
        ? reducedGroupedOptions
        : [
            existingValues?.length &&
              changingValue?.length > 2 && {
                label: "Existing Records",
                options: existingValues?.map((children, index) =>
                  typeof children === "string"
                    ? {
                        label: children,
                        value: children,
                        disabled: true,
                        key: `custom-${index}`,
                      }
                    : { ...children, key: `custom-${index}` }
                ),
              },
            {
              label: "Google Results",
              options: predictions?.map(({ place_id, description }) => {
                return { key: place_id, value: description };
              }),
            },
            isCustomValue &&
              changingValue && {
                label: "Custom Results",
                options: [
                  {
                    key: "1",
                    value: changingValue,
                  },
                ],
              },
          ].filter(Boolean);
    }
  }

  return (
    <LabeledInput
      {...{
        label: customLabel || label,
        formItemName,
        initialValue,
        noFormItem,
        className,
        style,
        required,
        rules,
        popoverVisible,
        popoverTitle,
        popoverContent,
        popoverPlacement,
        popoverClassName,
      }}
    >
      <AutoComplete
        {...{
          dropdownStyle,
          defaultValue: initialValue,
          onSelect(val, option) {
            onSelection(val);
            onSelect(val, option);
          },
          "data-testid": dataTestid,
          onSearch: _.debounce(onSearch, 500, { trailing: true }),
          disabled,
          placeholder,
          popupClassName,
          onPopupScroll,
          filterOption,
          autoFocus,
          onClear,
          defaultOpen: defaultOpen,
          options: renderOptions(),
          showSearch: true,
          allowClear: true,
          popupMatchSelectWidth: false,
          ["data-testid"]: dataTestid,
          onKeyDown(val) {
            onKeyDown(val);
          },
          onChange(val) {
            handleClear(val);
            onChange(val);
          },
          onBlur() {
            handleBlur();
            onBlur();
          },
          ...(open === true ? { open: true } : {}),
        }}
      >
        <Input />
      </AutoComplete>
    </LabeledInput>
  );
};

export default SimplePlacesInput;
