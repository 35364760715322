import { getChangedData } from "../../../../../SidebarPages/Accounting/components/utilities";

export const addEmergencyLogs = (compare) => {
  const curr = {};
  const prev = {};

  compare.added.forEach((item) => {
    const result = getChangedData(item, {});
    curr[item.fullName] = item;
    prev[item.fullName] = result.prev;
  });

  compare.deleted.forEach((item) => {
    const result = getChangedData({}, item);
    curr[item.fullName] = result.curr;
    prev[item.fullName] = item;
  });

  compare.updated.forEach((item) => {
    curr[item.fullName] = item.changes.curr;
    prev[item.fullName] = item.changes.prev;
  });

  return { curr, prev };
};
