import { Button } from "antd";

function PaperworkWarningPopover({ onUpdate, onCancel, isDarkMode = false }) {
  return (
    <div
      className={`paperworkWarningPopover ${isDarkMode ? "dark" : ""}`}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        flexWrap: "nowrap",
        clear: "both",
        overflow: "hidden",
        whiteSpace: "nowrap",
      }}
      role="alertdialog"
    >
      <span
        style={{
          marginBottom: "1rem",
          color: isDarkMode ? "#fff" : "#333238",
        }}
      >
        Are you sure you want to update the paperwork type?
      </span>
      <div
        style={{
          display: "flex",
          gap: "0.5rem",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={onUpdate}
          style={{
            backgroundColor: "#71cf48",
            color: "white",
            fontWeight: "400",
          }}
          data-testid="paperwork-confirm-button"
        >
          Update
        </Button>
        <Button
          onClick={onCancel}
          style={{
            backgroundColor: "#fe4c4a",
            color: "white",
            fontWeight: "400",
          }}
          data-testid="paperwork-cancel-button"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default PaperworkWarningPopover;
