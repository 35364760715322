import { Input } from "antd";
import { getDispatchLocationOptions } from "../../../../Header/forms/RequestFormModal/requestFormModalData";
import DoubleLineLabel from "../../../PersonalProtectiveEquipment/components/DoubleLineLabel";
import { searchSafetyAddress } from "../helpers";
import { addConditionalFieldsSafety } from "./otherTradeIncidentSafetyFields";
import { toCamelCase } from "../../../../pages/Settings/settingsComponents/ApprovalsDynamicForms/FormFieldModals/newFieldModal";

const preTaskPlanFields = ({
  form,

  projects = [],
  scheduling = [],
  //   propAddress = "",
  //   defaultId = null,
  dynamicFields = [],
  immediateActions = "",
  schedulesForDate = [],
  //   projectExecutives = [],
  userConfiguration = {},
  disabledAllFields = false,
  otherTypeOfEquipment = "",
  onChangeAddress = () => {},
  changeDateOfIncident = () => {},
  dispatchSafetyFormData = () => {},
}) => {
  let allFields = [...dynamicFields];
  console.log(" dynamicFields:", { dynamicFields });
  try {
    let fields = [];

    // const projectExecutivesOpt =
    //   projectExecutives?.map((executive) => ({
    //     id: executive.peId,
    //     label: `${executive.firstName} ${executive.lastName}`,
    //     value: `${executive.firstName} ${executive.lastName}`,
    //   })) || [];

    for (let i = 0; i < allFields.length; i++) {
      let field = structuredClone(allFields[i]);
      let labelTmp = field?.label;
      if (labelTmp?.includes("||")) {
        let labelEn = labelTmp?.split("||")?.[0];
        field["formItemName"] = toCamelCase(labelEn);
      }
      delete field["placeholder"];
      field["customLabel"] = <DoubleLineLabel label={field.label} />;

      if (field.type === "select") {
        field = {
          ...field,
          showSearch: true,
          allowClear: true,
          //   ...(field.label.includes("Project Executive")
          //     ? { customOptions: projectExecutivesOpt }
          //     : {}),
        };
      }

      if (field.type === "placesautocomplete") {
        field = {
          ...field,
          allowClear: true,
          //   label: <DoubleLineLabel label={field.label} />,
          style: { width: 320 },
        };
      }

      if (field.type === "radio") {
        // field.type = field?.mode === "multiple" ? "radio" : "checkboxgroup";
        const optStr = field?.options?.join("").toLowerCase();
        if (!!field?.yesno && optStr.includes("yes") && optStr.includes("no")) {
          delete field?.options;
        }
        field = {
          ...field,
          style: {
            borderRadius: 10,
            backgroundColor: "#f5f5f7",
            padding: 10,
          },
          ...(field?.options
            ? {
                customOptions: field.options.map((option) => {
                  if (option.includes("-//-")) {
                    let subOptions = option.split("-//-");
                    return {
                      label: (
                        <DoubleLineLabel
                          label={subOptions[0]}
                          isOption={true}
                        />
                      ),
                      value: subOptions[1],
                      subOptions: subOptions.map((opt, index) => ({
                        key: index,
                        label: <DoubleLineLabel label={opt} isOption={true} />,
                        value: opt,
                      })),
                    };
                  } else {
                    return {
                      label: <DoubleLineLabel label={option} isOption={true} />,
                      value: option,
                    };
                  }
                }),
              }
            : {}),
        };
      }

      if (
        field.formItemName === "dateOfIncident" ||
        field.formItemName === "reportPreparedOn"
      ) {
        field = {
          ...field,
          ...(field.formItemName === "dateOfIncident"
            ? { customOnChange: changeDateOfIncident }
            : {}),
          validTime: "past",
          form,
        };
      }

      if (field.formItemName === "timeOfIncident") {
        field = {
          ...field,
          dateField: "dateOfIncident",
          validTime: "past",
          form,
        };
      }

      if (field.formItemName === "incidentAddress") {
        field = {
          ...field,
          onSelect: onChangeAddress,
          //   initialValue: propAddress,
          groupedOptions: getDispatchLocationOptions(
            schedulesForDate,
            projects
          ),
          showSearch: true,
          customOnSearch: async (searchValue) =>
            await searchSafetyAddress({
              searchValue,
              dispatchSafetyFormData,
              userConfiguration,
              projects,
              scheduling,
              schedulesForDate,
              dateField: "dateOfIncident",
              form,
            }),
        };
      }

      //   if (field.formItemName === "reportPreparedBy") {
      //     delete field?.options;
      //     field = {
      //       ...field,
      //       showSearch: true,
      //       allowClear: true,
      //       customOptions: userConfiguration?.allUsers?.Items?.map((user) => {
      //         return { value: user?.identityId, label: user?.nameOfUser };
      //       }),
      //       style: { width: 200 },
      //       initialValue: !defaultId && userConfiguration?.identityId,
      //     };
      //   }

      let fieldCategoryIndex = fields.findIndex(
        (e) => e?.title === field?.category
      );

      if (fieldCategoryIndex > -1) {
        fields[fieldCategoryIndex]["fields"].push(field);
        // addConditionalFieldsSafety({
        //   fields,
        //   fieldCategoryIndex,
        //   otherPersonnelAffected,
        //   immediateActions,
        //   otherTypeOfEquipment,
        //   field,
        // });
      } else {
        fields.push({
          title: field?.category,
          fields: [field],
        });
        // addConditionalFieldsSafety({
        //   fields,
        //   fieldCategoryIndex: fields.length - 1 || 0,
        //   otherPersonnelAffected,
        //   immediateActions,
        //   otherTypeOfEquipment,
        //   field,
        // });
      }

      if (disabledAllFields) {
        if (!field.disabled) {
          field.disabled = disabledAllFields;
        }
      }
    }

    let additionalInfoOptions = fields.findIndex(
      (e) => e.title === "Additional Information"
    );
    if (additionalInfoOptions > -1) {
      let tmp = fields.splice(additionalInfoOptions, 1);
      fields.push(tmp[0]);
    }

    return fields;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export default preTaskPlanFields;
