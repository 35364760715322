export const getSingleFields = (dynamicFields, category, rowData) => {
  // Filter dynamic fields based on category and render type
  const filteredDynamicFields = dynamicFields?.filter(
    ({ dynamicFieldCategory, dynamicFieldRenderFor }) =>
      dynamicFieldCategory.toLowerCase() === category.toLowerCase() &&
      dynamicFieldRenderFor === "single" // Only include fields meant for single rendering
  );

  // Further filter the fields to ensure they have a valid value in rowData
  const mappedDynamicFieldsFiltered = filteredDynamicFields?.filter(
    ({ dynamicFieldValue }) => {
      const value = rowData[dynamicFieldValue];
      // Ensure value is not empty, not an object, and is defined
      return value !== "" && typeof value !== "object" && value !== undefined;
    }
  );

  const items = [
    {
      label: `----${category}----`,
      children: mappedDynamicFieldsFiltered?.map((item) => ({
        ...item,
        label: item.dynamicFieldLabel,
      })),
    },
  ];

  return items;
};

export const getTableFields = (programFields, category, allDynamicFields) => {
  // Find dynamic field options corresponding to the specified category
  const foundProgamFields = programFields?.find(
    ({ fieldName }) => fieldName === "Dynamic Fields Subcategories" // Look for the specific field name
  )?.fieldOptions[category?.toLowerCase()]?.options; // Access the category options

  // If no program fields are found, return an empty array
  if (!foundProgamFields) {
    return [];
  }

  // Map the found program fields to create button elements for the dropdown
  const mappedTableFields = foundProgamFields?.map((tableField) => ({
    label: `----${tableField}----`, // Use the table field as the key
    children: allDynamicFields
      ?.filter(
        ({ dynamicFieldCategory, dynamicFieldRenderFor }) =>
          dynamicFieldCategory === tableField &&
          dynamicFieldRenderFor === "single"
      )
      .map((item) => ({
        ...item,
        label: item.dynamicFieldLabel,
        action: true,
        type: "tableFields",
      })),
  }));

  return mappedTableFields; // Return the mapped table fields for rendering
};

export function flattenArray(array) {
  const result = [];

  function extractLabels(arr) {
    arr.forEach((item) => {
      result.push(item.label);

      if (item.children) {
        item.children.forEach((child) => {
          if (child.children) {
            extractLabels(child.children);
          }
        });
      }
    });
  }

  extractLabels(array);
  return result;
}

export function findObjectByLabel(arr, selectedValue) {
  for (const item of arr) {
    // Check if the current item's label matches the selectedValue
    if (item.label === selectedValue) {
      return item;
    }

    // If the item has children, recursively search in the children
    if (item.children) {
      const found = findObjectByLabel(item.children, selectedValue);
      if (found) {
        return found;
      }
    }
  }

  // Return null if no matching object is found
  return null;
}
