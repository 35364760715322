import dayjs from "dayjs";
import { fetchAllData } from "./ApiMethods";

/**
 * Recursively formats an array of notes.
 *
 * This function takes an array of notes and formats each note's createdAt
 * property to a specific date format. It also recursively formats any replies
 * associated with the notes.
 *
 * @param {Array} notes - An array of note objects
 * @returns {Array} - The formatted array of notes
 */
const formatNotes = (notes) =>
  notes.map((note) => ({
    ...note,
    createdAt: dayjs(note.createdAt).format("MM/DD/YYYY hh:mm A"),
    replies: note.replies ? formatNotes(note.replies) : [],
  }));

export const fetchNotesAndFormatData = async (recordId) => {
  const result = await fetchAllData({
    endpoint: "notes",
    resultId: "noteId",
    otherStringParams: {
      filters: JSON.stringify([
        {
          conditions: [{ column: "recordId", value: recordId, formula: "is" }],
        },
      ]),
    },
  });

  console.log({ result });

  return formatNotes(result?.filter((item) => item.privateOnlyTo === ""));
};
