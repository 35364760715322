export function generateDataset(dataArray, selectedDataSource) {
  const dataSourceName = selectedDataSource?.split(" ")?.join("") || "";
  const dataset = {
    WidthSensitivity: "Auto",
    Fields: [],
    Query: {
      CommandText: "jpath=$.*",
      DataSourceName: dataSourceName,
    },
    KanatypeSensitivity: "Auto",
    CaseSensitivity: "Auto",
    AccentSensitivity: "Auto",
    Name: `${dataSourceName}DataSet`,
  };

  const sampleObject = dataArray.length > 0 ? dataArray[0] : {};

  Object.keys(sampleObject).forEach((key) => {
    if (Array.isArray(sampleObject[key])) {
      // Handle array fields
      if (
        sampleObject[key].length > 0 &&
        typeof sampleObject[key][0] === "object"
      ) {
        const arrayFields = Object.keys(sampleObject[key][0]).map((field) => {
          return {
            DataField: `${key}.${field}`,
            Name: `${key}.${field}`,
          };
        });
        dataset["Fields"] = [...dataset["Fields"], ...arrayFields];
      }
    } else if (typeof sampleObject[key] === "object") {
      // Handle nested object fields
      const nestedObjectFields = Object.keys(sampleObject[key] || {}).map(
        (field) => {
          return {
            DataField: `${key}.${field}`,
            Name: `${key}.${field}`,
          };
        }
      );
      dataset["Fields"] = [...dataset["Fields"], ...nestedObjectFields];
    } else {
      // Handle primitive fields
      dataset["Fields"].push({
        DataField: key,
        Name: key,
      });
    }
  });

  return { ...dataset }; // Return a new object to avoid mutation
}
