import { fetchAllData } from "../ApiMethods";

const otherKeys = ["rentalId", "services", "taxRate"];

// optimized report fetch data
export const getServicesOfRental = async ({ keysToInclude }) => {
  const rentals = await fetchAllData({
    endpoint: "rentals",
    resultId: "rentalId",
    otherStringParams: {
      keysToInclude: JSON.stringify([
        ...new Set([...keysToInclude, ...otherKeys]),
      ]),
    },
  });

  return rentals.flatMap(({ services = [], ...rental }) => {
    return services.map((service) => {
      let totalPrice = 0;

      service?.serviceOptions?.forEach(({ items = [] }) => {
        // from the extracted services we extract all weeks and take the amount from those weeks
        items.forEach(({ rentalDetails = {} }) => {
          rentalDetails.details?.forEach(({ days }) => {
            days?.map(({ dailyAmount = 0 }) => {
              totalPrice = totalPrice + dailyAmount;
            });
          });
        });
      });

      const totalTax = totalPrice * (rental?.taxRate || 0);

      let totalAmount = totalPrice;

      if (totalTax > 0) {
        totalAmount = totalPrice + totalTax;
      }

      return {
        ...rental,
        serviceName: service.label,
        quickBooksNumber: service?.quickbooksNumber,
        rentPercentage: service.rentPercentage,
        retainage: service.retainage,
        totalPrice,
        totalTax,
        totalAmount,
      };
    });
  });
};
