import { message } from "antd";
import { InputComponent } from "../../../components";
import { useEffect, useRef, useState } from "react";
import { MondayButton } from "../../../../../commonComponents";
import { PlusOutlined } from "@ant-design/icons";
import { API } from "aws-amplify";
import { v4 } from "uuid";
import { fetchData } from "../../../utils";

const SelectCustomOption = ({
  menu,
  companies,
  setCompanies,
  isInInformation = false,
}) => {
  const ref = useRef(null);
  const [customOption, setCustomOption] = useState("");

  useEffect(() => {
    if (!isInInformation) return;
    const loadingMessage = message.loading("Loading companies...", 0);

    fetchData("companies")
      .then((r) => {
        const formattedOptions = [
          "Personal",
          ...r.map((item) => item.companyName),
        ];

        setCompanies(formattedOptions);
      })
      .finally(() => {
        loadingMessage();
      });
  }, []);

  const addCustomOption = () => {
    if (companies?.some((op) => op?.label === customOption)) {
      message.error({
        key: "customOption",
        content: "This value already exists!",
      });
      return;
    }

    const newCompany = {
      companyId: v4(),
      companyName: customOption,
      address: "",
      logos: {},
      phoneNumber: "",
    };

    API.post("companies", "/companies", { body: newCompany })
      .then(() => {
        if (isInInformation) {
          setCompanies((prev) => [...prev, customOption]);
        } else {
          setCompanies((prev) => [...prev, { companyName: customOption }]);
        }
        setCustomOption("");
        ref.current?.focus();
      })
      .catch((e) => console.error("API Error:", e));
  };

  return (
    <>
      {menu}
      <div className="selectCustomOption">
        <InputComponent
          placeholder="Other..."
          ref={ref}
          value={customOption}
          onChange={(e) => setCustomOption(e.target.value)}
          onKeyDown={(e) => e.stopPropagation()}
        />
        <MondayButton
          Icon={<PlusOutlined />}
          onClick={addCustomOption}
          disabled={!customOption}
        />
      </div>
    </>
  );
};

export default SelectCustomOption;
