import { CloseOutlined } from "@ant-design/icons";
import {
  MondayButton,
  MultiLevelTreeLogs,
  ProgressComponent,
  RedWarningModal,
} from "../../../../../commonComponents";
import {
  DeleteIcon,
  TickIcon,
} from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { LogsIcon } from "../../../../../SidebarPages/DynamicView/src";
import { PdfIcon } from "../../../../../SidebarPages/Documentation/View/assets";
import ToDoButton from "../../../../../SidebarPages/ToDos/components/ToDoButton/ToDoButton";
import { useState } from "react";
import { useRequestModalContext } from "../../store";
import {
  createRequest,
  deleteRequest,
  deleteRequestFilesHandler,
  editRequest,
  requestBody,
  saveRequestFilesHandler,
} from "../../helpers";
import { useEditLogs, useProgressComponent } from "../../../../../../hooks";
import { compareIncluding } from "../../../../../SidebarPages/utils";
import { useSelector } from "react-redux";
import {
  approvalsDocDefinition,
  configPdfApprovalsData,
} from "../../helperData";
import pdfMake from "pdfmake/build/pdfmake";
import { v4 as uuidv4 } from "uuid";
import { message } from "antd";
import { generatePdf } from "./fetchImageSrc";
import { driveApi } from "../../../../../../integrations/DriveRequest";
import { ReportIcon } from "../../../../../../icons";
import { EmailIcon } from "../../../../../../assets";
import broadcastNotification from "../../../../../../helpers/controllers/broadcastNotification";
import { getCognitosForNotification } from "../../../../../../utils";
import { useGoogleSignIn } from "../../../../../../hooks/useGoogleSignIn";
import Report from "../../../../../SidebarPages/DynamicView/Pages/Overview/components/Report/Report";
import EmailBox from "../../../../../SidebarPages/Communication/components/EmailBox/EmailBox";
import { useCustomerWebsocketContext } from "../../../../../../contexts/WebsocketProvider/useCustomerWebsocket";
// import Report from "../../../../../SidebarPages/DynamicView/Pages/Overview/components/Report/Report";

const topicCategory = "Approvals";

/**
 * Renders the footer component for the request form modal.
 *
 * @component
 * @param {Object} form - The form object.
 * @param {number} currentStep - The current step of the form.
 * @param {boolean} changes - Indicates if there are any changes in the form.
 * @param {Array} uploadedFiles - The list of uploaded files.
 * @param {Array} formattedFiles - The list of formatted files.
 * @param {Function} onCancel - The function to cancel the form.
 * @param {Array} filesToBeDeleted - The list of files to be deleted.
 * @param {Object} userConfiguration - The user configuration object.
 * @param {boolean} allFieldsDisabled - Indicates if all fields are disabled.
 * @returns {JSX.Element} The rendered component.
 */
const RequestFooter = ({
  form = {},
  currentStep = 0,
  changes = false,
  uploadedFiles = [],
  formattedFiles = [],
  onCancel = () => {},
  filesToBeDeleted = [],
  userConfiguration = {},
  allFieldsDisabled = false,
}) => {
  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
  } = useProgressComponent({ categoryName: "Approvals", actionType: "Create" });
  const { googleAccessToken } = useGoogleSignIn();
  const { saveAddedLogs } = useEditLogs();

  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const {
    statuses,
    requestData: {
      selectedMainField = {},
      editLogs = [],
      fetchedData = {},
      selectedTeam = [],
    },
    requestType,
    originalRequestForm = null,
    globalDisabled,
    inputFields: { [requestType]: inputFields = [] },
    ws = null,
    setVisible = () => {},
    from,
    action,
    geoFenceInfo,
    geoLocation,
  } = useRequestModalContext();
  const { sendWsMessage } = useCustomerWebsocketContext();

  const latLngKeys =
    requestType === "Project"
      ? { lat: "lat", lng: "lng" }
      : requestType === "Opportunity"
      ? { lat: "opportunityLatitude", lng: "opportunityLongitude" }
      : false;

  const [{ base64 = "" }, { accessToken = "" }] = useSelector((state) => [
    state.base64,
    state.accessToken,
  ]);

  const driveRequest = driveApi({ accessToken });

  const [warning, setWarning] = useState({
    type: "",
    func: () => {},
  });
  const [emailBox, setEmailBox] = useState(false);
  const [emailBoxes, setEmailBoxes] = useState([]);
  const [emailFile, setEmailFile] = useState([]);
  const [editLogsVisibility, setEditLogsVisibility] = useState(false);
  const [attachments, setAttachments] = useState([]);

  const [reportModal, setReportModal] = useState(false);
  const [saving, setSaving] = useState(false);

  const disabledDelete = () => {
    if (globalDisabled) {
      return true;
    }

    if (
      userConfiguration?.groupName === "Admin" ||
      userConfiguration?.groupName === "Super Admin"
    ) {
      return false;
    }

    if (
      originalRequestForm?.userRequesting === userConfiguration?.cognitoUserId
    ) {
      return false;
    }

    return true;
  };

  const onSave = async () => {
    const tmpBody = requestBody({
      statuses,
      currentStep,
      requestType,
      inputFields,
      formattedFiles,
      selectedMainField,
      selectedTeam,
      originalRequestForm,
      fetchedData,
      getFieldsValue: form.getFieldsValue,
    });

    const proceedTmpBody = {
      ...tmpBody,
      requestObject: {
        ...tmpBody.requestObject,
        [latLngKeys?.lat]: geoLocation?.lat,
        [latLngKeys?.lng]: geoLocation?.lng,
        geoFenceInfo,
      },
    };

    setSaving(true);

    if (!originalRequestForm?.requestId) {
      setWarning((prev) => ({ ...prev, type: "" }));

      return await createRequest({
        ws,
        tmpBody: proceedTmpBody,
        setVisible(error) {
          setVisible(false);
          if (error) {
            message.error({
              content: "Something went wrong.",
              key: "loading",
            });
          } else {
            message.success({
              content: "Saved successfully.",
              key: "loading",
            });
          }
        },
        requestType,
        userConfiguration,
        updateProgressStatus,
        fileHandler: () =>
          saveRequestFilesHandler({
            accessToken,
            filesToBeDeleted,
          }),
        setVisibleCreationProgress,
        saveAddedLogs,
      }).then(() => {
        setWarning((prev) => ({ ...prev, func: () => {} }));
      });
    } else {
      setWarning((prev) => ({ ...prev, type: "" }));
      return await editRequest({
        authenticatedUser,
        ws,
        from,
        action,
        tmpBody: proceedTmpBody,
        requestForm: originalRequestForm,
        setVisible(error) {
          setVisible(false);
          if (error) {
            message.error({
              content: "Something went wrong.",
              key: "loading",
            });
          } else {
            message.success({
              content: "Saved successfully.",
              key: "loading",
            });
          }
        },
        userConfiguration,
        fileHandler: () =>
          saveRequestFilesHandler({
            accessToken,
            filesToBeDeleted,
          }),
        saveAddedLogs,
        sendWsMessage,
      }).then(() => {
        setWarning((prev) => ({ ...prev, func: () => {} }));
      });
    }
  };

  const onDelete = async () => {
    message.loading({
      content: "Deleting request. Please wait...",
      duration: 0,
      key: "loading",
    });
    await deleteRequest({
      ws,
      requestId: originalRequestForm?.requestId,
      recordName: originalRequestForm?.requestObject?.recordName,
      requestType,
      userConfiguration,
      handleCloseModal: (error) => {
        setVisible(false);
        if (error) {
          message.error({
            content: "Something went wrong.",
            key: "loading",
          });
        } else {
          message.success({
            content: "Request deleted successfully.",
            key: "loading",
          });
        }
      },
      fileHandler: () => {
        deleteRequestFilesHandler({
          accessToken,
          uploadedFiles,
          gDriveUploads: originalRequestForm?.gDriveUploads || [],
          filesToBeDeleted,
        });
      },
      saveAddedLogs,
    });
  };

  function base64ToFile(dataUrl, fileName) {
    var arr = dataUrl.split(","),
      mime = "application/pdf",
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  }

  const assignedTo = userConfiguration?.allUsers?.Items?.find(
    (user) => user?.cognitoUserId === originalRequestForm?.assignedTo
  )?.nameOfUser;

  const createdBy = userConfiguration?.allUsers?.Items?.find(
    (user) => user?.cognitoUserId === originalRequestForm?.createdBy
  )?.nameOfUser;

  function addPdfToEmail() {
    let companyLogo = base64?.find(({ fileName }) =>
      compareIncluding(fileName, "Core Logo Black")
    )?.base64;

    const pdfDocGenerator = pdfMake.createPdf(
      approvalsDocDefinition(
        `${requestType} Request Form`,
        configPdfApprovalsData({
          title: "Details",
          body: {
            requestForm: { ...originalRequestForm, assignedTo, createdBy },
          },
        }),
        (companyLogo = companyLogo)
      )
    );

    pdfDocGenerator.getDataUrl((dataUrl) => {
      const file = base64ToFile(dataUrl, `${requestType}RequestFormPDF.pdf`);
      const fileObj = {
        blob: dataUrl,
        id: uuidv4(),
        name: file.name,
        size: file.size,
        type: file.type,
        status: 400,
        uid: file.uid,
      };
      setEmailFile([fileObj]);
      setEmailBox(true);
    });
  }

  function onEmailSave({ e, key, type, setLoading }) {
    if (type.toUpperCase() === "EMAIL") {
      message.success("Email sent");
      setLoading(false);
      setEmailBox(false);
    }
  }

  function sendEmailNotification() {
    broadcastNotification(
      "13",
      "onRequestEmail",
      [
        {
          common: userConfiguration?.nameOfUser,
        },
        {
          userName: userConfiguration?.nameOfUser,
          currentUser: authenticatedUser?.sub,
          cognitos: getCognitosForNotification(userConfiguration),
        },
      ],
      originalRequestForm?.requestForm?.requestId
    );
  }

  const sendEmailProps = {
    email: {
      onClose() {
        setEmailBox(false);
      },
      setEmails() {},
      rowData: originalRequestForm?.requestForm,
      authToken: googleAccessToken,
      onSave(e, type, setLoading) {
        onEmailSave({ e, type, setLoading });
        // setEmailBox(false);
      },
      chargePdf: emailFile,
      isFromCharge: true,
      isRedirected:
        originalRequestForm?.requestForm?.requestObject?.gDriveUploads?.map(
          (e) => e?.id
        ),
      sendEmailNotification,
      proppedRecordId: originalRequestForm?.requestForm?.requestId,
      proppedTemplateCat: "Approvals",
    },

    reports: {
      emailBoxes,
      onClose: () => {
        if (attachments.length > 0) setAttachments([]);
        setEmailBoxes([]);
      },
      rowData: originalRequestForm?.requestForm,
      setEmailBoxes,
      attachments,
      setAttachments,
      visibleReport: reportModal,
    },
  };

  return (
    <div className="request-modal-footer">
      {originalRequestForm && (
        <div className="buttonContainer" key="request-form-footer-left">
          <MondayButton
            {...{
              className: "deleteButton mondayButtonRed",
              Icon: <DeleteIcon />,
              onClick: () => {
                setWarning({
                  type: "delete",
                  func: onDelete,
                });
              },
              disabled: disabledDelete(),
              id: "deleteButton",
              topicCategory,
              tooltipKey: "delete",
            }}
          >
            Delete
          </MondayButton>
          <MondayButton
            {...{
              className: "showLogsButton mondayButtonBlue",
              Icon: <LogsIcon />,
              onClick: () => {
                setEditLogsVisibility(true);
              },
            }}
            id="logsButton"
            topicCategory={topicCategory}
            tooltipKey="showLogs"
          >
            Show Logs
          </MondayButton>
          <ToDoButton
            {...{
              recordId: originalRequestForm?.requestId,
              recordName:
                originalRequestForm?.requestObject?.recordName !== undefined
                  ? originalRequestForm?.requestObject?.recordName
                  : "Request",
              topicCategory,
            }}
          />
          <MondayButton
            {...{
              className: "mondayButtonBlue",
              Icon: <ReportIcon />,
              onClick: () => setReportModal(true),
              topicCategory,
              tooltipKey: "showReport",
            }}
          >
            Report
          </MondayButton>
          <MondayButton
            {...{
              className: "emailButton mondayButtonBlue",
              Icon: <EmailIcon />,
              onClick: addPdfToEmail,
              topicCategory,
              tooltipKey: "openEmail",
            }}
          >
            Send Email
          </MondayButton>

          <MondayButton
            {...{
              className: "mondayButtonPdf",
              Icon: <PdfIcon />,
              onClick: () => {
                generatePdf({
                  base64,
                  driveRequest,
                  originalRequestForm: {
                    ...originalRequestForm,
                    assignedTo,
                    createdBy,
                  },
                  requestType,
                });
              },
              topicCategory,
              tooltipKey: "exportPdf",
            }}
          >
            Export To PDF
          </MondayButton>
        </div>
      )}
      <div className="buttonContainer" key="request-form-footer-right">
        <MondayButton
          {...{
            className: "cancelButton mondayButtonRed",
            Icon: <CloseOutlined />,
            onClick: () => setWarning({ type: "cancel", func: onCancel }),
            topicCategory,
            tooltipKey: "cancel",
          }}
        >
          Cancel
        </MondayButton>
        <MondayButton
          {...{
            className: "saveButton",
            Icon: <TickIcon />,
            disabled:
              !!saving || allFieldsDisabled || globalDisabled || !changes,
            onClick: () => {
              form.validateFields().then(() => {
                setWarning({
                  type: "save",
                  func: onSave,
                });
              });
            },
            id: "saveButton",
            topicCategory,
            tooltipKey: "save",
          }}
        >
          Save
        </MondayButton>
        {!!warning.type && (
          <RedWarningModal
            {...{
              visible: !!warning.type,
              onCancel: () => setWarning({ type: "", func: () => {} }),
              closable: true,
              titleText: "Caution",
              footerProps: { onConfirm: warning.func },
              confirmModal:
                warning.type === "save" || warning.type === "cancel",
              deleteModal: warning.type === "delete",
            }}
          >
            <p>
              Are you sure you want to <b>{warning.type}</b>{" "}
              {warning.type === "delete" ||
                (warning.type === "save" && "this request")}
              ?
            </p>
            {warning?.type === "cancel" ? (
              <p>
                Confirming will cancel all changes made and they will not be
                saved.
              </p>
            ) : warning?.type === "delete" ? (
              <p>
                This action is irreversible and will permanently remove all
                associated data.
              </p>
            ) : (
              <p>Confirming will save all changes made.</p>
            )}
          </RedWarningModal>
        )}
        {!!editLogsVisibility && (
          <MultiLevelTreeLogs
            {...{
              visible: editLogsVisibility,
              setVisible: setEditLogsVisibility,
              logsData: editLogs,
              title: "Request Logs",
              // viewLogs: [],
            }}
          />
        )}

        {visibleCreationProgress && creationProgresses && (
          <ProgressComponent
            {...{
              categoryName: "Approvals",
              actionType: "Create",
              visibleCreationProgress,
              creationProgresses,
              closeModal: setVisible,
            }}
          />
        )}

        {reportModal && (
          <Report
            {...{
              isModalVisible: reportModal,
              onCancel: () => setReportModal(false),
              customCategoryName: "Approvals Report",
              customPartitionKeys: { identityId: "requestId" },
              recordId: originalRequestForm?.requestId,
              customData: [originalRequestForm],
              setVisibleReport: setReportModal,
              emailBoxes,
              setEmailBoxes,
              setAttachments,
              sendByEmail: true,
            }}
          />
        )}
      </div>
      {(emailBox || emailBoxes?.length > 0) && (
        <div className="safetyEmailBox">
          <EmailBox
            {...(emailBox
              ? { ...sendEmailProps?.email }
              : { ...sendEmailProps?.reports })}
          />
        </div>
      )}
    </div>
  ); //.filter(Boolean);
};

export default RequestFooter;
