import { Button } from "antd";
import { useState } from "react";
import { NormalSizedModal } from "../../../../../../SidebarPages/Fleet/components";
import "./Chained.scss";

const Chained = (params) => {
  const [visible, setVisible] = useState(false);

  const scopeNr = params?.data?.foundInScopes?.length;
  return (
    <div className="chainedContainer">
      <Button
        onClick={() => !!scopeNr && setVisible((prev) => !prev)}
        className={`chainedButton ${
          !!scopeNr ? "chainedButtonActive" : "chainedButtonNotActive"
        }`}
      >
        {!!scopeNr ? `Chained (${scopeNr} services)` : "Not Chained"}
      </Button>

      <NormalSizedModal
        visible={visible}
        title="Chained Services"
        centered={true}
        setVisible={setVisible}
        footer={[
          {
            text: "Close",
            cancel: true,
            className: "noMarginButton",
            onClick: () => setVisible(false),
          },
        ]}
        className="chainedServicesModal"
      >
        <table>
          <tr style={{ textAlign: "left" }}>
            <th>Service ID</th>
            <th>Service Name</th>
          </tr>
          {!!scopeNr ? (
            params?.data?.foundInScopes?.map((service) => {
              return (
                <tr>
                  <td>{service?.serviceId}</td>
                  <td>{service?.serviceName}</td>
                </tr>
              );
            })
          ) : (
            <div>
              <span>No chained services</span>
            </div>
          )}
        </table>
      </NormalSizedModal>
    </div>
  );
};

export default Chained;
