import moment from "moment";
import { forceToNumber } from "../../components/SidebarPages/Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { fetchAllData, fetchData } from "../ApiMethods";

export const getEstimationServices = async ({
  recordId,
  keysToInclude,
  reportUsedKeys,
}) => {
  const finalKeys = [...keysToInclude];

  if (reportUsedKeys.some((key) => key.toLowerCase() === "status")) {
    finalKeys.push("estSTATUS");
  }

  if (reportUsedKeys.includes("calendarYear")) {
    finalKeys.push("createdAt");
  }

  let estimations = [];

  if (recordId) {
    estimations = [
      await fetchData({
        endpoint: "estimations",
        query: `estimations/${recordId}`,
      }),
    ];
  } else {
    estimations = await fetchAllData({
      endpoint: "estimations",
      resultId: "estimationId",
      otherStringParams: {
        keysToInclude: JSON.stringify([
          ...new Set(["estimationId", ...finalKeys]),
        ]),
      },
    });
  }

  return estimations
    .map((est) => {
      const newServices = est.services
        .filter((service) => !service.isScope)
        .map((service) => {
          const serviceOptions = Array.isArray(service.serviceOptions?.[0])
            ? service.serviceOptions[0]
            : service.serviceOptions;

          const newServiceOptions = serviceOptions?.map((option) => ({
            name: option.elevationLabel,
            totalAmount:
              option.items?.reduce(
                (acc, obj) => acc + obj?.totalPrice || 0,
                0
              ) || 0,
          }));

          return {
            accountName: est.accountName,
            jobSiteAddress: est.jobSiteAddress,
            createdAt: est.createdAt,
            estimationId: est.estimationId,
            serviceName: service.label,
            estimationNumber: est.estimationNumber,
            serviceOptions: newServiceOptions,
            totalAmount: newServiceOptions?.reduce(
              (sum, option) => sum + option.totalAmount,
              0
            ),
          };
        });

      return {
        ...est,
        status: est.estSTATUS,
        calendarYear: moment(est.createdAt).year(),
        totalEstimationAmount: newServices?.reduce(
          (sum, service) => sum + service.totalAmount,
          0
        ),
        serviceCount: est.services.filter((service) => !service.isScope).length,
        services: newServices,
      };
    })
    .filter(Boolean);
};
