import { FormInstance } from "antd";
import { createContext } from "react";
import { ColumnApi, GridApi } from "ag-grid-community";

/**
 * @typedef CoordinateType
 * @property {number} lat
 * @property {number} lng
 */

/**
 * @typedef GeofenceType
 * @property {number} createdAt
 * @property {string} createdBy
 * @property {string} description
 * @property {Array<CoordinateType>} geoFenceInfo
 * @property {string} shapeId
 * @property {string} title
 * @property {string} type
 */

/**
 * @typedef JobsiteType
 * @property {string | {accountId: string, accountName: string, accountRecordType: string}} accountName
 * @property {CoordinateType} addressPosition
 * @property {number} createdAt
 * @property {Array<GeofenceType>} geoFenceInfo
 * @property {Array<GeofenceType>} geofenceInfo
 * @property {string} googleSheetLink
 * @property {string} jobAddress
 * @property {string} jobName
 * @property {string} jobsiteId
 * @property {string | number} locationRadius
 * @property {"Certified Payroll" | "Open Shop" | "Prevailing Wage"} payrollType
 * @property {string} projectId
 * @property {object} rates
 * @property {boolean} reimbursement
 * @property {string} scheduleId
 * @property {Array<string>} services
 * @property {string} userId
 */

/**
 * @typedef analytics
 * @property {object} employeesHoursPerDay
 * @property {object} employeeIncorrectShifts
 * @property {object} employeeOver24HShift
 * @property {object} employeeOverheadShifts
 * @property {object} employeeWeekTotals
 * @property {Array} employeeNotFoundLocations
 * @property {Array} dateRange
 * @property {Array} shiftsWithMultipleJobsites
 * @property {Array} missingLunchEntries
 * @property {number} generalOverheadTotal
 */

/**
 * @typedef PayrollContextType
 * @property {FormInstance} form
 * @property {Array} crews
 * @property {function} setCrews
 * @property {Array} rowData
 * @property {Array<JobsiteType>} jobsites
 * @property {function} setRowData
 * @property {string} degName
 * @property {Array} foremans
 * @property {Array} fingerCheckWeeklyData
 * @property {Array} notFound
 * @property {Array} accounts
 * @property {string} degStatus
 * @property {Array} emptyData
 * @property {function} setEmptyData
 * @property {analytics} analytics
 * @property {boolean} uploading
 * @property {function} setUploading
 * @property {object} rowToEdit
 * @property {object} rowToEdit
 * @property {GridApi} degGridApi
 * @property {function} setDegGridApi
 * @property {ColumnApi} degColumnApi
 * @property {function} setDegColumnApi
 * @property {boolean} isLastStep
 * @property {boolean} isDarkMode
 * @property {function} updateData
 * @property {number} currentStep
 * @property {string} accountName
 * @property {function} setNotFound
 * @property {object} entriesLogs
 * @property {Array} incorrectData
 * @property {GridApi} shiftsGridApi
 * @property {function} setShiftsGridApi
 * @property {object} degStepFilters
 * @property {object} warningFilters
 * @property {function} addEntryAction
 * @property {object} entriesActions
 * @property {function} analyticsUpdate
 * @property {object} actionsRegister
 * @property {function} addActionToRegister
 * @property {object} jobsiteServices
 * @property {function} setJobsiteServices
 * @property {Array} allServiceOptions
 * @property {function} massUpdateHandler
 * @property {function} updateCrewHandler
 * @property {object} shiftExternalFilter
 * @property {HTMLButtonElement} stopUploadingButton
 * @property {function} updateWarningFilters
 * @property {object} filtersFromSelection
 * @property {function} setFiltersFromSelection
 * @property {function} updateJobsitesHandler
 * @property {function} updateShiftStatistics
 * @property {function} updateStatisticsHandler
 * @property {function} updateShiftExternalFilter
 * @property {object} defaultServicesPerProject
 * @property {function} setDefaultServicesPerProject
 *
 */

/**@type {PayrollContextType} */
const initialValue = {};

const DegModalContext = createContext(initialValue);

export default DegModalContext;
