import { useEffect, useState } from "react";
import { camelToTitle } from "../../../utils";
import "./AgFooterColumnStats.scss";
import _ from "lodash";
import { useDragScroll } from "./hooks/useDragScroll";

const AgFooterColumnStats = ({ api, fieldKeys = {} }) => {
  const dragScroll = useDragScroll();
  const [calculatedStats, setCalculatedStats] = useState({});

  const actions = {
    sum: (arr) => arr.reduce((acc, curr) => acc + curr, 0),
    count: (arr) => arr.length,
    avg: (arr) =>
      (arr.reduce((acc, curr) => acc + curr, 0) / arr.length).toFixed(2),
    mode: (arr) => {
      let frequency = _.countBy(arr);
      return _.maxBy(Object.keys(frequency), (o) => frequency[o]);
    },
  };

  const addInteger = (val, arr) => {
    if (/^\d+$/.test(val)) arr.push(val);
  };

  const calculateData = () => {
    let counts = {};

    Object.entries(fieldKeys).forEach(([field, operations]) => {
      if (!counts[field]) counts[field] = {};
      operations.forEach((op) => {
        const arr = [];
        api.forEachNodeAfterFilter(({ data }) => {
          addInteger(data[field], arr);
        });
        const result = !!arr.length ? actions[op](arr) : 0;
        counts[field][op] = result;
      });
    });

    setCalculatedStats(counts);
  };

  useEffect(() => {
    const events = ["filterChanged", "firstDataRendered", "rowDataUpdated"];

    calculateData();

    events.forEach((event) => api.addEventListener(event, calculateData));

    return () =>
      events.forEach((event) => api.removeEventListener(event, calculateData));
  }, []);

  return (
    <div className="ag-footer-stats-wrapper" {...dragScroll}>
      {Object.entries(calculatedStats).map(([fieldKey, obj], idx) => (
        <div key={idx} className="key-stats" data-testid="key-stats">
          {Object.entries(obj).map(([op, count], i) => (
            <div
              key={i}
              className="key-stats-item"
              data-testid="key-stats-item"
            >
              {labels[op]} {camelToTitle(fieldKey)}: <strong>{count}</strong>{" "}
              {i !== Object.keys(obj).length - 1 ? "|" : ""}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default AgFooterColumnStats;

const labels = {
  sum: "Total",
  count: "Count",
  avg: "Average",
  mode: "Mode",
};
