import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./SchedulingProgressTrack.scss";
import { Alert, Card, message, Modal, App, Skeleton, Spin, Badge } from "antd";
import { MondayButton } from "../../../../../commonComponents";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { AgGridReact } from "ag-grid-react";
import {
  columnDefsForProgressTracking,
  convertCamelCaseToTitleCase,
} from "../../SchedulingFirstPage/helperData";
import { FilterIcon } from "../../../../../SidebarPages/BasePage/src";
import SchedulingProgressFilters from "./SchedulingProgressFilters";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { ExcelIconWhite } from "../../../../../../assets";
import ProgressCrews from "./ProgressCrews";
import TimelineProgress from "./TimelineProgress/TimelineProgress";
import { API } from "aws-amplify";
import {
  debounceSearch,
  fetchAllData,
  fetchSearchRecords,
  filterTables,
  gridSidebar,
} from "../../../../../../utils";
import { InputComponent } from "../../../../../SidebarPages/Fleet/components";
import { LoadingDots } from "../../../../../commonComponents/3LoadingDots/LoadingDots";
import { groupBy } from "lodash";
import { columnDefsForServices } from "./columnDefsForServices";
import { assignServiceColor } from "../../../../../../helpers/constants/forProject";

const SchedulingProgressTrack = ({
  openProgressLogs = false,
  onCancel = () => {},
  progressLogs = [],
  fetchFUNCTION = () => {},
  infoHeaderDisplayed = [],
  view = "",
}) => {
  const [loading, setLoading] = useState(false);
  const [viewCrews, setViewCrews] = useState({ visible: false, crews: {} });
  const [projects, setProjects] = useState([]);

  const onChangeHandler = async (e) => {
    setLoading(true);
    const { value } = e.target;

    if (!value || value === "") {
      setProjects([]);
      setLoading(false);
      return;
    }

    try {
      const res = await fetchSearchRecords("projects", "projectAddress", value);

      if (!res?.length) {
        setProjects([]);
        setLoading(false);
        message.info("No projects found! Please double-check your search.");
        return;
      }

      const projectsWithSchedulesAndProgress = await Promise.all(
        res.map(async (project) => {
          const projectProgress = await fetchAllData({
            endpoint: "progressItems",
            resultId: "projectId",
            otherStringParams: {
              filters: JSON.stringify([
                {
                  conditions: [
                    {
                      column: "projectId",
                      value: project?.projectId,
                      formula: "is",
                    },
                  ],
                },
              ]),
            },
          });
          return {
            ...project,
            progresses: projectProgress || [],
          };
        })
      );

      setProjects(projectsWithSchedulesAndProgress);
    } catch (error) {
      console.error(
        "Error fetching projects, schedules, or progress items:",
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const debouncedChange = debounceSearch(onChangeHandler, 300);

  useEffect(() => {
    if (openProgressLogs && view === "forSchedule") {
      const fetchProgressLogs = async () => {
        try {
          message.loading("Loading progress data...", 0);
          await fetchFUNCTION().then((res) => {
            console.log("ressss", { res });
            setProjects(
              [
                {
                  projectAddress: "",
                  progresses: [...res, ...progressLogs],
                },
              ] || []
            );
          });

          message.destroy();
        } catch (e) {
          message.error("Error fetching progress logs!");
          console.error(e);
        }
      };
      fetchProgressLogs();
    }
  }, [openProgressLogs, view]);

  return (
    <div>
      <Modal
        open={openProgressLogs}
        title="Progress Process"
        centered
        footer={null}
        onCancel={onCancel}
        className="progress-track-modal"
        closeIcon={<XIcon />}
      >
        <div className="progressTrackModal">
          {view !== "forSchedule" && (
            <InputComponent
              placeholder="Search project..."
              onChange={debouncedChange}
            />
          )}
          <Spin spinning={loading} />
          <div className="progressTrackCardContainer">
            {projects?.map((project) => {
              const groupServiceProgress = groupBy(
                project?.progresses || [],
                "serviceName"
              );
              return (
                <div>
                  <Card
                    className="progressTrackCard"
                    title={
                      view === "forSchedule"
                        ? infoHeaderDisplayed?.[0]?.value
                        : project?.projectAddress
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 22,
                      }}
                    >
                      {!Object.values(groupServiceProgress).flat().length ? (
                        <>
                          <span>No data available</span>
                        </>
                      ) : (
                        Object.entries(groupServiceProgress).map(
                          ([service, progress]) => {
                            return (
                              <Badge.Ribbon
                                text={service}
                                color={
                                  assignServiceColor[progress?.[0]?.serviceId]
                                }
                              >
                                <div
                                  className={`documentationsTable ${"light-ag-theme ag-theme-alpine"}`}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                    }}
                                  >
                                    <AgGridReact
                                      {...{
                                        columnDefs:
                                          columnDefsForServices[service] || [],
                                        rowData: progress || [],
                                        rowGroupPanelShow: "always",
                                        pivotPanelShow: "always",
                                        groupHeaderHeight: 32,
                                        floatingFiltersHeight: 20,
                                        enableCellChangeFlash: true,
                                        pivotHeaderHeight: 32,
                                        pivotGroupHeaderHeight: 32,
                                        grandTotalRow: "bottom",
                                        defaultColDef: {
                                          resizable: true,
                                          enablePivot: true,
                                          enableColResize: true,
                                          enableRowGroup: true,
                                          editable: false,
                                          sortable: true,
                                          filter: true,
                                          pivot: true,
                                          enableRowGroup: true,
                                          enableValue: true,
                                        },
                                      }}
                                    />
                                  </div>
                                </div>
                              </Badge.Ribbon>
                            );
                          }
                        )
                      )}
                    </div>
                  </Card>
                </div>
              );
            })}
          </div>
        </div>

        {/* <>
          <div className="progress-track-content">
            <Alert
              className="progress-track-alert"
              type="info"
              showIcon
              message={
                <div className="progress-track-alert-content">
                  {typeof infoHeaderDisplayed === "string"
                    ? infoHeaderDisplayed
                    : infoHeaderDisplayed?.map((item) => {
                        return (
                          <div className="progress-track-alert-content-item">
                            <span>{item.field}</span>
                            <span>{item.value}</span>
                          </div>
                        );
                      }) || []}
                  <div style={{ display: "flex", gap: 10 }}>
                    {/* {view === "allSchedules" && (
                      <MondayButton
                        {...{
                          onClick: () => setTimeline(true),
                          hasIcon: false,
                          className: "mondayButtonPrismarine",
                        }}
                      >
                        Progress Timeline
                      </MondayButton>
                    )} */}
        {/* <MondayButton
                      onClick={() =>
                        setProgressFilters({
                          ...progressFilters,
                          visible: true,
                        })
                      }
                      {...{
                        className: "mondayButtonBlue",
                        Icon: <FilterIcon />,
                      }}
                    >
                      Open Filters
                    </MondayButton> */}
        {/* {!!Object.keys(progressFilters?.filters || {})?.length && (
                      <MondayButton
                        {...{
                          className: "mondayButtonRed",
                          Icon: <XIcon />,
                          onClick: () => applyFilters({}),
                        }}
                      >
                        Clear Filters
                      </MondayButton>
                    )} */}
        {/* <MondayButton
                      {...{
                        className: "mondayButtonBlue",
                        Icon: <ExcelIconWhite />,
                        onClick: exportGridDataToExcel,
                      }}
                    >
                      Export to Excel
                    </MondayButton>
                  </div>
                </div>
              }
            />
          </div>
          <div
            className={`documentationsTable ${"light-ag-theme ag-theme-alpine"}`}
            style={{ width: "100%", height: "94.5%" }}
          >
            <AgGridReact
              {...{
                ref: gridRef,
                onGridReady,
                columnDefs: columnDefsForProgressTracking(view, setViewCrews),
                rowData:
                  [
                    ...(filteredData?.prev || []),
                    ...(filteredData?.curr || []),
                  ] || [],
                autoGroupColumnDef,
                sideBar: true,
                rowGroupPanelShow: "always",
                pagination: true,
                paginationPageSize: 35,
                defaultColDef: {
                  minWidth: 100,
                  enableValue: true,
                  enableRowGroup: true,
                  enablePivot: true,
                  resizable: true,
                },
              }}
            />
          </div>
        </> */}
      </Modal>
      {/* {progressFilters?.visible && (
        <SchedulingProgressFilters
          {...{
            progressFilters,
            setProgressFilters,
            progressLogs:
              [...(filteredData?.prev || []), ...(filteredData?.curr || [])] ||
              [],
            applyFilters,
            inputOptions,
          }}
        />
      )} */}
      {/* {!!viewCrews?.visible && (
        <ProgressCrews
          {...{
            viewCrews,
            onCancel: () => setViewCrews({ visible: false, data: {} }),
          }}
        />
      )} */}
      {/* {!!timeline && (
        <TimelineProgress
          {...{
            timeline,
            onCancel: () => setTimeline(false),
            rowData:
              [...(filteredData?.prev || []), ...(filteredData?.curr || [])] ||
              [],
            inputOptions,
          }}
        />
      )} */}
    </div>
  );
};

export default SchedulingProgressTrack;

// import React, { useCallback, useEffect, useMemo, useState } from "react";
// import "./SchedulingProgressTrack.scss";
// import { Alert, Card, message, Modal, App } from "antd";
// import { MondayButton } from "../../../../../commonComponents";
// import { XIcon } from "../../../../../SidebarPages/Communication/assets";
// import { AgGridReact } from "ag-grid-react";
// import {
//   columnDefsForProgressTracking,
//   convertCamelCaseToTitleCase,
// } from "../../SchedulingFirstPage/helperData";
// import { FilterIcon } from "../../../../../SidebarPages/BasePage/src";
// import SchedulingProgressFilters from "./SchedulingProgressFilters";
// import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
// import { ExcelIconWhite } from "../../../../../../assets";
// import ProgressCrews from "./ProgressCrews";
// import TimelineProgress from "./TimelineProgress/TimelineProgress";
// import { API } from "aws-amplify";
// import { gridSidebar } from "../../../../../../utils";

// const SchedulingProgressTrack = ({
//   openProgressLogs = false,
//   onCancel = () => {},
//   progressLogs = [],
//   fetchFUNCTION = () => {},
//   infoHeaderDisplayed = [],
//   view = "",
// }) => {
//   const gridRef = React.useRef(null);
//   const { notification } = App.useApp();
//   const [timeline, setTimeline] = useState(false);
//   const [progressFilters, setProgressFilters] = useState({});
//   const [filteredData, setFilteredData] = useState({
//     prev: [],
//     curr: [],
//     unchangedPrev: [],
//   });
//   const [viewCrews, setViewCrews] = useState({ visible: false, crews: {} });
//   const [dataFetched, setDataFetched] = useState(false);
//   const [gridApi, setGridApi] = useState(null);

//   const onGridReady = (params) => {
//     setGridApi(params.api);
//   };

//   const inputOptions = (rowData, keys) => {
//     if (!rowData || !rowData.length) return [];

//     const keyArray = Array.isArray(keys) ? keys : [keys];

//     const options = keyArray.flatMap((key) =>
//       rowData.flatMap((item) => {
//         const value = item?.[key];

//         if (Array.isArray(value)) {
//           return value.map((subValue) => ({
//             label: subValue,
//             value: subValue,
//           }));
//         }

//         if (typeof value === "object") {
//           return Object.values(value)
//             .flat(1)
//             .map((subValue) => ({
//               label: subValue.crewName,
//               value: subValue.crewName,
//             }));
//         }
//         return {
//           label:
//             key === "startDate" ? dayjsNY(value).format("MM/DD/YYYY") : value,
//           value: value,
//         };
//       })
//     );
//     const uniqueOptions = Array.from(
//       new Map(options?.map((item) => [item?.value, item])).values()
//     )?.filter((item) => !!item?.value);

//     return uniqueOptions;
//   };

//   const exportGridDataToExcel = useCallback(() => {
//     if (!gridApi) return;
//     const params = {
//       fileName: "ProgressLogs",
//       sheetName: "ProgressLogs",
//       allColumns: true,
//       skipHeader: false,
//     };
//     gridApi.exportDataAsExcel({
//       ...params,
//       processCellCallback: ({ column, value }) => {
//         if (column?.colId === "crews") {
//           return Object.keys(value || {}).join(", ");
//         }
//         return value;
//       },
//     });
//   }, [gridApi]);

//   const applyFilters = (filters = {}) => {
//     if (Object.keys(filters).length === 0) {
//       setFilteredData({
//         prev: filteredData?.unchangedPrev || [],
//         curr: progressLogs || [],
//         unchangedPrev: filteredData?.unchangedPrev || [],
//       });
//       setProgressFilters({ filters, visible: false });
//       return;
//     }
//     const filterItems = (items = []) => {
//       if (!items.length || !Object.keys(filters).length) return items;
//       return items.filter((item) => {
//         return Object.entries(filters).every(([key, value]) => {
//           if (key === "startDate" || key === "progressTimeGiven") {
//             return (
//               dayjsNY(item?.[key]).format("MM/DD/YYYY") ===
//               dayjsNY(value).format("MM/DD/YYYY")
//             );
//           }

//           const itemValue = item?.[key];
//           if (Array.isArray(itemValue)) {
//             return itemValue.includes(value);
//           } else if (typeof itemValue === "string") {
//             return itemValue.includes(value);
//           } else if (typeof itemValue === "object") {
//             return Object.values(itemValue)
//               .flat(1)
//               .some((subValue) => subValue.crewName === value);
//           } else {
//             return itemValue === value;
//           }
//         });
//       });
//     };

//     const { unchangedPrev = [], curr = [] } = filteredData || {};

//     const filteredPrev = filterItems(unchangedPrev);
//     const filteredCurr = filterItems(curr);
//     setFilteredData({
//       prev: filteredPrev,
//       curr: filteredCurr,
//       unchangedPrev: unchangedPrev,
//     });

//     setProgressFilters({ ...progressFilters, visible: false });

//     Object.keys(filters || {}).length &&
//       Object.entries(filters || {})?.forEach?.(([key, value]) => {
//         const title = convertCamelCaseToTitleCase(key);
//         notification.info({
//           className: "info__notification",
//           message: `${title}`,
//           description: `Filter applied for ${title} - ${value}`,
//           duration: 8,
//           placement: "bottomLeft",
//         });
//       });
//   };

//   const autoGroupColumnDef = useMemo(() => {
//     return {
//       minWidth: 200,
//     };
//   }, []);

//   useEffect(() => {
//     if (openProgressLogs && !dataFetched) {
//       const fetchProgressLogs = async () => {
//         try {
//           message.loading("Loading progress data...", 0);
//           await fetchFUNCTION().then((res) => {
//             setFilteredData({
//               prev: res || [],
//               curr: progressLogs || [],
//               unchangedPrev: res || [],
//             });
//           });

//           setDataFetched(true);
//           message.destroy();
//         } catch (e) {
//           message.error("Error fetching progress logs!");
//           console.error(e);
//         }
//       };

//       fetchProgressLogs();
//     } else if (!openProgressLogs) {
//       setFilteredData({
//         prev: filteredData?.prev || [],
//         curr: progressLogs || [],
//         unchangedPrev: filteredData?.unchangedPrev || [],
//       });
//     }
//   }, [openProgressLogs, progressLogs, dataFetched]);

//   return (
//     <div>
//       <Modal
//         open={openProgressLogs}
//         title="Progress Process"
//         centered
//         footer={null}
//         // footer={
//         //   <MondayButton
//         //     onClick={onCancel}
//         //     {...{ className: "mondayButtonRed", Icon: <XIcon /> }}
//         //   >
//         //     Close
//         //   </MondayButton>
//         // }
//         onCancel={onCancel}
//         className="progress-track-modal"
//         closeIcon={<XIcon />}
//       >
//         <>
//           <div className="progress-track-content">
//             <Alert
//               className="progress-track-alert"
//               type="info"
//               showIcon
//               message={
//                 <div className="progress-track-alert-content">
//                   {typeof infoHeaderDisplayed === "string"
//                     ? infoHeaderDisplayed
//                     : infoHeaderDisplayed?.map((item) => {
//                         return (
//                           <div className="progress-track-alert-content-item">
//                             <span>{item.field}</span>
//                             <span>{item.value}</span>
//                           </div>
//                         );
//                       }) || []}
//                   <div style={{ display: "flex", gap: 10 }}>
//                     {/* {view === "allSchedules" && (
//                       <MondayButton
//                         {...{
//                           onClick: () => setTimeline(true),
//                           hasIcon: false,
//                           className: "mondayButtonPrismarine",
//                         }}
//                       >
//                         Progress Timeline
//                       </MondayButton>
//                     )} */}
//                     <MondayButton
//                       onClick={() =>
//                         setProgressFilters({
//                           ...progressFilters,
//                           visible: true,
//                         })
//                       }
//                       {...{
//                         className: "mondayButtonBlue",
//                         Icon: <FilterIcon />,
//                       }}
//                     >
//                       Open Filters
//                     </MondayButton>
//                     {!!Object.keys(progressFilters?.filters || {})?.length && (
//                       <MondayButton
//                         {...{
//                           className: "mondayButtonRed",
//                           Icon: <XIcon />,
//                           onClick: () => applyFilters({}),
//                         }}
//                       >
//                         Clear Filters
//                       </MondayButton>
//                     )}
//                     <MondayButton
//                       {...{
//                         className: "mondayButtonBlue",
//                         Icon: <ExcelIconWhite />,
//                         onClick: exportGridDataToExcel,
//                       }}
//                     >
//                       Export to Excel
//                     </MondayButton>
//                   </div>
//                 </div>
//               }
//             />
//           </div>
//           <div
//             className={`documentationsTable ${"light-ag-theme ag-theme-alpine"}`}
//             style={{ width: "100%", height: "94.5%" }}
//           >
//             <AgGridReact
//               {...{
//                 ref: gridRef,
//                 onGridReady,
//                 columnDefs: columnDefsForProgressTracking(view, setViewCrews),
//                 rowData:
//                   [
//                     ...(filteredData?.prev || []),
//                     ...(filteredData?.curr || []),
//                   ] || [],
//                 autoGroupColumnDef,
//                 sideBar: true,
//                 rowGroupPanelShow: "always",
//                 pagination: true,
//                 paginationPageSize: 35,
//                 defaultColDef: {
//                   minWidth: 100,
//                   enableValue: true,
//                   enableRowGroup: true,
//                   enablePivot: true,
//                   resizable: true,
//                 },
//               }}
//             />
//           </div>
//         </>
//       </Modal>
//       {progressFilters?.visible && (
//         <SchedulingProgressFilters
//           {...{
//             progressFilters,
//             setProgressFilters,
//             progressLogs:
//               [...(filteredData?.prev || []), ...(filteredData?.curr || [])] ||
//               [],
//             applyFilters,
//             inputOptions,
//           }}
//         />
//       )}
//       {!!viewCrews?.visible && (
//         <ProgressCrews
//           {...{
//             viewCrews,
//             onCancel: () => setViewCrews({ visible: false, data: {} }),
//           }}
//         />
//       )}
//       {/* {!!timeline && (
//         <TimelineProgress
//           {...{
//             timeline,
//             onCancel: () => setTimeline(false),
//             rowData:
//               [...(filteredData?.prev || []), ...(filteredData?.curr || [])] ||
//               [],
//             inputOptions,
//           }}
//         />
//       )} */}
//     </div>
//   );
// };

// export default SchedulingProgressTrack;
