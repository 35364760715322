import { Form } from "antd";
import { useEffect, useState } from "react";
import {
  CheckBox,
  DatePicker,
  Dropdown,
  Email,
  Input,
  Multiple,
  MultipleAutoComplete,
  Number,
  Phone,
  RichText,
  TimePicker,
} from "../../FormItemComponents";
import "./FormItems.scss";
import { useSelector } from "react-redux";
import SliderRange from "../../FormItemComponents/Slider/Slider";
import SingleFileUploader from "../../FormItemComponents/SingleFileUploader/SingleFileUploader";
import MultipleInputs from "../../FormItemComponents/MultipleInputs/MultipleInputs";
import PlacesInputWithCoordinates from "../../FormItemComponents/PlacesInputWithCoordinates/PlacesInputWithCoordinates";

const FormItem = ({
  name,
  value: inputValue,
  BodyComponent,
  options = [],
  form,
  rules = [],
  label,
  disabled,
  onChange,
  disabledDate,
  getTaxExept,
  placeholder,
  style,
  customOptions,
  format,
  status,
  showNow,
  disabledTime,
  newDate,
  range,
  marks,
  min,
  max,
  initialFileId,
  folderCredentialKey,
  onlyTypes,
  allowCustomDropdownOptions,
  dynamicFields,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [val, setVal] = useState(inputValue);

  useEffect(() => {
    setVal(inputValue);
  }, [inputValue]);

  return (
    <div className="FormItemsClassName">
      <Form.Item
        {...{
          name,
          initialValue: val,
          onChange,
          rules: [
            ...(rules || []),
            name === "binNumber"
              ? {
                  validator: (_, value = []) => {
                    return !!value.length &&
                      value.some((val) => isNaN(parseInt(val)))
                      ? Promise.reject(new Error("Value must be a number"))
                      : Promise.resolve();
                  },
                }
              : false,
          ].filter(Boolean),
        }}
      >
        <BodyComponent
          {...{
            range,
            marks,
            min,
            max,
            style,
            value: val,
            form,
            name,
            options,
            disabledDate,
            label,
            disabled,
            onChange,
            getTaxExept,
            placeholder,
            customOptions,
            isDarkMode,
            format,
            status,
            showNow,
            disabledTime,
            newDate,
            isDarkMode,
            initialFileId,
            folderCredentialKey,
            onlyTypes,
            allowCustomDropdownOptions,
            dynamicFields,
          }}
        />
      </Form.Item>
    </div>
  );
};
FormItem.Input = ({
  name,
  value,
  form,
  rules,
  disabled,
  onChange,
  isDarkMode,
}) => (
  <FormItem
    {...{
      name,
      value,
      BodyComponent: Input,
      form,
      rules,
      disabled,
      onChange,
      isDarkMode,
    }}
  />
);
FormItem.DatePicker = ({
  name,
  value,
  form,
  rules,
  disabled,
  disabledDate,
  placeholder,
  onChange,
  isDarkMode,
}) => (
  <FormItem
    {...{
      name,
      value,
      BodyComponent: DatePicker,
      form,
      rules,
      disabledDate,
      onChange,
      disabled,
      placeholder,
      isDarkMode,
    }}
  />
);
FormItem.TimePicker = ({
  name,
  value,
  form,
  disabled,
  disabledTime,
  onChange,
  isDarkMode,
  format,
  status,
  use12Hours,
  showNow,
  newDate,
}) => (
  <FormItem
    {...{
      name,
      value,
      BodyComponent: TimePicker,
      form,
      disabled,
      onChange,
      isDarkMode,
      use12Hours,
      format,
      status,
      showNow,
      disabledTime,
      newDate,
    }}
  />
);
FormItem.Phone = ({
  name,
  value,
  form,
  rules,
  disabled,
  onChange,
  isDarkMode,
}) => (
  <FormItem
    {...{
      name,
      value,
      BodyComponent: Phone,
      form,
      rules,
      disabled,
      onChange,
      isDarkMode,
    }}
  />
);
FormItem.Email = ({
  name,
  value,
  form,
  rules,
  disabled,
  onChange,
  isDarkMode,
}) => (
  <FormItem
    {...{
      name,
      value,
      BodyComponent: Email,
      form,
      rules,
      disabled,
      onChange,
      isDarkMode,
    }}
  />
);
FormItem.Dropdown = ({
  name,
  value,
  form,
  rules,
  options,
  disabled,
  placeholder,
  onChange,
  getTaxExept,
  customOptions,
  isDarkMode,
  allowCustomDropdownOptions,
}) => (
  <FormItem
    {...{
      name,
      value,
      BodyComponent: Dropdown,
      form,
      options,
      onChange,
      rules,
      disabled,
      placeholder,
      getTaxExept,
      customOptions,
      isDarkMode,
      allowCustomDropdownOptions,
    }}
  />
);
FormItem.RichText = ({
  name,
  value,
  form,
  rules,
  disabled,
  setValue,
  onChange,
  isDarkMode,
}) => (
  <RichText
    {...{
      form,
      formItemName: name,
      initialValue: value,
      rules,
      disabled,
      setValue,
      onChange,
      isDarkMode,
    }}
  />
);
FormItem.CheckBox = ({
  name,
  value,
  form,
  rules,
  label,
  disabled,
  onChange,
  isDarkMode,
}) => (
  <FormItem
    {...{
      name,
      value,
      BodyComponent: CheckBox,
      form,
      rules,
      label,
      disabled,
      onChange,
      isDarkMode,
    }}
  />
);
FormItem.Number = ({
  name,
  value,
  form,
  rules,
  disabled,
  onChange,
  isDarkMode,
}) => (
  <FormItem
    {...{
      name,
      value,
      BodyComponent: Number,
      form,
      rules,
      disabled,
      onChange,
      isDarkMode,
    }}
  />
);
FormItem.Multiple = ({
  name,
  value,
  form,
  rules,
  options,
  disabled,
  customOptions,
  style,
  onChange,
  isDarkMode,
}) => (
  <FormItem
    {...{
      name,
      value,
      BodyComponent: Multiple,
      form,
      options,
      rules,
      disabled,
      customOptions,
      style,
      onChange,
      isDarkMode,
    }}
  />
);
FormItem.MultipleAutoComplete = ({
  name,
  value,
  form,
  rules,
  options,
  disabled,
  placeholder,
  style,
  onChange,
  isDarkMode,
}) => (
  <FormItem
    {...{
      name,
      value,
      BodyComponent: MultipleAutoComplete,
      form,
      options,
      rules,
      disabled,
      placeholder,
      style,
      onChange,
      isDarkMode,
    }}
  />
);
FormItem.SliderRange = ({
  min,
  max,
  name,
  form,
  value,
  range,
  marks,
  disabled,
  onChange,
  isDarkMode,
}) => (
  <FormItem
    {...{
      min,
      max,
      name,
      value,
      range,
      marks,
      BodyComponent: SliderRange,
      form,
      disabled,
      onChange,
      isDarkMode,
    }}
  />
);
FormItem.MultipleInputs = ({
  name,
  label,
  value,
  dynamicFields,
  form,
  isDarkMode,
}) => (
  <FormItem
    {...{
      name,
      label,
      value,
      dynamicFields,
      form,
      isDarkMode,
      BodyComponent: MultipleInputs,
    }}
  />
);
FormItem.SingleFileUploader = ({
  name,
  value,
  form,
  initialFileId,
  disabled,
  folderCredentialKey,
  folderId,
  onlyTypes,
  maxFileSize,
}) => (
  <FormItem
    {...{
      name,
      value,
      form,
      initialFileId,
      disabled,
      folderId,
      folderCredentialKey,
      onlyTypes,
      maxFileSize,
      BodyComponent: SingleFileUploader,
    }}
  />
);
FormItem.PlacesInputWithCoordinates = ({
  name,
  value,
  form,
  disabled,
  placeholder,
  onChange,
  isDarkMode,
}) => (
  <FormItem
    {...{
      name,
      value,
      BodyComponent: PlacesInputWithCoordinates,
      form,
      disabled,
      placeholder,
      onChange,
      isDarkMode,
    }}
  />
);
export default FormItem;
