import { Modal, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import DegModalContext from "../DegModalContext";
import { MondayButton } from "../../../../../../../commonComponents";
import {
  dayjsNY,
  dayjsNYDate,
} from "../../../../../../../DateComponents/contants/DayjsNY";
import BasePage from "../../../../../../../SidebarPages/BasePage";
import { AddIcon } from "../../../../../../../Header/components/GoogleEvents/assets";
import { useEntriesApi } from "../../../../../../PayrollLive/utils";
import { columnDefs } from "./AgGridData/columnDefs";
import { v4 as uuid } from "uuid";

const CheckMissingEntries = ({ open, onCancel }) => {
  const { updateEntries } = useEntriesApi();
  const [missingEntries, setMissingEntries] = useState([]);
  const [selectedEntries, setSelectedEntries] = useState([]);

  const {
    rowToEdit,
    rowData,
    setRowData,
    fingerCheckWeeklyData,
    crews,
    crewTeams,
    isDarkMode,
  } = useContext(DegModalContext);

  const handleAddSelectedEntries = async () => {
    const messageKey = "updatingEntries";

    try {
      message.loading({
        content: `Adding ${selectedEntries.length} entries...`,
        key: messageKey,
        duration: 0,
      });

      await updateEntries({
        entries: missingEntries,
      });

      setRowData([...rowData, ...selectedEntries]);

      message.success({
        content: `Successfully added ${selectedEntries.length} entries`,
        key: messageKey,
        duration: 3,
      });

      onCancel();
    } catch (error) {
      console.error("Error updating entries:", error);

      message.error({
        content: "Failed to add entries. Please try again.",
        key: messageKey,
        duration: 5,
      });
    }
  };

  useEffect(() => {
    const findMissingEntries = () => {
      const extractNumericId = (employeeId = "") => {
        if (!employeeId) return "";
        const matches = employeeId.match(/\d+/);
        return matches ? matches[0] : employeeId;
      };

      const normalizeDate = (dateString) => {
        try {
          const nyDate = dayjsNYDate(dateString);
          const normalizedDate = nyDate.format("YYYY-MM-DD");
          return normalizedDate;
        } catch (error) {
          console.error("Date normalization error:", dateString, error);
          return "";
        }
      };

      const createCompositeKey = (entry) => {
        if (!entry) return "";
        const numericId = extractNumericId(entry.employeeId);
        const normalizedDate = normalizeDate(entry.punchDate);
        const punchType = entry.punchType || "";
        return `${numericId}_${normalizedDate}_${punchType}`;
      };

      const rowDataKeys = new Set();
      const rowDataMap = new Map();

      rowData.forEach((entry) => {
        const key = createCompositeKey(entry);
        if (key) {
          rowDataKeys.add(key);
          rowDataMap.set(key, entry);
        }
      });

      const missing = fingerCheckWeeklyData
        .filter((fingerCheckEntry) => {
          const key = createCompositeKey(fingerCheckEntry);
          const exists = key && rowDataKeys.has(key);
          return !exists;
        })
        .map((entry) => {
          const enrichedEntry = { ...entry };

          // Standardize date formats
          const punchDate = dayjsNY(entry.punchDate).format(
            "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
          );
          const punchTime = dayjsNY(entry.punchTime).format(
            "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"
          );

          Object.assign(enrichedEntry, {
            punchDate,
            punchTime,
            activityStatus: "Draft",
            company: rowToEdit.companyName,
            companyName: rowToEdit.companyName,
            uploadName: "Imported from FingerCheck",
            uploadId: uuid(),
            degId: rowToEdit.degId,
            punchTimeStamp: dayjsNY(entry.punchTime).valueOf(),
            salaryType: "Hourly",
            reason: "",
            employeeType: {
              jobsiteMatch: {
                jobName: "",
                payrollType: "",
              },
              role: entry.employeeRole || "",
            },
            jobsiteId: "",
            jobsiteMatch: {
              jobAddress: "",
              jobName: "",
              jobsiteId: "",
              googleSheetLink: "",
              services: [],
              reimbursement: false,
            },
            lastModifiedBy: "reihabibi",
            lastModifiedAt: "2025-03-27 09:35:10",
          });

          if (entry.punchCoordinates) {
            enrichedEntry.punchCoordinates = {
              lat: Number(entry.punchCoordinates.lat),
              lng: Number(entry.punchCoordinates.lng),
            };
          }

          const employeeNumber = entry.employeeId?.split("-")?.[1] || "";
          enrichedEntry.employeeNumber = employeeNumber;

          const matchedCrew = crews.find(
            (crew) =>
              crew.employeeId === `${rowToEdit.companyName}-${entry.employeeId}`
          );

          if (matchedCrew) {
            Object.assign(enrichedEntry, {
              crewId: matchedCrew.crewId,
              employeeId: matchedCrew.employeeId,
              employeeRate: matchedCrew.employeeRate,
              crewPosition: matchedCrew.crewPosition,
              salaryType: matchedCrew.salaryType || "Hourly",
            });

            const matchedCrewTeam = crewTeams.find((team) => {
              const isForeman = team.crewForeman?.crewId === matchedCrew.crewId;
              const isMember = team.crewMembers?.some(
                (member) => member.crewId === matchedCrew.crewId
              );
              return isForeman || isMember;
            });

            if (matchedCrewTeam) {
              Object.assign(enrichedEntry, {
                crewTeamId: matchedCrewTeam.crewTeamId,
                crewTeamName: matchedCrewTeam.crewTeamName,
                crewForeman: matchedCrewTeam.crewForeman?.crewName,
              });
            }
          }

          return enrichedEntry;
        });

      setMissingEntries(missing);
    };

    if (fingerCheckWeeklyData?.length && rowData?.length && crews?.length) {
      findMissingEntries();
    }
  }, [fingerCheckWeeklyData, rowData, crews, crewTeams, rowToEdit]);

  return (
    <Modal
      open={open}
      closable={true}
      onCancel={onCancel}
      closeIcon={<XIcon />}
      title="Missing Entries"
      data-testid="shiftsToSplit-modal"
      className={`shiftsToSplitModal ${isDarkMode && "shiftsToSplitModalDark"}`}
      footer={[
        <MondayButton
          Icon={<XIcon />}
          key="close-modal"
          onClick={onCancel}
          className="mondayButtonRed"
          data-testid="close-missingEntries-modal-btn"
        >
          Cancel
        </MondayButton>,
        <MondayButton
          Icon={<AddIcon />}
          key="add-modal"
          onClick={handleAddSelectedEntries}
          className="mondayButtonGreen"
          data-testid="add-missingEntries-modal-btn"
          disabled={selectedEntries.length === 0}
        >
          Add Entries ({selectedEntries.length})
        </MondayButton>,
      ]}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "calc(90dvh - 210px)",
          overflow: "hidden",
        }}
      >
        <BasePage
          title=""
          rowData={missingEntries}
          columnDefs={columnDefs}
          hasNew={false}
          hasStatus={false}
          hasFilter={true}
          hasLive={false}
          defaultColDef={{
            sortable: true,
            resizable: true,
            filter: true,
            flex: 1,
            minWidth: 100,
          }}
          agGridDefs={{
            rowHeight: 48,
            animateRows: true,
            rowSelection: "multiple",
            onSelectionChanged: (event) => {
              const selectedRows = event.api.getSelectedRows();
              setSelectedEntries(selectedRows);
            },
          }}
        />
      </div>
    </Modal>
  );
};

export default CheckMissingEntries;
