import { useContext, useEffect, useMemo, useState } from "react";
import PayrollContext from "../../PayrollContext";
import PayrollLayout from "../../Layout/PayrollLayout";
import {
  dayjsNY,
  dayjsNYTimestamp,
} from "../../../../DateComponents/contants/DayjsNY";
import { columnDefs } from "./AgGridData/columnDefs";
import { InputComponent } from "../../../../SidebarPages/Fleet/components";
import { useSelector } from "react-redux";
import {
  AgFooterColumnStats,
  ExportOrEmailDropdown,
} from "../../../../commonComponents";
import { getExcelColumnKeys } from "../../../../../utils/getExcelColumnKeys";
import "./JobsTab.scss";

const JobsTab = ({ accessRight }) => {
  const { drivers, routes } = useContext(PayrollContext);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [gridApi, setGridApi] = useState(null);
  const [columnApi, setColumnApi] = useState(null);
  const [activeFilters, setActiveFilters] = useState({});
  const [initialRowData, setInitialRowData] = useState([]);

  const handleJobsData = (arr1, arr2) => {
    const filtered = arr2
      .map((e) => e?.routes)
      .flat(Infinity)
      .filter((route) => !!route?.departAt)
      .map((el) => {
        const idx = arr1.findIndex((dr) => dr.driverId === el.driverId);
        if (idx !== -1) {
          const { yardRelated, nemoRelated } = arr1[idx];
          return { ...el, yardRelated, nemoRelated };
        }
        return el;
      });

    const entriesByDate = _.groupBy(filtered, ({ departAt }) =>
      dayjsNY(departAt).format("MM/DD/YYYY")
    );

    const data = Object.entries(entriesByDate)
      .map(([date, arr]) => {
        const nemoRelated = arr.filter((entry) => entry.nemoRelated).length;
        const yardRelated = arr.filter((entry) => entry.yardRelated).length;

        return { periodDate: date, nemoRelated, yardRelated };
      })
      .sort(
        (a, b) =>
          dayjsNY(b?.periodDate).startOf("day").valueOf() -
          dayjsNY(a?.periodDate).startOf("day").valueOf()
      );

    setInitialRowData(data);
  };

  const exportGridToExcel = (tableColumns) => {
    gridApi.exportDataAsExcel({
      columnKeys: getExcelColumnKeys(gridApi, tableColumns),
      processCellCallback(params) {
        const value = params?.value;
        return value === undefined ? "" : `${value}`;
      },
    });
  };

  const rowData = useMemo(() => {
    if (!Object.values(activeFilters || {}).filter(Boolean).length)
      return initialRowData;
    const toReturn = [];

    initialRowData.forEach((row) => {
      const doesMatch = Object.entries(activeFilters).some(([key, value]) => {
        if (key === "range") {
          const [start, end] = value;
          const date = dayjsNY(row.periodDate);
          return date.isSameOrAfter(start) && date.isSameOrBefore(end);
        } else {
          //may add new filter by key cases here
          return true;
        }
      });
      if (doesMatch) toReturn.push(row);
    });

    return toReturn;
  }, [initialRowData, activeFilters]);

  const statusBar = useMemo(
    () => ({
      statusPanels: [
        {
          statusPanel: AgFooterColumnStats,
          align: "left",
          statusPanelParams: {
            fieldKeys: {
              nemoRelated: ["sum", "avg"],
              yardRelated: ["sum", "avg"],
            },
          },
        },
      ],
    }),
    []
  );

  useEffect(() => {
    if (!!drivers && routes) {
      handleJobsData(drivers, routes);
    }
  }, [drivers, routes]);

  return (
    <PayrollLayout
      {...{
        rowData,
        statusBar,
        columnDefs,
        classNames: "jobs-tab-container",
        hasNew: false,
        title: "Jobs",
        hasFilterBtn: false,
        dataIdKey: "periodDate",
        suppressDoubleClickEdit: true,
        getGridApi: (api) => setGridApi(api),
        getColumnApi: (api) => setColumnApi(api),
        customActionButtons: [
          <InputComponent
            type="rangepicker"
            dataTestid={"jobsDateRange"}
            placeholder="Set Date Range..."
            dropdownClassName={isDarkMode && "darkDateDropDown"}
            onChange={(e) =>
              setActiveFilters((prev) => ({ ...prev, range: e }))
            }
          />,
          <ExportOrEmailDropdown
            {...{
              rowData,
              gridApi,
              exportGridToExcel,
              gridColumnApi: columnApi,
              defaultDataRowData: false,
              title: "Project Cost - Jobs",
              tooltipCategory: "Project Cost - Jobs",
            }}
          />,
        ],
      }}
    />
  );
};

export default JobsTab;
