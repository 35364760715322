import React from "react";
import { TickIcon } from "../../../../../../pages/Settings/settingsComponents/Roles/src";
import { MondayButton } from "../../../../../../commonComponents";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { HistoryOutlined } from "@ant-design/icons";
import { updateEmployee } from "../../services/updateEmployee";

const FooterSection = ({ id, employeeType, form, refreshTable, onCancel }) => {
  async function onUpdateEmployee() {
    form.validateFields().then((updatedEmployeeData) => {
      const homeAddress = form.getFieldValue("homeAddress") || {
        address: "",
        coordinates: null,
      };
      updatedEmployeeData.homeAddress = homeAddress;

      updateEmployee(
        id,
        employeeType,
        updatedEmployeeData,
        refreshTable,
        onCancel
      );
    });
  }

  return [
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <MondayButton
        className="mondayButtonRed"
        onClick={onCancel}
        Icon={<XIcon />}
      >
        Cancel
      </MondayButton>
      {/* <div className="crewLogsDiv">
        <MondayButton
          className="mondayButtonBlue"
          //   onClick={openLogs}
          Icon={<HistoryOutlined />}
          tooltipKey="showLogs"
        >
          Logs
        </MondayButton>
      </div> */}
      <MondayButton
        Icon={<TickIcon height={17} width={17} />}
        onClick={() => onUpdateEmployee()}
      >
        Save Changes
      </MondayButton>
    </div>,
  ];
};

export default FooterSection;
