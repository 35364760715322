import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import { useAdvancedFilterContext } from "../../../commonComponents/AdvancedFilters/context/AdvancedFilterContext";
import { useFieldOptionFinder } from "../../../../hooks";
import { AdvancedFilters } from "../../../commonComponents";
import { initialFilters } from "../../../commonComponents/AdvancedFilters/utils";
import { MobileBasePageContext } from "../MobileBasePage";
import { useSelector } from "react-redux";
import FiltersModal from "../../../commonComponents/RowDataGridModal/components/FiltersModal";
import { adaptedTitlesForFiltersPanel } from "../utils";
import { Button, message, Result } from "antd";
import MappingConfigModal from "../../../pages/Settings/settingsComponents/MappingConfigurations/components/Modal/MappingConfigModal";

const MobileFilterModal = ({
  setOpenFilters,
  clearFiltersClicked,
  openFilters,
  setClearFiltersClicked,
}) => {
  const {
    rowData,
    setRowData,
    title,
    setOtherFilters,
    filterSearchData,
    setLoadingData,
    defaultRowData,
  } = useContext(MobileBasePageContext);
  const { topicCategories } = useSelector((state) => state.topicCategories);

  const findFiltersObjects = useFieldOptionFinder({
    fieldName: "Global Filter Keys",
  });
  const filtersObject = useMemo(() => {
    const titleToSearch = adaptedTitlesForFiltersPanel[title] || title;
    return findFiltersObjects[titleToSearch] || [];
  }, [findFiltersObjects, title]);
  console.log({ filtersObject });

  const { filters, setFilters, resetFilters } = useAdvancedFilterContext();

  useEffect(() => {
    setFilters(initialFilters);
  }, []);

  useEffect(() => {
    const isFilterActive = filters?.some((filter) =>
      filter?.conditions?.some((condition) => condition?.value !== "")
    );
    if (isFilterActive) {
      setOtherFilters(true);
    } else {
      setOtherFilters(false);
      setClearFiltersClicked(false);
    }
  }, [filters]);

  const handleResetFilters = async () => {
    if (!filterSearchData) {
      setRowData(defaultRowData);
      return;
    }
    if (clearFiltersClicked) {
      setLoadingData(true);
      try {
        resetFilters(setRowData);
        await filterSearchData([], true);
      } catch (error) {
        console.log({ error });
      } finally {
        setLoadingData(false);
      }
    }
  };

  useEffect(() => {
    handleResetFilters();
  }, [clearFiltersClicked]);

  const hasAdvancedFilters = useMemo(() => {
    return topicCategories?.find((category) =>
      category?.categoryName === title
        ? category?.categoryName === title
        : category?.prefix === title
    )?.hasAdvancedFilters;
  }, [topicCategories]);
  console.log({ openFilters });

  return !filtersObject.length && !hasAdvancedFilters ? (
    <MappingConfigModal
      open={openFilters}
      onCancel={() => setOpenFilters(false)}
      title="No filters found"
    >
      <Result
        extra={<Button onClick={() => setOpenFilters(false)}>Close</Button>}
        status="404"
        title="No filters found"
      />
    </MappingConfigModal>
  ) : hasAdvancedFilters ? (
    <AdvancedFilters
      {...{
        rowData,
        setRowData,
        title,
        isOpen: openFilters,
        filtersObject,
        setIsFiltersOpen: setOpenFilters,
      }}
    />
  ) : (
    <FiltersModal
      {...{
        title,
        showFilterModal: openFilters,
        setShowFilterModal: setOpenFilters,
        // appliedFilters: generalFilters,
        setAppliedFilters: (appliedFilters) => {
          const filtered = rowData.filter((row) => {
            return Object.keys(appliedFilters).every((key) => {
              const filteredValue = appliedFilters[key].map((el) =>
                el?.toLowerCase()?.trim()
              );
              const filteredRow = row[key]?.toLowerCase()?.trim();
              return filteredValue.includes(filteredRow);
            });
          });
          setOtherFilters(true);
          setRowData(filtered);
        },
        filtersObject,
        rowData,
      }}
    />
  );
};

export default memo(MobileFilterModal);
