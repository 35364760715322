import { message } from "antd";
import { API } from "aws-amplify";
import { v4 as uuid } from "uuid";

import { getDateRange } from "../utils";
import { findTeamForEmployee } from "./editShiftModalData";
import { DEG_DATE_FORMAT, findTimeDiff } from "../utils/cellFunctions";
import { createShiftsForCrews } from "../TeamShiftsModal/teamShiftModalData";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import { withinRadius } from "../../../../Activity/components/payrollActivityModalData";
import {
  setHourMinute,
  getDateOffset,
} from "../../../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

const SHIFT_PUNCH_TYPE = {
  clockIn: "ID",
  lunchEnd: "IL",
  clockOut: "OD",
  lunchStart: "OL",
};

function entryObjectGenerator({
  sow,
  rate,
  degId,
  shift,
  reason,
  duration,
  punchDate,
  punchTime,
  punchType,
  shiftStatus,
  scheduleDay,
  selectedTeam,
  employeeMatch,
  scheduleMatch,
  matchedJobsite,
  distanceFromJob,
}) {
  const generatedEntry = {
    sow,
    degId,
    reason,
    punchDate,
    punchType,
    entryId: uuid(),
    employeeRate: rate,
    punchTime: punchTime,
    company: shift?.company,
    duration: duration || 0,
    activityStatus: shiftStatus,
    crewId: employeeMatch?.crewId,
    employeeId: shift?.employeeId,
    scheduleDayId: scheduleDay?.id,
    companyName: shift?.companyName,
    employeeRole: shift?.employeeRole,
    jobsiteId: matchedJobsite?.jobsiteId,
    crewTeamId: selectedTeam?.crewTeamId,
    distanceFromJob: distanceFromJob || 0,
    scheduleId: scheduleMatch?.scheduleId,
    salaryType: employeeMatch?.salaryType,
    payrollType: matchedJobsite?.payrollType,
    punchCoordinates: shift.punchCoordinates,
    crewTeamName: selectedTeam?.crewTeamName,
    employeeFullName: shift?.employeeFullName,
    punchLocation: matchedJobsite?.jobAddress,
    punchTimeStamp: dayjsNY(punchTime).valueOf(),
    scheduleAddress: scheduleMatch?.scheduleAddress,
    punchCoordinates: matchedJobsite?.addressPosition,
    jobsiteMatch: {
      rates: matchedJobsite?.rates,
      jobName: matchedJobsite?.jobName,
      services: matchedJobsite?.services,
      jobsiteId: matchedJobsite?.jobsiteId,
      jobAddress: matchedJobsite?.jobAddress,
      reimbursement: matchedJobsite?.reimbursement,
      googleSheetLink: matchedJobsite?.googleSheetLink,
    },
  };
  return generatedEntry;
}

// #region Shift Edit
export function onShiftEdit({
  shift,
  crews,
  fields = {},
  rowData = [],
  onCancel,
  setCrews,
  formRate,
  rowToEdit,
  setRowData = () => {},
  alteredRate,
  postEntries,
  selectedTeam,
  employeeMatch,
  scheduleMatch,
  updateEntries,
  removeEntries,
  matchedJobsite,
  addActionToRegister,
  changeRateForEmployee = false,
}) {
  const { reason, punchDate, shiftStatus, shiftType, lunchIncluded, sow } =
    fields;

  let entriesForShift = []; // Entries of shift are sorted in [ID, OL, Il, OD] order or [ID, OD] order

  let newEntries = []; // List of new entries created
  let editActions = []; // List of edit actions
  let editedEntries = []; // List of edited entries
  let removedEntries = []; // List of removed entries

  for (const entryId of shift?.["entries"] || []) {
    let entry = rowData?.findIndex(({ entryId: id }) => id === entryId);
    if (entry > -1) {
      entriesForShift.push(rowData[entry]);
    }
  }
  let fieldsToIterate = lunchIncluded
    ? ["clockIn", "lunchStart", "lunchEnd", "clockOut"]
    : ["clockIn", "clockOut"];

  const scheduleDay = (scheduleMatch?.scheduleDays || []).find(
    (el) =>
      dayjsNY(el?.startDate).format(DEG_DATE_FORMAT) ===
      punchDate?.format?.(DEG_DATE_FORMAT)
  );

  if (shiftType === "Regular Shift") {
    // When shift is HR and will be transformed in a Regular Shift
    if (shift?.hrShift) {
      for (const field of fieldsToIterate) {
        const punchType = SHIFT_PUNCH_TYPE[field];

        const distanceFromJob =
          !!matchedJobsite?.addressPosition?.lat &&
          withinRadius(matchedJobsite?.addressPosition, shift?.punchCoordinates)
            .distanceInFeet;
        const duration = distanceFromJob && distanceFromJob / 3.5;

        const newEntry = entryObjectGenerator({
          sow,
          shift,
          reason,
          duration,
          punchDate,
          punchType,
          scheduleDay,
          shiftStatus,
          selectedTeam,
          scheduleMatch,
          employeeMatch,
          matchedJobsite,
          distanceFromJob,
          degId: rowToEdit?.degId,
          punchTime: fields?.[field],
          rate: formRate
            ? formRate
            : !isNaN(Number(alteredRate))
            ? Number(alteredRate)
            : employeeMatch?.employeeRate,
        });

        newEntries.push(newEntry);
      }
      removedEntries.push(entriesForShift?.[0]);
    } else {
      // Normal case when editing a shift

      if (lunchIncluded && !shift?.lunchStart) {
        // When editing adds lunch time, Add lunch entries
        for (let i = 0; i < entriesForShift.length; i++) {
          const shiftEntry = entriesForShift[i];

          const distanceFromJob =
            !!matchedJobsite?.addressPosition?.lat &&
            withinRadius(
              matchedJobsite?.addressPosition,
              shiftEntry?.punchCoordinates
            ).distanceInFeet;
          const duration = distanceFromJob && distanceFromJob / 3.5;

          const formDataEntry = entryObjectGenerator({
            sow,
            shift,
            reason,
            duration,
            punchDate,
            scheduleDay,
            shiftStatus,
            selectedTeam,
            scheduleMatch,
            employeeMatch,
            matchedJobsite,
            distanceFromJob,
            degId: rowToEdit?.degId,
            punchType: shiftEntry?.punchType,
            punchTime: fields?.[i ? "clockOut" : "clockIn"],
            rate: formRate
              ? formRate
              : !isNaN(Number(alteredRate))
              ? Number(alteredRate)
              : employeeMatch?.employeeRate,
          });
          const editedEntry = {
            ...formDataEntry,
            degId: rowToEdit?.degId,
            entryId: shiftEntry?.entryId,
            punchCoordinates:
              shiftEntry?.punchCoordinates || matchedJobsite?.addressPosition,
          };

          editedEntries.push(editedEntry);
          editActions.push({ curr: editedEntry, prev: shiftEntry });
        }

        // Add lunch entries
        for (let i = 0; i < ["lunchStart", "lunchEnd"].length; i++) {
          const field = ["lunchStart", "lunchEnd"][i];
          const punchType = SHIFT_PUNCH_TYPE[field];

          const newEntry = entryObjectGenerator({
            sow,
            shift,
            reason,
            punchDate,
            punchType,
            duration: 0,
            scheduleDay,
            shiftStatus,
            selectedTeam,
            scheduleMatch,
            employeeMatch,
            matchedJobsite,
            distanceFromJob: 0,
            degId: rowToEdit?.degId,
            punchTime: fields?.[field],
            rate: formRate
              ? formRate
              : !isNaN(Number(alteredRate))
              ? Number(alteredRate)
              : employeeMatch?.employeeRate,
          });

          newEntries.push(newEntry);
        }
      } else if (!lunchIncluded && shift?.lunchStart) {
        // When editing removes lunch time, Remove lunch entries
        for (let i = 0; i < entriesForShift.length; i++) {
          const shiftEntry = entriesForShift[i];

          if (i === 1 || i === 2) {
            // Remove lunch entries;
            if (
              shiftEntry?.punchType === "IL" ||
              shiftEntry?.punchType === "OL"
            ) {
              removedEntries.push(shiftEntry);
              continue;
            }
          }

          const distanceFromJob =
            !!matchedJobsite?.addressPosition?.lat &&
            withinRadius(
              matchedJobsite?.addressPosition,
              shiftEntry?.punchCoordinates
            ).distanceInFeet;
          const duration = distanceFromJob && distanceFromJob / 3.5;

          const formDataEntry = entryObjectGenerator({
            sow,
            shift,
            reason,
            duration,
            punchDate,
            scheduleDay,
            shiftStatus,
            selectedTeam,
            scheduleMatch,
            employeeMatch,
            matchedJobsite,
            distanceFromJob,
            degId: rowToEdit?.degId,
            punchType: shiftEntry?.punchType,
            punchTime: fields?.[i ? "clockOut" : "clockIn"],
            rate: formRate
              ? formRate
              : !isNaN(Number(alteredRate))
              ? Number(alteredRate)
              : employeeMatch?.employeeRate,
          });
          const editedEntry = {
            ...formDataEntry,
            degId: shiftEntry?.degId,
            entryId: shiftEntry?.entryId,
            punchCoordinates:
              shiftEntry?.punchCoordinates || matchedJobsite?.addressPosition,
          };

          editedEntries.push(editedEntry);
          editActions.push({ curr: editedEntry, prev: shiftEntry });
        }
      } else {
        // When lunch time is not changed
        for (let i = 0; i < entriesForShift.length; i++) {
          const shiftEntry = entriesForShift[i];

          const distanceFromJob =
            !!matchedJobsite?.addressPosition?.lat &&
            withinRadius(
              matchedJobsite?.addressPosition,
              shiftEntry?.punchCoordinates
            ).distanceInFeet;
          const duration = distanceFromJob && distanceFromJob / 3.5;

          const formDataEntry = entryObjectGenerator({
            sow,
            shift,
            reason,
            duration,
            punchDate,
            scheduleDay,
            shiftStatus,
            selectedTeam,
            scheduleMatch,
            employeeMatch,
            matchedJobsite,
            distanceFromJob,
            degId: rowToEdit?.degId,
            punchType: shiftEntry?.punchType,
            punchTime: fields?.[fieldsToIterate[i]],
            rate: formRate
              ? formRate
              : !isNaN(Number(alteredRate))
              ? Number(alteredRate)
              : employeeMatch?.employeeRate,
          });
          const editedEntry = {
            ...formDataEntry,
            degId: shiftEntry?.degId,
            entryId: shiftEntry?.entryId,
            punchCoordinates:
              shiftEntry?.punchCoordinates || matchedJobsite?.addressPosition,
          };

          editedEntries.push(editedEntry);
          editActions.push({ curr: editedEntry, prev: shiftEntry });
        }
      }
    }
  }

  if (shiftType === "HR Shift") {
    const formHREntry = entryObjectGenerator({
      sow: [],
      shift,
      reason,
      duration: 0,
      punchDate,
      scheduleDay,
      shiftStatus,
      selectedTeam,
      scheduleMatch,
      employeeMatch,
      matchedJobsite,
      distanceFromJob: 0,
      degId: rowToEdit?.degId,
      punchType: "HR",
      punchTime: fields?.["clockIn"],
      rate: formRate
        ? formRate
        : !isNaN(Number(alteredRate))
        ? Number(alteredRate)
        : employeeMatch?.employeeRate,
    });
    // When Transforming a Regular Shift to a HR Shift
    if (!shift?.hrShift) {
      for (let i = 0; i < entriesForShift.length; i++) {
        const regularEntry = entriesForShift[i];
        if (!i) {
          // the first entry will be edited as HR
          const editedHrEntry = {
            ...formHREntry,
            degId: regularEntry?.degId,
            entryId: regularEntry?.entryId,
          };
          editedEntries.push(editedHrEntry);
          editActions.push({ curr: editedHrEntry, prev: regularEntry });
        } else {
          removedEntries.push(regularEntry);
        }
      }
    } else {
      // Normal case when editing a HR Shift
      const editedEntry = {
        ...formHREntry,
        degId: rowToEdit?.degId,
        entryId: entriesForShift?.[0]?.entryId,
      };
      editedEntries.push(editedEntry);
      editActions.push({ curr: editedEntry, prev: entriesForShift?.[0] });
    }
  }

  if (changeRateForEmployee) {
    const newCrewsList = crews.map((crew) => {
      if (crew?.crewId === employeeMatch?.crewId) {
        return Object.assign(crew, { employeeRate: formRate });
      } else {
        return crew;
      }
    });
    API.put("crews", `/crews/${employeeMatch?.crewId}`, {
      body: {
        employeeRate: formRate,
      },
    })
      .then(() => setCrews(newCrewsList))
      .catch((err) => console.log("changing employee rate Error: ", err));
  }

  const entriesToUpdate = editedEntries.map((el) => {
    const { duration, ...rest } = el;
    return rest;
  });

  message.loading({
    duration: 0,
    key: "onShiftEdit",
    content: "Editing Shift...",
  });

  Promise.all(
    [
      !!newEntries?.length &&
        postEntries({
          entries: newEntries.map((el) => ({ ...el, degId: rowToEdit?.degId })),
        }),
      !!removedEntries?.length && removeEntries({ entries: removedEntries }),
      !!entriesToUpdate?.length &&
        updateEntries({
          entries: entriesToUpdate.map((el) => ({
            ...el,
            degId: rowToEdit?.degId,
          })),
        }),
    ].filter(Boolean)
  )
    .then(() => {
      setRowData((prev) =>
        newEntries.concat(
          prev.flatMap((el) => {
            const removedIndex = removedEntries.findIndex(
              (rmv) => rmv?.entryId === el?.entryId
            );
            if (removedIndex > -1) {
              return [];
            }
            const editedIndex = editedEntries.findIndex(
              (edt) => edt?.entryId === el?.entryId
            );
            if (editedIndex > -1) {
              return editedEntries[editedIndex];
            }
            return el;
          })
        )
      );
      addActionToRegister({
        type: "massChanges",
        massChangeActions: {
          newEntries,
          removedEntries,
          editedEntries: editActions,
        },
      });
      message.success({
        duration: 3,
        key: "onShiftEdit",
        content: "Shift edited successfully",
      });
      onCancel();
    })
    .catch((err) => {
      message.error({
        duration: 3,
        key: "onShiftEdit",
        content: "There was a problem editing this shift",
      });
      console.log("Error updating shift: ", err);
    });
}

// #region Shift Create
export async function onShiftCreate({
  form,
  crews,
  setCrews,
  jobsites,
  onCancel,
  formRate,
  crewTeams,
  rowToEdit,
  setRowData,
  postEntries,
  scheduleMatch,
  setExistingShifts,
  addActionToRegister,
  employeesHoursPerDay,
  checkForExisting = true,
  setExistingShiftsWarning,
  changeRateForEmployee = false,
}) {
  message.loading({
    duration: 0,
    key: "creatingShifts",
    content: "Creating Shifts...",
  });
  const tmpObj = form.getFieldsValue();
  const formObj = {
    services: tmpObj?.sow,
    reason: tmpObj?.reason,
    shiftType: tmpObj?.shiftType,
    selectedTime: tmpObj?.clockIn,
    launchEndTime: tmpObj?.lunchEnd,
    selectedDate: tmpObj?.punchDate,
    selectedJobsite: tmpObj?.jobsite,
    shiftStatus: tmpObj?.shiftStatus,
    selectedEndTime: tmpObj?.clockOut,
    launchStartTime: tmpObj?.lunchStart,
    selectedMembers: tmpObj?.selectedMembers,
  };

  await createShiftsForCrews({
    crews,
    jobsites,
    crewTeams,
    scheduleMatch,
    form: formRate
      ? Object.assign(formObj, { employeeRate: formRate })
      : formObj,
    launch: tmpObj?.lunchIncluded,
  })
    .then(({ newShifts }) => {
      let listOfExistingShifts = [];
      if (checkForExisting) {
        newShifts.forEach((data) => {
          const datesPerEmployee = Object.keys(
            employeesHoursPerDay?.[data?.employeeId] || {}
          ).filter(
            (date) => date === dayjsNY(data?.punchDate).format("MM/DD/YYYY")
          );
          if (datesPerEmployee?.length) {
            datesPerEmployee.forEach((date) => {
              const shift = employeesHoursPerDay?.[data?.employeeId]?.[date];
              if (
                dayjsNY(shift?.firstClockIn).format("hh A") ===
                dayjsNY(data?.punchTime).format("hh A")
              ) {
                const scheduleDay = (scheduleMatch?.scheduleDays || []).find(
                  (el) =>
                    dayjsNY(el?.startDate).format(DEG_DATE_FORMAT) ===
                    dayjsNY(shift?.punchDate).format(DEG_DATE_FORMAT)
                );

                Object.assign(shift, {
                  employeeId: data?.employeeId,
                  scheduleDayId: scheduleDay?.id,
                  scheduleId: scheduleMatch?.scheduleId,
                  employeeFullName: data?.employeeFullName,
                  jobsiteId: data?.jobsiteMatch?.jobsiteId,
                  jobsiteMatch: data?.jobsiteMatch?.jobName,
                  scheduleAddress: scheduleMatch?.scheduleAddress,
                });
                listOfExistingShifts.push(shift);
              }
            });
          }
        });
      }
      if (changeRateForEmployee) {
        const newCrewsList = crews.map((crew) => {
          if (formObj?.includes(crew?.crewId)) {
            return Object.assign(crew, { employeeRate: formRate });
          } else {
            return crew;
          }
        });
        formObj?.selectedMembers.forEach((employeeId) =>
          API.put("crews", `/crews/${employeeId}`, {
            body: {
              employeeRate: formRate,
            },
          }).catch((err) => console.log("changing employee rate Error: ", err))
        );
        setCrews(newCrewsList);
      }
      if (listOfExistingShifts?.length) {
        setExistingShifts(listOfExistingShifts);
        setExistingShiftsWarning(true);
        message.destroy();
        return;
      } else {
        const entriesToPost = newShifts.map((el) => ({
          ...el,
          degId: rowToEdit?.degId,
        }));
        postEntries({
          entries: entriesToPost,
          onSuccessCallback: () => {
            setRowData((prev) => entriesToPost.concat(prev));
            addActionToRegister({
              type: "new",
              newActions: entriesToPost,
            });
            message.success({
              duration: 3,
              key: "creatingShifts",
              content: "Shifts Created Successfully!",
            });
            onCancel();
          },
          onErrorCallback: (err) => {
            message.error({
              duration: 3,
              key: "creatingShifts",
              content: "Something went wrong while creating the shifts!",
            });
            console.log("Error creating shifts: ", err);
          },
        });
      }
    })
    .catch((err) => {
      message.error({
        duration: 3,
        key: "creatingShifts",
        content: "Something went wrong while creating the shifts!",
      });
      console.log("Error creating shifts: ", err);
    });
}

// #region Date Range Shift Create
export async function onDateRangeShiftCreate({
  form,
  crews,
  setCrews,
  jobsites,
  onCancel,
  formRate,
  crewTeams,
  rowToEdit,
  setRowData,
  postEntries,
  scheduleMatch,
  setExistingShifts,
  employeesHoursPerDay,
  checkForExisting = true,
  setExistingShiftsWarning,
  changeRateForEmployee = false,
}) {
  message.loading({
    duration: 0,
    key: "creatingShifts",
    content: "Creating Shifts...",
  });
  const tmpObj = form.getFieldsValue();
  const dateRange = tmpObj?.punchDateRange;
  const dates = getDateRange(dateRange?.[0], dateRange?.[1]);

  let newShiftsCreated = [];

  for (const selectedDate of dates) {
    const formObj = {
      selectedDate,
      services: tmpObj?.sow,
      reason: tmpObj?.reason,
      shiftType: tmpObj?.shiftType,
      selectedTime: tmpObj?.clockIn,
      launchEndTime: tmpObj?.lunchEnd,
      shiftStatus: tmpObj?.shiftStatus,
      selectedJobsite: tmpObj?.jobsite,
      selectedEndTime: tmpObj?.clockOut,
      launchStartTime: tmpObj?.lunchStart,
      selectedMembers: tmpObj?.selectedMembers,
    };

    if (
      formObj?.selectedTime?.get("date") < formObj?.selectedEndTime?.get("date")
    ) {
      Object.assign(formObj, {
        selectedEndTime: setHourMinute(
          selectedDate.add(1, "d"),
          formObj?.selectedEndTime
        ),
      });
    } else {
      Object.assign(formObj, {
        selectedEndTime: setHourMinute(selectedDate, formObj?.selectedEndTime),
      });
    }
    if (
      formObj?.launchStartTime?.get("date") <
      formObj?.launchEndTime?.get("date")
    ) {
      Object.assign(formObj, {
        launchEndTime: setHourMinute(
          selectedDate.add(1, "d"),
          formObj?.launchEndTime
        ),
      });
    } else {
      Object.assign(formObj, {
        launchEndTime: setHourMinute(selectedDate, formObj?.launchEndTime),
      });
    }
    Object.assign(formObj, {
      selectedTime: setHourMinute(selectedDate, formObj?.selectedTime),
    });
    Object.assign(formObj, {
      launchStartTime: setHourMinute(selectedDate, formObj?.launchStartTime),
    });
    await createShiftsForCrews({
      crews,
      jobsites,
      crewTeams,
      form: formRate
        ? Object.assign(formObj, { employeeRate: formRate })
        : formObj,
      launch: tmpObj?.lunchIncluded,
    })
      .then(({ newShifts }) => {
        newShiftsCreated = newShiftsCreated.concat(
          newShifts.map((el) => {
            const scheduleDay = (scheduleMatch?.scheduleDays || []).find(
              (day) =>
                dayjsNY(day?.startDate).format(DEG_DATE_FORMAT) ===
                dayjsNY(el?.punchDate).format(DEG_DATE_FORMAT)
            );
            return {
              ...el,
              degId: rowToEdit?.degId,
              scheduleDayId: scheduleDay?.id,
              scheduleId: scheduleMatch?.scheduleId,
              scheduleAddress: scheduleMatch?.scheduleAddress,
            };
          })
        );
      })
      .catch((err) => {
        message.error({
          content: "Something went wrong while creating the shifts!",
          key: "creatingShifts",
        });
        console.log("Error creating shifts: ", err);
      });
  }

  let listOfExistingShifts = [];
  if (checkForExisting) {
    newShiftsCreated.forEach((data) => {
      const datesPerEmployee = Object.keys(
        employeesHoursPerDay?.[data?.employeeId] || {}
      ).filter(
        (date) => date === dayjsNY(data?.punchDate).format("MM/DD/YYYY")
      );

      if (datesPerEmployee?.length) {
        datesPerEmployee.forEach((date) => {
          const shift = employeesHoursPerDay?.[data?.employeeId]?.[date];
          if (
            dayjsNY(shift?.firstClockIn).format("hh A") ===
            dayjsNY(data?.punchTime).format("hh A")
          ) {
            Object.assign(shift, {
              employeeId: data?.employeeId,
              employeeFullName: data?.employeeFullName,
              jobsiteId: data?.jobsiteMatch?.jobsiteId,
              jobsiteMatch: data?.jobsiteMatch?.jobName,
            });
            listOfExistingShifts.push(shift);
          }
        });
      }
    });
  }

  if (changeRateForEmployee) {
    const newCrewsList = crews.map((crew) => {
      if (tmpObj?.includes(crew?.crewId)) {
        return Object.assign(crew, { employeeRate: formRate });
      } else {
        return crew;
      }
    });
    tmpObj?.selectedMembers.forEach((employeeId) =>
      API.put("crews", `/crews/${employeeId}`, {
        body: {
          employeeRate: formRate,
        },
      }).catch((err) => console.log("changing employee rate Error: ", err))
    );
    setCrews(newCrewsList);
  }
  if (listOfExistingShifts?.length) {
    setExistingShifts(listOfExistingShifts);
  }
  if (listOfExistingShifts?.length) {
    setExistingShiftsWarning(true);
    return;
  } else {
    postEntries({
      entries: newShiftsCreated,
      onSuccessCallback: () => {
        setRowData((prev) => newShiftsCreated.concat(prev));

        addActionToRegister({
          type: "new",
          newActions: newShiftsCreated,
        });

        message.success({
          duration: 3,
          key: "creatingShifts",
          content: "Shifts Created Successfully!",
        });
        onCancel();
      },
      onErrorCallback: (err) => {
        console.log("Error creating shifts in date range: ", err);
        message.error({
          duration: 3,
          key: "creatingShifts",
          content: "There was a problem creating shifts",
        });
      },
    });
  }
}

// #region Mass Entry
export function onMassEntry({
  form,
  crews,
  rowData,
  jobsites,
  onCancel,
  crewTeams,
  rowToEdit,
  setRowData,
  postEntries,
  updateEntries,
  removeEntries,
  scheduleMatch,
  shiftsGridApi,
  addActionToRegister,
}) {
  message?.loading({
    duration: 0,
    key: "onMassEntry",
    content: "Mass updating...",
  });

  // Get selected shifts
  const selectedShifts = shiftsGridApi
    .getSelectedNodes()
    .map(({ data }) => data);

  const formObj = form.getFieldsValue(); // form fields data
  const employee = crews.find(
    ({ crewId }) => crewId === formObj?.selectedMembers
  );

  const teamMatch = findTeamForEmployee(
    crewTeams,
    employee?.crewId,
    employee?.employeeId
  );

  const selectedJobsite = jobsites.find(
    ({ jobsiteId }) => jobsiteId === formObj?.jobsite
  );

  const scheduleDay = (scheduleMatch?.scheduleDays || []).find((day) => {
    return (
      dayjsNY(day?.startDate).format(DEG_DATE_FORMAT) ===
      dayjsNY(formObj?.punchDate).startOf("D").format(DEG_DATE_FORMAT)
    );
  });

  const workActivities = ["ID", "OD"]; // Punch types for work hours
  const lunchActivities = ["IL", "OL"]; // Punch types for lunch hours

  let newActivities = []; // newly created entries
  let editedEntries = []; // edited entries
  let entriesToRemove = []; // removed entries

  // Object holding reference for each punchType activity
  const activitiesShift = {
    newActivityID: {
      punchType: "ID",
      sow: formObj?.sow,
      degId: rowToEdit?.degId,
      crewId: employee?.crewId,
      punchTime: formObj?.clockIn,
      company: employee?.accountName,
      scheduleDayId: scheduleDay?.id,
      employeeId: employee?.employeeId,
      crewTeamId: teamMatch?.crewTeamId,
      companyName: employee?.accountName,
      employeeRole: employee?.crewPosition,
      activityStatus: formObj?.shiftStatus,
      employeeFullName: employee?.crewName,
      crewTeamName: teamMatch?.crewTeamName,
      jobsiteId: selectedJobsite?.jobsiteId,
      scheduleId: scheduleMatch?.scheduleId,
      payrollType: selectedJobsite?.payrollType,
      punchLocation: selectedJobsite?.jobAddress,
      punchTimeStamp: formObj?.clockIn?.valueOf(),
      salaryType: employee?.salaryType || "Hourly",
      scheduleAddress: scheduleMatch?.scheduleAddress,
      employeeRate:
        selectedJobsite?.payrollType !== "Prevailing Wage"
          ? parseFloat(employee?.employeeRate || 0)
          : parseFloat(selectedJobsite?.rates?.[employee?.crewPosition] || 0),
      punchDate: formObj?.punchDate
        ? dayjsNY(formObj?.punchDate).startOf("D")
        : undefined,
      jobsiteMatch: selectedJobsite && {
        jobName: selectedJobsite?.jobName,
        services: selectedJobsite?.services,
        jobsiteId: selectedJobsite?.jobsiteId,
        jobAddress: selectedJobsite?.jobAddress,
        reimbursement: selectedJobsite?.reimbursement,
      },
    },
  };

  // Creating entries for each punch type updated with form data
  Object.assign(activitiesShift, {
    newActivityOD: Object.assign(
      { ...activitiesShift.newActivityID },
      {
        punchType: "OD",
        punchTime: formObj?.clockOut,
        punchTimeStamp: formObj?.clockOut?.valueOf(),
      }
    ),

    newActivityOL: Object.assign(
      { ...activitiesShift.newActivityID },
      {
        punchType: "OL",
        punchTime: formObj?.lunchStart,
        punchTimeStamp: formObj?.lunchStart?.valueOf(),
      }
    ),

    newActivityIL: Object.assign(
      { ...activitiesShift.newActivityID },
      {
        punchType: "IL",
        punchTime: formObj?.lunchEnd,
        punchTimeStamp: formObj?.lunchEnd?.valueOf(),
      }
    ),

    newActivityHR: Object.assign(
      { ...activitiesShift.newActivityID },
      {
        punchType: "HR",
        reason: formObj?.reason,
        punchTime: formObj?.clockIn,
      }
    ),
  });

  for (const shift of selectedShifts) {
    let entriesOfShift = rowData.filter(({ entryId, punchType }) => {
      if (
        formObj?.shiftType === "HR Shift" &&
        shift?.entries?.includes(entryId)
      ) {
        // entriesToRemove.push(entryId);
        if (punchType === "ID" || punchType === "HR") {
          return shift?.entries?.includes(entryId);
        }
      } else if (formObj?.shiftType === "Regular Shift") {
        return shift?.entries?.includes(entryId);
      }
    });

    // update each entry with form values
    for (const entry of entriesOfShift) {
      const employeeRate =
        selectedJobsite && selectedJobsite?.payrollType === "Prevailing Wage"
          ? selectedJobsite?.rates?.[
              employee?.crewPosition
                ? employee?.crewPosition
                : entry?.employeeRole
            ]
          : entry?.jobsiteMatch?.rates
          ? entry?.jobsiteMatch?.rates?.[
              employee?.crewPosition
                ? employee?.crewPosition
                : entry?.employeeRole
            ]
          : entry?.employeeRate;

      // Get duration and distance from jobsite for each ID entry
      if (
        entry?.punchType === "ID" &&
        formObj?.shiftType === "Regular Shift" &&
        !!selectedJobsite?.addressPosition?.lat
      ) {
        const distanceFromJob = withinRadius(
          selectedJobsite.addressPosition,
          entry.punchCoordinates
        ).distanceInFeet;
        const duration = distanceFromJob / 3.5;
        Object.assign(entry, { distanceFromJob, duration });
      }

      // when entry is HR and we want to transform it to a regular shift we iterate workActivities and create 2 new activities ID and OD
      if (entry?.punchType === "HR" && formObj?.shiftType === "Regular Shift") {
        for (const punchType of workActivities) {
          let activityType = Object.assign(
            // structuredClone(activitiesShift[`newActivity${punchType}`]),
            { ...activitiesShift?.[`newActivity${punchType}`] },
            {
              punchTime: activitiesShift[`newActivity${punchType}`]?.punchTime,
              punchDate: activitiesShift[`newActivity${punchType}`]?.punchDate,
              punchTimeStamp:
                activitiesShift[`newActivity${punchType}`]?.punchTimeStamp,
            }
          );

          for (const key of Object.keys(activityType)) {
            if (activityType[key] !== 0 && !activityType[key]) {
              delete activityType[key];
            }
          }

          const activityDate = activityType?.punchDate
            ? activityType?.punchDate
            : entry?.punchDate;

          const newEntry = Object.assign(
            { ...entry },
            {
              ...activityType,
              activityStatus: activityType?.activityStatus
                ? activityType?.activityStatus
                : entry.activityStatus,
              entryId: punchType === "OD" ? uuid() : entry?.entryId,
              punchTimeStamp: activityType?.punchTime
                ? setHourMinute(activityDate, activityType?.punchTime).valueOf()
                : entry.punchTimeStamp,
              employeeRate: employeeRate
                ? employeeRate
                : activityType?.employeeRate
                ? activityType?.employeeRate
                : entry?.employeeRate,
              punchDate: activityDate,
              punchTime:
                punchType === "OD"
                  ? activityType?.punchTime
                    ? setHourMinute(activityDate, activityType?.punchTime)
                    : setHourMinute(activityDate, entry?.punchTime.add(8, "h"))
                  : activityType?.punchTime
                  ? setHourMinute(activityDate, activityType?.punchTime)
                  : setHourMinute(activityDate, entry?.punchTime),
            }
          );
          // assign punchDate and punchTime on push to keep them as dayjsObject format not string
          newActivities.push(newEntry);
        }
      }
      //  else if (entry?.punchType === "HR" && formObj?.shiftType === "HR Shift") {

      // }
      else {
        // else we update the values of entries from the form excluding undefined values

        let clockInTime = // Start time of the shift
          activitiesShift[`newActivityID`]?.punchTime ||
          entriesOfShift?.find(({ punchType }) => punchType === "ID")
            ?.punchTime;

        let tmpPunchDate = // Start date of the shift
          formObj?.shiftType === "Regular Shift"
            ? activitiesShift[`newActivityID`]?.punchDate ||
              dayjsNY(
                entriesOfShift?.find(({ punchType }) => punchType === "ID")
                  ?.punchDate
              )
            : activitiesShift[`newActivityHR`]?.punchDate ||
              dayjsNY(entry?.punchDate);

        const activityType =
          formObj?.shiftType === "Regular Shift"
            ? // Object.assign(
              {
                ...activitiesShift[`newActivity${entry?.punchType}`],
                punchTime:
                  activitiesShift[`newActivity${entry?.punchType}`]?.punchTime,
                punchDate:
                  activitiesShift[`newActivity${entry?.punchType}`]?.punchDate,
                punchTimeStamp:
                  activitiesShift[`newActivity${entry?.punchType}`]
                    ?.punchTimeStamp,
              }
            : // ,
              //   {
              //     punchTime:
              //       activitiesShift[`newActivity${entry?.punchType}`]
              //         ?.punchTime,
              //     punchDate:
              //       activitiesShift[`newActivity${entry?.punchType}`]
              //         ?.punchDate,
              //     punchTimeStamp:
              //       activitiesShift[`newActivity${entry?.punchType}`]
              //         ?.punchTimeStamp,
              //   }
              // )
              Object.assign(
                { ...activitiesShift[`newActivityHR`] },
                {
                  punchTime:
                    activitiesShift[`newActivityHR`]?.punchTime ||
                    entry?.punchTime,
                  punchDate:
                    activitiesShift[`newActivityHR`]?.punchDate ||
                    entry?.punchDate,
                  punchTimeStamp:
                    activitiesShift[`newActivityHR`]?.punchTimeStamp ||
                    entry?.punchTimeStamp,
                }
              );

        for (const key of Object.keys(activityType)) {
          if (activityType[key] !== 0 && !activityType[key]) {
            delete activityType[key];
          }
        }

        const newEntry = Object.assign(
          { ...entry },
          {
            ...activityType,
            activityStatus: activityType?.activityStatus
              ? activityType?.activityStatus
              : entry.activityStatus,
            punchTimeStamp: activityType?.punchTime
              ? setHourMinute(tmpPunchDate, activityType?.punchTime).valueOf()
              : setHourMinute(tmpPunchDate, entry?.punchTime).valueOf(),
            employeeRate: employeeRate
              ? employeeRate
              : activityType?.employeeRate
              ? activityType?.employeeRate
              : entry?.employeeRate,
            punchDate: tmpPunchDate,
            punchTime: activityType?.punchTime
              ? setHourMinute(tmpPunchDate, activityType?.punchTime)
              : setHourMinute(tmpPunchDate, entry?.punchTime),
          }
        );

        const newEntryPunchTime = activityType?.punchTime
          ? activityType?.punchDate
            ? setHourMinute(activityType?.punchDate, activityType?.punchTime)
            : activityType?.punchTime
          : entry?.punchTime;

        // let todayDate = dayjsNY();
        // Check if other activities have passed to the next day
        if (
          activityType?.punchType !== "ID" &&
          activityType?.punchType !== "HR"
        ) {
          if (getDateOffset(clockInTime, newEntryPunchTime) > 0) {
            Object.assign(newEntry, {
              punchTime: setHourMinute(
                tmpPunchDate.add(1, "d"),
                newEntry.punchTime
              ),
              punchDate: tmpPunchDate.add(1, "d"),
              punchTimeStamp: setHourMinute(
                tmpPunchDate.add(1, "d"),
                newEntry.punchTime
              ).valueOf(),
            });
          }
        }
        if (
          // fix break time when changing only the shift clock in and clock out
          entriesOfShift?.length === 4 &&
          !formObj?.lunchIn &&
          !formObj?.lunchOut &&
          (entry?.punchType === "OL" || entry?.punchType === "IL") &&
          (!!formObj?.clockIn || !!formObj?.clockOut)
        ) {
          const oldClockIn = entriesOfShift.find(
            ({ punchType }) => punchType === "ID"
          )?.punchTime;
          const oldLunchStart = entriesOfShift.find(
            ({ punchType }) => punchType === "OL"
          ).punchTime;
          const oldLunchEnd = entriesOfShift.find(
            ({ punchType }) => punchType === "IL"
          ).punchTime;
          const diffFromClockIn = findTimeDiff(
            oldClockIn,
            oldLunchStart,
            "minute"
          );

          const breakLength = findTimeDiff(
            oldLunchStart,
            oldLunchEnd,
            "minute"
          );

          const newLunchIn = setHourMinute(
            tmpPunchDate,
            clockInTime.add(diffFromClockIn, "minutes")
          );

          const newLunchOut = newLunchIn.add(breakLength, "minutes");

          if (entry.punchType === "OL") {
            Object.assign(newEntry, {
              punchTime: newLunchIn,
              punchDate: newLunchIn.startOf("d"),
              punchTimeStamp: newLunchIn.valueOf(),
            });
          } else if (entry.punchType === "IL") {
            Object.assign(newEntry, {
              punchTime: newLunchOut,
              punchDate: newLunchOut.startOf("d"),
              punchTimeStamp: newLunchOut.valueOf(),
            });
          }
        }
        // assign punchDate and punchTime on push to keep them as dayjsObject format not string
        newActivities.push(newEntry);
      }
    }
    // if entry does not have break create break from form values
    if (
      entriesOfShift?.length <= 2 &&
      formObj?.lunchIncluded &&
      formObj?.shiftType === "Regular Shift"
    ) {
      const entry = { ...entriesOfShift[0] };
      const employeeRate =
        selectedJobsite && selectedJobsite?.payrollType !== "Prevailing Wage"
          ? selectedJobsite?.rates?.[
              employee?.crewPosition
                ? employee?.crewPosition
                : entry?.employeeRole
            ]
          : entry?.jobsiteMatch?.rates
          ? entry?.jobsiteMatch?.rates?.[
              employee?.crewPosition
                ? employee?.crewPosition
                : entry?.employeeRole
            ]
          : entry?.employeeRate;

      lunchActivities.forEach((punchType) => {
        let activityType = Object.assign(
          { ...activitiesShift[`newActivity${punchType}`] },
          {
            punchDate: activitiesShift[`newActivity${punchType}`]?.punchDate,
            punchTime: activitiesShift[`newActivity${punchType}`]?.punchTime,
            punchTimeStamp:
              activitiesShift[`newActivity${punchType}`]?.punchTimeStamp,
          }
        );

        for (const key of Object.keys(activityType)) {
          if (activityType[key] !== 0 && !activityType[key]) {
            delete activityType[key];
          }
        }

        const isILNextDay =
          activitiesShift[`newActivityOL`]?.punchTime &&
          activitiesShift[`newActivityIL`]?.punchTime &&
          getDateOffset(
            activitiesShift[`newActivityOL`]?.punchTime,
            activitiesShift[`newActivityIL`]?.punchTime
          ) > 0;

        const activityDate = activityType?.punchDate
          ? activityType?.punchDate
          : entry?.punchDate;

        const newEntry = Object.assign(
          { ...entry },
          {
            ...activityType,
            entryId: uuid(),
            activityStatus: activityType?.activityStatus
              ? activityType?.activityStatus
              : entry.activityStatus,
            punchTimeStamp: activityType?.punchTime
              ? setHourMinute(activityDate, activityType?.punchTime).valueOf()
              : entry.punchTimeStamp,
            employeeRate: employeeRate
              ? employeeRate
              : activityType?.employeeRate
              ? activityType?.employeeRate
              : entry?.employeeRate,
            punchDate: activityDate,
            punchTime: activityType?.punchTime
              ? setHourMinute(activityDate, activityType?.punchTime)
              : setHourMinute(activityDate, entry?.punchTime),
          }
        );

        // When there is an overnight break we add one day to the time
        if (isILNextDay && punchType === "IL") {
          Object.assign(newEntry, {
            punchDate: newEntry.punchTime.add(1, "day").startOf("d"),
            punchTime: newEntry.punchTime.add(1, "day"),
            punchTimeStamp: newEntry.punchTime.add(1, "day").valueOf(),
          });
        }
        // assign punchDate and punchTime on push to keep them as dayjsObject format not string
        newActivities.push(newEntry);
      });
    }
  }

  // update rowData with new entries and add new activities to rowData
  const newRowData = rowData
    .flatMap((activity) => {
      let activityIndex = newActivities.findIndex(
        ({ entryId }) => entryId === activity?.entryId
      );
      let newActivity = newActivities?.[activityIndex];
      if (newActivity) {
        newActivities = newActivities.filter(
          ({ entryId }) => entryId !== newActivity?.entryId
        );

        editedEntries.push(newActivity);
        return newActivity;
      } else if (!entriesToRemove.includes(activity?.entryId)) {
        return activity;
      } else {
        return [];
      }
    })
    .concat(newActivities);

  const removedRowData = (rowData || []).filter(({ entryId }) =>
    entriesToRemove?.includes(entryId)
  );

  // Remove duration key from API call to not cause Error 500
  const updateActionEntries = editedEntries.map((el) => {
    const { duration, ...rest } = el;
    return rest;
  });

  const editActions = editedEntries.map((el) => {
    const indx = rowData.findIndex((entry) => entry?.entryId === el?.entryId);
    return { curr: el, prev: rowData[indx] };
  });

  Promise.allSettled(
    [
      !!newActivities?.length && postEntries({ entries: newActivities }),
      !!updateActionEntries?.length &&
        updateEntries({ entries: updateActionEntries }),
      !!removedRowData?.length && removeEntries({ entries: removedRowData }),
    ].filter(Boolean)
  )
    .then(() => {
      setRowData(newRowData);

      addActionToRegister({
        type: "massChanges",
        massChangeActions: {
          newEntries: newActivities,
          editedEntries: editActions,
          removedEntries: removedRowData,
        },
      });

      message?.success({
        duration: 3,
        key: "onMassEntry",
        content: "Shifts updated successfully",
      });
      onCancel();
    })
    .catch((err) => {
      console.log("There was a problem updating shifts: ", err);
      message?.error({
        duration: 3,
        key: "onMassEntry",
        content: "There was a problem updating shifts",
      });
    });
}
