import { Select } from "antd";
import { DropDownArrow } from "../../../Fleet/components/InputComponent/assets/index";
import "./Dropdown.scss";
import SelectCustomOption from "../../../Fleet/fleet/modals/NewFleetModal/SelectCustomOption";
import { useState } from "react";

const FormItemDropdown = ({
  value,
  form,
  name,
  options = [],
  disabled,
  onChange,
  getTaxExept,
  placeholder = "",
  customOptions = [],
  isDarkMode,
  allowCustomDropdownOptions = false,
}) => {
  const [mergedOptions, setMergedOptions] = useState(
    allowCustomDropdownOptions ? null : options
  );
  let fileredCustomOptions = customOptions;

  if (customOptions.length > 1) {
    fileredCustomOptions = customOptions.filter(
      (option) => option.value !== value?.id
    );
  }

  const dropdownRender = allowCustomDropdownOptions
    ? (menu) => (
        <SelectCustomOption
          menu={menu}
          companies={mergedOptions}
          setCompanies={setMergedOptions}
          isInInformation={true}
        />
      )
    : null;

  return (
    <div className={`EditDropdown ${isDarkMode && "EditDropdownDark"}`}>
      <Select
        disabled={disabled}
        placeholder={placeholder}
        dropdownRender={dropdownRender}
        showSearch
        // popupClassName={`EditDropDownClassName ${isDarkMode && "darkDropDown"}`}  // commented this line cause it caused width issues in mobile view
        popupClassName={`${isDarkMode && "darkDropDown"}`}
        className="EditDropdownComponent"
        // popupMatchSelectWidth={false}
        popupMatchSelectWidth={false}
        value={
          customOptions.length !== 0
            ? value?.hasOwnProperty("email")
              ? value.email
              : value
            : value
        }
        suffixIcon={<DropDownArrow />}
        defaultValue={
          customOptions.length !== 0
            ? value?.hasOwnProperty("email")
              ? value.email
              : value
            : value
        }
        onChange={(e, data) => {
          form.setFieldsValue({
            [name]:
              customOptions.length !== 0
                ? value?.hasOwnProperty("email")
                  ? { email: data.label, id: e }
                  : [data]
                : e,
          });
          name === "taxExempt"
            ? getTaxExept(e)
            : onChange(
                customOptions.length !== 0
                  ? value?.hasOwnProperty("email")
                    ? { email: data.label, id: e }
                    : [data]
                  : e
              );
        }}
        {...(customOptions.length !== 0
          ? {
              options:
                name === "primaryContact"
                  ? fileredCustomOptions
                  : customOptions,
            }
          : {})}
      >
        {(mergedOptions || []).map((e, i) => {
          return typeof e === "object" ? (
            <Select.Option key={i} value={e?.value}>
              {e?.label}
            </Select.Option>
          ) : (
            <Select.Option key={i} value={e}>
              {e}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};
export default FormItemDropdown;
