export const data = (form, violationStatus, formFields) => {
  const isPaidByDisabled =
    parseInt(formFields?.fine_amount || 0) +
      parseInt(formFields?.interest_amount || 0) +
      parseInt(formFields?.penalty_amount || 0) -
      parseInt(formFields?.payment_amount || 0) -
      parseInt(formFields?.reduction_amount || 0) !==
    0;
  if (isPaidByDisabled) {
    form.setFieldValue("paidBy", undefined);
  } else if (!form.getFieldValue("paidBy")) {
    form.setFieldValue("paidBy", "Paid By Core/Nemo");
  }
  return [
    {
      label: "Fine Amount",
      formItemName: "fine_amount",
      type: "input",
      placeholder: "Fine Amount",
      disabled: true,
      typeNumber: true,
      style: { minWidth: 115, width: 200 },
      rules: [
        {
          validator: (_, value) =>
            !!value && parseInt(value) > 0
              ? parseInt(value) >=
                parseInt(form.getFieldValue("payment_amount") || 0) +
                  parseInt(form.getFieldValue("reduction_amount") || 0)
                ? Promise.resolve()
                : Promise.reject(
                    new Error(
                      "Value should be more than or equal to the amount paid"
                    )
                  )
              : Promise.reject(new Error("Please enter a valid value!")),
        },
      ],
    },
    {
      label: "Interest Amount",
      formItemName: "interest_amount",
      type: "input",
      placeholder: "Interest Amount",
      disabled: violationStatus === "Paid" ? true : false,
      typeNumber: true,
      style: { minWidth: 115, width: 200 },
      rules: [
        {
          validator: (_, value) =>
            !!value
              ? parseInt(value) >= 0
                ? Promise.resolve()
                : Promise.reject(new Error("Please enter a valid value!"))
              : Promise.resolve(),
        },
      ],
    },
    {
      label: "Payment Amount",
      formItemName: "payment_amount",
      placeholder: "Payment Amount",
      disabled: violationStatus === "Paid" ? true : false,
      type: "input",
      typeNumber: true,
      style: { minWidth: 115, width: 200 },
      rules: [
        {
          validator: (_, value) =>
            !!value
              ? parseInt(value) >= 0
                ? parseInt(value) <=
                  parseInt(form.getFieldValue("fine_amount") || 0) -
                    parseInt(form.getFieldValue("reduction_amount") || 0) +
                    parseInt(form.getFieldValue("interest_amount") || 0) +
                    parseInt(form.getFieldValue("penalty_amount") || 0)
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(
                        "Value should be less than or equal to liability amount"
                      )
                    )
                : Promise.reject(new Error("Please enter a valid value!"))
              : Promise.resolve(),
        },
      ],
    },
    {
      label: "Penalty Amount",
      formItemName: "penalty_amount",
      placeholder: "Penalty Amount",
      disabled: violationStatus === "Paid" ? true : false,
      type: "input",
      typeNumber: true,
      style: { minWidth: 115, width: 200 },
      rules: [
        {
          validator: (_, value) =>
            !!value
              ? parseInt(value) >= 0
                ? Promise.resolve()
                : Promise.reject(new Error("Please enter a valid value!"))
              : Promise.resolve(),
        },
      ],
    },
    {
      label: "Reduction Amount",
      formItemName: "reduction_amount",
      placeholder: "Reduction Amount",
      disabled: violationStatus === "Paid" ? true : false,
      type: "input",
      typeNumber: true,
      style: { minWidth: 115, width: 200 },
      rules: [
        {
          validator: (_, value) =>
            !!value
              ? parseInt(value) >= 0
                ? parseInt(value) <=
                  parseInt(form.getFieldValue("fine_amount") || 0) +
                    parseInt(form.getFieldValue("interest_amount") || 0) +
                    parseInt(form.getFieldValue("penalty_amount") || 0) -
                    parseInt(form.getFieldValue("payment_amount") || 0)
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(
                        "Value should be less than or equal to liability amount"
                      )
                    )
                : Promise.reject(new Error("Please enter a valid value!"))
              : Promise.resolve(),
        },
      ],
    },
    {
      label: "Who Paid for the violation?",
      formItemName: "paidBy",
      disabled: isPaidByDisabled,
      type: "radio",
      buttonStyle: "solid",
      defaultValue: !isPaidByDisabled ? "Paid By Core/Nemo" : undefined,
      options: ["Paid By Core/Nemo", "Paid By Driver"],
    },
  ];
};
