import {
  getDriverStatus,
  getFleetExpenses,
  getServicesOfRental,
  getFleetWorkOrders,
  getChargesOverview,
  getSchedulingServices,
  getCreditMemoServices,
  getEstimationServices,
  getFleetRepairWorkOrders,
  modifyAndSortApplications,
  getDispatchesByActivities,
  getSchedulingTotalProgress,
  getTakeOffServicesAndCompare,
  getSOVComparisonWithAccounting,
  groupAndInspectionsByActivityId,
  getRentalComparisonWithAccounting,
  getTakeOffComparisonWithAccounting,
  getSchedulingTotalProgressWithCharges,
  getForecastingRentalsPerService,
  generateNotInvoicedPaid,
  getBills,
  getIds,
  projectsOverview,
  retrieveInvoices,
  programFieldsParameters,
  codeInvitations,
  allUsers,
  geoFencesActiveActivities,
  fleetDocuments,
  getAccountingComparisonPerProject,
  getSalesReportApprovedEstimates,
  getDriverStatistics,
  opportunityDetails,
  projectDetails,
  retrieveInspections,
  getHospitalsNearby,
  getProjects,
  getAllLogs,
} from "./ActiveReportJsUtils";
import { filterTables } from "./filterTables";
import { filteredCategoriesWithGeofence } from "../components/pages/Settings/settingsComponents/GeofenceConfigurations/AllProgramGeofences/utils/categoriesWithGeofence";
import { fetchNotesAndFormatData } from "./fetchNotesAndFormatData";
import { fetchAllData, fetchData } from "./ApiMethods";
import { extractUsedKeysFromReportObj } from "./ActiveReportJsUtils/helpers/extractUsedKeysFromReportObj";

const doNotApplyRecordId = (dataSourceName) => {
  const dataSourcesWithoutRecordId = ["serviceDefinitions"];

  return dataSourcesWithoutRecordId.includes(dataSourceName);
};

/**
 * Format notes array
 *
 * This function takes an array of notes and formats it so that it can be used in the UI.
 * It adds the createdAt property to each note and formats the date to MM/DD/YYYY hh:mm A.
 * It also recursively formats any replies in the notes array.
 *
 * @param {Array} notes - an array of notes
 * @returns {Array} - the formatted array of notes
 */

export const dataSourceReportsFetch = async (
  { dataSourceName, reportObj, currSampleObjKeys = [] },
  recordId
) => {
  let result = [];

  const { keysToInclude, reportUsedKeys } = extractUsedKeysFromReportObj({
    reportObj,
    currSampleObjKeys,
  });

  // console.log(`===============${dataSourceName}===============`);

  // console.log({
  //   currSampleObjKeys,
  //   keysToInclude,
  //   reportUsedKeys,
  // });

  try {
    switch (dataSourceName) {
      case "userConfiguration":
        result = await fetchData({ endpoint: "userConfiguration" }).then(
          (r) => [r]
        );
        break;
      case "projectsIds":
        result = await getIds("projects");
        break;
      case "notes":
        result = await fetchNotesAndFormatData(recordId);
        break;
      case "accountsIds":
        result = await getIds("accounts");
        break;
      case "estimationsIds":
        result = await getIds("estimations");
        break;
      case "opportunitiesIds":
        result = await getIds("opportunities");
        break;
      case "invoicesIds":
        result = await getIds("invoices");
        break;
      case "permitdrawings":
        result = await fetchAllData({
          endpoint: "permitDrawings",
          resultId: "permitId",
        });

        break;
      case "fleets":
        result = await fetchAllData({ endpoint: "fleet", resultId: "fleetId" });

        break;
      case "billedServices":
        result = await generateNotInvoicedPaid();

        break;
      case "editLogs":
        result = await getAllLogs({ keysToInclude });

        break;
      case "activity":
        result = await fetchAllData({
          endpoint: "fleetActivity",
          resultId: "activityId",
        });

        break;
      case "violations":
        result = await fetchAllData({
          endpoint: "fleetViolations",
          resultId: "violationId",
        });

        break;
      case "dispatching":
        result = await fetchAllData({
          endpoint: "formRequests",
          resultId: "formRequestId",
        });

        break;
      case "approvals":
        result = await fetchAllData({
          endpoint: "formRequests",
          resultId: "formRequestId",
        });

        break;
      case "safetyPropertyDamage":
        result = recordId
          ? await fetchData({
              endpoint: "safety",
              query: `safety/${recordId}`,
            }).then((res) => [res])
          : await filterTables(
              "safety",
              "safetyApplicationCategory",
              "Property Damage"
            );

        break;
      case "safetyVehicleDamage":
        result = await filterTables(
          "safety",
          "safetyApplicationCategory",
          "Vehicle Damage"
        );

        break;
      case "safetyPersonalInjury":
        result = await filterTables(
          "safety",
          "safetyApplicationCategory",
          "Personal Injury"
        );

        break;
      case "safetyOtherTradeIncident":
        result = await filterTables(
          "safety",
          "safetyApplicationCategory",
          "Other Trade Incident"
        );

        break;
      case "incidentsPropertyDamage":
        result = await filterTables(
          "incidents",
          "incidentCategory",
          "Property Damage"
        );
        break;
      case "incidentsVehicleDamage":
        result = await filterTables(
          "incidents",
          "incidentCategory",
          "Vehicle Damage"
        );

        break;
      case "incidentsPersonalInjury":
        result = await filterTables(
          "incidents",
          "incidentCategory",
          "Personal Injury"
        );

        break;
      case "incidentsOtherTradeIncident":
        result = await filterTables(
          "incidents",
          "incidentCategory",
          "Other Trade Incident"
        );

        break;
      case "maintenance":
        result = await fetchAllData({
          endpoint: "fleetMaintenance",
          resultId: "maintenanceId",
        });

        break;
      case "tasksManager":
        result = await fetchAllData({
          endpoint: "tasksManagement",
          resultId: "taskId",
          otherStringParams: {
            keysToInclude: JSON.stringify([
              ...new Set([...keysToInclude, ...["taskId"]]),
            ]),
          },
        });

        break;
      case "inspectionsView/:id":
        result = (
          await fetchAllData({
            endpoint: "inspections",
            resultId: "inspectionId",
          })
        ).map((el) => ({
          ...el,
          serviceLabels: el.inspectionReport
            .map((el) => el.serviceType)
            .join(", "),
        }));

        //is same with "inspectionsView/:id"
        break;
      case "urgentCaresNearby":
        {
          result = await getHospitalsNearby(recordId);
        }
        break;
      case "inspections": {
        const inspections = await fetchAllData({
          endpoint: "inspections",
          resultId: "inspectionId",
        });

        result =
          inspections?.map((inspection) => ({
            ...inspection,
            serviceLabels: (inspection?.inspectionReport || [])
              ?.map((report) => report?.serviceType || "")
              ?.join(", "),
          })) || [];

        break;
      }
      case "projectOverview":
        result = await projectsOverview();

        break;
      case "invoicesOpenBalance":
        result = await retrieveInvoices();
        break;
      case "invoices":
        result = await fetchAllData({
          endpoint: "invoices",
          resultId: "invoiceId",
          otherStringParams: {
            keysToInclude: JSON.stringify([
              ...new Set([...keysToInclude, "invoiceId"]),
            ]),
          },
        });
        break;
      case "payments": {
        result = await fetchAllData({
          endpoint: "payments",
          resultId: "paymentId",
          otherStringParams: {
            ...(keysToInclude.length && {
              keysToInclude: JSON.stringify([
                ...new Set([...keysToInclude, "paymentId"]),
              ]),
            }),
          },
        });

        break;
      }
      case "parameters":
        result = await programFieldsParameters();
        break;
      case "invitationCodes":
        result = await codeInvitations();

        break;
      case "users":
        result = await allUsers({ keysToInclude });

        break;
      case "geoFencesActiveActivities":
        result = await geoFencesActiveActivities({ keysToInclude });

        break;
      case "fleetDocuments":
        result = await fleetDocuments({ keysToInclude });

        break;
      case "fleetInspectionCompletion":
        result = await groupAndInspectionsByActivityId();

        break;
      case "fleetRepairLog":
        result = await getFleetRepairWorkOrders();

        break;
      case "fleetExpenses":
        result = await getFleetExpenses();

        break;
      case "workOrders":
        result = await getFleetWorkOrders();

        break;
      case "requisitions":
        result = await modifyAndSortApplications({
          keysToInclude,
          reportUsedKeys,
        });

        break;
      case "creditMemoServices":
        result = await getCreditMemoServices({ keysToInclude });

        break;
      case "chargesOverview":
        result = await getChargesOverview({ keysToInclude, reportUsedKeys });

        break;
      case "takeOffComparison":
        result = await getTakeOffComparisonWithAccounting({
          recordId,
          keysToInclude,
          reportUsedKeys,
        });

        break;
      case "rentalsComparison":
        result = await getRentalComparisonWithAccounting({
          keysToInclude,
          reportUsedKeys,
        });

        break;
      case "accountingComparison":
        result = await getAccountingComparisonPerProject();

        break;
      case "scheduleOfValuesComparison":
        result = await getSOVComparisonWithAccounting({ keysToInclude });

        break;
      case "salesReportApprovedEstimates":
        result = await getSalesReportApprovedEstimates(); //same

        break;
      case "salesReportEstimationByService":
        result = await getSalesReportApprovedEstimates(); //same

        break;
      case "takeOffServiceComparison":
        result = await getTakeOffServicesAndCompare({
          recordId,
          keysToInclude,
          reportUsedKeys,
        });

        break;
      case "estimationServices":
        result = await getEstimationServices({
          recordId,
          keysToInclude,
          reportUsedKeys,
        });

        break;
      case "servicesOfRental":
        result = await getServicesOfRental({ keysToInclude });
        break;
      case "scheduleTotalProgress":
        result = await getSchedulingTotalProgress({ keysToInclude });

        break;
      case "scheduleTotalProgressWithCharges":
        result = await getSchedulingTotalProgressWithCharges({ keysToInclude });

        break;
      case "schedulingServices":
        result = await getSchedulingServices({ keysToInclude });
        // case "geoFencesActivities":
        //   result = await getGeoFencesActivities();

        //   break;

        break;
      case "driverStatistics":
        result = await getDriverStatistics();

        break;
      case "fleetDispatching":
        result = await getDispatchesByActivities();

        break;
      case "forecastRentals":
        result = await getForecastingRentalsPerService();

        break;
      case "projectGeofences":
        result = await filteredCategoriesWithGeofence(dataSourceName);

        break;
      case "opportunitiesGeofences":
        result = await filteredCategoriesWithGeofence(dataSourceName);

        break;
      case "bills":
        result = await getBills();

        break;
      case "projectDetails":
        result = await projectDetails(recordId);

        break;
      case "opportunityDetails": {
        result = await opportunityDetails(recordId);
        break;
      }
      case "scheduleOfValues": {
        result = await fetchAllData({
          endpoint: "scheduleOfValues",
          resultPosition: "schedules",
          resultId: "scheduleId",
          otherStringParams: {
            keysToInclude: JSON.stringify([
              ...new Set([...keysToInclude, "scheduleId"]),
            ]),
          },
        });

        break;
      }

      case "projects":
        result = await getProjects({ keysToInclude });

        break;

      case "safetyInspections":
        result = []; //hereeeeeeeeeeee

      default: {
        result = await fetchWithDefaultFn({
          dataSourceName,
          ...(!doNotApplyRecordId(dataSourceName) && { recordId }),
          keysToInclude,
        });

        break;
      }
    }

    console.log({ LENGTH: result.length, [dataSourceName]: result });
    return result;
  } catch (error) {
    console.error("dataSourceReportsFetch error:", error);
    throw error;
  }
};

const fetchWithDefaultFn = async ({
  dataSourceName,
  recordId,
  keysToInclude,
}) => {
  if (recordId) {
    return [
      await fetchData({
        endpoint: dataSourceName,
        query: `${dataSourceName}/${recordId}`,
      }),
    ];
  }

  const response = await fetchData({
    endpoint: dataSourceName,
    ...(keysToInclude.length && {
      queryStringParams: {
        queryStringParameters: {
          withPagination: "true",
          getMaxLimit: "true", // When this key is set to "true", data retrieval is based on the total size limit, not restricted to just 24 records.
          keysToInclude: JSON.stringify(keysToInclude),
        },
      },
    }),
  });

  if (Array.isArray(response)) {
    return response;
  }

  return response?.[dataSourceName] || [];
};
