import { Form, Input } from "antd";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { uniqBy } from "lodash";
import dayjs from "dayjs";
import {
  PlusOutlined,
  SearchOutlined,
  StarFilled,
  StarTwoTone,
} from "@ant-design/icons";
import { isNumber } from "chart.js/helpers";

import { RenderDynamicComponents } from "../../../../../../Header/forms/Components";
import { useGeneralReportsContext } from "../../context";
import { MondayButton } from "../../../../../../commonComponents";
import { TrashIcon, XIcon } from "../../../../../Communication/assets";
import GeneralReportsFiltersModal from "./GeneralReportsFiltersModal";
import { useProgramFields } from "../../../../../../../hooks";
import RemoveModal from "./RemoveModal";
import htmlParser from "../../../../../../../utils/htmlParser";
import { dataSourceReportsFetch } from "../../../../../../../utils";
import { NormalSizedModal } from "../../../../../Fleet/components/index";
import { FilterIcon } from "../../../../../BasePage/src/index";
import { ClearFilterIcon } from "../../../../../DynamicView/src/index";
import regex from "../../../../components/helpers/regex";

import "./GeneralReportsFilters.scss";

const GeneralReportsFilters = ({ isDarkMode }) => {
  const {
    filters = [],
    activeFilters,
    setActiveFilters,
    reportsAvailable,
    setReportsAvailable,
    reportsFilter,
  } = useGeneralReportsContext();
  // * @EneaXharau -> Got reportsIntegrationConfig from useProgramFields()
  // * to add it as a dependency for useEffect so it re-renders when it changes
  const { ["Reports Integration Config"]: reportsIntegrationConfig } =
    useProgramFields();
  //used for controlling dynamic filters value
  const [filtersForm] = Form.useForm();
  const [filtersFormState, setFilterFormState] = useState({});
  const [populatedFilters, setPopulatedFilters] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalRemove, setOpenModalRemove] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [dataName, setDataName] = useState(null);
  const [term, setTerm] = useState("");
  const [favoriteSelected, setFavoriteSelected] = useState(false);

  const { preferences: allPreferences } = useSelector(
    (state) => state.preferences
  );

  const onFilterChange = (dataSourceName, key) => (value) =>
    setFilterFormState((prev) => ({
      ...prev,
      [dataSourceName]: { ...(prev?.[dataSourceName] || {}), [key]: value },
    }));

  const onClearFilters = () => {
    setFilterFormState({});
    setActiveFilters({});
    setTerm("");
    setFavoriteSelected(false);
    setReportsAvailable(reportsFilter);
  };

  const onOpenModalAdd = () => {
    setOpenModal(true);
  };

  const onOpenModalRemove = () => {
    setOpenModalRemove(true);
  };

  const onShowFiltersModal = () => {
    setShowFilters(true);
  };

  const onCancel = () => {
    setShowFilters(false);
  };

  const onFilter = () => {
    setActiveFilters(filtersFormState);
  };

  function numericStringMask(str, mask) {
    if (!mask) return str;
    const numeric = str.replaceAll(/[^\d]/g, "");
    let idx = 0;
    const formated = mask.split("").map((el) => {
      if (el === "#") {
        el = numeric[idx];
        idx++;
      }
      return el;
    });
    return formated.join("");
  }

  const displayDifferentLabel = (param) => {
    switch (typeof param) {
      case "string":
        {
          if (param.length === 11 && isNumber(param)) {
            return numericStringMask(param, "(###) ####-####");
          } else if (param.charAt(0) === "<") {
            return htmlParser(param);
          }
          return param;
        }
        break;
      case "number":
        {
          if (param.toString().length === 13)
            return dayjs(param).format("MM/DD/YYYY HH:mm");
          else return param.toString();
        }
        break;
      case "boolean":
        {
          if (param) return "Yes";
          else return "No";
        }
        break;
      default:
        return param;
        break;
    }
  };

  useEffect(() => {
    (async () => {
      const populatedFilters = await Promise.all(
        Object.entries(filters).map(async ([dataSourceName, filters]) => {
          //response of the datasource so we can get the options
          /**
           * * @EneaXharau -> Changed the way the datasource is taken
           * * to support pagination, add a new case if a new datasource
           * * is paginated
           */
          setDataName(dataSourceName);
          let res = [];
          const tmpData = await dataSourceReportsFetch({ dataSourceName });
          if (Array.isArray(tmpData)) {
            res = tmpData;
          }

          return filters.map((filter = {}) => {
            //formItemName is also the key that is gonna get filtered
            const { formItemName, labelKey } = filter;

            return {
              ...filter,

              customOptions: uniqBy(
                //filters all the object that don't have a value for the key that is gonna get filtered
                res
                  ?.filter(
                    (record) =>
                      !!record?.[formItemName] ||
                      record?.[formItemName] === false
                  )
                  .flatMap(
                    (record) => {
                      const keyValue = Array.isArray(labelKey)
                        ? record?.[labelKey?.[0]]?.[labelKey?.[1]]
                        : record?.[labelKey] || record?.[formItemName];
                      return Array.isArray(record[formItemName])
                        ? record[formItemName].map((value) => ({
                            value: displayDifferentLabel(value),
                            label: value,
                          }))
                        : //this will be returned for all the other datasources
                          {
                            label: displayDifferentLabel(keyValue),
                            value: record[formItemName],
                          };
                    }
                    //handles "tasksManagement" "taskAssignedTo" filter
                  ),
                "value"
              ),
              onSelect: onFilterChange(dataSourceName, formItemName),
            };
          });
        })
      );

      setPopulatedFilters(populatedFilters.flat());
    })();
  }, [reportsIntegrationConfig]);

  useEffect(() => {
    // sets unsaved fields to undefined
    let formKeys = filtersForm.getFieldsValue();

    if (dataName !== null && activeFilters[dataName]) {
      Object.keys(formKeys).forEach((key) => {
        if (!Object.keys(activeFilters[dataName]).includes(key)) {
          filtersForm.setFieldValue(key, undefined);
        }
      });
    } else {
      Object.keys(formKeys).forEach((key) =>
        filtersForm.setFieldValue(key, undefined)
      );
    }
  }, [showFilters]);

  const onChangeHandler = (e) => {
    const { value } = e.target;
    setTerm(value);
  };

  useEffect(() => {
    let favoriteReports = [];

    const filteredOnSearch = [...reportsFilter].filter(
      (report) =>
        regex(report.reportName).includes(regex(term)) ||
        String(regex(dayjs(report.createdAt).format("MM/DD/YYYY"))).includes(
          regex(term)
        ) ||
        regex(report.createdBy).includes(regex(term)) ||
        String(regex(report.datasources.length)).includes(regex(term))
    );

    const reportPreferences =
      allPreferences?.preferences?.reportPreferences || {};
    if (favoriteSelected) {
      favoriteReports = [...filteredOnSearch]?.filter(
        ({ reportId }) => reportPreferences[reportId]
      );
    }
    if (!favoriteSelected) {
      favoriteReports = [...filteredOnSearch];
    }

    setReportsAvailable(favoriteReports);
  }, [term, favoriteSelected]);

  const handleOnFavoriteFilter = () => {
    setFavoriteSelected((prev) => !prev);
  };

  return (
    <>
      <Form form={filtersForm}>
        <div className="general-reports-filters">
          <NormalSizedModal
            title="Filter"
            className={`general-reports-filter-modal ${
              isDarkMode && "general-reports-filter-modal-dark"
            }`}
            visible={showFilters}
            setVisible={setShowFilters}
            maskClosable={true}
            closable={true}
            footerModal={[
              <div className="general-reports-footer">
                <MondayButton
                  className="mondayButtonGrey"
                  Icon={<XIcon />}
                  onClick={() => {
                    filtersForm.resetFields();
                    onClearFilters();
                  }}
                >
                  Clear
                </MondayButton>
                <MondayButton
                  Icon={<FilterIcon />}
                  className="filterReportsButton"
                  onClick={() => {
                    onFilter();
                    setShowFilters(false);
                  }}
                >
                  Filter
                </MondayButton>
              </div>,
            ]}
          >
            <div className="filters-grid">
              {RenderDynamicComponents(populatedFilters)}
            </div>
          </NormalSizedModal>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <MondayButton
                Icon={<FilterIcon />}
                className="filterReportsButton"
                onClick={onShowFiltersModal}
              >
                Open Filter
              </MondayButton>
              <MondayButton
                Icon={<TrashIcon />}
                className="mondayButtonRed"
                onClick={onOpenModalRemove}
              >
                Remove Filters
              </MondayButton>
              <MondayButton
                Icon={<ClearFilterIcon />}
                className="mondayButtonRed"
                onClick={onClearFilters}
                disabled={term === ""}
              >
                Clear Filters
              </MondayButton>
              <Input
                // value={term}
                // defaultValue={search}
                className="general-reports-filter-report-search"
                placeholder={"Search..."}
                allowClear
                autoFocus={true}
                prefix={
                  <SearchOutlined className="general-reports-SearchLogoIcon" />
                }
                onChange={onChangeHandler}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <MondayButton
                className="mondayButtonGrey"
                Icon={
                  favoriteSelected ? (
                    <StarFilled
                      style={{ color: "#fcba03", fontSize: "20px" }}
                    />
                  ) : (
                    <StarTwoTone
                      twoToneColor="#fcba03"
                      style={{ fontSize: "20px" }}
                    />
                  )
                }
                onClick={handleOnFavoriteFilter}
              >
                Show Favorites
              </MondayButton>
              <MondayButton
                Icon={<PlusOutlined />}
                // className="mondayButtonGreenReports"
                onClick={onOpenModalAdd}
              >
                Add Filters
              </MondayButton>
            </div>
          </div>
        </div>
      </Form>
      <GeneralReportsFiltersModal
        visible={openModal}
        setVisible={setOpenModal}
        isDarkMode={isDarkMode}
      />
      <RemoveModal
        visible={openModalRemove}
        setVisible={setOpenModalRemove}
        isDarkMode={isDarkMode}
      />
    </>
  );
};

export default GeneralReportsFilters;
