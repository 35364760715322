import { v4 as uuidv4 } from "uuid";

export const localStorageHistory = "global_search_last_searches";

export const handleLastSearches = (
  categoryName,
  rest,
  label,
  linkTo,
  setCategoriesData
) => {
  const data = {
    categoryName,
    ...rest,
    label,
    linkTo,
    isLastSearch: true,
    key: uuidv4(),
  };

  let searchHistory =
    JSON.parse(localStorage.getItem(localStorageHistory)) || [];

  // if the search data already exists in LS
  if (searchHistory.some((item) => item.linkTo === linkTo)) return;

  // lastSearches length === 5 then remove the last item
  if (searchHistory.length === 5) {
    searchHistory.pop();
  }

  searchHistory.unshift(data); // Add new data at the BEGINNING of lastSearches
  localStorage.setItem(localStorageHistory, JSON.stringify(searchHistory)); // update LS

  // update STATE
  setCategoriesData((prev) => {
    // Check if "Last Searches" category already exists
    const lastSearchesIndex = prev.findIndex((item) => item["Last Searches"]);

    // exist --> UPDATE
    if (lastSearchesIndex !== -1) {
      return prev.map((item, index) =>
        index === lastSearchesIndex
          ? {
              "Last Searches": [data, ...item["Last Searches"].slice(0, 4)],
            }
          : item
      );
    }

    // not exist --> CREATE
    return [{ "Last Searches": [data] }, ...prev];
  });
};
