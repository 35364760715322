import { API } from "aws-amplify";
import { useState, useEffect, useMemo } from "react";
import { InfoCircleFilled } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { Form, Modal, Tour, message, Divider } from "antd";

import {
  employeeFields,
  footerButtons,
  employeeEmergencyContactFields,
} from "./updateCrewData";
import { useEditLogs } from "../../../../../../../hooks";
import { VectorIcon } from "../../../../../../../assets";
import { fetchData } from "../../../../../../SidebarPages/Fleet/utils";
import { compareIncluding } from "../../../../../../SidebarPages/utils";
import { getCognitosForNotification } from "../../../../../../../utils";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import { useModalOnceObject } from "../../../../../../../hooks/useModalOnce";
import { useMultipleRefs } from "../../../../../../../hooks/useMultipleRefs";
import {
  getActiveEmployeesData,
  getEmployeesRateByDateRange,
} from "../../../../../Payroll/Tabs/DEG/FingerCheckConfig/fingercheckFunctions";
import CustomModalHeader, {
  findTutorialSteps,
} from "../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { programFields as setProgramFields } from "../../../../../../../actions";
import { RenderDynamicComponents } from "../../../../../../Header/forms/Components";
import { InputComponent } from "../../../../../../SidebarPages/Fleet/components/index";
import { broadcastArrayToString } from "../../../../../../../utils/broadcastArrayToString";
import { getChangedData } from "../../../../../../SidebarPages/Accounting/components/utilities";
import broadcastNotification from "../../../../../../../helpers/controllers/broadcastNotification";
import MultipleInputs from "../../../../../../SidebarPages/DynamicView/FormItemComponents/MultipleInputs/MultipleInputs";
import { compareArrays } from "../../utils/compareArrays";
import { addIdsToObjects } from "../../utils/addIdsToObjects";
import { addEmergencyLogs } from "../../utils/addEmergencyLogs";

import "./UpdateCrew.scss";

const map = {
  employeeId: "ID",
  crewName: "Name",
  crewStatus: "Status",
  crewPosition: "Role",
  employeeRate: "Pay Rate",
  salaryType: "Salary Type",
  accountName: "Subcontractor",
};

const updatedKeys = (key) => map[key] || "";

export const UpdateCrew = ({
  crews,
  jobsites,
  refreshData,
  refreshTable,
  recordSelected,
  updateModalVisible,
  handleUpdateModalVisible,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { programFields } = useSelector((state) => state.programFields);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [role, setRole] = useState();
  const [status, setStatus] = useState();
  const [subcontractor, setSubcontractor] = useState([]);
  const [disabledActions, setDisabledActions] = useState(false);

  const [form] = Form.useForm();

  const authUser = useSelector(
    (state) => state.authenticatedUser.authenticatedUser
  );

  const dispatch = useDispatch();
  const { saveAddedLogs } = useEditLogs();

  const [tourOpen, setTourOpen] = useModalOnceObject("Tour");
  const [cancelRef, saveRef] = useMultipleRefs(3);

  function stepsMapHelper(title) {
    if (title?.includes("Cancel")) {
      return cancelRef.current;
    } else if (title?.includes("Save")) {
      return saveRef.current;
    }
  }

  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        ...step,
        target: () => stepsMapHelper(step?.title),
        className: isDarkMode ? `custom-tour-dark` : `custom-tour-light`,
      };
    });
    return newSteps;
  }

  const dbSteps = mapRefs(findTutorialSteps("Update Crew", programFields));

  const clientConfigs = useMemo(() => {
    if (programFields?.length) {
      let index = programFields.findIndex(
        (field) => field?.fieldName === "Payroll Configuration"
      );
      return programFields[index].fieldOptions;
    }
    return [];
  }, [programFields]);

  const formFieldsJSON = useMemo(() => {
    return employeeFields(isDarkMode, role, status, jobsites);
  }, [isDarkMode, role, status, jobsites]);

  const formEmergencyContactJSON = useMemo(() => {
    return employeeEmergencyContactFields({ form });
  }, [isDarkMode, role, recordSelected]);

  async function onFetchRate() {
    message.loading({
      key: "onFetchRate",
      content: "Fetching Rate...",
      duration: 0,
    });
    const dateRange = [dayjsNY().subtract(1, "m"), dayjsNY()];
    const employeeData = recordSelected;
    let employeeNumber = employeeData?.employeeNumber;
    if (!employeeData?.employeeNumber) {
      employeeNumber = (employeeData?.employeeId || "")?.replace(
        `${employeeData?.accountName}-`,
        ""
      );
    }

    const clientKey = clientConfigs.find(
      (el) => el.clientName === employeeData?.accountName
    )?.clientKey;

    let rateData = await getEmployeesRateByDateRange({
      dateRange,
      clientKey,
      employeeNumber,
    });

    if (rateData?.status === 200) {
      const data = rateData?.data?.[0];
      form.setFieldValue("employeeRate", data?.Rate);
      form.setFieldValue("fingerCheckId", data?.EmployeeID);
      form.setFieldValue("employeeNumber", employeeNumber);
      message.success({
        duration: 1.5,
        key: "onFetchRate",
        content: "Rate updated!",
      });
      return;
    }

    if (rateData?.status !== 200) {
      const codeLength = 6;
      const zerosToAdd = codeLength - employeeNumber.length;
      for (let i = 0; i < zerosToAdd; i++) {
        employeeNumber = "0" + employeeNumber;
      }

      getEmployeesRateByDateRange({
        dateRange,
        clientKey,
        employeeNumber,
      })
        .then((res) => {
          const data = res?.data?.[0];
          form.setFieldValue("employeeRate", data?.Rate);
          form.setFieldValue("fingerCheckId", data?.EmployeeID);
          form.setFieldValue("employeeNumber", employeeNumber);
          message.success({
            duration: 1.5,
            key: "onFetchRate",
            content: "Rate updated!",
          });
        })
        .catch((err) => {
          console.log("Error: ", err);
          message.error({
            duration: 1.5,
            key: "onFetchRate",
            content: "Could not get rate for this employee!",
          });
        });
    } else {
      message.error({
        duration: 1.5,
        key: "onFetchRate",
        content: "Could not get rate for this employee!",
      });
    }
  }

  async function onUpdateEmployee() {
    message.loading({
      duration: 0,
      key: "onUpdateEmployee",
      content: "Fetching Employee data...",
    });
    const employeeData = recordSelected;
    let employeeNumber = employeeData?.employeeNumber;
    if (!employeeData?.employeeNumber) {
      employeeNumber = (employeeData?.employeeId || "")?.replace(
        `${employeeData?.accountName}-`,
        ""
      );
    }

    const clientKey = clientConfigs.find(
      (el) => el.clientName === employeeData?.accountName
    )?.clientKey;

    if (!clientKey) {
      message.warning({
        duration: 3,
        key: "onUpdateEmployee",
        content:
          "This client does not have a client configuration to fetch employee data.",
      });
      return;
    }

    let result = await getActiveEmployeesData(clientKey, employeeNumber)
      .then((res) => res)
      .catch((err) => {
        console.log("error: ", err);
      });

    if (result?.status !== 200) {
      const codeLength = 6;
      const zerosToAdd = codeLength - employeeNumber?.length;
      for (let i = 0; i < zerosToAdd; i++) {
        employeeNumber = "0" + employeeNumber;
      }
      result = await getActiveEmployeesData(clientKey, employeeNumber);
    }

    if (result?.status !== 200) {
      message.error({
        duration: 1.5,
        key: "onUpdateEmployee",
        content: "Could not get employee data from fingerCheck!",
      });
    } else {
      const data = result?.data?.[0];
      form.setFieldsValue({
        employeeNumber,
        foreman: data?.foreman,
        crewName: data?.crewName,
        crewStatus: data?.crewStatus,
        salaryType: data?.salaryType,
        crewPosition: data?.crewPosition,
        employeeRate: data?.employeeRate,
        fingerCheckId: data?.fingerCheckId,
      });
      form.setFieldValue("crewStatus", data?.crewStatus);
      message.success({
        duration: 1.5,
        key: "onUpdateEmployee",
        content: "Employee Data Updated!",
      });
    }
  }

  const handleUpdate = async () => {
    setDisabledActions(true);
    form
      .validateFields()
      .then((val) => {
        const emergencyValues =
          form.getFieldsValue(true)?.emergencyContact || [];

        const body = {
          crewName: val?.crewName,
          crewStatus: val?.crewStatus,
          employeeId: val?.employeeId,
          salaryType: val?.salaryType,
          defaultJob: val?.defaultJob,
          accountName: val?.accountName,
          employeeRate: val.employeeRate,
          crewPosition: val?.crewPosition,
          emergencyContact: val.hasOwnProperty("emergencyContact")
            ? addIdsToObjects(emergencyValues)
            : [],
          fingerCheckId: val?.fingerCheckId || "",
          employeeNumber: val?.employeeNumber || "",
          absentCount: recordSelected?.absentCount ?? 0,
          foreman:
            val.foreman === undefined ? recordSelected?.foreman : val.foreman,
        };

        const compare = compareArrays(
          recordSelected.emergencyContact,
          body.emergencyContact
        );

        let newEditLog = {
          topic: "",
          label: "",
          updatedKeys: [],
          currentData: {},
          previousData: {},
          actionType: "Edit",
          category: "Crew Logs",
          updatedAt: dayjsNY().valueOf(),
          recordId: recordSelected?.crewId,
          recordName: recordSelected?.crewName,
          cognitoUserId: userConfiguration?.cognitoUserId,
          nameOfUser: `${authUser.given_name} ${authUser.family_name}`,
        };

        const crewTeamsConfiguration = programFields.find(
          ({ fieldName }) => fieldName === "Crew Teams"
        );

        let crewTeams =
          crewTeamsConfiguration?.fieldOptions?.[
            process.env.NODE_ENV === "production" ? "prod" : "dev"
          ];

        if (typeof body?.foreman === "string") {
          const teamOfMember = crewTeams.find(
            ({ crewForeman }) => crewForeman?.crewId === body?.foreman
          );

          if (teamOfMember) {
            Object.assign(teamOfMember, {
              crewMembers: teamOfMember?.crewMembers.map((crew) =>
                crew?.crewId === recordSelected?.crewId
                  ? {
                      crewName: body?.crewName,
                      employeeId: body?.employeeId,
                      crewId: recordSelected?.crewId,
                    }
                  : crew
              ),
            });
            crewTeams = crewTeams.map((team) =>
              team?.crewTeamId === teamOfMember?.crewTeamId
                ? teamOfMember
                : team
            );
          }
        } else if (body?.foreman === true) {
          const teamOfMember = crewTeams.find(
            ({ crewForeman }) => crewForeman?.crewId === recordSelected?.crewId
          );
          if (teamOfMember) {
            Object.assign(teamOfMember, {
              crewForeman: {
                crewName: body?.crewName,
                employeeId: body?.employeeId,
                crewId: recordSelected?.crewId,
              },
            });
            crewTeams = crewTeams.map((team) =>
              team?.crewTeamId === teamOfMember?.crewTeamId
                ? teamOfMember
                : team
            );
          }
        }

        const updatedProgramFields = programFields.map((field) =>
          field.fieldName === "Crew Teams"
            ? {
                ...field,
                fieldOptions: {
                  ...field.fieldOptions,
                  [process.env.NODE_ENV === "production" ? "prod" : "dev"]:
                    crewTeams,
                },
              }
            : field
        );

        const broadCastChanges = [];

        for (let key in body) {
          let result;
          if (!!body[key] && key !== "editLogs" && recordSelected[key]) {
            result = getChangedData(body[key], recordSelected[key]);

            if (result !== false && key !== "emergencyContact") {
              newEditLog.currentData[key] = result.curr;
              newEditLog.previousData[key] = result.prev;
              newEditLog.updatedKeys.push(key);

              newEditLog.currentData[key] !== newEditLog.previousData[key] &&
                broadCastChanges.push(updatedKeys(key));
            }
          } else {
            continue;
          }
        }

        if (
          compare.added.length > 0 ||
          compare.deleted.length > 0 ||
          compare.updated.length > 0
        ) {
          broadCastChanges.push("Emergency Contact Fields");
          const { curr, prev } = addEmergencyLogs(compare);

          newEditLog.currentData["Emergency Contact"] = curr;
          newEditLog.previousData["Emergency Contact"] = prev;
        }

        const saveLog =
          Object.keys(newEditLog.currentData).length > 0 ||
          Object.keys(newEditLog.previousData).length > 0
            ? true
            : false;

        Promise.allSettled([
          API.patch("crews", `/crews/${recordSelected?.crewId}`, {
            body,
          }),
          saveAddedLogs(saveLog ? newEditLog : null),
          API.patch(
            "programFields",
            `/programFields/${crewTeamsConfiguration.fieldId}`,
            {
              body: {
                fieldOptions: {
                  ...crewTeamsConfiguration?.fieldOptions,
                  [process.env.NODE_ENV === "production" ? "prod" : "dev"]:
                    crewTeams,
                },
              },
            }
          ),
        ])
          .then(([{ value: crewRes }]) => {
            if (!!refreshTable) {
              refreshTable(crewRes, "edit");
            }
            handleUpdateModalVisible();
            dispatch(setProgramFields(updatedProgramFields));
            refreshData().then(() => {
              form.resetFields();
              message.success("Updated successfully");
            });
            const common2 = broadcastArrayToString(broadCastChanges);

            common2 &&
              broadcastNotification(
                "20",
                "onCrewEdit",
                [
                  {
                    commonNext: body.crewName,
                    common: userConfiguration?.nameOfUser,
                    common2: broadcastArrayToString(broadCastChanges),
                  },
                  {
                    currentUser: authUser?.sub,
                    recordId: recordSelected?.crewId,
                    userName: userConfiguration?.nameOfUser,
                    cognitos: getCognitosForNotification(userConfiguration),
                  },
                ],
                ""
              );
          })
          .catch((e) => {
            console.error(e);
            setDisabledActions(false);
            message.error("Error while updating");
          });
      })
      .catch((e) => {
        console.error(e);
        setDisabledActions(false);
      });
  };

  const clientsConfig = useMemo(() => {
    if (programFields?.length) {
      let index = programFields.findIndex(
        (field) => field.fieldName === "Payroll Configuration"
      );
      return programFields[index].fieldOptions;
    }
    return [];
  }, [programFields]);

  useEffect(() => {
    if (!!programFields?.length) {
      const fieldObj = programFields?.reduce(
        (acc, { fieldName, fieldOptions }) => ({
          ...acc,
          [fieldName]: fieldOptions,
        })
      );
      setStatus(
        fieldObj?.["Crew Status"]?.filter(
          ({ statusName }) => statusName !== "s" && statusName !== "test"
        )
      );
      setRole(fieldObj?.["Crew Position"]);
    }
  }, [userConfiguration, programFields]);

  useEffect(() => {
    if (recordSelected) {
      fetchData("accounts").then((r) => {
        setSubcontractor(() =>
          r?.filter((item) =>
            compareIncluding(item?.accountRecordType, "subcontractors")
          )
        );
      });

      form.setFieldsValue(recordSelected);
      form.setFieldValue(
        "foreman",
        typeof recordSelected?.foreman === "string"
          ? false
          : recordSelected?.foreman
      );
      form.setFieldValue("employeeId", recordSelected?.employeeId);
      form.setFieldValue("employeeRate", recordSelected?.employeeRate);
    }
  }, [recordSelected]);

  return (
    <>
      {recordSelected && (
        <Modal
          closable={true}
          centered={true}
          closeIcon={<XIcon />}
          destroyOnClose={true}
          open={updateModalVisible}
          data-testid="edit-crew-modal"
          onCancel={handleUpdateModalVisible}
          className={`editCrewModal ${isDarkMode && "editCrewModalDark"} `}
          title={
            <CustomModalHeader
              title={`Edit ${recordSelected?.crewName}`}
              onClick={() => {
                setTourOpen(true);
              }}
            />
          }
          footer={footerButtons({
            saveRef,
            cancelRef,
            handleUpdate,
            disabledActions,
            onCancel: () => handleUpdateModalVisible(),
          })}
        >
          <div className="updateCrewInfo">
            <InfoCircleFilled style={{ color: "#0F5C97", fontSize: 20 }} />
            <p className="updateCrewText">
              Edit crew member details such as name,role,pay rate,and more.You
              can also retrieve the latest rate and sync data with Fingercheck.
            </p>
          </div>
          <Form form={form}>
            <section className="fingerCheckFetch">
              <InputComponent
                {...{
                  type: "select",
                  required: true,
                  label: "Subcontractor",
                  formItemName: "accountName",
                  getPopUpContainer: document.body,
                  className: "editCrewSubcontractor",
                  placeholder: "Select subcontractor...",
                  dropdownClassName: `${isDarkMode && "darkDropDown"}`,
                  customOptions: (subcontractor || [])?.map(
                    ({ accountName }, key) => ({
                      key,
                      label: accountName,
                      value: accountName,
                    })
                  ),
                  customOptions: [
                    {
                      label: "Client Configuration",
                      options: clientsConfig?.flatMap(
                        ({ clientName, activeConfig }, key) =>
                          activeConfig
                            ? { key, value: clientName, label: clientName }
                            : []
                      ),
                    },
                    {
                      label: "Subcontractors",
                      options: (subcontractor || [])?.map(
                        ({ accountName }, key) => ({
                          key,
                          label: accountName,
                          value: accountName,
                        })
                      ),
                    },
                  ],
                }}
              />
              <div className="employeeNumberTextDiv">
                <div
                  onClick={onFetchRate}
                  style={{ cursor: "pointer" }}
                  className="employeeLatestRate"
                >
                  <span className="employeeNumberText">Get latest rate</span>
                  <VectorIcon />
                </div>
                <div
                  onClick={onUpdateEmployee}
                  style={{ cursor: "pointer" }}
                  className="updateEmployeeData"
                >
                  <span className="employeeNumberText">
                    Update employee data
                  </span>
                  <VectorIcon />
                </div>
              </div>
            </section>
            <section className="formSection">
              {RenderDynamicComponents(formFieldsJSON.slice(0, 3), form)}
            </section>
            <section className="firstFormSection">
              {RenderDynamicComponents(formFieldsJSON.slice(3, 7), form)}
            </section>
            <section style={{ display: "none" }}>
              {RenderDynamicComponents(formFieldsJSON.slice(7, 10), form)}
            </section>
            <Divider
              style={{
                ...(isDarkMode && {
                  color: "white",
                  borderColor: "white",
                }),
              }}
            >
              Emergency Contact Fields
            </Divider>
            <MultipleInputs
              label={""}
              form={form}
              isDarkMode={isDarkMode}
              name={formEmergencyContactJSON.formItemName || ""}
              value={recordSelected?.emergencyContact || []}
              dynamicFields={formEmergencyContactJSON.dynamicFields || []}
            />
          </Form>
        </Modal>
      )}
      {tourOpen && (
        <Tour
          open={tourOpen}
          steps={dbSteps}
          mask={{ color: "#2a2b3a71" }}
          onClose={() => {
            setTourOpen(false);
          }}
        />
      )}
    </>
  );
};
