const REQUEST_FORM_TYPES = {
  ESTIMATION: "Estimation",
  PERMIT_DRAWING: "Permit Drawing",
  SCHEDULING: "Scheduling",
  DISPATCH: "Dispatch",
  SAFETY_INSPECTION: "Safety Inspection",
  PROJECT: "Project",
  CLAIM: "Claim",
  INCIDENT: "Incident",
  SERVICE_INSPECTION: "Service Inspection",
  SCHEDULE_CHANGE: "Schedule Change",
  DOCUMENTATION: "Documentation",
  CHANGE_ORDER: "Change Order",
  OPPORTUNITY: "Opportunity",
  WORK_ORDER: "Work Order",
  FLEET_INSPECTION: "Fleet Inspection",
  FLEET_INVENTORY: "Fleet Inventory",
  SCHEDULE_MAINTENANCE: "Schedule Maintenance",
  TO_DO: "Todo",
  NEW_VENDOR: "New Vendor",
  INSPECTION: "Inspection",
};

export default REQUEST_FORM_TYPES;
