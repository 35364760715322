import "./ThreeDotedActions.scss";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useChartCardsContext } from "../../../ChartCardsContext";
import DeleteCardAction from "./DeleteCardAction/DeleteCardAction";
import EditCardTitle from "./EditCardTitle/EditCardTitle";
import ChangeChartType from "./ChangeChartType/ChangeChartType";
import ChartFilters from "../../ChartFilters/ChartFilters";
import ChangeChartWidth from "./ChangeChartWidth/ChangeChartWidth";

const ThreeDotedActions = ({ cardConfigs, setHasBlur, cardFilters }) => {
  const { id, permanentCard = false, createdBy } = cardConfigs;

  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [showFilterModal, setShowFilterModal] = useState(false);

  const {
    setGeneralFilters,
    setHiddenCardIds,
    categoryCards,
    setUpdatedCards,
    showPrivateCards,
    setNewChanges,
    showAddBtn,
    isAdmin,
  } = useChartCardsContext();

  const canUserModifyPermanentCard =
    !permanentCard ||
    createdBy.cognitoUserId === (userConfiguration.cognitoUserId || "");

  const actions = [
    // Only user that created that permanent card can add filters
    // Filters are saved in each card so other users can not change these filters
    canUserModifyPermanentCard && {
      name: "Filter",
      onClick: () => setShowFilterModal(true),
    },
    isAdmin && {
      name: "Blur",
      onClick: () => {
        setHasBlur(true);
      },
    },
    isAdmin && {
      name: "Hide",
      onClick: () => {
        if (!!id) {
          setNewChanges(true);
          setHiddenCardIds((prev) => [...prev, id]);
        }
      },
    },
  ].filter(Boolean);

  return (
    <div className="chart-card-three-doted-actions">
      {actions.map(({ name, onClick }, i) => (
        <span key={i} className="ActionElementThreeDots" onClick={onClick}>
          {name}
        </span>
      ))}
      {isAdmin && !!showAddBtn && (
        <>
          <EditCardTitle {...{ cardConfigs }} />
          <ChangeChartType {...{ cardConfigs }} />

          {canUserModifyPermanentCard && (
            <>
              <ChangeChartWidth {...{ cardConfigs }} />
              <DeleteCardAction {...{ cardConfigs }} />
            </>
          )}
        </>
      )}
      {showFilterModal && (
        <ChartFilters
          {...{
            showFilterModal,
            setShowFilterModal,
            defaultFilters: cardFilters,
            cardConfigs,
            setFilters: (filters) => {
              setNewChanges(true);
              //if permanent card save its filters inside the card config
              if (!!permanentCard) {
                setUpdatedCards((prev) => {
                  const isPrivate = showPrivateCards === "private";
                  return {
                    ...prev,
                    [isPrivate]: prev[isPrivate].map((card) =>
                      card.id === id
                        ? { ...card, permanentFilters: filters }
                        : card
                    ),
                  };
                });
              } else {
                setGeneralFilters((prev) => {
                  if (filters.length > 0) {
                    return { ...prev, [id]: filters };
                  } else {
                    const { [id]: catToRemove, ...rest } = prev;
                    return rest;
                  }
                });
              }
            },
          }}
        />
      )}
    </div>
  );
};

export default ThreeDotedActions;
