import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { isTimestamp } from "../../../../../pages/Settings/settingsComponents/ReportBasePagesDatasets/utils";

/**
 * @typedef {import("../../../../FleetMaintenanceView/types").FleetType} FleetType
 */

/**
 * @typedef {import("../../../../FleetMaintenanceView/types").VehicleReport} VehicleReport
 */

/**
 * @typedef {import("../../../../FleetMaintenanceView/types").VehicleReportError} VehicleReportError
 */

/**
 * @typedef {{vehicleReports: VehicleReport[], reportErrors: VehicleReportError[]}} Report
 */

/**
 * @typedef {import("../../../../FleetMaintenanceView/types").ServiceProgramType} ServiceProgramType
 */

/**
 * @typedef UpcomingMaintenance
 * @property {string} fleetName
 * @property {string} fleetId
 * @property {string} programName
 * @property {string} programId
 * @property {string} vinNumber
 * @property {string} licensePlate
 * @property {number} odometerReading
 * @property {string} maintenanceReason
 * @property {number} estimatedMaintenanceDate
 * @property {number} estimatedOdometer
 * @property {number} lastDateScheduleService
 * @property {number} lastMileScheduleService
 */

/**
 * @typedef CalculateParam
 * @property {Report} vehicleReports
 * @property {ServiceProgramType[]} servicePrograms
 * @property {FleetType[]} vehicles
 *
 * @param {CalculateParam} param
 * @returns {UpcomingMaintenance[]}
 */
function calculateUpcomingMaintenances(param = {}) {
  /** @type {UpcomingMaintenance[]} */
  const data = [];

  const { servicePrograms, vehicleReports, vehicles } = param;

  /** @type {Record<string, FleetType>} */
  const vehiclesMap = (vehicles || []).reduce(
    (acc, val) => ({
      ...acc,
      [val.fleetId]: val,
    }),
    {}
  );

  /** @type {Record<string, VehicleReport>} */
  const reportsByVehicle = (vehicleReports?.vehicleReports || [])?.reduce(
    (acc, val) => ({
      ...acc,
      [val.fleetId]: val,
    }),
    {}
  );

  for (const program of servicePrograms) {
    const {
      stopType,
      stopValue,
      programId,
      programName,
      executionData,
      repetitionMiles,
      repetitionDuration,
    } = program;

    if (stopType === "date" && stopValue < Date.now()) {
      continue;
    }

    for (const fleetId in executionData) {
      if (!(fleetId in vehiclesMap)) {
        continue;
      }

      const {
        fleetName,
        executionCount,
        nextExecutionDate,
        nextExecutionMiles,
        workOrderCreatedDate,
        workOrderCreatedMiles,
        lastDateScheduleService,
        lastMileScheduleService,
      } = executionData[fleetId];

      const { vinNumber, licensePlate } = vehiclesMap[fleetId];

      const report = reportsByVehicle?.[fleetId];

      if (!report) {
        continue;
      }

      if (stopType === "count" && executionCount >= stopValue) {
        continue;
      }

      if (!workOrderCreatedMiles || !workOrderCreatedDate) {
        continue;
      }

      let maintenanceReason = "";
      let estimatedOdometer = 0;
      let estimatedMaintenanceDate = 0;

      let dateCondition = false;

      if (repetitionMiles && repetitionDuration) {
        const daysToReachMiles =
          (nextExecutionMiles - report["odometerReading"]) /
          report["averageMilesPerDay"];

        const estimatedDateFromMiles = dayjsNY()
          .add(daysToReachMiles, "days")
          .valueOf();

        if (estimatedDateFromMiles > nextExecutionDate) {
          dateCondition = true;
        }
      } else if (repetitionDuration) {
        dateCondition = true;
      }

      console.log({ dateCondition });

      if (dateCondition) {
        maintenanceReason = "Scheduled Date";
        estimatedMaintenanceDate = nextExecutionDate;

        const daysUntilRepetition = dayjsNY(nextExecutionDate).diff(
          dayjsNY(),
          "day"
        );

        console.log({
          fleetName,
          aaa:
            report["odometerReading"] +
            report["averageMilesPerDay"] * daysUntilRepetition,
        });

        estimatedOdometer =
          report["odometerReading"] +
          report["averageMilesPerDay"] * daysUntilRepetition;
      } else {
        maintenanceReason = "Reached Odometer";
        estimatedOdometer = nextExecutionMiles;
        console.log({ fleetName, nextExecutionMiles });

        const daysDiff =
          (nextExecutionMiles - report["odometerReading"]) /
          report["averageMilesPerDay"];

        estimatedMaintenanceDate = dayjsNY().add(daysDiff, "days").valueOf();
      }

      if (!isTimestamp(estimatedMaintenanceDate)) {
        continue;
      }

      data.push({
        fleetId,
        fleetName,
        programId,
        vinNumber,
        programName,
        licensePlate,
        estimatedOdometer,
        maintenanceReason,
        lastDateScheduleService,
        lastMileScheduleService,
        estimatedMaintenanceDate,
        odometerReading: report["odometerReading"],
      });
    }
  }

  data.sort(
    (a, b) =>
      a.estimatedMaintenanceDate - b.estimatedMaintenanceDate ||
      a.fleetName
        .toLocaleLowerCase()
        .localeCompare(b.fleetName.toLocaleLowerCase())
  );

  return data;
}

export default calculateUpcomingMaintenances;
