import { message } from "antd";
import { API } from "aws-amplify";
import { apiPost, apiPut } from "../../../../../../../../utils";
import { callGmailAPI } from "../../../../../../Communication/functions";
import dayjs from "dayjs";

export async function logoutAll(user, updateActiveSessions, saveAddedLogs) {
  await apiPut("userConfiguration", user?.identityId, {
    activeSessions: updateActiveSessions,
  })
    .then(() => {
      saveAddedLogs({
        recordId: `Users Actions - ${user?.nameOfUser}`,
        recordName: user?.nameOfUser,
        actionType: "Force Logout",
        category: "Users Actions",
        updatedKeys: [
          {
            label: `${user?.nameOfUser} Logout`,
            previousValue: `Active Sessions: ${user?.activeSessions?.length}`,
            updatedValue: "Active Sessions: 0",
          },
        ],
      });
    })
    .catch((err) => {
      console.log("Error Logging Out: ", err);
    });
}

function sendEmail(user) {
  callGmailAPI("sendEmail", {
    to: [user.userName],
    subject: "Password Changed",
    body: `<p>Hello ${user.nameOfUser},</p>
        <br/>
        <p>We received a request from admin to reset the password for your account.</p>
        <p>To reset your password, click on the link below:</p>
        <p><a href="http://portal.corescaffold.com">Portal</a>.</p>
        <br/>
        <p>Best Regards,</p>
        <p>Core Team</p>`,
    attachments: [],
    category: "error",
    authData: false,
  });
}

export async function resetPassword(user, saveAddedLogs) {
  saveAddedLogs({
    recordId: `Users Actions - ${user?.nameOfUser}`,
    recordName: user?.nameOfUser,
    actionType: "Force Password Resetting",
    category: "Users Actions",
    updatedKeys: [
      {
        label: "User Password Resetting",
        previousValue: "False",
        updatedValue: "True",
      },
    ],
  });

  await apiPut("userConfiguration", user?.identityId, {
    isForceToResetPassword: true,
  });

  sendEmail(user);
}

export async function changeStatus(user, saveAddedLogs) {
  await apiPut("userConfiguration", user?.identityId, {
    isSuspended: !user?.isSuspended,
    suspendedDate: Date.now(),
    reactivatedDate: !!user?.suspendedDate ? Date.now() : "",
  }).then(() => {
    saveAddedLogs({
      recordId: `Users Actions - ${user?.nameOfUser}`,
      recordName: user?.nameOfUser,
      actionType: user?.isSuspended ? "Reactivate" : "Suspend",
      category: "Users Actions",
      updatedKeys: [
        {
          label: `${user?.nameOfUser} Status`,
          previousValue: user?.isSuspended ? "Suspended" : "Active",
          updatedValue: !user?.isSuspended ? "Suspended" : "Active",
        },
      ],
    });
  });
}

export async function logoutSingleSession(
  user,
  saveAddedLogs,
  updatedActiveSessions,
  session
) {
  await apiPut("userConfiguration", user?.identityId, {
    activeSessions: updatedActiveSessions,
  })
    .then(() => {
      message.success("Update Successful!");
      saveAddedLogs({
        recordId: `Users Actions - ${user?.nameOfUser}`,
        recordName: user?.nameOfUser,
        actionType: "Force Session Logout",
        category: "Users Actions",
        updatedKeys: [
          {
            label: `${user?.nameOfUser} Session Logout`,
            previousValue: `Session Start: ${dayjs(session?.loggedIn).format(
              "MM/DD/YYYY hh:mm a"
            )}`,
            updatedValue: "Session End",
          },
        ],
      });
    })
    .catch((err) => {
      message.error(
        "An error ocurred while trying to logout, please try again later."
      );
      console.log("Error Logging Out of Session: ", err);
    });
}

export async function unlinkUser({
  user,
  rowData,
  endpoint,
  keyId,
  linkKey,
  saveAddedLogs,
  elementName,
}) {
  await apiPut("userConfiguration", user?.identityId, {
    linked: false,
  })
    .then(() => {
      apiPut(endpoint, rowData?.[keyId], {
        [linkKey]: "",
      })
        .then(() => {
          message.success("Unlink successful!");
          saveAddedLogs({
            recordId: `Users Actions - ${user?.nameOfUser}`,
            recordName: user?.nameOfUser,
            actionType: "Edit",
            category: "Users Actions",
            updatedKeys: [
              {
                label: `${user?.nameOfUser} linked status`,
                updatedValue: "Unlinked",
                previousValue: `Linked to: ${rowData?.[elementName]}`,
              },
            ],
          });
        })
        .catch((err) => {
          console.log("Error: ", err);
        });
    })
    .catch((err) => {
      console.log("Error: ", err);
      message.error(
        "An error ocurred while trying to logout, please try again later."
      );
    });
}
