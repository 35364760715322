import React, { useEffect, useState } from "react";
import { Checkbox, message } from "antd";
import { useRedux } from "../../../hooks";
import { useCreateBillContext } from "../context/BillModalProvider";
import { useInitialBillContext } from "../context/InitialBillProvider";

const TaxCheckBox = (props) => {
  const [isWritable] = useRedux("billIsWritable");
  const { form, billData, vendor } = useInitialBillContext();
  const { rowData, setRowData } = useCreateBillContext();

  const [checked, setChecked] = useState(false);
  const [previousTaxAmount, setPreviousTaxAmount] = useState(0);
  const [initialAmount, setInitialAmount] = useState(null); 

  useEffect(() => {
    const row = rowData.find((row) => row.itemId === props.data.itemId);
    if (row) {
      if (row.taxAmount > 0) {
        setChecked(true);
        setPreviousTaxAmount(row.taxAmount);
        if (initialAmount === null) {
          setInitialAmount(row.amount); // amount for first check
        }
      } else {
        setChecked(false);
        setPreviousTaxAmount(0);
        setInitialAmount(null);
      }
      if (row.amount !== initialAmount && initialAmount !== null) {
        setChecked(false);
        setPreviousTaxAmount(0);
        setRowData((prev) =>
          prev.map((row) => {
            if (row.itemId === props.data.itemId) {
              return {
                ...row,
                amount: row.total, 
                tax: false,
                taxAmount: 0,
                billable: false,
              };
            }
            return row;
          })
        );
      }
    }
  }, [rowData, props.data.itemId, initialAmount]);

  const applyTax = (isChecked) => {
    const row = rowData.find((row) => row.itemId === props.data.itemId);
    const rowTotal = row?.total || 0;

    if (isChecked) {
      if (vendor?.vendorTaxRate) {
        const taxAmount = rowTotal * (vendor.vendorTaxRate / 100);
        const amountExcludingTax = rowTotal - taxAmount;

          setRowData((prev) =>
            prev.map((row) => {
              if (row.itemId === props.data.itemId) {
                return {
                  ...row,
                  amount: amountExcludingTax,
                  tax: true,
                  taxAmount,
                  billable: true,
                };
              }
              return row;
            })
          ); 
      } else {
        message.warning("This item is tax-free.");
      }
    } else {
      setRowData((prev) =>
        prev.map((row) => {
          if (row.itemId === props.data.itemId) {
            return {
              ...row,
              amount: rowTotal,
              tax: false,
              taxAmount: 0,
              billable: false,
            };
          }
          return row;
        })
      );
      setPreviousTaxAmount(0);  
      setInitialAmount(null); // reset amount when unchecked 
    }
  };

  const onCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setChecked(isChecked);  
    applyTax(isChecked);    
  };
  // const onCheckboxChange = () => {
  //   if (!!vendor?.vendorTaxRate) {
  //     const appliedAmount = rowData.reduce((acc, row) => (acc += row.total), 0);
  //     const totalAmount = form.getFieldValue("billAmount") || 0;
  //     const remainingValue = totalAmount - appliedAmount;

  //     setRowData((prev) =>
  //       prev.map((row) => {
  //         const isTaxable = !row.tax;
  //         const taxAmount = isTaxable ? row.amount * vendor.vendorTaxRate : 0;
  //         const isBillable = isTaxable ? true : row.billable;

  //         if (row.itemId === props.data.itemId) {
  //           if (remainingValue - taxAmount < 0 && isTaxable) {
  //             message.error("You can't apply more than the total amount.");

  //             return {
  //               ...row,
  //               tax: false,
  //               taxAmount: 0,
  //               total: row.amount,
  //             };
  //           }

  //           return {
  //             ...row,
  //             billable: isBillable,
  //             tax: isTaxable,
  //             taxAmount,
  //             total: row.amount + (isTaxable ? taxAmount : 0),
  //           };
  //         }
  //         return row;
  //       })
  //     );
  //   } else {
  //     message.warning("This item is tax-free.");
  //   }
  // };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Checkbox
        disabled={!isWritable && billData}
      //checked={props.value} 
        checked={checked}
        className="checkbox"
        onChange={onCheckboxChange}
      />
    </div>
  );
};

export default TaxCheckBox;