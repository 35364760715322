export const fontSizeOptions = [
  { label: "8px", key: "8" },
  { label: "10px", key: "10" },
  { label: "12px", key: "12" },
  { label: "14px", key: "14" },
  { label: "16px", key: "16" },
  { label: "18px", key: "18" },
  { label: "24px", key: "24" },
  { label: "30px", key: "30" },
  { label: "32px", key: "32" },
  { label: "34px", key: "34" },
  { label: "36px", key: "36" },
  { label: "48px", key: "48" },
  { label: "60px", key: "60" },
  { label: "72px", key: "72" },
  { label: "96px", key: "96" },
];

export const textFormatOptions = [
  { label: "Paragraph", key: "p" },
  { label: <h1>Heading 1</h1>, key: "h1" },
  { label: <h2>Heading 2</h2>, key: "h2" },
  { label: <h3>Heading 3</h3>, key: "h3" },
  { label: <h4>Heading 4</h4>, key: "h4" },
  { label: "Quote", key: "blockquote" },
];

export const categoriesOptions = [
  {
    label: "Elevation Label",
    key: "elevationLabel",
    usedIn: ["scope", "hoist", "service"],
  },
  {
    label: "Elevation Description",
    key: "elevationDesc",
    usedIn: ["hoist", "service"],
  },
  {
    label: "Elevation Notes",
    key: "notes",
    usedIn: ["hoist", "service"],
  },
  {
    label: "Item Label",
    key: "itemLabel",
    usedIn: ["scope", "hoist", "service"],
  },
  {
    label: "Addon Label",
    key: "addonLabel",
    usedIn: ["hoist", "service"],
  },
];

export const listOptions = [
  { label: "None", key: undefined },
  { label: "Ordered List", key: "ol" },
  { label: "Unordered List", key: "ul" },
];
