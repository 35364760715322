import { useState } from "react";
import { RedWarningModal } from "../../../../../../commonComponents";
import { DeleteIcon } from "../../../Roles/src";
import "./DeleteDataset.scss";

export const DeleteDataset = ({
  data,
  onDeleteDataset,
  isDeleteModalOpen,
  setIsDeleteModalOpen,
}) => {
  return (
    <>
      <DeleteIcon
        style={{ cursor: "pointer" }}
        fill="#FE4C4A"
        onClick={() => {
          setIsDeleteModalOpen(data);
        }}
      />

      {isDeleteModalOpen?.id === data?.id && (
        <RedWarningModal
          {...{
            visible: isDeleteModalOpen?.id === data?.id,
            onCancel: () => {
              setIsDeleteModalOpen(false);
            },
            titleText: "Confirm Deletion",
            TitleIcon: DeleteIcon,
            footerProps: {
              onConfirm: () => onDeleteDataset(),
            },
            deleteModal: true,
          }}
        >
          <div className="deleteDataset-text">
            <p className="deleteDataset-name">
              Are you sure you want to delete <b>{data?.title}</b> ?
            </p>
            <p>
              This action is irreversible and will permanently remove all
              associated data.
            </p>
          </div>
        </RedWarningModal>
      )}
    </>
  );
};
