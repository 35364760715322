import { Button, Modal, Tooltip, message, Switch, Select, Input } from "antd";

import React, { useEffect, useState } from "react";

import _ from "lodash";
import { filterItemsByJump } from "../../../../../../SidebarPages/Scheduling/helpers/filters";
import { hoist_dimension } from "../../../../DataEntryGrid/tools/columnDefinitions/ProgressColumnDefinition";
import { setInItems } from "../../../helpers/PLI-setters-getters";
import "./JumpProgressModal.scss";
import { MondayButton } from "../../../../../../commonComponents";
import {
  Tick,
  TickIcon,
} from "../../../../../../pages/Settings/settingsComponents/Roles/src";
import { AutoRenew, DeleteIcon, PlusIcon } from "../../../../../../../assets";
import Swal from "sweetalert2";
import {
  BorderedTextCard,
  InputComponent,
} from "../../../../../../SidebarPages/Fleet/components/index";
import FloorsModal from "./FloorsModal";
import {
  checkProgressForHoist,
  checkWhichJumpWeWorking,
  sliceNumbers,
  swalTexts,
  breakdownsServiceId,
  prevJumpCompleted,
  prevBreakdownCompleted,
  pliEditedFromNextSchedules,
  columnDefsForProgressHoist,
  columnDefsForHoistInspection,
  swalToast,
} from "../../../SchedulingFirstPage/helperData";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import BreakdownsSelectedDays from "../Breakdown/BreakdownsSelectedDays";
import { CloseOutlined } from "@ant-design/icons";
import { AgGridReact } from "ag-grid-react";
import { WarningCircleIcon } from "../../../../../../SidebarPages/Projects/DobTab/assets";
import { openInNewTab } from "../../../../../../../utils";
import { useSelector } from "react-redux";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
export const JumpProgressModal = ({
  jump,
  elevation,
  scheduleDays,
  onJumpModalClose,
  set_activeJump,
  typeOfWork,
  typeOfRemoval,
  serviceDefinitions,
  editMode,
  hoistDriveIds,
  updateProgressLogs,
  estimationId,
  elevationId,
  estimation,
  serviceName,
  elevationStatus,
  serviceId,
  updateBreakdownLogs,
}) => {
  const { userConfiguration } = useSelector((state) => state?.userConfig);
  const { floors, name } = jump;

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const itemsOfJump = filterItemsByJump(elevation?.items, jump);
  const [breakVisible, setBreakVisible] = useState(false);
  const [specificBreakdown, setSpecificBreakdown] = useState({});
  const [breakdownValue, set_breakdownValue] = useState(
    getBreakdownValueForJump(itemsOfJump, jump, typeOfWork)?.filter(
      (el) =>
        !!el[typeOfWork === "Inspection" ? "usage" : "inspections"]?.pli?.value
    )
  );

  const [data, setData] = useState("");

  const [jump_state, set_jump_state] = useState({
    ..._.cloneDeep(jump),
    days: [...new Set(jump?.days)] || [],
    progressByDay: jump?.progressByDay || [],
    totalProgress: jump?.totalProgress || 0,
    [hoist_dimension]: 100,
  });

  const [itemsOfJumpState, set_itemsOfJumpState] = useState(itemsOfJump);

  const onOk = () => {
    let jumpState = {
      ...jump_state,
      breakdownValue,
    };

    // delete jumpState.editedFrom;

    let logs = [];

    if (jumpState?.progressByDay?.length > 0) {
      jumpState?.progressByDay?.forEach((progress) => {
        if (progress?.progress?.length > 0) {
          progress?.progress?.forEach((prg) => {
            if (!!prg?.changed) {
              const findDay =
                scheduleDays?.find((day) => day?.id === progress?.day) || {};
              // delete prg.changed;
              logs.push({
                dayId: progress?.day,
                estimationId,
                pli: prg?.floor,
                user: userConfiguration?.nameOfUser,
                progressTimeGiven: Date.now(),
                startDate: dayjsNY(findDay?.startDate).valueOf(),
                endDate: dayjsNY(findDay?.endDate).valueOf(),
                progressValues: [{ progress: prg?.progress }],
                elevationId,
                estimation,
                serviceName,
                pliProgressPercentage: prg?.progress,
                pliProgress: prg?.progress,
                elevationStatus,
                dayProgress: prg?.progress || 0,
                serviceId,
                elevation:
                  elevation?.elevationLabel + " - " + elevation?.elevationId,
                firstDateOnSchedule: dayjsNY(
                  scheduleDays?.[0]?.startDate
                ).valueOf(),
                pliRemainingProgress: 100 - prg?.progress,
                jump: jumpState?.name || "",
                totalPli: 100,
                isHoist: true,
              });
            }
            prg.changed = false;
          });
        }
      });
    }

    console.log("logsssss", { logs, jumpState });

    updateProgressLogs(logs);

    onJumpModalClose(true, name, {
      breakdownValue,
      jump: jumpState,
      modifiedItems: itemsOfJumpState,
    });
  };
  const onCancel = () => {
    onJumpModalClose(false);
  };

  const selectDays_jump = (days) => {
    let jump_state_copy = _.cloneDeep(jump_state);
    let itemsOfJumpState_copy = _.cloneDeep(itemsOfJumpState);

    jump_state_copy.days = days;
    jump_state_copy.progressByDay =
      days?.map((dayId) => ({
        day: dayId,
        progress: floors?.map((el) => ({
          ...el,
          floor: el?.floor,
          progress: 0,
        })),
      })) || [];

    const { progressByDay, totalProgress } = jump_state_copy;
    setInItems(itemsOfJumpState_copy, { days, progressByDay, totalProgress });
    set_itemsOfJumpState(itemsOfJumpState_copy);

    set_jump_state(jump_state_copy);
  };

  const { progressByDay } = jump_state;

  const onChangeProgressFloor = (e, index, floor) => {
    if (!editMode) {
      swalToast({
        icon: "error",
        title: "Available only in edit mode!",
        position: "bottom-end",
        showConfirmButton: false,
        timer: 4000,
      });
      return;
    }
    let { previousProgressByDays, progressByDayForThisSchedule } =
      jump_state?.progressByDay?.reduce?.(
        (result, el) => {
          const daysOfThisSchedule = scheduleDays?.some(
            (a) => a?.id === el?.day
          );
          if (!!daysOfThisSchedule) {
            result.progressByDayForThisSchedule.push(el);
          } else {
            result.previousProgressByDays.push(el);
          }
          return result;
        },
        {
          previousProgressByDays: [],
          progressByDayForThisSchedule: [],
        }
      );

    let valueInserted = Number(e.target.value) || 0;
    if (valueInserted > 100) {
      message.error("Progress cannot be more than 100%!");
      return;
    }
    if (valueInserted < 0) {
      message.error("Progress cannot be less than 0%!");
      return;
    }
    if (isNaN(valueInserted)) {
      message.error("Progress must be a number!");
      valueInserted = 0;
    }

    const progressForPreviousFloor = previousProgressByDays?.reduce?.(
      (a, b) => {
        const progressForThisDay = b?.progress?.find?.(
          (el) => el?.floor === floor
        )?.progress;
        return a + progressForThisDay;
      },
      0
    );

    if (valueInserted > 100 - progressForPreviousFloor) {
      message.error(
        `Progress cannot be more than ${100 - progressForPreviousFloor}%!`
      );
      return;
    }

    let sortedDays = [];
    for (const scheduleDay of scheduleDays || []) {
      let id = progressByDayForThisSchedule?.find(
        (id) => id?.day === scheduleDay?.id
      );
      if (id) sortedDays.push(id);
    }

    sortedDays[index].progress = sortedDays[index]?.progress?.map?.((el) => ({
      ...el,
      progress: el?.floor === floor ? valueInserted : el?.progress,
      changed:
        el?.floor === floor
          ? el?.progress === valueInserted
            ? false
            : true
          : el?.changed || false,
    }));
    set_jump_state({
      ...jump_state,
      progressByDay: [...previousProgressByDays, ...sortedDays],
    });
  };

  const inspectionsType = {
    service: {
      label: "Service",
      value: true,
    },
    elevation: {
      label: "Elevation",
      value: false,
    },
    pli: {
      label: "Jump",
      value: true,
    },
  };
  // validations on the input fields for name and rate
  const onChangeInputs = (e, type) => {
    if (type === "name") {
      if (!isNaN(e) && e !== "") {
        message.error("Name cannot be a number!");
        return;
      }
      if (breakdownValue?.some((el) => el?.name === e)) {
        message.error(`${e} already exists!`);
        return;
      }
      setData(e);
    }
  };

  // adds a new breakdown
  const onAddBreakdown = () => {
    if (!data) {
      message.error("Please fill all the fields!");
      return;
    }
    set_breakdownValue([
      ...breakdownValue,
      {
        name: data,
        id: new Date().getTime().toString(),
        breakdown_dimension: 100,
        totalProgress: 0,
        progressByDay: [],
        days: [],
        [typeOfWork === "Inspection" ? "usage" : "inspections"]:
          inspectionsType,
      },
    ]);
    setData("");
  };

  const onDeleteBreakdown = (id) => {
    const breakDowns = [...breakdownValue];
    set_breakdownValue(breakDowns?.filter?.((b) => b?.id !== id));
  };

  const updateBreakdownsOnGrid = (e) => {
    const { data, newValue, colDef } = e;
    const { field } = colDef;
    const { id } = data;
    const breakdowns = [...breakdownValue];
    const breakdown = breakdowns?.find?.((b) => b?.id === id);
    breakdown[field] = newValue;
    set_breakdownValue(breakdowns);
  };

  const onMatchForemanProgress = (dayId, floor, foremanProgress) => {
    let jump_state_copy = _.cloneDeep(jump_state);
    let progressByDay_copy = [...jump_state_copy?.progressByDay];
    let progressForThisDay = progressByDay_copy?.find?.(
      (el) => el?.day === dayId?.day
    );
    if (!progressForThisDay) {
      progressForThisDay = {
        day: dayId,
        progress: floors?.map?.((el) => ({
          ...el,
          floor: el?.floor,
          progress: 0,
        })),
      };
      progressByDay_copy.push(progressForThisDay);
    }
    progressForThisDay.progress = progressForThisDay?.progress?.map?.((el) => ({
      ...el,
      progress: el?.floor === floor ? foremanProgress : el?.progress,
    }));
    jump_state_copy.progressByDay = progressByDay_copy;
    set_jump_state(jump_state_copy);
  };

  const calcPrg =
    progressByDay?.reduce?.(
      (a, { progress = [] }) =>
        a + progress?.reduce?.((b, { progress = 0 }) => b + progress, 0),
      0
    ) / jump_state?.floors?.length;

  // Stops you from working on a jump if the previous jump is not completed
  // Different for removals (Starts from the top floor)
  useEffect(() => {
    if (Boolean(jump)) {
      if (pliEditedFromNextSchedules(jump)) {
        return;
      }
      const floorsByJump = checkWhichJumpWeWorking(
        {
          ...elevation,
          jumps: elevation?.jumps?.filter(
            (jump) => !pliEditedFromNextSchedules(jump)
          ),
        },
        name
      );
      const jumpWeWorking = floorsByJump?.findIndex?.((el) => el?.exactStuff);
      const previousJumps = floorsByJump?.slice?.(
        sliceNumbers(jumpWeWorking, floorsByJump?.length)?.[typeOfWork]?.first,
        sliceNumbers(jumpWeWorking, floorsByJump?.length)?.[typeOfWork]?.last
      );
      const arePrevJumsCompleted =
        previousJumps?.length > 0
          ? previousJumps[
              typeOfWork === "Removal" ? 0 : previousJumps?.length - 1
            ]?.allStuff
          : [];
      if (typeOfWork === "Removal" && typeOfRemoval === "Full Removal") {
        return;
      } else if (checkProgressForHoist(arePrevJumsCompleted)) {
        return;
      } else {
        set_activeJump(false);
        Swal.fire({
          title: "Workflow Warning!",
          text: swalTexts?.[typeOfWork],
          icon: "warning",
        });
      }
    }
  }, [Boolean(jump)]);

  return (
    <>
      <Modal
        title={name}
        open={Boolean(jump)}
        onCancel={onCancel}
        closeIcon={<XIcon />}
        centered
        destroyOnClose={true}
        className={`jump-breakdown-schedule-modal ${
          isDarkMode && "jump-breakdown-schedule-modal-dark"
        }`}
        maskClosable={false}
        width={1300}
        height="fit-content"
        footer={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <MondayButton
              onClick={onCancel}
              {...{ Icon: <XIcon />, className: "mondayButtonRed" }}
            >
              Cancel
            </MondayButton>
            <MondayButton
              onClick={onOk}
              {...{ Icon: <Tick width={19} height={19} /> }}
            >
              Save Changes
            </MondayButton>
          </div>
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "3rem",
          }}
        >
          {typeOfWork !== "Inspection" && (
            <div
              style={{
                padding: 0,
                margin: 0,
              }}
            >
              <BorderedTextCard
                {...{
                  title:
                    prevJumpCompleted(jump) || pliEditedFromNextSchedules(jump)
                      ? ""
                      : "To add progress for a jump then add days and for each day the corresponding progress.",
                  isDarkMode,
                }}
              />
              {prevJumpCompleted(jump) ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "1rem",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <b style={{ fontSize: 35 }}>Jump is already completed!</b>
                    <span>
                      <TickIcon fill="green" width={40} height={40} />
                    </span>
                  </div>
                  <span style={{ fontSize: 20 }}>
                    You can proceed by editing in breakdowns down below!
                  </span>
                </div>
              ) : pliEditedFromNextSchedules(jump) ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "1rem",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <b style={{ fontSize: 35 }}>Jump is edited in</b>
                    <b
                      onClick={() => {
                        openInNewTab(
                          `/scheduling/${jump?.editedFrom?.scheduleId}`
                        );
                      }}
                      style={{
                        fontSize: 35,
                        color: "#1264a3",
                        cursor: "pointer",
                      }}
                    >
                      {jump?.editedFrom?.scheduleName || "another schedule!"}
                    </b>
                    <span>
                      <WarningCircleIcon fill="orange" width={40} height={40} />
                    </span>
                  </div>
                  <span style={{ fontSize: 20 }}>
                    You can proceed by editing in breakdowns down below!
                  </span>
                </div>
              ) : (
                <div
                  className={`documentationsTable ${
                    isDarkMode
                      ? "dark-ag-theme ag-theme-alpine-dark"
                      : "light-ag-theme ag-theme-alpine"
                  }`}
                  style={{ width: "100%", height: "100%", marginTop: -20 }}
                >
                  <AgGridReact
                    {...{
                      columnDefs: columnDefsForProgressHoist({
                        calcPrg,
                        jump_state,
                        scheduleDays,
                        setIsModalVisible,
                        set_jump_state,
                        elevation,
                        isDarkMode,
                      }),
                      rowData: [jump] || [],
                      animateRows: true,
                      suppressDragLeaveHidesColumns: true,
                      suppressRowClickSelection: true,
                      rowSelection: "multiple",
                      defaultColDef: {
                        resizable: true,
                        enablePivot: true,
                        enableColResize: true,
                        enableRowGroup: true,
                        editable: false,
                        sortable: true,
                        flex: 1,
                        minWidth: 100,
                        filter: true,
                      },
                    }}
                  />
                </div>
              )}
            </div>
          )}
          <div
            style={{
              padding: 0,
              margin: 0,
              display: "flex",
              flexDirection: "column",
              gap: "0.4rem",
            }}
          >
            <BorderedTextCard
              {...{
                title: "Breakdowns regarding this jump!",
                isDarkMode,
              }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                marginTop: -20,
              }}
            >
              <Tooltip trigger="hover" title={data}>
                <InputComponent
                  type="autocomplete"
                  placeholder="Add Breakdown..."
                  value={data}
                  onChange={(e) => {
                    onChangeInputs(e, "name");
                  }}
                  options={breakdownsServiceId(
                    serviceDefinitions,
                    typeOfWork === "Inspection" ? "inspections" : "breakdowns",
                    "3",
                    typeOfWork === "Inspection" ? "usage" : "inspections",
                    "pli"
                  )
                    ?.filter(
                      (el) =>
                        !breakdownValue?.some(
                          (element) => element?.name === el?.name
                        )
                    )
                    ?.map((s) => {
                      return (
                        <Select.Option value={s?.name}>{s?.name}</Select.Option>
                      );
                    })}
                />
              </Tooltip>
              <MondayButton
                onClick={onAddBreakdown}
                {...{
                  Icon: <PlusIcon />,
                }}
              >
                Add Breakdown
              </MondayButton>
            </div>
            {breakdownValue?.length > 0 && (
              <div
                className={`documentationsTable ${
                  isDarkMode
                    ? "dark-ag-theme ag-theme-alpine-dark"
                    : "light-ag-theme ag-theme-alpine"
                }`}
                style={{ width: "100%", height: "100%" }}
              >
                <AgGridReact
                  {...{
                    onCellValueChanged: (e) => {
                      updateBreakdownsOnGrid(e);
                    },
                    columnDefs: columnDefsForHoistInspection({
                      scheduleDays,
                      setBreakVisible,
                      setSpecificBreakdown,
                      onDeleteBreakdown,
                    }),
                    rowData: breakdownValue || [],
                    animateRows: true,
                    suppressDragLeaveHidesColumns: true,
                    suppressRowClickSelection: true,
                    rowSelection: "multiple",
                    defaultColDef: {
                      resizable: true,
                      enablePivot: true,
                      enableColResize: true,
                      enableRowGroup: true,
                      editable: false,
                      sortable: true,
                      flex: 1,
                      minWidth: 100,
                      filter: true,
                    },
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </Modal>
      {isModalVisible && (
        <FloorsModal
          {...{
            isModalVisible,
            setIsModalVisible,
            jump_state,
            scheduleDays,
            onChangeProgressFloor,
            onMatchForemanProgress,
            hoistDriveIds,
          }}
        />
      )}
      {breakVisible && (
        <BreakdownsSelectedDays
          {...{
            breakVisible,
            setBreakVisible,
            scheduleDays: scheduleDays?.sort((a, b) => a?.day - b?.day),
            breakdownValue,
            setBreakdownValue: set_breakdownValue,
            isHoist: true,
            specificBreakdown,
            typeOfWork,
            selectDays_jump,
            set_jump_state,
            jump_state,
            editMode,
            isDarkMode,
            updateBreakdownLogs,
          }}
        />
      )}
    </>
  );
};

const getBreakdownValueForJump = (items, jump) => {
  let breakdowns_sample = [];
  let areAllTheSame = true;

  items = filterItemsByJump(items, jump);
  for (let i = 0; i < items?.length; i++) {
    const pli = items[i];
    if (i === 0) breakdowns_sample = pli?.breakdownValue;
    else if (
      JSON.stringify(breakdowns_sample) !== JSON.stringify(pli?.breakdownValue)
    ) {
      // breakdowns_sample = []; //reset it
      areAllTheSame = false;
      break;
    }
  }
  return breakdowns_sample;
};
