import { Modal, message } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./ProgressChanges.scss";
import { InfoText } from "../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { InputComponent } from "../../../../../SidebarPages/Fleet/components";
import progressChangesFooter from "./progressChangesFooter";
import { DocUploader } from "../../../../../SidebarPages/Documentation/View/components/DocumentationModal/components";
import { useUploadedFiles } from "../../../../../../hooks";
import { driveApi } from "../../../../../../integrations/DriveRequest";
import { useSelector } from "react-redux";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { fetchProgressChangesForemenFiles } from "./fetchProgressChangesForemenFiles";
import { API } from "aws-amplify";
import { UploadDoc } from "../../../../../SidebarPages/Documentation/View/assets";
import { useGooglePicker } from "../../../../../Header/forms/Scheduling/Subcomponents/ServiceDocumentation/utils/utils";
import useTranslate from "../../../Language/useTranslate";
import findMissingBreakdownFiles from "./findMissingBreakdownFiles";
import { AlertIcon } from "../../../../../../assets";
import { MondayButton } from "../../../../../commonComponents";
import { AutoCompleteIcon } from "../../../assets/images";
import ForemanNotesModal from "./ForemanNotesModal/ForemanNotesModal";
import { EditIcon } from "../../../../../../icons";
import { TickIcon } from "../../../../Settings/settingsComponents/Roles/src";
import { hasFalsePermission } from "../../../../Settings/settingsComponents/Roles/components/UserRoles/utils";
import { hoist_dimension } from "../../../../../Header/forms/DataEntryGrid/tools/columnDefinitions/ProgressColumnDefinition";

const ProgressChanges = ({
  pliSelected = {},
  onCancel = () => {},
  d1 = "",
  d2 = "",
  dayId = "",
  detailIndex,
  serviceIndex,
  elevationIndex,
  serviceId,
  onChangeProgress = () => {},
  onSave = () => {},
  serviceName,
  estimationNumber,
  endDate,
  googleDriveFolderIds,
  detail,
  service,
  elevationId,
  scheduleId,
  typeOfWork = "",
  estNr,
  autoCompleteItems = () => {},
  fullElevationName,
}) => {
  const { t } = useTranslate();
  const { data: row = [], rowIndex } = pliSelected || {};
  const { progressByDay = [], typeOfProgress = "" } = row || {};
  const dayWeEditing =
    progressByDay?.find((progressDay) => progressDay?.day === dayId) || {};

  const [openNotes, setOpenNotes] = useState({
    visible: false,
    type: "",
  });

  const isHoist = service?.isHoist || false;

  const [newFolderId, setNewFolderId] = useState("");
  const [breakdownFiles, setBreakdownFiles] = useState({});
  const [initialBreakdownFiles, setInitialBreakdownFiles] = useState({});
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { accessToken } = useSelector((state) => state.accessToken);
  // =================== Upload ===================
  const { getFolderIdOrCreate, deleteDriveItem, list } = driveApi({
    accessToken,
  });
  const [uploadHandlers, deleteHandlers, logFn] = useUploadedFiles([]);
  const [uploadedFiles, setUploadedFiles, formattedFiles, onPickerSuccess] =
    uploadHandlers;
  const [filesToBeDeleted, setFilesToBeDeleted, onDelete] = deleteHandlers;
  const [autoFileLogs, manualFileLogs] = logFn;
  // =================== Upload ===================

  const { docs, openGooglePicker } = useGooglePicker();

  const endDateKey = dayjsNY(endDate).format("MM/DD/YYYY");

  const folderIds = useMemo(() => {
    let docLabel = "";
    if (!estimationNumber) {
      docLabel = `${serviceName} -`;
    } else {
      docLabel = `${serviceName} - ${estimationNumber}`;
    }
    const dayFolderId =
      googleDriveFolderIds?.[docLabel]?.[endDateKey] || newFolderId;
    let serviceFolderId = "";
    serviceFolderId = googleDriveFolderIds?.[docLabel]?.id;
    if (!serviceFolderId) {
      let tmpLabel = `${serviceName} - ${estNr}`;
      serviceFolderId = googleDriveFolderIds?.[tmpLabel]?.id;
      docLabel = tmpLabel;
    }
    return {
      dayFolderId,
      serviceFolderId,
      documentationLabel: docLabel,
    };
  }, [newFolderId, serviceName]);

  let data = [];
  if (typeOfProgress === "dimensions" || typeOfProgress === "" || isHoist) {
    if (!d2) {
      if (d1 === hoist_dimension) {
        const floorWeEditing = dayWeEditing?.["progress"]?.find(
          (floor) => floor?.floor === row?.floor
        )?.floor;
        data = [floorWeEditing || 0];
      } else {
        data = [d1];
      }
    } else {
      data = [d1, d2, "Sqf"];
    }
  }

  const jumpWeEditing = service?.serviceOptions?.[elevationIndex]?.jumps?.find(
    (jump) => jump?.name === row?.jump
  );

  const addForemanDataToJump = jumpWeEditing?.progressByDay
    ?.find((day) => day?.day === dayId)
    ?.progress?.find((floor) => floor?.floor === data[0]);

  const value = (dimension = "") => {
    const value = isHoist
      ? jumpWeEditing?.progressByDay
          ?.find((day) => day?.day === dayId)
          ?.progress?.find((floor) => floor?.floor === dimension)?.foreman
          ?.progress || 0
      : "foreman" in dayWeEditing
      ? dayWeEditing?.["foreman"]?.[dimension]
      : null;

    return value;
  };

  const breakdowns = useMemo(() => {
    if (typeOfProgress === "breakdown") {
      return (
        row?.breakdownValue
          ?.filter((a) => a?.days?.includes(dayId))
          ?.map((breakdown) => {
            return {
              name: breakdown?.name,
              progress:
                breakdown?.progressByDay?.find(({ day = "" }) => day === dayId)
                  ?.foreman?.progress || 0,
              notes:
                breakdown?.progressByDay?.find(({ day = "" }) => day === dayId)
                  ?.foreman?.notes || "",
              id: breakdown?.id,
              files:
                breakdown?.progressByDay?.[0]?.["files"]?.[
                  userConfiguration?.cognitoUserId
                ] || [],
            };
          }) || []
      );
    } else {
      return [];
    }
  }, [pliSelected, row?.breakdownValue, dayId]);

  async function deleteFilesArray(filesToBeDeleted) {
    if (filesToBeDeleted?.length > 0) {
      await Promise.allSettled(
        filesToBeDeleted.map(async (id) => await deleteDriveItem(id))
      );
    }
  }

  async function onModalCancel() {
    setFilesToBeDeleted([]);
    const files = uploadedFiles
      .filter((file) => file?.newDocFile)
      .map(({ id }) => id);

    await deleteFilesArray(files);

    onCancel();
  }

  const getBreakdownFileLogs = useCallback(() => {
    const curr = breakdownFiles;
    const prev = initialBreakdownFiles;
    const keys = Object.keys(curr);
    let logs = {};
    keys.forEach((key) => {
      const currFiles = curr[key];
      const fullCurr =
        currFiles?.length === 0
          ? []
          : uploadedFiles?.filter(({ id = "" }) => currFiles?.includes(id)) ||
            [];
      const prevFiles = prev[key] || [];
      logs[key] = manualFileLogs(prevFiles, fullCurr);
    });
    return logs;
  }, [breakdownFiles, initialBreakdownFiles, uploadedFiles]);

  //region SAVE
  async function onModalSave() {
    if (filesToBeDeleted?.length > 0) {
      await deleteFilesArray(filesToBeDeleted).then(() =>
        setFilesToBeDeleted([])
      );
    }
    if (uploadedFiles?.length === 0) {
      message.error({
        content: t(
          "Please upload at least one photo before saving progress changes."
        ),
        duration: 5,
        key: "upload",
      });
      return;
    }
    if (newFolderId) {
      await API.put("scheduling", `/scheduling/${scheduleId}`, {
        body: {
          googleDriveFolderIds: {
            ...googleDriveFolderIds,
            [folderIds.documentationLabel]: {
              ...googleDriveFolderIds[folderIds.documentationLabel],
              [endDateKey]: newFolderId,
            },
          },
        },
      });
    }
    const filesToSave =
      typeOfProgress !== "breakdown"
        ? {
            [userConfiguration.cognitoUserId]: (formattedFiles || []).map(
              (file) => file.id
            ),
          }
        : { userId: userConfiguration.cognitoUserId, files: breakdownFiles };

    const fileLogs =
      typeOfProgress !== "breakdown" ? autoFileLogs() : getBreakdownFileLogs();

    onSave(
      detail,
      service,
      elevationId,
      row?.id,
      typeOfProgress === "breakdown" ? breakdowns : false,
      filesToSave,
      fullElevationName,
      [isHoist, row?.jump, data[0]],
      fileLogs
    ).then(onCancel);
  }

  async function getDriveFilesByForeman(folderId) {
    let foremanFiles = {};
    const foremanId = userConfiguration.cognitoUserId;
    let breakdownFilesObj = {};

    if (typeOfProgress === "breakdown") {
      const allFiles = breakdowns
        ?.map((el) => {
          breakdownFilesObj[el?.id] = el?.files || [];
          return el?.files || [];
        })
        .flat();
      foremanFiles = { [foremanId]: allFiles };
    } else if (!!isHoist) {
      foremanFiles = addForemanDataToJump?.files || {};
    } else {
      foremanFiles = dayWeEditing?.files || {};
    }

    await fetchProgressChangesForemenFiles({
      folderId,
      list,
      foremanFiles,
      userConfiguration,
      foremanId,
    }).then((res) => {
      if (typeOfProgress === "breakdown") {
        const { missingElements, updatedObj } = findMissingBreakdownFiles(
          res,
          breakdownFilesObj
        );
        setBreakdownFiles(updatedObj);
        setInitialBreakdownFiles(
          Object.keys(updatedObj || {})?.reduce((acc, key) => {
            acc[key] = res.filter(({ id }) => updatedObj[key]?.includes(id));
            return acc;
          }, {})
        );
      }
      setUploadedFiles(res, true);
    });
  }

  async function createFolderAndGetFiles() {
    const name = endDateKey;
    const parent = folderIds?.serviceFolderId;
    const dayFolder = folderIds?.dayFolderId;
    if (!!dayFolder) {
      await getDriveFilesByForeman(dayFolder);
      return;
    }
    if (!!parent) {
      await getFolderIdOrCreate({ name, parents: [parent] }).then(
        async (id) => {
          setNewFolderId(id);
          await getDriveFilesByForeman(id);
        }
      );
    } else {
      message.error({
        duration: 5,
        content:
          "You cant upload files at this time, please contact your administrator.",
      });
    }
  }

  useEffect(() => {
    createFolderAndGetFiles();
  }, []);

  function openBreakdownPicker(breakdown) {
    openGooglePicker({
      parentId: folderIds?.dayFolderId,
    }).then((doc) => {
      onPickerSuccess({ uploadedFiles: doc });
      const onlyIds = doc.map(({ id }) => id);
      setBreakdownFiles((prev) => ({
        ...prev,
        [breakdown?.id]: [...(prev[breakdown?.id] || []), ...onlyIds],
      }));
    });
  }

  const onChangeNotes = (e) => {
    if (typeOfProgress === "breakdown") {
      setOpenNotes({ ...openNotes, type: { ...openNotes?.type, notes: e } });
    }
    onChangeProgress(
      e,
      detailIndex,
      serviceIndex,
      elevationIndex,
      rowIndex,
      serviceId,
      !openNotes?.type ? "notes" : ["notes"],
      openNotes?.type,
      [isHoist, data[0]]
    );
  };

  function spreadFilesForBreakdown(breakdownId) {
    return (
      uploadedFiles.filter(({ id }) =>
        breakdownFiles?.[breakdownId]?.includes(id)
      ) || []
    );
  }

  function onBreakdownFileDelete(fileId, breakdownId) {
    onDelete(fileId);
    setBreakdownFiles((prev) => ({
      ...prev,
      [breakdownId]: prev[breakdownId].filter((id) => id !== fileId),
    }));
  }

  return (
    <Modal
      {...{
        title: t("Editing"),
        open: Object.keys(pliSelected || {}).length > 0,
        onCancel: onModalCancel,
        centered: true,
        footer: progressChangesFooter({
          onCancel: onModalCancel,
          onSave: onModalSave,
          t,
        }),
        className: "progress-changes-modal",
        closeIcon: <XIcon />,
        maskClosable: false,
        keyboard: false,
        destroyOnClose: true,
      }}
    >
      {typeOfProgress === "breakdown" && (
        <div className="progress-body-dimensions table">
          <InfoText
            {...{
              text: `${breakdowns?.length} ${
                breakdowns?.length > 1 ? t("items") : t("item")
              } ${t("found")}`,
              style: { padding: "0 20px" },
              isDarkMode: false,
            }}
          />
          <div className="breakdown-table">
            <div className="breakdown-header">
              <div>{t("Name")}</div>
              <div>{t("Daily")} %</div>
              <div>{t("Actions")}</div>
            </div>
            {breakdowns?.map((breakdown, i) => {
              return (
                <div className="breakdown-el" key={i}>
                  <div className="breakdown-header breakdown-item">
                    <div
                      style={{
                        display: "flex",
                        gap: 5,
                        flexWrap: "nowrap",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{breakdown?.name}</span>
                      <AutoCompleteIcon
                        style={{
                          cursor: "pointer",
                          flexShrink: 0,
                          height: 20,
                          width: 20,
                        }}
                        fill="#68C142"
                        onClick={() =>
                          autoCompleteItems(breakdown, "breakdowns")
                        }
                      />
                    </div>
                    <InputComponent
                      className="progress-input"
                      typeTel={true}
                      label={""}
                      noFormItem={true}
                      value={breakdown?.progress}
                      onChange={(e) => {
                        if (Number(e.target.value) > 100) {
                          message.error("Progress should be less than 100%");
                          return;
                        }
                        onChangeProgress(
                          Number(e.target.value),
                          detailIndex,
                          serviceIndex,
                          elevationIndex,
                          rowIndex,
                          serviceId,
                          ["progress", d1, d2],
                          breakdown,
                          isHoist
                        );
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingLeft: 0,
                        gap: 10,
                      }}
                    >
                      <UploadDoc
                        {...{
                          ...(!folderIds?.dayFolderId
                            ? { fill: "#FE4C4A" }
                            : {}),
                          style: { cursor: "pointer" },
                          onClick: () => {
                            if (!folderIds?.dayFolderId) {
                              return;
                            }
                            openBreakdownPicker({ ...breakdown, index: i });
                          },
                        }}
                      />
                      <EditIcon
                        fill="#1264A3"
                        onClick={() =>
                          setOpenNotes({ visible: true, type: breakdown })
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <DocUploader
                    {...{
                      uploadedFiles: spreadFilesForBreakdown(breakdown?.id),
                      checkWarnings: false,
                      responsiveWidth: 5000,
                      folderId: folderIds?.dayFolderId,
                      onlyUploadedFiles: true,
                      onPickerSuccess: (doc) => {
                        onPickerSuccess(doc);
                        const onlyIds = doc.uploadedFiles.map(({ id }) => id);
                        setBreakdownFiles((prev) => ({
                          ...prev,
                          [breakdown?.id]: [
                            ...(prev[breakdown?.id] || []),
                            ...onlyIds,
                          ],
                        }));
                      },
                      onDelete: (fileId) =>
                        onBreakdownFileDelete(fileId, breakdown?.id),
                      disabled: !folderIds?.dayFolderId,
                      previewModal: "drawOnPhoto",
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
      {(typeOfProgress === "dimensions" ||
        typeOfProgress === "" ||
        typeOfProgress === "jumpBreakdown") && (
        <div className="progress-body-dimensions">
          <div className="progress-header">
            {d1 === hoist_dimension
              ? ""
              : data?.map((key, index) => {
                  return (
                    <div key={index} className="progress-details-label">
                      <span className="title">{t(key)}: </span>
                      <span className="number">
                        {row?.[key === "Sqf" ? "surface" : key]}
                      </span>
                      {dayWeEditing?.foreman?.approved && key !== "Sqf" && (
                        <TickIcon fill="#68C142" />
                      )}
                    </div>
                  );
                })}
          </div>
          {!d2 ? (
            <div className="dimensions-inputs">
              <InputComponent
                className={`progress-input${
                  value(d1) > row?.[d1] ? "error" : ""
                }`}
                typeTel={true}
                label={d1 === hoist_dimension ? `Floor ${data[0]}` : t(d1)}
                noFormItem={true}
                value={value(isHoist ? data[0] : d1)}
                onChange={(e) => {
                  onChangeProgress(
                    Number(e.target.value),
                    detailIndex,
                    serviceIndex,
                    elevationIndex,
                    rowIndex,
                    serviceId,
                    isHoist ? "progress" : d1,
                    false,
                    [isHoist, data[0]]
                  );
                }}
              />

              {!isHoist && (
                <AutoCompleteIcon
                  fill="green"
                  className="auto-complete-icon"
                  onClick={() => autoCompleteItems([d1], "dimensions")}
                />
              )}
              {value(d1) > row?.[d1] && (
                <span style={{ marginBottom: "5px" }}>
                  <AlertIcon fill="red" />
                  Over {typeOfWork}!
                </span>
              )}
            </div>
          ) : (
            <div className="dimensions-inputs">
              <InputComponent
                className={`progress-input${
                  value(d1) * value(d2) > row?.surface ? "error" : ""
                }`}
                typeTel={true}
                label={t(d1)}
                noFormItem={true}
                value={value(d1)}
                onChange={(e) => {
                  onChangeProgress(
                    Number(e.target.value),
                    detailIndex,
                    serviceIndex,
                    elevationIndex,
                    rowIndex,
                    serviceId,
                    d1,
                    false,
                    isHoist
                  );
                }}
              />
              <InputComponent
                className={`progress-input${
                  value(d2) * value(d1) > row?.surface ? "error" : ""
                }`}
                typeTel={true}
                label={t(d2)}
                noFormItem={true}
                value={value(d2)}
                onChange={(e) => {
                  onChangeProgress(
                    Number(e.target.value),
                    detailIndex,
                    serviceIndex,
                    elevationIndex,
                    rowIndex,
                    serviceId,
                    d2,
                    false,
                    isHoist
                  );
                }}
              />
              <AutoCompleteIcon
                style={{ cursor: "pointer" }}
                fill="#68C142"
                className="auto-complete-icon"
                onClick={() => autoCompleteItems([d1, d2], "dimensions")}
              />
              {value(d1) * value(d2) > row?.surface && (
                <span style={{ marginBottom: "5px" }}>
                  <AlertIcon fill="red" />
                  Over {typeOfWork}!
                </span>
              )}
            </div>
          )}
          <MondayButton
            className="mondayButtonBlue"
            onClick={() => setOpenNotes({ visible: true, type: false })}
            Icon={<EditIcon />}
          >
            {t("Add Notes")}
          </MondayButton>
          <DocUploader
            {...{
              uploadedFiles,
              onDelete,
              onPickerSuccess,
              folderId: folderIds?.dayFolderId,
              checkWarnings: false,
              responsiveWidth: 10,
              disabled: !folderIds?.dayFolderId,
              previewModal: "drawOnPhoto",
            }}
          />
        </div>
      )}
      {openNotes?.visible && (
        <ForemanNotesModal
          {...{
            openNotes,
            setOpenNotes,
            onChangeNotes,
            dayWeEditing: isHoist ? addForemanDataToJump : dayWeEditing,
            userConfiguration,
          }}
        />
      )}
    </Modal>
  );
};

export default ProgressChanges;

const dimensionsLogs = {
  prev: {
    day: {
      estNr: {
        service: {
          elevation: {
            pli: {
              dimensions: { l: "length1", h: "height1" },
              // ...fileLogs.prev,
            },
          },
        },
      },
    },
  },
  curr: {
    day: {
      estNr: {
        service: {
          elevation: {
            pli: {
              dimensions: { l: "length2", h: "height2" },
              // ...fileLogs.curr,
            },
          },
        },
      },
    },
  },
};

const breakDownLogs = {
  curr: {
    day: {
      estNr: {
        service: {
          elevation: {
            pli: {
              breakdownsName1: {
                files: {
                  // ...fileLogs[breakdownId].curr,
                },
                progress: {},
              },
              brName2: {},
            },
          },
        },
      },
    },
  },
  prev: {
    day: {
      estNr: {
        service: {
          elevation: {
            pli: {
              breakdownsName1: {
                files: {
                  // ...fileLogs[breakdownId].prev,
                },
                progress: {},
              },
            },
          },
        },
      },
    },
  },
};
