import { isEmpty } from "lodash";
import {
  getFormattedAddon,
  getFormattedPLI,
} from "../../../../../../../../../SidebarPages/Estimations/DataEntryGrid/subcomponents/Header/WordFactory/process/process";
import { checkIfRowHasValidData } from "../../../../../../../../../SidebarPages/Estimations/DataEntryGrid/tools/formatters/validators";

//region styleRender
function styleRender(key, value) {
  switch (key) {
    case "font-size":
    case "padding-left":
    case "margin-left":
      return `${key}: ${value}px;`;
    case "color":
    case "background-color":
      return `${key}: ${value};`;
    default:
      return "";
  }
}
const defaultStyles = `width: fit-content; padding-right: 5px; text-wrap: normal;`;

//region styleToString
export const convertStyleToString = (style) => {
  if (!style) return "";
  const styleString = Object.entries(style)
    .filter(([key, value]) => value !== undefined)
    .map(([key, value]) => styleRender(key, value))
    .filter(Boolean)
    .join(" ");

  return `${defaultStyles} ${styleString}`;
};

//region formatText
export const formatTextProposal = (text, format, index = 1) => {
  let formattedText = text;
  if (!formattedText) return "";
  const style = convertStyleToString(format);
  if (format?.listType === "ul") {
    formattedText = `<ul style="${style}"><li>${formattedText}</li></ul>`;
  } else if (format?.listType === "ol") {
    formattedText = `<ol style="${style}"><li value="${index}">${formattedText}</li></ol>`;
  } else {
    formattedText = formattedText;
  }

  if (format?.bold) {
    formattedText = `<b>${formattedText}</b>`;
  }
  if (format?.italic) {
    formattedText = `<i>${formattedText}</i>`;
  }
  if (format?.underline) {
    formattedText = `<u>${formattedText}</u>`;
  }
  if (format?.["text-format"]) {
    formattedText = `<${format["text-format"]}>${formattedText}</${format["text-format"]}>`;
  }
  return formattedText;
};

//region renderPLIs
const renderItems = (items, styles, itemIndex, serviceId) => {
  if (isEmpty(items)) return "";
  return items
    .map((item, index) => {
      if (!checkIfRowHasValidData(item)) return "";
      const pliData = getFormattedPLI(item, serviceId);
      const { addons } = item;
      let itemHtml = `<div class="item">
        <div class="item-label" style="${convertStyleToString(
          styles.itemLabel
        )} ">
          ${formatTextProposal(
            pliData,
            styles.itemLabel,
            itemIndex + index + 1
          )}
        </div>`;
      if (addons) {
        addons.forEach((addon, idx) => {
          const addonLabel = getFormattedAddon(addon, serviceId);
          itemHtml += `<div class="addon" style="${convertStyleToString(
            styles.addonLabel
          )}">
            ${formatTextProposal(addonLabel, styles.addonLabel, idx + 1)}
          </div>`;
        });
      }
      itemHtml += `</div>`;
      return itemHtml;
    })
    .join("");
};

//region addElevationStyles

/**
 * Adds elevation styles to the provided data.
 *
 * @param {Array} data - An array of elevation objects.
 * @param {Object} styles - An object containing style configurations.
 * @param {Object} styles.elevationLabel - Styles for the elevation label.
 * @param {Object} styles.elevationDesc - Styles for the elevation description.
 * @param {Object} styles.notes - Styles for the notes.
 * @returns {string} - A string of HTML with the applied elevation styles.
 */
function addElevationStyles(elevations, styles, serviceId) {
  const toReturn = elevations
    .map((elevation, index) => {
      const elevationLabel =
        elevation.elevationLabel + " " + elevation?.elevationId;
      let elevationHtml = `<div class="elevation">
            <div class="elevation-label" style="${convertStyleToString(
              styles.elevationLabel
            )}">
              ${formatTextProposal(
                elevationLabel,
                styles.elevationLabel,
                index + 1
              )}
            </div>
            ${
              elevation.elevationDescription
                ? `<div class="elevation-desc" 
            style="${convertStyleToString(styles.elevationDesc)}"
                  >
              ${formatTextProposal(
                elevation.elevationDescription,
                styles.elevationDesc
              )}
            </div>`
                : ""
            }
            ${renderItems(elevation.items, styles, index, serviceId)}
            ${
              elevation.notes
                ? formatTextProposal(elevation.notes, styles.notes)
                : ""
            }
          </div>`;
      return elevationHtml;
    })
    .join("");

  return toReturn;
}

export default addElevationStyles;
