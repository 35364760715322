import React from "react";
import { AutoComplete, Input } from "antd";
import { useState, useCallback } from "react";
import LabeledInput from "../LabeledInput/LabeledInput";
import { NYC_CENTER } from "../../../../commonComponents/Map/mapData";
import _ from "lodash";

const PlacesInputWithCoordinates = ({
  form,
  label = "",
  formItemName = "",
  initialValue = "",
  className = "",
  placeholder = "",
  popupClassName = "",
  dropdownStyle = "",
  noFormItem = false,
  required = false,
  disabled = false,
  style = {},
  dataTestid,
  value,
  name = "",
}) => {
  const [predictions, setPredictions] = useState([]);
  const [changingValue, setChangingValue] = useState("");
  const [selectedAddress, setSelectedAddress] = useState(() => {
    const formValue = form?.getFieldValue(formItemName);
    return formValue?.address || initialValue?.address || initialValue || "";
  });

  const [autocompleteService] = useState(
    new google.maps.places.AutocompleteService()
  );
  const [geocoder] = useState(new google.maps.Geocoder());

  const handleBlur = () => {
    const currentValue = changingValue || selectedAddress;

    form.setFieldsValue({
      [formItemName]: {
        address: currentValue,
        coordinates: form.getFieldValue(formItemName)?.coordinates || null,
      },
    });
    setPredictions([]);
  };

  const handleClear = (val) => {
    if (!val) {
      setChangingValue("");
      setSelectedAddress("");
      setPredictions([]);
      form.setFieldsValue({
        [formItemName]: {
          address: "",
          coordinates: null,
        },
      });
    }
  };

  const onSearch = useCallback(
    _.debounce(async (value) => {
      if (!autocompleteService || !value) return;

      setChangingValue(value);
      setSelectedAddress(value);

      try {
        let results = [];
        if (value.length > 2) {
          const response = await autocompleteService.getPlacePredictions({
            input: value,
            language: "en-US",
            locationBias: {
              center: NYC_CENTER,
              radius: 200000,
            },
          });
          results = response?.predictions || [];
        }
        setPredictions(results);
      } catch (err) {
        console.log("Error searching: ", err);
      }
    }, 500),
    [autocompleteService]
  );

  const onSelection = async (val) => {
    setSelectedAddress(val);
    setChangingValue(val);
    setPredictions([]);

    try {
      const result = await geocoder.geocode({ address: val });
      if (result.results?.[0]?.geometry?.location) {
        const location = result.results[0].geometry.location;

        form.setFieldsValue({
          [formItemName]: {
            address: val,
            coordinates: {
              lat: location.lat(),
              lng: location.lng(),
            },
          },
        });
      } else {
        form.setFieldsValue({
          [formItemName]: {
            address: val,
            coordinates: null,
          },
        });
      }
    } catch (error) {
      console.error("Geocoding error:", error);
      form.setFieldsValue({
        [formItemName]: {
          address: val,
          coordinates: null,
        },
      });
    }
  };

  function renderOptions() {
    return [
      {
        label: "Google Results",
        options: predictions?.map(({ place_id, description }) => ({
          key: place_id,
          value: description,
        })),
      },
      changingValue && {
        label: "Custom Results",
        options: [
          {
            key: "1",
            value: changingValue,
          },
        ],
      },
    ].filter(Boolean);
  }

  const displayValue =
    changingValue ||
    selectedAddress ||
    form.getFieldValue(formItemName)?.address ||
    "";

  return (
    <LabeledInput
      label={label}
      noFormItem={true}
      className={className}
      style={style}
      required={required}
    >
      <AutoComplete
        value={displayValue}
        style={{ width: "100%" }}
        onSelect={(val) => {
          onSelection(val);
        }}
        onSearch={_.debounce(onSearch, 500, { trailing: true })}
        onChange={(val) => {
          if (!val) {
            handleClear(val);
          } else {
            setChangingValue(val);
          }
        }}
        onBlur={handleBlur}
        disabled={disabled}
        placeholder={placeholder}
        popupClassName={popupClassName}
        dropdownStyle={dropdownStyle}
        options={renderOptions()}
        showSearch
        allowClear
        popupMatchSelectWidth={false}
        data-testid={dataTestid}
      >
        <Input />
      </AutoComplete>
    </LabeledInput>
  );
};

export default PlacesInputWithCoordinates;
