import { generatePolygonImage } from "../../components/commonComponents/Map/Geofence/shapesImagesGeneratorForFences";
import { calculateZoomLevel } from "../../components/commonComponents/Map/Geofence/utils";
import { fetchData } from "../ApiMethods";

export const getProjects = async ({ keysToInclude }) => {
  const res = await fetchData({
    endpoint: "projects",
    ...(keysToInclude.length && {
      queryStringParams: {
        queryStringParameters: {
          withPagination: "true",
          getMaxLimit: "true", // When this key is set to "true", data retrieval is based on the total size limit, not restricted to just 24 records.
          keysToInclude: JSON.stringify(keysToInclude),
        },
      },
    }),
  });

  const destructedRes = await Promise.all(
    res.projects.map(async (r) => {
      return {
        ...r,
        geoFenceInfo: await Promise.all(
          r.geoFenceInfo.map(async (geo) => {
            const image = await generatePolygonImage({
              polygonCoordinates: geo.geoFenceInfo,
              // zoomOfMap: calculateZoomLevel(geo.geoFenceInfo),
            });
            return {
              ...geo,
              image,
            };
          })
        ),
      };
    })
  );

  return destructedRes;
};
