import React from "react";
import "./LoadingDiamonds.scss";
import { useSelector } from "react-redux";

const LoadingDiamonds = ({ style = {}, className = "", componentTestId }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  return (
    <div
      className={`maskLoadingDiamonds ${className}`}
      style={style}
      data-testid={componentTestId ?? "loading-diamonds"}
    >
      <div className={`loadingDiamonds ${isDarkMode && "loadingDiamondsDark"}`}>
        {/* <div className={`loadingDiamonds`}> */}
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default LoadingDiamonds;
