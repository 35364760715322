import { combineReducers } from "redux";
import estimations from "./estimations";
import accounts from "./accounts";
import opportunities from "./opportunities";
import leads from "./leads";
import projects from "./tasks";
import scheduleOfValuesReducer from "./scheduleOfValues";
import editableFormReducer from "./editableForm";
import applicationsReducer from "./applications";
import schedule from "./schedule";
import authenticatedUserReducer from "./authUser";
import userConfigReducer from "./userConfigReducer";
import {
  programFields,
  base64,
  hotCredentials,
  serviceDefinitions,
  preferences,
  proposedTypeOfWork,
  fleetFields,
  accessToken,
  loggedOnTasks,
  proposedConstructionServices,
  notification,
  topicCategories,
  userGroupsReducer,
  sampleObjectsReducer,
  communicationReducer,
  googleAuthReducer,
  yardsReducer,
  userImages,
  sessionNotificationsReducer,
  customerUsers,
  teams,
} from "./commonsStates";
import rentalsReducer from "./rentals";
import { chargeReducer } from "./Charge/reducer";
import customReducer from "../components/SidebarPages/hooks/myReducer";
import booleanIfContact from "./booleanIfContact";
import notificationsReducer from "./notificationsReducer";
import darkModeReducer from "./darkModeReducer";
import notificationSettingsReducer from "./notificationSettingsReducer";
import approvalsReducer from "./approvalsReducer";

export const rootReducer = combineReducers({
  estimations: estimations,
  accounts: accounts,
  opportunities: opportunities,
  leads: leads,
  tasks: projects,
  companyDetails: "companyDetailsReducer",
  scheduleOfValues: scheduleOfValuesReducer,
  editableForm: editableFormReducer,
  applications: applicationsReducer,
  notifications: notificationsReducer,
  rentals: rentalsReducer,
  schedule: schedule,
  authenticatedUser: authenticatedUserReducer,
  userConfig: userConfigReducer,
  programFields: programFields,
  userGroups: userGroupsReducer,
  base64: base64,
  hotCredentials: hotCredentials,
  serviceDefinitions: serviceDefinitions,
  preferences: preferences,
  proposedTypeOfWork: proposedTypeOfWork,
  fleetFields: fleetFields,
  accessToken,
  loggedOnTasks,
  proposedConstructionServices,
  notification,
  customReducer,
  charge: chargeReducer,
  ifContact: booleanIfContact,
  topicCategories,
  darkMode: darkModeReducer,
  notificationSettings: notificationSettingsReducer,
  sampleObjects: sampleObjectsReducer,
  communication: communicationReducer,
  googleAuth: googleAuthReducer,
  approvals: approvalsReducer,
  yards: yardsReducer,
  userImages,
  sessionNotifications: sessionNotificationsReducer,
  customerUsers,
  teams,
});
