import { API } from "aws-amplify";
import { message } from "antd";
import uniqBy from "lodash/uniqBy";
import { v4 as uuidv4 } from "uuid";

import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import { getCoordinatesAndZip } from "../../../../utils";
import { getChangedData } from "../../../../../Accounting/components/utilities";
import {
  batchPost,
  getCognitosForNotification,
  updateTeamsConfiguration,
} from "../../../../../../../utils";
import broadcastNotification from "../../../../../../../helpers/controllers/broadcastNotification";
import { MondayButton } from "../../../../../../commonComponents";
import { VideoTutorialIcon } from "../../../../../../Header/components/ModalVideoTutorials/assets/svg";
import { updateViolationsForActivities } from "../../../../violations/modals/NewViolationModal/helpers";

/** @typedef {import("../../../../../FleetMaintenanceView/types").VendorType} */

const FLEET_TOPIC_ID = "11";
// const SCHEDULING_TOPIC_ID = "14";
// const ESTIMATION_TOPIC_ID = "3";
// const PROJECT_TOPIC_ID = "4";

/**
 * Dispatch saving function
 * @param {Object} param
 * @param {FormInstance} param.form Pointer to the form instance
 * @param {Array} param.fleet List of fleets
 * @param {VendorType[]} param.vendors List of vendors
 * @param {Object} param.authenticatedUser The authenticate user object
 * @param {Array} param.drivers List of drivers
 * @param {Number} param.length The final route length without the return
 * @param {Function} param.refreshTable Updates the main page's AgGrid
 * @param {Array} param.schedules A list of schedules
 * @param {Array} param.projects A list of projects
 * @param {Array} param.listOfLabels The list of labels, used to put a project id in the routes that need it
 * @param {Object} param.rowObject The selected dispatch from the grid
 * @param {Function} param.setAllDispatches State setter for the new created dispatch
 * @param {Array} param.cancelledRoutes The list of initial routes that can either be cancelled or not
 * @param {Function | null} param.defaultSaveHandler The save handler to the schedule creation
 * @param {Object} param.userConfiguration Redux state
 * @param {Array} param.items The member selection
 * @param {Record<index, any[]>} param.itineraryData
 * @param {WebSocket} param.dispatchSocket
 * @param {number[]} param.varianceRoutes
 */
export async function saveDispatch({
  form,
  fleet,
  vendors,
  authenticatedUser,
  drivers,
  length,
  refreshTable,
  schedules,
  projects,
  listOfLabels,
  rowObject,
  setAllDispatches,
  cancelledRoutes,
  listOfDistances,
  varianceRoutes,
  hoistRoutes,
  rowScheduleChanges,
  userConfiguration,
  items,
  updateProgressStatus,
  setVisibleCreationProgress,
  itineraryData,
  saveAddedLogs,
  dispatchSocket,
}) {
  message.loading({ content: "Saving...", key: "dispatch" });
  let dispatchRoutes = [];
  let existingFields = form.getFieldsValue();
  let loggedUser = `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`;

  //gets the label indicator from the label JSX element
  let labels = listOfLabels?.map((el) => {
    let t = getAllTextFromTags(el);
    if (t?.find((e) => /^\(Schedule.*/.test(e))) {
      return "schedule";
    } else if (t?.includes("Project (off schedule)")) {
      return "project";
    } else if (t?.includes("(Yard)")) {
      return "yard";
    } else if (t?.includes("(Vendor)")) {
      return "vendor";
    } else {
      return "";
    }
  });

  for (let i = 0; i < length + 1; i++) {
    //condition to get the return route
    let c = i === length;

    let scheduleAddress = "",
      scheduleId = "";

    if (c) {
      // For return route, get schedule from the last  route
      let lastRouteSchedule = schedules?.find(
        (el) => el?.scheduleId === existingFields[`Schedule#${length - 1}`]
      );
      if (!!lastRouteSchedule) {
        scheduleAddress = lastRouteSchedule?.scheduleAddress;
        scheduleId = lastRouteSchedule?.scheduleId;
      }
    } else {
      let s = schedules?.find(
        (el) => el?.scheduleId === existingFields[`Schedule#${i}`]
      );
      if (!!s) {
        scheduleAddress = s?.scheduleAddress;
        scheduleId = s?.scheduleId;
      }
    }

    let pickupLocation =
      existingFields[`${c ? "Return" : ""}PickupLocation#${!c ? i : ""}`];
    let dropOffLocation =
      existingFields[`${c ? "Return" : ""}DropOffLocation#${!c ? i : ""}`];
    let coordinates = await Promise.allSettled([
      getCoordinatesAndZip(pickupLocation),
      getCoordinatesAndZip(dropOffLocation),
    ])
      .then(([{ value: pickup }, { value: dropOff }]) => {
        return {
          pickupCoordinates: pickup?.coordinates,
          dropOffCoordinates: dropOff?.coordinates,
        };
      })
      .catch((err) => {
        console.log("Error getting coordinates: ", err);
      });

    if (!coordinates?.pickupCoordinates || !coordinates?.dropOffCoordinates) {
      message.error({
        content:
          "Something went wrong while trying to get location coordinates, please try again",
        key: "coordsError",
      });
      throw new Error("No coordinates");
    }

    let driver = drivers?.find(
      (el) =>
        el?.driverId ===
        existingFields[`${c ? "Return" : ""}Driver#${!c ? i : ""}`]
    );

    let projectId = "";
    let vendorId = "";

    if (c) {
      // For return route, get project ID from the last  route
      if (
        labels?.[length - 1] === "project" ||
        labels?.[length - 1] === "schedule"
      ) {
        projectId = projects?.find(
          (el) =>
            el?.projectName === existingFields[`DropOffLocation#${length - 1}`]
        )?.projectId;
      } else if (labels?.[length - 1] === "vendor") {
        vendorId =
          vendors?.find(
            ({ vendorAddress }) =>
              vendorAddress === existingFields[`DropOffLocation#${length - 1}`]
          )?.vendorId || "";
      }
    } else {
      if (labels?.[i] === "project" || labels?.[i] === "schedule") {
        projectId = projects?.find(
          (el) => el?.projectName === dropOffLocation
        )?.projectId;
      } else if (labels?.[i] === "vendor") {
        vendorId =
          vendors?.find(
            ({ vendorAddress }) => vendorAddress === dropOffLocation
          )?.vendorId || "";
      }
    }

    let baseSelector = c ? "Return" : "";
    let indexSelector = !c ? i : "";

    let tmp = {
      driverName: driver?.driverName,
      driverSub: driver?.driverSub ?? "",
      driverId: driver?.driverId,
      scheduleId,
      vendorId,
      routeLength: listOfDistances[i],
      scheduleAddress,
      routeName: c ? "Return Route" : `Route ${i + 1}`,
      activityId: uuidv4(),
      pickUpLocation: pickupLocation,
      routeVariance: varianceRoutes.includes(i),
      varianceReason:
        existingFields[`${baseSelector}VarianceReason#${indexSelector}`] || "",
      varianceNotes:
        existingFields[`${baseSelector}VarianceNotes#${indexSelector}`] || "",
      dropOffLocation,
      pickUpCoordinates: coordinates?.pickupCoordinates,
      dropOffCoordinates: coordinates?.dropOffCoordinates,
      hoistRoute: hoistRoutes.includes(i),
      activityStatus: form.getFieldValue(
        `${baseSelector}ActivityStatus#${indexSelector}`
      ),
      fromAudit: false,
      actualDepart: undefined,
      actualArrive: undefined,
      itinerary: itineraryData[i]
        ? itineraryData[i].map((e) => ({
            ...e,
            departAt: e.departAt.format(),
            arriveBy: e.arriveBy.format(),
          }))
        : [],
      cargoWeight:
        Number(
          form.getFieldValue(`${baseSelector}CargoWeight#${indexSelector}`)
        ) ?? 0,
      returningWeight:
        Number(
          form.getFieldValue(`${baseSelector}ReturningWeight#${indexSelector}`)
        ) ?? 0,
      projectId,
      passengerId: [
        form.getFieldValue(`${baseSelector}Passenger#${indexSelector}`),
      ].filter(Boolean),
      timeExit:
        baseSelector === "Return"
          ? undefined
          : dayjsNY(
              existingFields[`${baseSelector}TimeExit#${indexSelector}`]
            ).format(),
      departAt: dayjsNY(
        existingFields[`${baseSelector}DepartAt#${indexSelector}`]
      ).format(),
      arriveBy: dayjsNY(
        existingFields[`${baseSelector}ArriveBy#${indexSelector}`]
      ).format(),
      timeScheduled:
        existingFields[`${baseSelector}Duration#${indexSelector}`] ?? "00:00",
      cargo: existingFields[`${baseSelector}Cargo#${indexSelector}`],
      dispatchNotes:
        existingFields[`${baseSelector}NotesAndDetails#${indexSelector}`] ?? "",
      paperworkCollectStatus:
        existingFields[
          `${baseSelector}PaperworkCollectStatus#${indexSelector}`
        ],
      paperworkSoftwareStatus:
        existingFields[
          `${baseSelector}PaperworkSoftwareStatus#${indexSelector}`
        ],
      paperworkType:
        existingFields[`${baseSelector}PaperworkType#${indexSelector}`],
    };

    dispatchRoutes.push(tmp);
  }

  let user = `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`;

  let body = {
    fleetId: existingFields["truckNumber"],
    fleetName:
      fleet?.find((el) => el?.fleetId === existingFields["truckNumber"])
        ?.fleetName ?? "",
    dispatchDate: dayjsNY(existingFields["date"]).startOf("day").format(),
    dispatchId: uuidv4(),
    dispatchedBy: loggedUser,
    scheduleId: dispatchRoutes[0]?.scheduleId ?? "",
    projectId: dispatchRoutes[0]?.projectId ?? "",
    teamsConfiguration: updateTeamsConfiguration(userConfiguration, items),
    dispatchStatus: existingFields["dispatchStatus"],
    scheduleChanges: differentiateScheduleChanges({
      rowScheduleChanges,
      scheduleChanges: rowObject?.scheduleChanges || [],
      form,
    }),
    routes: [...dispatchRoutes],
  };

  if (
    rowObject?.scheduleChanges?.length &&
    (existingFields["dispatchStatus"] === rowObject?.["dispatchStatus"] ||
      existingFields["dispatchStatus"] === rowObject?.["status"])
  ) {
    body["dispatchStatus"] = "Confirmed";
  }

  if (rowObject) {
    if (
      body?.dispatchStatus === "Confirmed" &&
      rowObject?.status !== "Confirmed"
    ) {
      body["scheduleChanges"] = [];
    }
  }

  setVisibleCreationProgress(rowObject);
  updateProgressStatus({ updatingRecord: "executing" });
  if (!!rowObject?.dispatchId) {
    /**
     * At this point we have a dispatch object with new ids for each activity
     * We need to differentiate the cancelled Ids since they won't be deleted,
     * but they need to update the corresponding activity with a "cancelled" status
     */
    delete body["dispatchId"];
    body["dispatchedBy"] =
      rowObject["dispatchedBy"]?.name || rowObject["dispatchedBy"];
    let existingRouteIds = rowObject?.routes?.reduce(
      (acc, val) => [...acc, val?.activityId],
      []
    );
    let newRouteIds = body?.routes?.reduce(
      (acc, val) => [...acc, val?.activityId],
      []
    );

    /**
     * All of the original routes can be cancelled, which means they have a "cancelId"
     * property in the field. We take the indexes of the fields that have the id
     * These are Ids that should not be altered
     */
    let originalRouteIndexesInBody = [];
    let idsToCancel = cancelledRoutes
      ?.map((el) => !!el?.cancelled && el?.id)
      ?.filter(Boolean);
    let originalIdsLeftInBody = cancelledRoutes
      ?.map((el) => el?.cancelled === false && el?.id)
      ?.filter(Boolean);
    let idToUpdate = [
        rowObject["routes"][0]["activityId"],
        rowObject["routes"][rowObject?.routes?.length - 1]["activityId"],
      ],
      idToCreate = [];

    body["routes"][newRouteIds?.length - 1]["activityId"] =
      existingRouteIds[existingRouteIds?.length - 1];

    existingRouteIds.pop();
    newRouteIds.pop();

    //if the first route was not cancelled
    if (cancelledRoutes[0]["cancelled"] === false) {
      body["routes"][0]["activityId"] = existingRouteIds[0];
      existingRouteIds.shift();
      newRouteIds.shift();
      originalIdsLeftInBody.shift();
    } else {
      idToCreate = [newRouteIds[0]];
      idToUpdate.shift();
    }

    Object.keys(existingFields)?.forEach((key) => {
      if (
        key?.includes("cancelId") &&
        !!existingFields[key] &&
        !key?.includes("Ghost")
      ) {
        //we take the id the cancel id has in the form
        if (parseInt(key?.split("#")[1]) > 0) {
          originalRouteIndexesInBody.push(parseInt(key?.split("#")[1]));
        }
      }
    });

    //for each route we see which ids to update and delete
    let idMatchCount = 0;
    for (let i = 1; i <= body?.routes?.length - 2; i++) {
      if (originalRouteIndexesInBody?.includes(i)) {
        body["routes"][i]["activityId"] = originalIdsLeftInBody[idMatchCount];
        idToUpdate.push(originalIdsLeftInBody[idMatchCount]);
        ++idMatchCount;

        let prevRoute = rowObject["routes"].find(
          ({ activityId }) => activityId === body["routes"][i]["activityId"]
        );
        if (prevRoute) {
          let activityStatus = prevRoute?.activityStatus || "Draft";
          if (prevRoute?.actualArrive || prevRoute?.actualDepart) {
            activityStatus = "Audited";
          }

          body["routes"][i]["fromAudit"] = prevRoute?.fromAudit || false;
          body["routes"][i]["activityStatus"] = activityStatus;
          body["routes"][i]["actualDepart"] = prevRoute?.actualDepart;
          body["routes"][i]["actualArrive"] = prevRoute?.actualArrive;
        }
      } else {
        idToCreate.push(body["routes"][i]["activityId"]);
        body["routes"][i]["fromAudit"] = location.pathname === "/fleets/live";
      }
    }

    let startStatus = rowObject?.["routes"]?.[0]?.activityStatus || "Draft";
    let endStatus =
      rowObject?.["routes"]?.[rowObject["routes"]["length"] - 1]
        ?.activityStatus || "Draft";

    if (
      rowObject?.["routes"]?.[0]?.actualArrive ||
      rowObject?.["routes"]?.[0]?.actualDepart
    ) {
      startStatus = "Audited";
    }

    if (
      rowObject?.["routes"]?.[rowObject["routes"]["length"] - 1]?.[
        "actualDepart"
      ] ||
      rowObject?.["routes"]?.[rowObject["routes"]["length"] - 1]?.[
        "actualArrive"
      ]
    ) {
      endStatus = "Audited";
    }

    body["routes"][0]["fromAudit"] =
      rowObject?.["routes"]?.[0]?.["fromAudit"] || false;
    body["routes"][0]["activityStatus"] = startStatus;
    body["routes"][0]["actualDepart"] =
      rowObject?.["routes"]?.[0]?.actualDepart;
    body["routes"][0]["actualArrive"] =
      rowObject?.["routes"]?.[0]?.actualArrive;

    body["routes"][body["routes"]["length"] - 1]["fromAudit"] =
      rowObject?.["routes"]?.[rowObject["routes"]["length"] - 1]?.[
        "fromAudit"
      ] || false;
    body["routes"][body["routes"]["length"] - 1]["activityStatus"] = endStatus;
    body["routes"][body["routes"]["length"] - 1]["actualDepart"] =
      rowObject?.["routes"]?.[rowObject["routes"]["length"] - 1]?.[
        "actualDepart"
      ];
    body["routes"][body["routes"]["length"] - 1]["actualArrive"] =
      rowObject?.["routes"]?.[rowObject["routes"]["length"] - 1]?.[
        "actualArrive"
      ];

    // let tmpBody = {
    //   dispatchedBy: body?.dispatchedBy,
    //   driverName: body?.driverName,
    //   createdAt: rowObject?.createdAt,
    //   dispatchDate: body?.dispatchDate,
    //   dispatchId: rowObject?.dispatchId,
    //   driverId: body?.driverId,
    //   fleetId: body?.fleetId,
    //   fleetName: body?.fleetName,
    //   arriveBy: body?.routes[body?.routes?.length - 1]?.arriveBy,
    //   status: "Draft",
    //   departAt: body?.routes[0]?.departAt,
    //   location: `${body?.routes[0].dropOffLocation}`,
    //   dropOffLocation: body?.routes[body?.routes?.length - 1].dropOffLocation,
    //   pickUpLocation: body?.routes[0].pickUpLocation,
    //   routes: body?.routes,
    // };

    // console.log("VARS: ", {
    //   tmpBody,
    //   labels,
    //   originalRouteIndexesInBody,
    //   idToUpdate,
    //   idToCreate,
    //   existingFields,
    //   cancelledRoutes,
    //   originalIdsLeftInBody,
    // });

    // setAllDispatches((prev) => {
    //   let t = structuredClone(prev);
    //   let i = t?.findIndex((el) => el?.dispatchId === tmpBody?.dispatchId);
    //   t[i] = { ...tmpBody };
    //   return t;
    // });

    await API.put(
      "fleetDispatching",
      `/fleetDispatching/${rowObject?.dispatchId}`,
      {
        body,
      }
    )
      .then(async (res) => {
        setVisibleCreationProgress(res);
        updateProgressStatus({
          updatingRecord: "finished",
          sendingNotification: "executing",
        });

        message.success({
          content: "Saved Successfully!",
          key: "dispatch",
          duration: 2,
        });
        let bodyToRefresh = {
          dispatchedBy: body?.dispatchedBy,
          driverName: body?.driverName,
          createdAt: rowObject?.createdAt,
          dispatchDate: body?.dispatchDate,
          dispatchId: rowObject?.dispatchId,
          driverId: body?.driverId,
          fleetId: body?.fleetId,
          fleetName: body?.fleetName,
          teamsConfiguration: body?.teamsConfiguration,
          arriveBy: body?.routes[body?.routes?.length - 1]?.arriveBy,
          status: body?.dispatchStatus,
          departAt: body?.routes[0]?.departAt,
          location: `${body?.routes[0].dropOffLocation}`,
          dropOffLocation:
            body?.routes[body?.routes?.length - 1].dropOffLocation,
          pickUpLocation: body?.routes[0].pickUpLocation,
          routes: JSON.parse(JSON.stringify(body?.routes)),
        };
        const path = `fleets/overview?tab=Dispatch&dispatchId=${rowObject?.dispatchId}`;

        broadcastNotification(
          FLEET_TOPIC_ID,
          "onDispatchEdit",
          [
            {
              common: user,
              location: `${body?.routes[0].dropOffLocation}`,
              truck: `${body?.fleetName}`,
              drivers: uniqBy(
                body?.routes?.map(({ driverName, driverId, driverSub }) => ({
                  driverName,
                  driverId,
                  driverSub,
                })),
                (driver) => driver?.driverId
              ),
            },
            {
              userName: user,
              currentUser: authenticatedUser?.sub,
              cognitos: [
                ...uniqBy(
                  body.routes.map(({ driverSub }) => driverSub),
                  "driverSub"
                ),
                ...getCognitosForNotification(
                  userConfiguration,
                  body?.teamsConfiguration,
                  "fleet administrator"
                ),
              ],
            },
          ],
          path
        ).then((notificationSent) => {
          updateProgressStatus({
            sendingNotification: !!notificationSent ? "finished" : "hasError",
          });
        });

        refreshTable(bodyToRefresh, "edit");
        try {
          dispatchSocket?.send(
            JSON.stringify({
              request: "dispatch-edit",
              body: bodyToRefresh,
            })
          );
        } catch (err) {
          console.log("Could not send socket message: ", err);
        }
        setAllDispatches((prev) => {
          let t = structuredClone(prev);
          let i = t?.findIndex(
            (el) => el?.dispatchId === bodyToRefresh?.dispatchId
          );
          t[i] = { ...bodyToRefresh };
          return t;
        });

        let changeResult = getChangedData(body, rowObject);

        if (!!changeResult) {
          let curr = structuredClone(changeResult?.curr);
          let prev = structuredClone(changeResult?.prev);
          for (let i = 0; i < changeResult?.curr?.routes?.length; i++) {
            Object.keys(curr?.routes)?.forEach((route) => {
              delete curr?.routes?.[route]?.dropOffCoordinates;
              delete curr?.routes?.[route]?.pickUpCoordinates;
              delete curr?.routes?.[route]?.driverSub;
            });
          }
          for (let i = 0; i < changeResult?.prev?.routes?.length; i++) {
            Object.keys(prev?.routes)?.forEach((route) => {
              delete prev?.routes?.[route]?.dropOffCoordinates;
              delete prev?.routes?.[route]?.pickUpCoordinates;
              delete prev?.routes?.[route]?.driverSub;
            });
          }

          saveAddedLogs({
            recordId: rowObject?.dispatchId,
            recordName: res?.location,
            actionType: "Edit",
            category: "Fleet Dispatching",
            currentData: curr,
            previousData: prev,
            updatedKeys: ["routes"],
          });
        }

        const updateData = {};
        for (const id of idsToCancel) {
          updateData[id] = {
            activityStatus: "Cancelled",
          };
        }

        for (const id of idToUpdate) {
          let t = body?.routes?.find((el) => el?.activityId === id);
          let activityStatus = t?.activityStatus || "Draft";
          // delete t?.["activityId"];

          try {
            const response = await API.get(
              "fleetActivity",
              `/fleetActivity/${id}`
            );
            activityStatus = response?.activityStatus || activityStatus;
          } catch (err) {
            console.log(`Error fetching activity status for ${id}:`, err);
          }

          updateData[id] = {
            ...t,
            fleetId: body["fleetId"],
            fleetName: body["fleetName"],
            activityDate: body?.dispatchDate,
            dispatchId: rowObject?.dispatchId,
            dispatchedBy: body["dispatchedBy"],
            cargo: t["cargo"],
            startingTime: t?.departAt,
            timeAtLocation: t?.arriveBy,
            timeExitingTheLocation: t?.timeExit,
            dispatchNotes: t?.dispatchNotes,
            activityStatus: activityStatus,
            teamsConfiguration: body?.teamsConfiguration || [],
          };

          delete updateData[id]?.activityId;
        }

        const newData = idToCreate.map((id) => {
          let t = body?.routes?.find((el) => el?.activityId === id);
          return {
            ...t,
            fromAudit: location.pathname === "/fleets/live",
            fleetId: body["fleetId"],
            fleetName: body["fleetName"],
            dispatchId: rowObject?.dispatchId,
            activityDate: body?.dispatchDate,
            dispatchedBy: body["dispatchedBy"],
            cargo: t["cargo"],
            startingTime: t?.departAt,
            timeAtLocation: t?.arriveBy,
            timeExitingTheLocation: t?.timeExit,
            dispatchNotes: t?.dispatchNotes,
            activityStatus: t?.activityStatus
              ? t?.activityStatus
              : body?.dispatchStatus || "Draft",
            teamsConfiguration: body?.teamsConfiguration || [],
          };
        });

        await Promise.all(
          [
            API.post("batchUpdate", "/batchUpdate", {
              body: {
                entries: Object.values(updateData),
                tableName: "fleetActivity",
                sortKey: "activityId",
              },
            })
              .then(() => {
                updateViolationsForActivities(updateData);
              })
              .catch(async (err) => {
                console.log("Batch update error: ", err);
                return Promise.all(
                  Object.keys(updateData).map((id) =>
                    API.put("fleetActivity", `/fleetActivity/${id}`, {
                      body: {
                        ...updateData[id],
                      },
                    }).then(() => {
                      updateViolationsForActivities({ [id]: updateData[id] });
                    })
                  )
                );
              }),
            newData.length &&
              batchPost({
                items: newData,
                tableName: "fleetActivity",
              }).catch(async (err) => {
                console.log("Batch post error: ", err);
                return Promise.all(
                  newData.map((body) =>
                    API.post("fleetActivity", "/fleetActivity", { body })
                  )
                );
              }),
          ].filter(Boolean)
        );
      })
      .catch((err) => {
        updateProgressStatus({ updatingRecord: "hasError" });
        message.error("An error ocurred while saving, please try again later.");
        console.log("Error Saving: ", err);
      });
  } else {
    if (location.pathname === "/fleets/live") {
      body["routes"] = body["routes"].map((e) => ({
        ...e,
        fromAudit: true,
      }));
    }

    await API.post("fleetDispatching", "/fleetDispatching", {
      body,
    })
      .then((res) => {
        setVisibleCreationProgress(res);

        message.success({
          content: "Saved Successfully!",
          key: "dispatch",
          duration: 2,
        });

        //set progress of creating record as 100%
        updateProgressStatus({
          updatingRecord: "finished",
          sendingNotification: "executing",
        });

        //adds the result to the grid
        let bodyToRefresh = {
          dispatchedBy: res?.dispatchedBy,
          driverName: res?.driverName,
          createdAt: res?.createdAt,
          dispatchDate: res?.dispatchDate,
          dispatchId: res?.dispatchId,
          driverId: res?.driverId,
          fleetId: res?.fleetId,
          fleetName: res?.fleetName,
          teamsConfiguration: res?.teamsConfiguration,
          arriveBy: res?.routes[res?.routes?.length - 1]?.arriveBy,
          status: res?.dispatchStatus,
          departAt: res?.routes[0]?.departAt,
          location: `${res?.routes[0].dropOffLocation}`,
          dropOffLocation: res?.routes[res?.routes?.length - 1].dropOffLocation,
          pickUpLocation: res?.routes[0].pickUpLocation,
          routes: res?.routes,
        };

        refreshTable(bodyToRefresh, "add");
        try {
          dispatchSocket?.send(
            JSON.stringify({
              request: "dispatch-create",
              body: bodyToRefresh,
            })
          );
        } catch (err) {
          console.log("Could not send socket message: ", err);
        }

        setAllDispatches((prev) => prev.concat(bodyToRefresh));
        let path = `fleets/overview?tab=Dispatch&dispatchId=${res?.dispatchId}`;

        broadcastNotification(
          FLEET_TOPIC_ID,
          "onDispatchCreation",
          [
            {
              common: user,
              location: `${body?.routes[0].dropOffLocation}`,
              truck: `${body?.fleetName}`,
              drivers: uniqBy(
                body.routes.map(({ driverName, driverId, driverSub }) => ({
                  driverName,
                  driverId,
                  driverSub,
                })),
                (driver) => driver.driverId
              ),
            },
            {
              userName: user,
              currentUser: authenticatedUser?.sub,
              cognitos: [
                ...uniqBy(
                  body.routes.map(({ driverSub }) => driverSub),
                  "driverSub"
                ),
                ...getCognitosForNotification(
                  userConfiguration,
                  body?.teamsConfiguration,
                  "fleet administrator"
                ),
              ],
            },
          ],
          path
        ).then((notificationSent) => {
          updateProgressStatus({
            sendingNotification: !!notificationSent ? "finished" : "hasError",
          });
        });

        saveAddedLogs({
          recordId: rowObject?.dispatchId,
          recordName: res?.location,
          category: "Fleet Dispatching",
        });
      })
      .catch((err) => {
        updateProgressStatus({ updatingRecord: "hasError" });
        message.error("An error ocurred while saving, please try again later.");
        console.log("Error Saving: ", err);
      });
  }
}

/**
 * Function that deletes the dispatch and the activities it is linked to
 * @param {Object} rowObject The selected row object
 * @param {Function} refreshTable Updates the row data
 * @param {Object} authenticatedUser The authenticated user object
 * @param {Array} allSchedules The list of all schedules to update
 * @param {Function} setAllSchedules Updated the schedule state
 * @param {WebSocket} dispatchSocket
 */
export async function deleteDispatch({
  rowObject,
  refreshTable,
  authenticatedUser,
  saveAddedLogs,
  dispatchSocket,
}) {
  message.loading({
    content: "Deleting",
    key: "deleteMessage",
  });
  await API.del(
    "fleetDispatching",
    `/fleetDispatching/${rowObject?.dispatchId}`
  )
    .then(() => {
      message.success({
        content: "Deleted successfully!",
        key: "deleteMessage",
      });
      refreshTable(rowObject, "remove");
      try {
        dispatchSocket?.send(
          JSON.stringify({
            request: "dispatch-delete",
            body: {
              ...rowObject,
            },
          })
        );
      } catch (err) {
        console.log("Could not send socket message: ", err);
      }

      let tmp = rowObject?.routes?.reduce(
        (acc, val) => [...acc, val?.activityId],
        []
      );
      tmp?.forEach((e) => {
        API.del("fleetActivity", `/fleetActivity/${e}`).catch((err) => {
          console.log("Error: ", err);
        });
      });

      broadcastNotification(
        FLEET_TOPIC_ID,
        "onDispatchDelete",
        [
          {
            common: `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`,
            location: `${rowObject?.routes[0].dropOffLocation}`,
            truck: `${rowObject?.fleetName}`,
          },
          {
            userName: `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`,
            currentUser: authenticatedUser?.sub,
          },
        ],
        rowObject?.dispatchId
      );
      saveAddedLogs({
        recordId: rowObject?.dispatchId,
        recordName: rowObject?.location,
        actionType: "Delete",
        category: "Fleet Dispatching",
      });
    })
    .catch((err) => {
      console.log("Error deleting: ", err);
      message.error({
        content:
          "An error ocurred while deleting the dispatch, please try again later.",
        key: "deleteMessage",
      });
    });
}

/**
 * Function that looks recursively through the JSX component for all text.
 * Crated to avoid circular reference errors in JSON.stringify
 * @param {import("react").ReactChild} html A SINGLE JSX component
 * @returns An array of all the visible text
 */
export function getAllTextFromTags(html, result = []) {
  if (Array.isArray(html)) {
    html.forEach((element) => {
      getAllTextFromTags(element, result);
    });
  } else {
    let htmlProps = html?.props?.children;

    if (!!htmlProps) {
      if (Array.isArray(htmlProps)) {
        htmlProps.forEach((prop) => getAllTextFromTags(prop, result));
      } else if (typeof htmlProps === "string") {
        result.push(htmlProps);
      }
    } else {
      if (typeof html === "string") {
        result.push(html);
      }
    }
  }
  return result;
}

/**
 * Function that takes the users from the user configuration and
 * separates the users present in the departments of interest
 * @param {Array} users userConfiguration.allUsers?.Items
 */
export function differentiateUsersByDepartments(users) {
  let finalList = {
    accountManagers: [],
    fleetAdministrators: [],
    projectExecutives: [],
  };
  for (let i = 0; i < users?.length; i++) {
    if (users[i]?.groupName === "Fleet Administrator") {
      finalList.fleetAdministrators.push(users[i]["cognitoUserId"]);
    } else if (users[i]?.groupName === "Account Manager") {
      finalList.accountManagers.push(users[i]["cognitoUserId"]);
    } else if (users[i]?.groupName === "Project Executive") {
      finalList.projectExecutives.push(users[i]["cognitoUserId"]);
    }
  }

  return finalList;
}

/**
 * Function that checks if there schedules to the same address
 * with overlapping schedule days
 * @param {Array} schedulesForDate The list of schedules or the date
 */
export function findOverlappingSchedules(schedulesForDate) {
  let tmpSchedules = {},
    finalSchedules = {};
  for (let i = 0; i < schedulesForDate?.length; i++) {
    if (!tmpSchedules[schedulesForDate[i]["scheduleAddress"]]) {
      tmpSchedules[schedulesForDate[i]["scheduleAddress"]] = [
        schedulesForDate[i]["scheduleId"],
      ];
    } else {
      tmpSchedules[schedulesForDate[i]["scheduleAddress"]] = [
        ...tmpSchedules[schedulesForDate[i]["scheduleAddress"]],
        schedulesForDate[i]["scheduleId"],
      ];
    }
  }

  Object.keys(tmpSchedules).forEach((key) => {
    if (tmpSchedules[key]?.length > 1) {
      finalSchedules[key] = [...tmpSchedules[key]];
    }
  });

  return finalSchedules;
}

/**
 * Function that gets the steps of the tour depending on the link
 */
export function getTourSteps({
  programFields,
  setShowVideoTutorial,
  setTourOpen,
  setVideoTutorialLink,
  isDarkMode,
}) {
  const links = programFields
    ?.find((item) => item.fieldName === "Portal Video Tutorials")
    ?.fieldOptions.find((item) => item.categoryName === "Fleet Management")
    ?.subCategories[0]?.videos.filter((item, i) => i >= 1 && i <= 2)
    .map((item) => item);

  const steps =
    programFields
      ?.find(({ fieldName }) => fieldName === "Tutorials Steps")
      ?.fieldOptions?.find(
        ({ categoryName }) => categoryName === "dispatchModal"
      )?.steps || [];

  const stepsMapHelper = (title) => {
    const stepMap = {
      "Live View": document.querySelector("#liveButton"),
      "Dispatch Date": document.querySelector(".dispatchDateSelect"),
      Truck: document.querySelector(".dispatchTruckNumberSelect"),
      Status: document.querySelector(".dispatchStatusSelect"),
      "Create Driver": document.querySelector(".createDriverBtn"),
      "Create Vehicle": document.querySelector(".createVehicleBtn"),
      Routes: document.querySelector(".dispatchRouteCard "),
      "Add Route": document.querySelector(".addRouteButton"),
      "Return Route": document.querySelector("#dispatchReturnCard"),
      "Show Table": document.querySelector(".hideTable"),
      Finish: document.querySelector(".saveBtn"),
      "Show Routes": document.querySelector(".dispatchSelectDriver"),
      "Maps Directions": document.querySelector("#mapsDirectionsButton"),
      "Add Hoist Route": document.querySelector("#addHoistRouteButton"),
      "Hoist Route": document.querySelector("#makeHoistRouteButton"),
    };
    return stepMap[title] || null;
  };

  function mapTourRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        ...step,
        target: () => stepsMapHelper(step?.title),
        className: isDarkMode ? "custom-tour-dark" : "custom-tour-light",
      };
    });
    return newSteps;
  }

  const tourSteps = [
    {
      title: (
        <div style={{ width: 280 }}>
          Watch Dispatch Tutorial or tap 'Next' to view a quick tour
        </div>
      ),
      description: (
        <div style={{ display: "flex", gap: 5, flexDirection: "column" }}>
          {links.map((link) => (
            <MondayButton
              key={link.videoLink}
              {...{
                className: "mondayButtonBlue guideButton",
                onClick: () => {
                  setShowVideoTutorial(true);
                  setTourOpen(false);
                  setVideoTutorialLink(link.videoLink);
                },
                style: { display: "flex", justifyContent: " center" },
                Icon: <VideoTutorialIcon />,
              }}
            >
              {link.videoName}
            </MondayButton>
          ))}
        </div>
      ),
      className: isDarkMode ? "custom-tour-dark" : "custom-tour-light",
      placement: "center",
    },
    ...mapTourRefs(steps),
  ];

  return tourSteps;
}

/**
 * Function that looks at all the accepted schedule changes
 * and returns a filtered list
 * @param {Object} rowScheduleChanges The changes grouped by activity id
 * @param {Array} scheduleChanges The array of the changes we got from the schedule
 * @param {FormInstance} form A pointer to the form
 */
function differentiateScheduleChanges({
  rowScheduleChanges,
  scheduleChanges,
  form,
}) {
  /**
   * The idea is to group all the schedule ids that have an
   * accepted key and filter the row data schedule changes based on the
   * filtered results
   */
  let acceptedSchedules = [];
  let existingFields = form.getFieldsValue();

  /**
   *this will hold the list of ids left in the form.
   *if the route loses the reference to the schedule it
   *doesn't make sense to still keep the schedule changes
   */
  let idsLeft = [];

  Object.keys(existingFields)?.forEach((key) => {
    if (
      key?.includes("cancelId") &&
      !!existingFields[key] &&
      !key?.includes("Ghost")
    ) {
      let indexLeftInBody = key.replace("cancelId#", "");
      idsLeft.push({
        activityId: existingFields[key],
        scheduleId: existingFields[`Schedule#${indexLeftInBody}`],
      });
    }
  });

  Object.keys(rowScheduleChanges || {})?.forEach((key) => {
    if (rowScheduleChanges[key]?.some(({ accepted }) => accepted === true)) {
      acceptedSchedules.push(rowScheduleChanges[key][0]["scheduleId"]);
    }
  });

  let newScheduleChanges = scheduleChanges?.filter(({ scheduleId, action }) => {
    if (!idsLeft?.find(({ scheduleId: s }) => s === scheduleId)) {
      return false;
    } else if (
      action === "Start Time Change" &&
      acceptedSchedules.includes(scheduleId)
    ) {
      return false;
    }
    return true;
  });

  return newScheduleChanges;
}

/**
 * Function that retrieves the teams selections from the saved team configuration
 * @param {Array} teamsConfiguration
 */
export function getTeamsSelectionsFromItems(teamsConfiguration) {
  return teamsConfiguration?.map((team) => team.value).filter(Boolean);
}

/**
 * Function that checks the access rights for different parts of the form
 * @param {Object} accessRight The Overview access rights for the dispatch
 * @param {String} childTitle The title of the child we should check
 */
export function getAccessRights(accessRight, childTitle) {
  return (accessRight?.children || [])?.find(
    ({ title }) => title === childTitle
  );
}
