export function engineerFields(isDarkMode = false, salaryAccess = {}) {
  return [
    {
      label: "First Name",
      type: "input",
      placeholder: "First Name...",
      formItemName: "firstName",
      className: "crewNameInput",
      required: true,
    },
    {
      label: "Last Name",
      type: "input",
      placeholder: "Last Name...",
      formItemName: "lastName",
      className: "crewNameInput",
      required: true,
    },
    {
      label: "Email",
      type: "input",
      placeholder: "example@email.com",
      formItemName: "userName",
      className: "crewNameInput",
      required: true,
    },
    salaryAccess?.write && {
      label: "Pay Rate",
      type: "number",
      typeNumber: true,
      placeholder: "Pay rate...",
      formItemName: "employeeRate",
      className: "crewNameInput",
      prefix: "$",
      min: 0,
      required: true,
    },
    salaryAccess?.write && {
      label: "Salary Type",
      type: "select",
      placeholder: "Select salary type...",
      formItemName: "salaryType",
      className: "crewNameInput",
      popupClassName: isDarkMode && "darkDropDown",
      customOptions: [
        {
          key: 0,
          label: "Hourly",
          value: "Hourly",
        },
        {
          key: 1,
          label: "Salary",
          value: "Salary",
        },
      ],
      required: true,
    },
    {
      label: "Status",
      type: "select",
      placeholder: "Select status...",
      formItemName: "employeeStatus",
      className: "crewNameInput",
      popupClassName: isDarkMode && "darkDropDown",
      customOptions: [
        {
          key: 0,
          label: "Active",
          value: "Active",
        },
        {
          key: 1,
          label: "Suspended",
          value: "Suspended",
        },
      ],
      required: true,
    },
  ];
}

export function architectFields(isDarkMode = false, salaryAccess = {}) {
  return [
    {
      label: "First Name",
      type: "input",
      placeholder: "First Name...",
      formItemName: "firstName",
      className: "crewNameInput",
      required: true,
    },
    {
      label: "Last Name",
      type: "input",
      placeholder: "Last Name...",
      formItemName: "lastName",
      className: "crewNameInput",
      required: true,
    },
    {
      label: "Email",
      type: "input",
      placeholder: "example@email.com",
      formItemName: "userName",
      className: "crewNameInput",
      required: true,
    },
    salaryAccess?.write && {
      label: "Pay Rate",
      type: "number",
      typeNumber: true,
      placeholder: "Pay rate...",
      formItemName: "employeeRate",
      className: "crewNameInput",
      prefix: "$",
      min: 0,
      required: true,
    },
    salaryAccess?.write && {
      label: "Salary Type",
      type: "select",
      placeholder: "Select salary type...",
      formItemName: "salaryType",
      className: "crewNameInput",
      popupClassName: isDarkMode && "darkDropDown",
      customOptions: [
        {
          key: 0,
          label: "Hourly",
          value: "Hourly",
        },
        {
          key: 1,
          label: "Salary",
          value: "Salary",
        },
      ],
      required: true,
      getPopUpContainer: document.body,
    },
    {
      label: "Status",
      type: "select",
      placeholder: "Select status...",
      formItemName: "employeeStatus",
      className: "crewNameInput",
      popupClassName: isDarkMode && "darkDropDown",
      customOptions: [
        {
          key: 0,
          label: "Active",
          value: "Active",
        },
        {
          key: 1,
          label: "Suspended",
          value: "Suspended",
        },
      ],
      required: true,
    },
  ];
}

export function driverFields({
  driverRatingOptions,
  driverStatus,
  teams,
  onTeamSelect,
  onTeamDeselect,
}) {
  return [
    {
      label: "Full Name",
      type: "input",
      placeholder: "Full Name",
      formItemName: "driverName",
      className: "crewNameInput",
      required: true,
    },
    {
      label: "Employee Id",
      type: "input",
      placeholder: "Employee Id",
      formItemName: "employeeId",
      className: "crewNameInput",
      required: true,
    },
    {
      label: "Rating",
      type: "select",
      placeholder: "Select Drivers Rating...",
      formItemName: "driverRating",
      customOptions: (driverRatingOptions || []).map(
        ({ statusName }, index) => ({
          key: index,
          label: statusName,
          value: statusName,
        })
      ),

      className: "crewNameInput",
      required: true,
    },
    {
      label: "Status",
      type: "select",
      placeholder: "Select Driver's Status...",
      formItemName: "driverStatus",
      customOptions: (driverStatus || []).map(({ statusLabel }, index) => ({
        key: index,
        label: statusLabel,
        value: statusLabel,
      })),
      className: "crewNameInput",
      required: true,
    },
    {
      label: "Driver Type",
      type: "select",
      placeholder: "Select Driver's Type...",
      formItemName: "driverType",
      customOptions: [
        { key: 0, label: "CDL", value: "CDL" },
        { key: 1, label: "Other", value: "Other" },
      ],
      className: "crewNameInput",
      required: true,
    },
    {
      label: "Team",
      type: "select",
      placeholder: "Select team...",
      formItemName: "driverTeam",
      customOptions: teams?.map((team) => ({
        label: team.teamName,
        value: team.teamName,
        members: team.members?.map(
          ({ identityId, nameOfUser, cognitoUserId = "" }) => ({
            identityId,
            nameOfUser,
            cognitoUserId,
          })
        ),
      })),
      onSelect: onTeamSelect,
      onDeselect: onTeamDeselect,
      className: "crewNameInput",
      required: true,
    },
    {
      label: "Home Address",
      type: "placeswithcoordinatesautocomplete",
      formItemName: "homeAddress",
      placeholder: "Enter address...",
      required: false,
    },
  ];
}

export function employeeEmergencyContactFields() {
  return {
    formItemName: "emergencyContact",
    label: "Emergency Contact",
    type: "MultipleInputs",
    disabled: false,
    dynamicFields: [
      {
        formItemName: "fullName",
        label: "Full Name",
        type: "Input",
        disabled: false,
        rules: [{ required: true, message: "Please input the Full Name" }],
      },
      {
        formItemName: "email",
        label: "Email",
        type: "Email",
        disabled: false,
        rules: [
          {
            required: true,
            type: "email",
            message: "The input is not valid e-mail!",
          },
        ],
      },
      {
        formItemName: "phoneNumber",
        label: "Phone Number",
        type: "PhoneNumber",
        rules: [
          {
            required: true,
            message: "Please input your phone number!",
          },
          {
            pattern:
              /^1?[-. ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
            message: "Please enter a valid phone number",
          },
        ],
        disabled: false,
      },
      {
        formItemName: "relationship",
        label: "Relationship",
        type: "ComboBox",
        options: ["Parent", "Spouse", "Sibling", "Child", "Friend"],
        disabled: false,
        rules: [
          {
            required: true,
            message: "Please select or enter a Relationship",
          },
        ],
      },
    ],
  };
}
