import {
  convertStyleToString,
  formatTextProposal,
} from "../../../../../../pages/Settings/settingsComponents/ProposalBuilder/components/EditTemplate/EditGeneral/ElevationHtmlConfiguration/helpers/addElevationStyles";
import { groupBy } from "../../../../../../SidebarPages/Accounting/components/utilities";
import { forceToNumber } from "../../../../../../SidebarPages/Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { formatFloorHeight } from "../../../../../../SidebarPages/Estimations/components/EstVersionsTab/components/CompareTakeOffVersions/utils";
import hasContent from "../../../../../../SidebarPages/Estimations/DataEntryGrid/functional/hasContent";
import {
  formatDimensions,
  stripHtmlTags,
} from "../../../../../../SidebarPages/Estimations/DataEntryGrid/tools/exporters/retrieveDOCX";

function getStopCount(items, type) {
  let stopsCount = {
    A: 0,
    B: 0,
  };
  items.forEach((item) => {
    if (item?.stop === true) {
      stopsCount.A++;
    }
    if (item?.dual === true && type === "dual") {
      stopsCount.B++;
    }
  });
  return stopsCount;
}

function getFirstStop(items) {
  let firstStop = 0;
  for (let i = 0; i < items.length; i++) {
    if (items[i]?.stop === true) {
      firstStop = items[i]?.floor;
      break;
    }
  }

  return firstStop;
}

function getLastStop(items) {
  let lastStop = 0;
  for (let i = items.length - 1; i >= 0; i--) {
    if (items[i]?.stop === true) {
      lastStop = items[i]?.floor;
      break;
    }
  }

  return lastStop;
}

function toOrdinalFloor(value) {
  if (value) {
    let prevValue = value;
    value = parseInt(value);
    if (isNaN(value)) return prevValue;
    const j = value % 10,
      k = value % 100;
    if (value === 0) return "Ground Floor";
    else if (value === -1) return "Cellar";
    else if (value === -2) return "Sub Cellar";
    else if (j === 1 && k !== 11) {
      return value + "st floor level";
    } else if (j === 2 && k !== 12) {
      return value + "nd floor level";
    } else if (j === 3 && k !== 13) {
      return value + "rd floor level";
    } else {
      return value + "th floor level";
    }
  } else {
    return "-";
  }
}

function getNDANote(elevation) {
  let temp = "";
  if (elevation?.access === "nonDirect") {
    temp = `<span>/ Non Direct Access${stripHtmlTags(
      !!elevation?.accessNote ? ` (${elevation?.accessNote})` : ""
    )}</span>`;
  } else if (elevation?.access === "direct") {
    temp = `<span> / Direct Access</span>`;
  }
  return temp;
}

const getElevationName = (elevation, id = "", showIds) => {
  return `<span>${elevation} ${showIds ? id : ""}</span>`;
};

//region MAIN
export function hoistElevationsHtml(elevation, index, styles) {
  console.log("hoistElevationsHtml ~ styles:", { styles });
  let elevationName = getElevationName(
    elevation?.elevationLabel,
    elevation?.elevationId,
    true
  );
  let description = [];
  description.push(
    `<p>${elevation?.type === "dual" ? "Dual Hoist" : "Single Hoist"}</p>`
  );
  description.push(
    `<p>Located in the ${elevation?.location} ${getNDANote(elevation)}</p>`
  );
  description.push(`<p>
        Lift Height: ${elevation?.lift_height ?? "0"}′H [Mast: ${
    elevation?.mastHeight ?? "0"
  }] from (${toOrdinalFloor(
    getFirstStop(elevation?.items ?? [])
  )}) to (${toOrdinalFloor(getLastStop(elevation?.items ?? []))})</p>`);

  let stopsCount = getStopCount(
    elevation?.items ?? [],
    elevation?.type || "single"
  );
  description.push(`<p>
            Number of Stops: ${
              stopsCount.A > 0 ? `Cabin A: ${stopsCount.A}` : ""
            } ${stopsCount.B > 0 ? `Cabin B: ${stopsCount.B}` : ""} ${
    elevation?.stops_including?.length !== 0
      ? `including ${elevation?.stops_including?.join(", ")}`
      : ""
  }${
    elevation?.stops_excluding?.length !== 0
      ? ` excluding ${elevation?.stops_excluding?.join(", ")}`
      : ""
  } </p>`);

  let includesAndHeights = hoistIncludesAndHeights(elevation);

  let notes = "";
  if (hasContent(elevation?.note)) {
    notes = `<span>${elevation?.note}</span>`;
  }

  let titleFinal = `<div class="elevation-label" style="${convertStyleToString(
    styles?.elevationLabel
  )}">  ${formatTextProposal(
    elevationName,
    styles?.elevationLabel,
    index + 1
  )} </div>`;

  let descriptionFinal = `<div class="elevation-desc" style="${convertStyleToString(
    styles?.elevationDesc
  )}"> ${formatTextProposal(
    description.join(""),
    styles?.elevationDesc
  )} </div>`;

  let notesFinal = notes
    ? `<div class="elevation-notes" style="${convertStyleToString(
        styles?.notes
      )}"> ${formatTextProposal(notes, styles?.notes)} </div>`
    : "";

  return `<div class="elevation${index}"> 
            ${titleFinal}
            ${descriptionFinal}
            ${includesAndHeights.join("")}
            ${notesFinal}
            </div>`;
}

//region group_Floors
function groupFloors(floors) {
  const groups = {};

  for (const floor in floors) {
    for (const addon in floors[floor]) {
      for (const length of floors[floor][addon]) {
        if (!groups[addon]) {
          groups[addon] = {};
        }
        if (!groups[addon][length]) {
          groups[addon][length] = [];
        }
        groups[addon][length].push(`${toOrdinalFloor(floor)}`);
      }
    }
  }

  let result = "";

  for (const addon in groups) {
    for (const length in groups[addon]) {
      result += `<li >${addon} ${length} - Floor: ${groups[addon][length].join(
        ", "
      )}</li> `;
    }
  }

  return result.trim();
}

//region inc_height
function hoistIncludesAndHeights(elevation) {
  const myDataHolder = {};
  let applicableAddons = [];

  const tempData = elevation?.items?.forEach((item) => {
    myDataHolder[item?.floor] = {};
    let currAddons = item?.addons;
    if (Array.isArray(currAddons)) {
      currAddons?.forEach((addon) => {
        let tempName = addon?.name?.values?.find(
          (el) => el?.name === "Name"
        ).value;
        applicableAddons.push(tempName);
        if (!applicableAddons.includes(tempName)) {
          myDataHolder[item?.floor] = {
            ...(myDataHolder[item?.floor] || {}),
            [tempName]: [],
          };
        }

        let currAddon = {
          name: tempName,
          ...addon,
        };
        myDataHolder[item?.floor] = {
          ...(myDataHolder[item?.floor] || {}),
          [tempName]: [
            ...(myDataHolder[item?.floor]?.[tempName] || []),
            currAddon,
          ],
        };
      });
    }
  });

  Object.entries(myDataHolder).forEach(([key, value]) => {
    // iterate through floors
    if (Object.keys(value).length > 0) {
      Object.entries(value).forEach(([addonKey, addonValue]) => {
        myDataHolder[key][addonKey] = addonValue?.map((el) =>
          formatDimensions(el, true)
        );
      });
    }
  });

  const myTempHtml = [];
  let tempHtmlHolder = groupFloors(myDataHolder);
  console.log("hoistIncludesAndHeights ~ tempHtmlHolder:", { tempHtmlHolder });

  if (!!tempHtmlHolder) {
    myTempHtml.push(`<span><strong>Elevation Includes:</strong></span>`);
    myTempHtml.push(`<ul>${tempHtmlHolder}</ul>`);
  }

  //--------------------------Floor heights--------------------------------
  let tempAllFloors = "";
  const result = groupBy(
    elevation?.items
      ?.sort((a, b) => forceToNumber(a?.floor) - forceToNumber(b?.floor))
      .map((el) => ({ ...el, height: el?.floor_height })) || [],
    "height"
  );
  let sortedFloors = elevation?.items
    ?.sort((a, b) => forceToNumber(a?.floor) - forceToNumber(b?.floor))
    .map((el) => el.floor);

  do {
    Object.entries(result).forEach(([key, value]) => {
      let floorsArr = value?.map((el) => el?.floor) || [];
      if (floorsArr.includes(sortedFloors[0])) {
        sortedFloors = sortedFloors.filter((el) => {
          return !floorsArr.includes(el);
        });
        if (floorsArr?.length > 0) {
          tempAllFloors += `<li>Floor - ${floorsArr
            ?.map((f) => toOrdinalFloor(f))
            .join(", ")}<strong style="color:red;"> [${formatFloorHeight(
            key
          )} H]</strong></li>`;
        }
      }
    });
  } while (sortedFloors?.length > 0);

  console.log({ tempAllFloors });
  let tempHeights = [];
  tempHeights.push(...myTempHtml);
  tempHeights.push(`<br><span><strong>Floor Heights:</strong></span>`);
  tempHeights.push(`<span>${tempAllFloors}</span>`);

  return tempHeights;
}
