import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { AutoComplete, Button } from "antd";

import "./PredictAutocomplete.scss";

function PredictAutocomplete(props) {
  //#region HOOKS
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [originalOptions, setOriginalOptions] = useState(
    structuredClone(props.customOptions)
  );
  const [value, setValue] = useState(props?.defaultValue);
  const [optionsToShow, setOptionsToShow] = useState(
    structuredClone(props.customOptions)
  );

  const underText = useRef(null);
  const suggestionSpan = useRef(null);

  useEffect(() => {
    //#region OPTIONS CHANGE
    setOriginalOptions(props?.customOptions);
    setOptionsToShow(props?.customOptions);
  }, [JSON.stringify(props.customOptions)]);

  function clearRefs(resetOptions = false) {
    //#region CLEAR REFS
    if (underText.current) {
      underText.current.innerHTML = "";
    }
    if (suggestionSpan.current) {
      suggestionSpan.current.innerHTML = "";
    }
    if (resetOptions) {
      setOptionsToShow(originalOptions);
    }
  }

  function inputChange(val) {
    //#region INPUT CHANGE
    setValue(val);

    if (!val) {
      clearRefs(true);
      return;
    }

    let eligibleOptions = originalOptions?.filter((e) => {
      return e?.value?.toLowerCase().startsWith(val?.toLowerCase());
    });

    if (underText.current) {
      underText.current.innerHTML = val;
    }
    if (suggestionSpan.current && eligibleOptions?.length) {
      suggestionSpan.current.innerHTML =
        eligibleOptions[0]?.["value"].slice(val.length ?? 0) || "";
    }

    if (eligibleOptions?.length) {
      setOptionsToShow(eligibleOptions);
    } else if (props?.addNewShow) {
      clearRefs();
      setOptionsToShow([
        {
          value: val,
          addNew: true,
          label: (
            <div
              style={{
                width: "100%",
                display: "grid",
                placeItems: "center",
              }}
            >
              <Button
                type="primary"
                style={{ borderRadius: "5px" }}
                onClick={() => {
                  props?.addNewClick(val?.trim());
                }}
              >
                Add New
              </Button>
            </div>
          ),
        },
      ]);
    } else {
      setOptionsToShow([]);
    }
  }

  function onKeyDown(e) {
    //#region ON KEY DOWN
    const key = e.key;
    if (key === "Tab") {
      if (optionsToShow?.length && suggestionSpan.current.innerHTML) {
        e.preventDefault();
        clearRefs();
        setValue(optionsToShow[0]?.value);
        if (props.onChange) {
          props.onChange(optionsToShow[0]?.value);
        }

        if (props.onSelect) {
          props.onSelect(optionsToShow[0]?.value);
        }
      }
    } else if (key === "ArrowRight") {
      if (e?.target?.selectionStart === value?.length && value?.length > 0) {
        clearRefs();
        setValue(optionsToShow[0]?.value);
        if (props.onChange) {
          props.onChange(optionsToShow[0]?.value);
        }

        if (props.onSelect) {
          props.onSelect(optionsToShow[0]?.value);
        }
      }
    } else if (key === "ArrowUp" || key === "ArrowDown") {
      clearRefs();
    } else if (key === "Enter" && !!optionsToShow[0]?.addNew) {
      props?.addNewClick(value?.trim());
    }
  }

  function onClear() {
    //#region ON CLEAR
    if (props?.onClear) {
      props.onClear();
    }
    setOptionsToShow(originalOptions);
    setValue("");
    inputChange("");
  }

  function onChange(e) {
    //#region ON CHANGE
    if (props?.onChange) {
      props.onChange(e);
    }
    inputChange(e);
  }

  function onSelect(e) {
    //#region ON SELECT
    if (props?.onSelect) {
      props?.onSelect(e);
    }
    setValue(e);
  }

  function onBlur() {
    //#region ON BLUR
    clearRefs();
    if (props?.onBlur) {
      props.onBlur();
    }
  }

  //#region JSX
  return (
    <div
      className={`predict-autocomplete-container ${
        isDarkMode && "predict-autocomplete-container-dark"
      }`}
    >
      <AutoComplete
        popupClassName={`predict-autocomplete-dropdown ${
          props?.popupClassName || ""
        } ${isDarkMode && "darkDropDown"}`}
        className="predict-autocomplete-input"
        allowClear={props?.allowClear || false}
        options={optionsToShow}
        style={props?.style}
        defaultValue={props.defaultValue}
        value={props?.value ?? value}
        placeholder={props?.placeholder}
        showSearch={true}
        suffixIcon={null}
        backfill={true}
        disabled={props?.disabled}
        autoFocus={props?.autoFocus}
        defaultOpen={props?.defaultOpen}
        onKeyDown={onKeyDown}
        onClear={onClear}
        onChange={onChange}
        onSelect={onSelect}
        onBlur={onBlur}
      />
      <span className="predict-autocomplete-suggestion-span">
        <span className="predict-existing-input" ref={underText}></span>
        <span className="predict-suggestion" ref={suggestionSpan}></span>
      </span>
    </div>
  );
}

export default PredictAutocomplete;
