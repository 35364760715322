import "./ChartTitle.scss";
import { useSelector } from "react-redux";
import { Popover, Tag, Tooltip } from "antd";
import { FilterFilled, InfoCircleFilled, KeyOutlined } from "@ant-design/icons";
import { KeyIcon } from "../../../../../assets";
import { ActionsIcon } from "../../../../SidebarPages/DynamicView/src";
import { useChartCardsContext } from "../../ChartCardsContext";
import ThreeDotedActions from "./ThreeDotedActions/ThreeDotedActions";
import MondayButton from "../../../MondayButton/MondayButton";

const ChartTitle = ({
  cardConfigs,
  hasBlur,
  setHasBlur,
  cardFilters,
  generalFilters,
}) => {
  const { id, chartTitle, permanentCard, createdBy } = cardConfigs;

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const {
    setGeneralFilters,
    setUpdatedCards,
    showPrivateCards,
    setNewChanges,
  } = useChartCardsContext();

  return (
    <div className={`chart-card-title ${isDarkMode ? "dark-mode" : ""}`}>
      <div className="title">
        {permanentCard && (
          <Tooltip
            title={
              <>
                <div>This card is default and can not be deleted!</div>
                <div>Created by: {createdBy?.nameOfUser}!</div>
              </>
            }
          >
            <InfoCircleFilled style={{ fontSize: 14, color: "#1264A3" }} />
          </Tooltip>
        )}
        {chartTitle}
      </div>
      <div className="title-right-section">
        <div className="chart-card-active-filters">
          <Tag icon={<KeyOutlined />} color="error">
            {cardConfigs.rowFilterKey}
          </Tag>
          {cardFilters?.length === 0 && (
            <Tag icon={<FilterFilled />} color="error">
              {generalFilters?.GENERAL_CHART_FILTERS?.[0]?.label}
            </Tag>
          )}
          {cardFilters?.map(({ value, filterType, label }) => (
            <Tag
              key={filterType}
              color="processing"
              icon={<FilterFilled />}
              closable={
                !permanentCard ||
                (createdBy.cognitoUserId ===
                  (userConfiguration.cognitoUserId || "") &&
                  !hasBlur)
              }
              onClose={() => {
                setNewChanges(true);

                if (!!permanentCard) {
                  setUpdatedCards((prev) => {
                    const isPrivate = showPrivateCards === "private";

                    return {
                      ...prev,
                      [isPrivate]: prev[isPrivate].map((card) =>
                        card.id === id
                          ? {
                              ...card,
                              permanentFilters: card?.permanentFilters?.filter(
                                (filter) => filter.filterType !== filterType
                              ),
                            }
                          : card
                      ),
                    };
                  });
                } else {
                  setGeneralFilters((prev) => ({
                    ...prev,
                    [id]: prev?.[id]?.filter(
                      (filter) => filter.filterType !== filterType
                    ),
                  }));
                }
              }}
            >
              {label ? label : Array.isArray(value) ? value.join(", ") : value}
            </Tag>
          ))}
        </div>

        <Popover
          overlayClassName={`chart-cards-popover three-doted-actions-popover ${
            isDarkMode ? "chart-cards-popover-content-dark" : ""
          }`}
          overlayStyle={hasBlur && { display: "none" }}
          placement="bottom"
          trigger="hover"
          content={() => (
            <ThreeDotedActions {...{ cardConfigs, setHasBlur, cardFilters }} />
          )}
        >
          <span className="chart-card-actions">
            {hasBlur && (
              <MondayButton
                className="mondayButtonRed"
                tooltipCategory="CHART CARDS"
                Icon={<KeyIcon />}
                onClick={() => {
                  setHasBlur(false);
                }}
              >
                Tap To Unlock
              </MondayButton>
            )}

            <ActionsIcon />
          </span>
        </Popover>
      </div>
    </div>
  );
};

export default ChartTitle;
