// Used inside controller of settings in dynamic base page

export const permitDrawingColumns = [
  { id: "1", content: { value: "logo", label: "logo" }, checked: true },
  {
    id: "2",
    content: { value: "jobSiteAddress", label: "Job Site Address" },
    checked: true,
  },
  {
    id: "3",
    content: { value: "permitStatus", label: "Status" },
    checked: true,
  },
  {
    id: "4",
    content: { value: "projectExecutive", label: "P. Executive" },
    checked: true,
  },
  { id: "5", content: { value: "dueDate", label: "Due Date" }, checked: true },
  {
    id: "6",
    content: { value: "assignedTo", label: "Assigned to" },
    checked: true,
  },
  {
    id: "7",
    content: { value: "drawingType", label: "Drawing Type" },
    checked: true,
  },
  {
    id: "8",
    content: { value: "engProgress", label: "Engineer Progress" },
    checked: true,
  },
  { id: "9", content: { value: "sow", label: "Sow" }, checked: true },
];

export const documentationColumns = [
  { id: "1", content: { value: "logo", label: "logo" }, checked: true },
  {
    id: "2",
    content: { value: "categoryName", label: "Category Name" },
    checked: true,
  },
  {
    id: "3",
    content: { value: "docStatus", label: "Doc Status" },
    checked: true,
  },
  { id: "4", content: { value: "docType", label: "Doc Type" }, checked: true },
  {
    id: "5",
    content: { value: "recordName", label: "Record Name" },
    checked: true,
  },
  // {
  //   id: "6",
  //   content: { value: "createdAt", label: "Created At" },
  //   checked: true,
  // },
  {
    id: "7",
    content: { value: "expirationDate", label: "Due Date" },
    checked: true,
  },
  // {
  //   id: "8",
  //   content: { value: "Created By", label: "createdBy" },
  //   checked: true,
  // },
];

export const estimationsColumns = [
  { id: "1", content: { value: "logo", label: "Logo" }, checked: true },
  {
    id: "2",
    content: { value: "jobSiteAddress", label: "Job Site Address" },
    checked: true,
  },
  { id: "3", content: { value: "estSTATUS", label: "Status" }, checked: true },
  {
    id: "4",
    content: { value: "projectExecutive", label: "P. Executive" },
    checked: true,
  },
  { id: "5", content: { value: "dueDate", label: "Due Date" }, checked: true },
  {
    id: "6",
    content: { value: "engAssignedTo", label: "Engineer" },
    checked: true,
  },
  {
    id: "7",
    content: { value: "statusByEng", label: "Engineer Status" },
    checked: true,
  },
  {
    id: "8",
    content: { value: "engProgress", label: "Engineer Progress" },
    checked: true,
  },
  {
    id: "9",
    content: { value: "archAssignedTo", label: "Architect" },
    checked: true,
  },
  {
    id: "10",
    content: { value: "statusByArch", label: "Architect Status" },
    checked: true,
  },
  {
    id: "11",
    content: { value: "archProgress", label: "Architect Progress" },
    checked: true,
  },
];
