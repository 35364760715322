import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Modal, Form, Button, Tooltip, message } from "antd";
import { API } from "aws-amplify";

import {
  inventoryFields,
  additionalFields,
  footerButtons,
} from "./ModalData/inventoryFields";
import {
  lazyFetch,
  updateDocumentTitle,
  fetchAllData,
} from "../../../../../../utils";
import { filterPeopleList } from "../../../../utils/filterPeopleList";
import { validateForm } from "../../../../Fleet/utils";
import { useEditLogs, useProgressComponent } from "../../../../../../hooks";
import broadcastNotification from "../../../../../../helpers/controllers/broadcastNotification";
import { RenderDynamicComponents } from "../../../../../Header/forms/Components";
import { getChangedData } from "../../../../Accounting/components/utilities";
import WarningModal from "../../../../../commonComponents/WarningModal/WarningModal";
import {
  MondayButton,
  MultiLevelTreeLogs,
  ProgressComponent,
  SortModalFields,
} from "../../../../../commonComponents";
import CustomModalHeader from "../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { FormTextEditor } from "../../../../../Header/forms/Components";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { XIcon } from "../../../../Communication/assets";
import { TransferIconNew } from "../../../../DynamicView/src";
import { TrashIcon } from "../../../../Communication/assets";
import { ReactComponent as WarningIcon } from "../../../../../../icons/warningTriangle.svg";
import { AddIcon } from "../../../../../Header/components/GoogleEvents/assets";

import "./NewFleetInventory.scss";
import { deleteAllRelatedToDos } from "../../../../ToDos/forms/helpers";

let logKeysToDelete = [
  "userId",
  "itemId",
  "lastModifiedBy",
  "createdBy",
  "createdAt",
];

function NewFleetInventory({
  rowObject,
  setVisible,
  visible: open,
  refreshTable = () => {},
  requestObject = null,
  afterSaveRequest = async () => {},
}) {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const authUser = useSelector(
    (state) => state.authenticatedUser.authenticatedUser
  );

  const [warning, setWarning] = useState(false);
  const [description, setDescription] = useState("");
  const [logs, setLogs] = useState([]);
  const [logsVisible, setLogsVisible] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [users, setUsers] = useState([]);
  const [totalNewItems, setTotalNewItems] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(1);
  const [acceptedIndexes, setAcceptedIndexes] = useState([]);
  const [sortOpen, setSortOpen] = useState(false);
  const [sortFieldsObj, setSortFieldsObj] = useState(
    programFields?.find(({ fieldName }) => fieldName === "Modal Sorted Fields")
      ?.fieldOptions?.inventoryModal || {}
  );
  const [accessRight, setAccessRight] = useState();

  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
  } = useProgressComponent({
    categoryName: "Fleets Inventory",
    actionType: !!rowObject ? "Update" : "Create",
  });
  const { saveAddedLogs } = useEditLogs();

  const [form] = Form.useForm();
  const initialCost = Form.useWatch("costPerUnit", form);
  const initialQuantity = Form.useWatch("itemQuantity", form);

  const vendors = useMemo(() => {
    if (!programFields) {
      return [];
    }

    return (
      programFields?.find(({ fieldName }) => fieldName === "Vendors")
        ?.fieldOptions || []
    ).map(({ vendorName, vendorId }) => {
      return {
        label: vendorName,
        value: vendorId,
      };
    });
  }, [programFields]);

  useEffect(() => {
    userConfiguration &&
      setAccessRight(
        userConfiguration?.routeConfig?.find(
          ({ title }) => title === "Fleets Inventory"
        )
      );
  }, [userConfiguration]);

  const getPricingAccess = useMemo(() => {
    return (accessRight?.children || [])
      ?.find(({ title }) => title === "Overview")
      ?.children?.find(({ title }) => title && title === "Price");
  }, [accessRight]);

  function onCancel() {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setVisible(false);
  }

  const inventoryFieldsArr = inventoryFields({
    allItems,
    vendors,
    categories,
    rowObject,
    quantitiesDisabled: !!totalNewItems,
    setTotalCost,
    pricingAccess: getPricingAccess,
    form,
  });

  inventoryFieldsArr?.sort((a, b) => {
    if (Object.keys(sortFieldsObj).length === 0) {
      return 0;
    }
    const aIndex = sortFieldsObj[a.formItemName];
    const bIndex = sortFieldsObj[b.formItemName];
    return aIndex - bIndex;
  });

  function saveHandler() {
    message.loading({
      content: "Saving...",
      key: "saveInventory",
    });

    let q = parseInt(form.getFieldValue("itemQuantity"));
    let cq = parseInt(form.getFieldValue("criticalQuantity"));
    let vendorId = form.getFieldValue("vendorId");
    let selectedVendor = vendors?.find((el) => el?.value === vendorId)?.label;
    let body = {
      itemName: form.getFieldValue("itemName"),
      itemPartNo: form.getFieldValue("itemPartNo"),
      itemDescription: description,
      costPerUnit: totalCost,
      itemQuantity: form.getFieldValue("itemQuantity"),
      itemCategory: form.getFieldValue("itemCategory") ?? "",
      vendorName: selectedVendor ?? "",
      vendorId: form.getFieldValue("vendorId") ?? "",
      criticalQuantity: cq,
      itemStatus: q <= cq ? "Low Stock" : "In Stock",
    };

    setVisibleCreationProgress(rowObject);
    updateProgressStatus({ updatingRecord: "executing" });
    if (!!rowObject) {
      API.put("fleetInventory", `/fleetInventory/${rowObject?.itemId}`, {
        body: { ...body },
      })
        .then(async (res) => {
          //set progress of updating record as 100%
          updateProgressStatus({
            updatingRecord: "finished",
            sendingNotification: "executing",
          });

          message.success({
            content: "Saved Successfully",
            key: "saveInventory",
          });
          refreshTable(res);

          let change = getChangedData(body, rowObject);
          if (!!change) {
            logKeysToDelete.forEach((key) => {
              delete change?.curr?.[key];
              delete change?.prev?.[key];
            });
            saveAddedLogs({
              recordId: res?.itemId,
              recordName: res?.itemName,
              category: "Fleet Inventory",
              currentData: change.curr,
              previousData: change.prev,
              updatedKeys: ["itemPartNo", "costPerUnit"],
            });
          }

          // await broadcastNotification(
          broadcastNotification(
            "11",
            "onInventoryEdit",
            [
              {
                common: `${authUser.given_name} ${authUser.family_name}`,
                itemName: body?.itemName,
              },
              {
                userName: `${authUser.given_name} ${authUser.family_name}`,
                currentUser: authUser.sub,
              },
            ],
            rowObject?.itemId
          ).then((notificationSent) => {
            updateProgressStatus({
              sendingNotification: !!notificationSent ? "finished" : "hasError",
            });
          });

          form.resetFields();
          // onCancel();
        })
        .catch((err) => {
          updateProgressStatus({ updatingRecord: "hasError" });
          message.error({
            content: "Something went wrong while saving",
            key: "saveInventory",
          });
          console.log("Error Saving Item: ", err);
          form.resetFields();
          onCancel();
        });
    } else {
      API.post("fleetInventory", "/fleetInventory", {
        body: { ...body },
      })
        .then((res) => {
          message.success({
            content: "Saved Successfully",
            key: "saveInventory",
          });

          updateProgressStatus({
            updatingRecord: "finished",
            sendingNotification: "executing",
          }); //set progress of creating record as 100%
          setVisibleCreationProgress(res); // save datas to generate logs in case of any fail

          refreshTable(res);

          broadcastNotification(
            "11",
            "onInventoryCreate",
            [
              {
                common: `${authUser.given_name} ${authUser.family_name}`,
                itemName: res?.itemName,
              },
              {
                userName: `${authUser.given_name} ${authUser.family_name}`,
                currentUser: authUser.sub,
              },
            ],
            res?.itemId
          ).then((notificationSent) => {
            updateProgressStatus({
              sendingNotification: !!notificationSent ? "finished" : "hasError",
            });
          });
          saveAddedLogs([
            {
              recordId: res?.itemId,
              recordName: res?.itemName,
              category: "Fleet Inventory",
            },
            {
              recordId: res?.itemId,
              recordName: res?.itemName,
              actionType: "Edit",
              category: "Fleet Inventory",
              currentData: {
                costPerUnit: form.getFieldValue("costPerUnit"),
                itemQuantity: form.getFieldValue("itemQuantity"),
              },
            },
          ]);

          afterSaveRequest({
            recordId: res?.itemId,
            path: "fleetInventory",
            recordName: requestObject?.recordName,
          });

          form.resetFields();
          // onCancel();
        })
        .catch((err) => {
          updateProgressStatus({ updatingRecord: "hasError" });
          message.error({
            content: "Something went wrong while saving",
            key: "saveInventory",
          });
          console.log("Error Saving Item: ", err);
          form.resetFields();
          onCancel();
        });
    }
  }

  function deleteHandler() {
    message.loading({
      content: "Deleting...",
      key: "inventoryDelete",
    });
    onCancel();
    API.del("fleetInventory", `/fleetInventory/${rowObject?.itemId}`)
      .then(() => {
        refreshTable({ itemId: rowObject?.itemId }, "remove");
        message.success({
          content: "Deleted Successfully!",
          key: "inventoryDelete",
        });
        deleteAllRelatedToDos({ recordId: rowObject?.itemId });
        broadcastNotification(
          "11",
          "onInventoryDelete",
          [
            {
              common: `${authUser.given_name} ${authUser.family_name}`,
              itemName: rowObject?.itemName,
            },
            {
              userName: `${authUser.given_name} ${authUser.family_name}`,
              currentUser: authUser.sub,
            },
          ],
          rowObject?.itemId
        );

        saveAddedLogs({
          recordId: rowObject?.itemId,
          recordName: rowObject?.itemName,
          actionType: "Delete",
          category: "Fleet Inventory",
        });
      })
      .catch((err) => {
        console.log("Error Saving Item: ", err);
        message.error({
          content: "Something went wrong while deleting",
          key: "inventoryDelete",
        });
      });
  }

  function removeAdditionalQuantity(index) {
    let newFields = {};
    let existingFields = form.getFieldsValue();
    //when removing an items we want to transfer the properties
    for (let i = index + 1; i < totalNewItems; i++) {
      newFields[`newQuantity#${i - 1}`] =
        existingFields[`newQuantity#${i}`] || 0;
      newFields[`newCost#${i - 1}`] = existingFields[`newCost#${i}`] || 0;
    }

    //if the selection was previously confirmed,
    //we need to reverse the calculation formula
    if (acceptedIndexes?.includes(index)) {
      let quantity = parseInt(form.getFieldValue(`newQuantity#${index}`));
      let cost = parseFloat(form.getFieldValue(`newCost#${index}`));
      let newQuantity = totalQuantity - quantity;
      let newCost = (totalCost * totalQuantity - quantity * cost) / newQuantity;

      setTotalQuantity(newQuantity);
      setTotalCost(newCost);

      setAcceptedIndexes(
        acceptedIndexes
          ?.map((e) => {
            if (e < index) {
              return e;
            } else if (e > index) {
              return e - 1;
            }
          })
          .filter((e) => e !== undefined)
      );

      newFields["costPerUnit"] = newCost?.toFixed(2);
      newFields["itemQuantity"] = newQuantity;
    }

    form.setFieldsValue(newFields);
    setTotalNewItems(totalNewItems - 1);
  }

  function confirmAmounts(index) {
    let quantity = form.getFieldValue(`newQuantity#${index}`);
    let cost = form.getFieldValue(`newCost#${index}`);

    if (!quantity || !cost) {
      message.error({
        content: "Please add a valid quantity and amount",
        key: "inventoryItemsError",
      });
      return;
    }

    quantity = parseInt(quantity);
    cost = parseFloat(cost);
    let newTotalQuantity = totalQuantity + quantity;

    let newCost =
      (totalQuantity * totalCost + quantity * cost) / newTotalQuantity;

    setTotalQuantity(newTotalQuantity);
    setTotalCost(newCost);
    setAcceptedIndexes([...acceptedIndexes, index]);

    form.setFieldsValue({
      costPerUnit: newCost?.toFixed(2),
      itemQuantity: newTotalQuantity,
    });
  }

  let addButtonEnabled =
    !!form.getFieldValue("costPerUnit") && !!form.getFieldValue("itemQuantity");

  useEffect(() => {
    lazyFetch({
      tableName: "fleetInventory",
      listOfKeys: ["itemName", "itemPartNo"],
    })
      .then((res) => {
        setAllItems(res);
      })
      .catch((err) => {
        console.log("Error Getting Items: ", err);
      });

    setUsers(filterPeopleList(userConfiguration?.allUsers?.Items));
  }, []);

  useEffect(() => {
    if (!!rowObject) {
      form.setFieldsValue({
        itemName: rowObject?.itemName,
        itemPartNo: rowObject?.itemPartNo,
        itemPart: rowObject?.itemPart,
        costPerUnit: parseFloat(rowObject?.costPerUnit).toFixed(2),
        itemQuantity: rowObject?.itemQuantity,
        itemCategory: rowObject?.itemCategory,
        vendorName: rowObject?.vendorName,
        criticalQuantity: rowObject?.criticalQuantity,
        vendorId: rowObject?.vendorId,
      });
      setDescription(rowObject?.itemDescription);
      setTotalCost(parseFloat(rowObject?.costPerUnit));
      setTotalQuantity(parseInt(rowObject?.itemQuantity));

      fetchAllData({
        endpoint: "editLogs",
        resultPosition: "editLogs",
        resultId: "logId",
        otherStringParams: {
          getMaxLimit: "true",
          filters: JSON.stringify([
            {
              conditions: [
                {
                  column: "recordId",
                  value: rowObject?.itemId,
                  formula: "is",
                },
              ],
            },
          ]),
        },
      })
        .then((res) => {
          setLogs(res);
        })
        .catch((err) => {
          console.log("Error Getting Logs: ", err);
        });
    }

    if (!!requestObject) {
      form.setFieldsValue({
        itemName: requestObject?.partName,
        itemPartNo: requestObject?.partNumber,
        itemQuantity: requestObject?.quantity,
        costPerUnit: requestObject?.costPerUnit,
        criticalQuantity: requestObject?.criticalQuantity,
        itemCategory: requestObject?.categories,
      });

      setTotalCost(parseFloat(requestObject?.costPerUnit));
      setTotalQuantity(parseInt(requestObject?.quantity));
    }

    let tmpFields = programFields?.find(
      ({ fieldName }) => fieldName === "Fleet Inventory Categories"
    );

    if (!!tmpFields) {
      setCategories(
        tmpFields?.fieldOptions?.map((el) => ({
          value: el?.categoryName,
        }))
      );
    }
  }, [JSON.stringify(rowObject), JSON.stringify(requestObject)]);

  return (
    <Modal
      {...{
        open,
        onCancel,
        title: (
          <CustomModalHeader
            {...{
              title: "New Inventory Item",
              onClick: () => {
                // setTourOpen(true);
                message.info("No tutorial found");
              },
              headerInfo: () => {
                return (
                  userConfiguration.groupName === "Admin" && (
                    <Tooltip title="Sort Fields">
                      <TransferIconNew
                        {...{
                          className: "sortFieldsIcon",
                          onClick: () => {
                            setSortOpen(true);
                          },
                        }}
                      />
                    </Tooltip>
                  )
                );
              },
            }}
          />
        ),
        afterOpenChange: (event) => {
          event && updateDocumentTitle({ newTitle: "New Inventory Item" });
        },
        closable: true,
        closeIcon: <XIcon />,
        footer: footerButtons({
          setWarning,
          setLogsVisible,
          validateForm,
          editMode: !!rowObject,
          form,
          saveHandler,
          onCancel,
          changes: form?.isFieldsTouched(),
          recordId: rowObject?.itemId,
          recordName: rowObject?.itemName,
        }),
        className: `newInventoryItemModal ${
          isDarkMode && "newInventoryItemModalDark"
        }`,
        centered: true,
      }}
    >
      <Form form={form}>
        {RenderDynamicComponents(inventoryFieldsArr, { form })}
        <Tooltip
          title={
            addButtonEnabled ? "" : "Add the initial price and quantity first"
          }
          trigger={["hover", "click"]}
        >
          <MondayButton
            {...{
              Icon: <AddIcon />,
              className: `mondayButtonBlue`,
              disabled: !addButtonEnabled,
              onClick() {
                form.setFieldsValue({
                  [`newQuantity#${totalNewItems}`]: null,
                  [`newCost#${totalNewItems}`]: null,
                });
                setTotalNewItems(totalNewItems + 1);
                if (!totalCost) {
                  setTotalCost(parseFloat(form.getFieldValue("costPerUnit")));
                }
                setTotalQuantity(parseInt(form.getFieldValue("itemQuantity")));
              },
            }}
          >
            Add Quantity
          </MondayButton>
        </Tooltip>
        <div className="inventoryAdditionalItems">
          {[...new Array(totalNewItems).keys()].map((index) => (
            <div className="inventoryAddAdditionalItem">
              {RenderDynamicComponents(
                additionalFields({
                  index,
                  disabled: acceptedIndexes?.includes(index),
                }),
                {
                  form,
                }
              )}
              <div className="actionButton">
                <Button
                  type="primary"
                  style={{ background: "#71cf48" }}
                  disabled={acceptedIndexes?.includes(index)}
                  onClick={() => {
                    confirmAmounts(index);
                  }}
                >
                  <TickIcon />
                </Button>
              </div>
              <div className="actionButton">
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    removeAdditionalQuantity(index);
                  }}
                >
                  <TrashIcon fill="#fff" />
                </Button>
              </div>
            </div>
          ))}
        </div>
        <FormTextEditor
          {...{
            label: "",
            onChange(val) {
              setDescription(val);
            },
            value: description,
            style: {
              width: "100%",
              marginTop: "1rem",
            },
          }}
        />
      </Form>
      {logsVisible && (
        <MultiLevelTreeLogs
          {...{
            visible: logsVisible,
            setVisible: setLogsVisible,
            logsData: logs || [],
            title: "Item Logs",
          }}
        />
      )}

      {!!warning && (
        <WarningModal
          visible={!!warning}
          setVisible={setWarning}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
        >
          <div className="logout-modal-body">
            <span>
              <WarningIcon />
            </span>
            <p>
              Are you sure you want to{" "}
              {warning === "delete" ? "delete?" : "cancel?"}
            </p>
            <div className="buttons">
              <MondayButton
                onClick={() => setWarning(false)}
                Icon={<XIcon />}
                className="mondayButtonRed mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton
                onClick={() => {
                  if (warning === "delete") {
                    deleteHandler();
                  } else {
                    onCancel();
                  }
                }}
                Icon={<TickIcon width={17} height={17} />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
      {sortOpen && (
        <SortModalFields
          {...{
            visible: sortOpen,
            setVisible: setSortOpen,
            title: "Inventory Item",
            rowData: inventoryFieldsArr,
            onSave: (newSort) => {
              setSortFieldsObj(newSort);
            },
            saveKey: "inventoryModal",
          }}
        />
      )}
      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: "Fleets Inventory",
            actionType: !!rowObject ? "Update" : "Create",
            visibleCreationProgress,
            creationProgresses,
            closeModal: onCancel,
          }}
        />
      )}
    </Modal>
  );
}

export default NewFleetInventory;
