import { useSelector } from "react-redux";
import { Form, message } from "antd";
import { InputComponent } from "../../Fleet/components";
import { RenderDynamicComponents } from "../../../Header/forms/Components";
import { useState, useEffect, useMemo } from "react";
import { LogsButton, MondayButton } from "../../../commonComponents";
import { API } from "aws-amplify";
import { XIcon, XIconWhite } from "../../Communication/assets";
import { PlusOutlined } from "@ant-design/icons";
import { saveCitation } from "./helpers/saveCitationFunction";
import SafetyStatus from "../SafetyModal/components/SafetyStatus";
import { InspectionModal, LoadableComp } from "../../XComponents";
import { DeleteIcon, LogsIcon } from "../../DynamicView/src";
import editCitation from "./helpers/editCitationFunction";
import ScheduleViewModal from "../../Projects/Tabs/ScheduleView/components/ScheduleViewModal/ScheduleViewModal";
import "./CitationModal.scss";
import { setInitialDynamicFieldValues } from "./helpers/setInitialDynamicFieldValues";
import { useUploadedFiles, useEditLogs } from "../../../../hooks";
import { useCreateDriveFolders } from "../../../../hooks/useCreateDriveFolders";
import { driveApi } from "../../../../integrations/DriveRequest";
import { getDriveFolderKeyName } from "../../utils/getDriveFolderKeyName";
import useHandleAttachments from "../hooks/useHandleAttachments";
import { DocumentationModal } from "../../Documentation/View/components";
import { DocUploader } from "../../Documentation/View/components/DocumentationModal/components";
import { dayjsNY } from "../../../DateComponents/contants/DayjsNY";
import { EmailIcon } from "../../../InternalChat/assets/icons";
import { useEmailBoxContainer } from "../../Communication/components/EmailBox/providers/EmailBoxContainerProvider";
import EmailBox from "../../Communication/components/EmailBox/EmailBox";
import TasksBasePage from "../../TaskManager/Tasks/TasksBasePage";
import ToDoModal from "../../ToDos/ToDoModal";
import {
  fetchAllData,
  getCognitosForNotification,
  gsiQueryTable,
} from "../../../../utils";
import { generateCitationObjects } from "./helpers/generateCitationObjects";
import { ExportPdfIcon } from "../../../../assets";
import SafetyCitationsExportPreview from "./SafetyCitationsExportPreview";
import { citationValues } from "./helpers/setCitationsValues";
import { camelCaseToNormalText } from "../../../commonComponents/RowDataGridModal/components/utils";
import Report from "../../DynamicView/Pages/Overview/components/Report/Report";
import { ReportIcon } from "../../../../icons";
import { fetchData } from "../../Fleet/utils";
import WarningMessageModal from "./helpers/WarningMessageModal";
import SignaturesSection from "./SignaturesSection";
import broadcastNotification from "../../../../helpers/controllers/broadcastNotification";
import {
  deleteNotifications,
  getCognitosForNotificationByAccess,
} from "../../../../utils/notificationHelpers";
import { useDispatch } from "react-redux";

const CitationModal = ({
  visible,
  setVisible,
  crewId,
  data,
  refreshTable,
  documentationModalFiles,
  setDocumentationModalFiles,
}) => {
  const [
    { userConfiguration },
    { programFields },
    { isDarkMode },
    { sampleObjects },
    { hotCredentials },
    { base64 },
    { accessToken },
    { authenticatedUser },
    { notifications },
  ] = useSelector((state) => [
    state.userConfig,
    state.programFields,
    state.darkMode,
    state.sampleObjects,
    state.hotCredentials,
    state.base64,
    state.accessToken,
    state.authenticatedUser,
    state.notifications,
  ]);

  const [witnessCount, setWitnessCount] = useState(1);
  const [witnessSignings, setWitnessSignings] = useState();
  const [divClickable, setDivClickable] = useState(true);
  const [warningModalVisible, setWarningModalVisible] = useState(false);
  const [crews, setCrews] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [onSaveLoading, setOnSaveLoading] = useState(false);
  const [tasksVisible, setTasksVisible] = useState(false);
  const [toDoVisible, setToDoVisible] = useState(false);
  const [deletedButNotSavedFiles, setDeletedButNotSavedFiles] = useState([]);
  const [showPdfPreview, setShowPdfPreview] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [citations, setCitations] = useState([]);
  const [showCitationWarning, setShowCitationWarning] = useState(false);
  const [bypassCitationWarning, setBypassCitationWarning] = useState(false);
  const [signature, setSignature] = useState("");
  const isAdmin = userConfiguration.groupName.toLowerCase() === "admin";

  const emailHandlers = useEmailBoxContainer();
  const [uploadHandlers, deleteHandlers] = useUploadedFiles([]);
  const { saveAddedLogs } = useEditLogs();
  const dispatch = useDispatch();
  const [filesToBeDeleted, setFilesToBeDeleted, onDelete] = deleteHandlers;
  const [uploadedFiles, setUploadedFiles, formattedFiles, onPickerSuccess] =
    uploadHandlers;
  const {
    emailBoxes,
    openEmailBox,
    closeEmailBox,
    setAttachments,
    attachments,
  } = emailHandlers || {};

  const [form] = Form.useForm();
  const formFields = Form.useWatch([], { preserve: true, form });

  const driveFunctions = useCreateDriveFolders("Safety Citations");
  const driveRequest = driveApi({ accessToken });
  const folderDriveKeyName = getDriveFolderKeyName({
    programFields,
    name: "Safety Citations",
  });

  const objectsWithPk = useMemo(
    () => sampleObjects.filter((item) => item.apiName && item.primaryKey),
    [sampleObjects]
  );
  const dynamicFields = programFields?.find(
    (el) => el?.fieldName === "Safety Dynamic Fields"
  ).fieldOptions["Safety Citations"];

  const citationsHotCredential = hotCredentials?.find(
    (obj) => obj.credentialId === "27"
  );
  const rowObjectKey = "citationObject";
  const { googleDriveUpload: requestUploads = [] } = data || {};

  const safetyDirectorSignature = useMemo(() => {
    if (data?.citationObject?.safetyDirectorSignature) {
      return data?.citationObject?.safetyDirectorSignature;
    } else {
      return (
        programFields?.find((el) => el?.fieldName === "Safety Dynamic Fields")
          ?.fieldOptions?.safetyDirectorSignature || null
      );
    }
  }, [programFields, userConfiguration]);

  const createFolder = async () => {
    setWarningModalVisible(false);
    const folderName = `${(
      form.getFieldValue("citationName") ||
      form.getFieldValue("employee") ||
      ""
    ).trim()} ${dayjsNY().format("DD/MM/YYYY HH:mm:ss")}`;
    const result = await driveFunctions.create({
      parentFolderName: folderName,
      parentId: citationsHotCredential?.credentialValue,
    });
    setDivClickable(false);
    return result;
  };

  const { handleAttachments, handleUploadedFiles } = useHandleAttachments({
    base64,
    accessToken,
    category: "General",
    driveRequest,
    rowObject: data,
    driveFunctions,
    folderDriveKeyName,
    dynamicFields: dynamicFields,
    selectedTeam: [],
    crews,
    userConfiguration,
    defaultId: data?.citationId,
    witnessCount,
    witnessSignings,
    rowObjectKey,
    pdfFileName: `Safety Citation ${data?.citationName || "N/A"}`,
    objectsWithPk,
  });

  useEffect(() => {
    message.loading("Loading...");
    if (!crews?.length) {
      API.get("crews", "/crews")
        .then((res) => {
          setCrews(res);
        })
        .catch((err) => console.log("Error getting crews: ", err))
        .finally(() => {
          message.destroy();
        });
    }
    fetchData("citations").then((citations) => setCitations(citations));
  }, [crews]);

  const validateForm = (selectedRecordIds) => {
    const employee = formFields.employee?.value || formFields.employee;

    if (selectedRecordIds.projectId) {
      function isSameDay(date1, date2) {
        if (!date1 || !date2) return false;
        const d1 = new Date(date1);
        const d2 = new Date(date2);

        return (
          d1.getUTCFullYear() === d2.getUTCFullYear() &&
          d1.getUTCMonth() === d2.getUTCMonth() &&
          d1.getUTCDate() === d2.getUTCDate()
        );
      }
      const existingCitation = citations.find(
        (citation) =>
          citation.citationId !== data?.citationId &&
          citation.citationObject.projectId === selectedRecordIds.projectId &&
          citation.employeeId === employee &&
          isSameDay(citation.citationObject.date, formFields.date)
      );
      if (existingCitation && !bypassCitationWarning) {
        setShowCitationWarning(true);
        setBypassCitationWarning(true);
        return false;
      }
    }
    return true;
  };

  //region Save and Edit
  const onCitationSave = async () => {
    await form.validateFields();

    let newValues = {};
    const selectedRecordIds = dynamicFields.reduce((acc, input) => {
      const primaryKey = objectsWithPk.find(
        (obj) => obj.apiName === input.table
      )?.primaryKey;

      if (!primaryKey) {
        newValues = {
          ...newValues,
          [input.formItemName]: formFields[input.formItemName],
        };
        return acc;
      }

      return {
        ...acc,
        ...(!acc?.[primaryKey] && {
          [primaryKey]: formFields?.[input?.formItemName],
        }),
      };
    }, {});

    if (!validateForm(selectedRecordIds)) {
      return;
    }
    setOnSaveLoading(true);

    Promise.allSettled(
      filesToBeDeleted.map(async (id) => await driveRequest.deleteDriveItem(id))
    );

    setFilesToBeDeleted([]);

    let allFolders = null;
    if (!driveFunctions.parentFolderId && !data?.citationId) {
      try {
        const { folders } = await createFolder();
        allFolders = folders;
      } catch (error) {
        console.error("Error Creating Folder: ", error);
        allFolders = null;
      }
    }

    const driveFolderCondition = () => {
      if (data?.googleDriveFolderIds?.[folderDriveKeyName]) {
        return data?.googleDriveFolderIds;
      } else if (driveFunctions?.parentFolderId) {
        return driveFunctions?.allFolders;
      } else {
        return allFolders;
      }
    };

    const folderName = `${(
      form.getFieldValue("citationName") ||
      form.getFieldValue("employee") ||
      ""
    ).trim()} ${dayjsNY().format("DD/MM/YYYY HH:mm:ss")}`;
    const folderId =
      driveFunctions.parentFolderId || data?.googleDriveFolderIds[rowObjectKey];

    if (folderId && form.getFieldValue("citationName") !== data?.citationName) {
      try {
        await driveRequest.updateFolderName(folderId, folderName);
        console.log("Folder renamed successfully!");
      } catch (error) {
        console.error("Error renaming folder: ", error);
      }
    }

    if (!!documentationModalFiles) {
      const documentedIds = documentationModalFiles.map((d) => d.id);

      formattedFiles.forEach((file) => {
        if (documentedIds.includes(file.id)) {
          file.documented = {
            isDocumented: true,
            docId: documentationModalFiles.docId,
          };
        }
      });
    }
    const employeeId = formFields.employee.value ?? formFields.employee;
    const crewObject = crews.find(
      (crew) => crew.crewId === (crewId || employeeId)
    );
    const employeeName = `${crewObject.crewName} - ${crewObject.crewPosition}`;

    const updatedValues = {
      ...newValues,
      citationName: formFields.citationName,
      employeeId: crewId || employeeId,
      citationObject: {
        ...selectedRecordIds,
        ...newValues,
        employeeName,
        safetyDirectorSignature: signature
          ? { ...safetyDirectorSignature, src: signature }
          : safetyDirectorSignature,
      },
      googleDriveUpload: [
        ...formattedFiles,
        ...(requestUploads.filter(
          (file) => !filesToBeDeleted.includes(file.id)
        ) || []),
      ],
      googleDriveFolderIds: driveFolderCondition(),
    };

    dynamicFields.forEach((input) => {
      const field = formFields[input.formItemName];

      if (field?.$isDayjsObject) {
        updatedValues.citationObject[input.formItemName] = field.valueOf();
      }
    });

    try {
      if (!data) {
        await saveCitation(
          updatedValues,
          statuses[currentStep].statusName || "Draft",
          userConfiguration,
          saveAddedLogs,
          authenticatedUser
        );
        setVisible(false);
        form.resetFields();
        refreshTable();
      } else {
        const { prevObj, currObj } = await generateCitationObjects(
          data,
          form,
          statuses,
          currentStep,
          dynamicFields,
          objectsWithPk,
          updatedValues,
          employeeName
        );

        await editCitation(
          data.citationId,
          updatedValues,
          statuses[currentStep].statusName || "Draft",
          userConfiguration,
          saveAddedLogs,
          prevObj,
          currObj,
          authenticatedUser
        );
      }
    } catch (error) {
      console.error("Error during citation save/edit:", error);
    } finally {
      setVisible(false);
      setOnSaveLoading(false);
    }
  };

  //region Delete
  const deleteFolders = async () => {
    if (!!driveFunctions?.parentFolderId) {
      await driveFunctions?.delete();
      message.success("Folder Deleted Successfully");
    } else if (data?.googleDriveFolderIds?.[folderDriveKeyName]) {
      await driveRequest?.deleteDriveItem(
        data?.googleDriveFolderIds?.[folderDriveKeyName]
      );
      message.success("Folder Deleted Successfully");
    }
  };

  const onFileDelete = (fileId) => {
    setDeletedButNotSavedFiles((prev) => [...prev, fileId]);
    onDelete(fileId);
  };

  const onCitationDelete = async () => {
    if (!data) return;
    const employeeId = formFields.employee.value ?? formFields.employee;
    const crewObject = crews.find(
      (crew) => crew.crewId === (crewId || employeeId)
    );
    const employeeName = `${crewObject.crewName} - ${crewObject.crewPosition}`;

    const updatedValues = {
      citationName: formFields.citationName,
      employeeId: data.citationId,
      citationObject: {
        ...data.citationObject,
        employeeName,
      },
    };
    await API.del("citations", `/citations/${data.citationId}`).then(() => {
      deleteNotifications(notifications, data?.citationId, dispatch);
      if (statuses[currentStep].statusName.toLowerCase() !== "draft") {
        broadcastNotification("125", "onSafetyCitationDeletion", [
          { common: userConfiguration?.nameOfUser },
          {
            userName: userConfiguration?.nameOfUser,
            currentUser: authenticatedUser?.sub,
            cognitos: getCognitosForNotificationByAccess(
              userConfiguration,
              "Safety Citations"
            ),
          },
        ]);
      }
    });

    const { prevObj, currObj } = await generateCitationObjects(
      data,
      form,
      statuses,
      currentStep,
      dynamicFields,
      objectsWithPk,
      updatedValues,
      employeeName
    );

    saveAddedLogs({
      recordId: data.citationId,
      recordName: data.citationName,
      category: "Safety Citation",
      actionType: "Delete",
      topic: data?.citationCategory || "General",
      currentData: {},
      previousData: prevObj || {},
      updatedKeys: Object.keys(prevObj),
    });

    deleteFolders();
    refreshTable();
  };

  //region onCitationCancel
  const onCitationCancel = async () => {
    if (onSaveLoading) return;
    setVisible(false);
    form.resetFields();
    setCurrentStep(0);

    if (!data) {
      deleteFolders();
    } else {
      let filteredFilesToBeDeleted = filesToBeDeleted;
      if (deletedButNotSavedFiles.length > 0) {
        filteredFilesToBeDeleted = filesToBeDeleted.filter(
          (file) => !deletedButNotSavedFiles.includes(file.id)
        );
      }
      await Promise.allSettled([
        ...filteredFilesToBeDeleted.map(
          async (id) => await driveRequest.deleteDriveItem(id)
        ),
        ...uploadedFiles.map(async (file) => {
          if (file.id) {
            await driveRequest.deleteDriveItem(file.id);
          }
        }),
      ]);
    }

    setFilesToBeDeleted([]);
    setDivClickable(true);
    driveFunctions.reset();
    setUploadedFiles([]);
  };

  useEffect(() => {
    const fieldObj =
      programFields?.find((item) => item.fieldName === "Status colors")
        .fieldOptions.Safety || [];
    setStatuses(fieldObj);
  }, [programFields]);

  const statusesColors = useMemo(() => {
    return (
      programFields?.find((el) => el?.fieldName === "Statuses Of Categories")
        ?.fieldOptions?.Safety || []
    );
  }, [programFields]);

  const getFields = async () => {
    try {
      const resolvedFields = await Promise.all(
        dynamicFields.map(async (element) => ({
          key: camelCaseToNormalText(element.formItemName),
          value: await citationValues({
            element,
            rowObject: formFields,
            objectsWithPk,
          }),
        }))
      );

      return [
        {
          key: "Employee Name",
          value: formFields.employee.label,
        },
        { key: "Status", value: statuses[currentStep].statusName ?? "Draft" },
        ...resolvedFields,
        { key: "Safety Director Signature", value: signature },
      ];
    } catch (error) {
      console.error("Error resolving citation values:", error);
      return [];
    }
  };

  useEffect(() => {
    if (!data) {
      form.resetFields();
      if (crewId) {
        const employee = crews.find((crew) => crew.crewId == crewId);
        form.setFieldValue("employee", {
          label: `${employee?.crewName} - ${employee?.crewPosition}`,
          value: crewId,
        });
      }
      setCurrentStep(0);
      return;
    }
    const newDataToAdd = dynamicFields.reduce((acc, input) => {
      const primaryKey = objectsWithPk.find(
        (obj) => obj.apiName === input.table
      )?.primaryKey;

      if (!primaryKey) {
        return acc;
      }

      return {
        ...acc,
        ...(!acc?.[input.formItemName] && {
          [input.formItemName]: data?.citationObject?.[primaryKey],
        }),
      };
    }, {});
    const formValues = {
      citationName: data.citationName,
      ...setInitialDynamicFieldValues(data.citationObject, dynamicFields),
      ...newDataToAdd,
      employee: {
        value: data.employeeId,
        label: data.citationObject.employeeName,
      },
      citationStatus: data.citationStatus,
    };
    setSignature(data.citationObject.safetyDirectorSignature?.src ?? "");
    form.setFieldsValue(formValues);
    const statusIndex = statuses.findIndex(
      (status) => status.statusName === data.citationStatus
    );
    setCurrentStep(statusIndex !== -1 ? statusIndex : 0);
  }, [data, form, statuses, crews]);

  const fetchLogs = async () => {
    message.loading("Retrieving logs, please wait...");
    try {
      const res = await gsiQueryTable({
        tableName: "editLogs",
        indexName: "recordId-index",
        filterKey: "recordId",
        filterValue: data.citationId,
      });
      setEditLogs(res);
    } catch (error) {
      console.log({ error });
    } finally {
      setEditLogsVisibility(true);
      message.destroy();
    }
  };

  //region JSX
  return (
    <LoadableComp loading={false}>
      <ScheduleViewModal
        open={visible}
        onCancel={onCitationCancel}
        title="Safety Citation"
      >
        <div className="citationModal">
          <div
            className={`citationModalContent ${
              isDarkMode ? "citationModalContentDarkMode" : ""
            }`}
          >
            {tasksVisible && (
              <InspectionModal
                {...{
                  visible: tasksVisible,
                  onCancel: () => setTasksVisible(false),
                  title: `Tasks for ${data?.citationName}`,
                  width: 1600,
                  footer: false,
                  closable: true,
                  close: <XIcon fill="#333238" />,
                  className: `header-tasks-modal-light`,
                  transitionName: "linear",
                  isDarkMode,
                }}
              >
                <div style={{ height: "65dvh" }}>
                  <TasksBasePage
                    {...{
                      customTaskProps: {
                        rowData: data,
                        taskTopic: "Safety Citations",
                        taskTopicId: data?.citationId,
                        taskRelatedTo: data?.citationName,
                      },
                    }}
                  />
                </div>
              </InspectionModal>
            )}
            {toDoVisible && (
              <ToDoModal
                {...{
                  toDoVisible,
                  setToDoVisible,
                  recordId: data.citationId,
                  recordName: data.citationName,
                  selectedCategory: "safetyCitations",
                  isDarkMode,
                }}
              />
            )}
            {!!reportModal && (
              <Report
                {...{
                  isModalVisible: reportModal,
                  onCancel: () => setReportModal(false),
                  recordId: data.citationId,
                  customCategoryName: "Safety Citation Report",
                  customPartitionKeys: { identityId: "citationId" },
                  customData: [data],
                  setVisibleReport: setReportModal,
                  sendByEmail: true,
                }}
              />
            )}
            {showPdfPreview && (
              <SafetyCitationsExportPreview
                {...{
                  showPdfPreview,
                  setShowPdfPreview,
                  rowObject: data,
                  getFields,
                }}
              />
            )}
            <div>
              <SafetyStatus
                {...{
                  form,
                  currentStep,
                  safetyStatus: data?.citationStatus || "Draft",
                  isDarkMode,
                  statusesColors,
                  setCurrentStep,
                }}
              />
              <div className="docUploaderAndForm">
                <div className="uploaderSection">
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        zIndex: !data && divClickable ? 10 : 0,
                      }}
                      onClick={() => {
                        if (data || !divClickable) return;
                        setWarningModalVisible(true);
                      }}
                    ></div>
                    {!!documentationModalFiles && (
                      <DocumentationModal
                        {...{
                          visible: !!documentationModalFiles,
                          setVisible: setDocumentationModalFiles,
                          viewTitle: data?.citationName,
                          categoryName: "Safety Citations",
                          docType: data?.citationCategory || "General",
                          folderId: data?.googleDriveFolderIds,
                          recordId: data?.citationId,
                          recordValue: data?.citationName,
                          docStatus: data?.citationStatus,
                          docStatuses: statusesColors,
                          googleDriveUploads: documentationModalFiles,
                          setDocumentation: () => {},
                          recordName: data?.citationName,
                          record: data,
                          accessToken,
                          teamsConfiguration: [],
                          isModalOpenedFromFileItem: true,
                          onSaveParentModal: onCitationSave,
                        }}
                      />
                    )}
                    <DocUploader
                      {...{
                        uploadedFiles: [
                          ...uploadedFiles,
                          ...requestUploads.filter(
                            (file) => !filesToBeDeleted.includes(file.id)
                          ),
                        ],
                        onPickerSuccess({ uploadedFiles }) {
                          onPickerSuccess({ uploadedFiles });
                          handleUploadedFiles(uploadedFiles);
                        },
                        onDelete: onFileDelete,
                        disabled:
                          (!data?.googleDriveFolderIds?.[folderDriveKeyName] &&
                            !driveFunctions.parentFolderId) ||
                          onSaveLoading,
                        onChange() {},
                        folderId:
                          data?.googleDriveFolderIds?.[folderDriveKeyName] ||
                          driveFunctions.parentFolderId,
                        isCreateDocumentEnabled: true,
                        setDocumentationModalFiles,
                      }}
                    />
                  </div>
                  <WarningMessageModal
                    visible={!!warningModalVisible}
                    setVisible={setWarningModalVisible}
                    title="Warning Message"
                    message="You are about to create a folder without saving the citation. Are you sure you want to proceed?"
                    onConfirm={() => {
                      createFolder();
                      setDivClickable(false);
                      setWarningModalVisible(false);
                    }}
                    onCancel={() => {
                      setWarningModalVisible(false);
                    }}
                  />
                  <WarningMessageModal
                    visible={!!showCitationWarning}
                    setVisible={setShowCitationWarning}
                    title="Warning: Existing Citation"
                    message="There is an existing safety citation for this employee on this date for the selected jobsite. Are you sure you want to proceed?"
                    onConfirm={() => {
                      setShowCitationWarning(false);
                      setBypassCitationWarning(true);
                      onCitationSave();
                    }}
                    onCancel={() => {
                      setBypassCitationWarning(false);
                      setShowCitationWarning(false);
                    }}
                  />
                </div>
                <Form form={form} layout="vertical" onFinish={onCitationSave}>
                  <div className="formElements">
                    <InputComponent
                      {...{
                        label: "Citation Name",
                        formItemName: "citationName",
                        placeholder: "Citation Name...",
                        type: "text",
                        required: true,
                        value: form.getFieldValue("citationName")?.value,
                      }}
                    />
                    <InputComponent
                      {...{
                        label: "Employee",
                        formItemName: "employee",
                        placeholder: "Select Employee...",
                        type: "select",
                        required: true,
                        disabled: crewId,
                        customOptions: crews
                          .filter(
                            (crew) =>
                              crew.crewStatus?.toLowerCase() === "active"
                          )
                          .map((crew) => ({
                            label: `${crew.crewName} - ${crew.crewPosition}`,
                            value: crew.crewId,
                          })),
                        value: form.getFieldValue("employee")?.value,
                      }}
                    />
                    {RenderDynamicComponents(
                      dynamicFields.map((field, i) => {
                        const keysToUse = [
                          ...new Set(
                            dynamicFields
                              .filter((input) => input.table == field.table)
                              .map((input) => input.formItemName)
                          ),
                        ];
                        if (!field.table) {
                          return {
                            formItemName: field.formItemName,
                            label: field.label,
                            placeholder: field.placeholder,
                            type: field.type,
                            options: field.options,
                            index: i,
                            required: field.required,
                            ...(field.type === "timepicker"
                              ? {
                                  format: "HH:mm",
                                }
                              : {}),
                            ...(field.type === "datepicker"
                              ? {
                                  format: "MM/DD/YYYY",
                                }
                              : {}),
                          };
                        } else {
                          const primaryKey = objectsWithPk.find(
                            (obj) => obj.apiName === field.table
                          )?.primaryKey;
                          return {
                            selectedTopic: {
                              apiName: field.table,
                              primaryKey: primaryKey,
                              rowName: field.key,
                            },
                            formItemName: field.formItemName,
                            label: field.label,
                            index: i,
                            modifyValue: true,
                            placeholder: field.placeholder,
                            type: "fetchOptions",
                            required: field.required,
                            onChange: (_, input) => {
                              keysToUse.forEach((key) => {
                                form.setFieldValue(key, input.value);
                              });
                            },
                          };
                        }
                      }),
                      { form }
                    )}
                    <SignaturesSection
                      {...{
                        safetyDirectorSignature,
                        signature,
                        setSignature,
                        removeDisabled: !isAdmin,
                        signDisabled: !isAdmin,
                      }}
                    />
                  </div>
                </Form>
              </div>
            </div>
            <div className="formButtons">
              {data && (
                <div>
                  <MondayButton
                    htmlType="reset"
                    className="mondayButtonRed"
                    Icon={<DeleteIcon />}
                    disabled={onSaveLoading}
                    onClick={() => {
                      onCitationDelete();
                      setVisible(false);
                    }}
                  >
                    Delete
                  </MondayButton>
                  <LogsButton
                    {...{
                      logsTitle: `${data.citationName} Logs`,
                      filtersObj: { recordId: data.citationId },
                    }}
                  />
                  <MondayButton
                    className="mondayButtonBlue emailButton"
                    Icon={<EmailIcon />}
                    disabled={onSaveLoading}
                    onClick={() => handleAttachments(data.googleDriveUpload)}
                  >
                    Email
                  </MondayButton>
                  <MondayButton
                    className="mondayButtonBlue"
                    Icon={<ExportPdfIcon />}
                    onClick={() => setShowPdfPreview(true)}
                  >
                    PDF Preview
                  </MondayButton>
                  <MondayButton
                    className="mondayButtonBlue"
                    Icon={<ReportIcon />}
                    onClick={() => setReportModal(true)}
                  >
                    Report
                  </MondayButton>
                  <MondayButton
                    className="mondayButtonGreen"
                    Icon={<PlusOutlined />}
                    disabled={onSaveLoading}
                    onClick={() => setTasksVisible(true)}
                  >
                    Tasks
                  </MondayButton>
                  <MondayButton
                    className="mondayButtonGreen"
                    Icon={<PlusOutlined />}
                    onClick={() => setToDoVisible(true)}
                    disabled={onSaveLoading}
                  >
                    To Do
                  </MondayButton>
                </div>
              )}
              <MondayButton
                className="mondayButtonRed"
                Icon={<XIconWhite />}
                onClick={onCitationCancel}
                disabled={onSaveLoading}
              >
                Cancel
              </MondayButton>
              <MondayButton
                className="mondayButtonGreen"
                Icon={<PlusOutlined />}
                disabled={onSaveLoading}
                onClick={onCitationSave}
              >
                Save
              </MondayButton>
            </div>
          </div>
          {emailBoxes?.length > 0 && (
            <div className="citationsEmailBox">
              <EmailBox
                {...{
                  rowData: data,
                  onClose: () => {
                    if (attachments.length > 0) setAttachments([]);
                    closeEmailBox(0);
                  },
                  attachments,
                  openEmailBox,
                }}
              />
            </div>
          )}
        </div>
      </ScheduleViewModal>
    </LoadableComp>
  );
};

export default CitationModal;
