import "./ChangePasswordModal.scss";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Input, message, Tooltip } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { API, Auth } from "aws-amplify";
import { v4 } from "uuid";
import { Tick } from "../../../../../Roles/src/index";
import { XIcon } from "../../../../../../../../SidebarPages/Communication/assets/index";
import { MondayButton } from "../../../../../../../../commonComponents/index";
import { useUserSession } from "../../../../../../../../../hooks/useUserSession";
import { apiPut } from "../../../../../../../../../utils/ApiMethods";
import { apiRoutes } from "../../../../../../../../SidebarPages/Fleet/utils";
import {
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../../../../../utils/windowMessages";
import { updateOtherUser } from "../../../../../../../../../actions/userConfig";
import { callGmailAPI } from "../../../../../../../../SidebarPages/Communication/functions";
import { HiddenIcon } from "../../../../../../../../SidebarPages/DynamicView/src";
import { ClosedEyeIcon } from "../../../../../../../../../assets";
import { updateDocumentTitle } from "../../../../../../../../../utils";
import { useEditLogs } from "../../../../../../../../../hooks";

const ChangePasswordModal = ({ updatePassword, setUpdatePassword }) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [readOnly, setReadOnly] = useState(true);
  const userSession = useUserSession();

  const { activeSessions = [], identityId } = userConfiguration;

  const dispatch = useDispatch();
  const { saveAddedLogs } = useEditLogs();

  const validatePassword = (password) => {
    const regex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?=.*[a-zA-Z0-9]).{8,}$/;
    return regex.test(password);
  };

  const closeModal = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setUpdatePassword(false);
  };

  const handleUpdatePassword = async () => {
    if (
      !oldPassword.trim() ||
      !newPassword.trim() ||
      !confirmNewPassword.trim()
    ) {
      message.error("Please fill in all fields");
      return;
    }

    if (newPassword !== confirmNewPassword) {
      message.error("New password do not match");
      return;
    }

    if (oldPassword === newPassword) {
      message.error("New password can't be same with Old password");
      return;
    }

    if (!validatePassword(newPassword)) {
      message.error("Password is invalid");
      return;
    }

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword).then(() => {
        closeModal();
        message.success("Password updated successfully");
        sendEmail(userConfiguration);
        addNewPasswordToLogs();
        logoutAllSessions();
      });
    } catch (error) {
      console.error("Error changing password ", { error });
      if (error.message === "Incorrect username or password.") {
        message.error("Incorret Current Password");
        return;
      }
      message.error(error.message);
    }
  };

  //Logs user out from all sessions
  const logoutAllSessions = async () => {
    const updatedActiveSessions = activeSessions?.map((session) => ({
      ...session,
      logout: true,
    }));

    showLoadingMsg();

    await apiPut(apiRoutes.userConfiguration, identityId, {
      activeSessions: updatedActiveSessions,
    }).catch((error) => {
      console.log("Error puting user configuration ", error);
    });

    dispatch(
      updateOtherUser({ identityId, activeSessions: updatedActiveSessions })
    );
    showSuccessMsg();

    await userSession.close();
    await Auth.signOut({ global: true }).then((e) => {
      window.location.pathname = "/login";
    });
  };

  const addNewPasswordToLogs = () => {
    const newObj = {
      actionType: "Change Password",
      recordId: userConfiguration.cognitoUserId,
      recordName: userConfiguration.nameOfUser,
      category: "Change Password",
      recordName: userConfiguration.nameOfUser,
      updatedKeys: [
        {
          key: v4(),
          label: "Password Changed",
          previousValue: "",
          updatedValue: "",
        },
      ],
    };

    saveAddedLogs(newObj);
  };

  function sendEmail(userConfiguration) {
    callGmailAPI("sendEmail", {
      to: [userConfiguration.userName],
      subject: "Password Changed",
      body: `<p>Hello ${userConfiguration.nameOfUser},</p>
      <br/>
      <p>You have successfully updated your password of Core system.</p>
      <p>You can access it from <a href="http://portal.corescaffold.com">Portal</a>.</p>
      <br/>
      <p>Best Regards,</p>
      <p>Core Team</p>`,
      attachments: [],
      category: "error",
      authData: false,
    })
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <div>
      <Modal
        centered
        className={`updatedPassword ${isDarkMode ? "updatedPasswordDark" : ""}`}
        title="Change Password"
        afterOpenChange={(event) => {
          event && updateDocumentTitle({ newTitle: "Change Password" });
        }}
        open={updatePassword}
        onCancel={closeModal}
        footer={
          <div className="actionButtons">
            <MondayButton
              onClick={closeModal}
              {...{ Icon: <XIcon />, className: "mondayButtonRed" }}
            >
              Cancel
            </MondayButton>
            <MondayButton
              onClick={handleUpdatePassword}
              {...{ Icon: <Tick width={17} height={17} /> }}
            >
              Save
            </MondayButton>
          </div>
        }
        closeIcon={<XIcon />}
      >
        <div className="modal-inputs">
          <div>
            <label className="inputLabel">Current Password</label>
            <Input.Password
              placeholder="Type current password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              readOnly={readOnly}
              onFocus={() => setReadOnly(false)}
              onBlur={() => setReadOnly(true)}
              iconRender={(visible) =>
                visible ? (
                  <ClosedEyeIcon width={16} height={16} cursor={"pointer"} />
                ) : (
                  <HiddenIcon width={16} height={16} cursor={"pointer"} />
                )
              }
            />
          </div>
          <div>
            <label className="inputLabel">New Password</label>
            <div className="input-info">
              <Input.Password
                type="password"
                placeholder="Type new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                autoComplete="off"
                iconRender={(visible) =>
                  visible ? (
                    <ClosedEyeIcon width={16} height={16} cursor={"pointer"} />
                  ) : (
                    <HiddenIcon width={16} height={16} cursor={"pointer"} />
                  )
                }
              />
              <Tooltip
                title={
                  <div style={{ padding: "2px" }}>
                    <h1
                      style={{
                        color: "white",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      New Password most contain:
                    </h1>
                    <ul>
                      <li>At least 8 characters</li>
                      <li>At least 1 uppercase character</li>
                      <li>At least 1 number</li>
                      <li>At least 1 special character</li>
                    </ul>
                  </div>
                }
              >
                <InfoCircleFilled
                  style={{ color: "#1264A3", fontSize: "20px" }}
                />
              </Tooltip>
            </div>
          </div>
          <div>
            <label className="inputLabel">Confirm New Password</label>
            <div className="">
              <Input.Password
                type="showConfirmPassword"
                placeholder="Confirm the password you just entered"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                iconRender={(visible) =>
                  visible ? (
                    <ClosedEyeIcon width={16} height={16} cursor={"pointer"} />
                  ) : (
                    <HiddenIcon width={16} height={16} cursor={"pointer"} />
                  )
                }
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ChangePasswordModal;
