import { darkModeRowStyleRules } from "../../tools/formatters/GridStyles";
import HoistForm2 from "../HoistForm/HoistForm2";
import React, { useRef, useState } from "react";
import _ from "lodash";

import { AgGridReact } from "ag-grid-react";
import ServiceColumnDefiner, {
  defaultColDef,
} from "../../tools/columnDefiners/ServiceColumnDefiner";
import { getContextMenuItems } from "../../tools/controllers/ContextMenuItems";
import { cellValueChanged } from "../../tools/controllers/CellEditingEvents";
import { onRangeSelectionChanged } from "../../tools/polyfillers";
import { useMemo, useCallback } from "react";
import { useTakeOffContext } from "../../context";
import { useSelector } from "react-redux";
import {
  defaultScopeColumnDefs,
  scopeColDefPropsByType,
} from "../../../../../pages/Settings/settingsComponents/OtherScopes";
import { useRedux } from "../../../../hooks/useRedux";
import DataEntryGridView from "../../DataEntryGridView";
import { Button, message } from "antd";
import GeneralAddonEditor from "../../subcomponents/cellRenderers/AddonEditor/GeneralAddonEditor";
import SelectEditor from "../../subcomponents/cellRenderers/SelectEditor/SelectEditor";
import { DimensionEditor } from "../../subcomponents/cellEditors";
import { PriceTablePPURenderer } from "../../subcomponents/cellRenderers";
import {
  AddonEditor,
  CheckboxRenderer,
  Header,
  MultipleChoiceRenderer,
  rtfEditor,
  ScopeSelectorModal,
  ServiceMenuContainer,
  SidewalkShedAddonsDetail,
  SidewalkShedPPUAdvisor,
  SidewalkShedRentAdvisor,
  StatusPanel,
  rtfEditorAddon,
} from "../../subcomponents";
import SidewalkShedAddonsRenderer from "../../subcomponents/SidewalkShed/SidewalkShedAddonsDetail/SidewalkShedAddonsRendererFunc";
import JumpRenderer from "../../subcomponents/cellRenderers/jumpRenderer";
import ApprovedRenderer from "../../subcomponents/cellRenderers/approvedRenderer";
import PPUSuggestor from "../SidewalkShed/SidewalkShedPPUAdvisor/PPUSuggestor";
import ApprovedRend from "../cellRenderers/ApprovedRend";
import SelectCellEditor from "../cellRenderers/SelectEditor/SelectCellEditor";
import CheckerRender from "../cellRenderers/CheckboxRenderer/CheckerRender";
import HoistFormFunc from "../HoistForm/HoistFormFunc";
import useUnmount from "../../../../../../hooks/useUnmount";
import PLIAddon from "./PLIAddon/PLIAddon";
import { checkIfServiceIsHoist } from "../../models/Service";
import moment from "moment";
import useTakeOffAccessRights from "../../tools/controllers/useTakeOffAccessRights";
import ServiceAddon from "../../ServiceAddon";
import ElevationAddons from "./ElevationAddons/ElevationAddons";
import "./elevationPanel.scss";
var gridApi;
export const ElevationContent = ({
  service,
  serviceIndex,
  optionIndex,
  elevationIndex,
  elevation,
  // gridData,
  dataEntryGrid,
  accessRights,
  removePLIRow,
  docStateIndex,
  loading,
  unitMeasurement,
  // darkMode,
}) => {
  const [gridData, setTakeOffTableData] = useRedux("takeOffTableData");
  const [darkMode] = useRedux("estimationsDarkMode", true, false);
  const [focusedCell, setFocusedCell] = useRedux("focusedCell", null, false);
  const serviceDefinitions = useSelector((state) => state.serviceDefinitions);
  const scopesColumnDefs = serviceDefinitions?.filter(
    ({ isScope }) => !!isScope
  );
  const [isWritable] = useRedux("estimationsIsWritable");
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const { canViewPrice = false } = useTakeOffAccessRights("Take-Off");

  // const aggridRef =
  const {
    stateSetter,
    updateStateAndSave,
    resizeGridRows,
    handleSave,
    handleInputElementChange,
    saveSidewalkShedAddonsData,
    onAddonRichTextChange,
    getAddonsCaption,
  } = dataEntryGrid;
  const {
    // gridData,
    pricingData,
    ServicesIndex,
    selectOptions,
    selectedPriceSchemesForService,
    priceView,
    priceEdit,
    UIFeaturesForEachElevation,
    agGridKey,
  } = dataEntryGrid.state;
  const { agGridTheme } = dataEntryGrid.props;

  // const { elevationId } = elevation;

  //  this function is to check if for one collapsed ag grid that have more then 5 addons addedd the master deal row the height will be 300 px otherwise the height will be 180px  the funcion is passed in ag grid
  let datas = service?.serviceOptions[0][0]?.items;
  const addonItemsLength = useMemo(() => {
    if (!!datas) {
      const funcArr = [];
      datas?.map((el) => {
        el?.addons?.map((x) => {
          funcArr.push(x);
        });
      });
      return funcArr?.length;
    }
  }, [datas]);

  const handleCheckboxElementChange = (e, itemReference, irrelevant) => {
    // const pliIndex = gridData[serviceIndex].serviceOptions[optionIndex][
    //   elevationIndex
    // ]?.items.findIndex((item) => item.id === itemReference.id);
    // let temp = _.cloneDeep(gridData);
    // _.update(
    //   temp[serviceIndex].serviceOptions[optionIndex][elevationIndex][pliIndex],
    //   "approved",
    //   () => e.target.checked
    // );
    // setTakeOffTableData(temp);
    // this.props.updateStateAndSave(temp);
    // this.props.handleSave(false);
  };
  const onCellKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default Enter behavior
      const focusedCell = focusedCell.cell;
      console.log("focusedCell", focusedCell);
      if (focusedCell) {
        message.info("Enter");
        gridApi.tabToNextCell();
      }
      //   const nextColumn = gridColumnApi.getColumnAfter(focusedCell.column);
      //   if (nextColumn) {
      //     // Programmatically focus on the next cell
      //     const nextCell = gridApi.getCellForCol(
      //       nextColumn,
      //       focusedCell.rowIndex
      //     );
      //     if (nextCell) {
      //       setTimeout(() => {
      //         nextCell.getGui().focus();
      //       }, 0);
      //     }
      //   }
      // }
    }
  };

  const cellValueChangedElevation = useCallback(
    (params) => {
      console.log("cellValueChangedElevation", params);
      return cellValueChanged(
        params,
        serviceIndex,
        optionIndex,
        elevationIndex,
        gridData,
        selectedPriceSchemesForService,
        ServicesIndex,
        updateStateAndSave,
        setTakeOffTableData,
        canViewPrice
      );
    },
    [gridData]
  );

  const frameworkComponents = {
    StatusPanel,
    CheckboxRenderer: (params) => (
      <CheckerRender {...{ ...params, handleCheckboxElementChange }} />
    ),
    JumpRenderer,
    ApprovedRenderer: ApprovedRend,
    rtfEditor,
    rtfEditorAddon,
    MultipleChoiceRenderer,
    SidewalkShedAddonsDetail: (params) => (
      <PLIAddon
        {...{
          ...params,
          handleSave,
        }}
      />
    ),
    // SidewalkShedAddonsDetail,
    SidewalkShedAddonsRenderer: (params) => (
      <SidewalkShedAddonsRenderer
        {...{
          ...params,
          handleSave,
        }}
      />
    ),
    // SidewalkShedPPUAdvisor,
    SidewalkShedPPUAdvisor: (params) => (
      <PPUSuggestor {...{ ...params, ServicesIndex }} />
    ),
    SidewalkShedRentAdvisor,
    AddonEditor,
    GeneralAddonEditor,
    SelectEditor: SelectCellEditor,
    DimensionEditor,
    PriceTablePPURenderer,
  };

  const getContextMenuItems = (params) => {
    let id = undefined; //a new id
    let NOT_ON_ROWS = true; //if we have clicked (with right button) not in any row
    if (params.node) {
      //if we are in an existing row
      id = params.node.data.id;
      NOT_ON_ROWS = false;
    }
    let result = [
      "autoSizeAll",
      "resetColumns",
      "separator",
      {
        name: "Append Row",
        icon: '<img src="https://img.icons8.com/material-outlined/16/ffffff/plus--v1.png" alt=""/>',
        action: () => {
          dataEntryGrid.appendPLIRow(
            params.api,
            serviceIndex,
            optionIndex,
            elevationIndex,
            id
          );
        },
      },
      // {
      //   name: "Duplicate Row",
      //   icon: '<img src="https://img.icons8.com/material-outlined/16/ffffff/duplicate.png" alt=""/>',
      //   action: () => {
      //     dataEntryGrid.duplicatePLIRow(
      //       params.api,
      //       serviceIndex,
      //       optionIndex,
      //       elevationIndex,
      //       params.node.data.id
      //     );
      //   },
      //   disabled: NOT_ON_ROWS,
      // },
      // {
      //   name: "Delete Row",
      //   icon: '<img src="https://img.icons8.com/material-outlined/16/ffffff/minus-sign.png" alt=""/>',
      //   action: () => {
      //     const { removePLIRow } = dataEntryGrid;
      //     removePLIRow(
      //       params.api,
      //       serviceIndex,
      //       optionIndex,
      //       elevationIndex,
      //       params.node.data.id
      //     );
      //   },
      //   disabled: NOT_ON_ROWS,
      // },
      "separator",
      "copy",
      "copyWithHeaders",
      "paste",
      "separator",
      "export",
      "chartRange",
    ];

    return result;
  };

  const myElevationRowData = useMemo(() => {
    const myData = Array.isArray(gridData)
      ? gridData?.[serviceIndex]?.serviceOptions?.[optionIndex]?.[
          elevationIndex
        ]?.items
      : [];

    return myData;
  }, [gridData, serviceIndex, optionIndex, elevationIndex]);

  //   const masterDetailDissapear = () =>{
  // 	console.warning(this.addonItemsLength)
  //   }

  // if (loading) {
  //   return <div>Loading...</div>;
  // }
  const tempCond = checkIfServiceIsHoist(service);
  switch (
    tempCond //if hoist we have a slightly different approach
  ) {
    case true: {
      return (
        <HoistFormFunc
          serviceId={service?.serviceId}
          indexes={{ serviceIndex, optionIndex, elevationIndex }}
          {...{
            handleSave,
            resizeGridRows,
            handleInputElementChange,
            gridData,
            isWritable,
            service,
            ServicesIndex,
            UIFeaturesForEachElevation,
            pricingData,
            selectOptions,
            updateStateAndSave,
            saveSidewalkShedAddonsData,
            onAddonRichTextChange,
            agGridTheme,
            docStateIndex,
          }}
          setState={stateSetter}
          darkMode={darkMode}
        />
        // <HoistForm2
        //   serviceId={service?.serviceId}
        //   indexes={{ serviceIndex, optionIndex, elevationIndex }}
        //   {...{
        //     handleSave,
        //     resizeGridRows,
        //     handleInputElementChange,
        //     gridData,
        //     isWritable,
        //     service,
        //     ServicesIndex,
        //     UIFeaturesForEachElevation,
        //     pricingData,
        //     selectOptions,
        //     updateStateAndSave,
        //     saveSidewalkShedAddonsData,
        //     onAddonRichTextChange,
        //     agGridTheme,
        //     docStateIndex,
        //   }}
        //   setState={stateSetter}
        // />
      );
    }
    default: {
      // const UIFeatureForThisElevation =
      //   dataEntryGrid.state.UIFeaturesForEachElevation[service.serviceId]?.[
      //     optionIndex
      //   ]?.[elevationIndex];
      return (
        <div
          key={elevationIndex}
          className={`elevationContainer ${
            darkMode ? "ag-theme-alpine-dark" : "ag-theme-alpine"
          }`}
          style={{ height: "100%" }}
        >
          <AgGridReact //ag-grid for an elevation. example: SS-2-01
            key={docStateIndex}
            overlayLoadingTemplate={
              '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
            }
            className="takeOffElevation"
            // sideBar={true}
            context={{
              isWritable,
              handleSave,
              cellValueChangedElevation,
            }}
            rowData={myElevationRowData}
            // suppressNavigateAfterEdit={true}
            // immutableData={true}
            // enableFillHandle={true}
            domLayout={"autoHeight"}
            // detailGridOptions={}
            getRowNodeId={(data) => data.id}
            embedFullWidthRows={true}
            animateRows={true}
            undoRedoCellEditing={true}
            enableRangeSelection={false}
            undoRedoCellEditingLimit={20}
            detailRowHeight={addonItemsLength > 5 ? 300 : 200} // this make the open addon size
            sideBar="columns"
            rowSelection={"single"}
            enableCellChangeFlash={true}
            // onCellFocused={(params) => {
            //   setFocusedCell({ cell: params, time: moment().valueOf() });
            // }}
            statusBar={dataEntryGrid.state.statusBar}
            // gridOptions={{
            //   // i want it client side
            //   rowModelType: "clientSide",
            // }}
            headerHeight={32}
            onGridReady={(params) => {
              // setGridApi(params.api);
              // setGridColumnApi(params.columnApi);
              dataEntryGrid.onGridReady(
                params,
                elevation.items,
                service.serviceId,
                optionIndex,
                elevationIndex,
                params.api.setDomLayout("autoHeight")
              );
            }}
            // rowData={elevation.items}
            columnDefs={ServiceColumnDefiner(
              service,
              serviceIndex,
              optionIndex,
              elevationIndex,
              dataEntryGrid,
              agGridTheme,
              accessRights,
              scopesColumnDefs,
              handleSave
            )}
            getContextMenuItems={getContextMenuItems}
            suppressDragLeaveHidesColumns={true}
            allowDragFromColumnsToolPanel={true}
            defaultColDef={defaultColDef}
            onCellEditingStarted={(params) => {
              dataEntryGrid.cellEditingStarted(params);
              setFocusedCell({ cell: params, time: moment().valueOf() });
            }}
            onCellEditingStopped={(params) => {
              // // dataEntryGrid.cellEditingStopped(params);
              // params.api.navigateToNextCell();
              // console.log("onCellEditingStopped", params);
            }}
            rowHeight={40}
            // getRowHeight={getRowHeight}
            onCellValueChanged={(params) => cellValueChangedElevation(params)}
            frameworkComponents={frameworkComponents}
            onRangeSelectionChanged={onRangeSelectionChanged}
            // getRowNodeId={dataEntryGrid.getRowNodeId}
            // onCellKeyPress={dataEntryGrid.onCellKeyPress}
            // onCellKeyPress={(params) => {
            //   if (
            //     params.event.code === "Tab" ||
            //     params.event.code === "Enter"
            //   ) {
            //     params.event.preventDefault(); // Prevent default Tab or Enter behavior
            //     const cellToFocus = focusedCell.cell;
            //     if (cellToFocus) {
            //       const { rowIndex, column } = cellToFocus;
            //       params.api.startEditingCell({
            //         rowIndex,
            //         colKey: column.colId,
            //         keyPress: params.event.code,
            //       });
            //     }
            //   }
            // }}
            onToolPanelVisibleChanged={(params) => {
              params.api.sizeColumnsToFit();
            }}
            // onCellFocused={dataEntryGrid.handleGridCellFocused}
            masterDetail={true}
            detailRowAutoHeight={true}
            detailCellRenderer="SidewalkShedAddonsDetail"
            detailCellRendererParams={{
              ServicesIndex,
              handleSave,
              isWritable,
              pricingData,
              service,
              selectOptions,
              setState: stateSetter,
              indexes: { serviceIndex, optionIndex, elevationIndex },
              saveSidewalkShedAddonsData,
              onAddonRichTextChange,
              priceView,
              priceEdit,
              updateStateAndSave,
              gridData,
              agGridTheme: dataEntryGrid.props.agGridTheme,
            }}
            rowClassRules={darkModeRowStyleRules}
          />{" "}
          <ElevationAddons
            {...{
              serviceIndex,
              unitMeasurement,
              pricingData,
              service,
              elevation: myElevationRowData,
              elevationIndex,
              optionIndex,
              updateStateAndSave,
              canViewPrice,
            }}
          />
        </div>
      );
    } //end of default case for switch (service.serviceId)
  }
};

// export const ElevationContent = React.memo(MemoElevationContent);

//What is the optimal height of panel content when we open it. It is based on number of items and if too panel on the right is open
export const calculateElevationOptimalHeight = (
  items,
  UIFeatureForThisElevation,
  rowHeight = 50
) => {
  // return (60 * items.length) > 150 ? (60 * items.length) : 150

  let differentHeights = {
    minHeight: 200,
    itemsHeight: 0,
    toolPanelHeight: 0,
  };

  if (rowHeight * items.length > 150)
    differentHeights.itemsHeight = items.length * rowHeight;

  if (UIFeatureForThisElevation?.toolPanelVisible !== undefined) {
    if (UIFeatureForThisElevation.toolPanelVisible === true) {
      differentHeights.toolPanelHeight = 500;
    }
  }

  return Math.max(...Object.values(differentHeights));
};
