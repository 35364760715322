import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";

export const columnDefs = [
  {
    field: "periodDate",
    headerName: "Period",
    cellClass: "cell-left-aligned",
    cellRenderer: ({ data }) => {
      return !!data?.periodDate
        ? dayjsNY(data.periodDate).format("MM/DD/YYYY")
        : "--";
    },
    valueGetter: ({ data }) => {
      return !!data?.periodDate ? dayjsNY(data.periodDate).valueOf() : null;
    },
    filterParams: {
      valueGetter: ({ data }) => {
        return !!data?.periodDate ? dayjsNY(data.periodDate).valueOf() : null;
      },
    },
  },
  {
    field: "nemoRelated",
    headerName: "Nemo Related",
    cellClass: "cell-left-aligned",
  },
  {
    field: "yardRelated",
    headerName: "Yard Related",
    cellClass: "cell-left-aligned",
  },
];
