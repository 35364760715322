import { camelCase } from "lodash";
import { attachDataSources, attachParameters } from ".";
import { dataSourceReportsFetch } from "../../../../utils";
import { driveApi } from "../../../../integrations/DriveRequest";
import {
  incidentReportFormatter,
  inspectionReportFormatter,
  safetyReportFormatter,
  vendorsReportFormatter,
} from "./formatters";

export const formatDataSourceData = (accessToken) => {
  const driveRequest = driveApi({ accessToken });

  return {
    inspections: async (data = []) =>
      await Promise.all(
        data.map(async (inspection) => {
          const { inspectionReport = {} } = inspection;

          return {
            ...inspection,
            inspectionReport: {
              estimations: inspectionReport?.estimations,
              questions: Object.entries(
                inspectionReport?.questions ?? {}
              ).flatMap(([key, categories]) =>
                categories?.flatMap((subCategory) =>
                  subCategory?.questions?.map(async (question) => {
                    if (question?.fileIds && question?.fileIds?.length > 0) {
                      return {
                        ...question,
                        images: await Promise.all(
                          question.fileIds.map(async (id) => {
                            const response = await driveRequest.getImageSrc(id);
                            return response.src;
                          })
                        ),
                      };
                    }
                    return question;
                  })
                )
              ),
            },
          };
        })
      ),
    tasksManagement: (data = []) =>
      data?.flatMap(({ taskAssignedTo = [], ...record }) => {
        return taskAssignedTo?.map((user) => ({
          ...record,
          taskAssignedTo: user,
        }));
      }),
    ["safety other trade incident"]: async (data = []) =>
      await safetyReportFormatter(data, driveRequest),
    ["safety vehicle damage"]: async (data = []) =>
      await safetyReportFormatter(data, driveRequest),
    ["safety property damage"]: async (data = []) =>
      await safetyReportFormatter(data, driveRequest),
    ["safety personal injury"]: async (data = []) =>
      await safetyReportFormatter(data, driveRequest),
    ["safety inspections"]: async (data = []) =>
      await incidentReportFormatter(data, driveRequest),
    ["incidents personal injury"]: async (data = []) =>
      await incidentReportFormatter(data, driveRequest),
    ["incidents property damage"]: async (data = []) =>
      await incidentReportFormatter(data, driveRequest),
    ["incidents vehicle damage"]: async (data = []) =>
      await incidentReportFormatter(data, driveRequest),
    ["incidents other trade incident"]: async (data = []) =>
      await incidentReportFormatter(data, driveRequest),
    vendors: async (data = []) => await vendorsReportFormatter(data),
  };
};

export const populateReport = async ({
  report,
  categoryParameters,
  accessToken,
  activeFilters = {},
  recordId,
  partitionKeys, // optional for custom data
  customData, // optional
  setCustomData = () => {}, // optional
  sampleObjects = [],
}) => {
  const { datasources = [], reportParameters, reportObj } = report;

  // console.log({ report, recordId });

  const formatter = formatDataSourceData(accessToken);
  let completedDataSources = 0;
  const totalDataSources = datasources.length;
  let populatedDataSources = [];
  try {
    populatedDataSources = await Promise.all(
      datasources.map(async (name = "") => {
        const recordKey = partitionKeys?.identityId;

        // filter object to filter the datasource { [keyToFilter]: valueToMatch}
        const currentDataSourceFilter = activeFilters?.[camelCase(name)] || {};

        let currSampleObj =
          sampleObjects.find((item) => item.objectName === name) || {};

        // Some sample objects has saved only keys used in report, so we search if it has a main category to get its keys
        if (currSampleObj?.categoryName) {
          currSampleObj =
            sampleObjects.find(
              (item) => item.objectName === currSampleObj?.categoryName
            ) || {};
        }

        let finalData;

        //if custom data, do not fetch
        if (!!customData) {
          if (Array.isArray(customData)) {
            finalData = customData;
          } else {
            finalData = customData?.[name];
          }
        }

        if (!finalData) {
          finalData = await dataSourceReportsFetch(
            {
              dataSourceName: camelCase(name),
              reportObj,
              currSampleObjKeys: Object.keys(
                currSampleObj?.objectSample?.[0] || {}
              ),
            },
            recordId
          );
        }

        completedDataSources++;

        if (window) {
          window.progressInterval &&
            window.progressInterval(completedDataSources, totalDataSources);
        }

        //formatter function (optional)
        const { [name.toLowerCase()]: dataFormatter } = formatter;

        //filters the datasource if we have a filter object or a partition key
        const filteredData = Array.isArray(finalData)
          ? finalData?.filter((record) => {
              //if we don't have a value(active filters) returns "true", otherwise compares the values
              if (
                Object.entries(currentDataSourceFilter).every(
                  ([key, value]) => !value || record[key] === value
                )
              ) {
                // if we have a recordId and a partition key
                if (Array.isArray(recordId)) {
                  // if we want to get multiple records, filter rowdata with given array of ids
                  return recordId.includes(record[recordKey]);
                }

                if (recordKey in record) {
                  return (
                    !recordId || !recordKey || record[recordKey] === recordId
                  );
                }

                return true;
              }
            })
          : [];

        const dataToUse = (await dataFormatter?.(filteredData)) || filteredData;

        setCustomData((prev) => ({ ...prev, [name]: dataToUse }));

        return {
          name,
          data: dataToUse,
        };
      })
    );
  } catch (e) {
    console.error("populateReport ~ e", { e });
  }
  return attachParameters(
    attachDataSources(reportObj, populatedDataSources),
    reportParameters,
    categoryParameters
  );
};
