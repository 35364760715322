import { Form } from "antd";
import { useRef, useState } from "react";
import {
  AbsoluteStepper,
  Address,
  Information,
  LongText,
  Members,
  MultiBox,
  Small,
  Transfer,
  ProgressBar,
  DateRange,
  CustomStatus,
  Upload,
  DobCard,
  TeamsCard,
  FleetItems,
  LaborCard,
  DynamicUsers,
  ViolationPieChartEdit,
  AssignProjectGridEdit,
  DriverAssign,
  InactivityCard,
  ManufacturerCard,
  LicensePlate,
} from "../../editComponents";
import "./EditCard.scss";
import { XIcon } from "../../../Communication/assets";
import { useSelector } from "react-redux";

/**
 * @callback editedValueCallback
 * used to get edited value from the cards on CardView component
 * @param {Object} value edited value
 *  @argument1 {object} response - new values in [key]: value pair
 *  @argument2 {object} [newValues] - new values in [key]: value pair | {}
 *  @argument3 {object} [newValues] - new values in [key]: value pair | {}
 *  @argument4 {object} prevObject - card object with old params
 *  @argument5 {object} currObject - card object with new params 
 * /

/**
 * @param {string} title title of card
 * @param {f} setEditMode state for showing edit modal
 * @param {JSX} BodyComponent component to render inside card
 * @param {Object} BodyComponentParams params to pass inside of body component
 * @param {editedValueCallback} getEditedValue callback to get edited data
 * @returns
 */
const EditCard = ({
  title,
  setEditMode,
  BodyComponent,
  BodyComponentParams,
  getEditedValue,
  currentStep,
  id,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [form] = Form.useForm();
  let ref = useRef(null);
  const [formChanged, setFormChanged] = useState(false);

  const handleClickOutside = (event) => {
    if (event.target.className === "editMask") {
      setEditMode(false);
    }
  };

  const getFormChanged = () => {
    return formChanged;
  };

  document.addEventListener("mousedown", handleClickOutside);

  return (
    <div className="editMask">
      <div
        className={`EditCardComponent ${isDarkMode && "EditCardComponentDark"}`}
        draggable={false}
      >
        <Form
          form={form}
          onValuesChange={() => {
            setFormChanged(true);
          }}
        >
          <div draggable={false} ref={ref} className="EditCardComponentBody">
            <div className="editHeaderCont">
              <span className="editHeaderTitle">{title}</span>
              <span className="closeIcon">
                <XIcon onClick={() => setEditMode(false)} />
              </span>
            </div>
            <div className="editCardBodyContent">
              <BodyComponent
                {...{
                  title,
                  id,
                  form,
                  params: BodyComponentParams,
                  getEditedValue,
                  setEditMode,
                  currentStep,
                  getFormChanged,
                  setFormChanged,
                  isDarkMode,
                }}
              />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

EditCard.AbsoluteStepper = ({ setEditMode, params }) => (
  <AbsoluteStepper {...{ params, setEditMode }} />
);

EditCard.MultiBox = ({
  title,
  params,
  dataView,
  setDataView,
  setEditMode,
  getEditedValue,
  id,
  isDarkMode,
}) => (
  <EditCard
    {...{
      title,
      BodyComponent: MultiBox,
      BodyComponentParams: params,
      dataView,
      setDataView,
      getEditedValue,
      setEditMode,
      id,
      isDarkMode,
    }}
  />
);

EditCard.Members = ({
  title,
  params,
  dataView,
  setDataView,
  setEditMode,
  getEditedValue,
  id,
  isDarkMode,
}) => (
  <EditCard
    {...{
      title,
      BodyComponent: Members,
      BodyComponentParams: params,
      dataView,
      setDataView,
      setEditMode,
      getEditedValue,
      id,
      isDarkMode,
    }}
  />
);
EditCard.Upload = ({
  title,
  params,
  dataView,
  setDataView,
  setEditMode,
  getEditedValue,
  id,
  editable,
  isDarkMode,
}) => (
  <EditCard
    {...{
      title,
      BodyComponent: Upload,
      BodyComponentParams: params,
      dataView,
      editable,
      setDataView,
      setEditMode,
      getEditedValue,
      id,
      isDarkMode,
    }}
  />
);

EditCard.LongText = ({
  title,
  params,
  dataView,
  setDataView,
  setEditMode,
  getEditedValue,
  id,
  isDarkMode,
}) => (
  <EditCard
    {...{
      title,
      BodyComponent: LongText,
      BodyComponentParams: params,
      dataView,
      setDataView,
      setEditMode,
      getEditedValue,
      id,
      isDarkMode,
      isDarkMode,
    }}
  />
);
EditCard.DobCard = ({
  title,
  params,
  dataView,
  setDataView,
  setEditMode,
  getEditedValue,
  id,
  isDarkMode,
}) => (
  <EditCard
    {...{
      title,
      BodyComponent: DobCard,
      BodyComponentParams: params,
      dataView,
      setDataView,
      setEditMode,
      getEditedValue,
      id,
      isDarkMode,
    }}
  />
);

EditCard.Information = ({
  title,
  params,
  dataView,
  setDataView,
  setEditMode,
  getEditedValue,
  id,
  isDarkMode,
}) => (
  <EditCard
    {...{
      title,
      BodyComponent: Information,
      BodyComponentParams: params,
      dataView,
      setDataView,
      setEditMode,
      getEditedValue,
      id,
      isDarkMode,
    }}
  />
);
EditCard.DateRange = ({
  title,
  params,
  dataView,
  setDataView,
  setEditMode,
  getEditedValue,
  id,
  isDarkMode,
}) => (
  <EditCard
    {...{
      title,
      BodyComponent: DateRange,
      BodyComponentParams: params,
      dataView,
      setDataView,
      setEditMode,
      getEditedValue,
      id,
      isDarkMode,
    }}
  />
);
EditCard.Address = ({
  title,
  params,
  dataView,
  setDataView,
  setEditMode,
  getEditedValue,
  id,
  isDarkMode,
}) => (
  <EditCard
    {...{
      title,
      BodyComponent: Address,
      BodyComponentParams: params,
      dataView,
      setDataView,
      setEditMode,
      getEditedValue,
      id,
      isDarkMode,
    }}
  />
);
EditCard.CustomStatus = ({
  title,
  params,
  dataView,
  setDataView,
  setEditMode,
  getEditedValue,
  currentStep,
  id,
  isDarkMode,
}) => (
  <EditCard
    {...{
      title,
      BodyComponent: CustomStatus,
      BodyComponentParams: params,
      dataView,
      setDataView,
      setEditMode,
      getEditedValue,
      currentStep,
      id,
      isDarkMode,
    }}
  />
);
EditCard.Small = ({
  title,
  params,
  dataView,
  setDataView,
  setEditMode,
  getEditedValue,
  currentStep,
  id,
  isDarkMode,
}) => (
  <EditCard
    {...{
      title,
      BodyComponent: Small,
      BodyComponentParams: params,
      dataView,
      setDataView,
      setEditMode,
      getEditedValue,
      currentStep,
      id,
      isDarkMode,
    }}
  />
);
EditCard.Transfer = ({
  title,
  params,
  dataView,
  setDataView,
  setEditMode,
  getEditedValue,
  id,
  isDarkMode,
}) => (
  <EditCard
    {...{
      title,
      BodyComponent: Transfer,
      BodyComponentParams: params,
      dataView,
      setDataView,
      setEditMode,
      getEditedValue,
      id,
      isDarkMode,
    }}
  />
);
EditCard.TeamsCard = ({
  title,
  params,
  dataView,
  setDataView,
  setEditMode,
  getEditedValue,
  id,
  isDarkMode,
}) => (
  <EditCard
    {...{
      title,
      BodyComponent: TeamsCard,
      BodyComponentParams: params,
      dataView,
      setDataView,
      setEditMode,
      getEditedValue,
      id,
      isDarkMode,
    }}
  />
);
EditCard.DynamicUsers = ({
  title,
  params,
  dataView,
  setDataView,
  setEditMode,
  getEditedValue,
  id,
  isDarkMode,
}) => (
  <EditCard
    {...{
      title,
      BodyComponent: DynamicUsers,
      BodyComponentParams: params,
      dataView,
      setDataView,
      setEditMode,
      getEditedValue,
      id,
      isDarkMode,
    }}
  />
);
EditCard.ProgressBar = ({
  title,
  params,
  dataView,
  setDataView,
  setEditMode,
  getEditedValue,
  id,
  isDarkMode,
}) => (
  <EditCard
    {...{
      title,
      BodyComponent: ProgressBar,
      BodyComponentParams: params,
      dataView,
      setDataView,
      setEditMode,
      getEditedValue,
      id,
      isDarkMode,
    }}
  />
);
EditCard.FleetItems = ({
  title,
  params,
  dataView,
  setDataView,
  setEditMode,
  getEditedValue,
  id,
  isDarkMode,
}) => (
  <EditCard
    {...{
      title,
      BodyComponent: FleetItems,
      BodyComponentParams: params,
      dataView,
      setDataView,
      setEditMode,
      getEditedValue,
      id,
      isDarkMode,
    }}
  />
);
EditCard.LaborCard = ({
  title,
  params,
  dataView,
  setDataView,
  setEditMode,
  getEditedValue,
  id,
  isDarkMode,
}) => (
  <EditCard
    {...{
      title,
      BodyComponent: LaborCard,
      BodyComponentParams: params,
      dataView,
      setDataView,
      setEditMode,
      getEditedValue,
      id,
      isDarkMode,
    }}
  />
);
EditCard.ViolationPieChart = ({
  title,
  params,
  dataView,
  setDataView,
  setEditMode,
  getEditedValue,
  id,
  isDarkMode,
}) => (
  <EditCard
    {...{
      title,
      BodyComponent: ViolationPieChartEdit,
      BodyComponentParams: params,
      dataView,
      setDataView,
      setEditMode,
      getEditedValue,
      id,
      isDarkMode,
    }}
  />
);
EditCard.AssignProjectGrid = ({
  title,
  params,
  dataView,
  setDataView,
  setEditMode,
  getEditedValue,
  id,
  isDarkMode,
}) => (
  <EditCard
    {...{
      title,
      BodyComponent: AssignProjectGridEdit,
      BodyComponentParams: params,
      dataView,
      setDataView,
      setEditMode,
      getEditedValue,
      id,
      isDarkMode,
    }}
  />
);
EditCard.DriverAssign = ({
  title,
  params,
  dataView,
  setDataView,
  setEditMode,
  getEditedValue,
  id,
  isDarkMode,
}) => (
  <EditCard
    {...{
      title,
      BodyComponent: DriverAssign,
      BodyComponentParams: params,
      dataView,
      setDataView,
      setEditMode,
      getEditedValue,
      id,
      isDarkMode,
    }}
  />
);
EditCard.InactivityCard = ({
  title,
  params,
  dataView,
  setDataView,
  setEditMode,
  getEditedValue,
  id,
  isDarkMode,
}) => (
  <EditCard
    {...{
      title,
      BodyComponent: InactivityCard,
      BodyComponentParams: params,
      dataView,
      setDataView,
      setEditMode,
      getEditedValue,
      id,
      isDarkMode,
    }}
  />
);
EditCard.ManufacturerCard = ({
  title,
  params,
  dataView,
  setDataView,
  setEditMode,
  getEditedValue,
  id,
  isDarkMode,
}) => (
  <EditCard
    {...{
      title,
      BodyComponent: ManufacturerCard,
      BodyComponentParams: params,
      dataView,
      setDataView,
      setEditMode,
      getEditedValue,
      id,
      isDarkMode,
    }}
  />
);

EditCard.LicensePlate = ({
  title,
  params,
  dataView,
  setDataView,
  setEditMode,
  getEditedValue,
  id,
  isDarkMode,
}) => (
  <EditCard
    {...{
      title,
      BodyComponent: LicensePlate,
      BodyComponentParams: params,
      dataView,
      setDataView,
      setEditMode,
      getEditedValue,
      id,
      isDarkMode,
    }}
  />
);
export default EditCard;
