import { useState } from "react";
import {
  CloseOutlined,
  FilterOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Tooltip, message } from "antd";
import {
  MondayButton,
  MultiLevelTreeLogs,
} from "../../../../../../commonComponents";
import { LogsIcon } from "../../../../../../SidebarPages/DynamicView/src/index";
import { RefreshIcon } from "../../../../../../SidebarPages/Communication/assets";
import { useDispatch } from "react-redux";
import { fetchAllData } from "../../../../../../../utils";
import {
  apiRoutes,
  fetchData,
} from "../../../../../../SidebarPages/Fleet/utils";
import { showLoadingMsg, showSuccessMsg } from "../../../../../../../utils";
import { UserConfig } from "../../../../../../../actions";
import ExportOrEmailDropdown from "../../../../../../commonComponents/ExportOrEmailDropdown/ExportOrEmailDropdown";
import ButtonDropdown from "src/components/commonComponents/MondayButtonDropDown/ButtonDropdown";
import { getChartingOptions } from "../../utils/getChartingOptions.js";
import { VideoTutorialIcon } from "../../../../../../../icons/index.js";
import VideoModal from "../../../../../../VideoModal/VideoModal.jsx";
import { InputComponent } from "../../../../../../SidebarPages/Fleet/components/index.js";
import FiltersModal from "../../../../../../commonComponents/RowDataGridModal/components/FiltersModal.jsx";
import { camelCaseToNormalText } from "../../../../../../commonComponents/RowDataGridModal/components/utils.js";
import { keysForDates } from "../../../../../../../helpers/constants/keysForDates.js";
import { useSelector } from "react-redux";
import ChartButton from "../../../../../../ChartButton/ChartButton.jsx";
import { PlusIcon } from "../../../../../../../assets/index.js";
import NewUserModal from "../NewUserModal/NewUserModal.jsx";
import ResponsiveHeaderButtons from "../../../../../../commonComponents/ResponsiveHeaderButtons/ResponsiveHeaderButtons.jsx";

const ControlPanel = ({
  title,
  hasFilters,
  setAppliedFilters,
  columnApi,
  rowData,
  setModalOpen,
  gridApi,
  exportGridToExcel,
  showFilterModal,
  setShowFilterModal,
  appliedFilters,
  allUsersColDefs,
}) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [newUserVisible, setNewUserVisible] = useState(false);

  const dispatch = useDispatch();

  const onRefresh = async () => {
    showLoadingMsg({ content: "Refreshing..." });
    const userConfiguration = await fetchData(apiRoutes.userConfiguration);
    dispatch(UserConfig(userConfiguration));
    showSuccessMsg({ content: "Refreshed!" });
  };

  const clearFilters = () => {
    gridApi?.setFilterModel(null);
    setAppliedFilters();
  };

  const headerButtons = (setOpen) => (
    <>
      <div className="filter-buttons">
        {!!hasFilters && (
          <MondayButton
            className="mondayButtonYellow"
            Icon={<CloseOutlined />}
            onClick={() => {
              setOpen && setOpen(false);
              clearFilters();
            }}
          >
            Clear Filters
          </MondayButton>
        )}
        <MondayButton
          className="mondayButtonBlue"
          onClick={() => {
            setOpen && setOpen(false);
            setShowFilterModal(true);
          }}
          Icon={<FilterOutlined />}
        >
          Open Filters
        </MondayButton>
      </div>
      <ButtonDropdown
        {...{
          mondayClassName: "newButton",
          title: "Full Screen",
          dropDownOptions: [
            {
              key: "1",
              label: (
                <>
                  <p>Whole Page</p>
                </>
              ),
              onClick: () =>
                setModalOpen((prev) => ({
                  ...prev,
                  fullPage: !prev.fullPage,
                })),
            },
            {
              key: "2",
              label: (
                <>
                  <p>Table</p>
                </>
              ),
              onClick: () =>
                setModalOpen((prev) => ({ ...prev, agGrid: !prev.agGrid })),
            },
          ],
        }}
      />
      <ExportOrEmailDropdown
        {...{
          rowData,
          title,
          gridApi,
          gridColumnApi: columnApi,
          exportGridToExcel,
        }}
      />
      <div onClick={() => setOpen && setOpen(false)}>
        <ChartButton
          {...{
            getChartingOptions,
            data: rowData,
          }}
        />
      </div>
      <MondayButton
        className="mondayButtonBlue"
        onClick={() => {
          setVideoModalVisible(true);
          setOpen && setOpen(false);
        }}
        Icon={<VideoTutorialIcon />}
      />
      <MondayButton
        className="mondayButtonBlue"
        onClick={async () => {
          await onRefresh();
          setOpen && setOpen(false);
        }}
        Icon={<RefreshIcon />}
      />
      <MondayButton
        {...{
          onClick: () => {
            setOpen && setOpen(false);
            setNewUserVisible(true);
          },
          Icon: <PlusIcon />,
        }}
      >
        Create User
      </MondayButton>
    </>
  );

  return (
    <>
      <div className="allUsersHeader">
        <InputComponent
          {...{
            placeholder: "Search a record here...",
            onChange: (event) => {
              gridApi.setQuickFilter(event.target.value);
            },
            addonBefore: <SearchOutlined />,
          }}
        />
        <div className="rightSection">
          <ResponsiveHeaderButtons headerButtons={headerButtons} />
        </div>
      </div>

      {newUserVisible && (
        <NewUserModal
          {...{ visible: newUserVisible, setVisible: setNewUserVisible }}
        />
      )}
      {videoModalVisible && (
        <VideoModal
          {...{
            modalOpen: videoModalVisible,
            setModalOpen: setVideoModalVisible,
            title: "All Users/Settings",
          }}
        />
      )}
      {showFilterModal && (
        <FiltersModal
          {...{
            title,
            showFilterModal,
            setShowFilterModal,
            appliedFilters,
            setAppliedFilters: (filters) => {
              filters === "clearFilters"
                ? clearFilters()
                : setAppliedFilters(filters);
            },
            gridApi,
            filtersObject: allUsersColDefs
              .sort((a, b) =>
                keysForDates.includes(a.field)
                  ? -1
                  : keysForDates.includes(b.field)
                  ? 1
                  : 0
              )
              .map(({ field }, index) => ({
                globalKeyId: index,
                createdAt: Date.now(),
                createdBy: userConfiguration.nameOfUser,
                dataType: keysForDates.includes(field) ? "date" : "string",
                style: "",
                label: camelCaseToNormalText(field),
                type: keysForDates.includes(field) ? "RangePicker" : "Dropdown",
                key: field,
              })),
            additionalItemsToExclude: ["lastlogintime", "lastlogindevice"],
            rowData,
          }}
        />
      )}
    </>
  );
};

export default ControlPanel;
