import { Modal } from "antd";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useAdvancedFilterContext } from "./context";
import { XIcon } from "../../SidebarPages/Communication/assets";
import { AddFilterButton, AdvancedFilter, Footer } from "./components";

import "./AdvancedFilter.scss";
import { useResponsive } from "../../../hooks";

const AdvancedFilters = ({
  title,
  isOpen,
  rowData,
  gridApi,
  setRowData,
  filtersObject,
  setIsFiltersOpen,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { filters, addFilter } = useAdvancedFilterContext();
  const { mobile, tablet } = useResponsive();

  const onCancel = () => {
    setIsFiltersOpen(false);
  };

  return (
    <Modal
      wrapClassName={`advanced-filters-modal ${
        isDarkMode && "advanced-filters-modal-dark"
      } ${(mobile || tablet) && "advanced-filters-modal-mobile"}`}
      open={isOpen}
      title={`Filter`}
      onCancel={onCancel}
      closeIcon={<XIcon />}
      maskClosable={false}
      footer={
        <Footer
          {...{ rowData, setRowData, title, setIsFiltersOpen, gridApi }}
        />
      }
      width={1152}
    >
      <div className="body-modal">
        <div className="fields-container">
          <div className="filter-text">Filter {title}</div>
          <div className="description-container">
            Use filters bellow to search {title} data!
          </div>
        </div>
        {filters.map((filter, filterIndex) => (
          <Fragment key={filter.id}>
            {filterIndex > 0 && (
              <AddFilterButton
                {...{
                  text: filter.operator,
                  disabled: true,
                }}
              />
            )}
            <div key={filter.id} className="filter-fields-container">
              {filter.conditions.map((condition, conditionIndex) => (
                <Fragment key={condition.id}>
                  <AdvancedFilter
                    {...{
                      condition,
                      filterIndex,
                      conditionIndex,
                      filtersObject,
                      rowData,
                      title,
                    }}
                  />

                  {condition.column && (
                    <AddFilterButton
                      {...{
                        text:
                          filter.conditions.length > conditionIndex + 1
                            ? condition.operator
                            : `+ ${condition.operator || "AND"}`,
                        addFilter,
                        globalOperator: filter.operator,
                        conditionOperator: condition.operator,
                        filterIndex,
                        conditionId: condition.id,
                      }}
                    />
                  )}
                </Fragment>
              ))}
            </div>
          </Fragment>
        ))}
      </div>
    </Modal>
  );
};

export default AdvancedFilters;
