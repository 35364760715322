import "./communication-rich-editor.scss";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { JoditEditorComp } from "../../../../commonComponents";
import { useEmailBoxBody } from "../EmailBox/providers/EmailBoxBodyProvider";
import { useAttachments } from "../EmailBox/providers/AttachmentsProvider";
import { useInitialEmailBoxProps } from "../EmailBox/providers/InitialEmailBoxProvider";
import useDynamicFieldsData from "../EmailBox/hooks/useDynamicFieldsData";
import { getCategory } from "../EmailBox/components/footer/EmailBoxFooter/dynamic-fields/dynamic-fields-helpers";
import {
  findObjectByLabel,
  flattenArray,
  getSingleFields,
  getTableFields,
} from "./functions";

function CommunicationRichEditor({ toolbarUp, readonly = false, messageType }) {
  const [menuItems, setMenuItems] = useState([]);

  const { programFields } = useSelector((state) => state.programFields);

  const { editorContent, setEditorContent, showEditorToolbar } =
    useEmailBoxBody();
  const { attachments } = useAttachments();

  const { rowData } = useInitialEmailBoxProps();
  const recordDetails = {
    categoryType: window.location.pathname.split("/")[1],
    recordId:
      window.location.pathname.split("/")[1] === "inspections"
        ? window.location.href.split("?id=")[1].split("&&")[0]
        : window.location.pathname.split("/")[2],
  };

  const category = getCategory(
    "permitdrawings",
    "Permit Drawings",
    recordDetails
  );

  // The  hook which fetches dynammic fields data
  const { dynamicFields, allDynamicFields } = useDynamicFieldsData(
    rowData,
    recordDetails
  );

  useEffect(() => {
    if (messageType === "forward") {
      setEditorContent("");
    }
  }, [messageType]);

  useEffect(() => {
    setMenuItems([
      {
        key: "1",
        label: "--Single Fields--",
        children: getSingleFields(dynamicFields, category, rowData),
      },
      {
        key: "2",
        label: "--Table Fields--",
        children: getTableFields(programFields, category, allDynamicFields),
      },
    ]);
  }, [dynamicFields.length, allDynamicFields.length]);

  return (
    <div
      className={`communication-rich-editor-container ${
        attachments.length > 0 ? "has-attachments" : ""
      } ${toolbarUp ? "toolbar-up" : ""}`}
    >
      <JoditEditorComp
        content={editorContent}
        setContent={setEditorContent}
        placeholder="Start typing..."
        customConfig={{
          buttons: [
            "undo",
            "redo",
            "|",
            "bold",
            "italic",
            "brush",
            "fontsize",
            "font",
            "|",
            "align",
            "|",
            "eraser",
            "|",
            "ol",
            "ul",
            "table",
            "|",
            "underline",
            "|",
            "print",
          ],
          disablePlugins: "add-new-line",
          extraButtons: [
            {
              name: "Dynamic Fields",
              list: flattenArray(menuItems),
              exec: (editor, event, { control }) => {
                const selectedValue = control.args && control.args[0];

                if (selectedValue?.startsWith("--")) return false;

                const selectedValueKey = findObjectByLabel(
                  menuItems,
                  selectedValue
                )?.dynamicFieldValue;
                const rowDataValue = rowData[selectedValueKey] || "N/A";

                if (selectedValue) {
                  editor.s.insertHTML(`${selectedValue}: ${rowDataValue}`);
                  const updatedContent = editor.value;
                  setEditorContent(updatedContent);
                }

                return false;
              },
            },
          ],
        }}
        whiteTheme={true}
        showToolbar={showEditorToolbar}
        readonly={readonly}
      />
    </div>
  );
}

export default CommunicationRichEditor;
