export const editorButtons = [
  "undo",
  "redo",
  "|",
  "bold",
  "italic",
  "underline",
  "|",
  "ul",
  "ol",
  "|",
  "fullsize",
  "source",
  "|",
  "image",
  "link",
  "table",
  "|",
  "eraser",
  "print",
  "|",
];
