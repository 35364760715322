import { getDispatchLocationOptions } from "../../../../../Header/forms/RequestFormModal/requestFormModalData";
import searchIncidentAddress from "../searchIncidentAddress";

function addConditionalFields({
  fields,
  fieldCategoryIndex,
  selectedService,
  immediateActions,
  formItemName,
  typeOfEquipment,
  field,
}) {
  if (
    formItemName === "typeOfEquipmentInvolved" &&
    typeOfEquipment === "Other"
  ) {
    fields?.[fieldCategoryIndex]?.["fields"]?.push({
      type: "input",
      formItemName: "otherEquipment",
      label: "Other Equipment",
      required: true,
      placeholder: "Other Equipment",
      category: field?.category,
    });
  }

  if (formItemName === "serviceBeingDone" && selectedService === "Other") {
    fields?.[fieldCategoryIndex]?.["fields"]?.push({
      type: "input",
      label: "Other Service",
      formItemName: "otherService",
      required: true,
      placeholder: "Other Service",
      category: field?.category,
    });
  }

  if (
    formItemName === "immediateActionsTakenFollowingTheIncident" &&
    (immediateActions === "Escorted by" || immediateActions === "Other")
  ) {
    fields?.[fieldCategoryIndex]?.["fields"]?.push({
      type: "input",
      formItemName: "otherAction",
      label:
        immediateActions === "Escorted by" ? "Escorted By" : "Other Action",
      required: true,
      placeholder:
        immediateActions === "Escorted by" ? "Escorted By" : "Other Action",
      category: field?.category,
    });
  }
}

const personalInjuryIncidentFields = ({
  form,
  crews = [],
  projects = [],
  defaultId = "",
  scheduling = [],
  propAddress = "",
  dynamicFields = [],
  typeOfEquipment = "",
  selectedService = "",
  schedulesForDate = [],
  projectExecutives = [],
  immediateActions = "",
  userConfiguration = {},
  mainDisabledField = false,
  disabledAllFields = false,
  onChangeAddress = () => {},
  changeDateOfIncident = () => {},
  dispatchIncidentFormData = () => {},
}) => {
  let fields = [];

  const projectExecutivesOpt =
    projectExecutives?.map((executive) => ({
      id: executive.peId,
      label: `${executive.firstName} ${executive.lastName}`,
      value: `${executive.firstName} ${executive.lastName}`,
    })) || [];

  const foremen = crews
    ?.filter((e) => !!e.foreman)
    ?.map(({ crewId, crewName, members }) => ({
      label: crewName,
      value: crewName,
      members,
      key: crewId,
    }));

  const crewMembers = crews
    ?.filter((e) => e.foreman !== true)
    ?.map(({ crewId, crewName }) => ({
      label: crewName,
      value: crewId,
    }));

  for (let i = 0; i < dynamicFields?.length; i++) {
    let field = structuredClone(dynamicFields[i]);

    delete field["placeholder"];

    const { formItemName, type, label } = field;

    if (type === "select") {
      field = {
        ...field,
        showSearch: true,
        allowClear: true,
        ...(field.label.includes("Project Executive")
          ? { customOptions: projectExecutivesOpt }
          : {}),
      };
    }

    if (type === "placesautocomplete") {
      field = {
        ...field,
        allowClear: true,
        style: { width: 320 },
      };
    }

    if (formItemName === "fullNameOfInjuredIndividual") {
      field = {
        ...field,
        disabled: mainDisabledField,
      };
    }
    if (
      formItemName === "dateOfInjury" ||
      formItemName === "reportPreparedOn"
    ) {
      field = {
        ...field,
        ...(formItemName === "dateOfInjury"
          ? { customOnChange: changeDateOfIncident }
          : {}),
        validTime: "past",
        form,
      };
    }

    if (formItemName === "timeOfInjury") {
      field = {
        ...field,
        validTime: "past",
        dateField: "dateOfInjury",
        form,
      };
    }

    if (formItemName === "reportPreparedBy") {
      delete field?.options;
      field = {
        ...field,
        customOptions: userConfiguration?.allUsers?.Items?.map((e) => ({
          label: e.nameOfUser,
          value: e.identityId,
        })),
        initialValue: !defaultId && userConfiguration?.identityId,
      };
    }

    if (formItemName === "fullNameOfForeman") {
      delete field?.options;
      field = {
        ...field,
        customOptions: foremen,
        type: "predictautocomplete",
        addNewShow: false,
      };
    }

    if (formItemName === "addressOfInjury") {
      field = {
        ...field,
        initialValue: propAddress,
        onSelect: onChangeAddress,
        groupedOptions: getDispatchLocationOptions(schedulesForDate, projects),
        showSearch: true,
        customOnSearch: async (searchValue) =>
          await searchIncidentAddress({
            searchValue,
            dispatchIncidentFormData,
            userConfiguration,
            projects,
            scheduling,
            schedulesForDate,
            dateField: "dateOfInjury",
            form,
          }),
      };
    }

    let fieldCategoryIndex = fields.findIndex(
      (e) => e?.title === field?.category
    );

    if (fieldCategoryIndex > -1) {
      fields[fieldCategoryIndex]["fields"].push(field);

      addConditionalFields({
        fields,
        fieldCategoryIndex,
        immediateActions,
        formItemName,
        selectedService,
        typeOfEquipment,
        field,
      });
    } else {
      fields.push({
        title: field?.category,
        fields: [field],
      });
      addConditionalFields({
        fields,
        fieldCategoryIndex: fields?.length - 1 || 0,
        immediateActions,
        formItemName,
        selectedService,
        typeOfEquipment,
        field,
      });
    }

    if (disabledAllFields) {
      field.disabled = disabledAllFields;
    }
  }

  let additionalInfoOptions = fields.findIndex(
    (e) => e.title === "Additional Information"
  );

  if (additionalInfoOptions > -1) {
    let tmp = fields.splice(additionalInfoOptions, 1);
    fields.push(tmp[0]);
  }

  return fields;
};

export default personalInjuryIncidentFields;
