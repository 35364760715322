import { message } from "antd";
import { PlusIcon } from "../../../../../../../../assets";
import { MondayButton } from "../../../../../../../commonComponents";
import { GDriveIcon } from "../../../../assets";
import "./CardHeader.scss";

const TITLE = "Drive Files";

const CardHeader = ({
  setTemplateModalVisible,
  categoryConfiguration,
  isEditable,
}) => {
  return (
    <div className="docFileTreeHeader">
      {/* <span className="gDriveIcon">
        <GDriveIcon />
      </span> */}
      <span className="docFileTreeHeaderTitle">{TITLE}</span>
      {isEditable ? (
        <MondayButton
          Icon={<PlusIcon />}
          className="mondayButtonGreen"
          tooltipCategory="Documentations"
          tooltipKey="onNew"
          {...{
            onClick: () => {
              if (!categoryConfiguration?.templatesAvailable?.length) {
                message.warning("No templates available");
                return;
              }
              setTemplateModalVisible(true);
            },
          }}
        >
          <span> Templates </span>
        </MondayButton>
      ) : null}
    </div>
  );
};

export default CardHeader;
