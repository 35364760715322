import { ProgressStatistics } from "../../components/SidebarPages/Scheduling/helpers/totals";
import { fetchAllData } from "../ApiMethods";

export const getSchedulingTotalProgressWithCharges = async ({
  keysToInclude,
}) => {
  const [scheduling, chargesRes, toBeScheduledArr] = await Promise.all([
    fetchAllData({
      endpoint: "scheduling",
      resultId: "scheduleId",
      otherStringParams: {
        keysToInclude: JSON.stringify([
          ...new Set([...keysToInclude, ...["scheduleId", "projectId"]]),
        ]),
      },
    }),
    fetchAllData({
      endpoint: "charges",
      resultId: "chargeId",
      otherStringParams: {
        keysToInclude: JSON.stringify([
          "chargeId",
          "projectId",
          "categoryFrom",
          "recordId",
          "chargeItems",
        ]),
      },
    }),
    fetchAllData({
      endpoint: "toBeScheduled",
      resultPosition: "toBeScheduled",
      resultId: "toBeScheduledId",
      otherStringParams: {
        keysToInclude: JSON.stringify([
          "toBeScheduledId",
          "scheduleId",
          "estimationId",
          "estimationNumber",
          "label",
          "serviceOptions",
          "serviceId",
        ]),
      },
    }),
  ]);

  const result = scheduling.map((schedule) => {
    const toBeScheduled = toBeScheduledArr.filter(
      ({ scheduleId }) => scheduleId === schedule.scheduleId
    );

    return {
      ...schedule,
      services: toBeScheduled.map((service) => {
        const { progressPercentage = 0 } =
          ProgressStatistics.serviceTotal(service);

        const charges = chargesRes.filter(
          (charge) =>
            charge.projectId === schedule.projectId &&
            charge.categoryFrom === "Estimation" &&
            charge.recordId === service.estimationId
        );

        return {
          ...schedule,
          estimationNumber: service.estimationNumber,
          serviceName: service.label,
          totalProgress: progressPercentage,
          totalChargeAmount: charges.reduce(
            (acc, charge) =>
              (acc += charge.chargeItems
                .filter((chargeItem) => chargeItem.label === service.label)
                .reduce(
                  (sum, item) => (sum += item.totalities?.totalPrice || 0),
                  0
                )),
            0
          ),
        };
      }),
    };
  });

  return result;
};
