import { ReportIcon } from "src/icons";
import { MondayButton } from "src/components/commonComponents";
import { Close } from "src/components/SidebarPages/DynamicView/src";

import "./Footer.scss";

const Footer = ({
  openReport,
  onCancel,
  isReport = true,
  actions,
  downloadExcel,
}) => {
  return (
    <div className="report-footer-container">
      <MondayButton
        className="mondayButtonRed"
        Icon={<Close />}
        onClick={onCancel}
        data-testid="report-modal-cancel-btn"
      >
        Cancel
      </MondayButton>
      <MondayButton
        Icon={<ReportIcon />}
        onClick={openReport}
        className={!isReport && "mondayButtonBlue"}
        data-testid="report-modal-confirm-btn"
      >
        {isReport
          ? "Open Report"
          : downloadExcel
          ? "Export Excel"
          : actions === "download"
          ? "Download PDF"
          : "Print PDF"}
      </MondayButton>
    </div>
  );
};

export default Footer;
