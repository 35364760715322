import { message, Modal } from "antd";
import { useSelector } from "react-redux";
import React, {
  useMemo,
  useState,
  useRef,
  useCallback,
  useEffect,
} from "react";
import {
  CloseIconModal,
  CloseIconModalHeader,
  // OpenedEyeIcon,
} from "../../../../../../../../assets";
import {
  JoditEditorComp,
  MondayButton,
} from "../../../../../../../commonComponents";
import bracketsToTextProposal from "./bracketsToTextProposal";
import { SaveIcon } from "../../../../../../../SidebarPages/BasePage/src";
// import TemplateDesigner from "../../NewTemplate/TemplateDesigner/TemplateDesigner";
import { useSettingsProposalContext } from "../../ProposalBuilderSettings/ProposalContext";
import ServiceCategoriesSelectionProposal from "../../NewTemplate/ServiceCategoriesSelectionProposal/ServiceCategoriesSelectionProposal";
import {
  createNewTemplate,
  updateProposalTemplate,
} from "../../../utils/requests";
import SaveNameModal from "../../NewTemplate/SaveNameModal";
import { customConfig } from "../../../../ServicesTemplate/utils/customConfig";
import { InfoText } from "../../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
// import ExistingTemplate from "../../NewTemplate/ExistingTemplate/ExistingTemplate";
import { Jodit } from "jodit-react";
import {
  ToolBox,
  ToolboxTooltip,
} from "./ElevationHtmlConfiguration/ElevationHtmlConfiguration";

import "../../NewTemplate/NewProposalTemplate.scss";
import { useEditLogs } from "../../../../../../../../hooks";
import proposalTemplateEditLogs from "./ElevationHtmlConfiguration/helpers/proposalTemplateEditLogs";

function correctServiceFormat(service, serviceDefinitions) {
  let serviceCopy = { ...service };
  if (serviceCopy?.workName || serviceCopy?.workId) {
    serviceCopy = serviceDefinitions.find(
      (item) => item.serviceId === serviceCopy.workId
    );
  }
  return serviceCopy;
}

const defaultStyles = {
  elevationLabel: {},
  elevationDesc: {},
  notes: {},
  itemLabel: {},
  addonLabel: {},
};
const EditGeneralTemplate = ({
  item,
  visible = false,
  closeModal = () => {},
  type = "generalTemplate",
  callback,
}) => {
  const proposalDesignRef = useRef();
  const { curryDispatch, estimationSample, serviceTemplates } =
    useSettingsProposalContext();
  const serviceDefinitions = useSelector((state) => state.serviceDefinitions);

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const [nameVisible, setNameVisible] = useState(false);

  const [tooltipContent, setTooltipContent] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [styles, setStyles] = useState(
    item?.additionalDetails?.styles || defaultStyles
  );

  const { saveAddedLogs } = useEditLogs();

  const handleStyleChange = (category, style) => {
    if (style === "clear") {
      setStyles((prev) => ({
        ...prev,
        [category]: {},
      }));
    } else {
      setStyles((prev) => ({
        ...prev,
        [category]: { ...prev?.[category], ...style },
      }));
    }
  };

  const [details, setDetails] = useState({
    templateCategory: !!item
      ? item?.templateCategory
      : type === "generalTemplate"
      ? "general"
      : "service",
    otherDetails: {
      services: item?.additionalDetails?.services || [],
    },
    templateContent: item?.templateContent || "",
    service:
      correctServiceFormat(
        item?.additionalDetails?.service,
        serviceDefinitions
      ) || null,
  });

  const createLog = async (templateId, templateTitle) => {
    const logBody = {
      actionType: "Create",
      category:
        type === "generalTemplate"
          ? "General Proposal Template"
          : "Service Proposal Template",
      recordId: templateId,
      recordName: templateTitle,
      label:
        type === "generalTemplate" ? "General Template" : "Service Template",
      updatedAt: Date.now(),
      currentData: {
        [userConfiguration?.nameOfUser]: "Created",
      },
      previousData: {
        [userConfiguration?.nameOfUser]: "",
      },
      updatedKeys: [],
    };
    await saveAddedLogs(logBody);
  };

  const editLogs = async (currTemplate, oldTemplate) => {
    const changes = proposalTemplateEditLogs(
      oldTemplate,
      currTemplate,
      serviceDefinitions
    );
    const { prev, curr } = changes || {};

    const logBody = {
      actionType: "Edit",
      category:
        type === "generalTemplate"
          ? "General Proposal Template"
          : "Service Proposal Template",
      recordId: oldTemplate?.templateId,
      recordName: oldTemplate?.templateTitle,
      label:
        type === "generalTemplate" ? "General Template" : "Service Template",
      updatedAt: Date.now(),
      currentData: {
        [userConfiguration?.nameOfUser]: curr,
      },
      previousData: {
        [userConfiguration?.nameOfUser]: prev,
      },
      updatedKeys: Object.keys(curr),
    };

    await saveAddedLogs(logBody);
  };

  //region onSave
  const onPreSave = async (name) => {
    if (!details?.service) {
      return message.error({
        content: "Please select a service to save",
        key: "serviceNotSelected",
      });
    }
    setSaveDisabled(true);
    const newContent = proposalDesignRef.current.component.value;
    const selectedServices = details?.otherDetails?.services || [];
    const useAlso = selectedServices?.filter(
      (item) => details?.service !== item
    );
    const templateBody = {
      isActive: true,
      templateTitle: name,
      createdAt: new Date(),
      templateContent: newContent,
      templateType: "Proposal Settings",
      createdBy: userConfiguration?.nameOfUser,
      templateCategory: details.templateCategory,
      additionalDetails: {
        service: details?.service || null,
        services: useAlso || [],
        ...(details?.templateCategory === "service" ? { styles } : {}),
      },
    };

    const newBody = {
      templateContent: newContent,
      templateCategory: details.templateCategory,
      additionalDetails: {
        service: details?.service,
        services: details?.otherDetails?.services || [],
        ...(details?.templateCategory === "service" ? { styles } : {}),
      },
    };
    if (!!item) {
      if (callback) {
        callback(newContent);
      } else {
        curryDispatch(
          updateProposalTemplate(
            item.templateId,
            {
              ...newBody,
            },
            () => editLogs(newBody, item)
          )
        );
      }
    } else {
      curryDispatch(createNewTemplate(templateBody, createLog));
    }
    setSaveDisabled(false);
    closeModal();
  };

  //region onPreview
  const onPreviewClick = useCallback(
    (editor) => {
      if (type !== "generalTemplate" && !details?.service?.serviceName) {
        return message.info({
          content: "Please select a service to preview",
          key: "serviceNotSelected",
        });
      }

      const content = editor.value;
      console.log(" content:", { content });
      if (!content || content === "<p><br></p>") {
        return message.info({
          content: "Please add content to preview",
          key: "noContent",
        });
      }

      function getConvertedContent() {
        try {
          const converted = bracketsToTextProposal({
            estimationSample,
            templateContent: content,
            // editorRef: proposalDesignRef,
            item: item
              ? item
              : {
                  additionalDetails: {
                    service: {
                      serviceName: details?.service?.serviceName,
                    },
                    styles,
                  },
                },
            serviceToPreview: null,
            type,
            serviceTemplates,
            styles,
          });
          return converted;
        } catch (e) {
          console.error("error while processing template", e);
          return editor.value;
        }
      }

      const customContent = getConvertedContent();
      console.log(" customContent:", { customContent });

      // Create a modal
      const dialog = new Jodit.modules.Dialog({
        title: "Preview",
      });

      dialog.setHeader("Preview");

      // Set the content of the modal
      dialog.setContent(`
        <div class="jodit-dialog-content">
          ${customContent}
        </div>
      `);

      // Add OK button to the dialog
      dialog.setSize(800, 900);

      dialog.className(
        "jodit jodit-dialog jodit-dialog_theme_default jodit-dialog_resizable_true jodit-dialog_active_true jodit-dialog_fullsize_false jodit-dialog_modal_true"
      );

      // Apply custom CSS class for overlay
      dialog.container.classList.add("jodit-dialog-overlay");

      // Open the modal
      dialog.open();
    },
    [
      proposalDesignRef.current,
      details,
      item,
      type,
      serviceTemplates,
      estimationSample,
      styles,
    ]
  );

  //region config
  const editorConfig = useMemo(() => {
    const config = customConfig();
    const newButtons = [
      {
        icon: "eye",
        tooltip: "Preview",
        name: "preview",
        exec: onPreviewClick,
      },
    ];
    config.buttons = [
      ...config.buttons,
      ...["hr", "outdent", "indent", "|"],
      ...newButtons,
    ];
    return config;
  }, [proposalDesignRef.current, onPreviewClick]);

  const modalTitle = useMemo(() => {
    const tempType =
      type === "generalTemplate"
        ? " General Proposal Template"
        : " Service Proposal Template";
    return item
      ? `Edit ${tempType}` + " | " + item?.templateTitle
      : `Create New ${tempType}`;
  }, [type, item]);

  //region handleSelection
  const handleSelection = useCallback(() => {
    const selection = window.getSelection();
    const label = "{{engineersWork}}";
    const parentElement = selection?.anchorNode?.parentElement;
    if (
      label.includes(selection.toString()) &&
      parentElement &&
      parentElement.innerHTML.includes(label)
    ) {
      if (selection.rangeCount > 0 && !selection.isCollapsed) {
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();
        setTooltipPosition({
          top: rect.top + window.scrollY,
          left: rect.left + window.scrollX,
        });
        const ser = details?.service;

        const serviceType = ser?.isScope
          ? "scope"
          : ser?.isHoist
          ? "hoist"
          : "service";
        setTooltipContent(
          <ToolBox
            onStyleChange={handleStyleChange}
            styles={styles}
            callback={(data) => setStyles(data)}
            serviceType={serviceType}
          />
        );
      } else {
        setTooltipContent(null);
      }
    } else {
      setTooltipContent(null);
    }
  }, [setTooltipPosition, setTooltipContent, handleStyleChange, styles]);

  //region eventListeners
  useEffect(() => {
    if (type === "serviceTemplate") {
      const editorElement = document.querySelector(".jodit-wysiwyg");

      if (editorElement) {
        editorElement.addEventListener("mouseup", handleSelection);
        editorElement.addEventListener("keyup", handleSelection);
      }

      // Cleanup event listeners on component unmount
      return () => {
        if (editorElement) {
          editorElement.removeEventListener("mouseup", handleSelection);
          editorElement.removeEventListener("keyup", handleSelection);
        }
      };
    }
  }, [handleSelection, proposalDesignRef.current, type]);

  return (
    <Modal
      open={visible}
      centered
      width="100%"
      maskClosable={false}
      className={
        "new-template-modal " + (isDarkMode ? "new-template-modal-dark" : "")
      }
      title={modalTitle}
      onCancel={closeModal}
      closeIcon={<CloseIconModalHeader fill="#323338" />}
      destroyOnClose
      footer={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <MondayButton
            onClick={closeModal}
            Icon={<CloseIconModal />}
            className="mondayButtonRed"
          >
            Close
          </MondayButton>
          <MondayButton
            className="mondayButtonBlue"
            disabled={saveDisabled}
            onClick={() => {
              if (!!item) {
                onPreSave(item?.templateTitle);
              } else {
                setNameVisible(true);
              }
            }}
            Icon={<SaveIcon />}
          >
            Save Changes
          </MondayButton>
        </div>
      }
    >
      <div className="edit_template__header">
        <InfoText
          {...{
            text: "Edit the template content below",
          }}
        />
      </div>
      <div className="designer-proposal">
        <JoditEditorComp
          {...{
            height: "100%",
            content: details?.templateContent || "",
            // setContent: (val) => {
            //   proposalDesignRef.current.component.value = val;
            // },
            customConfig: editorConfig,
            ref: proposalDesignRef,
          }}
        />
        {/* <TemplateDesigner
        {...{
          proposalDesignRef,
          closeModal: closeModal,
          type,
          customVal: details?.templateContent,
          }}
          /> */}
        <ServiceCategoriesSelectionProposal
          {...{
            type,
            details,
            setDetails,
            disabled: !!item,
            proposalDesignRef,
            onChange: () => {},
          }}
        />{" "}
      </div>
      {nameVisible && (
        <SaveNameModal
          {...{
            onSave: onPreSave,
            visibility: nameVisible,
            setVisible: setNameVisible,
          }}
        />
      )}
      {tooltipContent ? (
        <ToolboxTooltip content={tooltipContent} position={tooltipPosition} />
      ) : (
        <></>
      )}
    </Modal>
  );
};

export default EditGeneralTemplate;
