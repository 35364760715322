import { Checkbox } from "antd";
import { useState } from "react";

import { elif } from "../../../RolesData";
import ActionButtons from "../../ActionButtons";
import SideDrawer from "./SideDrawer/SideDrawer";
import { hasFalsePermission } from "../utils";
import { useParams } from "react-router-dom";

import AccessCheckboxGroup from "../../../../Management/EditUser/components/AccessCheckboxGroup/AccessCheckboxGroup";
import { useSelector } from "react-redux";

const RouteCard = ({
  route,
  selectedTab,
  accessConfiguration,
  setAccessConfiguration,
  searchRouteConfigs,
  disabled = false,
  isRoleAccess = false,
}) => {
  const [openRouteTree, setOpenRouteTree] = useState(false);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [expandedKeys, setExpandedKeys] = useState({});
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const { role } = useParams();

  const { title, checked, read, write } = route;

  const isRoute = selectedTab === "Route";

  const onRouteClickHandler = () => {
    setOpenRouteTree((prev) => {
      if (prev === true) {
        setExpandedKeys((prev) => ({
          ...prev,
          [title]: [],
        }));
      }
      return !prev;
    });
  };

  function onReadChange(node) {
    node.onReadChange();
    setAccessConfiguration(accessConfiguration.getAllChangedData());
  }

  function onWriteChange(node) {
    node.onWriteChange();
    setAccessConfiguration(accessConfiguration.getAllChangedData());
  }

  function onDescriptionChange(node, val) {
    node.onDescriptionChange(val);
    setAccessConfiguration(accessConfiguration.getAllChangedData());
  }
  const changeDescription = isRoleAccess && role === "Super Admin";
  return (
    <AccessCheckboxGroup
      {...{
        selectedRoute: route,
        onCollapseClick: onRouteClickHandler,
        collapsedOpen: openRouteTree,
        onReadChange,
        onWriteChange,
        onDescriptionChange,
        hideWrite: !isRoute,
        disabled: role === "Super Admin" || disabled,
        changeDescription,
        selectedTab,
        isDarkMode,

        setAutoExpandParent,
        setExpandedKeys,
        expandedKeys,
        autoExpandParent,

        searchRouteConfigs,
      }}
    />
  );
};

export default RouteCard;
