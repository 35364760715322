import { useSelector } from "react-redux";
import { message, Switch, Tooltip } from "antd";
import { InfoWindowF } from "@react-google-maps/api";
import { Dispatch, SetStateAction, useContext, useMemo, useState } from "react";

import {
  EntryType,
  ScheduleType,
  EmployeeReportType,
} from "../../payrollLiveTypes";
import { InfoIcon } from "src/assets";
import PayrollLiveContext from "../../PayrollLiveContext";
import {
  StoreType,
  JobsiteType,
  CoordinateType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import { useEntriesApi, convertSecondsToTime } from "../../utils";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { MondayButton, WarningModal } from "src/components/commonComponents";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { WarningTriangle } from "src/components/SidebarPages/DynamicView/src";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";
import { withinRadius } from "../../../Payroll/Tabs/Activity/components/payrollActivityModalData";
import { findDistanceFromGeofenceSide } from "../../../Payroll/Tabs/DEG/components/modalComponents/utils";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

import "./EmployeeInfo.scss";
interface Props {
  color: string;
  onClick: () => void;
  isHistoryPunch?: boolean;
  data: EmployeeReportType & { isForeman?: boolean };
  setEmployeesHistory?: Dispatch<
    SetStateAction<Array<EntryType & { color?: string }>>
  >;
}

function EmployeeInfo(props: Props) {
  const { data, color, onClick, setEmployeesHistory, isHistoryPunch } = props;
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);

  const {
    mapRef,
    jobsites,
    schedules,
    degEntries,
    accessRight,
    setDegEntries,
    setHistoryInfo,
    selectedWeekDeg,
    setEmployeesInfo,
  } = useContext(PayrollLiveContext);
  const [distanceTime, setDistanceTime] = useState<string | null>(null);
  const [unApproveWarning, setUnApproveWarning] = useState<boolean>(false);

  const { updateEntries } = useEntriesApi();

  function onScheduleSelect(jobId: string) {
    message.loading({
      duration: 0,
      key: "onJobUpdate",
      content: "Changing jobsite...",
    });

    const updatedEntryReport = {
      ...data,
      color: "#e9c466",
    };
    let scheduleIndex = schedules.findIndex((el) => el.scheduleId === jobId);
    let jobIndex = jobsites.findIndex((el) => el.jobsiteId === jobId);

    if (scheduleIndex > -1) {
      const matchedSchedule = schedules[scheduleIndex];
      let isMatch;

      if (matchedSchedule?.geoFenceInfo?.length) {
        isMatch = findDistanceFromGeofenceSide({
          point: data?.punchCoordinates,
          geofence: matchedSchedule?.geoFenceInfo[0]?.geoFenceInfo,
          tolerance: +matchedSchedule?.radius || 300,
        });
      } else {
        isMatch = withinRadius(
          matchedSchedule.addressPosition,
          data.punchCoordinates,
          300
        );
      }

      Object.assign(updatedEntryReport, {
        distance: isMatch?.distanceInFeet,
        projectId: schedules?.[scheduleIndex]?.projectId,
        color: isMatch?.withinRange || isMatch?.inRange ? "#00a464" : "#e9c466",
      });
    }

    if (jobIndex > -1 && scheduleIndex === -1) {
      let isMatch;
      const matchedJobsite = jobsites?.[jobIndex];
      if (
        matchedJobsite?.geoFenceInfo?.length ||
        matchedJobsite?.geofenceInfo?.length
      ) {
        const geofence = matchedJobsite?.geofenceInfo?.length
          ? matchedJobsite?.geofenceInfo
          : matchedJobsite?.geoFenceInfo;

        isMatch = findDistanceFromGeofenceSide({
          point: data?.punchCoordinates,
          geofence: geofence?.[0]?.geoFenceInfo,
          tolerance: +matchedJobsite?.locationRadius || 300,
        });
      } else {
        isMatch = withinRadius(
          matchedJobsite.addressPosition,
          data.punchCoordinates,
          Number(matchedJobsite.locationRadius) || 300
        );
      }

      Object.assign(updatedEntryReport, {
        distance: isMatch?.distanceInFeet,
        projectId: matchedJobsite?.projectId,
        jobsiteId: matchedJobsite?.jobsiteId,
        jobAddress: matchedJobsite?.jobAddress,
        color: isMatch?.withinRange || isMatch?.inRange ? "#00a464" : "#e9c466",
      });
    } else if (scheduleIndex > -1) {
      jobIndex = jobsites.findIndex(
        (el) => el?.projectId === schedules?.[scheduleIndex]?.projectId
      );
    }

    const job = jobsites?.[jobIndex];
    if (job) {
      const jobUpdateBody = {
        projectId: job?.projectId,
        jobsiteId: job?.jobsiteId,
        jobAddress: job?.jobAddress,
        entryId: connectedEntry?.entryId,
        activityStatus: "Completed" as "Completed" | "Draft" | "Pending",
        jobsiteMatch: {
          jobName: job?.jobName,
          jobsiteId: job?.jobsiteId,
          jobAddress: job?.jobAddress,
          services: job?.services || [],
          reimbursement: job.reimbursement,
        },
      };
      updateEntries({
        entries: [jobUpdateBody as unknown as EntryType],
        onSuccessCallback: () => {
          setDegEntries((prev) => {
            return prev.map((el) => {
              const reportEntry = connectedEntry?.entryId === el?.entryId;
              if (reportEntry) {
                return {
                  ...el,
                  ...jobUpdateBody,
                };
              } else {
                return el;
              }
            });
          });
          message.success({
            duration: 3,
            key: "onJobUpdate",
            content: "Job changed successfully!",
          });
        },
        onErrorCallback: (err) => {
          console.log("Error updating jobsite:: ", err);
          message.error({
            duration: 3,
            key: "onJobUpdate",
            content: "There was a problem updating jobsite",
          });
        },
      });
    }
  }

  function onShowRoute() {
    const jobIndex = jobsites.findIndex(
      (el) =>
        el?.projectId === data?.projectId || el?.jobsiteId === data?.jobsiteId
    );
    if (jobIndex > -1) {
      mapRef.current.getDirections({
        origin: data.punchCoordinates,
        travelMode: google.maps.TravelMode.WALKING,
        destination: jobsites[jobIndex].addressPosition,
      });
    }
  }

  async function directionModeToggle(e: boolean) {
    const jobIndex = jobsites.findIndex(
      (el) => el?.projectId === data?.projectId
    );

    const directions = await mapRef.current.getDirections({
      origin: data.punchCoordinates,
      destination: jobsites?.[jobIndex]?.addressPosition,
      travelMode: e
        ? google.maps.TravelMode.DRIVING
        : google.maps.TravelMode.WALKING,
    });
    setDistanceTime(directions?.routes?.[0]?.legs?.[0]?.duration?.text);
  }

  function showPunchHistory() {
    let historyEntries = degEntries.flatMap((el) => {
      return (el?.employeeId || "").split("-")?.[1] === data?.employeeNumber
        ? { ...el, color: "#1264a38b" }
        : [];
    });
    setEmployeesHistory(historyEntries);
    setHistoryInfo(
      `${historyEntries?.[0]?.employeeId}${historyEntries?.[0]?.punchType}`
    );
    setEmployeesInfo(undefined);
  }

  async function onApprovePunch() {
    message.loading({
      duration: 0,
      key: "onApprovePunch",
      content:
        connectedEntry?.activityStatus === "Completed"
          ? "UnApproving..."
          : "Approving...",
    });
    const jobIndex = jobsites.findIndex(
      (el) => el?.jobsiteId === data?.jobsiteId
    );

    updateEntries({
      entries: [
        {
          activityStatus:
            connectedEntry?.activityStatus === "Completed"
              ? "Draft"
              : "Completed",
          entryId: connectedEntry?.entryId,
          jobsiteId: jobsites?.[jobIndex]?.jobsiteId,
          jobAddress: jobsites?.[jobIndex]?.jobAddress,
          jobsiteMatch: {
            jobName: jobsites?.[jobIndex]?.jobName,
            jobsiteId: jobsites?.[jobIndex]?.jobsiteId,
            jobAddress: jobsites?.[jobIndex]?.jobAddress,
            services: jobsites?.[jobIndex]?.services || [],
            reimbursement: jobsites?.[jobIndex]?.reimbursement,
          },
        },
      ],
      onSuccessCallback: () => {
        setDegEntries((prev) =>
          prev.map((el) =>
            el?.entryId === connectedEntry?.entryId
              ? {
                  ...el,
                  activityStatus:
                    connectedEntry?.activityStatus === "Completed"
                      ? "Draft"
                      : "Completed",
                }
              : el
          )
        );
        if (connectedEntry?.activityStatus === "Completed") {
          message.warning({
            key: "onApprovePunch",
            content: "Punch UnApproved",
            duration: 3,
          });
        } else {
          message.success({
            key: "onApprovePunch",
            content: "Punch Approved",
            duration: 3,
          });
        }
      },
      onErrorCallback: (error) => {
        console.log("Error updating entry: ", error);
        message.error({
          duration: 3,
          key: "onApprovePunch",
          content: "Error approving this punch",
        });
      },
    });
  }

  function getJobOptions({
    data,
    type,
    empCoordinates,
  }: {
    type: "jobsites" | "schedules";
    empCoordinates: CoordinateType;
    data: Array<JobsiteType | ScheduleType>;
  }) {
    const options = data
      .sort((a, b) => {
        const positionA = a?.addressPosition?.lat
          ? a?.addressPosition
          : { lat: 0, lng: 0 };
        const positionB = b?.addressPosition?.lat
          ? b?.addressPosition
          : { lat: 0, lng: 0 };

        const distanceA = withinRadius(
          positionA,
          empCoordinates
        )?.distanceInMile;
        const distanceB = withinRadius(
          positionB,
          empCoordinates
        )?.distanceInMile;

        return distanceA - distanceB;
      })
      .map((el: ScheduleType & JobsiteType) => {
        const { jobsiteId, jobAddress, scheduleAddress, addressPosition } = el;
        let distance = withinRadius(
          addressPosition,
          empCoordinates
        )?.distanceInFeet;

        let lengthName = "Feet";

        if (distance >= 5280) {
          distance = Math.round(distance / 5280);
          lengthName = "Miles";
        }

        return {
          key: type === "schedules" ? el?.projectId : jobsiteId,
          value: type === "schedules" ? el?.projectId : jobsiteId,
          label: (
            <div
              style={{
                gap: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              key={type === "schedules" ? scheduleAddress : jobAddress} // key used for search options
            >
              <div>{type === "schedules" ? scheduleAddress : jobAddress}</div>
              {empCoordinates?.lat && (
                <div
                  style={{
                    color: "grey",
                  }}
                >
                  ({distance}) {lengthName}
                </div>
              )}
            </div>
          ),
        };
      });
    return options;
  }

  function onEmployeeNavigate() {
    if (data?.crewId) {
      window.open(
        `${window.location.origin}/hrManagement/crews/${data?.crewId}`,
        "_blank"
      );
    }
    return;
  }

  const connectedEntry = useMemo(() => {
    const employeeEntries = degEntries
      .filter((el) => {
        // when entry has employeeId
        const firstCondition = el?.employeeId === data?.employeeId;
        // when entry does not have employeeId
        const secondCondition =
          el?.employeeId === `${data?.company}-${data?.employeeNumber}`;

        return firstCondition || secondCondition;
      })
      .sort((a, b) => a.punchTimeStamp - b.punchTimeStamp);

    const matchedEntry = employeeEntries?.[employeeEntries?.length - 1];

    return matchedEntry;
  }, [JSON.stringify(degEntries), data]);

  const customOptions = useMemo(() => {
    const empCoordinates = data.punchCoordinates;
    const scheduleOptions = getJobOptions({
      data: schedules.filter(
        (el: ScheduleType & JobsiteType) => !el?.jobsiteId
      ),
      type: "schedules",
      empCoordinates,
    });
    const jobOptions = getJobOptions({
      empCoordinates,
      data: jobsites,
      type: "jobsites",
    });

    return [
      {
        label: "Schedules",
        options: scheduleOptions,
      },
      {
        label: "Jobsites",
        options: jobOptions,
      },
    ];
  }, [schedules, data]);

  const distance = useMemo(() => {
    const jobIndex = jobsites.findIndex(
      (el) =>
        el?.projectId === data?.projectId || el?.jobsiteId === data?.jobsiteId
    );
    let lengthName = "ft";
    let duration = 0;

    let dst = withinRadius(
      data?.punchCoordinates,
      jobsites?.[jobIndex]?.addressPosition
    ).distanceInFeet;

    duration = Math.round(dst / 4.7);

    if (dst >= 5280) {
      dst = Math.round(dst / 5280);
      lengthName = "Miles";
    }

    return { dst, lengthName, duration };
  }, [data, jobsites]);

  return (
    <InfoWindowF
      zIndex={3}
      position={data?.punchCoordinates}
      key={data?.employeeNumber + "info"}
      options={{ pixelOffset: new google.maps.Size(0, -36) }}
    >
      <section
        className={`employee-info-window ${
          darkMode ? "employee-info-window-dark" : ""
        }`}
        style={{ boxShadow: `0px 0px 4px 0px ${color || "#e9c466"}` }}
      >
        <div className="info-header">
          <span className="info-title">
            Employee{" "}
            <span className={connectedEntry?.activityStatus}>
              {connectedEntry?.activityStatus
                ? "(" + connectedEntry?.activityStatus + ")"
                : ""}
            </span>
          </span>
          <XIcon onClick={onClick} width={12} height={12} />
        </div>
        <div className="info-body">
          <div className="location-information">
            <Tooltip title="Employee's Punch location">
              <InfoIcon width={14} height={14} />
            </Tooltip>
            <span>{data?.punchLocation}</span>
          </div>
          <div className="info-data">
            <label className="employee-name" onClick={onEmployeeNavigate}>
              {data.employeeName}{" "}
              {data?.isForeman ? (
                <span style={{ color: "#acacac" }}>(Foreman)</span>
              ) : (
                ""
              )}
            </label>
            <div className="employee-status">
              <div
                className="status-pin"
                style={{ backgroundColor: color || "#e9c466" }}
              ></div>
              <span>{data.liveStatus}</span>
            </div>
          </div>
          <div className="info-data">
            <div>
              {data?.crewForeman
                ? `${data?.crewTeamName || ""} (${data?.crewForeman})`
                : `${data?.crewTeamName || ""}`}
            </div>
            <div>
              {data.punchTime.length > 19
                ? dayjsNY(data.punchTime).format("MM/DD/YYYY, hh:mm A")
                : parseInTz(data.punchTime).format("MM/DD/YYYY, hh:mm A")}
            </div>
          </div>
          {(data?.projectId || data?.jobsiteId) &&
          data?.punchCoordinates?.lat ? (
            <div className="info-data">
              <div className="distance-data">
                <span>
                  Distance: {distance?.dst + " " + distance?.lengthName}
                </span>
                <div className="duration">
                  ({distanceTime ?? convertSecondsToTime(distance.duration)} )
                </div>
              </div>
              <span>
                <Switch
                  checkedChildren="Driving"
                  unCheckedChildren="Walking"
                  onChange={directionModeToggle}
                />
              </span>
              <span onClick={onShowRoute} className="show-route">
                Show Route
              </span>
            </div>
          ) : null}
          {setEmployeesHistory ? (
            <div onClick={showPunchHistory} className="show-route">
              Show Punch History
            </div>
          ) : null}

          {Object.hasOwn(data, "company") &&
          isHistoryPunch &&
          accessRight?.write &&
          selectedWeekDeg?.degStatus !== "Completed" ? (
            <InputComponent
              allowClear
              type="select"
              label="Schedule"
              onSelect={onScheduleSelect}
              customOptions={customOptions}
              placeholder="Select schedule match"
              onClear={() => onScheduleSelect("")}
              initialValue={
                connectedEntry?.jobsiteId
                  ? connectedEntry?.jobsiteId
                  : data?.jobsiteId || data?.projectId
              }
              filterOption={(
                input: string,
                option: { label: React.ReactElement }
              ) => {
                return (option?.label?.key || "")
                  .toLowerCase()
                  .includes((input || "").toLowerCase());
              }}
            />
          ) : null}
          {accessRight?.write ? (
            <div className="approve-container">
              <Tooltip
                title={
                  connectedEntry?.activityStatus === "Completed"
                    ? "UnApprove Punch"
                    : ""
                }
              >
                <MondayButton
                  hasIcon={false}
                  onClick={() =>
                    connectedEntry?.activityStatus === "Completed"
                      ? setUnApproveWarning(true)
                      : onApprovePunch()
                  }
                  disabled={!connectedEntry}
                  className={
                    connectedEntry?.activityStatus === "Completed"
                      ? "mondayButtonRedHover"
                      : !connectedEntry
                      ? ""
                      : "mondayButtonBlue"
                  }
                >
                  {connectedEntry?.activityStatus === "Completed"
                    ? "APPROVED"
                    : "Approve Punch"}
                </MondayButton>
              </Tooltip>
            </div>
          ) : null}
          {!!unApproveWarning ? (
            <WarningModal
              closable={true}
              title="Warning Message"
              visible={!!unApproveWarning}
              setVisible={setUnApproveWarning}
              className="logout-warning-modal"
            >
              <div className="logout-modal-body">
                <span>
                  <WarningTriangle />
                </span>
                <p>
                  Are you sure you want to <b>UNAPPROVE</b> this punch?
                </p>
                <div className="buttons">
                  <MondayButton
                    Icon={<XIcon />}
                    className="mondayButtonRed"
                    onClick={() => setUnApproveWarning(false)}
                  >
                    No
                  </MondayButton>
                  <MondayButton
                    onClick={() => {
                      onApprovePunch();
                      setUnApproveWarning(false);
                    }}
                    Icon={<TickIcon />}
                  >
                    Yes
                  </MondayButton>
                </div>
              </div>
            </WarningModal>
          ) : null}
        </div>
      </section>
    </InfoWindowF>
  );
}

export default EmployeeInfo;
