import "./documentation-types.scss";
import { Badge, Button, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

import { PermitDrawingsWhite } from "../../../../../../../../../icons";
import { fetchAllData } from "../../../../../../../../../utils";
import { useDocumentationTypes } from "../../../../providers/DocumentationTypesProvider";
import { useEmailTemplates } from "../../../../providers/EmailTemplatesProvider";
import CustomDropdown from "../../../custom-dropdown/CustomDropdown";
import { useFilePreviewModal } from "../../../../providers/FilePreviewModalProvider";
import { useDriveDocuments } from "../../../../providers/DriveDocumentsProvider";

import DocTypesFilesModal from "./doc-types-modal/DocTypesFilesModal";

function DocumentationTypes({ recordDetails }) {
  const { setSelectedFile, setFilePreview } = useFilePreviewModal();
  const [isDocTypesModalOpen, setIsDocTypesModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const { documentationTypes, setDocumentationTypes, documentationTypesFiles } =
    useDocumentationTypes();
  const { loading } = useEmailTemplates();
  const { driveDocuments } = useDriveDocuments();

  const { userConfiguration } = useSelector((state) => state.userConfig);

  const count = documentationTypesFiles?.length;

  useEffect(() => {
    (async () => {
      const response = await fetchAllData({
        endpoint: "documentation",
        resultId: "docId",
        otherStringParams: {
          filters: JSON.stringify([
            {
              conditions: [
                {
                  column: "categoryName",
                  value:
                    recordDetails?.categoryType === "permitdrawings"
                      ? "Permit-Drawings" // If categoryType is "permitdrawings", use "Permit-Drawings"
                      : recordDetails?.categoryType === "accounts"
                      ? "Clients" // If categoryType is "accounts", use "Clients"
                      : recordDetails?.categoryType && // If categoryType exists,
                        recordDetails?.categoryType?.charAt(0).toUpperCase() + // Capitalize the first letter of categoryType
                          recordDetails?.categoryType?.slice(1), // Add the rest of the string
                  formula: "is",
                },
              ],
            },
          ]),
        },
      });

      if (response) {
        setDocumentationTypes(
          response
            ?.filter(({ recordId, teamsConfiguration }) => {
              const members =
                teamsConfiguration?.flatMap((team) =>
                  team.members
                    ?.map((member) => member.cognitoUserId)
                    ?.filter(Boolean)
                ) || [];

              return (
                recordId === recordDetails.recordId &&
                members?.includes(userConfiguration?.cognitoUserId)
              );
            }) // Filter to keep only records matching the current recordId
            .map(({ docType, folderId, googleDriveUploads }) => ({
              docType, // Keep only the necessary fields: docType, folderId, and googleDriveUploads
              folderId,
              googleDriveUploads,
            }))
        );
      }
    })();
  }, []);

  const items = _.uniqBy(documentationTypes, "folderId").map(
    // Map over unique documentation types based on folderId
    ({ docType, folderId, googleDriveUploads }) => ({
      key: folderId, // Use folderId as the key for each item
      label: (
        <Button
          type="link"
          className="dropdown-item-button" // Assign a CSS class to style the button
          onClick={() => {
            setIsDocTypesModalOpen(true); // Open the document types modal when the button is clicked
            setFiles(googleDriveUploads); // Set the files for the modal using googleDriveUploads
          }}
          data-testid="dropdown-item-label" // Add a data-testid attribute for testing purposes
        >
          {docType} {/* Display the docType as the button label */}
        </Button>
      ),
    })
  );

  const toolTipData =
    documentationTypesFiles?.length > 0
      ? documentationTypesFiles?.map(({ name, blob, type, id }) => (
          <p
            key={id}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSelectedFile({
                src: blob,
                mimeType: type,
              });
              setFilePreview(true);
            }}
            data-testid={`drive-document-item-${id}`}
          >
            {name}
          </p>
        ))
      : "Documentation Types";

  if (loading)
    return (
      <div className="documentation-types-loading-container documentation-types-container">
        <Badge
          status="processing"
          className="documentation-types-loading-badge"
        />
        <PermitDrawingsWhite className="documentation-types-icon" />
      </div>
    );

  return (
    <div className="documentation-types-container">
      <Tooltip placement="left" title={toolTipData}>
        <Badge count={count || driveDocuments?.length}>
          <CustomDropdown items={items} placement="top" trigger={["click"]}>
            <a
              onClick={(e) => e.preventDefault()}
              data-testid="documentation-types-dropdown-trigger"
            >
              <PermitDrawingsWhite className="documentation-types-icon" />
            </a>
          </CustomDropdown>
        </Badge>
      </Tooltip>

      {isDocTypesModalOpen && (
        <DocTypesFilesModal
          isDocTypesModalOpen={isDocTypesModalOpen}
          setIsDocTypesModalOpen={setIsDocTypesModalOpen}
          files={files}
        />
      )}
    </div>
  );
}

export default DocumentationTypes;
