import { isPartOfTeam } from "../../components/SidebarPages/Documentation/Tabs/helper";

/**
 * @param {Object} userConfiguration current user configuration
 * @param {Array} rowData current row data to filter
 * @param {false | function} isAssigned function to check if the row is assigned
 * @returns {Array} Array with filtered row data by team config, createdBy and groupName
 */
export const filterRowDataByTeamConfig = (
  userConfiguration,
  rowData = [],
  isAssigned = false
) => {
  if (userConfiguration?.groupName?.toLowerCase() === "admin") {
    return rowData;
  }
  if (rowData?.every((row) => !row?.teamsConfiguration)) {
    return rowData;
  } else {
    return rowData?.filter((row) => {
      let createdBy = row?.createdBy?.name || row?.createdBy;
      let teamMember = isPartOfTeam(row?.teamsConfiguration, userConfiguration);
      let assignedTo = isAssigned ? isAssigned(row) : false;
      return (
        teamMember || createdBy === userConfiguration?.nameOfUser || assignedTo
      );
    });
  }
};
