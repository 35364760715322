import { Button, Checkbox, Select } from "antd";
import { useEffect, useState } from "react";

import {
  More,
  mute as muteIcon,
  pause as pauseIcon,
  play as playIcon,
  Selected,
  sortAsc,
  sortDesc,
  volume as volumeIcon,
} from "../../../assets";
import { openInNewTab, useLocalStorage } from "../../../utils";
import "../Estimations/Controller/page/Controller.scss";
import { DropDownArrow } from "../Fleet/components/InputComponent/assets";
import { useLocation } from "react-router-dom";
import {
  documentationColumns,
  permitDrawingColumns,
} from "../../../Dashboard/utils/controller-live-dashboard-columns";
import { SettingsColumnsPanel } from "../../../Dashboard/components/SettingsColumnsPanel";

const getLiveDashboardHref = (pathname) => {
  const formattedPathName = pathname.replace(/\//g, "");
  switch (formattedPathName) {
    case "documentation":
      return "documentationDashboard";
    case "estimations":
      return "estimationDashboard";
    default:
      return "permitDrawingsDashboard";
  }
};

const getColumnOptions = (pathName) => {
  switch (pathName.replace(/\//g, "")) {
    case "permitdrawings":
      return permitDrawingColumns;
    case "documentation":
      return documentationColumns;
    default:
      return [];
  }
};

const Controller = ({ closeModal, statusPanel }) => {
  const location = useLocation();
  const columnOptions = getColumnOptions(location.pathname);
  const [lastUpdatedClicked, setLastUpdatedClicked] = useState(false);
  const [mute, setMute] = useLocalStorage("muteDrawings");
  const [shownColumns, setShownColumns] = useLocalStorage(
    "shownColumnsForDrawings"
  );
  const [storedChoosenColumn, setStoredChoosenColumn] = useLocalStorage(
    "choosenColumnForDrawings"
  );
  const [scroll, setScroll] = useLocalStorage("scrollDrawings");
  const [storedSort, setStoredSort] = useLocalStorage("sortDrawings");
  const volumeControllerIcon = mute ? muteIcon : volumeIcon;
  const scrollControllerIcon = scroll ? pauseIcon : playIcon;

  const [sort, setSort] = useState("null");
  const [choosenColumn, setChoosenColumn] = useState();
  const [checkedList, setCheckedList] = useState(columnOptions);
  const [checkAll, setCheckAll] = useState(true);
  const [indeterminate, setIndeterminate] = useState(false);
  const [openLiveButton, setOpenLiveButton] = useState(false);
  const [pinnedRowsNr, setPinnedRowsNr] = useLocalStorage("pinnedRowsNr");
  const [liveDashboardAppliedFilters, setLiveDashboardAppliedFilters] =
    useLocalStorage("live_dashboard_applied_filters");
  const [options, setOptions] = useState(columnOptions);
  const [statusOptions, setStatusOptions] = useState(
    statusPanel.data?.map((status, index) => ({
      id: (index + 1).toString(),
      content: {
        value: status.statusName,
        label: status.statusName,
      },
      checked: index < 4 ? true : false,
    }))
  );

  useEffect(() => {
    setMute(false);
    setScroll(false);
    setShownColumns(checkedList);
    setStoredSort("null");
    setStoredChoosenColumn(null);
    // setLiveDashboardAppliedFilters(null);

    // return () => {
    //   setLiveDashboardAppliedFilters(null);
    // };
  }, []);

  const onChange = (checkedList) => {
    setCheckedList(checkedList);
    setIndeterminate(
      !!checkedList.length && checkedList.length < columnOptions.length
    );
    setCheckAll(checkedList.length === columnOptions.length);
    setSort("null");
    setChoosenColumn(null);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? columnOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const goLive = () => {
    openInNewTab(`${getLiveDashboardHref(location.pathname)}`);
    closeModal();
  };

  return (
    <div className="controllerPage">
      <div className="controller">
        {!lastUpdatedClicked ? (
          <>
            <div className="columnsContainer">
              <div className="columnsCheckboxPanel">
                <div className="label">Columns: </div>
                <SettingsColumnsPanel
                  options={options}
                  setOptions={setOptions}
                />
              </div>
              <div className="columnsCheckboxPanel">
                <div className="label">Statuses: </div>
                <SettingsColumnsPanel
                  options={statusOptions}
                  setOptions={setStatusOptions}
                />
              </div>
            </div>
            <div className="controlsContainer">
              <Select
                placeholder="Amount of Pinned rows"
                id="pinnedRows"
                suffixIcon={<DropDownArrow />}
                allowClear
                onChange={(e) => {
                  setPinnedRowsNr(e === undefined ? null : e);
                }}
                value={pinnedRowsNr}
                style={{
                  padding: 0,
                  margin: "12px 0 12px 0",
                  width: "200px",
                  borderRadius: "8px",
                  height: "32px",
                }}
              >
                <Select.Option value="1">1 Pinned Row</Select.Option>
                <Select.Option value="2">2 Pinned Row</Select.Option>
                <Select.Option value="3">3 Pinned Row</Select.Option>
                <Select.Option value="4">4 Pinned Row</Select.Option>
              </Select>

              <div className="controllerIconContainer">
                <div className="soundControl">
                  <div className="label">Sound</div>
                  <img
                    src={volumeControllerIcon}
                    alt={volumeControllerIcon}
                    onClick={() => setMute(!mute)}
                  />
                </div>
                <div className="autoplayControl">
                  <div className="label">Autoplay</div>
                  <img
                    src={scrollControllerIcon}
                    alt={scrollControllerIcon}
                    onClick={() => setScroll(!scroll)}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="columnsControlPanel">
            <div className="columnsSectionContainer">
              <span
                className="columnsControlPanelItem"
                style={{ cursor: "unset" }}
              >
                last updated
              </span>
              {checkedList.map((item) => (
                <span
                  key={item.id}
                  className="columnsControlPanelItem"
                  onClick={() => setChoosenColumn(item)}
                >
                  {item.content.label}
                  {item.content.value === choosenColumn && (
                    <img src={Selected} alt={Selected} />
                  )}
                </span>
              ))}
            </div>
            <div
              className="columnsSectionContainer"
              style={{ padding: "20px 0" }}
            >
              <span
                className="columnsControlPanelItem"
                onClick={() => setSort("asc")}
              >
                <span>
                  asc <img src={sortAsc} alt={sortAsc} />
                </span>
                {sort.includes("asc") && <img src={Selected} alt={Selected} />}
              </span>
              <span
                className="columnsControlPanelItem"
                onClick={() => setSort("desc")}
              >
                <span>
                  desc <img src={sortDesc} alt={sortDesc} />
                </span>
                {sort.includes("desc") && <img src={Selected} alt={Selected} />}
              </span>
            </div>
          </div>
        )}

        <div className="actionButtonsContainer">
          <Button
            disabled={!checkedList.length}
            className="lastUpdatedButton"
            onClick={() => setLastUpdatedClicked(!lastUpdatedClicked)}
          >
            last updated
            <img src={More} alt={More} />
          </Button>

          <Button
            className="applyButton"
            onClick={() => {
              setShownColumns(checkedList);
              setStoredSort(sort);
              setStoredChoosenColumn(choosenColumn);
              setOpenLiveButton(!openLiveButton);
              setLiveDashboardAppliedFilters({
                options: options
                  .filter((option) => option.checked)
                  .map((option) => option.content.value),
                statusOptions: statusOptions
                  .filter((option) => option.checked)
                  .map((option) => option.content.value),
              });
            }}
          >
            apply
          </Button>

          {openLiveButton && (
            <Button className="liveButton" onClick={goLive}>
              live
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Controller;
