export const SERVICE_PROPOSAL_INSERTS = {
  "Service Name": "{{serviceName}}",
  "Engineers Work": "{{engineersWork}}",
  "Service Includes": "{{serviceIncludes}}",
  "Service Excludes": "{{serviceExcludes}}",
  "Service Rental Payment Terms": "{{serviceRentalPaymentTerms}}",
  "Service Price No Tax": "{{servicePriceNoTax}}",
  "Service Tax Amount": "{{serviceTaxAmount}}",
  "Service Total Amount": "{{serviceTotalAmount}}",

  // "Service Additional Rentals": "{{serviceAdditionalRentals}}",
  // "Service Additional Rentals Price": "{{serviceAdditionalRentalsPrice}}",
  // "Service Additional Rentals Tax": "{{serviceAdditionalRentalsTax}}",
  // "Service Additional Rentals Total": "{{serviceAdditionalRentalsTotal}}",
  // "Service Addons Price": "{{serviceAddonsPrice}}",
  // "Service Addons Tax": "{{serviceAddonsTax}}",
  // "Service Addons Total": "{{serviceAddonsTotal}}",
  // only hoist
  // "Hoist Type": "{{hoistType}}",
  // "Hoist Model": "{{hoistModel}}",
  // "Hoist Length": "{{hoistLength}}",
  // "Hoist Location": "{{hoistLocation}}", //e.g. FRONT of the Building.
  // "Hoist Speed": "{{hoistSpeed}}", //e.g. 175 fpm
  // "Hoist Capacity": "{{hoistCapacity}}", //e.g. 7000 lbs
  // "Interior Dimensions": "{{interiorDimensions}}", //e.g.  4 ft 11in. Clear Width X 7 ft. 6in. Clear
  // // High X 12 ft. 9in. Clear Length
  // "Hoist from-to": "{{hoistFromTo}}", //e.g. from 1st floor to the Roof Level
  // "Hoist Price": "{{hoistPrice}}", //e.g. $1,000
  // "Hoist Tax": "{{hoistTax}}", //e.g. $100
  // "Hoist Total Price": "{{hoistTotalPrice}}", //e.g. $1,100
  // "Hoist Rental Terms": "{{HoistRentalTerms}}", //e.g. 1 week
};

export const ESTIMATION_INSERTS = {
  "Account Manager": "{{accountManager}}",
  "Account Name": "{{accountName}}",
  Borough: "{{borough}}",
  "Jobsite Address": "{{jobSiteAddress}}",
  "Project Executive": "{{projectExecutive}}",
  "Version Name": "{{versionName}}",
};

export const getServiceProposalInserts = (
  type,
  serviceTemplates = [],
  serviceDefinitions
) => {
  switch (type) {
    case "generalTemplate":
      let generalTmp = {};
      serviceTemplates.forEach((template) => {
        let tmp = { ...template };
        if (tmp?.additionalDetails?.service?.workId) {
          tmp.additionalDetails.service = serviceDefinitions.find(
            (service) =>
              service.serviceId === tmp?.additionalDetails?.service?.workId
          );
        }
        generalTmp[tmp.templateTitle] = {
          templateId: tmp.templateId,
          templateContent: tmp.templateContent,
          additionalDetails: tmp,
        };
      });
      return Object.entries(generalTmp).map(([key, value]) => {
        return {
          name: key,
          value: `{{${key}_${value?.templateId}}}`,
          others: value?.additionalDetails,
        };
      });

    default:
      // we are templates
      return Object.entries(SERVICE_PROPOSAL_INSERTS).map(([key, value]) => {
        return {
          name: key,
          value: value,
        };
      });
  }
};

export const SAMPLE_SERVICE_PROPOSAL_INSERTS = {
  "Engineers Work": "{{engineersWork}}",
  "Service Includes": "{{serviceIncludes}}",
  "Service Excludes": "{{serviceExcludes}}",
  "Service Rental Payment Terms": "{{serviceRentalPaymentTerms}}",
  "Service Price No Tax": "{{servicePriceNoTax}}",
  "Service Tax Amount": "{{serviceTaxAmount}}",
  "Service Total Amount": "{{serviceTotalAmount}}",
  "Service Additional Rentals": "{{serviceAdditionalRentals}}",
  "Service Additional Rentals Price": "{{serviceAdditionalRentalsPrice}}",
  "Service Additional Rentals Tax": "{{serviceAdditionalRentalsTax}}",
  "Service Additional Rentals Total": "{{serviceAdditionalRentalsTotal}}",
  "Service Addons Price": "{{serviceAddonsPrice}}",
  "Service Addons Tax": "{{serviceAddonsTax}}",
  "Service Addons Total": "{{serviceAddonsTotal}}",
};
