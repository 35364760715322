import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

export const useResponsive = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const isLandscapeMobile = useMediaQuery({
    minWidth: 576,
    maxHeight: 500,
  });
  const handleWindowSizeChange = () => {
    const currentWidth = window.innerWidth;
    const previousWidth = width;

    const crossingMobileBoundary =
      (currentWidth < 768 && previousWidth >= 768) ||
      (currentWidth >= 768 && previousWidth < 768);
    const crossingTabletBoundary =
      (currentWidth >= 768 &&
        currentWidth <= 1024 &&
        (previousWidth < 768 || previousWidth > 1024)) ||
      (previousWidth >= 768 &&
        previousWidth <= 1024 &&
        (currentWidth < 768 || currentWidth > 1024));
    const crossingDesktopBoundary =
      (currentWidth > 1024 && previousWidth <= 1024) ||
      (currentWidth <= 1024 && previousWidth > 1024);

    if (
      crossingMobileBoundary ||
      crossingTabletBoundary ||
      crossingDesktopBoundary
    ) {
      setWidth(currentWidth);
    }
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [width]);

  const mobile = width < 768 || isLandscapeMobile;
  const tablet = width >= 768 && width <= 1024;
  const desktop = width > 1024;

  return { mobile, tablet, desktop, width, height };
};
export default useResponsive;
