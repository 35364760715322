import { Tooltip } from "antd";
import { EmailIcon, ExportPdfIcon, FilledEyeIcon } from "../../../../../assets";
import { ReportIcon, TasksIconNew } from "../../../../../icons";
import { LogsButton, MondayButton } from "../../../../commonComponents";
import { DynamicAvatars } from "../../../../commonComponents/DynamicAvatars/DynamicAvatars";
import { TickIcon } from "../../../../pages/Settings/settingsComponents/Roles/src";
import { TrashIcon, XIcon } from "../../../Communication/assets";
import { LogsIcon } from "../../../DynamicView/src";
import { viewSafetyUsed } from "./NewSafetyInspection";
import { Notes } from "../../../../commonComponents/Notes/Notes";
import { LinkButton } from "../../../../commonComponents";
import SafetyInspectionEmails from "./SafetyInspectionEmails";

/**
 * Renders the footer section of the modal.
 *
 * @param {Function} onCancel - The callback function for cancel action. Default is an empty function.
 * @param {Function} onSave - The callback function for save action. Default is an empty function.
 * @param {Function} onDelete - The callback function for delete action. Default is an empty function.
 * @param {Function} onGeneratePdf - The callback function for generate PDF action. Default is an empty function.
 * @param {Function} onEmail - The callback function for email action. Default is an empty function.
 * @param {string} defaultId - The default ID.
 * @param {Array} selectedSafety - The selected safety items.
 * @param {boolean} hasReport - Indicates if there is a report available.
 * @param {Function} onReportView - The callback function for view report action. Default is an empty function.
 * @param {Function} setTasksVisible - The callback function for setting tasks visibility. Default is an empty function.
 * @param {Object} selectedTeam - The selected team object.
 * @param {Object} changes - The changes object.
 * @param {Object} noteAccess - The note access object.
 * @param {string} inspectionName - The name of the inspection. Default is "Safety Inspection".
 * @param {string} inspectionCategory - The category of the inspection.
 * @param {Object} popoverProps - Params for Link Button
 * @returns {Array} - The rendered footer section of the modal.
 */
function modalFooter({
  onCancel = () => {},
  onSave = () => {},
  onDelete = () => {},
  onGeneratePdf = () => {},
  onEmail = () => {},
  defaultId,
  selectedSafety = [],
  isDarkMode,
  hasReport = false,
  onReportView = () => {},
  setTasksVisible = () => {},
  selectedTeam,
  changes,
  noteAccess = null,
  inspectionName = "Safety Inspection",
  inspectionCategory,
  popoverProps,
  setShowPdfPreview,
}) {
  return [
    <>
      {!!defaultId && (
        <div className="buttonContainer">
          <MondayButton
            {...{
              Icon: <TrashIcon />,
              className: "mondayButtonRed",
              onClick: onDelete,
            }}
          >
            Delete
          </MondayButton>
          <LogsButton
            {...{
              logsTitle: "Safety Inspection Logs",
              filtersObj: { recordId: defaultId },
            }}
          />
          <MondayButton
            {...{
              Icon: <ExportPdfIcon />,
              className: "mondayButtonBlue",
              onClick: () => setShowPdfPreview(true),
            }}
          >
            PDF Preview
          </MondayButton>
          {hasReport && (
            <MondayButton
              {...{
                Icon: <ReportIcon />,
                className: "mondayButtonBlue",
                onClick: onReportView,
              }}
            >
              View Report
            </MondayButton>
          )}
          <MondayButton
            {...{
              Icon: <TasksIconNew />,
              className: "mondayButtonBlue",
              onClick() {
                setTasksVisible(true);
              },
            }}
          >
            Tasks
          </MondayButton>
          <LinkButton popoverProps={popoverProps} title="Go To" />
          {!!noteAccess && noteAccess?.read && (
            <div className="safety-notes-button">
              <Notes
                {...{
                  noteModalName: inspectionName,
                  rowId: defaultId,
                  tooltipCategory: "Safety Inspection",
                  tooltipCategory: ["Safety", "Safety Inspection"],
                  topicCategory: "Inspections",
                }}
              />
            </div>
          )}
          {selectedSafety.length ? (
            <Tooltip title={"View used Safety Report"}>
              <MondayButton
                {...{
                  Icon: <FilledEyeIcon style={{ height: 23, width: 23 }} />,
                  className: "mondayButtonBlue previewButton",
                  onClick: () => viewSafetyUsed(selectedSafety),
                }}
              ></MondayButton>
            </Tooltip>
          ) : null}

          <SafetyInspectionEmails
            title={inspectionName}
            recordId={defaultId}
            categoryName="Safety Inspections"
          />
        </div>
      )}
      <div className="buttonContainer">
        {(selectedTeam || [])?.length > 0 && (
          <DynamicAvatars
            {...{
              title: "Team",
              isDarkMode: isDarkMode,
              members: selectedTeam.reduce(
                (acc, { members }) => [...acc, ...members],
                []
              ),
            }}
          />
        )}
        <MondayButton
          {...{
            Icon: <XIcon />,
            className: "mondayButtonRed",
            onClick: onCancel,
            tooltipCategory: "Safety Inpections",
            tooltipKey: "cancel",
          }}
        >
          Cancel
        </MondayButton>
        <MondayButton
          {...{
            Icon: <TickIcon />,
            onClick: onSave,
            disabled: !changes,
            tooltipCategory: "Safety Inpections",
            tooltipKey: "save",
          }}
        >
          Save
        </MondayButton>
      </div>
    </>,
  ].filter(Boolean);
}

export default modalFooter;
