import { ColorPicker, Dropdown } from "antd";
import "./ToolboxItem.scss";
import { debounce } from "lodash";
import { useState } from "react";

function ToolboxItem({
  selected = false,
  onClick = () => {},
  children,
  title = "",
  style = {},
  dropdown = null,
  type = null,
  defaultValue = "#1677ff",
}) {
  const [color, setColor] = useState(defaultValue);
  const handleColorChange = debounce((newColor) => {
    onClick(newColor);
  }, 300);

  return (
    <div
      style={style}
      title={title}
      {...(type !== "colorPicker"
        ? {
            onClick:
              type === "clear"
                ? () => handleColorChange(null)
                : () => onClick(),
          }
        : { onClick: (e) => e.preventDefault() })}
      className={`toolbox-item ${selected && !type ? "selected" : ""}`}
    >
      {dropdown ? (
        <Dropdown {...dropdown}>
          <span onClick={(e) => e.preventDefault()}>{children}</span>
        </Dropdown>
      ) : type === "colorPicker" ? (
        <ColorPicker
          //   title="Text Color"
          onChange={(e) => {
            let newColor = typeof e === "string" ? e : e?.toHexString();
            setColor(newColor);
            handleColorChange(newColor);
          }}
          value={color}
          showText={() => children}
        />
      ) : (
        children
      )}
    </div>
  );
}

export default ToolboxItem;
