import { Form, Tooltip, Tour, message } from "antd";
import { useNavigate } from "react-router-dom";
import {
  AvatarImages,
  MondayButton,
  ProgressComponent,
  RichTextEditor,
  SortModalFields,
} from "../../../commonComponents";
import {
  BorderedTextCard,
  LabeledInput,
  NormalSizedModal,
} from "../../../SidebarPages/Fleet/components";
import { v4 as uuidv4 } from "uuid";
import "./ClientModal.scss";
import {
  ADDRESS_INFORMATION,
  body,
  COMPANY_INFORMATION,
  fieldsJSON,
  footerButtons,
} from "./clientModalData";
import { useMemo, useState } from "react";
import { BillingAndShippingAdd, RenderDynamicComponents } from "../Components";
import { compareIncluding } from "../../../SidebarPages/utils";
import { useEffect } from "react";
import {
  apiRoutes,
  fetchData,
  validateForm,
} from "../../../SidebarPages/Fleet/utils";
// import { createDriveFolders, updateDriveFolders } from "./utils";
// import { driveApi } from "../../../../integrations/DriveRequest";
import { API } from "aws-amplify";
import {
  fetchAllData,
  filterTables,
  getCognitosForNotification,
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
  updateTeamsConfiguration,
} from "../../../../utils";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import broadcastNotification from "../../../../helpers/controllers/broadcastNotification";
import { filterPeopleList } from "../../../SidebarPages/utils/filterPeopleList";
import { ContactModal } from "..";
import WarningModal from "../../../commonComponents/WarningModal/WarningModal";
// import { ReactComponent as CloseIcon } from "../../../SidebarPages/DynamicView/src/close.svg";
// import { ReactComponent as Tick } from "../../../pages/Settings/settingsComponents/Roles/src/Tick.svg";
import { WarningTriangle } from "src/components/SidebarPages/DynamicView/src";
import { PlusIcon } from "../../../../assets";
import { useCreateDriveFolders } from "../../../../hooks/useCreateDriveFolders";
import { DownCaretWhite } from "../../../../assets/icons";
// import { AnimatedCheckBox } from "../../../SidebarPages/Fleet/components/InputComponent/components";
// import { VideoTutorialIcon } from "../../components/ModalVideoTutorials/assets/svg";
import CustomModalHeader, {
  PlayVideoTutorial,
  tourTitle,
} from "../../../commonComponents/CustomModalHeader/CustomModalHeader";
import DynamicTeamModal from "../DynamicTeamModal/DynamicTeamModal";
import { XIcon } from "../../../SidebarPages/Communication/assets";
import { TransferIconNew } from "src/components/SidebarPages/DynamicView/src";
import {
  useDispatchProgramFields,
  useEditLogs,
  useProgressComponent,
} from "../../../../hooks";
import getStringErrorMessage from "./utils/getErrorMessage";
import { isEmpty } from "lodash";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";
// import EditModal from "../../../pages/Settings/settingsComponents/GlobalFilterKeys/actionComponents/EditModal";
import EditModal from "../../../pages/Settings/settingsComponents/ConfigPageTable/components/EditModal/EditModal";
import { getChangedData } from "../../../SidebarPages/Accounting/components/utilities";
// import { recordName } from "../../../commonComponents/NextSteps/helpers/constants";

const ClientModal = ({
  visible,
  setVisible,
  account = {},
  creatingOnLeadView = false,
  creatingOnProjectView = false,
  type: proppedType = "Client",
  refreshTable = () => {},
  onContactCreated = () => {},
  onCancel: proppedOnCancel = () => {},
  saveOnly,
  lead = {},
  preventContinue = false,
  setSubcontractor,
  title,
  isNextStep,
}) => {
  const {
    accountId,
    accountName,
    accountPhone = "",
    accountMobile = "",
    billingAddress: primaryAddress,
    shippingAddress: secondaryAddress,
    leadObject,
    opportunitiesObject,
  } = account;

  const {
    shippingAddApartmentNr: shippingAddressAptNr,
    aptNumber: billingAddressAptNr,
    leadWebsite,
    projectExecutive: leadProjectExecutive,
    leadSalePerson,
    googleDriveFolderIds,
    googleDriveUploads,
    phoneExtension,
    teamsConfiguration,
  } = lead;

  const [
    { authenticatedUser },
    { programFields },
    { hotCredentials },
    { accessToken },
    { isDarkMode },
  ] = useSelector((state) => [
    state.authenticatedUser,
    state.programFields,
    state.hotCredentials,
    state.accessToken,
    state.darkMode,
  ]);

  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
  } = useProgressComponent({ categoryName: "Clients", actionType: "Create" });

  const navigate = useNavigate();
  const [accounts, setAccounts] = useState([]);
  const [projectExecutives, setProjectExecutives] = useState([]);
  const [salesPerson, setSalesPerson] = useState([]);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [saving, setSaving] = useState(false);
  const [addressesCompKey, setAddressesCompKey] = useState(uuidv4());
  const [buildingManagementCategories, setBuildingManagementCategories] =
    useState([]);
  const [coreClientDriveFolderId, setCoreClientDriveFolderId] = useState();
  // const [centralClientDriveFolderId, setCentralClientDriveFolderId] =
  //   useState();
  const [coreLeadDriveFolderId, setCoreLeadDriveFolderId] = useState();
  // const [centralLeadDriveFolderId, setCentralLeadDriveFolderId] = useState();
  const [type, setType] = useState();
  const [description, setDescription] = useState();
  const [users, setUsers] = useState([]);
  const [subcontractorTypes, setSubcontractorTypes] = useState([]);
  const [contactToCreate, setContactToCreate] = useState();
  // const [selectedAccountName, setSelectedAccountName] = useState("");
  const [clientTeam, setClientTeam] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [leads, setLeads] = useState([]);
  const [prequalificationStages, setPrequalificationStages] = useState([]);
  const [customerUsers, setCustomerUsers] = useState([]);
  const [preQualificationDisabled, setPreQualificationDisabled] =
    useState(false);
  const [mandatoryPreQualificationStages, setMandatoryPreQualificationStages] =
    useState(false);
  // const [folderDriveId, setFolderDriveId] = useState();
  // const [subFolderDriveId, setSubFolderDriveId] = useState();
  const [fileNames, setFileNames] = useState([]);
  const [formChanged, setFormChanged] = useState(false);
  const [checkShabbat, setCheckShabbat] = useState(false);
  const [selectedSubcontractorType, setSelectedSubcontractorType] = useState({
    type: "",
    animated: false,
  });
  const [existingClientsOptions, setExistingClientsOptions] = useState([]);
  const [sortOpen, setSortOpen] = useState(false);
  const [sortFieldsObj, setSortFieldsObj] = useState(
    proppedType === "Client"
      ? programFields?.find(
          ({ fieldName }) => fieldName === "Modal Sorted Fields"
        )?.fieldOptions?.clientModal
      : programFields?.find(
          ({ fieldName }) => fieldName === "Modal Sorted Fields"
        )?.fieldOptions?.subcontractorModal || {}
  );
  const [subcontractorModal, setSubcontractorModal] = useState(false);

  const subcontractorTypeFields = useMemo(() => {
    return programFields.find((el) => el.fieldName === "Subcontractor Types");
  }, [programFields]);

  const { saveAddedLogs } = useEditLogs();
  const driveFunctions = useCreateDriveFolders("Clients");
  const { dispatchFields } = useDispatchProgramFields(
    subcontractorTypeFields?.fieldId,
    subcontractorTypeFields?.fieldName,
    true
  );

  // useDuplicatePrevention({
  //   doNotPrevent: !visible || !!contactToCreate,
  //   listOfRecords: accounts,
  //   filter: {
  //     accountName: selectedAccountName,
  //     accountRecordType: proppedType,
  //   },
  //   compareIncludingKeys: ["accountName", "accountRecordType"],
  //   onDuplication: () => {
  //     if (!visible) return
  //     message.warning(
  //       `There is an existing ${proppedType.toLowerCase()} with this information!`
  //     )
  //     form.resetFields(["accountName"])
  //     setSelectedAccountName("")
  //   },
  // })

  useEffect(() => {
    async function fetchTeams() {
      await API.get("teams", "/teams").then((r) => {
        setFormChanged(false);
        setClientTeam(r);
      });
    }
    fetchAllData({
      endpoint: "customerUsers",
      resultPosition: "customerUsers",
      resultId: "identityId ",
      otherStringParams: {
        allUsers: true,
      },
    })
      .then((res) => {
        setCustomerUsers(res);
      })
      .catch((err) => {
        console.error("err:", { err });
      });
    fetchTeams();
  }, []);

  // const driveRequest = driveApi({ accessToken });

  const [tourOpen, setTourOpen] = useState(false);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);
  const videoTutorialLink = programFields
    ?.filter((item) => item.fieldName === "Portal Video Tutorials")[0]
    ?.fieldOptions.find((item) => item.categoryName === "Sales")
    ?.subCategories[0].videos.find((item) =>
      proppedType === "Client"
        ? item.videoName === "New Client"
        : item.videoName === "New Subcontractor"
    )?.videoLink;

  const { driveParentId, leadDriveId } = {
    driveParentId: coreClientDriveFolderId,
    leadDriveId: coreLeadDriveFolderId,
  };

  const postErrorLog = async ({ error, reason }) => {
    const message = getStringErrorMessage(error, reason);
    const step = creatingOnProjectView
      ? "Project Conversion"
      : creatingOnLeadView
      ? "Lead Conversion"
      : "Account Create Error";

    saveAddedLogs({
      recordId: `${step} - ${dayjs().format("MM/DD/YYYY HH:mm:ss")}`,
      recordName: creatingOnProjectView
        ? account?.accountName
        : creatingOnLeadView
        ? lead?.leadCompany
        : "Account Create Error",
      actionType: "Error",
      category: "Account Modal Errors",
      topic: reason,
      currentData: { message, path: window.location.pathname, reason, step },
      previousData: {
        message: "-||-",
        path: "-||-",
        reason: "-||-",
        step: "-||-",
      },
      updatedKeys: ["message", "path", "reason", "step"],
    });
  };

  const [form] = Form.useForm();

  const user = `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`;

  const onSelect = (_, data) => {
    setSelectedTeam((prev) => [
      ...(prev || []),
      {
        value: data?.value,
        members: data?.members?.map((el) => el),
      },
    ]);
  };

  const openCLientTeamModal = () => {
    setOpen(true);
  };

  const onDeselect = (val, data) => {
    const teamName = data?.value || val;
    setSelectedTeam((prev) =>
      prev
        ?.filter((el) => el.value !== teamName)
        ?.map((el) => ({
          ...el,
          teamId: el.teamId?.filter((id) => id !== data?.teamId?.[0]) || [],
        }))
    );
    if (form.getFieldValue("teamClient").length < 1) {
      setSelectedTeam([]);
    }
  };

  const ClearOptions = () => {
    setOpen(false);
    // form.resetFields(["teamClient"]);
  };

  //gets the names and ids of the uploaded files
  const onPickerSuccess = ({ uploadedFiles, uploaderId }) => {
    let uploadedFileNames = [];
    for (const item of uploadedFiles) {
      const fileNameObj = { ...item, uploadedAt: dayjs() };
      uploadedFileNames.push(fileNameObj);
    }
    setFileNames(
      !![...fileNames]?.filter((item) =>
        compareIncluding(item.uploaderId, uploaderId)
      ).length
        ? [...fileNames]?.map((item) =>
            !compareIncluding(item?.uploaderId, uploaderId)
              ? item
              : {
                  ...item,
                  files: [...item?.files, ...uploadedFileNames],
                }
          )
        : [...fileNames, { uploaderId, files: [...uploadedFileNames] }]
    );
  };

  const preQualificationOnChange = (value) => {
    if (value === "Completed") {
      setMandatoryPreQualificationStages(true);
      setPreQualificationDisabled(false);
    } else if (value === "Not Required") {
      setMandatoryPreQualificationStages(false);
      setPreQualificationDisabled(true);
    } else {
      setMandatoryPreQualificationStages(false);
      setPreQualificationDisabled(false);
    }
  };

  const inputFields = fieldsJSON({
    type,
    checkShabbat,
    setCheckShabbat,
    accounts,
    form,
    leads,
    description,
    onSelect,
    creatingOnLeadView,
    creatingOnProjectView,
    onDeselect,
    phoneExtension,
    setExistingClientsOptions,
    existingClientsOptions,
    prequalificationStages,
    preQualificationOnChange,
    preQualificationDisabled,
    mandatoryPreQualificationStages,
    onPickerSuccess,
    parentId: driveFunctions?.parentFolderId,
    accessToken,
    fileNames,
    clientTeam: clientTeam?.map((team) => ({
      label: team.teamName,
      value: team.teamName,
      members: team.members?.map(
        ({ identityId, nameOfUser, cognitoUserId = "" }) => ({
          identityId,
          nameOfUser,
          cognitoUserId,
        })
      ),
    })),
    projectExecutives: projectExecutives?.map(
      ({ firstName, lastName }) => `${firstName} ${lastName}`
    ),
    salesPerson,
    setDescription,
    subcontractorTypes: subcontractorTypes?.map(({ statusName }) => statusName),
    buildingManagementCategories,
    proppedType,
    leadWebsite,
    setSelectedSubcontractorType,
    selectedSubcontractorType,
    setSubcontractorModal,
    customerPortalUsers: customerUsers,
    // setSelectedAccountName,
    ...account,
  });

  useEffect(() => {
    fetchData(apiRoutes?.projectExecutives)
      .then((r) => {
        setFormChanged(false);
        setProjectExecutives(r);
      })
      .catch((error) =>
        console.log("Error getting Project Executives: ", error)
      );
  }, []);

  const bodyObj = () =>
    body({
      form,
      type,
      description,
      checkShabbat,
    });

  const resetForm = () => {
    form.resetFields();
    setSaving(false);
    setAddressesCompKey(uuidv4());
    setDescription("");
    setVisible(false);
    driveFunctions?.reset();
  };
  //region SAVE
  const saveRecord = async (props = {}) => {
    let res;

    const key = "clientModalMsg";
    showLoadingMsg({ key });
    setSaving(true);

    let newLogObject = { recordId: "", recordName: "", category: "Accounts" };

    const teamsConfiguration = updateTeamsConfiguration(
      userConfiguration,
      selectedTeam
    );
    setSelectedTeam([]);
    setVisibleCreationProgress(true);

    updateProgressStatus({ updatingRecord: "executing" }); //start fetch api progress
    if (mandatoryPreQualificationStages === true) {
      await API.post(apiRoutes?.accounts, `/${apiRoutes?.accounts}`, {
        body: {
          ...bodyObj(),
          teamsConfiguration,
          leadRecordType: lead?.leadRecordType,
          phoneExtension: form.getFieldValue("phoneExtension") || "",
          googleDriveFolderIds: driveFunctions?.allFolders,
          googleDriveUploads: [
            ...(fileNames[0]?.files || []),
            ...(googleDriveUploads || []),
          ],
          leadId: account?.accountId,
        },
      })
        .then(async (r) => {
          setVisibleCreationProgress({ ...r, ...props }); // save datas to generate logs in case of any fail
          updateProgressStatus({ updatingRecord: "finished" });

          if (typeof setSubcontractor === "function") {
            setSubcontractor((prev) => [...prev, r]);
          }
          const { accountName, accountId } = r || {};

          newLogObject.recordId = r?.accountId;
          newLogObject.recordName = r?.accountName;
          saveAddedLogs(newLogObject);

          if ((!creatingOnLeadView && !!leadObject) || creatingOnLeadView) {
            await API.patch(
              apiRoutes.leads,
              `/${apiRoutes.leads}/${account?.accountId || lead?.leadId}`,
              {
                body: {
                  leadStatus: "Converted",
                  googleDriveUploads: [],
                },
              }
            ).then(async (r) => {
              saveAddedLogs({
                ...newLogObject,
                recordId: r?.leadId,
                recordName: r?.leadCompany,
                actionType: "Lead Conversion",
                category: "Leads",
              });

              const hideLoading = message.loading("Migrating Opportunities", 0);
              const opportunities = await filterTables(
                apiRoutes.opportunities,
                "leadId",
                account?.accountId || lead?.leadId
              );
              Promise.all(
                opportunities?.map((opportunity) => {
                  return API.patch(
                    apiRoutes.opportunities,
                    `/${apiRoutes.opportunities}/${opportunity.opportunityId}`,
                    {
                      body: {
                        accountId: accountId,
                      },
                    }
                  );
                })
              )
                .then(() => hideLoading())
                .catch(async (e) => {
                  console.error(e);
                  await postErrorLog({
                    error: e,
                    reason:
                      "Opportunities Migration Error (opportunities - patch)",
                  });
                })
                .finally(() =>
                  message.success("Opportunities Migrated Successfully")
                );
              hideLoading();
            });
          }

          updateProgressStatus({ sendingNotification: "executing" });
          broadcastNotification(
            "6",
            "onCreation",
            [
              { common: user },
              {
                userName: user,
                currentUser: authenticatedUser?.sub,
                cognitos: getCognitosForNotification(
                  userConfiguration,
                  teamsConfiguration
                ),
              },
            ],
            r.accountId
          ).then((notificationSent) => {
            updateProgressStatus({
              sendingNotification: !!notificationSent ? "finished" : "hasError",
            });
          });

          res = r;
          // resetForm();
          showSuccessMsg({ key });
          setTimeout(() => refreshTable([r]), 0);
          setAccounts((prev) => [...prev, r]);
        })
        .catch(async (e) => {
          updateProgressStatus({ updatingRecord: "hasError" });
          console.error(e);
          await postErrorLog({
            error: e,
            reason:
              "Failed to save Pre Qualification Account (accounts - Post)",
          });
          showErrorMsg();
        });
    } else {
      let allFolders = null;
      await driveFunctions[
        !isEmpty(googleDriveFolderIds) ? "update" : "create"
      ]({
        parentFolderName: form.getFieldValue("accountName").replace("&", ""),
        parentId: driveParentId,
        oldFolders: googleDriveFolderIds,
        updateFrom: "Leads",
      })
        .then(async ({ folders = {} }) => {
          allFolders = folders;
        })
        .catch(async (e) => {
          console.error(e);
          allFolders = !isEmpty(googleDriveFolderIds)
            ? googleDriveFolderIds
            : null;
          await postErrorLog({
            error: e,
            reason: isEmpty(googleDriveFolderIds)
              ? "Error Creating Folders"
              : "Error Updating Folders",
          });
          showErrorMsg();
        });

      await API.post(apiRoutes?.accounts, `/${apiRoutes?.accounts}`, {
        body: {
          ...bodyObj(),
          teamsConfiguration,
          leadRecordType: lead?.leadRecordType,
          phoneExtension: form.getFieldValue("phoneExtension") || "",
          googleDriveFolderIds: {
            ...(allFolders || {}),
          },
          googleDriveUploads: [
            ...(fileNames[0]?.files || []),
            ...(googleDriveUploads || []),
          ],
          leadId: account?.accountId,
        },
      })
        .then(async (r) => {
          setVisibleCreationProgress({ ...r, ...props }); // save datas to generate logs in case of any fail
          updateProgressStatus({ updatingRecord: "finished" });

          if (typeof setSubcontractor === "function") {
            setSubcontractor((prev) => [...prev, r]);
          }
          const { accountName, accountId } = r || {};

          newLogObject.recordId = r?.accountId;
          newLogObject.recordName = r?.accountName;
          await saveAddedLogs(newLogObject);

          if ((creatingOnLeadView && !!leadObject) || creatingOnLeadView) {
            await API.patch(
              apiRoutes.leads,
              `/${apiRoutes.leads}/${account?.accountId || lead?.leadId}`,
              {
                body: {
                  leadStatus: "Converted",
                },
              }
            )
              .then(async (r) => {
                saveAddedLogs({
                  ...newLogObject,
                  recordId: r?.leadId,
                  recordName: r?.leadCompany,
                  actionType: "Lead Conversion",
                  category: "Leads",
                });

                const hideLoading = message.loading(
                  "Migrating Opportunities",
                  0
                );
                const opportunities = await filterTables(
                  apiRoutes.opportunities,
                  "leadId",
                  account?.accountId || lead?.leadId
                );
                Promise.all(
                  opportunities?.map((opportunity) => {
                    return API.patch(
                      apiRoutes.opportunities,
                      `/${apiRoutes.opportunities}/${opportunity.opportunityId}`,
                      { body: { accountId: accountId } }
                    );
                  })
                )
                  .then(() => hideLoading())
                  .catch(async (e) => {
                    await postErrorLog({
                      error: e,
                      reason:
                        "Opportunities Migration Error (opportunities - patch)",
                    });
                  })
                  .finally(() =>
                    message.success("Opportunities Migrated Successfully")
                  );
                hideLoading();
              })
              .catch(async (e) => {
                console.error(e);
                await postErrorLog({
                  error: err,
                  reason: "Updating Lead Status Failed (leads - patch)",
                });
              });
          }

          updateProgressStatus({ sendingNotification: "executing" });
          broadcastNotification(
            "6",
            "onCreation",
            [
              { common: user },
              { userName: user, currentUser: authenticatedUser?.sub },
            ],
            r.accountId
          ).then((notificationSent) => {
            updateProgressStatus({
              sendingNotification: !!notificationSent ? "finished" : "hasError",
            });
          });
          res = r;
          // resetForm();
          showSuccessMsg({ key });
          setTimeout(() => refreshTable([r]), 0);
          setAccounts((prev) => [...prev, r]);
        })
        .catch(async (e) => {
          updateProgressStatus({ updatingRecord: "hasError" });
          console.error(e);
          showErrorMsg();
          await postErrorLog({ error: e, reason: "Account Post Failed" });
        });
    }
    message.destroy("projectDriveFolders");
    message.destroy("windowMessages");

    setSaving(false);

    return res;
  };

  const validateClientForm = async (onSuccessFunc) => {
    const accountName = form.getFieldValue("accountName") || "";
    if (accountName?.replace(/\s/g, "").length >= 3) {
      validateForm(form, onSuccessFunc, () => {}, ["accountName"]);
    } else {
      message.error("Company Name needs to be more than 3 characters long");
    }
  };
  const onFinish = async (val) => {
    if (val) {
      await saveRecord({ doNotCreateContact: true }).then(() =>
        setVisible(false)
      );
    } else {
      await saveRecord().then(() => setVisible(false));
    }
  };

  const onSaveAndContinue = async () => {
    await saveRecord({ doNotCreateContact: true, navigateThen: true });
  };

  const onCancel = () => {
    driveFunctions.delete();
    proppedOnCancel();
    setVisible(false);
    setCancelModalVisible(false);
    resetForm();
  };

  const onEnterPress = (event) => {
    if (event.charCode === 13) {
      onCancel();
    }
  };

  const onOptionClick = ({ key }) => {
    let tmpKey = preventContinue ? "Finish" : "Save & Close";

    compareIncluding(key, tmpKey)
      ? validateClientForm(() => {
          preventContinue ? onSaveAndContinue() : onFinish(true);
        })
      : validateClientForm(() => {
          saveRecord();
        });
  };

  const formatPhoneNr = (phoneNr = "") =>
    (phoneNr.includes("+1")
      ? phoneNr.replace("+1", "")
      : phoneNr.replace("+", "")
    ).trim();

  useEffect(() => {
    const programFieldsObj =
      programFields?.length &&
      programFields.reduce(
        (acc, { fieldName, fieldOptions }) => ({
          ...acc,
          [fieldName]: fieldOptions,
        }),
        {}
      );
    setUsers(filterPeopleList(userConfiguration?.allUsers?.Items));
    if (programFieldsObj) {
      setSubcontractorTypes(programFieldsObj?.["Subcontractor Types"]);
      setBuildingManagementCategories(
        programFieldsObj?.["Building Management Categories"]
      );
      setSalesPerson(programFieldsObj?.["Sales person"]);
      setPrequalificationStages(programFieldsObj?.["Prequalification Stages"]);
    }
  }, [programFields, userConfiguration]);

  let companyInfoFields = inputFields[1]?.fields;

  function filterFields(allFields) {
    if (proppedType === "Client") {
      const tmp = [...allFields.slice(0, -2)].sort((a, b) => {
        if (Object.keys(sortFieldsObj || {}).length === 0) {
          return 0;
        }
        const aIndex = sortFieldsObj[a.formItemName];
        const bIndex = sortFieldsObj[b.formItemName];
        return aIndex - bIndex;
      });
      return [...tmp, ...allFields.slice(-2)];
    } else {
      let tmp = allFields.sort((a, b) => {
        if (Object.keys(sortFieldsObj).length === 0) {
          return 0;
        }
        const aIndex = sortFieldsObj[a.formItemName];
        const bIndex = sortFieldsObj[b.formItemName];
        return aIndex - bIndex;
      });
      return tmp;
    }
  }

  const sortedFields = useMemo(
    () => filterFields(companyInfoFields),
    [companyInfoFields, sortFieldsObj]
  );

  useEffect(() => {
    const formattedCredentials =
      hotCredentials?.length &&
      hotCredentials?.reduce(
        (acc, { credentialKey, credentialValue }) => ({
          ...acc,
          [credentialKey]: credentialValue,
        }),
        []
      );
    if (formattedCredentials) {
      setCoreClientDriveFolderId(
        formattedCredentials?.["CORE/CLIENT PARENT FOLDER"]
      );
      setCoreLeadDriveFolderId(
        formattedCredentials?.["CORE/LEAD PARENT FOLDER"]
      );
    }
    // setCentralClientDriveFolderId(
    //   formattedCredentials["CENTRAL/CLIENT PARENT FOLDER"]
    // );
    // setCentralLeadDriveFolderId(
    //   formattedCredentials["CENTRAL/LEAD PARENT FOLDER"]
    // );
  }, [hotCredentials]);

  useEffect(() => {
    async function fetchEffectData() {
      await fetchData("accounts")
        .then((res) => {
          setFormChanged(false);
          setAccounts(() => {
            return res;
          });
          setExistingClientsOptions(
            res?.map(({ accountName, accountId }) => ({
              label: (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {accountName}
                  <a href={`/accounts/${accountId}`} target="_blank">
                    Go To
                  </a>
                </div>
              ),
              value: accountName,
              disabled: true,
            }))
          );
        })
        .catch((error) => console.log("Error getting accounts: ", error));
      await fetchData("leads")
        .then((r) => {
          setFormChanged(false);
          setLeads(r);
        })
        .catch((error) => console.log("Error getting Leads: ", error));
    }
    fetchEffectData().catch((error) =>
      console.log("Error Effect fetch data: ", error)
    );
  }, []);

  useEffect(() => {
    setType(proppedType);
  }, [proppedType]);

  useEffect(() => {
    if (!!accountName && (!!lead || !!account)) {
      const oldValues = form.getFieldsValue();
      const dataToAdd = {
        ...account,
        primaryAddress,
        secondaryAddress,
        accountPhone: formatPhoneNr(accountPhone || lead?.leadPhone),
        accountMobile: formatPhoneNr(accountMobile || lead?.leadMobile),
        projectExecutive:
          account?.projectExecutive || lead?.projectExecutive || undefined,
        leadSalePerson:
          account?.leadSalePerson || lead?.leadSalePerson || undefined,
        phoneExtension,
        website: lead?.leadWebsite || account?.leadWebsite,
        jewishClient: lead?.jewishClient || account?.jewishClient,
        teamClient: lead?.teamsConfiguration || account?.teamsConfiguration,
        preQualificationStage: lead?.preQualificationStage || undefined,
        preQualificationWebsite: lead?.preQualificationWebsite || undefined,
        preQualificationDate: !!lead?.preQualificationDate
          ? dayjs(lead?.preQualificationDate)
          : undefined,
      };
      form.setFieldsValue({
        ...oldValues,
        ...dataToAdd,
      });
      preQualificationOnChange(lead?.preQualificationStage);
      if (dataToAdd?.teamClient?.length > 0) {
        form.setFieldsValue({ teamClient: dataToAdd?.teamClient?.[0]?.value });
        setSelectedTeam(dataToAdd?.teamClient);
        // onSelect(null, dataToAdd?.teamClient);
      }
    }
  }, [account, lead]);

  useEffect(() => {
    const compName = form.getFieldValue("accountName");

    !!compName &&
    mandatoryPreQualificationStages &&
    !!driveParentId &&
    compName?.replace(/\s+/g, "").length > 3 &&
    !driveFunctions?.parentFolderId
      ? driveFunctions[!!googleDriveFolderIds ? "update" : "create"]({
          parentFolderName: form.getFieldValue("accountName").replace("&", ""),
          parentId: driveParentId,
          oldFolders: googleDriveFolderIds,
          updateFrom: "Leads",
          googleDriveUploads,
        }).then(({ folders }) => {
          // setFolderDriveId(folderId);
          // setSubFolderDriveId(subFolderId);
          // console.log("folderId", { folderId, subFolderId });
        })
      : mandatoryPreQualificationStages &&
        message.error(
          "Enter a valid company name and select completed stage again",
          3
        );
  }, [mandatoryPreQualificationStages]);

  const steps =
    programFields
      ?.find(({ fieldName }) => fieldName === "Tutorials Steps")
      ?.fieldOptions?.find(({ categoryName }) =>
        proppedType === "Client"
          ? categoryName === "clientModal"
          : categoryName === "subcontractorModal"
      )?.steps || [];
  // console.log("programFields: ", programFields.find(el => el.fieldName === "Subcontractor Types"));
  // console.log("subcontractorTypes: ", subcontractorTypes);
  function stepsMapHelper(title) {
    const stepsMap = {
      "Required Fields": document.querySelector(".companyName"),
      "Company Name": document.querySelector(".companyName"),
      "Pre-qualification Stages": document.querySelector(
        ".client-pre-qualification-stages"
      ),
      "Add more information": document.querySelector(".clientBorderedTextCard"),
      "Add Billing and Shipping Address": document.querySelector(
        ".billingBorderedTextCard"
      ),
      "Copy Billing Address": document.querySelector(".copyIconDiv"),
      "Street View Address": document.querySelector(".billingAddIcon"),
      "Create Client": document.querySelector(".saveButton"),
      "Subcontractor Type": document.querySelector(".subcontractorType"),
      "Create Subcontractor": document.querySelector(".saveButton"),
    };
    return stepsMap[title] || null;
  }

  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        title: tourTitle(step?.title, () => {
          setShowVideoTutorial(true);
          setTourOpen(false);
        }),
        description: step?.description,
        target: () => stepsMapHelper(step?.title),
        className: `custom-tour-light`,
      };
    });
    return newSteps;
  }

  function onSubcontractorTypeCreation(newData) {
    message.loading({
      key: "typeCreation",
      content: "Creating Type...",
      duration: 0,
    });

    const result = getChangedData(newData, {});

    const newLogInfo = {
      recordId: newData.statusId,
      recordName: newData.statusName,
      actionType: "Creation",
      category: "SubcontractorTypes",
      previousData: result.prev,
      currentData: result.curr,
      nameOfUser: userConfiguration?.nameOfUser,
      cognitoUserId: userConfiguration?.cognitoUserId,
      updatedKeys: [],
    };

    const fieldOptions = [newData, ...subcontractorTypes];
    try {
      API.patch(
        "programFields",
        `/programFields/${subcontractorTypeFields?.fieldId}`,
        {
          body: {
            fieldOptions,
          },
        }
      );

      saveAddedLogs(newLogInfo);
      dispatchFields(fieldOptions);
      message.success({
        key: "typeCreation",
        content: "Subcontractor Type created successfully",
        duration: 1.8,
      });
    } catch (error) {
      console.log("Error adding subcontractor type: type");
      message.error({
        key: "typeCreation",
        content: "There was a problem creating this subcontractor type",
        duration: 1.8,
      });
    }
  }

  const tourSteps = mapRefs(steps);

  return (
    <>
      <NormalSizedModal
        visible={visible}
        // title={`New ${proppedType}`}
        title={
          <CustomModalHeader
            title={`New ${proppedType}`}
            onClick={() => {
              setTourOpen(true);
            }}
            noX={true}
            headerInfo={() => {
              return (
                userConfiguration?.groupName === "Admin" && (
                  <Tooltip title="Sort Fields">
                    <TransferIconNew
                      {...{
                        className: "sortFieldsIcon",
                        onClick: () => {
                          setSortOpen(true);
                        },
                      }}
                    />
                  </Tooltip>
                )
              );
            }}
          />
        }
        className={`clientModalContainer ${
          isDarkMode && "clientModalContainerDark"
        }`}
        onCancel={() => {
          if (formChanged) {
            setCancelModalVisible(true);
          } else {
            onCancel();
          }
        }}
        newFooterDesign={footerButtons({
          saving,
          onCancel: () => {
            if (formChanged) {
              setCancelModalVisible(true);
            } else {
              onCancel();
            }
          },
          onSave: () =>
            form.validateFields().then(() =>
              validateClientForm(() => {
                preventContinue ? onFinish() : onSaveAndContinue();
              })
            ),
          onOptionClick,
          suffix: <DownCaretWhite />,
          saveOnly,
          preventContinue,
        })}
        centered
        data-testid="client-modal"
      >
        <Form
          form={form}
          onFieldsChange={() => {
            setFormChanged(true);
          }}
        >
          <div className="clientModalBodyContainer">
            <BorderedTextCard
              title={COMPANY_INFORMATION}
              className="clientBorderedTextCard"
            >
              {RenderDynamicComponents(sortedFields)}
              <div className="clientAvatarsDiv">
                {!!selectedTeam?.length && (
                  <MondayButton
                    Icon={<PlusIcon />}
                    onClick={openCLientTeamModal}
                    className="membersButton"
                  >
                    Members
                  </MondayButton>
                )}
                <div className="clientAvatars">
                  <AvatarImages
                    {...{
                      cognitoUserIds: selectedTeam
                        .reduce((acc, { members }) => [...acc, ...members], [])
                        .map(({ cognitoUserId }) => cognitoUserId),
                    }}
                  />
                </div>
              </div>
              <LabeledInput
                label="Description"
                style={{ height: 150, width: "100%" }}
              >
                <RichTextEditor
                  {...{
                    name: "Description",
                    onChange: (value) => setDescription(value),
                    className: "ignore-onClickoutside",
                    style: { width: "100%" },
                  }}
                />
              </LabeledInput>
            </BorderedTextCard>
            <BillingAndShippingAdd
              {...{
                form,
                disabled: !!primaryAddress,
                billingAddress: primaryAddress,
                shippingAddress: secondaryAddress,
                shippingAddressAptNr: shippingAddressAptNr,
                billingAddressAptNr: billingAddressAptNr,
                key: addressesCompKey,
                title: ADDRESS_INFORMATION,
                mapClassName: "clientModalBodyContainer-splash",
              }}
            />
          </div>
        </Form>
      </NormalSizedModal>
      <WarningModal
        visible={cancelModalVisible}
        setVisible={setCancelModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        onKeyPress={(e) => onEnterPress(e)}
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningTriangle />
          </span>
          <p>Are you sure you want to cancel?</p>
          <div className="buttons">
            <MondayButton
              onClick={() => setCancelModalVisible(false)}
              Icon={<XIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton
              onClick={onCancel}
              Icon={<TickIcon width={19} height={19} />}
            >
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
      <div>
        {open && (
          <DynamicTeamModal
            {...{
              open,
              setOpen,
              selectedTeam,
              ClearOptions,
              setSelectedTeam,
              proppedAllTeams: clientTeam,
              onSave: (data) => {
                form.setFieldValue("teamClient", data || []);
              },
            }}
          />
        )}
      </div>
      {!!contactToCreate && (
        <ContactModal
          saveOnly
          {...{
            accountUpdate: accounts,
            visible: !!contactToCreate,
            setVisible: () => setContactToCreate(""),
            contact: contactToCreate,
            refreshTable: onContactCreated,
          }}
        />
      )}
      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={tourSteps}
          mask={{ color: "#2a2b3a71" }}
        />
      )}
      {showVideoTutorial && (
        <PlayVideoTutorial
          {...{
            visible: showVideoTutorial,
            setVisible: setShowVideoTutorial,
            url: videoTutorialLink,
            title: `Create ${proppedType} Tutorial`,
          }}
        />
      )}
      {sortOpen && (
        <SortModalFields
          {...{
            visible: sortOpen,
            setVisible: setSortOpen,
            title:
              proppedType === "Client" ? "Client Modal" : "Subcontractor Modal",
            rowData:
              proppedType === "Client"
                ? sortedFields?.slice(0, -2)
                : sortedFields,
            onSave: (newSort) => {
              setSortFieldsObj(newSort);
            },
            saveKey:
              proppedType === "Client" ? "clientModal" : "subcontractorModal",
          }}
        />
      )}

      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: "Clients",
            actionType: "Create",
            visibleCreationProgress,
            creationProgresses,
            closeModal: () => {
              const { navigateThen, doNotCreateContact, ...createdClient } =
                visibleCreationProgress;
              const { accountId } = createdClient;

              setVisibleCreationProgress(false);
              resetForm();

              if (!doNotCreateContact && !leadObject) {
                setContactToCreate({
                  ...createdClient,
                  accountEmail: undefined,
                  contactType: `${type} Contact`,
                });
              }

              navigateThen &&
                !!!isNextStep &&
                navigate(`/accounts/${accountId}`);
            },
          }}
        />
      )}
      {subcontractorModal && (
        <EditModal
          editModal={subcontractorModal}
          setEditModal={setSubcontractorModal}
          data={subcontractorTypes}
          setData={setSubcontractorTypes}
          manualSave={onSubcontractorTypeCreation}
          categories={false}
          hasKeyColor={true}
          selectedData={false}
          getPrevOrCurr={() => {}}
          setSelectedData={() => {}}
          createLog={() => {}}
        />
      )}
    </>
  );
};

export default ClientModal;
