import "./styles/TaskDatesInput.scss";
import React from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Form, message } from "antd";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import { TimeIcon } from "../../../../../../../icons";
import { tzDate } from "src/components/SidebarPages/Fleet/components/QBDateInput/helpers";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { useResponsive } from "../../../../../../../hooks";
import { handleDisableData } from "./handleDisableData";
import { updateDependantDates } from "./updateDependantDates";

export const TaskDatesInput = ({ form }) => {
  const [
    taskStartDate,
    taskEndDate,
    taskDeadline,
    taskStartHour,
    taskEndHour,
    taskDeadlineHour,
  ] = [
    Form.useWatch("taskStartDate", form) || "",
    Form.useWatch("taskEndDate", form) || "",
    Form.useWatch("taskDeadline", form) || "",
    Form.useWatch("taskStartTime", form) || "",
    Form.useWatch("taskEndTime", form) || "",
    Form.useWatch("taskDeadlineTime", form) || "",
  ];

  const startDate =
    tzDate(`${taskStartDate} ${taskStartHour}`).valueOf() ||
    dayjsNY().startOf("D").valueOf();

  const endDate =
    tzDate(`${taskEndDate} ${taskEndHour}`).valueOf() ||
    dayjsNY().startOf("D").add(30, "minutes").valueOf();

  const deadlineDate =
    tzDate(`${taskDeadline} ${taskDeadlineHour}`).valueOf() ||
    dayjsNY().endOf("D").valueOf();

  const startDisplay =
    tzDate(taskStartDate, "MM/DD/YYYY")?.format("dddd, MMMM D") ||
    dayjsNY().format("dddd, MMMM D");

  const endDisplay =
    tzDate(taskEndDate, "MM/DD/YYYY")?.format("dddd, MMMM D") ||
    dayjsNY().format("dddd, MMMM D");

  const deadlineDisplay =
    tzDate(taskDeadline, "MM/DD/YYYY")?.format("dddd, MMMM D") ||
    dayjsNY().format("dddd, MMMM D");

  return (
    <div className="task-dates-section">
      {[
        {
          title: "Start Date",
          dateKey: "taskStartDate",
          timeKey: "taskStartTime",
          validTime: "future",
          dateVal: startDate,
          // dependantDates: ["taskEndDate", "taskDeadline"],
          // dependantTimes: ["taskEndTime", "taskDeadlineTime"],
          displayDate: startDisplay,
        },
        {
          title: "End Date",
          dateKey: "taskEndDate",
          timeKey: "taskEndTime",
          validTime: "future",
          dateVal: endDate,
          validationDate: "taskStartDate",
          compareWith: {
            compareDate: startDate,
            errorMsg: "End Time cannot be before Start Time",
          },
          // dependantDates: ["taskDeadline"],
          // dependantTimes: ["taskDeadlineTime"],
          displayDate: endDisplay,
        },
        {
          title: "Deadline",
          dateKey: "taskDeadline",
          timeKey: "taskDeadlineTime",
          dateVal: deadlineDate,
          validationDate: "taskEndDate",
          validTime: "future",
          compareWith: {
            compareDate: endDate,
            errorMsg: "Deadline cannot be before End Time",
          },
          displayDate: deadlineDisplay,
        },
      ].map((props, i) => (
        <TaskDate key={i} {...{ form, ...props }} />
      ))}
    </div>
  );
};

const TaskDate = ({
  form,
  title,
  dateKey,
  timeKey,
  validTime,
  validationDate,
  // dateVal,
  compareWith = {},
  // dependantDates = [],
  // dependantTimes = [],
  displayDate,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { compareDate = "", errorMsg = "" } = compareWith;
  const { mobile } = useResponsive();

  // const updateDependantDates = ({ parsedValue }) => {
  //   !!form.getFieldValue(timeKey) && form.resetFields([timeKey]);
  //   // if (dependantDates?.length) {
  //   //   const formValues = form.getFieldsValue();
  //   //   dependantDates.map((key) => {
  //   //     const { [key]: value } = formValues;

  //   //     if (
  //   //       !!value &&
  //   //       tzDate(value, "MM/DD/YYYY")?.valueOf() <
  //   //         tzDate(parsedValue, "MM/DD/YYYY")?.valueOf()
  //   //     ) {
  //   //       form.setFieldValue(key, parsedValue);
  //   //       form.setFieldValue(
  //   //         `datePicker${key}`,
  //   //         tzDate(parsedValue, "MM/DD/YYYY")
  //   //       );
  //   //     } else if (!value) {
  //   //       form.setFieldValue(key, parsedValue);
  //   //       form.setFieldValue(
  //   //         `datePicker${key}`,
  //   //         tzDate(parsedValue, "MM/DD/YYYY")
  //   //       );
  //   //     }
  //   //   });
  //   // }
  //   // if (dependantTimes?.length) {
  //   //   const formValues = form.getFieldsValue();
  //   //   const timeValue = formValues[timeKey];
  //   //   dependantTimes.map((key) => {
  //   //     const { [key]: value } = formValues;
  //   //     if (
  //   //       !!value &&
  //   //       tzDate(value, "hh:mm A")?.valueOf() <
  //   //         tzDate(timeValue, "hh:mm A")?.valueOf()
  //   //     ) {
  //   //       form.setFieldValue(key, timeValue);
  //   //     } else if (!value) {
  //   //       form.setFieldValue(key, timeValue);
  //   //     }
  //   //   });
  //   // }
  // };

  const updateDependantTimes = ({ parsedValue }) => {
    // if (dependantTimes?.length) {
    //   const formValues = form.getFieldsValue();
    //   dependantTimes.map((key) => {
    //     const { [key]: value } = formValues;
    //     if (
    //       !!value &&
    //       tzDate(value, "hh:mm A")?.valueOf() <
    //         tzDate(parsedValue, "hh:mm A")?.valueOf()
    //     ) {
    //       form.setFieldValue(key, parsedValue);
    //     }
    //   });
    // }

    const startTime = form.getFieldValue("taskStartTime");
    const endTime = form.getFieldValue("taskEndTime");
    const deadLineTime = form.getFieldValue("taskDeadlineTime");

    const startDate = form.getFieldValue("taskStartDate");
    const endDate = form.getFieldValue("taskEndDate");
    const deadLine = form.getFieldValue("taskDeadline");

    const startDay = startDate ? dayjsNY(startDate).startOf("day") : null;
    const endDay = endDate ? dayjsNY(endDate).startOf("day") : null;
    const deadlineDay = deadLine ? dayjsNY(deadLine).startOf("day") : null;

    // Validation logic
    if (startDay && endDay && startDay.isSame(endDay)) {
      if (dayjsNY(startTime, "hh:mm A").isAfter(dayjsNY(endTime, "hh:mm A"))) {
        message.error(
          "Start time must be earlier than end time on the same day."
        );
        if (timeKey === "taskStartTime") {
          form.resetFields(["taskStartTime"]);
        } else {
          form.resetFields(["taskEndTime"]);
        }
        return;
      }
    }

    if (startDay && deadlineDay && startDay.isSame(deadlineDay)) {
      if (
        dayjsNY(startTime, "hh:mm A").isAfter(dayjsNY(deadLineTime, "hh:mm A"))
      ) {
        message.error(
          "Start time must be earlier than deadline time on the same day."
        );

        if (timeKey === "taskStartTime") {
          form.resetFields(["taskStartTime"]);
        } else {
          form.resetFields(["taskDeadlineTime"]);
        }

        return;
      }
    }

    if (endDay && deadlineDay && endDay.isSame(deadlineDay)) {
      if (
        dayjsNY(deadLineTime, "hh:mm A").isBefore(dayjsNY(endTime, "hh:mm A"))
      ) {
        message.error(
          "Deadline time must be earlier or equal to end time on the same day."
        );

        if (timeKey === "taskEndTime") {
          form.resetFields(["taskEndTime"]);
        } else {
          form.resetFields(["taskDeadlineTime"]);
        }

        return;
      }
    }
  };

  const dates = {
    taskStartTime: form.getFieldValue("taskStartDate"),
    taskEndTime: form.getFieldValue("taskEndDate"),
    taskDeadlineTime: form.getFieldValue("taskDeadline"),
  };

  return (
    <div className={dateKey}>
      <span className="durationSpan">{title}</span>
      <div className="durationContent">
        <div className="durationEnd">
          <div
            className={`durationEnd-container ${
              mobile && "durationEnd-container-mobile"
            }`}
          >
            <TimeIcon
              id={dateKey === "taskStartDate" ? "greenTime" : "redTime"}
            />
            {displayDate},
          </div>
          <div
            className={`duration-end-input ${
              mobile && "duration-end-input-mobile"
            }`}
          >
            <InputComponent
              {...{
                form,
                formItemName: timeKey,
                validTime,
                customOnChange: (time) => {
                  return updateDependantTimes({ parsedValue: time });
                },
                showLabel: false,
                dateField: dateKey,
                customClassName: "task-date-input",
                required: false,
                type: "timeinput",
                disabled: dates[timeKey] === undefined,
                defaultValue: "12:00 AM",
              }}
            />
            <InputComponent
              {...{
                form,
                formItemName: dateKey,
                showLabel: false,
                customClassName: "task-date-input",
                className: "task-date-input",
                type: "dateinput",
                required: false,
                isDarkMode,
                validTime,
                validationDate:
                  tzDate(
                    form.getFieldValue(validationDate) || "",
                    "MM/DD/YYYY"
                  ).isValid() && !!form.getFieldValue(validationDate)
                    ? tzDate(form.getFieldValue(validationDate), "MM/DD/YYYY")
                        ?.startOf("D")
                        .valueOf()
                    : tzDate(form.getFieldValue("taskStartDate"), "MM/DD/YYYY")
                        ?.startOf("D")
                        .valueOf(),

                customOnChange: (date) => {
                  return updateDependantDates({
                    form,
                    timeKey,
                  });
                },
                hasDatePicker: true,
                disabledDate: (current) => {
                  return handleDisableData({ current, form, dateKey });
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
