import { useState, useEffect } from "react";
import { message } from "antd";
import { useSelector } from "react-redux";
import { uniqBy } from "lodash";
import IndividualLoader from "../../../IndividualLoader";
import { InputComponent } from "../../../SidebarPages/Fleet/components";
import { updateRecordLabels } from "../../../SidebarPages/TaskManager/Tasks/TasksModals/NewTask/helpers";
import { fetchAllData, filterRowDataByTeamConfig } from "../../../../utils";
import { camelCaseToNormalText } from "../../RowDataGridModal/components/utils";
import { getUpdatedResult } from "./utils/getUpdatedResult";

/**
  @param {Object} selectedTopic - Object for current category, selectedTopic should contain these keys: apiName, primaryKey
  @param {Function} onChange - Function that returns object of seleted record 
  @param {String} defaultValue - OPTIONAL - Default value for option
  @param {String} formItemName - OPTIONAL - Form item name if it has one
  @param {Array} recordIdsToExclude - OPTIONAL - Array with recordId's to remove from option
  @param {Function} onClear - OPTIONAL - Function to clear some state or form item 
  @param {String} label - OPTIONAL - Label of option
  @param {String} modifyOptionLabel - OPTIONAL - If you want to modify label of select options 
*/
const FetchedOptions = ({
  selectedTopic,
  onChange,
  defaultValue,
  formItemName,
  recordIdsToExclude = [],
  onClear,
  label = "Task Related To",
  modifyOptionLabel = true,
  tooltipCategory,
  tooltipKey,
  modifyValue = false,
}) => {
  const { categoryName, apiName, primaryKey, rowName, otherName, keysToUse } =
    selectedTopic;

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [optionsLength, setOptionsLength] = useState(50);

  useEffect(() => {
    handleFetchData(); //used to call in mount to display some records
  }, [apiName, rowName]);

  const handleFetchData = async () => {
    setLoading(true);
    setRecords([]);

    try {
      const result = await fetchAllData({
        endpoint: apiName,
        resultId: primaryKey,
        otherStringParams: {
          getMaxLimit: "true",
          keysToInclude: JSON.stringify(
            [
              primaryKey,
              rowName,
              otherName,
              keysToUse?.includes("teamsConfiguration") && "teamsConfiguration",
            ].filter(Boolean)
          ),
        },
      });

      const updatedResult = await getUpdatedResult(categoryName, result);

      const records = Array.isArray(updatedResult)
        ? updatedResult
        : updatedResult[apiName];

      let updatedRecords = (
        keysToUse?.includes("teamsConfiguration")
          ? filterRowDataByTeamConfig(userConfiguration, records)
          : records
      ).filter((row) => {
        return typeof row[rowName] === "string"
          ? !!row[rowName].trim()
          : !!row[rowName];
      });

      if (recordIdsToExclude.length) {
        updatedRecords = updatedRecords.filter(
          (row) => !recordIdsToExclude.includes(row[primaryKey])
        );
      }

      setRecords(
        uniqBy(
          updateRecordLabels({
            records: updatedRecords,
            topic: selectedTopic,
            modifyOptionLabel,
            modifyValue,
          }),
          "recordName"
        )
      );

      setLoading(false);
    } catch (error) {
      message.error(
        "Sorry, we couldn't retrieve data at the moment. Please try again later."
      );
      console.error("Error Fetching data", error);
    }
  };

  const handlePopupScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      setOptionsLength((prev) => (prev += 25));
    }
  };

  return (
    <InputComponent
      key={rowName}
      {...{
        style: { width: "100%" },
        ...(formItemName && { label }),
        ...(formItemName && { formItemName }),
        ...(formItemName && { noFormItem: !formItemName }),
        placeholder: `Select ${camelCaseToNormalText(rowName)}`,
        type: "select",
        defaultValue,
        customOptions: records.slice(0, optionsLength),
        className: "fetched-options-component",
        dropdownClassName: isDarkMode && "darkDropDown",
        onChange,
        tooltipCategory: tooltipCategory || categoryName,
        tooltipKey: tooltipKey || formItemName,
        onPopupScroll: handlePopupScroll,
        onSearch: (e) => {
          setOptionsLength(!!e ? records?.length : 50);
        },
        dropdownRender: (menu) =>
          loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "20px",
              }}
            >
              {!!loading ? <IndividualLoader /> : <span>No data found!</span>}
            </div>
          ) : (
            menu
          ),
        allowClear: !!onClear,
        onClear: () => {
          typeof onClear === "function" && onClear();
        },
      }}
    />
  );
};

export default FetchedOptions;
