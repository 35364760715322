import {
  DirectionsRenderer,
  GoogleMap,
  Marker,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "../../../../helpers/constants";
import { useEffect, useRef, useState } from "react";
import { hospitalMarker } from "../../Fleet/assets";
import { POLYLINE_OPTIONS } from "../../Fleet/Dispatch/modals/NewDispatchModal/components/DispatchItineraryModal/ItineraryStepClass";
import { MARKER_ICON_URL } from "../../Fleet/fleetsLive/data";
import { NYC_CENTER } from "../../../commonComponents/Map/mapData";

const libraries = ["places"];

const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

function NearbyPlaces({ coordinates, addressName, urgentCares, showMap }) {
  const { isLoaded } = useLoadScript({
    libraries,
    googleMapsApiKey: GOOGLE_API_KEY,
  });

  const [map, setMap] = useState(null);

  const pinAnchor = useRef(new google.maps.Point(20, 40));
  const mapRef = useRef(null);
  const placesServices = useRef(null);
  // const directionService = useRef(new google.maps.DirectionsService());
  // const directionsRenderer = useRef(
  //   new google.maps.DirectionsRenderer({
  //     draggable: false,
  //     markerOptions: {
  //       clickable: false,
  //       draggable: false,
  //       visible: true,
  //     },
  //     polylineOptions: POLYLINE_OPTIONS,
  //   })
  // );

  // function getAndSaveDirections(place){
  //   directionService.current
  //   .route({
  //     destination: {
  //       lat: place.geometry.location.lat(),
  //       lng: place.geometry.location.lng(),
  //     },
  //     origin: coordinates,
  //     travelMode: google.maps.TravelMode.DRIVING,
  //     avoidFerries: false,
  //     avoidHighways: true,
  //     avoidTolls: true,
  //   })
  //   .then((dir) => {
  //     console.log(".then ~ directions:", { dir });
  //     setDirections((prev) => {
  //       if (prev[place.place_id]) {
  //         delete prev[place.place_id];
  //         return { ...prev };
  //       }
  //       return { ...prev, [place.place_id]: dir };
  //     });
  //     // directionsRenderer.current.setDirections(directions);
  //   });
  // }

  return (
    <div className="nearby-places">
      {showMap ? (
        !isLoaded ? (
          <div>Loading Map...</div>
        ) : (
          <GoogleMap
            id="urgent-care-map"
            mapContainerStyle={mapContainerStyle}
            center={coordinates}
            ref={mapRef}
            zoom={12}
            onLoad={(map) => {
              setMap(map);
              placesServices.current = new google.maps.places.PlacesService(
                document.createElement("div")
              );
              // directionsRenderer.current.setMap(map);
            }}
          >
            {urgentCares
              .concat([
                {
                  place_id: addressName,
                  coordinates,
                  name: addressName,
                  currentLocation: true,
                },
              ])
              .map((place) => (
                <MarkerF
                  key={place.place_id}
                  position={
                    place?.currentLocation
                      ? coordinates
                      : {
                          lat:
                            place?.geometry?.location?.lat() || NYC_CENTER.lat,
                          lng:
                            place?.geometry?.location?.lng() || NYC_CENTER.lng,
                        }
                  }
                  title={place?.name || ""}
                  icon={
                    place?.currentLocation
                      ? undefined
                      : {
                          url: hospitalMarker,
                          anchor: pinAnchor?.current,
                        }
                  }
                />
              ))}
            {/* {Object.keys(directions).length > 0 &&
              Object.values(directions).flatMap((el) => (
                <DirectionsRenderer
                  directions={el}
                  options={{
                    suppressMarkers: true,
                  }}
                />
              ))} */}
          </GoogleMap>
        )
      ) : null}
    </div>
  );
}

export default NearbyPlaces;
