import { useDispatch, useSelector } from "react-redux";

import { useResponsive } from "../../../../../../hooks";
import useEmailActions from "../../../../../../hooks/useEmailActions";
import { useCommunication } from "../../../providers/CommunicationProvider";
import AttachmentsView from "./attachments-view/AttachmentsView";
import "./communication-email-view.scss";
import { formatCommunicationTimestamp } from "../../../utils/formatCommunicationTimestamp";
import { setEmailActionType } from "../../../../../../actions/communicationActions";

const CommunicationEmailView = ({ data, triggerEmailView, openEmailBox }) => {
  const { handleMarkAs } = useEmailActions();
  const { from, snippet, subject, date, id, draftId, attachments } = data;

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { mobile } = useResponsive();
  const { authData } = useCommunication();
  const formattedCreatedAt = formatCommunicationTimestamp(date);
  const dispatch = useDispatch();

  return (
    <div
      className={`communication-email-view-container 
      ${isDarkMode ? "dark-mode" : ""}
      ${mobile ? "mobile" : ""}`}
      data-testid="communication-email-view-container"
      onClick={() => {
        if (draftId) {
          openEmailBox(draftId);
          dispatch(setEmailActionType("DRAFT"));
        } else {
          triggerEmailView(id);
        }
        handleMarkAs("READ", [id], authData);
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div className="communication-email-view-container-left">
          <div className="from-info-container">
            <p className="from" dangerouslySetInnerHTML={{ __html: from }} />
            {mobile && <p className="createdAt">{dateFormatted}</p>}
          </div>
          <div className="communication-email-view-container-right">
            <p
              className="subject"
              dangerouslySetInnerHTML={{ __html: subject }}
            />

            <span>-</span>

            <p
              className="snippet"
              dangerouslySetInnerHTML={{ __html: snippet }}
            />
          </div>
        </div>

        {!mobile && <p className="createdAt">{formattedCreatedAt}</p>}
      </div>

      {attachments?.length > 0 && (
        <AttachmentsView
          attachments={attachments}
          handleMoreFilesClick={() => {
            triggerEmailView(id);
            handleMarkAs("READ", [id], authData);
          }}
        />
      )}
    </div>
  );
};

export default CommunicationEmailView;
