import moment from "moment";

const findClosestEndDate = (sortedHistory, endDate) => {
  let closestEndDate = null;

  for (let i = 0; i < sortedHistory.length; i++) {
    const currentEndDate = moment(
      sortedHistory[i].endDate ?? sortedHistory[i].startDate
    );

    if (
      !closestEndDate ||
      (currentEndDate.isBefore(endDate) &&
        currentEndDate.isAfter(closestEndDate))
    ) {
      closestEndDate = currentEndDate;
    }
  }

  return closestEndDate;
};

const findClosestStartDate = (sortedHistory, startDate) => {
  let closestStartDate = null;

  for (let i = 0; i < sortedHistory.length; i++) {
    const currentStartDate = sortedHistory[i].startDate;
    if (currentStartDate === startDate) continue;
    if (startDate + 12 * 60 * 60 * 1000 < currentStartDate) {
      closestStartDate = currentStartDate;
      break;
    }
  }

  return closestStartDate;
};

const isOverlapping = (sortedHistory, date) => {
  return sortedHistory.some((period) => {
    const periodStart = period.startDate;
    const periodEnd = period.endDate ? period.endDate : periodStart;

    return periodEnd
      ? date.isBetween(periodStart, periodEnd, "day")
      : date.isAfter(periodStart, "day");
  });
};

export const disabledEndDate = ({
  current,
  driverHistory,
  lastDriverStartDate,
  lastDriverEndDate,
  selectedDriverStartDate,
  type,
}) => {
  if (current.valueOf() > Date.now()) return true;

  if (lastDriverStartDate && type === "first") {
    const sortedHistory = [...driverHistory].sort(
      (a, b) => a.startDate - b.startDate
    );
    if (isOverlapping(sortedHistory, current)) return true;

    const closestStartDate = findClosestStartDate(
      sortedHistory,
      lastDriverStartDate
    );

    if (current.valueOf() < lastDriverStartDate) return true;

    if (closestStartDate && current.isAfter(closestStartDate, "day")) {
      return true;
    }
  }
  if (
    type == "second" &&
    (!Array.isArray(driverHistory) || driverHistory.length === 0)
  ) {
    if (lastDriverEndDate) {
      if (
        (current.valueOf() > lastDriverStartDate &&
          current.valueOf() < lastDriverEndDate) ||
        current.valueOf() < selectedDriverStartDate
      ) {
        return true;
      }
    }
    if (
      selectedDriverStartDate < lastDriverStartDate &&
      current.valueOf() > lastDriverStartDate
    ) {
      return true;
    }
    if (
      selectedDriverStartDate > lastDriverEndDate &&
      current.valueOf() < lastDriverEndDate
    ) {
      return true;
    }
  }
  if (!Array.isArray(driverHistory) || driverHistory.length === 0) return false;
  if (selectedDriverStartDate && type === "second") {
    const sortedHistory = [
      ...driverHistory.slice(0, driverHistory.length - 1),
      {
        ...driverHistory.at(-1),
        endDate: lastDriverEndDate,
      },
    ].sort((a, b) => a.startDate - b.startDate);
    if (isOverlapping(sortedHistory, current)) return true;

    const closestStartDate = findClosestStartDate(
      sortedHistory,
      selectedDriverStartDate
    );
    if (lastDriverStartDate) {
      if (
        current.valueOf() > lastDriverStartDate &&
        current.valueOf() < lastDriverEndDate
      )
        return true;
    }
    if (current.valueOf() < selectedDriverStartDate) return true;

    if (closestStartDate && current.isAfter(closestStartDate, "day")) {
      return true;
    }
  }

  return false;
};

export const disabledStartDate = ({
  current,
  driverHistory,
  lastDriverStartDate,
  lastDriverEndDate,
  selectedDriverEndDate,
  type,
}) => {
  if (current.valueOf() > Date.now()) return true;
  if (
    type == "second" &&
    (!Array.isArray(driverHistory) || driverHistory.length === 0)
  ) {
    if (lastDriverEndDate) {
      if (
        current.valueOf() > lastDriverStartDate &&
        current.valueOf() < lastDriverEndDate
      ) {
        return true;
      }
    }
    if (
      selectedDriverEndDate &&
      selectedDriverEndDate > lastDriverEndDate &&
      current.valueOf() < lastDriverEndDate
    ) {
      return true;
    }
    if (
      selectedDriverEndDate &&
      selectedDriverEndDate < lastDriverEndDate &&
      current.valueOf() > lastDriverEndDate
    ) {
      return true;
    }
  }
  if (!Array.isArray(driverHistory) || driverHistory.length === 0) return false;
  const driverHistoryCopy = structuredClone(driverHistory);

  const sortedHistory = [...driverHistoryCopy].sort(
    (a, b) => a.startDate - b.startDate
  );
  if (type === "second") {
    driverHistoryCopy[driverHistoryCopy.length - 1] = {
      ...driverHistoryCopy[driverHistoryCopy.length - 1],
      endDate: lastDriverEndDate,
    };
  }
  for (let i = 0; i < driverHistoryCopy.length; i++) {
    const start = driverHistoryCopy[i].startDate;
    const end = driverHistoryCopy[i].endDate || driverHistoryCopy[i].startDate;

    if (current.valueOf() >= start && current.valueOf() <= end) {
      return true;
    }
  }

  if (lastDriverEndDate && type === "first") {
    const closestEndDate = findClosestEndDate(sortedHistory, lastDriverEndDate);
    if (current.valueOf() > lastDriverEndDate) return true;
    if (closestEndDate && current.isBefore(closestEndDate, "day")) {
      return true;
    }
  }

  if (selectedDriverEndDate && type === "second") {
    const closestEndDate = findClosestEndDate(
      sortedHistory,
      selectedDriverEndDate
    );
    if (lastDriverEndDate) {
      if (
        current.valueOf() > lastDriverStartDate &&
        current.valueOf() < lastDriverEndDate
      ) {
        return true;
      }
    }
    if (current.valueOf() < selectedDriverEndDate) return true;

    if (closestEndDate && current.isBefore(closestEndDate, "day")) {
      return true;
    }
  }

  return false;
};
