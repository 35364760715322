import { Modal } from "antd";

import "./MappingConfigModal.scss";
import { useSelector } from "react-redux";

const MappingConfigModal = ({
  open = false,
  onCancel = () => {},
  title = "",
  children = null,
  width = 520,
  className = "",
  closeIcon = null,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <Modal
      width={width}
      open={open}
      onCancel={onCancel}
      centered
      footer={null}
      className={`mappingConfigModal ${className && "noPadding"} ${
        isDarkMode && "mappingConfigModalDark"
      }`}
      title={title}
      {...(closeIcon && { closeIcon })}
    >
      {children}
    </Modal>
  );
};

export default MappingConfigModal;
