import { updateOtherUser } from "../../../../../../../../../../actions";
import broadcastNotification from "../../../../../../../../../../helpers/controllers/broadcastNotification";
import {
  apiPut,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../../../../../../utils";
import sendResetPassEmail from "./sendResetPassEmail";

const logoutAllSessions = async ({
  forceResetPassword = false,
  user,
  wsSessions,
  saveAddedLogs,
  dispatch,
  userConfiguration,
}) => {
  const { identityId, nameOfUser, activeSessions = [] } = user || {};
  const updatedActiveSessions = (activeSessions || [])?.map((session) => {
    const { wsStatus, isIdle, ...rest } = session;
    return {
      ...rest,
      logout: true,
    };
  });
  showLoadingMsg();

  const putBody = {
    activeSessions: updatedActiveSessions,
    ...(forceResetPassword ? { isForceToResetPassword: true } : {}),
  };

  await apiPut("userConfiguration", identityId, {
    ...putBody,
  }).then(async () => {
    try {
      wsSessions.send(
        JSON.stringify({
          request: "force-logout",
          body: {
            userId: identityId,
            resetPassword: forceResetPassword,
          },
        })
      );
    } catch (e) {
      console.error("Error sending logout request", e);
    }
    const logCommonKeys = {
      recordId: `Users Actions - ${nameOfUser}`,
      recordName: nameOfUser,
      category: "Users Actions",
    };
    if (!!forceResetPassword) {
      await sendResetPassEmail(user);
      saveAddedLogs({
        ...logCommonKeys,
        actionType: "Reset Password",
        currentData: { [nameOfUser]: "True" },
        previousData: { [nameOfUser]: "False" },
        updatedKeys: [
          {
            label: "User Password Resetting",
            previousValue: "False",
            updatedValue: "True",
          },
        ],
      }).catch((err) => console.log("Error Creating Logs: ", err));
    }
    saveAddedLogs({
      ...logCommonKeys,
      actionType: "Force Logout",
      currentData: { [nameOfUser]: "Active Sessions: 0" },
      previousData: {
        [nameOfUser]: `Active Sessions: ${activeSessions?.length}`,
      },
      updatedKeys: [
        {
          label: `${nameOfUser} Logout`,
          previousValue: `Active Sessions: ${activeSessions?.length}`,
          updatedValue: "Active Sessions: 0",
        },
      ],
    }).catch((err) => console.log("Error Creating Logs: ", err));
  });
  broadcastNotification(
    "84",
    "onUserLogout",
    [
      {
        common: nameOfUser,
        commonNext: userConfiguration.nameOfUser,
      },
      {
        userName: userConfiguration.nameOfUser,
        currentUser: userConfiguration.cognitoUserId,
      },
    ],
    null
  );

  dispatch(updateOtherUser({ identityId, ...putBody }));
  showSuccessMsg();
};

export default logoutAllSessions;
