import {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  memo,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import { MobileBasePageContext } from "../MobileBasePage";
import { camelCaseToTitleCase } from "../../../pages/Settings/settingsComponents/ProjectsOverviewConfig/utils";
import { Skeleton } from "antd";
import { modifyRowData } from "../utils";
import { useFieldOptionFinder } from "../../../../hooks";
import { useLocation } from "react-router-dom";

// Helper function to determine dynamic props
const determineDynamicProps = (component, data, value) => {
  const toString = component.toString();
  const dynamicPropKey =
    toString.includes("params") || toString.includes("data") ? "data" : "value";
  const dynamicPropValue =
    toString.includes("params") || toString.includes("data") ? data : value;
  return { dynamicPropKey, dynamicPropValue };
};

const itemsPerPage = (colDefs) => {
  if (colDefs && colDefs.length) {
    const defaultHeight = window.innerHeight - 75;
    const dynamicHeight = colDefs.length * 22 + (colDefs.length - 1) * 10 + 20;
    const toShow = Math.floor(defaultHeight / dynamicHeight) + 1;
    return toShow;
  }
};

const Grid = ({ isModal = false }) => {
  const { categories = [] } =
    useFieldOptionFinder({
      fieldName: "Device Management",
    }) || {};
  const location = useLocation();

  const pathName = useMemo(() => {
    const { pathname, search } = location;
    return pathname + search;
  }, [location]);

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const {
    customStatusPanel,
    rowData,
    dynamicColumnDefs,
    statuses,
    panelFilter,
    loadingData,
    title,
    selectedCard,
  } = useContext(MobileBasePageContext);

  const [visibleData, setVisibleData] = useState([]);
  const [page, setPage] = useState(1);

  const containerRef = useRef(null);

  const keyToMapForBgColor = useMemo(() => {
    return (
      customStatusPanel ||
      (Array.isArray(statuses) ? statuses : statuses?.[panelFilter?.key])
    );
  }, [panelFilter, statuses, customStatusPanel]);

  const proceedRowData = useMemo(
    () => modifyRowData(rowData)[title] || rowData,
    [rowData, title]
  );

  useEffect(() => {
    if (isModal) {
      setVisibleData(proceedRowData.slice(0, itemsPerPage(dynamicColumnDefs)));
    }
  }, [isModal]);

  const handleScroll = useCallback(() => {
    if (isModal && containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

      if (scrollTop + clientHeight >= scrollHeight - 50) {
        const nextPageData = proceedRowData.slice(
          page * itemsPerPage(dynamicColumnDefs),
          (page + 1) * itemsPerPage(dynamicColumnDefs)
        );

        if (nextPageData.length > 0) {
          setVisibleData((prev) => [...prev, ...nextPageData]);
          setPage((prev) => prev + 1);
        } else {
          console.log("No more data to load");
        }
      }
    }
  }, [page, proceedRowData, isModal]);

  useEffect(() => {
    if (isModal) {
      const container = containerRef.current;
      if (container) {
        container.addEventListener("scroll", handleScroll);
        return () => container.removeEventListener("scroll", handleScroll);
      }
    }
  }, [handleScroll, isModal]);

  const rowDataToRead = useMemo(() => {
    const toRead = isModal ? visibleData : proceedRowData;
    const filtered = toRead.filter(({ isFiltered = false }) => isFiltered);
    return selectedCard ? filtered : toRead;
  }, [isModal, proceedRowData, visibleData]);

  const checkForTabs = useMemo(() => {
    const finded = categories.find(({ path, title: t }) => {
      return path === pathName;
    })?.tabs?.length;
    return finded;
  }, [categories, title, pathName]);

  const heightToMinus = useMemo(() => {
    if (typeof pathName !== "string") {
      console.error("pathName is not a string:", pathName);
      return;
    }
    const isFromSettings = pathName.toLowerCase().includes("settings");
    if (isFromSettings) {
      if (checkForTabs) {
        return 457;
      }
      return 405;
    }
    if (!isFromSettings) {
      if (checkForTabs) {
        return 445;
      }
      return 399;
    }
  }, [pathName, checkForTabs]);

  return (
    <div
      ref={containerRef}
      className="mobileBasePageGridContainer"
      style={{
        height: `${window.innerHeight - (isModal ? 85 : heightToMinus)}px`,
      }}
    >
      {(!loadingData && !rowData.length) ||
      (selectedCard && !rowDataToRead.length) ? (
        <div
          data-testid="empty"
          className="empty"
          style={{ height: window.innerHeight - 509 }}
        >
          No Data Available!
        </div>
      ) : loadingData ? (
        [...Array(14)].map((_, index) => (
          <Skeleton.Input style={{ width: "100%" }} key={index} active />
        ))
      ) : (
        rowDataToRead.map((data) => (
          <div
            key={data.id}
            className={`tableContainer ${isDarkMode ? "dark" : ""}`}
          >
            {dynamicColumnDefs?.map((Col, i) => {
              const value = data?.[Col?.field];
              const proceedValue = typeof value === "string" ? value : "";

              const CellRendererFramework = Col.cellRendererFramework;
              const ValueGetter = Col.valueGetter;
              const CellRenderer = Col.cellRenderer;
              const withStatusAndColor = Col.field === panelFilter?.key;

              const bgColorForStatus = keyToMapForBgColor?.find(
                ({ statusName }) => statusName === data[panelFilter?.key]
              )?.statusColor;

              let valueToRead = proceedValue;

              if (
                Col?.field?.toLowerCase()?.trim() === "no" ||
                Col?.headerName?.toLowerCase()?.trim() === "no"
              ) {
                valueToRead = i + 1;
              } else if (
                typeof CellRendererFramework === "string" ||
                typeof ValueGetter === "string" ||
                typeof CellRenderer === "string"
              ) {
                valueToRead = proceedValue;
              } else if (CellRendererFramework) {
                const { dynamicPropKey, dynamicPropValue } =
                  determineDynamicProps(CellRendererFramework, data, value);
                valueToRead = (
                  <CellRendererFramework
                    {...{ [dynamicPropKey]: dynamicPropValue, value }}
                  />
                );
              } else if (ValueGetter) {
                const { dynamicPropKey, dynamicPropValue } =
                  determineDynamicProps(ValueGetter, data, value);
                valueToRead = (
                  <ValueGetter
                    {...{ [dynamicPropKey]: dynamicPropValue, value }}
                  />
                );
              } else if (CellRenderer) {
                const { dynamicPropKey, dynamicPropValue } =
                  determineDynamicProps(CellRenderer, data, value);
                valueToRead = (
                  <CellRenderer
                    {...{ [dynamicPropKey]: dynamicPropValue, value }}
                  />
                );
              }

              return (
                <div key={i} className={`elements ${isDarkMode ? "dark" : ""}`}>
                  <div className="header">
                    {Col?.headerName || camelCaseToTitleCase(Col?.field)}:
                  </div>
                  <div
                    className="field"
                    style={
                      withStatusAndColor
                        ? {
                            backgroundColor: bgColorForStatus,
                            color: bgColorForStatus ? "white" : "",
                            textTransform: "uppercase",
                            borderRadius: "5px",
                            padding: bgColorForStatus ? "2px 20px" : "",
                          }
                        : {}
                    }
                  >
                    {valueToRead}
                  </div>
                </div>
              );
            })}
          </div>
        ))
      )}
    </div>
  );
};

export default memo(Grid);
