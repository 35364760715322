import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { filterTables, getFiltersObject, getPanelObject } from "../../../utils";
import BasePage from "../BasePage";
import { LoadableComp } from "../XComponents";
import { columnDefs, chartingOptions, excelColumnsConfig } from "./AgGridData";
import { useLocation } from "react-router-dom";
import { getExcelColumnKeys } from "../../../utils/getExcelColumnKeys";
import { getChartingOptions } from "./utils/getChartingOptions";
import { useResponsive } from "../../../hooks";
import MobileBasePage from "../../MobileComponents/MobileBasePage/MobileBasePage";

export const getSubcontractors = async () =>
  await filterTables("accounts", "accountRecordType", "Subcontractors").then(
    (res) => res
  );
const SubcontractorsBasePage = ({ rowData, reloadGrid = () => {} }) => {
  let { state: locationState } = useLocation();
  const keysToUpdate = locationState?.chartFilter || {};

  // redux
  const { programFields } = useSelector((state) => state.programFields);
  const { mobile, tablet } = useResponsive();

  const panelObject = getPanelObject(programFields, "Subcontractors");
  const filtersObject = getFiltersObject(
    programFields,
    "Subcontractors",
    keysToUpdate
  );

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const [accessRight, setAccessRight] = useState();
  const [viewAccessRight, setViewAccessRight] = useState();
  const [gridApi, setGridApi] = useState();

  const [panelFilter, setPanelFilter] = useState({
    status: panelObject?.createdAt?.label,
    checked: false,
    visible: true,
  });

  const changePanels = (e) => {
    e
      ? setPanelFilter({
          status: panelObject?.subcontractorType?.label,
          checked: true,
          visible: true,
        })
      : setPanelFilter({
          status: panelObject?.createdAt?.label,
          checked: false,
          visible: true,
        });
  };

  useEffect(() => {
    setAccessRight(
      userConfiguration?.routeConfig?.find(
        ({ title }) => title === "Subcontractors"
      )
    );
    setViewAccessRight(
      userConfiguration?.routeConfig?.find(
        ({ title }) => title === "Accounts/View"
      )
    );
  }, [userConfiguration]);

  const exportGridToExcel = (tableColumns) => {
    gridApi.exportDataAsExcel({
      columnKeys: getExcelColumnKeys(gridApi, tableColumns),
      processCellCallback(params) {
        const value = params?.value;

        return value === undefined ? "" : `${value}`;
      },
    });
  };

  const columnDefsMemo = useMemo(() => {
    return columnDefs({
      view: viewAccessRight,
    });
  }, [viewAccessRight]);

  return (
    <LoadableComp loading={!!!rowData}>
      {mobile || tablet ? (
        <MobileBasePage
          title="Subcontractors"
          rowData={rowData}
          columnDefs={columnDefsMemo}
        />
      ) : (
        <BasePage
          {...{
            title: "Subcontractors",
            rowData,
            filtersObject,
            chartingOptions,
            videoLinks: true,
            columnDefs: columnDefsMemo,
            getGridApi: (e) => {
              setGridApi(e);
            },
            getChartingOptions: (data) => getChartingOptions(data),
            defaultExcelExportParams: {
              columnKeys: excelColumnsConfig,
              fileName: "Subcontractors",
            },
            exportGridToExcel,
            hasNew: accessRight?.write,
            panelObject: panelFilter.checked
              ? panelObject?.subcontractorType
              : panelObject?.createdAt,
            panelFilter: panelFilter,
            setPanelFilter: changePanels,
            reloadGrid,
          }}
        />
      )}
    </LoadableComp>
  );
};
export default SubcontractorsBasePage;
