import { useCallback } from "react";
import {
  otherTradeIncidentSafetyFields,
  personalInjurySafetyFields,
  preTaskPlanFields,
  propertyDamageSafetyFields,
  vehicleDamageSafetyFields,
} from "../formData";

/**
 *
 * @param {Object} data - Object that contains all the data that we need to create and manipulate safety fields dynamically
 * @returns {Object} - an object that contains pointers to functions that will create the safety fields
 */
const safetyFieldsFunctions = (data = {}) => ({
  "Property Damage": () => propertyDamageSafetyFields({ ...data }),

  "Personal Injury": () => personalInjurySafetyFields({ ...data }),

  "Vehicle Damage": () => vehicleDamageSafetyFields({ ...data }),

  "Other Trade Incident": () => otherTradeIncidentSafetyFields({ ...data }),
  "Pre-task Plan": () => preTaskPlanFields({ ...data }),
});

/**
 * This custom hook calls the necessary function to create the safety fields
 * which is kept in a useCallback hook that will change when the safetyCategory changes
 *
 * @param {String} safetyCategory
 * @returns {Array} - safety fields
 */
const useSafetyFields = (safetyCategory) => {
  const safetyFields = useCallback(
    (data) => {
      try {
        return safetyFieldsFunctions(data)?.[safetyCategory]();
      } catch (err) {
        console.log(err);
        return [];
      }
    },
    [safetyCategory]
  );

  return { safetyFields };
};

export default useSafetyFields;
