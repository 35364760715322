import { assignedToObject } from "..";
import sortFields from "../sortFields";

/**
 * Generates an array of fields for an new vendor request form.
 *

* @param {Array} inputFields - The list of input fields.
 * @param {boolean} isDarkMode - Indicates if the form is in dark mode.
 * @param {Object} userConfiguration - The user configuration.
 * @param {boolean} globalDisabled - Indicates if all fields are disabled.
 * @param {boolean} allFieldsDisabled - Indicates if all fields are disabled.
 * @returns {Array} The sorted array of fields for the new vendor request form.
 */
const newVendorRequestFields = ({
  inputFields = [],
  isDarkMode = false,
  userConfiguration = {},
  globalDisabled = false,
  vendorTypes = [],
  allFieldsDisabled = false,
}) => {
  const newFields = [];

  inputFields.forEach((input) => {
    let field = structuredClone(input);

    const { type = "", formItemName = "" } = field;

    field.className = "defaultInputComponent";
    field.disabled = !!allFieldsDisabled || globalDisabled;

    if (isDarkMode) {
      if (type === "select") {
        Object.assign(field, { dropdownClassName: "darkDropDown" });
      } else if (type === "datepicker" || type === "rangepicker") {
        Object.assign(field, { dropdownClassName: "darkDateDropDown" });
      }
    }

    if (formItemName === "assignedTo") {
      Object.assign(field, {
        ...assignedToObject({
          title: "Vendors",
          users: userConfiguration?.allUsers?.Items,
          currentUser: userConfiguration?.cognitoUserId,
        }),
      });
    }

    if (formItemName === "vendorType") {
      Object.assign(field, {
        options: vendorTypes,
      });
    }

    newFields.push(field);
  });

  return sortFields(newFields);
};

export default newVendorRequestFields;
