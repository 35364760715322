import { fetchAllData } from "../ApiMethods";

// optimized report fetch data
export const fleetDocuments = async ({ keysToInclude }) => {
  try {
    const [fleetDocs, allFleets] = await Promise.all([
      fetchAllData({
        endpoint: "documentation",
        resultId: "documentationId",
        otherStringParams: {
          ...(keysToInclude.length && {
            keysToInclude: JSON.stringify([
              ...new Set([...keysToInclude, "documentationId", "recordId"]),
            ]),
          }),
          filters: JSON.stringify([
            {
              conditions: [
                { formula: "is", column: "categoryName", value: "Fleet" },
              ],
            },
          ]),
        },
      }),
      fetchAllData({ endpoint: "fleet", resultId: "fleetId" }),
    ]);

    return fleetDocs
      .map((doc) => {
        const fleet = allFleets.find(({ fleetId }) => fleetId === doc.recordId);

        if (!!fleet?.fleetName) {
          return { ...doc, ...fleet };
        }
      })
      .filter(Boolean);
  } catch (error) {
    console.error("data source function fleetDocuments error: " + error);
  }
};
