import { useContext, useMemo } from "react";
import "./MobileStatusPanel.scss";
import { MobileBasePageContext } from "../MobileBasePage";
import { Skeleton, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { dateFilterOptions } from "../../../SidebarPages/XComponents/ControlPanel/datas";

function calculateValueForStatusPanel(rowData, panelObject, statusName, title) {
  const result =
    rowData?.filter((e) => {
      if (panelObject?.key === "createdAt") {
        return (
          parseInt(e[panelObject?.key]) >=
            dateFilterOptions[statusName][0].valueOf() &&
          parseInt(e[panelObject?.key]) <=
            dateFilterOptions[statusName][1].valueOf()
        );
      } else {
        if (typeof e?.[panelObject?.key] === "string") {
          return (
            e?.[panelObject?.key]?.toLowerCase() === statusName?.toLowerCase()
          );
        } else if (title === "Vendors") {
          return statusName?.toLowerCase() === e?.vendorStatus?.toLowerCase();
        } else if (title === "Bills") {
          return statusName?.toLowerCase() === e?.billStatus?.toLowerCase();
        } else {
          return false;
        }
      }
    })?.length || 0;

  return result;
}

const MobileStatusPanel = () => {
  const {
    title,
    rowData,
    selectedCard,
    setSelectedCard,
    loadingData,
    panelFilter,
    statuses,
    customStatusPanel,
  } = useContext(MobileBasePageContext);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const proceedStatuses = useMemo(() => {
    if (customStatusPanel) {
      return customStatusPanel.map(({ statusName, statusColor }) => {
        return {
          value: calculateValueForStatusPanel(rowData, panelFilter, statusName),
          statusName,
          statusColor,
        };
      });
    }
    const keyToMap = Array.isArray(statuses)
      ? statuses
      : statuses[panelFilter.key];

    if (!Array.isArray(keyToMap)) {
      console.error("keyToMap is not an array:", keyToMap);
      return [];
    }

    return keyToMap.map(({ statusName, statusColor }) => {
      return {
        value: calculateValueForStatusPanel(
          rowData,
          panelFilter,
          statusName,
          title
        ),
        statusName,
        statusColor,
      };
    });
  }, [rowData, statuses, panelFilter]);

  return (
    <div
      data-testid="mobile-status-panel"
      className="mobileStatusPanelContainer"
    >
      <div className={`mobileStatusPanel ${isDarkMode && "dark"}`}>
        {loadingData ? (
          <Skeleton.Input
            block
            style={{
              height: "94px",
              borderRadius: "5px",
            }}
            active
            data-testid="progressbar"
          />
        ) : proceedStatuses.length > 0 ? (
          proceedStatuses.map(
            ({ statusId, statusName, statusColor, value }) => {
              const isActive = statusName === selectedCard?.statusName;
              return (
                <div
                  key={statusId}
                  className="mobileStatusContent"
                  style={{ backgroundColor: statusColor }}
                  onClick={() => {
                    if (isActive) {
                      setSelectedCard();
                    } else {
                      setSelectedCard({ statusName, statusColor, value });
                    }
                  }}
                >
                  <div className="count">{value}</div>
                  <Tooltip title={statusName} placement="bottom">
                    <div className="text">{statusName}</div>
                  </Tooltip>
                </div>
              );
            }
          )
        ) : (
          <div className="noStatusMessage">No statuses available</div>
        )}
      </div>
    </div>
  );
};

export default MobileStatusPanel;
