import moment from "moment";

export function getTopics(data, priority) {
  const filteredResults = []; // Array to hold the filtered results
  const uniqueCategories = new Set(); // Set to track unique template categories

  data.forEach((obj) => {
    // Check if templates exist in the current object
    if (Array.isArray(obj.templates)) {
      // Filter templates by the specified priority
      const filteredTemplates = obj.templates.filter(
        (template) =>
          template.templateContent.priority.toLowerCase() === priority
      );

      // Process each filtered template
      filteredTemplates.forEach((template) => {
        // Add unique categories to the results
        if (
          template.templateCategory &&
          !uniqueCategories.has(template.templateCategory)
        ) {
          uniqueCategories.add(template.templateCategory); // Add category to the Set
          filteredResults.push({
            templateCategory: template.templateCategory, // Store category in results
          });
        }
      });
    }
  });

  return filteredResults; // Return the array of filtered results
}

export const getTodayTimestamps = () => {
  const startOfToday = new Date().setHours(0, 0, 0, 0);
  const endOfToday = new Date().setHours(23, 59, 59, 999);

  return { startOfToday, endOfToday };
};

export const getThisWeekTimestamps = () => {
  const startOfWeek = new Date();
  startOfWeek.setHours(0, 0, 0, 0);
  startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());

  const endOfWeek = new Date(startOfWeek); // Start from the startOfWeek date
  endOfWeek.setDate(endOfWeek.getDate() + 6); // Set to the end of the week
  endOfWeek.setHours(23, 59, 59, 999);

  return {
    startOfWeek: startOfWeek.getTime(),
    endOfWeek: endOfWeek.getTime(),
  };
};

export const generateFiltersForTopics = (topics, tab, sub) => {
  // Return an empty array if there are no topics
  if (topics.length === 0) return [];

  // Extract template categories from the topics array
  const topicsArray = topics.map(({ templateCategory }) => templateCategory);

  // Check if the tab is "all" to generate the appropriate filters
  if (tab === "all") {
    return [
      {
        conditions: [
          {
            operator: "AND",
            column: "notificationObject.topic",
            value: topicsArray, // Filter based on topics
            formula: "is_one_of",
          },
          {
            operator: "AND",
            column: `cognitoUserId.${sub}.hasBeenHidden`, // Check hidden status for the user
            value: false,
            formula: "is",
          },
        ],
      },
    ];
  }

  // Return filters only for topics if tab is not "all"
  return [
    {
      conditions: [
        {
          operator: "AND",
          column: "notificationObject.topic",
          value: topicsArray, // Filter based on topics
          formula: "is_one_of",
        },
      ],
    },
  ];
};

export const generateFiltersForDate = (filterBy, tab, sub) => {
  // Check if the filter is for today
  if (filterBy === "today") {
    const { startOfToday, endOfToday } = getTodayTimestamps(); // Get today's timestamps
    if (tab === "all") {
      return [
        {
          conditions: [
            {
              operator: "AND",
              column: "createdAt", // Filter based on creation date
              value: [startOfToday, endOfToday], // Date range for today
              formula: "is between",
            },
            {
              operator: "AND",
              column: `cognitoUserId.${sub}.hasBeenHidden`, // Check hidden status for the user
              value: false,
              formula: "is",
            },
          ],
        },
      ];
    }

    // Return filters for today's date if tab is not "all"
    return [
      {
        conditions: [
          {
            operator: "AND",
            column: "createdAt", // Filter based on creation date
            value: [startOfToday, endOfToday], // Date range for today
            formula: "is between",
          },
        ],
      },
    ];
  } else {
    // Get timestamps for the current week
    const { startOfWeek, endOfWeek } = getThisWeekTimestamps();
    if (tab === "all") {
      return [
        {
          conditions: [
            {
              operator: "AND",
              column: "createdAt", // Filter based on creation date
              value: [startOfWeek, endOfWeek], // Date range for this week
              formula: "is between",
            },
            {
              operator: "AND",
              column: `cognitoUserId.${sub}.hasBeenHidden`, // Check hidden status for the user
              value: false,
              formula: "is",
            },
          ],
        },
      ];
    }

    // Return filters for this week's date if tab is not "all"
    return [
      {
        conditions: [
          {
            operator: "AND",
            column: "createdAt", // Filter based on creation date
            value: [startOfWeek, endOfWeek], // Date range for this week
            formula: "is between",
          },
        ],
      },
    ];
  }
};

export const generateFiltersForHidden = (sub) => {
  return [
    {
      conditions: [
        {
          operator: "AND",
          column: `cognitoUserId.${sub}.hasBeenHidden`,
          value: true,
          formula: "is",
        },
      ],
    },
  ];
};

export const generateFiltersForUnread = (sub) => {
  return [
    {
      conditions: [
        {
          operator: "AND",
          column: `cognitoUserId.${sub}.hasBeenSeen`,
          value: false,
          formula: "is",
        },
      ],
    },
  ];
};

function filterByUnauthorizedActivity(sub) {
  return [
    {
      conditions: [
        {
          operator: "AND",
          column: "notificationObject.action",
          value: "sendUnauthorizedIPDevice",
          formula: "is",
        },
        {
          operator: "AND",
          column: `cognitoUserId.${sub}.hasBeenHidden`,
          value: false,
          formula: "is",
        },
      ],
    },
  ];
}

export const generateFiltersForIWasMentioned = (data) => {
  const filteredResults = []; // Array to hold templates that match the filter criteria

  data.forEach((obj) => {
    // Check if templates exist in the current object
    if (Array.isArray(obj?.templates)) {
      // Filter templates where the action is "oncommentmention"
      const filteredTemplates = obj.templates?.filter(
        (template) =>
          template?.templateAction?.toLowerCase() === "oncommentmention"
      );

      // Add the filtered templates to the results array
      filteredResults.push(...filteredTemplates);
    }
  });

  // Generate filters for the filtered templates and return the result, or an empty array
  return generateFiltersForTopics(filteredResults) || [];
};

export const generateFiltersForAssignedToMe = (sub) => {
  return [
    {
      conditions: [
        {
          operator: "AND",
          column: "notificationObject.action", // Filter for task assignment action
          value: "onTaskAssignation", // Specific action to match
          formula: "is",
        },
        {
          operator: "AND",
          column: `cognitoUserId.${sub}.hasBeenHidden`, // Check hidden status for the user
          value: false, // Ensure the notification hasn't been hidden
          formula: "is",
        },
      ],
    },
  ];
};

export const generateFiltersForSessions = () => {
  return [
    {
      conditions: [
        {
          operator: "AND",
          column: "notificationObject.topic",
          value: "110",
          formula: "is",
        },
      ],
    },
  ];
};

export const combineObjectsAndCreateConditionsArrayFilters = (obj1, obj2) => {
  return [
    {
      conditions: [obj1, obj2],
    },
  ];
};

export function getFilters(data, filterBy, tab, sub) {
  const isTopic = ["important", "critical", "info"]; // Array of topic filters
  const isDate = ["today", "thisweek"]; // Array of date filters

  // Base filter template
  const baseFilter = (column, value) => ({
    conditions: [
      {
        operator: "AND",
        column: `cognitoUserId.${sub}.${column}`, // Dynamic column for the user
        value,
        formula: "is",
      },
    ],
  });

  // Function to combine two filter conditions
  const combineFilters = (filters1, filters2) => {
    return combineObjectsAndCreateConditionsArrayFilters(
      filters1[0].conditions[0],
      filters2[0].conditions[0]
    );
  };

  // Handle case when no specific filter is applied
  if (filterBy === "") {
    if (tab === "all") return [baseFilter("hasBeenHidden", false)]; // Show all visible items
    if (tab === "hidden") return generateFiltersForHidden(sub); // Filters for hidden items
    if (tab === "iwasmentioned") return generateFiltersForIWasMentioned(data); // Filters for mentions
    if (tab === "assignedtome") return generateFiltersForAssignedToMe(sub); // Filters for tasks assigned to user
    if (tab === "sessions") return generateFiltersForSessions(); // Filters for session items
  }

  // Handle filters when tab is "all"
  if (tab === "all") {
    if (isTopic.includes(filterBy)) {
      return (
        generateFiltersForTopics(getTopics(data, filterBy), tab, sub) || []
      ); // Filters for topics
    }
    if (isDate.includes(filterBy))
      return generateFiltersForDate(filterBy, tab, sub); // Filters for dates

    if (filterBy === "unread") {
      return generateFiltersForUnread(sub); // Filters for unread items
    }

    if (filterBy === "unauthorizedaccess") {
      return filterByUnauthorizedActivity(sub);
    }
  }

  // Handle filters when tab is "hidden"
  if (tab === "hidden") {
    const filtersForHidden = generateFiltersForHidden(sub);
    if (isTopic.includes(filterBy)) {
      return combineFilters(
        filtersForHidden,
        generateFiltersForTopics(getTopics(data, filterBy))
      ); // Combine hidden filters with topic filters
    }
    if (filterBy === "unread") {
      return combineFilters(filtersForHidden, generateFiltersForUnread(sub)); // Combine hidden filters with unread filters
    }
    if (isDate.includes(filterBy)) {
      return combineFilters(filtersForHidden, generateFiltersForDate(filterBy)); // Combine hidden filters with date filters
    }
  }

  // Handle filters when tab is "iwasmentioned"
  if (tab === "iwasmentioned") {
    const filtersForIWasMentioned = generateFiltersForIWasMentioned(data);
    if (isTopic.includes(filterBy)) {
      return combineFilters(
        filtersForIWasMentioned,
        generateFiltersForTopics(getTopics(data, filterBy))
      ); // Combine mentioned filters with topic filters
    }
    if (filterBy === "unread") {
      return combineFilters(
        filtersForIWasMentioned,
        generateFiltersForUnread(sub)
      ); // Combine mentioned filters with unread filters
    }
    if (isDate.includes(filterBy)) {
      return combineFilters(
        filtersForIWasMentioned,
        generateFiltersForDate(filterBy)
      ); // Combine mentioned filters with date filters
    }
  }

  // Handle filters when tab is "assignedtome"
  if (tab === "assignedtome") {
    const filtersForAssignedToMe = generateFiltersForAssignedToMe(sub);
    if (isTopic.includes(filterBy)) {
      return filtersForAssignedToMe; // Return filters for assigned tasks
    }
    if (filterBy === "unread") {
      return combineFilters(
        filtersForAssignedToMe,
        generateFiltersForUnread(sub)
      ); // Combine assigned filters with unread filters
    }
    if (isDate.includes(filterBy)) {
      return combineFilters(
        filtersForAssignedToMe,
        generateFiltersForDate(filterBy)
      ); // Combine assigned filters with date filters
    }
  }

  // Handle filters when tab is "sessions"
  if (tab === "sessions") {
    const filtersForSessions = generateFiltersForSessions();
    if (isTopic.includes(filterBy)) {
      return filtersForSessions; // Return filters for session items
    }
    if (filterBy === "unread") {
      return combineFilters(filtersForSessions, generateFiltersForUnread(sub)); // Combine session filters with unread filters
    }
    if (isDate.includes(filterBy)) {
      return combineFilters(
        filtersForSessions,
        generateFiltersForDate(filterBy)
      ); // Combine session filters with date filters
    }
  }
}

export function calculateTotalNotificationsUnreadCount(notifications) {
  let totalUnreadLength = 0; // Initialize total unread notifications count

  // Iterate over each category in the notifications object
  for (const categoryKey in notifications) {
    const category = notifications[categoryKey]; // Access the current category

    // Check if unreadLength is defined for the category
    if (category.unreadLength !== undefined) {
      totalUnreadLength += category.unreadLength; // Add unread count to the total
    }
  }

  return totalUnreadLength; // Return the total unread notifications count
}

export const getTabValue = (tab) => {
  // Check if the tab matches "i was mentioned" and return the corresponding value
  if (tab?.toLowerCase() === "i was mentioned") {
    return "iWasMentioned";
  }
  // Check if the tab matches "assigned to me" and return the corresponding value
  else if (tab?.toLowerCase() === "assigned to me") {
    return "assignedToMe";
  }
  // For any other tab value, return the lowercase version of the tab
  else {
    return tab?.toLowerCase();
  }
};

export const getFilterByValue = (filterBy) => {
  // Check if the filterBy matches "this week" and return the corresponding value
  if (filterBy.toLowerCase() === "this week") {
    return "thisWeek";
  } else if (filterBy === "Unauthorized Access") {
    return "unauthorizedAccess";
  }
  // For any other filterBy value, return the lowercase version of the filterBy
  else {
    return filterBy?.toLowerCase();
  }
};

export const filterEmails = (emails) => {
  // Create an object to hold filtered email categories
  const filters = {
    // Filter for important emails
    important: {
      notifications:
        emails.filter((email) => email.priority === "important") || [],
    },
    // Filter for critical emails
    critical: {
      notifications:
        emails.filter((email) => email.priority === "critical") || [],
    },
    // Filter for informational emails
    info: {
      notifications: emails.filter((email) => email.priority === "info") || [],
    },
    // Filter for unread emails
    unread: {
      notifications: emails.filter((email) => !email.seen) || [],
    },
    // Filter for emails created today
    today: {
      notifications:
        emails.filter((email) =>
          moment(email.createdAt).isSame(moment(), "day")
        ) || [],
    },
    // Filter for emails created this week
    thisWeek: {
      lastEvaluatedKey: "", // Placeholder for pagination, if needed
      notifications:
        emails.filter((email) =>
          moment(email.createdAt).isBetween(
            moment().startOf("week"),
            moment().endOf("week"),
            null,
            "[]"
          )
        ) || [],
    },
  };
  return filters; // Return the object containing all filtered categories
};

export function updateNotificationStatus(notifications, ids, status) {
  // Map through each notification to update its status based on the provided ids
  return notifications.map((notification) => {
    // Check if the current notification's id is included in the ids array
    if (ids.includes(notification.id)) {
      // If so, return a new notification object with the updated seen status
      return { ...notification, seen: status };
    }
    // Otherwise, return the original notification
    return notification;
  });
}

export function updateAllNotificationsStatus(notifications, status) {
  // Map through all notifications to update their seen status to the provided value
  return notifications.map((notification) => ({
    ...notification, // Spread the existing notification properties
    seen: status, // Update the seen status to the provided value
  }));
}
