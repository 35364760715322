import { Popover } from "antd";
import { useState } from "react";
import { gmailTime } from "../../../functions";
import { getSelectedToEmail } from "../../EmailContainer/helpers";
import useIsReplyEmailBoxActive from "../../ReplyEmailBox/hooks/useIsReplyEmailBoxActive";
import ReplyEmailBoxContainer from "../../ReplyEmailBox/ReplyEmailBoxContainer";
import AttachmentsContainer from "./attachments/AttachmentsContainer";

import More from "../more/More";
import Reply from "../reply/Reply";

import htmlParser from "../../../../../../utils/htmlParser";
import "./single-email-view.scss";
import RepliesView from "../replies/RepliesView";
import { formatCommunicationTimestamp } from "../../../utils/formatCommunicationTimestamp";

let email;
/**
 *
 * @param {Object} mainEmail email object to view or to reply
 * @param {Boolean} isParent condition if selected email is parent email or reply email
 * @param {Array} to Array with destinations of selected emails Ex. [ernest@flex.al, feliks@flex.al]gi
 * @param {Function} setEmail Callback function that return return  of on save in reply to update email without fetch again
 * @param {Object} rowData object of selected record of the application Ex. {leadObject},{opportunityObject}
 * @param {Object} authToken object of logged google user account if we want to save email with logged email if not just pass as a paramater `false`
 * @returns
 */

const SingleEmailView = ({
  mainEmail,
  setEmail,
  rowData,
  authData,
  isEditable = true,
  isLast,
  emails,
}) => {
  email = mainEmail;

  const {
    from = "",
    fromUser,
    timestampDate,
    attachments,
    body,
    replies,
  } = email;
  const createdAt = parseInt(timestampDate);
  const formattedCreatedAt = formatCommunicationTimestamp(createdAt);

  const [collapsed, setCollapsed] = useState(isLast ? false : true);
  const { isActive, setIsActive } = useIsReplyEmailBoxActive();

  const emailInformation = (email, type) => (
    <span>
      {type.toUpperCase() + " :" + getSelectedToEmail(type, email, collapsed)}
    </span>
  );

  return (
    <>
      <div
        {...{
          className: collapsed
            ? "emailItemComponentCollapsed"
            : "emailItemComponent",
        }}
        data-testid="email-container"
      >
        <div
          className={`EmailInformation ${isLast ? "last" : ""}`}
          data-testid="email-information"
        >
          <div
            {...{ className: "EmailInformationLeft" }}
            onClick={() => !isLast && setCollapsed(!collapsed)}
          >
            <span {...{ className: "userLetter" }}>
              {fromUser?.at(0)?.toUpperCase() || from?.at(0)?.toUpperCase()}
            </span>
            <div {...{ className: "emailParts" }}>
              <span {...{ className: "fromEmail" }}>{fromUser || from}</span>
              <Popover
                content={
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {!collapsed ? (
                      ["to", "cc", "bcc"].map((type) => (
                        <span key={type}>{emailInformation(email, type)}</span>
                      ))
                    ) : (
                      <span key="to">
                        {getSelectedToEmail("to", email, collapsed)}
                      </span>
                    )}
                  </div>
                }
                placement="right"
              >
                <span className="toEmail">
                  {getSelectedToEmail("to", email)}
                </span>
              </Popover>
            </div>
          </div>

          <div className="EmailInformationRight">
            <span onClick={() => !isLast && setCollapsed(!collapsed)}>
              {formattedCreatedAt}
            </span>

            {!collapsed && (
              <>
                <Reply
                  isEditable={isEditable}
                  setIsActive={setIsActive}
                  isActive={isActive}
                />
                <More
                  setIsActive={setIsActive}
                  isEditable={isEditable}
                  isActive={isActive}
                />
              </>
            )}
          </div>
        </div>

        {!collapsed && (
          <div className="single-email-view-rich-editor-container">
            {htmlParser(body)}

            {/* {replies?.length > 0 && <RepliesView replies={replies} />} */}
          </div>
        )}

        {attachments?.length !== 0 && !collapsed && (
          <AttachmentsContainer attachments={attachments} />
        )}

        {isActive && !collapsed && (
          <ReplyEmailBoxContainer
            authData={authData}
            isEditable={isEditable}
            mainEmail={mainEmail}
            rowData={rowData}
            setEmail={setEmail}
            setIsActive={setIsActive}
            isActive={isActive}
            emails={emails}
          />
        )}
      </div>

      {!isLast && <hr className="divider" />}
    </>
  );
};
export default SingleEmailView;
