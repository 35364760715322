import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API } from "aws-amplify";
import { ReactComponent as WarningIcon } from "../../../../icons/warningTriangle.svg";
import { BorderedTextCard, InputComponent } from "../../Fleet/components";
import { LoadableComp, SignatureModal } from "../../XComponents";
import {
  MondayButton,
  MultiLevelTreeLogs,
  ProgressComponent,
  Stepper,
} from "../../../commonComponents";
import { Form, message, Modal } from "antd";
import { TickIcon } from "../../../pages/Settings/settingsComponents/Roles/src";
import { XIcon } from "../../Communication/assets";
import WarningModal from "../../../commonComponents/WarningModal/WarningModal";
import SignControllerButtons from "../../Fleet/view/pageView/components/SingleTruckIncidents/modals/IncidentModal/components/SignControllerButtons";
import {
  DocUploader,
  DocumentationLogsModal,
} from "../../Documentation/View/components/DocumentationModal/components";
import { filterSchedulesOnDate } from "../../Safety/SafetyModal/utils/filterSchedulesOnDate";
import { RenderDynamicComponents } from "../../../Header/forms/Components";
import {
  fetchAllData,
  filterTables,
  lazyFetch,
  updateDocumentTitle,
} from "../../../../utils";
import { driveApi } from "../../../../integrations/DriveRequest";
import { getDriveFolderKeyName } from "../../utils/getDriveFolderKeyName";
import { useCreateDriveFolders } from "../../../../hooks/useCreateDriveFolders";
import {
  useEditLogs,
  useProgressComponent,
  useUploadedFiles,
} from "../../../../hooks";
import { dayjsNY } from "../../../DateComponents/contants/DayjsNY";
import StatementsFields from "../../Safety/SafetyModal/components/StatementsFields";
import { preventDuplicate } from "../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { fixFormFields } from "./utils";
import { modalFooter } from "./utils";
import "../../Safety/SafetyModal/SafetyModal.scss";
import { useMediaQuery } from "react-responsive";
import SafetyModal from "../../Safety/SafetyModal/SafetyModal";
import AskForTaskModal from "../../Fleet/view/pageView/components/SingleTruckIncidents/modals/IncidentModal/AskForTaskModal";
import {
  pdfBorderedSection,
  separateWitnessForPdf,
  incidentPdfBody,
} from "./utils/incidentsPdf";
import CustomModalHeader, {
  PlayVideoTutorial,
} from "../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { toCamelCase } from "../../../pages/Settings/settingsComponents/ApprovalsDynamicForms/FormFieldModals/newFieldModal";
import { PlusIcon } from "../../../../assets";
import DynamicTeamModal from "../../../Header/forms/DynamicTeamModal/DynamicTeamModal";
import EmailBox from "../../Communication/components/EmailBox/EmailBox";
import InspectionModal from "../../components/AgGridComponents/InspectionModal";
import TasksBasePage from "../../TaskManager/Tasks/TasksBasePage";
import InspectionBasePage from "../../Inspections/page/InspectionBasePage";
import { useNavigate } from "react-router-dom";
import {
  changeDateOfIncident,
  checkPicker,
  checkSaveDraft,
  onChangeAddress,
  onCloseModal,
  populateSafetyForm,
  safetyBody,
  safetyEmailNotif,
} from "../../Safety/helpers";
import {
  createIncident,
  deleteIncident,
  editIncident,
  incidentPdf,
} from "./utils/helpers";
import IncidentComments from "../Components/IncidentComments";

export const viewSafetyModal = (safetyUsingIncident) => {
  Modal.info({
    title:
      "View Safety Reports that are using this Incident. Click on the Safety Report to open it.",
    content: (
      <div className="safetySmallInfoModalContainer">
        {safetyUsingIncident?.map(({ safetyApplicationId, safetyName }) => {
          return (
            <a
              className="safetySmallInfoModalLink"
              href={`/safety/${safetyApplicationId}`}
              target="_blank"
            >
              {safetyName}
            </a>
          );
        })}
      </div>
    ),
    onOk() {},
    maskClosable: true,
    centered: true,
    className: "safetySmallInfoModal",
  });
};

function NewPropertyDamageIncidentModal({
  visible,
  setVisible,
  refreshTable = () => {},
  rowObject,
  showNextStep = true,
  setVisibleReport,
  requestId = "",
  setToDoVisible = () => {},
  propProjId = "",
  propAddress = "",
  nextStepHandler = () => {},
  handleSavedRecord = () => {},
}) {
  const dispatch = useDispatch();
  const { saveAddedLogs } = useEditLogs();

  const [
    { accessToken },
    { userConfiguration },
    { hotCredentials },
    { programFields },
    { authenticatedUser },
    { base64 },
    { notifications },
    { isDarkMode },
  ] = useSelector((state) => [
    state.accessToken,
    state.userConfig,
    state.hotCredentials,
    state.programFields,
    state.authenticatedUser,
    state.base64,
    state.notifications,
    state.darkMode,
  ]);
  const { preferences } = useSelector((state) => state.preferences);
  const { loggedOnTasks } = useSelector((state) => state.loggedOnTasks);

  const {
    incidentObject: defaultObj,
    incidentId: defaultId,
    gDriveUploads: requestUploads = [],
  } = rowObject || {};

  const [uploadHandlers, deleteHandlers] = useUploadedFiles([]);
  const [uploadedFiles, setUploadedFiles, formattedFiles, onPickerSuccess] =
    uploadHandlers;
  const [filesToBeDeleted, setFilesToBeDeleted, onDelete] = deleteHandlers;
  const driveFunctions = useCreateDriveFolders("Property Damage Incident");
  const [actionType, setActionType] = useState("Update");

  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
    hideProgress,
  } = useProgressComponent({
    categoryName: "Incidents",
    actionType:
      actionType === "Delete" ? "Delete" : !!defaultId ? "Update" : "Create",
  });

  const folderDriveKeyName = getDriveFolderKeyName({
    programFields,
    name: "Property Damage Incident",
  });
  const [pdfId, setPdfId] = useState("");
  const [emailBox, setEmailBox] = useState(false);
  const driveRequest = driveApi({ accessToken });
  const navigate = useNavigate();

  const [dynamicFields, setDynamicFields] = useState();
  const [descriptionValues, setDescriptionValues] = useState({});
  const [sign, setSign] = useState();
  const [editLogs, setEditLogs] = useState([]);
  const [projects, setProjects] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [schedulesForDate, setSchedulesForDate] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [editLogsVisibility, setEditLogsVisibility] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [signatureModalVisible, setSignatureModalVisible] = useState(false);
  const [whichSignToChange, setWhichSignToChange] = useState();
  const [divClickable, setDivClickable] = useState(true);
  const [steps, setSteps] = useState([]);
  const [statusesColors, setStatusesColors] = useState([]);
  const [foremen, setForemen] = useState([]);
  const [crewMembers, setCrewMembers] = useState([]);
  const [witnessCount, setWitnessCount] = useState(1);
  const [witnessStatements, setWitnessStatements] = useState([""]);
  const [witnessSignings, setWitnessSignings] = useState([""]);
  const [safetyData, setSafetyData] = useState();
  const [safetyModalVisible, setSafetyModalVisible] = useState(false);
  const [requiredTextArea, setRequiredTextArea] = useState([]);
  const [addressDisabled, setAddressDisabled] = useState(
    !!defaultObj?.addressOfDamagedProperty
  );
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [assignedTo, setAssignedTo] = useState([]);
  const [addressSelectionInfo, setAddressSelectionInfo] = useState({
    projectId: rowObject?.projectId || propProjId || "",
    accountId: rowObject?.accountId || "",
    scheduleId: rowObject?.scheduleId || "",
  });
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [teams, setTeams] = useState([]);
  const [teamModalVisible, setTeamModalVisible] = useState(false);
  const [changes, setChanges] = useState(false);
  const [safetyUsingIncident, setSafetyUsingIncident] = useState([]);
  const [tasksData, setTasksData] = useState([]);
  const [tasksVisible, setTasksVisible] = useState(false);
  const [inspections, setInspections] = useState(false);

  const disableAllFields =
    (safetyUsingIncident?.length > 0 &&
      Array.isArray(safetyUsingIncident) &&
      !!defaultId) ||
    false;

  const [videoTutorial, setVideoTutorial] = useState(false);
  const videoTutorialLink = programFields
    ?.filter((item) => item?.fieldName === "Portal Video Tutorials")[0]
    ?.fieldOptions.find((item) => item?.categoryName === "Incidents")
    ?.subCategories[0]?.videos.find(
      (item) => item?.videoName === "New Property Damage Incident"
    )?.videoLink;
  const [displayViewLogs, setDisplayViewLogs] = useState();
  // const [usersWithAccess] = useState(
  //   userConfiguration?.allUsers?.Items?.filter((person) => {
  //     return person?.routeConfig?.find(
  //       ({ title, write }) => title === "Incidents" && write === true
  //     );
  //   })
  //     .map((user, index) => ({
  //       id: user.cognitoUserId || user.identityId,
  //       value: user.nameOfUser,
  //       key: index,
  //     }))
  //     ?.filter((el) => el?.id !== authenticatedUser?.sub)
  // );

  const [form] = Form.useForm();

  const selectedForeman = Form.useWatch(
    "foremanOfCrewThatCausedTheDamage",
    form
  );
  const typeOfEquipment = Form.useWatch("typeOfEquipmentInvolved", form);

  const incidentsHotCredential = hotCredentials?.find(
    (obj) => obj.credentialId === "18"
  );

  useEffect(() => {
    visible &&
      disableAllFields &&
      message.warning(
        "This form is disabled because this Incident is being used in one or more Safety Reports and cannot be edited.",
        [5]
      );
  }, [disableAllFields, visible]);

  useEffect(() => {
    if (visible) {
      message.loading({
        content: "Opening Form...",
        key: "modalLoading",
      });
      Promise.allSettled([
        filterTables("programFields", "fieldName", "Incidents Dynamic Fields"),
        lazyFetch({
          tableName: "crews",
          listOfKeys: ["crewId", "crewName", "foreman", "members"],
          filterKey: "crewStatus",
          filterValue: "Active",
        }),
        lazyFetch({
          tableName: "projects",
          listOfKeys: [
            "projectName",
            "projectId",
            "projectManager",
            "accountId",
            "accountName",
          ],
        }),
        lazyFetch({
          tableName: "scheduling",
          listOfKeys: [
            "scheduleAddress",
            "scheduleId",
            "scheduleDays",
            "projectId",
          ],
        }),
        API.get("teams", "/teams"),
        !!defaultId && filterTables("safety", "incidentId", defaultId),
      ])
        .then(
          ([
            { value: res },
            { value: crews },
            { value: pr },
            { value: sch },
            { value: teams },
            { value: safety },
          ]) => {
            message.destroy("modalLoading");

            setTeams(teams);
            setProjects(pr);
            setSchedules(sch);
            if (!!defaultId) {
              setSafetyUsingIncident(safety);
            }
            setSchedulesForDate(
              filterSchedulesOnDate(
                sch,
                defaultObj?.dateOfPropertyDamage || dayjsNY()
              )?.schedulesForDay || []
            );

            let defaultObject = {};
            if (!!rowObject) {
              defaultObject = structuredClone(rowObject?.incidentObject);
              Object.keys(rowObject?.incidentObject || {})?.forEach((key) => {
                if (checkPicker(dynamicFields, key)) {
                  defaultObject[key] = dayjsNY(
                    rowObject?.incidentObject?.[key]
                  );
                }
              });
              setSign(defaultObject?.signature);
              // form.setFieldsValue(defaultObject);
            }
            let formFields = res[0]?.fieldOptions?.["Property Damage"] || [];
            let tmpDescriptions = {};
            for (let i = 0; i < formFields?.length; i++) {
              if (formFields[i].type === "textEditor") {
                tmpDescriptions[formFields[i].formItemName] =
                  defaultObject?.[formFields[i].formItemName] || "";
              }
            }
            setForemen(
              crews
                ?.filter((e) => e.foreman === true)
                ?.map(({ crewId, crewName, members }) => ({
                  label: crewName,
                  value: crewId,
                  members,
                }))
            );
            setCrewMembers(
              crews
                ?.filter((e) => e.foreman !== true)
                ?.map(({ crewId, crewName }) => ({
                  label: crewName,
                  value: crewId,
                }))
            );
            setDescriptionValues(tmpDescriptions);
            setDynamicFields(formFields);
          }
        )
        .catch((err) => {
          message.error({
            content: "Something Went Wrong!",
            key: "modalLoading",
          });
          console.error("Error Getting Fields: ", err);
          setVisible(false);
          updateDocumentTitle(); // reset document title to "Lead Manager";
        });

      const fieldObj = programFields?.reduce(
        (acc, { fieldName, fieldOptions }) => ({
          ...acc,
          [fieldName]: fieldOptions,
        }),
        {}
      );

      setSteps(
        fieldObj["Statuses Of Categories"]["Incidents"]
          ?.map(({ statusName }) => ({ title: statusName }))
          ?.filter((el) => {
            if (rowObject?.incidentStatus !== "Proceeded to Safety") {
              return el.title !== "Proceeded to Safety";
            } else {
              return el;
            }
          })
      );
      setStatusesColors(fieldObj["Statuses Of Categories"]["Incidents"]);
    }
  }, [visible]);

  const fieldsJSON = useMemo(() => {
    if (!dynamicFields) {
      return [];
    }

    return fixFormFields({
      dynamicFields,
      descriptionValues,
      crewMembers,
      selectedForeman,
      typeOfEquipment,
      foremen,
      setDescriptionValues,
      addressDisabled:
        addressDisabled ||
        (!!rowObject?.googleDriveFolderIds?.[folderDriveKeyName] &&
          !!driveFunctions.parentFolderId),
      userConfiguration,
      form,
      setRequiredTextArea,
      projects,
      schedulesForDate,
      changeDateOfIncident(date) {
        changeDateOfIncident({
          date,
          form,
          addressField: "addressOfDamagedProperty",
          addressSelectionInfo,
          setSchedulesForDate,
          setAddressSelectionInfo,
          schedules,
        });
      },
      onChangeAddress(val, option) {
        onChangeAddress({
          val,
          option,
          projects,
          schedulesForDate,
          setAddressSelectionInfo,
        });
      },
      setChanges,
      disableAllFields,
      addressSelectionInfo,
      navigate,
      defaultId,
      isDarkMode,
      propAddress,
    });
  }, [
    dynamicFields,
    descriptionValues,
    addressDisabled,
    crewMembers,
    selectedForeman,
    typeOfEquipment,
    foremen,
    userConfiguration,
    schedulesForDate,
    isDarkMode,
  ]);

  useEffect(() => {
    if (!!rowObject && !!defaultId) {
      Promise.allSettled([
        fetchAllData({
          endpoint: "editLogs",
          resultPosition: "editLogs",
          resultId: "logId",
          otherStringParams: {
            getMaxLimit: "true",
            filters: JSON.stringify([
              {
                conditions: [
                  {
                    column: "recordId",
                    value: rowObject?.incidentId,
                    formula: "is",
                  },
                ],
              },
            ]),
          },
        }),
        fetchAllData({
          endpoint: "tasksManagement",
          resultId: "taskId",
          otherStringParams: {
            filters: JSON.stringify([
              {
                conditions: [
                  {
                    column: "taskTopicId",
                    value: rowObject?.incidentId,
                    formula: "is",
                  },
                ],
              },
            ]),
          },
        }),
      ])
        .then(([{ value: logsRes }, { value: taskRes }]) => {
          setEditLogs(logsRes);
          setTasksData(taskRes);
        })
        .catch((err) => {
          console.error("Error Getting Logs: ", err);
          setEditLogs([]);
        });
    }
    if (!!rowObject && !!dynamicFields) {
      populateSafetyForm({
        form,
        pdfId,
        rowObject,
        statusKey: "incidentStatus",
        fieldsObject: "incidentObject",
        dynamicFields: fieldsJSON,
        setSign,
        statusesColors,
        setPdfId,
        setAssignedTo,
        setCurrentStep,
        setSelectedTeam,
        setUploadedFiles,
        setWitnessCount,
        setWitnessSignings,
        setWitnessStatements,
        setDescriptionValues,
      });
      if (!!defaultId) {
        if (!!rowObject?.googleDriveFolderIds?.[folderDriveKeyName]) {
          setDivClickable(false);
        }
        let { viewLogs = {} } = rowObject;
        viewLogs = {
          ...viewLogs,
          [authenticatedUser?.sub]: {
            lastSeen: Date.now(),
            name: userConfiguration?.nameOfUser,
          },
        };

        API.put("incidents", `/incidents/${rowObject?.incidentId}`, {
          body: {
            viewLogs,
          },
        })
          .then(() => {
            setDisplayViewLogs(viewLogs);
          })
          .catch((err) => console.error("Error Updating View Logs: ", err));
      }
    }
  }, [rowObject, dynamicFields]);

  async function onSave(saveDraft = false) {
    let findRequiredArea = Object.keys(descriptionValues)?.filter((key) => {
      if (requiredTextArea.includes(key) && !descriptionValues[key]) {
        return key;
      }
    });

    if (findRequiredArea?.length > 0 && !saveDraft) {
      findRequiredArea?.forEach((key) => {
        message.error(
          `${
            dynamicFields?.find((el) => el.formItemName === key)?.label
          } is required`
        );
      });
      return;
    }
    if (!sign && !saveDraft) {
      message.error("Please Sign this Safety Report");
      return;
    }

    let allFolders = null;
    if (
      !driveFunctions.parentFolderId &&
      !rowObject?.googleDriveFolderIds?.[folderDriveKeyName] &&
      !!form.getFieldValue("addressOfDamagedProperty")
    ) {
      try {
        const { folders } = await driveFunctions.create({
          parentFolderName: `${
            form.getFieldValue("addressOfDamagedProperty") || "Draft"
          } ${dayjsNY().format("DD/MM/YYYY HH:mm:ss")}`,
          parentId: incidentsHotCredential?.credentialValue,
        });
        allFolders = folders;
      } catch (err) {
        console.error("err", err);
        allFolders = null;
      }
    }

    const { parentFolderId } = driveFunctions;
    const driveFolderCondition = () => {
      if (rowObject?.googleDriveFolderIds?.[folderDriveKeyName]) {
        return rowObject?.googleDriveFolderIds;
      } else if (parentFolderId) {
        return driveFunctions?.allFolders;
      } else {
        return allFolders;
      }
    };

    const { tmpBody = {} } = safetyBody({
      table: "incidents",
      form,
      sign,
      assignedTo,
      currentStep,
      selectedTeam,
      witnessCount,
      nameKey: "addressOfDamagedProperty",
      category: "Property Damage Incident",
      projects,
      schedules,
      statusesColors,
      saveDraft,
      dynamicFields,
      witnessSignings,
      requiredTextArea,
      witnessStatements,
      userConfiguration,
      descriptionValues,
      googleDriveFolderIds: driveFolderCondition(),
      formattedFiles,
      addressSelectionInfo,
    });

    message.loading({
      content: "Saving...",
      key: "safetyLoading",
      duration: 0,
    });

    if (!!defaultId) {
      setVisibleCreationProgress(rowObject);
      setCancelModalVisible(false);

      await editIncident({
        tmpBody,
        nameKey: "addressOfDamagedProperty",
        rowObject,
        category: "Property Damage",
        editAction: "onPropertyDamageEdit",
        authenticatedUser,
        userConfiguration,
        customLogObj(data) {
          return {
            foremanOfCrewThatCausedTheDamage:
              foremen?.find(
                ({ value }) => value === data?.foremanOfCrewThatCausedTheDamage
              )?.label || "",
            crewMembersThatCausedDamage: data?.crewMembersThatCausedDamage?.map(
              (el) => crewMembers?.find((e) => e?.value === el)?.label
            ),
            reportedPreparedBy: userConfiguration?.allUsers?.Items?.find(
              ({ identityId }) => identityId === data?.reportedPreparedBy
            ),
          };
        },
        updateProgressStatus,
        refreshTable,
        saveAddedLogs,
      });
      onClose();
    } else {
      setCancelModalVisible(false);

      await createIncident({
        dispatch,
        driveRequest,
        tmpBody,
        nameKey: "addressOfDamagedProperty",
        category: "Property Damage",
        assignedTo,
        preferences,
        saveDraft,
        createAction: "onPropertyDamageCreation",
        loggedOnTasks,
        // onClose,
        programFields,
        requestId,
        requestUploads,
        userConfiguration,
        authenticatedUser,
        folderDriveKeyName,
        refreshTable(res) {
          refreshTable(
            [res].reduce(
              (acc, val) => [
                ...acc,
                {
                  ...val,
                  createdBy: val?.createdBy.name,
                  projectName: val?.incidentObject?.projectName || "N/A",
                  scheduleAddress:
                    val?.incidentObject?.scheduleAddress || "N/A",
                  accountName: val?.incidentObject?.accountName || "N/A",
                },
              ],
              []
            )
          );
        },
        setSafetyData,
        addressSelectionInfo,
        // setConfirmationModal,
        handleSavedRecord,
        setVisibleCreationProgress,
        updateProgressStatus,
        hideProgress,
        saveAddedLogs,
      }).then(() => {
        nextStepHandler();
      });
    }
    message.destroy("projectDriveFolders");
    message.destroy("windowMessages");
  }

  const onTeamSelect = (_, data) => {
    setSelectedTeam((prev) => [
      ...prev,
      {
        value: data.value,
        members: data?.members?.map((el) => el),
      },
    ]);
  };

  const onTeamDeselect = (_, data) => {
    setSelectedTeam((prev) => prev?.filter((el) => el.value !== data.value));
    if (form.getFieldValue("team").length < 1) {
      setSelectedTeam([]);
    }
  };

  async function onClose(param = {}) {
    await onCloseModal({
      form,
      param,
      driveRequest,
      nameKey: "addressOfDamagedProperty",
      folderDriveKeyName,
      objectKey: "incidentObject",
      rowObject,
      driveFunctions,
      filesToBeDeleted,
      setVisible,
      setUploadedFiles,
      updateDocumentTitle,
      setFilesToBeDeleted,
      setCancelModalVisible,
      saveAddedLogs,
    });
  }

  //upload button handler
  const uploaderButton = document.querySelector(".uploaderButton");
  async function onContinueToUpload() {
    form
      .validateFields(["addressOfDamagedProperty"])
      .then(async () => {
        const { folders } = await driveFunctions.create({
          parentFolderName: `${
            form.getFieldValue("addressOfDamagedProperty") || "Not Set"
          } ${dayjsNY().format("DD/MM/YYYY HH:mm:ss")}`,
          parentId: incidentsHotCredential?.credentialValue,
        });
        setAddressDisabled(true);
        setCancelModalVisible(false);
        setDivClickable(false);
        setTimeout(() => {
          if (!!uploaderButton) {
            uploaderButton.click();
          }
        }, 1000);
      })
      .catch((err) => {
        setCancelModalVisible(false);
        console.error("Error Upload: ", err);
      });
  }

  async function onDeleteRow() {
    if (!!defaultId) {
      setCancelModalVisible(false);
      setActionType("Delete");

      message.loading({
        content: "Deleting...",
        key: "messageDeleting",
      });
      await deleteIncident({
        dispatch,
        nameKey: "addressOfDamagedProperty",
        category: "Property Damage",
        rowObject,
        deleteAction: "onPropertyDamageDeletion",
        // onClose,
        notifications,
        userConfiguration,
        authenticatedUser,
        refreshTable,
        setVisibleCreationProgress,
        updateProgressStatus,
      });
    }
  }

  const filterUsersByAccess = userConfiguration?.allUsers?.Items?.reduce(
    (acc, user) =>
      !!user?.routeConfig?.find(
        ({ title, write }) => title === "Incidents" && write === true
      ) &&
      user.cognitoUserId !== userConfiguration.cognitoUserId &&
      !user?.isSuspended
        ? [
            ...acc,
            {
              cognitoUserId: user?.cognitoUserId,
              nameOfUser: user?.nameOfUser,
              userName: user?.userName,
            },
          ]
        : acc,
    []
  );

  const sections = preventDuplicate(fieldsJSON, "category")?.map((e, index) => {
    let filteredFields = fieldsJSON.filter((a) => a.category === e.category);
    if (filteredFields.some((el) => !!el.index)) {
      filteredFields = filteredFields.sort((a, b) => a.index - b.index);
    }
    return (
      <BorderedTextCard
        {...{
          title: e.category || category,
          key: index,
          className: toCamelCase(e.category),
          isDarkMode,
        }}
      >
        {RenderDynamicComponents(filteredFields, { form })}

        {e.category?.toLowerCase() === "additional information" && (
          <>
            <InputComponent
              mode="multiple"
              required
              showSearch
              label="Assign To"
              placeholder="Select some users"
              dropdownClassName={isDarkMode && "darkDropDown"}
              type="select"
              formItemName="assignedTo"
              onChange={(value, option) => setAssignedTo(option)}
              options={filterUsersByAccess?.map(
                ({ cognitoUserId, nameOfUser, userName }, i) => (
                  <Option
                    key={i}
                    label={nameOfUser}
                    value={nameOfUser}
                    cognitoUserId={cognitoUserId}
                    userName={userName}
                  />
                )
              )}
              disabled={disableAllFields}
            />
            <div className="signings">
              <div className="signContainer">
                <img src={sign} alt="" />
              </div>
              <SignControllerButtons
                {...{
                  setSignatureModalVisible,
                  sign,
                  setSign,
                  setWhichSignToChange,
                  existingSignature: "",
                  signDisabled: disableAllFields,
                  removeDisabled: disableAllFields,
                }}
              />
            </div>
          </>
        )}
        {e.category?.toLowerCase() === "general information" && (
          <div style={{ display: "flex", gap: "20px" }}>
            <InputComponent
              mode="multiple"
              type="select"
              label="Team"
              required={true}
              formItemName="team"
              dropdownClassName={isDarkMode && "darkDropDown"}
              allowClear={false}
              customOptions={teams?.map((team) => ({
                value: team?.teamName,
                label: team?.teamName,
                members: team?.members?.map(
                  ({ identityId, nameOfUser, cognitoUserId = "" }) => ({
                    identityId,
                    nameOfUser,
                    cognitoUserId,
                  })
                ),
              }))}
              onSelect={onTeamSelect}
              onDeselect={onTeamDeselect}
              showSearch={true}
              disabled={disableAllFields}
            />
            {!!selectedTeam?.length && (
              <MondayButton
                {...{
                  onClick: () => {
                    setTeamModalVisible(true);
                  },
                  Icon: <PlusIcon />,
                  style: { marginTop: "25px" },
                }}
                disabled={disableAllFields}
              >
                Team Members
              </MondayButton>
            )}
          </div>
        )}
      </BorderedTextCard>
    );
  });

  function createPdfBody() {
    let tmpFields = [
      ...fieldsJSON,
      {
        label: "Team Members",
        value: selectedTeam?.map((el) =>
          el?.members?.map((e) => e?.nameOfUser)
        ),
        category: "General Information",
      },
    ];

    const configPdfData = preventDuplicate(tmpFields, "category")?.map((el) => {
      return pdfBorderedSection({
        title: `${el.category}`,
        body: incidentPdfBody({
          title: el?.category,
          fieldsJSON: tmpFields,
          rowObject,
          foremen,
          crewMembers,
          userConfiguration,
        }),
      });
    });

    const witnessList = separateWitnessForPdf({
      defaultId,
      rowObject,
      witnessCount,
      witnessSignings,
    });

    const witnessPdfData = witnessList?.map((el) =>
      pdfBorderedSection({
        title: "Witness",
        body: el,
      })
    );

    return configPdfData?.concat(!!witnessList?.length ? witnessPdfData : []);
  }

  async function onGeneratePdf() {
    await incidentPdf({
      base64,
      pdfId,
      accessToken,
      category: "Property Damage",
      rowObject,
      driveFunctions,
      setPdfId,
      folderDriveKeyName,
      createPdfBody,
    });
  }
  function onEmailSave({ e, key, type, setLoading }) {
    if (type.toUpperCase() === "EMAIL") {
      message.success("Email sent");
      setLoading(false);
      setEmailBox(false);
    }
  }
  const isMobileView = useMediaQuery({
    query: "(max-width: 575px)",
  });

  function checkForChanges() {
    if (!defaultId) {
      let requiredFields = fieldsJSON
        ?.filter(
          ({ category, required }) =>
            category === "General Information" && required
        )
        ?.map(({ formItemName }) => formItemName);
      let fields = form.getFieldsValue(requiredFields);
      if (Object.values(fields).some((value) => !!value)) {
        setCancelModalVisible(true);
      } else {
        onClose();
      }
    } else {
      setCancelModalVisible(true);
    }
  }

  function onCancel() {
    if (!!changes || !!driveFunctions.parentFolderId) {
      checkForChanges();
    } else if (!changes && !defaultId) {
      checkForChanges();
    } else {
      onClose();
    }
  }

  async function sendEmailNotification() {
    await safetyEmailNotif({
      table: "incidents",
      category: "Property Damage Incident",
      rowObject,
      defaultId,
      setEditLogs,
      authenticatedUser,
      userConfiguration,
      saveAddedLogs,
    });
  }

  return (
    <Modal
      {...{
        open: visible,
        title: (
          <CustomModalHeader
            title={"Property Damage Incident Form"}
            onClick={() => {
              !!videoTutorialLink
                ? setVideoTutorial(true)
                : message.info("No video tutorial available for this form");
            }}
          />
        ),
        afterOpenChange: (event) => {
          event &&
            updateDocumentTitle({ newTitle: "Property Damage Incident Form" });
        },
        closable: true,
        centered: true,
        style: {
          display: !!dynamicFields ? "" : "none",
        },
        closeIcon: <XIcon />,
        wrapClassName: `safetyDynamicFormsWrap ${
          isDarkMode && "safetyDynamicFormsWrapDark"
        }`,
        className: "safetyDynamicForms",
        onCancel,
        footer: modalFooter({
          onCancel,
          safetyUsingIncident,
          changes,
          isDarkMode: isDarkMode,
          onSave() {
            checkSaveDraft({
              changes,
              form,
              descriptionValues,
              requiredTextArea,
              saveFunction: onSave,
              setVisible,
              setCancelModalVisible,
              populatedInputFields: dynamicFields,
              incidentId: "12",
            });
          },
          form,
          onDelete() {
            !!disableAllFields
              ? message.error({
                  content:
                    "This Incident is linked to a Safety Report. Please delete the Safety first.",
                  key: "messageDeleting",
                })
              : setCancelModalVisible("Delete");
          },
          onShowLogs() {
            setEditLogsVisibility(true);
          },
          onGeneratePdf,
          onEmail() {
            setEmailBox(true);
            !pdfId && message.info("There is no PDF file for this report");
          },
          hasReport: true,
          onReportView: () => setVisibleReport(true),
          viewTasks() {
            setTasksVisible(true);
          },
          viewInspections() {
            setInspections(true);
          },
          status: rowObject?.incidentStatus,
          defaultId,
          selectedTeam,
          hasInspections: true,
          openToDos: () => setToDoVisible(true),
          incidentName: rowObject?.incidentName,
          topicCategory: "Property Damage",
        }),
      }}
    >
      <LoadableComp loading={!dynamicFields}>
        {isMobileView ? (
          <div className="stepper-section-mobile">
            <InputComponent
              {...{
                label: "Status",
                type: "select",
                formItemName: "stepper",
                dropdownClassName: isDarkMode && "darkDropDown",
                customOptions: statusesColors?.map(
                  ({ statusId, statusName }) => ({
                    value: statusId,
                    label: statusName,
                  })
                ),
                onSelect(e) {
                  setCurrentStep(e);
                  setChanges(true);
                },
                defaultValue: currentStep,
                disabled: disableAllFields,
              }}
            />
          </div>
        ) : (
          <div className="stepper-section">
            <Stepper
              {...{
                currentStep,
                setCurrentStep(e) {
                  form.setFieldValue("stepper", e);
                  setCurrentStep(e);
                  setChanges(true);
                },
                steps,
                statusesColors,
                stepperClassName: "stepper-component",
                stepRenderer: false,
                disabled: disableAllFields,
              }}
            />
          </div>
        )}
        <Form
          form={form}
          onFieldsChange={() => {
            setChanges(true);
          }}
        >
          <div className="form-section">
            {sections}
            <StatementsFields
              {...{
                form,
                fieldCount: witnessCount,
                setFieldCount: setWitnessCount,
                dynamicStatements: witnessStatements,
                dynamicSignings: witnessSignings,
                setDynamicStatements: setWitnessStatements,
                setDynamicSignings: setWitnessSignings,
                disableFields: disableAllFields,
                isDarkMode,
              }}
            />
            {/* {!defaultId ? ( */}
            <BorderedTextCard
              title="Notes"
              className="notesCard"
              isDarkMode={isDarkMode}
            >
              <IncidentComments
                {...{
                  form,
                  nameKey: "addressOfDamagedProperty",
                  rowObject,
                  setChanges,
                  userConfiguration,
                  refreshTable,
                  setChanges,
                  table: "incidents",
                }}
              />
            </BorderedTextCard>
            {/* )} */}
          </div>
          <div className="uploaderSection">
            <div
              style={{
                position: "relative",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  zIndex: divClickable ? 10 : 0,
                }}
                onClick={() => {
                  divClickable && setCancelModalVisible("Upload");
                }}
              ></div>
              <DocUploader
                {...{
                  uploadedFiles: [...uploadedFiles, ...(requestUploads || [])],
                  onPickerSuccess: ({ uploadedFiles }) => {
                    setChanges(true);
                    onPickerSuccess({ uploadedFiles });
                  },
                  onDelete,
                  disabled:
                    ((!rowObject?.googleDriveFolderIds?.[folderDriveKeyName] ||
                      !rowObject?.googleDriveFolderIds) &&
                      !driveFunctions.parentFolderId) ||
                    disableAllFields,
                  onChange() {},
                  folderId:
                    rowObject?.googleDriveFolderIds?.[folderDriveKeyName] ||
                    rowObject?.googleDriveFolderIds ||
                    driveFunctions.parentFolderId,
                }}
              />
            </div>
          </div>
        </Form>
        <SignatureModal
          visible={signatureModalVisible}
          setVisible={setSignatureModalVisible}
          setSign={whichSignToChange?.setFunc}
          isDarkMode={isDarkMode}
        />
        {editLogsVisibility && (
          <DocumentationLogsModal
            {...{
              visible: editLogsVisibility,
              setVisible: setEditLogsVisibility,
              logsData: editLogs,
              title: "Property Damage Logs",
              viewLogs: displayViewLogs,
            }}
          />
        )}

        {videoTutorial && (
          <PlayVideoTutorial
            {...{
              visible: videoTutorial,
              setVisible: setVideoTutorial,
              url: videoTutorialLink,
              title: "Property Damage Incident Tutorial",
            }}
          />
        )}
        <WarningModal
          visible={!!cancelModalVisible}
          setVisible={setCancelModalVisible}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
          darkMode={isDarkMode}
        >
          <div className="logout-modal-body">
            <span>
              <WarningIcon />
            </span>
            {cancelModalVisible === "Upload" ? (
              <>
                <strong style={{ textAlign: "center" }}>
                  If you choose to continue, you will not be able to change the
                  property address anymore
                </strong>
                <p>Are you sure you want to continue?</p>
              </>
            ) : cancelModalVisible === "Delete" ? (
              <p>Are you sure you want to delete?</p>
            ) : defaultId && cancelModalVisible !== "saveDraft" ? (
              <p>Are you sure you want to cancel?</p>
            ) : (
              <div style={{ textAlign: "center" }}>
                <strong>
                  {cancelModalVisible !== "saveDraft"
                    ? "You are about to exit the form with unsaved data"
                    : "You have made some changes"}
                </strong>
                <p>Would you like to save the current changes as new?</p>
              </div>
            )}
            <div className="buttons">
              <MondayButton
                onClick={() => {
                  if (defaultId || cancelModalVisible === "Upload") {
                    setCancelModalVisible(false);
                  } else {
                    onClose({
                      deleteAction: true,
                    });
                  }
                }}
                Icon={<XIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton
                onClick={() => {
                  if (cancelModalVisible === "Upload") {
                    onContinueToUpload();
                  } else if (cancelModalVisible === "Delete") {
                    onDeleteRow();
                  } else {
                    if (defaultId && cancelModalVisible !== "saveDraft") {
                      onClose({
                        cancel: true,
                      });
                    } else {
                      onSave(true);
                    }
                  }
                }}
                Icon={<TickIcon />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      </LoadableComp>
      {teamModalVisible && (
        <DynamicTeamModal
          {...{
            open: teamModalVisible,
            setOpen: setTeamModalVisible,
            selectedTeam,
            setSelectedTeam,
            ClearOptions: () => {
              setTeamModalVisible(false);
            },
          }}
        />
      )}
      {confirmationModal && (
        <AskForTaskModal
          {...{
            title: "Safety Request",
            span: "The next step will be creating a Safety Application for this incident, do you wish to continue?",
            askTaskModal: confirmationModal,
            createTask() {
              setSafetyModalVisible(true);
              setConfirmationModal(false);
              // setVisible(false);
            },
            cancelTaskModal() {
              setConfirmationModal(false);
              setVisible(false);
              updateDocumentTitle(); // reset document title to "Lead Manager";
            },
          }}
        />
      )}
      {safetyModalVisible && (
        <SafetyModal
          {...{
            setEdit() {
              onClose();
              setSafetyModalVisible(false);
            },
            category: "Property Damage",
            safetyData,
            updateIncidentStatus: refreshTable,
          }}
        />
      )}
      {emailBox && (
        <div className="safetyEmailBox">
          <EmailBox
            {...{
              // incident: true,
              // isFull: true,
              onClose: () => setEmailBox(false),
              setEmails: () => {},
              rowData: rowObject,
              onSave: (e, type, setLoading) => {
                onEmailSave({ e, type, setLoading });
                // setEmailBox(false);
              },
              isRedirected: [
                pdfId,
                ...uploadedFiles
                  ?.concat(requestUploads || [])
                  ?.map((el) => el?.id),
              ],
              isFromCharge: true,
              sendEmailNotification,
              proppedTemplateCat: "Incidents",
            }}
          />
        </div>
      )}
      {!!tasksVisible && (
        <InspectionModal
          {...{
            visible: tasksVisible,
            onCancel() {
              setTasksVisible(false);
            },
            title: `Tasks for ${rowObject?.incidentName}`,
            width: 1600,
            footer: false,
            closable: true,
            closeIcon: <XIcon fill="#333238" />,
            className: "header-tasks-modal-light",
          }}
        >
          <div style={{ height: "65dvh" }}>
            <TasksBasePage
              {...{
                customTaskProps: {
                  rowData: rowObject,
                  taskTopic: "Incidents",
                  taskTopicId: rowObject?.incidentId,
                  taskRelatedTo: rowObject?.incidentName || "Save Draft",
                },
              }}
            />
          </div>
        </InspectionModal>
      )}
      {!!inspections && (
        <InspectionModal
          {...{
            visible: inspections,
            onCancel() {
              setInspections(false);
            },
            title: `Inspections for ${rowObject?.incidentName}`,
            width: 1600,
            footer: false,
            closable: true,
            closeIcon: <XIcon fill="#333238" />,
            className: "header-tasks-modal-light",
          }}
        >
          <InspectionBasePage
            {...{
              filterKey: "jobsiteAddress",
              filterValue: defaultObj?.addressOfDamagedProperty || "",
              filterProjectId: defaultObj?.projectId || "",
              isTab: true,
            }}
          />
        </InspectionModal>
      )}

      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: "Incidents",
            actionType:
              actionType === "Delete"
                ? "Delete"
                : !!defaultId
                ? "Update"
                : "Create",
            visibleCreationProgress,
            creationProgresses,
            closeModal: () => {
              setVisibleCreationProgress(false);
              showNextStep
                ? setConfirmationModal(true)
                : actionType === "Delete"
                ? onClose({ deleteAction: true, deletePostLogsBody })
                : onClose();
            },
          }}
        />
      )}
    </Modal>
  );
}

export default NewPropertyDamageIncidentModal;
