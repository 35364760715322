import "./drive-items.scss";
import { Badge, Button, Spin, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Buffer } from "buffer";

import { DriveIcon } from "../../../../../../assets";
import { driveApi } from "../../../../../../../../../integrations/DriveRequest";
import { getLength } from "../../../../../../functions";
import { blobToBase64 } from "../../../../../../../utils";
import { useEmailTemplates } from "../../../../providers/EmailTemplatesProvider";
import CustomDropdown from "../../../custom-dropdown/CustomDropdown";
import { useAttachments } from "../../../../providers/AttachmentsProvider";
import { useDriveDocuments } from "../../../../providers/DriveDocumentsProvider";
import useDynamicFieldsData from "../../../../hooks/useDynamicFieldsData";
import { useFilePreviewModal } from "../../../../providers/FilePreviewModalProvider";

import ChooseFilesModal from "./drive-items-modal/choose-files-modal/ChooseFilesModal";

function DriveItems({ rowData, recordDetails }) {
  const [chooseFilesVisible, setChooseFilesVisible] = useState(false);
  const [selectedDocumentationField, setSelectedDocumentationField] = useState({
    folderName: "",
    folderId: "",
  });
  const [accessibleFolders, setAccessibleFolders] = useState([]);

  const { setFilePreview } = useFilePreviewModal();
  const { driveDocuments, setDriveDocuments } = useDriveDocuments();
  const { setAttachments } = useAttachments();
  const { loading: temlatesLoading } = useEmailTemplates();
  const { dynamicFields, driveItems, setDriveItems, loading } =
    useDynamicFieldsData(rowData, recordDetails);

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { accessToken } = useSelector((state) => state.accessToken);
  const driveRequest = driveApi({ accessToken });

  const items = accessibleFolders.map(
    ({ dynamicFieldLabel, dynamicFieldValue }) => ({
      key: dynamicFieldLabel,
      label: (
        <Tooltip title={dynamicFieldLabel} placement="left">
          <Button
            type="link"
            className="dropdown-item-button"
            onClick={() => {
              setChooseFilesVisible(true); // Show the file selection modal or component
              setSelectedDocumentationField({
                // Set the selected documentation field
                folderName:
                  recordDetails?.categoryType !== "inspections"
                    ? dynamicFieldLabel // If not inspections, use the dynamic field label
                    : rowData?.inspectionId, // If inspections, use the inspection ID
                folderId:
                  recordDetails?.categoryType !== "inspections"
                    ? rowData?.googleDriveFolderIds?.[dynamicFieldValue] // Get folder ID from Google Drive folder IDs
                    : rowData[dynamicFieldValue], // If inspections, use the value from rowData
              });
            }}
            data-testid="dropdown-item-label" // For testing purposes
          >
            {dynamicFieldLabel}
          </Button>
        </Tooltip>
      ),
    })
  );

  const toolTipData =
    driveDocuments?.length > 0
      ? driveDocuments?.map(({ name, blob, type, id }) => (
          <p
            key={id}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSelectedFile({
                src: blob,
                mimeType: type,
              });
              setFilePreview(true);
            }}
            data-testid={`drive-document-item-${id}`}
          >
            {name}
          </p>
        ))
      : "Drive";

  useEffect(() => {
    const filteredDynamicFields = dynamicFields?.filter(
      ({ dynamicFieldRenderFor }) => dynamicFieldRenderFor === "documentation"
    );

    if (filteredDynamicFields?.length > 0) {
      (async () => {
        try {
          const results = await Promise.allSettled(
            filteredDynamicFields.map(
              async ({ dynamicFieldLabel, dynamicFieldValue }) => {
                const folderId =
                  recordDetails?.categoryType !== "inspections"
                    ? rowData?.googleDriveFolderIds?.[dynamicFieldValue] // Get folder ID from Google Drive folder IDs
                    : rowData[dynamicFieldValue]; // If inspections, use the value from rowData

                if (!folderId) {
                  return null; // Skip if folderId is missing
                }

                try {
                  const response = await driveRequest.permissionList(folderId);
                  const data = await response.json(); // Parse the response JSON

                  if (data?.permissions) {
                    const permission = data.permissions.find(
                      ({ emailAddress }) =>
                        emailAddress === userConfiguration?.userName
                    );

                    if (permission) {
                      return {
                        nameOfUser: userConfiguration?.nameOfUser,
                        userName: userConfiguration?.userName,
                        permissionId: permission.id,
                        type: permission.role,
                        dynamicFieldValue,
                        dynamicFieldLabel,
                        folderId,
                      };
                    }
                  }
                } catch (error) {
                  console.error(
                    "Error fetching permissions for folder:",
                    folderId,
                    error
                  );
                }

                return null; // Return null if no permission is found
              }
            )
          );

          const successfulResults = results
            .filter(
              (result) => result.status === "fulfilled" && result.value !== null
            )
            .map((result) => result.value);

          if (successfulResults?.length > 0) {
            setAccessibleFolders(successfulResults);
          }
        } catch (error) {
          console.error("Error in Promise.allSettled:", error);
        }
      })();
    }
  }, [dynamicFields]);

  if (temlatesLoading)
    return (
      <div className="drive-items-loading-container">
        <Badge status="processing" className="loading-badge" />
        <DriveIcon className="drive-icon" />
      </div>
    );

  return (
    <>
      <Tooltip
        placement="leftBottom"
        title={toolTipData}
        data-testid="drive-documents-tooltip"
      >
        <Badge count={driveDocuments?.length}>
          {loading ? (
            <Spin size="small" />
          ) : (
            <CustomDropdown items={items} placement="top" trigger={["click"]}>
              <a
                onClick={(e) => e.preventDefault()}
                data-testid="drive-items-dropdown-trigger"
              >
                <DriveIcon className="drive-icon" />
              </a>
            </CustomDropdown>
          )}
        </Badge>
      </Tooltip>

      {chooseFilesVisible && (
        <ChooseFilesModal
          setVisible={setChooseFilesVisible}
          files={driveItems}
          setFiles={setDriveItems}
          type="drive"
          onCancel={() =>
            setSelectedDocumentationField({ folderName: "", folderId: "" })
          }
          folderName={selectedDocumentationField?.folderName}
          folderId={selectedDocumentationField?.folderId}
          getSelected={async (e, type) => {
            let elements = e.map(({ id, name }) => {
              let src;
              return driveRequest.getDriveItem(id, { alt: "media" }).then((r) =>
                r.blob().then(async (e) => {
                  await blobToBase64(e).then((res) => (src = res));
                  return {
                    name: name,
                    blob: src,
                    size: getLength(
                      Buffer.from(src.substring(src.indexOf(",") + 1))
                    ),
                    type: e.type,
                    mimeType: e.type,
                    id: id,
                    status: 400,
                  };
                })
              );
            });

            let finalElements = await Promise.all(elements).then((res) => res);

            if (finalElements.length > 0) {
              setAttachments((prev) => {
                return [...prev, ...finalElements];
              });
            }

            setDriveDocuments((prev) => {
              const fileIds = new Set(prev.map(({ id }) => id));

              const uniqueFiles = finalElements.filter(
                ({ type, id }) => type !== "Folder" && !fileIds.has(id)
              );

              return [...prev, ...uniqueFiles];
            });

            setChooseFilesVisible(false);
            setSelectedDocumentationField({ folderName: "", folderId: "" });
          }}
        />
      )}
    </>
  );
}

export default DriveItems;
