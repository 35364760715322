import { message } from "antd";
import { useSelector } from "react-redux";
import { useEditLogs } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import HeaderFilter from "./HeaderFilter/HeaderFilter";
import ProposalCard from "./ProposalCards/ProposalCard";
import { fetchData } from "../../SidebarPages/Fleet/utils";
import React, { useEffect, useMemo, useState } from "react";
import { SmallLoadableComp } from "../../Sidebars/components";
// import BodyStatistics from "./BodyStatistics/BodyStatistics";
import HeaderStatistics from "./HeaderStatistics/HeaderStatistics";
import { CardTemplate } from "../../pages/Settings/settingsComponents/Management/EditUser/components";
import { proposalDeleteHandler } from "../../SidebarPages/Estimations/components/ProposalBuilder/utils/requests";

import "./styles/WaitingRoomProposal.scss";

function WaitingRoomProposal() {
  const navigate = useNavigate();
  const { saveAddedLogs } = useEditLogs();
  const { isDarkMode } = useSelector((state) => state?.darkMode);
  const { userConfiguration } = useSelector((state) => state?.userConfig);

  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    jobSiteAddress: undefined,
    proposalStatus: undefined,
  });

  const logs = (item) => {
    const estAddress = item?.otherDetails?.jobSiteAddress || "";
    const logLabel = `${estAddress} - (${item?.otherDetails?.title || ""})`;
    return {
      actionType: "Delete",
      category: `Proposal Builder`,
      recordId: item?.proposalId,
      recordName: logLabel,
      label: estAddress,
      updatedAt: Date.now(),
      currentData: {
        [userConfiguration?.nameOfUser]: "",
      },
      previousData: {
        [userConfiguration?.nameOfUser]: "Deleted Proposal",
      },
      updatedKeys: [],
    };
  };

  function handleFilterChange(key, value) {
    if (key === "reset") {
      setFilters({
        jobSiteAddress: undefined,
        proposalStatus: undefined,
      });
    } else {
      setFilters({
        ...filters,
        [key]: value,
      });
    }
  }

  const filteredCards = useMemo(() => {
    if (!filters?.jobSiteAddress && !filters?.proposalStatus) {
      return rowData;
    }
    return (
      rowData?.filter((item) => {
        return (
          item?.otherDetails?.jobSiteAddress?.includes(
            filters?.jobSiteAddress || ""
          ) && item?.proposalStatus.includes(filters?.proposalStatus || "")
        );
      }) || []
    );
  }, [rowData, filters]);

  async function handleDeleteProposal(item, callback) {
    await proposalDeleteHandler(item?.proposalId)
      .then(() => {
        setRowData(rowData.filter((el) => el?.proposalId !== item?.proposalId));
        saveAddedLogs(logs(item));
        message.success("Proposal Deleted Successfully");
        callback();
      })
      .catch((error) => {
        console.error(
          "WaitingRoomProposal ~ handleDeleteProposal ~ error",
          error
        );
        message.error("Failed to delete Proposal");
        callback();
      });
  }

  useEffect(() => {
    setLoading(true);
    fetchData("proposalBuilds")
      .then((data) => {
        setRowData(data?.sort((a, b) => b?.createdAt - a?.createdAt));
        setLoading(false);
      })
      .catch((error) => {
        console.error("WaitingRoomProposal ~ fetchData ~ error", error);
        setLoading(false);
      });
  }, []);

  return (
    <div className={`waiting-room-proposal ${isDarkMode ? "dark" : ""}`}>
      <HeaderStatistics
        {...{
          title: "PROPOSAL BUILDER",
          description:
            "Here, you can view current proposals and create new ones.",
          onGoBack: () => {
            navigate("/");
          },
        }}
      />
      <div className="waiting-room-proposal_body">
        <HeaderFilter
          {...{
            handleFilterChange,
            filters,
            rowData,
          }}
        />
        {/* <div className="waiting-room-proposal_body-statistics">
          <BodyStatistics
          {...{
            rowData: filteredCards,
            }}
            />
            </div> */}
        <CardTemplate
          {...{
            title: "Proposals List",
            isDarkMode,
            className: "waiting-room-proposal_body-cards",
          }}
        >
          <SmallLoadableComp loading={loading}>
            {filteredCards.map((item, index) => {
              return (
                <ProposalCard
                  key={index}
                  title={item?.proposalStatus ?? item?.otherDetails?.title}
                  item={item}
                  handleDeleteProposal={handleDeleteProposal}
                />
              );
            })}
          </SmallLoadableComp>
        </CardTemplate>
      </div>
    </div>
  );
}

export default WaitingRoomProposal;
