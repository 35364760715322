import {
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";

import {
  ratesGridColumns,
  roleRateRelations,
  getNotificationForRates,
} from "./utils";
import { InfoIconBlue } from "../../../../../../Header/forms/Scheduling/Subcomponents/SelectView/newModals/TrucksModal/HelpingComponents/icons";

import "./JobsiteRatesGrid.scss";

const JobsiteRatesGrid = forwardRef(function (props, ref) {
  const {
    ratesForRole = {},
    getRatesApi = () => {},
    paginationPageSize,
    editable = true,
    showInfo = true,
    selectedClient,
    employees = [],
    clients = [],
    isDarkMode,
  } = props;

  const { programFields } = useSelector((state) => state.programFields);
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState();
  const [roles, setRoles] = useState([]);

  const onGridReady = useCallback((param) => {
    setGridApi(param.api);
    getRatesApi(param.api);
  }, []);

  const getRowId = useCallback((param) => {
    return param?.data?.role;
  }, []);

  function onCellValueChanged(event) {
    const { role, rate } = event.data;
    if (!!roleRateRelations?.[role]) {
      let emptyValues = [];
      gridApi.forEachNode(({ data }) => {
        if (roleRateRelations[role].includes(data.role)) {
          if (Number(data.rate) === 0) {
            emptyValues.push(data.role);
          }
        }
      });

      if (emptyValues?.length) {
        getNotificationForRates({
          baseRate: role,
          affectedRates: emptyValues,
          acceptHandler() {
            gridApi.applyTransaction({
              update: emptyValues.map((role) => ({ role, rate })),
            });
          },
        });
      }
    }
  }

  useImperativeHandle(ref, function () {
    return {
      getRatesGridApi: () => {
        return gridApi;
      },
    };
  });

  useEffect(() => {
    if (programFields?.length) {
      const tmpRoles = programFields.reduce(
        (acc, { fieldName, fieldOptions }) => ({
          ...acc,
          [fieldName]: fieldOptions,
        })
      )?.["Crew Position"];
      setRoles(tmpRoles);
    }
  }, [programFields]);

  useEffect(() => {
    let tmpRows = [];
    for (const role of roles) {
      tmpRows.push({
        role: role?.statusName,
        rate: ratesForRole?.[role?.statusName] || 0,
      });
    }
    let rolesIncluded = [];
    if (!!selectedClient) {
      const clientData = clients?.find(
        ({ accountId }) => accountId === selectedClient
      );
      rolesIncluded = _.uniqBy(
        employees.filter(
          ({ accountName }) => accountName === clientData?.accountName
        ),
        ({ crewPosition }) => crewPosition
      )?.map(({ crewPosition }) => crewPosition);
    }

    tmpRows = !!rolesIncluded?.length
      ? tmpRows.filter(({ role }) => rolesIncluded.includes(role))
      : tmpRows;
    setRowData(tmpRows);
  }, [JSON.stringify(ratesForRole), roles, selectedClient]);

  return (
    <div
      data-testid="jobsite-rates-grid-container"
      className={`jobsite-rates-grid-container ${
        isDarkMode
          ? "dark-ag-theme ag-theme-alpine-dark"
          : "light-ag-theme ag-theme-alpine"
      }`}
    >
      {showInfo && (
        <div className="editInfo">
          <InfoIconBlue />
          {editable ? (
            <span>
              If you leave a role without a rate, the rate will default to the
              one specified when the employee was created
            </span>
          ) : (
            <span>
              If a role doesn't have a specified rate, the calculations are made
              with the employee's default rate
            </span>
          )}
        </div>
      )}
      <AgGridReact
        {...{
          rowData: rowData,
          columnDefs: ratesGridColumns({ editable }),
          pagination: !!paginationPageSize,
          paginationPageSize,
          onGridReady,
          getRowId,
          onCellValueChanged,
          gridOptions: {
            alwaysShowHorizontalScroll: true,
            alwaysShowVerticalScroll: true,
          },
          animateRows: true,
          defaultColDef: {
            resizable: true,
            enableColResize: true,
            enableRowGroup: false,
            sortable: true,
            filter: true,
            flex: 1,
            suppressSizeToFit: true,
            minWidth: 130,
            suppressDragLeaveHidesColumns: true,
          },
        }}
      />
    </div>
  );
});

export default JobsiteRatesGrid;
