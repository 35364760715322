import dayjs from "dayjs";
import { dayjsNY } from "../../DateComponents/contants/DayjsNY";
import createPDF from "../../../integrations/createPDF";
import { generateDocDefinition } from "../../../utils/generateDocDefinition";

export const adaptedTitlesForStatusesPanel = {
  Scheduling: "Schedulings",
  Clients: "Accounts",
  Inspections: "Safety Inspection",
  Documentations: "Documentations 1",
  "Fleets Violations": "Violations",
  Activities: "Activity",
  "/fleets/overview?tab=Documentation": "Fleets Documentations",
  "Fleet Incident": "Incidents",
  "/fleetInspections": "Fleets Inspections",
  Requisition: "Applications",
  Charge: "Charges",
  Invoice: "Invoices",
  "Receive Payments": "Payments",
  "Personal Injury Incident": "Incidents",
};

export const adaptedTitlesForFiltersPanel = {
  Clients: "Accounts",
};

export const adaptedTitlesForStatuses = {
  "Property Damage Incident": "Incidents",
  "Personal Injury Incident": "Incidents",
  "Fleet Incident": "Incidents",
  "Other Incident": "Incidents",
};

export const missingPanelObjects = {
  Bills: {
    key: "billStatus",
    label: "Status",
    type: "Dropdown",
  },
  Vendors: {
    key: "vendorStatus",
    label: "Status",
    type: "Dropdown",
  },
  Activities: {
    type: "Dropdown",
    key: "activityStatus",
    label: "Status",
  },
};

export const modifyRowData = (rowData) => ({
  Scheduling: rowData.map((data) => {
    const firstDate = data?.scheduleDays
      ?.filter((day) => day?.startDate)
      ?.sort(
        (a, b) => dayjs(a.startDate).valueOf() - dayjs(b.startDate).valueOf()
      )?.[0]?.startDate;
    return {
      ...data,
      scheduleDays: firstDate ? dayjsNY(firstDate).format("MM/DD/YYYY") : null,
    };
  }),
});

export const enhancedEndpoints = {
  "to do": "tasksManagement",
  Requisition: "applications",
  "To Do": "todos",
  "Fleets Violations": "fleetViolations",
};

export const onGeneratePdfMobile = (
  action,
  additionalData,
  getDocDefinition = false,
  headersData
) => {
  console.log({ action, additionalData, getDocDefinition, headersData });

  const { base64, dataToExport, columnHeader } = additionalData;

  const columnsToInclude =
    headersData || columnHeader.map((item, index) => index);
  console.log({ dataToExport, columnsToInclude });

  const body = dataToExport.map((item) =>
    Object.entries(item).map(([key, value]) =>
      key === "opportunityAddress" && value?.[0]?.value
        ? value[0].value
        : value || ""
    )
  );

  const docDefinition = generateDocDefinition(
    base64,
    "Subtopic",
    columnsToInclude,
    columnHeader,
    body
  );

  console.log({ docDefinition });
  console.log({ body });
  if (getDocDefinition) {
    return docDefinition;
  }

  createPDF({
    action,
    docDefinition,
    title: action === "print" ? false : "Subtopic.pdf",
  });
};
