import { updateTeamsConfiguration } from "../../../../../utils";
import { getGridProjectInfo } from "../../SafetyBasePage/getGridProjectInfo";
import checkDateInput from "./checkDateInput";
import checkPicker from "./checkPicker";
import checkTimeInput from "./checkTimeInput";
import dateToSave from "./dateToSave";

const keyData = {
  safety: {
    categoryKey: "safetyApplicationCategory",
    objectKey: "safetyApplicationObject",
    idKey: "safetyApplicationId",
    taskKey: "Safety",
    recordName: "safetyName",
    statusKey: "safetyApplicationStatus",
    defaultStatus: "Draft",
  },
  incidents: {
    categoryKey: "incidentCategory",
    objectKey: "incidentObject",
    idKey: "incidentId",
    taskKey: "Incidents",
    statusKey: "incidentStatus",
    recordName: "incidentName",
    defaultStatus: "Draft",
  },
};

const foremanObj = {
  key: "crewName",
  label: "crewName",
  idKey: "crewId",
  table: "crews",
};
const customOptionsData = {
  fullNameOfForeman: foremanObj,
  foremanOfCrewThatCausedIncident: foremanObj,
  foremanOfCrewThatCausedTheDamage: foremanObj,
  crewMembersThatCausedDamage: {
    key: "crewId",
    label: "crewName",
    table: "crews",
    mode: "multiple",
  },
  nameOfDriver: {
    key: "driverId",
    label: "driverName",
    table: "drivers",
  },
};

const relatedDateFields = [
  "dateOfPropertyDamage",
  "dateOfInjury",
  "dateOfVehicleDamageOrIncident",
  "dateOfIncident",
  "dateOfPropertyDamage",
];

/**
 * Generates the body object for the safety form.
 *
 * @param {Object} form - The form object.
 * @param {string} sign - The signature.
 * @param {string} assignedTo - The assigned user.
 * @param {number} currentStep - The current step.
 * @param {string} selectedTeam - The selected team.
 * @param {number} witnessCount - The number of witnesses.
 * @param {string} [nameKey=""] - The name key.
 * @param {Array} [projects=[]] - The projects array.
 * @param {Array} [schedules=[]] - The schedules array.
 * @param {Array} [crews=[]] - The crews array.
 * @param {Array} [drivers=[]] - The drivers array.
 * @param {Object} statusesColors - The statuses and colors object.
 * @param {Array} formattedFiles - The formatted files array.
 * @param {string} [table=""] - The table name.
 * @param {string} [incidentId=""] - The incident ID.
 * @param {boolean} [saveDraft=false] - Indicates if the form is saved as a draft.
 * @param {Array} [dynamicFields=[]] - The dynamic fields array.
 * @param {Array} [witnessSignings=[]] - The witness signings array.
 * @param {Object} [userConfiguration={}] - The user configuration object.
 * @param {Array} [googleDriveFolderIds=[]] - The Google Drive folder IDs array.
 * @param {Object} [addressSelectionInfo={}] - The address selection info object.
 * @returns {Object} The generated body object.
 */
function safetyBody({
  form,
  sign,
  assignedTo,
  currentStep,
  selectedTeam,
  witnessCount,
  nameKey = "",
  projects = [],
  schedules = [],
  crews = [],
  drivers = [],
  statusesColors,
  formattedFiles,
  table = "",
  incidentId = "",
  saveDraft = false,
  dynamicFields = [],
  witnessSignings = [],
  // requiredTextArea = {},
  // witnessStatements = [],
  userConfiguration = {},
  // descriptionValues = {},
  googleDriveFolderIds = [],
  addressSelectionInfo = {},
  safetyDirectorSignature,
  documentationModalFiles = null,
  visualizationImages = [],
}) {
  const tables = {
    crews,
    drivers,
    schedules,
    projects,
  };

  const {
    defaultStatus = "",
    statusKey = "",
    objectKey = "",
    recordName = "",
  } = keyData[table];

  // At formattedFiles added e new Documented Object if file has been Documented
  if (!!documentationModalFiles) {
    const documentedIds = documentationModalFiles.map((d) => d.id);

    formattedFiles.forEach((file) => {
      if (documentedIds.includes(file.id)) {
        file.documented = {
          isDocumented: true,
          docId: documentationModalFiles.docId,
        };
      }
    });
  }

  let formObject = {
    signature: sign,
    isDraft: saveDraft,
    ...(safetyDirectorSignature ? { safetyDirectorSignature } : {}),
    // ...descriptionValues,
    ...form.getFieldsValue(),
    ...getGridProjectInfo({
      projects,
      schedules,
      data: addressSelectionInfo,
    }),
    assignedTo,
    visualizationImages,
  };

  for (let i = 0; i < witnessCount; i++) {
    // formObject[`witnessStatement-${i}`] = witnessStatements[i];
    formObject[`witnessSign-${i}`] = witnessSignings[i];
  }

  let tmpBody = structuredClone({
    [objectKey]: formObject,
    incidentId,
    [recordName]: (form.getFieldValue(nameKey) || "")?.trim(),
    [statusKey]: saveDraft
      ? defaultStatus
      : statusesColors[currentStep]?.statusName || defaultStatus,
    ...addressSelectionInfo,
    googleDriveUpload: formattedFiles,
    googleDriveFolderIds,
    teamsConfiguration: [],
    // teamsConfiguration: updateTeamsConfiguration(
    //   userConfiguration,
    //   selectedTeam
    // ),
  });
  if (table === "incidents") {
    delete tmpBody.incidentId;
  }

  let dateInput;

  let timeFields = [];

  /**
   * Loops through the form object to update the body object.
   */
  Object.keys(formObject).forEach((key) => {
    if (checkPicker(dynamicFields, key)) {
      if (!!formObject[key]) {
        tmpBody[objectKey][key] = dayjsNY(formObject[key]).format();
      }
    }
    if (checkDateInput({ fields: dynamicFields, formItemName: key })) {
      if (!!formObject[key]) {
        if (relatedDateFields?.includes(key)) {
          dateInput = formObject[key];
        }

        tmpBody[objectKey][key] = dateToSave(formObject[key], "MM/DD/YYYY");
      }
    }

    if (checkTimeInput({ fields: dynamicFields, formItemName: key })) {
      if (!!formObject[key]) {
        timeFields.push(key);
      }
    }
    if (key === nameKey && !!formObject[key]) {
      tmpBody[objectKey][key] = formObject[key].trim();
    }

    if (key?.toLowerCase()?.includes("datepicker")) {
      delete tmpBody[objectKey][key];
    }

    if (customOptionsData?.[key]) {
      const {
        table = "",
        key: tableKey = "",
        label = "",
        idKey = "",
      } = customOptionsData[key];

      // if (!!found) {
      if (customOptionsData?.[key]?.mode === "multiple") {
        console.log({ key });
        tmpBody[objectKey][key] = formObject[key]
          ?.map((id) => {
            const found =
              tables?.[table]?.find((el) => el?.[tableKey] === id) || null;

            return {
              value: found?.[label] || id || null,
              id: id || null,
            };
          })
          ?.filter((el) => !!el?.id);
      } else {
        const found =
          tables?.[table]?.find((el) => el?.[tableKey] === formObject[key]) ||
          null;

        if (!!found) {
          tmpBody[objectKey][key] = {
            value: found?.[label] || null,
            id: found?.[idKey] || formObject[key] || null,
          };
        } else {
          tmpBody[objectKey][key] = {
            value: formObject[key] || null,
            id: formObject[key] || null,
          };
        }
      }
      // }
    }
  });

  if (!!dateInput) {
    timeFields.forEach((key) => {
      if (!!formObject[key]) {
        tmpBody[objectKey][key] = dateToSave(
          `${dateInput} ${formObject[key]}`,
          "MM/DD/YYYY hh:mm A",
          "time"
        );
      }
    });

    dateInput = null;
  }

  return {
    tmpBody,
  };
}

export default safetyBody;
