import { Dayjs } from "dayjs";

/**
 * Returns the start and end of a week containing the specified date
 * Start is Sunday 00:00:00, end is Saturday 23:59:59
 * 
 * @param dateOfWeek A dayjs object representing any date within the desired week
 * @returns An array containing [sundayStart, saturdayEnd] as dayjs objects, or undefined if input is invalid
 */
export function getWeekDateRange(dateOfWeek: Dayjs): [Dayjs, Dayjs] | undefined {
  if (!dateOfWeek || !dateOfWeek.isValid()) {
    return undefined;
  }
  
  const date = dateOfWeek.clone();
  const day = date.day();

  const sunday = date.subtract(day, 'day').startOf('day');
  const saturday = sunday.add(6, 'day').endOf('day');
  
  return [sunday, saturday];
}