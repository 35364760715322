import axios from "axios";
import { linxupEndpoints } from "../../components/SidebarPages/Fleet/fleetsLive/utils";
import { fetchAllData } from "../ApiMethods";
import { isLocationInsideGeofences } from "../Geofence/isLocationInsideGeofences";

const otherKeys = [
  "projectName",
  "projectAddress",
  "projectStatus",
  "geoFenceInfo",
];

export const geoFencesActiveActivities = async ({ keysToInclude }) => {
  try {
    const [projects, locationsData] = await Promise.all([
      fetchAllData({
        endpoint: "projects",
        resultId: "projectId",
        otherStringParams: {
          keysToInclude: JSON.stringify([
            ...new Set([...keysToInclude, ...otherKeys]),
          ]),
        },
      }),
      axios.get(linxupEndpoints.location),
    ]);

    let locations =
      locationsData.status === 200 ? locationsData.data.data.locations : [];

    const activities = [];

    projects.forEach((project) => {
      Array.isArray(project.geoFenceInfo) &&
        locations.forEach((location) => {
          const isInProject = isLocationInsideGeofences(
            location,
            project.geoFenceInfo
          );
          isInProject &&
            activities.push({
              ...location,
              projectName: project.projectName,
              projectAddress: project.projectAddress,
              projectStatus: project.projectStatus,
            });
        });

      return activities;
    });
  } catch (error) {
    console.log(
      "data source function geoFencesActiveActivities error: ",
      error
    );
  }
};
