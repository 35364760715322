import { message } from "antd";
import {
  addDrafts,
  deleteDraftById,
  updateDraftById,
  updateDrafts,
} from "../../../../../../actions/communicationActions";
import { callGmailAPI, getLength, updateTable } from "../../../functions";
import { lazyFetch } from "../../../../../../utils";
import { uniqBy } from "lodash";
import { blobToBase64 } from "../../../../utils";
import { updateDraftsInTable } from "./save-drafts-to-record-helpers";
import { Buffer } from "buffer";
import { processAttachments } from "./send-button-helpers";

export const getDraftToSave = (
  form,
  draftId,
  email,
  editorContent,
  selectedEmailTemplate,
  authData,
  signature,
  uploadedFiles
) => {
  // Prepare the email object to be sent to the Gmail API
  return {
    ...form.getFieldValue(), // Get current form values
    draftId, // Include the draft ID if updating an existing draft
    from: email, // Set the sender's email address
    body: editorContent, // Set the email body content
    snippet: editorContent, // Use the same content for the email snippet
    category: window.location.pathname, // Current category based on the URL path
    template: selectedEmailTemplate?.id, // Selected template ID, if available
    authData, // Include authorization data
    signature, // Include the user's email signature
    attachments: uploadedFiles, // Attach uploaded files to the email
  };
};

export const saveAsDraft = async (
  form,
  draftId, // Unique identifier for the draft
  email, // Email address from which the draft is being sent
  editorContent, // The content of the email body
  selectedEmailTemplate, // The selected email template object
  authData, // Authorization data for sending the email
  signature, // Signature to include in the email
  uploadedFiles, // List of files attached to the email
  setDraftId, // Function to set the draft ID in state
  dispatch, // Redux dispatch function for state management
  drafts, // Current list of saved drafts
  driveDocuments,
  selectedDocTypes,
  driveRequest,
  hotCredentials
) => {
  const { documents, attachmentsWithoutDrive } = await processAttachments(
    uploadedFiles,
    driveDocuments,
    selectedDocTypes,
    driveRequest,
    hotCredentials
  );

  const emailToSend = getDraftToSave(
    form,
    draftId,
    email,
    editorContent + documents,
    selectedEmailTemplate,
    authData,
    signature,
    // uploadedFiles
    attachmentsWithoutDrive
  );

  // Call the Gmail API to save the draft
  const response = await callGmailAPI("saveAsDraft", emailToSend);

  if (response) {
    // Set the draft ID in state based on the response from the API
    setDraftId(response.data.draftId);

    saveDraftsToRecord();

    // Check if the draft already exists in the current drafts list
    const emailExists = drafts?.some(
      (draft) => draft.draftId === response.data.draftId
    );

    // Update the draft if it already exists
    if (emailExists) {
      dispatch(updateDraftById(response.data)); // Update the existing draft
    } else if (drafts.length !== 0) {
      // If there are existing drafts but the current one is new
      dispatch(updateDrafts(response.data)); // Update the drafts list
    } else {
      // If this is the first draft being added
      dispatch(addDrafts(response.data)); // Add the new draft to the list
    }

    return response; // Return the response from the API call
  }
};

export const removeDraft = (
  draftId, // The unique identifier of the draft to be removed
  authData, // Authorization data for the API call
  rowData, // Data object containing the drafts and related information
  recordDetails, // Details of the record associated with the draft
  recordID, // Optional record ID for identifying the record in the database
  dispatch, // Redux dispatch function for state management
  onClose // Callback function to execute after draft removal
) => {
  // Call the Gmail API to remove the draft
  callGmailAPI("removeDraft", {
    id: draftId, // Pass the draft ID to be removed
    authData, // Include authorization data
  }).then(async (e) => {
    // Check if the API call was successful
    if (e.data) {
      const {
        status, // Extract the status code from the response
        data: { id }, // Extract the ID of the deleted draft from the response
      } = e;
      let drafts;

      // Determine if rowData is an array or an object
      if (Array.isArray(rowData)) {
        // If it's an array, find the object that has a drafts property
        drafts = rowData.find((obj) => obj.hasOwnProperty("drafts"));
      } else {
        // Otherwise, get drafts directly from rowData
        drafts = rowData?.drafts;
      }

      // If the status indicates success (200), proceed to update the drafts table
      status === 200 &&
        updateTable(
          recordDetails?.categoryType === "permitdrawings" // Determine the table to update based on category type
            ? "permitDrawings"
            : recordDetails?.categoryType,
          recordID || recordDetails?.recordId, // Use recordID if available; otherwise, use recordDetails
          "drafts", // Specify the key to update in the database
          drafts?.filter(({ draftId: id }) => id !== draftId) // Filter out the removed draft from the drafts list
        ).then(() => {
          // Dispatch action to remove the draft from the Redux store
          dispatch(deleteDraftById(draftId));
          message.info("Draft deleted"); // Display a success message
          onClose(true); // Call onClose callback with true to indicate success
        });
    } else {
      // If the API call was unsuccessful, display a warning message
      message.warning("An error occurred during communication");
    }
  });
};

export const onHeaderClick = (e, setMaximaze, setFullSize) => {
  e.stopPropagation();
  setMaximaze((prevState) => !prevState);
  setFullSize(false);
  return false;
};

export const fetchRolesForId = async (id, type) => {
  // Determine the filter key based on the type provided
  const filterKey = type === "project" ? "accountId" : "leadId";

  // Set the filter value; for "project", use the provided id; otherwise, use an empty string
  const filterValue = type === "project" ? id : "";

  // Fetch contacts from the "contacts" table with specified keys and filter
  const contacts = await lazyFetch({
    tableName: "contacts", // The name of the database table to query
    listOfKeys: ["contactRole", "contactEmail"], // The fields to retrieve from the table
    filterKey, // The key to filter results
    filterValue, // The value to filter results
  });

  // Map the fetched contacts to a more simplified structure containing role and email
  return contacts.map(({ contactRole, contactEmail }) => ({
    role: contactRole, // Map contactRole to role
    email: contactEmail, // Map contactEmail to email
  }));
};

export const getRoles = async (rowData) => {
  let roleslist = []; // Initialize an empty array to hold the roles

  // Check if rowData contains either accountId or leadId
  if (rowData?.accountId || rowData?.leadId) {
    // Determine the type based on the presence of accountId or leadId
    const type = rowData?.accountId ? "account" : "lead";
    // Get the relevant ID
    const id = rowData?.accountId || rowData?.leadId;
    // Fetch roles for the given ID and type
    roleslist = await fetchRolesForId(id, type);
  }
  // Check if rowData contains a projectId
  else if (rowData?.projectId) {
    // Fetch associated accountId for the projectId
    const projects = await lazyFetch({
      tableName: "projects", // The name of the table to query
      listOfKeys: ["accountId"], // The fields to retrieve
      filterKey: "projectId", // The key to filter results
      filterValue: rowData.projectId, // The value to filter results
    });

    // If projects were found, get the accountId from the first project
    if (projects.length > 0) {
      const project = projects[0]; // Get the first project
      // Fetch roles for the associated accountId
      roleslist = await fetchRolesForId(project.accountId, "project");
    }
  }

  // Return the list of roles
  return roleslist;
};

export const getEmailsOfRoles = async (rolesList, programFields, rowData) => {
  try {
    let emailsList = []; // Initialize an empty array to store email addresses

    // Find the field definition for "Lead Roles" in the programFields array
    const leadRolesField = programFields.find(
      ({ fieldName }) => fieldName === "Lead Roles"
    );

    // Throw an error if the "Lead Roles" field is not found
    if (!leadRolesField) {
      throw new Error("Lead Roles field not found.");
    }

    // Extract the options for lead roles
    const leadRoles = leadRolesField.fieldOptions;

    // Iterate over each role in the rolesList
    for (const role of rolesList) {
      // Check if the current role exists in the lead roles
      const roleInLeadRoles = leadRoles.includes(role);

      if (roleInLeadRoles) {
        // Determine the filter key based on whether accountId is empty
        const filterKey = rowData?.accountId === "" ? "leadId" : "accountId";
        // Set the filter value based on the filter key
        const filterValue =
          rowData?.accountId === "" ? rowData?.leadId : rowData?.accountId;

        // Fetch contacts based on the filter criteria
        const contacts = await lazyFetch({
          tableName: "contacts", // The table to query for contacts
          listOfKeys: ["contactRole", "accountId", "leadId", "contactEmail"], // Fields to retrieve
          filterKey, // The key to filter results
          filterValue, // The value to filter results
        });

        // Iterate over the fetched contacts to find matching roles
        contacts.forEach(({ contactRole = [], contactEmail }) => {
          // If the contact role matches the current role, add the email to the list
          if (contactRole.includes(role)) {
            emailsList.push(contactEmail);
          }
        });
      } else {
        // If the role is not in lead roles, add it directly to the emails list
        emailsList.push(role);
      }
    }

    // Return the list of collected email addresses
    return emailsList;
  } catch (error) {
    // Log any errors that occur during the execution
    console.error("Error in getEmailsOfRoles:", error);
    return []; // Return an empty array in case of error
  }
};

// It gets draft from gmail
export const getDraft = async (selectedDraft, authData) => {
  try {
    const response = await callGmailAPI("getDraft", {
      id: selectedDraft,
      authData,
    });

    return response;
  } catch (error) {
    console.error("Error fetching draft:", error);
    throw error; // You can handle the error further if needed
  }
};

const handleDraftGeneralInformation = (draft, fieldName) => {
  // Check if the field specified by fieldName in the draft object contains only an empty string
  return draft[fieldName]?.split(",")?.length === 1 &&
    draft[fieldName]?.split(",").includes("")
    ? [] // If the condition is true, return an empty array
    : draft[fieldName]?.split(","); // Otherwise, return the result of splitting the field value by commas
};

export const handleSetFormFields = (form, draft) => {
  const { subject } = draft;
  form.setFieldsValue({
    to: handleDraftGeneralInformation(draft, "to"),
    cc: handleDraftGeneralInformation(draft, "cc"),
    bcc: handleDraftGeneralInformation(draft, "bcc"),
    subject: subject,
  });
};

export const handleEditorContent = (
  setEditorContent,
  setSelectedEmailTemplate,
  body,
  template
) => {
  // Set the editor content to the provided body
  setEditorContent(body);

  // Check if a template is provided
  if (template) {
    // If a template exists, set the selected email template with its ID and body content
    setSelectedEmailTemplate({ id: template, customBody: body });
  } else {
    // If no template is provided, set the editor content again (redundant but explicit)
    setEditorContent(body);
  }
};

export const fetchSignature = (authData, setSignatureState) => {
  callGmailAPI("getSignature", { authData }).then((res) => {
    if (res && res.data) {
      const sendAsObj = res.data[0];
      setSignatureState(sendAsObj.signature);
    } else {
      message.warning("An error occured when getting Signature");
    }
  });
};

export const getUndoTimerValue = (
  authToken,
  userConfiguration,
  coreStorageSignature
) => {
  return (
    (authToken
      ? userConfiguration?.undoTimer
      : coreStorageSignature.undoTimer) || 5
  );
};

export const getDriveItems = async (folderId, folderName, driveRequest) => {
  const res = await driveRequest.getFilesByFolderId(folderId);
  const data = await res.json();

  if (data?.error) {
    return null;
  }

  const itemsList = [];
  const uniqueItems = new Set();

  for (const { mimeType, id, name } of data?.files) {
    if (mimeType === "application/vnd.google-apps.folder") {
      const folderItems = await getDriveItems(id, name, driveRequest);
      itemsList.push(...folderItems);
    } else {
      const item = {
        folder: folderName,
        folderId,
        id,
        name,
        type: "Item",
      };

      if (!uniqueItems.has(id)) {
        itemsList.push(item);
        uniqueItems.add(id);

        const blobResponse = await driveRequest.getDriveItem(id, {
          alt: "media",
        });
        const blobData = await blobResponse.blob();
        const blob = await blobToBase64(blobData);

        itemsList.push({
          name: item.name,
          blob,
          size: Buffer.from(blob.substring(blob.indexOf(",") + 1))?.length,
          type: blobData.type,
          id: item.id,
          status: 400,
        });
      }
    }
  }

  return itemsList.filter(({ blob }) => blob);
};

export const getDocItems = async (attachmentsToFetch, driveRequest) => {
  let finalElements = await fetchBlobData(attachmentsToFetch, driveRequest);

  return finalElements;
};

// It fetches docFolders which are used inside documentation types of email box
export const fetchDocuments = async (docFolders, allDocTypes, driveRequest) => {
  let documents = [];
  let unexistDocs = [];

  for await (let parent of docFolders) {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    let items = [];

    if (allDocTypes.find(({ docType }) => docType === parent)) {
      await getDriveItems(
        allDocTypes.find(({ docType }) => docType === parent).folderId,
        parent,
        items,
        driveRequest
      ).then((list) => delay(1300).then(() => Promise.all(list)));

      documents.push(items);
    } else {
      unexistDocs.push(parent);
    }
  }

  return { documents, unexistDocs };
};

export const filterAndSetSelectedItems = (docItems, documents) => {
  docItems.forEach((e) => {
    if (
      uniqBy(documents.flat(1), "id")
        .filter(({ type }) => type === "Item")
        .find(({ id }) => e.id === id)
    ) {
      e.selected = true;
    } else {
      e.selected = false;
    }
  });
};

// fetches blob data from google drive for ever element provided
export const fetchBlobData = async (elements, driveRequest) => {
  return Promise.all(
    elements.map(async (el) => {
      const { id, name } = el;
      let blob;
      return driveRequest.getDriveItem(id, { alt: "media" }).then((r) =>
        r.blob().then(async (e) => {
          const { type } = e;
          await blobToBase64(e).then((res) => (blob = res));
          return {
            name,
            blob,
            size: Buffer.from(blob.substring(blob.indexOf(",") + 1)).length,
            type,
            id,
            mimeType: type,
            status: 400,
          };
        })
      );
    })
  );
};

export const handleSetSelectedDocTypes = (
  finalElements,
  setSelectedDocTypes
) => {
  setSelectedDocTypes(finalElements.filter(({ type }) => type !== "Folder"));
};

// calls the update drafts table to update the record table with new drafts
export const saveDraftsToRecord = async (drafts, recordName, recordId) => {
  try {
    await updateDraftsInTable(recordName, recordId, drafts);
  } catch (error) {
    console.error(error);
    message.error(error);
  }
};

export function formatTitle(title) {
  let words = title.match(/[A-Za-z][a-z]*/g);

  // Add null check for words
  if (!words) {
    return ""; // Return empty string if no words found
  }

  let formattedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  let formattedTitle = formattedWords.join(" ");

  return formattedTitle;
}

// returns the selected email template
export const getTemplate = (emailTemplates, selectedEmailTemplate) => {
  if (!emailTemplates || !selectedEmailTemplate) return null;

  return emailTemplates?.find(
    ({ templateId }) => templateId === selectedEmailTemplate?.id
  );
};

// fetches uploaded files from google drive
export const getUploadedFiles = async (
  templateAttachments,
  isRedirected,
  driveRequest
) => {
  try {
    const staticDocuments = await Promise.all(
      [...templateAttachments, ...(isRedirected || [])].map(async (el) => {
        try {
          const r = await driveRequest.getDriveItem(el, {});
          const e = await r.json();
          const { id, mimeType, name } = e;

          // Validate the API response
          if (!id || !mimeType || !name) {
            console.error(`Invalid document data for ${el}:`, e);
            return null;
          }

          return {
            id,
            uid: id,
            name,
            type: mimeType,
            mimeType,
            status: 400,
          };
        } catch (error) {
          console.error(`Failed to get drive item for ${el}:`, error);
          return null;
        }
      })
    );

    const filteredDocuments = staticDocuments.filter(
      (doc) => doc !== null && doc.id && doc.name
    );

    if (filteredDocuments.length > 0) {
      const uploadedFilesResult = await Promise.all(
        filteredDocuments.map(async (e) => {
          try {
            const r = await driveRequest.getDriveItem(e.id, { alt: "media" });
            if (!r.ok) {
              throw new Error(`Failed to fetch media for item ${e.id}`);
            }
            const el = await r.blob();
            const blob = await blobToBase64(el);
            return {
              ...e,
              blob,
              size: getLength(
                Buffer.from(blob?.substring(blob?.indexOf(",") + 1))
              ),
            };
          } catch (error) {
            console.error(`Failed to process file ${e.id}:`, error);
            return null;
          }
        })
      );

      return uploadedFilesResult.filter((file) => file !== null);
    }

    return [];
  } catch (error) {
    console.error("Failed to get uploaded files:", error);
    return [];
  }
};

export const processTemplateRoles = async (
  templateRoles,
  programFields,
  rowData,
  form
) => {
  const rolesToUpdate = ["to", "cc", "bcc"];
  await Promise.all(
    rolesToUpdate.map(async (role) => {
      if (templateRoles[role]) {
        const res = await getEmailsOfRoles(
          templateRoles[role],
          programFields,
          rowData
        );
        form.setFieldValue(role, res);
      } else {
        templateRoles[role] = null;
      }
    })
  );
};
