import { message } from "antd";
import { fetchAllData } from "../../../../../../../../../../../utils/ApiMethods";
import { matchEditActivities } from "../../../../../../../utils/matchEditActivities";
import { gsiQueryTable } from "../../../../../../../../../../../utils";

export const fetchEditLogsForEmptyAudit = async ({
  eligibleForAudit,
  vehicle,
  plannedTripsForDate,
}) => {
  if (eligibleForAudit.length > 0 && vehicle) {
    return;
  }
  const messageKey = "editLogsLoading";

  try {
    const activityIds = plannedTripsForDate?.[vehicle]
      ?.filter((trip) => trip.isEdited)
      .map((trip) => trip.activityId)
      .filter(Boolean);

    if (activityIds && activityIds.length > 0) {
      const logsByActivityId = {};
      let processedCount = 0;

      for (const activityId of activityIds) {
        processedCount++;
        message.loading({
          content: `Getting data from edited routes (${processedCount}/${activityIds.length})`,
          key: messageKey,
          duration: 0,
        });

        const logs = await gsiQueryTable({
          filterKey: "recordId",
          tableName: "editLogs",
          indexName: "recordId-index",
          filterValue: activityId,
        });

        if (logs && logs.length > 0) {
          logsByActivityId[activityId] = logs;
        }
      }

      if (Object.keys(logsByActivityId).length > 0) {
        const matchEditActivitiesRes = matchEditActivities({
          logsByActivityId,
          planForTruck: _.cloneDeep(plannedTripsForDate?.[vehicle] || []),
        });

        message.destroy(messageKey);
        return matchEditActivitiesRes;
      } else {
        message.warning({
          content: "Getting data from edited routes were found",
          key: messageKey,
        });
        return [];
      }
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching edit logs:", error);
    message.error({
      content: "Error getting data from edited routes!",
      key: messageKey,
    });
    return [];
  }
};
