import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { message, Form, Tooltip } from "antd";
import moment from "moment";
import { lazyFetch } from "src/utils";
import { MondayButton } from "src/components/commonComponents";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";

import "./DriverAssign.scss";
import { getMomentDayTimestamp } from "../../../Fleet/utils";
import { disabledEndDate, disabledStartDate } from "./disableDates";

function DriverAssign(props) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [drivers, setDrivers] = useState([]);
  const [prev, setPrev] = useState();
  const [isEndDate, setIsEndDate] = useState(false);
  const [hasDriverChanged, setHasDriverChanged] = useState("");

  const [form] = Form.useForm();

  const { params, setEditMode, getEditedValue } = props;

  const lastDriverStartDate = Form.useWatch("lastDriverStartDate", form);
  const lastDriverEndDate = Form.useWatch("lastDriverEndDate", form);
  const selectedDriverStartDate = Form.useWatch(
    "selectedDriverStartDate",
    form
  );
  const selectedDriverEndDate = Form.useWatch("selectedDriverEndDate", form);
  const assignedDriver = Form.useWatch("assignedDriver", form);

  useEffect(() => {
    setPrev(_.cloneDeep(props));
  }, [props]);

  useEffect(() => {
    lazyFetch({
      tableName: "drivers",
      listOfKeys: ["driverName", "driverId", "driverSub"],
    })
      .then(setDrivers)
      .catch((err) => {
        message.error({
          content:
            "Something went wrong while trying to get the list of drivers",
          key: "driverFetchErr",
        });
        console.log("Error getting drivers: ", err);
      });
  }, []);

  useEffect(() => {
    if (!lastDriverEndDate) {
      form.setFieldsValue({
        selectedDriverStartDate: null,
        selectedDriverEndDate: null,
      });
      setHasDriverChanged("");
    }
  }, [lastDriverEndDate]);

  useEffect(() => {
    form.setFieldsValue({
      assignedDriver: params?.assignedDriver,
      lastDriverStartDate:
        params?.assignedDriver && params?.driverHistory?.at(-1)?.startDate
          ? moment(params?.driverHistory?.at(-1)?.startDate)
          : null,
      lastDriverEndDate: null,
      selectedDriverStartDate: null,
      selectedDriverEndDate: null,
    });
  }, [params, form]);

  function onCancel() {
    setEditMode(false);
  }

  function getCardData({ assignedDriver, driverHistory }) {
    return {
      id: "driverAssign",
      Card: "DriverAssign",
      title: "Driver Assign",
      params: {
        ...params,
        assignedDriver,
        driverHistory,
      },
    };
  }

  async function saveHandler() {
    try {
      const values = await form.validateFields();

      const selectedDriverStartTimestamp = selectedDriverStartDate
        ? getMomentDayTimestamp(selectedDriverStartDate)
        : null;
      const selectedDriverEndTimestamp = selectedDriverEndDate
        ? getMomentDayTimestamp(selectedDriverEndDate)
        : null;
      const lastDriverStartTimestamp = lastDriverStartDate
        ? getMomentDayTimestamp(lastDriverStartDate)
        : null;
      const lastDriverEndTimestamp = lastDriverEndDate
        ? getMomentDayTimestamp(lastDriverEndDate)
        : null;

      const prevDriver = prev?.params?.assignedDriver;
      const prevDriverHistory = prev?.params?.driverHistory || [];

      let newAssignedDriver = null;
      const newDriverHistory = [...prevDriverHistory];
      let lastHistoryEntry = null;

      if (newDriverHistory.length > 0) {
        lastHistoryEntry = { ...newDriverHistory[newDriverHistory.length - 1] };
        if (prevDriver) {
          lastHistoryEntry.endDate = lastDriverEndTimestamp;
        }
        newDriverHistory[newDriverHistory.length - 1] = lastHistoryEntry;
      }

      if (prevDriver !== assignedDriver) {
        const newDriverEntry = {
          driverId: assignedDriver,
          driverName:
            drivers.find(({ driverId }) => driverId === assignedDriver)
              ?.driverName || "",
          startDate: prevDriver
            ? selectedDriverStartTimestamp
            : lastDriverStartTimestamp,
          endDate: prevDriver
            ? selectedDriverEndTimestamp
            : lastDriverEndTimestamp,
        };
        newDriverHistory.push(newDriverEntry);

        if (!selectedDriverEndDate) {
          newAssignedDriver = assignedDriver;
        }
      }

      if (
        prevDriver === assignedDriver ||
        !assignedDriver ||
        (!prevDriver && assignedDriver && lastDriverEndDate)
      ) {
        newAssignedDriver = null;
      }

      if (prevDriverHistory.length == 0) {
        if (!lastDriverEndDate) {
          newAssignedDriver = assignedDriver;
        }
        const newDriverEntry = {
          driverId: prevDriver,
          driverName:
            drivers.find(({ driverId }) => driverId === prevDriver)
              ?.driverName || "",
          startDate: lastDriverStartTimestamp,
          endDate: lastDriverEndTimestamp || null,
        };
        if (prevDriver) {
          newDriverHistory.unshift(newDriverEntry);
        }
      }

      const response = {
        assignedDriver: newAssignedDriver,
        driverHistory: newDriverHistory,
      };

      const prevData = {
        assignedDriver: prevDriver,
        driverHistory:
          prevDriver !== assignedDriver || selectedDriverEndTimestamp
            ? [
                ...(prevDriverHistory?.length
                  ? prevDriverHistory.slice(0, -1)
                  : []),
                lastHistoryEntry
                  ? {
                      ...lastHistoryEntry,
                      endDate: lastDriverEndTimestamp,
                    }
                  : null,
              ].filter(Boolean)
            : prevDriverHistory,
      };

      const currData = {
        assignedDriver,
        driverHistory: assignedDriver
          ? [
              {
                driverId: assignedDriver,
                driverName:
                  drivers.find(({ driverId }) => driverId === assignedDriver)
                    ?.driverName || "",
                startDate: selectedDriverStartTimestamp,
                endDate: selectedDriverEndTimestamp,
              },
            ]
          : prevDriverHistory?.length
          ? [
              {
                ...prev.params.driverHistory.at(-1),
                endDate: lastDriverEndTimestamp,
              },
            ]
          : [],
      };

      getEditedValue(
        response,
        { assignedDriver: params?.assignedDriver },
        {},
        getCardData(prevData),
        getCardData(currData)
      );

      onCancel();
    } catch (error) {
      console.error("Form validation failed:", error);
    }
  }

  return (
    <Form
      form={form}
      className={`driver-assign-form ${
        isDarkMode ? "driver-assign-form-dark" : ""
      }`}
    >
      <div>
        <Tooltip
          title={
            !isEndDate &&
            prev?.params?.assignedDriver &&
            "Determine the End Date for the current driver to proceed"
          }
        >
          <InputComponent
            label="Please select a driver to assign"
            required={true}
            type="select"
            formItemName="assignedDriver"
            showSearch={true}
            dropdownClassName={isDarkMode && "darkDropDown"}
            customOptions={drivers.map(({ driverName, driverId }) => ({
              label: driverName,
              value: driverId,
            }))}
            allowClear={false}
            disabled={!isEndDate && prev?.params?.assignedDriver}
            onChange={(value) => {
              setHasDriverChanged(value);
            }}
          />
        </Tooltip>
        <InputComponent
          label={`Start Date for ${
            hasDriverChanged &&
            hasDriverChanged !== params?.assignedDriver &&
            params?.assignedDriver
              ? "Previous"
              : "the selected"
          } Driver`}
          type="datePicker"
          formItemName="lastDriverStartDate"
          disabled={
            prev?.params?.assignedDriver &&
            prev?.params?.driverHistory?.length > 0
          }
          required={true}
          disabledDate={(current) =>
            disabledStartDate({
              current,
              driverHistory: params?.driverHistory,
              lastDriverStartDate: getMomentDayTimestamp(lastDriverStartDate),
              lastDriverEndDate: lastDriverEndDate
                ? getMomentDayTimestamp(lastDriverEndDate)
                : null,
              type: "first",
            })
          }
        />
        {(prev?.params?.assignedDriver || lastDriverStartDate) && (
          <InputComponent
            label={`Enter End Date for ${
              hasDriverChanged &&
              hasDriverChanged !== params?.assignedDriver &&
              params?.assignedDriver
                ? "Previous"
                : "the selected"
            } Driver`}
            required={params?.driverHistory?.some(
              (driver) =>
                new Date(driver.startDate) >
                getMomentDayTimestamp(lastDriverStartDate)
            )}
            type="datePicker"
            formItemName="lastDriverEndDate"
            allowClear={true}
            onChange={(date) => {
              setIsEndDate(!!date);
              if (!date && params?.assignedDriver) {
                form.setFieldValue("assignedDriver", params?.assignedDriver);
              }
            }}
            rules={[{ required: false, message: "Please select an end date!" }]}
            disabledDate={(current) =>
              disabledEndDate({
                current,
                driverHistory: params?.driverHistory,
                lastDriverStartDate: getMomentDayTimestamp(lastDriverStartDate),
                lastDriverEndDate: getMomentDayTimestamp(lastDriverEndDate),
                type: "first",
              })
            }
          />
        )}
        {hasDriverChanged &&
          params.assignedDriver &&
          hasDriverChanged !== params.assignedDriver && (
            <>
              <InputComponent
                label="Enter Start Date for Selected Driver"
                required={true}
                type="datePicker"
                formItemName="selectedDriverStartDate"
                allowClear={true}
                disabled={!isEndDate && prev?.params?.assignedDriver}
                rules={[
                  { required: true, message: "Please select a start date!" },
                ]}
                disabledDate={(current) =>
                  disabledStartDate({
                    current,
                    driverHistory: params?.driverHistory,
                    lastDriverStartDate:
                      getMomentDayTimestamp(lastDriverStartDate),
                    lastDriverEndDate: getMomentDayTimestamp(lastDriverEndDate),
                    selectedDriverEndDate: getMomentDayTimestamp(
                      selectedDriverEndDate
                    ),
                    type: "second",
                  })
                }
              />
              <InputComponent
                label="Enter End Date for the selected Driver"
                required={
                  params?.driverHistory?.some(
                    (driver) =>
                      new Date(driver.startDate) >
                      getMomentDayTimestamp(selectedDriverStartDate)
                  ) ||
                  getMomentDayTimestamp(selectedDriverStartDate) <
                    lastDriverStartDate
                }
                type="datePicker"
                formItemName="selectedDriverEndDate"
                allowClear={true}
                rules={[
                  { required: false, message: "Please select an end date!" },
                ]}
                disabledDate={(current) =>
                  disabledEndDate({
                    current,
                    driverHistory: params?.driverHistory,
                    lastDriverStartDate:
                      getMomentDayTimestamp(lastDriverStartDate),
                    lastDriverEndDate: getMomentDayTimestamp(lastDriverEndDate),
                    selectedDriverStartDate: getMomentDayTimestamp(
                      selectedDriverStartDate
                    ),
                    type: "second",
                  })
                }
              />
            </>
          )}
      </div>
      <div className="confirmContainer">
        <MondayButton
          Icon={<XIcon />}
          className="mondayButtonRed"
          onClick={onCancel}
        >
          Cancel
        </MondayButton>
        <MondayButton Icon={<TickIcon />} onClick={saveHandler}>
          Save
        </MondayButton>
      </div>
    </Form>
  );
}

export default DriverAssign;
