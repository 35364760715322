export const MandatoryFields = {
  1: ["length", "height", "width"],
  2: ["length", "height"],

  3: [],
  4: ["length"],

  5: ["length", "width"],
  6: ["length", "height", "width"],
  7: ["length", "width"],
  8: ["length", "width"],

  9: ["length"],
  10: ["length", "height"],
  11: ["length"],
  12: ["length"],
  13: ["length"],

  14: ["length", "height"],
  15: ["length"],

  16: [],
  17: ["length"],
  18: ["length"],
  19: ["length"],
  20: ["length"],
  69: ["length", "height"],
  70: ["length", "height", "width"],
  71: ["length", "width"],
  72: ["length"],
  73: ["length"],
  79: ["length"],
  80: ["length"],
  81: ["length"],
  85: ["length", "width"],
  86: ["length", "width"],
  89: ["length", "height", "width"],
  90: ["length", "height", "width"],

  9999: [],
};
export const TabFieldsTakeOff = {
  1: ["length", "height", "width"],
  2: ["length", "height", "facadeHeight"],

  3: [],
  4: ["length", "height"],
  5: ["length", "width", "stories"],
  6: ["length", "height", "width"], // add type
  7: ["length", "width", "onFloor"],
  8: ["length", "width", "onFloor"],
  9: ["length"],
  10: ["length", "height", "width"],
  11: ["length", "height", "width"],
  12: ["length", "height"], // add type
  13: ["length", "number_of_shoring"], //add type

  14: ["length", "height"],
  15: ["length", "diameterInInch", "fromFloor", "toFloor", "hoppers"],

  16: [],
  17: ["length"],
  18: ["length", "height"],
  19: ["length", "height"],
  20: ["length", "height", "width"],
  69: ["length", "height"],
  70: ["length", "height", "width"],
  71: ["length", "width"],
  72: ["length", "height"],
  73: ["length", "height"],
  79: ["length", "width"],
  80: ["length", "height", "width"],
  80: ["length", "height"],
  81: ["length"],
  85: ["length", "height", "width"],
  86: ["length", "width", "height"],
  89: ["length", "height", "width"],
  90: ["length", "height", "width"],

  9999: [],
};
