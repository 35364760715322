import { Form, message } from "antd";
import { API } from "aws-amplify";
import { CommentsContainer } from "src/components/commonComponents/Comment";
import { SPECIAL_REQUESTS } from "../../helpers";

const RequestFormComments = ({
  form,
  setChanges = () => {},
  requestId,
  requestType = "",
  selectedSchedule = null,
}) => {
  const formComments = Form.useWatch("comments", form) || [];
  const jobsiteAddress = Form.useWatch("jobsiteAddress", form) || [];

  const commentsChanged = (comments) => {
    // if editing
    if (!!requestId) {
      API.patch("formRequests", `/formRequests/${requestId}`, {
        body: { comments },
      })
        .then(() => {
          message.success("Comments Updated Successfully");
          form.setFieldValue("comments", comments);
          setChanges(true);
        })
        .catch((err) => {
          message.error("Error updating comments");
          console.log({ err });
        });
    } else {
      setChanges(true);
      form.setFieldValue("comments", comments);
    }
  };

  const commentClassName = () => {
    // if (!!formComments?.length) {
    if (SPECIAL_REQUESTS.includes(requestType)) {
      if (requestType === "Schedule Change" && !selectedSchedule) {
        return "request-form-comment-full";
      }
      return "request-form-comment";
    }
    // return "request-form-comment";
    // } else {
    return "request-form-comment-full";
    // }
  };

  return (
    <div className="request-comments-wrapper">
      <div className={commentClassName()}>
        <Form.Item {...{ name: "comments" }}>
          <CommentsContainer
            {...{
              category: "Approvals",
              recordName: jobsiteAddress,
              comments: formComments,
              commentsChanged,
            }}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default RequestFormComments;
