import API from "@aws-amplify/api";
import { message } from "antd";
import { proposalActions } from "../components/ProposalBuilderSettings/reducers/actions";

//region PUT
function updateProposal(templateId, body) {
  return new Promise((resolve, reject) => {
    if (!templateId) {
      reject("Template Id is required");
    }
    if (!body || Object.keys(body)?.length === 0) {
      reject("Body is required");
    }
    API.put("proposalTemplates", `/proposalTemplates/${templateId}`, {
      body: {
        ...body,
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//region CREATE
export const createNewTemplate = (newTemplate, logFn) => async (dispatch) => {
  message.loading({ content: "Saving Template", key: "save" });
  await API.post("proposalTemplates", "/proposalTemplates", {
    body: {
      ...newTemplate,
    },
  })
    .then(async (res) => {
      message.success({
        content: "Template Saved Successfully",
        key: "save",
      });
      await logFn(res?.templateId, res?.templateTitle);
      dispatch({
        type: proposalActions.CREATE_PROPOSAL,
        payLoad: { newProposal: res },
      });
    })
    .catch((err) => {
      message.error({ content: "Error Saving Template", key: "save" });
      console.log("Error Saving Template", err);
    });
};

//region DELETE
export const deleteTemplate = (templateId, deleteLog) => async (dispatch) => {
  await API.del("proposalTemplates", `/proposalTemplates/${templateId}`)
    .then(async (res) => {
      message.success("Template Deleted Successfully");
      await deleteLog();
      dispatch({
        type: proposalActions.DELETE_PROPOSAL,
        payLoad: templateId,
      });
    })
    .catch((err) => {
      message.error("Error Deleting Template");
      console.error("Error Deleting Template", err);
    });
};

//region update
export const updateProposalTemplate =
  (templateId, body, logFn) => async (dispatch) => {
    message.loading({ content: "Saving Template", key: "save" });
    await updateProposal(templateId, body)
      .then(async (res) => {
        message.success({
          content: "Template Updated Successfully",
          key: "save",
        });
        try {
          await logFn();
        } catch (err) {
          console.error("Error logging update", { err });
        }
        dispatch({
          type: proposalActions.UPDATE_PROPOSAL,
          payLoad: { templateId, body },
        });
      })
      .catch((err) => {
        message.error({ content: "Error Updating Template", key: "save" });
        console.error("Error Updating Template", { err });
      });
  };

//region make DEF
export const makeTemplateDefault =
  (templateId, templateCategory, templates) => async (dispatch) => {
    message.loading({ content: "Marking template as default", key: "default" });
    const defaultToBeRemoved = templates?.find(
      (el) =>
        el?.isDefault === true &&
        el?.templateId !== templateId &&
        el?.templateCategory === templateCategory
    );
    await updateProposal(templateId, { isDefault: true })
      .then(async (res) => {
        if (defaultToBeRemoved) {
          await updateProposal(defaultToBeRemoved?.templateId, {
            isDefault: false,
          });
        }
        message.success({
          content: "Template marked as default",
          key: "default",
        });
        dispatch({
          type: proposalActions.SET_FAVORITE_PROPOSAL,
          payLoad: { templateId, templateCategory },
        });
      })
      .catch((err) => {
        message.error({
          content: "Error marking template as default",
          key: "default",
        });
        console.error("Error marking template as default", err);
      });
  };

//region remove DEF
export const removeDefaultTemplate = (templateId) => async (dispatch) => {
  message.loading({ content: "Marking as not default", key: "default" });
  await updateProposal(templateId, { isDefault: false })
    .then((res) => {
      message.success({
        content: "Template marked as not default",
        key: "default",
      });
      dispatch({
        type: proposalActions.REMOVE_FAVORITE_PROPOSAL,
        payLoad: templateId,
      });
    })
    .catch((err) => {
      message.error({
        content: "Error marking template as not default",
        key: "default",
      });
      console.error("Error marking template as not default", err);
    });
};
