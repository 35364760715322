import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { Form, Input, Segmented } from "antd";

import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import {
  parseInTz,
  setHourMinute,
} from "../../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

import "./CustomTimeInput.scss";

function getFormTime(time) {
  if (!time) {
    return null;
  } else {
    return dayjsNY(time);
  }
}

function CustomTimeInput({
  form,
  onChange,
  className,
  dataTestid,
  formListName,
  selectedDate,
  formItemName,
  disabled = false,
  secondaryDarkMode = false,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [timeForm] = Form.useForm();

  /**
   * @type {dayjs | null}
   */
  const currentValue = Array.isArray(formItemName)
    ? form.getFieldsValue()?.[formListName]?.[formItemName?.[0]]?.[
        formItemName?.[1]
      ]
    : getFormTime(Form.useWatch(formItemName, form));

  const segmentValue = Form.useWatch("day-period-field", timeForm);

  const hoursInput = useRef();
  const timeoutRef = useRef();
  const minutesInput = useRef();
  const dayPeriodRef = useRef();
  const formDateRef = useRef(
    parseInTz(selectedDate ? selectedDate : currentValue || undefined)
  );

  let runOnce = 0;

  /**
   *
   * @param {any} props props that are going tu used from propFunction
   * @param {Function} propFunction function that is going to run after timeout
   * @param {number} timeout time in milliseconds that is going to wait before running the propFunction
   */
  function debounce({ props, propFunction, timeout }) {
    //#region DEBOUNCE
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => propFunction(_, props), timeout);
  }

  /**
   * @param {Object[]} allFields - List of all fields inside timeForm
   *
   * onFieldsChange creates a time object using the values from the hours, minutes, dayPeriod.
   * It recreates this time object every time the fields change with
   * a timeout using the debounce function above.
   */
  function onFieldsChange(_, allFields) {
    //#region FIELD CHANGE
    const [{ value: hours }, { value: minutes }, { value: dayPeriod }] =
      allFields;
    const localHours = dayjs().hour();
    const localMinutes = dayjs().minute();

    const inputHours = !!hours
      ? dayPeriod === "PM" && Number(hours.replaceAll("e", "")) < 12
        ? Number(hours.replaceAll("e", "")) + 12
        : hours.replaceAll("e", "")
      : null;

    let inputMinutes = minutes.toLowerCase().replaceAll("e", "").slice(-2);
    inputMinutes = +inputMinutes >= 59 ? "59" : inputMinutes;

    const time = dayjs(
      `${inputHours ?? localHours}:${
        inputMinutes ?? localMinutes
      } ${dayPeriod}`,
      "hh:mm A"
    );

    const newTime = setHourMinute(formDateRef.current, time);

    if (!!hours && !!minutes) {
      if (Array.isArray(formItemName)) {
        const data = form.getFieldValue(formListName);
        Object.assign(data[formItemName[0]], { [formItemName[1]]: newTime });
        form.setFieldValue(formListName, data);
        form.setFieldValue(formItemName, newTime);
      } else {
        console.log(`${formItemName}: `, newTime);
        form.setFieldValue(formItemName, newTime);
      }
      if (onChange) {
        onChange(parseInTz(newTime));
      }
    }
  }

  /**
   * This useEffect updates the fields of timeForm when the currentValue changes
   */
  useEffect(() => {
    //#region FORM UPDATE EFFECT
    if (typeof currentValue === "object" && currentValue && runOnce === 0) {
      const initialHours =
        currentValue.get("hours") > 12 // when hours go past 12PM
          ? String(currentValue.get("hour") - 12).padStart(2, "0")
          : currentValue.get("hours") === 0 // when its 12AM set hours field 12 not 0
          ? "12"
          : String(currentValue.get("hour")).padStart(2, "0");

      timeForm.setFieldValue("hours", initialHours);
      timeForm.setFieldValue(
        "minutes",
        String(currentValue.get("minute")).padStart(2, "0")
      );

      timeForm.setFieldValue(
        "day-period-field",
        currentValue.get("hours") >= 12 ? "PM" : "AM"
      );
      runOnce = 1;
    }
    if (!currentValue) {
      timeForm.setFieldValue("hours", null);
      timeForm.setFieldValue("minutes", null);
    } else {
      formDateRef.current = currentValue;
    }
  }, [currentValue, runOnce]);

  useEffect(() => {
    if (selectedDate) {
      formDateRef.current = setHourMinute(
        selectedDate,
        currentValue || parseInTz()
      );
    }
  }, [selectedDate]);

  //#region JSX
  return (
    <Form
      form={form}
      data-testid={dataTestid || "custom-time-input"}
      className={`custom-time-input ${isDarkMode && "custom-time-input-dark"}`}
    >
      <Form
        form={timeForm}
        className={`time-input-wrapper ${
          secondaryDarkMode && "reverse-dark-mode"
        } ${className}`}
        onFieldsChange={(_, e) =>
          debounce({ props: e, propFunction: onFieldsChange, timeout: 1500 })
        }
      >
        <Form.Item name="hours">
          <Input
            count={2}
            type="number"
            placeholder="00"
            ref={hoursInput}
            disabled={disabled}
            data-testid="hours-input"
            focus={{
              options: {
                cursor: "all",
              },
            }}
            onClick={(e) => e.target.select()}
            onChange={(e) => {
              if (e.target.value.length === 2) {
                minutesInput.current.select();
              }
              if (Number(e.target.value) > 12) {
                timeForm.setFieldValue("hours", "12");
                minutesInput.current.select();
              }
            }}
          />
        </Form.Item>
        <div className="semicolon">:</div>
        <Form.Item name="minutes">
          <Input
            count={2}
            type="number"
            placeholder="00"
            ref={minutesInput}
            disabled={disabled}
            data-testid="minutes-input"
            onClick={(e) => e.target.select()}
            focus={{
              options: {
                cursor: "all",
              },
            }}
            onChange={(e) => {
              if (e.target.value.length >= 2) {
                dayPeriodRef.current.childNodes[0].childNodes[
                  segmentValue === "PM" ? 1 : 0
                ].focus();

                let lastTwoDigits = e.target.value.slice(-2);
                lastTwoDigits = +lastTwoDigits >= 59 ? "59" : lastTwoDigits;
                timeForm.setFieldValue("minutes", lastTwoDigits);
              }
            }}
          />
        </Form.Item>
        <Form.Item
          currentValue={"AM"}
          name="day-period-field"
          onFocus={() => {
            dayPeriodRef.current.firstChild
              .querySelector(".ant-segmented-item-selected")
              .focus();
          }}
        >
          <Segmented
            ref={dayPeriodRef}
            disabled={disabled}
            value={segmentValue}
            options={["AM", "PM"]}
            data-testid="day-period"
            className="day-period-container"
            onChange={(e) => {
              timeForm.setFieldValue("day-period-field", e);
            }}
          />
        </Form.Item>
      </Form>
      <Form.Item
        form={form}
        name={formItemName}
        value={currentValue}
        className="custom-item-error"
      ></Form.Item>
    </Form>
  );
}

export default CustomTimeInput;
