import React from "react";
import { Badge, Button, Divider, Input, message, Select, Tooltip } from "antd";
import { formatDate } from "../../../helpers/creators";
import _ from "lodash";
import "./DaysInScheduleMaster.scss";
import { get_ProgressDimensionsByServiceId } from "../../../../DataEntryGrid/tools/columnDefinitions/ProgressColumnDefinition";
import { get_new_progressByDay } from "../../../../../../SidebarPages/Scheduling/helpers/setters";
import { getItemProgress } from "../../../../../../SidebarPages/Scheduling/helpers/calculators";
import { PROGRESS_ORIGIN } from "../PliProgressModal";
import { hasItemYetToSchedule } from "../../../helpers/PLI-setters-getters";
import { DaySelectOption } from "../SmallComponents/DaySelectOption";
import moment from "moment-timezone";
import {
  availableDays,
  prevPliCompleted,
  prevSubPliCompleted,
} from "../../../SchedulingFirstPage/helperData";
import dayjs from "dayjs";
import { DropDownArrow } from "../../../../../../SidebarPages/Fleet/components/InputComponent/assets";
import { MondayButton } from "../../../../../../commonComponents";
import { Redirect } from "../../../../../../SidebarPages/BasePage/src";
import { openInNewTab } from "../../../../../../../utils";
import TransferDays from "../../TransferDays";

// const { Option } = Select;

/**
 * A dropdown component which selects days in items
 * */
export class DaysInScheduleMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transferDays: {},
    };
    const { d1, d2 } = get_ProgressDimensionsByServiceId(
      this?.props?.serviceId
    );
    this.d1 = d1;
    this.d2 = d2;
  }
  d1;
  d2;

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {
    // if(JSON.stringify(this.props.scheduleDays) !== JSON.stringify(prevProps.scheduleDays)) {
    //         this.setState({scheduleDays:this.props.scheduleDays || []})
    // }
  }

  handleDays = (selectedDayIdList, e, i) => {
    if (!this?.props?.isWritable && this?.props?.editMode) {
      message.error("Enable write mode to edit the schedule!");
      return;
    }
    try {
      selectedDayIdList = sortDayIds(
        this?.props?.scheduleDays,
        selectedDayIdList
      );

      // this.setState({day: e});
      const progressByDay = _.cloneDeep(this?.props?.data?.progressByDay);

      this.props.node.setDataValue(
        this?.props?.column?.colId,
        selectedDayIdList
      );

      const {
        concatDays_From_PLI_ToElevation,
        updateDays_From_Addons_to_PLI,
        updateDays_From_SubPLI_to_PLI,
        updateDays_from_serviceAddons,
        serviceId,
        elevationIndex,
        origin,
      } = this.props;

      // if(serviceId.toString() !== "3") {
      const { d1, d2 } = this;
      let new_progressByDay =
        get_new_progressByDay(selectedDayIdList, progressByDay, { d1, d2 }) ||
        [];
      new_progressByDay =
        new_progressByDay?.filter(
          (day) => !progressByDay?.some((d) => d?.day === day?.day)
        ) || [];

      this.props.node.setDataValue("progressByDay", [
        ...(progressByDay || []),
        ...new_progressByDay,
      ]);

      let totalDays = [];

      this?.props?.api?.forEachNode((n) => {
        // if (n?.data?.addons && n?.data?.addons?.length > 0) {
        //   for (const addon of n?.data?.addons) {
        //     totalDays = totalDays.concat(addon?.days);
        //     // addon.days = selectedDays
        //   }
        // }
        totalDays = totalDays.concat(n?.data?.days);
      });
      totalDays = [...new Set(totalDays)];
      totalDays = sortDayIds(this?.props?.scheduleDays, totalDays);

      // console.log(totalDays)

      // console.log(this.props)
      if (origin === "pli") {
        concatDays_From_PLI_ToElevation(
          serviceId,
          elevationIndex,
          selectedDayIdList,
          this?.props?.node?.data
        );
      } else if (origin === "subPLI") {
        updateDays_From_SubPLI_to_PLI(
          serviceId,
          elevationIndex,
          this?.props?.node?.data,
          totalDays
        );
      } else if (origin === PROGRESS_ORIGIN.SERVICE_ADDON) {
        updateDays_from_serviceAddons({
          serviceId,
          addon: this?.props?.node?.data,
        });
      } else if (origin === PROGRESS_ORIGIN.PLI_ADDON) {
        updateDays_From_Addons_to_PLI(
          serviceId,
          elevationIndex,
          this?.props?.node?.data,
          totalDays
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    let disabled = false;
    let originPli = this?.props?.origin === "pli";
    let originSubPli = this?.props?.origin === "subPLI";
    let text = "";
    if (prevPliCompleted(this?.props?.node?.data) && originPli) {
      disabled = true;
      text = "Completed Pli!";
    } else if (this?.props?.data?.typeOfProgress === "breakdown" && originPli) {
      disabled = true;
      text = "Days should be selected in breakdowns!";
    } else if (
      this?.props?.editMode &&
      !this?.props?.data?.subPli?.length &&
      originPli &&
      !"other Scope" in this?.props?.data
    ) {
      disabled = false;
      text = "Select Days";
    } else if (this?.props?.data?.subPli?.length && originPli) {
      disabled = true;
      text = "Select days in sub-PLIs";
    } else if (originSubPli) {
      disabled = prevSubPliCompleted(this?.props?.data);
      text = prevSubPliCompleted(this?.props?.data)
        ? "Completed!"
        : "Select days";
    } else if (this?.props?.data?.hasOwnProperty("other Scope")) {
      disabled = true;
      text = "For this service, use breakdowns!";
    } else {
      disabled = false;
      text = "Select Days";
    }

    let sortedDays = [];
    if (this?.props?.value?.length > 0) {
      sortedDays = [...new Set(this?.props?.value)]?.filter?.((el) => {
        let day = this?.props?.scheduleDays?.find((d) => d?.id === el);
        if (!!day) {
          return (
            day?.status !== "Postponed" &&
            day?.status !== "Canceled" &&
            day?.status !== "Skipped"
          );
        } else return false;
      });
    }

    sortedDays = sortDayIds(this?.props?.scheduleDays, sortedDays) || [];

    const scheduleIds =
      this?.props?.context?.thisProjectSchedules?.map?.(
        (sch) => sch?.scheduleId
      ) || [];

    return (
      <>
        {!this?.props?.node?.group ? (
          <div className="days-in-scheduleMaster-cell">
            {"editedFrom" in (this?.props?.node?.data || {}) &&
            Object.keys(this?.props?.node?.data?.editedFrom || {})?.length >
              0 ? (
              <Tooltip
                overlayClassName="tooltip-overlay-days-in-scheduleMaster-cell"
                title={
                  <div style={{ width: 800 }}>
                    <span>{`Schedule is deleted!`}</span>{" "}
                    <strong
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        this?.props?.unlockPliIfPrevScheduleDeleted(
                          this?.props?.serviceId,
                          this?.props?.elevationIndex,
                          this?.props?.node?.data
                        )
                      }
                    >
                      Click here to unlock pli!
                    </strong>
                  </div>
                }
                defaultOpen={
                  !scheduleIds?.includes?.(
                    this?.props?.node?.data?.editedFrom?.scheduleId
                  )
                }
                color="red"
                open={
                  !scheduleIds?.includes?.(
                    this?.props?.node?.data?.editedFrom?.scheduleId
                  )
                }
              >
                <span
                  style={{
                    display: "flex",
                    // justifyContent: "center",
                    alignItems: "center",
                    width: 300,
                    gap: 10,
                    color: this?.props?.context?.isDarkMode
                      ? "#fff"
                      : "#333238",
                  }}
                >
                  Edited Pli in
                  <strong
                    // onClick={() =>
                    //   this?.props?.unlockPliIfPrevScheduleDeleted(
                    //     this?.props?.serviceId,
                    //     this?.props?.elevationIndex,
                    //     this?.props?.node?.data
                    //   )
                    // }
                    onClick={() =>
                      openInNewTab(
                        `/scheduling/${this?.props?.node?.data?.editedFrom?.scheduleId}`
                      )
                    }
                    style={{
                      color: "#1264A3",
                      cursor: "pointer",
                    }}
                  >
                    {this?.props?.node?.data?.editedFrom?.scheduleName}
                  </strong>
                </span>
              </Tooltip>
            ) : (
                originPli ? sortedDays?.length > 4 : sortedDays?.length > 2
              ) ? (
              <Button
                disabled={disabled}
                style={{
                  width: 280,
                  border: "none",
                  borderRadius: 5,
                  backgroundColor: "#1264A3",
                  color: "#fff",
                }}
                onClick={() => {
                  this.setState({
                    transferDays: { visible: true, days: sortedDays },
                  });
                }}
              >
                {sortedDays?.length +
                  " " +
                  "days" +
                  " " +
                  "used in this" +
                  " " +
                  this?.props?.origin}
              </Button>
            ) : (
              // <Button
              //   style={{
              //     width: 280,
              //     border: "none",
              //     borderRadius: 5,
              //     backgroundColor: "#1264A3",
              //     color: "#fff",
              //   }}
              //   onClick={() => {
              //     this.setState({
              //       transferDays: { visible: true, days: sortedDays },
              //     });
              //   }}
              // >
              //   {sortedDays?.length + " " + "days" + " " + "used in this PLI"}
              // </Button>
              <Select
                notFoundContent={"No Data"}
                popupClassName={
                  this?.props?.context?.isDarkMode && "darkDropDown"
                }
                placeholder={disabled ? text : "Select Days"}
                value={sortedDays}
                onChange={this.handleDays}
                optionLabelProp="label"
                mode="multiple"
                style={{
                  width: 300,
                  border: "none",
                  // height: 40,
                  overflow: "auto",
                }}
                disabled={disabled}
                className="days-in-schedule-cell-select"
                suffixIcon={<DropDownArrow />}
              >
                {this?.props?.scheduleDays
                  ?.filter?.((sch) => {
                    return !sch.isNotAvailable();
                  })
                  ?.sort?.((a, b) => dayjs(a?.startDate) - dayjs(b?.startDate))
                  ?.map?.((scheduleDay, index) => {
                    return DaySelectOption({
                      scheduleDay,
                      isDarkMode: this?.props?.context?.isDarkMode,
                    });
                  })}
              </Select>
            )}
          </div>
        ) : (
          ""
        )}
        <TransferDays
          {...{
            transferDays: this?.state?.transferDays,
            scheduleDays:
              this?.props?.scheduleDays
                ?.filter(
                  (a) =>
                    a?.status !== "Postponed" &&
                    a?.status !== "Canceled" &&
                    a?.status !== "Skipped"
                )
                ?.sort((c, d) => dayjs(c?.startDate) - dayjs(d?.startDate)) ||
              [],
            closeTransferDays: (e) => {
              this.setState({
                transferDays: { ...this?.state?.transferDays, visible: e },
              });
            },
            handleDays: this.handleDays,
            comesFromElevation: false,
          }}
        />
      </>
    );
  }
}

export const sortDayIds = (scheduleDays, idList) => {
  let sortedIdList = [];

  for (const scheduleDay of scheduleDays || []) {
    let id = idList?.find((id) => id === scheduleDay?.id);
    if (id) sortedIdList.push(id);
  }

  return sortedIdList;
};
