import { fetchAllData, fetchData } from "../ApiMethods";

export const getTakeOffComparisonWithAccounting = async ({ keysToInclude }) => {
  const [estimationsRes, scheduleOfValuesRes = [], paymentsRes, invoicesRes] =
    await Promise.all([
      fetchAllData({
        endpoint: "estimations",
        resultId: "estimationId",
        otherStringParams: {
          keysToInclude: JSON.stringify([
            ...new Set([
              "estimationId",
              "projectId",
              "estSTATUS",
              "estimationNumber",
              "versionServices",
              ...keysToInclude,
            ]),
          ]),
          filters: JSON.stringify([
            {
              conditions: [
                { column: "estSTATUS", value: "Approved", formula: "is" },
              ],
            },
          ]),
        },
      }),
      fetchAllData({
        endpoint: "scheduleOfValues",
        resultPosition: "schedules",
        resultId: "scheduleId",
        otherStringParams: {
          keysToInclude: JSON.stringify([
            "scheduleId",
            "projectId",
            "services",
          ]),
        },
      }),
      fetchAllData({
        endpoint: "payments",
        resultId: "paymentId",
        otherStringParams: {
          keysToInclude: JSON.stringify([
            "paymentId",
            "projectId",
            "payments",
            "invoices",
          ]),
        },
      }),
      fetchAllData({
        endpoint: "invoices",
        resultId: "invoiceId",
        otherStringParams: {
          keysToInclude: JSON.stringify([
            "invoiceId",
            "projectId",
            "categoriesFrom",
            "invoiceItems",
            "invoiceNumber",
          ]),
        },
      }),
    ]);

  const result = estimationsRes.map((estimation) => {
    const invoices = invoicesRes
      .filter(
        (invoice) =>
          invoice.projectId === estimation.projectId &&
          invoice.categoriesFrom.includes("estimations")
      )
      .map((invoice) => {
        return {
          ...invoice,
          invoiceItems: invoice.invoiceItems.filter(
            (item) => item.category === "estimations"
          ),
        };
      });

    const scheduleOfValues = scheduleOfValuesRes.filter(
      (schedule) => schedule.projectId === estimation.projectId
    );

    const payments = paymentsRes.filter((payment) =>
      payment.payments.some((paymentDetail) =>
        invoices.some(
          (invoice) => invoice.invoiceNumber === paymentDetail.invoiceNumber
        )
      )
    );

    const versionServices = Object.values(estimation.versionServices).filter(
      (service) => service.status === "Approved"
    );

    return versionServices.map((versionService) => ({
      ...estimation,
      takeOffStatus: versionService.status,
      versionName: versionService.versionName,
      services: versionService.services.map((service) => ({
        serviceName: service.label,
        takeOffStatus: versionService.status,
        versionName: versionService.versionName,
        estimationNumber: estimation.estimationNumber,
        estimationId: estimation.estimationId,
        totalEstimation: service.totalities.totalPrice, //getServiceLegacyPrice(service),
        totalEstimationServiceOnly: service.serviceOptions?.reduce(
          (acc, optionGroup) => {
            return (
              acc +
              optionGroup?.[0]?.items?.reduce((innerAcc, item) => {
                return innerAcc + item.totalPrice;
              }, 0)
            );
          },
          0
        ), //getServiceLegacyPrice(service),
        totalInvoice: invoices.reduce((sum, invoice) => {
          return (
            sum +
            invoice.invoiceItems
              .filter((item) => item.name === service.label)
              .reduce((itemSum, item) => {
                return itemSum + item.total;
              }, 0)
          );
        }, 0),
        totalInvoiceServiceOnly: invoices.reduce((sum, invoice) => {
          return (
            sum +
            invoice.invoiceItems
              .filter((item) => item.name === service.label)
              .reduce((itemSum, item) => {
                return (
                  itemSum +
                  item.data?.serviceOptions?.reduce((acc, optionGroup) => {
                    return (
                      acc +
                      optionGroup?.[0]?.items?.reduce((innerAcc, item) => {
                        return innerAcc + item.totalPrice;
                      }, 0)
                    );
                  }, 0)
                );
              }, 0)
          );
        }, 0),
        totalSov: scheduleOfValues.reduce((sum, sov) => {
          return (
            sum +
            sov.services
              .filter((sovService) => sovService.label === service.label)
              .reduce((itemSum, item) => {
                return itemSum + item.totalPrice;
              }, 0)
          );
        }, 0),
        totalPaid: payments.reduce((sum, payment) => {
          return (
            sum +
            payment.invoices.reduce((itemSum, item) => {
              return (
                itemSum +
                service.totalities.totalPrice *
                  ((item?.invoicePaidPercentage || 0) / 100)
              );
            }, 0)
          );
        }, 0),
      })),
    }));
  });

  return result.flat();
};
