import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useEditLogs,
  useProgramFields,
  useProgressComponent,
  useUploadedFiles,
} from "../../../../hooks";
import { useCreateDriveFolders } from "../../../../hooks/useCreateDriveFolders";
import { getDriveFolderKeyName } from "../../utils/getDriveFolderKeyName";
import { driveApi } from "../../../../integrations/DriveRequest";
import { Form, Modal, Tooltip, message } from "antd";
import {
  useIncidentFields,
  useIncidentsFieldsWatch,
  incidentsApiData,
  incidentReducer,
  modalFooter,
  DATE_KEY,
  ADDRESS_FIELD,
  DRIVE_FOLDER_KEY,
  NAME_KEY,
  EDIT_ACTION,
  CREATE_ACTION,
  DELETE_ACTION,
  SAFETY_BODY_CAT,
  incidentCustomLogObj,
} from "./utils";
import {
  fetchAllData,
  fetchData,
  filterTables,
  updateDocumentTitle,
} from "../../../../utils";
import {
  changeDateOfIncident as dateOfIncidentHandler,
  onChangeAddress as addressChangeHandler,
  safetyBody,
  onCloseModal,
  populateSafetyForm,
  safetyEmailNotif,
  checkSaveDraft,
} from "../../Safety/helpers";
import { filterSchedulesOnDate } from "../../Safety/SafetyModal/utils/filterSchedulesOnDate";
import { dayjsNY } from "../../../DateComponents/contants/DayjsNY";
import {
  createIncident,
  deleteIncident,
  editIncident,
  incidentPdf,
} from "./utils/helpers";
import { API } from "aws-amplify";
import { XIcon } from "../../Communication/assets";
import CustomModalHeader, {
  PlayVideoTutorial,
} from "../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { LoadableComp } from "../../XComponents";
import IncidentStatus from "../Components/IncidentStatus";
import IncidentFormSection from "../Components/IncidentFormSection";
import DynamicTeamModal from "../../../Header/forms/DynamicTeamModal/DynamicTeamModal";
import AskForTaskModal from "../../Fleet/view/pageView/components/SingleTruckIncidents/modals/IncidentModal/AskForTaskModal";
import EmailBox from "../../Communication/components/EmailBox/EmailBox";
import {
  MultiLevelTreeLogs,
  ProgressComponent,
} from "../../../commonComponents";
import IncidentWarningSection from "../Components/IncidentWarningSection";
import InspectionModal from "../../components/AgGridComponents/InspectionModal";
import TasksBasePage from "../../TaskManager/Tasks/TasksBasePage";
import SafetyModal from "../../Safety/SafetyModal/SafetyModal";
import {
  findMissingFolderKeys,
  safetyAndIncidentFolderKeys,
} from "../../DynamicView/Pages/Overview/components/DrivePermission/utils/utils";
import AddDriveFolderModal from "../../DynamicView/Pages/Overview/components/AddDriveFolderModal/AddDriveFolderModal";
import { keys } from "../../../pages/Settings/settingsComponents/Roles/RolesData";
import { SettingIcon } from "../../../Header/subcomponents";
import { toCamelCase } from "../../../pages/Settings/settingsComponents/ApprovalsDynamicForms/FormFieldModals/newFieldModal";
import DocumentationModal from "../../Documentation/View/components/DocumentationModal/DocumentationModal";
import SafetyInspections from "../../Safety/Inspections/SafetyInspections";
import SortFields from "../../../pages/Settings/settingsComponents/SafetyDynamicForms/SortFields/SortFields";
import { TransferIconNew } from "../../DynamicView/src";
import { saveSortedFields } from "../../Safety/SafetyModal/helpers";
import IncidentExportPreview from "../Components/IncidentExportPreview";
import useHandleAttachments from "../../Safety/hooks/useHandleAttachments";

const NewIncidentModal = ({
  incidentCategory,
  visible,
  getSingleIncidentData,
  setVisible: closeIncident,
  refreshTable = () => {},
  rowObject,
  showNextStep = true,
  setVisibleReport,
  requestId = "",
  propProjId = "",
  propAddress = "",
  nextStepHandler = () => {},
  handleSavedRecord = () => {},
  afterSaveRequest = async () => {},
  documentationModalFiles,
  setDocumentationModalFiles,
}) => {
  const dispatch = useDispatch();
  const { saveAddedLogs } = useEditLogs();
  const rowObjectKey = "incidentObject";

  const [
    { accessToken },
    { userConfiguration },
    { hotCredentials },
    { programFields },
    { authenticatedUser },
    { base64 },
    { notifications },
    { isDarkMode },
    { preferences },
    { loggedOnTasks },
  ] = useSelector((state) => [
    state.accessToken,
    state.userConfig,
    state.hotCredentials,
    state.programFields,
    state.authenticatedUser,
    state.base64,
    state.notifications,
    state.darkMode,
    state.preferences,
    state.loggedOnTasks,
  ]);

  const { ["List of Folders"]: folderList } = useProgramFields();

  const {
    incidentObject: defaultObj = {},
    incidentId: defaultId = "",
    gDriveUploads: requestUploads = [],
  } = rowObject || {};

  const [uploadHandlers, deleteHandlers] = useUploadedFiles([]);
  const [uploadedFiles, setUploadedFiles, formattedFiles, onPickerSuccess] =
    uploadHandlers;
  const [filesToBeDeleted, setFilesToBeDeleted, onDelete] = deleteHandlers;
  const driveFunctions = useCreateDriveFolders(
    SAFETY_BODY_CAT?.[incidentCategory]
  );

  const driveRequest = driveApi({ accessToken });

  const updateRef = useRef(0);

  const folderDriveKeyName = getDriveFolderKeyName({
    programFields,
    name: SAFETY_BODY_CAT?.[incidentCategory],
  });

  const [actionType, setActionType] = useState("Update");

  const [driveFolder, setDriveFolder] = useState(null);
  const [addDriveModal, setAddDriveModal] = useState(false);

  const [wordPreview, setWordPreview] = useState(false);

  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
    hideProgress,
  } = useProgressComponent({
    categoryName: "Incidents",
    actionType:
      actionType === "Delete" ? "Delete" : !!defaultId ? "Update" : "Create",
  });

  const [form] = Form.useForm();

  const [incidentFormData, dispatchIncidentFormData] = useReducer(
    incidentReducer,
    {}
  );

  const { incidentFields } = useIncidentFields(incidentCategory);

  const fieldsToWatch = useIncidentsFieldsWatch(form, incidentCategory);

  const [pdfId, setPdfId] = useState("");
  const [editLogsVisibility, setEditLogsVisibility] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [signatureModalVisible, setSignatureModalVisible] = useState(false);
  const [editSignIn, setEditSignIn] = useState(false);
  const [whichSignToChange, setWhichSignToChange] = useState();
  const [divClickable, setDivClickable] = useState(true);
  const [witnessCount, setWitnessCount] = useState(1);
  const [witnessSignings, setWitnessSignings] = useState([""]);
  const [safetyData, setSafetyData] = useState();
  const [safetyModalVisible, setSafetyModalVisible] = useState(false);
  const [mainDisabledField, setMainDisabledField] = useState(
    !!defaultObj?.[DRIVE_FOLDER_KEY?.[incidentCategory]]
  );
  const [tasksVisible, setTasksVisible] = useState(false);
  const [inspections, setInspections] = useState(false);
  const [loading, setLoading] = useState(false);

  // !!defaultObj?.addressOfDamagedProperty
  // const [askTaskModal, setAskTaskModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState();
  const [assignedTo, setAssignedTo] = useState([]);
  const [addressSelectionInfo, setAddressSelectionInfo] = useState({
    projectId: rowObject?.projectId || propProjId || "",
    accountId: rowObject?.accountId || "",
    scheduleId: rowObject?.scheduleId || "",
  });
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [teamModalVisible, setTeamModalVisible] = useState(false);
  const [changes, setChanges] = useState(false);
  const [videoTutorial, setVideoTutorial] = useState(false);
  const [sign, setSign] = useState(false);
  const [currentStep, setCurrentStep] = useState();
  const [openSort, setOpenSort] = useState(false);

  const popoverProps = {
    projectId: rowObject?.projectId,
    accountId: rowObject?.accountId,
    scheduleId: rowObject?.scheduleId,
  };

  const currentProject = useMemo(() => {
    if (
      !(addressSelectionInfo?.projectId || [])?.length ||
      !addressSelectionInfo?.projectId
    ) {
      return null;
    }
    return (
      incidentFormData?.projects?.find(
        (el) => el?.projectId === addressSelectionInfo?.projectId
      ) || null
    );
  }, [addressSelectionInfo?.projectId, incidentFormData?.projects]);

  const { handleAttachments, handleUploadedFiles } = useHandleAttachments({
    base64,
    accessToken,
    category: incidentCategory,
    driveRequest,
    rowObject,
    driveFunctions,
    folderDriveKeyName,
    dynamicFields: incidentFormData?.dynamicFields,
    selectedTeam,
    crews: incidentFormData?.crews,
    userConfiguration,
    defaultId,
    witnessCount,
    witnessSignings,
    rowObjectKey,
    pdfFileName: `Property Damage Incident ${rowObject?.incidentName}`,
    uploadedFiles,
  });

  const disabledAllFields = useMemo(() => {
    return (
      (incidentFormData?.safety?.length > 0 &&
        Array.isArray(incidentFormData?.safety) &&
        !!defaultId) ||
      false
    );
  }, [incidentFormData?.safety, defaultId]);

  const videoTutorialLink = useMemo(() => {
    return programFields
      ?.filter((item) => item?.fieldName === "Portal Video Tutorials")?.[0]
      ?.fieldOptions?.find((item) => item?.categoryName === "Incidents")
      ?.subCategories?.[0]?.videos?.find(
        (item) =>
          item?.videoName === `New ${SAFETY_BODY_CAT?.[incidentCategory]}`
      )?.videoLink;
  }, [programFields]);

  const statusesColors = useMemo(() => {
    return programFields
      ?.find((el) => el?.fieldName === "Statuses Of Categories")
      ?.fieldOptions?.Incidents.filter(({ status }) => status);
  }, [programFields]);

  const incidentsHotCredential = hotCredentials?.find(
    (obj) => obj.credentialId === "18"
  );

  const noteAccess = useMemo(
    () =>
      userConfiguration?.routeConfig
        ?.find(({ title }) => title === "Notes/View")
        ?.children?.find((el) => el?.title === incidentCategory),
    [userConfiguration?.routeConfig]
  );

  const windowHandler = useCallback((event) => {
    event.preventDefault();
    event.returnValue = "Are you sure you want to leave the page?";
    return "Are you sure you want to leave the page?";
  }, []);

  function setVisible(val) {
    if (documentationModalFiles !== null) {
      getSingleIncidentData();
      window.removeEventListener("beforeunload", windowHandler);
      return;
    }

    window.removeEventListener("beforeunload", windowHandler);
    closeIncident(val);
  }

  function closeModal() {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setVisible(false);
  }

  const changeDateOfIncident = (date) =>
    dateOfIncidentHandler({
      date,
      form,
      schedules: incidentFormData?.scheduling,
      setSchedulesForDate: (data) => {
        dispatchIncidentFormData({
          type: "SCHEDULES_FOR_DATE",
          payload: { schedulesForDate: data },
        });
      },
      setAddressSelectionInfo,
      addressSelectionInfo,
      addressField: ADDRESS_FIELD?.[incidentCategory] || "",
    });

  const onChangeAddress = (val, option) =>
    addressChangeHandler({
      val,
      form,
      option,
      projects: incidentFormData?.projects || [],
      schedulesForDate: incidentFormData?.schedulesForDate || [],
      setAddressSelectionInfo,
    });

  const onTeamSelect = (_, data) => {
    setSelectedTeam((prev) => [
      ...prev,
      {
        value: data.value,
        members: data?.members?.map((el) => el),
      },
    ]);
  };

  const onTeamDeselect = (val, data) => {
    const teamName = data?.value || val;
    setSelectedTeam((prev) => prev?.filter((el) => el.value !== teamName));
    if (form.getFieldValue("team").length < 1) {
      setSelectedTeam([]);
    }
  };

  const safetyDirectorSignature = useMemo(() => {
    if (rowObject?.incidentObject?.safetyDirectorSignature) {
      return rowObject?.incidentObject?.safetyDirectorSignature;
    } else {
      return (
        programFields?.find((el) => el?.fieldName === "Safety Dynamic Fields")
          ?.fieldOptions?.safetyDirectorSignature || null
      );
    }
  }, [programFields, userConfiguration]);
  //region FIELDS
  const formFields = useMemo(() => {
    if (!incidentFormData?.dynamicFields) return [];

    return incidentFields({
      mainDisabledField,
      userConfiguration,
      changeDateOfIncident,
      onChangeAddress,
      setChanges,
      disabledAllFields,
      addressSelectionInfo,
      defaultId,
      propAddress,
      rowObject,
      isDarkMode,
      dispatchIncidentFormData,
      ...fieldsToWatch,
      ...incidentFormData,
    });
  }, [
    mainDisabledField,
    JSON.stringify(incidentFormData),
    JSON.stringify(fieldsToWatch),
  ]);

  //region onSave
  const onSave = async (saveDraft = false) => {
    if (!sign && !saveDraft) {
      return message.error("Please sign the form before saving");
    }

    let allFolders = null;

    const folderName = form.getFieldValue(DRIVE_FOLDER_KEY?.[incidentCategory]);

    if (
      !driveFunctions.parentFolderId &&
      !rowObject?.googleDriveFolderIds?.[folderDriveKeyName] &&
      !!folderName
    ) {
      try {
        const { folders } = await driveFunctions.create({
          parentFolderName: `${folderName} ${dayjsNY().format(
            "MM/DD/YYYY HH:mm:ss"
          )}`,
          parentId: incidentsHotCredential?.credentialValue,
        });

        allFolders = folders;
      } catch (err) {
        console.error("Error Creating Drive Folder: ", err);
        allFolders = null;
      }
    }

    const { parentFolderId } = driveFunctions;
    const driveFolderCondition = () => {
      if (rowObject?.googleDriveFolderIds?.[folderDriveKeyName]) {
        return rowObject?.googleDriveFolderIds;
      } else if (parentFolderId) {
        return driveFunctions?.allFolders;
      } else {
        return allFolders;
      }
    };

    //region saveBody
    const { tmpBody = {} } = safetyBody({
      table: "incidents",
      form,
      sign,
      assignedTo,
      currentStep,
      selectedTeam,
      witnessCount,
      nameKey: NAME_KEY?.[incidentCategory],
      category: SAFETY_BODY_CAT?.[incidentCategory],
      projects: incidentFormData?.projects,
      schedules: incidentFormData?.scheduling,
      statusesColors,
      saveDraft,
      dynamicFields: incidentFormData?.dynamicFields,
      witnessSignings,
      // witnessStatements,
      userConfiguration,
      googleDriveFolderIds: driveFolderCondition(),
      formattedFiles,
      addressSelectionInfo,
      crews: incidentFormData?.crews,
      drivers: incidentFormData?.drivers,
      safetyDirectorSignature,
      documentationModalFiles,
    });

    if (!!defaultId) {
      setVisibleCreationProgress(rowObject);
      setCancelModalVisible(false);

      await editIncident({
        tmpBody,
        nameKey: NAME_KEY?.[incidentCategory],
        rowObject,
        category: incidentCategory,
        editAction: EDIT_ACTION?.[incidentCategory],
        authenticatedUser,
        userConfiguration,
        refreshTable,
        updateProgressStatus,
        customLogObj(data) {
          return incidentCustomLogObj?.[incidentCategory]?.({
            data,
            userConfiguration,
            ...incidentFormData,
          });
        },
        saveAddedLogs,
      });
    } else {
      setCancelModalVisible(false);

      await createIncident({
        dispatch,
        accessToken,
        tmpBody,
        nameKey: NAME_KEY?.[incidentCategory],
        category: incidentCategory,
        assignedTo,
        preferences,
        saveDraft,
        createAction: CREATE_ACTION?.[incidentCategory],
        loggedOnTasks,
        programFields,
        requestId,
        requestUploads,
        userConfiguration,
        authenticatedUser,
        folderDriveKeyName,
        refreshTable(res) {
          refreshTable(
            [res].reduce(
              (acc, val) => [
                ...acc,
                {
                  ...val,
                  addressOfInjury: val?.incidentObject?.addressOfInjury,
                  projectName: val?.incidentObject?.projectName || "N/A",
                  scheduleAddress:
                    val?.incidentObject?.scheduleAddress || "N/A",
                  accountName: val?.incidentObject?.accountName || "N/A",
                },
              ],
              []
            )
          );
        },
        setSafetyData,
        addressSelectionInfo,
        handleSavedRecord,
        setVisibleCreationProgress,
        updateProgressStatus,
        hideProgress,
        afterSaveRequest,
        saveAddedLogs,
      })
        .then((res) => res?.incidentStatus !== "Draft" && nextStepHandler())
        ?.catch((err) => console.error("Error Creating Incident: ", err));
    }
  };

  async function onContinueToUpload() {
    const uploaderButton = document.querySelector(".uploaderButton");

    form
      .validateFields([DRIVE_FOLDER_KEY?.[incidentCategory]])
      .then(async () => {
        const { folders } = await driveFunctions.create({
          parentFolderName: `${form
            .getFieldValue(DRIVE_FOLDER_KEY?.[incidentCategory] || "")
            .trim()} ${dayjsNY().format("DD/MM/YYYY HH:mm:ss")}`,
          parentId: incidentsHotCredential?.credentialValue,
        });

        setMainDisabledField(true);
        setCancelModalVisible(false);
        setDivClickable(false);
        setDriveFolder(folders?.[folderDriveKeyName]);
        setTimeout(() => {
          if (!!uploaderButton) {
            uploaderButton.click();
          }
        }, 1000);
      })
      .catch((err) => {
        setCancelModalVisible(false);
        console.error("Error uploading: ", err);
      });
  }

  //region onClose
  async function onClose(param = {}) {
    await onCloseModal({
      form,
      param,
      driveRequest,
      nameKey: NAME_KEY?.[incidentCategory],
      folderDriveKeyName,
      objectKey: "incidentObject",
      rowObject,
      driveFunctions,
      filesToBeDeleted,
      setVisible,
      setUploadedFiles,
      updateDocumentTitle,
      setFilesToBeDeleted,
      setCancelModalVisible,
      saveAddedLogs,
    });
  }

  //region onDeleteRow
  async function onDeleteRow() {
    if (!!defaultId) {
      setCancelModalVisible(false);
      setActionType("Delete");

      message.loading({
        content: "Deleting...",
        key: "messageDeleting",
      });
      await deleteIncident({
        dispatch,
        nameKey: NAME_KEY?.[incidentCategory],
        category: incidentCategory,
        rowObject,
        deleteAction: DELETE_ACTION?.[incidentCategory],
        // onClose,
        notifications,
        userConfiguration,
        authenticatedUser,
        refreshTable,
        setVisibleCreationProgress,
        updateProgressStatus,
      });
    }
  }

  function checkForChanges() {
    if (!defaultId) {
      let requiredFields = incidentFormData?.dynamicFields
        ?.filter(
          ({ category, required }) =>
            category === "General Information" && required
        )
        ?.map(({ formItemName }) => formItemName);
      let fields = form.getFieldsValue(requiredFields);
      if (Object.values(fields).some((value) => !!value)) {
        setCancelModalVisible(true);
      } else {
        onClose();
      }
    } else {
      setCancelModalVisible(true);
    }
  }

  function onCancel() {
    if (!!changes || !!driveFunctions.parentFolderId) {
      checkForChanges();
    } else if (!changes && !defaultId) {
      checkForChanges();
    } else {
      onClose();
    }
  }

  async function sendEmailNotification() {
    await safetyEmailNotif({
      table: "incidents",
      category: incidentCategory,
      rowObject,
      defaultId,
      setEditLogs(res) {
        dispatchIncidentFormData({
          type: "EDIT_LOGS",
          payload: { editLogs: res },
        });
      },
      authenticatedUser,
      userConfiguration,
      saveAddedLogs,
    });
  }

  async function onGeneratePdf(sorted = false) {
    await incidentPdf({
      base64,
      pdfId,
      accessToken,
      category: incidentCategory,
      driveRequest,
      rowObject,
      driveFunctions,
      setPdfId,
      folderDriveKeyName,
      createPdfParams: {
        dynamicFields: !!sorted ? sorted : incidentFormData?.dynamicFields,
        selectedTeam,
        crews: incidentFormData?.crews,
        userConfiguration,
        defaultId,
        witnessCount,
        witnessSignings,
      },
      rowObjectKey,
    });
  }

  useEffect(() => {
    if (visible) {
      const apiData = {
        ...incidentsApiData?.[incidentCategory],

        dynamicFields: () =>
          filterTables(
            "programFields",
            "fieldName",
            "Incidents Dynamic Fields"
          ),

        teams: () => API.get("teams", "/teams"),
        projectExecutives: () =>
          fetchData({
            endpoint: "projectExecutives",
          }),
      };

      if (!!defaultId) {
        Object.assign(apiData, {
          safety: () => filterTables("safety", "incidentId", defaultId),
        });
      }

      const dataResponses = Object.keys(apiData)?.map(async (key) => {
        let data = await apiData?.[key]();

        const tmpData = Array.isArray(data) ? data : data?.[key];

        if (!!tmpData?.length) {
          if (key === "dynamicFields") {
            return {
              [key]: tmpData[0]?.fieldOptions?.[incidentCategory],
            };
          }
          return {
            [key]: tmpData,
          };
        }
      });

      Promise.all([...dataResponses])
        .then((responses) => {
          window.addEventListener("beforeunload", windowHandler);

          const dispatchData = responses?.reduce(
            (acc, curr) => ({
              ...acc,
              ...curr,
            }),
            {}
          );

          dispatchIncidentFormData({
            type: "GET_DATA",
            payload: { data: dispatchData },
          });

          dispatchIncidentFormData({
            type: "SCHEDULES_FOR_DATE",
            payload: {
              schedulesForDate:
                filterSchedulesOnDate(
                  dispatchData?.scheduling,
                  defaultObj?.[DATE_KEY?.[incidentCategory]] || dayjsNY()
                )?.schedulesForDay || [],
            },
          });
        })
        .catch((err) => {
          console.error("Error Getting Incident Data: ", err);
          closeModal();
        });
    }

    return () => window.removeEventListener("beforeunload", windowHandler);
  }, [visible]);

  useEffect(() => {
    if (!!rowObject) {
      if (!!defaultId) {
        Promise.allSettled([
          fetchAllData({
            endpoint: "editLogs",
            resultPosition: "editLogs",
            resultId: "logId",
            otherStringParams: {
              getMaxLimit: "true",
              filters: JSON.stringify([
                {
                  conditions: [
                    { column: "recordId", value: defaultId, formula: "is" },
                  ],
                },
              ]),
            },
          }),
          fetchAllData({
            endpoint: "tasksManagement",
            resultId: "taskId",
            otherStringParams: {
              filters: JSON.stringify([
                {
                  conditions: [
                    {
                      column: "taskTopicId",
                      value: rowObject?.incidentId,
                      formula: "is",
                    },
                  ],
                },
              ]),
            },
          }),
        ])
          ?.then(([{ value: editLogs }, { value: taskData }]) => {
            dispatchIncidentFormData({
              type: "EDIT_LOGS_&_TASKS",
              payload: { editLogs, taskData },
            });
          })
          .catch((err) => {
            console.error("Error Getting Logs: ", err);
          });

        // if (!!rowObject?.googleDriveFolderIds?.[folderDriveKeyName]) {
        setDivClickable(false);
        // }
        let { viewLogs = {} } = rowObject;
        viewLogs = {
          ...viewLogs,
          [authenticatedUser?.sub]: {
            lastSeen: Date.now(),
            name: userConfiguration?.nameOfUser,
          },
        };

        API.put("incidents", `/incidents/${rowObject?.incidentId}`, {
          body: {
            viewLogs,
          },
        })
          .then(() => {
            dispatchIncidentFormData({
              type: "VIEW_LOGS",
              payload: { viewLogs },
            });
          })
          .catch((err) => console.error("Error Updating View Logs: ", err));
      }

      if (!!incidentFormData?.dynamicFields) {
        populateSafetyForm({
          form,
          pdfId,
          rowObject,
          statusKey: "incidentStatus",
          fieldsObject: "incidentObject",
          dynamicFields: incidentFormData?.dynamicFields,
          setSign,
          statusesColors,
          setPdfId,
          setAssignedTo,
          setCurrentStep,
          setSelectedTeam,
          setUploadedFiles,
          setWitnessCount,
          setWitnessSignings,
          data: incidentFormData,
        });
      }
    }
  }, [incidentFormData?.dynamicFields, rowObject]);

  const driveKeys = useMemo(() => {
    return safetyAndIncidentFolderKeys(
      "Incidents",
      incidentCategory,
      folderDriveKeyName
    );
  }, [JSON.stringify(rowObject?.googleDriveFolderIds)]);

  const hasMissingFolders = useMemo(() => {
    if (updateRef?.current === 1) return false;
    const missingKeys = findMissingFolderKeys(
      driveKeys.folderName,
      rowObject?.googleDriveFolderIds,
      folderList
    );
    return missingKeys.length > 0;
  }, [
    rowObject?.googleDriveFolderIds,
    driveKeys.folderName,
    driveFolder,
    updateRef,
  ]);

  useEffect(() => {
    if (!defaultId || !accessToken) return;
    else {
      (async () => {
        if (
          !rowObject?.googleDriveFolderIds ||
          keys(rowObject?.googleDriveFolderIds).length === 0
        ) {
          setDriveFolder(false);
        } else {
          setDriveFolder(false);
          try {
            const driveIdKey = driveKeys?.key;
            const driveFolderId = rowObject?.googleDriveFolderIds?.[driveIdKey];
            const driveRes = await driveRequest.getDriveItem(driveFolderId);
            if (driveRes?.ok) {
              const res = await driveRes.json();
              setDriveFolder(res?.id || driveFolderId);
              if (hasMissingFolders) {
              }
            } else {
              setDriveFolder(false);
            }
          } catch (err) {
            console.log("Folder not found: ", err);
            setDriveFolder(false);
          }
        }
      })();
    }
  }, [JSON.stringify(rowObject?.googleDriveFolderIds), accessToken, defaultId]);
  //region JSX
  return (
    <Modal
      {...{
        open: visible,
        onCancel,
        className: "safetyDynamicForms",
        closable: true,
        centered: true,
        wrapClassName: `safetyDynamicFormsWrap ${
          isDarkMode && "safetyDynamicFormsWrapDark"
        }`,
        afterOpenChange: (event) => {
          event &&
            updateDocumentTitle({ newTitle: `${incidentCategory} Form` });
        },
        style: {
          display: !!incidentFormData?.dynamicFields ? "" : "none",
        },
        closeIcon: <XIcon />,
        destroyOnClose: true,
        title: (
          <CustomModalHeader
            {...{
              title: `${incidentCategory} Form`,
              headerInfo: () =>
                (userConfiguration?.groupName === "Admin" ||
                  userConfiguration?.groupName === "Super Admin") && (
                  <>
                    <Tooltip title="Add New Fields">
                      <SettingIcon
                        className="settingsIcon"
                        onClick={() =>
                          window.open(
                            `${window.location.origin}/settings/${
                              incidentCategory?.toLowerCase()?.includes("other")
                                ? "otherIncident"
                                : `${toCamelCase(incidentCategory)}Incident`
                            }  `
                          )
                        }
                      />
                    </Tooltip>
                    <Tooltip title="Sort Fields">
                      <TransferIconNew
                        {...{
                          className: "sortFieldsIcon",
                          onClick: () => {
                            setOpenSort(true);
                          },
                        }}
                      />
                    </Tooltip>
                  </>
                ),
              onClick() {
                !!videoTutorialLink
                  ? setVideoTutorial(true)
                  : message.info("No video tutorial available for this form");
              },
            }}
          />
        ),
        //region FOOTER
        footer: modalFooter({
          form,
          onCancel,
          setOpenSort,
          setWordPreview,
          isDarkMode: isDarkMode,
          safetyUsingIncident: incidentFormData?.safety,
          changes,
          onSave() {
            checkSaveDraft({
              changes,
              form,
              saveFunction: onSave,
              setVisible,
              setCancelModalVisible,
              populatedInputFields: incidentFormData?.dynamicFields,
              incidentId: "123",
            });
          },
          onDelete() {
            !!disabledAllFields
              ? message.error({
                  content:
                    "This Incident is linked to a Safety Report. Please delete the Safety first.",
                  key: "messageDeleting",
                })
              : setCancelModalVisible("Delete");
          },
          onShowLogs() {
            setEditLogsVisibility(true);
          },
          onGeneratePdf,
          onEmail() {
            handleAttachments(uploadedFiles);
          },
          hasReport: true,
          onReportView: () => setVisibleReport(true),
          viewTasks() {
            setTasksVisible(true);
          },
          viewInspections() {
            setInspections(true);
          },
          status: rowObject?.incidentStatus,
          defaultId,
          selectedTeam,
          hasInspections: true,
          incidentName: rowObject?.incidentName,
          topicCategory: incidentCategory,
          maskClosable: false,
          noteAccess,
          incidentCategory,
          popoverProps,
          projectId: rowObject?.projectId,
          recordId: rowObject?.incidentId,
          loading,
        }),
      }}
    >
      <LoadableComp loading={!incidentFormData?.dynamicFields}>
        <IncidentStatus
          {...{
            form,
            currentStep,
            isDarkMode,
            incidentStatus: rowObject?.incidentStatus || "",
            statusesColors,
            setChanges,
            setCurrentStep,
            disabledAllFields,
          }}
        />

        <Form form={form} onFieldsChange={() => setChanges(true)}>
          <IncidentFormSection
            {...{
              form,
              sign,
              teams: incidentFormData?.teams,
              nameKey: NAME_KEY?.[incidentCategory],
              rowObject,
              formFields,
              accessToken,
              witnessCount,
              selectedTeam,
              isDarkMode,
              uploadedFiles,
              driveFunctions,
              onDelete,
              divClickable,
              setSign,
              witnessSignings,
              setChanges,
              userConfiguration,
              folderDriveKeyName,
              refreshTable,
              onTeamSelect,
              setAssignedTo,
              onTeamDeselect,
              disabledAllFields,
              onPickerSuccess,
              setWitnessCount,
              setWitnessSignings,
              setTeamModalVisible,
              setWhichSignToChange,
              setCancelModalVisible,
              setSignatureModalVisible,
              setEditSignIn,
              driveFolder,
              hasMissingFolders,
              setAddDriveModal,
              safetyDirectorSignature,
              setDocumentationModalFiles,
              handleOnFileUploadSuccess: async (newFiles) => {
                message.loading(
                  `Attaching ${
                    newFiles?.length > 1 ? "files" : "file"
                  } to email...`
                );
                await handleUploadedFiles(newFiles);
              },
            }}
          />
        </Form>
        {!!editLogsVisibility && (
          <MultiLevelTreeLogs
            {...{
              visible: editLogsVisibility,
              setVisible: setEditLogsVisibility,
              logsData: incidentFormData?.editLogs,
              title: `${incidentCategory} Edit Logs`,
              userConfiguration,
            }}
          />
        )}

        {!!videoTutorial && (
          <PlayVideoTutorial
            {...{
              visible: videoTutorial,
              setVisible: setVideoTutorial,
              url: videoTutorialLink,
              title: `${incidentCategory} Form Video Tutorial`,
            }}
          />
        )}

        {!!teamModalVisible && (
          <DynamicTeamModal
            {...{
              open: teamModalVisible,
              setOpen: setTeamModalVisible,
              selectedTeam,
              setSelectedTeam,
              ClearOptions: () => setTeamModalVisible(false),
              onSave: (data) => {
                form.setFieldsValue({ team: data });
              },
              proppedAllTeams: incidentFormData?.teams,
            }}
          />
        )}

        <IncidentExportPreview
          {...{
            wordPreview,
            setWordPreview,
            rowObject,
            incidentFormData,
            selectedTeam,
            incidentCategory,
            witnessCount,
            witnessSignings,
            defaultId,
            userConfiguration,
            accessToken,
            rowObjectKey,
            currentProject,
          }}
        />

        {!!confirmationModal && (
          <AskForTaskModal
            {...{
              title: "Safety Request",
              span: "The next step will be creating a Safety Application for this incident, do you wish to continue?",
              askTaskModal: confirmationModal,
              createTask() {
                setSafetyModalVisible(true);
                setConfirmationModal(false);
                // setVisible(false);
              },
              cancelTaskModal() {
                setConfirmationModal(false);
                setVisible(false);
                updateDocumentTitle(); // reset document title to "Lead Manager";
              },
            }}
          />
        )}

        {!!openSort && (
          <SortFields
            {...{
              visible: !!openSort,
              setVisible: setOpenSort,
              rowData: incidentFormData?.dynamicFields || [],
              saveText: openSort === "pdf" ? "Export to PDF" : "Save",
              onFinish: (res, newLog) => {
                if (openSort === "pdf") {
                  onGeneratePdf(res);
                } else {
                  saveSortedFields({
                    res,
                    newLog,
                    setNewSortFields: () => {
                      dispatchIncidentFormData({
                        type: "SORT_DYNAMIC_FIELDS",
                        payload: { dynamicFields: res },
                      });
                    },
                    programFields,
                    category: incidentCategory,
                    saveAddedLogs,
                    fieldName: "Incidents Dynamic Fields",
                  });
                }
              },
              isDarkMode,
              fieldKey: "category",
              title: "Incidents - " + incidentCategory,
              sortCategories: true,
            }}
          />
        )}
      </LoadableComp>

      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: "Incidents",
            actionType:
              actionType === "Delete"
                ? "Delete"
                : !!defaultId
                ? "Update"
                : "Create",
            visibleCreationProgress,
            creationProgresses,
            closeModal: () => {
              setVisibleCreationProgress(false);

              const { deletePostLogsBody = {} } = creationProgresses || {};

              if (actionType === "Delete") {
                onClose({ deleteAction: true, deletePostLogsBody });
              } else if (!!showNextStep && !defaultId && !!safetyData) {
                setConfirmationModal(true);
              } else {
                onClose();
              }
            },
          }}
        />
      )}

      {addDriveModal && (
        <AddDriveFolderModal
          {...{
            open: addDriveModal,
            onCancel: () => setAddDriveModal(false),
            folderData: driveKeys,
            rowData: rowObject,
            setDriveFolder,
            driveRequest,
            hasMissingFolders,
            callBack: () => {
              updateRef.current = 1;
            },
          }}
        />
      )}

      {safetyModalVisible && (
        <SafetyModal
          {...{
            setEdit() {
              onClose(), setSafetyModalVisible(false);
            },

            category: incidentCategory,
            safetyData,
            updateIncidentStatus: refreshTable,
          }}
        />
      )}

      {cancelModalVisible && (
        <IncidentWarningSection
          {...{
            visible: cancelModalVisible,
            setVisible: setCancelModalVisible,
            defaultId,
            onSave,
            onClose,
            onDeleteRow,
            isDarkMode,
            onContinueToUpload,
            incidentCategory,
          }}
        />
      )}

      {!!tasksVisible && (
        <InspectionModal
          {...{
            visible: tasksVisible,
            onCancel: () => setTasksVisible(false),
            title: `Tasks for ${rowObject?.incidentName}`,
            width: 1600,
            footer: false,
            closable: true,
            closeIcon: <XIcon fill="#333238" />,
            className: "header-tasks-modal-light",
          }}
        >
          <div style={{ height: "65dvh" }}>
            <TasksBasePage
              {...{
                customTaskProps: {
                  rowData: rowObject,
                  taskTopic: "Incidents",
                  taskTopicId: rowObject?.incidentId,
                  taskTopicName: rowObject?.incidentName || "Save Draft",
                },
              }}
            />
          </div>
        </InspectionModal>
      )}

      {!!inspections && (
        <InspectionModal
          {...{
            visible: inspections,
            onCancel: () => setInspections(false),
            title: `Inspections for ${rowObject?.incidentName}`,
            width: 1600,
            footer: false,
            closable: true,
            closeIcon: <XIcon fill="#333238" />,
            className: "header-tasks-modal-light",
            transitionName: "linear",
            isDarkMode,
          }}
        >
          <SafetyInspections
            {...{
              projectId: rowObject?.projectId,
              projectAddress:
                rowObject?.incidentObject?.[ADDRESS_FIELD?.[incidentCategory]],
            }}
          />
          {/* <InspectionBasePage
            {...{
              filterKey: "jobsiteAddress",
              filterValue:
                rowObject?.incidentObject?.[ADDRESS_FIELD[incidentCategory]] ||
                "",
              filterProjectId: rowObject?.incidentObject?.projectId || "",
              isTab: true,
            }}
          /> */}
        </InspectionModal>
      )}

      {!!documentationModalFiles && (
        <DocumentationModal
          {...{
            visible: !!documentationModalFiles,
            setVisible: setDocumentationModalFiles,
            viewTitle: rowObject?.incidentName,
            categoryName: "Incidents",
            docType: incidentCategory,
            folderId: driveFolder,
            recordId: rowObject?.incidentId,
            recordValue: rowObject?.incidentName,
            docStatus: rowObject?.incidentStatus,
            docStatuses: statusesColors,
            googleDriveUploads: documentationModalFiles,
            setDocumentation: () => {},
            recordName: rowObject?.incidentName,
            record: rowObject,
            accessToken,
            teamsConfiguration: selectedTeam.map((team) => ({
              teamName: team?.value,
              members: team?.members,
            })),
            isModalOpenedFromFileItem: true,
            onSaveParentModal: onSave,
          }}
        />
      )}
    </Modal>
  );
};

export default NewIncidentModal;
