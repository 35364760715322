import { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import { WarningTriangle } from "../../src";
import "./DriverAssign.scss";
import { AvatarImage } from "../../../../commonComponents";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(advancedFormat);

function DriverAssign(props) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { preferences } = useSelector((state) => state.preferences);

  const [assignedDriver, setAssignedDriver] = useState(null);
  const [assignedUser, setAssignedUser] = useState({});

  const {
    assignedDriver: defaultAssignedDriver,
    driverHistory,
    fleetId,
  } = props.params || "";

  useEffect(() => {
    if (defaultAssignedDriver) {
      API.get("drivers", `/drivers/${defaultAssignedDriver}`)
        .then((res) => {
          setAssignedDriver(res);
          let tmpUser = undefined;
          if (res?.driverSub) {
            tmpUser = userConfiguration?.allUsers?.Items?.find(
              ({ cognitoUserId }) => cognitoUserId === res?.driverSub
            );
          }

          if (!tmpUser) {
            tmpUser = {
              googleDriveFileId: undefined,
              isSuspended: res?.status === "Terminated",
              nameOfUser: res?.driverName,
              groupName: "Driver",
            };
          }

          setAssignedUser(tmpUser);
        })
        .catch((err) => {
          console.log("Error getting assigned driver: ", err);
        });
    }
  }, [defaultAssignedDriver]);

  if (!assignedDriver) {
    return (
      <div
        className={`driver-link-no-driver ${
          isDarkMode ? "driver-link-dark" : ""
        }`}
      >
        <WarningTriangle className="no-driver-warning-icon" />
        <h2>There is no assigned driver for this vehicle!</h2>
        <h3>Edit this card in order to add a driver</h3>
      </div>
    );
  }

  return (
    <Fragment>
      <div className="membersContent-container">
        <div className="content-membersContent">
          <div className="member-content">
            {assignedUser?.googleDriveFileId ? (
              <div className="ImageContainer" onClick={() => {}}>
                <AvatarImage
                  {...{
                    nameOfUser: assignedUser?.nameOfUser,
                    googleDriveFileId: assignedUser?.googleDriveFileId,
                  }}
                />
              </div>
            ) : (
              <div
                className="InitialsContainer"
                style={{
                  backgroundColor: assignedUser?.isSuspended
                    ? "#A9A9A9"
                    : assignedUser?.nameOfUser === userConfiguration.nameOfUser
                    ? preferences?.preferences?.profileColor || "#4895ef"
                    : "#F8F8FA",
                }}
                onClick={() => {}}
              >
                <span
                  className="initialsStyle"
                  style={{
                    color:
                      assignedUser?.nameOfUser === userConfiguration.nameOfUser
                        ? "#fff"
                        : "#323338",
                  }}
                >
                  {assignedUser?.nameOfUser?.charAt(0)}
                </span>
              </div>
            )}
            <div className="memberData">
              <div
                className={`nameContainer ${
                  assignedUser?.isSuspended && "suspendedName"
                }`}
                onClick={() => {}}
              >
                {assignedUser?.nameOfUser}
              </div>
              <div
                className={`groupName ${
                  assignedUser?.isSuspended && "suspendedGroupName"
                }`}
              >
                {assignedUser?.groupName}
              </div>
              <div>
                {Array.isArray(driverHistory) && driverHistory.length > 0 && (
                  <span style={{ color: isDarkMode ? "#acacca" : "" }}>
                    Driver since:{" "}
                    {driverHistory?.at(-1).startDate &&
                      dayjs(driverHistory.at(-1).startDate).format(
                        "MM/DD/YYYY"
                      )}{" "}
                    -{" "}
                    {driverHistory?.at(-1)?.endDate &&
                    driverHistory?.at(-1)?.startDate
                      ? dayjs(driverHistory.at(-1).endDate).format("MM/DD/YYYY")
                      : "Present"}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div />
      </div>
    </Fragment>
  );
}

export default DriverAssign;
