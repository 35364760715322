import { Button, Dropdown, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { TemplatesIcon } from "../../../../../../assets";
import { useEmailTemplates } from "../../../../providers/EmailTemplatesProvider";
import { useSelector } from "react-redux";
import { driveApi } from "../../../../../../../../../integrations/DriveRequest";
import { keys } from "../../../../../../../../pages/Settings/settingsComponents/Roles/RolesData";
import { useDynamicFields } from "../../../../providers/DynamicFieldsProvider";
import { useEmailBox } from "../../../../providers/EmailBoxProvider";
import { useInitialEmailBoxProps } from "../../../../providers/InitialEmailBoxProvider";
import {
  fetchBlobData,
  getDocItems,
  getTemplate,
  getUploadedFiles,
  processTemplateRoles,
} from "../../../../utils/emailbox-helpers";
import "./email-box-templates.scss";
import { useDocumentationTypes } from "../../../../providers/DocumentationTypesProvider";
import { useEmailBoxBody } from "../../../../providers/EmailBoxBodyProvider";
import { filterTables } from "../../../../../../../../../utils";
import CustomDropdown from "../../../custom-dropdown/CustomDropdown";
import { useAttachments } from "../../../../providers/AttachmentsProvider";

function EmailBoxTemplates() {
  // Access Redux state for programFields // Access Redux state for programFields
  const { programFields } = useSelector((state) => state.programFields);

  // Access email templates and selected template
  const { emailTemplates, selectedEmailTemplate, setLoading } =
    useEmailTemplates();

  // Access dynamic fields and email box context
  const { dynamicFields } = useDynamicFields();
  const {
    recordDetails,
    isRedirected,
    rowData,
    selectedColumns,
    docDefinition,
    agGridToEmail,
    selectedDraft,
  } = useInitialEmailBoxProps();
  const { googleDriveFolderIds, setDriveDocuments, form } = useEmailBox();
  const { setAttachments } = useAttachments();
  const { documentationTypes, setDocumentationTypesFiles } =
    useDocumentationTypes();
  const { setEditorContent } = useEmailBoxBody();

  // Access accessToken from Redux state
  const { accessToken } = useSelector((state) => state.accessToken);
  const driveRequest = driveApi({ accessToken });

  // Extract selected columns and row data
  const getDataValue = () => {
    if (selectedColumns) {
      const filteredObject = {};

      selectedColumns.forEach((item) => {
        const key = Object.keys(item)[0];
        const value = item[key];

        // Exclude keys with the value "undefined"
        if (key !== undefined && !!key) {
          // Use the value from selectedColumns as the value in filteredObject
          filteredObject[key] = value || "";
        }
      });
      return filteredObject;
    }

    return rowData?.[0] || rowData;
  };
  const regex = new RegExp("{(.*?)(?=})}", "g");

  // Render the template body and dynamically replace placeholders
  const renderBody = async ({ templateBody: template }) => {
    function extractTrElement(template, tdElement, forAgGridTable = false) {
      const trRegex = /<tr[^>]*>(.*?)<\/tr>/s;
      const match = template.match(trRegex);

      const rowEl = match ? match[0] : null;

      const firstPart = rowEl?.match(/<tr[^>]*>/)[0];
      const secondPart = rowEl?.match(/<\/tr>/)[0];

      let rowTds = "";
      if (tdElement) {
        const rowDataArr = getDataValue();
        // Table Rows
        Object.keys(forAgGridTable ? docDefinition.header : rowDataArr).forEach(
          (key) => {
            if (!key.toLowerCase() !== "id" && key.toLowerCase() !== "notes") {
              const filledRow = tdElement?.replace(/\{(.*?)\}/g, `{${key}}`);
              rowTds += filledRow;
            }
          }
        );
      }
      return `${firstPart} ${rowTds} ${secondPart}`;
    }

    // Function to extract the first td element as a string
    function extractTdElement(template) {
      const tdRegex = /<td[^>]*>(.*?)<\/td>/s;
      const match = template.match(tdRegex);
      return match ? match[0] : null;
    }

    const updateTempBody = () => {
      if (!!agGridToEmail) {
        const tdElement = extractTdElement(template);
        const trElement = extractTrElement(template, tdElement, true);

        let tableHeads = "";
        // Table Header
        Object.keys(docDefinition.header).forEach((key) => {
          const value = docDefinition.header[key];
          if (!key.toLowerCase() !== "id" && key.toLowerCase() !== "notes") {
            tableHeads += `<td><b>${
              value.charAt(0).toUpperCase() + value.slice(1)
            }</b></td>`;
          }
        });

        let duplicatedRows = `<tr>${tableHeads}</tr>`;

        if (trElement) {
          docDefinition.body.forEach((row) => {
            let filledRow = trElement?.replace(
              /\{(.*?)\}/g,
              (match, matched) => {
                const cellValue = row[matched][matched];

                if (cellValue === 0) {
                  return "0";
                } else {
                  return cellValue || "";
                }
              }
            );
            duplicatedRows += filledRow;
          });
        }

        // Extract the first and second parts
        const firstPart = template.slice(0, template.indexOf("<tbody>"));
        const secondPart = template.slice(
          template.indexOf("</tbody>") + "</tbody>".length
        );
        // Insert the duplicated rows into the template
        return `${firstPart} ${duplicatedRows} ${secondPart}`;
      }
      return template?.replace(/\{(.*?)\}/g, function (index, matched) {
        if (matched === "primaryContact") {
          return rowData[matched]?.name;
        }

        if (matched === "inspectedBy") {
          return rowData[matched]?.nameOfUser || "-||-";
        }

        return rowData[matched] || "-||-";
      });
    };

    const templateBody = updateTempBody();

    var newDiv = document.createElement("div");

    newDiv.innerHTML = templateBody;

    let tableDiv = newDiv?.getElementsByTagName("table");
    for await (let table of tableDiv) {
      const { id } = table;
      let tableKeys = Array.from(table.innerHTML.matchAll(regex), (x) => x[1]);
      const relationKey = programFields?.find(
        ({ fieldName }) => fieldName === "Dynamic Fields Subcategories"
      )?.fieldOptions[recordDetails?.categoryType?.toLowerCase()]?.relationKey;

      if (!!id) {
        await filterTables(
          id.toLowerCase(),
          recordDetails?.categoryType?.toLowerCase() === "contacts"
            ? !!rowData?.leadId
              ? "leadId"
              : "accountId"
            : relationKey,
          rowData[
            recordDetails?.categoryType?.toLowerCase() === "contacts"
              ? rowData?.leadId
                ? "leadId"
                : "accountId"
              : relationKey
          ]
        ).then((res) => {
          res.forEach((record) => {
            var newRow = document.createElement("tr");
            newRow.innerHTML = `${tableKeys
              .map(
                (e) =>
                  `<td style="border: 1px solid #000">${
                    !!record[e] ? record[e] : ""
                  }</td>`
              )
              .join("")}`;
            table.getElementsByTagName("tbody")[0].appendChild(newRow);
          });
          table.getElementsByTagName("tr")[1].remove();
        });
      } else {
      }
    }

    const htmlContent = newDiv.innerHTML;
    return `${htmlContent}`;
  };

  async function processDriveFolders(
    driveFolders,
    googleDriveFolderIds,
    driveRequest
  ) {
    const matchingFolderIds = driveFolders?.reduce((acc, folder) => {
      const folderName = folder?.undefined;
      const folderId = googleDriveFolderIds?.[folderName];
      if (folderId) {
        acc.push(folderId);
      }
      return acc;
    }, []);

    const filesPromises = matchingFolderIds.map(async (folderId) => {
      const res = await driveRequest.getFilesByFolderId(folderId);

      return res.json();
    });

    const filesByFolderId = await Promise.all(filesPromises);

    const finalFiles = await Promise.all(
      filesByFolderId.map(
        async (filesArray) =>
          await fetchBlobData(filesArray?.files, driveRequest)
      )
    );

    return finalFiles.flat();
  }

  const resetStates = () => {
    setEditorContent("");
    setDriveDocuments([]);
    setDocumentationTypesFiles([]);
    setLoading(false);
  };

  const processDriveItems = async (selectedTemplate) => {
    const { docTypes } = selectedTemplate;

    if (docTypes) {
      const matchedItems = docTypes.map((e) =>
        Array.from(e?.matchAll(regex), (x) => x)
      );

      const recordDocuments = dynamicFields?.filter(
        ({ dynamicFieldCategory, dynamicFieldRenderFor }) =>
          dynamicFieldCategory.toLowerCase() ===
            recordDetails?.categoryType.toLowerCase() &&
          dynamicFieldRenderFor === "documentation"
      );

      const driveFolders = matchedItems.flat(1).map((e) => {
        return {
          [recordDocuments?.find(
            ({ dynamicFieldValue }) => dynamicFieldValue === e[1]
          )?.dynamicFieldLabel]: e[1],
        };
      });

      const uploadedItems = await processDriveFolders(
        driveFolders,
        googleDriveFolderIds,
        driveRequest
      );

      setDriveDocuments((prev) => {
        return [...prev, ...uploadedItems];
      });
    }
  };

  const processDocItems = async (selectedTemplate) => {
    const { predefinedAttachments } = selectedTemplate;
    if (predefinedAttachments && predefinedAttachments.length > 0) {
      const attachmentsToFetch = documentationTypes
        .filter((docType) => predefinedAttachments.includes(docType.docType))
        .flatMap((docType) => docType.googleDriveUploads)
        .map((attachments) => ({
          id: attachments?.id,
          name: attachments?.name,
        }));

      const docItemsFiles = await getDocItems(attachmentsToFetch, driveRequest);
      setDocumentationTypesFiles(docItemsFiles);
    }
  };

  const processPredefinedAttachments = (
    predefinedAttachments,
    documentationTypes
  ) => {
    return documentationTypes
      .filter((obj) => predefinedAttachments.includes(obj.docType))
      .map((obj) => obj.googleDriveUploads.map((upload) => upload.id))
      .flat();
  };

  const processUploadedFiles = async (selectedTemplate) => {
    const { templateAttachments, predefinedAttachments } = selectedTemplate;

    const docTypesPredefinedAttachmentsFilesIds = processPredefinedAttachments(
      predefinedAttachments,
      documentationTypes
    );

    if (
      templateAttachments.length > 0 ||
      docTypesPredefinedAttachmentsFilesIds?.length > 0
    ) {
      let uploadedFiles = await getUploadedFiles(
        [...templateAttachments, ...docTypesPredefinedAttachmentsFilesIds],
        isRedirected,
        driveRequest
      );

      setAttachments((prev) => [...prev, ...uploadedFiles]);
      setLoading(false);
    }
  };

  const processTemplateBody = async (selectedTemplate) => {
    if (selectedTemplate?.customBody) {
      setEditorContent(selectedTemplate.customBody);
    } else if (
      selectedTemplate.templateName === "Tutorial Video" &&
      Array.isArray(rowData)
    ) {
      let htmlContent = "";

      for (let video of rowData) {
        htmlContent += `
        <p>Video Name: ${video.videoName}<br>Category: ${
          video.category
        }<br>SubCategory: ${video.subCategoryName}<br>Video Length: ${
          video.lengthVideoInMin
        } min<br>
        ${
          video.hasOwnProperty("description")
            ? `Description: ${video.description}`
            : ``
        }</p><br>
        <p>
          <a href="https://drive.google.com/uc?export=download&id=${
            video.videoId
          }" target="_blank">
            <img src="https://img.favpng.com/11/10/24/video-ico-icon-png-favpng-s6CbnZ4HHHeBt3phvJuU5rgyC.jpg" style="max-width: 0px; max-height: 0px; border: none;">
          </a>
        </p>
      `;
      }

      setEditorContent(htmlContent);
    } else {
      const formattedTemplateContent = await renderBody(selectedTemplate);
      setEditorContent(formattedTemplateContent);
    }
  };

  const processTemplateSubject = (templateSubject, rowData) => {
    if (
      templateSubject === "Tutorial Video for {videoName}" &&
      Array.isArray(rowData)
    ) {
      let result = "Tutorial Videos for";

      rowData.forEach((item, index) => {
        index === 0
          ? (result += ` ${item.videoName}`)
          : (result += `, ${item.videoName}`);
      });

      return result;
    }

    Array.from(templateSubject?.matchAll(regex), (x) => x).map((e) => {
      templateSubject = templateSubject?.replace(
        e[0],
        e[1] !== "inspectedBy"
          ? !!rowData[e[1]] || rowData[e[1]] === ""
            ? rowData[e[1]]
            : ""
          : !!rowData[e[1]]
          ? rowData[e[1]].nameOfUser
          : ""
      );
      return rowData[e[1]] ? true : false;
    });
    return templateSubject;
  };

  const handleSelectedTemplate = (template) => {
    resetStates();
    let { templateRoles, templateSubject } = template;
    processDriveItems(template);
    processDocItems(template);
    processUploadedFiles(template);
    processTemplateRoles(templateRoles, programFields, rowData, form);
    processTemplateBody(template);
    const newTemplateSubject = processTemplateSubject(templateSubject, rowData);
    form.setFieldsValue({
      // ...templateRoles,
      subject: newTemplateSubject,
    });
  };

  useEffect(() => {
    const template = getTemplate(emailTemplates, selectedEmailTemplate);
    if (template && !selectedDraft) {
      handleSelectedTemplate(template);
    }
  }, [selectedEmailTemplate?.id]);

  const items = emailTemplates?.map((template) => ({
    key: template.templateId,
    label: (
      <Tooltip title={template.templateName} placement="left">
        <Button
          type="link"
          className="dropdown-item-button"
          onClick={() => {
            handleSelectedTemplate(template);
            setAttachments([]);
            if (template.templateAttachments?.length > 0) {
              setLoading(true);
            }
          }}
          data-testid="dropdown-item-label"
        >
          {template.templateName}
        </Button>
      </Tooltip>
    ),
  }));

  return (
    <Tooltip {...{ title: "Templates" }}>
      <CustomDropdown items={items} placement="top" trigger={["click"]}>
        <a
          onClick={(e) => e.preventDefault()}
          data-testid="email-box-dropdown-templates-trigger"
          className="email-box-template-dropdown-anchor"
        >
          <TemplatesIcon className="templates-icon" />
        </a>
      </CustomDropdown>
    </Tooltip>
  );
}

export default EmailBoxTemplates;
