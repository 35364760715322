export function matchEditActivities({ logsByActivityId, planForTruck }) {
  const matchArray = [];

  for (const activityId in logsByActivityId) {
    const logs = logsByActivityId[activityId];
    const planetRoute = planForTruck.find((f) => f.activityId === activityId);

    if (!logs || !logs.length) continue;

    const sortedLogs = [...logs].sort((a, b) => a.updatedAt - b.updatedAt);

    const firstLog = sortedLogs[0];

    matchArray.push({
      activityId: planetRoute?.activityId,
      pickUpLocation: planetRoute?.pickUpLocation,
      dropOffLocation: planetRoute?.dropOffLocation,
      plannedDepart:
        firstLog?.previousData?.startingTime ||
        firstLog?.currentData?.startingTime,
      plannedArrive:
        firstLog.previousData.timeAtLocation ||
        firstLog.currentData.timeAtLocation,
      driverName: planetRoute?.driverName,
      driverId: planetRoute?.driverId,
      fleetName: planetRoute?.fleetName,
      cargo: planetRoute?.cargo,
      cargoWeight: planetRoute?.cargoWeight,
      actualDepart: planetRoute?.startingTime,
      actualArrive: planetRoute?.timeAtLocation,
    });
  }

  return matchArray;
}
