import Swal from "sweetalert2";
import { forceToNumber } from "../../../../../../SidebarPages/Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { checkIfServiceIsHoist } from "../../../../../../SidebarPages/Estimations/DataEntryGrid/models/Service";
import { proposalServiceHTML } from "../../../../../../SidebarPages/Estimations/DataEntryGrid/subcomponents/Header/WordFactory/process/htmlService";
import {
  getElevationExtras,
  getServicesAsHtml,
} from "../../../../../../SidebarPages/Estimations/DataEntryGrid/subcomponents/Header/WordFactory/process/process";
import {
  otherScopeServiceTaxMapper,
  otherScopeServiceTotalPriceMapper,
} from "../../../../../../SidebarPages/Estimations/DataEntryGrid/subcomponents/ServiceHeader/ServicePrices/utils";
import { formatCurrency } from "../../../../../../SidebarPages/utils";
import { hoistElevationsHtml } from "./hoistElevationsHtml";
import { isEmpty } from "lodash";
import scopeServices from "./scopeServices";

// dataMapper.js
export function mapTagToData(temp) {
  let toReturn = "";
  let htmlTemplate = temp?.html || "";
  let processedSrc = getProcessedSrc(temp);
  let unmatchedTags = [];
  if (htmlTemplate) {
    var regex = /{{(.*?)}}/g;
    // const matchedItems = Array.from(htmlTemplate?.matchAll(regex), (x) => x);
    let str = htmlTemplate?.replace(/\{\{(.*?)\}\}/g, function (i, match) {
      if (match.includes("&ccedil;")) {
        match = match.replace("&ccedil;", "ç");
        if (!!processedSrc?.[match]) {
          return wrapToTag(processedSrc?.[match] || "");
        } else {
          unmatchedTags.push(match);
          return "";
        }
      } else {
        if (!!processedSrc?.[match]) {
          return wrapToTag(processedSrc?.[match] || "");
        } else {
          unmatchedTags.push(match);
          return "";
        }
      }
    });
    // if (unmatchedTags?.length > 0) {
    //   Swal.fire({
    //     title: "Unmatched Tags - Please check the following tags in Take off!",
    //     html: `<div style="text-align: left;">${unmatchedTags
    //       .map((item) => `<p>${item}</p>`)
    //       .join("")}</div>`,
    //     icon: "warning",
    //     confirmButtonText: "Ok",
    //   });
    // }
    return str;
  }
}

const wrapToTag = (tag = "") => {
  // style="user-select:none;"
  return `<span contenteditable="false">${tag}</span>`;
};

export function mapServiceTotalAmount(serviceTotalAmount, tagData) {
  // Your custom logic to map serviceTotalAmount data
  return `Service Total Amount: ${serviceTotalAmount[tagData]}`;
}

export function mapAnotherDataType(anotherDataType, tagData) {
  // Your custom logic to map anotherDataType
  return `Another Data Type: ${anotherDataType[tagData]}`;
}

const getProcessedSrc = (src) => {
  let ALL_SOURCED = {};
  let tempEstimation = src?.proposalData?.estimation;
  let tempTakeOff = src?.proposalData?.takeOff;
  if (!!tempEstimation) {
    getEstimationFields(tempEstimation, ALL_SOURCED);
  }
  if (!!tempTakeOff) {
    getTakeOffFields(tempTakeOff, ALL_SOURCED, src?.styles);
  }
  console.log("ALL_SOURCED", ALL_SOURCED);
  return {
    ...(ALL_SOURCED || {}),
    // "serviceTaxAmount_System Scaffold": "$0",
    // "engineersWork_System Scaffold": `<p style="padding-left: 40px;"><span style="font-size: 14pt; background-color: #0000ff; color: #fff;">Scaffold</span></p>
    // <p style="padding-left: 80px;"><strong><u>-&nbsp; Along Queens Boulevard &ndash; North&nbsp;</u></strong></p>
    // <p style="padding-left: 120px;">11&prime;L x 77&prime;H x 8&prime;W from shed to roof (9th floor level)</p>
    // <p style="padding-left: 120px;">20&prime;L x 77&prime;H x 8&prime;W from shed to roof (9th floor level)</p>
    // <p style="padding-left: 120px;">26&prime;L x 90&prime;H x 8&prime;W from ground to roof (9th floor level)</p>
    // <p style="padding-left: 120px;">39&prime;L x 77&prime;H x 8&prime;W from ground to 8th floor setback (including 8&rsquo; corner)</p>
    // <p style="padding-left: 120px;">39&prime;L x 77&prime;H x 8&prime;W from ground to 8th floor setback (including 8&rsquo; corner) <span style="color: red;">(NOTE: Scaffold frames to be leveled over shed.)</span></p>
    // <p style="padding-left: 120px;">17&prime;L x 19&prime;H x 8&prime;W from 8th floor setback to roof (9th floor level)</p>
    // <p style="padding-left: 120px;">14&prime;L x 19&prime;H x 8&prime;W from 8th floor setback to roof (9th floor level) (6&rsquo;W space)</p>
    // <p style="padding-left: 120px;">31&prime;L x 19&prime;H x 8&prime;W</p>`,
    // "serviceRentalPaymentTerms_System Scaffold": `RENTAL:

    // *NO CREDIT FOR UNUSED RENT*

    // Rental begins twenty-four (24) weeks after initial installation of progress work completed. Rental charge as follows:  $5,580.00 per each 4 Week period ($1,395.00 Weekly) plus applicable sales tax. Billing to be done on a 4-week period. Pro-Rated weekly per progress installation and/or progress removal.`,
    // "servicePriceNoTax_System Scaffold": "$10,000",
    // "serviceTotalAmount_System  Scaffold": "$10,000",
  };
};

const getEstimationFields = (estimation = {}, toSet = {}) => {
  let temp = {
    estimationNumber: estimation?.estimationNumber || "",
    accountManager: estimation?.accountManager || "",
    jobSiteAddress: estimation?.jobSiteAddress || "",
    estimationType:
      estimation?.isChangeOrder === false ? "Estimation" : "Change Order",
    accountName: estimation?.accountName || "",
    borough: estimation?.borough || "",
    estSTATUS: estimation?.estSTATUS || "",
    estimationDescription: estimation?.estimationDescription || "",
    projectExecutive: estimation?.projectExecutive || "",
  };
  return Object.assign(toSet, { ...(toSet || {}), ...temp });
};

const getTakeOffFields = (takeOff = {}, toSet = {}, styles) => {
  let temp = {
    takeOffStatus: takeOff?.status || "",
    versionName: takeOff?.versionName || "",
  };
  /// before we proceed any further, we have to get all the data from services.
  /// We need to consider the fact that we have 3 categories of services to consider
  /// Other Scope has different structure than the other two
  /// Hoist will need to be handled differently too

  if (takeOff?.services && takeOff?.services?.length > 0) {
    for (let service of takeOff?.services) {
      if (service?.isHoist) {
        getHoistField(service, toSet, styles);
      } else if (service?.isScope) {
        scopeServices(service, toSet, styles);
      } else {
        getLegacyServiceFields(service, toSet, styles);
      }
    }
  }

  return Object.assign(toSet, { ...(toSet || {}), ...temp });
};

const getLegacyServiceFields = (service = {}, toSet = {}, styles) => {
  let tempHtml = [];
  let legacyFields = {};
  legacyFields = getDefaultLegacyFields(service, styles);
  return Object.assign(toSet, { ...(toSet || {}), ...legacyFields });
};

const getHoistField = (service = {}, toSet = {}, styles) => {
  let hoistFields = {};
  hoistFields = getDefaultHoistFields(service, styles);
  return Object.assign(toSet, { ...(toSet || {}), ...hoistFields });
};

const getDefaultHoistFields = (service = {}, styles) => {
  const { serviceOptions, label } = service;

  let tempHtml = [];
  let elev = [];
  const styleToUse = styles?.[label];
  serviceOptions?.forEach((option) => {
    if (Array.isArray(option)) {
      option?.forEach((elevation, indx) => {
        elev.push(hoistElevationsHtml(elevation, indx, styleToUse));
      });
    }
  });
  tempHtml.push(elev.join(""));
  // style="user-select:none;"
  tempHtml[0] = `<span contenteditable="false" >${tempHtml[0]}</div>`;
  let {
    servicePriceWithTax = 0,
    servicePriceNoTax = 0,
    serviceTaxAmount = 0,
    serviceTaxRate = 0,
    serviceRentAmount = 0,
    serviceAddonsPrice = 0,
    serviceRentTaxAmount = 0,
    serviceRentTotalAmount = 0,
    serviceAddonsTaxAmount = 0,
    serviceAddonsTotalAmount = 0,
    serviceAdditionalRentalAmount = 0,
    serviceAdditionalRentalTaxAmount = 0,
    serviceAdditionalRentalTotalAmount = 0,
  } = calculateServicePrice(service);

  let toReturn = {
    [`engineersWork_${label}`]: tempHtml?.join("") || "",
    [`serviceIncludes_${label}`]: getIncludes(service),
    [`serviceExcludes_${label}`]: getExcludes(service),
    [`serviceName_${label}`]: label || "",
    [`serviceRentalPaymentTerms_${label}`]: getRentalPaymentTerms(service),
    [`servicePriceNoTax_${label}`]: formatCurrency(servicePriceNoTax),
    [`serviceTaxAmount_${label}`]: formatCurrency(serviceTaxAmount),
    [`serviceTotalAmount_${label}`]: formatCurrency(servicePriceWithTax),

    //TODO:
    [`hoistType_${label}`]: "",
    [`hoistModel_${label}`]: "",
    [`hoistLength_${label}`]: "",
    [`hoistLocation_${label}`]: "",
    [`hoistSpeed_${label}`]: "",
    [`hoistCapacity_${label}`]: "",
    [`interiorDimensions_${label}`]: "",
    [`hoistFrom-to_${label}`]: "",
    [`hoistPrice_${label}`]: "",
    [`hoistTax_${label}`]: "",
    [`hoistTotalPrice_${label}`]: "",
    [`hoistRentalTerms_${label}`]: "",
  };
  return toReturn;
};

const getDefaultLegacyFields = (service = {}, styles) => {
  let tempHtml = [];
  proposalServiceHTML([service], [], tempHtml, styles);
  // style="user-select:none;"
  tempHtml[0] = `<span contenteditable="false" >${tempHtml[0]}</div>`;
  let {
    servicePriceWithTax = 0,
    servicePriceNoTax = 0,
    serviceTaxAmount = 0,
    serviceTaxRate = 0,
    serviceRentAmount = 0,
    serviceAddonsPrice = 0,
    serviceRentTaxAmount = 0,
    serviceRentTotalAmount = 0,
    serviceAddonsTaxAmount = 0,
    serviceAddonsTotalAmount = 0,
    serviceAdditionalRentalAmount = 0,
    serviceAdditionalRentalTaxAmount = 0,
    serviceAdditionalRentalTotalAmount = 0,
  } = calculateServicePrice(service);

  let toReturn = {
    [`engineersWork_${service?.label}`]: tempHtml?.join("") || "",
    [`serviceIncludes_${service?.label}`]: getIncludes(service),
    [`serviceExcludes_${service?.label}`]: getExcludes(service),
    [`serviceName_${service?.label}`]: service?.label || "",
    [`serviceRentalPaymentTerms_${service?.label}`]:
      getRentalPaymentTerms(service),
    [`servicePriceNoTax_${service?.label}`]: formatCurrency(servicePriceNoTax),
    [`serviceTaxAmount_${service?.label}`]: formatCurrency(serviceTaxAmount),
    [`serviceTotalAmount_${service?.label}`]:
      formatCurrency(servicePriceWithTax),
  };
  return toReturn;
};

export const getRentalPaymentTerms = (service = {}) => {
  let toReturn = "";
  if (!!service?.rentalPaymentTerms?.name) {
    let toReturn = `<span>${service?.rentalPaymentTerms?.name}</span>`;
    if (!!service?.rentalPaymentTerms?.description) {
      toReturn += `<p>${service?.rentalPaymentTerms?.description}</p>`;
    }
    return toReturn;
  }
  return toReturn;
};

const getIncludes = (service = {}) => {
  if (isEmpty(service?.includes)) return "";
  let temp = `<ul style="padding-left: 40px;"><span>INCLUDES:</span>`;
  if (Array.isArray(service?.includes)) {
    service?.includes?.forEach((item) => {
      temp += `<li>${item?.name}</li>`;
    });
  }
  return temp + "</ul>";
};

const getExcludes = (service = {}) => {
  if (isEmpty(service?.excludes)) return "";
  let temp = `<ul style="padding-left: 40px;"><span>EXCLUDES:</span>`;
  if (Array.isArray(service?.excludes)) {
    service?.excludes?.forEach((item) => {
      temp += `<li>${item?.name}</li>`;
    });
  }
  return temp + "</ul>";
};
const totalPriceMapper = (el) =>
  !isNaN(+el?.totalPrice) ? +el?.totalPrice : 0;
const totalTaxMapper = (el) => (!isNaN(+el?.taxAmount) ? +el?.taxAmount : 0);
const hoistTotalPriceMapper = ({ totalPrice }) => totalPrice;
export const calculateServicePrice = (service = {}) => {
  let priceUnified = 0;
  let others = 0;
  let taxes = 0;
  let serviceAddonsTaxes = 0;
  let serviceAddons = service?.serviceAddons?.reduce((a, b) => {
    return a + forceToNumber(b?.totalPrice);
  }, 0);
  service?.serviceAddons.forEach((el) => {
    taxes += forceToNumber(el?.includedTax?.taxAmount);
    serviceAddonsTaxes += forceToNumber(el?.includedTax?.taxAmount);
  });
  let additionalRentals = service?.additionalRentalTerms?.newPrice || 0;
  let additionalRentalsTax = service?.additionalRentalTerms?.taxAmount || 0;
  if (service?.isScope === true) {
    priceUnified += otherScopeServiceTotalPriceMapper(service);
    taxes += otherScopeServiceTaxMapper(service);
  } else if (!checkIfServiceIsHoist(service)) {
    priceUnified += [
      ...service?.serviceOptions?.[0]?.flatMap?.(
        ({ items }) =>
          items
            ?.filter?.(({ approved }) => !!approved)
            ?.map?.(totalPriceMapper) || [0]
      ),
      // ...(service?.serviceAddons?.map?.(totalPriceMapper) || [0]),
    ]
      ?.filter(Boolean)
      ?.reduce((acc, curr) => (acc += curr), 0);
    taxes += [
      ...service?.serviceOptions?.[0]?.flatMap?.(
        ({ items }) =>
          items
            ?.filter?.(({ approved }) => !!approved)
            ?.map?.(totalTaxMapper) || [0]
      ),
      ...(service?.serviceAddons?.map?.(totalTaxMapper) || [0]),
    ]
      ?.filter(Boolean)
      ?.reduce((acc, curr) => (acc += curr), 0);
  } else if (checkIfServiceIsHoist(service)) {
    priceUnified += [
      ...service?.serviceOptions?.[0]?.map(hoistTotalPriceMapper),
      // ...service?.serviceAddons.map(totalPriceMapper),
    ]
      ?.filter(Boolean)
      ?.reduce((acc, curr) => (acc += curr), 0);
    taxes += [
      ...service?.serviceOptions?.[0]?.map((el) => el?.taxAmount),
      ...service?.serviceAddons.map(totalTaxMapper),
    ]
      ?.filter(Boolean)
      ?.reduce((acc, curr) => (acc += curr), 0);
  }
  let totalPriceUnified =
    (priceUnified + others + additionalRentals + serviceAddons).toFixed(2) ?? 0;
  return {
    servicePriceWithTax: totalPriceUnified,
    servicePriceNoTax: totalPriceUnified - taxes,
    serviceTaxAmount: taxes,
    serviceTaxRate: service?.taxRate || 0,
    serviceRentAmount: 0, //TODO:
    serviceRentTaxAmount: 0, //TODO:
    serviceRentTotalAmount: 0, //TODO:
    serviceAdditionalRentalAmount: additionalRentals - additionalRentalsTax,
    serviceAdditionalRentalTaxAmount: additionalRentalsTax,
    serviceAdditionalRentalTotalAmount: additionalRentals,
    serviceAddonsPrice: serviceAddons - serviceAddonsTaxes,
    serviceAddonsTaxAmount: serviceAddonsTaxes,
    serviceAddonsTotalAmount: serviceAddons,
  };
};
