import React, {
  useMemo,
  useState,
  useEffect,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import { useJsApiLoader } from "@react-google-maps/api";
import dayjs from "dayjs";
import { v4 as uuid } from "uuid";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Modal, Form, message, Tooltip, Button, Tour } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import {
  removeRoute,
  driverSelect,
  getSuggestion,
  switchDrivers,
  populateFields,
  nullifyAllTimes,
  addRouteOnIndex,
  getNewLabelHTML,
  getDriverRoutes,
  changeCargoStates,
  validateOverlapping,
  populateRequestObject,
  handleExternalNewRoutes,
  selectNewSwitchLocation,
  validateTruckAvailability,
} from "./utils/routeFunctions";
import {
  saveDispatch,
  getTourSteps,
  deleteDispatch,
  getAccessRights,
  findOverlappingSchedules,
  getTeamsSelectionsFromItems,
  differentiateUsersByDepartments,
} from "./utils/dispatchFunctions";
import {
  dateFormat,
  GhostRoute,
  timeFormat,
  routeFields,
  getLabelHTML,
  footerButtons,
  filterRequests,
  dropOffBaseHTML,
  driverShowField,
  varianceField,
  flashAnimationFrames,
  flashAnimationOptions,
  simulateClosingFrames,
} from "./utils/routeFields";
import {
  getSameCargoWarning,
  getTimeErrorForRequest,
  getStatusWarningMessage,
  getMandatoryNotification,
  getDriverErrorNotification,
  getNextDayErrorNotification,
  getNextDayWarningNotification,
  getTruckOverlappingNotification,
  getRouteCancelOnSwitchNotification,
} from "./utils/userNotifications";
import {
  parseInTz,
  changeDate,
  setDateMonth,
  changeRouteDates,
  setHourMinute,
} from "./utils/dateFunctions";
import { headerFields } from "./utils/headerFields";
import { generateDispatchPdf } from "./utils/dispatchPdfData";
import { fetchData, validateForm } from "../../../utils";
import {
  lazyFetch,
  filterTables,
  openInNewTab,
  fetchAllData,
  updateDocumentTitle,
} from "../../../../../../utils";
import {
  useSocket,
  useEditLogs,
  useProgressComponent,
} from "../../../../../../hooks";
import { formatNumber, onFilter } from "../../../../utils";
import { filterPeopleList } from "../../../../utils/filterPeopleList";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import {
  RouteShower,
  DispatchesTable,
  DispatchMapsCard,
  PopoverSuggestion,
  DispatchItineraryModal,
  RouteListingForSwitchModal,
  SpecificScheduleSelectModal,
} from "./components";
import { NewFleetModal } from "../../../fleet/modals";
import { NewDriverModal } from "../../../driver/modals";
import { wsDispatches, wsFormRequests } from "../../../../../../AppData";
import CustomModalHeader, {
  PlayVideoTutorial,
} from "../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import {
  AvatarImages,
  MondayButton,
  MultiLevelTreeLogs,
  ProgressComponent,
} from "../../../../../commonComponents";
import { Modal as VendorModal } from "../../../../../pages/Settings/settingsComponents/Vendors/Modal";
import { VehicleLocationWidget } from "../../../components";
import WarningModal from "../../../../../commonComponents/WarningModal/WarningModal";
import DynamicTeamModal from "../../../../../Header/forms/DynamicTeamModal/DynamicTeamModal";
import { ReportCheck } from "../../../fleetsLive/components/LiveReportsView/components/ReportViewController/components";
import DispatchScheduleDayModal from "../../../../../Header/forms/Scheduling/Subcomponents/ScheduleDayViews/DispatchScheduleDayModal";
import { LiveIcon } from "../../../../DynamicView/src";
import { XIcon } from "../../../../Communication/assets";
import { WarningTriangle } from "../../../../DynamicView/src";
import { ManageAlternatesIcon } from "../../../../../../assets";
import { AddIcon } from "../../../../../Header/components/GoogleEvents/assets";
import { RenderDynamicComponents } from "../../../../../Header/forms/Components";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";

import "./NewDispatchModal.scss";
import "./NewDispatch.scss";

dayjs.extend(customParseFormat);

const VENDOR_FIELD_ID = "43dgdfgd-5645--46545nvbnvb";

/** @typedef {{lat: number, lng: number}} LatLng */

/** @typedef {"Yard"|"Schedule"|"Off Project"|"Off Schedule"|"Vendor"} DestinationType */

/** @typedef {import("../../../../FleetMaintenanceView/types").VendorType} VendorType */

/** @typedef {import("dayjs").Dayjs} Dayjs */

/**
 * @typedef ItineraryType
 * @property {string} pickUpLocation
 * @property {string} dropOffLocation
 * @property {Dayjs|null} departAt
 * @property {Dayjs|null} arriveBy
 * @property {number|null} routeLength
 * @property {number|null} duration
 * @property {number} index
 * @property {string} formattedDistance
 * @property {string} formattedDuration
 * @property {google.maps.TravelMode} travelMode
 * @property {DestinationType} destinationType
 * @property {string|null} destinationId
 * @property {string|null} destinationName
 * @property {google.maps.DirectionsWaypoint[]} waypoints
 * @property {string|null} overview_polyline
 * @property {LatLng|null} pickUpCoordinates
 * @property {LatLng|null} dropOffCoordinates
 */

//#region COMPONENT
/**
 * Default Data is provided when creating a dispatch
 * directly from a schedule
 * defaultData = {
 *  fleetId,
 *  driverId,
 *  schedule: {
 *    scheduleAddress,
 *    scheduleId,
 *    typeOfWork,
 *    scheduleDays: [{startDate}]
 *  },
 * }
 *
 * The onDispatchReady function executes when all the
 * data has finished fetching. This is useful when passing
 * a reference to the dispatch form
 */
const NewDispatchModal = React.forwardRef(
  (
    {
      visible,
      setVisible,
      refreshTable = () => {},
      rowObject: obj = {},
      defaultData,
      requestForm = {},
      onDispatchReady = () => {},
      schedules: proppedSchedules,
      dispatches: proppedDispatches,
    },
    ref
  ) => {
    const { base64 } = useSelector((state) => state.base64);
    const { isDarkMode } = useSelector((state) => state.darkMode);
    const { programFields } = useSelector((state) => state.programFields);
    const { userConfiguration } = useSelector((state) => state.userConfig);
    const { hotCredentials } = useSelector((state) => state.hotCredentials);
    const { yards: fieldYards } = useSelector((state) => state.yards);
    const { authenticatedUser } = useSelector(
      (state) => state.authenticatedUser
    );

    //#region STATES
    const [accessRight] = useState(
      userConfiguration?.routeConfig
        ?.find(({ title }) => title === "Fleets Dispatching")
        ?.children?.find(({ title }) => title === "Overview")
    );
    const [users, setUsers] = useState([]);
    const [rowObject, setRowObject] = useState(obj);
    //holds the number of routes WITHOUT COUNTING THE RETURN ROUTE
    const [routeLength, setRouteLength] = useState(1);
    const [trucks, setTrucks] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [newVendorOpen, setNewVendorOpen] = useState(false);
    const [allSchedules, setAllSchedules] = useState(proppedSchedules || []);
    const [allDispatches, setAllDispatches] = useState([]);
    const [allTeams, setAllTeams] = useState([]);
    const [vendorFences, setVendorFences] = useState([]);
    //allProjects are the data as it comes from the database
    const [allProjects, setAllProjects] = useState([]);
    const [gridApi, setGridApi] = useState();
    const [tableHide, setTableHide] = useState(false);
    //states used to globally filter the list of trucks and drivers
    //useful when creating complementary routes
    const [filterTrucks, setFilterTrucks] = useState();
    const [filterDrivers, setFilterDrivers] = useState();
    //when changed simply updates the route cards
    const [fieldChanged, setFieldChanged] = useState(false);
    const [schedulesForDate, setSchedulesForDate] = useState([]);
    //this holds a list of labels that change depending on the user selection in the dropdown
    const [listOfLabels, setListOfLabels] = useState([dropOffBaseHTML]);
    const [listOfDistances, setListOfDistances] = useState([]);
    const [returnDistance, setReturnDistance] = useState(0);
    const [varianceRoutes, setVarianceRoutes] = useState([]);
    const [varianceReason, setVarianceReason] = useState("");
    const [varianceNotes, setVarianceNote] = useState("");

    const [scheduleChangesModal, setScheduleChangesModal] = useState(false);
    const [rowScheduleChanges, setRowScheduleChanges] = useState({});
    //state that keeps track of the indexes created for hoist
    const [hoistRoutes, setHoistRoutes] = useState([]);
    //state tracks if there are multiple schedules to the same address for the date
    //{ [scheduleName]: scheduleId[] }
    const [overlappingSchedules, setOverlappingSchedules] = useState({});
    const [dispatchReady, setDispatchReady] = useState(false);
    const [readyState, setReadyState] = useState({
      fleets: false,
      drivers: false,
      dispatches: false,
      schedules: false,
      projects: false,
      requests: false,
      teams: false,
    });
    const [stepperParams, setStepperParams] = useState({
      currentStep: 0,
      steps: [],
      statusesColors: [],
    });
    const [activityStatuses, setActivityStatuses] = useState([]);
    /**
     * holds a single index of a route whose dropOff location
     * should stay enabled during a forced route cancel
     */
    const [dropOffSelectAfterForceCancel, setDropOffSelectAfterForceCancel] =
      useState(-1);
    const [dropOffAutoFocus, setDropOffAutoFocus] = useState(false);
    /**
     * Holds the index of the route that start inverse calculations.
     * This is used when a request has an earlier time than the other
     * routes and we absolutely need to make the time
     */
    const [reverseCalcStart, setReverseCalcStart] = useState(-1);
    const [logs, setLogs] = useState([]);
    const [logsVisible, setLogsVisible] = useState(false);
    const [prevDate, setPrevDate] = useState(
      defaultData && defaultData?.schedule?.scheduleDays?.[0]?.startDate
        ? dayjsNY(defaultData?.schedule?.scheduleDays?.[0]?.startDate).startOf(
            "day"
          )
        : dayjsNY(obj?.dispatchDate)
    );
    /**
     * State that holds the dispatch request routes,.
     * These can only be selected under certain conditions
     */
    const [requests, setRequests] = useState([]);
    const [eligibleRequests, setEligibleRequests] = useState([]);
    /**
     * State to keep track of all the routes with selections from approvals
     * [{index, address, requestId}]
     */
    const [requestSelections, setRequestSelections] = useState([]);
    /**
     * open the listing modal in order to view request routes
     * {index: number, needsNew: Boolean} | null
     */
    const [openListingForRequests, setOpenListingForRequests] = useState(null);
    /**
     * Holds the times set on a route the moment the user clicks one of the time pickers.
     * Used to keep the correct dates in the time pickers, specially when dealing with nex-day routes
     * {index: number | null, depart: dayjs | null, arrive: dayjs | null, exit: dayjs | null}
     */
    const [prevTime, setPrevTime] = useState(null);
    /**
     * Opens a warning modal, the message and the handlers
     * change depending on the string value
     * "close" | "delete" | "cancelRoute#<index>" | "addRequest#<index>" | ""
     */
    const [cancelModalVisible, setCancelModalVisible] = useState("");
    //holds the index of a new created route, used to flash the new created route to the user
    const [newCreatedRouteIndex, setNewCreatedRouteIndex] = useState(null);
    const [mapsVisible, setMapsVisible] = useState(null);
    const [newDriver, setNewDriver] = useState(false);
    const [newTruck, setNewTruck] = useState(false);
    const [defaultScheduleSelected, setDefaultScheduleSelected] =
      useState(false);
    const [tourOpen, setTourOpen] = useState(false);
    const [showVideoTutorial, setShowVideoTutorial] = useState(false);
    const [videoTutorialLink, setVideoTutorialLink] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState([]);
    const [teamsModalVisible, setTeamsModalVisible] = useState(false);
    const [headerDisabled, setHeaderDisabled] = useState({
      date: false,
      vehicle: false,
    });
    /**
     * Holds the parameters of the schedule selection at the point of execution.
     * Becomes useful when we have more than one schedule to the same address for the date
     */
    const [scheduleSelectParams, setScheduleSelectParams] = useState();
    /**
     * State used when selecting data from a request.
     * It triggers the route selection function when all the states are set
     */
    const [waitForIndex, setWaitForIndex] = useState({
      value: "",
      option: {},
      index: -1,
    });
    /**
     * In edit mode, holds a parsed list of original routes, useful for route cancellation validation
     * [{cancelIndex: number, cancelled: boolean, defaultPassenger: boolean, id: activityId, label: JSX}]
     */
    const [cancelledRoutes, setCancelledRoutes] = useState([]);
    //this will be an object that holds the driver id and the parsed routes for the driver
    const [routeShower, setRouteShower] = useState(null);
    //holds the index of the route that accepted the next day notification
    //prevents the "Next Day" notification from showing unnecessarily
    const [acceptNextDay, setAcceptNextDay] = useState(false);
    /**
     * State that keeps lists of users that need notifying
     * when creating routes that are not projects/schedules
     */
    const [usersByDepartment] = useState(
      differentiateUsersByDepartments(userConfiguration?.allUsers?.Items || [])
    );
    /**
     * State that keeps a formatted list of users based on schedule
     * address. All the users here are part of the scheduling team,
     * they need to be notified when we create a route that is not schedule
     */
    const [teamsBySchedule, setTeamsBySchedule] = useState({});
    /**
     * State used to hold a list of external dispatches whose times should be changed automatically
     * This will get populated during a driver switch and the data makes a request only when the
     * save button is clicked. It holds the ids of the dispatches that should be updated and the
     * bodies of the corresponding activities that should be updated.
     * [{dispatchId, activities: [{activityId, startingTime, timeAtLocation, timeExitingTheLocation}]}]
     */
    const [affectedDispatches, setAffectedDispatches] = useState([]);
    /**
     * State used to keep track of dispatches that need to be created in a mandatory way.
     * When saving a modal, "Save & New" will get simulated, but with a new
     * row object similar to opening a regular dispatch.
     * [{location, arrive, startDriver, returnDriver, scheduleId?, projectId?}]
     */
    const [complementaryRoutes, setComplementaryRoutes] = useState([]);
    /**
     * State that keeps track of route switches.
     * Every element here opens a new modal with the switched dispatch.
     * The modal checks for the first element, this means that the moment
     * a suggestion gets set on a route, it should remove the object with it's index from routeChangeInfo.
     * When all the suggestions are set, it needs to remove the whole object from the array
     * routeSwitched = [{dispatchId, routeChangeInfo: [{index, depart}]}]
     */
    const [routeSwitched, setRouteSwitched] = useState([]);
    /**
     * state that takes the possible driver id to switch, the route index, and optional
     * properties: depart (when set, updates the departure time in the opened dispatch)
     * allRoutes (when set, opens the RouteListingForSwitchModal with all the routes for the driver)
     * selectedRoute (used to automatically make a driver switch when the user wants to add new routes
     * in external dispatches)
     * switchDriver = {driverToSwitch, routeIndex, selector, depart?, allRoutes?, selectedRoute?} | null
     */
    const [switchDriver, setSwitchDriver] = useState(null);
    /**
     * this is a state used to get the previous value of the driver on the dropdown
     * becomes useful on driver switch. It gets set when the user clicks on the dropdown,
     * it reads the value and updates the state
     */
    const [prevDriver, setPrevDriver] = useState(null);
    //used to disabled the fields of routes up to a certain index
    const [editBlock, setEditBlock] = useState(-1);
    /**
     * "trafficDepart#<index of the card>" | "trafficArrive#<index of the card>" | null
     * Used to open the right popover on time suggestion
     * the idea is to have a simple way of differentiating between indexes and popover placements
     * the changes in "popover" states triggers function calls and handlers
     */
    const [routePopover, setRoutePopover] = useState("");
    /**
     * "trafficDepart" | "trafficArrive" | null
     * Used to open the right popover on time suggestion
     */
    const [returnPopover, setReturnPopover] = useState("");
    /**
     * arrive: true | false (if the suggestion is of the arrive or depart time)
     * index: the index of the card that needs updating
     * time: the time got from the distance service (used to actually open the popover component)
     */
    const [routeTime, setRouteTime] = useState({
      arrive: true,
      index: 0,
      time: "",
    });
    const [returnTime, setReturnTime] = useState({
      arrive: true,
      time: "",
    });
    const [itineraryData, setItineraryData] = useState(
      /** @type {Record<number, ItineraryType[]>} */ ([])
    );
    const [openItinerary, setOpenItinerary] = useState(
      /** @type {number|null} */ (null)
    );
    const [garageAddresses, setGarageAddresses] = useState(
      /** @type {Record<string, {address: string|null, position: LatLng|null}>} */ ({})
    );

    const [paperworkPopoverVisible, setPaperworkPopoverVisible] =
      useState(false);
    const [activePopoverIndex, setActivePopoverIndex] = useState(null);

    const handlePaperworkUpdate = (index) => {
      setFieldChanged((prev) => !prev);
      setPaperworkPopoverVisible(false);
      setActivePopoverIndex(null);
    };

    const handlePaperworkCancel = ({ index, paperworkInitValue }) => {
      form.setFieldValue(`PaperworkType#${index}`, paperworkInitValue);
      setPaperworkPopoverVisible(false);
      setActivePopoverIndex(null);
    };

    const handelPaperworkPopoverVisible = (index) => {
      setActivePopoverIndex(index);
      setPaperworkPopoverVisible(true);
    };

    //#region HOOKS
    const [form] = Form.useForm();

    const googleMapsApiKey = useMemo(() => {
      return (hotCredentials ?? []).find(
        (e) => e.credentialKey === "GOOGLE MAPS KEY"
      )?.credentialValue;
    }, [hotCredentials]);

    const { isLoaded } = useJsApiLoader({
      id: "google-map-script",
      googleMapsApiKey,
    });

    const yards = useMemo(() => {
      return fieldYards.map(({ address }) => address);
    }, [fieldYards]);

    const { socket: dispatchSocket } = useSocket({
      path: wsDispatches.online,
      onopen(ws) {
        ws.send(
          JSON.stringify({
            request: "create-session",
            body: { env: process.env.NODE_ENV },
          })
        );
      },
    });

    const { socket: requestFormWs } = useSocket({
      path: wsFormRequests.online,
    });

    const {
      visibleCreationProgress,
      setVisibleCreationProgress,
      creationProgresses,
      updateProgressStatus,
    } = useProgressComponent({
      categoryName: "Fleet Dispatch",
      actionType: !!obj?.dispatchId ? "Update" : "Create",
    });
    const { saveAddedLogs } = useEditLogs();

    //#region CONST CONDITIONS
    const { requestObject = null } = requestForm;

    let cancelDisabledCondition =
      JSON.stringify(rowObject) !== JSON.stringify(obj);

    let saveCondition =
      !!complementaryRoutes?.length ||
      routeSwitched?.filter((el) => el?.dispatchId !== rowObject?.dispatchId)
        ?.length > 0;

    let openedDispatchForSwitchCondition =
      cancelDisabledCondition && !!rowObject?.dispatchId;

    function onCancel(fromSave = false) {
      //#region ON CANCEL
      if (requestForm?.requestId && !fromSave) {
        API.patch(
          "formRequests",
          `/${"formRequests"}/${requestForm?.requestId}`,
          {
            body: {
              responseFrom: "no response",
            },
          }
        ).then(() => {
          try {
            requestFormWs.send(
              JSON.stringify({
                request: "request-response",
                body: {
                  requestId: requestForm?.requestId,
                  responseFrom: "no response",
                },
              })
            );
          } catch (err) {
            console.log("Could not send socket message: ", err);
          }
        });
      }
      updateDocumentTitle();
      setVisible(false);
      setCancelModalVisible("");
    }

    useEffect(() => {
      //#region FETCH DATA EFFECT
      Promise.allSettled([
        lazyFetch({
          tableName: "fleet",
          listOfKeys: [
            "fleetId",
            "fleetName",
            "fleetStatus",
            "deviceSerialNumber",
            "teamsConfiguration",
            "inactivityTimeline",
          ],
        }),
        lazyFetch({
          tableName: "drivers",
          listOfKeys: [
            "driverId",
            "driverSub",
            "driverName",
            "driverType",
            "driverStatus",
            "inactivityTimeline",
          ],
        }),
      ])
        .then(([{ value: tr }, { value: dr }]) => {
          let truckRes = tr?.sort((a, b) =>
            a?.fleetName?.localeCompare(b?.fleetName)
          );
          setTrucks(
            truckRes?.map((truck) => ({
              ...truck,
              teamsConfiguration: truck?.teamsConfiguration || [],
            }))
          );
          setDrivers(
            dr?.sort((a, b) => a?.driverName?.localeCompare(b?.driverName))
          );

          setReadyState((prev) => ({ ...prev, fleets: true, drivers: true }));

          if (!!requestObject) {
            form.setFieldsValue(populateRequestObject(requestObject, dr, tr));
            let labelType = "Not a project (off schedule)";
            if (!!requestObject?.scheduleId) {
              labelType = "schedule";
            } else if (!!requestObject?.projectId) {
              labelType = "project (off schedule)";
            } else if (yards.includes(requestObject?.jobsiteAddress)) {
              labelType = "yard";
            }
            let label = getLabelHTML(labelType);
            setListOfLabels([label]);
            setRequestSelections([
              {
                index: 0,
                address: requestObject?.jobsiteAddress,
                requestId: requestForm?.requestId,
                cargoStatus: requestObject?.cargoStatus,
              },
            ]);
            setTimeout(() => {
              if (!!form.getFieldValue("ArriveBy#0")) {
                setRoutePopover("trafficDepart#0");
              }
            }, 100);
          }
        })
        .catch((err) => {
          console.log("Error Getting Data: ", err);
        });

      lazyFetch({
        tableName: "projects",
        listOfKeys: [
          "projectName",
          "projectId",
          "accountName",
          "projectLatitude",
          "projectLongitude",
        ],
      }).then((r) => {
        let names = [],
          p = [];
        r?.forEach((project) => {
          const projectName = (project?.projectName ?? "")?.trim();

          if (!names?.includes(projectName)) {
            names.push(projectName);
            p.push({
              ...project,
              projectName,
            });
          }
        });
        setAllProjects(
          r.map((e) => ({ ...e, projectName: (e?.projectName ?? "").trim() }))
        );
        setReadyState((prev) => ({ ...prev, projects: true }));
      });

      API.get("getGeofences", "/getGeofences")
        .then(setVendorFences)
        .catch((err) => {
          console.log("Error getting vendor fences: ", err);
        });

      filterTables(
        "formRequests",
        "assignedTo",
        userConfiguration?.cognitoUserId
      )
        .then((res) => {
          let requestsToConsider = res?.filter(
            ({ requestObject, responseFrom, category = "" }) => {
              const { status } = requestObject;
              if (
                responseFrom !== "approved" &&
                status !== "Cancelled" &&
                category?.toLowerCase().includes("dispatch")
              ) {
                return true;
              }
            }
          );
          setRequests(requestsToConsider);

          let tmpEligible = filterRequests({
            requests: requestsToConsider,
            date: rowObject?.dispatchDate || undefined,
            truck: rowObject?.fleetName || undefined,
          });

          if (!tmpEligible?.length && !requestForm?.requestId) {
            message.info("There are no requests for the selected date");
          }

          setEligibleRequests(tmpEligible);
          setReadyState((prev) => ({ ...prev, requests: true }));
        })
        .catch((err) => {
          console.log("Error Getting Requests: ", err);
        });

      message.loading({
        content: "Loading dispatch data...",
        key: "scheduleLoad",
        duration: 0,
      });

      (proppedDispatches
        ? Promise.resolve(proppedDispatches)
        : fetchAllData({
            endpoint: "fleetDispatching",
            resultId: "dispatchId",
            resultPosition: "fleetDispatching",
          })
      )
        .then((dis) => {
          setAllDispatches(dis);
          setReadyState((prev) => ({ ...prev, dispatches: true }));

          (proppedSchedules
            ? Promise.resolve(proppedSchedules)
            : fetchAllData({
                endpoint: "scheduling",
                resultId: "scheduleId",
                resultPosition: "scheduling",
                otherStringParams: {
                  keysToInclude: JSON.stringify([
                    "scheduleAddress",
                    "scheduleName",
                    "scheduleId",
                    "scheduleDays",
                    "typeOfWork",
                    "teamsConfiguration",
                    "projectId",
                  ]),
                },
              })
          )
            .then((r) => {
              message.destroy("scheduleLoad");

              let s = (r || []).map((e) => ({
                ...e,
                scheduleAddress: (e?.scheduleAddress ?? "")?.trim(),
              }));

              if (
                !!defaultData?.schedule &&
                !defaultData?.schedule?.scheduleId
              ) {
                s = s.concat(
                  r?.concat({
                    ...defaultData.schedule,
                    scheduleId: "scheduleToCreate",
                    teamsConfiguration: [],
                  })
                );
              }
              setAllSchedules(s);

              let scheduleSelections = {};
              for (let i = 0; i < s?.length; i++) {
                let tmp = [];
                scheduleSelections[
                  s[i]?.scheduleAddress?.replace(/(, USA)$/, "")
                ] = tmp;
                for (let j = 0; j < s[i]?.teamsConfiguration?.length; j++) {
                  s[i]?.teamsConfiguration[j]?.members?.forEach((member) => {
                    tmp.push(member?.nameOfUser);
                  });
                }
              }
              setTeamsBySchedule(scheduleSelections);

              //if we have a date we want to get all the schedules for that date
              let dateToCompare = "";
              if (!!rowObject?.dispatchDate) {
                dateToCompare = dayjsNY(rowObject?.dispatchDate).format(
                  "MM/DD/YYYY"
                );
              } else if (!!defaultData?.schedule?.scheduleDays[0]?.startDate) {
                dateToCompare = dayjsNY(
                  defaultData?.schedule?.scheduleDays[0]?.startDate
                ).format("MM/DD/YYYY");
              } else {
                dateToCompare = dayjsNY(form.getFieldValue("date")).format(
                  "MM/DD/YYYY"
                );
              }

              const newSchedulesForDate = s?.filter(({ scheduleDays }) =>
                scheduleDays?.some(
                  ({ startDate }) =>
                    dayjsNY(startDate).format("MM/DD/YYYY") === dateToCompare
                )
              );

              setSchedulesForDate(newSchedulesForDate);
              setOverlappingSchedules(
                findOverlappingSchedules(newSchedulesForDate)
              );

              //added only to make the route card re-render once the schedules are filtered
              setFieldChanged(!fieldChanged);
              setReadyState((prev) => ({ ...prev, schedules: true }));
            })
            .catch((err) => {
              console.log("Error getting schedules: ", err);
              message.error({
                content: "Something went wrong while getting schedules",
                key: "scheduleLoad",
              });
            });
        })
        .catch((err) => console.log("Error getting dispatches: ", err));

      fetchData("teams")
        .then((res) => {
          setAllTeams(res);
          setReadyState((prev) => ({ ...prev, teams: true }));
        })
        .catch((err) => {
          console.log("Error getting teams: ", err);
        });

      setUsers(
        filterPeopleList(userConfiguration.allUsers?.Items, "cognitoUserId")
      );

      const fieldObj = programFields?.reduce(
        (acc, { fieldName, fieldOptions }) => ({
          ...acc,
          [fieldName]: fieldOptions,
        }),
        {}
      );

      setActivityStatuses(
        fieldObj["Statuses Of Categories"]["Activity"].flatMap(
          ({ statusName, status }) => {
            if (
              !status ||
              statusName === "Cancelled" ||
              statusName === "Audited"
            ) {
              return [];
            }

            return { value: statusName, label: statusName };
          }
        )
      );

      setStepperParams({
        steps: fieldObj["Statuses Of Categories"]["Dispatches"]
          ?.filter(({ status }) => status)
          .map(({ statusName }) => ({ title: statusName })),
        statusesColors: fieldObj["Statuses Of Categories"]["Dispatches"],
      });

      form.setFieldValue("dispatchStatus", "Draft");

      let reloadWarningHandler = (event) => {
        event.preventDefault();
        event.returnValue = "";
      };

      window.addEventListener("beforeunload", reloadWarningHandler);

      return () => {
        window.removeEventListener("beforeunload", reloadWarningHandler);
      };
    }, []);

    const addressesField = useMemo(() => {
      //#region HOME ADDRESSES
      return programFields.find(
        ({ fieldName }) => fieldName === "Home Addresses"
      );
    }, [programFields]);

    const vendors = useMemo(() => {
      //#region VENDORS
      if (!programFields) {
        return [];
      }

      /** @type {VendorType[]} */
      const v =
        programFields?.find(({ fieldName }) => fieldName === "Vendors")
          ?.fieldOptions || [];

      setGarageAddresses(
        v.reduce((acc, val) => {
          if (!val.fleetId) {
            return acc;
          }

          return {
            ...acc,
            [val.fleetId]: {
              address: val.vendorAddress,
              position: val.addressPosition,
            },
          };
        }, {})
      );

      return v;
    }, [programFields]);

    /**
     * Function that takes all the trucks present in the yard before a specific time
     * @param selector "truck" | "driver"
     * @param data The data to be filtered "trucks" | "drivers"
     * @returns A filtered list of available trucks
     */
    function getFilteredResources(selector, data) {
      //#region FILTER OPTIONS
      let field = selector === "truck" ? "fleetId" : "driverId";
      let excludeDriver = [];
      if (!!complementaryRoutes?.length && selector === "driver") {
        excludeDriver = [
          complementaryRoutes[0]?.routes?.[0]?.driverId,
          complementaryRoutes[0]?.routes?.[1]?.driverId,
        ];
      }
      let potentialDataToRemove = filteredDispatches
        ?.reduce(
          (acc, val) => [
            ...acc,
            !acc?.includes(val?.[field]) ? val?.[field] : null,
          ],
          []
        )
        .filter(Boolean);
      let dataToRemove = [];
      for (let i = 0; i < potentialDataToRemove?.length; i++) {
        if (
          selector === "driver" &&
          excludeDriver.includes(potentialDataToRemove[i])
        ) {
          continue;
        } else {
          let maxReturnTime = Math.max(
            ...filteredDispatches
              ?.filter((route) => route?.[field] === potentialDataToRemove[i])
              ?.reduce(
                (acc, val) => [...acc, dayjsNY(val?.lastEntry).valueOf()],
                []
              )
          );
          if (
            maxReturnTime > dayjsNY(complementaryRoutes[0]?.arrive).valueOf()
          ) {
            dataToRemove.push(potentialDataToRemove[i]);
          }
        }
      }
      return data?.filter((el) => !dataToRemove?.includes(el?.[field]));
    }

    useEffect(() => {
      //#region ON DISPATCH READY
      if (Object.values(readyState).every((value) => value === true)) {
        onDispatchReady();
        setDispatchReady(true);
      }
    }, [readyState]);

    /**
     * Populates the form fields with the selected row data
     */
    useEffect(() => {
      //#region POPULATE DATA
      if (Object.keys(rowObject)?.length > 0) {
        if (!!rowObject?.dispatchId) {
          fetchAllData({
            endpoint: "editLogs",
            resultPosition: "editLogs",
            resultId: "logId",
            otherStringParams: {
              getMaxLimit: "true",
              filters: JSON.stringify([
                {
                  conditions: [
                    {
                      column: "recordId",
                      value: rowObject?.dispatchId,
                      formula: "is",
                    },
                  ],
                },
              ]),
            },
          })
            .then((r) => {
              setLogs(r);
            })
            .catch((err) => {
              console.log("Error getting logs: ", err);
            });

          //we filter the requests based on the new row objects
          //if we have a propped object, the eligible requests are
          //set in the first use effect
          if (
            rowObject?.dispatchId !== obj?.dispatchId ||
            (!rowObject?.dispatchId && !!Object.keys(rowObject)?.length)
          ) {
            let tmpEligible = filterRequests({
              requests: requests,
              date: rowObject?.dispatchDate || undefined,
              truck: rowObject?.fleetName || undefined,
            });

            if (!tmpEligible?.length && !requestForm?.requestId) {
              message.info("There are no requests for the selected date");
            }

            setEligibleRequests(tmpEligible);
          }
        }

        let formObject = populateFields(rowObject);
        //we set up the list of drop off labels
        let tmpList = [],
          tmpDistances = [],
          tmpVariances = [],
          tmpCancelRoutes = [],
          tmpHoistRoutes = [],
          tmpItinerary = {};
        rowObject?.routes?.forEach((route, i) => {
          //the last route is always the return route so it doesn't need a label
          let c = i === rowObject?.routes?.length - 1;
          if (!c) {
            if (!!route?.scheduleId) {
              tmpList.push(getLabelHTML("schedule"));
            } else if (!!route?.projectId) {
              tmpList.push(getLabelHTML("project (off schedule)"));
            } else if (yards.includes(route?.dropOffLocation)) {
              tmpList.push(getLabelHTML("yard"));
            } else if (route?.vendorId) {
              tmpList.push(getLabelHTML("vendor"));
            } else {
              tmpList.push(getLabelHTML("not a project (off schedule)"));
            }
            tmpDistances.push(route?.routeLength || 0);
            if (route.hoistRoute === true) {
              tmpHoistRoutes.push(i);
            }

            if (!!route?.routeVariance) {
              tmpVariances.push(i);
            }

            if (route.varianceReason) {
              formObject[`VarianceReason#${i}`] = route.varianceReason;
            }
            if (route.varianceNotes) {
              formObject[`VarianceNotes#${i}`] = route.varianceNotes;
            }
          }

          if (route?.itinerary?.length) {
            tmpItinerary[i] = route.itinerary.map((e) => ({
              ...e,
              departAt: dayjsNY(e.departAt),
              arriveBy: dayjsNY(e.arriveBy),
            }));
          }
        });

        //we get the possible route cancellations
        Object.keys(formObject)?.forEach((key) => {
          if (key?.includes("cancelId")) {
            let cancelIndex = key?.split("#")?.[1];
            if (!isNaN(+cancelIndex)) {
              tmpCancelRoutes.push({
                id: formObject[key],
                cancelIndex: parseInt(cancelIndex),
                label: tmpList[cancelIndex],
                defaultPassenger: !!formObject[`Passenger#${cancelIndex}`],
                cancelled: false,
              });
            }
          }
        });

        if (!!routeSwitched[0] && !!rowObject?.dispatchId) {
          //we sort the index changes by the indexes
          let sw = structuredClone(routeSwitched[0]);
          let routeInfo = [...sw?.routeChangeInfo];
          routeInfo.sort((a, b) => a?.index - b?.index);

          for (let i = 0; i < routeInfo?.length; i++) {
            let rt = routeInfo[i]?.index === rowObject?.routes?.length - 1;
            formObject[
              `${rt ? "Return" : ""}DepartAt#${rt ? "" : routeInfo[i]?.index}`
            ] = dayjsNY(routeInfo[i]?.depart);
            formObject[
              `${rt ? "Return" : ""}ArriveBy#${rt ? "" : routeInfo[i]?.index}`
            ] = null;
            if (routeInfo[i]?.index > 0) {
              formObject[`TimeExit#${routeInfo[i]?.index - 1}`] = dayjsNY(
                routeInfo[i]?.depart
              );
            }
          }

          let smallestIndex = routeInfo[0].index;
          //condition for the return route
          let c = smallestIndex === rowObject?.routes?.length - 1;

          setRouteSwitched(
            [
              { ...sw, routeChangeInfo: routeInfo },
              ...routeSwitched.slice(1),
            ].filter(Boolean)
          );

          setTimeout(() => {
            if (c) {
              setReturnPopover("trafficArrive");
            } else {
              setRoutePopover(`trafficArrive#${smallestIndex}`);
            }
          }, 0);
        } else if (!!complementaryRoutes?.length) {
          let t = [...complementaryRoutes];
          t.splice(0, 1);
          setFilterTrucks(getFilteredResources("truck", trucks));
          setFilterDrivers(getFilteredResources("driver", drivers));
          setTimeout(() => {
            setRouteTime({ arrive: false, index: 0, time: "" });
            setRoutePopover("trafficDepart#0");
          }, 0);
          setComplementaryRoutes(t);
        }

        form.setFieldsValue({
          ...formObject,
          dispatchTeam: getTeamsSelectionsFromItems(
            rowObject?.teamsConfiguration
          ),
        });
        setListOfLabels(tmpList);
        setListOfDistances(tmpDistances);
        setItineraryData(tmpItinerary);
        // setReturnDistance(res?.[rowObject?.routes?.length - 1]);
        setReturnDistance(
          rowObject?.routes?.length === 2
            ? 0
            : rowObject?.routes?.[rowObject?.routes?.length - 1]?.routeLength ||
                0
        );
        setVarianceRoutes(tmpVariances);
        setSelectedTeam(rowObject?.teamsConfiguration || []);
        setHoistRoutes(tmpHoistRoutes);
        setCancelledRoutes(tmpCancelRoutes);
        setRouteLength(rowObject?.routes?.length - 1);
        setFieldChanged(!fieldChanged);

        /**
         * groups the changes from the schedule with the activity IDs
         * if the scheduled is referenced more than once in the same dispatch
         * the first reference will show the changes button
         */
        if (allSchedules?.length && rowObject?.scheduleChanges?.length) {
          let tmpSchChanges = rowObject?.scheduleChanges?.map((change) => {
            const scheduleAddress =
              change?.scheduleAddress ||
              allSchedules?.find(
                ({ scheduleId }) => scheduleId === change?.scheduleId
              )?.scheduleAddress;

            return {
              ...change,
              scheduleAddress,
              cancelId: rowObject?.routes?.find(
                ({ scheduleId, dropOffLocation }) =>
                  scheduleId === change?.scheduleId ||
                  scheduleAddress === dropOffLocation
              )?.activityId,
            };
          });
          let changesObject = {};
          tmpSchChanges?.forEach((change) => {
            if (!changesObject[change?.cancelId]) {
              changesObject[change?.cancelId] = [
                {
                  ...change,
                  accepted: false,
                },
              ];
            } else {
              changesObject[change?.cancelId] = [
                ...changesObject[change?.cancelId],
                { ...change, accepted: false },
              ];
            }
          });
          setRowScheduleChanges(changesObject);
        }

        if (allSchedules?.length) {
          setListOfLabels((prev) => {
            let list = [...prev];
            rowObject?.routes?.forEach(({ scheduleId }, index) => {
              if (!!scheduleId) {
                let scheduleName = allSchedules?.find(
                  (sch) => sch?.scheduleId === scheduleId
                )?.scheduleName;
                list[index] = getLabelHTML("schedule", null, scheduleName);
              }
            });

            return list;
          });
        }

        if (rowObject?.routes) {
          rowObject.routes.forEach((route, i) => {
            if (route.varianceReason) {
              setVarianceReason(route.varianceReason);
            }
            if (route.varianceNotes) {
              setVarianceNote(route.varianceNotes);
            }
          });
        }
      } else {
        form.setFieldsValue({
          [`ActivityStatus#0`]: "Draft",
          [`ReturnActivityStatus#`]: "Draft",
        });
      }
    }, [JSON.stringify(rowObject), allSchedules]);

    useEffect(() => {
      //#region DISPATCH SOCKET
      if (dispatchSocket) {
        dispatchSocket.addEventListener("message", (msg) => {
          const message = JSON.parse(msg.data);

          /** @type {string} */
          const request = message?.request;
          /** @type {Record<string, any>} */
          const body = message?.body;

          if (!request) {
            return;
          }

          switch (request) {
            case "dispatch-create":
              setAllDispatches((prev) => [body, ...prev]);
              break;
            case "dispatch-edit":
              setAllDispatches((prev) => {
                const rowIndex = prev.findIndex(
                  (d) => d.dispatchId === body?.dispatchId
                );

                if (rowIndex === -1) {
                  return prev;
                }

                prev[rowIndex] = {
                  ...prev[rowIndex],
                  ...body,
                };

                return [...prev];
              });
              break;
            case "dispatch-delete":
              setAllDispatches((prev) =>
                prev.filter((d) => d.dispatchId !== body?.dispatchId)
              );
              break;
            case "dispatch-schedule-changes":
              const {
                affectedDispatches = [],
                changes = [],
                status = "Pending",
                keepId = false,
                scheduleId = "",
                projectId = "",
              } = body;

              setAllDispatches((prev) => {
                return prev.map((dispatch) => {
                  if (affectedDispatches.includes(dispatch?.dispatchId)) {
                    const newDispatch = { ...dispatch };
                    newDispatch["dispatchStatus"] = status;
                    newDispatch["scheduleChanges"] = [
                      ...(dispatch?.scheduleChanges || []),
                      ...changes,
                    ];

                    if (!keepId) {
                      newDispatch["routes"] = newDispatch["routes"].map(
                        (route) => {
                          if (route?.scheduleId === scheduleId) {
                            return { ...route, scheduleId: "", projectId };
                          }

                          return route;
                        }
                      );
                    }

                    return newDispatch;
                  }

                  return dispatch;
                });
              });
              break;
            default:
              break;
          }
        });
      }
    }, [dispatchSocket]);

    function onGridReady(params) {
      setGridApi(params.api);
      params.api.sizeColumnsToFit();
    }

    /**
     * Returns a list of routes happening on the selected date
     */
    const filteredDispatches = useMemo(() => {
      //#region FILTERED DISPATCHES
      let selectedDispatchDate = dayjs
        .tz(form.getFieldValue("date"))
        .format(dateFormat);
      //in edit mode we do not want to include the selected row's routes
      //so to not cause time conflicts
      return allDispatches
        ?.filter(
          (el) =>
            (dayjsNY(el?.dispatchDate).format(dateFormat) ===
              selectedDispatchDate ||
              el?.routes?.some(
                (e) =>
                  dayjsNY(e?.arriveBy).format(dateFormat) ===
                  selectedDispatchDate
              )) &&
            el?.dispatchId !== rowObject?.dispatchId
        )
        ?.reduce(
          (acc, val) => [
            ...acc,
            ...val?.routes?.map((route, index) => ({
              dispatchDate: dayjsNY(val?.dispatchDate).format("MM/DD/YYYY"),
              dispatchId: val?.dispatchId,
              firstExit: dayjsNY(route?.departAt).valueOf(),
              lastEntry: dayjsNY(route?.arriveBy).valueOf(),
              timeExit: !!route?.timeExit
                ? dayjsNY(route?.timeExit).valueOf()
                : "",
              driverId: route?.driverId ?? val?.driverId,
              passengerId: route?.passengerId?.[0] ?? "",
              fleetId: val?.fleetId,
              scheduleId: route?.scheduleId || val?.scheduleId || "",
              pickupLocation: route?.pickUpLocation,
              dropOffLocation: route?.dropOffLocation,
              activityId: route?.activityId,
              originalRouteIndex: index,
              routeName: route?.routeName ?? "",
              fleetName: val?.fleetName,
              projectId: route?.projectId || val?.projectId || "",
            })),
          ],
          []
        )
        ?.filter((el) => !!el?.lastEntry);
    }, [
      allDispatches,
      dayjsNY(form.getFieldValue("date")).format(dateFormat),
      rowObject,
    ]);

    const dispatchesTableRows = useMemo(() => {
      //#region DISPATCHES TABLE
      return allDispatches?.reduce(
        (
          accumulated,
          { dispatchDate, status, fleetName, dispatchStatus, routes = [] }
        ) => [
          ...accumulated,
          ...routes?.reduce(
            (acc, curr) => [
              ...acc,
              {
                dropOffLocation: curr?.dropOffLocation,
                departAt: curr?.departAt,
                arriveBy: curr?.arriveBy,
                fleetName,
                driverName: curr?.driverName,
                status: dispatchStatus || status,
                dispatchDate: dayjsNY(dispatchDate).format("MM/DD/YYYY"),
              },
            ],
            []
          ),
        ],
        []
      );
    }, [allDispatches]);

    /**
     * This is used during the driver switch. When a driver has time conflicts, there
     * are validations that prevent us from setting the new driver. Since the filtered
     * dispatches don't change unless all the dispatches change (which is the case on a driver switch),
     * we use that to simulate a driver selection, but now with the correct data
     */
    useEffect(() => {
      //#region DRIVER SWITCH
      if (switchDriver) {
        let c = switchDriver?.routeIndex === null;
        if (!!switchDriver?.allRoutes && !!switchDriver?.selectedRoute) {
          // let actId = switchDriver?.selectedRoute?.activityId;

          let t = structuredClone(switchDriver);
          delete t["allRoutes"];
          delete t["selectedRoute"];
          setSwitchDriver(t);

          let route = !!switchDriver?.selectedRoute?.activityId
            ? filteredDispatches?.find(
                (el) =>
                  el?.activityId === switchDriver?.selectedRoute?.activityId
              )
            : {
                ...switchDriver?.selectedRoute,
                firstExit: dayjsNY(
                  switchDriver?.selectedRoute?.departAt
                ).valueOf(),
                lastEntry: dayjsNY(
                  switchDriver?.selectedRoute?.arriveBy
                ).valueOf(),
                timeExit: !!switchDriver?.selectedRoute?.timeExit
                  ? dayjsNY(switchDriver?.selectedRoute?.timeExit).valueOf()
                  : "",
                passengerId: switchDriver?.selectedRoute?.passengerId[0] || "",
                pickupLocation: switchDriver?.selectedRoute?.pickUpLocation,
              };

          switchDriversHandler({
            selectedRoute: route,
          });
          return;
        }
        let selector =
          switchDriver?.selector === "driver" ? "Driver" : "Passenger";
        form.setFieldValue(
          `${c ? "Return" : ""}${selector}#${
            c ? "" : switchDriver?.routeIndex
          }`,
          switchDriver?.driverToSwitch
        );
        if (!!switchDriver?.depart) {
          form.setFieldValue(
            `${c ? "Return" : ""}DepartAt#${c ? "" : switchDriver?.routeIndex}`,
            dayjsNY(switchDriver?.depart)
          );

          if (switchDriver?.routeIndex > 0) {
            form.setFieldValue(
              `TimeExit#${switchDriver?.routeIndex - 1}`,
              dayjsNY(switchDriver?.depart)
            );
          } else if (switchDriver?.routeIndex === null) {
            form.setFieldValue(
              `TimeExit#${routeLength - 1}`,
              dayjsNY(switchDriver?.depart)
            );
          }

          if (c) {
            setReturnPopover("trafficArrive");
          } else {
            setRoutePopover(`trafficArrive#${switchDriver?.routeIndex}`);
          }
        }
        if (!c) {
          //in this case we want to change the drivers in the modal, but only on the
          //previous driver's routes
          for (
            let index = switchDriver?.routeIndex;
            index <= routeLength;
            index++
          ) {
            let rt = index === routeLength;
            let driverField = `${rt ? "Return" : ""}Driver#${rt ? "" : index}`;
            let passengerField = `${rt ? "Return" : ""}Passenger#${
              rt ? "" : index
            }`;

            if (form.getFieldValue(driverField) === prevDriver) {
              form.setFieldValue(driverField, switchDriver?.driverToSwitch);
            } else if (form.getFieldValue(passengerField) === prevDriver) {
              form.setFieldValue(passengerField, switchDriver?.driverToSwitch);
            } else if (
              !form.getFieldValue(driverField) &&
              selector === "Driver"
            ) {
              form.setFieldValue(driverField, switchDriver?.driverToSwitch);
            }
          }
        }
        setSwitchDriver(null);
      }
    }, [filteredDispatches]);

    useEffect(() => {
      //#region WAIT FOR INDEX
      if (waitForIndex.index > -1) {
        onDropOffSelect(
          waitForIndex.value,
          waitForIndex.index,
          waitForIndex.option,
          true
        );
        setWaitForIndex({ value: "", option: {}, index: -1 });
      }
    }, [waitForIndex.index]);

    /**
     * Function that updates the duration field on time changes
     * @param {String} caller The caller identifier "route" | "return"
     * @param {Number} index The caller route index
     */
    function updateDurationHandler(caller, index) {
      //#region UPDATE DURATION HANDLER
      let c = caller === "return";
      let durationField = `${c ? "Return" : ""}Duration#${c ? "" : index}`;
      let departField = `${c ? "Return" : ""}DepartAt#${c ? "" : index}`;
      let arriveField = `${c ? "Return" : ""}ArriveBy#${c ? "" : index}`;

      form.setFieldValue(
        durationField,
        dayjs
          .utc(
            dayjs
              .duration(
                dayjs
                  .tz(form.getFieldValue(arriveField) || undefined)
                  .diff(dayjsNY(form.getFieldValue(departField) || undefined))
              )
              .asMilliseconds()
          )
          .format("HH:mm")
      );
    }

    function passSuggestionHandler(
      departSmaller,
      index,
      tmp,
      distance,
      reverse = false
    ) {
      //#region PASS SUGGESTION HANDLER
      let c = index === null;
      let keepDateCondition =
        (reverseCalcStart === -1 && reverse) || reverseCalcStart !== -1;
      if (departSmaller && !keepDateCondition) {
        form.setFieldsValue(
          changeRouteDates({
            existingFields: form.getFieldsValue(),
            index,
            routeLength,
          })
        );
        tmp["time"] = dayjsNY(tmp?.time).add(1, "day").valueOf();
      }
      if (c) {
        setReturnTime(tmp);
        setReturnDistance(distance);
      } else {
        let dArray = [...listOfDistances];
        dArray[index] = distance;
        setListOfDistances(dArray);
        setRouteTime(tmp);
      }
    }

    /**
     * Gives a suggestion based on the existing itineraries
     * @param {String} pop The caller's popover text, used to differentiate between depart and arrive
     * @param {Number} index The route that needs the suggestion
     * @param {boolean} findSimilar Finds a similar match
     */
    function setItinerarySuggestion(pop, index, findSimilar = false) {
      //#region ITINERARY SUGGESTION
      let indexToConsider = index === null ? routeLength : index;
      let isInItinerary = false;
      const isReturn = index === null;
      const pick = form.getFieldValue(
        `${isReturn ? "Return" : ""}PickupLocation#${isReturn ? "" : index}`
      );
      const drop = form.getFieldValue(
        `${isReturn ? "Return" : ""}DropOffLocation#${isReturn ? "" : index}`
      );

      let matchingIndex = undefined;

      if (findSimilar) {
        matchingIndex = Object.keys(itineraryData).find((key) => {
          const itinerary = itineraryData[key];
          return (
            (itinerary[0]["pickUpLocation"] === pick &&
              itinerary[itinerary.length - 1]["dropOffLocation"] === drop) ||
            (itinerary[0]["pickUpLocation"] === drop &&
              itinerary[itinerary.length - 1]["dropOffLocation"] === pick)
          );
        });
      }

      if (`${indexToConsider}` in itineraryData || !isNaN(+matchingIndex)) {
        /**
         * In case the user has a pre-selected itinerary, we don't
         * need to go through the calculations. In this case we can
         * simply get the total offset and change the times based on
         * the total and the field set to be changed
         */
        if (!(`${indexToConsider}` in itineraryData)) {
          indexToConsider = +matchingIndex;
        } else {
          isInItinerary = true;
        }

        const itinerary = itineraryData[indexToConsider];
        let totalDistance = 0,
          totalDuration = 0;

        for (let i = 0; i < itinerary.length; i++) {
          const step = itinerary[i];
          let nextStep = itinerary[i + 1];

          if (nextStep) {
            totalDuration += dayjsNY(nextStep.departAt).diff(
              dayjsNY(step.arriveBy),
              "milliseconds"
            );
          }

          totalDistance += step.routeLength;
          totalDuration += step.duration;
        }

        let time = null,
          arrive = false;

        /**
         * When the user changes the times manually, we need
         * to make sure that everything stays synced
         */
        let diffChange = 0;

        if (pop.includes("Arrive")) {
          //in this case the user changed the depart time
          const selected = form.getFieldValue(
            `${isReturn ? "Return" : ""}DepartAt#${isReturn ? "" : index}`
          );

          time = selected.add(totalDuration, "milliseconds");

          arrive = true;

          diffChange = dayjsNY(selected).diff(
            itinerary[0]["departAt"],
            "milliseconds"
          );
        } else {
          //in this case the user changed the arrive time
          const selected = form.getFieldValue(
            `${isReturn ? "Return" : ""}ArriveBy#${isReturn ? "" : index}`
          );
          time = selected.subtract(totalDuration, "milliseconds");
          diffChange = dayjsNY(selected).diff(
            itinerary[itinerary.length - 1]["arriveBy"],
            "milliseconds"
          );
        }

        if (diffChange && isInItinerary) {
          const newItinerary = itinerary.map((e) => ({
            ...e,
            departAt: e.departAt.add(diffChange, "milliseconds"),
            arriveBy: e.arriveBy.add(diffChange, "milliseconds"),
          }));

          setItineraryData((prev) => ({
            ...prev,
            [indexToConsider]: newItinerary,
          }));
        }

        if (isReturn) {
          setReturnTime({
            arrive,
            time,
          });
          setReturnDistance(totalDistance);
        } else {
          let dArray = [...listOfDistances];
          dArray[index] = totalDistance;
          setListOfDistances(dArray);
          setRouteTime({ arrive, time, index });
        }

        return true;
      } else {
        if (findSimilar) {
          message.warning({
            content: `Could not get time difference (${
              isReturn ? "Return" : `Route ${index + 1}`
            })`,
            key: "noTimeDifference",
          });
        }

        return false;
      }
    }

    /**
     * Function that gets the time suggestion and opens the appropriate popover on the route cards
     * @param {String} model The traffic model
     * @param {String} pop The caller's popover text, used to differentiate between depart and arrive
     * @param {String} caller The caller itself, used to differentiate between route and return
     * @param {Number} index The route that needs the suggestion
     */
    async function getSuggestionForRoute(model, pop, caller, index) {
      //#region GET SUGGESTION FOR ROUTE
      const isItinerary = setItinerarySuggestion(pop, index);
      if (isItinerary) {
        return;
      }

      getSuggestion(model, pop, caller, form, index)
        .then((res) => {
          if (isNaN(res?.time)) {
            setItinerarySuggestion(pop, index, true);

            return;
          }

          //sets the distance between the two points in state according to the index
          let distance = res?.distance;
          let tmp = { ...res };
          delete tmp["distance"];

          let valueStartOf = dayjsNY(form.getFieldValue("date"))
            .startOf("day")
            .valueOf();
          let valueEndOf = dayjsNY(form.getFieldValue("date"))
            .endOf("day")
            .valueOf();
          let departSmaller = false;
          let arriveLarger = false;
          let departField = `${index === null ? "Return" : ""}DepartAt#${
            index === null ? "" : index
          }`;

          if (dayjsNY(tmp?.time).valueOf() < valueStartOf) {
            //this is only used when the user selects an arrive close to 12am
            departSmaller = true;
          } else if (dayjsNY(tmp?.time).valueOf() > valueEndOf) {
            let departFieldValue = form.getFieldValue(departField);
            if (!!departFieldValue) {
              if (
                dayjsNY(departFieldValue).startOf("day")?.valueOf() <
                  dayjsNY(tmp?.time)?.startOf("day")?.valueOf() ||
                dayjsNY(tmp?.time).valueOf() > valueStartOf
              ) {
                arriveLarger = true;
              }
            }
          }

          if (index !== 0) {
            let prevArriveField = `ArriveBy#${
              !!index ? index - 1 : routeLength - 1
            }`;
            let prevDepartField = "";
            if (!!form.getFieldValue(prevArriveField)) {
              if (
                dayjsNY(tmp?.time).valueOf() <
                dayjsNY(form.getFieldValue(prevArriveField))
              ) {
                departSmaller = true;
              }
            } else if (!!form.getFieldValue(prevDepartField)) {
              if (
                dayjsNY(tmp?.time).valueOf() <
                dayjsNY(form.getFieldValue(prevDepartField))
              ) {
                departSmaller = true;
              }
            }
          }

          let thisRouteIndex = index === null ? routeLength : index;
          let indexToCompare =
            acceptNextDay === null
              ? routeLength
              : acceptNextDay !== false
              ? acceptNextDay
              : routeLength;

          let isFromRequest = requestSelections?.find(
            (el) => el?.index === index
          );

          if (
            (departSmaller || arriveLarger) &&
            thisRouteIndex <= indexToCompare &&
            reverseCalcStart === -1
          ) {
            let prevEditBlock = editBlock;
            setEditBlock(routeLength);
            if (isFromRequest && departSmaller && reverseCalcStart === -1) {
              getTimeErrorForRequest({
                index,
                time: dayjsNY(form.getFieldValue(`ArriveBy#${index}`)).format(
                  timeFormat
                ),
                handler() {
                  setReverseCalcStart(index);
                  setEditBlock(prevEditBlock);
                  passSuggestionHandler(
                    departSmaller,
                    index,
                    tmp,
                    distance,
                    true
                  );
                },
                closeHandler() {
                  setEditBlock(prevEditBlock);
                },
              });
            } else {
              getNextDayWarningNotification({
                index,
                prevEditBlock,
                setEditBlock,
                handler() {
                  setAcceptNextDay(index === null ? routeLength : index);
                  setEditBlock(prevEditBlock);
                  passSuggestionHandler(departSmaller, index, tmp, distance);
                },
              });
            }
          } else {
            passSuggestionHandler(departSmaller, index, tmp, distance);
          }
        })
        .catch((err) => console.log("Error getting suggestion: ", err));
    }

    //#region ON PICK UP SELECT
    function onPickupLocationSelect(val, index) {
      let dropOff = form.getFieldValue(`DropOffLocation#${index}`);
      if (dropOff === val) {
        message.error("Pickup and drop off cannot be the same!");
        form.setFieldValue(`PickupLocation#${index}`, "");
        return;
      }
      form.setFieldsValue({
        "ReturnDropOffLocation#": form.getFieldValue("PickupLocation#0"),
        ...nullifyAllTimes(index),
      });
      setRoutePopover(`trafficArrive#${index}`);

      //we cause a re-render of the return so it gets the new route widths
      setFieldChanged(!fieldChanged);
    }

    //#region ON DROP OFF SELECT
    /**
     * Function that handles the drop off selection
     * @param {String} val The selected value
     * @param {Number} index The caller route index
     * @param {Object} option The select option from the dropdown select
     * returned by the second parameter of the onSelect handler of the Select component
     */
    async function onDropOffSelect(
      val,
      index,
      option,
      selectionFromRequest = false
    ) {
      //if the user wants to see the requests (in the first route)
      if (val === "") {
        setOpenListingForRequests({
          index,
          needsNew: false,
        });
        return;
      }

      let c = index === routeLength - 1;

      if (
        val?.trim() !==
        (
          form.getFieldValue(
            `${c ? "Return" : ""}PickupLocation#${c ? "" : index + 1}`
          ) || ""
        )?.trim()
      ) {
        if (`${index === null ? routeLength : index + 1}` in itineraryData) {
          message.warning({
            content: `Itinerary was cleared from ${
              c || index === null ? "Return" : "Route " + String(index + 2)
            }`,
            key: `clearItinerary${c ? "Return" : index + 2}`,
          });

          setItineraryData((prev) => {
            const tmp = { ...prev };
            delete tmp[index === null ? `${routeLength}` : `${index + 1}`];

            return tmp;
          });
        }
      }

      if (index === dropOffSelectAfterForceCancel) {
        //case after cancelling a route needed for a driver switch
        await selectNewSwitchLocation({
          index,
          allDispatches,
          routeSwitched,
          cancelledRoutes,
          rowObject,
          filteredDispatches,
          location: val.trim(),
          form,
          setAllDispatches,
          setRouteSwitched,
        }).catch((err) => {
          form.setFieldsValue({
            [`DropOffLocation#${index}`]: "",
            [`${c ? "Return" : ""}PickupLocation#${c ? "" : index + 1}`]: "",
          });
          return Promise.reject(err);
        });
        setEditBlock(-1);
        setDropOffSelectAfterForceCancel(-1);
        if (!option) {
          //user clicked "Use Existing"
          form.setFieldsValue({
            [`${c ? "Return" : ""}PickupLocation#${c ? "" : index + 1}`]: val,
          });
        }
      }

      let inversePopoverCondition = false;
      let changeCargo = true;
      if (selectionFromRequest) {
        let newAddress = val?.trim();
        form.setFieldsValue({
          [`DropOffLocation#${index}`]: newAddress,
          [`${c ? "Return" : ""}PickupLocation#${c ? "" : index + 1}`]:
            newAddress,
        });

        //in request options, the key is the request id
        let selectedRequest = requests?.find(
          ({ requestId }) => requestId === option.key
        );

        if (selectedRequest) {
          const { requestObject } = selectedRequest;

          if (index === 0) {
            if (requestObject?.routeNotes) {
              form.setFieldValue(
                `NotesAndDetails#${index}`,
                `[${requestObject?.priority}] - ${requestObject?.routeNotes}`
              );
            }

            changeCargo = false;

            if (requestObject?.driver) {
              if (
                requestObject?.driver &&
                !form.getFieldValue(`Driver#${index}`)
              ) {
                for (let i = index; i <= routeLength; i++) {
                  let r = i === routeLength;
                  form.setFieldValue(
                    `${r ? "Return" : ""}Driver#${r ? "" : i}`,
                    requestObject?.driver
                  );
                }
              }
            }

            if (requestObject?.timeOnSite) {
              let selectedTime = setHourMinute(
                requestObject?.dateOnSite,
                dayjsNY(requestObject?.timeOnSite)
              );

              form.setFieldsValue({
                [`DepartAt#${index}`]: null,
                [`DepartAt#${index}`]: null,
                [`ArriveBy#${index}`]: selectedTime,
                [`TimeExit#${index}`]: dayjsNY(selectedTime).add(1, "h"),
                [`${c ? "Return" : ""}DepartAt#${c ? "" : index + 1}`]: dayjsNY(
                  selectedTime
                ).add(1, "h"),
              });
            }
          }

          if (requestObject.vehicle && !form.getFieldValue("truckNumber")) {
            form.setFieldValue("truckNumber", requestObject.vehicle);
            onTruckSelect(requestObject.vehicle);
          }

          if (requestObject?.timeOnSite) {
            inversePopoverCondition = true;
          }
          if (index === 0) {
            let tmp = structuredClone(requestSelections);
            tmp = [
              ...tmp,
              {
                index,
                address: newAddress,
                requestId: selectedRequest?.requestId,
                cargoStatus: selectedRequest?.requestObject?.cargoStatus,
              },
            ];
            setRequestSelections(tmp);
          }
        }
      }

      let value = val;
      let tmp = getNewLabelHTML({
        listOfLabels,
        option,
        index: index === null ? routeLength : index,
        omitVendors: selectionFromRequest,
      });

      if (index !== null) {
        setListOfLabels(tmp?.list);
      }

      const isForHoist = hoistRoutes.includes(index);

      let type = tmp?.type;
      if (type === "schedule") {
        value = option?.value?.trim();
        let s = schedulesForDate?.find(
          (e) => e?.scheduleAddress === value
        )?.scheduleId;

        if (!!s) {
          form.setFieldsValue({
            [`DropOffLocation#${index}`]: value,
          });
          if (index === 0 && !!defaultData?.schedule?.scheduleId) {
            form.setFieldsValue({
              [`Schedule#${index}`]: defaultData?.schedule?.scheduleId,
            });
            onScheduleSelect(
              defaultData?.schedule?.scheduleId,
              index,
              inversePopoverCondition,
              changeCargo
            );
          } else if (!!overlappingSchedules[value]) {
            setScheduleSelectParams({
              index,
              inversePopoverCondition,
              changeCargo,
              schedules: schedulesForDate?.filter(({ scheduleId }) =>
                overlappingSchedules[value]?.includes(scheduleId)
              ),
            });
          } else {
            onScheduleSelect(s, index, inversePopoverCondition, changeCargo);
          }
          //we cause a re-render of the return so it gets the new route widths
          setFieldChanged(!fieldChanged);
          return;
        } else if (!defaultData) {
          message.warning("Schedule has been deleted");
        }
      }

      //set departure to 5am if it's the first route and there are no dispatches for the day
      if (type === "project" && index === 0 && !Object.keys(obj)?.length) {
        const thisProject = allProjects?.find(
          (el) => el?.projectName === option?.value
        );
        const dispatchesForProject = filteredDispatches?.filter(
          (el) => el?.projectId === thisProject?.projectId
        );
        const selectedDate = dayjs(form.getFieldValue("date"));
        console.log({ thisProject, dispatchesForProject, selectedDate });
        if (dispatchesForProject?.length === 0 && !!selectedDate) {
          const departure = dayjsNY(selectedDate)
            .set("hour", 5)
            .set("minute", 0);

          onDepartChange(departure, 0);
        }
      }
      if (
        (type !== "project" || type !== "schedule") &&
        index === 0 &&
        !Object.keys(obj)?.length
      ) {
        const notProjectOrSchedule = filteredDispatches?.filter(
          (d) =>
            !d?.projectId &&
            !d?.scheduleId &&
            d?.dropOffLocation === option?.value
        );
        const selectedDate = dayjs(form.getFieldValue("date"));

        if (notProjectOrSchedule?.length === 0 && !!selectedDate) {
          const departure = dayjsNY(selectedDate)
            .set("hour", 5)
            .set("minute", 0);

          onDepartChange(departure, 0);
        }
      }

      if (index !== null) {
        form.setFieldValue(`Schedule#${index}`, "");
      }

      let pickup = form.getFieldValue(`PickupLocation#${index}`);
      //if it's the last route before the end
      if (pickup === value && !isForHoist && !hoistRoutes.includes(index - 1)) {
        message.error("Pickup and drop off cannot be the same!", 6);
        form.setFieldValue(`DropOffLocation#${index}`, "");
        return;
      } else if (
        value ===
        form.getFieldValue(
          `${c ? "Return" : ""}DropOffLocation#${c ? "" : index + 1}`
        )
      ) {
        message.error(
          "Drop off cannot be the same the next route's drop off!",
          6
        );
        form.setFieldValue(`DropOffLocation#${index}`, "");
        return;
      }

      form.setFieldsValue({
        ...nullifyAllTimes(
          index === null
            ? null
            : inversePopoverCondition
            ? c
              ? routeLength
              : index + 1
            : index
        ),
      });

      if (!selectionFromRequest && index !== null) {
        form.setFieldValue(
          `${c ? "Return" : ""}PickupLocation#${c ? "" : index + 1}`,
          value
        );
      }

      if (index !== null) {
        if (!inversePopoverCondition) {
          if (!!form.getFieldValue(`DepartAt#${index}`)) {
            setRoutePopover(`trafficArrive#${index}`);
          }
        } else {
          setRoutePopover(`trafficDepart#${index}`);
        }
      } else {
        setReturnPopover("trafficArrive");
      }
      //we cause a re-render of the return so it gets the new route widths
      setFieldChanged(!fieldChanged);
    }

    //#region DEPART UPDATE
    function departUpdate(index) {
      setRouteTime({ ...routeTime, time: "" });
      setRoutePopover(`trafficArrive#${index}`);
      updateDurationHandler("route");
    }

    //#region ON DEPART CHANGE
    function onDepartChange(val, index) {
      let value = null;

      if (!!prevTime?.depart) {
        value = setDateMonth(parseInTz(val), dayjsNY(prevTime.depart));
      } else {
        value = setHourMinute(prevDate, parseInTz(val));
      }

      form.setFieldsValue({
        [`DepartAt#${index}`]: value,
        [`ArriveBy#${index}`]: null,
        [`TimeExit#${index}`]: null,
      });
      if (!!form.getFieldValue(`DropOffLocation#${index}`)) {
        if (
          index > 0 &&
          dayjsNY(form.getFieldValue(`DepartAt#${index}`)).valueOf() <
            dayjsNY(form.getFieldValue(`ArriveBy#${index - 1}`)).valueOf()
        ) {
          let prevEditBlock = editBlock;
          setEditBlock(routeLength);
          getNextDayErrorNotification({
            index,
            prevEditBlock,
            setEditBlock,
            handler() {
              form.setFieldValue(
                `DepartAt#${index}`,
                dayjsNY(value).add(1, "d")
              );
              form.setFieldsValue(
                changeRouteDates({
                  existingFields: form.getFieldsValue(),
                  index,
                  routeLength,
                })
              );
              setAcceptNextDay(index - 1);
              departUpdate(index);
            },
            cancelHandler() {
              form.setFieldsValue({
                [`DepartAt#${index}`]: prevTime.depart,
                [`ArriveBy#${index}`]: prevTime.arrive,
                [`TimeExit#${index}`]: prevTime.exit,
              });
            },
          });
          return;
        } else if (index > 0) {
          form.setFieldValue(`TimeExit#${index - 1}`, value);
        }
      }
      departUpdate(index);
    }

    //#region ON EXIT CHANGE
    function onExitChange(val, index) {
      let value = null;
      if (!!prevTime?.exit) {
        value = setDateMonth(parseInTz(val), dayjsNY(prevTime.exit));
      } else {
        value = setHourMinute(prevDate, parseInTz(val));
      }

      let c = index === routeLength - 1;

      form.setFieldsValue({
        [`TimeExit#${index}`]: value,
        [`${c ? "Return" : ""}DepartAt#${c ? "" : index + 1}`]: value,
      });

      let routeArrive = form.getFieldValue(`ArriveBy#${index}`);
      if (dayjsNY(routeArrive).unix() > dayjsNY(value).unix()) {
        let prevEditBlock = editBlock;
        setEditBlock(routeLength);
        getNextDayErrorNotification({
          index: c ? null : index + 1,
          prevEditBlock,
          setEditBlock,
          handler() {
            form.setFieldsValue({
              ...changeRouteDates({
                existingFields: form.getFieldsValue(),
                index: c ? null : index + 1,
                routeLength,
              }),
              [`TimeExit#${c ? "" : index}`]: dayjsNY(value).add(1, "d"),
              [`${c ? "Return" : ""}DepartAt#${c ? "" : index + 1}`]: dayjsNY(
                value
              ).add(1, "d"),
              [`${c ? "Return" : ""}ArriveBy#${c ? "" : index + 1}`]: null,
            });
            setAcceptNextDay(index);
            if (!c) {
              onDepartChange(dayjsNY(value).add(1, "d"), index + 1);
            } else {
              setReturnTime({ ...returnTime, time: "" });
              setReturnPopover("trafficArrive");
            }
          },
          cancelHandler() {
            form.setFieldsValue({
              [`DepartAt#${index}`]: prevTime.depart,
              [`ArriveBy#${index}`]: prevTime.arrive,
              [`TimeExit#${index}`]: prevTime.exit,
            });
          },
        });
        return;
      }

      form.setFieldsValue({
        [`${c ? "Return" : ""}DepartAt#${c ? "" : index + 1}`]: value,
        [`${c ? "Return" : ""}ArriveBy#${c ? "" : index + 1}`]: null,
      });
      if (!c) {
        onDepartChange(value, index + 1);
      } else {
        setReturnTime({ ...returnTime, time: "" });
        setReturnPopover("trafficArrive");
      }
    }

    //#region ON ARRIVAL CHANGE
    /**
     * Function that handles the update of the arrival time for the ROUTE
     * @param {Dayjs} val The new arrival time
     * @param {Number} index The caller route index
     */
    function onArrivalChange(val, index) {
      let value = null;
      if (!!prevTime?.arrive) {
        value = setDateMonth(parseInTz(val), dayjsNY(prevTime?.arrive));
      } else {
        value = setHourMinute(prevDate, parseInTz(val));
      }

      let c = index === routeLength - 1;
      let exit = dayjsNY(value).add(1, "h");
      form.setFieldsValue({
        [`ArriveBy#${index}`]: value,
        [`TimeExit#${index}`]: exit,
        [`DepartAt#${index}`]: null,
      });

      if (!!form.getFieldValue(`DropOffLocation#${index}`)) {
        if (
          !!validateOverlapping({
            caller: "route",
            filteredDispatches,
            form,
            drivers,
            index,
            selector: null,
            setSwitchDriver,
            prevDriver,
          })
        ) {
          form.setFieldValue(`TimeExit#${routeLength - 1}`, null);
          form.setFieldValue(`ArriveBy#${routeLength - 1}`, null);
        } else {
          form.setFieldsValue({
            [`${c ? "Return" : ""}DepartAt#${c ? "" : index}`]: null,
            [`${c ? "Return" : ""}DepartAt#${c ? "" : index + 1}`]: exit,
            [`${c ? "Return" : ""}ArriveBy#${c ? "" : index + 1}`]: null,
          });
          setRouteTime({ ...routeTime, index, time: "" });
          setRoutePopover(`trafficDepart#${index}`);
        }
      }
    }

    //#region DRIVER SELECT HELPER
    function selectHelper(index) {
      driverSelect(
        index,
        form,
        routeLength,
        filteredDispatches,
        "driver",
        drivers,
        setSwitchDriver,
        prevDriver
      );
      setFieldChanged(!fieldChanged);
    }

    //#region ON DRIVER SELECT
    function onDriverSelect(val, index) {
      let baseSelector = index === null ? "Return" : "";
      let indexSelector = index === null ? "" : index;
      let thisDriverId = form.getFieldValue(
        `${baseSelector}Driver#${indexSelector}`
      );
      let thisDriver = drivers?.find(
        (el) => el?.driverId === thisDriverId
      )?.driverName;
      let prev = drivers?.find((el) => el?.driverId === prevDriver)?.driverName;
      /**
       * When selecting a driver, we need to make sure that the driver is free
       * for the duration of the dispatch. The driver is free if they finished their routes
       * but the time they're required to come to the location, or if the driver does't start
       * until the end of this dispatch
       */
      let thisDepart = null;
      if (!!form.getFieldValue(`${baseSelector}DepartAt#${indexSelector}`)) {
        thisDepart = dayjsNY(
          form.getFieldValue(`${baseSelector}DepartAt#${indexSelector}`)
        ).valueOf();
      }
      let dispatchArrive = undefined;
      if (!!form.getFieldValue("ReturnArriveBy#")) {
        dispatchArrive = dayjsNY(
          form.getFieldValue(`ReturnArriveBy#`)
        ).valueOf();
      } else if (
        !!form.getFieldValue(`${baseSelector}ArriveBy#${indexSelector}`)
      ) {
        dispatchArrive = dayjsNY(
          form.getFieldValue(`${baseSelector}ArriveBy#${indexSelector}`)
        ).valueOf();
      } else if (!!thisDepart) {
        dispatchArrive = thisDepart;
      }

      let routesInvolvingDriver = filteredDispatches?.filter(
        (el) =>
          el?.driverId === thisDriverId || el?.passengerId === thisDriverId
      );
      let routesFromYard = routesInvolvingDriver?.filter((routes) =>
        yards.includes(routes?.pickupLocation)
      );
      let routesToYard = routesInvolvingDriver?.filter((routes) =>
        yards.includes(routes?.dropOffLocation)
      );

      //this checks if a driver has an overlapping dispatch (not route)
      let canBeSelected = false;
      if (
        !routesFromYard?.every((route) => route?.firstExit >= dispatchArrive)
      ) {
        if (routesToYard?.every((route) => route?.lastEntry < thisDepart)) {
          canBeSelected = true;
        }
      } else {
        canBeSelected = true;
      }

      if (!prevDriver) {
        canBeSelected = true;
      }

      /**
       * If there is an overlapping dispatch, the "canBeSelected" is false,
       * however, the overlapping dispatch may have a route to the same location
       * which makes the driver selectable only if switched
       */
      let switchCondition =
        !!prevDriver &&
        routesInvolvingDriver?.some(
          (route) =>
            route?.pickupLocation ===
            form.getFieldValue(`${baseSelector}PickupLocation#${indexSelector}`)
        );

      let prevEditBlock = editBlock;

      let conflict = validateOverlapping({
        caller: index === null ? "return" : "route",
        filteredDispatches,
        form,
        index,
        drivers,
        selector: "driver",
        setSwitchDriver,
        fromManualSelection: true,
        prevDriver,
        canBeSelected,
        prevEditBlock,
        setEditBlock,
      });

      if (!conflict) {
        //we need to predict the switch condition, since if the condition
        //is true, then the user has already been notified of a possible switch
        if (canBeSelected && !switchCondition && !!prevDriver) {
          setEditBlock(routeLength);
          getMandatoryNotification({
            index,
            form,
            prevDriver,
            thisDriver,
            prev,
            selectHelper(i) {
              setEditBlock(i);
              selectHelper(i, false);
            },
            complementaryRoutes,
            setComplementaryRoutes,
            listOfLabels,
            thisDriverId,
            schedulesForDate,
            allProjects,
            switchDriversHandler,
            prevEditBlock,
            setEditBlock,
            routeLength,
          });
        } else if (!canBeSelected && !switchCondition) {
          setEditBlock(routeLength);
          getDriverErrorNotification({
            index,
            switchCondition,
            drivers,
            driver: thisDriverId,
            setSwitchDriver,
            canBeSelected,
            setEditBlock,
            prevEditBlock,
          });
          if (prevDriver) {
            form.setFieldValue(
              `${baseSelector}Driver#${indexSelector}`,
              prevDriver
            );
          }
        } else if (switchCondition) {
          if (prevDriver) {
            form.setFieldValue(
              `${baseSelector}Driver#${indexSelector}`,
              prevDriver
            );
          } else {
            form.setFieldValue(
              `${baseSelector}ArriveBy#${indexSelector}`,
              null
            );
          }
          setEditBlock(prevEditBlock);
        } else if (!prevDriver) {
          selectHelper(index);
        }
      } else {
        if ((!switchCondition || !canBeSelected) && !!prevDriver) {
          form.setFieldValue(
            `${baseSelector}Driver#${indexSelector}`,
            prevDriver
          );
        } else {
          form.setFieldValue(`${baseSelector}DepartAt#${indexSelector}`, null);
        }
      }
    }

    //#region ON CARGO CHANGE
    function onCargoChange({
      value: e,
      index,
      addRouteLength = false,
      reduceRouteLength = false,
    }) {
      let val = typeof e === "string" ? e : e.target.value;
      let i = index === null ? routeLength : index;
      let rl = routeLength;
      if (addRouteLength) {
        rl++;
      } else if (reduceRouteLength) {
        rl--;
      }

      let newValues = changeCargoStates(i, rl, val);

      //when adding a new route on index, we do not
      //want to overwrite the selected paperwork
      if (addRouteLength) {
        delete newValues[`PaperworkType#${index}`];
      }

      form.setFieldsValue(newValues);
      if (!!index && !reduceRouteLength && !addRouteLength) {
        let valueAbove = form.getFieldValue(`Cargo#${index - 1}`);
        if (val === "Loaded" && val === valueAbove) {
          form.setFieldValue(`PaperworkType#${index}`, "");
          getSameCargoWarning(index);
        }
      }

      setFieldChanged(!fieldChanged);
    }

    //#region REMOVE ROUTE HANDLER
    function removeRouteHandler({
      index,
      createGhost = false,
      openSuggestions = true,
    }) {
      /**
       * Since when a route is removed, all the routes of the dispatch have
       * their times reduced,
       */
      let newFields = removeRoute(
        index,
        routeLength,
        form.getFieldsValue(),
        createGhost,
        cancelledRoutes,
        rowObject
      );
      form.setFieldsValue({ ...newFields });

      if (index > 0 && routeLength > 1) {
        let t = [...listOfLabels];
        t.splice(index, 1);

        let d = [...listOfDistances];
        d.splice(index, 1);

        //regulates variance routes
        setVarianceRoutes((prev) =>
          prev.flatMap((i) => (i > index ? --i : i === index ? [] : i))
        );

        //regulates the hoist indexes
        let h = [...hoistRoutes];
        let indexInArr = h?.findIndex((el) => el === index);
        if (indexInArr > -1) {
          h.splice(indexInArr, 1);
        }
        h = h.map((i) => {
          if (i >= index) {
            return i - 1;
          } else {
            return i;
          }
        });
        setHoistRoutes(h);

        setItineraryData((prev) => {
          return Object.keys(prev).reduce(
            (acc, val) => ({
              ...acc,
              ...(+val >= index ? {} : { [val]: prev[val] }),
            }),
            {}
          );
        });

        let s = requestSelections
          ?.map((el) => {
            if (el?.index > index) {
              return {
                ...el,
                index: el?.index - 1,
              };
            } else if (el?.index < index) {
              return el;
            }
          })
          .filter(Boolean);

        //if there is no drop off after the route remove
        //it means that the route routes would conflict,
        //so we need to update the request selection states
        if (!newFields[`DropOffLocation#${index}`]) {
          s = s?.filter((el) => el?.index !== index);
        }
        setRequestSelections(s);

        onCargoChange({
          value: form.getFieldValue(`Cargo#${index - 1}`),
          index: index - 1,
          reduceRouteLength: true,
        });

        //if the deleted route is adjacent to the return field
        if (index === routeLength - 1) {
          if (
            !!form.getFieldValue(`ReturnPickupLocation#`) &&
            openSuggestions
          ) {
            setReturnPopover("trafficArrive");
          }
        } else {
          if (
            !!form.getFieldValue(`DropOffLocation#${index}`) &&
            openSuggestions
          ) {
            setRoutePopover(`trafficArrive#${index}`);
          } else {
            t[index] = dropOffBaseHTML;
            d[index] = 0;
          }
        }

        setListOfLabels(t);
        setListOfDistances(d);
        setRouteLength(routeLength - 1);
      } else {
        setFieldChanged(!fieldChanged);
      }
    }

    //#region HOUR VALIDATOR HELPER
    /**
     * Helper function for the disabled hours validator.
     * Checks if the dispatch has finished on the same hour but has minutes of difference
     * @param {Number} toValidate The timestamp of the hour to validate
     * @param {Number} lastEntry The timestamp of the last entry
     * @returns Boolean
     */
    function hourValidatorHelper(toValidate, lastEntry) {
      if (dayjsNY(lastEntry).hours() === dayjsNY(toValidate).hours()) {
        return false;
      } else if (toValidate <= lastEntry) {
        return true;
      }
      return false;
    }

    //#region GET DISABLED HOURS
    /**
     * Function that eliminates the disabled hours from the routes times
     * @param {String} selector Differentiates between route caller and return caller "route" | "return"
     * @param {Number} index The caller route index
     */
    function getDisabledHours(selector, index = 0) {
      if (filteredDispatches?.length === 0) {
        return [];
      }
      let c = selector === "return";
      //this function always targets the active route since the previous
      //ones cannot be selected anymore
      let driver =
        form.getFieldValue(`${c ? "Return" : ""}Driver#${!c ? index : ""}`) ??
        "";

      let passenger = null;
      if (
        !!form.getFieldValue(
          `${c ? "Return" : ""}Passenger#${index}${!c ? index : ""}`
        )
      ) {
        passenger = form.getFieldValue(`Passenger#${index}`);
      }

      let fleet = form.getFieldValue("truckNumber") ?? "";
      let selectedDate = dayjs.tz(form.getFieldValue("date")).startOf("day");
      return [...new Array(24).keys()].filter((i) => {
        let tmpH = dayjsNY(selectedDate).add(i, "h").valueOf();
        //we filter the selected date's dispatches based on the selected fleet and driver
        return filteredDispatches
          ?.filter(
            (el) =>
              (el?.fleetId === fleet && !!fleet) ||
              (el?.driverId === driver && !!driver) ||
              (el?.driverId === passenger && !!passenger) ||
              (el?.passengerId === driver && !!driver) ||
              (el?.passengerId === passenger && !!passenger)
          )
          ?.some(
            (el) =>
              tmpH >= el?.firstExit && hourValidatorHelper(tmpH, el?.lastEntry)
          );
      });
    }

    //#region GET DISABLED MINUTES
    /**
     * Function that eliminates the disabled minutes for every hour selected
     * @param {Number} selectedHour The selected hour 0-24
     * @param {String} selector Differentiates between route caller and return caller "route" | "return"
     * @param {Number} index The caller route index
     */
    function getDisabledMinutes(selectedHour, selector, index = 0) {
      if (filteredDispatches?.length === 0) {
        return [];
      }

      let c = selector === "return";
      let driver =
        form.getFieldValue(`${c ? "Return" : ""}Driver#${!c ? index : ""}`) ??
        "";

      let passenger = null;
      if (!!form.getFieldValue(`Passenger#${index}`)) {
        passenger = form.getFieldValue(`Passenger#${index}`);
      }

      let fleet = form.getFieldValue("truckNumber") ?? "";
      let selectedDate = dayjs
        .tz(form.getFieldValue("date"))
        .startOf("day")
        .add(selectedHour, "h");

      return [...new Array(60).keys()].filter((i) => {
        let tmpM = dayjsNY(selectedDate).add(i, "m").valueOf();

        return filteredDispatches
          ?.filter(
            (el) =>
              (el?.fleetId === fleet && !!fleet) ||
              (el?.driverId === driver && !!driver) ||
              (el?.driverId === passenger && !!passenger) ||
              (el?.passengerId === driver && !!driver) ||
              (el?.passengerId === passenger && !!passenger)
          )
          ?.some((el) => tmpM >= el?.firstExit && tmpM <= el?.lastEntry);
      });
    }

    //#region ON SCHEDULE SELECT
    /**
     * Function that handles the schedule change for every route.
     * Extra validation is needed for the first route.
     * @param {String} val The selected schedule Id
     * @param {Number} index The caller route index
     * @param {Boolean} defaultCondition Used when there are requests
     * @param {Boolean} changeCargo
     */
    function onScheduleSelect(
      val,
      index,
      defaultCondition = false,
      changeCargo = true
    ) {
      let schedule = schedulesForDate?.find((el) => el?.scheduleId === val);
      let condition = defaultCondition;
      let c = index === routeLength - 1;
      const isForHoist = hoistRoutes.includes(index);

      if (
        val?.trim() !==
        (
          form.getFieldValue(
            `${c ? "Return" : ""}PickupLocation#${c ? "" : index + 1}`
          ) || ""
        )?.trim()
      ) {
        if (`${index + 1}` in itineraryData) {
          message.warning({
            content: `Itinerary was cleared from ${
              c ? "Return" : "Route " + String(index + 2)
            }`,
            key: `clearItinerary${c ? "Return" : index + 2}`,
          });

          setItineraryData((prev) => {
            const tmp = { ...prev };
            delete tmp[`${index + 1}`];

            return tmp;
          });
        }
      }

      //on schedule select we need to check the pickup of the route
      //and and the drop off of the next in order to not conflict with the
      if (
        schedule?.scheduleAddress ===
          form.getFieldValue(`PickupLocation#${index}`) &&
        !isForHoist &&
        !hoistRoutes.includes(index - 1)
      ) {
        message.error("Locations can not be the same!", 6);
        form.setFieldValue(`Schedule#${index}`, "");
        return;
      } else if (
        schedule?.scheduleAddress ===
        form.getFieldValue(
          `${c ? "Return" : ""}DropOffLocation#${c ? "" : index + 1}`
        )
      ) {
        message.error(
          "Drop off location cannot be the same as the next route's drop off location!",
          6
        );
        form.setFieldValue(`Schedule#${index}`, "");
        return;
      }

      //the first route needs to take the time suggestions of the schedule start
      //if this dispatch is the first dispatch of the day to the schedule
      if (index === 0) {
        let selectedDate = dayjs(form.getFieldValue("date")).format(dateFormat);
        //we check if there are any previous dispatches to the schedule
        //if there are, we want to check if any of the route's arrive time matches the schedule start
        let r = filteredDispatches?.filter((el) => el?.scheduleId === val);
        let scheduleDay = schedule?.scheduleDays?.find(
          (el) => dayjsNY(el?.startDate).format(dateFormat) === selectedDate
        );

        if (
          r?.length === 0
          // ||
          // !r?.some(
          //   (el) => el?.lastEntry === dayjsNY(scheduleDay?.startDate).valueOf()
          // )
        ) {
          condition = true;

          //* we set the departure time to 5:00 AM
          let departure = dayjsNY(scheduleDay?.startDate)
            .set("hour", 5)
            .set("minute", 0);
          onDepartChange(departure, index);
          //*

          //* we set the suggestion exit time
          // let suggestion = dayjsNY(scheduleDay?.startDate).add(1, "h");

          form.setFieldsValue({
            // "DepartAt#0": null,
            // "DepartAt#0": departure,
            // "ArriveBy#0": dayjsNY(scheduleDay?.startDate),
            // "TimeExit#0": suggestion,
            // "TimeExit#0": null,
            // [`${c ? "Return" : ""}DepartAt#${c ? "" : index + 1}`]: suggestion,
            [`${c ? "Return" : ""}PickupLocation#${c ? "" : index + 1}`]:
              schedule?.scheduleAddress,
          });
        }
      }

      let list = [...listOfLabels];
      list[index] = getLabelHTML("schedule", null, schedule?.scheduleName);
      setListOfLabels(list);

      //we set a suggestion for the cargo state depending on the schedule type of work
      let work = !changeCargo ? "" : schedule?.typeOfWork;
      if (work === "Installation" || work === "Deliver material") {
        onCargoChange({ value: "Loaded", index });
      } else if (work === "Removal" || work === "Pickup material") {
        onCargoChange({ value: "Empty", index });
      }

      form.setFieldsValue({
        [`Schedule#${index}`]: schedule?.scheduleId,
        [`DropOffLocation#${index}`]: schedule?.scheduleAddress,
        [`${c ? "Return" : ""}PickupLocation#${c ? "" : index + 1}`]:
          schedule?.scheduleAddress,
      });

      if (condition) {
        if (!!form.getFieldValue(`ArriveBy#${index}`)) {
          setRoutePopover(`trafficDepart#${index}`);
        }
      } else {
        if (!!form.getFieldValue(`DepartAt#${index}`)) {
          setRoutePopover(`trafficArrive#${index}`);
        }
      }
    }

    //#region ROUTES FOR DRIVER MODAL
    /**
     * Function that opens the roues modal for a selected driver
     */
    function onDriverShowSelect(value, index) {
      let routesInvolvingDriver = [];
      let truckId = trucks?.find((el) => el?.fleetName === value)?.fleetId;
      let driverId = null;
      if (!!truckId) {
        routesInvolvingDriver = filteredDispatches?.filter(
          (el) => el?.fleetId === truckId
        );
      } else {
        driverId = drivers?.find((el) => el?.driverName === value)?.driverId;
        routesInvolvingDriver = filteredDispatches?.filter(
          (el) => el?.driverId === driverId || el?.passengerId === driverId
        );
      }

      let existingFields = form.getFieldsValue();

      let t = getDriverRoutes(driverId, existingFields, routeLength, truckId);

      routesInvolvingDriver = routesInvolvingDriver
        ?.concat(t)
        ?.filter((el) => !!el?.firstExit && el?.firstExit !== "Invalid date");

      routesInvolvingDriver.sort((a, b) => a?.firstExit - b?.firstExit);

      form.setFieldValue(
        `DriverShow#${index !== null ? index : "Return"}`,
        null
      );

      if (routesInvolvingDriver?.length === 0) {
        message.warning("There are no routes to show");
        return;
      }

      let finalRoutes = routesInvolvingDriver?.reduce(
        (acc, val) => [
          ...acc,
          {
            ...val,
            fleetName: trucks?.find((el) => el?.fleetId === val?.fleetId)
              ?.fleetName,
            driverName: drivers?.find((el) => el?.driverId === val?.driverId)
              ?.driverName,
            passengerName: drivers?.find(
              (el) => el?.driverId === val?.passengerId
            )?.driverName,
            departAt: val?.firstExit,
            arriveBy: val?.lastEntry,
          },
        ],
        []
      );

      setRouteShower({
        driverId,
        truckId,
        routes: finalRoutes,
      });
    }

    //#region ON DROP OFF BLUR
    /**
     * Function that re-instates the form value of the drop off on blur
     * Useful for when schedules or projects were selected
     * @param {Number} index
     */
    function onDropOffBlur(index) {
      const c = index === null;

      let v = form.getFieldValue(
        `${c ? "Return" : ""}DropOffLocation#${c ? "" : index}`
      );
      if (!!v) {
        form.setFieldValue(
          `${c ? "Return" : ""}DropOffLocation#${c ? "" : index}`,
          v?.trim()
        );
      }
      if (dropOffAutoFocus) {
        setDropOffAutoFocus(false);
        setFieldChanged(!fieldChanged);
      }
    }

    //#region OPEN MAPS CARD
    async function openMapsCard(index) {
      let c = index === null;
      let origin = form.getFieldValue(
        `${c ? "Return" : ""}PickupLocation#${c ? "" : index}`
      );
      let destination = form.getFieldValue(
        `${c ? "Return" : ""}DropOffLocation#${c ? "" : index}`
      );
      if (!origin || !destination) {
        message.warning(
          "Both pickup and drop off locations need to be completed to open the map view"
        );
        return;
      }

      let itineraryRoute = itineraryData?.[index];
      if (!itineraryRoute) {
        itineraryRoute = Object.values(itineraryData).find((itinerary) => {
          const arr = [origin, destination];
          const arr2 = [
            itinerary[0]["pickUpLocation"],
            itinerary[itinerary.length - 1]["dropOffLocation"],
          ];

          return arr2.every((e) => arr.includes(e));
        });
      }

      const directionService = new window.google.maps.DirectionsService();
      let results;
      try {
        if (itineraryRoute) {
          results = await Promise.all(
            itineraryRoute.map((itinerary) =>
              directionService.route({
                destination: itinerary.dropOffCoordinates,
                origin: itinerary.pickUpCoordinates,
                travelMode: itinerary.travelMode,
                avoidFerries: false,
                avoidHighways: false,
                avoidTolls: false,
                waypoints: itinerary.waypoints.map(
                  ({ location, stopover }) => ({
                    location: new google.maps.LatLng(location),
                    stopover,
                  })
                ),
              })
            )
          );
        } else {
          results = await directionService.route({
            origin,
            destination,
            travelMode: window.google.maps.TravelMode.DRIVING,
            avoidFerries: false,
            avoidHighways: false,
            avoidTolls: false,
          });
        }
      } catch (err) {
        message.warning("Something went wrong while opening the map");
        console.log("Error opening map: ", err);
        return;
      }
      setMapsVisible(results);
    }

    //#region ROUTE CANCEL HANDLER
    function routeCancelHandler(index, forceSuggestion = false) {
      //when the cancel modal was called the cancel index was part of the cancel state
      //we simply extract the index
      let cancelIndex = null;
      if (!!cancelModalVisible) {
        cancelIndex = parseInt(cancelModalVisible?.split("#")?.[1]);
      } else {
        cancelIndex = index;
      }

      if (`${cancelIndex}` in itineraryData) {
        message.warning({
          content: `Itinerary was cleared from ${
            "Route " + String(cancelIndex + 1)
          }`,
          key: `clearItinerary${cancelIndex + 1}`,
        });

        setItineraryData((prev) => {
          const tmp = { ...prev };
          delete tmp[`${cancelIndex}`];

          return tmp;
        });
      }

      let existingFields = form.getFieldsValue();
      let tmp = [...cancelledRoutes];
      let i = tmp?.findIndex(
        (el) => el?.id === existingFields[`cancelId#${cancelIndex}`]
      );
      tmp[i] = { ...tmp[i], cancelled: true };
      tmp.sort((a, b) => a?.cancelIndex - b?.cancelIndex);
      setCancelledRoutes(tmp);
      removeRouteHandler({
        index: cancelIndex,
        createGhost: true,
        openSuggestions: !!cancelModalVisible || forceSuggestion,
      });
      setCancelModalVisible("");
    }

    //#region SCROLL TO CARD
    /**
     * Function that scrolls the container to a card
     * @param {String} caller "route" | "return"
     * @param {Number} index The index of the card
     */
    function scrollToCard(caller = "return", index = null) {
      let container = document.getElementById("overallRouteContainer");
      let t = null;
      if (caller === "route") {
        t = document.getElementsByClassName("can-flash").item(index);
        if (!!t && !!container) {
          let yPosition = t?.getBoundingClientRect()?.y;
          if (index !== 0 && yPosition > container.clientHeight) {
            container.scroll({
              left: 0,
              top: yPosition - 180,
              behavior: "smooth",
            });
          }
        }
      } else {
        t = document.getElementById("dispatchReturnCard");
        if (!!t && !!container) {
          let yPosition = t?.getBoundingClientRect()?.y;
          container.scroll({
            left: 0,
            top: yPosition + 230,
            behavior: "smooth",
          });
        }
      }
    }

    //#region REGULATE SWITCH ROUTE SUGGESTIONS
    /**
     * In case we're dealing with route switches, this function
     * trims the routeSwitched state
     */
    function regulateSwitchedRoutesSuggestions(index) {
      //the first element needs to have the same dispatch id
      if (
        cancelDisabledCondition &&
        routeSwitched?.[0]?.["dispatchId"] === rowObject?.dispatchId &&
        !!rowObject?.dispatchId
      ) {
        //we need to see if the suggestion is part of the
        //auto-suggestions from the driver switch
        let rs = structuredClone(routeSwitched[0]);
        let t = rs["routeChangeInfo"]?.findIndex((el) => el?.index === index);
        if (t > -1) {
          //we remove the duplicates from the affected dispatches
          let newAffected = structuredClone(affectedDispatches);
          let affectedIndex = newAffected?.findIndex(
            (el) => el?.dispatchId === rs?.dispatchId
          );
          if (affectedIndex > -1) {
            newAffected.splice(affectedIndex, 1);
            setAffectedDispatches(newAffected);
          }
          //if we find an element with the route index, we remove
          //the object from the routeChangeInfo array
          let newInfo = [...rs.routeChangeInfo];
          newInfo.splice(t, 1);
          if (newInfo?.length === 0) {
            //in this case all the suggestions for the dispatch are set
            //so we remove the element from the main array entirely
            let tmp = structuredClone(routeSwitched);
            setRouteSwitched(tmp.slice(1));
          } else {
            rs = { ...rs, routeChangeInfo: [...newInfo] };
            setRouteSwitched([rs, ...(routeSwitched?.slice(1) || [])]);
          }
        }
      }
    }

    //#region GET ELIGIBLE REQUESTS PER ROUTE
    /**
     * Function that filters out the eligible requests for each route
     */
    function getEligibleRequestsForRoute(index) {
      /**
       * the index is the of the route that renders the "Add" button
       * for a route to be able to accept a request it needs to fulfill
       * some conditions
       */
      let c = index === routeLength - 1;
      //when adding a route we always put the driver below
      let driverIdBelow = form.getFieldValue(
        `${c ? "Return" : ""}Driver#${c ? "" : index + 1}`
      );

      let thisLocation = (
        form.getFieldValue(`DropOffLocation#${index}`) || ""
      )?.trim();

      let locationBelow = (
        form.getFieldValue(
          `${c ? "Return" : ""}DropOffLocation#${c ? "" : index + 1}`
        ) || ""
      )?.trim();

      //we need to add a condition if there are eligible requests, however the
      //route extends to the next day
      let dateToConsider = null;
      if (!!form.getFieldValue(`TimeExit#${index}`)) {
        dateToConsider = dayjsNY(
          form.getFieldValue(`TimeExit#${index}`)
        ).format(dateFormat);
      } else if (!!form.getFieldValue(`ArriveBy#${index}`)) {
        dateToConsider = dayjsNY(
          form.getFieldValue(`ArriveBy#${index}`)
        ).format(dateFormat);
      } else if (!!form.getFieldValue(`DepartAt#${index}`)) {
        dateToConsider = dayjsNY(
          form.getFieldValue(`DepartAt#${index}`)
        ).format(dateFormat);
      }

      return eligibleRequests?.filter(({ requestId, requestObject }) => {
        let { driver, jobsiteAddress, dateOnSite } = requestObject;

        if (!!dateToConsider) {
          if (dateToConsider !== dayjsNY(dateOnSite).format(dateFormat)) {
            return false;
          }
        }

        if (!requestSelections?.find((e) => e?.requestId === requestId)) {
          if (
            (driver === driverIdBelow || !driver || !driverIdBelow) &&
            jobsiteAddress !== thisLocation &&
            jobsiteAddress !== locationBelow
          ) {
            return true;
          }
        }
        return false;
      });
    }

    //#region ADD ROUTE
    function addRoute(index, addNew = null, hoist = false) {
      validateForm(
        form,
        () => {
          form.setFieldsValue(
            addRouteOnIndex(index, form.getFieldsValue(), routeLength, addNew)
          );
          //we move the indexes for the request selections
          let s = requestSelections?.map((el) => {
            if (el.index > index) {
              return {
                ...el,
                index: el?.index + 1,
              };
            } else {
              return el;
            }
          });
          if (!!addNew?.requestId) {
            s.push({
              index: addNew.index,
              address: addNew.address,
              requestId: addNew.requestId,
              cargoStatus: addNew.cargoStatus,
            });
          }

          if (Object.keys(itineraryData).find((key) => +key > index)) {
            message.warning({
              content: `Itinerary was cleared from routes below`,
              key: `clearItinerary`,
            });
          }

          setItineraryData((prev) => {
            return Object.keys(prev).reduce(
              (acc, val) => ({
                ...acc,
                ...(val > index ? {} : { [val]: prev[val] }),
              }),
              {}
            );
          });

          //we separate the list of labels
          let t = [...listOfLabels];
          t.splice(index + 1, 0, dropOffBaseHTML);
          setListOfLabels(t);

          setRequestSelections(s);

          //we separate the list of distances
          let d = [...listOfDistances];
          d.splice(index + 1, 0, 0);
          setListOfDistances(d);

          //regulates variance routes
          setVarianceRoutes((prev) => prev.map((i) => (i > index ? ++i : i)));

          //regulates the hoist indexes
          let h = hoistRoutes?.map((i) => {
            if (i > index) {
              return i + 1;
            } else {
              return i;
            }
          });
          if (hoist) {
            h = h.concat(index + 1);
          }
          setHoistRoutes(h);

          //add route length
          setRouteLength(routeLength + 1);
          setNewCreatedRouteIndex(index + 1);
          scrollToCard("route", index + 1);

          if (!addNew) {
            onCargoChange({
              value: form.getFieldValue(`Cargo#${index}`),
              index,
              addRouteLength: true,
            });
          }

          if (addNew) {
            setWaitForIndex({
              value: addNew.value,
              option: addNew.option,
              index: index + 1,
            });
          }
        },
        null,
        "",
        "all"
      );
    }

    //#region DROP OFF CLEAR
    function onDropOffClear(index) {
      let tmp = structuredClone(requestSelections);
      let selectionForIndex = tmp?.findIndex((el) => el?.index === index);
      if (selectionForIndex > -1) {
        tmp[selectionForIndex] = {
          requestId: "",
          address: "",
          cargoStatus: "",
          index,
        };
        form.setFieldValue(`NotesAndDetails#${index}`, "");
        setRequestSelections(tmp);
        setFieldChanged(!fieldChanged);
      }
    }

    //#region APPLY SCHEDULE TIME CHANGE
    /**
     * Function that applies the time changes from the schedule
     * @param {String} calledRouteId The cancel id of the route that called the function
     * @param {String} newTime The time of the change
     */
    function applyScheduleTimeChange(calledRouteId, newTime) {
      let callIndex = null;
      for (let i = 0; i < routeLength; i++) {
        if (form.getFieldValue(`cancelId#${i}`) === calledRouteId) {
          callIndex = i;
          break;
        }
      }

      let changes = { ...rowScheduleChanges };
      let tmp = changes[calledRouteId]?.map((el) => ({
        ...el,
        accepted: el?.action === "Start Time Change",
      }));
      changes[calledRouteId] = tmp;
      setRowScheduleChanges(changes);

      onArrivalChange(
        dayjs(
          `${dayjsNY(form.getFieldValue("date")).format(
            dateFormat
          )} ${newTime}`,
          `${dateFormat} HH:mm`
        ),
        callIndex
      );
    }

    /**
     * Holds the JSX of the route cards. Adds configuration to the suggestion popovers
     */
    const RouteCards = useMemo(() => {
      //#region ROUTE CARDS
      //a list of parameters used to render the correct route fields
      const routeParams = {
        onPickupLocationSelect,
        onDropOffSelect,
        onDriverSelect,
        onScheduleSelect,
        onDepartChange,
        onCargoChange,
        drivers: filterDrivers || drivers,
        onExitChange,
        schedulesForDate,
        onArrivalChange,
        form,
        projects: allProjects,
        onDropOffBlur,
        setPrevDriver,
        vendors,
        setPrevTime,
        dropOffInitialValue:
          defaultData?.schedule?.scheduleAddress ?? undefined,
        dropOffSelectAfterForceCancel,
        onDropOffClear,
        dropOffAutoFocus,
        hoistRoutes,
        isDarkMode,
        setOpenItinerary,
        garageAddresses,
        addressesField,
      };

      return [...new Array(routeLength)].map((_, i) => {
        //#region ROUTE -- CARD ARRAY MAP
        let c = i === routeLength - 1;
        let dropOffDisabledCondition =
          form.getFieldValue(`Driver#${i}`) !==
          form.getFieldValue(`${c ? "Return" : ""}Driver#${c ? "" : i + 1}`);

        let driverIsSwitchedCondition = false;
        for (let k = i; k > 0; k--) {
          if (
            form.getFieldValue(`Driver#${k}`) !==
            form.getFieldValue(`Driver#${k - 1}`)
          ) {
            driverIsSwitchedCondition = true;
            break;
          }
        }

        let possibleRequestsToRender = getEligibleRequestsForRoute(i);
        let approvalOptions = [];
        let approvalSelectedCondition = requestSelections?.find(
          (el) => el.index === i
        );
        if (!!approvalSelectedCondition) {
          approvalOptions = possibleRequestsToRender;
        }

        if (i === 0) {
          approvalOptions = possibleRequestsToRender;
          approvalSelectedCondition = false;
        }

        let cannotCancelCondition = false;
        if (dropOffDisabledCondition) {
          let driverForRoute = form.getFieldValue(`Driver#${i}`);
          let nextDriver = form.getFieldValue(
            `${c ? "Return" : ""}Driver#${c ? "" : i + 1}`
          );
          if (i !== 0) {
            let driverAbove = form.getFieldValue(`Driver#${i - 1}`);
            if (
              driverAbove !== driverForRoute &&
              driverForRoute !== nextDriver
            ) {
              cannotCancelCondition = true;
            }
          }
        }
        if (!cannotCancelCondition) {
          let driverForRoute = form.getFieldValue(`Driver#${i}`);
          let nextDriver = form.getFieldValue(
            `${c ? "Return" : ""}Driver#${c ? "" : i + 1}`
          );
          let thisDepart = null;
          let nextDepart = null;
          try {
            thisDepart = dayjsNY(
              form.getFieldValue(`DepartAt#${i}`)
            )?.valueOf();
            nextDepart = dayjsNY(
              form.getFieldValue(
                `${c ? "Return" : ""}DepartAt#${c ? "" : i + 1}`
              )
            )?.valueOf();
          } catch {}
          if (!!thisDepart && !!nextDepart) {
            let routesForThisDriver = filteredDispatches?.filter(
              (el) =>
                el?.driverId === driverForRoute && el?.firstExit > thisDepart
            );
            let routesForNextDriver = filteredDispatches?.filter(
              (el) => el?.driverId === nextDriver && el?.firstExit < nextDepart
            );
            routesForNextDriver?.reverse();
            routesForThisDriver = routesForThisDriver?.find(
              (el) =>
                el?.pickupLocation ===
                form.getFieldValue(`DropOffLocation#${i}`)
            );
            routesForNextDriver = routesForNextDriver?.find(
              (el) =>
                el?.dropOffLocation ===
                form.getFieldValue(`DropOffLocation#${i}`)
            );
            if (!!routesForNextDriver && !!routesForThisDriver) {
              if (
                routesForNextDriver?.dispatchId !==
                routesForThisDriver?.dispatchId
              ) {
                cannotCancelCondition = true;
              }
            }
          }
        }

        setTimeout(() => {
          //we flash the new created card
          let flashCard = document.getElementsByClassName("can-flash");
          if (newCreatedRouteIndex === i) {
            flashCard
              .item(newCreatedRouteIndex)
              .animate(
                flashAnimationFrames[isDarkMode ? "dark" : "light"],
                flashAnimationOptions
              );
            setNewCreatedRouteIndex(null);
          }
        }, 50);

        if (
          defaultData &&
          !defaultScheduleSelected &&
          schedulesForDate?.length
        ) {
          if (!!defaultData?.schedule?.scheduleAddress) {
            setTimeout(() => {
              setDefaultScheduleSelected(true);
              let a = defaultData?.schedule?.scheduleAddress + " ";
              onDropOffSelect(a, 0, { value: a, label: a.trim() });
              if (!!defaultData?.driverId) {
                form.setFieldValue("Driver#0", defaultData?.driverId);
                form.setFieldValue("truckNumber", defaultData?.fleetId);
                onDriverSelect(defaultData?.driverId, 0);
              }
            }, 0);
          }
        }

        if (dropOffSelectAfterForceCancel === i) {
          scrollToCard("route", i);
        }

        setDropOffAutoFocus(false);

        let newRouteCondition = i > 0 && routeLength > 1;
        let cancelElement = cancelledRoutes?.find(
          (el) => el?.cancelIndex === i
        );

        const scheduleChangesToShow = rowScheduleChanges?.[
          form.getFieldValue(`cancelId#${i}`)
        ]?.filter((change) => {
          if (
            form.getFieldValue(`DropOffLocation#${i}`)?.trim() !==
            change?.scheduleAddress
          ) {
            return false;
          } else if (change?.accepted) {
            return false;
          }

          return true;
        });

        //#region ROUTE -- RETURN
        return (
          <>
            {!!cancelElement?.cancelled && (
              <GhostRoute
                {...{
                  index: cancelElement?.cancelIndex,
                  form,
                  cancelObject: cancelElement,
                  drivers,
                }}
              />
            )}
            <div className="dispatchRouteCard can-flash">
              {newRouteCondition &&
                i > editBlock &&
                !form.getFieldValue(`cancelId#${i}`) && (
                  <Tooltip trigger="hover" title="Remove Route">
                    <div
                      data-testid={`remove-route-${i}`}
                      className="removeDispatchRoute"
                      onClick={() => removeRouteHandler({ index: i })}
                    >
                      <span>
                        <CloseOutlined />
                      </span>
                    </div>
                  </Tooltip>
                )}
              <div className="cardTitle">{`Route ${i + 1}`}</div>
              {!!form.getFieldValue(`cancelId#${i}`) && i > editBlock ? (
                <Tooltip
                  trigger={["hover", "click"]}
                  title={
                    cannotCancelCondition
                      ? "Route can not be cancelled since more than two dispatches depend on this route to make driver switches"
                      : dropOffDisabledCondition
                      ? "Careful, this route is required for a driver switch!"
                      : undefined
                  }
                >
                  {/**
                   //#region CANCEL BUTTON
                   */}
                  <Button
                    danger
                    type="primary"
                    data-testid={`cancel-route-${i}`}
                    disabled={cannotCancelCondition}
                    style={{
                      position: "relative",
                      top: "28px",
                      borderRadius: "5px",
                      marginRight: "1rem",
                      height: "33px",
                    }}
                    onClick={() => {
                      if (dropOffDisabledCondition) {
                        let prevEditBlock = editBlock;
                        setEditBlock(routeLength);
                        getRouteCancelOnSwitchNotification({
                          index: i,
                          setEditBlock,
                          prevEditBlock,
                          handler() {
                            setDropOffSelectAfterForceCancel(
                              i === 0 ? 0 : i - 1
                            );
                            setEditBlock(routeLength);
                            routeCancelHandler(i);
                          },
                        });
                      } else {
                        setCancelModalVisible(`cancelRoute#${i}`);
                      }
                    }}
                  >
                    Cancel Route
                  </Button>
                </Tooltip>
              ) : (
                <></>
              )}
              {/**
               //#region ROUTE FIELDS
               */}
              {RenderDynamicComponents(
                routeFields({
                  ...routeParams,
                  index: i,
                  itineraryData,
                  setFieldChanged,
                  fromApprovals: !!approvalSelectedCondition,
                  approvalOptions,
                  driverIsSwitchedCondition,
                  dropOffLabel: listOfLabels[i],
                  globalDisabled: i <= editBlock,
                  lastRouteOnEditBlock: i === editBlock,
                  dropOffDisabledCondition,
                  requestDisabledFields: !!requestSelections?.find(
                    ({ index }) => index === i
                  ),
                  disabledHours: () => getDisabledHours("route", i),
                  disabledMinutes: (selectedHour) =>
                    getDisabledMinutes(selectedHour, "route", i),
                  departPopoverVisible:
                    (!routeTime?.arrive &&
                      !!routeTime?.time &&
                      routeTime?.index === i) ||
                    (routePopover?.split("#")?.[0] === "trafficDepart" &&
                      routePopover?.split("#")?.[1] === i.toString()),
                  arrivePopoverVisible:
                    (!!routeTime?.arrive &&
                      !!routeTime?.time &&
                      routeTime?.index === i) ||
                    (routePopover?.split("#")?.[0] === "trafficArrive" &&
                      routePopover?.split("#")?.[1] === i.toString()),
                  PopoverContent: (
                    <PopoverSuggestion
                      {...{
                        visibleType: routePopover || routeTime?.time,
                        setTrafficModel(val) {
                          scrollToCard("route", i);
                          getSuggestionForRoute(
                            val,
                            routePopover?.split("#")?.[0],
                            "route",
                            i
                          );
                          setRoutePopover(null);
                        },
                        setSuggestion() {
                          //#region ROUTE -- POPOVER
                          let innerConflict = false;
                          if (routeTime.arrive) {
                            let c = i === routeLength - 1;
                            form.setFieldsValue({
                              [`ArriveBy#${i}`]: dayjsNY(routeTime?.time),
                            });
                            regulateSwitchedRoutesSuggestions(i);
                            //if the dispatch has been referenced multiple times during driver switches
                            //we want to maintain the synchronized departure times
                            if (
                              !openedDispatchForSwitchCondition ||
                              i !== editBlock
                            ) {
                              form.setFieldsValue({
                                [`TimeExit#${i}`]: dayjs
                                  .tz(routeTime?.time)
                                  .add(1, "h"),
                                [`${c ? "Return" : ""}DepartAt#${
                                  c ? "" : i + 1
                                }`]: dayjsNY(routeTime?.time).add(1, "h"),
                              });
                            } else {
                              // when switching drivers, we don't want the routes below to be affected
                              //if it's not necessary. Forcing the user to accept new times will cause
                              //wrong dispatches to be created
                              if (
                                dayjsNY(routeTime?.time)
                                  .add(1, "h")
                                  .valueOf() <=
                                dayjsNY(
                                  form
                                    .getFieldValue(
                                      `${c ? "Return" : ""}DepartAt#${
                                        c ? "" : i + 1
                                      }`
                                    )
                                    .valueOf() &&
                                    !openedDispatchForSwitchCondition
                                )
                              ) {
                                form.setFieldsValue({
                                  [`TimeExit#${i}`]: dayjs
                                    .tz(routeTime?.time)
                                    .add(1, "h"),
                                  [`${c ? "Return" : ""}DepartAt#${
                                    c ? "" : i + 1
                                  }`]: dayjsNY(routeTime?.time).add(1, "h"),
                                });
                              }
                            }
                          } else {
                            if (i > 0) {
                              let prevArrive = form.getFieldValue(
                                `ArriveBy#${i - 1}`
                              );
                              if (!prevArrive) {
                                prevArrive = form.getFieldValue(
                                  `DepartAt#${i - 1}`
                                );
                              }
                              if (
                                !!prevArrive &&
                                reverseCalcStart === -1 &&
                                dayjsNY(prevArrive).unix() >
                                  dayjsNY(routeTime?.time).unix()
                              ) {
                                message.error(
                                  "Truck can not depart quicker than the previous route's arrival time!"
                                );
                                form.setFieldValue(`TimeExit#${i - 1}`, null);
                                setRoutePopover(null);
                                setRouteTime({ ...routeTime, time: "" });
                                return;
                              }
                              form.setFieldValue(
                                `TimeExit#${i - 1}`,
                                dayjsNY(routeTime?.time)
                              );

                              //these are the conditions when calculating in reverse
                              if (!!prevArrive && reverseCalcStart > -1) {
                                //we take what would be the arrival time for this route's depart suggestion
                                let scheduleArriveForPrevRoute = dayjsNY(
                                  routeTime?.time
                                )
                                  .subtract(1, "h")
                                  .valueOf();

                                if (
                                  scheduleArriveForPrevRoute <
                                  dayjsNY(prevArrive).valueOf()
                                ) {
                                  innerConflict = true;
                                  form.setFieldValue(`DepartAt#${i - 1}`, null);
                                  form.setFieldValue(
                                    `ArriveBy#${i - 1}`,
                                    dayjsNY(scheduleArriveForPrevRoute)
                                  );
                                }
                              } else {
                                innerConflict = true;
                              }
                            }
                            form.setFieldValue(
                              `DepartAt#${i}`,
                              dayjsNY(routeTime?.time)
                            );
                          }
                          if (!!i) {
                            form.setFieldValue(
                              `TimeExit#${i - 1}`,
                              form.getFieldValue(`DepartAt#${i}`)
                            );
                          }
                          let overlapRes = validateOverlapping({
                            caller: "route",
                            filteredDispatches,
                            form,
                            drivers,
                            index: i,
                            selector: null,
                            setSwitchDriver,
                            prevDriver,
                          });
                          //if the new set time overlaps with a route for whatever reason we want to clear the times
                          //and make the user select a different one
                          if (!!overlapRes) {
                            form.setFieldsValue({
                              [`ArriveBy#${i}`]: null,
                              [`TimeExit#${i}`]: null,
                            });
                            setRoutePopover(null);
                            setRouteTime({ ...routeTime, time: "" });
                            return;
                          }

                          //we open a suggestion popup for the return only on valid dates
                          updateDurationHandler("route", i);
                          //we want to open the popover of the next rote until the return
                          // setRoutePopover(`trafficArrive#${i + 1}`);
                          if (reverseCalcStart > -1) {
                            //this is the first condition of the index reset
                            if (i === 0 || !innerConflict) {
                              //when the routes are changed until we arrive at index 0
                              let condition =
                                reverseCalcStart === routeLength - 1;
                              if (condition) {
                                setReturnPopover("trafficArrive");
                                setRoutePopover(null);
                              } else {
                                setRoutePopover(
                                  `trafficArrive#${reverseCalcStart + 1}`
                                );
                              }
                              setReverseCalcStart(-1);
                            } else {
                              setRoutePopover(`trafficDepart#${i - 1}`);
                            }
                          } else {
                            /**
                             * when we are calculating the routes normally, we
                             * want to fill the empty value. If none of the times
                             * are set, then we want to stop the calculations
                             */
                            if (i !== routeLength - 1) {
                              if (
                                !form.getFieldValue(`DepartAt#${i + 1}`) &&
                                !!form.getFieldValue(`ArriveBy#${i + 1}`)
                              ) {
                                setRoutePopover(`trafficDepart#${i + 1}`);
                              } else if (
                                form.getFieldValue(`DepartAt#${i + 1}`)
                              ) {
                                setRoutePopover(`trafficArrive#${i + 1}`);
                              }
                            } else {
                              if (
                                !form.getFieldValue("ReturnDepartAt#") &&
                                !!form.getFieldValue("ReturnArriveBy#")
                              ) {
                                setReturnPopover("trafficDepart");
                              } else if (
                                form.getFieldValue("ReturnDepartAt#")
                              ) {
                                setReturnPopover("trafficArrive");
                              }
                              setRoutePopover(null);
                            }
                          }

                          setRouteTime({ ...routeTime, time: "" });
                        },
                        ignoreSuggestion() {
                          setRoutePopover(null);
                          setRouteTime({ ...routeTime, time: "" });
                        },
                        form,
                      }}
                    />
                  ),
                  paperworkPopoverVisible: paperworkPopoverVisible,
                  activePopoverIndex: activePopoverIndex,
                  onPaperworkPopoverVisible: (index) =>
                    handelPaperworkPopoverVisible(index),
                  onPaperworkUpdate: handlePaperworkUpdate,
                  onPaperworkCancel: handlePaperworkCancel,
                })
                  ?.filter(Boolean)
                  .map(({ baseFormName, ...el }) => ({
                    ...el,
                    formItemName: `${baseFormName}${i}`,
                  })),
                { form }
              )}
              {/**
               //#region ROUTE -- CARD FOOTER
               */}
              <div className="dispatchRouteDistance">
                {listOfDistances[i] !== 0 && !!listOfDistances[i] ? (
                  <div>
                    <span>Distance:&nbsp;</span>

                    <span style={{ color: "#ff4d4f" }}>{`${(
                      listOfDistances[i] * 0.000621371
                    )
                      .toFixed(1)
                      .toLocaleString()} mi`}</span>
                  </div>
                ) : (
                  <span></span>
                )}
                <div className="dispatchMapsShowButton">
                  {/** 
                    //#region HOIST CHECK
                  */}
                  <Form.Item name={`hoist-${i}`}>
                    <ReportCheck
                      label="Hoist"
                      dataTestId={`hoist-check-${i}`}
                      checked={hoistRoutes.includes(i)}
                      onChange={() => {
                        if (hoistRoutes.includes(i)) {
                          const dropOff = (
                            form.getFieldValue(`DropOffLocation#${i}`) || ""
                          )?.trim();
                          const prevDrop = (
                            form.getFieldValue(`DropOffLocation#${i - 1}`) || ""
                          )?.trim();
                          const nextDrop = (
                            form.getFieldValue(`DropOffLocation#${i + 1}`) || ""
                          )?.trim();

                          if (!dropOff) {
                            setHoistRoutes((prev) =>
                              prev.filter((e) => e !== i)
                            );
                            return;
                          }

                          if (
                            dropOff === nextDrop &&
                            !hoistRoutes.includes(i + 1)
                          ) {
                            message.warning({
                              content:
                                "Unable to remove hoist route, next route has the same drop off location",
                              key: "noRemoveHoist",
                            });
                            return;
                          }

                          if (
                            dropOff === prevDrop &&
                            !hoistRoutes.includes(i - 1)
                          ) {
                            message.warning({
                              content:
                                "Unable to remove hoist route, pick up and drop off locations are the same",
                              key: "noRemoveHoist",
                            });
                            return;
                          }
                          setHoistRoutes((prev) => prev.filter((e) => e !== i));
                        } else {
                          setHoistRoutes((prev) => prev.concat(i));
                        }
                      }}
                    />
                  </Form.Item>
                  {/** 
                    //#region VARIANCE CHECK
                  */}
                  <Form.Item name={`Variance#${i}`}>
                    <ReportCheck
                      label="Variance"
                      checked={varianceRoutes.includes(i)}
                      dataTestId={`variance-check-${i}`}
                      onChange={(checked) => {
                        if (checked) {
                          setVarianceRoutes(varianceRoutes.concat(i));
                        } else {
                          setVarianceRoutes(
                            varianceRoutes.filter((e) => e !== i)
                          );
                        }
                      }}
                    />
                  </Form.Item>
                  {/** 
                    //#region VARIANCE - VARIANCE Reasons & VARIANCE Notes
                  */}
                  {varianceRoutes.includes(i) &&
                    RenderDynamicComponents(
                      varianceField(
                        varianceReason,
                        setVarianceReason,
                        setVarianceNote,
                        i
                      ),
                      {
                        form,
                      }
                    )}
                  {/**
                 //#region ROUTE -- DRIVER ROUTES
                 */}
                  {RenderDynamicComponents(
                    driverShowField(
                      drivers,
                      trucks,
                      form,
                      onDriverShowSelect,
                      i
                    ),
                    { form }
                  )}
                  {/**
                   //#region ROUTE -- SCHEDULE CHANGES
                   */}
                  {!!scheduleChangesToShow?.length && (
                    <MondayButton
                      {...{
                        className: "mondayButtonRed",
                        "data-testid": `schedule-changes-${i}`,
                        onClick() {
                          setScheduleChangesModal(
                            form.getFieldValue(`cancelId#${i}`)
                          );
                        },
                        Icon: <ManageAlternatesIcon />,
                      }}
                    >
                      See Schedule Changes
                    </MondayButton>
                  )}
                  {/** 
                    //#region ROUTE STATUS
                  */}
                  {RenderDynamicComponents(
                    [
                      {
                        formItemName: `ActivityStatus#${i}`,
                        label: null,
                        required: true,
                        defaultValue: "Draft",
                        type: "select",
                        allowClear: false,
                        customOptions: activityStatuses,
                      },
                    ],
                    { form }
                  )}
                  {/**
                   //#region ROUTE -- SHOW MAP
                   */}
                  <Button
                    type="primary"
                    onClick={() => {
                      openMapsCard(i);
                    }}
                    style={{ borderRadius: "5px" }}
                    id={"mapsDirectionsButton"}
                    data-testid={`open-directions-${i}`}
                  >
                    Open maps directions
                  </Button>
                </div>
              </div>
            </div>
            {/**
             //#region ROUTE -- ADD ROUTE
             */}
            {i >= editBlock ? (
              <div
                className="addRouteOnIndexArrow"
                style={{ minHeight: "7px" }}
              >
                <span
                  className="addRouteButton"
                  data-testid={`add-route-${i}`}
                  onClick={() => {
                    addRoute(i);
                  }}
                >
                  Add Route
                </span>
                {!!possibleRequestsToRender?.length && (
                  <span
                    className="addRouteFromRequestButton"
                    data-testid={`add-request-route-${i}`}
                    onClick={() => {
                      setOpenListingForRequests({
                        index: i,
                        needsNew: true,
                      });
                    }}
                  >
                    Add From Request
                  </span>
                )}
              </div>
            ) : (
              <></>
            )}
          </>
        );
      });
    }, [
      //#region ROUTE -- DEPENDENCIES
      yards,
      dispatchSocket,
      drivers,
      addressesField,
      activityStatuses,
      trucks,
      garageAddresses,
      allDispatches,
      filteredDispatches,
      requestSelections,
      routeLength,
      routeTime,
      routePopover,
      fieldChanged,
      allProjects,
      newCreatedRouteIndex,
      prevDriver,
      editBlock,
      vendors,
      reverseCalcStart,
      rowScheduleChanges,
      hoistRoutes,
      isDarkMode,
      allSchedules,
      schedulesForDate,
      itineraryData,
      varianceRoutes,
      JSON.stringify(prevTime),
      paperworkPopoverVisible,
    ]);

    function onReturnDepartChange(val) {
      //#region RETURN DEPART CHANGE
      let value = null;
      if (!!prevTime?.depart) {
        value = setDateMonth(parseInTz(val), dayjsNY(prevTime.depart));
      } else {
        value = setHourMinute(prevDate, parseInTz(val));
      }

      form.setFieldValue("ReturnDepartAt#", value);

      let prevArrive = form.getFieldValue(`ArriveBy#${routeLength - 1}`);
      if (dayjsNY(prevArrive).unix() > dayjsNY(value).unix()) {
        let prevEditBlock = editBlock;
        setEditBlock(routeLength);
        getNextDayErrorNotification({
          index: null,
          prevEditBlock,
          setEditBlock,
          handler() {
            form.setFieldValue("ReturnDepartAt#", dayjsNY(value).add(1, "d"));
            form.setFieldsValue(
              changeRouteDates({
                existingFields: form.getFieldsValue(),
                index: null,
                routeLength,
              })
            );
            setAcceptNextDay(routeLength - 1);
            setReturnPopover("trafficArrive");
            updateDurationHandler("return");
          },
          cancelHandler() {
            form.setFieldsValue({
              [`TimeExit#${routeLength - 1}`]: null,
              "ReturnDepartAt#": null,
            });
            setReturnPopover(null);
            setReturnTime({ ...returnTime, time: "" });
          },
        });
        return;
      } else {
        form.setFieldsValue({
          "ReturnDepartAt#": value,
          "ReturnArriveBy#": null,
          [`TimeExit#${routeLength - 1}`]: value,
        });
      }

      setReturnPopover("trafficArrive");
      updateDurationHandler("return");
    }

    function onReturnArriveChange(val) {
      //#region RETURN ARRIVE CHANGE
      let value = null;
      if (!!prevTime?.arrive) {
        value = setDateMonth(parseInTz(val), dayjsNY(prevTime.arrive));
      } else {
        value = setHourMinute(prevDate, parseInTz(val));
      }

      form.setFieldsValue({
        "ReturnArriveBy#": value,
        "ReturnDepartAt#": null,
        [`TimeExit#${routeLength - 1}`]: value,
      });

      setReturnTime({ ...routeTime, time: "" });
      setReturnPopover("trafficDepart");
    }

    //#region RETURN CARD
    /**
     * Holds the form fields of the return card
     */
    const ReturnCard = useMemo(() => {
      /**
       * When routes are cancelled, and the route length decreases,
       * the "ghost" routes need somewhere to be attached to, since the return
       * route is always present, we attach them here
       */
      let cancelled = [];

      let driverIsSwitchedCondition = false;
      for (let k = routeLength; k > 0; k--) {
        let c = k === routeLength;
        if (
          form.getFieldValue(`${c ? "Return" : ""}Driver#${c ? "" : k}`) !==
          form.getFieldValue(`Driver#${k - 1}`)
        ) {
          driverIsSwitchedCondition = true;
          break;
        }
      }

      let tmp = cancelledRoutes?.filter(
        (el) => el?.cancelIndex >= routeLength && !!el?.cancelled
      );
      for (let i = 0; i < tmp?.length; i++) {
        cancelled.push(
          <GhostRoute
            {...{
              index: tmp[i]?.cancelIndex,
              form,
              cancelObject: tmp[i],
              drivers,
            }}
          />
        );
      }
      return (
        <>
          {cancelled}
          <div className="dispatchRouteCard" id="dispatchReturnCard">
            <div className="cardTitle">Return Route</div>
            {RenderDynamicComponents(
              routeFields({
                addressesField: addressesField,
                disabledHours: () => getDisabledHours("return"),
                disabledMinutes: (selectedHour) =>
                  getDisabledMinutes(selectedHour, "return"),
                onDepartChange: onReturnDepartChange,
                onArrivalChange: onReturnArriveChange,
                setFieldChanged,
                onDriverSelect,
                globalDisabled: editBlock === routeLength,
                form,
                garageAddresses,
                projects: allProjects,
                vendors,
                driverIsSwitchedCondition,
                dropOffLabel: dropOffBaseHTML,
                onCargoChange,
                setPrevDriver,
                setPrevTime,
                isDarkMode,
                itineraryData,
                routeLength,
                onDropOffBlur,
                onDropOffSelect,
                dropOffAutoFocus,
                onPickupLocationSelect,
                setOpenItinerary() {
                  setOpenItinerary(routeLength);
                },
                //#region RETURN -- POPOVER
                PopoverContent: (
                  <PopoverSuggestion
                    {...{
                      visibleType: returnPopover || returnTime?.time,
                      setTrafficModel(val) {
                        scrollToCard("return");
                        setReturnPopover(null);
                        setReturnTime({ ...returnTime, time: "" });

                        setTimeout(() => {
                          getSuggestionForRoute(
                            val,
                            returnPopover,
                            "return",
                            null
                          );
                        }, 0);
                      },
                      setSuggestion() {
                        //#region RETURN -- POPOVER CONFIRM
                        if (returnTime.arrive) {
                          form.setFieldValue(
                            "ReturnArriveBy#",
                            dayjsNY(returnTime?.time)
                          );
                          regulateSwitchedRoutesSuggestions(routeLength);
                        } else {
                          form.setFieldsValue({
                            "ReturnDepartAt#": dayjsNY(returnTime?.time),
                            [`TimeExit#${routeLength - 1}`]: dayjsNY(
                              returnTime?.time
                            ),
                          });
                        }

                        form.setFieldValue(
                          `TimeExit#${routeLength - 1}`,
                          form.getFieldValue("ReturnDepartAt#")
                        );

                        let overlapRes = validateOverlapping({
                          caller: "return",
                          filteredDispatches,
                          form,
                          drivers,
                          index: null,
                          selector: null,
                          setSwitchDriver,
                          prevDriver,
                        });
                        //if the new set time overlaps with a route for whatever reason we want to clear the times
                        //and make the user select a different one
                        if (!!overlapRes) {
                          form.setFieldValue("ReturnArriveBy#", null);
                          setReturnPopover(null);
                          setReturnTime({ ...returnTime, time: "" });
                          return;
                        }

                        //we need to check for the availability of the truck
                        //after setting suggestions for the return
                        if (
                          !!form.getFieldValue("DepartAt#0") &&
                          form.getFieldValue("ReturnArriveBy#")
                        ) {
                          if (
                            validateTruckAvailability({
                              fleetId: form.getFieldValue("truckNumber"),
                              allDispatches,
                              depart: dayjsNY(
                                form.getFieldValue("DepartAt#0")
                              ).valueOf(),
                              arrive: dayjsNY(
                                form.getFieldValue("ReturnArriveBy#")
                              ).valueOf(),
                              dispatchId: rowObject?.dispatchId || null,
                            })
                          ) {
                            getTruckOverlappingNotification();
                            form.setFieldValue("ReturnArriveBy#", null);
                            setReturnPopover(null);
                            setReturnTime({ ...returnTime, time: "" });
                            return;
                          }
                        }

                        updateDurationHandler("return");
                        setReturnPopover(null);
                        setReturnTime({ ...returnTime, time: "" });
                      },
                      ignoreSuggestion() {
                        setReturnPopover(null);
                        setReturnTime({ ...returnTime, time: "" });
                      },
                      form,
                    }}
                  />
                ),
                departPopoverVisible:
                  (!returnTime?.arrive && !!returnTime?.time) ||
                  returnPopover === "trafficDepart",
                arrivePopoverVisible:
                  (!!returnTime?.arrive && !!returnTime?.time) ||
                  returnPopover === "trafficArrive",
                returnCondition: true,
                drivers: filterDrivers || drivers,
                paperworkPopoverVisible: paperworkPopoverVisible,
                activePopoverIndex: activePopoverIndex,
                onPaperworkPopoverVisible: (index) =>
                  handelPaperworkPopoverVisible(index),
                onPaperworkUpdate: handlePaperworkUpdate,
                onPaperworkCancel: handlePaperworkCancel,
              })
                .filter(Boolean)
                .map(({ baseFormName, ...el }) => ({
                  ...el,
                  formItemName: `Return${baseFormName}`,
                })),
              { form }
            )}
            <div className="dispatchRouteDistance">
              {routeLength > 1 && listOfDistances[routeLength - 1] !== 0 ? (
                <span>
                  {routeLength > 1 && (
                    <div>
                      <span>Route length:&nbsp;</span>

                      <span style={{ color: "#ff4d4f" }}>
                        {formatNumber(returnDistance * 0.000621371, {
                          unit: "mile",
                          fixed: 1,
                        })}
                      </span>
                    </div>
                  )}
                </span>
              ) : (
                <span></span>
              )}
              <div className="dispatchMapsShowButton">
                {RenderDynamicComponents(
                  driverShowField(
                    drivers,
                    trucks,
                    form,
                    onDriverShowSelect,
                    null
                  ),
                  { form }
                )}
                {RenderDynamicComponents(
                  [
                    {
                      formItemName: `ReturnActivityStatus#`,
                      label: null,
                      required: true,
                      defaultValue: "Draft",
                      type: "select",
                      allowClear: false,
                      customOptions: activityStatuses,
                    },
                  ],
                  { form }
                )}
                <Button
                  type="primary"
                  onClick={() => {
                    openMapsCard(null);
                  }}
                  style={{ borderRadius: "5px" }}
                >
                  Open maps directions
                </Button>
              </div>
            </div>
          </div>
        </>
      );
    }, [
      //#region RETURN -- DEPENDENCIES
      yards,
      drivers,
      dispatchSocket,
      trucks,
      allSchedules,
      garageAddresses,
      schedulesForDate,
      allDispatches,
      returnPopover,
      returnTime,
      fieldChanged,
      allProjects,
      routeLength,
      prevDriver,
      editBlock,
      vendors,
      isDarkMode,
      itineraryData,
      activityStatuses,
      JSON.stringify(prevTime),
    ]);

    //#region REF HANDLE
    useImperativeHandle(
      ref,
      () => {
        return {
          addRoute(index, defaultData) {
            addRoute(index, defaultData);
          },
          removeRoute(index) {
            routeCancelHandler(index, true);
          },
          getRouteData() {
            return form.getFieldsValue();
          },
          setDate(date) {
            let dateToSet = dayjsNY(date).startOf("D");
            form.setFieldValue("date", dateToSet);
            onDateChange(dateToSet);
            setHeaderDisabled((prev) => ({ ...prev, date: true }));
          },
          setVehicle(fleetId) {
            form.setFieldValue("truckNumber", fleetId);
            onTruckSelect(fleetId);
            setHeaderDisabled((prev) => ({ ...prev, vehicle: true }));
          },
          openSuggestionAt(index) {
            if (index > routeLength || index === undefined) {
              setReturnPopover("trafficDepart");
            } else if (!isNaN(index)) {
              setRoutePopover(`trafficDepart#${index}`);
            }
          },
        };
      },
      [RouteCards, ReturnCard, dispatchReady]
    );

    //#region VALIDATE ALL ROUTES
    /**
     * Function used on truck selection. If the truck is selected after setting the
     * routes and the times, checks if the truck has any conflicting routes set. If there
     * any conflicts it resets the time fields in order to make new ones.
     * A truck can be selected only when there is one route
     */
    function validateAllRoutes() {
      let routeRes = validateOverlapping({
        caller: "route",
        filteredDispatches,
        form,
        drivers,
        index: 0,
        selector: null,
        setSwitchDriver,
        prevDriver,
      });
      let returnRes = validateOverlapping({
        caller: "return",
        filteredDispatches,
        form,
        drivers,
        index: null,
        selector: null,
        setSwitchDriver,
        prevDriver,
      });

      if (!!routeRes) {
        form.setFieldValue({
          ...nullifyAllTimes(0, 1),
        });
      }

      if (!!returnRes) {
        form.setFieldsValue({
          "ReturnArriveBy#": null,
        });
      }
    }

    //#region SWITCH DRIVERS HANDLER
    /**
     * Function that handles the "driver switch operation"
     */
    function switchDriversHandler({
      selectedRoute = null,
      outerDriver = null,
      outerIndex = "",
      outerHandler,
    }) {
      switchDrivers(
        filteredDispatches,
        selectedRoute,
        prevDriver,
        outerDriver || switchDriver?.driverToSwitch,
        setAllDispatches,
        form.getFieldsValue(),
        // outerIndex || switchDriver?.routeIndex,
        outerIndex !== ""
          ? outerIndex === null
            ? null
            : outerIndex === 0
            ? 0
            : !!outerIndex
            ? outerIndex
            : switchDriver?.routeIndex
          : switchDriver?.routeIndex,
        drivers,
        allDispatches,
        setRouteSwitched,
        routeSwitched,
        !!outerHandler ? outerHandler : setSwitchDriver,
        setAffectedDispatches,
        routeLength,
        affectedDispatches
      );

      if (!headerDisabled.date) {
        setHeaderDisabled({ ...headerDisabled, date: true });
      }

      if (switchDriver?.routeIndex !== null || outerIndex !== null) {
        setEditBlock(
          outerIndex === 0
            ? -1
            : outerIndex || switchDriver?.routeIndex === 0
            ? -1
            : switchDriver?.routeIndex || routeLength
        );
      } else {
        setEditBlock(routeLength);
      }
    }

    //#region SAVE HANDLER
    function saveHandler(newDispatch = false) {
      validateForm(
        form,
        async () => {
          saveDispatch({
            form,
            vendors,
            varianceRoutes,
            fleet: trucks,
            authenticatedUser,
            drivers,
            length: routeLength,
            refreshTable,
            dispatchSocket,
            schedules: allSchedules,
            projects: allProjects,
            listOfLabels,
            rowObject,
            setAllDispatches,
            cancelledRoutes,
            users,
            listOfDistances: listOfDistances.concat(
              returnDistance || listOfDistances[0]
            ),
            itineraryData,
            usersByDepartment,
            teamsBySchedule,
            setAllSchedules,
            hoistRoutes,
            rowScheduleChanges,
            userConfiguration,
            items: selectedTeam,
            updateProgressStatus,
            setVisibleCreationProgress: (data) =>
              setVisibleCreationProgress({ ...data, newDispatch }),
            saveAddedLogs,
          })
            .then(() => {
              setPrevDriver(null);
              setSelectedTeam([]);
              //#region SAVE -- REQUESTS
              /**
               * we remove the requests entirely from the main array
               * if there are any more dispatches to be opened the eligible
               * requests will be set in the use effect
               */
              let acceptedIds = requestSelections?.reduce(
                (acc, val) => ({
                  ...acc,
                  [`${val?.requestId}`]: val?.requestId,
                }),
                {}
              );
              Object.keys(acceptedIds).forEach((id) => {
                let selectedRequest = requests?.find(
                  (e) => e?.requestId === id
                );
                let requestNotes =
                  selectedRequest?.requestObject?.comments || [];
                requestNotes[requestNotes?.length] = {
                  author: `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`,
                  children: [],
                  content:
                    "<span><strong>~Resolved~</strong><br></br><span>...</span></span>",
                  id: uuid(),
                  mentions: [],
                  status: "Draft",
                };

                API.patch("formRequests", `/${"formRequests"}/${id}`, {
                  body: {
                    responseFrom: "approved",
                    requestObject: {
                      ...selectedRequest?.requestObject,
                      comments: requestNotes,
                      path: `fleets/overview?tab=Dispatch`,
                      status: "Reviewed",
                    },
                  },
                }).then(() => {
                  requestFormWs.send(
                    JSON.stringify({
                      request: "request-response",
                      body: { requestId: id, responseFrom: "approved" },
                    })
                  );
                });
              });
              setRequests(
                requests?.filter(({ requestId }) => !acceptedIds[requestId])
              );

              setRequestSelections([]);
              if (newDispatch) {
                let prevDate = form.getFieldValue("date");
                form.resetFields();
                setRouteLength(1);
                setSchedulesForDate(
                  allSchedules?.filter(({ scheduleDays }) =>
                    scheduleDays?.some(
                      ({ startDate }) =>
                        dayjsNY(startDate).format("MM/DD/YYYY") ===
                        dayjsNY(form.getFieldValue("date")).format("MM/DD/YYYY")
                    )
                  )
                );
                if (complementaryRoutes?.length > 0) {
                  //#region SAVE -- COMPLEMENTARY
                  // let t = structuredClone(complementaryRoutes);
                  let t = [...complementaryRoutes];
                  //we simulate a modal closing
                  let modal = document.getElementsByClassName(
                    "newDispatchModalContainer"
                  );
                  if (!!modal?.item(0)) {
                    modal.item(0).animate(simulateClosingFrames, {
                      duration: 500,
                      delay: 200,
                    });
                  }
                  setEditBlock(-1);
                  setRowObject({
                    dispatchDate: dayjsNY(prevDate).format(),
                    routes: [
                      {
                        pickUpLocation: yards?.[0],
                        dropOffLocation: t[0]?.location,
                        driverId: t[0]?.returnDriver,
                        departAt: null,
                        arriveBy: dayjsNY(t[0]?.arrive).format(),
                        timeExit: dayjsNY(t[0]?.arrive).add(1, "h").format(),
                        scheduleId: t[0]?.scheduleId ?? "",
                        projectId: t[0]?.projectId ?? "",
                      },
                      {
                        pickUpLocation: t[0]?.location,
                        dropOffLocation: yards?.[0],
                        driverId: t[0]?.startDriver,
                        departAt: dayjsNY(t[0]?.arrive).add(1, "h").format(),
                        arriveBy: null,
                      },
                    ],
                  });
                }
              } else if (routeSwitched?.length > 0) {
                //#region SAVE -- AFTER SWITCH
                //In the case of a driver switch, we want to change the selected data
                //in order to simulate the opening of a new modal
                let d = allDispatches?.find(
                  (el) => el?.dispatchId === routeSwitched[0]["dispatchId"]
                );
                setFilterDrivers();
                setFilterTrucks();
                if (!!d) {
                  //we simulate a modal closing
                  let modal = document.getElementsByClassName(
                    "newDispatchModalContainer"
                  );
                  if (!!modal?.item(0)) {
                    modal.item(0).animate(simulateClosingFrames, {
                      duration: 500,
                      delay: 200,
                    });
                  }
                  setSwitchDriver(null);
                  //we set to block the editing of the routes with indexes smaller than the max
                  let tmpIndexes = routeSwitched[0]["routeChangeInfo"].reduce(
                    (acc, val) => [...acc, val?.index],
                    []
                  );
                  setEditBlock(Math.max(...tmpIndexes) - 1);
                  setRowObject(d);
                }
              } else {
                //#region SAVE -- DEFAULT
                affectedDispatches?.forEach((el) => {
                  let updatedDispatch = allDispatches?.find(
                    (e) => e?.dispatchId === el?.dispatchId
                  );
                  if (!!updatedDispatch) {
                    let t = structuredClone(updatedDispatch);
                    delete t["dispatchId"];
                    API.put(
                      "fleetDispatching",
                      `/fleetDispatching/${el?.dispatchId}`,
                      {
                        body: { ...t },
                      }
                    );
                    el?.routes?.forEach((activity) => {
                      let a = structuredClone(activity);
                      delete a["activityId"];
                      API.put(
                        "fleetActivity",
                        `/fleetActivity/${activity?.activityId}`,
                        {
                          body: { ...a },
                        }
                      );
                    });
                  }
                });
                form.resetFields();
                onCancel(true);
              }
            })
            .catch((err) => console.log("Error Saving: ", err));
        },
        null,
        "",
        "all"
      );
    }

    //#region DELETE HANDLER
    async function deleteHandler() {
      await deleteDispatch({
        rowObject,
        refreshTable,
        authenticatedUser,
        users,
        allSchedules,
        setAllSchedules,
        saveAddedLogs,
        dispatchSocket,
      });
      form.resetFields();
      onCancel();
    }

    //#region WILL OPEN NEW
    let willOpenNew =
      !!routeSwitched?.length ||
      !!complementaryRoutes?.length ||
      affectedDispatches?.length;
    let noOfAutomaticUpdates = 0;
    affectedDispatches?.forEach((el) => {
      if (!routeSwitched?.find((e) => e?.dispatchId === el?.dispatchId)) {
        noOfAutomaticUpdates++;
      }
    });

    //#region MODAL TITLE
    const modalTitle = (
      <>
        {Object.keys(rowObject)?.length > 1
          ? cancelDisabledCondition
            ? "Edit for driver switch"
            : "Edit dispatch"
          : "New Dispatch"}{" "}
        {willOpenNew ? (
          <span className="titleInfoSpan">
            {willOpenNew && "( "}
            {`${complementaryRoutes?.length} to create, `}
            {`${routeSwitched?.length} to update, `}
            {`${noOfAutomaticUpdates} automatic update${
              noOfAutomaticUpdates !== 1 ? "s" : ""
            }`}
            {willOpenNew && ")"}
          </span>
        ) : (
          <></>
        )}
      </>
    );

    //#region CONVERT REQUESTS
    function convertRequestsToRoutes(index) {
      if (!!eligibleRequests?.length) {
        let possibleOptions = getEligibleRequestsForRoute(index);
        return possibleOptions
          ?.map((el) => {
            let { requestObject: r } = el;
            return {
              activityId: el?.requestId,
              cargoStatus: r?.cargoStatus,
              dropOffLocation: r?.jobsiteAddress,
              fleetName: trucks?.find(({ fleetId }) => fleetId === r?.vehicle)
                ?.fleetName,
              driverName: drivers?.find(
                ({ driverId }) => driverId === r?.driver
              )?.driverName,
              lastEntry: r?.timeOnSite,
              priority: r?.priority,
              routeNotes: r?.routeNotes,
            };
          })
          .sort((a) => {
            if (a?.priority === "High") {
              return 1;
            } else if (a?.priority === "Low") {
              return -1;
            }
          });
      } else {
        return [];
      }
    }

    /**
     * @param {any} fenceBody
     * @param {"ADD"|"EDIT"|"REMOVE"} action
     */
    function updateVendorFences(fenceBody, action) {
      //#region UPDATE VENDOR FENCES
      const { geofenceUUID } = fenceBody;
      switch (action) {
        case "ADD": {
          setVendorFences([...vendorFences, { ...fenceBody }]);
          break;
        }
        case "EDIT": {
          const existingFences = [...vendorFences];
          const existingIndex = existingFences.findIndex(
            ({ geofenceUUID: id }) => id === geofenceUUID
          );
          if (existingIndex > -1) {
            existingFences[existingIndex] = {
              ...existingFences[existingIndex],
              ...fenceBody,
            };
          }
          setVendorFences(existingFences);
          break;
        }
        case "REMOVE": {
          const removeIndex = vendorFences?.findIndex(
            ({ geofenceUUID: id }) => id === geofenceUUID
          );
          if (removeIndex > -1) {
            vendorFences.splice(removeIndex, 1);
            setVendorFences([...vendorFences]);
          }
          break;
        }
      }
    }

    //#region TRUCK SELECT
    function onTruckSelect(value) {
      let truck = trucks?.find((el) => el?.fleetId === value);
      let selectedFleet = truck?.fleetName;
      onFilter({
        gridApi,
        column: "fleetName",
        current: selectedFleet,
      });
      validateAllRoutes();
      setEligibleRequests(
        filterRequests({
          requests,
          date: form.getFieldValue("date"),
          truck: value,
        })
      );

      form.setFieldValue(
        "dispatchTeam",
        getTeamsSelectionsFromItems(truck?.teamsConfiguration)
      );
      setSelectedTeam(truck?.teamsConfiguration);

      const selectedPickup = form.getFieldValue("PickupLocation#0");
      if (!yards.includes(selectedPickup)) {
        const garage = garageAddresses?.[value]?.["address"];
        form.setFieldValue("PickupLocation#0", garage ?? yards?.[0]);
        setTimeout(() => {
          onPickupLocationSelect(garage ?? yards?.[0], 0);
        }, 50);
      }

      //we check if the truck is available for the selected times
      if (
        !!form.getFieldValue("DepartAt#0") &&
        !!form.getFieldValue("ReturnArriveBy#")
      ) {
        if (
          validateTruckAvailability({
            fleetId: value,
            allDispatches,
            depart: dayjsNY(form.getFieldValue("DepartAt#0")).valueOf(),
            arrive: dayjsNY(form.getFieldValue("ReturnArriveBy#")).valueOf(),
            dispatchId: rowObject?.dispatchId || null,
          })
        ) {
          getTruckOverlappingNotification();
          form.setFieldValue("ReturnArriveBy#", null);
        }
      }
      setFieldChanged(!fieldChanged);
    }

    //#region DATE CHANGE
    function onDateChange(val) {
      const dateValue = parseInTz(val);

      form.setFieldsValue({
        ...changeDate({
          existingFields: form.getFieldsValue(),
          prevDate,
        }),
        date: dateValue,
      });

      setPrevDate(dateValue);
      setPrevTime(null);

      let date = dayjsNY(dateValue).format(dateFormat);
      const truck = trucks?.find(
        (el) => el?.fleetId === form.getFieldValue("truckNumber")
      );

      const driver0 = drivers.find(
        ({ driverId }) => driverId === form.getFieldValue("Driver#0")
      );

      let isSameDriverThroughDispatch = true;

      if (truck) {
        const d = dateValue.valueOf();
        if (
          (truck?.inactivityTimeline || []).some(
            ({ start, end }) => start <= d && (end || Date.now()) >= d
          )
        ) {
          form.setFieldValue("truckNumber", undefined);
          message.warning({
            content:
              "Vehicle is cleared since it was terminated for the selected date",
            key: "truckTermination",
            duration: 5.5,
          });
        }
      }

      if (driver0) {
        const d = dateValue.valueOf();

        for (let i = 0; i <= routeLength; i++) {
          const c = i === routeLength;
          const driverForRoute = form.getFieldValue(
            `${c ? "Return" : ""}Driver#${c ? "" : i}`
          );

          if (driverForRoute && driverForRoute !== driver0?.driverId) {
            isSameDriverThroughDispatch = false;
            break;
          }
        }

        if (isSameDriverThroughDispatch) {
          if (
            (driver0?.inactivityTimeline || []).some(
              ({ start, end }) => start <= d && (end || Date.now()) >= d
            )
          ) {
            for (let i = 0; i <= routeLength; i++) {
              const c = i === routeLength;

              form.setFieldValue(
                `${c ? "Return" : ""}Driver#${c ? "" : i}`,
                undefined
              );
            }

            setPrevDriver(null);

            message.warning({
              content:
                "Driver was cleared since it was terminated for the selected date",
              key: "driverTermination",
              duration: 6,
            });
          }
        }
      }

      let newSchedulesForDate = allSchedules?.filter(({ scheduleDays }) =>
        scheduleDays?.some(
          ({ startDate }) => dayjsNY(startDate).format(dateFormat) === date
        )
      );
      setOverlappingSchedules(findOverlappingSchedules(newSchedulesForDate));
      setSchedulesForDate(newSchedulesForDate);
      let tmpEligible = filterRequests({
        requests,
        date: val,
        truck: truck?.fleetName,
      });

      if (!tmpEligible?.length) {
        message.info("There are no requests for the selected date");
      }

      setEligibleRequests(tmpEligible);
      setFieldChanged((prev) => !prev);
      //when active we only have one route
      if (form.getFieldValue("Schedule#0")) {
        form.setFieldValue("Schedule#0", "");
        form.setFieldValue("DropOffLocation#0", "");
        form.setFieldValue("ReturnPickupLocation#", "");
      }
    }

    /**
     * @param {ItineraryType[]} itinerary
     */
    function onItineraryConfirm(itinerary) {
      //#region ITINERARY CONFIRM
      let { departAt } = itinerary[0];
      departAt = dayjsNY(departAt);

      const { dropOffLocation, destinationType, destinationId } =
        itinerary[itinerary.length - 1];

      const routeIndex = openItinerary;
      const isReturn = routeIndex === routeLength;
      const isLast = routeIndex === routeLength - 1;

      let prevArrive = null;
      if (routeIndex !== 0) {
        prevArrive = form.getFieldValue(`ArriveBy#${routeIndex - 1}`);
      }

      if (prevArrive) {
        if (departAt.valueOf() < prevArrive.valueOf()) {
          message.warning({
            content: `Itinerary conflicts with dispatch times. Cannot depart before ${prevArrive.format(
              "HH:mm"
            )}`,
            key: "noDepartBefore",
          });

          return;
        }
      }

      form.setFieldsValue({
        [`${isReturn ? "Return" : ""}DepartAt#${isReturn ? "" : routeIndex}`]:
          departAt,
        [`${isReturn ? "Return" : ""}DropOffLocation#${
          isReturn ? "" : routeIndex
        }`]: dropOffLocation,
        [`${isLast ? "Return" : ""}PickupLocation#${
          isLast ? "" : routeIndex + 1
        }`]: dropOffLocation,
      });

      setItineraryData((prev) => {
        return {
          ...prev,
          [routeIndex]: itinerary,
        };
      });

      setOpenItinerary(null);

      if (isReturn) {
        setReturnPopover(`trafficArrive`);
      } else {
        setTimeout(() => {
          if (destinationType === "Schedule") {
            onScheduleSelect(destinationId, routeIndex, false);
          } else {
            onDropOffSelect(
              dropOffLocation,
              routeIndex,
              destinationType === "Off Project"
                ? {}
                : {
                    value: dropOffLocation,
                    label: dropOffLocation,
                    key: destinationId,
                  }
            );
          }
        }, 0);
      }
    }

    function onStatusChange(status) {
      //#region STATUS CHANGE
      if (rowObject?.scheduleChanges?.length && status === "Confirmed") {
        getStatusWarningMessage(status);
      }
    }

    //#region JSX
    return (
      <>
        <Modal
          {...{
            open: visible,
            onCancel() {
              if (form?.isFieldsTouched()) {
                setCancelModalVisible("close");
              } else {
                setVisible(false);
              }
            },
            "data-testid": "dispatch-modal",
            centered: true,
            title: (
              <CustomModalHeader
                {...{
                  title: modalTitle,
                  onClick: () => {
                    setTourOpen(true);
                  },
                  noX: true,
                  hasButton: Object.keys(rowObject).length > 1 ? false : true,
                }}
              />
            ),
            afterOpenChange: (event) => {
              if (event) {
                updateDocumentTitle({
                  newTitle:
                    Object.keys(rowObject)?.length > 1
                      ? cancelDisabledCondition
                        ? "Edit for driver switch"
                        : "Edit dispatch"
                      : "New Dispatch",
                });
              }
            },
            wrapClassName: "newDispatchModalWrap",
            footer: footerButtons({
              rowObject,
              saveHandler,
              complementaryRoutes,
              accessRight,
              deleteHandler() {
                setCancelModalVisible("delete");
              },
              onCancel() {
                setCancelModalVisible("close");
              },
              openLogs() {
                setLogsVisible(true);
              },
              cancelDisabledCondition: cancelDisabledCondition,
              saveCondition,
              defaultData,
              changes: form.isFieldsTouched(),
              setVisible,
              generatePdf() {
                generateDispatchPdf({ body: rowObject, base64 });
              },
            }),
            closable: false,
            className: `newDispatchModalContainer ${
              isDarkMode && "newDispatchModalContainerDark"
            }`,
          }}
        >
          <Form form={form}>
            <div className="dispatchModalControl">
              <div className="liveContainer">
                <MondayButton
                  Icon={<LiveIcon height={17.5} width={17.5} />}
                  className="mondayButtonRed"
                  onClick={() => {
                    openInNewTab(`${window.location.origin}/fleets/live`);
                  }}
                  id={"liveButton"}
                >
                  Live
                </MondayButton>
              </div>
              <VehicleLocationWidget
                deviceSerialNumber={
                  trucks?.find(
                    ({ fleetId }) =>
                      fleetId === form.getFieldValue("truckNumber")
                  )?.deviceSerialNumber
                }
              />
              {/**
               //#region HEADER
               */}
              {RenderDynamicComponents(
                headerFields({
                  routeLength,
                  defaultData,
                  rowObject,
                  requestObject,
                  trucks,
                  onTruckSelect,
                  filterTrucks,
                  stepperParams,
                  onStatusChange,
                  form,
                  allTeams,
                  setSelectedTeam,
                  accessRight,
                  onDateChange,
                  headerDisabled,
                }),
                { form }
              )}
              <MondayButton
                Icon={<AddIcon />}
                onClick={() => {
                  setNewDriver(true);
                }}
                className="createDriverBtn"
                data-testid="new-driver-button"
              >
                Create Driver
              </MondayButton>
              <MondayButton
                Icon={<AddIcon />}
                onClick={() => {
                  setNewTruck(true);
                }}
                className="createVehicleBtn"
                data-testid="new-fleet-button"
              >
                Create Vehicle
              </MondayButton>
              {/**
               * ======= DO NOT CHANGE THIS CONDITION =======
               *
               * CREATING GEOFENCES EVEN FOR TESTING PURPOSES
               * CONFLICTS WITH THE WORK OF OTHER DEPARTMENTS
               */}
              {process.env.NODE_ENV === "production" ? (
                <MondayButton
                  Icon={<AddIcon />}
                  onClick={() => {
                    setNewVendorOpen(true);
                  }}
                  className="createVendorBtn"
                >
                  Create Vendor
                </MondayButton>
              ) : null}
              {!!selectedTeam?.length ? (
                <MondayButton
                  Icon={<AddIcon />}
                  onClick={() => {
                    setTeamsModalVisible(true);
                  }}
                  data-testid="change-members-button"
                >
                  Members
                </MondayButton>
              ) : (
                <></>
              )}
              {getAccessRights(accessRight, "Teams") ? (
                <AvatarImages
                  {...{
                    cognitoUserIds: selectedTeam
                      .reduce((acc, { members }) => [...acc, ...members], [])
                      .map(({ cognitoUserId }) => cognitoUserId),
                  }}
                />
              ) : (
                <></>
              )}
            </div>
            <div className="overallRouteContainer" id="overallRouteContainer">
              {/**
               //#region ROUTE CARDS
               */}
              {RouteCards}
              {ReturnCard}
            </div>

            {/**
            //#region DISPATCHES TABLE
            */}
            {!!tableHide ? (
              <DispatchesTable
                {...{
                  dispatches: allDispatches,
                  rowData: dispatchesTableRows,
                  form,
                  onGridReady,
                  gridApi,
                  setTableHide,
                  isDarkMode,
                }}
              />
            ) : (
              <div className="show-table-div">
                <div
                  className="hideTable"
                  onClick={() => setTableHide(true)}
                  data-testid="dispatch-show-table"
                >
                  Show Table
                </div>
              </div>
            )}
          </Form>
        </Modal>
        {/**
         //#region NEW DRIVER
         */}
        {newDriver && (
          <NewDriverModal
            {...{
              visible: newDriver,
              setVisible: setNewDriver,
              refreshTable(res) {
                if (!!filterDrivers) {
                  setFilterDrivers(filterDrivers?.concat(res));
                }
                let tmp = drivers?.concat(res);
                setDrivers(tmp);
              },
              saveButtonReversed: true,
            }}
          />
        )}
        {/**
         //#region NEW VEHICLE
         */}
        {newTruck && (
          <NewFleetModal
            {...{
              visible: newTruck,
              setVisible: setNewTruck,
              refreshTable(res) {
                if (!!filterTrucks) {
                  setFilterTrucks(filterTrucks?.concat(res));
                }
                setTrucks(trucks?.concat(res));
              },
              reverseSaveButtons: true,
            }}
          />
        )}
        {/** 
          //#region NEW VENDOR
        */}
        {/**
         * ======= DO NOT CHANGE THIS CONDITION =======
         *
         * CREATING GEOFENCES EVEN FOR TESTING PURPOSES
         * CONFLICTS WITH THE WORK OF OTHER DEPARTMENTS
         */}
        {newVendorOpen && process.env.NODE_ENV === "production" ? (
          <VendorModal
            {...{
              open: true,
              type: "Create",
              mapLoaded: isLoaded,
              fieldId: VENDOR_FIELD_ID,
              vendorFences,
              data: vendors,
              title: "Create Vendor",
              updateVendorFences,
              onCancel() {
                setNewVendorOpen(false);
              },
            }}
          />
        ) : null}
        {/**
         //#region DISPATCH MAP CARD
         */}
        <DispatchMapsCard
          {...{
            open: !!mapsVisible && isLoaded,
            onCancel() {
              setMapsVisible(null);
            },
            directions: mapsVisible,
            isDarkMode,
          }}
        />
        {/**
         //#region ROUTE DETAILS
         */}
        <RouteShower
          {...{
            open: routeShower?.driverId || routeShower?.truckId,
            onCancel() {
              setRouteShower(null);
            },
            routes: routeShower?.routes,
            isDarkMode,
            title: `Planned routes for ${
              routeShower?.driverId
                ? drivers.find((el) => el?.driverId === routeShower?.driverId)
                    ?.driverName
                : trucks?.find((el) => el?.fleetId === routeShower?.truckId)
                    ?.fleetName
            }`,
            showCargoInfo: false,
            selectedDate: form.getFieldValue("date"),
          }}
        />
        {/**
         //#region ROUTE LIST SWITCH
         */}
        {(!!switchDriver || openListingForRequests !== null) && (
          <RouteListingForSwitchModal
            {...{
              open: !!switchDriver || openListingForRequests !== null,
              onCancel() {
                setOpenListingForRequests(null);
                setSwitchDriver();
                setEditBlock(-1);
              },
              requestData: openListingForRequests !== null,
              async confirmHandler(selectedRoute = null) {
                if (openListingForRequests !== null) {
                  let i = openListingForRequests.index;
                  if (openListingForRequests.needsNew) {
                    i++;
                  }

                  let selectOption = {
                    key: selectedRoute?.activityId,
                    value: (selectedRoute?.dropOffLocation || "")?.trim(),
                    label: (selectedRoute?.dropOffLocation || "")?.trim(),
                  };

                  let selectedRequest = requests?.find(
                    (el) => el?.requestId === selectedRoute?.activityId
                  );

                  let requestToSet = {};

                  if (selectedRequest) {
                    let { requestObject: r } = selectedRequest;
                    if (r?.selectedMainField?.scheduleId || r?.scheduleId) {
                      selectOption["value"] = selectOption["value"] + " ";
                    } else if (
                      r?.selectedMainField?.projectId ||
                      r?.projectId
                    ) {
                      delete selectOption["label"];
                    }

                    requestToSet = {
                      index: i,
                      address: selectedRoute?.dropOffLocation,
                      requestId: selectedRoute?.activityId,
                      cargoStatus: selectedRoute?.cargoStatus,
                      requestObject: r,
                    };
                  }

                  setFieldChanged(!fieldChanged);
                  if (openListingForRequests.needsNew) {
                    addRoute(i - 1, {
                      ...requestToSet,
                      value: selectOption.value,
                      option: selectOption,
                    });
                  } else {
                    onDropOffSelect(selectOption.value, i, selectOption, true);
                  }
                  setOpenListingForRequests(null);
                } else if (switchDriver?.allRoutes) {
                  await handleExternalNewRoutes({
                    selectedRoute,
                    allDispatches,
                    setAllDispatches,
                    existingFields: form.getFieldsValue(),
                    index: switchDriver?.routeIndex,
                    driverToPut: switchDriver?.driverToSwitch,
                    setSwitchDriver,
                    filteredDispatches,
                    prevDriver,
                    routeLength,
                    setAffectedDispatches,
                    affectedDispatches,
                    routeSwitched,
                  });
                } else {
                  switchDriversHandler({ selectedRoute });
                }
              },
              allRoutes: !!switchDriver?.allRoutes,
              routes:
                openListingForRequests !== null
                  ? convertRequestsToRoutes(openListingForRequests.index)
                  : switchDriver?.allRoutes
                  ? filteredDispatches?.filter(
                      (route) =>
                        route?.driverId === switchDriver?.driverToSwitch &&
                        route?.routeName !== "Return Route"
                    )
                  : filteredDispatches?.filter(
                      (route) =>
                        route?.pickupLocation ===
                          form.getFieldValue(
                            `${
                              switchDriver?.routeIndex !== null ? "" : "Return"
                            }PickupLocation#${
                              switchDriver?.routeIndex !== null
                                ? switchDriver?.routeIndex
                                : ""
                            }`
                          ) &&
                        (route?.driverId === switchDriver?.driverToSwitch ||
                          route?.passengerId === switchDriver?.driverToSwitch)
                    ),
              prevDriver:
                drivers?.find((el) => el?.driverId === prevDriver)
                  ?.driverName ?? "",
              driverToSwitch:
                drivers?.find(
                  (el) => el?.driverId === switchDriver?.driverToSwitch
                )?.driverName ?? "",
            }}
          />
        )}

        {/**
         //#region DISPATCH SCHEDULE DAY
         */}
        {!!scheduleChangesModal && (
          <DispatchScheduleDayModal
            {...{
              openDispatchDays: !!scheduleChangesModal,
              setOpenDispatchDays: setScheduleChangesModal,
              dispatchDays: rowScheduleChanges[scheduleChangesModal],
              openFromDispatch: true,
              isDarkMode,
              timeHandler(newTime) {
                applyScheduleTimeChange(scheduleChangesModal, newTime);
                setScheduleChangesModal(false);
              },
            }}
          />
        )}

        {/**
         //#region SPECIFIC SCHEDULE
         */}
        {!!scheduleSelectParams && (
          <SpecificScheduleSelectModal
            {...{
              open: scheduleSelectParams,
              onSelect(schedule) {
                const { index, inversePopoverCondition, changeCargo } =
                  scheduleSelectParams;
                onScheduleSelect(
                  schedule?.scheduleId,
                  index,
                  inversePopoverCondition,
                  changeCargo
                );
                let tmpList = [...listOfLabels];
                tmpList[index] = getLabelHTML(
                  "schedule",
                  null,
                  schedule?.scheduleName
                );
                setListOfLabels(tmpList);
                setScheduleSelectParams();
              },
              onCancel() {
                const { index } = scheduleSelectParams;
                form.setFieldsValue({
                  [`Schedule#${index}`]: "",
                  [`DropOffLocation#${index}`]: "",
                });
                let tmpList = [...listOfLabels];
                tmpList[index] = getLabelHTML();
                setListOfLabels(tmpList);
                setScheduleSelectParams();
                setFieldChanged(!fieldChanged);
              },
              projects: allProjects,
              rowData: scheduleSelectParams?.schedules,
              selectedDate: form.getFieldValue("date"),
            }}
          />
        )}

        {/**
         //#region DYNAMIC TEAM
         */}
        {teamsModalVisible && (
          <DynamicTeamModal
            {...{
              open: teamsModalVisible,
              setOpen: setTeamsModalVisible,
              selectedTeam,
              setSelectedTeam,
              isDarkMode,
              ClearOptions() {
                setTeamsModalVisible(false);
              },
              proppedAllTeams: allTeams,
              onSave: (data) => {
                form.setFieldValue(
                  "dispatchTeam",
                  getTeamsSelectionsFromItems(data)
                );
              },
            }}
          />
        )}

        {/**
         //#region TOUR
         */}
        {tourOpen && (
          <Tour
            open={tourOpen}
            onClose={() => setTourOpen(false)}
            steps={getTourSteps({
              programFields,
              setShowVideoTutorial,
              setTourOpen,
              setVideoTutorialLink,
              isDarkMode,
            })}
            mask={{ color: "#2a2b3a71" }}
          />
        )}

        {/**
         //#region TUTORIAL
         */}
        {showVideoTutorial && (
          <PlayVideoTutorial
            {...{
              visible: showVideoTutorial,
              setVisible: setShowVideoTutorial,
              url: videoTutorialLink,
              title: "Create Dispatch Tutorial",
            }}
          />
        )}

        {/**
         //#region LOGS
         */}
        {logsVisible && (
          <MultiLevelTreeLogs
            {...{
              visible: logsVisible,
              setVisible: setLogsVisible,
              logsData: logs || [],
              title: "Dispatch Logs",
            }}
          />
        )}

        {/**
         //#region PROGRESS
         */}
        {visibleCreationProgress && creationProgresses && (
          <ProgressComponent
            {...{
              categoryName: "Fleet Dispatch",
              actionType: obj?.dispatchId ? "Update" : "Create",
              visibleCreationProgress,
              creationProgresses,
              closeModal: () => {
                setVisibleCreationProgress(false);
              },
            }}
          />
        )}

        {/**
         //#region ITINERARY
         */}
        {openItinerary !== null && (
          <DispatchItineraryModal
            {...{
              itinerary: itineraryData[openItinerary],
              onCancel() {
                setOpenItinerary(null);
              },
              mapLoaded: isLoaded,
              onConfirm: onItineraryConfirm,
              staticLast: openItinerary >= routeLength,
              open: true,
              selectedDate: form.getFieldValue("date"),
              startLocation: form.getFieldValue(
                `${
                  openItinerary === routeLength ? "Return" : ""
                }PickupLocation#${
                  openItinerary === routeLength ? "" : openItinerary
                }`
              ),
              startTime: form.getFieldValue(
                `${openItinerary === routeLength ? "Return" : ""}DepartAt#${
                  openItinerary === routeLength ? "" : openItinerary
                }`
              ),
              endLocation: form.getFieldValue(
                `${
                  openItinerary === routeLength ? "Return" : ""
                }DropOffLocation#${
                  openItinerary === routeLength ? "" : openItinerary
                }`
              ),
              projects: allProjects,
              vendors,
              scheduleId:
                form.getFieldValue(`Schedule#${openItinerary}`) || null,
              projectId:
                openItinerary === routeLength
                  ? null
                  : !form.getFieldValue(`DropOffLocation#${openItinerary}`)
                  ? null
                  : allProjects?.find(
                      ({ projectName }) =>
                        projectName ===
                        form.getFieldValue(`DropOffLocation#${openItinerary}`)
                    )?.projectId || null,
              schedulesForDate,
              overlappingSchedules,
            }}
          />
        )}

        {/**
         //#region WARNING MODAL
         */}
        <WarningModal
          visible={!!cancelModalVisible}
          setVisible={() => {
            setCancelModalVisible("");
          }}
          title="Warning Message"
          closable={true}
          className="logout-warning-modal"
          darkMode={isDarkMode}
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            {cancelModalVisible === "delete" ? (
              <p>
                Are you sure you want to delete the dispatch and all it&apos;s
                activities?
              </p>
            ) : cancelModalVisible?.includes("cancelRoute") ? (
              <>
                <strong>Are you sure you want to cancel this route?</strong>
                <p style={{ textAlign: "center" }}>
                  This action can not be reversed.
                </p>
              </>
            ) : (
              <p>Are you sure you want to cancel</p>
            )}
            <div className="buttons">
              <MondayButton
                onClick={() => setCancelModalVisible("")}
                Icon={<XIcon />}
                className="mondayButtonRed"
                data-testid="warning-no"
              >
                No
              </MondayButton>
              <MondayButton
                data-testid="warning-yes"
                onClick={() => {
                  if (cancelModalVisible === "delete") {
                    deleteHandler();
                  } else if (cancelModalVisible?.includes("cancelRoute")) {
                    routeCancelHandler();
                  } else {
                    onCancel();
                  }
                }}
                Icon={<TickIcon />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      </>
    );
  }
);

export default NewDispatchModal;
