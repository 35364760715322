import { sidewalkShed } from "./sidewalkShed";
import { scaffold } from "./scaffold";
import { hoist } from "./hoist";
import { fence } from "./fence";
import { roofProtection } from "./roofProtection";
import { overheadProtection } from "./overheadProtection";
import { needleBeam } from "./needleBeam";
import { horizontalNetting } from "./horizontalNetting";
import { barriers } from "./barriers";
import { workingPlatform } from "./workingPlatforms";
import { systemScaffold } from "./systemScaffold";
import { stairTower } from "./stairTower";
import { shoring } from "./shoring";
import { plywoodEnclosure } from "./plywoodEnclosure";
import { debrisChute } from "./debrisChute";
import { turnStiles } from "./turnStiles";
import { hangingScaffold } from "./hangingScaffold";
import { verticalNetting } from "./verticalNetting";
import { windowProtection } from "./windowProtection";
import { platformScaffold } from "./platformScaffold";
import { elevatedRoofProtection } from "./elevatedRoofProtection";
import { winterTarp } from "./winterTarp";
import { crashDeck } from "./crashdeck";
import { conEdVaultProtection } from "./conEdVaultProtection";
import { heavyDuttyNetting } from "./heavyDuttyNetting";
import { mashNetting } from "./mashNetting";
import { roadPlates } from "./roadPlates";
import { systemStair } from "./systemStair";
import { engEnclosureSystem } from "./engEnclosureSystem";
import { treeProtection } from "./treeProtection";
import { heavyDuttyRamps } from "./heavyDutyRamps";
import { coreCanopy } from "./coreCanopy";
import { scaffoldMockup } from "./scaffoldMockup";
const servicesColumns = {
  1: sidewalkShed,
  2: scaffold,
  3: hoist,
  4: fence,
  5: roofProtection,
  6: overheadProtection,
  7: needleBeam,
  8: horizontalNetting,
  9: barriers,
  10: workingPlatform,
  11: systemScaffold,
  12: stairTower,
  13: shoring,
  14: plywoodEnclosure,
  15: debrisChute,
  16: turnStiles,
  17: hangingScaffold,
  18: verticalNetting,
  19: windowProtection,
  20: platformScaffold,
  44: elevatedRoofProtection,
  38: hoist,
  69: winterTarp,
  70: crashDeck,
  71: conEdVaultProtection,
  72: mashNetting,
  73: heavyDuttyNetting,
  79: roadPlates,
  80: systemStair,
  81: engEnclosureSystem,
  85: treeProtection,
  86: heavyDuttyRamps,
  89: coreCanopy,
  90: scaffoldMockup,
  // case 89 core canopy
};

export default servicesColumns;
