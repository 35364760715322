import dayjs from "dayjs";
import { Modal as AntModal, Popconfirm, message } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { MondayButton } from "../../../../../../commonComponents";
import { API } from "aws-amplify";
import { DeleteOutlined } from "@ant-design/icons";
import { useEditLogs } from "../../../../../../../hooks";
import { SmallLoadableComp } from "../../../../../../Sidebars/components";
import { gsiQueryTable } from "../../../../../../../utils";

const Modal = ({
  visible,
  setVisible,
  logsCategory,
  videoName,
  logsName,
  title,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const gridRef = useRef(); // Optional - for accessing Grid's API

  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { saveAddedLogs } = useEditLogs();

  const fetchLogs = async () => {
    const result = await gsiQueryTable({
      tableName: "editLogs",
      indexName: "recordId-index",
      filterKey: "recordId",
      filterValue: logsName,
    });

    setRowData(result);
    setLoading(false);
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  const labelValueGetter = (label) => (params) => {
    if (label === "user") {
      return params.data ? params.data.nameOfUser : null;
    } else if (label === "updatedAt") {
      const date = new Date(params.data.updatedAt);
      const localizedDateString = date.toLocaleString();
      return params.data ? localizedDateString : null;
    } else {
      return params?.data?.updatedKeys[0]?.[label] || null;
    }
  };

  // Each Column Definition results in one Column.
  const columnDefs = [
    { headerName: "Label", valueGetter: labelValueGetter("label") },
    {
      headerName: "Updated Value",
      valueGetter: labelValueGetter("updatedValue"),
    },
    {
      headerName: "User",
      valueGetter: labelValueGetter("user"),
    },
    {
      headerName: "Date & Time",
      field: "updatedAt",
      cellRenderer: ({ value }) => {
        return <span>{dayjs(value).format("MM/DD/YYYY HH:mm")}</span>;
      },
      resizable: false,
    },
  ];

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      suppressMenu: true,
      suppressMovable: false,
      flex: 1,
    }),
    []
  );

  // Example using Grid's API
  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById("filter-text-box").value
    );
  }, []);

  const generateLog = () => {
    saveAddedLogs({
      actionType: "Delete",
      category: "Video Tutorials",
      recordName: "Deleted",
      updatedKeys: [{ label: `Deleted All Logs of ${videoName}` }],
    });
  };

  const handleDeleteLogs = () => {
    const logsToDelete = rowData.map((log) => log.logId);
    message.loading({ content: "Deleting logs...", duration: 0 });

    API.del("editLogs", "/editLogs/123", {
      body: {
        logs: logsToDelete,
      },
    }).then(() => {
      generateLog();
      // setVisible(false);
      message.destroy();
      message.success("Logs deleted successfully!");
    });
  };

  return (
    <AntModal
      title={title}
      open={visible}
      onOk={null}
      onCancel={() => setVisible(false)}
      footer={null}
      centered
      width="56%"
      className={
        isDarkMode
          ? "documentationLogsModal dark-mode"
          : "documentationLogsModal"
      }
      maskClosable={false}
    >
      <div className="filter">
        <input
          type="text"
          id="filter-text-box"
          placeholder="Search..."
          onInput={onFilterTextBoxChanged}
          disabled={!!loading}
        />
        <Popconfirm
          title="Delete all logs"
          description="Are you sure to delete all logs?"
          onConfirm={handleDeleteLogs}
          okText="Yes"
          cancelText="No"
        >
          <MondayButton
            className="mondayButtonRed"
            disabled={!rowData.length || !!loading}
            Icon={<DeleteOutlined />}
          >
            Erase all logs
          </MondayButton>
        </Popconfirm>
      </div>
      <div
        style={{
          width: "100%",
          height: 500,
          marginTop: "16px",
          position: "relative",
        }}
        className={
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }
      >
        <SmallLoadableComp loading={loading}>
          <AgGridReact
            {...{
              ref: gridRef,
              rowData,
              columnDefs,
              defaultColDef,
              pagination: true,
              paginationAutoPageSize: true,
              animateRows: true,
            }}
          />
        </SmallLoadableComp>
      </div>
    </AntModal>
  );
};

export default Modal;
