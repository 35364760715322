import {
  addons,
  dispatching,
  estimations,
  fleet,
  fleetInspections,
  leads,
  opportunities,
  projects,
  scheduling,
  violations,
  applications,
  rentals,
  charges,
  invoices,
  claims,
  incidents,
  safety,
  todos,
  projectCost,
  inspections,
  citations,
} from "./subCategory";

export const statusProgress = (
  IdHandler,
  updatedDynamicStates,
  programFields,
  applicationsData,
  rentalsData,
  chargesData,
  invoicesData,
  incidentsData,
  projectsData,
  jobsites
) => {
  console.log("statusProgresss", {
    IdHandler,
    updatedDynamicStates,
  });

  return {
    Leads: () => leads(updatedDynamicStates, IdHandler, programFields), //
    Claims: () => claims(updatedDynamicStates, IdHandler, programFields), //
    Incidents: () => incidents(updatedDynamicStates, IdHandler, programFields), //
    Opportunities: () =>
      opportunities(
        updatedDynamicStates,
        IdHandler,
        programFields,
        projectsData
      ), //<=
    Projects: () => projects(updatedDynamicStates, IdHandler, programFields), //
    Scheduling: () =>
      scheduling(updatedDynamicStates, IdHandler, programFields), //
    Dispatching: () => dispatching(updatedDynamicStates, IdHandler), //
    Fleet: () => fleet(updatedDynamicStates, IdHandler),
    "Fleet Inspections": () =>
      fleetInspections(updatedDynamicStates, IdHandler, programFields),
    Violations: () => violations(updatedDynamicStates, IdHandler), //
    Estimations: () =>
      estimations(updatedDynamicStates, IdHandler, programFields), //
    "Add-ons": () => addons(updatedDynamicStates, IdHandler), //
    Applications: () =>
      applications(
        updatedDynamicStates,
        IdHandler,
        programFields,
        applicationsData
      ), //
    Rentals: () =>
      rentals(updatedDynamicStates, IdHandler, programFields, rentalsData), //
    Charges: () =>
      charges(updatedDynamicStates, IdHandler, programFields, chargesData), //
    Invoices: () =>
      invoices(updatedDynamicStates, IdHandler, programFields, invoicesData), //
    Safety: () => safety(updatedDynamicStates, IdHandler, programFields), //
    "To Do": () => todos(updatedDynamicStates, IdHandler, programFields), //
    "Project Cost": () =>
      projectCost(IdHandler, programFields, updatedDynamicStates, jobsites), //
    Inspections: () =>
      inspections(updatedDynamicStates, IdHandler, programFields), //
    Citations: () => citations(updatedDynamicStates, IdHandler, programFields),
  };
};
