import { TickIcon } from "../../../Roles/src";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { MondayButton } from "../../../../../../commonComponents";

export const footerButtons = ({
  saveRef,
  onCancel,
  cancelRef,
  handleUpdate,
  disabledActions,
}) => {
  return [
    <div className="footerButtons">
      <div ref={cancelRef}>
        <MondayButton
          Icon={<XIcon />}
          onClick={onCancel}
          disabled={disabledActions}
          className="mondayButtonRed"
        >
          Cancel
        </MondayButton>
      </div>
      <div ref={saveRef}>
        <MondayButton
          onClick={handleUpdate}
          disabled={disabledActions}
          Icon={<TickIcon height={17} width={17} />}
        >
          Save Changes
        </MondayButton>
      </div>
    </div>,
  ];
};

export function employeeFields(isDarkMode, role, status, jobsites) {
  return [
    {
      type: "input",
      required: true,
      label: "Full Name",
      placeholder: "Name...",
      formItemName: "crewName",
      dataTestid: "input-name",
      className: "crewNameUpdate",
    },
    {
      label: "Role",
      type: "select",
      required: true,
      dataTestid: "select-role",
      formItemName: "crewPosition",
      placeholder: "Select role...",
      className: "crewPositionSelect",
      getPopUpContainer: document.body,
      popupClassName: isDarkMode && "darkDropDown",
      customOptions: (role || [])?.map(({ statusName }, key) => ({
        key,
        label: statusName,
        value: statusName,
      })),
    },
    {
      type: "select",
      required: true,
      label: "Employee Type",
      formItemName: "foreman",
      getPopUpContainer: document.body,
      placeholder: "Select employee type...",
      customOptions: [
        { key: 0, label: "Member", value: false },
        { key: 1, label: "Foreman", value: true },
      ],
    },
    {
      type: "select",
      required: true,
      label: "Status",
      formItemName: "crewStatus",
      getPopUpContainer: document.body,
      placeholder: "Select employee status...",
      customOptions: (status || []).map(({ statusName }, key) => {
        return {
          key,
          label: statusName,
          value: statusName,
        };
      }),
    },
    {
      type: "select",
      allowClear: true,
      label: "Default Job",
      formItemName: "defaultJob",
      placeholder: "Select job...",
      customOptions: (jobsites || []).map((job, key) => ({
        key,
        value: job?.jobsiteId,
        label: `${job?.jobName} (${job?.accountName?.accountName})`,
      })),
    },
    {
      type: "input",
      required: true,
      addonBefore: "$",
      typeNumber: true,
      label: "Pay Rate",
      placeholder: "Pay rate...",
      formItemName: "employeeRate",
    },
    {
      type: "select",
      required: true,
      label: "Salary Type",
      formItemName: "salaryType",
      getPopUpContainer: document.body,
      placeholder: "Select salary type...",
      popupClassName: isDarkMode && "darkDropDown",
      customOptions: [
        {
          key: 0,
          label: "Hourly",
          value: "Hourly",
        },
        {
          key: 1,
          label: "Salary",
          value: "Salary",
        },
      ],
    },
    {
      type: "input",
      required: true,
      label: "Employee ID",
      formItemName: "employeeId",
      className: "employeeIdEdit",
      placeholder: "Employee Id...",
    },
    {
      type: "input",
      formItemName: "fingerCheckId",
    },
    {
      type: "input",
      formItemName: "employeeNumber",
    },
  ];
}

const isUnique = (form, fieldName, value) => {
  // Get the existing values for the given field
  const existingValues = form
    .getFieldsValue()
    .emergencyContact.map((item) => item[fieldName]);

  // Find the current value index
  const currentIndex = form
    .getFieldValue("emergencyContact")
    ?.findIndex((item) => item[fieldName] === value);

  // If the value exists in the form, remove it temporarily to avoid comparing it with itself
  if (currentIndex !== -1) {
    existingValues.splice(currentIndex, 1);
  }

  // Check if the value already exists in the form field
  return existingValues.includes(value);
};

export function employeeEmergencyContactFields({ form }) {
  return {
    disabled: false,
    type: "MultipleInputs",
    label: "Emergency Contact",
    formItemName: "emergencyContact",
    dynamicFields: [
      {
        type: "Input",
        disabled: false,
        label: "Full Name",
        formItemName: "fullName",
        rules: [
          { required: true, message: "Full Name is required" },
          {
            validator: async (_, value) => {
              if (!value || value.trim() === "") {
                return Promise.reject(new Error("Full Name cannot be empty"));
              }

              const isFullNameUnique = !isUnique(form, "fullName", value);
              if (!isFullNameUnique) {
                return Promise.reject(new Error("Full Name must be unique"));
              }
              return Promise.resolve();
            },
          },
        ],
      },
      {
        type: "Email",
        label: "Email",
        disabled: false,
        formItemName: "email",
        rules: [
          { required: true, message: "Email is required" },
          {
            pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: "Please enter a valid email address",
          },
          {
            validator: async (_, value) => {
              if (!value || value.trim() === "") {
                return Promise.reject(new Error("Email cannot be empty"));
              }

              const isEmailUnique = !isUnique(form, "email", value);
              if (!isEmailUnique) {
                return Promise.reject(new Error("Email must be unique"));
              }
              return Promise.resolve();
            },
          },
        ],
      },
      {
        disabled: false,
        type: "PhoneNumber",
        label: "Phone Number",
        formItemName: "phoneNumber",
        rules: [
          { required: true, message: "Phone number is required" },
          {
            pattern: /^\+1 \(\d{3}\) \d{3}-\d{4}$/,
            message: "Please enter a valid phone number",
          },
          {
            validator: async (_, value) => {
              const isEmailUnique = !isUnique(form, "phoneNumber", value);
              if (!isEmailUnique) {
                return Promise.reject(new Error("Phone Number must be unique"));
              }
              return Promise.resolve();
            },
          },
        ],
      },
      {
        rules: [{ required: true, message: "Relationship is required" }],
        disabled: false,
        type: "ComboBox",
        label: "Relationship",
        formItemName: "relationship",
        options: ["Parent", "Spouse", "Sibling", "Child", "Friend"],
      },
    ],
  };
}
