import addElevationStyles from "../../../../../../../pages/Settings/settingsComponents/ProposalBuilder/components/EditTemplate/EditGeneral/ElevationHtmlConfiguration/helpers/addElevationStyles";
import { checkIfRowHasValidData } from "../../../../tools/formatters/validators";
import { getElevationExtras, getPLItem, getServiceExtras } from "./process";

export const proposalServiceHTML = (
  gridData,
  serviceDefs,
  toAdd,
  styles,
  showIds
) => {
  // console.log("gridData.forEach ~ service:", { gridData, styles });
  if (Array.isArray(gridData) && gridData?.length > 0) {
    let phases = gridData?.map((el) => el?.phase);
    gridData.forEach((service) => {
      let serviceDataHtml = [];
      let serviceName = service?.label;
      let styleToUse = styles?.[serviceName] || null;
      let serviceDef = serviceDefs.find(
        (def) => def?.workId?.toString() === service?.serviceId?.toString()
      );

      // here i am going to add the phase
      if (
        !!service?.phase &&
        service?.phase !== "N/A" &&
        phases?.includes(service?.phase)
      ) {
        serviceDataHtml.push(
          `<p style="padding-left: 40px;"><span style="font-size: 14pt; background-color: ${`#F6CB51`}; color: #fff;">${
            service?.phase
          }</span></p>`
        );
        phases = phases.filter((el) => el !== service?.phase);
      }

      // First we add the name
      // serviceDataHtml.push(
      //   getServiceNameAccordingly(service, serviceDef, false)
      // );

      service?.serviceOptions.forEach((option, optionIndex) => {
        // Case where we have alternate elevation phase
        // if (optionIndex === 1) {
        //   serviceDataHtml.push(
        //     getServiceNameAccordingly(service, serviceDef, true)
        //   );
        // }
        if (Array.isArray(option)) {
          if (styleToUse) {
            const elevationsStyles = addElevationStyles(
              option,
              styleToUse,
              service?.serviceId
            );
            serviceDataHtml.push(elevationsStyles);
          } else {
            // Add the Elevation Name
            option.forEach((elevation) => {
              serviceDataHtml.push(
                getElevationName(
                  elevation?.elevationLabel,
                  elevation?.elevationId,
                  showIds
                )
              );
              // Navigate through each PLI
              let PLIs = elevation?.items || [];
              if (Array.isArray(PLIs) && PLIs?.length > 0) {
                PLIs.forEach((pli, idx) => {
                  if (checkIfRowHasValidData(pli) === false) return;
                  serviceDataHtml.push(
                    getPLItem(pli, service?.serviceId, service, idx, showIds)
                  );
                });
              }
              getElevationExtras(serviceDataHtml, elevation, service);
            });
          }

          // since for each service we will have different particulates
          // And Hoist differs more than others we have to differentiate
        }
      });

      getServiceExtras(serviceDataHtml, service);

      // Lastly we add it to the main array
      toAdd.push(serviceDataHtml.join(""));
    });
  }
};

const getServiceNameAccordingly = (service, serviceDef) => {
  let isAlternate = service?.optionIndex === 1;
  if (isAlternate !== true) {
    return `<p style="padding-left: 40px;"><span style="font-size: 14pt; background-color: ${
      serviceDef?.wordDocColor || serviceDef?.colorCode || "#000"
    }; color: #fff;">${service?.label}</span></p>`;
  } else {
    return `<p style="padding-left: 40px;"><span style="font-size: 14pt; background-color: ${
      serviceDef?.wordDocColor || serviceDef?.colorCode
    }; color: #fff;">${
      service?.label
    }</span><span style="color: #e03e2d; font-size: 14pt; font-weight: bold; background-color: yellow;">- Alternate Phase</span></p>`;
  }
};

const getElevationName = (elevation, id = "", showIds) => {
  return `<p style="padding-left: 80px;"><strong><u>─ ${elevation} ${
    showIds ? id : ""
  }</u></strong></p>
    `;
};
