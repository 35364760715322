import { useState } from "react";
import { message } from "antd";
import { HistoryOutlined } from "@ant-design/icons";
import { useEditLogs } from "../../../hooks";
import MondayButton from "../MondayButton/MondayButton";
import MultiLevelTreeLogs from "../MultiLevelTreeLogs/MultiLevelTreeLogs";

const LogsButton = ({
  buttonLabel,
  logsTitle = "Logs",
  filtersObj = {},
  tooltipCategory,
  tooltipKey,
  onFetchedLogs,
  className = "mondayButtonBlue",
  type = "default",
  children = null,
}) => {
  const [showLogsModal, setShowLogsModal] = useState(false);

  const { fetchedLogs, setFetchedLogs, handleFetchLogs, loading, setLoading } =
    useEditLogs();

  const handleShowLogs = () => {
    setLoading(true);
    const hideLoading = message.loading("Fetching logs...", 0);

    const filters = [
      {
        conditions: Object.entries(filtersObj).map(([column, value]) => ({
          column,
          value,
          formula: "is",
          operator: "AND",
        })),
      },
    ];

    handleFetchLogs({ filters }).then((response) => {
      setFetchedLogs(response);
      setShowLogsModal(true);
      setLoading(false);
      hideLoading();

      if (onFetchedLogs) {
        onFetchedLogs(response ?? []);
      }
    });
  };

  const ui = {
    onlyIcon: (
      <HistoryOutlined
        style={{
          fontSize: "18px",
          color: "#1264A3",
          cursor: "pointer",
        }}
        onClick={handleShowLogs}
      />
    ),
    onlyText: (
      <div
        onClick={handleShowLogs}
        style={{
          color: "#1264A3",
          cursor: "pointer",
        }}
      >
        Logs
      </div>
    ),
    default: (
      <MondayButton
        className={className}
        Icon={<HistoryOutlined />}
        onClick={handleShowLogs}
        tooltipCategory={tooltipCategory}
        tooltipKey={tooltipKey}
      >
        {buttonLabel}
      </MondayButton>
    ),
    custom: children ? children(handleShowLogs) : <></>,
  };

  return (
    <>
      {ui[type]}
      {!loading ? (
        <MultiLevelTreeLogs
          visible={showLogsModal}
          setVisible={setShowLogsModal}
          logsData={fetchedLogs?.sort((a, b) => a.updatedAt - b.updatedAt)}
          title={logsTitle}
          onLogsErase={() => setFetchedLogs([])}
        />
      ) : null}
    </>
  );
};

export default LogsButton;
