import { Modal } from "antd";
import { HoverButton, RedWarningModal } from "../../../../../commonComponents";
import { useMemo, useState } from "react";
import ReportPreviewCard from "../../ReportPreviewCard/ReportPreviewCard";
import { useSelector } from "react-redux";
import { XIcon } from "../../../../Communication/assets";
import "./ChooseReport.scss";

function ChooseReport({
  reportConfiguration,
  report,
  setReport,
  categoryName,
  customCategoryName,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [visible, setVisible] = useState(false);
  const { preferences: allPreferences } = useSelector(
    (state) => state.preferences
  );

  const defaultReport = useMemo(() => {
    const tmp = reportConfiguration?.reportsAvailable?.find(
      (r) =>
        r.reportName === customCategoryName ||
        r.reportName === categoryName + "Report"
    );
    if (!tmp || report?.reportId === tmp.reportId) {
      return null;
    } else {
      return tmp;
    }
  }, [report?.reportId, reportConfiguration, categoryName, customCategoryName]);

  const finalReportPrefs = allPreferences?.preferences?.reportPreferences || {};

  const otherReports = useMemo(() => {
    const filtered =
      reportConfiguration?.reportsAvailable?.filter((r) => {
        if (r.useInAutomation) {
          return false;
        }

        if (r?.reportType === "multiple") {
          return false;
        }

        return r?.reportId !== report?.reportId;
      }) || [];

    if (defaultReport?.reportId) {
      return (
        filtered?.filter((r) => r?.reportId !== defaultReport?.reportId) || []
      );
    }

    return filtered || [];
  }, [reportConfiguration, defaultReport, report]);
  return (
    <>
      <HoverButton
        {...{
          alwaysShowIcon: true,
          type: "new",
          text: "Choose other report",
          onClick: () => setVisible(true),
        }}
      />
      {visible && (
        <Modal
          {...{
            open: visible,
            onCancel: () => setVisible(false),
            title: "Choose another report",
            closeIcon: <XIcon />,
            footer: false,
            closable: true,
            centered: true,
            destroyOnClose: true,
            className: `choose-reports-modal ${
              isDarkMode && "choose-reports-modal-dark"
            }`,
          }}
        >
          <div className="general-reports-cards">
            {defaultReport && (
              <div className="default-report">
                <span>Default Report</span>
                <ReportPreviewCard
                  key={defaultReport.reportId}
                  report={defaultReport}
                  onClick={() => {
                    setReport(defaultReport);
                    setVisible(false);
                  }}
                  isFavorite={finalReportPrefs?.[defaultReport.reportId]}
                  onFavoriteClick={() => {}}
                  hideActions={true}
                />
              </div>
            )}
            {otherReports.map((r, i) => (
              <ReportPreviewCard
                key={r.reportId}
                report={r}
                onClick={() => {
                  setReport(r);
                  setVisible(false);
                }}
                isFavorite={finalReportPrefs?.[r.reportId]}
                onFavoriteClick={() => {}}
              />
            ))}
          </div>
        </Modal>
      )}
    </>
  );
}

export default ChooseReport;
