import { fetchAllData } from "../ApiMethods";

const otherKeys = ["chargeItems", "categoryFrom", "chargeType", "chargeAmount"];

// optimized report fetch data
export const getCreditMemoServices = async ({ keysToInclude }) => {
  const creditMemos = await fetchAllData({
    endpoint: "charges",
    resultId: "chargeId",
    otherStringParams: {
      ...(keysToInclude.length && {
        keysToInclude: JSON.stringify([
          ...new Set(["chargeId", ...keysToInclude, ...otherKeys]),
        ]),
      }),
      filters: JSON.stringify([
        {
          conditions: [
            { formula: "is", column: "chargeType", value: "Credit Memo" },
          ],
        },
      ]),
    },
  });

  return creditMemos.map((credit) => ({
    ...credit,
    creditAmount: credit.chargeAmount,
    totalPrice: credit.chargeItems?.reduce(
      (acc, item) => acc + (item?.totalities?.totalPrice || item.total),
      0
    ),
    totalTax: credit.chargeItems?.reduce(
      (acc, item) => acc + (item?.totalities?.includedTaxes || item.taxAmount),
      0
    ),
    items: credit.chargeItems?.map((item) => ({
      ...credit,

      serviceName: item?.name || item.label,
      category: item?.category || credit.categoryFrom,
      amount: item?.amount || item?.totalities?.totalPrice,
      creditAmount: item?.creditAmount || item.price,
      creditPercentage:
        (item?.creditPercentage ||
          (item.price + item.taxAmount) / item?.totalities?.totalPrice) * 100,
      taxAmount: item.taxAmount,
    })),
  }));
};
