import { AutoComplete } from "antd";
import { useState, useCallback } from "react";
import _ from "lodash";
import { NYC_CENTER } from "../../../../commonComponents/Map/mapData";
import "./PlacesInputWithCoordinates.scss";

const PlacesInputWithCoordinates = ({
  value,
  form,
  name,
  disabled,
  isDarkMode,
  placeholder,
}) => {
  const [predictions, setPredictions] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(value?.address || "");
  const [autocompleteService] = useState(
    new google.maps.places.AutocompleteService()
  );
  const [geocoder] = useState(new google.maps.Geocoder());

  const onSearch = useCallback(
    async (searchText) => {
      if (!searchText || !autocompleteService) return;

      try {
        const response = await autocompleteService.getPlacePredictions({
          input: searchText,
          language: "en-US",
          locationBias: {
            center: NYC_CENTER,
            radius: 200000,
          },
        });

        setPredictions(response?.predictions || []);
      } catch (err) {
        console.error("Place search error:", err);
        setPredictions([]);
      }
    },
    [autocompleteService]
  );

  const onSelect = async (selectedAddress) => {
    setSelectedAddress(selectedAddress);

    try {
      const result = await geocoder.geocode({ address: selectedAddress });

      if (result.results?.[0]?.geometry?.location) {
        const location = result.results[0].geometry.location;

        const value = {
          address: selectedAddress,
          coordinates: {
            lat: location.lat(),
            lng: location.lng(),
          },
        };

        if (form && name) {
          form.setFieldsValue({
            [name]: value,
          });
        }
      }
    } catch (error) {
      console.error("Geocoding error:", error);
    }
  };

  const handleBlur = () => {
    if (form && name && selectedAddress) {
      form.setFieldsValue({
        [name]: {
          address: selectedAddress,
          coordinates: form.getFieldValue(name)?.coordinates || null,
        },
      });
    }
    setPredictions([]);
  };

  const handleClear = () => {
    setSelectedAddress("");
    setPredictions([]);
    if (form && name) {
      form.setFieldsValue({
        [name]: { address: "", coordinates: null },
      });
    }
  };

  const onChange = (value) => {
    setSelectedAddress(value);
    if (form && name) {
      form.setFieldsValue({
        [name]: {
          address: value,
          coordinates: null,
        },
      });
    }
  };

  return (
    <div className={`EditPlacesInput ${isDarkMode && "EditPlacesInputDark"}`}>
      <AutoComplete
        value={selectedAddress}
        style={{ width: "100%" }}
        onSearch={_.debounce(onSearch, 300)}
        onSelect={onSelect}
        onChange={onChange}
        onBlur={handleBlur}
        disabled={disabled}
        placeholder={placeholder}
        allowClear
        onClear={handleClear}
        options={predictions.map((prediction) => ({
          value: prediction.description,
          label: prediction.description,
        }))}
        className="EditPlacesInputComponent"
        showSearch
        popupMatchSelectWidth={false}
      />
    </div>
  );
};

export default PlacesInputWithCoordinates;
