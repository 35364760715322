import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import weekOfYear from "dayjs/plugin/weekOfYear";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(isSameOrBefore);
dayjs.extend(weekOfYear);
dayjs.extend(customParseFormat);

/**
 * Function that sets the timezone offset correcting for daylight saving
 * @param {dayjs.Dayjs|number|string} value
 * @param {string} [customFormat]
 * @return {"Etc/GMT+4"|"Etc/GMT+5"}
 */
export function getOffsetTz(value, customFormat) {
  const date = dayjs(value, customFormat);

  const marchIterator = new Date(date.year(), 2, 1);
  const novemberIterator = new Date(date.year(), 10, 1);

  let edtStart,
    edtEnd,
    firstSundayFound = false;

  while (!edtStart) {
    if (!marchIterator.getDay()) {
      if (!firstSundayFound) {
        firstSundayFound = true;
      } else {
        edtStart = marchIterator.getDate();
        break;
      }
    }

    marchIterator.setDate(marchIterator.getDate() + 1);
  }

  while (!edtEnd) {
    if (!novemberIterator.getDay()) {
      edtEnd = novemberIterator.getDate();
      break;
    }

    novemberIterator.setDate(novemberIterator.getDate() + 1);
  }

  const dateMonth = date.month();
  const marchMonth = marchIterator.getMonth();
  const novemberMonth = novemberIterator.getMonth();

  const dateDate = date.date();
  const marchDate = marchIterator.getDate();
  const novemberDate = novemberIterator.getDate();

  let isEdt = false;
  if (dateMonth > marchMonth && dateMonth < novemberMonth) {
    isEdt = true;
  } else if (dateMonth === marchMonth) {
    if (dateDate > marchDate) {
      isEdt = true;
    }
  } else if (dateMonth === novemberMonth) {
    if (dateDate < novemberDate) {
      isEdt = true;
    }
  }

  return isEdt ? "Etc/GMT+4" : "Etc/GMT+5";
}

export const dayjsNY = (date, customFormat = undefined) => {
  return dayjs.utc(date, customFormat).tz(getOffsetTz(date, customFormat));
};

export const dayjsNYFixed = (date, customFormat = undefined) => {
  return dayjs.utc(date, customFormat).tz("America/New_York");
};

export const dayjsNYTimestamp = (date) => {
  /* You should use format in order to convert time correctly to timezone */
  return new Date(dayjsNY(date).format("YYYY-MM-DD HH:mm:ss")).getTime();
};

export const dayjsNYDate = (date) => {
  const day = dayjs(date).add(6, "hour");
  return dayjs.utc(day).tz(getOffsetTz(date));
};
