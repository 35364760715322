import { fetchAllData, fetchData } from "../ApiMethods";

function groupEstimationsByLabel(estimations) {
  const groupedEstimations = [];

  estimations.forEach((estimation) => {
    const {
      jobSiteAddress,
      estimationId,
      estSTATUS,
      estimationNumber,
      versionServices,
      isChangeOrder,
    } = estimation;

    // Iterate through the versionServices object keys
    Object.keys(versionServices).forEach((versionId) => {
      const versionService = versionServices[versionId];

      if (
        versionService &&
        versionService.services &&
        Array.isArray(versionService.services)
      ) {
        versionService.services.forEach((service) => {
          const { label, totalities, taxRate } = service;

          groupedEstimations.push({
            estimationId,
            jobSiteAddress,
            estSTATUS,
            estimationNumber,
            isChangeOrder,
            createdAt: versionService.createdAt,
            service: label,
            price: totalities?.servicePrice?.price || 0,
            tax: totalities?.servicePrice?.tax || 0,
            taxRate: taxRate || 0,
            additionalRent: totalities?.additionalRent || 0,
            scopeAmount: totalities?.includedScope?.scopeAmount || 0,
            scopeTax: totalities?.includedScope?.scopeTax || 0,
            serviceStatus: versionService.status,
            versionName: versionService.versionName,
            totalPrice:
              (totalities?.totalPrice || 0) + (totalities?.includedTaxes || 0),
          });
        });
      }
    });
  });

  return groupedEstimations;
}

export const getTakeOffServicesAndCompare = async ({ recordId }) => {
  let estimations = [];

  if (recordId) {
    estimations = [
      await fetchData({
        endpoint: "estimations",
        query: `estimations/${recordId}`,
      }),
    ];
  } else {
    estimations = await fetchAllData({
      endpoint: "estimations",
      resultId: "estimationId",
      otherStringParams: {
        keysToInclude: JSON.stringify([
          "estimationId",
          "jobSiteAddress",
          "estSTATUS",
          "estimationNumber",
          "versionServices",
          "isChangeOrder",
        ]),
      },
    });
  }

  const groupedEstimations = groupEstimationsByLabel(estimations);
  return groupedEstimations;
};
