import NewCardWidget from "./NewCardWidget/NewCardWidget";
import PublicPrivateCards from "./PublicPrivateCards/PublicPrivateCards";
import ReloadCards from "./ReloadCards/ReloadCards";
import HiddenCards from "./HiddenCards/HiddenCards";
import FilterCards from "./FilterCards/FilterCards";
import SearchCards from "./SearchCards/SearchCards";
import SaveCardChanges from "./SaveCardChanges/SaveCardChanges";
import ExportChartsAsPdf from "./ExportChartsToPdf/ExportChartsAsPdf";
import "./SidebarActions.scss";
import { useChartCardsContext } from "../../ChartCardsContext";

const SidebarActions = () => {
  const { filterPeriod, isAdmin, showAddBtn } = useChartCardsContext();

  return (
    <div className="chart-cards-sidebar-actions">
      {isAdmin && !!showAddBtn && <NewCardWidget />}
      {!!showAddBtn && <PublicPrivateCards />}
      <ReloadCards />
      <HiddenCards />
      {!!filterPeriod && <FilterCards />}
      <SearchCards />
      {isAdmin && <SaveCardChanges />}
      <ExportChartsAsPdf />
    </div>
  );
};

export default SidebarActions;
