import { fetchAllData } from "../ApiMethods";

export const getSOVComparisonWithAccounting = async ({ keysToInclude }) => {
  let [scheduleOfValuesRes, chargesRes, requisitionsRes] = await Promise.all([
    fetchAllData({
      endpoint: "scheduleOfValues",
      resultPosition: "schedules",
      resultId: "scheduleId",
      otherStringParams: {
        keysToInclude: JSON.stringify([
          ...new Set(["scheduleId", "projectId", ...keysToInclude]),
        ]),
      },
    }),
    fetchAllData({
      endpoint: "charges",
      resultId: "chargeId",
      otherStringParams: {
        keysToInclude: JSON.stringify([
          "chargeId",
          "projectId",
          "categoryFrom",
          "chargeItems",
        ]),
      },
    }),
    fetchAllData({
      endpoint: "applications",
      resultId: "applicationId",
      otherStringParams: {
        keysToInclude: JSON.stringify([
          "applicationId",
          "scheduleId",
          "services",
        ]),
      },
    }),
  ]);

  const result = scheduleOfValuesRes.map(({ services = [], ...schedule }) => {
    const charges = chargesRes.filter(
      (charge) =>
        charge.projectId === schedule.projectId &&
        charge.categoryFrom === "Requisition"
    );

    const requisitions = requisitionsRes.filter(
      (requisition) => requisition.scheduleId === schedule.scheduleId
    );

    return {
      ...schedule,
      services: services.map((service) => ({
        ...schedule,
        serviceName: service.label,
        estimationId: service.estimationId,
        totalSovAmount: service.totalities?.totalPrice || 0,
        totalRequisitionsAmount: requisitions.reduce(
          (sum, requisition) =>
            (sum += requisition.services
              .filter((reqService) => reqService.label === service.label)
              .reduce(
                (acc, item) =>
                  (acc +=
                    item.amounts.reduce(
                      (itemSum, amount) => (itemSum += amount.paymentDue),
                      0
                    ) || 0),
                0
              )),
          0
        ),
        totalChargesAmount: charges.reduce(
          (acc, charge) =>
            (acc += charge.chargeItems
              .filter((chargeItem) => chargeItem.label === service.label)
              .reduce(
                (sum, item) => (sum += item.totalities?.totalPrice || 0),
                0
              )),
          0
        ),
      })),
    };
  });

  return result;
};
