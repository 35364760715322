import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDeviceType, useFieldOptionFinder } from "../../../hooks";

import MobileFiltersPanel, {
  checkForShowCheckbox,
} from "./components/MobileFiltersPanel";

import MobileGridTable from "./components/MobileGridTable";
import MobileStatusPanel from "./components/MobileStatusPanel";

import "./MobileBasePage.scss";
import {
  createColumnDefsFromDeviceConfigs,
  defaultColumnDefsBasePage,
} from "../../pages/Settings/settingsComponents/DeviceManagementV2/utils";
import { filterTables } from "../../../utils";
import { useSelector } from "react-redux";
import { LoadableComp } from "../../SidebarPages/XComponents";
import {
  adaptedTitlesForStatuses,
  adaptedTitlesForStatusesPanel,
  missingPanelObjects,
} from "./utils";
import { sortBy } from "lodash";
import { useLocation } from "react-router-dom";
import { dateFilterOptions } from "../../SidebarPages/XComponents/ControlPanel/datas";

function removeDynamicDigitPart(url) {
  if (typeof url !== "string") {
    console.error("removeDynamicDigitPart expects a string, received:", url);
    return url; // Return the input as is if it's not a string
  }
  return url.replace(/\/[^\/?]+\b[0-9a-fA-F\-]+\b/, "");
}

function findMainTitle(path = "", sections = []) {
  let mainTitle = null;
  const search = (items, parentTitle = null) => {
    for (const item of items) {
      if (item.path === path) {
        mainTitle = parentTitle || item.title;
        return true;
      }
      if (item.tabs) {
        if (search(item.tabs, parentTitle || item.title)) {
          return true;
        }
      }
    }
    return false;
  };
  search(sections);
  return mainTitle;
}

export const MobileBasePageContext = createContext();

const MobileBasePage = ({
  title = "",
  rowData = [],
  columnDefs = [],
  fetchAllHandler = null,
  hasFetchSearchFromDb = false,
  reloadData = null,
  filterSearchData = null,
  optionButton = false,
  customStatusPanel = false,
  creationModalOpener = null,
}) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const location = useLocation();

  const panelObjectPF =
    useFieldOptionFinder({
      fieldName: "Base Page Panel Object",
    }) || {};

  const panelObject = useMemo(() => {
    return Object.entries(panelObjectPF).reduce((acc, [key, value]) => {
      acc[key.toLowerCase()] = value;
      return acc;
    }, {});
  }, [panelObjectPF]);

  const { categories = [] } =
    useFieldOptionFinder({
      fieldName: "Device Management",
    }) || {}; // used for device configs

  const statusesFromPF = useFieldOptionFinder({ fieldName: "Status colors" });

  const pathName = useMemo(() => {
    const { pathname, search } = location;
    return pathname + search;
  }, [location]);

  const currentCategory = useMemo(() => {
    const findedTitle =
      adaptedTitlesForStatusesPanel[pathName] ||
      adaptedTitlesForStatusesPanel[title];
    const finalTitle = findedTitle
      ? findedTitle?.toLowerCase()
      : title?.toLowerCase();
    return panelObject?.[finalTitle] || missingPanelObjects?.[finalTitle] || {};
  }, [panelObject, pathName, title]);

  const statuses = useMemo(() => {
    const proceedTitle = adaptedTitlesForStatuses[title] || title;
    const statusesOfCategory = statusesFromPF?.[proceedTitle] || {};
    return statusesOfCategory;
  }, [statusesFromPF, pathName, title]);

  const panelFilterObjectStuff = useMemo(() => {
    const hasMoreThanOneKeyToSwitch = checkForShowCheckbox(currentCategory);
    const toArray = Object.keys(currentCategory);
    const modified = hasMoreThanOneKeyToSwitch
      ? currentCategory?.[toArray?.[0]]
      : currentCategory;
    return {
      label: modified?.label,
      key: modified?.key || "",
      checked: false,
    };
  }, [currentCategory]);

  const [rowDataState, setRowDataState] = useState(rowData);
  const [searchedData, setSearchedData] = useState([]);
  const [selectedCard, setSelectedCard] = useState();
  const [otherFilters, setOtherFilters] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [creationModal, setCreationModal] = useState(false);
  const [deviceManagementConfigs, setDeviceManagementConfigs] = useState([]); // used for device configs
  const [trackDeviceManagement, setTrackDeviceManagement] = useState(""); // used for device configs
  const [panelFilter, setPanelFilter] = useState(panelFilterObjectStuff);
  const [newButtonData, setNewButtonData] = useState(null);
  const [loadingImports, setLoadingImports] = useState(true);

  useEffect(() => {
    if (creationModalOpener) {
      setLoadingImports(false);
      return;
    }
    const loadButtonData = async () => {
      const data = await import(
        "../../SidebarPages/BasePage/BasePageData/newButtonData"
      );
      setLoadingImports(false);
      setNewButtonData(data.default);
    };

    loadButtonData();
  }, []);

  useEffect(() => {
    setRowDataState(rowData);
  }, [rowData]);

  const handleChangePanelFilter = useCallback(
    (event) => {
      if (event) {
        const toArray = Object.keys(currentCategory);
        const modified = currentCategory?.[toArray?.[1]];
        setPanelFilter({
          label: modified?.label,
          key: modified?.key || "",
          checked: true,
        });
      } else {
        setPanelFilter(panelFilterObjectStuff);
      }
    },
    [currentCategory]
  );

  const rowDataWithFilters = useMemo(() => {
    const keyOfCategoryStatus = panelFilter?.key;
    if (selectedCard) {
      const { statusName } = selectedCard;
      if (keyOfCategoryStatus === "createdAt") {
        return rowDataState.map((row) =>
          parseInt(row[keyOfCategoryStatus]) >=
            dateFilterOptions[statusName][0].valueOf() &&
          parseInt(row[keyOfCategoryStatus]) <=
            dateFilterOptions[statusName][1].valueOf()
            ? { ...row, isFiltered: true }
            : row
        );
      }
      return rowDataState.map((row) =>
        row[keyOfCategoryStatus] === statusName
          ? { ...row, isFiltered: true }
          : row
      );
    } else {
      return rowDataState;
    }
  }, [selectedCard, panelFilter, rowDataState]);

  const hasActiveFilters = useMemo(() => {
    return selectedCard || otherFilters;
  }, [selectedCard, otherFilters]);

  const handleClearFilters = useCallback(() => {
    setSelectedCard();
    setOtherFilters();
  }, []);

  // for device configs start
  const currentDevice = useDeviceType();

  const currentDepartment = useMemo(
    () => userConfiguration?.departmentName,
    [userConfiguration?.departmentName]
  );

  const currentRoleByDepartment = useMemo(
    () => userConfiguration?.groupName,
    [userConfiguration?.groupName]
  );

  const deviceManagementQueries = useMemo(() => {
    const relatedTo = { title, pathname: removeDynamicDigitPart(pathName) };
    const categoryName = findMainTitle(relatedTo.pathname, categories);
    return { categoryName, relatedTo };
  }, [title, pathName]);

  const shouldShowDeviceConfigs = useMemo(() => {
    if (typeof pathName !== "string") {
      console.error("pathName is not a string:", pathName);
      return false;
    }
    const split = pathName
      ?.split("/")
      ?.filter((el) => el !== "")
      ?.filter(Boolean);

    const numberItems = split?.map((el) => {
      const isN = /\d/.test(el);
      return isN;
    });

    const containsNumber = numberItems?.find((el) => el === true);
    const shouldEnterForDeviceFetch = !containsNumber ? true : false;

    // if (
    //   trackDeviceManagement === "noDataFound" &&
    //   ["/fleets/overview?tab=Documentation"].some((el) => el === pathName)
    // ) {
    //   return false;
    // }

    if (trackDeviceManagement === "noDataFound" && !shouldEnterForDeviceFetch) {
      return false;
    }
    return true;
  }, [pathName, trackDeviceManagement]);

  const fetchDeviceConfigs = async () => {
    const { categoryName, relatedTo } = deviceManagementQueries;
    if (
      currentDevice &&
      currentDepartment &&
      currentRoleByDepartment &&
      categoryName
    ) {
      try {
        const res = await filterTables(
          "deviceConfiguration",
          "categoryName",
          categoryName
        );
        if (res && res.length > 0) {
          const response = res.find(
            (r) =>
              r.relatedTo.path === relatedTo.pathname || // this is first try to find, if it doesn't find here tries the second condition
              r.relatedTo.title === relatedTo.title
          );

          const findedConfigs = response
            ? response?.configs?.[currentDevice]?.[currentDepartment]?.[
                currentRoleByDepartment
              ]
            : null;

          const toReturn =
            findedConfigs && Object.keys(findedConfigs).length
              ? response?.configColumns?.map((row) => {
                  const addedRows = findedConfigs[row?.field];
                  if (addedRows) {
                    return {
                      ...row,
                      ...addedRows,
                    };
                  }
                })
              : [];

          setTrackDeviceManagement(
            !toReturn.length ? "noDataFound" : "dataFound"
          );
          setDeviceManagementConfigs(toReturn?.filter(Boolean));
        } else {
          setTrackDeviceManagement("noDataFound");
          setDeviceManagementConfigs([]);
        }
      } catch (err) {
        setTrackDeviceManagement("noDataFound");
        console.error("Error fetching device configurations:", err);
      }
    } else {
      setTrackDeviceManagement("noDataFound");
      setDeviceManagementConfigs([]);
    }
  };

  useEffect(() => {
    fetchDeviceConfigs();
  }, [title, currentDevice, currentDepartment, currentRoleByDepartment]);

  const dynamicColumnDefs = useMemo(() => {
    const sourceColumnDefs =
      typeof columnDefs === "function" ? columnDefs() : columnDefs;

    let updatedColumnDefs;

    if (shouldShowDeviceConfigs) {
      if (trackDeviceManagement) {
        if (
          trackDeviceManagement === "dataFound" &&
          deviceManagementConfigs.length
        ) {
          updatedColumnDefs = createColumnDefsFromDeviceConfigs(
            deviceManagementConfigs,
            sourceColumnDefs
          )?.filter(({ hide }) => !hide);
        } else {
          updatedColumnDefs = defaultColumnDefsBasePage(sourceColumnDefs);
        }
      } else {
        updatedColumnDefs = undefined;
      }
    } else {
      updatedColumnDefs = sourceColumnDefs;
    }

    return updatedColumnDefs;
  }, [
    columnDefs,
    deviceManagementConfigs,
    trackDeviceManagement,
    shouldShowDeviceConfigs,
  ]);

  // for device configs end

  const NewButton = useMemo(() => {
    if (!newButtonData) {
      if (creationModalOpener) {
        return creationModalOpener;
      }
      return;
    }
    const button = newButtonData?.[title];
    if (button) {
      return button;
    }
  }, [title, newButtonData, creationModalOpener]);

  const props = {
    title,
    rowData: searchedData.length
      ? searchedData
      : sortBy(rowDataWithFilters, "createdAt"),
    setRowData: setRowDataState,
    selectedCard,
    setSelectedCard,
    fetchAllHandler,
    hasActiveFilters,
    handleClearFilters,
    hasFetchSearchFromDb,
    reloadData,
    setOtherFilters,
    filterSearchData,
    loadingData,
    setLoadingData,
    setCreationModal,
    dynamicColumnDefs,
    searchedData,
    setSearchedData,
    defaultRowData: rowData,
    panelFilter,
    handleChangePanelFilter,
    optionButton,
    statuses,
    customStatusPanel,
    creationModalOpener,
    NewButton,
    loadingImports,
  };

  return (
    <LoadableComp loading={!dynamicColumnDefs}>
      <MobileBasePageContext.Provider value={props}>
        <div className="mobileBasePageContainer">
          <MobileStatusPanel key={1} />
          <MobileFiltersPanel key={2} />
          <MobileGridTable key={3} />
        </div>
        {creationModal && (
          <NewButton
            key={title}
            visible={creationModal}
            setVisible={setCreationModal}
          />
        )}
      </MobileBasePageContext.Provider>
    </LoadableComp>
  );
};

export default MobileBasePage;
