import { fetchAllData } from "../ApiMethods";

export function compareEstimationsRequisitions(estimations) {
  const withMissingServices = estimations.map((estimation) => {
    const currentRequisition = estimation.requisitions[0];

    if (currentRequisition) {
      return {
        ...currentRequisition,
        missingServices: estimation.services
          .map((service) => {
            const foundService = currentRequisition.services.find(
              (reqService) =>
                reqService.estimationId === estimation.estimationId &&
                reqService.label === service.label
            );

            if (!foundService)
              return {
                ...service,
                serviceOptions: service.serviceOptions?.[0].flat() || [],
              };

            const notFoundOptions = service.serviceOptions?.[0]?.filter(
              (option) =>
                !foundService.serviceOptions?.[0]?.some(
                  (foundOption) =>
                    foundOption.elevationId === option.elevationId
                )
            );

            if (notFoundOptions.length > 0)
              return {
                ...service,
                serviceOptions: notFoundOptions.flat().filter(Boolean),
              };

            const optionsWithNotFoundItems = service.serviceOptions?.[0]
              ?.map((option) =>
                option?.items?.filter(
                  (item) =>
                    !foundService.serviceOptions?.[0]?.map((option) =>
                      option?.items?.some(
                        (foundItem) => foundItem.id === item.id
                      )
                    )
                )
              )
              .filter(Boolean);

            if (optionsWithNotFoundItems.length > 0)
              return {
                ...service,
                serviceOptions: optionsWithNotFoundItems.flat().filter(Boolean),
              };
          })
          .filter(Boolean),
      };
    }
  });

  return withMissingServices;
}

const groupRequisitionsByEstimations = (estimations, requisitions) => {
  const estimationsRequisitions = [];

  estimations.forEach((estimation) => {
    const estimationId = estimation.estimationId;
    const estimationRequisitions = {
      estimationId: estimationId,
      jobSiteAddress: estimation.jobSiteAddress,
      services: estimation.services,
      requisitions: [],
    };

    // Filter requisitions for the current estimation
    const estimationReqs = requisitions
      .filter((requisition) =>
        requisition.estimationsSelected.includes(estimationId)
      )
      .map((requisition) => {
        return {
          ...requisition,
          services: requisition.services.filter(
            (service) => service.estimationId === estimationId
          ),
        };
      })
      .sort((a, b) => b.applicationNo - a.applicationNo);
    estimationRequisitions.requisitions = estimationReqs;
    estimationsRequisitions.push(estimationRequisitions);
  });

  return estimationsRequisitions;
};

function calculateInvoiceItems(
  categoryId,
  invoices,
  totalAmount,
  recordNumber,
  projectName
) {
  const categoryItems = invoices.flatMap((invoice) =>
    invoice.invoiceItems.filter((item) => item.categoryId === categoryId)
  );

  const currentItemsInvoiceAmount = invoices.reduce((total, invoice) => {
    const itemsAmount = invoice.invoiceItems
      .filter((item) => item.categoryId === categoryId)
      .reduce((total, item) => total + item.amount, 0);

    return total + itemsAmount;
  }, 0);

  const invoiceAmount = invoices.reduce((total, invoice) => {
    if (invoice.invoiceItems.some((item) => item.categoryId === categoryId)) {
      return total + invoice.totalInvoiceAmount;
    }
    return total;
  }, 0);

  const amountDue = invoices.reduce((total, invoice) => {
    if (invoice.invoiceItems.some((item) => item.categoryId === categoryId)) {
      return total + invoice.amountDue;
    }
    return total;
  }, 0);

  const currentItemsPercentage = currentItemsInvoiceAmount / totalAmount || 0;

  const newPercentage = currentItemsInvoiceAmount / invoiceAmount || 0;

  const paidAmount = invoiceAmount * newPercentage - amountDue * newPercentage;

  const categoryItemsData = categoryItems.map((item) => ({
    name: item.name,
    amount: item.amount,
    percentage: (item.amount / item.scopeAmount) * 100,
    paidAmount:
      paidAmount * (item.amount / (invoiceAmount * currentItemsPercentage)),
    category: item.category,
    categoryId: item.categoryId,
    scopeAmount: item.scopeAmount,
    serviceOptions: item?.data?.serviceOptions || [],
  }));

  return {
    categoryId,
    totalAmount,
    recordNumber,
    invoiceAmount: currentItemsInvoiceAmount,
    services: categoryItemsData,
    amountDue,
    paidAmount,
    percentage: currentItemsPercentage,
    projectName,
  };
}

export const generateNotInvoicedPaid = async () => {
  try {
    const [requisitions, estimations, charges, rentals, invoices] =
      await Promise.all([
        fetchAllData({
          endpoint: "applications",
          resultId: "applicationId",
          otherStringParams: {
            keysToInclude: JSON.stringify([
              "applicationId",
              "services",
              "estimationsSelected",
              "applicationNo",
            ]),
          },
        }),
        fetchAllData({
          endpoint: "estimations",
          resultId: "estimationId",
          otherStringParams: {
            keysToInclude: JSON.stringify([
              "estimationId",
              "jobSiteAddress",
              "services",
            ]),
          },
        }),
        fetchAllData({
          endpoint: "charges",
          resultId: "chargeId",
          otherStringParams: {
            keysToInclude: JSON.stringify([
              "categoryFrom",
              "chargeId",
              "chargeAmount",
              "chargeNumber",
              "projectAddress",
            ]),
          },
        }),
        fetchAllData({
          endpoint: "rentals",
          resultId: "rentalId",
          otherStringParams: {
            keysToInclude: JSON.stringify([
              "rentalId",
              "totalPrice",
              "rentalNumber",
              "projectAddress",
            ]),
          },
        }),

        fetchAllData({
          endpoint: "invoices",
          resultId: "invoiceId",
          otherStringParams: {
            keysToInclude: JSON.stringify(["invoiceId", "invoiceItems"]),
          },
        }),
      ]);

    const newInvoices = invoices.filter((invoice) =>
      Array.isArray(invoice.invoiceItems)
    );

    const estimationsWithRequisitions = groupRequisitionsByEstimations(
      estimations,
      requisitions
    );

    const requisitionsMissingServices = compareEstimationsRequisitions(
      estimationsWithRequisitions
    ).filter(Boolean);

    const chargesInvoiceItems = charges.map((charge) => {
      const missingServices =
        charge.categoryFrom === "Requisition"
          ? requisitionsMissingServices
              .filter((req) => req.charged === charge.chargeId)
              .flatMap((requisition) => requisition.missingServices)
          : [];

      return {
        ...calculateInvoiceItems(
          charge.chargeId,
          newInvoices,
          charge.chargeAmount,
          charge.chargeNumber,
          charge.projectAddress
        ),
        missingServices,
        categoryFrom: charge.categoryFrom,
        categoryTo: "Charge",
      };
    });

    const rentalsInvoiceItems = rentals.map((rental) => ({
      ...calculateInvoiceItems(
        rental.rentalId,
        newInvoices,
        rental.totalPrice,
        rental.rentalNumber,
        rental.projectAddress
      ),
      missingServices: [],
      categoryFrom: "Rental",
      categoryTo: "Rental",
    }));

    return chargesInvoiceItems.concat(rentalsInvoiceItems);
  } catch (error) {
    console.error({ error });
    return [];
  }
};
