import "./NotesComponent.scss";
import { useEffect, useRef, useState } from "react";
import broadcastNotification from "../../../../helpers/controllers/broadcastNotification";
import { Collapse, Alert, message, Dropdown } from "antd";
import {
  HistoryOutlined,
  InfoCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { state } from "../../../pages/Settings/settingsComponents/Roles/RolesData";
import { useSelector } from "react-redux";
import {
  getAccessRights,
  getAdminAndTeamUsers,
  recursivelyFindAndReplaceObj,
} from "../../../../utils";
import { v4 as uuidv4 } from "uuid";
import { CheckAccessRights } from "../../../../utils/CheckAccessRights";
import { NoteCommentModal, WriteNoteContainer } from "./common_components";
import { CollapseArrow } from "../../../../icons";
import NotesCategoryPanel from "./common_components/NotesCategoryPanel";
import { deleteNote, postNote, updateNote, updateReplies } from "../utils";
import MondayButton from "../../MondayButton/MondayButton";
import { InputComponent } from "../../../SidebarPages/Fleet/components";
import htmlParser from "../../../../utils/htmlParser";
import { getAllTextFromTags } from "../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dispatchFunctions";
import CommentInput from "../../Comment/components/CommentInput/CommentInput";
import { DropDownArrow } from "src/components/SidebarPages/Fleet/components/InputComponent/assets";
import { CommentContent } from "../../Comment";
import { useRedux } from "src/components/SidebarPages/hooks";
import { useProgressComponent } from "src/hooks";
import ProgressComponent from "../../ProgressComponent/ProgressComponent";
import { useEditLogs } from "../../../../hooks";
import MultiLevelTreeLogs from "../../MultiLevelTreeLogs/MultiLevelTreeLogs";
import { onGeneratePdf } from "../utils/onGeneratePdf";
import { defaultExcelHeaderStyle } from "../../../../helpers/constants/defaultExcelHeaderStyle";
import { defaultExcelBodyStyle } from "../../../../helpers/constants/defaultExcelBodyStyle";
import XLSX from "sheetjs-style";
import { excelCellFitToColumn } from "../../../../utils/excelCellFitToColumn";
import dayjs from "dayjs";
import LogsButton from "../../LogsButton/LogsButton";

const notificationActions = {
  10: "onCommentMention",
  3: "onEstNotesAddition",
};

const emptyCell = { v: "", s: defaultExcelBodyStyle };

export const NotesComponent = (props) => {
  const {
    hasWriteAccess,
    notes,
    setRowNotes,
    noteModalName,
    rowId,
    topicCategory,
    defaultCategory,
    defaultCommentId,
    customLinkTo,
    teamsConfiguration,
    isFromSettings = null,
  } = props;
  console.log({ props });

  const intervalRef = useRef();

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { base64 } = useSelector((state) => state.base64);
  const { programFields } = useSelector((state) => state.programFields);
  const { sampleObjects } = useSelector((state) => state.sampleObjects);

  const [fakeNotes, setFakeNotes] = useRedux("fakeNotes");
  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
  } = useProgressComponent({ categoryName: "Notes", actionType: "Create" });
  const {
    saveAddedLogs,
    fetchedLogs,
    setFetchedLogs,
    handleFetchLogs,
    loading,
  } = useEditLogs();

  const [searchValue, setSearchValue] = useState("");
  const [replyModalVisible, setReplyModalVisible] = useState(false);
  const [commentToReply, setCommentToReply] = useState(null);
  const [deletedComment, setDeletedComment] = useState();
  const [exportFilter, setExportFilter] = useState([]);
  const [canEditNote, setCanEditNote] = useState(""); //save note id that can be edited

  //generate link for broadcast notification
  const goToLink = !!customLinkTo
    ? customLinkTo
    : topicCategory.toLowerCase() === "inspections"
    ? `inspectionsView/${rowId}`
    : `${topicCategory.toLowerCase().replace(/\s/g, "")}/${rowId}`;

  const noteCategories =
    programFields.find(({ fieldName }) => fieldName === "Categories for Notes")
      .fieldOptions[topicCategory] || [];

  const editTime =
    programFields.find(({ fieldName }) => fieldName === "Edit Allowance")
      ?.fieldOptions?.["Note Edit Time"] || 0;

  //Function to search notes
  const filteredNotes = notes?.filter((note) => {
    const noteCommentMatches = getAllTextFromTags(htmlParser(note.noteComment))
      .filter((el) => el.trim())
      .join("")
      ?.toLowerCase()
      ?.includes(searchValue.toLowerCase());

    const repliesCommentMatches = note?.replies?.some((reply) =>
      reply?.replyContent?.toLowerCase().includes(searchValue.toLowerCase())
    );
    return noteCommentMatches || repliesCommentMatches;
  });

  // find note that is clicked
  useEffect(() => {
    let clickedNoteIsReply = false; //used to open replies when user is mentioned in a reply

    const findNoteByProp = notes.find(
      ({ noteId, replies }) =>
        noteId === defaultCommentId ||
        replies.some(({ noteId }) => {
          clickedNoteIsReply = true;
          return noteId === defaultCommentId;
        })
    );
    if (findNoteByProp) {
      findNoteByProp.repliesVisibility = clickedNoteIsReply;
    } else {
      defaultCommentId !== undefined && setDeletedComment(true);
    }
    setRowNotes(state(notes));
  }, []);

  let maxLevel = 0;

  const processRepliesRecursively = (replies, rows, level = 0) => {
    maxLevel = Math.max(maxLevel, (1 + level) * 3);
    replies.forEach((reply) => {
      const newArray = [
        emptyCell,
        emptyCell,
        emptyCell,
        emptyCell,
        emptyCell,
        {
          v: reply?.replyContent?.replace(/(<([^>]+)>)/gi, "") || "",
          s: defaultExcelBodyStyle,
        },
        { v: reply?.author || "", s: defaultExcelBodyStyle },
        {
          v: dayjs(reply?.createdAt)?.format("MM/DD/YYYY hh:mm A") || "",
          s: defaultExcelBodyStyle,
        },
      ];
      if (level) {
        for (let i = 0; i < level; i++) {
          newArray.unshift(emptyCell);
          newArray.unshift(emptyCell);
          newArray.unshift(emptyCell);
        }
      }
      rows.push(newArray);

      if (reply?.replies?.length) {
        processRepliesRecursively(reply.replies, rows, level + 1);
      }
    });
  };

  function exportNotesToExcel({ exportFilter }) {
    const headers = [
      { v: "Topic Category", s: defaultExcelHeaderStyle },
      { v: "Category", s: defaultExcelHeaderStyle },
      { v: "Comment", s: defaultExcelHeaderStyle },
      { v: "Author", s: defaultExcelHeaderStyle },
      { v: "Created Date", s: defaultExcelHeaderStyle },
      { v: "Replies", s: defaultExcelHeaderStyle },
    ];

    let rows = [];

    const filteredNotes = exportFilter?.length
      ? notes?.filter(({ author }) => exportFilter.includes(author))
      : notes;

    filteredNotes.forEach((note) => {
      rows.push([
        { v: note?.topicCategory || "", s: defaultExcelBodyStyle },
        { v: note?.category || "", s: defaultExcelBodyStyle },
        {
          v: note?.noteComment?.replace(/(<([^>]+)>)/gi, "") || "",
          s: defaultExcelBodyStyle,
        },
        { v: note?.author || "", s: defaultExcelBodyStyle },
        {
          v: dayjs(note?.createdAt)?.format("MM/DD/YYYY hh:mm A") || "",
          s: defaultExcelBodyStyle,
        },
      ]);

      if (note?.replies?.length) {
        processRepliesRecursively(note.replies, rows);
      }
    });

    const allData = [headers, ...rows];

    // Create the worksheet from the data
    const worksheet = XLSX.utils.aoa_to_sheet(allData);

    // Only apply merging and styling if maxLevel is greater than 0
    if (maxLevel > 0) {
      // Add merge to the desired cells horizontally (starting at F1)
      worksheet["!merges"] = [
        { s: { r: 0, c: 5 }, e: { r: 0, c: 5 + maxLevel - 1 } }, // Merges F1, G1, H1, ... depending on maxLevel
      ];

      const centerStyle = {
        alignment: {
          horizontal: "center",
          vertical: "center",
        },
        ...defaultExcelHeaderStyle,
      };

      worksheet["F1"].s = centerStyle;
    } else {
      // If maxLevel is 0, apply the style to just the single "Replies" cell (F1)
      const centerStyle = {
        alignment: {
          horizontal: "center",
          vertical: "center",
        },
        ...defaultExcelHeaderStyle,
      };

      worksheet["F1"].s = centerStyle; // Apply style to just F1 without merging
    }

    // Set column widths (if needed)
    worksheet["!cols"] = excelCellFitToColumn(allData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the file
    XLSX.writeFile(workbook, `Notes | ${noteModalName}.xlsx`);
  }

  // function to send notification to mentioned user
  const mentionNotification = async (
    topic,
    commentId,
    category,
    cognitos,
    updateProgressStatus = () => {}
  ) => {
    const action = notificationActions[topic];

    if (!!action) {
      updateProgressStatus({ sendingNotification: "executing" });
      // await broadcastNotification(
      broadcastNotification(
        topic,
        action,
        [
          {
            common: userConfiguration?.nameOfUser,
            commonNext: topicCategory.toUpperCase(),
            commonName: noteModalName,
          },
          {
            userName: userConfiguration?.nameOfUser,
            currentUser: userConfiguration?.cognitoUserId,
            commentId,
            category,
            cognitos,
            notesOpen: true,
          },
        ],
        goToLink
      ).then((notificationSent) => {
        updateProgressStatus({
          sendingNotification: !!notificationSent ? "finished" : "hasError",
        });
      });
    }
  };

  //show reply modal for given comment
  function customShowReplyInput(note) {
    setReplyModalVisible(true);
    setCommentToReply(note);
  }

  const setNoteToEdit = (noteId) => {
    setCanEditNote(noteId);
    intervalRef.current = setTimeout(() => {
      setCanEditNote("");

      clearTimeout(intervalRef.current);
    }, editTime * 60000);
  };

  //Post note from WriteNoteContainer modal and inline category input
  const handlePostNote = ({
    category,
    inputValue,
    mentions,
    isPrivateComment,
  }) => {
    if (typeof inputValue === "string" && inputValue !== "<p><br></p>") {
      const noteObj = {
        recordId: rowId || "Empty recordId",
        relatedTo: noteModalName,
        reply: "",
        replies: [],
        author: userConfiguration?.nameOfUser,
        cognitoUserId: userConfiguration?.cognitoUserId,
        noteComment: inputValue,
        mentions: mentions.map(({ nameOfUser }) => nameOfUser),
        topicCategory:
          topicCategory?.charAt(0).toUpperCase() + topicCategory?.slice(1),
        category,
        peopleWhoRead: {},
        repliesVisibility: false,
        // createdAt: Date.now(),
        linkTo: `/${goToLink}`,
        fromSettings:
          typeof isFromSettings === "boolean" || isFromSettings !== null
            ? isFromSettings
            : window.location.pathname.split("/")[1] === "settings",
        privateOnlyTo: !!isPrivateComment
          ? userConfiguration?.cognitoUserId
          : "",
        ...(!!Array.isArray(teamsConfiguration) && { teamsConfiguration }), // add teams config only if related to record has provided its teams configurations
      };

      !!mentions.length && setVisibleCreationProgress(true);

      updateProgressStatus({ updatingRecord: "executing" });
      postNote(noteObj, saveAddedLogs)
        .then((res) => {
          if (!!mentions.length) {
            setVisibleCreationProgress(res);
            updateProgressStatus({ updatingRecord: "finished" });
          }

          setNoteToEdit(res.noteId);

          setRowNotes((prev) => [...prev, res]);

          if (Array.isArray(fakeNotes)) {
            const updatedNotes = [...fakeNotes, res];
            setFakeNotes(updatedNotes); //in NotesBasePage we update notes with redux
          }

          // 10 is topicId for Mentions and 3 is topicId for Estimations
          mentions.length > 0 &&
            mentionNotification(
              "10",
              res.noteId,
              category,
              mentions.map(({ cognitoUserId }) => cognitoUserId),
              updateProgressStatus
            );

          //send notification for dropped note in estimations
          topicCategory.toLowerCase() === "estimations" &&
            mentionNotification(
              "3",
              res.noteId,
              category,
              usersWithAccess.map(({ id }) => id)
            );
        })
        .catch((error) => {
          console.log({ error });
          updateProgressStatus({ updatingRecord: "hasError" });
        });
    } else {
      message.error("Note was empty!");
    }
  };

  const updateComment = ({
    comment: { noteId, category },
    inputValue,
    isPrivateComment,
    mentions,
  }) => {
    if (!!mentions.length) {
      const findedNote = notes.find((note) => note.noteId === noteId);
      setVisibleCreationProgress(findedNote);
    }
    updateNote(
      noteId,
      inputValue,
      !!isPrivateComment ? userConfiguration?.cognitoUserId : "",
      notes,
      saveAddedLogs,
      fakeNotes,
      setFakeNotes,
      setRowNotes
    ).then(() => {
      !!mentions.length && updateProgressStatus({ updatingRecord: "finished" });
      mentions.length > 0 &&
        mentionNotification(
          "10",
          noteId,
          category,
          mentions.map(({ cognitoUserId }) => cognitoUserId),
          updateProgressStatus
        );
    });
  };

  const deleteComment = (noteId) => {
    deleteNote(
      noteId,
      saveAddedLogs,
      notes,
      fakeNotes,
      setFakeNotes,
      setRowNotes
    );
  };

  const findReplyNoteId = (replies = [], replyId) => {
    return replies.find(
      (note) =>
        note.noteId === replyId || findReplyNoteId(note.replies, replyId)
    );
  };

  //function to post a reply
  const postReply = ({ inputValue, mentions }) => {
    const noteId = uuidv4();

    const mainNote = findReplyNoteId(notes, commentToReply.noteId);

    const updatedComments = recursivelyFindAndReplaceObj(
      notes,
      "noteId",
      commentToReply.noteId,
      {
        replies: [
          ...commentToReply.replies,
          {
            noteId,
            author: userConfiguration?.nameOfUser,
            cognitoUserId: userConfiguration?.cognitoUserId,
            replyContent: inputValue,
            createdAt: Date.now(),
            mentions,
            replies: [],
          },
        ],
      },
      "replies"
    );

    const updatedNoteReplies = updatedComments.find(
      (note) => note.noteId === mainNote.noteId
    );

    updateReplies(
      mainNote.noteId,
      updatedNoteReplies.replies,
      saveAddedLogs,
      notes,
      fakeNotes,
      setFakeNotes,
      setRowNotes
    ).then(() => {
      mentions?.length > 0 &&
        mentionNotification(
          "10",
          noteId,
          mainNote.category,
          mentions.map(({ cognitoUserId }) => cognitoUserId)
        );
    });
  };

  //delete a reply from replies
  const deleteNoteReply = (replyId) => {
    const mainNote = findReplyNoteId(notes, replyId);

    const updatedComments = recursivelyFindAndReplaceObj(
      notes,
      "noteId",
      replyId,
      false,
      "replies"
    );

    const updatedNoteReplies = updatedComments.find(
      (note) => note.noteId === mainNote.noteId
    );

    updateReplies(
      mainNote.noteId,
      updatedNoteReplies.replies,
      saveAddedLogs,
      notes,
      fakeNotes,
      setFakeNotes,
      setRowNotes
    );
  };

  const editNoteReply = ({
    comment: { noteId, replyContent, commentChanges },
    inputValue,
    mentions,
  }) => {
    const mainNote = findReplyNoteId(notes, noteId);

    const updatedComments = recursivelyFindAndReplaceObj(
      notes,
      "noteId",
      noteId,
      {
        replyContent: inputValue,
        commentChanges: [
          {
            previous: replyContent,
            current: inputValue,
            updatedAt: Date.now(),
          },
          ...(commentChanges || []),
        ],
      },
      "replies"
    );

    const updatedNoteReplies = updatedComments.find(
      (note) => note.noteId === mainNote.noteId
    );

    updateReplies(
      mainNote.noteId,
      updatedNoteReplies.replies,
      saveAddedLogs,
      notes,
      fakeNotes,
      setFakeNotes,
      setRowNotes
    ).then(() => {
      mentions.length > 0 &&
        mentionNotification(
          "10",
          noteId,
          mainNote.category,
          mentions.map(({ cognitoUserId }) => cognitoUserId)
        );
    });
  };

  //count notes for given category
  const countNotesByCategory = (category) => {
    return (
      notes?.filter((note) => {
        return note.category === category?.categoryName;
      }).length || 0
    );
  };

  //avoid me as user & get users with access to Notes
  const usersWithAccess = (
    !!Array.isArray(teamsConfiguration)
      ? getAdminAndTeamUsers(userConfiguration, teamsConfiguration)
      : userConfiguration?.allUsers?.Items?.filter(
          ({ isSuspended }) => !isSuspended
        )
  )?.reduce((acc, person, index) => {
    if (
      person?.cognitoUserId &&
      person.cognitoUserId !== userConfiguration?.cognitoUserId
    ) {
      //we get accesses of each user & then we see if that user has access to notes
      const accessRightsToNotes = getAccessRights({
        userConfiguration: person,
        title: "Notes/View",
      }).routeConfig?.children;

      if (CheckAccessRights(accessRightsToNotes, topicCategory)) {
        acc.push({
          id: person.cognitoUserId,
          value: person.nameOfUser,
          key: index,
        });
      }
    }
    return acc;
  }, []);

  //sort categories in desc order to get last updated to first position
  const sortedCategories = [
    ...new Set(
      filteredNotes
        ?.sort((a, b) => b?.createdAt - a?.createdAt)
        ?.map((event) => event?.category)
    ),
  ]
    .map((cat) => noteCategories.find((el) => el.categoryName === cat))
    .filter(Boolean);

  const dropdownActions = [
    {
      key: "1",
      label: "Export To PDF",
      onClick: () => {
        onGeneratePdf({
          notesProps: notes,
          base64,
          title: noteModalName,
          exportFilter,
        });
      },
    },
    {
      key: "2",
      label: "Export To Excel",
      onClick: () => exportNotesToExcel({ exportFilter }),
    },
  ];

  const deletedNoteMessage = (
    <Alert
      showIcon={false}
      banner="true"
      style={{ textAlign: "center", marginTop: "15px" }}
      closable
      message="Note was removed"
      type="error"
      onClose={() => setDeletedComment(false)}
    />
  );

  const handleShowLogs = async () => {
    const hideLoading = message.loading(
      "Retrieving log data. Please wait...",
      0
    );
    const filters = [
      {
        operator: "AND",
        conditions: [
          {
            operator: "AND",
            column: "recordId",
            value: rowId,
            formula: "is",
          },
          {
            operator: "AND",
            column: "category",
            value: "Notes",
            formula: "is",
          },
        ],
      },
    ];

    await handleFetchLogs({ filters });

    !loading && hideLoading();
  };

  return (
    <div className="notes-component-wrapper">
      {!hasWriteAccess && (
        <Alert
          style={{ borderRadius: 0 }}
          message="You have no access to write notes"
          type="error"
        />
      )}
      <div
        className={`more-info-square ${isDarkMode && "more-info-square-dark"}`}
      >
        <div className="info-container">
          <InfoCircleFilled />
          <h6>
            Concise record notes: key details, dates, and actions captured
            efficiently.
          </h6>
        </div>
        <div className="export-container">
          <InputComponent
            type="select"
            dropdownClassName={isDarkMode && "darkDropDown"}
            onChange={(e) => setExportFilter(e)}
            placeholder="Filter export by member"
            mode="multiple"
            customOptions={[...new Set(notes.map(({ author }) => author))].map(
              (name, key) => ({
                key,
                label: name,
                value: name,
              })
            )}
          />
          <LogsButton
            {...{
              logsTitle: `showing logs for - (${noteModalName})`,
              filtersObj: { recordId: rowId },
              buttonLabel: "Logs",
            }}
          />
          {/* <MondayButton
            className="mondayButtonBlue"
            onClick={fetchLogs}
            Icon={<HistoryOutlined />}
            tooltipKey="showLogs"
          >
            Logs
          </MondayButton> */}
          <Dropdown
            menu={{ items: dropdownActions }}
            overlayClassName={isDarkMode ? "actionsDropdown-dark" : ""}
          >
            <MondayButton
              className="mondayButtonBlue"
              Icon={<DropDownArrow />}
              tooltipCategory="Notes"
              tooltipKey="noteExport"
            >
              Actions
            </MondayButton>
          </Dropdown>
        </div>
      </div>

      <InputComponent
        value={searchValue}
        placeholder="Search..."
        onChange={(event) => setSearchValue(event.target.value)}
        className="notes_search"
        suffixIcon={<SearchOutlined />}
      />

      {deletedComment &&
        !sortedCategories.find((cat) => cat.categoryName === defaultCategory) &&
        deletedNoteMessage}

      <Collapse
        defaultActiveKey={defaultCategory}
        expandIcon={({ isActive }) => (
          <CollapseArrow
            style={{
              height: "14px",
              transform: isActive ? "rotate(-90deg)" : "rotate(90deg)",
            }}
          />
        )}
        items={sortedCategories
          .map((category) => {
            const { categoryName } = category;
            const numberOfNotesByCategory = countNotesByCategory(category);
            const notesOfCategory = filteredNotes?.filter(
              (note) => note.category === categoryName
            );

            return (
              numberOfNotesByCategory > 0 && {
                key: categoryName,
                label: (
                  <>
                    <NotesCategoryPanel {...{ category, filteredNotes }} />
                  </>
                ),
                collapsible: numberOfNotesByCategory === 0 ? "disabled" : false,
                extra: numberOfNotesByCategory,
                children: (
                  <>
                    <div className="notes-container">
                      {/* if note deleted */}
                      {deletedComment &&
                        defaultCategory === categoryName &&
                        deletedNoteMessage}
                      {/* if note deleted */}
                      {notesOfCategory.map((note, index) => (
                        <CommentContent
                          key={index}
                          {...{
                            canEditNote,
                            defaultCommentId,
                            findKeysIn: { commentId: "noteId" },
                            usersWithAccess,
                            thread: {
                              ...note,
                              commentId: note.noteId,
                              commentContent: note.noteComment,
                            },
                            updateComment,
                            deleteComment,
                            updateReply: editNoteReply,
                            deleteReply: deleteNoteReply,
                            customShowReplyInput,
                          }}
                        />
                      ))}
                    </div>

                    {hasWriteAccess && (
                      <CommentInput
                        {...{
                          placeholder: `Add note to category: ${categoryName}!`,
                          usersWithAccess,
                          saveComment: (props) => {
                            handlePostNote({
                              category: categoryName,
                              ...props,
                            });
                          },
                        }}
                      />
                    )}
                  </>
                ),
              }
            );
          })
          .filter(Boolean)}
      />

      <WriteNoteContainer
        handlePostNote={handlePostNote}
        noteCategories={noteCategories}
        hasWriteAccess={hasWriteAccess}
        usersWithAccess={usersWithAccess}
      />
      {/* Reply a comment modal */}
      {replyModalVisible && (
        <NoteCommentModal
          visible={replyModalVisible}
          onCancel={() => setReplyModalVisible(false)}
          onPost={postReply}
          commentToReply={commentToReply}
          usersWithAccess={usersWithAccess}
        />
      )}

      {/* <MultiLevelTreeLogs
        visible={fetchedLogs}
        setVisible={setFetchedLogs}
        logsData={fetchedLogs}
        title="Notes Logs"
      /> */}
      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: "Notes",
            actionType: "Create",
            visibleCreationProgress,
            creationProgresses,
            closeModal: () => {
              setVisibleCreationProgress(false);
            },
          }}
        />
      )}
    </div>
  );
};
