import React from "react";
import { Divider } from "antd";

const MultipleInputs = ({ value, dynamicFields }) => {
  const dataArray = Array.isArray(value)
    ? value
    : value
    ? [value]
    : [
        dynamicFields.reduce(
          (acc, field) => ({ ...acc, [field.formItemName]: "" }),
          {}
        ),
      ];

  const formatLabel = (str) => {
    return str
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase())
      .trim();
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
    const numeric = String(phoneNumber).replace(/[^\d]/g, "");
    return `+${numeric.slice(0, 1)} ${numeric.slice(1, 4)} ${numeric.slice(
      4,
      7
    )} ${numeric.slice(7)}`;
  };

  return (
    <div>
      {dataArray.map((item, index) => (
        <React.Fragment key={index}>
          {dynamicFields.map((field) => (
            <div
              className="InforamtionBodyElementContainer"
              key={field.formItemName}
            >
              <span className="InformationBodyElementContainerTitle">
                {field.label || formatLabel(field.formItemName)}:
              </span>
              <span className="InforamtionBodyElementContainerContent">
                {field.type === "Email" ? (
                  <a href={`mailto:${item?.[field.formItemName]}`}>
                    {item?.[field.formItemName]}
                  </a>
                ) : field.type === "PhoneNumber" ? (
                  <a href={`tel:${item?.[field.formItemName]}`}>
                    {formatPhoneNumber(item?.[field.formItemName])}
                  </a>
                ) : (
                  item?.[field.formItemName] || ""
                )}
              </span>
            </div>
          ))}
          {index < dataArray.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default MultipleInputs;
