import React, { useState } from "react";
import { EditFilled } from "@ant-design/icons";
import EditGeneralTemplate from "../../../EditTemplate/EditGeneral/EditGeneralTemplate";

import "./ServiceCategoriesInsideEditor.scss";

function ServiceCategoriesInsideEditor({ item = {}, changeDisabled }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const onOpenModal = (e) => {
    e.stopPropagation();
    if (changeDisabled) {
      changeDisabled(true);
    }
    setIsModalVisible(true);
  };

  const onCloseModal = () => {
    setIsModalVisible(false);
    if (changeDisabled) {
      changeDisabled(false);
    }
  };

  return (
    <>
      {/* <span className="edit_filled_icon_holder" onClick={onOpenModal}> */}
      <span className="iconOpen" onClick={onOpenModal}>
        <EditFilled />
      </span>
      {isModalVisible && (
        <EditGeneralTemplate
          item={item?.others}
          visible={isModalVisible}
          closeModal={onCloseModal}
          type="serviceTemplate"
        />
      )}
    </>
  );
}

export default ServiceCategoriesInsideEditor;
