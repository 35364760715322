import { createContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Input,
  Select,
  DatePicker,
  Alert,
  Space,
  Button,
  message,
  Tooltip,
  List,
  Tour,
  Flex,
  Switch,
} from "antd";
import debounce from "lodash/debounce";
import dayjs from "dayjs";
import {
  CalendarOutlined,
  CheckOutlined,
  CloseOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import {
  handlePopupScroll,
  searchFetchInput,
} from "src/utils/searchFetchForInput";
import IndividualLoader from "src/components/IndividualLoader";
import Text from "src/components/commonComponents/Typography/Text";

import { getServicesToBeInspected } from "./utils";
import useNewInspectionModal from "./useNewInspectionModal";

import {
  LabeledInput,
  InspectionModal,
  LoadableComp,
} from "../../../../../XComponents";
import { compareIncluding } from "../../../../../utils";
import {
  NO_SERVICES_INSPECTION_TYPES,
  CREATE_INSPECTIONS_FIELDS,
} from "../../../../datas";
import { InputComponent } from "../../../../../Fleet/components";
import {
  MondayButton,
  ProgressComponent,
} from "../../../../../../commonComponents";
import { XIcon } from "../../../../../Communication/assets";
import CustomModalHeader, {
  PlayVideoTutorial,
} from "../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import DynamicTeamModal from "../../../../../../Header/forms/DynamicTeamModal/DynamicTeamModal";
import { DropDownArrow } from "../../../../../Fleet/components/InputComponent/assets";
import { LeftArrow, RightArrow } from "../../../../../BasePage/src";
import { TickIcon } from "../../../../../../pages/Settings/settingsComponents/Roles/src";
import ServiceCollapse from "../../../ServiceCard/ServiceCollapse";
import { DynamicAvatars } from "../../../../../../commonComponents/DynamicAvatars/DynamicAvatars";

import "./Styles.scss";

export const FieldValueCtx = createContext({});

const NewInspectionModal = ({
  title,
  visible,
  setVisible,
  form,
  currentProject,
  setCurrentProject,
  isTab,
  otherProps,
  requestId = "",
  setRowData,
  isNextStep,
  afterSaveRequest = async () => {},
}) => {
  const navigate = useNavigate();

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const {
    next,
    setNext,
    alert,
    setAlert,
    projects,
    setProjects,
    formChanged,
    setFormChanged,
    open,
    setOpen,
    teamsOptions,
    allTeams,
    selectedTeam,
    setSelectedTeam,
    serviceValues,
    setServiceValues,
    currentPageKey,
    setCurrentPageKey,
    loadingProjects,
    setLoadingProjects,
    tourOpen,
    setTourOpen,
    showVideoTutorial,
    setShowVideoTutorial,
    userConfiguration,
    setFieldsValue,
    resetFields,
    validateFields,
    propedRowData,
    proppedProjects,
    setDescription,
    setDescriptionModalVisible,
    setProppedProjects,
    proppedCurrentPageKey,
    visibleCreationProgress,
    creationProgresses,
    completedSchedulesServices,
    serviceOptions,
    onSelectTeam,
    onDeselectTeam,
    openMemberModal,
    ClearOptions,
    pliArray,
    totalValues,
    onCreateInspection,
    inspectionTypes,
    tourSteps,
    projectManagers,
    updatedInspectors,
    videoTutorialLink,
    displayProgress,
    setDisplayProgress,
    filteredToBeScheduled,
    schedules,
    setSelectedInspectionType,
    inspectionType,
    uniqueProjectServices,
    error,
  } = useNewInspectionModal({
    form,
    currentProject,
    setCurrentProject,
    otherProps,
    requestId,
    afterSaveRequest,
  });

  const footerButton = ({
    text = "Close",
    onClick,
    className = "mondayButtonRed",
    Icon,
    tooltipCategory,
    tooltipKey,
    disabled = false,
    loading = false,
  }) => {
    return (
      <MondayButton
        key={`${text}+${new Date()}`}
        className={className}
        Icon={Icon}
        onClick={onClick}
        tooltipCategory={tooltipCategory}
        tooltipKey={tooltipKey}
        disabled={disabled}
        loading={loading}
      >
        {text}
      </MondayButton>
    );
  };

  const scheduleType = inspectionType?.relatedScheduleType ?? "";
  const progressLabel =
    scheduleType.toLowerCase() === "removal"
      ? "Removal Progress"
      : "Installation Progress";

  const hasServiceValue = serviceValues?.some(({ value }) => !!value);

  if (error) {
    throw new Error(error);
  }

  return (
    <FieldValueCtx.Provider value={{ form }}>
      <InspectionModal
        className={`newInspectionModal ${
          isDarkMode && "newInspectionModalDark"
        }`}
        title={
          <CustomModalHeader
            title={`new ${title}`}
            onClick={() => setTourOpen(true)}
          />
        }
        visible={visible}
        setVisible={setVisible}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
        maskClosable={false}
        closable={true}
        style={{ maxWidth: "700px" }}
        basicModalFooter={
          !next
            ? [
                footerButton({
                  text: "Close",
                  onClick: () => {
                    if (formChanged) {
                      setAlert(true);
                    } else {
                      setAlert(false);
                      setVisible(false);
                      setNext(false);
                      resetFields(CREATE_INSPECTIONS_FIELDS);
                    }
                  },
                  className: "mondayButtonRed",
                  Icon: <XIcon />,
                  tooltipCategory: "Inspections",
                  tooltipKey: "close",
                }),
                footerButton({
                  text: "Next",
                  onClick: () =>
                    validateFields(CREATE_INSPECTIONS_FIELDS).then((values) => {
                      const inspType =
                        values?.newInspectionInspType?.toUpperCase();

                      if (NO_SERVICES_INSPECTION_TYPES?.includes(inspType)) {
                        const hoist = inspType.toLowerCase().includes("Hoist")
                          ? "Hoist"
                          : "";

                        if (uniqueProjectServices?.includes(hoist)) {
                          onCreateInspection();
                        } else {
                          message.warning(
                            "Selected project doesn't have Hoist service!"
                          );
                        }
                      } else {
                        setNext(true), setServiceValues(totalValues);
                      }
                    }),
                  tooltipCategory: "Inspections",
                  tooltipKey: "next",
                  className: "mondayButtonBlue",
                  Icon: <RightArrow />,
                }),
              ]
            : [
                footerButton({
                  text: "Close",
                  onClick: () => {
                    if (formChanged) {
                      setAlert(true);
                    } else {
                      setAlert(false);
                      setVisible(false);
                      setNext(false);
                      resetFields(CREATE_INSPECTIONS_FIELDS);
                    }
                  },
                  tooltipCategory: "Inspections",
                  tooltipKey: "close",
                  className: "mondayButtonRed",
                  Icon: <XIcon />,
                }),
                footerButton({
                  text: "Back",
                  onClick: () => setNext(false),
                  className: "mondayButtonBlue",
                  Icon: <LeftArrow />,
                  tooltipCategory: "Inspections",
                  tooltipKey: "backBtn",
                }),
                footerButton({
                  text: "Inspect",
                  onClick: () => {
                    const servicesToBeInspected = getServicesToBeInspected(
                      serviceValues,
                      pliArray
                    );

                    onCreateInspection(servicesToBeInspected);

                    // if (
                    //   servicesToBeInspected?.filter(
                    //     ({ isInspectedFull }) => isInspectedFull !== "none"
                    //   ).length > 0
                    // ) {
                    //   onCreateInspection(servicesToBeInspected);
                    // }
                  },
                  className: "mondayButtonBlue",
                  Icon: <TickIcon width={17} height={17} />,
                  tooltipCategory: "Inspections",
                  tooltipKey: "inpectBtn",
                  // disabled: inspectionType?.affectedByService
                  //   ? !hasServiceValue
                  //   : false,
                  // !Object.values(serviceOptions)?.some(
                  //   (item) => !!item?.services?.length
                  // ) ||
                  // (inspectionType?.isAffectedBySchedule &&
                  //   !!!filteredToBeScheduled.length),
                }),
              ]
        }
      >
        {!next ? (
          <>
            <LabeledInput
              required
              style={{ minWidth: "410px" }}
              formItemName="newInspectionProjectName"
              label="project name"
              onChange={() => {
                setFormChanged(true);
              }}
              initialValue={isTab ? currentProject?.projectName : ""}
            >
              <Select
                data-testid="projectName"
                disabled={isTab || !!propedRowData?.jobsiteAddress}
                placeholder="Select a Project"
                notFoundContent="NOT FOUND"
                popupClassName={isDarkMode && "darkDropDown"}
                suffixIcon={<DropDownArrow />}
                showSearch
                onSearch={debounce((e) => {
                  let params = {
                    table: "projects",
                    idKey: "projectId",
                    searchKey: "projectName",
                    searchValue: e,
                    userConfiguration,
                    setLoading: setLoadingProjects,
                  };
                  if (!!proppedProjects?.length) {
                    params = {
                      ...params,
                      setRecords: setProppedProjects,
                    };
                  } else {
                    params = {
                      ...params,
                      setRecords: setProjects,
                    };
                  }
                  return searchFetchInput({ ...params });
                }, 500)}
                onPopupScroll={(e) => {
                  let params = {
                    e,
                    table: "projects",
                    idKey: "projectId",
                    searchKey: "projectName",
                    userConfiguration,
                    setLoading: setLoadingProjects,
                  };

                  if (!!proppedProjects?.length) {
                    params = {
                      ...params,
                      setRecords: setProppedProjects,
                      setCurrentPageKey: proppedCurrentPageKey,
                      currentPageKey: proppedCurrentPageKey,
                    };
                  } else {
                    params = {
                      ...params,
                      setRecords: setProjects,
                      setCurrentPageKey,
                      currentPageKey,
                    };
                  }
                  return handlePopupScroll({ ...params });
                }}
                onChange={() => {
                  setFormChanged(true);
                }}
                autoComplete="off"
                filterOption={(inputValue = "", option) => {
                  const {
                    children: {
                      props: {
                        children: [
                          {
                            props: { children },
                          },
                        ],
                      },
                    },
                  } = option || {};
                  return compareIncluding(children || "", inputValue || "");
                }}
                onSelect={(e) => {
                  const current = [...proppedProjects]?.filter(
                    ({ projectId }) => compareIncluding(projectId, e)
                  )[0];
                  setCurrentProject(current);
                  setFieldsValue({
                    newInspectionProjectExecutive: current?.projectExecutive,
                  });
                  setSelectedTeam(current?.teamsConfiguration);

                  form.setFieldsValue({
                    newInspectionTeams: current?.teamsConfiguration,
                    newInspectionProjectManager:
                      current?.projectManager?.map((manager) =>
                        typeof manager === "string"
                          ? manager
                          : manager?.nameOfUser
                      ) || [],
                  });
                }}
                dropdownRender={(menu) => {
                  return (
                    <>
                      {menu}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "0px 10px",
                        }}
                      >
                        {loadingProjects ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              margin: "20px",
                            }}
                          >
                            <IndividualLoader />
                          </div>
                        ) : null}
                      </div>
                    </>
                  );
                }}
              >
                {projects?.map((item, i) => {
                  const project = schedules?.find(
                    ({ projectId }) => item?.projectId === projectId
                  );

                  const { services = {} } = project ? item : {};

                  const objectsFromArrays = Object.values(services).flat();
                  let uniqueServices = [
                    ...new Set(
                      objectsFromArrays
                        ?.filter((item) => item?.isScope != true)
                        .map((item) => item.label)
                    ),
                  ];

                  return (
                    <Select.Option key={i} value={item?.projectId}>
                      <div className="inspectionProjectDropdownTag">
                        <div>{item?.projectName}</div>
                        <Tooltip
                          placement="right"
                          zIndex={99999}
                          title={
                            <List
                              style={{
                                overflowX: "auto",
                                maxHeight: "245px",
                              }}
                              dataSource={uniqueServices}
                              renderItem={(item) => (
                                <List.Item style={{ color: "#fff" }}>
                                  {item}
                                </List.Item>
                              )}
                            />
                          }
                        >
                          <div className="servicesTag">
                            Services: {uniqueServices.length}
                          </div>
                        </Tooltip>
                      </div>
                    </Select.Option>
                  );
                })}
              </Select>
            </LabeledInput>
            <LabeledInput
              required
              formItemName="newInspectionInspType"
              label="inspection type"
              onChange={() => {
                setFormChanged(true);
              }}
              style={{ width: "220px" }}
              initialValue={currentProject?.inspectionType || null}
            >
              <Select
                data-testid="inspectionType"
                disabled={!!!currentProject}
                popupClassName={isDarkMode && "darkDropDown"}
                suffixIcon={<DropDownArrow />}
                value={currentProject?.inspectionType || null}
                onClick={() => {
                  !!!currentProject &&
                    message.error("Please first select a Project!");
                }}
                onChange={(e) => {
                  setFormChanged(true);
                  setSelectedInspectionType(e);
                }}
              >
                {(!!completedSchedulesServices?.filter(({ label }) =>
                  compareIncluding("HOIST", label)
                )?.length
                  ? inspectionTypes
                  : inspectionTypes?.filter(
                      (typeName) => !compareIncluding(typeName, "HOIST")
                    )
                ).map((item, i) => (
                  <Select.Option key={i} value={item?.value}>
                    {item?.label}
                  </Select.Option>
                ))}
              </Select>
            </LabeledInput>
            <div className="executive-date-wrapper">
              <div
                onClick={() => {
                  !!!currentProject &&
                    message.error("Please first select a Project!");
                }}
              >
                <LabeledInput
                  onChange={() => {
                    setFormChanged(true);
                  }}
                  required
                  initialValue={isTab ? currentProject?.projectExecutive : ""}
                  formItemName="newInspectionProjectExecutive"
                  label="project executive"
                >
                  <Input disabled data-testid="project-executive" />
                </LabeledInput>
              </div>
              <LabeledInput
                onChange={() => {
                  setFormChanged(true);
                }}
                required
                formItemName="newInspectionInspDate"
                label="inspection date"
                initialValue={dayjs(new Date())}
              >
                <DatePicker
                  data-testid="inspection-date"
                  disabled={!!!currentProject}
                  popupClassName={isDarkMode && "darkDateDropDown"}
                  onClick={() => {
                    !!!currentProject &&
                      message.error("Please first select a Project!");
                  }}
                  onChange={() => {
                    setFormChanged(true);
                  }}
                  format="MM/DD/YYYY"
                  allowClear={false}
                  suffixIcon={
                    <CalendarOutlined
                      style={{ fontSize: 18, color: "#262626" }}
                    />
                  }
                  inputReadOnly
                />
              </LabeledInput>
            </div>
            <LabeledInput
              onChange={() => {
                setFormChanged(true);
              }}
              required
              formItemName="newInspectionInspectedBy"
              label="inspected by"
              style={{ width: "220px" }}
            >
              <Select
                data-testid="inspector-name"
                onChange={() => {
                  setFormChanged(true);
                }}
                placeholder="Inspector Name"
                popupClassName={isDarkMode && "darkDropDown"}
                suffixIcon={<DropDownArrow />}
                onSelect={() => {}}
                disabled={!!!currentProject}
                onClick={() => {
                  !!!currentProject &&
                    message.error("Please first select a Project!");
                }}
              >
                {updatedInspectors.map(({ name, cognitoUserId }, i) => (
                  <Select.Option key={i} value={cognitoUserId}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            </LabeledInput>
            <div className="teams">
              <InputComponent
                dataTestid="teams"
                label={"Teams"}
                type="select"
                dropdownClassName={isDarkMode && "darkDropDown"}
                mode="multiple"
                formItemName="newInspectionTeams"
                placeholder="Select team"
                customOptions={teamsOptions}
                onSelect={onSelectTeam}
                onDeselect={onDeselectTeam}
                initialValue={currentProject?.team || null}
              />
              <div style={{ display: "flex", gap: 5 }}>
                {!!selectedTeam?.length && (
                  <MondayButton
                    onClick={openMemberModal}
                    Icon={<PlusOutlined />}
                    className="membersButton"
                  >
                    Members
                  </MondayButton>
                )}
                <DynamicAvatars
                  {...{
                    title: "New Inspection Team",
                    members: selectedTeam?.reduce(
                      (acc, { members }) => [...acc, ...members],
                      []
                    ),
                  }}
                />
              </div>
            </div>
            <div
              onClick={() => {
                !!!currentProject &&
                  message.error("Please first select a Project!");
              }}
            >
              <LabeledInput
                onChange={() => {
                  setFormChanged(true);
                }}
                required
                formItemName="newInspectionProjectManager"
                label="project manager"
                style={{ width: "220px" }}
                initialValue={[]}
              >
                <Select
                  data-testid="project-managers"
                  onChange={() => setFormChanged(true)}
                  placeholder="Project Managers"
                  popupClassName={isDarkMode && "darkDropDown"}
                  suffixIcon={<DropDownArrow />}
                  disabled={!!!currentProject}
                  onClick={() => {
                    !!!currentProject &&
                      message.error("Please first select a Project!");
                  }}
                  mode="multiple"
                >
                  {projectManagers?.map((manager, i) => (
                    <Select.Option key={i} value={manager}>
                      {manager}
                    </Select.Option>
                  ))}
                </Select>
              </LabeledInput>
            </div>
          </>
        ) : (
          <div className="newInspNextContainer">
            <Flex justify="space-between">
              <div className="newInspNextTitle">Services</div>
              {inspectionType?.isAffectedBySchedule && (
                <Space direction="horizontal">
                  <Text span strong>
                    {progressLabel}
                  </Text>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={displayProgress}
                    onChange={() => setDisplayProgress(!displayProgress)}
                  />
                </Space>
              )}
            </Flex>
            <Flex vertical gap="middle" className="newInspServicesContainer">
              {Object.entries(serviceOptions)?.map(([key, value], index) =>
                value.services.length > 0 ? (
                  <div key={index}>
                    {!!value?.estimationId && value.estimationNumber !== "" ? (
                      <Link
                        target="_blank"
                        to={`/estimations/${value?.estimationId}`}
                      >
                        <h3>Estimation {value.estimationNumber}</h3>
                      </Link>
                    ) : (
                      <h3>Estimation #</h3>
                    )}
                    {value?.services?.length > 0
                      ? value.services?.map((service, i) => (
                          <ServiceCollapse
                            key={`${key}+${service?.serviceId}`}
                            service={service}
                            displayProgress={displayProgress}
                            progressLabel={progressLabel}
                            totalValues={totalValues}
                            setDescription={setDescription}
                            setDescriptionModalVisible={
                              setDescriptionModalVisible
                            }
                            setServiceValues={setServiceValues}
                          />
                        ))
                      : null}
                  </div>
                ) : null
              )}
            </Flex>
          </div>
        )}
        {open && (
          <DynamicTeamModal
            {...{
              open,
              setOpen,
              selectedTeam,
              setSelectedTeam,
              ClearOptions,
              onSave: (data) => {
                form.setFieldValue("newInspectionTeams", data || []);
              },
              proppedAllTeams: allTeams,
            }}
          />
        )}
        {tourOpen && (
          <Tour
            open={tourOpen}
            onClose={() => setTourOpen(false)}
            steps={tourSteps}
          />
        )}
        {showVideoTutorial && (
          <PlayVideoTutorial
            {...{
              visible: showVideoTutorial,
              setVisible: setShowVideoTutorial,
              url: videoTutorialLink,
              title: "Inspection Tutorial",
            }}
          />
        )}
        {alert && (
          <Alert
            className="newInspCancelAlert"
            type="warning"
            closable={false}
            message="Warning!"
            showIcon={false}
            description="You will lose all the changes made so far!"
            action={
              <Space direction="horizontal">
                <Button
                  danger
                  type="primary"
                  onClick={() => {
                    setAlert(false);
                    setVisible(false);
                    setNext(false);
                    resetFields(CREATE_INSPECTIONS_FIELDS);
                    setCurrentProject();
                    setSelectedTeam([]);
                    form.resetFields();
                  }}
                >
                  Close Anyway
                </Button>
                <Button type="primary" onClick={() => setAlert(false)}>
                  Continue
                </Button>
              </Space>
            }
          />
        )}

        {visibleCreationProgress && creationProgresses && (
          <ProgressComponent
            {...{
              categoryName: "Inspections",
              actionType: "Create",
              visibleCreationProgress,
              creationProgresses,
              closeModal: () => {
                const { inspectionId } = visibleCreationProgress;
                setVisible(false);
                inspectionId &&
                  !!!isNextStep &&
                  navigate(`/inspectionsView/${inspectionId}`);
              },
            }}
          />
        )}
      </InspectionModal>
    </FieldValueCtx.Provider>
  );
};
export default NewInspectionModal;
