import { Button, ConfigProvider, Layout } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  fetchEmailsFailure,
  fetchEmailsFinished,
  fetchEmailsStart,
  fetchEmailsSuccess,
  resetCommunicationState,
  setCurrentPage,
  setEmailActionType,
  setSelectedCategory,
} from "../../../../../actions/communicationActions";
import { useQuery, useResponsive } from "../../../../../hooks";
import { showErrorMsg } from "../../../../../utils";
import {
  CategoriesCollapseIcon as CollapseIcon,
  ComposeIcon,
  CategoriesExpandIcon as ExpandIcon,
} from "../../assets";

import { driveApi } from "../../../../../integrations/DriveRequest";
import { getData } from "../../functions";
import { useCommunication } from "../../providers/CommunicationProvider";
import CommunicationContent from "../communication-content/CommunicationContent";
import EmailBoxContainer from "../EmailBox/EmailBoxContainer";
import { useEmailBoxContainer } from "../EmailBox/providers/EmailBoxContainerProvider";
import SidebarMenu from "./Menu/SidebarMenu";
import { handleGoogleDriveUpload } from "../../../Projects/utils/email-attachments-helpers";

import "./communication-sidebar.scss";

const CommunicationSidebar = ({
  isRedirected,
  searchValue,
  filterObject,
  categoryName,
  recordId,
}) => {
  const { topicCategories } = useSelector((state) => state.topicCategories);
  const recordName = topicCategories?.find(
    (c) => c.categoryName === categoryName
  )?.apiName;
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const dispatch = useDispatch();

  const [collapsed, setCollapsed] = useState(false);
  const { mobile } = useResponsive();
  const {
    emails = [],
    drafts = [],
    recordData = { recordName: "", recordId: "" },
    selectedCategory,
  } = useSelector((state) => state.communication);
  const { authData } = useCommunication();
  const params = useQuery().get("threadId");
  const navigate = useNavigate();
  const location = useLocation();
  const { accessToken } = useSelector((state) => state.accessToken);
  const driveRequest = driveApi({ accessToken });
  const { openEmailBox, emailBoxes, setAttachments } = useEmailBoxContainer();

  // this is used when user is redirected from anywhere in app to the communication and a state is passed in the location state
  useEffect(() => {
    if (location?.state?.data?.openEmailBox && emailBoxes.length === 0) {
      // the function which opens the email box
      openEmailBox(null, mobile);

      // if any files in state to be fetched and update the attachments state
      const fetchDriveFiles = async (file) => {
        try {
          // This will get the already uploaded file from the google drive and return the needed formatted object to show in the email box attachments
          const response = await handleGoogleDriveUpload(file, driveRequest);
          setAttachments([response]);
        } catch (error) {
          console.error("Error fetching drive files:", error);
        }
      };

      if (location?.state?.data?.file) {
        fetchDriveFiles(location?.state?.data?.file);
      }
    }
  }, [location?.key]);

  useEffect(() => {
    // the reusuable getData function which  fetches the emails every time the authData changed
    getData(
      recordName,
      recordId,
      dispatch,
      fetchEmailsStart,
      fetchEmailsSuccess,
      fetchEmailsFailure,
      fetchEmailsFinished,
      showErrorMsg,
      authData
    );

    return () => {
      dispatch(resetCommunicationState());
    };
  }, [authData]);

  useEffect(() => {
    // the sidebar will be collapsed on mobile viewport
    if (mobile) {
      setCollapsed(true);
    }
  }, [mobile]);

  // handles sidebar category selection which will update redux state inside communicationReducer.js
  const handleMenuSelect = ({ key, label }) => {
    if (params) {
      navigate("?tab=Communication");
    }
    dispatch(setSelectedCategory({ key, label }));
    dispatch(setCurrentPage(1));
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            itemBorderRadius: 0,
          },
        },
      }}
    >
      <Layout
        className={`communication-sidebar-container ${
          isDarkMode ? "dark-mode" : ""
        }`}
        data-testid="communication-sidebar-container"
      >
        <Layout.Sider
          collapsible
          collapsed={collapsed}
          className={`communication-sidebar ${
            collapsed ? "communication-sidebar-collapsed" : ""
          }`}
          width={300}
          trigger={null}
          data-testid="communication-sidebar"
        >
          <div
            className={`compose-email-container ${
              collapsed ? "collapsed" : ""
            }`}
            data-testid="compose-email-container"
          >
            <Button
              className="compose-email-content"
              type="text"
              icon={<ComposeIcon className="compose-icon" />}
              onClick={() => {
                dispatch(setEmailActionType("COMPOSE"));
                openEmailBox(null, mobile);
              }}
              data-testid="compose-button"
            >
              {!collapsed && "Compose"}
            </Button>
          </div>

          <SidebarMenu
            collapsed={collapsed}
            selectedCategory={selectedCategory}
            handleMenuSelect={handleMenuSelect}
          />

          {!mobile && (
            <div style={{ position: "absolute", bottom: 50, right: -15 }}>
              <Button
                className="collapsed-button"
                icon={
                  collapsed ? (
                    <ExpandIcon style={{ fill: "red", color: "red" }} />
                  ) : (
                    <CollapseIcon style={{ fill: "red", color: "red" }} />
                  )
                }
                onClick={() => setCollapsed(!collapsed)}
                data-testid="collapse-button"
              />
            </div>
          )}
        </Layout.Sider>

        {/* Communication content is responsible for only showin the content of emails */}

        <CommunicationContent
          selectedCategory={selectedCategory}
          openEmailBox={(draftId) => openEmailBox(draftId, mobile)}
          fetchData={() =>
            // getData is passed so user can refresh the data and update the ui
            getData(
              recordName,
              recordId,
              dispatch,
              fetchEmailsStart,
              fetchEmailsSuccess,
              fetchEmailsFailure,
              fetchEmailsFinished,
              showErrorMsg,
              authData
            )
          }
          searchValue={searchValue}
          filterObject={filterObject}
        />
      </Layout>

      <EmailBoxContainer
        data={{ emails, drafts }}
        rowData={location?.state?.data?.rowData || recordData}
        isRedirected={isRedirected}
        authData={authData}
      />
    </ConfigProvider>
  );
};

export default CommunicationSidebar;
