import moment from "moment-timezone";
import { EditIcon } from "../../../../../../icons";
import { DeleteDataset } from "../components/DeleteDataset/DeleteDataset";
import { Tooltip } from "antd";
import { LogsButton, MondaySwitch } from "../../../../../commonComponents";
import AgDescription from "../../../../../commonComponents/AgDescription/AgDescription";
import { Notes } from "../../../../../commonComponents/Notes/Notes";

moment.tz.setDefault("America/New_York");

export const excelColumnsConfig = [
  "title",
  "path",
  "dataSetName",
  "createdAt",
  "createdBy",
];

export const columnDefs = ({
  onDeleteDataset,
  setIsModalOpen,
  isDeleteModalOpen,
  setIsDeleteModalOpen,
  updateState,
}) => {
  const centerItem = {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return [
    {
      headerName: "Title",
      field: "title",
      resizable: true,
      filter: "agMultiColumnFilter",
      minWidth: 200,
      flex: 1,
    },
    {
      headerName: "Path",
      field: "path",
      resizable: true,
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "Dataset State",
      field: "datasetState",
      filter: "agMultiColumnFilter",
      cellRendererFramework: ({ data }) => (
        <div style={{ margin: "auto 0" }}>
          <MondaySwitch
            {...{
              checked: data?.datasetState,
              onToggleSwitch: () => {
                updateState(data);
              },
              onText: "Enabled",
              offText: "Disabled",
              removeIcon: false,
            }}
          />
        </div>
      ),
      getQuickFilterText: ({ value }) => (value ? "Enabled" : "Disabled"),
      pdfExportOptions: {
        modifyCellValue: (data) => {
          return data ? "Enabled" : "Disabled";
        },
      },
    },
    {
      headerName: "Dataset Name",
      field: "dataSetName",
      resizable: true,
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "Description",
      field: "description",
      cellRendererFramework: ({ data }) => (
        <AgDescription
          {...{
            title: `Details`,
            title: `Report Base Pages Datasets - ${data.title} Details`,
            value: data?.description || "",
          }}
        />
      ),
    },
    {
      headerName: "Notes",
      field: "notes",
      filter: "agMultiColumnFilter",
      cellRendererFramework: (params) => (
        <div style={{ fontWeight: "600" }}>
          <Notes
            {...{
              noteModalName: params?.data?.title,
              rowId: params?.data?.id,
              topicCategory: "Report Base Pages Datasets",
              customLinkTo: window.location.pathname.substring(1),
            }}
          />
        </div>
      ),
      pdfExportOptions: {
        skipColumn: true,
      },
    },
    {
      headerName: "Created At",
      field: "createdAt",
      filter: "agMultiColumnFilter",
      cellRenderer: ({ value }) => moment(value).format("MM/DD/YYYY HH:mm"),
      pdfExportOptions: {
        modifyCellValue: (value) => moment(value).format("MM/DD/YYYY HH:mm"),
      },
      resizable: true,
    },
    {
      headerName: "Created By",
      field: "createdBy",
      resizable: true,
      filter: "agMultiColumnFilter",
    },
    {
      headerName: "Logs",
      cellRendererFramework: ({ data }) => (
        <div style={{ margin: "auto 0" }}>
          <LogsButton
            {...{
              type: "onlyIcon",
              logsTitle: "Report Base Page Logs",
              filtersObj: { recordId: data.id },
            }}
          />
        </div>
      ),
      pdfExportOptions: {
        skipColumn: true,
      },
    },
    {
      headerName: "Edit",
      filter: "agMultiColumnFilter",
      enableRowGroup: false,
      pdfExportOptions: {
        skipColumn: true,
      },
      cellRenderer: ({ data }) => {
        return (
          <div style={centerItem}>
            <EditIcon
              style={{ cursor: "pointer", width: 14, height: 14 }}
              fill="#1264A3"
              onClick={() => setIsModalOpen(data)}
            />
          </div>
        );
      },
    },
    {
      headerName: "Delete",
      filter: "agMultiColumnFilter",
      enableRowGroup: false,
      pdfExportOptions: {
        skipColumn: true,
      },
      cellRenderer: ({ data }) => {
        return (
          <Tooltip title="Delete">
            <div style={centerItem}>
              <DeleteDataset
                {...{
                  data,
                  onDeleteDataset,
                  isDeleteModalOpen,
                  setIsDeleteModalOpen,
                }}
              />
            </div>
          </Tooltip>
        );
      },
    },
    // {
    //   headerName: "Actions",
    //   field: "actions",
    //   maxWidth: 150,
    //   pdfExportOptions: {
    //     skipColumn: true,
    //   },
    //   cellRenderer: ({ data }) => {
    //     return (
    //       <div style={{ display: "flex", gap: "8px" }}>
    //         <EditFilled
    //           style={{
    //             ...actionsStyle,
    //             backgroundColor: "#1264a3",
    //           }}
    //           onClick={() => setIsModalOpen(data)}
    //         />
    //         <DeleteFilled
    //           style={{
    //             ...actionsStyle,
    //             backgroundColor: "red",
    //           }}
    //           onClick={() => setIsDeleteModalOpen(data)}
    //         />
    //       </div>
    //     );
    //   },
    //   resizable: false,
    // },
  ];
};

export const gridFilters = [
  {
    globalKeyId: "8b3b4bc5-4df3-42c9-801c-1ae3627a7b4f",
    label: "Title",
    key: "title",
    type: "Dropdown",
  },
  {
    globalKeyId: "389eea4b-206c-42df-92d5-d6a51fe4768e",
    label: "Path",
    key: "path",
    type: "Dropdown",
  },
  {
    globalKeyId: "cb673532-d69b-4853-8462-e154a3aac868",
    label: "Dataset Name",
    key: "dataSetName",
    type: "Dropdown",
  },
  {
    globalKeyId: "b971edcb-448f-42dd-9993-75f2cfbfb504",
    label: "Created At",
    key: "createdAt",
    type: "DateRange",
  },
  {
    globalKeyId: "bf21014f-7640-4667-b332-af3c5e980b46",
    label: "Created By",
    key: "createdBy",
    type: "Dropdown",
  },
];
