import { getAll } from "../components/Header/components/Notifications/NotificationData";
import {
  updateAllNotificationsStatus,
  updateNotificationStatus,
} from "../components/Header/components/Notifications/utils/helpers";
import types from "../types";

const initialState = {
  notifications: {
    all: {
      unfiltered: { lastEvaluatedKey: "", notifications: [] },
      important: { lastEvaluatedKey: "", notifications: [] },
      critical: { lastEvaluatedKey: "", notifications: [] },
      info: { lastEvaluatedKey: "", notifications: [] },
      unread: { lastEvaluatedKey: "", notifications: [] },
      today: { lastEvaluatedKey: "", notifications: [] },
      thisWeek: { lastEvaluatedKey: "", notifications: [] },
      unauthorizedAccess: { lastEvaluatedKey: "", notifications: [] },
      unreadLength: 0,
    },
    hidden: {
      unfiltered: { lastEvaluatedKey: "", notifications: [] },
      filtered: {
        important: { lastEvaluatedKey: "", notifications: [] },
        critical: { lastEvaluatedKey: "", notifications: [] },
        info: { lastEvaluatedKey: "", notifications: [] },
        unread: { lastEvaluatedKey: "", notifications: [] },
        today: { lastEvaluatedKey: "", notifications: [] },
        thisWeek: { lastEvaluatedKey: "", notifications: [] },
        unauthorizedAccess: { lastEvaluatedKey: "", notifications: [] },
      },
      unreadLength: 0,
    },
    iWasMentioned: {
      unfiltered: { lastEvaluatedKey: "", notifications: [] },
      important: { lastEvaluatedKey: "", notifications: [] },
      critical: { lastEvaluatedKey: "", notifications: [] },
      info: { lastEvaluatedKey: "", notifications: [] },
      unread: { lastEvaluatedKey: "", notifications: [] },
      today: { lastEvaluatedKey: "", notifications: [] },
      thisWeek: { lastEvaluatedKey: "", notifications: [] },
      unauthorizedAccess: { lastEvaluatedKey: "", notifications: [] },
      unreadLength: 0,
    },
    assignedToMe: {
      unfiltered: { lastEvaluatedKey: "", notifications: [] },
      important: { lastEvaluatedKey: "", notifications: [] },
      critical: { lastEvaluatedKey: "", notifications: [] },
      info: { lastEvaluatedKey: "", notifications: [] },
      unread: { lastEvaluatedKey: "", notifications: [] },
      today: { lastEvaluatedKey: "", notifications: [] },
      thisWeek: { lastEvaluatedKey: "", notifications: [] },
      unauthorizedAccess: { lastEvaluatedKey: "", notifications: [] },
      unreadLength: 0,
    },
    sessions: {
      unfiltered: { lastEvaluatedKey: "", notifications: [] },
      important: { lastEvaluatedKey: "", notifications: [] },
      critical: { lastEvaluatedKey: "", notifications: [] },
      info: { lastEvaluatedKey: "", notifications: [] },
      unread: { lastEvaluatedKey: "", notifications: [] },
      today: { lastEvaluatedKey: "", notifications: [] },
      thisWeek: { lastEvaluatedKey: "", notifications: [] },
      unreadLength: 0,
    },
    communication: {
      unfiltered: { notifications: [] },
      important: { notifications: [] },
      critical: { notifications: [] },
      info: { notifications: [] },
      unread: { notifications: [] },
      today: { notifications: [] },
      thisWeek: { notifications: [] },
      unreadLength: 0,
    },
  },
  communication: { emails: [], unreadLength: 0 },
  authenticatedUser: null,
  notificationsLoading: false,
  emailsLoading: false,
  tab: "all",
  filterBy: null,
};

const notificationsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_NOTIFICATIONS: {
      const {
        notifications,
        authenticatedUser,
        lastEvaluatedKey,
        unreadLength,
      } = payload;
      const tabNotifications = state.notifications[state.tab] || {};
      const filterKey = state.filterBy ? state.filterBy : "unfiltered";
      const filterNotifications = tabNotifications[filterKey] || {
        notifications: [],
      };

      // console.log({
      //   payload,
      //   types,
      //   state,
      //   notifications,
      //   tabNotifications,
      //   filterKey,
      //   filterNotifications,
      // });

      return {
        ...state,
        notifications: {
          ...state.notifications,
          [state.tab]: {
            ...tabNotifications,
            [filterKey]: {
              ...filterNotifications,
              notifications: [
                ...(filterNotifications.notifications || []),
                ...(notifications || []),
              ],
              lastEvaluatedKey,
            },
            unreadLength:
              tabNotifications.unreadLength > 0
                ? tabNotifications.unreadLength + unreadLength
                : unreadLength,
          },
        },
        authenticatedUser,
      };
    }

    case types.ADD_NOTIFICATION: {
      const { notification, category, notificationSettings } = payload;
      const filterKey = state.filterBy ? state.filterBy : "unfiltered";
      const tabNotifications = state.notifications[state.tab] || {};

      const notificationToAdd =
        getAll({
          notifications: [notification],
          authenticatedUser: state.authenticatedUser,
          notificationSettings: notificationSettings,
        }) || [];

      const unreadLength =
        tabNotifications?.unreadLength > 0
          ? tabNotifications?.unreadLength + notificationToAdd?.unreadLength
          : notificationToAdd?.unreadLength;

      return {
        ...state,
        notifications: {
          ...state.notifications,
          [category]: {
            ...state.notifications[category],
            [filterKey]: {
              ...state.notifications[category][filterKey],
              notifications: [
                ...(notificationToAdd?.notifications || []),
                ...(state.notifications[category]?.[filterKey]?.notifications ||
                  []),
              ],
            },
            unreadLength,
          },
        },
      };
    }

    case types.DELETE_NOTIFICATION: {
      const { id } = payload;
      const filterKey = state.filterBy ? state.filterBy : "unfiltered";
      const newUnreadLength =
        state.notifications?.[state.tab]?.[filterKey]?.notifications?.filter(
          (notification) => notification.id === id && !notification.seen
        ).length || 0;
      const prevUnreadLength = state?.notifications[state.tab].unreadLength;

      return {
        ...state,
        notifications: {
          ...state.notifications,
          [state.tab]: {
            ...state.notifications[state.tab],
            [filterKey]: {
              ...state.notifications[state.tab][filterKey],
              notifications: state.notifications[state.tab][
                filterKey
              ].notifications.filter((notification) => notification.id !== id),
            },
            unreadLength:
              prevUnreadLength !== 0
                ? prevUnreadLength - newUnreadLength
                : prevUnreadLength,
          },
        },
      };
    }

    case types.UPDATE_NOTIFICATIONS: {
      const { notifications: notificationIds, action } = payload;
      const tabNotifications = state.notifications[state.tab] || {};
      const filterKey = state.filterBy || "unfiltered";
      const filterNotifications = tabNotifications[filterKey] || {
        notifications: [],
      };
      const newUnreadLength =
        state.notifications?.[state.tab]?.[filterKey]?.notifications?.filter(
          (notification) =>
            notificationIds?.includes(notification.id) && !notification?.seen
        ).length || 0;
      const prevUnreadLength = state?.notifications[state.tab].unreadLength;

      const updateState = (newNotifications) => ({
        ...state,
        notifications: {
          ...state.notifications,
          [state.tab]: {
            ...tabNotifications,
            [filterKey]: {
              ...filterNotifications,
              notifications: newNotifications,
            },
            unreadLength:
              prevUnreadLength !== 0
                ? prevUnreadLength - newUnreadLength
                : prevUnreadLength,
          },
        },
      });

      const findNotificationsByIds = (ids, notificationList) =>
        notificationList.filter((notification) =>
          ids.includes(notification.id)
        );

      const removeNotificationsByIds = (ids, notificationList) =>
        notificationList.filter(
          (notification) => !ids.includes(notification.id)
        );

      const updateNotificationsByIds = (ids, notificationList) =>
        notificationList.map((notification) =>
          ids.includes(notification.id)
            ? { ...notification, seen: !notification.seen }
            : notification
        );

      switch (action) {
        case "hide": {
          const notificationsToHide = findNotificationsByIds(
            notificationIds,
            filterNotifications.notifications
          );
          const hiddenNotifications = [
            ...(state.notifications.hidden.unfiltered.notifications || []),
            ...notificationsToHide,
          ];
          const updatedFilterNotifications = removeNotificationsByIds(
            notificationIds,
            filterNotifications.notifications
          );

          return {
            ...state,
            notifications: {
              ...state.notifications,
              hidden: {
                ...state.notifications.hidden,
                unfiltered: { notifications: hiddenNotifications },
              },
              [state.tab]: {
                ...tabNotifications,
                [filterKey]: {
                  ...filterNotifications,
                  notifications: updatedFilterNotifications,
                },
              },
            },
          };
        }

        case "see": {
          const updatedNotifications = updateNotificationsByIds(
            notificationIds,
            filterNotifications.notifications
          );
          return updateState(updatedNotifications);
        }

        case "move to all": {
          const hiddenNotifications =
            state.notifications.hidden.unfiltered.notifications || [];
          const notificationsToAddBackToAll = findNotificationsByIds(
            notificationIds,
            hiddenNotifications
          );
          const updatedHiddenNotifications = removeNotificationsByIds(
            notificationIds,
            hiddenNotifications
          );
          const updatedAllNotifications = [
            ...notificationsToAddBackToAll,
            ...state.notifications.all.unfiltered.notifications,
          ];

          return {
            ...state,
            notifications: {
              ...state.notifications,
              all: {
                ...state.notifications.all,
                unfiltered: { notifications: updatedAllNotifications },
              },
              hidden: {
                ...state.notifications.hidden,
                unfiltered: { notifications: updatedHiddenNotifications },
              },
            },
          };
        }

        case "mark as read": {
          const { tab, filterBy } = state;
          const filterKey = filterBy || "unfiltered";
          const tabNotifications = state.notifications[tab] || {};
          const notificationsToUpdate =
            tabNotifications[filterKey]?.notifications || [];

          const updatedNotifications = updateNotificationStatus(
            notificationsToUpdate,
            notificationIds,
            true
          );

          return {
            ...state,
            notifications: {
              ...state.notifications,
              [tab]: {
                ...tabNotifications,
                [filterKey]: {
                  ...tabNotifications[filterKey],
                  notifications: updatedNotifications,
                },
                unreadLength:
                  tabNotifications.unreadLength.length !== 0
                    ? tabNotifications.unreadLength.length -
                      notificationIds.length
                    : 0,
              },
            },
          };
        }

        case "mark as unread": {
          const { tab, filterBy } = state;
          const filterKey = filterBy || "unfiltered";
          const tabNotifications = state.notifications[tab] || {};
          const notificationsToUpdate =
            tabNotifications[filterKey]?.notifications || [];

          const updatedNotifications = updateNotificationStatus(
            notificationsToUpdate,
            notificationIds,
            false
          );

          return {
            ...state,
            notifications: {
              ...state.notifications,
              [tab]: {
                ...tabNotifications,
                [filterKey]: {
                  ...tabNotifications[filterKey],
                  notifications: updatedNotifications,
                },
                unreadLength:
                  tabNotifications.unreadLength + notificationIds.length,
              },
            },
          };
        }

        case "mark all as read": {
          const { tab, filterBy } = state;
          const filterKey = filterBy || "unfiltered";
          const tabNotifications = state.notifications[tab] || {};
          const notificationsToUpdate =
            tabNotifications[filterKey]?.notifications || [];

          const updatedNotifications = updateAllNotificationsStatus(
            notificationsToUpdate,
            true
          );

          return {
            ...state,
            notifications: {
              ...state.notifications,
              [tab]: {
                ...tabNotifications,
                [filterKey]: {
                  ...tabNotifications[filterKey],
                  notifications: updatedNotifications,
                },
                unreadLength: 0,
              },
            },
          };
        }

        case "mark all as unread": {
          const { tab, filterBy } = state;
          const filterKey = filterBy || "unfiltered";
          const tabNotifications = state.notifications[tab] || {};
          const notificationsToUpdate =
            tabNotifications[filterKey]?.notifications || [];

          const updatedNotifications = updateAllNotificationsStatus(
            notificationsToUpdate,
            false
          );

          return {
            ...state,
            notifications: {
              ...state.notifications,
              [tab]: {
                ...tabNotifications,
                [filterKey]: {
                  ...tabNotifications[filterKey],
                  notifications: updatedNotifications,
                },
                unreadLength: updatedNotifications.length,
              },
            },
          };
        }

        case "delete": {
          const updatedFilterNotifications = removeNotificationsByIds(
            notificationIds,
            filterNotifications.notifications
          );
          return updateState(updatedFilterNotifications);
        }

        default:
          return state;
      }
    }

    case types.GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        notificationsLoading: true,
      };

    case types.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationsLoading: false,
      };

    case types.GET_EMAIL_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        emailsLoading: true,
      };

    case types.GET_EMAIL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        communication: {
          unreadLength: payload.unreadLength,
          emails: payload.emails,
        },
      };

    case types.GET_EMAIL_NOTIFICATIONS_REQUEST_FINISHED:
      return {
        ...state,
        emailsLoading: false,
      };

    case types.MARK_EMAILS_AS_READ:
      return {
        ...state,
        communication: {
          emails: state.communication.emails.map((email) =>
            email.id === payload.itemId
              ? {
                  ...email,
                  labels: email.labels.filter((label) => label !== "UNREAD"),
                }
              : email
          ),
          unreadLength:
            (state?.communication?.unreadLength > 0 &&
              state?.communication?.unreadLength - 1) ||
            0,
        },
      };

    case types.GET_EMAIL_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        communication: {
          emails: [],
          unreadLength: 0,
        },
        emailsLoading: false,
      };

    case types.SET_FILTERS:
      const { tab, filterBy } = payload;
      return {
        ...state,
        tab: tab,
        filterBy: filterBy,
      };

    case types.TOGGLE_NOTIFICATION_PINNED_STATUS: {
      const { tab, filterBy, notificationId } = payload;
      const filterKey = filterBy || "unfiltered";
      const tabNotifications = state.notifications[tab] || {};
      const notificationsToUpdate =
        tabNotifications[filterKey]?.notifications || [];

      const togglePinnedStatus = (notifications, id) => {
        return notifications.map((notification) => {
          if (notification.id === id) {
            return {
              ...notification,
              isPinned: !notification.isPinned,
            };
          }
          return notification;
        });
      };

      const updatedNotifications = togglePinnedStatus(
        notificationsToUpdate,
        notificationId
      );

      const sortedUpdatedNotifications = updatedNotifications.sort((a, b) => {
        if (a.isPinned !== b.isPinned) {
          return a.isPinned ? -1 : 1;
        }

        return b.createdAt - a.createdAt;
      });

      return {
        ...state,
        notifications: {
          ...state.notifications,
          [tab]: {
            ...tabNotifications,
            [filterKey]: {
              ...tabNotifications[filterKey],
              notifications: sortedUpdatedNotifications,
            },
          },
        },
      };
    }

    default:
      return state;
  }
};
export default notificationsReducer;
