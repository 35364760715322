import { Link } from "react-router-dom";
import { Notes } from "../../../commonComponents/Notes/Notes";
import { parsePhone } from "../../Accounting/components/utilities";
import gridFilterDate from "../../BasePage/components/utils/gridFilterDate";
import { queryParams } from "../../../../utils/queryParams";
import dayjs from "dayjs";
import { sortDates } from "../../ToDos/AgGridData/columnDefs";
import { AvatarImages } from "../../../commonComponents";

export const excelColumnsConfig = [
  "accountName",
  "accountPhone",
  "billingAddress",
  "subcontractorType",
  "createdAt",
  "createdBy",
  "shippingAddress",
  "accountRecordType",
  "borough",
];

export const columnDefs = ({ view }) => [
  {
    headerName: "Account Name",
    field: "accountName",
    resizable: true,
    cellRendererFrameworkParams: { view },
    link: (data) => `/accounts/${data.accountId}?tab=Overview`,
    cellRendererFramework: function (params) {
      try {
        return (
          <Link
            to={
              view
                ? {
                    pathname: `/accounts/${params.data.accountId}`,
                    state: { tabPosition: "overview" },
                    search: queryParams({
                      tab: "Overview",
                    }),
                  }
                : null
            }
            style={{ color: "#1065A4" }}
          >
            {params.data?.accountName}
          </Link>
        );
      } catch (e) {
        return null;
      }
    },
  },
  {
    headerName: "Phone Number",
    field: "accountPhone",
    valueFormatter: function (params) {
      return parsePhone(params.value);
    },
    minWidth: 170,
    maxWidth: 170,
  },
  {
    headerName: "Account Address",
    field: "billingAddress",
    resizable: true,
  },
  {
    headerName: "Subcontractor Type",
    field: "subcontractorType",
    resizable: true,
  },
  {
    headerName: "Notes",
    field: "notes",
    filter: "agMultiColumnFilter",
    minWidth: 100,
    enableRowGroup: false,
    pdfExportOptions: {
      skipColumn: true,
    },
    cellRendererFramework: (params) => {
      return Notes({
        noteModalName: params?.data?.accountName,
        rowId: params?.data?.accountId,
        topicCategory: "Subcontractors",
        teamsConfiguration: params?.data?.teamsConfiguration,
        customLinkTo: `accounts/${params?.data?.accountId}`,
      });
    },
  },
  {
    headerName: "Created At",
    field: "createdAt",
    sort: "desc",
    filter: "agDateColumnFilter",
    // filterParams: {
    //   comparator: (filterLocalDateAtMidnight, cellValue) =>
    //     gridFilterDate(filterLocalDateAtMidnight, cellValue),
    // },
    comparator: (date1, date2) => sortDates(date1, date2, "MM/DD/YYYY"),
    getQuickFilterText: () => "",
    // cellRenderer: ({ value }) =>
    //   `<div>${
    //     new Date(value).toLocaleDateString().includes("Invalid Date")
    //       ? "--"
    //       : new Date(value).toLocaleDateString()
    //   }</div>`,

    // pdfExportOptions: {
    //   modifyCellValue: (date) =>
    //     date
    //       ? new Date(date).toLocaleDateString("en-US", {
    //           year: "numeric",
    //           month: "2-digit",
    //           day: "2-digit",
    //         })
    //       : "",
    // },
    valueGetter: ({ data }) => {
      return dayjs(data?.createdAt).format("MM/DD/YYYY");
    },
  },
  {
    headerName: "Created By",
    field: "createdBy",
    valueGetter: ({ data }) =>
      data?.createdBy?.name || data?.createdBy?.nameOfUser || data?.createdBy,
    hide: true,
    minWidth: 150,
    cellRenderer({ value }) {
      return (
        <AvatarImages
          {...{
            nameOfUsers: [value],
          }}
        />
      );
    },
  },
  //excluded(hide) values beacuse comes from promise.all and is needed for filter from overview to work
  {
    headerName: "Shipping Address",
    field: "shippingAddress",
    // hide: true,
    chartDataType: "category",
    minWidth: 150,
  },
  //excluded(hide) values beacuse comes from promise.all and is needed for filter from overview to work
  {
    headerName: "Account Record Type",
    field: "accountRecordType",
    // hide: true,
    chartDataType: "category",
    minWidth: 150,
  },
  //excluded(hide) values beacuse comes from promise.all and is needed for filter from overview to work
  {
    headerName: "Borough",
    field: "borough",
    // hide: true,
    chartDataType: "category",
    minWidth: 150,
  },
];
