function proposalTemplateEditLogs(
  oldTemplate,
  newTemplate,
  serviceDefinitions
) {
  let toReturn = { prev: {}, curr: {} };
  const { templateContent, additionalDetails, templateCategory } =
    newTemplate || {};
  const { templateContent: oldContent, additionalDetails: oldDetails } =
    oldTemplate || {};

  const { styles, service, services } = additionalDetails || {};
  const {
    styles: oldStyles,
    service: oldService,
    services: oldServices,
  } = oldDetails || {};
  if (templateContent !== oldContent) {
    toReturn.prev.templateContent = "";
    toReturn.curr.templateContent = "Template Content Changed";
  }
  if (
    JSON.stringify(styles) !== JSON.stringify(oldStyles) &&
    templateCategory === "service"
  ) {
    toReturn.prev.styles = "";
    toReturn.curr.styles = "Styles Changed";
  }
  if (JSON.stringify(service) !== JSON.stringify(oldService)) {
    toReturn.prev.service = oldService?.serviceName || "Service Changed";
    toReturn.curr.service = service?.serviceName || "";
  }

  if (JSON.stringify(services) !== JSON.stringify(oldServices)) {
    const servicesObj = serviceDefinitions.reduce((acc, curr) => {
      acc[curr.serviceId] = curr.serviceName;
      return acc;
    }, {});

    const addedServices = services.filter(
      (item) => !oldServices.includes(item)
    );
    const removedServices = oldServices.filter(
      (item) => !services.includes(item)
    );
    toReturn.prev.services = { added: {}, removed: {} };
    toReturn.curr.services = { added: {}, removed: {} };

    const removedStrings = removedServices
      .map((item) => servicesObj[item])
      .join(", ");
    const addedStrings = addedServices
      .map((item) => servicesObj[item])
      .join(", ");
    toReturn.curr.services.removed = "Removed";
    toReturn.curr.services.added = addedStrings;

    toReturn.prev.services.removed = removedStrings;
    toReturn.prev.services.added = "Added";
  }

  return toReturn;
}

export default proposalTemplateEditLogs;
