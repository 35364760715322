const incidentCustomLogObj = {
  "Property Damage": ({ data, userConfiguration = {}, crews = [] }) => {
    const foremen = crews
      ?.filter((e) => !!e.foreman)
      ?.map(({ crewId, crewName, members }) => ({
        label: crewName,
        value: crewId,
        members,
      }));

    const crewMembers = crews
      ?.filter((e) => !e.foreman)
      ?.map(({ crewId, crewName }) => ({
        label: crewName,
        value: crewId,
      }));

    return {
      foremanOfCrewThatCausedTheDamage:
        foremen?.find(
          ({ value }) => value === data?.foremanOfCrewThatCausedTheDamage?.id
        )?.label || "",
      crewMembersThatCausedDamage: data?.crewMembersThatCausedDamage?.map(
        (el) => crewMembers?.find((e) => e?.value === el.id)?.label
      ),
      reportedPreparedBy: userConfiguration?.allUsers?.Items?.find(
        ({ identityId }) => identityId === data?.reportedPreparedBy
      ),
    };
  },

  "Personal Injury": ({ data, userConfiguration = {}, crews = [] }) => {
    const foremen = crews
      ?.filter((e) => !!e.foreman)
      ?.map(({ crewId, crewName, members }) => ({
        label: crewName,
        value: crewId,
        members,
      }));

    return {
      fullNameOfForeman:
        foremen?.find(({ value }) => value === data?.fullNameOfForeman?.id)
          ?.label || "",
      reportPreparedBy: userConfiguration?.allUsers?.Items?.find(
        ({ identityId }) => identityId === data?.reportPreparedBy
      ),
    };
  },
};

export default incidentCustomLogObj;
