// import { wsFormRequests } from "../../../../../AppData";
import {
  batchUpdateItems,
  getCognitosForNotification,
} from "../../../../../utils";
import { moveRequestFiles } from ".";
import { sendRequestWsMessageCP } from "../../../components/Approvals/utils/customerApprovals";
import broadcastNotification from "../../../../../helpers/controllers/broadcastNotification";

/**
 * Adds a new record to a request.
 *
 * @param {WebSocket} ws - The WebSocket connection.
 * @param {string} newRecordId - The ID of the new record.
 * @param {string} path - The path of the new record.
 * @param {string} requestId - The ID of the request.
 * @param {Function} setVisible - A function to set the visibility of the modal.
 * @param {Object} moveFilesParams - Parameters for moving files.
 * @param {Function} approveLog - A function to log the approval.
 * @returns {Promise<void>} - A promise that resolves when the new record is added.
 */
async function addNewRecordToRequest({
  ws = null,
  newRecordId = "",
  path = "",
  requestId = "",
  setVisible = () => {},
  moveFilesParams = null,
  approveLog = async () => {},
  userConfiguration,
  sendWsMessage = () => {},
  recordName,
  createdBy,
  authUser,
}) {
  try {
    let failedFiles = [];
    if (!!moveFilesParams) {
      if (moveFilesParams?.filesToMove?.length) {
        failedFiles = await moveRequestFiles({
          ...moveFilesParams,
        });
      }
    }

    await batchUpdateItems({
      tableName: "formRequests",
      conditionKey: "requestId",
      updateData: {
        [requestId]: {
          "requestObject.newRecordId": newRecordId,
          ...(path && { "requestObject.path": path }), //if reqObj has path then modify it
          "requestObject.gDriveUploads": failedFiles,
          "requestObject.status": "Reviewed",
          responseFrom: "approved",
        },
      },
    }).then(() => {
      ws.send(
        JSON.stringify({
          request: "request-response",
          body: {
            responseFrom: "approved",
            status: "Reviewed",
            requestId,
            newRecordId,
            path,
            gDriveUploads: failedFiles,
            // cognitoUserId,
          },
        })
      );

      broadcastNotification(
        "13",
        "onRequestApprove",
        [
          {
            common: userConfiguration?.nameOfUser,
            recordName: recordName,
          },
          {
            userName: userConfiguration?.nameOfUser,
            currentUser: authUser?.sub,
            cognitos: getCognitosForNotification(userConfiguration),
          },
        ],
        requestId
      );

      sendRequestWsMessageCP({
        sendWsMessage,
        recordId: requestId,
        action: "CP_RequestApproved",
        nameOfUser: userConfiguration.nameOfUser,
        recordName,
        createdBy,
        updatedReq: {
          responseFrom: "Approved",
          requestObject: { status: "Reviewed" },
        },
      });

      approveLog({
        status: "Reviewed",
        response: "Approved",
        newRecordId,
        path,
        gDriveUploads: failedFiles,
      }).catch((err) => {
        console.error("Error adding log: ", err);
      });
      setVisible(false);
    });
  } catch (err) {
    console.error(err);
  }
}

export default addNewRecordToRequest;
