import { Button } from "antd";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import Chained from "../components/Chained/Chained";
import { LogsButton } from "../../../../../commonComponents";

export const excelColumnsConfig = [
  "serviceId",
  "serviceName",
  "dimensions",
  "isScope",
  "hasAddonsOnPLI",
  "hasDocumentation",
  "hasServiceAddons",
  "createdAt",
];

export const formatCellValueHandler = (data) => {
  if (data.length < 1) {
    return "";
  } else {
    return data.map((item) => item).join(", ");
  }
};

export const columnDefs = ({ setSelectedRow, setOpenService }) => [
  {
    headerName: "Service ID",
    field: "serviceId",
    filter: "agMultiColumnFilter",
    enableRowGroup: false,
  },
  {
    headerName: "Service Name",
    field: "serviceName",
    filter: "agMultiColumnFilter",
    enableRowGroup: false,
    cellRendererFramework: (params) => {
      return (
        <span
          style={{ color: "#1264a3", cursor: "pointer" }}
          onClick={() => {
            setSelectedRow(params?.data);
            setOpenService(true);
          }}
        >
          {params?.data?.serviceName}
        </span>
      );
    },
  },
  {
    headerName: "Dimensions",
    field: "dimensions",
    filter: "agMultiColumnFilter",
  },
  {
    headerName: "Is Scope",
    field: "isScope",
    filter: "agMultiColumnFilter",
    cellRenderer({ value }) {
      return (
        <div style={{ margin: "auto 0" }}>
          <Button
            type="text"
            style={{
              display: "flex",
              justifyContent: "center",
              width: 70,
              background:
                value === true
                  ? "#BDE0A6"
                  : value === false
                  ? "#FCD3D3"
                  : "transparent",
              cursor: "default",
            }}
          >
            {value === true ? "Yes" : value === false ? "No" : ""}
          </Button>
        </div>
      );
    },
    pdfExportOptions: {
      modifyCellValue: (value) => {
        return value ? "Yes" : "No";
      },
    },
    cellStyle: {
      display: "flex",
      justifyContent: "center",
    },
  },
  {
    headerName: "Has Addons On PLI",
    field: "hasAddonsOnPLI",
    filter: "agMultiColumnFilter",
    cellRenderer({ value }) {
      return (
        <div style={{ margin: "auto 0" }}>
          <Button
            type="text"
            style={{
              display: "flex",
              justifyContent: "center",
              width: 70,
              background:
                value === true
                  ? "#BDE0A6"
                  : value === false
                  ? "#FCD3D3"
                  : "transparent",
              cursor: "default",
            }}
          >
            {value === true ? "Yes" : value === false ? "No" : ""}
          </Button>
        </div>
      );
    },
    cellStyle: {
      display: "flex",
      justifyContent: "center",
    },
    pdfExportOptions: {
      modifyCellValue: (value) => {
        return value ? "Yes" : "No";
      },
    },
  },
  {
    headerName: "Has Documentation",
    field: "hasDocumentation",
    filter: "agMultiColumnFilter",
    cellRenderer({ value }) {
      return (
        <div style={{ margin: "auto 0" }}>
          {" "}
          <Button
            type="text"
            style={{
              display: "flex",
              justifyContent: "center",
              width: 70,
              background:
                value === true
                  ? "#BDE0A6"
                  : value === false
                  ? "#FCD3D3"
                  : "transparent",
              cursor: "default",
            }}
          >
            {value === true ? "Yes" : value === false ? "No" : ""}
          </Button>
        </div>
      );
    },
    cellStyle: {
      display: "flex",
      justifyContent: "center",
    },
    pdfExportOptions: {
      modifyCellValue: (value) => {
        return value ? "Yes" : "No";
      },
    },
  },
  {
    headerName: "Has Service Addons",
    field: "hasServiceAddons",
    filter: "agMultiColumnFilter",
    cellRenderer({ value }) {
      return (
        <div style={{ margin: "auto 0" }}>
          <Button
            type="text"
            style={{
              display: "flex",
              justifyContent: "center",
              width: 70,
              background:
                value === true
                  ? "#BDE0A6"
                  : value === false
                  ? "#FCD3D3"
                  : "transparent",
              cursor: "default",
            }}
          >
            {value === true ? "Yes" : value === false ? "No" : ""}
          </Button>
        </div>
      );
    },
    cellStyle: {
      display: "flex",
      justifyContent: "center",
    },
    pdfExportOptions: {
      modifyCellValue: (value) => {
        return value ? "Yes" : "No";
      },
    },
  },
  {
    headerName: "Is Chained",
    field: "foundInScopes",
    filter: "agMultiColumnFilter",
    cellRenderer: Chained,
    valueGetter: ({ data }) => {
      data?.foundInScopes?.length > 0 ? "True" : "False";
    },
    pdfExportOptions: {
      modifyCellValue: (value) => {
        return value ? "Chained" : "Not Chained";
      },
    },
  },
  {
    headerName: "Created At",
    field: "createdAt",
    filter: "agDateColumnFilter",
    cellRenderer: ({ data }) => {
      if (data?.hasOwnProperty("createdAt")) {
        return dayjsNY(data.createdAt).format("MM/DD/YYYY");
      } else {
        return "";
      }
    },
  },
  {
    headerName: "Logs",
    cellRendererFramework: ({ data }) => (
      <div style={{ margin: "auto 0" }}>
        <LogsButton
          {...{
            logsTitle: "Services",
            filtersObj: { recordId: `Service-${data.serviceId}` },
          }}
        />
      </div>
    ),
    pdfExportOptions: {
      skipColumn: true,
    },
  },
];

export const defaultColDef = {
  resizable: true,
  enablePivot: true,
  enableColResize: true,
  enableRowGroup: true,
  editable: false,
  sortable: true,
  flex: 1,
  minWidth: 100,
  filter: true,
};
