import { notification } from "antd";
import { v4 as uuid } from "uuid";
import { appStore } from "../../../store";
import { showSuccessMsg, showErrorMsg } from "../../../utils";
import { addNotification } from "../../../actions/notificationsActions";
import { onNotificationClickUpdateState } from "../../../utils/onNotificationClickUpdateState";
import {
  apiRoutes,
  fetchData,
} from "../../../components/SidebarPages/Fleet/utils";
import { getGeoLocation } from "../../../hooks/useEditLogs";
import { returnDetailsForWhiteListIps } from "../../../components/pages/Settings/settingsComponents/AllowedIpAddresses/whiteListsIpAddressesFunctions";
import { useUserAgentNavigatorDevice } from "../../../hooks";

//#region TYPES
/**
 * @typedef {import("../../../components/SidebarPages/FleetMaintenanceView/types").StoreType} Store
 */

/**
 * @typedef MessageBody
 * @property {string} id
 * @property {string} topic
 * @property {string} action
 * @property {any} actionData
 * @property {string} [error]
 * @property {string} [status]
 * @property {string} originUrl
 * @property {any} notificationObject
 */

/**
 * @typedef NotificationMessage
 * @property {string} request
 * @property {MessageBody} body
 */

/**
 * @param {WebSocket} socket
 * @param {number|string} pingId
 */
export function pongHandler(socket, pingId) {
  //#region PONG
  if (pingId === Number.MAX_SAFE_INTEGER) {
    pingId = -1;
  }

  ++pingId;

  setTimeout(() => {
    try {
      socket.send(
        JSON.stringify({
          request: "ping",
          body: { id: pingId },
        })
      );
    } catch (err) {
      console.log("Could not ping ws: ", err);
    }
  }, 20000);
}

/**
 * @param {MessageBody} body
 */
export function notificationPreferenceHandler(body) {
  //#region PREFERENCE
  return;

  if (body?.status) {
    showSuccessMsg({
      content: "Template saved to all user preferences",
    });
  } else {
    showErrorMsg({
      content:
        "Something happened while trying to save the template to all users",
    });
  }
}

/**
 * @param {MessageBody} body
 * @param {import("react-router-dom").NavigateFunction} navigate
 */
export async function broadcastHandler(body, navigate) {
  const isDarkMode = localStorage.getItem("darkmode") === "true";
  const currentDevice = useUserAgentNavigatorDevice();

  //#region BROADCAST
  /** @type {Store["notificationSettings"]["notificationSettings"]} */
  const notificationSettings =
    appStore.getState()?.notificationSettings?.notificationSettings;

  // for ip address restriction start
  const fetchedIp = (await fetchData(apiRoutes.getIp)) || "";
  const currIp = fetchedIp?.split(",")[0];
  const userConfiguration =
    appStore.getState()?.userConfig?.userConfiguration || {};

  const programFields = appStore.getState()?.programFields?.programFields || [];
  const fieldOptionsIPs =
    programFields?.map(
      ({ fieldName }) => fieldName === "Allowed IP Addresses"
    ) || [];
  const ipAddressWhitelist = userConfiguration?.ipAddressWhitelist?.list || [];
  const currentWhiteListIpForUser = ipAddressWhitelist?.find(
    ({ ipv4 }) => ipv4 === currIp
  );

  const currentWhiteListIpForProgramFields =
    fieldOptionsIPs?.find(({ ipv4 }) => ipv4 === currIp) || [];

  let geoLocation = "Denied";

  try {
    geoLocation = await getGeoLocation();
  } catch (error) {}

  const { restrict = false } = returnDetailsForWhiteListIps(
    currentDevice,
    currIp,
    currentWhiteListIpForUser,
    currentWhiteListIpForProgramFields,
    userConfiguration.nameOfUser,
    geoLocation
  );

  if (restrict) {
    return;
  }
  // for ip address restriction end

  if (!notificationSettings) {
    return;
  }

  // update actions on condition
  await onNotificationClickUpdateState(appStore.dispatch, body).catch((err) => {
    console.log("Error updating notifications on click: ", err);
  });

  const currNotificationSetting = notificationSettings.find(
    (notificationSetting) => notificationSetting?.topicId === body.topic
  );
  const template = currNotificationSetting?.templates?.find(
    ({ templateAction }) => templateAction === body.action
  );

  if (!template || !currNotificationSetting) {
    console.error("Cannot show notification, template was not found");
    return;
  }

  // insert notification in store
  appStore.dispatch(
    addNotification({
      category: "all",
      notification: body.notificationObject,
      notificationSettings,
    })
  );

  // generate click redirect uri
  const generatedURL = (template?.templateContent?.uri || "").replace(
    /([$])|(\{id\})/g,
    function (match, g1, g2) {
      if (g2) {
        return body?.id;
      } else if (g1) {
        return "";
      }

      return match;
    }
  );

  // replace dynamic content in notification template
  const msgBody = (template?.templateContent?.body || "").replace(
    /\{\{(.*?)\}\}/g,
    (_, matched) => {
      const firstReplace = matched.replace("&ccedil;", "ç");
      if (body.topic === "19") {
        return body.actionData?.[1]?.rowSelected?.[firstReplace] || matched;
      } else {
        return body?.actionData?.[0]?.[firstReplace] || matched;
      }
    }
  );

  // generate browser notification
  if (!document.hasFocus()) {
    const notification = new Notification(template.templateContent?.head, {
      body: msgBody?.replace(/<\/?[^>]+(>|$)/g, "").replaceAll(/&nbsp;/g, " "),
    });
    notification.onclick = () => {
      id !== null && window.open(generatedURL, "_blank");
    };
  }

  // generate antd notification
  const notificationBody = {
    message: template.templateContent?.head,
    description: (
      <span>
        <p>
          {msgBody.replace(/<\/?[^>]+(>|$)/g, "").replaceAll(/&nbsp;/g, " ")}
        </p>
      </span>
    ),
    onClick() {
      if (body.id) {
        const { cognitos, ...rest } = body?.actionData?.[1] || {};
        navigate(generatedURL, { state: rest });
      }
    },
    className: isDarkMode ? "info__notification__dark" : "info__notification",
    placement: "bottomRight",
    key: uuid(),
    duration: template.templateContent?.duration || 1000,
  };

  if (template.templateContent?.priority?.includes("Critical")) {
    notification.error({
      ...notificationBody,
      className: isDarkMode
        ? "delete__notification__dark"
        : "delete__notification",
    });
  } else {
    notification.info(notificationBody);
  }
}

/**
 * @param {WebSocket} socket
 */
export function createSessionHandler(socket) {
  try {
    socket.send(
      JSON.stringify({
        request: "ping",
        body: { id: 0 },
      })
    );
  } catch (err) {
    console.log("Could not ping ws: ", err);
  }
}
