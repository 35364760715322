import { Flex, Form, Radio } from "antd";
import { InputComponent } from "../../../../SidebarPages/Fleet/components";
import { RenderDynamicComponents } from "../../Components";
import {
  JOBSITE_ADDRESS,
  OPPORTUNITY_INFORMATION,
  PROJECT_INFORMATION,
} from "../opportunityModalData";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";
import { PlusIcon } from "../../../../../assets";
import { DobButtonIcon } from "../../../../pages/Settings/settingsComponents/Roles/src";
import DobInfoModal from "./DobInfoModal/DobInfoModal";
import { AnimatedCheckBox } from "../../../../SidebarPages/Fleet/components/InputComponent/components";
import { useSelector } from "react-redux";
import Geofence from "../../../../commonComponents/Map/Geofence/Geofence";
import { getAccessRights } from "../../../../../utils";
import {
  AvatarImages,
  CustomTransfer,
  JoditEditorComp,
} from "../../../../commonComponents";

export const RecordType = ({ inputFields, setFormChanged }) => {
  return (
    <div className="opportunityTypeContainer">
      <span className="radioTitle">Select a record type:</span>
      <InputComponent
        {...{
          ...inputFields.type,
          onChange() {
            setFormChanged(true);
          },
          options: inputFields?.type?.options?.map((item, i) => (
            <Radio key={i} value={item}>
              {item}
            </Radio>
          )),
        }}
      />
    </div>
  );
};

export const JobsiteAddress = ({
  inputFields,
  geoFenceInfo,
  setGeoFenceInfo,
  opportunityAddress,
  selectedLead,
  geofencePlaces = [],
}) => {
  const { [JOBSITE_ADDRESS]: jobAddres } = inputFields;

  const { userConfiguration } = useSelector((state) => state.userConfig);

  const { routeConfig } = getAccessRights({
    userConfiguration,
    title: "Opportunities/View",
  });

  return (
    <Flex className="stepContent opportunityAddress">
      {RenderDynamicComponents([...jobAddres.main, ...jobAddres.split])}

      {geofencePlaces.length && (
        <Geofence
          key={geoFenceInfo.length}
          places={geofencePlaces}
          setGeoFenceInfo={setGeoFenceInfo}
          geoFenceInfo={geoFenceInfo}
          rowData={{
            opportunityAddress,
            accountName: selectedLead?.leadCompany,
          }}
          showDrawingManager={routeConfig.read}
          allowEditing={routeConfig.read}
        />
      )}
    </Flex>
  );
};

export const OpportunityInformation = ({
  inputFields,
  setDobInformation,
  dobInfoModalVisible,
  setDobInfoModalVisible,
  opportunityAddress,
  selectedTeam,
  setOpen,
}) => {
  const { [OPPORTUNITY_INFORMATION]: oppInfoFields } = inputFields;

  return (
    <Flex className="stepContent opportunityInformation">
      {RenderDynamicComponents(oppInfoFields)}

      {!!selectedTeam?.length && (
        <>
          <MondayButton Icon={<PlusIcon />} onClick={() => setOpen(true)}>
            Members
          </MondayButton>
          <AvatarImages
            {...{
              cognitoUserIds: selectedTeam
                .flatMap(({ members = [] }) => members)
                .map(({ cognitoUserId }) => cognitoUserId),
            }}
          />
        </>
      )}

      <MondayButton
        className="mondayButtonBlue dobButton"
        onClick={() => setDobInfoModalVisible(true)}
        Icon={<DobButtonIcon />}
      >
        DOB Information
      </MondayButton>

      <DobInfoModal
        {...{
          setDobInformation,
          visible: dobInfoModalVisible,
          setVisible: setDobInfoModalVisible,
          address: opportunityAddress,
        }}
      />
    </Flex>
  );
};

export const ProjectInfo = ({
  form,
  inputFields,
  uploadFile,
  setUploadFile,
  requiredBidDueDate,
  setRequiredBidDueDate,
}) => {
  const { [PROJECT_INFORMATION]: projectInfoFields } = inputFields;

  return (
    <Flex className="stepContent projectInfosContainer">
      <AnimatedCheckBox
        form={form}
        formItemName="scopeSheet"
        primaryStyle={{ maxWidth: 32 }}
        checkBoxProps={{
          value: uploadFile,
          setValue: setUploadFile,
        }}
        onChange={(e) => {
          setUploadFile(e.target.checked);
        }}
        label="Scope Sheet"
      />

      {RenderDynamicComponents(projectInfoFields.checkboxesAndUploads)}

      <AnimatedCheckBox
        primaryStyle={
          requiredBidDueDate
            ? { backgroundColor: "#71cf48", maxWidth: 32 }
            : { maxWidth: 32 }
        }
        secondaryStyle={
          requiredBidDueDate ? { backgroundColor: "#5fb13b" } : {}
        }
        checkBoxProps={{
          value: !requiredBidDueDate,
          setValue: setRequiredBidDueDate,
        }}
        onChange={({ target }) => {
          setRequiredBidDueDate(!target.checked);
          form.setFieldValue("bidDueDate", null);
        }}
        label="Not Applicable"
      />

      {RenderDynamicComponents(projectInfoFields.dates)}
    </Flex>
  );
};

export const TypeOfWork = ({ form }) => {
  const { proposedTypeOfWork } = useSelector(
    (state) => state.proposedTypeOfWork
  );

  const formProposedTypeOfWork =
    Form.useWatch("proposedTypeOfWork", form) || [];

  const workDetail = Form.useWatch("workDetail", form) || "";

  return (
    <Flex className="stepContent typeOfWorkStep">
      <CustomTransfer
        {...{
          name: "proposedTypeOfWork",
          redSectionTitle: "All",
          greenSectionTitle: "Selected",
          className: "proposedTypeOfWork",
          value: formProposedTypeOfWork,
          dataSource: proposedTypeOfWork
            ?.filter(({ isScope }) => !isScope)
            ?.map(({ workName, workId }) => ({ key: workId, title: workName })),
          onChange: (nonHiddenIds) => {
            form.setFieldValue("proposedTypeOfWork", nonHiddenIds);
          },
        }}
      />

      <Form.Item name="workDetail">
        <JoditEditorComp
          content={workDetail}
          className="workTypeContainer"
          setContent={(content) => form.setFieldValue("workDetail", content)}
        />
      </Form.Item>
    </Flex>
  );
};
