import {
  DownloadOutlined,
  FilterOutlined,
  LoadingOutlined,
  PlayCircleOutlined,
  ReloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Input, message, Skeleton } from "antd";
import { useCallback, useContext, useMemo, useState } from "react";
import { useFieldOptionFinder } from "../../../../hooks";
import { useSelector } from "react-redux";
import { camelCase, debounce } from "lodash";

import { debounceSearch } from "../../../../utils";

import { MondayButton, SwitchComponent } from "../../../commonComponents";
import { PlusIcon } from "../../../../assets";

import MobileFilterModal from "./MobileFilterModal";
import Export from "./Export";
import SettingsModal from "../../../SidebarPages/components/SettingsModal/SettingsModal";
import DrawingLive from "./../../../SidebarPages/Projects/Controller";

import { fetchData } from "../../../SidebarPages/Fleet/utils";
import { getSearchConfig } from "../../../SidebarPages/BasePage/components/AgGridFilters/dynamicSearch";
import { AdvancedFilterProvider } from "../../../commonComponents/AdvancedFilters/context/AdvancedFilterContext";
import { MobileBasePageContext } from "../MobileBasePage";
import { EstimationLive } from "../../../SidebarPages/Estimations/Controller/page/Controller";
import {
  adaptedTitlesForStatusesPanel,
  enhancedEndpoints,
  missingPanelObjects,
} from "../utils";
import { useLocation } from "react-router-dom";
import { XIconWhite } from "../../../SidebarPages/Communication/assets";
import { ReportIcon } from "../../../../icons";
import { ReportModal } from "../../../SidebarPages/BasePage/components";

function toLowerTrim(e) {
  return e.toLowerCase().trim();
}

const LiveData = {
  projectCost: ["/projectCost/live"],
  fleets: ["/fleets/live"],
  permitdrawings: ["/permitDrawingsDashboard", true],
  estimations: ["/estimationDashboard", true],
  documentation: ["/documentationDashboard", true],
};

const moreThanOneKeyDetectorStrings = ["key", "label", "type"];

export function checkForShowCheckbox(panelObject = {}) {
  const toArray = Object.keys(panelObject);
  const check = toArray.map((el) =>
    moreThanOneKeyDetectorStrings.some((key) => el === key)
  );
  const toReturn = check.every((e) => e);
  return !toReturn;
}

const MobileFiltersPanel = () => {
  const {
    rowData,
    title,
    setRowData,
    fetchAllHandler,
    hasFetchSearchFromDb,
    reloadData,
    setCreationModal,
    setLoadingData,
    setSearchedData,
    dynamicColumnDefs,
    handleChangePanelFilter,
    panelFilter,
    optionButton,
    creationModalOpener,
    selectedCard,
    setSelectedCard,
    NewButton,
    loadingImports,
    handleClearFilters,
    hasActiveFilters,
  } = useContext(MobileBasePageContext);
  const location = useLocation();
  const statusesFromPF = useFieldOptionFinder({ fieldName: "Status colors" });
  const panelObjectPF =
    useFieldOptionFinder({
      fieldName: "Base Page Panel Object",
    }) || {};

  const panelObject = useMemo(() => {
    return Object.entries(panelObjectPF).reduce((acc, [key, value]) => {
      acc[key.toLowerCase()] = value;
      return acc;
    }, {});
  }, [panelObjectPF]);

  const pathName = useMemo(() => {
    const { pathname, search } = location;
    return pathname + search;
  }, [location]);

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { topicCategories } = useSelector((state) => state.topicCategories);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [startKey, setStartKey] = useState("");
  const [loadingPagination, setLoadingPagination] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [areAllDataFetched, setAreAllDataFetched] = useState(false);
  const [clearFiltersClicked, setClearFiltersClicked] = useState(false);
  const [settings, setSettings] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);

  const hasLiveButton = useMemo(() => {
    const location = window.location.pathname.split("/");
    return (
      (location.length === 3 && location[1] === "fleets") ||
      (location.length === 2 && LiveData[location[1]])
    );
  }, [window.location.pathname]);

  const endpointName = useMemo(() => {
    const t = camelCase(title.toLowerCase().trim());
    const endpoint = enhancedEndpoints[title] || t;
    return endpoint;
  }, [title]);

  const openFiltersModal = useMemo(() => {
    return openFilters || clearFiltersClicked;
  }, [openFilters, clearFiltersClicked]);

  const dynamicPrimaryKey = useMemo(() => {
    return topicCategories?.find(({ categoryName }) => categoryName === title)
      ?.primaryKey;
  }, [topicCategories, title]);

  const handlePagination = async () => {
    if (startKey) {
      setLoadingPagination(true);
      const queryStringParameters = {
        ExclusiveStartKey: startKey,
        withPagination: "true",
      };

      const response = await fetchData(endpointName, endpointName, {
        queryStringParameters,
      });

      const nextPageKey =
        response?.LastEvaluatedKey?.[dynamicPrimaryKey] || undefined;
      setStartKey(nextPageKey);
      const toSet = [...rowData, ...response[endpointName]];
      setRowData(toSet);
      setLoadingPagination(false);
      return;
    }
    message.warning("could't get other data, try fetching all data");
  };

  const handleFetchAll = async () => {
    setAreAllDataFetched(true);
    setStartKey(undefined);
    fetchAllHandler(true);
  };

  const debouncedFilter = debounce((searchTerm, rowData) => {
    const dynamicKeys = dynamicColumnDefs.map(({ field }) => field);

    if (searchTerm) {
      const filtered = rowData.filter((row) =>
        dynamicKeys.some((key) => {
          const data = row[key] || "";
          const proceedData =
            typeof data === "number"
              ? data.toString()
              : typeof data === "string"
              ? data
              : "";
          return toLowerTrim(proceedData).includes(searchTerm);
        })
      );

      setSearchedData(filtered);
    } else {
      setSearchedData([]);
    }
  }, 1000);

  const onChangeHandler = (e) => {
    const { value } = e.target;
    if (hasFetchSearchFromDb) {
      getSearchConfig(title, value, null, setRowData, userConfiguration);
    } else {
      const searchTerm = toLowerTrim(value);
      debouncedFilter(searchTerm, rowData);
    }
  };

  const regularReload = useCallback(async () => {
    setLoadingData(true);
    try {
      const res = await fetchData(endpointName);
      setRowData(res);
    } catch (error) {
      console.log({ error });
    } finally {
      setLoadingData(false);
    }
  }, [endpointName]);

  const findedStatuses = useMemo(() => {
    return { data: statusesFromPF?.[title] || {}, onClick: () => {} };
  }, [statusesFromPF, title]);

  const debouncedChange = debounceSearch(onChangeHandler, 300);

  const getOtherData = (
    <div onClick={startKey !== undefined ? handlePagination : () => {}}>
      Get other data {loadingPagination ? <LoadingOutlined spin /> : ""}
    </div>
  );

  const showCheckBox = useMemo(() => {
    const findedTitle =
      adaptedTitlesForStatusesPanel[pathName] ||
      adaptedTitlesForStatusesPanel[title];
    const finalTitle = findedTitle
      ? findedTitle?.toLowerCase()
      : title?.toLowerCase();
    const currentCategory =
      panelObject?.[finalTitle] || missingPanelObjects?.[finalTitle] || {};
    return checkForShowCheckbox(currentCategory);
  }, [panelObject, title, pathName]);

  const handleCreationModal = useCallback(() => {
    if (creationModalOpener) {
      creationModalOpener();
    } else {
      setCreationModal(true);
    }
  }, [setCreationModal, creationModalOpener]);

  const elementsInPanel = [
    {
      isVisible: hasActiveFilters,
      element: (
        <MondayButton
          Icon={<XIconWhite />}
          className="mondayButtonRed"
          onClick={() => {
            setClearFiltersClicked(true);
            handleClearFilters();
          }}
        />
      ),
      width: 40,
    },
    {
      isVisible: true,
      element: (
        <MondayButton
          data-testid="Reload"
          Icon={<ReloadOutlined />}
          className="mondayButtonBlue"
          onClick={
            reloadData
              ? () => {
                  reloadData();
                  message.info("data reloaded successfully!");
                }
              : () => regularReload()
          }
        />
      ),
      width: 40,
    },
    {
      isVisible: true,
      element: (
        <MondayButton
          data-testid="Filter"
          Icon={<FilterOutlined />}
          className="mondayButtonBlue"
          onClick={() => setOpenFilters(true)}
        />
      ),
      width: 40,
    },
    {
      isVisible: true,
      element: <Export />,
      width: 150,
    },
    {
      isVisible: fetchAllHandler,
      element: (
        <MondayButton
          className="mondayButtonBlue"
          Icon={<DownloadOutlined />}
          onClick={handleFetchAll}
        />
      ),
      width: 40,
    },
    {
      isVisible: NewButton,
      element: (
        <MondayButton
          data-testid="NewButton"
          Icon={<PlusIcon />}
          className="mondayButtonGreen"
          onClick={handleCreationModal}
        />
      ),
      width: 34,
    },
    {
      isVisible: hasLiveButton,
      element: (
        <MondayButton
          className="mondayButtonRed"
          onClick={() => {
            hasLiveButton[1] === "fleets"
              ? openInNewTab(LiveData[hasLiveButton[1]][0])
              : setSettings(true);
          }}
          Icon={<PlayCircleOutlined />}
        >
          Live
        </MondayButton>
      ),
      width: 87,
    },
    {
      isVisible: optionButton,
      element: optionButton,
      width: 150,
    },
    {
      isVisible: showCheckBox,
      element: (
        <SwitchComponent
          label={panelFilter.label}
          checked={panelFilter.checked}
          onChange={handleChangePanelFilter}
        />
      ),
      width: 150,
    },
    {
      isVisible: true,
      element: (
        <MondayButton
          className="mondayButtonBlue"
          Icon={<ReportIcon />}
          onClick={() => setIsReportModalOpen(true)}
        />
      ),
      width: 34,
    },
  ].filter(({ isVisible }) => isVisible);

  const dynamicCenter = useMemo(() => {
    const REDUCED_WIDTH = 20;
    const widthOfStyle = (elementsInPanel.length - 1) * 10;
    const widthOfElements =
      elementsInPanel.reduce((acc, el) => acc + (el.width || 0), 0) +
      widthOfStyle;
    const containerWidth = window.innerWidth - REDUCED_WIDTH;
    if (widthOfElements > containerWidth) {
      return "";
    } else {
      return "center";
    }
  }, [elementsInPanel]);

  return (
    <div
      data-testid="mobile-filters-panel"
      className="mobileFiltersPanelWrapper"
    >
      <div
        className={`mobileFiltersPanel ${isDarkMode ? "dark" : ""}`}
        style={dynamicCenter ? { justifyContent: dynamicCenter } : {}}
      >
        {loadingImports ? (
          <Skeleton.Input
            block
            style={{
              height: "32px",
              borderRadius: "5px",
            }}
            active
          />
        ) : (
          elementsInPanel.map(({ element }) => element)
        )}
      </div>
      <div className={`details ${isDarkMode ? "dark" : ""}`}>
        <Input
          prefix={
            <SearchOutlined
              style={{
                marginRight: "5px",
                fontSize: "24px",
                color: isDarkMode ? "white" : "",
              }}
            />
          }
          placeholder="Search here..."
          onChange={debouncedChange}
          className={`searchInput ${isDarkMode ? "dark" : ""}`}
        />
        <div
          className={`paginationControls ${
            startKey === undefined || loadingPagination || areAllDataFetched
              ? "loading"
              : ""
          }`}
        >
          {selectedCard && (
            <div
              className="tag"
              style={{ backgroundColor: selectedCard.statusColor }}
            >
              <div className="tagValue">
                ({selectedCard.value}) {selectedCard.statusName}
              </div>
              <div className="x" onClick={() => setSelectedCard()}>
                x
              </div>
            </div>
          )}
          {fetchAllHandler ? (
            startKey !== undefined ? (
              getOtherData
            ) : (
              "reached the end"
            )
          ) : (
            <></>
          )}
        </div>
      </div>

      {openFiltersModal && (
        <AdvancedFilterProvider {...{ rowData, setRowData }}>
          <MobileFilterModal
            {...{
              setOpenFilters,
              clearFiltersClicked,
              openFilters,
              setClearFiltersClicked,
            }}
          />
        </AdvancedFilterProvider>
      )}
      <SettingsModal
        visible={settings}
        closeModal={() => setSettings()}
        title="Settings"
      >
        {title === "Estimations" ? (
          <EstimationLive
            closeModal={() => setSettings(false)}
            statusPanel={findedStatuses}
          />
        ) : (
          <DrawingLive
            closeModal={() => setSettings(false)}
            statusPanel={findedStatuses}
          />
        )}
      </SettingsModal>
      {isReportModalOpen && (
        <ReportModal
          {...{
            rowData,
            title,
            isReportModalOpen,
            setIsReportModalOpen,
            gridApi: {
              columnModel: {
                displayedColumns: dynamicColumnDefs.map(
                  ({ field, headerName }) => ({
                    colId: field || camelCase(headerName),
                  })
                ),
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default MobileFiltersPanel;
