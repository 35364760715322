import { useEffect, useMemo, useRef, useState } from "react";
import addElevationStyles from "./helpers/addElevationStyles";
import { IndentIcon } from "./assets";
import ToolboxItem from "./ToolboxItem";
import { DownOutlined } from "@ant-design/icons";
import { categoriesOptions, configToolBoxOptions } from "./constants";

import "./ElevationHtmlConfiguration.scss";
import { isEmpty } from "lodash";
const styleTooltip = {
  position: "absolute",
  backgroundColor: "#fff",
  color: "#fff",
  padding: "5px ",
  borderRadius: " 3px",
  zIndex: 2147483647,
  height: "fit-content",
  width: "fit-content",
};

const ElevationHtmlConfiguration = ({ data, onSave, onPreview }) => {
  const [styles, setStyles] = useState({
    elevationLabel: {},
    elevationDesc: {},
    notes: {},
    itemLabel: {},
    addonLabel: {},
  });
  const [preview, setPreview] = useState(false);
  const divRef = useRef(null);
  console.log("ElevationHtmlConfiguration ~ styles:", { styles, data });

  const handleStyleChange = (category, style) => {
    setStyles((prev) => ({
      ...prev,
      [category]: { ...prev?.[category], ...style },
    }));
  };

  const [tooltipContent, setTooltipContent] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const editorElement = document.querySelector(".jodit-wysiwyg");

    const handleSelection = () => {
      const selection = window.getSelection();
      const parentElement = selection.anchorNode.parentElement;
      if (
        parentElement &&
        parentElement.innerHTML.includes("{{engineersWork}}")
      ) {
        console.log("selection", { selection, parentElement });
        if (selection.rangeCount > 0 && !selection.isCollapsed) {
          const range = selection.getRangeAt(0);
          const rect = range.getBoundingClientRect();
          setTooltipPosition({
            top: rect.top + window.scrollY,
            left: rect.left + window.scrollX,
          });
          setTooltipContent(
            <ToolBox
              onStyleChange={handleStyleChange}
              styles={styles}
              from={"tooltip"}
            />
          );
        } else {
          setTooltipContent(null);
        }
      } else {
        setTooltipContent(null);
      }
    };

    if (editorElement) {
      editorElement.addEventListener("mouseup", handleSelection);
      editorElement.addEventListener("keyup", handleSelection);
    }

    // Cleanup event listeners on component unmount
    return () => {
      console.log("cleanup");
      if (editorElement) {
        editorElement.removeEventListener("mouseup", handleSelection);
        editorElement.removeEventListener("keyup", handleSelection);
      }
    };
  }, []);

  return tooltipContent ? (
    <ToolboxTooltip content={tooltipContent} position={tooltipPosition} />
  ) : (
    <></>
  );

  return (
    <div className="elevationHtmlConfiguration">
      <button
        onClick={() => {
          setPreview(!preview);
          divRef.current.innerHTML = addElevationStyles(data, styles);
          console.log("styles", addElevationStyles(data, styles));
        }}
      >
        Log Styles
      </button>

      <ToolBox onStyleChange={handleStyleChange} styles={styles} />

      <div
        ref={divRef}
        className="preview-elevation-container"
        // dangerouslySetInnerHTML={{ __html: addElevationStyles(data, styles) }}
      ></div>
      {/* {renderData()} */}
      {tooltipContent && (
        <Tooltip content={tooltipContent} position={tooltipPosition} />
      )}
    </div>
  );
};

export default ElevationHtmlConfiguration;

/**
 * ToolBox component provides a UI for selecting and configuring styles for different categories.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.onStyleChange - Callback function to handle style changes.
 * @param {Object} props.styles - Initial styles passed to the component.
 * @param {"scope"| "hoist" | "service"} props.serviceType - Type of service to filter categories.
 *
 * @returns {JSX.Element} The rendered ToolBox component.
 */
export const ToolBox = ({
  onStyleChange,
  styles: proppedStyles,
  serviceType,
}) => {
  const [styles, setStylesTmp] = useState(proppedStyles);

  const handleStyleChange = (category, style) => {
    if (style === "clear") {
      if (isEmpty(styles?.[category])) return;
      setStylesTmp((prev) => ({
        ...prev,
        [category]: {},
      }));
      onStyleChange(category, "clear");
    } else {
      setStylesTmp((prev) => ({
        ...prev,
        [category]: { ...prev?.[category], ...style },
      }));
      onStyleChange(category, style);
    }
  };

  const [indent, setIndent] = useState({});

  const [category, setSelectedCategory] = useState("");

  const catLabel = category
    ? categoriesOptions.find((cat) => cat.key === category)?.label
    : null;

  const categoriesFiltered = useMemo(
    () =>
      categoriesOptions
        .filter((cat) => cat.usedIn.includes(serviceType))
        .map(({ key, label }) => ({ key, label })),
    [serviceType]
  );

  return (
    <div className="toolbox">
      <div className="toolbox-category">
        <ToolboxItem
          {...{
            title: "Categories",
            selected: true,
            style: { color: "black" },
            dropdown: {
              menu: {
                items: categoriesFiltered,
                onSelect: ({ key }) => setSelectedCategory(key),
                selectable: true,
                defaultSelectedKeys: [category],
              },
              trigger: ["click"],
            },
          }}
        >
          {catLabel || "Select Category"}
          <DownOutlined />
        </ToolboxItem>
        {category && (
          <div className="toolbox-category-tools">
            {configToolBoxOptions.map(
              ({ title, icon, styleKey, dropdown, condition, type, style }) => {
                if (condition && !condition(category)) return null;
                return (
                  <ToolboxItem
                    key={title}
                    title={title}
                    style={style}
                    type={type}
                    defaultValue={styles?.[category]?.[styleKey]}
                    selected={!!styles?.[category]?.[styleKey]}
                    onClick={(e) => {
                      if (type === "colorPicker") {
                        handleStyleChange(category, {
                          [styleKey]: e,
                        });
                      } else if (type === "clear") {
                        handleStyleChange(category, "clear");
                      } else if (!dropdown) {
                        handleStyleChange(category, {
                          [styleKey]: !!styles?.[category]?.[styleKey]
                            ? false
                            : true,
                        });
                      }
                    }}
                    dropdown={
                      dropdown && {
                        menu: {
                          items: dropdown.items,
                          onSelect: ({ key }) => {
                            handleStyleChange(category, {
                              [dropdown.styleKey]: key,
                            });
                          },
                          selectable: true,
                          defaultSelectedKeys: [
                            styles?.[category]?.[dropdown.styleKey],
                          ],
                        },
                        trigger: ["click"],
                      }
                    }
                  >
                    {icon}
                    {dropdown && <DownOutlined />}
                  </ToolboxItem>
                );
              }
            )}
            <IndentComp
              {...{
                category,
                indent,
                setIndent,
                styles,
                onStyleChange: handleStyleChange,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const IndentComp = ({ category, indent, setIndent, styles, onStyleChange }) => {
  const [tmpValue, setTmpValue] = useState(
    styles?.[category]?.["margin-left"] || ""
  );
  return (
    <ToolboxItem title="Indent">
      <IndentIcon
        style={indent?.[category] ? { gap: "5px" } : { gap: 0 }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIndent({ ...indent, [category]: !!!indent?.[category] });
        }}
      />
      <input
        className={`indent-input ${indent?.[category] ? "show" : ""}`}
        type="number"
        onBlur={() => {
          setIndent({ ...indent, [category]: false });
          onStyleChange(category, { "margin-left": tmpValue });
        }}
        name="margin-left"
        max={500}
        min={0}
        value={tmpValue}
        onChange={
          (e) => setTmpValue(e.target.value)
          // onStyleChange(category, { "margin-left": e.target.value })
        }
        placeholder="Indent"
      />
    </ToolboxItem>
  );
};

export const ToolboxTooltip = ({ content, position }) => {
  return (
    <div
      className="tooltip elevationHtmlConfiguration"
      style={{
        top: position.top - 70,
        left: position.left,
        ...styleTooltip,
      }}
    >
      {content}
    </div>
  );
};
