import { isEmpty } from "lodash";
import {
  convertStyleToString,
  formatTextProposal,
} from "../../../../../../pages/Settings/settingsComponents/ProposalBuilder/components/EditTemplate/EditGeneral/ElevationHtmlConfiguration/helpers/addElevationStyles";
import { formatCurrency } from "../../../../../../SidebarPages/utils";
import { calculateServicePrice, getRentalPaymentTerms } from "./dataMapper";

function scopeServices(service = {}, toSet = {}, styles) {
  let hoistFields = {};
  hoistFields = getScopeFields(service, styles);
  return Object.assign(toSet, { ...(toSet || {}), ...hoistFields });
}
export default scopeServices;

function getScopeFields(service = {}, styles) {
  let tempHtml = [];
  const { serviceOptions, label } = service;
  const styleToUse = styles?.[label];

  const elevation = serviceOptions?.[0]?.[0];
  if (elevation) {
    const { items, elevationLabel } = elevation || {};
    const item = items?.[0] || {};
    const { Price, Name, formulas, Notes } = item;

    const elevationName = `<div class="elevation-label" style="${convertStyleToString(
      styleToUse?.elevationLabel
    )}">  ${formatTextProposal(
      elevationLabel,
      styleToUse?.elevationLabel
    )} </div>`;

    const pliLabel = Name + ": " + formatCurrency(Price);
    const pli = !formulas
      ? `<div class="elevation-item" style="${convertStyleToString(
          styleToUse?.itemLabel
        )}">
      ${formatTextProposal(pliLabel, styleToUse?.itemLabel)}
        </div>`
      : getFormulas(formulas, styleToUse);

    const finalElevation = `<div class="elevation"> ${elevationName} ${pli} </div>`;
    tempHtml.push(finalElevation);
  }

  let {
    servicePriceWithTax = 0,
    servicePriceNoTax = 0,
    serviceTaxAmount = 0,
    serviceTaxRate = 0,
    serviceRentAmount = 0,
    serviceAddonsPrice = 0,
    serviceRentTaxAmount = 0,
    serviceRentTotalAmount = 0,
    serviceAddonsTaxAmount = 0,
    serviceAddonsTotalAmount = 0,
    serviceAdditionalRentalAmount = 0,
    serviceAdditionalRentalTaxAmount = 0,
    serviceAdditionalRentalTotalAmount = 0,
  } = calculateServicePrice(service);

  tempHtml[0] = `<span contenteditable="false" >${tempHtml[0]}</div>`;

  let toReturn = {
    [`engineersWork_${label}`]: tempHtml?.join("") || "",
    [`serviceIncludes_${label}`]: getIncludes(service, "includes"),
    [`serviceExcludes_${label}`]: getIncludes(service, "excludes"),
    [`serviceName_${label}`]: label || "",
    [`serviceRentalPaymentTerms_${label}`]: getRentalPaymentTerms(service),
    [`servicePriceNoTax_${label}`]: formatCurrency(servicePriceNoTax),
    [`serviceTaxAmount_${label}`]: formatCurrency(serviceTaxAmount),
    [`serviceTotalAmount_${label}`]: formatCurrency(servicePriceWithTax),
  };

  return toReturn;
}

function getIncludes(service = {}, key) {
  if (isEmpty(service?.[key])) return "";
  let temp = `<ul style="padding-left: 40px;"><span>${
    key === "includes" ? "INCLUDES" : "EXCLUDES"
  }:</span>`;
  if (Array.isArray(service?.[key])) {
    service?.[key]?.forEach((item) => {
      temp += `<li>${item?.name}</li>`;
    });
  }
  return temp + "</ul>";
}

function getFormulas(formulas, styles) {
  let temp = "";
  Object.values(formulas).forEach((formulaArray, index) => {
    formulaArray.forEach((formula) => {
      const { field, value } = formula || {};
      const text = field + ": " + formatCurrency(value);
      temp += `<div class="formula" style="${convertStyleToString(
        styles?.itemLabel
      )}">
          ${formatTextProposal(text, styles?.itemLabel, index + 1)}
          </div>`;
    });
  });

  return temp;
}
