import { useSelector } from "react-redux";
import { Popconfirm, Popover, message } from "antd";
import { ExportOrEmailDropdown, MondayButton } from "../../..";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import "./ModalHeader.scss";
import { API } from "aws-amplify";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { useEditLogs, useResponsive } from "../../../../../hooks";
import {
  exportGridToExcel,
  generatePdf,
  handleExportPdfClick,
  uploadExcelToDrive,
} from "../../utils/exportPdfExcel";
import { v4 } from "uuid";
import { ThreeDotsIcon } from "../../../../SidebarPages/Communication/assets";

const ModalHeader = ({
  setVisible,
  newLogData,
  title,
  searchText,
  setSearchText,
  pdfDownloadLogName,
  onLogsErase,
}) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { base64 } = useSelector((state) => state.base64);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const { mobile } = useResponsive();
  const { saveAddedLogs } = useEditLogs();

  const dynamicHandleOfExport = newLogData?.find(
    ({ label }) => label === "Geofence"
  )
    ? true
    : false;

  const hasLogsAccess = userConfiguration?.routeConfig
    ?.find((item) => item.title === "Settings")
    ?.children.find((item) => item.title === "Audit")
    ?.children.find((item) => item.title === "Logs")?.write;

  const renderNode = (node, isLastChild, prefix = "") => {
    const borderStyle = isLastChild ? "" : "|-";
    const dynamic = node.children ? node.children : node.logs;

    const logContent = (dynamic || []).flatMap((log) => {
      const logKey = log.key || "";
      const updLogValue = log.updatedValue || "";
      const prevLogValue = log.updatedValue || "";
      const logChildrenContent = log.children
        ? renderNode(log, false, `${prefix}  `)
        : [];

      return [
        { text: `${prefix}${borderStyle}`, style: "nodeIndentation" },
        { text: `${logKey}:`, style: "nodeKey" },
        { text: `${updLogValue}, ${prevLogValue}`, style: "nodeValue" },
        ...logChildrenContent,
      ];
    });

    const childrenContent = (node?.children || []).flatMap((child, index) => {
      return renderNode(
        child,
        index === node.children.length - 1,
        `${prefix}  `
      );
    });

    return [...logContent, ...childrenContent];
  };

  const generateLog = async (actionType, recordName, recordId) => {
    const newLog = {
      actionType,
      category: title.slice(0, -4).trimEnd(),
      recordId: recordId || v4(),
      recordName,
      nameOfUser: userConfiguration.nameOfUser,
      cognitoUserId: userConfiguration?.cognitoUserId,
    };
    // added pdfDownloadLogName to add custom keys and values for newLog
    return await saveAddedLogs(
      !pdfDownloadLogName ? newLog : { ...newLog, ...pdfDownloadLogName }
    );
  };

  const handleDeleteLogs = () => {
    const logsToDelete = newLogData.map((log) => log.logId);
    message.loading({ content: "Deleting logs...", duration: 0 });

    API.del("editLogs", "/editLogs/123", {
      body: { logs: logsToDelete },
    })
      .then(() => {
        generateLog(
          "Delete",
          "Deleted All Logs",
          newLogData[0]?.recordId || ""
        ).then(() => {
          if (onLogsErase) {
            onLogsErase();
          }
        });
        message.destroy();
        message.success("Logs deleted successfully!");
        setVisible(false);
      })
      .catch((err) => {
        console.error(err);
        message.error("Something went wrong...");
        message.destroy();
      });
  };

  const actionsContent = (
    <div
      style={
        mobile
          ? {
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }
          : { display: "flex", gap: "10px" }
      }
    >
      {!hasLogsAccess && (
        <Popconfirm
          data-testid="yes-btn"
          title="Delete all logs"
          description="Are you sure to delete all logs?"
          onConfirm={handleDeleteLogs}
          okText="Yes"
          cancelText="No"
        >
          <MondayButton
            data-testid="erase-btn"
            className="mondayButtonBlue"
            disabled={!newLogData.length}
            Icon={<DeleteOutlined />}
          >
            Erase all logs
          </MondayButton>
        </Popconfirm>
      )}
      <ExportOrEmailDropdown
        {...{
          rowData: newLogData,
          onGeneratePDF: (action, getDocDefinition) =>
            dynamicHandleOfExport
              ? handleExportPdfClick(newLogData, action, getDocDefinition)
              : generatePdf(
                  newLogData,
                  title,
                  generateLog,
                  base64,
                  action,
                  getDocDefinition
                ),
          exportGridToExcel: (docDefinition) =>
            exportGridToExcel(title, generateLog, newLogData, docDefinition),
          uploadExcelToDrive: (driveRequest, driveFolderId) =>
            uploadExcelToDrive(driveRequest, driveFolderId),
          defaultDocDefinition: true,
          title: "Geofence",
          topicCategory: "Geofence",
          position: "absolute",
        }}
      />
    </div>
  );

  return (
    <div
      className={`header-container ${isDarkMode && "header-container-dark"} ${
        mobile && "header-container-mobile"
      }`}
    >
      <InputComponent
        value={searchText}
        allowClear
        className="search-logs"
        placeholder="Search..."
        prefix={<SearchOutlined className="SearchLogoIcon" />}
        onChange={(e) => setSearchText(e.target.value)}
      />
      {mobile ? (
        <Popover trigger="click" placement="bottom" content={actionsContent}>
          <div className="header-container-icon">
            <ThreeDotsIcon />
          </div>
        </Popover>
      ) : (
        actionsContent
      )}
    </div>
  );
};

export default ModalHeader;
