import { lazyFetch } from "../ApiMethods";

const data = {
  projects: {
    keysToInclude: ["projectId", "projectName"],
  },
  opportunities: {
    keysToInclude: ["opportunityId", "opportunityName"],
  },
  accounts: {
    keysToInclude: ["accountId", "accountName"],
  },
  estimations: {
    keysToInclude: ["estimationId", "estimationName"],
  },
  invoices: {
    keysToInclude: ["invoiceId", "accountName", "invoiceNumber"],
  },
};

// optimized report fetch data
export const getIds = (tableName) => {
  return lazyFetch({
    tableName,
    listOfKeys: data[tableName].keysToInclude,
  });
};
