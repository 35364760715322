import { Form, message, notification, Select } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { driveApi } from "../../../../../integrations/DriveRequest";
import { addToArray, callGmailAPI, updateTable } from "../../functions";

import { FilePreviewModal } from "../../../Documentation/View/components";
import EmailBoxHeaderInputs from "../EmailBox/components/emailbox-header-inputs-view/EmailBoxHeaderInputs";
import { useAttachments } from "../EmailBox/providers/AttachmentsProvider";
import { useEmailBoxBody } from "../EmailBox/providers/EmailBoxBodyProvider";
import { useFilePreviewModal } from "../EmailBox/providers/FilePreviewModalProvider";
import CommunicationRichEditor from "../rich-editor/CommunicationRichEditor";
import ReplyEmailBoxFooter from "./ReplyEmailBoxFooter/ReplyEmailBoxFooter";

import "./ReplyEmailBox.scss";
import RepliesView from "../EmailView/replies/RepliesView";
import { ConfirmationModal } from "../../../../commonComponents";
import Text from "../../../../commonComponents/Typography/Text";

/**
 *
 * @param {String} type forward|| reply || false (for not showing text editor)
 * @param {Object} parent object with parent email
 * @param {Function} onSave on save callback function
 * @param {Function} onClose on Close callback function
 * @param {Array} to Array with emails of users to send the email
 * @param {Object} rowData Object of selected application record
 * @param {Object} authToken Object with credentials of authenticated User of false to send email with Web App Account
 * @returns
 */
const ReplyEmailBox = ({
  type,
  parent,
  onSave,
  isChild,
  rowData,
  authData,
  setIsActive,
  emails,
}) => {
  /**
   * states of the component
   */

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [form] = Form.useForm();
  const [messageType, setMessageType] = useState(type);
  const [documentsActive, setDocumentsActive] = useState(true);
  const [loading, setLoading] = useState(false);
  const { attachments, setAttachments } = useAttachments();
  const { setFilePreview, filePreview, selectedFile } = useFilePreviewModal();
  const { editorContent, setEditorContent } = useEmailBoxBody();
  const currentEmail = emails[emails.length - 1];

  const [
    { authenticatedUser },
    { userConfiguration },
    { accessToken },
    { hotCredentials },
  ] = useSelector(
    ({ authenticatedUser, userConfig, accessToken, hotCredentials }) => [
      authenticatedUser,
      userConfig,
      accessToken,
      hotCredentials,
    ]
  );

  const replyAllEmails = [...new Set(emails.map(({ from }) => from))];

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [undoTimer, setUndoTimer] = useState(userConfiguration?.undoTimer || 5);
  const driveRequest = driveApi({ accessToken });

  const recordDetails = {
    categoryType: window.location.pathname.split("/")[1],
    recordId:
      window.location.pathname.split("/")[1] === "inspections"
        ? window.location.href.split("?id=")[1].split("&&")[0]
        : window.location.pathname.split("/")[2],
  };
  let undoSend = false;

  /**
   *
   * @param {String} e set Fields Value
   * @returns
   */

  useEffect(() => {
    form.setFieldsValue(
      messageType === "forward"
        ? { to: [] }
        : {
            to: replyAllEmails,
          }
    );
  }, [messageType]);

  /**
   *
   * @param {Object} e event of drowdown
   */
  const changeReplyType = (e) => {
    setMessageType(e);

    if (e === "reply") {
      setOpenConfirmation(true);
    }
  };

  /**
   *  On modal confirm, clear the form
   */
  const handleClearForm = () => {
    setAttachments([]);
    setEditorContent("");
    form.resetFields(["to", "cc", "bcc"]);
  };

  /**
   * onSave Function
   */
  const onSaveEmail = async () => {
    if (!form.getFieldsValue().to || form.getFieldsValue().to.length === 0) {
      message.warning("Please fill section To");
      return;
    }
    setLoading(true);
    let size = 0;
    let attachmentsWithDrive = [];
    let attachmentsWithoutDrive = [];
    let attachmentsCombined = [...attachments];

    attachmentsCombined.forEach((e) => {
      if (size < 36500000) {
        if (e.size + size < 36500000) {
          size = size + e.size;
          attachmentsWithoutDrive = [...attachmentsWithoutDrive, e];
        } else {
          attachmentsWithDrive = [...attachmentsWithDrive, e];
        }
      } else {
        attachmentsWithDrive = [...attachmentsWithDrive, e];
      }
    });

    let documents;
    /**
     * upload files in drive
     */
    let links = [];

    if (attachmentsWithDrive?.length > 0) {
      links = attachmentsWithDrive.map(async (file) => {
        try {
          const uploadResponse = await driveRequest.uploadFile(
            file.blob.split(",")[1],
            file.type || file?.mimeType,
            {
              name: file.name,
              mimeType: file.type || file?.mimeType,
              writersCanShare: true,
              parents: [
                hotCredentials.find(
                  ({ credentialKey }) => credentialKey === "GMAIL ATTACHMENTS"
                ).credentialValue,
              ],
            },
            true
          );

          const { id, name } = await uploadResponse.json();
          await driveRequest.share(id);

          return { id, name };
        } catch (error) {
          console.error("Error processing file:", error);
          return null; // Handle the error as needed
        }
      });
    }

    try {
      let allLinks = await Promise.all(links);
      allLinks = allLinks.filter((link) => link !== null); // Filter out any null results if needed

      documents = allLinks
        .map(
          ({ id, name }) =>
            ` <a href="https://drive.google.com/file/d/${id}/view">${name}</a><br/>`
        )
        .join("");
    } catch (error) {
      console.error("Error processing attachments:", error);
    }

    const forwardedMessage = `
      ${editorContent} ${documents}
      <span></span>
    
      <br />
      <br />
      <br />
    
      <p>---------- Forwarded message ---------</p>
      <p>From: ${currentEmail?.fromUser}</p>
      <p>Date: ${currentEmail?.date}</p>
      <p>Subject: ${currentEmail?.subject}</p>
      <p>To: ${currentEmail?.to}</p>
    
      <br /><br />
      ${currentEmail?.body}
    `;

    const replyMessage = `
      ${editorContent} ${documents}
      <span></span>
    
      <br />
      <br />
      <br />
      <p>On ${new Date(currentEmail?.date)?.toLocaleString()}, ${
      currentEmail?.from
    } wrote:</p>
      <div style="margin-left: 5px; padding-left: 10px; border-left:1px solid #ccc;">
        ${currentEmail?.body}
      </div>
    `;

    let emailToSend = {
      ...form.getFieldValue(),
      from: authenticatedUser.email,
      // body: editorContent + documents,
      body: messageType === "forward" ? forwardedMessage : replyMessage,
      // attachments: attachmentsWithoutDrive,
      attachments: attachmentsCombined,
      threadId: parent.threadId || null,
      emailId: currentEmail?.emailId || "",
      references: emails?.map((email) => email.id),
      subject: parent.subject,
      authData,
    };

    delete emailToSend["type"];
    form
      .validateFields()
      .then(async () => {
        notification?.info({
          description: (
            <div>
              Message sent
              <span
                className="undo"
                onClick={() => {
                  undoSend = true;
                  notification.destroy();
                }}
              >
                Undo
              </span>
            </div>
          ),
          placement: "bottomLeft",
          className: "undo_notification undo_notification_communication",
          bottom: 0,
          key: uuidv4(),
          duration: undoTimer,
        });
        window.addEventListener("beforeunload", beforeUnloadListener);
        message.warning("Sending email...", 40);
        for (let timePassed = 0; timePassed < undoTimer; timePassed++) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          if (!undoSend && timePassed === undoTimer - 1) {
            notification.destroy();

            setLoading(true);
            return callGmailAPI("sendEmail", emailToSend)
              .then((e) => {
                if (e.data) {
                  const { data } = e;
                  const { id, threadId, emailId } = data;

                  return updateTable(
                    recordDetails?.categoryType,
                    recordDetails?.recordId,
                    "emails",
                    addToArray(rowData?.emails || [], {
                      id,
                      threadId,
                    })
                  )
                    .then(() => {
                      messageType !== "forward"
                        ? onSave(data, undefined, setLoading)
                        : onSave(undefined, data, setLoading);
                      message.destroy();
                      message.success("Email sent successfully");
                    })
                    .catch((e) => {
                      message.destroy();
                      message.error("Problem with sending email");
                      setLoading(false);
                      window.removeEventListener(
                        "beforeunload",
                        beforeUnloadListener
                      );
                    });
                } else {
                  message.destroy();
                  message.warning("An error occured during communication");
                  setLoading(false);
                  return;
                }
              })
              .catch((e) => {
                message.destroy();
                message.error("Problem with sending email");
                window.removeEventListener(
                  "beforeunload",
                  beforeUnloadListener
                );

                setLoading(false);
              });
          } else if (undoSend) {
            message.destroy();
            message.success("Sending email canceled");
            window.removeEventListener("beforeunload", beforeUnloadListener);
            setLoading(false);
            return;
          }
        }
      })
      .catch((e) => {
        message.error("Problem with sending email");
        setLoading(false);
      });
  };

  /**
   * change type of reply email box if we choose different type
   */
  useEffect(() => {
    const uploadedFilesObject = ({ blob, id, mimeType, name }) => ({
      blob: `data:${mimeType};base64,${blob}`,
      id: id,
      type: mimeType,
      name: name,
    });

    if (messageType === "forward") {
      setEditorContent("");
      setAttachments(parent.attachments.map(uploadedFilesObject));
    }
  }, [messageType]);

  /**
   *
   * @param {Object} userConfiguration object of logged user
   * @returns
   */

  const beforeUnloadListener = (event) => {
    event.preventDefault();
    return (event.returnValue = "Are you sure you want to exit?");
  };

  return (
    <>
      <Form {...{ form }}>
        <div
          {...{
            className: `reply-emailbox-container ${
              isDarkMode ? "dark-mode" : ""
            } ${attachments?.length === 1 ? "one-attachment" : ""}`,
          }}
        >
          {/**
           * icon with first char of user name
           */}
          <span {...{ className: "userLetter" }}>
            {userConfiguration?.nameOfUser?.at(0).toUpperCase()}
          </span>
          <div {...{ className: "reply-email-box" }}>
            {/**
             * form with inputs of reply box
             */}
            <div {...{ className: "reply-emailbox-header" }}>
              <div className="reply-fwd-container">
                <Form.Item
                  {...{ name: "type", initialValue: type }}
                  className="reply-all-form-item"
                >
                  <Select
                    {...{
                      className: "typeStyle",
                      onChange: changeReplyType,
                      initialValue: type,
                      popupClassName: "emailBoxDropDownStyle",
                    }}
                  >
                    {[
                      {
                        key: "reply",
                        value: !isChild ? "Reply" : "Reply All",
                      },
                      { key: "forward", value: "Forward" },
                    ].map(({ key, value }) => (
                      <Option {...{ value: key }}>{value}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>

              <EmailBoxHeaderInputs form={form} rowData={rowData} />
            </div>

            <CommunicationRichEditor
              toolbarUp={true}
              messageType={messageType}
            />
            <div style={{ paddingLeft: 12 }}>
              {currentEmail?.replies?.length > 0 && (
                <RepliesView replies={currentEmail?.replies} />
              )}
            </div>

            <ReplyEmailBoxFooter
              onSaveEmail={onSaveEmail}
              loading={loading}
              documentsActive={documentsActive}
              setDocumentsActive={setDocumentsActive}
              rowData={rowData}
              recordDetails={recordDetails}
              setIsActive={setIsActive}
            />
          </div>
        </div>
        <FilePreviewModal
          {...{
            visible: filePreview,
            setVisible: () => setFilePreview(false),
            file: selectedFile,
            allowEmail: false,
          }}
        />

        {openConfirmation && (
          <ConfirmationModal
            visible={openConfirmation}
            setVisible={setOpenConfirmation}
            title="Clear Form"
            text={
              <Text>
                Are you sure you want to clear the form including attachments?
              </Text>
            }
            onConfirm={handleClearForm}
          />
        )}
      </Form>
    </>
  );
};
export default ReplyEmailBox;
