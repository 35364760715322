import { Dropdown } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CalendarDateIcon } from "../../../../assets";
import { dayjsNY } from "../../../DateComponents/contants/DayjsNY";
import { RedWarningModal, StarCheckbox } from "../../../commonComponents";
import { ThreeDotsIcon } from "../../../SidebarPages/Communication/assets";
import { UserIcon } from "src/components/pages/Settings/settingsComponents/Roles/components/DepartmentPage/components";

import "../styles/ProposalCard.scss";

function ProposalCard({ item = {}, handleDeleteProposal }) {
  const { isDarkMode } = useSelector((state) => state?.darkMode);
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();

  return (
    <div className={`proposal_card_draft_wrapper ${isDarkMode ? "dark" : ""}`}>
      <div className="cardHeader">
        <span>
          {" "}
          <StarCheckbox
            {...{
              checked: true,
              onChange: (favorite) => {},
            }}
          />
          <span
            title={item?.otherDetails?.jobSiteAddress}
            className="title-text"
          >
            {item?.otherDetails?.jobSiteAddress}
          </span>
        </span>

        <Dropdown
          overlayClassName={`layout-card-dropdown${
            isDarkMode ? " layout-dropdown-dark" : ""
          }`}
          trigger={["hover"]}
          menu={{
            items: [
              {
                key: "delete",
                label: "Delete",
                onClick: () => {
                  setVisible(true);
                },
              },
              {
                key: "to-do",
                label: "To Do",
                onClick: () => {},
              },
            ].filter(Boolean),
          }}
          placement="bottomRight"
        >
          <ThreeDotsIcon />
        </Dropdown>
      </div>{" "}
      <div
        className="cardBody"
        onClick={() => {
          navigate(
            `/new-proposal-builder?tab=Take-Off&takeOffId=${item?.takeOffVersion}&proposalId=${item?.proposalId}`
          );
        }}
      >
        <img src="https://i.ibb.co/2kM5gzZ/61bce4643a18373cd436962e8f50a521.png" />
      </div>{" "}
      <div
        onClick={() => {
          navigate(
            `/new-proposal-builder?tab=Take-Off&takeOffId=${item?.takeOffVersion}&proposalId=${item?.proposalId}`
          );
        }}
        className="cardFooter"
      >
        <div className="footer-row">
          <UserIcon width={20} height={20} />
          <span>
            <span> Created by </span>
            <strong>{`${item?.otherDetails?.createdBy}`} </strong>
          </span>
        </div>
        <div className="footer-row">
          <CalendarDateIcon width={20} height={20} />
          <span>
            <span> Created at </span>
            <strong>
              {`${dayjsNY(item?.otherDetails?.createdAt).format(
                "MM/DD/YYYY HH:mm A"
              )}`}{" "}
            </strong>
          </span>
        </div>
      </div>{" "}
      {/* <strong>{item?.otherDetails?.title}</strong> */}
      {visible && (
        <RedWarningModal
          visible={true}
          onCancel={() => {
            setVisible(false);
          }}
          deleteModal={true}
          setVisible={setVisible}
          children={
            <span>
              Are you sure you want to delete the Proposal for{" "}
              <b>{item?.otherDetails?.jobSiteAddress}</b>?
            </span>
          }
          footerProps={{
            onConfirm: () =>
              handleDeleteProposal(item, () => setVisible(false)),
          }}
        />
      )}
    </div>
  );
}

export default ProposalCard;
