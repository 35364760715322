import "./NewTaskModal.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InfoCircleFilled, LockFilled, UnlockFilled } from "@ant-design/icons";
import { Form, Modal, Tour, message } from "antd";
import { API } from "aws-amplify";
import { XIcon } from "../../../../Communication/assets";
import {
  MondayButton,
  ProgressComponent,
  ToggleSwitch,
} from "../../../../../commonComponents";
import { useGoogleSignIn } from "../../../../../../hooks/useGoogleSignIn";
import { LogIn } from "../../../../../../assets";
import { loggedOnTasks as updateTaskRedux } from "../../../../../../actions";
import { sendTaskAssignmentEmail } from "../../../utils/sendTaskAssignmentEmail";
import { createEventFromTask } from "../../../../../Header/components/GoogleEvents/googleClientAPI/execute";
import {
  TaskFormFields,
  TaskCommentInput,
  TaskDatesInput,
  TaskReminderInput,
  TaskModalFooter,
} from "./components";
import {
  getAssignedUsers,
  getTaskBody,
  sendTaskCreateNotification,
  updateProjectTasksList,
} from "./helpers";
import TaskStepper from "../components/TaskStepper";
import CustomModalHeader, {
  PlayVideoTutorial,
  tourTitle,
} from "../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { updateDocumentTitle } from "../../../../../../utils";
import { isLoggedIn } from "../../../../../Header/components/GoogleEvents/googleClientAPI/authenticate";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import {
  useEditLogs,
  useProgressComponent,
  useUploadedFiles,
} from "../../../../../../hooks";
import { DocUploader } from "../../../../Documentation/View/components/DocumentationModal/components";
import { useCreateDriveFolders } from "../../../../../../hooks/useCreateDriveFolders";
import { driveApi } from "../../../../../../integrations/DriveRequest";
import { getTopics } from "../../utils/getTopics";
import { checkForService } from "../../utils/checkForService";
import moment from "moment";

function stepsMapHelper(title) {
  const stepsMap = {
    "Task Statuses": document.querySelector(".antCustomStepper"),
    "Task Title": document.querySelector(".task-title"),
    "Task Topic": document.querySelector(".task-topic"),
    Department: document.querySelector(".task-departments"),
    Labels: document.querySelector(".task-labels"),
    Description: document.querySelector(".rich-editor-description"),
    "Notify Time": document.querySelector(".timeDatePicker"),
    "Reminder Assignee": document.querySelector(".task-users"),
    "Create Event": document.querySelector(".login-for-events"),
    Date: document.querySelector(".taskStartDate"),
    Duration: document.querySelector(".taskEndDate"),
    Deadline: document.querySelector(".taskDeadline"),
    Save: document.querySelector(".create_task_button"),
  };
  return stepsMap[title] || null;
}

function mapRefs(dbSteps = [], setShowVideoTutorial, setTourOpen) {
  let newSteps = dbSteps?.map((step) => {
    return {
      title: tourTitle(step?.title, () => {
        setShowVideoTutorial(true);
        setTourOpen(false);
      }),
      description: step?.description,
      target: () => stepsMapHelper(step?.title),
      className: `custom-tour-light`,
    };
  });
  return newSteps;
}

const remindMeInitialState = {
  reminderValue: null,
  reminderType: "Days",
  reminderTime: "",
  reminderDays: null,
  notifiedBy: ["Email", "Notification"],
  reminderAssignedTo: [],
};

////// NOTE
//
//
////// DO NOT CHANGE THIS COMP. WITHOUT CONSULTING BECAUSE IT'S A REUSABLE COMP.
//
//
////// NOTE

/**
@param {Array} rowData - Array with tasks
@param {Function} updateRowData - setState for tasks state
@param {Boolean} newTaskModal - Boolean value to show or hide new task modal
@param {Function} setNewTaskModal - setState to open close new task modal 
@param {Object} clonedTask - OPTIONAL - if in edit mode of task we click clone button
@param {Function} setClonedTask - OPTIONAL - setState to remove current cloned task
@param {Object} customTaskProps - OPTIONAL - props getted from other dynamic views
*/
const NewTaskModal = ({
  rowData = [],
  updateRowData = () => {},
  newTaskModal,
  setNewTaskModal,
  clonedTask,
  setClonedTask = () => {},
  customTaskProps,
  taskStatuses,
  fromSettings = null,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { preferences } = useSelector((state) => state.preferences);
  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { hotCredentials } = useSelector((state) => state.hotCredentials);
  const { topicCategories } = useSelector((state) => state.topicCategories);

  const { onGoogleSignIn, userDetails } = useGoogleSignIn();

  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
    hideProgress,
  } = useProgressComponent({ categoryName: "Tasks", actionType: "Create" });
  const { saveAddedLogs } = useEditLogs();

  // =================== Upload ===================
  const [uploadHandlers, deleteHandlers] = useUploadedFiles([]);
  const [uploadedFiles, setUploadedFiles, formattedFiles, onPickerSuccess] =
    uploadHandlers;
  const [filesToBeDeleted, setFilesToBeDeleted, onDelete] = deleteHandlers;
  const driveFunctions = useCreateDriveFolders("Tasks");
  const taskHotCredential = (hotCredentials || [])?.find(
    (obj) => obj?.credentialId === "21"
  );
  const { updateFolderName, deleteDriveItem, copyFileToNewParent } = driveApi({
    accessToken,
  });
  // =================== Upload ===================

  const [currentStatus, setCurrentStatus] = useState(0);
  const [disableButton, setDisableButton] = useState(false);

  //TaskCommentInput STATES
  const [mentionedUsers, setMentionedUsers] = useState([]);

  //TaskReminderInput STATES
  const [remindMe, setRemindMe] = useState(remindMeInitialState);

  //Task privacy
  const [isPrivate, setIsPrivate] = useState(true);

  // =================== Tour steps ===================
  const [tourOpen, setTourOpen] = useState(false);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);

  const tourSteps = mapRefs(
    programFields
      ?.find(({ fieldName }) => fieldName === "Tutorials Steps")
      ?.fieldOptions.find(({ categoryName }) => categoryName === "taskModal")
      .steps,
    setShowVideoTutorial,
    setTourOpen
  );
  const videoTutorialLink = programFields
    ?.filter((item) => item.fieldName === "Portal Video Tutorials")[0]
    ?.fieldOptions.find((item) => item.categoryName === "General Tutorials")
    ?.subCategories[0].videos.find(
      (item) => item.videoName === "New Task"
    )?.videoLink;
  // =================== Tour steps ===================

  // when cloned task
  useEffect(() => {
    if (!!clonedTask) {
      const {
        taskStartDate,
        taskEndDate,
        taskDeadline,
        taskRelatedTo,
        taskStatus,
        remindMe,
      } = clonedTask;
      form.setFieldsValue({
        ...clonedTask,
        taskStartDate: dayjsNY(taskStartDate).format("MM/DD/YYYY"),
        taskStartTime: dayjsNY(taskStartDate).format("hh:mm A"),
        taskEndDate: dayjsNY(taskEndDate).format("MM/DD/YYYY"),
        taskEndTime: dayjsNY(taskEndDate).format("hh:mm A"),
        taskDeadline: dayjsNY(taskDeadline).format("MM/DD/YYYY"),
        taskDeadlineTime: dayjsNY(taskDeadline).format("hh:mm A"),
        datePickertaskStartDate: dayjsNY(taskStartDate),
        datePickertaskEndDate: dayjsNY(taskEndDate),
        datePickertaskDeadline: dayjsNY(taskDeadline),
        taskRelatedToObj: taskRelatedTo,
      });
      setCurrentStatus(
        taskStatuses?.findIndex((status) => taskStatus === status.title)
      );
      setRemindMe(remindMe);
    }
  }, [clonedTask]);

  const handleCloseModal = ({ cancel = false }) => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    !!clonedTask && setClonedTask();
    setNewTaskModal(false);
    if (!!cancel) {
      driveFunctions.parentFolderId && driveFunctions.delete();
    }
    if (filesToBeDeleted?.length > 0) {
      Promise.allSettled(
        filesToBeDeleted.map(async (id) => await deleteDriveItem(id))
      ).then(() => setFilesToBeDeleted([]));
    }
  };

  async function createTmpFolder(name) {
    const folderName = name ? name : "tmp-task";
    const { folders, parentFolder } = await driveFunctions.create({
      parentFolderName: `${folderName} ${dayjsNY().format(
        "DD/MM/YYYY HH:mm:ss"
      )}`,
      parentId: taskHotCredential?.credentialValue,
    });
    return { parentFolderId: parentFolder, allFolders: folders };
  }

  async function createInitialTaskFolder() {
    await createTmpFolder().then(async (res) => {
      if (clonedTask && clonedTask?.googleDriveUploads?.length > 0) {
        await Promise.allSettled(
          clonedTask?.googleDriveUploads.map(async (file) => {
            const { id, ...rest } = file;
            return await copyFileToNewParent(id, res.parentFolderId)
              .then((res) => {
                return { ...rest, id: res };
              })
              .catch((err) => {
                throw new Error(err);
              });
          })
        ).then((res) => {
          setUploadedFiles(
            res
              .filter((el) => el.status === "fulfilled")
              .map((file) => file.value)
          );
        });
      }
    });
  }

  useEffect(() => {
    if (accessToken) {
      createInitialTaskFolder();
    }
  }, [accessToken]);

  //region SAVE TASK
  const saveTask = async ({ newTask }) => {
    const topics = getTopics(topicCategories, userConfiguration, programFields);

    let taskTopicObject = form.getFieldValue("taskTopic") || {
      categoryName: customTaskProps?.taskTopic,
    };

    const taskFormFields = {
      ...form.getFieldsValue(),
      fromSettings:
        typeof fromSettings === "boolean" || fromSettings !== null
          ? fromSettings
          : checkForService(taskTopicObject, topics),
    };

    if (!taskFormFields?.taskTitle) {
      message.error("Please enter a title!");
      return;
    }

    if (
      !customTaskProps &&
      taskFormFields?.taskTopic &&
      !taskFormFields?.taskRelatedToObj
    ) {
      message.error("Please select an record!");
      return;
    }

    message.loading("Saving...");
    let folders = {};
    if (driveFunctions.parentFolderId) {
      await updateFolderName(
        driveFunctions.parentFolderId,
        `${taskFormFields?.taskTitle} ${dayjsNY().format(
          "DD/MM/YYYY HH:mm:ss"
        )}`
      );
      folders = driveFunctions.allFolders;
    } else {
      const { allFolders: createdFolders } = await createTmpFolder(
        taskFormFields?.taskTitle
      );
      folders = createdFolders;
    }
    setDisableButton(true);
    setVisibleCreationProgress(true);

    form.submit();
    const { taskAssignedTo, projectTask, projectSubTask, assignToMe } =
      taskFormFields;

    const updatedTaskAssignedTo = getAssignedUsers(
      assignToMe,
      taskAssignedTo,
      userConfiguration
    ).filter(
      ({ cognitoUserId }) =>
        !isPrivate || cognitoUserId === userConfiguration?.cognitoUserId
    );

    const taskToSave = getTaskBody(
      taskFormFields,
      customTaskProps,
      updatedTaskAssignedTo,
      remindMe,
      currentStatus,
      rowData,
      userConfiguration,
      folders,
      formattedFiles,
      taskStatuses,
      isPrivate
    );

    const assignedUsers = updatedTaskAssignedTo.filter(
      ({ cognitoUserId }) => cognitoUserId !== userConfiguration.cognitoUserId
    );

    !isLoggedIn() && hideProgress("updatingEvent"); // this removes progress for event creation because if user is not logged in it will not create any event!
    // this removes 2 progresses if no user is assigned in task
    if (!assignedUsers.length) {
      hideProgress("sendingNotification");
      hideProgress("sendingEmail");
    }
    updateProgressStatus({ updatingRecord: "executing" });
    API.post("tasksManagement", "/tasksManagement", { body: taskToSave })
      .then(async (taskRes) => {
        message.success("Task has been created successfully!");

        setVisibleCreationProgress(taskRes); // save datas to generate logs in case of any fail
        updateProgressStatus({
          updatingRecord: "finished",
          updatingEvent: "executing",
        });
        setDisableButton(false);

        const updatedRowData = [...rowData, taskRes];
        updateRowData(updatedRowData);
        dispatch(updateTaskRedux(updatedRowData)); //Update logged tasks state on redux

        // CREATE EVENT FOR TASK
        await createEventFromTask(
          { ...taskToSave, taskId: taskRes.taskId },
          preferences?.calendarPreference
        )
          .then((res) => {
            updateProgressStatus({
              updatingEvent: "finished",
              sendingEmail: "executing",
            });
          })
          .catch((error) => {
            updateProgressStatus({
              updatingEvent: "hasError",
              sendingEmail: "executing",
            });
          });
        // EVENT CREATED

        // Send email to all users assigned to this task
        await sendTaskAssignmentEmail(
          assignedUsers,
          taskRes?.taskTitle,
          taskRes?.taskTopic,
          taskRes?.taskRelatedTo,
          taskRes?.taskDeadline,
          taskRes?.taskDescription,
          taskRes.taskId
        )
          .then(() => {
            updateProgressStatus({
              sendingNotification: "executing",
              sendingEmail: "finished",
            });
          })
          .catch((err) => {
            updateProgressStatus({
              sendingNotification: "executing",
              sendingEmail: "hasError",
            });
          });

        //update project task List
        if (taskRes.taskTopic.toLowerCase() === "projects" && projectTask) {
          updateProjectTasksList(taskRes, projectTask, projectSubTask);
        }

        //save logs
        saveAddedLogs({
          recordId: taskRes.taskId,
          recordName: taskRes.taskTitle,
          category: "Tasks",
          topic: taskRes.taskRelatedTo,
        });

        if (!isPrivate) {
          //send notification
          sendTaskCreateNotification(
            userConfiguration,
            taskRes,
            assignedUsers,
            mentionedUsers,
            updateProgressStatus
          );
        }

        if (!!newTask) {
          form.resetFields();
          setRemindMe(remindMeInitialState);
          setMentionedUsers();
          setCurrentStatus(0);
        }
      })
      .catch((e) => {
        console.log("Something went wrong on task creation", { e });
        driveFunctions.parentFolderId && driveFunctions.delete();
        updateProgressStatus({ updatingRecord: "hasError" });
        message.error({
          content: "Something went wrong on task creation",
          key: "taskCreationError",
          duration: 5,
        });
      });
  };

  return (
    <>
      <Modal
        afterOpenChange={(event) => {
          event && updateDocumentTitle({ newTitle: "Create New Task" });
        }}
        open={newTaskModal}
        className={`add-task-modal ${isDarkMode && "add-task-modal-dark"}`}
        title={
          <CustomModalHeader
            title="Create New Task"
            onClick={() => setTourOpen(true)}
            headerInfo={() => (
              <ToggleSwitch
                variant="filled"
                checked={isPrivate}
                label={isPrivate ? "Private" : "Public"}
                icon={isPrivate ? <LockFilled /> : <UnlockFilled />}
                onChange={setIsPrivate}
              />
            )}
          />
        }
        destroyOnClose={true}
        closeIcon={<XIcon />}
        onCancel={() => handleCloseModal({ cancel: true })}
        width={1826}
        centered
        footer={
          <TaskModalFooter {...{ saveTask, handleCloseModal, disableButton }} />
        }
        keyboard={false}
      >
        <Form
          initialValues={{
            taskLabels: customTaskProps?.taskLabels || [],
            taskStartDate: moment().startOf("day").format("MM/DD/YYYY"),
            taskEndDate: moment()
              .add(1, "day")
              .startOf("day")
              .format("MM/DD/YYYY"),
            taskDeadline: moment()
              .add(1, "day")
              .startOf("day")
              .format("MM/DD/YYYY"),
          }}
          form={form}
          layout="vertical"
        >
          <div className="modal-box-section">
            <div className="left-section">
              <TaskStepper
                steps={taskStatuses}
                currentStep={currentStatus}
                setCurrentStep={(index) => setCurrentStatus(index)}
              />

              <div
                className="left-section-details"
                style={{ display: "flex", gap: "20px" }}
              >
                <DocUploader
                  {...{
                    onPickerSuccess,
                    uploadedFiles,
                    setUploadedFiles,
                    onDelete,
                    folderId: driveFunctions.parentFolderId || "",
                    checkWarnings: false,
                    onChange: () => {},
                    onCancel: () => handleCloseModal({ cancel: true }),
                    responsiveWidth: 1400,
                  }}
                />

                <div className="block-section">
                  <TaskFormFields
                    {...{ form, customTaskProps, fromSettings, isPrivate }}
                  />
                  <TaskCommentInput
                    {...{ form, mentionedUsers, setMentionedUsers }}
                  />
                </div>
              </div>
            </div>
            <div className="right-section">
              <div className="login-for-events">
                <div style={{ display: "flex", gap: "10px" }}>
                  <InfoCircleFilled
                    style={{ fontSize: "20px", color: "#1264A3" }}
                  />
                  <span style={{ fontSize: 14 }}>
                    {userDetails?.email
                      ? `You are logged in as: ${userDetails.email}`
                      : "You must be logged in to create an event."}
                  </span>
                </div>
                {!userDetails?.email && (
                  <MondayButton
                    onClick={() => onGoogleSignIn()}
                    Icon={<LogIn width={19} height={19} />}
                    containerClassName="signInButtonContainer"
                    className="mondayButtonBlue"
                    tooltipCategory="Tasks"
                    tooltipKey="signin"
                  >
                    Sign In
                  </MondayButton>
                )}
              </div>

              <TaskDatesInput {...{ form }} />

              <TaskReminderInput {...{ form, remindMe, setRemindMe }} />
            </div>
          </div>
        </Form>
      </Modal>

      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={tourSteps}
          mask={{ color: "#2a2b3a71" }}
        />
      )}

      {showVideoTutorial && (
        <PlayVideoTutorial
          {...{
            visible: showVideoTutorial,
            setVisible: setShowVideoTutorial,
            url: videoTutorialLink,
            title: "Create Task Tutorial",
          }}
        />
      )}

      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: "Tasks",
            actionType: "Create",
            visibleCreationProgress,
            creationProgresses,
            closeModal: handleCloseModal,
          }}
        />
      )}
    </>
  );
};

export default NewTaskModal;
