import { SimplePLI } from "./GeneralModels";

export class CoreCanopyPLI extends SimplePLI {
  serviceId = 89;

  length;
  height;
  width;

  constructor(id, taxRate) {
    super(id, taxRate);
  }
}
export class AlternateCoreCanopyPLI extends SimplePLI {
  serviceId = 89;

  length;
  height;
  width;
  isAlternate = true;

  constructor(id, taxRate) {
    super(id, taxRate);
  }
}
