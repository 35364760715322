import { message, Modal } from "antd";
import { useSelector } from "react-redux";
import logsColumnDefs from "./helpers/logsColumnDefs";
import "./SessionLogsModal.scss";
import { gridOptions } from "../../../../commonComponents/UploadRecords/components/Steps/columnDefs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { antdModalProps, gsiQueryTable } from "../../../../../utils";
import applySessionLogsFilters from "../SessionLogsDrawer/helpers/applySessionLogsFilters";
import { isEmpty } from "lodash";
import SessionLogsModalActions from "./components/SessionLogsModalActions";
import { MondayButton } from "../../../../commonComponents";
import { XIcon } from "../../../../SidebarPages/Communication/assets";

function SessionLogsModal({
  visible,
  setVisible,
  sessionId,
  title = "Session Logs",
  clickedUser = "",
  clickedIp = "",
}) {
  const navigate = useNavigate();

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { sessionNotifications } = useSelector(
    (state) => state.sessionNotifications
  );
  const { allLogs = [], newCount = 0 } = sessionNotifications || {};
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const gridRef = useRef(null);
  const timeoutRef = useRef(null);

  const [initialState, setInitialState] = useState([]);
  const [unauthorizedIpActivities, setUnauthorizedIpActivities] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchLogs = async () => {
    if (clickedUser || clickedIp) {
      setLoading(true);
      try {
        const res = await gsiQueryTable({
          tableName: "editLogs",
          indexName: "recordId-index",
          filterKey: "recordId",
          filterValue: "Ip Address Access",
        });
        return res;
      } catch (error) {
        console.log({ error });
      } finally {
        setLoading(false);
        message.destroy();
      }
    }
  };

  useEffect(() => {
    const getLogs = async () => {
      try {
        const res = await fetchLogs();
        if (res.length) {
          const sortedLogs = res.sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          );

          const oneToManyRelationUsers =
            sortedLogs.filter(({ nameOfUser }) => nameOfUser === clickedUser) ||
            [];

          const oneToManyRelationIps =
            oneToManyRelationUsers.filter(
              (row) => row.sessionInfo.ipAddress === clickedIp
            ) || [];

          const proceedLogs = oneToManyRelationIps.map((row) => {
            return {
              device: row.currentData.devices.deviceName,
              ipv4: row.sessionInfo.ipAddress,
              reason:
                row.currentData.reason ||
                "no device access " + row.currentData.devices.deviceName,
              nameOfUser: row.nameOfUser,
            };
          });
          setUnauthorizedIpActivities(proceedLogs || []);
        }
      } catch (error) {
        console.error("Error fetching logs:", error);
      }
    };

    getLogs();
  }, [clickedIp, clickedUser]);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [filters, setFilters] = useState({});
  const [filteredRowData, setFilteredRowData] = useState([]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  function onRowClick({ data }) {
    if (!!sessionId) return;
    const { identityId } = data?.sessionInfo;
    if (identityId) {
      const path = window.location.pathname;
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("sessionUserId", identityId);
      const newUrl = `${path}?${queryParams.toString()}`;
      navigate(newUrl);
    }
  }

  const autoSizeAllColumns = useCallback(
    (skipHeader) => {
      if (initialState) {
        // setTimeout(() => {
        const allColumnIds = [];
        gridRef?.current?.columnApi?.getColumns().forEach((column) => {
          allColumnIds.push(column.getId());
        });
        gridRef?.current?.columnApi?.autoSizeColumns(allColumnIds, skipHeader);
        const widths = {
          rowWidth: parseInt(
            document.getElementsByClassName("ag-row")[0]?.offsetWidth
          ),
          parentWidth: parseInt(
            document.getElementsByClassName("ag-body-viewport")[0]?.offsetWidth
          ),
        };
        // If total width of rows is less than the container width, fit columns to screen
        if (widths.rowWidth < widths.parentWidth) {
          gridRef?.current?.api?.sizeColumnsToFit();
        }
      }
      // }, 100);
    },
    [initialState]
  );

  const allUsersObj = useMemo(
    () =>
      userConfiguration?.allUsers?.Items?.reduce((acc, user) => {
        acc[user.identityId] = user;
        return acc;
      }, {}) || {},
    [userConfiguration]
  );

  const columnDefs = useMemo(
    () => logsColumnDefs({ allUsersObj, sessionId }),
    [allUsersObj]
  );

  function onFilterApply(newFilters) {
    if (Object.keys(newFilters).length === 0) {
      gridApi.setRowData(initialState);
      setFilteredRowData(initialState);
      setFilters({});
      return;
    }
    gridApi.showLoadingOverlay();
    const newRowData = applySessionLogsFilters({
      allLogs: initialState,
      filters: newFilters,
    });
    setTimeout(() => {
      setFilters(newFilters);
      gridApi.setRowData(newRowData);
      setFilteredRowData(newRowData);
      gridApi.hideOverlay();
    }, 1000);
  }

  const clearVisible = useMemo(() => {
    return (
      Object.keys(filters).length > 0 &&
      Object.keys(filters)?.some((key) => !isEmpty(filters[key]))
    );
  }, [filters]);

  useEffect(() => {
    if (sessionId) {
      const filteredLogs = allLogs?.filter((el) => el?.sessionId === sessionId);
      setInitialState(filteredLogs);
      setFilteredRowData(filteredLogs);
    } else {
      setInitialState(allLogs);
      setFilteredRowData(allLogs);
    }
  }, [allLogs.length, sessionId]);

  // function getDifferenceInMinutes(timestamp1, timestamp2) {
  //   // Convert timestamps to Day.js objects
  //   const date1 = dayjs(timestamp1);
  //   const date2 = dayjs(timestamp2);

  //   // Calculate the difference in milliseconds
  //   const diffMilliseconds = date2.diff(date1);

  //   // Convert milliseconds to minutes
  //   const diffMinutes = diffMilliseconds / (1000 * 60);
  //   const turnPositive = Math.abs(diffMinutes).toFixed(2);

  //   if (turnPositive < 1) {
  //     return "less than a minute";
  //   } else if (turnPositive < 60) {
  //     return `${turnPositive} minutes`;
  //   } else {
  //     const hours = Math.floor(turnPositive / 60);
  //     const minutes = turnPositive % 60;
  //     return `${hours} hours ${minutes} minutes`;
  //   }
  // }
  // function processTimeData(data) {
  //   const sortedData = data.sort((a, b) => {
  //     return a.sessionInfo.logTime - b.sessionInfo.logTime;
  //   });
  //   // Calculate the inMinutes for each object
  //   const withTimeDiffs = sortedData.map((item, index, array) => {
  //     if (index === array.length - 1) {
  //       // Last item has no next item to compare with
  //       return { ...item, inMinutes: null };
  //     }

  //     const nextItem = array[index + 1];
  //     const diffInMinutes = getDifferenceInMinutes(
  //       nextItem?.sessionInfo?.logTime,
  //       item?.sessionInfo?.logTime
  //     );

  //     return { ...item, inMinutes: diffInMinutes };
  //   });

  //   return withTimeDiffs;
  // }

  // console.log({ withTime: processTimeData(filteredRowData) });

  const gridRowData = useMemo(() => {
    return initialState.concat(unauthorizedIpActivities);
  }, [initialState?.length, unauthorizedIpActivities?.length]);

  return (
    <Modal
      {...{
        ...antdModalProps,
        title: sessionId ? "Device Activity - " + title : title,
        open: visible,
        className: "session-logs-modal",
        onCancel: () => {
          setVisible(false);
        },
        wrapClassName: "",
        maskClosable: !sessionId,
        footer: (
          <MondayButton
            {...{
              onClick: () => setVisible(false),
              Icon: <XIcon />,
              className: "mondayButtonGrey",
            }}
          >
            Close
          </MondayButton>
        ),
      }}
    >
      <div className="session-logs-modal-container">
        <SessionLogsModalActions
          {...{
            isDarkMode,
            clearVisible,
            onFilterApply,
            filteredRowData,
            allUsersObj,
            filters,
            initialState,
            sessionId,
            title,
          }}
        />

        <div
          className={`${
            isDarkMode
              ? "dark-ag-theme ag-theme-alpine-dark"
              : "light-ag-theme ag-theme-alpine"
          }`}
          style={{
            height: "70vh",
            width: "100%",
          }}
          id="agGridId"
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "70vh",
              }}
            >
              Loading...
            </div>
          ) : (
            <AgGridReact
              {...{
                id: "agGridId",
                defaultColDef: {
                  resizable: true,
                  enablePivot: true,
                  enableRowGroup: true,
                  editable: false,
                  sortable: true,
                  filter: true,
                  flex: 1,
                },
                paginationPageSize: 12,
                paginationAutoPageSize: true,
                columnDefs,
                rowData: gridRowData,
                gridOptions,
                // sideBar: gridOptions.sideBar,
                onGridReady,
                onRowClicked: onRowClick,
                ref: gridRef,
                onFirstDataRendered: () => {
                  clearTimeout(timeoutRef.current);
                  if (!sessionId) {
                    timeoutRef.current = setTimeout(() => {
                      autoSizeAllColumns(false);
                    }, 300);
                  }
                },
              }}
            />
          )}
        </div>
      </div>
    </Modal>
  );
}

export default SessionLogsModal;
