import { API } from "aws-amplify";

export const lazyFetch = async ({
  tableName,
  listOfKeys = [],
  filterKey,
  filterValue,
}) =>
  await API.get("lazyFetching", "/lazyFetching", {
    queryStringParameters: { tableName, listOfKeys, filterKey, filterValue },
  });

export const apiPost = async (endpoint, body) =>
  await API.post(endpoint, `/${endpoint}`, { body });

export const apiDelete = async (endpoint, id) =>
  await API.del(endpoint, `/${endpoint}/${id}`);

export const apiPut = async (endpoint, id, body) =>
  await API.put(endpoint, `/${endpoint}/${id}`, { body });

export const fetchData = async ({
  endpoint,
  query,
  queryStringParams = undefined,
}) => {
  return await API.get(endpoint, `/${query || endpoint}`, queryStringParams);
};

export const fetchAllData = async ({
  endpoint,
  pathName = endpoint,
  resultPosition = endpoint,
  resultId,
  customSetter = () => {},
  otherStringParams = {},
}) => {
  const items = [];

  let currentPageKey = undefined;

  while (true) {
    const result = await fetchData({
      endpoint,
      query: pathName,
      queryStringParams: {
        queryStringParameters: {
          ExclusiveStartKey: currentPageKey,
          withPagination: "true",
          getMaxLimit: "true", // When this key is set to "true", data retrieval is based on the total size limit, not restricted to just 24 records.
          ...otherStringParams,
        },
      },
    });

    const updatedResults = result?.[resultPosition] || result;

    items.push(...updatedResults);
    customSetter(updatedResults);

    if (!result.LastEvaluatedKey) {
      break;
    }

    if (!resultId) {
      throw new Error("resultId is required");
    }

    const { userId, ...rest } = result.LastEvaluatedKey || {};

    currentPageKey = rest[resultId];

    // if there are some other keys excepting primaryKey pass them too. Example notifications are using createdAt key too
    if (Object.keys(rest).length > 1) {
      currentPageKey = JSON.stringify(result.LastEvaluatedKey);
    }
  }

  return items;
};
