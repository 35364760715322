import { useContext, useMemo } from "react";
import { ExportOrEmailDropdown } from "../../../commonComponents";
import { MobileBasePageContext } from "../MobileBasePage";
import { camelCaseToTitleCase } from "../../../pages/Settings/settingsComponents/ProjectsOverviewConfig/utils";
import { useSelector } from "react-redux";
import {
  onGeneratePDF,
  exportToExcel,
  uploadExcelToDrive,
} from "../../../pages/Settings/settingsComponents/DocumentationConfiguration/components/DocumentationsPanel/ExpirationDateEnhacment/utils";
import { onGeneratePDFMobile } from "../exportActionsUtils";
import { camelCase } from "lodash";

const Export = () => {
  const hostOrigin = window.location.href;
  const { rowData, dynamicColumnDefs, title } = useContext(
    MobileBasePageContext
  );

  const { topicCategories } = useSelector((state) => state.topicCategories);
  const { base64 } = useSelector((state) => state.base64);

  const dynamicPrimaryKey = useMemo(() => {
    return topicCategories?.find(({ categoryName }) => categoryName === title)
      ?.primaryKey;
  }, [topicCategories, title]);

  const dataToExport = useMemo(() => {
    const proceedData = rowData.map((row) => {
      const filteredRow = dynamicColumnDefs.reduce(
        (acc, { field: orgKey, headerName }) => {
          const field = camelCase(headerName) || orgKey;
          if (row.hasOwnProperty(orgKey)) {
            const value = enhanceValue(row[orgKey], field, orgKey);
            if (value !== undefined) {
              acc[field] = value;
            }
          }
          return acc;
        },
        {}
      );

      if (row.hasOwnProperty(dynamicPrimaryKey)) {
        filteredRow[dynamicPrimaryKey] = row[dynamicPrimaryKey];
      }

      return filteredRow;
    });

    const { rowName } =
      topicCategories?.find(({ categoryName }) => categoryName === title) || {};

    const dataToExp = proceedData
      .map((p) => ({
        ...p,
        ...(rowName && {
          [rowName]: [
            {
              value: p[rowName],
              redirect: `${hostOrigin}/${p[dynamicPrimaryKey]}`,
            },
          ],
        }),
      }))
      .map(({ [dynamicPrimaryKey]: _, ...rest }) => rest);

    const allKeys = Array.from(
      new Set(dataToExp.flatMap((row) => Object.keys(row)))
    );

    const normalizedData = dataToExp.map((row) => {
      return allKeys.reduce((acc, key) => {
        acc[key] = row[key] || "";
        return acc;
      }, {});
    });

    return normalizedData;
  }, [rowData, dynamicColumnDefs, topicCategories]);

  const execlDataToExport = useMemo(() => {
    const proceedData = rowData.map((row) => {
      const filteredRow = dynamicColumnDefs.reduce((acc, { field }) => {
        if (row.hasOwnProperty(field)) {
          const value = enhanceValue(row[field], field);
          if (value !== undefined) {
            acc[field] = value;
          }
        }
        return acc;
      }, {});

      return filteredRow;
    });
    return proceedData;
  }, [rowData, dynamicColumnDefs]);

  const columnHeader = useMemo(() => {
    if (dataToExport && dataToExport.length > 0) {
      const keys = Array.from(
        new Set(dataToExport.flatMap((row) => Object.keys(row)))
      );

      return keys.map((key) => camelCaseToTitleCase(key));
    }
    return [];
  }, [dataToExport]);

  return (
    <ExportOrEmailDropdown
      {...{
        rowData: true,
        title,
        topicCategory: "",
        defaultDocDefinition: true,
        onGeneratePDF: (action, getDocDefinition) => {
          return onGeneratePDFMobile({
            action,
            base64,
            getDocDefinition,
            dataToExport,
            columnHeader,
            ...(action !== "print" && { titleOfPd: title }),
            title,
          });
        },
        exportGridToExcel: (getDocDefinition) =>
          exportToExcel({
            getDocDefinition,
            dataToExport: execlDataToExport,
            columnHeader,
            title: title,
          }),
        uploadExcelToDrive: (driveRequest, driveFolderId) => {
          return uploadExcelToDrive({
            dataToExport,
            columnHeader,
            driveRequest,
            driveFolderId,
          });
        },
      }}
    />
  );
};

export default Export;

function enhanceValue(value, key, helperField) {
  if (typeof value === "string" || typeof value === "number") {
    return value;
  }

  if (!Array.isArray(value)) {
    return value?.[key] || value?.[helperField];
  }

  if (Array.isArray(value)) {
    return;
  }
}
