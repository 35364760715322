import { getChangedData } from "../../../../../SidebarPages/Accounting/components/utilities";

export const compareArrays = (oldArray = [], newArray = []) => {
  console.log({ oldArray, newArray });
  const changes = {
    added: [],
    deleted: [],
    updated: [],
  };

  const oldMap = new Map(oldArray.map((item) => [item.id, item]));
  const newMap = new Map(newArray.map((item) => [item.id, item]));

  // Check for added and updated items
  newArray.forEach((newItem) => {
    if (!oldMap.has(newItem.id)) {
      changes.added.push(newItem); // New item added
    } else {
      const oldItem = oldMap.get(newItem.id);
      const differences = getChangedData(newItem, oldItem);
      if (Object.keys(differences).length > 0) {
        changes.updated.push({
          id: newItem.id,
          fullName: oldItem.fullName,
          changes: differences,
        });
      }
    }
  });

  // Check for deleted items
  oldArray.forEach((oldItem) => {
    if (!newMap.has(oldItem.id)) {
      changes.deleted.push(oldItem);
    }
  });

  return changes;
};
